import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SectionWithTitle from "../../generic/SectionWithTitle";
import UserDetailStore from  "../../../flux/UserDetailStore";
import { getUserDetails, udpateUserDetail, getSession } from "../../../utils/FetchHelper";
import Dispatcher from "../../../flux/Dispatcher";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import SpinningNotification from '../../generic/SpinningNotification';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as FetchHelper from "../../../utils/FetchHelper";

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class UserUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            firstName: '',
            lastName: '',
            userName: '',
            email:'',
            formDirty: false,
            currentTenantName: ""
            }
        this.formSubmit = this.formSubmit.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.validatePwdInput = this.validatePwdInput.bind(this);
        this.refreshState = this.refreshState.bind(this);
    }

    componentDidMount(){
        
        var data = getSession(true);
        this.setState({currentTenantName: FetchHelper.getCurrentTenantName()});
        var user;
        data.then(session => {
            user = session.user;
            if(user !== undefined && !session.isidm) {
                let loadSpinAction = "USER_DETAIL_LOADING_SPIN";
                Dispatcher.dispatch({
                    type: loadSpinAction,
                    payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
                });
                getUserDetails(user.userName, false);
            }
        })
        .catch(error => {
            // handle error
        });
        
        UserDetailStore.on("change", this.refreshState);
    }

    componentWillUnmount() {
        UserDetailStore.removeListener("change", this.refreshState);
    }

    refreshState() {
        if(UserDetailStore.getState().user !== undefined){
            this.setState({
                user: UserDetailStore.getState().user,
                groups: UserDetailStore.getState().groups,
                firstName: UserDetailStore.getState().user.firstName,
                lastName: UserDetailStore.getState().user.lastName,
                userName: UserDetailStore.getState().user.userName,
                email:UserDetailStore.getState().user.email,
                password: "",
                repassword: "",
                newpassword: ""
            })
        }
    }

    formSubmit(event){
        event.preventDefault();
        const errors = Object.assign([], this.state.errorcode);
        if(errors === undefined || errors.length === 0){
            this.setState({errorcode: []});
            if(this.state.formDirty) {
                let loadSpinAction = "USER_UPDATE_INPROGRESS_LOADING_SPIN";
                Dispatcher.dispatch({
                    type: loadSpinAction,
                    payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
                });
                this.setState({formDirty: false});
                udpateUserDetail(event.target.elements);
            } else {
                Dispatcher.dispatch({
                    type: "GRP_UPDATE_ERROR",
                    payload: LocaleUtility.getLocaleMessage("user.update.error")
                });
            }
        } else {
            Dispatcher.dispatch({
                type: "GRP_UPDATE_ERROR",
                payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
            });
            this.setState({errorcode: errors});
        }
    }

    validatePwdInput(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.value !== '') {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            
            if(event.target.form.elements.newpassword.value.length > 0 ){
                if(event.target.form.elements.newpassword.value.length < 8 ){
                    index = errors.indexOf('invalidLenghtNewPwd')
                    if (index === -1) {
                        errors.push('invalidLenghtNewPwd');
                    }
                } else if(!ValidationUtility.pwdValidation(event.target.form.elements.newpassword.value)){
                    index = errors.indexOf('invalidLenghtNewPwd')
                    if (index !== -1)
                        errors.splice(index, 1);
                    index = errors.indexOf('invalidNewPwd')
                    if (index === -1) {
                        errors.push('invalidNewPwd');
                    }
                } else {
                    index = errors.indexOf('invalidLenghtNewPwd')
                    errors.splice(index, 1);
                    index = errors.indexOf('invalidNewPwd')
                    errors.splice(index, 1);
                }
            } 
           
            if((event.target.name === 'password' || event.target.name === 'newpassword'
                || event.target.name === 'repassword')){
                if(this.props.id === undefined){
                    index = errors.indexOf('invalidOldNew')
                    if((event.target.form.elements.password.value.length > 0 && 
                        event.target.form.elements.newpassword.value.length > 0) &&
                        event.target.form.elements.password.value === event.target.form.elements.newpassword.value ){
                        if(index === -1)
                            errors.push('invalidOldNew');
                    } else {
                        if (index !== -1) {
                            errors.splice(index, 1);
                        }
                    }
                }

                index = errors.indexOf('confirmInvalid')
                if((event.target.form.elements.repassword.value.length > 0 && 
                    event.target.form.elements.newpassword.value.length > 0) &&
                    event.target.form.elements.repassword.value !== event.target.form.elements.newpassword.value ){
                    if(index === -1)
                        errors.push('confirmInvalid');
                }else {
                    if (index !== -1) {
                        errors.splice(index, 1);
                    }
                }
            }

            this.setState({errorcode: errors});
        }
        this.setState({formDirty: true});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    validateTextInput(event){
        const errors = Object.assign([], this.state.errorcode);
        let field = event.target.name;
        let index = errors.indexOf(field)
        this.setState({ [field]: event.target.value });
        if (event.target.value !== '') {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            index = errors.indexOf('invalidEmail')
            if(event.currentTarget.name==="email"){
                if(!ValidationUtility.validateEmail(event.target.value)){
                    if (index === -1) {
                        errors.push('invalidEmail');
                    }
                } else {
                    if (index !== -1) {
                        errors.splice(index, 1);
                    }
                }
            }

            index = errors.indexOf('invalidFName')
            if(event.currentTarget.name==="firstName"){
                if(!ValidationUtility.alphanumericAndSpaceCheck(event.target.value) ){
                    if (index === -1) {
                        errors.push('invalidFName');
                    }
                } else {
                    if (index !== -1) {
                        errors.splice(index, 1);
                    }
                }
            }

            index = errors.indexOf('invalidLName')
            if(event.currentTarget.name==="lastName"){
                if(!ValidationUtility.alphanumericAndSpaceCheck(event.target.value) ){
                    if (index === -1) {
                        errors.push('invalidLName');
                    }
                } else {
                    if (index !== -1) {
                        errors.splice(index, 1);
                    }
                }
            }

            this.setState({errorcode: errors});
        }
        this.setState({formDirty: true});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    sectionContent() {
        let pwdFormFieldError, newpwdFormFieldError, repwdFormFieldError,
            fNFormFieldError, lNFormFieldError, uNFormFieldError, emailFormFieldError,
            fNError, lNError, uNError, emailError, pwdError, newpwdError, repwdError, 
            styleField1 = { width: '30em'}, styleField2 = { width: '30em'};

        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){

            if(this.state.errorcode.indexOf('password') !== -1){
                pwdError =<span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
                pwdFormFieldError='grommetux-form-field--error';
            } 
            if(this.state.errorcode.indexOf('repassword') !== -1){
                repwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
                repwdFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('newpassword') !== -1){
                newpwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
                newpwdFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidLenghtNewPwd') !== -1){
                newpwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert4")}</span>;
                newpwdFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidNewPwd') !== -1){
                newpwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert7")}</span>;
                newpwdFormFieldError = 'grommetux-form-field--error';
                styleField1 = { width: '30em' , minHeight: '95px'}
            } 
            if(this.state.errorcode.indexOf('invalidRePwd') !== -1){
                newpwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert7")}</span>;
                newpwdFormFieldError = 'grommetux-form-field--error';
                styleField2 = { width: '30em' , minHeight: '95px'}
            } 
            if(this.state.errorcode.indexOf('confirmInvalid') !== -1){
                repwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert5")}</span>;
                repwdFormFieldError = 'grommetux-form-field--error';
                styleField2 = { width: '30em' , minHeight: '95px'}
            }
            if(this.state.errorcode.indexOf('invalidOldNew') !== -1){
                newpwdError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert6")}</span>;
                newpwdFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('invalidFName') !== -1){
                fNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.borrow.license.form.alert5")}</span>;
                fNFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('invalidLName') !== -1){
                lNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.borrow.license.form.alert6")}</span>;
                lNFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('invalidEmail') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "configpageAlert6")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            pwdFormFieldError = '';
            newpwdFormFieldError = '';
            repwdFormFieldError = '';
            pwdError = '';
            newpwdError = '';
            repwdError = '';
            fNFormFieldError = '';
            lNFormFieldError = '';
            uNFormFieldError = '';
            emailFormFieldError = '';
            fNError = '';
            lNError = '';
            uNError = '';
            emailError = '';
        }

        let groupList = '';
        if(this.state.groups !== undefined && this.state.user.userName !== 'admin' && this.state.user.userName !== this.state.currentTenantName){
            this.state.groups.forEach(item => {
                if(groupList !== '')
                    groupList = groupList +", "+ item;
                else
                    groupList = groupList + item;
            })
        } else {
            if(this.state.user.userName === this.state.currentTenantName){
                groupList = LocaleUtility.getLocaleLabel(this.context.intl, "admin.gropuList.tenant");
            }else{
                groupList = LocaleUtility.getLocaleLabel(this.context.intl, "admin.gropuList");
            }
        }
        return (
            <section id="user_upt_formSec" pad="none" wrap={false} responsive={false} justify="center">
                <Form id="user_upt_formDetail" compact={false} pad="small" plain={true} onSubmit={this.formSubmit}>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.firstname")}
                        className={fNFormFieldError}>
                        <TextInput id='user_upt_firstname' name='firstName' value={this.state.firstName} onChange={event =>this.validateTextInput(event)} />
                        {fNError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.lastname")}
                        className={lNFormFieldError}>
                        <TextInput id='user_upt_lastname' name='lastName' value={this.state.lastName} onChange={event =>this.validateTextInput(event)} />
                        {lNError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.username")} 
                        className={uNFormFieldError}>
                        <TextInput id='user_upt_username' name='userName' value={this.state.userName} disabled={true} readOnly="readonly" style={{background:"#dfdfdf"}}/>
                        {uNError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.email")}
                        className={emailFormFieldError}>
                        {(this.state.user.userName === 'admin' || this.state.user.userName === this.state.currentTenantName) && (
                            <TextInput id='user_upt_email' name='email' value={this.state.email} onChange={event =>this.validateTextInput(event)} />
                        )}
                        {this.state.user.userName !== 'admin' && this.state.user.userName !== this.state.currentTenantName && (
                            <TextInput id='user_upt_email' name='email' value={this.state.email} disabled={true} readOnly="readonly"/>
                        )}
                        {emailError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.oldpassword")} 
                        className={pwdFormFieldError}>
                        <input id='user_upt_password' type="password" name='password' value={this.state.password} onChange={this.validatePwdInput} />
                        {pwdError}
                    </FormField>
                    <FormField style={styleField1} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.newpassword")} 
                        className={newpwdFormFieldError}>
                        <input id='user_upt_newpassword' type="password" name='newpassword' value={this.state.newpassword} onChange={this.validatePwdInput} />
                        {newpwdError}
                    </FormField>
                    <FormField style={styleField2} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.reenterpassword")} 
                        className={repwdFormFieldError}>
                        <input id='user_upt_repassword' type="password" name='repassword' value={this.state.repassword} onChange={this.validatePwdInput} />
                        {repwdError}
                    </FormField>
                    <FormField style={styleField2} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.usergroup")} 
                        >
                        <span>{groupList}</span>
                    </FormField>
                    <div style={{ width: '30em', padding: '24px 24px'}}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="button_wrap_primary">
                                            <Button id="user_upt_submitBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.update")}
                                                type="submit" plain={false} primary={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Form>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </section>
        );
    }

  render() {
    return (
        <div className="config-cont-backgroud">
            <div className="border_white_backg_shadow">
            <SectionWithTitle id="user_upt_formMainSec" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.update.user")} 
                sectionContent={this.sectionContent()} />
            </div>
        </div>
    );
  }
}

// UserUpdate.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UserUpdate;