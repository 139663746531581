import React, { Component } from "react";
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from 'grommet/components/Header';
import { Table } from 'grommet/components/Table';
import { TableRow } from 'grommet/components/TableRow';
import InUseGraph from "./InUseGraph";
import HistoryUsageGraph from "./HistoryUsageGraph";
import RejectedUsageGraph from "./RejectedUsageGraph";
import ConsumptionUsageGraph from "./ConsumptionUsageGraph";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import Select from "../../commons/APLSSelect";
import ColorLabel from './ColorLabel';
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import { Navigate } from 'react-router-dom';
import SectionWithTitle from "../../generic/SectionWithTitle";
import * as DateUtility from "../../../utils/DateUtility";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from '../../../utils/FetchHelper';
import * as Utils from "../../../utils/Util";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import DateRange from "../../generic/DateRange";
import DatesPopup from "../../generic/DatesPopup";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { getLocaleMessage }from "../../../utils/LocaleUtility";
import { Clock as ClockIcon } from 'grommet-icons';
import APLSBack from "../../generic/APLSBack";
import { useIntl } from 'react-intl';
import { createSearchParams } from "react-router-dom";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class InstantOnReportGraph extends Component {
    constructor(props) {
        super(props);
        this.inUseRef = React.createRef();
        this.historyRef = React.createRef();
        this.rejectedRef = React.createRef();
        this.consumeRef = React.createRef();
        let actIndex = 3;
        let cumulativeUsage = this.props.instanceDetail.cumulativeUsage;
        if(cumulativeUsage === undefined || cumulativeUsage === "undefined"
            || cumulativeUsage === null || cumulativeUsage === "null") {
            actIndex = 0;
        }
        this.state = {
            activeIndex: actIndex,
            selPoolName: [],
            selectPoolId: "0",
            selectCurrentVal: "",
            backToPreviousPage: false,
            usageStartDate: "",
            usageEndDate: "",
            selDateRange: {"label": getLocaleMessage("label.date.last.thirty.days"), "value": 3},
            poolColorIndexById: {},
            groupColorPalette: {},
            selDateRangeVal: "",
            showModal: false,
            tenentNameList: [],
            selTenantName: null,
            selTenantId: "0",
            datesErrorMessage: "",
            minCapacity: "",
            pdfFeature: "",
            adminCommuterCapacity: "",
            adminCommuterDays: "",
            showAdminCommuterDetails: false,
            commuterValue: 0,
            commuterNoOfDays: 0,
            editCommuterDaysValue: 0,
            editaAdminCommuterCapacityValue: 0,
            errorMessage: "",
            featurePkId: 0,
            exportExcelRequest: {},
            showExportExcelIcon: false,
            enableMorePools: false,
            limitpoolNamesWithColor: [],
            morePoolsList: [],
            morePoolMap: {},
            poolNamesIdsMap: {},
            instanceDetail: this.props.instanceDetail
        }
        this.tabClickAction = this.tabClickAction.bind(this);
        this.listPoolNamesAction = this.listPoolNamesAction.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.selectPoolName = this.selectPoolName.bind(this);
        this.backBtnAction = this.backBtnAction.bind(this);
        this.populateDates = this.populateDates.bind(this);
        this.selectDateRange = this.selectDateRange.bind(this);
        this.rejectedAction = this.rejectedAction.bind(this);
        this.onSelectDateRange = this.onSelectDateRange.bind(this);
        this.datesCancelAction = this.datesCancelAction.bind(this);
        this.datesOkAction = this.datesOkAction.bind(this);
        this.listTenantNames = this.listTenantNames.bind(this);
        this.selectTenantName = this.selectTenantName.bind(this);
        this.closePopuAction = this.closePopuAction.bind(this);
        this.showPopuAction = this.showPopuAction.bind(this);
        this.editOnClickAction = this.editOnClickAction.bind(this);
        this.onSaveChangesAction = this.onSaveChangesAction.bind(this);
        this.changeCommuterDays = this.changeCommuterDays.bind(this);
        this.changeCommuterCapacity = this.changeCommuterCapacity.bind(this);
        this.callBackCommuterUpdates = this.callBackCommuterUpdates.bind(this);
        this.callToExportRequest = this.callToExportRequest.bind(this);
        this.exportExcelAction = this.exportExcelAction.bind(this);
        this.closeMorePopupAction = this.closeMorePopupAction.bind(this);
        this.buildMorePools = this.buildMorePools.bind(this);
        this.clickMorePool = this.clickMorePool.bind(this);
        this.refreshInstanceDetail = this.refreshInstanceDetail.bind(this);
        this.onConfirmCapacity = this.onConfirmCapacity.bind(this);
    }

    // static propTypes = {
    //     children: _propTypes.node.isRequired,
    //     onClickOutside: _propTypes.func.isRequired
    // }

    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    backBtnAction() {
        let params = {};
        let pagination = {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortIndex: 2,
                sortBy: "startDate",
                sortOrder: "DESC"
            };
        params["pagination"] = pagination;
        params["sortIndex"] = 2;
        FetchHelper.listInstanceUsageData(this.context.intl, params);
        this.props.onClickBack();
    }
    tabClickAction(index, event) {
        if(index === 3) {
            this.populateDates(0, 0, false);
        }
        let params = {};
        let featArr = this.state.instanceDetail.featureId.split(":");
        params["featureId"] = featArr[0];
        params["featureVersion"] = featArr[1];
        params["uniqueInstanceId"] = this.state.instanceDetail.instanceId;
        FetchHelper.listInstanceUsageData(this.context.intl, params);
        if(this.state.activeIndex === index) {
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(this.state.selectPoolId+"", "", "", this.state.instanceDetail.instanceId);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(this.state.selectPoolId+"", this.state.usageStartDate,
                 this.state.usageEndDate, this.state.instanceDetail.instanceId);
            } else if(this.state.activeIndex === 2) {
                this.rejectedRef.current.setPoolId(this.state.selectPoolId, this.state.usageStartDate, 
                    this.state.usageEndDate, this.state.instanceDetail.instanceId);
            } else if(this.state.activeIndex === 3) {
                this.consumeRef.current.setTenantId(this.state.selTenantId, this.state.usageStartDate, this.state.usageEndDate);
            }
        }
        this.setState({activeIndex: index});
    }
    rejectedAction() {
        this.setState({activeIndex: 2});
    }
    selectPoolName(event) {
        if(event.option.value === "moreoptions") {
            this.setState({enableMorePools: true});
            this.buildMorePools();
        } else {
            this.setState({selectPoolId: event.option.value+""});
            this.setState({selPoolName: event.option});
            this.setState({selectCurrentVal: event.option});
            this.setState({activeIndex: this.state.activeIndex});
            this.setState({enableMorePools: false});
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(event.option.value+"", "", "", this.state.instanceDetail.instanceId);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(event.option.value+"", this.state.usageStartDate, 
                this.state.usageEndDate, this.state.instanceDetail.instanceId);
            }
        }
    }
    clickMorePool(value) {
        let pMap = this.state.morePoolMap;
        if(pMap) {
            this.setState({selectPoolId: value+""});
            this.setState({selPoolName: pMap[value]});
            this.setState({selectCurrentVal: pMap[value]});
            this.setState({activeIndex: this.state.activeIndex});
            this.setState({enableMorePools: false});
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(value+"", "", "", this.state.instanceDetail.instanceId);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(value+"", this.state.usageStartDate, 
                this.state.usageEndDate, this.state.instanceDetail.instanceId);
            }
        }
    }
    buildMorePools() {
        let pools = this.state.poolNamesIdsMap;
        let pList = [];
        if(pools) {
            Object.entries(pools).map(([key, value]) => {
                pList.push(<div className="more-all-pool-display" onClick={(event)=>this.clickMorePool(key)}>{value}</div>);
                return "";
            });
            this.setState({morePoolsList: pList});
        }
    }
    datesCancelAction() {
        this.setState({showModal: false});
    }
    datesOkAction(event, s) {
        let sDate = s.fromDate;
        let eDate = s.toDate;
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let fDate = new Date(sDate).getTime();
            let tDate = new Date(eDate).getTime();
            if(tDate < fDate) {
                //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation1", this.context.intl);
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                this.setState({displayValue: sDate+" - "+eDate});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate})
                this.selectDateRange(sDate, eDate);
                this.setState({showModal: false});
            }
        } else {
            //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation2", this.context.intl);
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }
    onSelectDateRange(event) {
        let v = event.option.value;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            this.setState({displayValue: v});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            this.selectDateRange(val[0], val[1]);
        }
    }
    selectDateRange(usageStartDate, usageEndDate) {
        this.setState({usageStartDate: usageStartDate});
        this.setState({usageEndDate: usageEndDate})
       if(this.state.activeIndex === 1) {
            this.historyRef.current.setPoolId(this.state.selectPoolId, usageStartDate, 
                usageEndDate, this.state.instanceDetail.instanceId);
        } else if(this.state.activeIndex === 2) {
            this.rejectedRef.current.setPoolId(this.state.selectPoolId, usageStartDate, 
                usageEndDate, this.state.instanceDetail.instanceId);
        } else if(this.state.activeIndex === 3) {
            this.consumeRef.current.setTenantId(this.state.selTenantId, usageStartDate, usageEndDate);
        }
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.callBackCommuterUpdates);
        APTableStore.removeListener("change", this.refreshInstanceDetail);
    }
    componentDidMount() {
        APTableStore.on("change", this.callBackCommuterUpdates);
        APTableStore.on("change", this.refreshInstanceDetail);
        if(this.state.activeIndex === 3) {
            this.populateDates(0, 0, false);
        } else {
            this.populateDates(29, 3, true);
        }
        this.setState({selTenantName: {"label": this.findLabel("label.select.all"), "value": null}});
    }
    populateDates(noOfdays, opt, flag) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        if(opt === 0) {
            previousDate = DateUtility.convertToServerTimeZone(previousDate);
        }
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        if(opt === 1) {
            this.setState({usageEndDate: DateUtility.getDate(previousDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(previousDate));
            }
        } else {
            this.setState({usageEndDate: DateUtility.getDate(currentDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(currentDate));
            }
        }
        let v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate);
        if(!flag) {
            v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(previousDate);
            this.setState({selDateRangeVal: {"label": this.findLabel("label.date.today"), "value": v}});
        }
        this.setState({displayValue: v});
    }
    listPoolNamesAction() {
        if(APTableStore.getState().actionType !== "usagehistory_graph") {
            let listReservationGroups = APTableStore.getState().listReservationGroups;
            let requestMap = APTableStore.getState().requestMap;
            let groupColorPalette = APTableStore.getState().groupColorPalette;
            let poolColorMap = {};
            if(requestMap) {
                let poolColore = requestMap["groupColors"];
                let poolColorList = poolColore.split(",");
                for(let i=0;i<poolColorList.length;i++) {
                    let p = poolColorList[i].split(":");
                    poolColorMap[p[0]] = p[1];
                }
            }
            let poolColorsById = {};
            let minDataSet = [];
            let counter = 1;
            let poolIdMap = {};
            if(listReservationGroups) {
                let pMap = {};
                Object.entries(listReservationGroups).map(item => {
                    let obj;
                    if(item[0] === "0") {
                        obj = {"label": <ColorLabel poolName={this.findLabel("label.select.all")} poolNameIndicationColor="#fff" />, "value": item[0]};
                        if(this.state.selectCurrentVal === "") {
                            this.setState({selPoolName: obj});
                        }
                    } else {
                        poolColorsById[item[0]] = poolColorMap[item[1]];
                        obj = {"label": <ColorLabel poolName={item[1]} poolNameIndicationColor={groupColorPalette[poolColorMap[item[1]]]} />, "value": item[0]};
                    }
                    if(counter <= 7) {
                        minDataSet.push(obj);
                    } else {
                        pMap[item[0]] = obj;
                        poolIdMap[item[0]] = item[1];
                    }
                    counter++;
                    return "";
                });
                this.setState({morePoolMap: pMap});
            }
            if(counter > 7) {
                minDataSet.push({label: <span id="pool_more_options_id">{this.findLabel("label.more.opitons")}</span>, value: "moreoptions"});
            }
            this.setState({poolNamesIdsMap: poolIdMap});
            this.setState({limitpoolNamesWithColor: minDataSet});
            this.setState({poolColorIndexById: poolColorsById});
            this.setState({groupColorPalette: groupColorPalette});
            let dataObj = APTableStore.getState().data;
            if(dataObj) {
                this.setState({minCapacity: dataObj.minCapacity});
                this.setState({featurePkId: dataObj.featurePkId});
                this.setState({adminCommuterDays: dataObj.adminCommuterDays});
                this.setState({adminCommuterCapacity: dataObj.adminCommuterCapacity});
                if(dataObj.adminCommuterCapacity < 1) {
                    this.setState({editaAdminCommuterCapacityValue: 0});
                } else {
                    this.setState({editaAdminCommuterCapacityValue: dataObj.adminCommuterCapacity});
                }
                this.setState({pdfFeature: dataObj.pdfFeature});
                if(dataObj.pdfFeature) {
                    this.setState({commuterNoOfDays: dataObj.pdfFeature.commuterNoOfDays});
                    if(dataObj.adminCommuterDays < 1) {
                        this.setState({editCommuterDaysValue: dataObj.pdfFeature.commuterNoOfDays});
                    } else {
                        this.setState({editCommuterDaysValue: dataObj.adminCommuterDays});
                    }
                }
                this.setState({commuterValue: dataObj.commuter});
            }
        }
    }
    callBackCommuterUpdates() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "updatecommuter") {
            let dataObj = APTableStore.getState().data;
            if(dataObj) {
                this.closePopuAction();
                this.setState({minCapacity: dataObj.minCapacity});
                this.setState({featurePkId: dataObj.featurePkId});
                this.setState({adminCommuterDays: dataObj.adminCommuterDays});
                this.setState({adminCommuterCapacity: dataObj.adminCommuterCapacity});
                if(dataObj.adminCommuterCapacity < 1) {
                    this.setState({editaAdminCommuterCapacityValue: 0});
                } else {
                    this.setState({editaAdminCommuterCapacityValue: dataObj.adminCommuterCapacity});
                }
                this.setState({commuterValue: dataObj.commuter});
            }
            this.setState({editaAminCommuterCapacity: false});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    listTenantNames() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "unitcons_graph") {
            this.setState({tenentNameList: APTableStore.getState().tenantsData});
        }
    }
    selectTenantName(event) {
        this.setState({selTenantName: event.option});
        if(this.state.activeIndex === 3) {
            this.setState({selTenantId: event.option.value});
            this.consumeRef.current.setTenantId(event.option.value, this.state.usageStartDate, this.state.usageEndDate);
        }
    }
    closePopuAction() {
        this.setState({showAdminCommuterDetails: false});
        this.setState({errorMessage: ""});
        this.setState({editaAminCommuterCapacity: false});
    }
    showPopuAction() {
        this.setState({showAdminCommuterDetails: true});
    }
    editOnClickAction() {
        this.setState({editaAminCommuterCapacity: true});
    }

    onConfirmCapacity(flag){
        if(flag === 1){
            let request = {
                featurePkId: this.state.featurePkId,
                maxCommNoDays: this.state.editCommuterDaysValue,
                maxCommNocapacity: this.state.editaAdminCommuterCapacityValue
            }
            FetchHelper.updateAdminCommuterDetails(request, this.context.intl);
        }
    }
    onSaveChangesAction() {
        let commuterDays = this.state.editCommuterDaysValue;
        let commuterCapacity = this.state.editaAdminCommuterCapacityValue;
        if(commuterDays === "" || !ValidationUtility.isNumber(commuterDays) 
            || Number(commuterDays) <= 0) {
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn1")});
        } else if(commuterCapacity === "" || !ValidationUtility.isNumber(commuterCapacity) 
            || Number(commuterCapacity) <= -1) {
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn2")});
        } else if(Number(commuterDays) > Number(this.state.commuterNoOfDays)) {
            let messageParams = {};
            messageParams["maxValue"] = this.state.commuterNoOfDays;
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn3", messageParams)});
        } else {
            if(Number(commuterCapacity) === 0) {
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleMessage("usage.report.admin.warn4"),
                    this.onConfirmCapacity, undefined, "","");
            } else {
                let request = {
                    featurePkId: this.state.featurePkId,
                    maxCommNoDays: this.state.editCommuterDaysValue,
                    maxCommNocapacity: this.state.editaAdminCommuterCapacityValue
                }
                FetchHelper.updateAdminCommuterDetails(request, this.context.intl);
            }
        }
    }
    changeCommuterDays(event) {
        this.setState({editCommuterDaysValue: event.target.value});
    }
    changeCommuterCapacity(event) {
        this.setState({editaAdminCommuterCapacityValue: event.target.value});
    }
    callToExportRequest() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "history" || pageType === "rejected" || pageType === "unitcons" 
            || pageType === "inuse") {
            let licenseModel = this.state.instanceDetail.licenseModel;
            let featureDesc = this.state.instanceDetail.featureDesc;
            let grpTotalCap,usedCapacity;
            if(pageType !== "unitcons") {
                let requestMap = APTableStore.getState().requestMap;
                grpTotalCap = requestMap["grptotalcapacity"];
                usedCapacity = requestMap["usedcapcity"];
                let list = APTableStore.getState().reportSearchList;
                if(list && list.length > 0) {
                    this.setState({showExportExcelIcon: true});
                } else {
                    this.setState({showExportExcelIcon: false});
                }
            } else {
                let list = APTableStore.getState().cumulativeUsageList;
                if(list && list.length > 0) {
                    this.setState({showExportExcelIcon: true});
                } else {
                    this.setState({showExportExcelIcon: false});
                }
            }
            let featInfo = this.state.instanceDetail.featureId;
            let featArr = featInfo.split(":");
            let featureId = featArr[0];
            let featureVersion = featArr[1];
            let request = {
                usageStartDate: this.state.usageStartDate,
                usageEndDate: this.state.usageEndDate,
                featureId: featureId,
                featureVersion: featureVersion,
                featureDescription: featureDesc,
                licenseModel: licenseModel,
                grptotalcapacity: grpTotalCap,
                overallusagecapacity: usedCapacity,
                tenentId: this.state.selTenantId,
                groupId: this.state.selectPoolId,
                uniqueInstanceId: this.state.instanceDetail.instanceId,
            }
            this.setState({exportExcelRequest: request});
        }
    }
    exportExcelAction(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        if(this.state.activeIndex === 1) {
            FetchHelper.downloadUsageHistoryReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 2) {
            FetchHelper.downloadUsageRejectedReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 3) {
            FetchHelper.downloadUsageConsumptionReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 0) {
            FetchHelper.downloadUsageInuseReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        }
    }
    closeMorePopupAction() {
        this.setState({enableMorePools: false});
    }
    refreshInstanceDetail() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "uniqueinstancedetail") {
            this.setState({instanceDetail: APTableStore.getState().instanceUsageList});
        }
    }
    render() {
        //let intl = this.context.intl, 
        let rightSection, midSection;
        let curHelpTopic = "Instant_On_UsageTab";
        let inUseTabCls = "inuseInActive";
        let historyTabCls = "inuseInActive";
        let rejectedTabCls = "inuseInActive";
        let consumptionTabCls = "inuseInActive";
        let featureDesc = this.state.instanceDetail.featureDesc;
        let cumulativeUsage = this.state.instanceDetail.cumulativeUsage;
        let ioStatus = localStorage.getItem("ioStatus");
        if(cumulativeUsage === undefined || cumulativeUsage === "undefined"
            || cumulativeUsage === null || cumulativeUsage === "null") {
            cumulativeUsage = false;
        }
        let licModel = this.state.instanceDetail.licenseModel;
        let inuseTitle = this.findLabel("usage.graph.inuse.title");
        if(licModel === "6" || licModel === 6) {
            licModel = 6;
            if(!cumulativeUsage) {
                inuseTitle = this.findLabel("usage.graph.consumption.title");
            }
        }
        if(licModel === "7" || licModel === 7) {
            licModel = 7;
            if(!cumulativeUsage) {
                inuseTitle = this.findLabel("usage.graph.consumption.title");
            }
        }
        if(this.state.activeIndex === 1){
            curHelpTopic = "Instant_On_UsageTab";
            historyTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                        {ioStatus === "1" && (<Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>)}
                        <span style={{borderRight: "0px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span>
                        <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="io-export-icon" colorIndex="brand"/></a>
                    </div>
            } else {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                        {ioStatus === "1" && (<Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>)}
                    </div>
            }
                    
        }else if(this.state.activeIndex === 2){
            curHelpTopic = "Instant_On_UsageTab";
            rejectedTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="io-export-icon" colorIndex="brand"/></a>
                        </div>
            } else {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                        </div>
            }
            
        }else if(this.state.activeIndex === 3){
            curHelpTopic = "Consumption_Tab";
            consumptionTabCls = "inuseActive";
            rightSection = <div style={{marginRight:"4px", float: "right"}}>
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="io-export-icon" colorIndex="brand"/></a>
                    </div>
        } else if(this.state.activeIndex === 0) {
            inUseTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                    {licModel !== 6 || licModel !== 7 ? 
                        <span>
                            {ioStatus === "1" && (<Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>)}
                            <span style={{paddingRight: "7px"}}></span>
                        </span>
                    : null}
                    <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="io-export-icon" colorIndex="brand"/></a>
                    </div>
            } else {
                rightSection = <div style={{marginRight:"4px", float: "right"}}>
                        {(licModel !== 6 || licModel !== 7) && ioStatus === "1" ? 
                            <Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand"  onClick={this.showPopuAction}/></Anchor>
                        : null}
                        </div>
            }
        }
        let isClockTampered = localStorage.getItem("isClockTampered");
        if(isClockTampered !== undefined && (isClockTampered === "true" || isClockTampered === true)) {
            midSection = <div className="clockTamperPopup">
                            <div style={{float: "left"}}><Anchor title={getLocaleMessage("label.tooltip.clock.tampered")}><ClockIcon /></Anchor></div>
                            <div classNam="clockTamperText" style={{paddingTop: "2px"}}>{getLocaleMessage("label.machine.clock.tampered")}</div>
                        </div>
        }
        let poolWidth = "50%";
        let dateWidth = "50%";
        if(this.state.activeIndex !== 0) {
            poolWidth = "0%";
            dateWidth = "50%";
        } else if(this.state.activeIndex === 3) {
            poolWidth = "50%";
            dateWidth = "0%";
        }
        if(this.state.backToPreviousPage) {
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_LIST_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}}}/>
        } else {
            let showMorePools = this.state.enableMorePools ? "modal display-block" : "modal display-none";
            let showCommuterWindow = this.state.showAdminCommuterDetails ? "modal display-block" : "modal display-none";
            return (
                <div style={{paddingRight: "0px", paddingBottom: "0px", backgroundColor: "#F5F7F8"}} className="config-cont-backgroud">
                    <div style={{backgroundColor: "#F5F7F8"}} className="inuse-container">
                        <APLSBack 
                            id="instance_usage_backBtn_id" 
                            className="back-button-container" 
                            onClick={this.backBtnAction}
                        />
                        <div style={{float: "right", width: "95%", lineHeight: "35px"}}>
                            <Table className=''  scrollable={false}>                            
                                <tbody>
                                <TableRow>
                                <td style={{textAlign:"right", fontWeight: "bold", width: "11%"}}>
                                    {this.findLabel("instance.usage.info")} :
                                </td>
                                <td style={{color:"#323435"}}>
                                    {this.state.instanceDetail.instanceId}
                                </td>
                                <td style={{textAlign:"right", fontWeight: "bold", width: "8%"}}>
                                    {this.findLabel("usageSearchStartDate")} :
                                </td>
                                <td style={{color:"#323435"}} >
                                    {this.state.instanceDetail.startDate}
                                </td>
                                <td style={{textAlign:"right", fontWeight: "bold"}}>
                                    {this.findLabel("usageSearchExpdate")} :
                                </td>
                                <td style={{color:"#323435"}}>
                                    {this.state.instanceDetail.expDate}
                                </td>
                                <td style={{textAlign:"right", fontWeight: "bold"}}>
                                    {this.findLabel("usage.report.admin.available.cap")} :
                                </td>
                                <td style={{color:"#323435"}}>
                                    {this.state.instanceDetail.avlCapacity}/{this.state.instanceDetail.totalCapacity}
                                </td>
                                </TableRow>
                            </tbody>
                            </Table>
                        </div>
                        <div style={{width: "100%", float: "left"}}>
                            <Header fixed={false} margin="none" id="header2" sticky={false} >
                                <Tabs style={{width: "100%"}} activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction} >
                                    {(!cumulativeUsage || licModel === 6 || licModel === 7) ? <Tab title={inuseTitle}
                                        className={inUseTabCls}>
                                        <InUseGraph ref={this.inUseRef}
                                            listPoolNamesAction = {this.listPoolNamesAction}
                                            //backButtonAction={this.backBtnAction}
                                            selectPoolId = {this.state.selectPoolId}
                                            poolColorIndexById = {this.state.poolColorIndexById}
                                            callToExportRequest = {this.callToExportRequest}
                                            instanceId = {this.state.instanceDetail.instanceId}
                                        />
                                    </Tab> : null}
                                    {(!cumulativeUsage && (licModel !== 6 || licModel !== 7)) ? <Tab title={this.findLabel("usage.graph.history.title")} 
                                        className={historyTabCls}>
                                        <HistoryUsageGraph ref={this.historyRef}
                                            listPoolNamesAction = {this.listPoolNamesAction}
                                            //backButtonAction={this.backBtnAction}
                                            selectPoolId = {this.state.selectPoolId}
                                            usageStartDate = {this.state.usageStartDate}
                                            usageEndDate = {this.state.usageEndDate}
                                            rejectedAction = {this.rejectedAction}
                                            poolColorIndexById = {this.state.poolColorIndexById}
                                            groupColorPalette = {this.state.groupColorPalette}
                                            callToExportRequest = {this.callToExportRequest}
                                            instanceId = {this.state.instanceDetail.instanceId}
                                        />
                                    </Tab> : null }
                                    {(!cumulativeUsage || (licModel === 6 || licModel === 7)) ? <Tab title={this.findLabel("usage.graph.rejected.title")}
                                        className={rejectedTabCls}>
                                        <RejectedUsageGraph  ref={this.rejectedRef}
                                            //backButtonAction={this.backBtnAction}
                                            selectPoolId = {this.state.selectPoolId}
                                            usageStartDate = {this.state.usageStartDate}
                                            usageEndDate = {this.state.usageEndDate}
                                            callToExportRequest = {this.callToExportRequest}
                                            instanceId = {this.state.instanceDetail.instanceId}
                                        />
                                    </Tab> : null }
                                    {cumulativeUsage ? <Tab title={this.findLabel("usage.graph.consumption.title")}
                                        className={consumptionTabCls}>
                                        <ConsumptionUsageGraph  ref={this.consumeRef}
                                            //backButtonAction={this.backBtnAction}
                                            selectPoolId = {this.state.selectPoolId}
                                            usageStartDate = {this.state.usageStartDate}
                                            usageEndDate = {this.state.usageEndDate}
                                            listTenantNames = {this.listTenantNames}
                                            callToExportRequest = {this.callToExportRequest}
                                            instanceId = {this.state.instanceDetail.instanceId}
                                        />
                                    </Tab> : null }
                                    <div style={{width: "100%", float: "left", paddingLeft: "6px"}} >
                                        <div style={{float: "left", width: dateWidth}}>
                                            {this.state.activeIndex === 0 ? null : <DateRange 
                                                label = {this.findLabel("usage.graph.daterange.label")}
                                                onChange = {this.onSelectDateRange}
                                                value = {this.state.selDateRangeVal}
                                                displayValue = {this.state.displayValue}
                                                width = "22em"
                                                defaultValue = {3}
                                                renderFor = "history"
                                            />}
                                        </div>
                                        <div style={{float: "left", width: poolWidth, textOverflow: "ellipsis"}}>
                                            {this.state.activeIndex === 3 ? 
                                                <Select id="usage_consumption_seletTenantName"
                                                    options={this.state.tenentNameList}
                                                    value={this.state.selTenantName}
                                                    onChange={this.selectTenantName}
                                                    label= {this.findLabel("Tenant")+": "}
                                                    width= {22}
                                                />
                                            : ""
                                            }
                                        </div>
                                        <div style={{float: "right", width: "50%"}}>
                                            <SectionWithTitle title={featureDesc} midSection = {midSection} rightSection={rightSection} helpTopic={curHelpTopic}/>
                                        </div>
                                    </div>
                                </Tabs>
                            </Header>
                        </div>
                    </div>
                    {this.state.showModal ? <DatesPopup 
                        cancelAction = {this.datesCancelAction}
                        startDateLabel = {this.findLabel("usage.graph.from.label")}
                        endDateLabel = {this.findLabel("usage.graph.to.label")}
                        showModal = {this.state.showModal}
                        fromDate = {this.state.usageStartDate}
                        toDate = {this.state.usageEndDate}
                        errorMessage = {this.state.datesErrorMessage}
                        okAction={this.datesOkAction} /> : ""}
                    <div>
                        <div id="commuter-info-popup-id" className={showCommuterWindow}>
                            <div id="commuterInfoDivId" className="modal">
                                <div style={{minWidth: "30%", backgroundColor: "#fff", width: "30%"}} className="modal-main panel panel-default">
                                    <span className="usageClose" onClick={this.closePopuAction}>X</span>
                                    {this.state.editaAminCommuterCapacity ? 
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <span style={{color: "red"}}>
                                                            {this.state.errorMessage}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                    </td>
                                                    <td>
                                                        <TextInput id="admin_commuter_days_id" value={this.state.editCommuterDaysValue} onChange={event =>this.changeCommuterDays(event)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {this.findLabel("usage.report.admin.commuter.capicty.allowed")}
                                                    </td>
                                                    <td>
                                                        <TextInput id="admin_commuter_capacity_id" value={this.state.editaAdminCommuterCapacityValue} onChange={event =>this.changeCommuterCapacity(event)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" align="right">
                                                        <Button id="admin_commuter_changes_savebtn" label={this.findLabel("admin.commuter.save.changes")} plain={false} primary={true} onClick={this.onSaveChangesAction} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    : 
                                        <table>
                                            <tbody>
                                                <tr id="tr-min-cap-id">
                                                    <td>{this.findLabel("usage.report.admin.commuter.min.cap")}</td>
                                                    <td>{this.state.minCapacity}</td>
                                                </tr>
                                                {this.state.commuterValue === 4 ? 
                                                    <tr>
                                                        <td>
                                                            <b>{this.findLabel("usage.report.admin.checkout.settings")}</b>
                                                        </td>
                                                        <td>
                                                            <Anchor label={this.findLabel("label.button.edit")} onClick={this.editOnClickAction} />
                                                        </td>
                                                    </tr>
                                                : null}
                                                {this.state.commuterValue === 4 ? 
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.adminCommuterDays < 1 ? 
                                                                this.state.commuterNoOfDays
                                                            :   this.state.adminCommuterDays }
                                                        </td>
                                                    </tr>
                                                : null}
                                                {this.state.commuterValue === 4 ?
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.capicty.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.adminCommuterCapacity < 1 ? 
                                                                <span>{this.findLabel("usage.report.admin.available.cap")}</span>
                                                            :   this.state.adminCommuterCapacity }
                                                        </td>
                                                    </tr>
                                                : null}
                                                {localStorage.getItem("isTokenBaseFeature") !== "0" && this.state.commuterValue === 1 ? 
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.commuterNoOfDays}
                                                        </td>
                                                    </tr>
                                                : null}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="more-pools-div-id" className={showMorePools}>
                        <div id="more-pools-sub-id" className="modal">
                            <div style={{minWidth: "30%"}} className="modal-main panel panel-default">
                                <span className="usageClose" onClick={this.closeMorePopupAction}>X</span>
                                <div className="more-all-groups">{this.findLabel("label.all.groups")}</div>
                                <div className="more-all-groups-display">{this.findLabel("label.select.group.display")}</div>
                                <div style={{height: "450px", overflow: "auto"}}>
                                    {this.state.morePoolsList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
// InstantOnReportGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default InstantOnReportGraph;