import React, { Component } from "react";
import "../../css/App.css";
import "../../css/index.css";
class APLSCaptcha extends Component {
// constructor(props) {
//     super(props);    
// }
render() {
    const buffer = this.props.captcha // e.g., <Buffer 89 50 4e ... >  
   const mimeType = "image/jpg" // e.g., image/png
    return ( <div className="captchacontainer">
                <img style={{height: "37px"}} src={`data:${mimeType};base64,${buffer}`} alt=""/> 
             </div>
    );

}
}
export default APLSCaptcha;