import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import { CheckBox } from "grommet/components/CheckBox";
import * as FetchHelper from "../../../utils/FetchHelper";
import Select from "../../commons/APLSSelect";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import {confirm} from "../../../utils/ValidationUtility";
import DragNDrop from "../../generic/DragNDrop";
import ReportFilter from "./ReportFilter";
import Dispatcher from "../../../flux/Dispatcher";
import { useIntl } from 'react-intl';
import Store from "../../../flux/GenericUsageStore";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var timePeriod = [ "year", "week", "month", "day", "hour"];
let sum = LocaleUtility.getLocaleMessage("usage.select.agg.sum.label");
let count = LocaleUtility.getLocaleMessage("usage.select.agg.count.label");

class CreateUsageReport extends Component {
    constructor(props) {
        super(props);
        this.repFilterRef = React.createRef();
        this.state = {
            reportName: "",
            reportDesc: "",
            reportFields: [],
            fields: [],
            fieldValues: [],
            selFieldError:"",
            addFieldError:"",
            addAggrError:"",
            saveRepErr:"",
            update: false,
            showSaveAs: false,
            repFieldAggr: undefined,
            repAggr: undefined,
            maxChecked: false,
            minChecked: false,
            featureID:"",
            showNotification: false,
            selectedFName: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selectedFDataType: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selectedFAggr: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selPeriodFilter: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selFeatureFilter: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.all")
            },
            usageRepFieldAggr:[
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "count", label:LocaleUtility.getLocaleMessage("usage.select.agg.count.label")},
                {value: "sum", label:LocaleUtility.getLocaleMessage("usage.select.agg.sum.label")},
                {value: "max", label:LocaleUtility.getLocaleMessage("usage.select.agg.max.label")},
                {value: "min", label:LocaleUtility.getLocaleMessage("usage.select.agg.min.label")}
            ],
            dataTypes: [
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "string", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.string.label")},
                {value: "int", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.int.label")},
                {value: "bigint", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.bigint.label")},
                {value: "precision", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.dprecision.label")},
                {value: "datetime", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.date.label")}
            ],
            timePeriodOptions: [{value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                                {value: "hour", label:LocaleUtility.getLocaleMessage("usage.select.period.hour.label")},
                                {value: "day", label:LocaleUtility.getLocaleMessage("usage.select.period.day.label")},
                                {value: "week", label:LocaleUtility.getLocaleMessage("usage.select.period.week.label")},
                                {value: "month", label:LocaleUtility.getLocaleMessage("usage.select.period.month.label")},
                                {value: "year", label:LocaleUtility.getLocaleMessage("usage.select.period.year.label")}]
        }
        this.findLabel = this.findLabel.bind(this);
        this.selectParentName = this.selectParentName.bind(this);
        this.handleChangeReportName = this.handleChangeReportName.bind(this);
        this.handleChangeReportDesc = this.handleChangeReportDesc.bind(this);
        this.onChangeFieldName = this.onChangeFieldName.bind(this);
        this.onChangeFieldDataType = this.onChangeFieldDataType.bind(this);
        this.onChangeFieldAggr = this.onChangeFieldAggr.bind(this);
        this.addReportFields = this.addReportFields.bind(this);
        this.updateReportFields = this.updateReportFields.bind(this);
        this.createAction = this.createAction.bind(this);
        this.deleteAction = this.deleteAction.bind(this);
        this.onConfrimDeleteAction = this.onConfrimDeleteAction.bind(this);
        this.updateFilterAction = this.updateFilterAction.bind(this);
        this.saveReportAction = this.saveReportAction.bind(this);
        this.onConfrimSaveAs = this.onConfrimSaveAs.bind(this);
        this.filterPeriodList = this.filterPeriodList.bind(this);
        this.filterfeatureList = this.filterfeatureList.bind(this);
        this.handleChangeReportMin = this.handleChangeReportMin.bind(this);
        this.handleChangeReportMax = this.handleChangeReportMax.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.modalSaveAs = this.modalSaveAs.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
        this.onModalOkCallback = this.onModalOkCallback.bind(this);
        this.cancelAction = this.cancelAction.bind(this);
        this.filterTenantList = this.filterTenantList.bind(this);
        this.loadNotification = this.loadNotification.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    selectParentName(event) {
        this.setState({poolParentName: event.suggestion});
    }
    handleChangeReportDesc(event) {
        this.setState({reportDesc: event.target.value});
    }
    handleChangeReportName(event) {
        let flag = this.state.reportName !== event.target.value;
        this.setState({reportName: event.target.value, repNameError:""});
        if(flag){
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    componentDidUpdate(prevProps) {
        let fields = this.props.fieldOptions.filter(function(itm){
            return itm.value !== "null";
        });
        if(prevProps.fieldOptions !== this.props.fieldOptions) {
          this.setState({fields: fields});
        }
      }
    componentDidMount() {
        let repFieldAggr = undefined, repAggr = undefined, maxChecked = false, 
            minChecked = false, periodFilter = null, tenantFilter = undefined;
        let fields = this.props.fieldOptions.filter(function(itm){
            return itm.value !== "null";
        });

        document.getElementById("custom-report-main").style.width = "100%";
        if(this.props.report !== undefined){
            let report = {...this.props.report};
            let fieldArr = [], filterArr = [], period = {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
            feature = {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.all")};
            report.fields.map(item => {
                fieldArr.push(item.name);
                if(item.aggregator === 'sum'){
                    repFieldAggr = sum+" ("+item.name+")";
                } else if(item.aggregator === 'count'){
                    repFieldAggr = count+" ("+item.name+")";
                }
                return "";
            });
            if(report.reportAggr !== undefined && report.reportAggr !== ''){
                if(report.reportAggr === 'max'){
                    maxChecked = true;
                } else if(report.reportAggr === 'min'){
                    minChecked = true;
                }
            }
            if(report.reportFilter !== undefined && report.reportFilter !== ''){
                filterArr = report.reportFilter.split("|").filter(function(itm){
                    if(tenantFilter === undefined && itm.indexOf("std_tenant=") > -1) 
                        tenantFilter = itm;
                    return (itm.indexOf("std_tenant=") === -1);
                });
            }

            if(tenantFilter !== undefined){
                let filItems = tenantFilter.split("=");
                tenantFilter = {value: filItems[1], label: filItems[1]};
            } else {
                tenantFilter = {
                    value: "null,"+this.props.tenants.toString(),
                    label: LocaleUtility.getLocaleMessage("label.select.all")
                };
            }
            
            if(report.periodFilter !== undefined && report.periodFilter !== null){
                for(let itm in this.state.timePeriodOptions){
                    if(this.state.timePeriodOptions[itm].value === report.periodFilter){
                        period = this.state.timePeriodOptions[itm];
                        periodFilter = period.value;
                        break;
                    }
                }
            }
            let reportSelectObj = this.props.reportFieldMap.get(report.reportId);
            if(reportSelectObj.report.featureId !== undefined && reportSelectObj.report.featureId !== null){
                for(let itm in this.props.featureidlist){
                    if(this.props.featureidlist[itm].value === reportSelectObj.report.featureId){
                        feature = this.props.featureidlist[itm];
                        // periodFilter = period.value;
                        break;
                    }
                }
            }

            this.setState({fields: fields, reportName: report.reportName, fieldValues: fieldArr, selPeriodFilter: period, selFeatureFilter: feature,
                reportDesc: report.reportDesc, reportFields: report.fields, reportId: report.reportId, periodFilter: periodFilter,
                repFieldOpt:this.props.fieldSet, productId: this.props.productId, filters: filterArr,
                repFieldAggr: repFieldAggr, repAggr: repAggr, maxChecked: maxChecked, minChecked: minChecked, selTenantFilter: tenantFilter});
        } else {
            this.setState({fields: fields, productId: this.props.productId, repFieldOpt:this.props.fieldSet, selTenantFilter:{
                    value: "null,"+this.props.tenants.toString(),
                    label: LocaleUtility.getLocaleMessage("label.select.all")
                }});
        }
        Store.on("change", this.loadNotification);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.loadNotification);
    }

    loadNotification(){
        if(!Store.getState().type === "reportUpdate"){
           this.setState({showNotification: false});
        } else {
            this.setState({showNotification: true});
        }
    }

    onChangeFieldName(event){
        this.setState({selectedFName: event.option, selFieldError:"", addAggrError:"", addFieldError:"",
            selectedFDataType: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selectedFAggr: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            }});
    }
    onChangeFieldDataType(event){
        if(this.state.selectedFAggr.value === undefined ||
            (event.option.value !== undefined && ((this.state.selectedFAggr.value === 'count' && event.option.value === 'string') || 
            (event.option.value !== 'datetime' && this.state.selectedFAggr.value !== 'count' && event.option.value !== 'string')))){
                this.setState({selectedFDataType: event.option, addAggrError:""})
        } else {
            this.setState({selectedFDataType: event.option, addAggrError: LocaleUtility.getLocaleMessage("usage.report.create.alert4")});
        }
    }
    onChangeFieldAggr(event){
        if(event.option.value === undefined || (this.state.selectedFDataType.value !== undefined && 
            ((this.state.selectedFDataType.value === 'string' && event.option.value === 'count') || 
            (this.state.selectedFDataType.value !== 'datetime' && this.state.selectedFDataType.value !== 'string' && event.option.value !== 'count')))){
                this.setState({selectedFAggr: event.option, addAggrError:""})
        } else {
            this.setState({selectedFAggr: event.option, addAggrError: LocaleUtility.getLocaleMessage("usage.report.create.alert4")});
        }
    }

    addReportFields(){
        let repFieldAggr = undefined, minC = this.state.minChecked, maxC = this.state.maxChecked;
        if(this.state.addFieldError === '' && this.state.addAggrError === '' && this.state.selFieldError === ''){
            var field = {}, fieldVal = this.state.fieldValues,  msg = "";
            let fieldArrayOption = Object.assign([], this.state.reportFields);
            if(this.state.selectedFName.value === undefined)
                msg = LocaleUtility.getLocaleMessage("usage.report.create.alert3");
            else if((fieldVal.some(v => (this.state.selectedFName.value === v)))){
                msg = LocaleUtility.getLocaleMessage("usage.report.create.alert5");
            }
            if(msg === ''){
                field.label = this.state.selectedFName.label;
                field.name = this.state.selectedFName.value;
                if(this.state.selectedFDataType !== undefined &&
                    this.state.selectedFDataType.value !== undefined){
                        field.dataType = this.state.selectedFDataType.value;
                } else {
                    field.dataType = "string";
                }
                if(this.state.selectedFAggr !== undefined &&
                    this.state.selectedFAggr.value !== undefined){
                        field.aggregator = this.state.selectedFAggr.value;
                        if(field.aggregator === 'sum'){
                            repFieldAggr = sum+" ("+field.name+")";
                        } else if(field.aggregator === 'count'){
                            repFieldAggr = count+" ("+field.name+")";
                        } 
                }
                fieldArrayOption.push(field);
                fieldVal.push(field.name);
                this.setState({reportFields: fieldArrayOption, fieldValues: fieldVal, addFieldError: "", addAggrError:"",
                                repFieldAggr: repFieldAggr, maxChecked: maxC, minChecked: minC})
            } else {
                this.setState({selFieldError: msg});
            }
        }
    }

    updateReportFields(elements){
        let fieldArr = [], repFieldAggr = undefined, minC = this.state.minChecked, maxC = this.state.maxChecked;
        elements.map(item => {
            fieldArr.push(item.name);
            if(item.aggregator === 'sum'){
                repFieldAggr = sum+" ("+item.name+")";
            } else if(item.aggregator === 'count'){
                repFieldAggr = count+" ("+item.name+")";
            } 
            return "";
        });

        if(repFieldAggr === undefined){
            minC = false; maxC = false;
        }
        this.setState({reportFields: elements, fieldValues: fieldArr, repFieldAggr: repFieldAggr,
                        maxChecked: maxC, minChecked: minC});
        if(this.repFilterRef && this.repFilterRef.current) {
            this.repFilterRef.current.updateComponent(true);
        }
        // Dispatcher.dispatch({
        //     type: "NOTIFICATION_CLOSE"
        // });
    }

    createAction(state){
        if(state.reportName === '' || state.reportName === undefined){
            this.setState({repNameError: LocaleUtility.getLocaleMessage("usage.report.create.alert1")});
        } else if(state.reportFields === undefined || state.reportFields.length < 2){
            this.setState({addFieldError: LocaleUtility.getLocaleMessage("usage.report.create.alert2")});
        } else {
            this.setState({addFieldError: ""});
            this.props.createAction(state);
        }
        //document.getElementById("custom-report-main").style.width = "auto";
    }

    saveReportAction(state){
        let repName = state.reportName;
        if( repName === '' || repName === undefined){
            this.setState({repNameError: LocaleUtility.getLocaleMessage("usage.report.create.alert1")});
        } else if(state.reportFields === undefined || state.reportFields.length < 2){
            this.setState({addFieldError: LocaleUtility.getLocaleMessage("usage.report.create.alert2")});
        } else {
            this.setState({addFieldError: "", showSaveAs: true, saveRepName:"", saveRepErr:""});
        }
        //document.getElementById("custom-report-main").style.width = "auto";
    }

    deleteAction(report){
        confirm(LocaleUtility.getLocaleMessage("usage.report.delete.alert", {repName: report.reportName}), 
                this.onConfrimDeleteAction, report);
    }

    onConfrimSaveAs(flag, state){
        if(flag){
            state.reportId = null; 
            if(state.reportName === this.props.report.reportName){
                state.reportName = state.reportName + " - 1";
            }
            this.props.createAction(state);
            //document.getElementById("custom-report-main").style.width = "auto";
        }
    }

    onConfrimDeleteAction(flag, report){
        if(flag){
            let params = {};
            params.reportName = report.reportName;
            params.reportId = report.reportId;
            params.productId = this.props.productId;
            FetchHelper.deleteUsageReport(params);
            this.props.cancelAction(true);
            //document.getElementById("custom-report-main").style.width = "auto";
        }
    }

    updateFilterAction(filters){
        var filter = filters.join("|");
        this.setState({filter: filter});
    }

    filterPeriodList(key, ev){
        let fieldArrayOption = [], fields = [], minC = this.state.minChecked, maxC = this.state.maxChecked;
        let period = [{name:ev.option.value, dataType:"string", label:ev.option.value, 
                        showOrder:1, period:true}], index = 1, showIindex=0;
        this.state.reportFields.map((itm, i) => {
            if(timePeriod.some(v => (itm.name === v))){
                index = itm.showOrder;
            } else {
                fieldArrayOption[itm.showOrder] = itm;
            }
            return "";
        });
        if(ev.option.value !== undefined){
            fieldArrayOption.splice(index, 0, ...period);
        }
        fieldArrayOption.map(item => {
            item.showOrder = showIindex;
            fields[showIindex++] = item;
            return item;
        });
        if(ev.option.value !== 'hour'){
            minC = false; maxC = false;
        }
        this.setState({reportFields: fields, periodFilter: ev.option.value, 
            selPeriodFilter: ev.option, maxChecked:maxC, minChecked: minC});
    }

    filterfeatureList(key, ev){
        this.setState({featureID: ev.option.value,selFeatureFilter: ev.option});
        this.props.featureFilter(key, ev);
        let fields = this.props.fieldOptions.filter(function(itm){
            return itm.value !== "null";
        });
        this.setState({fields: fields});
    }

    filterTenantList(event){
        if(event.option.value.indexOf("null") === -1)
            this.setState({tenantFilter: event.option.value, selTenantFilter: event.option});
        else
            this.setState({tenantFilter: undefined, selTenantFilter: {value: "null,"+this.props.tenants.toString(),
                    label: LocaleUtility.getLocaleMessage("label.select.all")}});
    }

    handleChangeReportMax(e){
        let checked = false;
        if(!this.state.maxChecked){
            checked = true;
        }
        this.setState({ repAggrValue: "max",
                        maxChecked:checked,
                        minChecked:false});
    }

    handleChangeReportMin(e){
        let checked = false;
        if(!this.state.minChecked){
            checked = true;
        }
        this.setState({ repAggrValue: "min",
                        maxChecked:false,
                        minChecked:checked
                    });
    }

     onModalCloseCallback() {
        this.setState({
            showSaveAs: false,
        });
    }
    onModalOkCallback() {
        if(this.state.saveRepName !== '' && this.state.saveRepErr === ''){
            let state = this.state;
            this.setState({
                showSaveAs: false,
                saveRepErr: ""
            });
            if(this.state.saveRepName !== this.props.report.reportName){
                state.reportName = this.state.saveRepName;
                state.reportId = null; 
            }
            this.props.createAction(state);
        } else {
            if(this.state.saveRepErr === '')
                this.setState({saveRepErr:LocaleUtility.getLocaleMessage("usage.report.create.alert1")});
        }
    }

    validateTextInput(event){
        if(event.target.value !== ''){
            if(!this.props.reportNames.some(v => v === event.target.value)){
                this.setState({saveRepName: event.target.value, saveRepErr:""});
            } else {
                this.setState({saveRepName: event.target.value, saveRepErr:LocaleUtility.getLocaleMessage("21001", {field: event.target.value})});
            }
        }
    }

    modalSaveAs(){
        let pwdFormFieldError, pwdError;
        if(this.state.saveRepErr !== ''){
            pwdError =<span className="grommetux-form-field__error">
            {this.state.saveRepErr}</span>;
            pwdFormFieldError='grommetux-form-field--error';
        } 

        const showHideModalStyle = this.state.showSaveAs ? "modal display-block" : "modal display-none";
        return (
            <div id={this.props.id} className={showHideModalStyle}> 
                <div id="modalDiv" className="modal">
                    <div className="modal-main panel panel-default" style={{minWidth: 0, width: "40%"}}>
                        <div className="panel-heading" >
                            <h2 id="modal_title" className="panel-title">{LocaleUtility.getLocaleMessage("usage.report.save.modal.title")}</h2>
                        </div>
                        <div id="alert_modal_content" className="panel-body">
                            <div id="save-modal-content" className="vertical-align">
                                <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("usage.report.name.label")} 
                                    className={pwdFormFieldError}>
                                    <TextInput id="save-report-name" autoFocus={true}
                                                        value={this.state.saveRepName} 
                                                        onChange={event =>this.validateTextInput(event)}
                                                    />
                                    {pwdError}
                                </FormField>
                            </div>
                        </div>
                        <div className="panel-footer panel-button-footer button_left_wrap">
                            <div className="button_wrap_primary"><Button 
                                style={{margin: "0px 5px"}}
                                id="alert_modal_Btn"
                                label={LocaleUtility.getLocaleMessage("label.button.ok")}  
                                plain={false}  
                                primary={true} 
                                onClick={this.onModalOkCallback} 
                            /></div>
                            <div className="button_wrap_secondary"><Button 
                                id="alert_cancel_Btn"
                                style={{margin: "0px 5px"}}
                                label={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                plain={false}  
                                primary={true} 
                                onClick={this.onModalCloseCallback} 
                            /></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    cancelAction(){
        document.getElementById("custom-report-main").style.width = "auto";
        this.props.cancelAction(false);
    }

    render() {
        let selFError, addFError, aggrFError, addFieldError, selFieldError, selAggrError,
                    createUpBtlabel = this.findLabel("resv.create.pool.button.label");
        let showHideModalStyle = this.props.showModal ? "display-nonflex" : "display-none";

        if(this.state.selFieldError !== ''){
            selFError =<span className="grommetux-form-field__error" style={{marginLeft:"-70px", top:"40px"}}>
            {this.state.selFieldError}</span>;
            selFieldError='grommetux-form-field-nozindex--error';
        } else if(this.state.addFieldError !== ''){
            addFError =<span className="grommetux-form-field__error" style={{marginLeft:"-40px", top:"40px"}}>
            {this.state.addFieldError}</span>;
            addFieldError='grommetux-form-field-nozindex--error';
        } else if(this.state.addAggrError !== ''){
            aggrFError =<span className="grommetux-form-field__error" style={{marginLeft:"-60px", top:"40px"}}>
            {this.state.addAggrError}</span>;
            selAggrError='grommetux-form-field-nozindex--error';
        } 

        if(this.props.report !== undefined){
            createUpBtlabel = this.findLabel("label.button.update");
        }

        return(
            <div id="createReportPopupId" className={showHideModalStyle} style={{width:"85%"}}>
                <div id="createReportDivId" >
                    <div >
                        <div>
                            <div className="createPollWindowTitle">
                                {this.findLabel("usage.report.field.modal.title")}
                            </div>
                            <div>
                                <section pad="none" wrap={false} responsive={false} justify="center">
                                <Form compact={false} id="create_report_form_id" pad="small" plain={true} className="cust_report_table">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td width="20%" colSpan="1" style={{float:"right"}}>
                                                {this.findLabel("usage.search.product.label")}:
                                            </td>
                                            <td colSpan="4" >
                                                {this.props.product}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("usage.report.name.label")} error={this.state.repNameError} >
                                                    <TextInput id="create-report-name" autoFocus={true}
                                                        value={this.state.reportName} 
                                                        onChange={event =>this.handleChangeReportName(event)}
                                                    />
                                                </FormField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("usage.report.desc.label")}  >
                                                    <TextInput id="create-report-desc-id" 
                                                        value={this.state.reportDesc} 
                                                        onChange={event =>this.handleChangeReportDesc(event)}
                                                    />
                                                </FormField>
                                            </td>
                                        </tr>
                                        <tr>
                                            {/* <td width="20%" >{LocaleUtility.getLocaleLabel(intl, "usage.report.default.period.filter")}</td> */}
                                            <td colSpan="2" style={{ width: '20%',verticalAlign: "top" }}>
                                                <FormField >
                                                <Select 
                                                    label={LocaleUtility.getLocaleMessage("usage.report.default.period.filter") + " : "} 
                                                    id="create-report-selperiod" options={this.state.timePeriodOptions} value={this.state.selPeriodFilter} 
                                                    onChange={this.filterPeriodList.bind(this, false)}/>
                                                </FormField>
                                            </td>
                                            {/* <td style={{ width: '10%',verticalAlign: "top" }}></td> */}
                                            {(this.state.selPeriodFilter !== undefined && this.state.selPeriodFilter.value === 'hour' &&
                                                    this.state.repFieldAggr !== undefined)? (
                                            <td colSpan="2" style={{ width: '40%',verticalAlign: "top" }}>
                                            <table style={{marginBottom: 0}}>
                                                <tbody>
                                                    <tr>
                                                    <td><FormField  >
                                                            <CheckBox id="enable-max-field-aggr" checked={this.state.maxChecked} 
                                                            onChange={this.handleChangeReportMax} 
                                                            label={LocaleUtility.getLocaleMessage("usage.report.enable.aggregator.radio",
                                                            {mainAggreator: LocaleUtility.getLocaleMessage("usage.select.agg.max.label"), 
                                                            subAggregator: this.state.repFieldAggr})}/>
                                                        </FormField></td>
                                                    </tr><tr>
                                                    <td><FormField >
                                                            <CheckBox id="enable-min-field-aggr" checked={this.state.minChecked} 
                                                            onClick={this.handleChangeReportMin} 
                                                            label={LocaleUtility.getLocaleMessage("usage.report.enable.aggregator.radio",
                                                            {mainAggreator: LocaleUtility.getLocaleMessage("usage.select.agg.min.label"), 
                                                            subAggregator: this.state.repFieldAggr})}/>
                                                        </FormField></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                            ):null}
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ width: '20%',verticalAlign: "top" }}>
                                                <FormField >
                                                <Select
                                                    label={LocaleUtility.getLocaleMessage("productFeature") + " : "} 
                                                    id="create-report-featureid" options={this.props.featureidlist} value={this.state.selFeatureFilter}
                                                    onChange={this.filterfeatureList.bind(this, false)}/>
                                                </FormField>
                                            </td>
                                            <td style={{ width: '10%',verticalAlign: "top" }}></td>
                                            <td colSpan="2" style={{ width: '20%',verticalAlign: "top" }}>
                                                <FormField >
                                                {/* <Select width={20}
                                                    label={LocaleUtility.getLocaleLabel(intl, "label.select.tenant.product") + " : "} 
                                                    id="create-report-tenant" options={this.props.tenantList} value={this.state.selTenantFilter}
                                                    onChange={this.filterTenantList.bind(this, false)}/> */}
                                                
                                                <Select id="custom-report-tenant-selection" placeholder={LocaleUtility.getLocaleMessage("configpageIpHostSelect")}
                                                    options={this.props.tenantList}
                                                    {...{value:this.state.selTenantFilter}}
                                                    value={this.state.selTenantFilter}
                                                    onChange={this.filterTenantList} 
                                                    label= {LocaleUtility.getLocaleMessage("label.select.tenant.product")+": "}
                                                    enabledSearch = {false}
                                                    //width= {20}
                                                    multiSelect = {true}
                                                />
                                                </FormField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <table><tbody><tr>
                                                    <td style={{ width: '10em',verticalAlign: "top"}} >{LocaleUtility.getLocaleMessage("usage.report.add.field.label")}</td>
                                            <td style={{ width: '20em',verticalAlign: "top" }} className = {selFieldError}>
                                                <FormField className = {selFieldError}>
                                                <Select id="usage_rep_field_name"
                                                    label={LocaleUtility.getLocaleMessage("usage.report.select.fields.label")}
                                                    options={this.state.fields}
                                                    value={this.state.selectedFName}
                                                    disabledScroll = {false}
                                                    onChange={this.onChangeFieldName} />
                                                    {selFError}
                                                </FormField>
                                            </td>
                                            <td style={{ width: '18em',verticalAlign: "top" }}>
                                                <FormField>
                                                 <Select id="usage_rep_field_datatype" 
                                                    label={LocaleUtility.getLocaleMessage("usage.report.select.fields.type.label")}
                                                    options={this.state.dataTypes}
                                                    value={this.state.selectedFDataType}
                                                    onChange={this.onChangeFieldDataType} />
                                                </FormField>
                                            </td>
                                            <td style={{ width: '20em',verticalAlign: "top" }}> 
                                                <FormField className={selAggrError}>
                                                 <Select id="usage_rep_field_aggr" 
                                                    label={LocaleUtility.getLocaleMessage("usage.report.select.fields.aggregator.label")}
                                                    options={this.state.usageRepFieldAggr}
                                                    value={this.state.selectedFAggr}
                                                    onChange={this.onChangeFieldAggr} />
                                                    {aggrFError}
                                                </FormField>
                                            </td>
                                            <td style={{ width: '12em' }}>
                                                <FormField className={addFieldError}>
                                                    <span style={{paddingRight: "10px"}}></span>
                                                    <div className="button_wrap_primary">
                                                    <Button id="usage_rep_addBtn" label={this.findLabel("label.button.add")} plain={false} primary={true} onClick={this.addReportFields} />
                                                    </div>
                                                    {addFError}
                                                </FormField>
                                            </td> 
                                            </tr></tbody></table>
                                           </td> 
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ width: '100%' }}>
                                                <DragNDrop elements={this.state.reportFields} showNotification = {this.state.showNotification} updateReportFields = {this.updateReportFields}></DragNDrop>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" style={{ width: '100%' }}>
                                                <ReportFilter ref={this.repFilterRef} product={this.state.productId} filter={this.state.filters} dataTypes={this.state.dataTypes}
                                                fields={this.state.fields} fieldSet={this.props.fieldSet} report={this.props.report} reportFields={this.state.reportFields} updateAction={this.updateFilterAction}></ReportFilter>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="button_left_wrap">
                                                <div className="button_wrap_primary">
                                                <Button id="create-report-btn" label={createUpBtlabel} plain={false} primary={true} onClick={(event)=>this.createAction(this.state)} />
                                                </div>
                                                <span style={{padding: "5px"}}></span>
                                                <div className="button_wrap_secondary"><Button id="create-report-canelBtn" label={this.findLabel("label.button.cancel")} plain={false} secondary={true} onClick={this.cancelAction} /></div>
                                                <span style={{padding: "5px"}}></span>
                                                {this.props.report !== undefined ?
                                                <div className="button_wrap_secondary"><Button id="clone-report-btn" label={this.findLabel("label.button.saveas")} plain={false} secondary={true} onClick={(event)=>this.saveReportAction(this.state)} /></div>
                                                :"" }
                                                <span style={{padding: "5px"}}></span>
                                                {this.props.report !== undefined ? 
                                                <div className="button_wrap_negative"><Button id="report-delete-btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true} onClick={event=>this.deleteAction(this.props.report)} /></div>
                                                :"" }
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {this.modalSaveAs()}
                                </Form>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// CreateUsageReport.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default CreateUsageReport;