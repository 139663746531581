import Dispatcher from "../flux/Dispatcher";
//import tls from 'tls';
const {X509Certificate} = require('crypto')
const forge = require('node-forge')

export function confirm(message, callbackFunction, data, okLabel, cancelLabel) {

    Dispatcher.dispatch({
        type: "CONFIRM_SHOW_MODAL",
        payload: {message: message, callBack: callbackFunction, data: data, okLabel: okLabel, cancelLabel: cancelLabel}
    });      

}

export function validateEmail(email) {
    var reEmail = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    if(email !== undefined && !email.match(reEmail)) {
        return false;
    }
    return true;
}
export function isEmpty(s) {			
    if (s && s.length === 0) {
        return false;
    } else if(!s){
        return false;
    }
    if (isOnlyWhitespace(s)) {			   
        return false;
    }
    return true;
}

export function isNotEmpty(s) {
    if (s === undefined || s === null || s === "") {
        return false;
    } else if (s.length === 0) {
        return false;
    } else if (!s) {
        return false;
    }
    if (isOnlyWhitespace(s)) {
        return false;
    }
    return true;
}

function isOnlyWhitespace(string_value) {			
    var whitespace = " \n\r\t";
    for (var counter = 0; counter < string_value.length; counter++) {
        var current_char = string_value.charAt(counter);
        if (whitespace.indexOf(current_char) === -1) {		        	
            return false;
        }		        
    }
    return true;
}
export function showErrorMessage(message, type) {
    //window.scrollTo(0, 0);
    dispatch(message, type);
}
export function showSuccessMessage(message, type, persist) {
    //window.scrollTo(0, 0);
    if(persist !== undefined && persist === true) {
        sessionStorage.setItem("persistedSuccessMsg", message);
    } else {
        dispatch(message, type);
    }
}
export function showLoadMask(message, type) {
    dispatch(message, type);
}
export function hideLoadMask(message, type) {
    dispatch(message, type);
}
export function compare(item1,item2) {	
    return item1.localeCompare(item2);
}
export function showPersistedSuccessMessage() {
    let msg = sessionStorage.getItem("persistedSuccessMsg");
    if(msg !== undefined && msg !== null && msg !== "") {
        sessionStorage.removeItem("persistedSuccessMsg");
        showSuccessMessage(msg, "CONFIGURATION_PAGE_SUCCESS_OK");
    }
}
function dispatch(payload, type) {
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function isNumber(value) {			
    const re = /^[0-9\b]+$/;
    if (value !== '' && !re.test(value)){
        return false;
    }
    return true;
}

export function isTrueORFalse(value) {			
    const re = /^(true|false|TRUE|FALSE)$/;
    if (value !== '' && !re.test(value)){
        return false;
    }
    return true;
}

export function notZero(value){
    if(value === '0'){
        return true;
    }
    return false;
}
export function isAlphaNumeric(value) {			
    const re = /^[a-zA-Z0-9-\b]+$/;
    if (value !== '' && !re.test(value)){
        return false;
    }
    return true;
}

export function alphanumericAndSpaceCheck(value){
	const re = /^[0-9a-zA-Z-_ ]+$/;
	if (value !== '' && !re.test(value)){
		return false;	
	}else{
		return true;
	}
}

export function validateSpecialChars(value) {
    if(value !== "") {
        value = value.replace("\\", "/");
    }
    let exp = /^$|^[\w.\-._\|/~@:#*\s]+$/i;
    var re = new RegExp(exp);
    if (!(re.test(value))) {
        return false;
    } else {
        return true;
    }
}
export function validateIPAddress(ipaddr) {
    if(ipaddr && ipaddr !== "") {
        var reIPV4 = /^((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*])))$/;
        var reIPV4Range  = /^(((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*])))-((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))))$/;
        if ((ipaddr.indexOf("*")!==-1)) {
                return false; 
            } 
        if (reIPV4.test(ipaddr)) {
            return true;
        } else {
            if (reIPV4Range.test(ipaddr)) {
                var ipaddra = ipaddr.split('-');
                if (((ipaddra[1].indexOf("*") === -1) && (ipaddra[0].indexOf("*") === -1)) && compare(ipaddra[1],ipaddra[0]) > 0) {
                    return true;
                }else if (compare(ipaddra[1],ipaddra[0]) === 0) {
                    return false;
                }else if ((ipaddra[1].indexOf("*") !== -1) && (ipaddra[0].indexOf("*") === -1)) {
                        var index1  = ipaddra[1].indexOf("*") ;
                        ipaddra[1] = ipaddra[1].substring(0,index1) ;
                        ipaddra[0] = ipaddra[0].substring(0,index1) ; 
                        if(compare(ipaddra[1],ipaddra[0])>=0){
                            return true;
                        } else {
                            return false;
                        }
                }else if ((ipaddra[0].indexOf("*") !== -1)) {
                        var index  = ipaddra[0].indexOf("*") ;
                        ipaddra[1] = ipaddra[1].substring(0,index) ;
                        ipaddra[0] = ipaddra[0].substring(0,index) ; 
                        if(compare(ipaddra[1],ipaddra[0])>=0){
                            return true;
                        } else {
                            return false;
                        }
                }else {
                    return false;
                }
            } else if (ipaddr.indexOf(":") !== -1 && ipaddr.indexOf("[") !== -1 && ipaddr.indexOf("]") !== -1) {
                return true;
            }else if (ipaddr === "*.*.*.*") {
                return false;
            }else {
                return false;
            }
        }
    }
    return true;
}

export function pwdValidation(value) {
	var p=/^(?=.*[\d])(?=.*[!@#$%^&_*])(?=.*[A-Z])[\w!@#$%^&*]{8,16}$/;
    if(value !== '' && p.test(value)) {
        return true;	  
    } else{
        return false;
    }
 }

 export function emailValidation(value){
    var r = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
	if(value !== undefined && value.indexOf("\\")!==-1){
		return false;
    } else {
        return (value !== undefined && value.match(r) == null) ? false : true;
    }

    // there must be >= 1 character before @, so we
    // start looking at character position 1 
    // (i.e. second character)			
    // var i = 1;
    // var sLength = value.length;			
    // // look for @
    // while ((i < sLength) && (value.charAt(i) !== "@")){
    //     i++;			    
    // }
   
    // if ((i >= sLength) || (value.charAt(i) !== "@")) 
	//     return false;
    // else i += 2;		
    // // look for .
    // while ((i < sLength) && (value.charAt(i) !== ".")){ 
    //     i++;		    	 
    // }
   
    // // there must be at least one character after the .
    // if ((i >= sLength - 1) || (value.charAt(i) !== ".")) return false;
    // else return true;
 }

 export function endsWith(target, suffix) {
    return target.indexOf(suffix, target.length - suffix.length) !== -1;
 }

 export function isAdminCheck(session) {
     if(session !== undefined && session.admin !== undefined && !session.admin) {
        return false;
    }
    return true;
 }

 export function isFieldNumber(value) {			
    const re = /^[0-9\b]+$/;
    if(value === "" || value === ''){
        return false;
    }
    if (value !== '' && !re.test(value)){
        return false;
    }
    return true;
}

export function validateHostName(hostName) {
    if(!isEmpty(hostName)){
        return true;
    }else{
        var regEx = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([a-zA-Z0-9]+)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-@]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;
        if(!hostName.match(regEx)) {
            return false;
        }
    }
    return true;
}

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function testCertAndKeyMatch (cert, key) {
    let pki = forge.pki;
    let isValid = false
    try {
        let pvtKey = pki.privateKeyFromPem(key);
        const publicKey = pki.setRsaPublicKey(pvtKey.n, pvtKey.e);
        let pubKey = pki.publicKeyToPem(publicKey);
        let cert1 = pki.certificateFromPem(cert);
        let pubKey2 = pki.publicKeyToPem(cert1.publicKey)
        if(pubKey === pubKey2)
            isValid = true;
        else
            isValid = false;
    } catch (e) {
        isValid = false
    }
    return isValid;
} 

export function isValidCertificate(content){
    let pki = forge.pki;
    let isValid = false
    try {
        let cert = pki.certificateFromPem(content);
        isValid = true
    } catch (e) {
        isValid = false
    }
    return isValid;
}

export function stringCheckForSAML(value){
	const re = /^[0-9a-zA-Z.:]+$/;
	if (value !== '' && !re.test(value)){
		return false;	
	}else{
		return true;
	}
}