
import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Anchor } from 'grommet/components/Anchor';
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { RadioButton } from 'grommet/components/RadioButton';
import CTConfigStore from "../../../flux/CTConfigStore";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import ProductStore from "../../../flux/ProductStore";
import CTFeatureTenantMap from "./CTFeatureTenantMap";
import { Edit as EditIcon } from 'grommet-icons';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from "../../commons/grommet/Header";
import TableGrid from "../../../components/commons/table/APTable";
import { CheckBox } from "grommet/components/CheckBox";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher"
import * as ValidationUtil from "../../../utils/ValidationUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import ControlTowerTenantConfig from "./ControlTowerTenantConfig";
import ControlTowerAssetConfig from "./ControlTowerAssetConfig";

import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let initialState = null;
let tempfeaturesTenantsList = [];
let tempfeaturesAssetsList = [];
class ControlTower extends Component {
    constructor(props) {
        super(props);
        this.featureTenantRef = React.createRef();
        this.featureAssetRef = React.createRef();
        this.state = {
            ctUsageFreequency:1,
            /**********************Tenant configuration ************/
            tenantIdmServerUrlValue: "",
            tenantIdmUserNameValue: "",
            tenantIdmPasswordValue: "",
            ctTenantServerUrlValue:"",            
            maxTenantUsageChecked:true,
            featuresTenantsList:[],
            /**********************Asset configuration ************/          
            featuresAssetsList: [],
            assetIdmServerUrlValue: "",
            assetIdmUserNameValue: "",
            assetIdmPasswordValue: "",
            ctAssetServerUrlValue:"",            
            maxAssetUsageChecked:true,
            //assetId duplication error
            invalidAssetsIds: "",
             updateFlagAsset:false,
           // hostnameError: "",
            usernameError: "",
            passwordError: "",
          //  ctHostError:"",
            freequencyError:"",
            isFormDirty: false,
           // ctHostName:"",
           // ctHostNameError:"",
            productNameSelected: undefined,
            productNameSelectOptions: ProductStore.getState().productNameSelectOptions,            
            updateFlagTenant:false,
            activeIndex:0,
            /******** Rejected Records **********************/
            selectedRowKeys: [],
            ctPagination: {
                deleted: false,
                sortBy: "lastUpdatedDate",
                sortOrder: "DESC",
                pageNumber: "1",
                pageSize: FetchHelper.getPageSize()
            },
            rejectedCTList:[],
            sortIndex: CTConfigStore.getState().sortIndex,
            sortAscending: CTConfigStore.getState().sortAscending,
            rejectedType:"TENANT",
            helpTopicScreenID:"ControlTower_Config"
        }
       this.findLabel = this.findLabel.bind(this); 
       this.makeFormDirty = this.makeFormDirty.bind(this);
       this.handleTenantChangeIdmServerUrl = this.handleTenantChangeIdmServerUrl.bind(this);
       this.handleTenantChangeUserName = this.handleTenantChangeUserName.bind(this);    
       this.handleTenantChangePassword = this.handleTenantChangePassword.bind(this);      
       this.submitTestTenantIDMConnection = this.submitTestTenantIDMConnection.bind(this);
       this.validateFormFields = this.validateFormFields.bind(this);
       this.handleChangeCTTenantServerUrl = this.handleChangeCTTenantServerUrl.bind(this);
       this.submitTenantIdmConfigSettings = this.submitTenantIdmConfigSettings.bind(this);
       this.validateCTUrl = this.validateCTUrl.bind(this);
       this.cancelTenantCTIdmConfigSettings = this.cancelTenantCTIdmConfigSettings.bind(this);
       this.handleChangeFrequency = this.handleChangeFrequency.bind(this);      
       this.refreshFormData = this.refreshFormData.bind(this);
       this.clearErrors = this.clearErrors.bind(this);
       this.editTenantPasswordField = this.editTenantPasswordField.bind(this);
       this.tabClickAction = this.tabClickAction.bind(this);      

       this.handleAssetChangeIdmServerUrl = this.handleAssetChangeIdmServerUrl.bind(this);
       this.handleAssetChangePassword = this.handleAssetChangePassword.bind(this);
       this.handleAssetChangeUserName = this.handleAssetChangeUserName.bind(this);
       this.handleChangeCTTAssetServerUrl = this.handleChangeCTTAssetServerUrl.bind(this);
       this.validateCTAssetUrl = this.validateCTAssetUrl.bind(this);
       this.validateFormFieldsForAsset = this.validateFormFieldsForAsset.bind(this);
       this.submitTestAssetIDMConnection = this.submitTestAssetIDMConnection.bind(this);
       this.submitAssetIdmConfigSettings = this.submitAssetIdmConfigSettings.bind(this);
       this.handleAssetIdChange = this.handleAssetIdChange.bind(this);
       this.editAssetPasswordField = this.editAssetPasswordField.bind(this);
       
       this.retryCtBtnClickHandler =  this.retryCtBtnClickHandler.bind(this);    
       this.onChangeCheckBoxCallback =  this.deleteCtBtnClickHandler.bind(this);   
       this.onRowClicked = this.onRowClicked.bind(this);
       this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
       this.exportCTrejected = this.exportCTrejected.bind(this);
       this.onSortClicked = this.onSortClicked.bind(this);
       this.onRetryConfirmSubmit = this.onRetryConfirmSubmit.bind(this);  
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        CTConfigStore.on("change", this.refreshFormData);
        FetchHelper.findCTAssetConfiguration(this.context.intl);    
    }
    componentWillUnmount(){
        CTConfigStore.removeListener("change",this.refreshFormData);
    }
    
    handleChangeFrequency(event){
        this.makeFormDirty();        
        this.setState({ctUsageFreequency: event.target.value});
    }

    handleTenantChangeIdmServerUrl(event) {
        this.makeFormDirty();        
        this.setState({tenantIdmServerUrlValue: event.target.value});
    }    

    handleTenantChangeUserName(event) {
        this.makeFormDirty();        
        this.setState({tenantIdmUserNameValue: event.target.value});
    }    

    handleTenantChangePassword(event) {
        this.makeFormDirty();        
        this.setState({tenantIdmPasswordValue: event.target.value});
    }  

    handleChangeCTTenantServerUrl(event){
        this.makeFormDirty();        
        this.setState({ctTenantServerUrlValue: event.target.value});
    }

    // changeCtHostName(val) {
    //     this.setState({ctHostName: val});
    // }

    // handleCTNameHost(event){
    //     this.makeFormDirty();
    //     this.changeCtHostName(event.target.value);
    // }

    submitTestTenantIDMConnection() {        
        if(this.validateFormFields(this.state, this.context.intl)) {
            this.clearErrors();
             let reqSaveData = this.state;
             if(reqSaveData.tenantIdmPasswordValue==="**********"){                 
                if(CTConfigStore.getState().tenantIdmPasswordValue !==undefined){
                    reqSaveData.tenantIdmPasswordValue = CTConfigStore.getState().tenantIdmPasswordValue;
                }
            }
            FetchHelper.testIDMForCTTenant(this.state, this.context.intl);
        }
    }

    submitTenantIdmConfigSettings() {
        this.clearErrors();
        if(!this.state.isFormDirty &&  this.validateCTUrl(this.state, this.context.intl)) {
            let reqSaveData = this.state;
            if(reqSaveData.tenantIdmPasswordValue==="**********"){
                if(CTConfigStore.getState().tenantIdmPasswordValue !==undefined){
                    reqSaveData.tenantIdmPasswordValue = CTConfigStore.getState().tenantIdmPasswordValue;
                }
            }
            FetchHelper.saveIDMCTConnectionDetails(reqSaveData, this.context.intl, "tenant");
            this.setState({tenantIdmPasswordValue:"**********"});
        }
    }

    validateCTUrl(data, intl) {
        let errorsMap = ConfigurationUtils.validateCTUrl(data, intl);
        let flag = true;

        if(errorsMap["freequency"] !== undefined && errorsMap["freequency"] !== ""){
            this.setState({freequencyError: errorsMap["freequency"]});
            flag = false;
        }
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["username"] !== undefined && errorsMap["username"] !== "") {
            this.setState({usernameError: errorsMap["username"]});
            flag = false;
        }
        if(errorsMap["password"] !== undefined && errorsMap["password"] !== "") {
            this.setState({passwordError: errorsMap["password"]});
            flag = false;
        }
        if(errorsMap["ctUrlInfo"] !== undefined && errorsMap["ctUrlInfo"] !== ""){
            this.setState({ctHostError: errorsMap["ctUrlInfo"]});
            flag = false;
        }

        // if(errorsMap["ctHost"] !== undefined && errorsMap["ctHost"] !== ""){
        //     this.setState({ctHostNameError: errorsMap["ctHost"]});
        //     flag = false;
        // }
        if(errorsMap["tenantError"] !== undefined && errorsMap["tenantError"] !== ""){
            ConfigurationUtils.showErrorMessage1("tenantError", this.context.intl);            
            this.setState({featuresTenantsList:errorsMap.tenantErrorMap});
            flag = false;
        }else{
            if(!flag) {
                ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
            }
        }
        return flag;
    }
    // validateCTTanantList(data, intl){
    //     let isValid = false;
    //     if(!isNaN(data.tenant)){
    //         isValid = true;
    //     }
    //     return isValid;
    // }
    validateFormFields(data, intl) {
        let errorsMap = ConfigurationUtils.validateIDMConfigurationForCTTenant(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["username"] !== undefined && errorsMap["username"] !== "") {
            this.setState({usernameError: errorsMap["username"]});
            flag = false;
        }
        if(errorsMap["password"] !== undefined && errorsMap["password"] !== "") {
            this.setState({passwordError: errorsMap["password"]});
            flag = false;
        }
         if(errorsMap["ctUrlInfo"] !== undefined && errorsMap["ctUrlInfo"] !== ""){
            this.setState({ctHostError: errorsMap["ctUrlInfo"]});
            flag = false;
        }
       
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        return flag;
    }
    clearErrors() {
        this.setState({hostnameError: ""});
        this.setState({usernameError: ""});
        this.setState({passwordError: ""});
        this.setState({ctHostError: ""});
        this.setState({freequencyError:""});
     //   this.setState({ctHostNameError:""});        
    }

    findLabel(key, intl) {
       
        return LocaleUtility.getLocaleMessage(key);
    }
    
    makeFormDirty() {
        this.setState({isFormDirty: false});
    }

    cancelTenantCTIdmConfigSettings() {
        ConfigurationUtils.showLoadMask("", this.context.intl);  
        if(initialState) {
            this.setState(initialState);
            this.setState({featuresTenantsList: tempfeaturesTenantsList});
            this.refreshFormData();
        }
        ConfigurationUtils.hideLoadMask(this.context.intl);  
    }
    refreshFormData() {
        if(CTConfigStore.getState().type === "CTTENANTSETTINGS"){
        this.clearErrors();
        let fTenantsList = [];
        let tempArr = [];
        tempfeaturesTenantsList = [];
        var tempCopy =  JSON.parse(JSON.stringify(CTConfigStore.getState().featuresTenantsList));
       tempCopy.map((item,index) => {
                    var key = item.featureDescription;
                    if(item.tenantError!==undefined && item.tenantError===true){
                        item.tenantError=false;
                    }
                        fTenantsList.push(item);                        
                        if(key !== undefined && key !== "" && !(key.indexOf(':') !== -1)){
                                item.featureDescription = item.featureId + ":"+item.featureVersion + "(" +item.featureDescription+")";
                        }else if(key === undefined){
                            item.featureDescription = item.featureId + ":"+item.featureVersion ;
                        }else if(!(key.indexOf('(') !== -1)){
                            item.featureDescription = item.featureId + ":"+item.featureVersion ;
                        }
                        tempArr[index] = JSON.parse(JSON.stringify(item));
                    return item;
                });

        let idmserver = CTConfigStore.getState().tenantIdmServerUrlValue;
        let username = CTConfigStore.getState().tenantIdmUserNameValue;
        let ctUrl = CTConfigStore.getState().ctTenantServerUrlValue;
        let freequency = CTConfigStore.getState().ctUsageFreequency;
        if ( freequency === undefined) {
            freequency = 1;
        }
        let usage = CTConfigStore.getState().maxTenantUsageChecked;
        // let hostName = CTConfigStore.getState().ctHostName;
        // if ( hostName === undefined) {
        //     hostName = "";
        // }
        let passworditem;
        if(ctUrl!==undefined && ctUrl!==""){
            this.setState({updateFlagTenant : true});
            passworditem="**********";
        }
        //clearing state values of asset
        this.setState({
            featuresAssetsList: undefined,
            assetIdmServerUrlValue: '',
            assetIdmUserNameValue: '',
            ctAssetServerUrlValue:'',
            maxAssetUsageChecked:'',
            assetIdmPasswordValue:''
        });

        this.setState({featuresTenantsList: fTenantsList});
        this.setState({tenantIdmServerUrlValue: idmserver});
        this.setState({tenantIdmUserNameValue: username});
        this.setState({ctTenantServerUrlValue: ctUrl});
        this.setState({ctUsageFreequency: freequency});
        this.setState({maxTenantUsageChecked: usage});
        this.setState({isFormDirty: false});
        this.setState({tenantIdmPasswordValue: passworditem});
      //  this.setState({ctHostName: hostName});
        
        ConfigurationUtils.hideLoadMask(this.context.intl);
        initialState = this.state;
        tempfeaturesTenantsList = JSON.parse(JSON.stringify(tempArr));
        this.setState({helpTopicScreenID: "ControlTower_Config"});
        }       

        if(CTConfigStore.getState().type === "REJECTEDCT"){
            //clearing state values of asset and tenant
            this.setState({
                tenantIdmServerUrlValue: '',
                tenantIdmUserNameValue: '',
                ctTenantServerUrlValue:'',
                maxTenantUsageChecked:'',
                tenantIdmPasswordValue:'',
                featuresTenantsList: undefined,
                assetIdmServerUrlValue: '',
                assetIdmUserNameValue: '',
                ctAssetServerUrlValue:'',
                maxAssetUsageChecked:'',
                assetIdmPasswordValue:'',
                featuresAssetsList: undefined
            });
            var pagination = CTConfigStore.getState().pagination;
            let sortIndex = 1;
                let indexx = pagination.sortBy;
                switch (indexx) {
                    case "featureDesc":
                        sortIndex=0;
                        break;
                    case "lastUpdatedDate":
                        sortIndex=1;
                        break;
                    case "errorMessage":
                        sortIndex=2;
                        break;
                    case "recordCount":
                        sortIndex=3;
                        break;         
                    default:
                        sortIndex=1;
                        break;
                }
            this.setState({rejectedCTList :  CTConfigStore.getState().rejectedList});
            this.setState({ctPagination :  CTConfigStore.getState().pagination});
            this.setState({mapData : CTConfigStore.getState().mapData});
            this.setState({sortIndex : sortIndex});
            this.setState({helpTopicScreenID: "CT_Rejected"});
            ConfigurationUtils.hideLoadMask(this.context.intl);      
        }
        
        if(CTConfigStore.getState().type === "CTASSETSETTINGS"){
        this.clearErrors();
        let fAssetsList = [];
        let tempArr = [];
        tempfeaturesAssetsList = [];
        var tempCopy =  JSON.parse(JSON.stringify(CTConfigStore.getState().featuresTenantsList));
       tempCopy.map((item,index) => {
                    var key = item.featureDescription;
                    if(item.assetError!==undefined && item.assetError===true){
                        item.assetError=false;
                    }
                        fAssetsList.push(item);                        
                        if(key !== undefined && key !== "" && !(key.indexOf(':') !== -1)){
                                item.featureDescription = item.featureId + ":"+item.featureVersion + "(" +item.featureDescription+")";
                        }else if(key === undefined){
                            item.featureDescription = item.featureId + ":"+item.featureVersion ;
                        }else if(!(key.indexOf('(') !== -1)){
                            item.featureDescription = item.featureId + ":"+item.featureVersion ;
                        }
                        tempArr[index] = JSON.parse(JSON.stringify(item));
                    return item;
                });

        let idmserver = CTConfigStore.getState().assetIdmServerUrlValue;
        let username = CTConfigStore.getState().assetIdmUserNameValue;
        let ctUrl = CTConfigStore.getState().ctAssetServerUrlValue;
        let freequency = CTConfigStore.getState().ctUsageFreequency;
        if ( freequency === undefined) {
            freequency = 1;
        }
        let usage = CTConfigStore.getState().maxAssetUsageChecked;        
        let passworditem;
        if(ctUrl!==undefined && ctUrl!==""){
            this.setState({updateFlagAsset : true});
            passworditem="**********";
        }
        //clearing state values of tenant
        this.setState({
            tenantIdmServerUrlValue: '',
            tenantIdmUserNameValue: '',
            ctTenantServerUrlValue:'',
            maxTenantUsageChecked:'',
            tenantIdmPasswordValue:''
        });
        this.setState({featuresTenantsList: fAssetsList});
        this.setState({assetIdmServerUrlValue: idmserver});
        this.setState({assetIdmUserNameValue: username});
        this.setState({ctAssetServerUrlValue: ctUrl});
        this.setState({ctUsageFreequency: freequency});
        this.setState({maxAssetUsageChecked: usage});
        this.setState({isFormDirty: false});
        this.setState({assetIdmPasswordValue: passworditem});
        this.setState({activeIndex:  CTConfigStore.getState().activeIndex});
        this.setState({helpTopicScreenID: "ControlTower_Config"});
        
        ConfigurationUtils.hideLoadMask(this.context.intl);
        initialState = this.state;
        tempfeaturesAssetsList = JSON.parse(JSON.stringify(tempArr));
        } 
    }    
    // updateTenantList(index, e, item){
        // if(!this.state.isFormDirty &&  this.validateCTTanantList(item, this.context.intl)) {            
        //     // FetchHelper.updateTenantList(item, this.context.intl);
        // //     response.then(()=>{
        // //         if(response==="success"){
        // //         let notify = document.createElement("span")
        // //         notify.innerHTML = "Successfully Updated";
        // //         notify.setAttribute("class", "successNotification")
        // //         e.target.parentElement.appendChild(notify);
        // //         setTimeout( () => {
        // //             notify.remove();
        // //         }, 2000);
        // //     }else{
        // //         let notify = document.createElement("span")
        // //         notify.innerHTML = "Update";
        // //         notify.setAttribute("class", "errorNotification")
        // //         e.target.parentElement.appendChild(notify);
        // //         setTimeout( () => {
        // //             notify.remove();
        // //         }, 2000);
        // //     }
        // // });            
        // }else{
        //     ConfigurationUtils.showErrorMessage1("tenantError", this.context.intl);
        // }
    // }

    editTenantPasswordField(e){
        this.setState({updateFlagTenant : false, tenantIdmPasswordValue: ""});
    }

    handleAssetIdChange(index, e){
        this.state.featuresTenantsList[index].assetId= e.target.value;
    }

    /*****************Asset Configuration***************/
     editAssetPasswordField(e){
        this.setState({updateFlagAsset : false, assetIdmPasswordValue: ""});
    }

    handleAssetChangeIdmServerUrl(event) {
        this.makeFormDirty();        
        this.setState({assetIdmServerUrlValue: event.target.value});
    }    

    handleAssetChangeUserName(event) {
        this.makeFormDirty();        
        this.setState({assetIdmUserNameValue: event.target.value});
    }    

    handleAssetChangePassword(event) {
        this.makeFormDirty();        
        this.setState({assetIdmPasswordValue: event.target.value});
    }  

    handleChangeCTTAssetServerUrl(event){
        this.makeFormDirty();        
        this.setState({ctAssetServerUrlValue: event.target.value});
    }

    submitTestAssetIDMConnection() {        
        if(this.validateFormFieldsForAsset(this.state, this.context.intl)) {
            this.clearErrors();
             let reqSaveData = this.state;
             if(reqSaveData.assetIdmPasswordValue==="**********"){                 
                if(CTConfigStore.getState().assetIdmPasswordValue !==undefined){
                    reqSaveData.assetIdmPasswordValue = CTConfigStore.getState().assetIdmPasswordValue;
                }
            }
            FetchHelper.testIDMForCTAsset(this.state, this.context.intl);
        }
    }
    validateFormFieldsForAsset(data, intl) {
        let errorsMap = ConfigurationUtils.validateIDMConfigurationForCTAsset(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["username"] !== undefined && errorsMap["username"] !== "") {
            this.setState({usernameError: errorsMap["username"]});
            flag = false;
        }
        if(errorsMap["password"] !== undefined && errorsMap["password"] !== "") {
            this.setState({passwordError: errorsMap["password"]});
            flag = false;
        }
         if(errorsMap["ctUrlInfo"] !== undefined && errorsMap["ctUrlInfo"] !== ""){
            this.setState({ctHostError: errorsMap["ctUrlInfo"]});
            flag = false;
        }
       
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        return flag;
    }

    submitAssetIdmConfigSettings() {
        this.clearErrors();
        if(!this.state.isFormDirty &&  this.validateCTAssetUrl(this.state, this.context.intl) && this.isValidAssetIds()) {
            let reqSaveData = this.state;
            if(reqSaveData.assetIdmPasswordValue==="**********"){
                if(CTConfigStore.getState().assetIdmPasswordValue !==undefined){
                    reqSaveData.assetIdmPasswordValue = CTConfigStore.getState().assetIdmPasswordValue;
                }
            }
            FetchHelper.saveIDMCTConnectionDetails(reqSaveData, this.context.intl, "asset");
            this.setState({assetIdmPasswordValue:"**********"});
        }
    }

    validateCTAssetUrl(data, intl) {
        let errorsMap = ConfigurationUtils.validateCTAssetUrl(data, intl);
        let flag = true;

        if(errorsMap["freequency"] !== undefined && errorsMap["freequency"] !== ""){
            this.setState({freequencyError: errorsMap["freequency"]});
            flag = false;
        }
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["username"] !== undefined && errorsMap["username"] !== "") {
            this.setState({usernameError: errorsMap["username"]});
            flag = false;
        }
        if(errorsMap["password"] !== undefined && errorsMap["password"] !== "") {
            this.setState({passwordError: errorsMap["password"]});
            flag = false;
        }
        if(errorsMap["ctUrlInfo"] !== undefined && errorsMap["ctUrlInfo"] !== ""){
            this.setState({ctHostError: errorsMap["ctUrlInfo"]});
            flag = false;
        }

        // if(errorsMap["ctHost"] !== undefined && errorsMap["ctHost"] !== ""){
        //     this.setState({ctHostNameError: errorsMap["ctHost"]});
        //     flag = false;
        // }
        if(errorsMap["tenantError"] !== undefined && errorsMap["tenantError"] !== ""){
            ConfigurationUtils.showErrorMessage1("tenantError", this.context.intl);            
            this.setState({featuresTenantsList:errorsMap.tenantErrorMap});
            flag = false;
        }else{
            if(!flag) {
                ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
            }
        }
        return flag;
    }
    
    
    tabClickAction(index, event) {
        this.setState({activeIndex: index});
        this.clearErrors();
        ConfigurationUtils.showLoadMask("", this.context.intl);  
        if(index == 0){ 
            this.setState({helpTopicScreenID: "ControlTower_Config"});    
            FetchHelper.findCTAssetConfiguration(this.context.intl);
        }else if(index == 1){  
            this.setState({helpTopicScreenID: "ControlTower_Config"});         
            FetchHelper.findCTTenantConfiguration(this.context.intl);
        }else if(index == 2){ 
            this.setState({helpTopicScreenID: "CT_Rejected"});
            let paginationTemp = {
                deleted: false,
                sortBy: "lastUpdatedDate",
                sortOrder: "DESC",
                pageNumber: "1",
                pageSize: FetchHelper.getPageSize()
            };
            ConfigurationUtils.showLoadMask("", this.context.intl); 
            this.setState({rejectedType : "ASSET"}, () =>{
                ConfigurationUtils.loadRejectedRecords(this.context.intl, paginationTemp, this.state.rejectedType);
            });
        }
        ConfigurationUtils.hideLoadMask(this.context.intl);
    }
    /****************** Rejected Tab ***********************/   
    onRowClicked(keys, event){    
        var els =document.getElementById('rejected_ct_view_grid');
        for (var i=els.length;i--;){    
                alert(els[i]);            
        }   
        this.setState({selectedRowKeys: keys});        
    }
    onRetryConfirmSubmit(flag){
         if(flag === 1){
            ConfigurationUtils.showLoadMask("",this.context.intl);
             var reqArr = [];
             for(var item of this.state.selectedRowKeys){
                let rec = this.state.mapData.get(item);   
               
                 reqArr.push({"featureId":rec.featureId,
                           "errorCode": rec.errorCode,
                            "featureVersion":rec.featureVersion
                        });
            }     
           FetchHelper.resendCTRejectedRec(this.context.intl,reqArr, this.state.rejectedType);
           this.state.selectedRowKeys = [];       
         }
        
    }
        
    retryCtBtnClickHandler(){
         if(this.state.selectedRowKeys !== undefined && this.state.selectedRowKeys.length >0){
             ValidationUtil.confirm(
             LocaleUtility.getLocaleMessage("label.ct.rejected.alert3"),
            this.onRetryConfirmSubmit,  undefined, "", ""); 
                 
        }else{
            Dispatcher.dispatch({
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleMessage("label.ct.rejected.alert1")
                 });
        }
    } 
    onConfirmSubmit(flag){
        if(flag === 1){
            ConfigurationUtils.showLoadMask("",this.context.intl);             
            var reqArr = [];
            for(var item of this.state.selectedRowKeys){
                let rec = this.state.mapData.get(item);
                
                reqArr.push({"featureId":rec.featureId,
                           "errorCode": rec.errorCode,
                            "featureVersion":rec.featureVersion
                        });
            }     
           FetchHelper.deleteCTRejectedRec(this.context.intl,reqArr,this.state.rejectedType);
           this.state.selectedRowKeys = [];
        }
    }   
    deleteCtBtnClickHandler(){
        if(this.state.selectedRowKeys !== undefined && this.state.selectedRowKeys.length >0){ 
           var messageArr = []; 
           messageArr.push(this.findLabel("ct.delete.confirm"));
          
            for(var item of this.state.selectedRowKeys){
                var row = this.state.mapData.get(item);
                messageArr.push(<span><br></br>{row.featureId} : {row.featureVersion} &nbsp; {row.errorMessage} &nbsp; <b>{LocaleUtility.getLocaleMessage("label.grid.col.record.count")} : {row.count}</b></span>);
                }  
           
            ValidationUtil.confirm(
            messageArr,
            this.onConfirmSubmit,  undefined, "", "");   
            
        }else{
             Dispatcher.dispatch({                 
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleMessage("label.ct.rejected.alert2")
                 });
            }
    }
    onCTPaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.ctPagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ctPagination: paginationTemp
    });
    FetchHelper.findCTRejectedList(this.context.intl,paginationTemp);
  };
  exportCTrejected(e){
        let mimetype = e.currentTarget.attributes.mimetype.value;
        FetchHelper.exportCTRejectedList(this.context.intl, mimetype, this.state.rejectedType);
  }
  onSortClicked(index, order, header) {
        var sortOrder = "ASC";
        let paginationTempobj = this.state.ctPagination 

        let paginationTemp = {
                deleted: paginationTempobj.deleted,
                sortBy: paginationTempobj.sortBy,
                sortOrder: paginationTempobj.sortOrder,
                pageNumber: paginationTempobj.pageNumber,
                pageSize: paginationTempobj.pageSize
            };
        
        let sortAscVar = true;
        
        if(paginationTemp.sortOrder === "ASC"){
            sortOrder = "DESC";
            sortAscVar = false;       
        }        
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = header[index].columnName;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.setState({ ctPagination: paginationTemp});   
        this.setState({ sortAscending : sortAscVar });   
        ConfigurationUtils.showLoadMask("", this.context.intl);                 
        FetchHelper.findCTRejectedList(this.context.intl,paginationTemp);
    }

    isValidAssetIds(){
        let validAssetIds = true;
        for(let i = 0; i < this.state.featuresTenantsList.length; i++){
            for(let j = i+1; j < this.state.featuresTenantsList.length; j++){
                if(ValidationUtil.isEmpty(this.state.featuresTenantsList[i].assetId) &&
                    ValidationUtil.isEmpty(this.state.featuresTenantsList[j].assetId) &&
                    this.state.featuresTenantsList[i].assetId === this.state.featuresTenantsList[j].assetId){
                    validAssetIds = false;
                    break;
                }
            }
        }
        if(!validAssetIds){
            ConfigurationUtils.showErrorMessage1("enter.unique.asset.ID", this.context.intl);
        }
        return validAssetIds;
    }

    onToggleChangeHandler(isChecked){
        if(isChecked){
            this.setState({rejectedType: 'TENANT'}, ()=>{
                ConfigurationUtils.loadRejectedRecords(this.context.intl, null, this.state.rejectedType);   
            });
        }else{
            this.setState({rejectedType: 'ASSET'},()=>{
                ConfigurationUtils.loadRejectedRecords(this.context.intl, null, this.state.rejectedType);   
            });
        }
    }

    render() {
        // let intl = this.context.intl;
        let editTenantPasswordButton;
        if(this.state.updateFlagTenant){
            editTenantPasswordButton = <Anchor><EditIcon id={"edit_tenant_icon"} name="editTenantIcon" className="password_edit_icon" size="huge" colorIndex="brand" style={{}} onClick= {event=>this.editTenantPasswordField(event)}/></Anchor>
        }else{
            editTenantPasswordButton = "";
        }

        let editAssetPasswordButton = "";
        if(this.state.updateFlagAsset){
            editAssetPasswordButton = <Anchor><EditIcon id={"edit_asset_icon"} name="editAssetIcon" className="password_edit_icon" size="huge" colorIndex="brand" style={{}} onClick= {event=>this.editAssetPasswordField(event)}/></Anchor>
        }else{
            editAssetPasswordButton = "";
        }
        return(
                <div className="config-cont-backgroud" style={{borderStyle:'1px solid'}}>
                    <div>
                        <SectionWithTitle title={this.findLabel("configpageCTtitle")} helpTopic={this.state.helpTopicScreenID}/>
                           <section>
                            <div fixed={false} margin="none" id="header2">
                                <Tabs activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction} >
                                    <Tab title={this.findLabel("label.ct.asset.configuration")} className="CTconfiguration">
                                        <ControlTowerAssetConfig findLabel = {this.findLabel} 
                                        // intl={intl} 
                                        freequencyError={this.state.freequencyError} ctUsageFreequency={this.state.ctUsageFreequency} handleChangeFrequency={this.handleChangeFrequency}
                                        hostnameError ={this.state.hostnameError} assetIdmServerUrlValue={this.state.assetIdmServerUrlValue} handleAssetChangeIdmServerUrl={this.handleAssetChangeIdmServerUrl}
                                        usernameError ={this.state.usernameError} assetIdmUserNameValue={this.state.assetIdmUserNameValue} handleAssetChangeUserName={this.handleAssetChangeUserName}
                                        passwordError ={this.state.passwordError} assetIdmPasswordValue={this.state.assetIdmPasswordValue} handleAssetChangePassword={this.handleAssetChangePassword} updateFlagAsset={this.state.updateFlagAsset} editAssetPasswordButton={editAssetPasswordButton}
                                        submitTestAssetIDMConnection={this.submitTestAssetIDMConnection}
                                        ctHostError ={this.state.ctHostError} ctAssetServerUrlValue={this.state.ctAssetServerUrlValue} handleChangeCTTAssetServerUrl={this.handleChangeCTTAssetServerUrl}
                                        maxAssetUsageChecked={this.state.maxAssetUsageChecked}
                                        featureAssetRef={this.featureAssetRef} featuresTenantsList={this.state.featuresTenantsList}
                                        submitAssetIdmConfigSettings={this.submitAssetIdmConfigSettings} cancelTenantCTIdmConfigSettings={this.cancelTenantCTIdmConfigSettings}
                                        />
                                    </Tab>
                                    <Tab title={this.findLabel("label.ct.tenant.configuration")} className="CTconfiguration">
                                        <ControlTowerTenantConfig findLabel = {this.findLabel} 
                                        // intl={intl} 
                                        freequencyError={this.state.freequencyError} ctUsageFreequency={this.state.ctUsageFreequency} handleChangeFrequency={this.handleChangeFrequency}
                                        hostnameError ={this.state.hostnameError} tenantIdmServerUrlValue={this.state.tenantIdmServerUrlValue} handleTenantChangeIdmServerUrl={this.handleTenantChangeIdmServerUrl}
                                        usernameError ={this.state.usernameError} tenantIdmUserNameValue={this.state.tenantIdmUserNameValue} handleTenantChangeUserName={this.handleTenantChangeUserName}
                                        passwordError ={this.state.passwordError} tenantIdmPasswordValue={this.state.tenantIdmPasswordValue} handleTenantChangePassword={this.handleTenantChangePassword} updateFlagTenant={this.state.updateFlagTenant} editTenantPasswordButton={editTenantPasswordButton}
                                        submitTestTenantIDMConnection={this.submitTestTenantIDMConnection}
                                        ctHostError ={this.state.ctHostError} ctTenantServerUrlValue={this.state.ctTenantServerUrlValue} handleChangeCTTenantServerUrl={this.handleChangeCTTenantServerUrl}
                                        maxTenantUsageChecked={this.state.maxTenantUsageChecked}
                                        featureTenantRef={this.featureTenantRef} featuresTenantsList={this.state.featuresTenantsList}
                                        submitTenantIdmConfigSettings={this.submitTenantIdmConfigSettings} cancelTenantCTIdmConfigSettings={this.cancelTenantCTIdmConfigSettings}
                                        />
                                    </Tab>
                            <Tab title={this.findLabel("usage.graph.rejected.title")} 
                                className="CTrejected"> 
                                <div className="action_filter_wrapper">
                                <div  className="button_left_wrap">                                   
                                               <div className="button_wrap_primary">
                                                    <Button id="ct_retry_Btn" label={LocaleUtility.getLocaleMessage("label.button.retry")} 
                                                        plain={false} primary={true} onClick={this.retryCtBtnClickHandler.bind(this)}/>
                                                </div>
                                                <div className="button_wrap_negative">
                                                    <Button id="ct_delete_Btn" label={LocaleUtility.getLocaleMessage("label.button.delete")} 
                                                        plain = {false}
                                                        secondary = {true}
                                                        critical = {true}
                                                        disabled = {false}
                                                        onClick = {this.state.selectedRowKeys.length == 0 ? '' : this.deleteCtBtnClickHandler.bind(this)}
                                                        />
                                                        </div>
                                                    <CheckBox
                                                    checked={this.state.rejectedType=="TENANT" ? true : false}
                                                    label={this.state.rejectedType=="TENANT" ? this.findLabel("label.ct.rejected.toggle.tenant") : this.findLabel("label.ct.rejected.toggle.asset")}
                                                    onChange={(event) => this.onToggleChangeHandler(event.target.checked)}
                                                    toggle={true}
                                                    />
                                </div>                
                                <div className="floatRight">
                                    <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportCTrejected}><DocumentDownloadIcon id="view-download-license" colorIndex="brand"/></a>
                                </div>   
                                </div>
                                <div>                                                    
                                    <TableGrid id="rejected_ct_view_grid" 
                                    header={[                                                                                   
                                    {
                                        columnName: "featureDesc",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.feature"),
                                        width: "auto",                                                
                                        sort: true
                                    },
                                    {
                                        columnName: "lastUpdatedDate",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.last.attempt"),                                                
                                        width: "20%",
                                        sort: true
                                    },
                                    {
                                        columnName: "errorMessage",
                                        headerLabel: LocaleUtility.getLocaleMessage("Error"),                                                
                                        width: "30%",
                                        sort: true
                                    },
                                    {
                                        columnName: "recordCount",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.record.count"),                                               
                                        width: "10%",
                                        sort: true
                                    }
                                ]}
                                {...{keys:this.state.selectedRowKeys}}
                                rowData={this.state.rejectedCTList}
                                pagination = {this.state.ctPagination}
                                rowSelection={3}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onCTPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                rowKey="usageID"
                                uncheckTheDisabled={true}
                                onChangeCheckBoxCallback={this.onRowClicked}
                                selectAllRows={false}
                                noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                itemsCountMessage={LocaleUtility.getLocaleMessage(
                                "label.grid.item.count.message", {count: (this.state.ctPagination!==undefined)?this.state.ctPagination.totalCount:0})}
                            />
                            </div>
                            </Tab>
                        </Tabs>
                        </div>
                        
                    </section> 
                    <div>
                                     <SpinningNotification centerPage={true} />
                        </div>                       
                   </div>              
                </div>
        )
    }
}
// ControlTower.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ControlTower;