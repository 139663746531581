'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// (C) Copyright 2016 Hewlett Packard Enterprise Development LP

var namespace = exports.namespace = 'grommetux-';

exports.default = {
  ACCORDION: namespace + 'accordion',
  ACCORDION_PANEL: namespace + 'accordion-panel',
  ANCHOR: namespace + 'anchor',
  ANIMATE: namespace + 'animate',
  APP: namespace + 'app',
  ARTICLE: namespace + 'article',
  ATTRIBUTE: namespace + 'attribute',
  BACKGROUND_COLOR_INDEX: namespace + 'background-color-index',
  BOX: namespace + 'box',
  BORDER_COLOR_INDEX: namespace + 'border-color-index',
  BRICK: namespace + 'brick',
  BUTTON: namespace + 'button',
  CALENDAR: namespace + 'calendar',
  CARD: namespace + 'card',
  CAROUSEL: namespace + 'carousel',
  CAROUSEL_CONTROLS: namespace + 'carousel-controls',
  CHART: namespace + 'chart',
  CHART_AXIS: namespace + 'chart-axis',
  CHART_BASE: namespace + 'chart-base',
  CHART_GRAPH: namespace + 'chart-graph',
  CHART_GRID: namespace + 'chart-grid',
  CHART_HOT_SPOTS: namespace + 'chart-hot-spots',
  CHART_LAYERS: namespace + 'chart-layers',
  CHART_MARKER: namespace + 'chart-marker',
  CHART_MARKER_LABEL: namespace + 'chart-marker-label',
  CHART_RANGE: namespace + 'chart-range',
  CHECK_BOX: namespace + 'check-box',
  COLLAPSIBLE: namespace + 'collapsible',
  COLOR_INDEX: namespace + 'color-index',
  COLUMNS: namespace + 'columns',
  CONTROL_ICON: namespace + 'control-icon',
  DATE_TIME: namespace + 'date-time',
  DATE_TIME_DROP: namespace + 'date-time-drop',
  DISTRIBUTION: namespace + 'distribution',
  DROP: namespace + 'drop',
  FOOTER: namespace + 'footer',
  FORM: namespace + 'form',
  FORM_FIELD: namespace + 'form-field',
  FORM_FIELDS: namespace + 'form-fields',
  HEADER: namespace + 'header',
  HEADING: namespace + 'heading',
  HEADLINE: namespace + 'headline',
  HERO: namespace + 'hero',
  IMAGE: namespace + 'image',
  IMAGE_FIELD: namespace + 'image-field',
  INPUT: namespace + 'input',
  LABEL: namespace + 'label',
  LAYER: namespace + 'layer',
  LEGEND: namespace + 'legend',
  LIST: namespace + 'list',
  LIST_ITEM: namespace + 'list-item',
  LOGIN: namespace + 'login',
  LOGIN_FORM: namespace + 'login-form',
  LOGO_ICON: namespace + 'logo-icon',
  MAP: namespace + 'map',
  MENU: namespace + 'menu',
  METER: namespace + 'meter',
  NAMESPACE: '' + namespace,
  NOTIFICATION: namespace + 'notification',
  NUMBER_INPUT: namespace + 'number-input',
  OBJECT: namespace + 'object',
  PARAGRAPH: namespace + 'paragraph',
  PASSWORD_INPUT: namespace + 'password-input',
  PULSE: namespace + 'pulse',
  QUOTE: namespace + 'quote',
  RADIO_BUTTON: namespace + 'radio-button',
  SEARCH: namespace + 'search',
  SEARCH_INPUT: namespace + 'search-input',
  SECTION: namespace + 'section',
  SELECT: namespace + 'select',
  SIDEBAR: namespace + 'sidebar',
  SKIP_LINK_ANCHOR: namespace + 'skip-link-anchor',
  SKIP_LINKS: namespace + 'skip-links',
  SPLIT: namespace + 'split',
  SPINNING: namespace + 'icon-spinning',
  STATUS_ICON: namespace + 'status-icon',
  SUN_BURST: namespace + 'sun-burst',
  TAB: namespace + 'tab',
  TABLE: namespace + 'table',
  TABLE_ROW: namespace + 'table-row',
  TABS: namespace + 'tabs',
  TAG: namespace + 'tag',
  TAGS: namespace + 'tags',
  TBD: namespace + 'tbd',
  TEXT_INPUT: namespace + 'text-input',
  TILE: namespace + 'tile',
  TILES: namespace + 'tiles',
  TIMESTAMP: namespace + 'timestamp',
  TIP: namespace + 'tip',
  TITLE: namespace + 'title',
  TOAST: namespace + 'toast',
  TOPOLOGY: namespace + 'topology',
  VALUE: namespace + 'value',
  VIDEO: namespace + 'video',
  WORLD_MAP: namespace + 'world-map'
};