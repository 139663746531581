import React, { Component } from "react";
import "../../../css/index.css";
import { TextInput } from 'grommet/components/TextInput';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Button } from "grommet/components/Button";
import { RadioButton } from 'grommet/components/RadioButton';
import {validateEmail, isNumber, alphanumericAndSpaceCheck} from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import {getSession} from "../../../utils/FetchHelper";
import Dispatcher from "../../../flux/Dispatcher";

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addUser: false,
            firstName:'',
            lastName:'',
            email:'',
            firstLoginChecked: false,
            mailLinkChecked: true
        };

        this.handleEmailChange= this.handleEmailChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleChangeMailLink = this.handleChangeMailLink.bind(this);
        this.handleChangeFirstLogin = this.handleChangeFirstLogin.bind(this);
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     this.setState({
    //         addUser: nextProps.addUser
    //     });
    // }

    handleFirstNameChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('firstName');
        if (event.target.value !== '' && isNumber(event.target.value)){
            if (index === -1)
                errors.push('firstName');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
		this.setState({ firstName: event.target.value });
    }

    handleLastNameChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('lastName');
        if (event.target.value !== '' && isNumber(event.target.value)){
            if (index === -1)
                errors.push('lastName');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
		this.setState({ lastName: event.target.value });
	}

    handleEmailChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('email');
        if (event.target.value === ''){
            if (index === -1)
                errors.push('email');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }

            index = errors.indexOf('invalidLength')
            if(event.target.name === 'email' && 
                    !(event.target.value.length > 5 &&  event.target.value.length < 64)){
                if (index === -1) {
                    errors.push('invalidLength');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            index = errors.indexOf('invalidEmail')
            if(event.target.name === 'email' && 
                !validateEmail(event.target.value) ){
                if (index === -1) {
                    errors.push('invalidEmail');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
        }

        this.setState({errorcode: errors});
		this.setState({ email: event.target.value });
	}

    handleSubmitButton(event) {
        event.preventDefault();
        var elements = event.target.elements;
        //const errors = Object.assign([], this.state.errorcode);
        if(this.state.errorcode != undefined && this.state.errorcode.length > 0){
            return;
        }
        if(this.props.mail !== undefined && !this.props.mail && this.state.mailLinkChecked ) {
            var payload = {
                title: LocaleUtility.getLocaleMessage("um.createusertitle"),
                content: LocaleUtility.getLocaleMessage("um.createUsermailnotconfiguredmessage"),
            }
             Dispatcher.dispatch({
                type: "MAIL_SERVER_NOT_CONF_ALERT_SHOW_TITLE_MODAL",
                payload: payload
            });
            return;
        }
        const errors = [];
        var flag = true;
        if(errors.length > 0){
            flag = false;
        } else if(elements.email.value === ''){
            let index = errors.indexOf(elements.email.name)
            if (index === -1) {
                errors.push(elements.email.name);
            }
            flag = false;
        }
        if (elements.lastName.value !== '' && !alphanumericAndSpaceCheck(elements.lastName.value)){
            flag = false;
            let index = errors.indexOf(elements.lastName.value)
            if (index === -1)
                errors.push('invalidLName');
        }  
        if (elements.firstName.value !== '' && !alphanumericAndSpaceCheck(elements.firstName.value)){
            flag = false;
            let index = errors.indexOf(elements.firstName.value)
            if (index === -1)
                errors.push('invalidFName');
        }

        if(flag === true){
            this.setState({
                addUser: false
            })

            const formPayload = {
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email:this.state.email,
                passwordResetBy: (!this.state.mailLinkChecked ? "firstlogin" : "")
            };

            this.props.handleSubmitButton(formPayload);
            this.setState({
                firstName:'',
                lastName:'',
                email:'',
                errorcode: []
            })
        } else {
            this.setState({errorcode: errors});
        }

       //call rest from here..
    }

    handleCancelButton(){
        this.setState({
            addUser: false,
            firstName:'',
            lastName:'',
            email:'',
            errorcode: []
        })
        this.props.handleUserCancelButton();
    }

    handleChangeMailLink(e){
        let checked = false;
        if(!this.state.mailLinkChecked){
            checked = true;
        }
        this.setState({ repAggrValue: "max",
                        mailLinkChecked:checked,
                        firstLoginChecked:false});
    }

    handleChangeFirstLogin(e){
        let checked = false;
        if(!this.state.firstLoginChecked){
            checked = true;
        }
        this.setState({ repAggrValue: "min",
                        mailLinkChecked:false,
                        firstLoginChecked:checked
                    });
    }


    infoSectionContent() {

        const showHideModalStyle = this.props.showModal ? "modal display-block" : "modal display-none";
       let emailFormFieldError, fNFormFieldError, lNFormFieldError, emailError, fNError, lNError, mailWarning;

        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('email') !== -1){
                emailError =<span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.input.field.alert")}</span>;
                emailFormFieldError='grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidLength') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert5")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidEmail') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert2")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('lastName') !== -1){
                lNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert4")}</span>;
                lNFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidLName') !== -1){
                lNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
                lNFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('firstName') !== -1){
                fNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert3")}</span>;
                fNFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidFName') !== -1){
                fNError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
                fNFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            emailFormFieldError = '';
            fNFormFieldError = '';
            lNFormFieldError = '';
            emailError = '';
            fNError = '';
            lNError = '';
        }

        mailWarning = '';
        if(!this.props.mail){
            mailWarning = <span style={{color:"red", fontSize:"12px", marginLeft:"2.5em"}}>
                {LocaleUtility.getLocaleMessage("label.reset.pwd.form.alert8")}</span>;
        }

           return (
            <div id="umAddUserDiv" className={showHideModalStyle}>
                <div className="modal">
                <div className="modal-main panel panel-default" style={{minWidth: "40%", width:"40%"}}>
                <div className="panel-heading">
                    <h2 className="panel-title">{this.props.title}</h2>
                </div>
                <Form id="user_add_Form" compact={false} pad="small" plain={true} onSubmit={this.handleSubmitButton}>
                    <div className="panel-body">
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.firstname")} 
                            className={fNFormFieldError}>
                            <TextInput id='user_add_firstName' autoFocus={true} name='firstName' value={this.state.firstName} onChange={event =>this.handleFirstNameChange(event)} />
                            {fNError}
                        </FormField>
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.lastname")} className={lNFormFieldError}>
                            <TextInput id='user_add_lastName' name='lastName' value={this.state.lastName} onChange={event =>this.handleLastNameChange(event)} />
                            {lNError}
                        </FormField>
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.email")} className={emailFormFieldError}>
                            <TextInput id='user_add_email' name='email' value={this.state.email} onChange={event =>this.handleEmailChange(event)}/>
                            {emailError}
                        </FormField>
                        <FormField>
                            <RadioButton id="enable-mail-link-radio" checked={this.state.mailLinkChecked} 
                            onChange={this.handleChangeMailLink} 
                            label={LocaleUtility.getLocaleMessage("add.user.reset.link.mail.radio")}/>
                            {mailWarning}
                        </FormField>
                        <FormField>
                            <RadioButton id="enable-first-login-radio" checked={this.state.firstLoginChecked} 
                            onChange={this.handleChangeFirstLogin} 
                            label={LocaleUtility.getLocaleMessage("add.user.reset.on.login.radio")}/>
                        </FormField>
                        <div>
                            <p>{LocaleUtility.getLocaleMessage("um.emailasusername")}</p>    
                        </div>                   
                    </div>
                    <div className="panel-footer panel-button-footer" >
                                <div className="create_user_popup_wrap button_right_wrap">
                                    <div className="button_wrap_primary">
                                        <Button id="user_add_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.submit")}
                                            type="submit" plain={false} primary={true}
                                        />
                                    </div>
                                    <div className="button_wrap_negative">
                                        <Button id="user_add_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                        type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                    </div>
                                </div>
                    </div>
                    </Form> 
                    </div>
                </div>
            </div>
        );
        
    }

    render() {
        return (
             this.infoSectionContent()
        );
    }
}

export default AddUser;