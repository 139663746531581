import React, { Component } from "react";
import { Anchor } from 'grommet/components/Anchor';
import { Button } from "grommet/components/Button";
import Dispatcher from "../../../../flux/Dispatcher";
import { Trash as TrashIcon } from 'grommet-icons';
import "../../../../css/index.css";
import * as FetchHelper from "../../../../utils/FetchHelper";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class FileUploadUnit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileListSize: this.props.listSize
        }

    }

    render() {
        let resultList;
        if (this.state.fileListSize > 0) {
            resultList = <Anchor id={this.props.title} onClick={this.props.delete}>
                <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
            </Anchor>;
        }
        return (
            <li className="selected_file_list">
                <div className="selected_file_div">
                    <label id={this.props.title}>{this.props.title}</label>
                    {resultList}
                </div>
            </li>
        );
    }
}

export default class FileUploadComponent extends Component {

    constructor(props) {
        super(props);
        this.size = 0;
        this.needMultiFile = this.props.needMultiFile
        this.state = {
            labelText: '',
            fileListSize: this.size,
            uploadObjects: [],
        }
        this.handleUpload = this.handleUpload.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleUpload(ev) {
        //ev.preventDefault();

        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        if (this.state.fileListSize > 0) {
            const files = this.state.uploadObjects.map(item => item.file);
            var data = new FormData();
            for (var i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }

            FetchHelper.requestValidateLicense(data);

        } else {
            let type = "DISABLE_LOADING_SPIN_DONE";
            Dispatcher.dispatch({
                type: type,
                payload: ''
            });

            type = "LICENSE_VALIDATION_ERROR";
            Dispatcher.dispatch({
                type: type,
                payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license.alert1")
            });
        }
    }

    handleCancelButton() {
        this.setState({ uploadObjects: [], fileListSize: 0,
            labelText: LocaleUtility.getLocaleLabel(this.context.intl, "label.select.files.click.add.files")});
    }

    processSelectedFiles = event => {
        var exists;
        const fileObjs = Array.from(event.target.files);
        const fileArray = Object.assign([], this.state.uploadObjects);
        const keys = fileArray.map(item => item.key);
        for (var i = 0; i < fileObjs.length; i++) {
            exists = validateFile(keys, fileObjs[i], this.context.intl);
            if (exists === '') {
                this.size = this.size + 1;
                fileArray.push({
                    key: fileObjs[i].name,
                    file: fileObjs[i]
                })
            }
        }
        if (exists === 'null' || exists === '') {
            exists = '';
        } else {
            let type = "LICENSE_VALIDATION_ERROR";
            Dispatcher.dispatch({
                type: type,
                payload: exists
            });
        }
        this.setState({
            uploadObjects: fileArray,
            fileListSize: this.size
            //labelText: exists
        })
    }

    removeFileUpload = (index) => {
        const fileArray = Object.assign([], this.state.uploadObjects);
        fileArray.splice(index, 1);
        this.size = this.size - 1;
        this.setState({
            uploadObjects: fileArray,
            fileListSize: this.size,
            labelText: LocaleUtility.getLocaleLabel(this.context.intl, "label.select.files.click.add.files")
        })
    }

     handleClick = (e) => {
    this.inputElement.click();
  }

    render() {
        var divStyle;

        var inputFileStyle = {
            opacity: '0',
            width: "0px",
            float: "left",
            padding: "0px"
        }

        // var labelStyle = {
        //     cursor: 'pointer',
        //     border: '2px solid #0073E7',
        //     borderRadius: '0px',
        //     fontSize: '0.875rem',
        //     fontWeight: '700',
        //     textAlign: 'center',
        //     outline: 'none',
        //     padding: '6px 8px',
        //     textTransform: 'none',
        //     letterSpacing: '0.5px',
        //     backgroundColor: '#0073E7',
        //     color: '#FFFFFF',
        //     top: "0.45rem",
        //     position: "relative"
        // }

        var resultList, addButtonLabel;
        addButtonLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.add.files");
        if(this.state.labelText === ''){
            this.setState({labelText:
                LocaleUtility.getLocaleLabel(this.context.intl, "label.select.files.click.add.files")});
        }

        if (this.state.fileListSize > 0) {
            addButtonLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.add.more.files");
            divStyle = {
                position: 'relative',
                height: '35px'
            };
            resultList = this.state.uploadObjects.map((uploadItem, index) => {
                return (
                    <FileUploadUnit
                        key={uploadItem.key}
                        title={uploadItem.key}
                        listSize={this.state.fileListSize}
                        delete={this.removeFileUpload.bind(this, index)} />
                )
            })
        } else {
            divStyle = {
                position: 'relative',
                height: '35px',
                whiteSpace: 'nowrap'
            };
            resultList = <li><label className="file_not_selected">{this.state.labelText}</label></li>;
        }

        return (
            <div className="filewrapper">
                <form id="lic_inst_installForm" className="file_upload_wrap">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <ul style={{ listStyleType: 'none' }}>
                                     {resultList}
                                </ul>                               
                            </div>
                        </div>
                        <div className="instalLic-btn-wrap button_left_wrap">
                            <div className="button_wrap_primary">
                                {/* <label style={labelStyle} onClick={this.handleClick}>{addButtonLabel}</label> */}
                                <Button onClick={this.handleClick}>{addButtonLabel}</Button>
                                <input hidden={true} style={inputFileStyle} ref={input => this.inputElement = input}
                                    value='' type="file" id="lic_inst_fileInput"
                                    multiple onChange={this.processSelectedFiles}
                                    accept=".txt,.dat,.xml,.xls,.xlsx" />
                            </div>
                            <div className="button_wrap_secondary">
                                <Button id="lic_inst_upFileBtn" label={this.props.uploadBtnLabel}
                                    type="button" accent={true} plain={false}
                                    //disabled={this.state.fileListSize > 0}
                                    onClick={this.handleUpload}
                                />
                            </div>
                            <div className="button_wrap_negative">
                                <Button id="lic_inst_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                            </div>
                        </div>
                </form>
            </div >
        )
    }
}

function validateFile(fileNames, fileItem, intl) {

    var exists = fileNames.some(v => (v === fileItem.name));
    if (!exists) {
        const ext = fileItem.name.slice((fileItem.name.lastIndexOf(".") - 1 >>> 0) + 2);
        if (ext === 'txt' || ext === 'dat' || ext === 'xml' || ext === 'xlsx' || ext === 'xls') {
            exists = '';
        } else {
            exists = LocaleUtility.getLocaleLabel(intl, "label.install.license.alert2");
        }
    } else {
        exists = 'null';
    }
    return exists;
}

// FileUploadComponent.contextTypes = {
//   intl: _propTypes2.default.object,
// };