import EventEmitter from "events";

class AppDispatcher extends EventEmitter {
  constructor() {
    super();
    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action) {
    this.emit("ALL", action);
  }

  register(callbackListener) {
    this.on("ALL", callbackListener);
  }

  unregister(callbackListener) {
    this.removeListener("ALL", callbackListener);
  }
}

const Dispatcher = new AppDispatcher();

export default Dispatcher;
