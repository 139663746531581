import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import { Select } from 'grommet/components/Select';
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import LDAPConfigurationStore from "../../../flux/LDAPConfigurationStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import "../../../css/App.css";
import "../../../css/index.css";
import APLSCaptcha from "../../commons/APLSCaptcha";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let initialState = null;

class LDAPConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ldapServers: [],
            ldapSelecedValue: "",
            ldapProtocols: [],
            ldapProtocolValue: "",
            ldapProtocolKey: "",
            ldapHostNameValue: "",
            ldapPortValue: "389",
            ldapAuthentications: [],
            ldapAuthMethodValue: "",
            ldapAuthMethodKey: "",
            ldapAuthUserName: "",
            ldapAuthPassword: "",
            authUserNameDisabled: false,
            authPasswordDisabled: false,
            ldapUserBaseValue: "",
            ldapSubtrees: [],
            ldapSubtreeValue: "",
            ldapSubtreeKey: "",
            uniqueIdAttributeValue: "",
            firstNameAttributeValue: "",
            lastNameAttributeValue: "",
            emailAttributeValue: "",
            userFilterValue: "",
            ldapGroupBaseValue: "",
            ldapGroupSubtreeValue: "",
            ldapGroupSubtreeKey: "",
            groupMemberAttributeValue: "",
            groupFilterAttributeValue: "",
            isFormDirty: false,
            hostnameError: "",
            portError: "",
            basednError: "",
            usersubtreeError: "",
            useridattrError: "",
            fnameattrError: "",
            lnameattrError: "",
            emailidattrError: "",
            gbasednError: "",
            gMemberAttrError: "",
            gFilterError: "",
            userFilterError: "",
            captch: "",
            captchError: "",
            authUserNameError: "",
            authPasswordError: ""
        }
        this.findLabel = this.findLabel.bind(this);
        this.refreshFormData = this.refreshFormData.bind(this);
        this.handleChangeLdapServer = this.handleChangeLdapServer.bind(this);
        this.changeLdapServer = this.changeLdapServer.bind(this);
        this.handleChangeProtocol = this.handleChangeProtocol.bind(this);
        this.changeProtocol = this.changeProtocol.bind(this);
        this.handleHostName = this.handleHostName.bind(this);
        this.changeHostName = this.changeHostName.bind(this);
        this.handlePort = this.handlePort.bind(this);
        this.changePort = this.changePort.bind(this);
        this.submitTestLdapConnection = this.submitTestLdapConnection.bind(this);
        this.handleChangeAuthMethod = this.handleChangeAuthMethod.bind(this);
        this.changeAuthMethod = this.changeAuthMethod.bind(this);
        this.handleAuthUserName = this.handleAuthUserName.bind(this);
        this.changeAuthUserName = this.changeAuthUserName.bind(this);
        this.handleAuthPassword = this.handleAuthPassword.bind(this);
        this.changeAuthPasswod = this.changeAuthPasswod.bind(this);
        this.submitTestLdapAuthConnection = this.submitTestLdapAuthConnection.bind(this);
        this.submitLdapConfigSettings = this.submitLdapConfigSettings.bind(this);
        this.cancelLdapConfigSettings = this.cancelLdapConfigSettings.bind(this);
        this.handleChangeUserBase = this.handleChangeUserBase.bind(this);
        this.changeUserBase = this.changeUserBase.bind(this);
        this.handleChangeSubTree = this.handleChangeSubTree.bind(this);
        this.changeSubTree = this.changeSubTree.bind(this);
        this.handleChangeUniqueIdAttribute = this.handleChangeUniqueIdAttribute.bind(this);
        this.changeUniqueIdAttribute = this.changeUniqueIdAttribute.bind(this);
        this.handleChangeFirstNameAttribute = this.handleChangeFirstNameAttribute.bind(this);
        this.changeFirstNameAttribute = this.changeFirstNameAttribute.bind(this);
        this.handleChangeLastNameAttribute = this.handleChangeLastNameAttribute.bind(this);
        this.changeLastNameAttribute = this.changeLastNameAttribute.bind(this);
        this.handleChangeEmailAttrbute = this.handleChangeEmailAttrbute.bind(this);
        this.changeEmailAttribute = this.changeEmailAttribute.bind(this);
        this.handleChangeUserFilter = this.handleChangeUserFilter.bind(this);
        this.changeUserFilter = this.changeUserFilter.bind(this);
        this.handleChangeGroupUserBase = this.handleChangeGroupUserBase.bind(this);
        this.changeGroupUserBase = this.changeGroupUserBase.bind(this);
        this.handleChangeGroupSubTree = this.handleChangeGroupSubTree.bind(this);
        this.changeGroupSubTree = this.changeGroupSubTree.bind(this);
        this.handleChangeGroupMemberAttribute = this.handleChangeGroupMemberAttribute.bind(this);
        this.changeGroupMemberAttribute = this.changeGroupMemberAttribute.bind(this);
        this.handleChangeGroupFilterAttribute = this.handleChangeGroupFilterAttribute.bind(this);
        this.changeGroupFilterAttribute = this.changeGroupFilterAttribute.bind(this);
        this.submitTestLdapMapping = this.submitTestLdapMapping.bind(this);
        this.madkeFormDirty = this.madkeFormDirty.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.onConfirmValidate = this.onConfirmValidate.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    madkeFormDirty() {
        this.setState({isFormDirty: true});
    }
    handleChangeLdapServer(event) {
        this.changeLdapServer(event.option);
        this.madkeFormDirty();
        let ldapServer = event.option.value;
        ConfigurationUtils.showLoadMask("", this.context.intl);
        FetchHelper.findLDAPConfiguration(this.context.intl, ldapServer, false);
    }
    changeLdapServer(obj) {
        this.setState({ldapSelecedValue: obj});
    }
    handleChangeProtocol(event) {
        this.madkeFormDirty();
        this.changeProtocol(event.option);
    }
    changeProtocol(obj) {
        this.setState({ldapProtocolValue: obj});
        this.setState({ldapProtocolKey: obj.value});
    }
    handleHostName(event) {
        this.madkeFormDirty();
        this.changeHostName(event.target.value);
    }
    changeHostName(val) {
        this.setState({ldapHostNameValue: val});
    }
    handlePort(event) {
        this.madkeFormDirty();
        this.changePort(event.target.value);
    }
    changePort(val) {
        this.setState({ldapPortValue: val});
    }
    handleChangeAuthMethod(event) {
        this.madkeFormDirty();
        this.changeAuthMethod(event.option.value);
        this.setState({ldapAuthMethodValue: event.option});
        this.setState({ldapAuthMethodKey: event.option.value});
        this.setState({authUserNameError:""});
        this.setState({authPasswordError:""});
    }
    changeAuthMethod(val) {
        if("false" === val.trim()) {
            this.setState({authUserNameDisabled: true});
            this.setState({authPasswordDisabled: true});
            this.setState({ldapAuthUserName: ""});
            this.setState({ldapAuthPassword: ""});
            //document.getElementById("conf_ldap_AuthUserNameId").value = "";
            //document.getElementById("conf_ldap_AuthPasswordId").value = "";
        } else {
            this.setState({authUserNameDisabled: false});
            this.setState({authPasswordDisabled: false});
        }
    }
    handleAuthUserName(event) {
        this.madkeFormDirty();
        this.changeAuthUserName(event.target.value);
        this.setState({authUserNameError: ""});
    }
    changeAuthUserName(val) {
        this.setState({ldapAuthUserName: val});
    }
    handleAuthPassword(event) {
        this.madkeFormDirty();
        this.changeAuthPasswod(event.target.value);
        this.setState({authPasswordError: ""});
    }
    changeAuthPasswod(val) {
        this.setState({ldapAuthPassword: val});
    }
    handleChangeUserBase(event) {
        this.madkeFormDirty();
        this.changeUserBase(event.target.value);
    }
    changeUserBase(val) {
        this.setState({ldapUserBaseValue: val});
    }
    handleChangeSubTree(event) {
        this.madkeFormDirty();
        this.changeSubTree(event.option);
    }
    changeSubTree(obj) {
        this.setState({ldapSubtreeValue: obj});
        this.setState({ldapSubtreeKey: obj.value});
    }
    handleChangeUniqueIdAttribute(event) {
        this.madkeFormDirty();
        this.changeUniqueIdAttribute(event.target.value);
    }
    changeUniqueIdAttribute(val) {
        this.setState({uniqueIdAttributeValue: val});
    }
    handleChangeFirstNameAttribute(event) {
        this.madkeFormDirty();
        this.changeFirstNameAttribute(event.target.value);
    }
    changeFirstNameAttribute(val) {
        this.setState({firstNameAttributeValue: val});
    }
    handleChangeLastNameAttribute(event) {
        this.madkeFormDirty();
        this.changeLastNameAttribute(event.target.value);
    }
    changeLastNameAttribute(val) {
        this.setState({lastNameAttributeValue: val});
    }
    handleChangeEmailAttrbute(event) {
        this.madkeFormDirty();
        this.changeEmailAttribute(event.target.value);
    }
    changeEmailAttribute(val) {
        this.setState({emailAttributeValue: val});
    }
    handleChangeUserFilter(event) {
        this.madkeFormDirty();
        this.changeUserFilter(event.target.value);
    }
    changeUserFilter(val) {
        this.setState({userFilterValue: val});
    }
    handleChangeGroupUserBase(event) {
        this.madkeFormDirty();
        this.changeGroupUserBase(event.target.value);
    }
    changeGroupUserBase(val) {
        this.setState({ldapGroupBaseValue: val});
    }
    handleChangeGroupSubTree(event) {
        this.madkeFormDirty();
        this.changeGroupSubTree(event.option);
    }
    changeGroupSubTree(obj) {
        this.setState({ldapGroupSubtreeValue: obj});
        this.setState({ldapGroupSubtreeKey: obj.value});
    }
    handleChangeGroupMemberAttribute(event) {
        this.madkeFormDirty();
        this.changeGroupMemberAttribute(event.target.value);
    }
    changeGroupMemberAttribute(val) {
        this.setState({groupMemberAttributeValue: val});
    }
    handleChangeGroupFilterAttribute(event) {
        this.madkeFormDirty();
        this.changeGroupFilterAttribute(event.target.value);
    }
    changeGroupFilterAttribute(val) {
        this.setState({groupFilterAttributeValue: val});
    }
    submitLdapConfigSettings() {
        if(this.state.isFormDirty && this.validateFormFields(this.state, this.context.intl, true)) {
            let msg = LocaleUtility.getLocaleMessage("configpageUMAlert26");
            ValidationUtility.confirm(msg,this.onConfirmValidate, "submit", "", "");
        }
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        LDAPConfigurationStore.on("change", this.refreshFormData);
        FetchHelper.findLDAPConfiguration(this.context.intl, "", false);
    }
    componentWillUnmount() {
        LDAPConfigurationStore.removeListener("change", this.refreshFormData);
    }
    cancelLdapConfigSettings() {
        if(initialState) {
            this.setState(initialState);
            this.refreshFormData();
        }
    }
    submitTestLdapConnection() {
        let isCapcha = true;
        let el = document.getElementById("conf_ldap_captcha");
        if(el.value === null || el.value === undefined || el.value === "") {
            isCapcha = false;
            let message = LocaleUtility.getLocaleMessage("capcha.empty");
            this.setState({captchError: message});
        }
        if(isCapcha && this.validateFormFields(this.state, this.context.intl, false) ) {
            this.clearErrors();
            this.madkeFormDirty();
            FetchHelper.testLDAPConnection(this.state, this.context.intl);
        }
    }

    onConfirmValidate(flag, method){
        if(flag === 1){
            if(method === 'submit'){
                FetchHelper.saveOrUpdateLDAPConfiguration(this.state, this.context.intl);
            }
        }
    }
    validateFormFields(data, intl, warnRequired) {
        let errorsMap = ConfigurationUtils.validateLDAPConfiguration(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["port"] !== undefined && errorsMap["port"] !== "") {
            this.setState({portError: errorsMap["port"]});
            flag = false;
        }
        if(errorsMap["basedn"] !== undefined && errorsMap["basedn"] !== "") {
            this.setState({basednError: errorsMap["basedn"]});
            flag = false;
        }
        if(errorsMap["usersubtree"] !== undefined && errorsMap["usersubtree"] !== "") {
            this.setState({usersubtreeError: errorsMap["usersubtree"]});
            flag = false;
        }
        if(errorsMap["useridattr"] !== undefined && errorsMap["useridattr"] !== "") {
            this.setState({useridattrError: errorsMap["useridattr"]});
            flag = false;
        }
        if(errorsMap["fnameattr"] !== undefined && errorsMap["fnameattr"] !== "") {
            this.setState({fnameattrError: errorsMap["fnameattr"]});
            flag = false;
        }
        if(errorsMap["lnameattr"] !== undefined && errorsMap["lnameattr"] !== "") {
            this.setState({lnameattrError: errorsMap["lnameattr"]});
            flag = false;
        }
        if(errorsMap["emailidattr"] !== undefined && errorsMap["emailidattr"] !== "") {
            this.setState({emailidattrError: errorsMap["emailidattr"]});
            flag = false;
        }
        if(errorsMap["gbasedn"] !== undefined && errorsMap["gbasedn"] !== "") {
            this.setState({gbasednError: errorsMap["gbasedn"]});
            flag = false;
        }
        if(errorsMap["gMemberAttr"] !== undefined && errorsMap["gMemberAttr"] !== "") {
            this.setState({gMemberAttrError: errorsMap["gMemberAttr"]});
            flag = false;
        }
        if(errorsMap["gFilter"] !== undefined && errorsMap["gFilter"] !== "") {
            this.setState({gFilterError: errorsMap["gFilter"]});
            flag = false;
        }
        if(errorsMap["userFilter"] !== undefined && errorsMap["userFilter"] !== "") {
            this.setState({userFilterError: errorsMap["userFilter"]});
            flag = false;
        }
        if(errorsMap["ldapAuthUserName"] !== undefined && errorsMap["ldapAuthUserName"] !== "") {
            this.setState({authUserNameError: errorsMap["ldapAuthUserName"]});
            flag = false;
        }
        if(errorsMap["ldapAuthPassword"] !== undefined && errorsMap["ldapAuthPassword"] !== "") {
            this.setState({authPasswordError: errorsMap["ldapAuthPassword"]});
            flag = false;
        }
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }

        return flag;
    }
    clearErrors() {
        this.setState({hostnameError: ""});
        this.setState({portError: ""});
        this.setState({basednError: ""});
        this.setState({usersubtreeError: ""});
        this.setState({useridattrError: ""});
        this.setState({fnameattrError: ""});
        this.setState({lnameattrError: ""});
        this.setState({emailidattrError: ""});
        this.setState({gbasednError: ""});
        this.setState({gMemberAttrError: ""});
        this.setState({gFilterError: ""});
        this.setState({userFilterError: "", captchError: "", authUserNameError:"", authPasswordError:""});
    }
    submitTestLdapAuthConnection() {
        FetchHelper.testLDAPAuthentication(this.state, this.context.intl);
    }
    submitTestLdapMapping() {
        FetchHelper.testLDAPMapping(this.state, this.context.intl);
    }
    refreshFormData() {        
        let captch= LDAPConfigurationStore.getState().captcha;
        if(LDAPConfigurationStore.getState().isTestConn !== true){
            this.clearErrors();
            let listProp = LDAPConfigurationStore.getState().listdefProp;
            let protocalValue = LDAPConfigurationStore.getState().protocol;
            let hostname = LDAPConfigurationStore.getState().hostname;
            let port = LDAPConfigurationStore.getState().port;
            let authenticationmethod = LDAPConfigurationStore.getState().authenticationmethod;
            let uBase = LDAPConfigurationStore.getState().basedn;
            let usersubtree = LDAPConfigurationStore.getState().usersubtree;
            let useridattr = LDAPConfigurationStore.getState().useridattr;
            let fnameattr = LDAPConfigurationStore.getState().fnameattr;
            let lnameattr = LDAPConfigurationStore.getState().lnameattr;
            let eamilattr = LDAPConfigurationStore.getState().eamilattr;
            let usersFilter = LDAPConfigurationStore.getState().usersFilter;
            let gbasedn = LDAPConfigurationStore.getState().gbasedn;
            let groupsubtree = LDAPConfigurationStore.getState().groupsubtree;
            let grmemattr = LDAPConfigurationStore.getState().grmemattr;
            let groupsFilter = LDAPConfigurationStore.getState().groupsFilter;
            let ldapUserName = LDAPConfigurationStore.getState().username;
            if(listProp) {
                let ldapServs = [];
                listProp.entry.map(element => {
                    let obj = {label: element.value, value: element.key};
                    ldapServs.push(obj);
                    return "";
                });
                this.setState({ldapServers: ldapServs});
            }
            let listProtocols = LDAPConfigurationStore.getState().listprotocols;
            if(listProtocols) {
                let ldapProtos = [];
                listProtocols.entry.map(element => {
                    let obj = {label: element.value, value: element.key};
                    if(protocalValue === element.key) {
                        this.setState({ldapProtocolValue: obj});
                        this.setState({ldapProtocolKey: element.key});
                    }
                    ldapProtos.push(obj);
                    return "";
                });
                this.setState({ldapProtocols: ldapProtos});
            }
            this.setState({ldapHostNameValue: hostname});
            this.setState({ldapPortValue: port});
            let listAuthMethods = LDAPConfigurationStore.getState().listauthmethods;
            if(listAuthMethods) {
                let ldapAuths = [];
                listAuthMethods.entry.map(element => {
                    let obj = {label: LocaleUtility.getLocaleMessage("config.ldap.authentication.simple"), value: element.key};
                    let objLabel = element.value;
                    switch (element.key.toLowerCase()) {
                        case "false":
                            objLabel = LocaleUtility.getLocaleMessage("config.ldap.authentication.anonymous");
                            break;
                        case "true":
                            objLabel = LocaleUtility.getLocaleMessage("config.ldap.authentication.simple");
                            break;
                        default:
                            objLabel=element.value;
                            break;
                    }
                    obj = {label: objLabel, value: element.key};
                    if(authenticationmethod === element.key) {
                        this.setState({ldapAuthMethodValue: obj});
                        this.setState({ldapAuthMethodKey: element.key});
                        if(element.key && "false" === element.key.trim()) {
                            this.changeAuthMethod(element.key);
                        }
                    }
                    ldapAuths.push(obj);
                    return "";
                });
                this.setState({ldapAuthentications: ldapAuths});
            }
            let scopesList = LDAPConfigurationStore.getState().listusersearchscope;
            if(scopesList) {
                let ldapScops = [];
                scopesList.entry.map(element => {
                    let obj = {label: element.value, value: element.key};
                    if(usersubtree === element.key) {
                        this.setState({ldapSubtreeValue: obj});
                        this.setState({ldapSubtreeKey: element.key});
                    }
                    if(groupsubtree === element.key) {
                        this.setState({ldapGroupSubtreeValue: obj});
                        this.setState({ldapGroupSubtreeKey: element.key});
                    }
                    ldapScops.push(obj);
                    return "";
                });
                this.setState({ldapSubtrees: ldapScops});
            }
            this.setState({ldapUserBaseValue: uBase});
            this.setState({uniqueIdAttributeValue: useridattr});
            this.setState({firstNameAttributeValue: fnameattr});
            this.setState({lastNameAttributeValue: lnameattr});
            this.setState({emailAttributeValue: eamilattr});
            this.setState({userFilterValue: usersFilter});
            this.setState({ldapGroupBaseValue: gbasedn});
            this.setState({groupMemberAttributeValue: grmemattr});
            this.setState({groupFilterAttributeValue: groupsFilter, ldapAuthUserName: ldapUserName});
            this.setState({ldapAuthPassword:""});
            ConfigurationUtils.hideLoadMask(this.context.intl);
            initialState = this.state;  
            this.setState({isFormDirty: false});
            this.setState({captch:captch});       
        }else{
            this.setState({captch:captch});
            this.setState({groupFilterAttributeValue: this.conf_ldap_groupFilterIdVal.value,
                ldapAuthUserName: this.conf_ldap_AuthUserNameIdVal.value,
                groupMemberAttributeValue: this.conf_ldap_groupMemberAttrIdVal.value,
                ldapUserBaseValue: this.conf_ldap_UserBaseIdVal.value,
                uniqueIdAttributeValue: this.conf_ldap_uniqueIdAttributeIdVal.value,
                firstNameAttributeValue: this.conf_ldap_firstNameAttributeIdVal.value,
                lastNameAttributeValue: this.conf_ldap_lastNameAttributeIdVal.value,
                emailAttributeValue: this.conf_ldap_emailAttributeIdVal.value,
                userFilterValue: this.conf_ldap_userFilterIdVal.value,
                ldapGroupBaseValue: this.conf_ldap_GroupUserBaseIdVal.value,
                ldapProtocolValue: this.conf_ldap_seletProtocolIdVal.value,
                ldapHostNameValue: this.conf_ldap_HostNameIdVal.value,
                ldapPortValue: this.conf_ldap_PortIdVal.value,
                ldapSubtreeValue: this.conf_ldap_userSubtreeIdVal.value
            });
			this.setState({isFormDirty: true});
        }
    }
    render() {
        let intl = this.context.intl;
        let disClassName = "enabledTextField";
        if(this.state.authUserNameDisabled) {
            disClassName = "disabledTextField";
        }
        return(
            <div className="config-cont-backgroud">
                <div>
                    <SectionWithTitle title={this.findLabel("configpageLDAPtitle")}  helpTopic="LDAP_conf"
                        setionWidth = "2"
                    />
                    <section pad="none" wrap={false} responsive={false} justify="center">
                        <Form compact={false} pad="small" id="conf_ldap_formId" plain={true}>
                            <div   className="action_filter_wrapper">
                                <div className="horizantal_label_input ldapConfig_wrap">
                            <FormField label= {this.findLabel("configpageLDAPChooseLdapServer")}>
                                <Select id="seletLdapServerId" placeholder={this.findLabel("configpageLDAPSelectServer")} ref={el => this.seletLdapServerIdVal=el}
                                    options={this.state.ldapServers}
                                    value={this.state.ldapSelecedValue}
                                    onChange={this.handleChangeLdapServer} />
                            </FormField>
                            </div>
                            </div>
                            <div className="border_white_backg_shadow two_col_wrap">
                                <div className="left_grid">
                                    <h2 className="headerConfig">
                                        {this.findLabel("configpageLDAPConnection")}
                                    </h2>
                                    <div className="horizantal_label_input ldapConfig_wrap">
                                    <FormField required={true} label= {this.findLabel("configpageLDAProtocol")} >
                                        <Select id="conf_ldap_seletProtocolId" placeholder={this.findLabel("configpageIpHostSelect")}  ref={el => this.conf_ldap_seletProtocolIdVal=el}
                                            options={this.state.ldapProtocols}
                                            value={this.state.ldapProtocolValue}
                                            onChange={this.handleChangeProtocol} />
                                    </FormField>
                                    <FormField error={this.state.hostnameError} required={true} label= {this.findLabel("configpageLDAPHostName")} >
                                        <TextInput id="conf_ldap_HostNameId" value={this.state.ldapHostNameValue} onChange={event =>this.handleHostName(event)} ref={el => this.conf_ldap_HostNameIdVal=el}/>
                                    </FormField>
                                    <FormField error={this.state.portError} required={true} label= {this.findLabel("configpageLDAPPort")} >
                                        <TextInput id="conf_ldap_PortId" value={this.state.ldapPortValue} onChange={event =>this.handlePort(event)}  ref={el => this.conf_ldap_PortIdVal=el}/>
                                    </FormField>                                    
                                    <FormField error={this.state.captchError} required={true} label= {this.findLabel("captcha")} >
                                        <TextInput id="conf_ldap_captcha" />
                                    </FormField>
                                    </div>
                                    <div className="button_right_wrap mar_right_10 ldapConfig_wrap">
                                        <APLSCaptcha captcha={this.state.captch} />
                                        <div className="button_wrap_secondary">
                                        <Button id="conf_ldap_testConnetion" label={this.findLabel("configpageLDAPTestConnection")} plain={false} primary={true} onClick={this.submitTestLdapConnection} />
                                        </div>
                                    </div>
                                    
                                    <div className="separator"></div>
                                    <h2 className="headerConfig">
                                        {this.findLabel("configpageLDAPAuthentication")}
                                    </h2>
                                    <div className="horizantal_label_input ldapConfig_wrap">
                                    <FormField required={true} label= {this.findLabel("configpageLDAPAuthMethod")} >
                                       <Select id="conf_ldap_AuthMethodNameId" placeholder={this.findLabel("configpageIpHostSelect")}  ref={el => this.conf_ldap_AuthMethodNameIdVal=el}
                                            options={this.state.ldapAuthentications}
                                            value={this.state.ldapAuthMethodValue}
                                            onChange={this.handleChangeAuthMethod} />
                                        {/*<select id="conf_ldap_AuthMethodNameId" placeholder={this.findLabel("configpageIpHostSelect", intl)} 
                                            value={this.state.ldapAuthMethodValue}
                                            onChange={this.handleChangeAuthMethod} className="customSelect">
                                            {this.state.ldapAuthentications.map((item, key) => 
                                            <option key={key} value={item.value}>{item.label}</option>)}
                                        </select>*/}
                                    </FormField>
                                    <FormField error={this.state.authUserNameError} required={!this.state.authUserNameDisabled} label= {this.findLabel("configpageLDAPAuthUserName")} >
                                        <TextInput value={this.state.ldapAuthUserName} id="conf_ldap_AuthUserNameId" className={disClassName} disabled={this.state.authUserNameDisabled} onChange={event =>this.handleAuthUserName(event)}  ref={el => this.conf_ldap_AuthUserNameIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.authPasswordError} label={this.findLabel("configpageLDAPAuthPassword")} required={!this.state.authPasswordDisabled} >
                                        <Password id="conf_ldap_AuthPasswordId" autoComplete="new-password" className={disClassName + " password_icon"} value={this.state.ldapAuthPassword} disabled={this.state.authPasswordDisabled} onChange={this.handleAuthPassword} />
                                    </FormField>
                                    </div>
                                    <div className="button_right_wrap ldapConfig_wrap">
                                        <div className="button_wrap_primary">
                                            <Button id="conf_ldap_testAuthConnBtn" label={this.findLabel("configpageLDAPTestAuthentication")} plain={false} primary={true} onClick={this.submitTestLdapAuthConnection} />
                                        </div>
                                    </div>
                                </div>
                                <div className="right_grid">
                                    <h2 className="headerConfig">
                                        {this.findLabel("configpageLDAPUserElementMapping")}
                                    </h2>
                                    <div className="horizantal_label_input ldapConfig_wrap">
                                    <FormField error={this.state.basednError} required={true} label= {this.findLabel("configpageLDAPUserBase")} >
                                        <TextInput id="conf_ldap_UserBaseId" value={this.state.ldapUserBaseValue} onChange={event =>this.handleChangeUserBase(event)} ref={el => this.conf_ldap_UserBaseIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.usersubtreeError} label= {this.findLabel("configpageLDAPUserSubTree")} >
                                        <Select id="conf_ldap_userSubtreeId" placeholder={this.findLabel("configpageIpHostSelect")} ref={el => this.conf_ldap_userSubtreeIdVal=el} 
                                            options={this.state.ldapSubtrees}
                                            value={this.state.ldapSubtreeValue}
                                            onChange={this.handleChangeSubTree} />
                                    </FormField>
                                    <FormField error={this.state.useridattrError} required={true} label= {this.findLabel("configpageLDAPUniqueIdAttribute")} >
                                        <TextInput id="conf_ldap_uniqueIdAttributeId" value={this.state.uniqueIdAttributeValue} onChange={event =>this.handleChangeUniqueIdAttribute(event)}  ref={el => this.conf_ldap_uniqueIdAttributeIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.fnameattrError} required={true} label= {this.findLabel("configpageLDAPFirstNameAttribute")} >
                                        <TextInput id="conf_ldap_firstNameAttributeId" value={this.state.firstNameAttributeValue} onChange={event =>this.handleChangeFirstNameAttribute(event)} ref={el => this.conf_ldap_firstNameAttributeIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.lnameattrError} required={true} label= {this.findLabel("configpageLDAPLastNameAttribute")} >
                                        <TextInput id="conf_ldap_lastNameAttributeId" value={this.state.lastNameAttributeValue} onChange={event =>this.handleChangeLastNameAttribute(event)} ref={el => this.conf_ldap_lastNameAttributeIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.emailidattrError} required={true} label= {this.findLabel("configpageLDAPEmailAttribute")} >
                                        <TextInput id="conf_ldap_emailAttributeId" value={this.state.emailAttributeValue} onChange={event =>this.handleChangeEmailAttrbute(event)} ref={el => this.conf_ldap_emailAttributeIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.userFilterError} required={true} label= {this.findLabel("configpageLDAPUserFilter")} >
                                        <TextInput id="conf_ldap_userFilterId" value={this.state.userFilterValue} onChange={event =>this.handleChangeUserFilter(event)} ref={el => this.conf_ldap_userFilterIdVal=el} />
                                    </FormField>
                                    </div>
                                    <div className="button_right_wrap ldapConfig_wrap">
                                        <div className="button_wrap_primary">
                                        <Button id="conf_ldap_testMappBtnId" label={this.findLabel("configpageLDAPTestMapping")} plain={false} primary={true} onClick={this.submitTestLdapMapping} />
                                        </div>
                                    </div>
                                    <div className="separator"></div>
                                    <h2 className="headerConfig">
                                        {this.findLabel("configpageLDAPGroupElementMapping")}
                                    </h2>
                                    
                                    <div className="horizantal_label_input ldapConfig_wrap">
                                    <FormField error={this.state.gbasednError} required={true} label= {this.findLabel("configpageLDAPGroupBase")} >
                                        <TextInput id="conf_ldap_GroupUserBaseId" value={this.state.ldapGroupBaseValue} onChange={event =>this.handleChangeGroupUserBase(event)} ref={el => this.conf_ldap_GroupUserBaseIdVal=el} />
                                    </FormField>
                                    <FormField label= {this.findLabel("configpageLDAPGroupSubTree")} >
                                        <Select id="conf_ldap_groupSubtreeId" placeholder={this.findLabel("configpageIpHostSelect")} ref={el => this.conf_ldap_groupSubtreeIdVal=el} 
                                            options={this.state.ldapSubtrees}
                                            value={this.state.ldapGroupSubtreeValue}
                                            onChange={this.handleChangeGroupSubTree} />
                                    </FormField>
                                    <FormField error={this.state.gMemberAttrError}  required={true} label= {this.findLabel("configpageLDAPGroupMemberAttribute")} >
                                        <TextInput id="conf_ldap_groupMemberAttrId" value={this.state.groupMemberAttributeValue} onChange={event =>this.handleChangeGroupMemberAttribute(event)} ref={el => this.conf_ldap_groupMemberAttrIdVal=el} />
                                    </FormField>
                                    <FormField error={this.state.gFilterError} required={true} label= {this.findLabel("configpageLDAPGroupFilter")} >
                                        <TextInput id="conf_ldap_groupFilterId" value={this.state.groupFilterAttributeValue} onChange={event =>this.handleChangeGroupFilterAttribute(event)} ref={el => this.conf_ldap_groupFilterIdVal=el} />
                                    </FormField>
                                    </div>
                                </div>
                            </div>
                            <div className="border_white_backg_shadow button_left_wrap">
                                <div className="button_wrap_primary">
                                    <Button id="conf_ldap_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitLdapConfigSettings} /></div>
                                <div className="button_wrap_negative">
                                    <Button id="conf_ldap_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelLdapConfigSettings} />
                                </div>
                            </div>
                            <div>
                                <SpinningNotification centerPage={true} />
                            </div>
                        </Form>
                    </section>
                </div>
            </div>
        )
    }
}
// LDAPConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LDAPConfiguration;