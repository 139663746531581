import React, { Component } from "react";
import "../../../css/index.css";
import { TextInput } from 'grommet/components/TextInput';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Button } from "grommet/components/Button";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
//import UserSearch from "./UserSearch";
import Dispatcher from "../../../flux/Dispatcher";
import Store from "../../../flux/APTableStore";
import TableGrid from "../../../components/commons/table/APTable";
import { AddCircle } from 'grommet-icons';

class SearchGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchGroup: false,
            searchText:'',
            isAdmin:'',
            userSearch:undefined,
            groupList:[],
            groupSearchTableData:[],
            groupListKey:[]
        };
        this.refreshState = this.refreshState.bind(this);
        this.handlesearchTextChange = this.handlesearchTextChange.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.onClickAddGroup = this.onClickAddGroup.bind(this);
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     this.setState({
    //         searchGroup: nextProps.searchGroup
    //     });
    // }

    handlesearchTextChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('searchText');

        if(event.target.name === 'searchText' && 
                !(event.target.value.length > 1 &&  event.target.value.length < 64)){
            if (index === -1) {
                errors.push('searchText');
            }
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }

        this.setState({errorcode: errors});
		this.setState({ searchText: event.target.value });
    }

componentDidMount() {
//   let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
//   Dispatcher.dispatch({
//       type: loadSpinAction,
//       payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
//   });
    Store.on("change", this.refreshState);
}

componentWillUnmount(){
    Store.removeListener("change", this.refreshState);
}

refreshState() {
    let ldapGroupData = Store.getState().ldapGroupData;
    if(ldapGroupData!== undefined){
        let rowData = [];
        let data = ldapGroupData;
        this.setState({
                        groupList: data
                    });
        ldapGroupData.map(item => {
            item["addGroup"] = <AddCircle colorIndex="brand" title="Assign to DB"/>; 
            return "";           
        });
        this.setState({
                        groupSearchTableData: ldapGroupData
                    });
        ldapGroupData.map(item=>{
            // if(item.groupName === Store.getState().groupName)
            // {  
                    //item.map((item, index) => {
                        rowData.push(item.groupName);
                   // });
                    // let tempPagination = this.state.userPagination;
                    // if(tempPagination !== undefined) {
                    //     tempPagination.totalCount = item.userList.length;
                    // }
            // }
            return "";
        });  
        this.setState({
            groupListKey: rowData
        });
    }
    // Dispatcher.dispatch({
    //         type: "LOAD_SPIN_DONE",
    //         payload: ''
    //     }); 
}
    handleDescriptionChange(e) {
		this.setState({ groupDesc: e.target.value });
	}

    handleCheckBoxChange(e){
        this.setState({isAdmin: e.target.checked});
    }
    
    handleSubmitButton(event) {
        event.preventDefault();
        var elements = event.target.elements;
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(errors.length > 0){
            flag = false;
        } else if(elements.searchText.value === ''){
            let index = errors.indexOf(elements.searchText.name)
            if (index === -1) {
                errors.push(elements.searchGroup.name);
            }
            flag = false;
        }

        if(flag === true){
            this.setState({
                searchGroup: false
            })
    
            const formPayload = {
                searchText:this.state.searchText,
                isAdmin: this.state.isAdmin
            };
    
            this.props.handleSubmitButton(formPayload);
            this.setState({
                searchText:''
            })
        } else {
            this.setState({errorcode: errors});
        }
    }

    handleCancelButton(){
        this.setState({
            searchGroup: false,
            errorcode: []
        }) 

        this.props.handleGroupCancelButton();
        // this.setState({
        //     searchText:''
        // })
    }
    onClickAddGroup(key, event){
        Dispatcher.dispatch({
            type: "GROUP_CREATE_LOADING_SPIN",
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        let data = this.state.groupList.filter(function(item){
            return item.groupName === key;         
        });
        FetchHelper.assignLdapGroup(data, this.props.intlContext);
        this.props.handleGroupCancelButton();
    }
    infoSectionContent() {

        const showHideModalStyle = this.props.showSearchGroupModal ? "modal display-block" : "modal display-none";
        let gNFormFieldError, grpNameError;

        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('searchText') !== -1){
                grpNameError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert7")}</span>;
                gNFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            gNFormFieldError = '';
            grpNameError = '';
        }


           return (
            <div id="umsearchGroupDiv" className={showHideModalStyle}>
                <div className="modal">
                <div className="modal-main panel panel-default" style={{minWidth: "40%", width:"40%"}}>
                <div className="panel-heading">
                    <h2 className="panel-title">{this.props.title}</h2>
                </div>
                <Form compact={false} pad="small" plain={true} onSubmit={this.handleSubmitButton}>
                    <div className="panel-body">
                        <FormField /*style={{ width: '30em' }}*/ label={"Search Group"} className={gNFormFieldError}>
                            <TextInput id='searchText' placeholder="Enter full group name for search" autoFocus={true} name='searchText'  onChange={event =>this.handlesearchTextChange(event)} />
                            {grpNameError}
                        </FormField>  
                        <div id="GroupSearchTable">
                    {/* <UserSearch {...this.state}
                    title={LocaleUtility.getLocaleMessage("um.assigngroups")}
                    userSearch={this.state.userSearch}
                    closeUserSearchButton={this.closeUserSearchButton}
                    handleAddButton={this.handleAddButton}/> */}
                    {this.state.groupList.length>0 && <div>
                        <TableGrid id="groups-search-list"
                            header={[
                                {
                                    columnName: "groupName",
                                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.groupname"),
                                    href: false,
                                    width: "auto",
                                    columnKey: "groupName"
                                },
                                {
                                    columnName: "groupDesc",
                                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.groupdesc"),
                                    href: false,
                                    width: "auto",
                                    columnKey: "groupDesc"
                                },{
                                    columnName: "groupEmail",
                                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.email"),
                                    href: false,
                                    width: "auto",
                                    columnKey: "groupEmail"
                                },{                                    
                                    columnName: "addGroup",
                                    href: false,
                                    width: "auto",
                                    columnKey: "groupName",
                                    onClick: this.onClickAddGroup
                                }
                            ]}
                            {...{keys:this.state.groupListKey}}
                            rowData={this.state.groupSearchTableData}
                            //pagination={/*paginationObj*/null}
                            rowSelection={0}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            //onPagination={this.onProductPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="groupName"
                            onChangeCheckBoxCallback={this.onProductRowClicked}
                            selectAllRows={false}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.groupList.length})}
                        />
                    </div>}
                    
                </div>                      
                    </div>
                    <div className="panel-footer panel-button-footer">
                        <table style={{float:"right"}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <Button id="group_searchBtn" label={LocaleUtility.getLocaleMessage("usageSearchTitle")}
                                            type="submit" plain={false} primary={true}
                                        />
                                    </td>
                                    <td>
                                        <Button id="group_search_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        </Form>
                    </div>
                </div>                
            </div>
        );
        
    }

    render() {
        return (
             this.infoSectionContent()
        );
    }
}

export default SearchGroup;