import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";

import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import TokenCalStore from "../../../flux/TokenCalStore";
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import Select from "../../commons/APLSSelect";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { Button } from "grommet/components/Button";
//import { FormField } from "grommet/components/FormField";
import AddFeatures from "./AddFeatures";
import {roundOfValue}  from "../../../utils/Util";
import {localNumberformat}  from "../../../utils/Util";
import { Trash as TrashIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class TokenCal extends Component {
    constructor(props) {
        super(props);        
          this.state = {
            rowData:  [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "featureId",
                sortOrder: "DESC"
            },
            pagination1: {
                required: true,
                style: "default",
                totalCount: 20,
                pageNumber: 1,
                pageSize: 20,
                sortBy: "",
                sortOrder: "DESC"
            },
           showcal:false,
            pdrowData:  [],            
            options:[],
            selectedValue:"",
            usageData:[],
            expectingCap:0,
            currentAvailableToken:0,
            tokenFeatureInfo:"",
            tokenFeaturesList:[],
            showCreatePopup: false,
            addpList:[],
            totalTokenCap:0,
            selTokenFeature: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")}           
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadData=this.loadData.bind(this);
        this.onClickFeatureId=this.onClickFeatureId.bind(this);
        this.onPaginationClicked=this.onPaginationClicked.bind(this);
        this.loadMappingData=this.loadMappingData.bind(this);
        this.paintTextboxForCapacity=this.paintTextboxForCapacity.bind(this);
        this.continueCapacitySaveAction = this.continueCapacitySaveAction.bind(this);
        this.backbutton=this.backbutton.bind(this);
        this.findData=this.findData.bind(this);
        this.handleResetAction=this.handleResetAction.bind(this);
        this.onChangeTokenFeature=this.onChangeTokenFeature.bind(this);
        this.onClickOpenpop=this.onClickOpenpop.bind(this);
        this.closeTokenPopup=this.closeTokenPopup.bind(this);
        this.applyFeaturesAction=this.applyFeaturesAction.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        
        
         
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
      TokenCalStore.on("change",this.loadData);
      TokenCalStore.on("change",this.loadMappingData);  
      this.findData();  
    }
    findData(){
      var postData={
        requestType:"TOKEN",
        pagination:null
      };
      FetchHelper.listTokenFeatures(postData);
    }
    componentWillUnmount() {
        TokenCalStore.removeListener("change", this.loadData);
        TokenCalStore.removeListener("change", this.loadMappingData);         
    }
    loadData(){
      if(TokenCalStore.getState()!==undefined && TokenCalStore.getState().actionType === "tokenList"){
        var fetlist=TokenCalStore.getState().data.productFeatureMapping;
        var temptokenFeaturesList=[];
        fetlist.forEach(element => {
          temptokenFeaturesList.push({value:element.tokenFeatureIDandVersion,label:element.featureDescription+" ("+element.tokenFeatureIDandVersion+")"});
        });
        this.setState({rowData: TokenCalStore.getState().data.productFeatureMapping,showcal: false,temptokenFeaturesList:temptokenFeaturesList})
        ConfigurationUtils.hideLoadMask(undefined);
      }
    }
    loadMappingData(){
      if(TokenCalStore.getState()!==undefined && TokenCalStore.getState().actionType === "tokenMappedList"){
        let mappedData=TokenCalStore.getState().pddata.productFeatureMapping;
       
         const rows = mappedData.map(item => {                    
                    item.textbox = this.paintTextboxForCapacity(item);
                    item.deleteIt =  <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />;
                    return item;
                });
       this.setState({pdrowData: rows,rowData:[], usageData:TokenCalStore.getState().pddata,currentAvailableToken:TokenCalStore.getState().pddata.availableCap})
       ConfigurationUtils.hideLoadMask(undefined);
      }
      
    }
    onClickFeatureId(value){
      //ConfigurationUtils.showErrorMessage1("configpageAlert511", this.context.intl);
      let featureInfo=value.split(":");
      var request = {
        productFeatureMapping: {
        tokenFeatureId:featureInfo[0],
        tokenFeatureVer:featureInfo[1]
      }};          
      
     this.setState({showcal: true,rowData:[],pdrowData:[],tokenFeatureInfo:value,expectingCap:0,totalTokenCap:0});
     FetchHelper.listMappedFeturesForToken(request); 
    }

    paintTextboxForCapacity(item){
        return (
            <input className="reserveCapacityEdit" defaultValue="0" type="text"  name={item.id} id={item.id} onBlur={(event)=>this.continueCapacitySaveAction(item, event)}/>
        )
    }
    continueCapacitySaveAction(item, event) {
      var total=0;
      var temptotalTokenCap=0;
      var fetlist=this.state.addpList;
      var catoken=parseFloat(this.state.usageData.availableCap);
      fetlist.forEach(element => {
        let el = document.getElementById(element.id);
        let featureInfo=element.mappedValue.split(":");
        let val='';
        if(el!==null && el!==undefined){
            val = el.value;
        }
        if(val===''){
         
        }else{
          if(ValidationUtility.isNumber(val)) {
          total=((parseFloat(parseFloat(featureInfo[0]/featureInfo[1]))*parseInt(val))+parseFloat(total));       
          }else{
            ConfigurationUtils.showErrorMessage1("configpageAlert511", this.context.intl);
          }
        }
      });
      temptotalTokenCap=total;
      if(parseFloat(this.state.usageData.availableCap) > 0 ){
       // total= parseFloat(this.state.usageData.availableCap)-parseFloat(total);       
        if(parseFloat(this.state.usageData.availableCap)-parseFloat(total) > 0.0){
         catoken=parseFloat(this.state.usageData.availableCap)-parseFloat(total);
        total=0;
        }
        else{
        total=parseFloat(total)-parseFloat(this.state.usageData.availableCap);
        catoken=0;
        }
        // console.log(catoken);
      }

        this.setState({expectingCap:total,currentAvailableToken:catoken,totalTokenCap:temptotalTokenCap});
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        var postData={
        requestType:"TOKEN",
        pagination:paginationTemp
      };
    FetchHelper.listTokenFeatures(postData);
    }
    backbutton(){
       this.setState({showcal: true,rowData:[],pdrowData:[]});
       this.findData();
    }
    handleResetAction(){
      this.setState({expectingCap:0,totalTokenCap:0});
       var fetlist=this.state.addpList;
       fetlist.forEach(element => {
         document.getElementById(element.id).value=0;
       });
        this.onClickFeatureId(this.state.tokenFeatureInfo);
        
    }
    onChangeTokenFeature(event){
       //  console.log(event)     
      this.setState({selTokenFeature: event.option,addpList:[]});
      this.onClickFeatureId(event.option.value);

    }
    onClickOpenpop(){
      //var valToken=document.getElementById("usage_list_seletToken").value;
      if(this.state.selTokenFeature !== undefined && LocaleUtility.getLocaleMessage("label.select.none") === this.state.selTokenFeature.label){
        ConfigurationUtils.showErrorMessage1("selecttokenfeature", this.context.intl);
      }else{
        this.setState({showCreatePopup: true});
      }
    }
    closeTokenPopup(val) {
        this.setState({addpList: val,showCreatePopup: false});             
    }
    applyFeaturesAction(val){
        //console.log(val);
        this.setState({showCreatePopup: false}); 
        let mappedData=val;
       
         const rows = mappedData.map(item => {                    
                    item.textbox = this.paintTextboxForCapacity(item);
                    item.deleteIt =  <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
                    return item;
                });
        var total=0;
        var temptotalTokenCap=0;
        var catoken=parseFloat(this.state.usageData.availableCap);
        rows.forEach(element => {
         let el = document.getElementById(element.id);
          let featureInfo=element.mappedValue.split(":");
          let val='';
          if(el!==null && el!==undefined){
              val = el.value;
          }
          
          if(val===''){
          
          }else{
            if(ValidationUtility.isNumber(val)) {
              total=((parseFloat(parseFloat(featureInfo[0]/featureInfo[1]))*parseInt(val))+parseFloat(total));
            }else{
              ConfigurationUtils.showErrorMessage1("configpageAlert511", this.context.intl);
            }
          }
        });
      temptotalTokenCap=total;
      if(parseFloat(this.state.usageData.availableCap) > 0 ){
        if(parseFloat(this.state.usageData.availableCap)-parseFloat(total) > 0.0){
         catoken=parseFloat(this.state.usageData.availableCap)-parseFloat(total);
        total=0;
        }
        else{
        total=parseFloat(total)-parseFloat(this.state.usageData.availableCap);
        catoken=0;
        }
      }      
       this.setState({addpList: rows,rowData:[],expectingCap:total,currentAvailableToken:catoken,totalTokenCap:temptotalTokenCap});       
       ConfigurationUtils.hideLoadMask(undefined);
      
       
    }
    onClickDelete(key, event) {        
        let listData = this.state.addpList.filter(function( obj ) {
          return obj.id !== key;
        });        
        this.setState({addpList: listData}, () => {
          this.continueCapacitySaveAction(null, null);
        });
    }
    render() {
        //let intl = this.context.intl;  
        return (<div className="config-cont-backgroud"> <SectionWithTitle title={this.findLabel("page.title.token.calculator")}  helpTopic="Token_Calculator"/>
                       <section pad="none" wrap={false} responsive={false} justify="center">
                      <div className="flex_jc_fs action_filter_wrapper">
                               {/*<FormField id="usage_list_form_seletToken" style={{ width: '30em' }} label= {this.findLabel("usageReportSelTokenFid", intl)} >
                               <Select id="usage_list_seletToken" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                                 options={this.state.temptokenFeaturesList}
                                  value={this.state.selTokenFeature}
                                  onChange={this.onChangeTokenFeature} />
                                </FormField> */}
                                <Select id="usage_list_seletToken" placeholder={this.findLabel("configpageIpHostSelect")}
                                 options={this.state.temptokenFeaturesList}
                                  value={this.state.selTokenFeature}
                                  onChange={this.onChangeTokenFeature} 
                                  label= {this.findLabel("usageReportSelTokenFid")+": "}
                                />
                                <div className="button_wrap_primary">
                                 <Button primary={true} className="tokenPopUp" onClick={this.onClickOpenpop}>
                                  {this.findLabel("label.token.add.button")}                                          
                                  </Button>
                                  </div>
                      </div>
                    {  this.state.addpList.length > 0 ? 
                      <div className="border_white_backg"> 
                        <section pad="none" wrap={false} responsive={false} justify="center">
                              
                                 <div>
                                  <TableGrid id="usage_report_list_grid1"
                                    header={[
                                        {
                                            columnName: "productCode",
                                            headerLabel: this.findLabel("label.productCode"),
                                            width: "auto",
                                            columnKey: "id"                                           
                                        },
                                        {
                                            columnName: "productCurrentVersion",
                                            headerLabel: this.findLabel("productVersion"),
                                            href: false,
                                            width: "auto",
                                            
                                        },
                                        {
                                            columnName: "featureId",
                                            headerLabel: this.findLabel("label.grid.col.feature"),
                                            href: false,
                                            width: "40%"                                            
                                        },                                        
                                        {
                                            columnName: "mappedValue",
                                            headerLabel: this.findLabel("mappedValue"),
                                            href: false,
                                            width: "auto",
                                            infoIcon: true,
                                            infoIconText:this.findLabel("token.mapping.info")                                         
                                        } ,                                        
                                        {
                                            columnName: "textbox",
                                            headerLabel: this.findLabel("usage.search.capacity.label"),
                                            href: false,
                                            width: "auto",
                                            infoIcon: true,
                                            infoIconText:this.findLabel("token.capacity.info")                                          
                                        },                                        
                                        {
                                            columnName: "deleteIt",
                                            headerLabel: this.findLabel("resv.grid.header.pool.delete"),
                                            href: false,
                                            width: "6%",
                                            columnKey: "id",
                                            onClick: this.onClickDelete                                         
                                        }                                                                                  
                                    ]}
                                    rowData={this.state.addpList}
                                    rowKey="id"
                                    pagination={this.state.pagination1}
                                     noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.addpList.length})}
                                  />
                                </div>
                                
                               
                                
                                <div className="token_data_table">
                                  <div>
                                  <div className="displayTokentable">
                                    {LocaleUtility.getLocaleMessage("token.current.available.tokens")}
                                    </div>
                                  <div className="displayTokentable">
                                    {LocaleUtility.getLocaleMessage("token.total.to.allocations")}
                                  </div>
                                  <div className="displayTokentable">
                                    {LocaleUtility.getLocaleMessage("token.available.after.allocations")}
                                  </div>
                                  <div className="displayTokentable" style={{"border-right":"1px solid #ccc"}}>
                                    {LocaleUtility.getLocaleMessage("capacityNeedToBuy")} 
                                    </div>                                  
                                  </div>
                                 <div>
                                   <div className="displayTokentableValue">{localNumberformat(roundOfValue(this.state.usageData.availableCap))}</div>
                                   <div className="displayTokentableValue">{localNumberformat(roundOfValue(this.state.totalTokenCap))}</div>
                                   <div className="displayTokentableValue">{localNumberformat(roundOfValue(this.state.currentAvailableToken))}</div>
                                   <div className="displayTokentableValuelast">{localNumberformat(roundOfValue(this.state.expectingCap))}</div>
                                 </div>
                                 </div>
                                 <div className="button_left_wrap mar_top_10">
                                <div className="button_wrap_negative">                                  
                                  <Button id="usage_token_restBtn" label={this.findLabel("label.button.clean")} plain={false} primary={true} onClick={this.handleResetAction} />                                                                  
                                </div>
                                </div>
                              <div>
                                <SpinningNotification centerPage={true} />
                                </div>
                            
                        </section>
                </div> : null
                                  }
                        </section>
                        {this.state.showCreatePopup ? 
                        <AddFeatures 
                          showModal = {this.state.showCreatePopup}
                            cancelAction = {this.closeTokenPopup}
                            pdrowData={this.state.pdrowData}
                            pagination={this.state.pagination1}
                            applyAction={this.applyFeaturesAction}
                            addpList={this.state.addpList}
                            title={this.state.selTokenFeature.label}                      
                        /> : ""
                    }
                </div>);
    }
 
}

// TokenCal.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default TokenCal;