import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
//import { FormField } from "grommet/components/FormField";
import Select from "../../commons/APLSSelect";
import { Anchor } from 'grommet/components/Anchor';
import { Search as SearchIcon } from 'grommet-icons';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { Clock as ClockIcon } from 'grommet-icons';
import { getLocaleMessage }from "../../../utils/LocaleUtility";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { Button } from "grommet/components/Button";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportListHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: null,
            productNameList: [],
            productNameSelValue: "",
            isSearchEnable: false,
            isClockTampered: false
        }
        this.findLabel = this.findLabel.bind(this);
    }
    findLabel(key, intl) {
        return getLocaleMessage(key);
    }
    render() {
        //let intl = this.context.intl, 
        let rightSection;

        if(this.props.isClockTampered) {
            rightSection = <div className="floatRight">
                            {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.props.exportExcel}><DocumentExcelIcon/></Anchor>
                            <span style={{borderRight: "2px solid #ccc"}}></span> */}
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.props.exportExcel}><DocumentDownloadIcon id="usage-report-export-icon" colorIndex="brand"/></a>
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.clock.tampered")}><ClockIcon colorIndex="brand" /></a>
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.search")}><SearchIcon colorIndex="brand" onClick={this.props.onClickSearchIcon}/></a>
                        </div>
        } else {
            rightSection = <div className="floatRight">
                            {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.props.exportExcel}><DocumentExcelIcon/></Anchor>
                            <span style={{borderRight: "2px solid #ccc"}}></span> */}
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.props.exportExcel}><DocumentDownloadIcon id="usage-report-export-icon"  colorIndex="brand"/></a>
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.search")}><SearchIcon colorIndex="brand" onClick={this.props.onClickSearchIcon}/></a>
                        </div>
        }

        return(
            <div className="action_filter_wrapper">
                <div className="action_wrap_left button_left_wrap">                    
                    {/*<FormField id="usage_list_form_seletProdId" style={{ width: '21em' }} label= {this.findLabel("usageReportSelProduct", intl)} >
                        <Select id="usage_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                            options={this.props.productNameList}
                            value={this.props.selProduct}
                            onChange={this.props.selectProductName} />
                    </FormField>*/}
                    <div className="button_wrap_primary">
                        <Button id="usage_list_manageBtn" label={this.findLabel("usageListManageBtn")} plain={false} primary={true} onClick={this.props.handleManageCommuter} />
                    </div>
                    <Select id="usage_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect")}
                        options={this.props.productNameList}
                        value={this.props.selProduct}
                        onChange={this.props.selectProductName} 
                        label= {this.findLabel("usageReportSelProduct")+": "}
                        width={25}
                    />
                </div>
                <SectionWithTitle helpTopic="C_Lic_Usage_Report"/>
                <div className="flexBox">
                    {rightSection}
                </div>
            </div>
        )
    }
}
// UsageReportListHeader.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportListHeader;