import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher";
import "../../../css/App.css";
import "../../../css/index.css";
import Store from "../../../flux/APTableStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import { CheckBox } from "grommet/components/CheckBox";
import { TextInput } from 'grommet/components/TextInput';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class CTFeatureTenantMap extends Component {
     constructor(props) {
         super(props);
         this.state = {
                        rowData: [],
                        productNameSelected: undefined,
                        productNameSelectOptions: [],
                        dataForProduct:[],
                        selectedRowKeys: [],
                        featuresTenantsList:this.props.featuresTenantsList
         }
              this.handleTenantChange = this.handleTenantChange.bind(this);
              this.handleTenantTrim = this.handleTenantTrim.bind(this);
     }
     findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }

    handleTenantChange(index, e){
        if(this.props.assetConfig){
            let validAssetId = true;
            this.props.featuresTenantsList.forEach(tenant => {
                if(e.target.value && tenant.assetId === e.target.value){
                    validAssetId = false;
                    return;
                }
            });
            this.props.featuresTenantsList[index].assetId = e.target.value.trim();
            if(!validAssetId){
                ConfigurationUtils.showErrorMessage1("enter.unique.asset.ID", this.context.intl);
            }
        }else{
            if(e.target.value && isNaN(e.target.value)){
                let notify = document.createElement("span")
                notify.innerHTML = this.findLabel("only.numbers.allowed");
                notify.setAttribute("class", "errorNotification");
            }    
            this.props.featuresTenantsList[index].tenant= e.target.value.trim();
        }
    }
    handleAssetIdChange(index, e){
        this.props.featuresTenantsList[index].assetId= e.target.value.trim();
    }
    handleTenantTrim(index, e){
        e.target.value = e.target.value.trim();
    }
    render(){
        let tableData = !this.props.featuresTenantsList? {} : this.props.featuresTenantsList.map((item,index) => {
            let classname = (item.tenantError)? "installTabInput inputError" : "installTabInput";
            let columnName = this.props.tenantConfig ? 'editTenant' : 'editAssetId';
                  item[columnName]=<div className={classname}><TextInput style={{ width: '95%' }} type="text" className={classname} defaultValue={ this.props.tenantConfig ? item.tenant : item.assetId} /*disabled={isDisabled}*/
                    onChange= {event=>this.handleTenantChange(index, event)} onBlur= {event=>this.handleTenantTrim(index, event)} key={`${Math.floor((Math.random() * 1000))}-min`}
                  /><span>{LocaleUtility.getLocaleMessage("enter.the.tenant.ID")}</span></div>;
                  
                  //item.editAssetId=<TextInput style={{ width: '95%' }} type="text" defaultValue={ item.assetId} 
                //  onDOMChange= {event=>this.handleAssetIdChange(index,event)}
               //   />
            return item;
        });
        let headerData = [{
                            columnName: "featureDescription",
                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.feature"),
                            width: "70%",
                            columnKey: "featureId",
                            onClick: this.onColumnClicked,
                            sort: false
                        }];

        if(this.props.tenantConfig){
            headerData.push({
                        columnName: "editTenant",
                        headerLabel: LocaleUtility.getLocaleMessage("lable.tenant"),
                        href: false,
                        width: "30%",
                        paddingLeft: "5px",
                        sort: false
                    });
        }
        if(this.props.assetConfig){
            headerData.push({
                            columnName: "editAssetId",
                            headerLabel: LocaleUtility.getLocaleMessage("lable.affected.id"),
                            href: false,
                            width: "30%",
                            sort: false
                        });
        }

        const options = [];
        let selectedValue = {
            value: undefined,
            label: LocaleUtility.getLocaleMessage("label.select.all")
        };
        options.push(selectedValue);
    
        if(this.state.productNameSelectOptions !== undefined && this.state.productNameSelectOptions.size>0){
            var entries = this.state.productNameSelectOptions.entries();
            for (var item of entries) {
            options.push({ value: item[0], label: item[1] });
                if (
                    this.state.productNameSelected !== undefined &&
                    item[0] === this.state.productNameSelected
                ) {
                    selectedValue = { value: item[0], label: item[1] };
                }
            }
        }
        return(
                <div  >
                 <Form compact={false} id="cttenant_form_id2" pad="small" plain={true}>
                    <div>
                        <section pad="none" wrap={false} responsive={false} justify="center">
                            <Form compact={false} id="usage_list_formId2" pad="small" plain={true} style={{width:'70%'}}>
                                        <div>
                                    <TableGrid id="lic_view_grid"
                                        header={headerData}
                                        {...{keys:this.state.selectedRowKeys}}
                                        rowData={tableData}
                                        pagination={this.state.pagination}
                                        rowSelection={0}
                                        isExportRequired={false}
                                        // onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        onPagination={this.onPaginationClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        rowKey="featureId"
                                        onChangeCheckBoxCallback={this.onRowClicked}
                                        selectAllRows={false}
                                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                        "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                                    />
                                </div>
                            </Form>
                        </section>
                    </div>
                 </Form>
                </div>
        )
      }
    }
// CTFeatureTenantMap.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default CTFeatureTenantMap;