import React, {Component} from "react";
import "./css/Pagination.scss";
import * as LocaleUtility from "../../../utils/LocaleUtility";

class Pagination extends Component {
    constructor(props) {     
        super(props);
        
        this.updatePaginationState = this.updatePaginationState.bind(this);
        this.state = {    
            paginationData:{
              startRecordCount : 0,
              endRecordCount : 0,
              totalCount : 0,
              pageNumber : 0,
              pageSize : 0,
              isFirstpage : false,
              islastpage : false
            }
        }
    }

    componentDidMount() {    
        this.updatePaginationState();         
    }  
 
    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (prevProps.pageNumber !== this.props.pageNumber) {
            this.updatePaginationState();
        }
    }

    updatePaginationState() {

        let paginationDatatemp = this.state.paginationData;

        paginationDatatemp.isFirstpage = this.props.pageNumber === 1  ;
        paginationDatatemp.islastpage = this.props.pageNumber >= (this.props.totalCount/this.props.pageSize)  ;
       
        paginationDatatemp.startRecordCount = paginationDatatemp.isFirstpage ? 1 : (this.props.pageSize * (this.props.pageNumber-1))+1 ;

        paginationDatatemp.endRecordCount = this.props.pageNumber === 1 
            ? this.props.pageSize  
            : (this.props.pageSize * (this.props.pageNumber-1)) +this.props.pageSize; 
            if (paginationDatatemp.endRecordCount > this.props.totalCount) {
                paginationDatatemp.endRecordCount  = this.props.totalCount;
            }

        paginationDatatemp.pageNumber = this.props.pageNumber;

        paginationDatatemp.totalCount = this.props.totalCount ;
        paginationDatatemp.pageSize = this.props.pageSize ;
       
        this.setState({paginationData : paginationDatatemp});

    }

    
    
    render() {
        return(
            <div>
                <div className="pageList">
                {LocaleUtility.getDefualtString("Pagination.Showing")} {this.state.paginationData.startRecordCount}   
                {' '} to  {this.state.paginationData.endRecordCount}  
                   {' '}of   {this.state.paginationData.totalCount}  entries
                </div>
                <div className="pagination">
                
                    <a  onClick={() => this.state.paginationData.isFirstpage ? "" :this.props.doPagination(this.props.pageNumber-1, this.props.pageSize)} >&laquo;</a>
                    <a className="active" style={{cursor:'auto'}}>{this.props.pageNumber}</a>
                    <a onClick={() => this.state.paginationData.islastpage ? "" : this.props.doPagination(this.props.pageNumber+1, this.props.pageSize)}>&raquo;</a>
                </div>
            </div>);
    }
}
export default Pagination;