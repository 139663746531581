import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import Store from "../../../flux/APTableStore";
import ProductStore from "../../../flux/ProductStore";
import UserStore from "../../../flux/UserDetailStore";
import { Button } from "grommet/components/Button";

import { Anchor } from 'grommet/components/Anchor';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { Tabs } from "grommet/components/Tabs";
import { Tab } from "grommet/components/Tab";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import Dispatcher from "../../../flux/Dispatcher";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import CreateGroup from "./CreateGroup";
import SearchGroup from "./searchGroup";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Search as SearchIcon } from 'grommet-icons';
//import { Select } from "grommet/components/Select";
import UserSearch from "./UserSearch";
import ProductSearch from "./ProductSearch";
import {validateEmail, confirm} from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import "../../../css/index.css";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UserGroupManagement extends Component {

     constructor(props) {
      super(props);
      this.state = {
        count: 1, 
        secondCount: 1,
        rowData: [],
        userData: [],
        sortIndex: Store.getState().sortIndex,
        sortAscending: Store.getState().sortAscending,
        selectAllRows: false,
        userDetail: undefined,
        selectedRowKeys:[],
        session:{},
        pagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "groupCreatedTime",
            sortOrder: "ASC"
        },
        userPagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "",
            sortOrder: "DESC"
        },
        productsPagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "",
            sortOrder: "DESC"
        },
        showGroup:true,
        showUser:false,
        selectedValue:"",
        dropDownOptions:[],
        showCreateGroupModal:false,
        selectedGroupUsers: [],
        usersRowData: [],
        groupProductList: [],
        selectedGroupProducts: [],
        showSearchGroupModal:false
    }

    this.refreshState = this.refreshState.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
    this.handleGroupCancelButton = this.handleGroupCancelButton.bind(this);
    this.refreshProductItem = this.refreshProductItem.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.manageGroupDivClicked = this.manageGroupDivClicked.bind(this);
    this.manageUserDivClicked = this.manageUserDivClicked.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.privilegeChanged = this.privilegeChanged.bind(this);
    this.handleUpdateButton = this.handleUpdateButton.bind(this);
    this.showUserList = this.showUserList.bind(this);
    this.showProductList = this.showProductList.bind(this);
    this.handleSearchField = this.handleSearchField.bind(this);
    this.searchGroup = this.searchGroup.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
    this.closeUserSearchButton = this.closeUserSearchButton.bind(this);
    this.closeSearchButton = this.closeSearchButton.bind(this);
    this.handleAddButton = this.handleAddButton.bind(this);
    this.handleProdAddButton = this.handleProdAddButton.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.tabClicked = this.tabClicked.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.onGroupPaginationClicked = this.onGroupPaginationClicked.bind(this);
    this.removeUsersAction = this.removeUsersAction.bind(this);
    this.onUsersRowClicked = this.onUsersRowClicked.bind(this);
    this.onUsersPaginationClicked = this.onUsersPaginationClicked.bind(this);
    this.onProductPaginationClicked = this.onProductPaginationClicked.bind(this);
    this.onProductRowClicked = this.onProductRowClicked.bind(this);
    this.onConfirmDeleteAction = this.onConfirmDeleteAction.bind(this);
    this.onConfirmAddProduct = this.onConfirmAddProduct.bind(this);
    this.onConfirmRemoveProduct = this.onConfirmRemoveProduct.bind(this);
    this.onConfirmRemoveUser = this.onConfirmRemoveUser.bind(this);
    this.onConfirmUpdateAction = this.onConfirmUpdateAction.bind(this);
    this.onConfirmDeleteUser = this.onConfirmDeleteUser.bind(this);
    this.searchGroupModal = this.searchGroupModal.bind(this);
    this.searchGroupSubmit = this.searchGroupSubmit.bind(this);
    this.onLdapDeleteClicked = this.onLdapDeleteClicked.bind(this);
    this.onConfirmDeleteLdapAction = this.onConfirmDeleteLdapAction.bind(this);
}


refreshState() {
    var options=[];
    var uniqueOptions=[];
    Store.getState().tableData.map(item=>{
        item.roleList.forEach(element => {
                let value="";
                switch (element.toLowerCase()) {
                    case "administrator":
                        value = LocaleUtility.getLocaleMessage("user.group.userType.administrator");
                        break;
                    case "product admin":
                        value = LocaleUtility.getLocaleMessage("user.group.userType.productAdmin");
                        break;
                    case "view only":
                        value = LocaleUtility.getLocaleMessage("user.group.userType.viewOnly");
                        break;
                    default:
                        value=element;
                        break;
                }
                // 306115 : APLS>Manage Groups>Mange User Groups:  Duplicate entries (Options) getting added for Privilege dropdown.
                let index = uniqueOptions.indexOf(value)
                if (index === -1) {
                    options.push({value:element, label:value})
                    uniqueOptions.push(value);
                }
               //  options.push({value:element, label:value})
            });
            this.setState({
                dropDownOptions :options
            });
        if(item.groupName === Store.getState().groupName)
        {
            //item.roleList.map(value => options.push({value:value, label:value}));            
            let rowData = [];
            if(item.userList !== undefined) {
                item.userList.map((item, index) => {
                    rowData.push({userName: item});
                    return "";
                });
                let tempPagination = this.state.userPagination;
                if(tempPagination !== undefined) {
                    tempPagination.totalCount = item.userList.length;
                }
            }
            this.setState({
                selectedRowKeys: [item.groupName],
                groupName:item.groupName !== undefined ? item.groupName: '',
                groupDesc:item.groupDesc !== undefined ? item.groupDesc: '',
                groupEmail:item.groupEmail !== undefined ? item.groupEmail: '',
                oldroleName: item.roleName !== undefined ? item.roleName: '',
                selectedValue : item.roleName !== undefined ? item.roleName: '',
                userList:item.userList,
                usersRowData: rowData,
                selectedGroupUsers: []
            });

        }
        return "";
    });

    var data = FetchHelper.getSession(true);
    data.then(session => {
        this.setState({session: session});
    })
    .catch(error => {
        // handle error
    });
    let pList = Store.getState().productList;
    var pRows = [];
    if(pList !== undefined) {
        var entries = pList.entries();
        for(var item of entries){
            pRows.push({productKey: item[0], productDescription: item[1]})
        }
    }
    let pagination = Store.getState().grppagination;
    pagination.required = true;
    pagination.style = "default";
    this.setState({
        rowData: Store.getState().tableData,
        productList: Store.getState().productList,
        sortIndex: Store.getState().sortIndex,
        sortAscending: Store.getState().sortAscending,
        pagination: pagination,
        groupProductList: pRows,
        selectedGroupProducts: []
    });

        Dispatcher.dispatch({
            type: "LOAD_SPIN_DONE",
            payload: ''
        }); 
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.rowData !== this.state.rowData || this.state.session === undefined) {
            ConfigurationUtils.hideLoadMask(this.context.intl);
            this.setState({session: FetchHelper.getSession()});
        }
    }

    refreshProductItem() {

        var entries = ProductStore.getState().productNameSelectOptions.entries();
        var rows = [];
        for(var item of entries){
            rows.push({key:item[0], product:item[1]})
        }
        this.setState({
            productSearch: true,
            productData: rows,
        });
        Dispatcher.dispatch({
            type: "LOAD_SPIN_DONE",
            payload: ''
        }); 
    }

    exportExcel(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        FetchHelper.downloadUserOrGroupList("group", undefined, mimetype);
    }

    refreshUser() {

        if(UserStore.getState().userGrpData !== undefined && UserStore.getState().userGrpData.length > 0){
            this.setState({
                userSearch:true,
                userGrpData: UserStore.getState().userGrpData,
                sortIndex: UserStore.getState().sortIndex,
                sortAscending: UserStore.getState().sortAscending,
            });
        } else {
            if(this.state.userSearch){
                this.setState({
                    userSearch:false,
                });
                Dispatcher.dispatch({
                    type: "NO_USERS_ALERT_SHOW_TITLE_MODAL",
                    payload: {content: LocaleUtility.getLocaleMessage("um.nouserfound"), 
                                title: LocaleUtility.getLocaleMessage("um.assigngroups")}
                });
            }
        }

        Dispatcher.dispatch({
            type: "LOAD_SPIN_DONE",
            payload: ''
        }); 
    }

    componentDidMount() {
      let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
      Dispatcher.dispatch({
          type: loadSpinAction,
          payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
      });
      let request = {
          "pagenation": this.state.pagination
      }
      FetchHelper.getAllGroups(request);
      Store.on("change", this.refreshState);
      UserStore.on("change", this.refreshUser);
      ProductStore.on("change", this.refreshProductItem);
    }

    onGroupPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({pagination: paginationTemp});
        let request = {
            "pagenation": paginationTemp
        }
        FetchHelper.getAllGroups(request);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.refreshState);
        UserStore.removeListener("change", this.refreshUser);
        ProductStore.removeListener("change", this.refreshProductItem);
    }

    onConfirmDeleteAction(flag, key){
        if(flag === 1){
            let loadSpinAction = "DELETING_USERGROUP_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            let request = {
                "groupName": key,
                "pagenation": this.state.pagination
            }
            FetchHelper.deleteUserGroup(request);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    onConfirmDeleteLdapAction(flag, key){
        if(flag === 1){
            let loadSpinAction = "DELETING_USERGROUP_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            let request = {
                "groupName": key,
                "pagenation": this.state.pagination
            }
            FetchHelper.deleteLdapUserGroup(request);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    onDeleteClicked(key, event){
        confirm(LocaleUtility.getLocaleMessage("um.alert10", {group: key}), this.onConfirmDeleteAction, key);
    }

    onLdapDeleteClicked(key, event){
        confirm(LocaleUtility.getLocaleMessage("um.alert10", {group: key}), this.onConfirmDeleteLdapAction, key);
    }

    handleSubmitButton(data){
        this.setState({
            createGroup: false,
            showCreateGroupModal:false
        })
        Dispatcher.dispatch({
            type: "GROUP_CREATE_LOADING_SPIN",
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        data["pagenation"] = this.state.pagination;
        FetchHelper.createGroupUser(data, this.context.intl);
    }

    searchGroupSubmit(data){
        this.setState({
            createGroup: false,
            showCreateGroupModal:false
        })
        Dispatcher.dispatch({
            type: "GROUP_CREATE_LOADING_SPIN",
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        FetchHelper.searchLdapGroup(data, this.context.intl);
    }

    handleGroupCancelButton(){
        this.setState({
            createGroup: false,
            showCreateGroupModal:false,
            showSearchGroupModal:false,
            errorcode: []
        })
    }

    createGroup() {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        if(this.state.session.mail !== undefined && this.state.session.mail ){
            this.setState({   createGroup:true, showCreateGroupModal:true   });
        } else {
            var payload = {
                title: LocaleUtility.getLocaleMessage("um.creategrouptitle"),
                content: LocaleUtility.getLocaleMessage("um.createGroupmailnotconfiguredmessage"),
            }
             Dispatcher.dispatch({
                type: "MAIL_SERVER_NOT_CONF_ALERT_SHOW_TITLE_MODAL",
                payload: payload
            });
        }
    }

    manageGroupDivClicked(){
        this.setState({
            showGroup:true,
            showUser:false
        });
    }

    manageUserDivClicked(){
        this.setState({
            showGroup:false,
            showUser:true,
            searchValue: undefined
        });
    }

    onRowClicked(keys, event){
        const options = []; 
        if(this.state.rowData[0] !== undefined &&
            this.state.rowData[0].roleList !== undefined){
        
            this.state.rowData.map(item=>{
                if(item.groupName === keys[keys.length-1])
                {
                    item.roleList.map(value => options.push({value:value, label:value}));
                    let usersRowData = [];
                    if(item.userList !== undefined) {
                        item.userList.map((item, index) => {
                            usersRowData.push({userName: item});
                            return "";
                        });
                        let tempPagination = this.state.userPagination;
                        if(tempPagination !== undefined) {
                            tempPagination.totalCount = item.userList.length;
                        }
                    }
                    this.setState({
                        selectedRowKeys: keys,
                        groupName:item.groupName !== undefined ? item.groupName: '',
                        groupDesc:item.groupDesc !== undefined ? item.groupDesc: '',
                        groupEmail:item.groupEmail !== undefined ? item.groupEmail: '',
                        oldroleName: item.roleName !== undefined ? item.roleName: '',
                        selectedValue : item.roleName !== undefined ? item.roleName: '',
                        dropDownOptions :options,
                        //status:item.status !== undefined ? item.status: '',
                        userList:item.userList,
                        usersRowData: usersRowData
                    })
                    FetchHelper.getProductGroups(item.groupName);
                }
                return "";  
            });
            this.setState({errorcode: []});
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            }); 
        }
    }
    
    tabClicked(index, event = null) {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        if (index === 0) {
            this.manageGroupDivClicked();
            this.setState({ activeIndex: index });
        } else if (index === 1) {
            //if(this.state.session.dbType !== undefined && this.state.session.dbType === 'DB'){
                this.setState({ activeIndex: index });
                this.manageUserDivClicked();
            //}
        }
    }

    handleDescChange(e) {
        this.setState({ groupDesc: e.target.value });
    }

    handleEmailChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('email');
        if (event.target.value === ''){
            if (index === -1)
                errors.push('email');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }

            index = errors.indexOf('invalidLength')
            if(event.target.name === 'email' && 
                    !(event.target.value.length > 5 &&  event.target.value.length < 64)){
                if (index === -1) {
                    errors.push('invalidLength');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            index = errors.indexOf('invalidEmail')
            if(event.target.name === 'email' && 
                !validateEmail(event.target.value) ){
                if (index === -1) {
                    errors.push('invalidEmail');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
        }

        this.setState({errorcode: errors});
        this.setState({ groupEmail: event.target.value });
    }

    privilegeChanged(e){
         this.setState({ selectedValue: e.currentTarget.value });
    }

    handleCancelButton(){
        var options=[];
        this.state.rowData.map(item=>{
            if(item.groupName === this.state.groupName){
                item.roleList.map(value => options.push({value:value, label:value}));
                let usersRowData = [];
                if(item.userList !== undefined) {
                    item.userList.map((item, index) => {
                        usersRowData.push({userName: item});
                        return "";
                    });
                    let tempPagination = this.state.userPagination;
                    if(tempPagination !== undefined) {
                        tempPagination.totalCount = item.userList.length;
                    }
                }
                this.setState({
                    selectedRowKeys: [item.groupName],
                    groupName:item.groupName !== undefined ? item.groupName: '',
                    groupDesc:item.groupDesc !== undefined ? item.groupDesc: '',
                    groupEmail:item.groupEmail !== undefined ? item.groupEmail: '',
                    oldroleName: item.roleName !== undefined ? item.roleName: '',
                    selectedValue : item.roleName !== undefined ? item.roleName: '',
                    // dropDownOptions :options,
                    userList:item.userList,
                    usersRowData: usersRowData
                })
            }
            return "";
        });
        this.setState({errorcode: []});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    onConfirmUpdateAction(flag){
        if(flag === 1){
            const updatePayload = {
                groupName:this.state.groupName,
                groupDesc:this.state.groupDesc,
                groupEmail:this.state.groupEmail,
                roleList: [this.state.oldroleName],
                roleName : this.state.selectedValue,
                "pagenation": this.state.pagination
            };

            FetchHelper.updateGroup(updatePayload);
        }
    }

    handleUpdateButton(event){
        event.preventDefault();
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(errors.length > 0){
            flag = false;
        }
        if(flag === true){
            confirm(LocaleUtility.getLocaleMessage("um.alert12", {group: this.state.groupName}), this.onConfirmUpdateAction);
        } else {
            Dispatcher.dispatch({
                type: "GRP_UPDATE_ERROR",
                payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
            });
            this.setState({errorcode: errors});
        }
    }

    onConfirmRemoveUser(flag){
        if(flag === 1){
            let loadSpinAction = "DELETING_USER_GROUP_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            const payload={
                groupName : this.state.groupName,
                userNames : this.state.selectedGroupUsers,
                "pagenation": this.state.pagination
            }
            FetchHelper.removeUserFromGroup(payload, this.context.intl);
        }
    }

    removeUsersAction() {
        if(this.state.selectedGroupUsers !== undefined && this.state.selectedGroupUsers.length > 0) {
            confirm(LocaleUtility.getLocaleMessage("disassociate.warning.message"), this.onConfirmRemoveUser);
        } else {
            ConfigurationUtils.showErrorMessage1("please.select.record", this.context.intl);
        }
        
    }

    onConfirmRemoveProduct(flag){
        if(flag){
            let loadSpinAction = "DELETING_PRODUCT_GROUP_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });

            const payload={
                groupName : this.state.groupName,
                productList : this.state.selectedGroupProducts
            }
            FetchHelper.removeProductFromGroup(payload);
        }
    }

    removeProductAction(key, event){
        if(this.state.selectedGroupProducts !== undefined && this.state.selectedGroupProducts.length > 0) {
            confirm(LocaleUtility.getLocaleMessage("disassociate.warning.message"), this.onConfirmRemoveProduct);
        } else {
            ConfigurationUtils.showErrorMessage1("please.select.record", this.context.intl);
        }
    }
    onUsersRowClicked(keys, event) {
        this.setState({selectedGroupUsers: keys});
    }
    onProductRowClicked(keys, event) {
        this.setState({selectedGroupProducts: keys});
    }
    onUsersPaginationClicked(pageNumber, pageSize) {
        let paginationTemp = this.state.userPagination;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ userPagination: paginationTemp });
    }
    onProductPaginationClicked(pageNumber, pageSize) {
        let paginationTemp = this.state.productsPagination;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ productsPagination: paginationTemp });
    }
    showUserList() {
        let rowData = [];
        let paginationObj = this.state.userPagination;
        let pageNumber = 1;
        let pageSize = 10;
        let usersRowData = this.state.usersRowData;
        if(usersRowData !== undefined && paginationObj !== undefined) {
            paginationObj.totalCount = usersRowData.length;
        }
        if(paginationObj !== undefined) {
            pageNumber = paginationObj.pageNumber;
            pageSize = paginationObj.pageSize;
        }
        
        if(usersRowData !== undefined && usersRowData.length > pageSize) {
            rowData = usersRowData.slice((pageNumber-1)*pageSize, (pageSize*pageNumber));
        } else {
            rowData = usersRowData;
        }
        if (rowData!== undefined && rowData.length > 0) {
            return (
                <div id="umUserList">
                    <div style={{width: "100%"}}>
                        <TableGrid id="groups-user-list"
                            header={[
                                {
                                    columnName: "userName",
                                    headerLabel: LocaleUtility.getLocaleMessage("label.form.text.groupname"),
                                    href: false,
                                    width: "auto",
                                    columnKey: "userName"
                                }
                            ]}
                            {...{keys:this.state.selectedGroupUsers}}
                            rowData={rowData}
                            pagination={paginationObj}
                            rowSelection={3}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            onPagination={this.onUsersPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="userName"
                            onChangeCheckBoxCallback={this.onUsersRowClicked}
                            selectAllRows={false}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: rowData.length})}
                        />
                    </div>
                </div>
            )
        } else {
            return LocaleUtility.getLocaleMessage("um.nouseringroup");
        }
    }

    showProductList() {
        let pRowData = [];
        let paginationObj = this.state.productsPagination;
        let pageNumber = 1;
        let pageSize = 10;
        let totalCount = 0;
        let productsRowData = this.state.groupProductList;
        if(productsRowData !== undefined && paginationObj !== undefined) {
            paginationObj.totalCount = productsRowData.length;
        }
        if(paginationObj !== undefined) {
            pageNumber = paginationObj.pageNumber;
            pageSize = paginationObj.pageSize;
            totalCount = paginationObj.totalCount;
        }
        
        if(productsRowData !== undefined && productsRowData.length > pageSize) {
            pRowData = productsRowData.slice((pageNumber-1)*pageSize, (pageSize*pageNumber));
        } else {
            pRowData = productsRowData;
        }
        //if (pRowData !== undefined && pRowData.size > 0) {
            return (
                <div id="umProductList">
                    <div>
                        <TableGrid id="groups-product-list"
                            header={[
                                {
                                    columnName: "productDescription",
                                    headerLabel: LocaleUtility.getLocaleMessage("usage.search.product.label"),
                                    href: false,
                                    width: "auto",
                                    columnKey: "productKey"
                                }
                            ]}
                            {...{keys:this.state.selectedGroupProducts}}
                            rowData={pRowData}
                            pagination={paginationObj}
                            rowSelection={3}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            onPagination={this.onProductPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="productKey"
                            onChangeCheckBoxCallback={this.onProductRowClicked}
                            selectAllRows={false}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: pRowData.length})}
                        />
                    </div>
                </div>
            )
        //}
    }

    handleSearchField(e) {
        this.setState({ searchValue: e.target.value });
    }

    searchGroup(){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({ userSearch:true });
    
        const groupPayload={
            firstName : this.state.searchValue,
            groupName : this.state.selectedRowKeys
        }

        FetchHelper.searchUser(groupPayload); 
    }

    searchProduct(){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        FetchHelper.getProducts("user_group", true);
    }

    modalUserSearch() {
   
        return (
            <UserSearch {...this.state}
                    title={LocaleUtility.getLocaleMessage("um.assigngroups")}
                    userSearch={this.state.userSearch}
                    closeUserSearchButton={this.closeUserSearchButton}
                    handleAddButton={this.handleAddButton}

            />
        );
    }

    modalProductSearch() {
   
        return (
            <ProductSearch {...this.state}
                    title={LocaleUtility.getLocaleMessage("um.assignproducts")}
                    userSearch={this.state.productSearch}
                    closeSearchButton={this.closeSearchButton}
                    handleAddButton={this.handleProdAddButton}

            />
        );
    }

    closeUserSearchButton(){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({
            userSearch: false
        });
    }
    handleAddButton(data){
        this.setState({
            userSearch: false
        });
        data["pagenation"] = this.state.pagination;
        FetchHelper.addUserToGroup(data);
        this.setState({searchValue: ""});
    }

    closeSearchButton(){
        this.setState({
            productSearch: false
        });
    }

    onConfirmAddProduct(flag, data){
        if(flag === 1){
            data["pagenation"] = this.state.pagination;
            FetchHelper.addProductToGroup(data);
        }
    }

    handleProdAddButton(data){
        confirm(LocaleUtility.getLocaleMessage("um.alert13", {group: data.groupName}), this.onConfirmAddProduct, data)
        this.setState({
            productSearch: false
        });
    }

    removeProduct(key, event){
        let loadSpinAction = "DELETING_PRODUCT_GROUP_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });

        const payload={
            groupName : this.state.groupName,
            productList : [key]
        }
        FetchHelper.removeProductFromGroup(payload);
    }

    onConfirmDeleteUser(flag, key){
        if(flag === 1){
            let loadSpinAction = "DELETING_USER_GROUP_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });

            const payload={
                groupName : this.state.groupName,
                userName : key,
                "pagenation": this.state.pagination
            }
            FetchHelper.removeUserFromGroup(payload, this.context.intl);
        }
    }

    removeUser(key, event){
        confirm(LocaleUtility.getLocaleMessage("um.alert1", {user: key, group: this.state.groupName}),
        this.onConfirmDeleteUser, key);
    }
    searchGroupModal(){         
        this.setState({showSearchGroupModal:true});
    }

    sectionContent() {

    const showHideGroup = this.state.showGroup ? "display-block" : "display-none";
    const showHideUser = this.state.showUser ? "display-block" : "display-none";
    
    let header=[{
                    columnName: "groupName",
                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.groupname"),
                    width: "auto",
                    columnKey: "groupName",
                },
                {
                    columnName: "groupDesc",
                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.groupdesc"),
                    href: false,
                    width: "auto",
                },
                {
                    columnName: "groupEmail",
                    headerLabel: LocaleUtility.getLocaleMessage("label.form.text.groupemail"),
                    href: false,
                    width: "auto",
                },
                {
                    columnName: "groupCreatedTime",
                    headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.createdtime"),
                    href: false,
                    width: "auto",
                }];
    if(this.state.session.dbType === 'DB' || this.state.session.dbType === 'DBLDAP' ){
        header.push({
            columnName: "delete",
            headerLabel: LocaleUtility.getLocaleMessage("resv.grid.header.pool.delete"),
            columnKey: "groupName",
            href: false,
            width: "5%",
            textAlign: "center",
            onClick: this.onDeleteClicked
        });
    }
    if(this.state.session.dbType === 'LDAP'){
        header.push({
            columnName: "delete",
            headerLabel: LocaleUtility.getLocaleMessage("resv.grid.header.pool.delete"),
            columnKey: "groupName",
            href: false,
            width: "5%",
            textAlign: "center",
            onClick: this.onLdapDeleteClicked
        });
    }
    let emailFormFieldError, emailError;

        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('invalidLength') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert5")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidEmail') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert11")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            emailFormFieldError = '';
            emailError = '';
        }

    let tab1Active = "tabActive primary", tab2Active="tabInActive";
    if(this.state.activeIndex === 1){
        tab1Active = "tabInActive"; tab2Active="tabActive primary";
    }
    let button;
    if (this.state.session.dbType !== undefined && (this.state.session.dbType === 'DB' || this.state.session.dbType === 'DBLDAP')) {
        button = <div id="umAddUserGroup" className="button_wrap_primary">
                    <Button label={LocaleUtility.getLocaleMessage("label.button.creategroup")} plain={false} primary={true} onClick={this.createGroup.bind(this)} style={{ float: 'right', whiteSpace: "nowrap" }}/>
                </div>;
        } else if(this.state.session.dbType !== undefined && this.state.session.dbType === 'LDAP'){
        button = <div id="umAddUserGroup" className="button_wrap_primary">
            <Button label={"Search Group"} plain={false} primary={true} onClick={this.searchGroupModal.bind(this)} style={{ float: 'right', whiteSpace: "nowrap"  }}/>
        </div>;
        }
    return (
      <section pad="none" wrap={false} responsive={false} justify="center"> 
      <div className="page_header_wrap border_white_backg_shadow">
            <div className="page_header_item">
                <SectionWithTitle title={LocaleUtility.getLocaleMessage("label.manage.user.groups")} helpTopic="UsersGroup_Management"
                        setionWidth = "100"
                    />
            </div>
            <div className="button_right_wrap" style={{marginTop:"0.9em"}}>
                {button}
            </div>
                {this.state.rowData.length > 0 ? 
                <div className="page_header_item"> 
                <div>
                    {/* <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcel}><DocumentExcelIcon/></a>
                    <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                    <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcel}><DocumentDownloadIcon id="user-group-export-icon" colorIndex="brand"/></a>
                </div>
            </div>: null}
      </div>
       
                <div id="umUserGroupTableGrid">
                    <TableGrid
                        header={header}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={1}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onGroupPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="groupName"
                        defaultSelectIds={this.state.selectedRowKeys}
                        onChangeCheckBoxCallback={this.onRowClicked.bind(this)}
                        selectAllRows={false}
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                    </div>
                {this.state.rowData.length > 0 && (
                <div class="bodyBackGroudColor" style={{border:"1px solid rgb(204, 204, 204)"}}>
                    <div id="umUserGroupNameDiv">
                            <div className="userLabelDiv userBackground"><label>{LocaleUtility.getLocaleMessage("label.form.text.usergroup")}</label></div>                
                            <div className="userInputDiv userBackground">:&nbsp;<label>{this.state.groupName}</label></div>
                    </div>
                    <div id="user-groud-details-tab">
                        <Tabs justify='start' 
                            activeIndex={this.state.activeIndex}
                            id="tabs"
                            onActive={this.tabClicked} >
                        <Tab title={LocaleUtility.getLocaleMessage("label.manage.user.groups")}
                            className={tab1Active}>
                        </Tab>
                            <Tab title={LocaleUtility.getLocaleMessage("label.manage.members")}
                                className={tab2Active}>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="user-details-div border_white_backg_shadow" style={{display:"block", float:"left", width:"99%"}}>
                        <div className={showHideGroup}>
                                <div  className="updateUserDiv">
                                <section pad="none" wrap={false} responsive={false} justify="center">
                                        <Form id="grp_update_form" compact={false} pad="small" plain={true} onSubmit={this.handleUpdateButton} >
                                            <FormField style={{ width: '29em' }} label={LocaleUtility.getLocaleMessage("label.form.text.privilege")} /*className={capacityFormFieldError}*/>
                                                {/*<Select id="adminPrivilege" options={this.state.dropDownOptions} value={this.state.selectedValue} onChange={this.privilegeChanged}/>*/}
                                                <select id="adminPrivilege" onChange={this.privilegeChanged} value={this.state.selectedValue} className="customSelect">
                                                    {this.state.dropDownOptions.map((item, key) => 
                                                    <option key={key} value={item.value}>{item.label}</option>)}
                                                </select>
                                            </FormField>
                                        
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("resv.create.pool.desc.label")}>
                                                {this.state.session.dbType === 'DBLDAP' && (
                                                    <TextInput id='description' value={this.state.groupDesc} name='description' disabled={true} readOnly="readonly" />
                                                )}
                                                {this.state.session.dbType !== 'DBLDAP' && (
                                                    <TextInput id='description' value={this.state.groupDesc} name='description' onChange={event =>this.handleDescChange(event)} />
                                                )}
                                            </FormField>
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.groupemail")} className={emailFormFieldError}>
                                                {this.state.session.dbType === 'DBLDAP' && (
                                                    <TextInput id='email' value={this.state.groupEmail} name='email' disabled={true} readOnly="readonly"/>
                                                )}
                                                {this.state.session.dbType !== 'DBLDAP' && (
                                                    <TextInput id='email' value={this.state.groupEmail} name='email' onChange={event =>this.handleEmailChange(event)}/>
                                                )}
                                                {emailError}
                                            </FormField>
                                            
                                            <div style={{ width: '30em', padding: '24px 24px'}}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="button_left_wrap">
                                                                    <div className="button_wrap_primary">
                                                                        <Button id="grp_form_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.submit")}
                                                                            type="submit" plain={false} primary={true}
                                                                        />
                                                                    </div>
                                                                    <div className="button_wrap_negative">
                                                                        <Button id="grp_form_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    
                                    </section>
                                </div>
                                <div style={{minHeight:"200px", width: "50%", float: "right", margin: "5px"}}>
                                    <div style={{marginLeft: "9px", marginRight: "5px"}}>
                                        {this.state.oldroleName === 'View Only' && (
                                            <span>{LocaleUtility.getLocaleMessage("um.fullviewrolemsg")}</span>
                                        )}
                                        {this.state.oldroleName === 'Product Admin' && (
                                            <div style={{paddingLeft: "9px"}}>
                                                <span>
                                                    <p style={{marginBottom: "20px", marginTop: "0px", maxWidth: "100%"}}>{LocaleUtility.getLocaleMessage("um.productadminmsg")}<br></br>
                                                    {LocaleUtility.getLocaleMessage("um.productadminmsg1")}</p>
                                                </span>
                                                <span style={{float:"left"}}>
                                                    <label>{LocaleUtility.getLocaleMessage("um.associatedproducts")}</label> 
                                                </span>
                                                <span style={{marginLeft: "25px", float:"left"}}>			
                                                    <Anchor id="grp_prod_search"><SearchIcon colorIndex="brand" onClick={this.searchProduct}></SearchIcon></Anchor>
                                                </span>
                                                <span style={{marginLeft: "6px", float:"left"}}>	
                                                    <div className="button_wrap_primary">	
                                                        <Button id="delete-group-products-btn" label={LocaleUtility.getLocaleMessage("label.disassociate.button")} plain={false} primary={true} onClick={event=>this.removeProductAction()} />
                                                    </div>	
                                                </span>
                                                {this.showProductList()}
                                            </div>
                                        )}
                                    </div>
                                    
                                </div>
                        </div>

                        <div className={showHideUser}>
                                <div style={{minHeight:"200px", width: "100%", float: "left", margin: "5px"}}>
                                    <div style={{marginLeft: "5px", marginTop: "5px", float:"left", width:"inherit"}}>
                                        <span style={{float:"inherit"}}>
                                            <label >{LocaleUtility.getLocaleMessage("um.associatedusers")}</label> 
                                        </span>
                                        <span id="search-span-id" style={{marginLeft: "25px", float:"inherit"}}>				
                                            <TextInput placeholder={LocaleUtility.getLocaleMessage("label.search.user")} id='userSearchInput' 
                                            value={this.state.searchValue} name='firstName' onChange={event =>this.handleSearchField(event)} />
                                        </span>
                                        <span style={{float:"inherit"}}>
                                            <Anchor id="grp_user_search"><SearchIcon colorIndex="brand" onClick={this.searchGroup}></SearchIcon></Anchor>
                                        </span>
                                        <span style={{marginLeft: "10px", float:"inherit"}}>
                                            {(this.state.session.dbType === 'DB' || this.state.session.dbType === 'DBLDAP' || this.state.session.dbType === 'LDAP') &&(
                                                <div className="button_wrap_primary">
                                                    <Button id="" label={LocaleUtility.getLocaleMessage("label.disassociate.button")} plain={false} primary={true} onClick={event=>this.removeUsersAction()} />
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                    <div style={{marginLeft: "5px", marginRight: "5px", width:"50%"}} className="panel-body"> 
                                        {this.showUserList()}
                                    </div>
                                </div>
                        </div>
                   </div>
                   </div>
                )}
    </section>
    );

  }

  render() {
    return (
      <div className="config-cont-backgroud">
        {this.sectionContent()}
        {this.state.showCreateGroupModal && <CreateGroup title={LocaleUtility.getLocaleMessage("um.creategrouptitle")}
                createGroup={this.state.createGroup}
                handleSubmitButton={this.handleSubmitButton}
                handleGroupCancelButton={this.handleGroupCancelButton}
                showCreateGroupModal={this.state.showCreateGroupModal}
               
        />}
        {this.state.showSearchGroupModal && <SearchGroup title={LocaleUtility.getLocaleMessage("search.group")}
                createGroup={this.state.createGroup}
                handleSubmitButton={this.searchGroupSubmit}
                handleGroupCancelButton={this.handleGroupCancelButton}
                showSearchGroupModal={this.state.showSearchGroupModal}
                intlContext ={this.context.intl}               
        />}
        {this.modalUserSearch()}
        {this.modalProductSearch()}
        <SpinningNotification centerPage={true} />
      </div>

    );
  }
}
// UserGroupManagement.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UserGroupManagement;