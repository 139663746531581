import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import SectionWithTitle from "../../generic/SectionWithTitle";
import store from "../../../flux/MultiTenantStore";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import ConfigEntriesStore from "../../../flux/ConfigEntriesStore";
import { CheckBox } from "grommet/components/CheckBox";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class ConfigEntries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            modifiedObjects: []
        }
        this.findDate = this.findData.bind(this);
        this.loadGridData = this.loadGridData.bind(this);
        this.changeKeyValue = this.changeKeyValue.bind(this);
        this.submitChanges = this.submitChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.refreshFormData = this.refreshFormData.bind(this);
    }
    componentDidMount() {
        store.on("change", this.loadGridData);
        ConfigEntriesStore.on("change", this.refreshFormData);
        this.findData();
    }
    componentWillUnmount() {
        store.removeListener("change", this.loadGridData);
        ConfigEntriesStore.removeListener("change", this.refreshFormData);
    }

    refreshFormData() {
        this.setState({ modifiedObjects: [] });
    }

    changeKeyValue(event, obj) {
        let value = event.target.value;
        if ("com.hp.autopassj.ls.auidts.enabled" === obj.entryKey) {
            value = obj.entryValue === "1" ? "0" : "1";
        } else {
            value = event.target.value;
        }
        let modifiedObjs = this.state.modifiedObjects;
        obj.entryValue = value;
        let isMatch = false;
        for (let mobj of modifiedObjs) {
            if(mobj.entryKey === obj.entryKey){
                mobj = obj;
                isMatch = true;
            }
        }
        if(!isMatch){
            modifiedObjs.push(obj);
        }
        this.setState({modifiedObjects: modifiedObjs});
    }

    submitChanges() {
        if(this.state.modifiedObjects !== undefined && this.state.modifiedObjects.length > 0) {
            let flag = false;
            this.state.modifiedObjects.map(obj => {
                let value = obj.entryValue;
                let type = obj.entryType;
                if(value === undefined || value === null || value === '') {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("value.cannot.be.empty");
                } else if(type === 1 && value !== 'true' && value !== 'false') {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.true");
                } else if(type === 2 && isNaN(value)) {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.numeric");
                } else if(type === 2 && Number(value) < 0) {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.negative");
                } else if(type === 2 && Number(value) === 0) {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.should.not.zero");
                } 
                else if(type === 2 && value.toString().indexOf(".")>=0) {
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("configpageAlert511");
                }
                else if(("com.hp.autopassj.ls.reports.exportrecordsize" === obj.entryKey || "feature.usage.max.limit.value" === obj.entryKey)
                    && Number(value) > 100000) {
                        flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.cannot.be.more", {maxValue: 100000});
                } else if("account.lock.period.in.minutes" === obj.entryKey 
                    && Number(value) > 60) {
                        flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.cannot.be.more", {maxValue: 60});
                } else if(("password.retry.count.limit" === obj.entryKey || "forgot.password.retry.count.limit" === obj.entryKey)
                    && Number(value) > 10) {
                        flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.cannot.be.more", {maxValue: 10});
                }else if(("server.auth.token.expiry.in.days" === obj.entryKey) && Number(value) > 90) {
                        flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.cannot.be.more", {maxValue: 90});
                } else if(("password.link.expired.period.in.minutes" === obj.entryKey)
                    && Number(value) > 360) {
                        flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.cannot.be.more", {maxValue: 360});
                }else if(("server.session.expiry.time" === obj.entryKey) && (Number(value) < 5 || Number(value) > 20)){
                 
                    flag = true;
                    document.getElementById("error-"+obj.id).innerHTML = LocaleUtility.getLocaleMessage("app.config.value.between");
                }
                if(flag){
                     document.getElementById("error-"+obj.id).style = "float: inherit";
                } else{
                    document.getElementById("error-"+obj.id).style = "float: inheritfloat: inherit; display: none;";
                }
                return "";
            });
            if(!flag) {
                this.clearErrors();
                FetchHelper.submitConfigEntries(this.state.modifiedObjects, false, false, this.context.intl);
            } else {
                ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
            }
        }
    }
    clearErrors() {
        this.state.rowData.map(obj => {
            document.getElementById("error-"+obj.id).innerHTML = '';
            return "";
        });
    }
    cancelChanges() {
        this.setState({rowData: [], modifiedObjects: []});
        this.findData();
    }
    findData() {
        let request = {};
        FetchHelper.listConfigEntries(request, this.context.intl);
    }
    loadGridData() {
        if(store.getState().actionType === 'configEntrylist') {
            let gData = store.getState().entryData;
            gData.map(obj => {
                if ("com.hp.autopassj.ls.auidts.enabled" === obj.entryKey) {
                    obj.entryValueInput = (
                        <span>
                        <span>
                            <CheckBox
                            id={"com.hp.autopassj.ls.auidts.enabled"}
                            checked={obj.entryValue === "1" ? true : false}
                            onChange={(event) => this.changeKeyValue(event, obj)}
                            />
                        </span>
                        <span
                            id={"error-" + obj.id}
                            style={{ float: "inherit" }}
                            className="grommetux-form-field__error"
                        ></span>
                        </span>
                    );
                 } else {
                    obj.entryValueInput = <span>
                        <span><TextInput className="configEntryEdit" id={"key-id-"+obj.id} defaultValue={obj.entryValue} onChange={event=>this.changeKeyValue(event, obj)} /></span>
                        <span id={"error-"+obj.id} style={{float: "inherit", display:"none"}} className="grommetux-form-field__error"></span>
                    </span>
                 }
                return "";
            });
            this.setState({rowData: gData});
        }
    }
    render() {
        return(<div className="config-cont-backgroud">
            <SectionWithTitle title="" helpTopic="Application"/>
            <section pad="none" wrap={false} responsive={false} justify="center">
                <div className="border_white_backg_shadow">
                    <div id="config_entry_div_id">
                        <TableGrid id="config_entry_gird"
                            header={[
                                {
                                    columnName: "entryKeyLabel",
                                    headerLabel: LocaleUtility.getLocaleMessage("config.entry.key.label") ,
                                    href: false,
                                    width: "30%"
                                },
                                {
                                    columnName: "entryValueInput",
                                    headerLabel: LocaleUtility.getLocaleMessage("config.entry.value.label") ,
                                    href: false,
                                    width: "auto"
                                }
                            ]}
                            rowData={this.state.rowData}
                            pagination={this.state.pagination}
                            rowSelection={0}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="id"
                            onChangeCheckBoxCallback={this.onRowClicked}
                            noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                    "label.grid.item.count.message", {count: this.state.rowData.length})}
                        />
                    </div>
                    <div className="saveBtnStyle button_left_wrap">
                        <div className="button_wrap_primary">
                        <Button id="conf_entry_saveBtn" label={LocaleUtility.getLocaleMessage("label.button.save")} plain={false} primary={true} onClick={this.submitChanges} />
                        </div>
                        <div className="button_wrap_negative">
                        <Button id="conf_entry_cancelBtn" plain={false} secondary={true} label={LocaleUtility.getLocaleMessage("label.button.cancel")} primary={false} onClick={this.cancelChanges} />
                    </div></div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </div>
            </section>
            </div>
        )
    }
}
// ConfigEntries.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ConfigEntries;