import ReduceStore from "./ReduceStore";

class MultiTenantStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_TENANTS_LIST": {
                return {
                    gridData: action.payload.gridData,
                    actionType: "listtenants"
                };
            }
            case "LOAD_TENANT_DROP_DOWN": {
                return {
                    ddData: action.payload.ddData,
                    actionType: "tenantsdropdown"
                };
            }
            case "POPULATE_LOGS_LIST": {
                return {
                    logsData: action.payload.gridData,
                    actionType: "logslist"
                };
            }
            case "POPULATE_CONFIG_ENTRY_LIST": {
                return {
                    entryData: action.payload.entryData,
                    actionType: "configEntrylist"
                };
            }
            case "LICENSE_TENANT_FETCH_COMPLETE": {
                return {
                    licenseDetail: action.payload.licenseDetail,
                    actionType: "assignlicense"
                };
            }
            case "POPULATE_TENANTS_DROPDOWN": {
                return {
                    type: "DROPDOWN_LIST",
                    ddData: action.payload.ddData,
                    refreshAction: action.payload.refreshAction
                };
            }
            case "POPULATE_CONFIG_ENTRY_SAML_LIST": {
                return {
                    //entryData: action.payload.entryData,
                    fileName1 :action.payload.fileName1,
                    identity : action.payload.identity,
                    idpCert : action.payload.certificate,
                    signonUrl : action.payload.signonUrl,
                    logoutUrl : action.payload.logoutUrl,
                    adminMail : action.payload.adminMail,
                    fileName2 : action.payload.fileName2,
                    localPvtKey: action.payload.localPvtKey,
                    localCert: action.payload.localCert,
                    userId: action.payload.userId,
                    map : action.payload.map,
                    digAlg: action.payload.digAlg,
                    sigAlg: action.payload.sigAlg,
                    actionType: "configSamllist",
                };
            }
            default:
                return state;
        }
    }
}
export default new MultiTenantStore();