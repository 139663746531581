import ReduceStore from "./ReduceStore";

class AuditStore extends ReduceStore {
  constructor() {
    super();
    this.state = {
      tokens: [],
    };
  }
  reduce(action, state) {
    switch (action.type) {
      case "PROCESS_AUDIT_DATA": {
        return {
          ...state,
          audits: action.payload.audits,
          pagination: action.payload.pagination,
          entityFilterVal: action.payload.entityFilterVal,
          entityIdVal: action.payload.entityIdVal,
          attributeVal: action.payload.attributeVal,
          operationVal: action.payload.operationVal,
          userVal: action.payload.userVal,
          oldVal: action.payload.oldVal,
          newVal: action.payload.newVal,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          auditEnabled: action.payload.auditEnabled,
        };
      }

      default:
        return state;
    }
  }
}

export default new AuditStore();
