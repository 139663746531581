import React, {Component} from "react";
import "./css/APPagination.scss";
import { Select } from 'grommet/components/Select';
import { TextInput } from 'grommet/components/TextInput';
//import SearchInput from 'grommet/components/SearchInput';
import { Previous as PreviousIcon } from 'grommet-icons';
import { Next as NextIcon } from 'grommet-icons';
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';
import * as LocaleUtility from "../../../utils/LocaleUtility";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class APPagination extends Component {
    constructor(props) {     
        super(props);
        
        this.updatePaginationState = this.updatePaginationState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onPageSizeEditAndSearch = this.onPageSizeEditAndSearch.bind(this);
        
              
        this.state = {    
            paginationData:{
              startRecordCount : 0,
              endRecordCount : 0,
              totalCount : 0,
              pageNumber : 0,
              pageSize : 0,
              isFirstpage : false,
              islastpage : false,
              pageCountArray: [],
              totalPages :0
            }
        }
    }


    onSearch (event) {
        //console.log("on search event  clicked");
    }

    onChange(event) {
        //console.log("onchnege clicked");
      //  console.log(onchageData.target);
       // console.log(onchageData.option);
        //console.log(onchageData.value);
        let pageNumber = event.value;        
        this.props.doPagination(pageNumber, this.props.pageSize)
    }

    onPageSizeEditAndSearch (event, flag) {
        let pageSize = event.target.value;
        if(Number(pageSize)>100){
            pageSize=100;
        }
        let paginationDatatemp = this.state.paginationData;
        if(flag || event.charCode === 13) {
            event.target.blur();
            if(pageSize === '' || pageSize === null || isNaN(pageSize) || pageSize <= 0 ) {
                paginationDatatemp.pageSize = "" ;
                this.setState({paginationData : paginationDatatemp});
            } else {
                this.props.doPagination(1, pageSize)
            }
        } else {
            paginationDatatemp.pageSize = pageSize;
            this.setState({paginationData : paginationDatatemp});
        }
      }

    componentDidMount() {  
        //console.log("AP-PAGINATION componentWillMount");
        this.updatePaginationState();         
    }  
 
    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        //console.log("AP-PAGINATION componentDidUpdate");  
        if (prevProps.pageNumber !== this.props.pageNumber || prevProps.pageSize !== this.props.pageSize 
            || prevProps.totalCount !== this.props.totalCount) {
            this.updatePaginationState();
        }
    }

    updatePaginationState() {

        let paginationDatatemp = this.state.paginationData;

        paginationDatatemp.isFirstpage = this.props.pageNumber === 1  ;
        paginationDatatemp.islastpage = this.props.pageNumber >= (this.props.totalCount/this.props.pageSize)  ;
       
        paginationDatatemp.startRecordCount = paginationDatatemp.isFirstpage ? 1 : (this.props.pageSize * (this.props.pageNumber-1))+1 ;

        paginationDatatemp.endRecordCount = this.props.pageNumber === 1 
            ? this.props.pageSize  
            : (this.props.pageSize * (this.props.pageNumber-1)) +this.props.pageSize; 
            if (paginationDatatemp.endRecordCount > this.props.totalCount) {
                paginationDatatemp.endRecordCount  = this.props.totalCount;
            }

        paginationDatatemp.pageNumber = this.props.pageNumber;
        paginationDatatemp.totalCount = this.props.totalCount ;
        paginationDatatemp.pageSize = this.props.pageSize ;
        paginationDatatemp.totalPages= Math.ceil(this.props.totalCount/this.props.pageSize);
        

        let pageCountArrayTemp = [];
        for (let i=1;i<=paginationDatatemp.totalPages;i++) {
            pageCountArrayTemp.push(i)
        }

        paginationDatatemp.pageCountArray = pageCountArrayTemp;
      
        //console.log("is first page"+paginationDatatemp.isFirstpage);
        //console.log("is last page"+paginationDatatemp.islastpage);
        this.setState({paginationData : paginationDatatemp});

    }

    
    
    render() {
        let intl = this.context.intl;
        return(
            <div className="pagination_wrapper">
                <div className="pagination_left">
                <div className="appaginationPageSize">
                <div className="no_of_records">{LocaleUtility.getLocaleMessage("no.of.records.per.page")}</div>
                {/*
                 <TextInput value={this.state.paginationData.pageSize+''}
                               onChange={event =>this.onPageSizeEditAndSearch(event)}
                               style={{width:'55px'}} />

                               <SearchInput  value={this.state.paginationData.pageSize+''}
                               onChange={event =>this.onPageSizeEditAndSearch(event)}
                               style={{width:'40px'}} />    
                
                */}
                <TextInput 
                    //inline={true}
                    ref={this.inputRef}
                    value={this.state.paginationData.pageSize+""}
                    onBlur={(event)=>this.onPageSizeEditAndSearch(event, true)}
                    onKeyPress={(event)=>this.onPageSizeEditAndSearch(event, false)}
                    onChange={(event)=>this.onPageSizeEditAndSearch(event, false)}
                    size='small'
                    />                        
                </div>

                 <div className="apPaginationNavigation">
                 <a  onClick={() => this.state.paginationData.isFirstpage ? "" :this.props.doPagination(1, this.props.pageSize)} ><PreviousIcon className="startPagebutton" colorIndex="brand"/><PreviousIcon colorIndex="brand"/></a>
                         <a   onClick={() => this.state.paginationData.isFirstpage ? "" :this.props.doPagination(this.props.pageNumber-1, this.props.pageSize)} ><PreviousIcon colorIndex="brand"/></a>
                         <a onClick={() => this.state.paginationData.islastpage ? "" : this.props.doPagination(this.props.pageNumber+1, this.props.pageSize)}><NextIcon  colorIndex="brand" /></a>
                         <a    onClick={() => this.state.paginationData.islastpage ? "" : this.props.doPagination(this.state.paginationData.totalPages, this.props.pageSize)}><NextIcon className="endPagebutton"  colorIndex="brand" /><NextIcon  colorIndex="brand" /></a>
                     </div>

                <div className="appageList">
                {this.state.paginationData.startRecordCount}   
                {' '} - {this.state.paginationData.endRecordCount}  
                   {' '} {LocaleUtility.getLocaleMessage("no.of.records.of")}   {this.state.paginationData.totalCount}
                </div>
            </div>
            <div className="pagination_right">
                
                <div className="appagination">
                <Select //placeholder= {this.state.paginationData.pageNumber}
                    width={5}
                    options={this.state.paginationData.pageCountArray}
                    value={this.state.paginationData.pageNumber}
                  //  onSearch={this.onSearch}
                    onChange={this.onChange}
                    />
                </div>
                <div className="appaginationTotalpages">
                    <span>{this.state.paginationData.totalPages} </span><span>{LocaleUtility.getLocaleMessage("pages")}</span>
                </div>
               </div>
               
            </div>);
    }
}


APPagination.defaultProps = {
    totalCount: 1,
    pageSize: 1,
    pageNumber :1
    
  };
  APPagination.propTypes = {
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    doPagination : PropTypes.func.isRequired,
  }
// APPagination.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default APPagination;