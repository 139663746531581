import React, { Component } from "react";
import { Button } from "grommet/components/Button";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleInstallClick=this.handleInstallClick.bind(this);
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     this.setState({
    //         showModal: nextProps.showModal
    //     });
    // }

    // componentDidUpdate(prevProps, prevState){
    //     if (prevState.showModal !== this.state.showModal) {
    //     this.setState({
    //         showModal: nextProps.showModal
    //     });
    // }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.showModal !== prevState.showModal)
           return { showModal: nextProps.showModal };
        else return null;
    }

    handleCloseModal() {
        this.setState({
            showModal: false
        })
        this.props.onCloseButtonCallback();
    }
    handleInstallClick(){
        this.setState({
            showModal: false
        })
        this.props.installBtnClick();
    }
    

    render() {
        const showHideModalStyle = this.state.showModal ? "modal display-block" : "modal display-none";
        let afterClosePath, secondary = true, primary = false;
        if(this.props.path !== undefined){
            afterClosePath = this.props.path;
        }
        if(this.props.primary !== undefined){
            primary = this.props.primary;
            secondary = false;
        }
        let installBtndata="";
        if(this.props.installBtn !== undefined && this.props.installBtn.disable===undefined){
            installBtndata = <div className="button_wrap_primary"><Button id="modal_Btn"  label={this.props.installBtn.label}
                                    plain={false}  primary={this.props.installBtn.primary} onClick={this.handleInstallClick}></Button>
                                    </div>
        }else if(this.props.installBtn !== undefined && this.props.installBtn.disable!==undefined){
            let isDisableProp = this.props.installBtn.disable;
            if(this.props.installBtn !== undefined && isDisableProp){
                installBtndata = <div className="button_wrap_primary"><Button id="modal_Btn"  label={this.props.installBtn.label} disabled={isDisableProp}
                                    plain={false}  primary={this.props.installBtn.primary} ></Button>
                                    </div>
            }else if(this.props.installBtn !== undefined){
                installBtndata = <div className="button_wrap_primary"><Button id="modal_Btn" label={this.props.installBtn.label} disabled={isDisableProp}
                                    plain={false}  primary={this.props.installBtn.primary} onClick={this.handleInstallClick}></Button>
                                    </div>
            }
        }
        return (
            <div id={this.props.id} className={showHideModalStyle}>
                <div id="modalDiv" className="modal">
                    <div className="modal-main panel panel-default" style={this.props.styleProps}>
                        <div className="panel-heading">
                            <h2 id="modal_title" className="panel-title">{this.props.title}</h2>
                        </div>
                        <div id="modal_content" className="modal-panel-body">{this.props.sectionContent}</div>
                        <div className="panel-footer panel-button-footer">
                            {/* {(this.props.installBtn !== undefined) && 
                                    <Button id="modal_Btn" className="modalFootInstallBtn" label={this.props.installBtn.label} 
                                        plain={false}  primary={this.props.installBtn.primary} onClick={this.handleInstallClick}></Button>
                                    } */}
                            {installBtndata}
                            <span style={{padding: "5px"}}></span>
                            {primary ? (
                                <Button id="modal_Btn" label={this.props.closeLabel} href={afterClosePath} 
                                    plain={false}  primary={primary} onClick={this.handleCloseModal}></Button>
                            ) :
                                (
                                    <div className="button_wrap_negative">
                                        <Button id="modal_Btn" label={this.props.closeLabel} path={afterClosePath} 
                                        accent={true} plain={false} secondary={secondary} onClick={this.handleCloseModal}></Button>
                                    </div>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;