import React from "react";

class TableConstant extends React.Component {
    static __NAME = "columnName";
    static __LABEL = "headerLabel";
    static __HREF = "href";
    static __WIDTH = "width";
    static __FILTER = "filter";
    static __ONCLICK = "onClick";
    static __COLUMNKEY = "columnKey";
    static __SORT = "sort";
	static __CLASSNAME = "className";
	static __EDITABLE = "editable";
	static __BEFOREEDIT = "beforeEdit";
	static __AFTEREDIT = "afterEdit";
	static __EDITFIELDTYPE = "editFieldType";
	static __FILTERPLACEHOLDER = "filterPlaceHolder";
	static __FILTERFIELDTYPE = "filterFieldType";
	static __FILTERFIELDFORMAT = "filterFieldFormat";
	static __FILTERFIELDVALUE = "filterFieldValue";
	static __INFOICON = "infoIcon";
	static __INFOICONTEXT = "infoIconText";
	static __ENABLED_TOOL_TIP = "enabledToolTip";
	static __TOOL_TIP_TEXT = "ToolTipText";
	static __TEXT_ALIGN_VALUE = "textAlign";
	static __ENABLE_CONTEXT_MENU = "enableCxtMenu";
	static __TOOL_TIP_ALIGNMENT = "tootipAlignment";
}

export default TableConstant;