import React, { Component } from "react";
import { CheckBox } from "grommet/components/CheckBox";
import { Anchor } from 'grommet/components/Anchor';
import { Button } from "grommet/components/Button";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import SectionWithTitle from "../../generic/SectionWithTitle";
import InstallPageWizard from "./subcomponents/InstallPageWizard";
import PACPageWizard from "./subcomponents/PACPageWizard";
import SpinningNotification from '../../generic/SpinningNotification';
import PageStore from "../../../flux/PageWizardStore";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as Utils from  "../../../utils/Util";
import Dispatcher from "../../../flux/Dispatcher";
import Store from "../../../flux/APTableStore";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import { Copy as CopyIcon } from 'grommet-icons';
import withRouter from "../../app/withRouter";
import { createSearchParams } from "react-router-dom";

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class License extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 1, secondCount: 1, currentStep: 1, pageType: 'default', redirectPage:""};
    this.nextStep = this.nextStep.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.saveUserAggrement = this.saveUserAggrement.bind(this);
    this.onChangeEulaCheck = this.onChangeEulaCheck.bind(this);
    this.onChangePrivacyCheck = this.onChangePrivacyCheck.bind(this);        
    this.refreshState = this.refreshState.bind(this);
  }

  callback1(loca, hist) {
    if (this.routerLocation.pathname === "/sublink2") {
      this.setState({ activeIndex: 1 });
    }
  }

  componentDidMount() {
    let loadSpinAction = "LICENSE_INSTALL_PAGE_LOADING_SPIN";
    Dispatcher.dispatch({
        type: loadSpinAction,
        payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
    });
    FetchHelper.getUserAggrement(false);
    PageStore.on("change", this.refreshState);
    Store.on("change", this.refreshState);
  }

  componentWillUnmount() {
    PageStore.removeListener("change", this.refreshState);    
    Store.removeListener("change", this.refreshState);
  }
  
  refreshState() {
    if(PageStore.getState().type !== undefined ){
      if(PageStore.getState().type === 'LICENSE_VALIDATE_COMPLETE' ) {
        this.setState({
            pageType: PageStore.getState().type,
            pagination: PageStore.getState().pagination,
            currentStep: PageStore.getState().currentStep,
            rowData: PageStore.getState().tableData,
            disabledRows: PageStore.getState().disableIds,
            sortIndex: PageStore.getState().sortIndex,
            sortAscending: PageStore.getState().sortAscending,
            error: PageStore.getState().error
        });
      } else if(PageStore.getState().type === 'PAC_ENTITLEMENTS_FETCH_COMPLETE' ) { 
        if(PageStore.getState().currentStep === 2) {
          var tableData = PageStore.getState().entitlementData;
          var environmentSelected = PageStore.getState().environmentSelected;
          //var environmentDesc;
          // if(PageStore.getState().environmentDesc !== undefined){
          //   environmentDesc = PageStore.getState().environmentDesc;   
          // } else {
          //   environmentDesc = environmentSelected;
          // }
          let entitlementData = tableData.get(environmentSelected.value);
          this.setState({
              pageType: PageStore.getState().type,
              currentStep: PageStore.getState().currentStep,
              rowData: entitlementData,
              sortIndex: PageStore.getState().sortIndex,
              sortAscending: PageStore.getState().sortAscending,
              options: PageStore.getState().environments,
              activationCode: PageStore.getState().activationCode,
              tableData: tableData,
              licenseList: PageStore.getState().licenseList,
              environmentSelected: environmentSelected,
              error: PageStore.getState().error
          });
        } else {
          this.setState({
            pageType: PageStore.getState().type,
            pagination: PageStore.getState().pagination,
            currentStep: PageStore.getState().currentStep,
            rowData: PageStore.getState().tableData,
            sortIndex: PageStore.getState().sortIndex,
            sortAscending: PageStore.getState().sortAscending,
            disabledRows: PageStore.getState().disableIds,
            error: PageStore.getState().error
          });
        }
      } else if(PageStore.getState().type === 'USER_AGREEMENT_FETCH_COMPLETE' ) {
        this.setState({
            eulaCheck: PageStore.getState().eulaCheck,
            isStandAlone: PageStore.getState().isStandAlone,
            privacyCheck: PageStore.getState().privacyCheck,
            defaultCheck: PageStore.getState().defaultCheck
        });
      } 
    }

    let redirect = Store.getState().redirectPage;
    this.setState({redirectPage:Store.getState().redirectPage});
    if(this.state.redirectPage!==undefined && this.state.redirectPage!==""){
        if(redirect==="product_feature"){
          this.props.navigate(FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH, {search: Utils.buildURLQueryParam(), state: {}});
          FetchHelper.resetInstallLicense();
        } else if(redirect==="view_license"){
          this.props.navigate(FrontendPagePath.LICENSE_VIEW_PATH, {search: Utils.buildURLQueryParam(), state: {}});
          FetchHelper.resetInstallLicense();
        } else if(redirect==="reset"){
          // this.setState({ count: 1, secondCount: 1, currentStep: 1, pageType: 'default', redirectPage:"" });
        }
    }

    var data = FetchHelper.getSession();
    if(data !== undefined){
      this.setState({ lockCode: data.lockCode});
    }
      let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: ""
    });
  }

  nextStep(currentStep, pageType) {
      // Make sure currentStep is set to something reasonable
      if (currentStep >= 2) {
          currentStep = 2;
      } else {
          currentStep = currentStep + 1;
      }

      this.setState({
          currentStep: currentStep,
          pageType: pageType
      });
  }

  prevStep(currentStep, pageType) {
    if (currentStep <= 1) {
        currentStep = 1;
    } else {
        currentStep = currentStep - 1;
    }

    if(currentStep === 1){
      this.setState({
          currentStep: currentStep,
          pageType: 'default'
      });
    } else {
      this.setState({
          currentStep: currentStep,
          rowData: this.state.tableData.get(this.state.environmentSelected.value),
          pageType: pageType
      });
    }
  }

  onChangeDefaultCheck(event) {
    this.setState({
      defaultCheck: event.target.checked
    });
  }

  onChangeEulaCheck(event) {
    this.setState({
      eulaCheck: event.target.checked
    });
  }

  onChangePrivacyCheck(event) {
    this.setState({
      privacyCheck: event.target.checked
    });
  }

  saveUserAggrement(event) {
    event.preventDefault();
    var selectedDataList = [];
    if(event.target.elements.privacyCheck !== undefined){
      selectedDataList.push({
                            "type": "CALL_HOME",
                            "value": event.target.elements.privacyCheck.checked
                        });
    }
    if(event.target.elements.defaultCheck !== undefined){
      selectedDataList.push({
                            "type": "MF_DEFAULT_CALL_HOME",
                            "value": event.target.elements.defaultCheck.checked
                        });
    }
    let loadSpinAction = "USER_AGREEMENT_SAVE_LOADING_SPIN";
    Dispatcher.dispatch({
        type: loadSpinAction,
        payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
    });
    FetchHelper.saveUserAggrement(selectedDataList);
                            
  }

  sectionContent() {

    let pageSection, privacySection;

    if(this.state.isStandAlone === true){
      privacySection = <div className="aggreement_wrapper">
                         {/* <span><CheckBox id="lic_inst_eulaChk" name="defaultCheck" checked={this.state.defaultCheck} onChange={this.onChangeDefaultCheck.bind(this)}/>
                          {LocaleUtility.getLocaleLabel(this.context.intl, "default.usage.agree")}&nbsp;
                          <Anchor id="lic_inst_policyLink" href="https://www.opentext.com/about/privacy" target="_blank"
                          label={LocaleUtility.getLocaleLabel(this.context.intl, "label.install.policy")} /></span><br></br> */}
                          <div className="aggreement_1"><CheckBox id="lic_inst_eulaChk" name="eulaCheck" disabled={true} checked={this.state.eulaCheck} onChange={this.onChangeEulaCheck.bind(this)}/>
                          <span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.install.agree")}</span>
                          <Anchor id="lic_inst_eulaLink" href="https://software.microfocus.com/en-us/legal/software-licensing" target="_blank"
                          label={LocaleUtility.getLocaleLabel(this.context.intl, "label.install.aggrement")} /></div>
                          <div className="aggreement_1"><CheckBox id="lic_inst_privChk" name="privacyCheck" checked={this.state.privacyCheck} onChange={this.onChangePrivacyCheck.bind(this)}/>
                          <span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.intall.authorize")}</span>
                          <Anchor id="lic_inst_policyLink" href="https://www.opentext.com/about/privacy" target="_blank"
                          label={LocaleUtility.getLocaleLabel(this.context.intl, "label.install.policy")} /></div>
                        </div>
    } else {
      privacySection = <div className="aggreement_wrapper">
                        {/*  <span><CheckBox id="lic_inst_eulaChk" name="defaultCheck" checked={this.state.defaultCheck} onChange={this.onChangeDefaultCheck.bind(this)}/>
                          {LocaleUtility.getLocaleLabel(this.context.intl, "default.usage.agree")}&nbsp;
                          <Anchor id="lic_inst_policyLink" href="https://www.opentext.com/about/privacy" target="_blank"
                          label={LocaleUtility.getLocaleLabel(this.context.intl, "label.install.policy")} /></span><br></br> */}
                          <div className="aggreement_1"><CheckBox id="lic_inst_privChk" name="privacyCheck" checked={this.state.privacyCheck} onChange={this.onChangePrivacyCheck.bind(this)}/>
                          <span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.intall.authorize")}</span>
                          <Anchor id="lic_inst_policyLink" href="https://www.opentext.com/about/privacy" target="_blank"
                          label={LocaleUtility.getLocaleLabel(this.context.intl, "label.install.policy")} /></div>
                        </div>
    }


    if(this.state.pageType === 'default'){
      pageSection = <div className="section_wrap">
                          <div className="section_items">
                            <div  id="lic_inst_leftDiv">
                              <InstallPageWizard currentStep={this.state.currentStep} nextPage={this.nextStep.bind(this)}/>
                            </div>
                            <div>                                  
                                  <div className="or_separator">{LocaleUtility.getLocaleMessage("label.or.cap")}</div>
                               </div>
                            <div className="pac_wrapper">
                              <PACPageWizard currentStep={this.state.currentStep} nextPage={this.nextStep.bind(this)}/>
                            </div>
                          </div>
                          <div className="section_items">
                            <div id="lic_inst_rightDiv">
                              <form id="lic_inst_usragrform" onSubmit={this.saveUserAggrement}>
                              <ul style={{ listStyleType: 'none' }}>
                                <li>{privacySection}</li>
                                <li>
                                  <span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license.redeemed")}&nbsp;
                                   {/*<Anchor id="lic_inst_policyUS" href="https://entitlement.mfgs.microfocus.com/mysoftware/index" target="_blank"
                                    label={LocaleUtility.getLocaleLabel(this.context.intl, "lable.install.entitlement.portal")} />&nbsp;
                                    ({LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license.redeemedUS")}) <br></br> */}
                                    <Anchor id="lic_inst_policyOth" href="https://sld.microfocus.com" target="_blank"
                                    label={LocaleUtility.getLocaleLabel(this.context.intl, "lable.install.entitlement.portal")} />&nbsp;
                                   {/* ({LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license.redeemedOth")}) */}
                                    </span>
                                </li>
                                <li>&nbsp;</li>
                                <li className="button_wrap_primary">
                                   <Button id="lic_inst_save" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.save")}
                                                type="submit" plain={false} primary={true} />
                                </li>
                              </ul>
                              </form>
                            </div>
                          </div>
                          </div>
    } else if(this.state.pageType === 'LICENSE_VALIDATE_COMPLETE'){
     pageSection =  <div >
                    <InstallPageWizard {...this.state} navigate={this.props.navigate} prevPage={this.prevStep.bind(this)}/>
                  </div>
    } else if(this.state.pageType === 'PAC_ENTITLEMENTS_FETCH_COMPLETE'){
      pageSection = <div >
                    <PACPageWizard {...this.state} navigate={this.props.navigate} prevPage={this.prevStep.bind(this)}/>
                  </div>
    }

    return (
      <section id="lic_inst_WizardSec" pad="none" wrap={false} responsive={false} justify="center">
        {pageSection}
        <SpinningNotification centerPage={true} />
      </section>
    );
  }

  copyToClipboard(event, key){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if (navigator.clipboard !== undefined) {//Chrome
            navigator.clipboard.writeText(key);
        } else if(window.clipboardData) { // Internet Explorer
            this.copyClipboard(key);
        }
        const span = document.getElementsByClassName('copiedTooltipText')[0];
        if(span){
            var x = event.clientX;
            var y = event.clientY;
            setTimeout(function(){
                span.style.visibility = "hidden";
                span.style.top = y;
                span.style.left = x+40;
            }, 2000);
            span.style.visibility = "visible";
        }
    }

  render() {
    let sectionTitle, midSection;
	let sectionHelpTopic;
    if (this.state.currentStep === 1) {
        sectionTitle=LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license");
		sectionHelpTopic = "install_license";
    } else {
        sectionTitle=LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license.wizard");
		sectionHelpTopic = "manage_license";
    }

    midSection = <div style={{marginTop: "10px"}} className="button_left_wrap"><label>{LocaleUtility.getLocaleLabel(this.context.intl, "label.lockcode")}</label>:&nbsp;<span>{this.state.lockCode}</span>
                      <div className="copiedTooltip" style={{paddingLeft: "8px"}}>
                          <Anchor title={LocaleUtility.getLocaleMessage("label.grid.col.copy")}>
                            <CopyIcon onClick={event => this.copyToClipboard(event, this.state.lockCode)}/>
                          </Anchor>
                          <span className="copiedTooltipText">{LocaleUtility.getLocaleMessage("tooltip.copied.text")}</span>
                        </div>
                      <div className="dbInfotooltip">
                            &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                            <span style={{"width": "500px !important"}} className="dbInfotooltipText">
                              {LocaleUtility.getLocaleLabel(this.context.intl, "label.install.visit.se")}</span>
                        </div>
                    </div>

    return (
      <div className="config-cont-backgroud">
        <SectionWithTitle id="lic_inst_mainSec" title={sectionTitle} midSection={midSection} sectionContent={this.sectionContent()} helpTopic={sectionHelpTopic}/>
      </div>
    );
  }
}

// License.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default withRouter(License);
