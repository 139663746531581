import React, { Component } from "react";
//import { Anchor } from 'grommet/components/Anchor';
import * as  Util from "../../utils/Util";
//import { getLocaleMessage }from "../../utils/LocaleUtility";
//import { Help as HelpIcon } from 'grommet-icons';
import * as FetchHelper from "../../utils/FetchHelper";
import "../../css/index.css";
class SectionWithTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            sectionContent: "",
            session: undefined
        };
        this.openHelp = this.openHelp.bind(this);
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        this.setState({session: FetchHelper.getSession()});
    }
    openHelp() {
        let version = "";
        if(this.state.session !== undefined && this.state.session.helpDocLinkAppVersion !== undefined) {
            version = this.state.session.helpDocLinkAppVersion;
        }
        Util.gethelp(this.props.helpTopic, version);
    }

    render() {
        if(this.props.helpTopic !== undefined) {
            localStorage.setItem("helpTopic", this.props.helpTopic)
        }
        let rightSection = this.props.rightSection !== undefined ? <div className="section_right">
                                {/* <div style={{ float: "right"}}> */}
                                {/*this.props.rightSection !== undefined && this.props.helpTopic !== undefined && (
                                    <span style={{borderRight: "0px solid #CCC", marginLeft:"-10px"}}></span> 
                                )*/}       
                                {/*this.props.helpTopic !== undefined ? 
                                    <Anchor title={getLocaleMessage("label.tooltip.help")}>
                                        <div className="helpIconCls">
                                            <HelpIcon colorIndex="brand" onClick={this.openHelp}/>
                                        </div>
                                        </Anchor>
                                :null*/}                                                    
                                {/* </div> */}
                                {this.props.rightSection}
                            </div> : ""
        let titleContent = <div className="section_node_lock_wrap">
                                {this.props.midSection === undefined && rightSection !== "" && (
                                    <div>
                                            {/*<td colSpan="2"><span id="secTitle" className="pageheader">{this.props.title}</span></td>*/}
                                            {rightSection}
                                            </div>
                                )}
                                {this.props.midSection !== undefined && (
                                    <div>
                                            {/*<td><span id="secTitle" className="pageheader">{this.props.title}</span></td>*/}
                                            {this.props.midSection}
                                            {rightSection}
                                    </div>
                                )}
                            </div> ; 
        
        let divStyle = {};
        let panelWidth = "25";
        if(this.props.setionWidth !== undefined && this.props.setionWidth !== null && this.props.setionWidth !== "") {
            panelWidth = this.props.setionWidth;
        }
        if(this.props.sectionContent === undefined || this.props.sectionContent === null) {
            divStyle = {float: "right", width: panelWidth+"%"};
        }
        return (
            <div id={this.props.id} className="section_with_title_wrap" >
                {titleContent}
                {/*<div className="lineseparator">
                </div>*/}
                <div className="section_content">
                    {this.props.sectionContent}
                </div>
            </div>
        )
    }

}

export default SectionWithTitle;