export const CONTEXT_PATH = "/autopass/ls";
export const HOME_PATH = "/";
export const LOGIN_PATH = CONTEXT_PATH + "/login";
export const LOGOUT_PATH = CONTEXT_PATH + "/logout";
export const RESET_PATH = CONTEXT_PATH + "/reset";
export const USER_RESET_PATH = CONTEXT_PATH + "/login/user/reset/:id";
export const TENANT_CREATE_PWD_PATH = CONTEXT_PATH + "/login/tenant/password/create/:id";
export const LICENSE_PATH = CONTEXT_PATH + "/license/install";
export const USAGE_PATH = CONTEXT_PATH + "/usage";
export const LICENSE_DETAIL_PATH = CONTEXT_PATH + "/license/detail";
export const LICENSE_VIEW_PATH = CONTEXT_PATH + "/license/view";
export const LICENSE_ARCHIVE_PATH = CONTEXT_PATH + "/license/archived";
export const LICENSE_BORROW_PATH = CONTEXT_PATH + "/license/borrow";
export const LICENSE_BORROW_FORM_PATH = CONTEXT_PATH + "/license/borrow/form";
export const LICENSE_CLEANUP_PATH     = CONTEXT_PATH + "/license/cleanup";
export const REPORTS_PATH = CONTEXT_PATH + "/reports";
export const RESERVATION_PATH = CONTEXT_PATH + "/reservation";
export const RESERVATION_POOL_MANAGE_PATH = CONTEXT_PATH + "/reservation/pool";
export const RESERVATION_FEATURE_BASE_PATH = CONTEXT_PATH + "/reservation/featurebase";
export const RESERVATION_PRODUCT_BASE_PATH = CONTEXT_PATH + "/reservation/productbase";
export const COMMUTER_CHECKOUT_PATH = CONTEXT_PATH + "/remotecommuter/checkout";
export const COMMUTER_CHECKIN_PATH = CONTEXT_PATH + "/remotecommuter/checkin";
export const USER_MGMT_PATH = CONTEXT_PATH + "/userManagement";
export const CONFIG_PATH = CONTEXT_PATH + "/configuration";
export const BUYMORE_PATH = CONTEXT_PATH + "/buymore";
export const ABOUT_PATH = CONTEXT_PATH + "/about";
export const USER_GROUPS_PATH = CONTEXT_PATH + "/userManagement/groups";
export const USER_TENANTS_PATH = CONTEXT_PATH + "/userManagement/tenants";
export const MAIN_CONFIG_PATH = CONTEXT_PATH + "/config/main";
export const USER_CONFIG_PATH = CONTEXT_PATH + "/config/user";
export const LDAP_CONFIG_PATH = CONTEXT_PATH + "/config/ldap";
export const IDM_CONFIG_PATH = CONTEXT_PATH + "/config/idm";
export const USAGE_CONFIG_PATH = CONTEXT_PATH + "/config/usage";
export const DB_CONFIG_PATH = CONTEXT_PATH + "/config/dataBase";
export const USAGE_REPORT_LIST_PATH = CONTEXT_PATH + "/usage/usageReportList";
export const USAGE_REPORT_LIST_SEARCH_PATH  = CONTEXT_PATH + "/usage/usageReportSearch";
export const USAGE_REPORT_GRAPHS_PATH  = CONTEXT_PATH + "/usage/graphs";
export const USAGE_REPORT_ADVANCED_PATH  = CONTEXT_PATH + "/usage/usageAdvancedReports";
export const USER_UPDATE_FORM_PATH = CONTEXT_PATH + "/user/update";
export const MF_PRIVACY_PAGE_LINK = "https://www.opentext.com/about/privacy";
export const LICENSE_PRODUCTFEATURE_PATH = CONTEXT_PATH + "/license/productfeature";
export const USAGE_REPORT_TOKEN_CAL_PATH =   CONTEXT_PATH + "/usage/tokenCal";
export const USAGE_REPORT_INSTANT_ON_PATH =   CONTEXT_PATH + "/usage/instanton";
export const CONTROL_TOWER_PATH = CONTEXT_PATH + "/config/ct";
export const LICENSE_NAMEDUSER_PATH = CONTEXT_PATH + "/license/nameduser";
export const APP_LOGS_CONFIG = CONTEXT_PATH + "/config/applogs";
export const APP_CONFIG_ENTRIES = CONTEXT_PATH + "/config/application";
export const USAGE_REPORT_GENERIC_PATH =   CONTEXT_PATH + "/usage/generic";
export const APP_CONFIG_SUBNOTIFICATION = CONTEXT_PATH + "/config/notification";
export const USER_TENANT_LICENSE_PATH = CONTEXT_PATH + "/license/tenant";
export const LICENSE_TENANT_FORM_PATH = CONTEXT_PATH + "/license/form";
export const USAGE_PRODUCT_REPORT_PATH =   CONTEXT_PATH + "/usage/productReport";
export const APP_CONFIG_AUTH_TOKEN = CONTEXT_PATH + "/config/token";
export const APP_CONFIG_SAML_ENTRIES = CONTEXT_PATH + "/config/saml";
export const SAML_CONFIG_LOGIN_PATH = CONTEXT_PATH + "/setsaml";
export const USAGE_TENANT_WISE_REPORT_PATH =   CONTEXT_PATH + "/usage/usageTenantWiseReportList";
export const APP_CONFIG_AUDITS = CONTEXT_PATH + "/config/audit";