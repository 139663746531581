import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { Tiles } from '../../commons/grommet/Tiles';
import { Tile } from '../../commons/grommet/Tile';
import { Box } from 'grommet/components/Box';
import { Close as CloseIcon } from 'grommet-icons';
import TableGrid from "../../../components/commons/table/PivotTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Store from "../../../flux/GenericUsageStore";
import ProductStore from "../../../flux/ProductStore";
import LicenseDetailStore from "../../../flux/LicenseDetailStore";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { Refresh as RefreshIcon } from 'grommet-icons';
import SpinningNotification from '../../generic/SpinningNotification';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher";
import Modal from "../../generic/Modal";
import ColumnFilter from "../../generic/ColumnFilter";
import { Button } from "grommet/components/Button";
import { Anchor } from 'grommet/components/Anchor';
import Select from "../../commons/APLSSelect";
import CreateUsageReport from './CreateUsageReport';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class GenericReporting extends Component {
    constructor(props) {
        super(props);
        this.poolMemRef = React.createRef();
        this.jobPopupRef = React.createRef();
        this.state = {
            rowData: [],
            headers: [],
            sortOrder: "ASC",
            sortIndex: 0,
            sortAscending: true,
            showCreatePopup: false,
            productName: undefined,
            featureId: undefined,
            featureVersion: undefined,
            searchCreatedTime: "",
            searchCreatedBy: "",
            searchAction: false,
            searchCreatedValue: "",
            session: undefined,
            prodAdmin: false,
            admin: false,
            selectedFileName: undefined,
            submitFiles: undefined,
            productNameSelected: undefined,
            customMetric: undefined,
            value: undefined,
            selPeriodFilter: undefined,
            selPeriodIndex: -1,
            selReportValue: undefined,
            report: undefined,
            aggregator: undefined,
            filterTasks: [],
            filterMap: new Map(),
            productNameSelectOptions: ProductStore.getState().productNameSelectOptions,
            featureSelectOptions: [],
            reportOptions:[],
            attFilterOptions:[],
            reportNames: [],
            featureidList:[],
            tenantList:[],
            tenants:[],
            timeMap: new Map([["year" ,"1:yyyy"], ["month", "2:MMM"], ["week", "2:WW"], 
                                ["week", "2:WW"], ["day", "3:dd"], ["hour", "4:HH24"]]),
            timePeriodOptions: [{value: undefined, label:LocaleUtility.getLocaleMessage("label.select.all")},
                                {value: "4:HH24", label:LocaleUtility.getLocaleMessage("usage.select.period.hour.label")},
                                {value: "3:dd", label:LocaleUtility.getLocaleMessage("usage.select.period.day.label")},
                                {value: "2:WW", label:LocaleUtility.getLocaleMessage("usage.select.period.week.label")},
                                {value: "2:MMM", label:LocaleUtility.getLocaleMessage("usage.select.period.month.label")},
                                {value: "1:yyyy", label:LocaleUtility.getLocaleMessage("usage.select.period.year.label")}]
        }

        this.refreshProductItem = this.refreshProductItem.bind(this);
        this.filterPeriodList = this.filterPeriodList.bind(this);
        this.filterFeatureList = this.filterFeatureList.bind(this);
        this.filterReportList = this.filterReportList.bind(this);
        this.onColumnClicked = this.onColumnClicked.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.okAction = this.okAction.bind(this);

        this.loadUsageGrid = this.loadUsageGrid.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.buildSearchParams = this.buildSearchParams.bind(this);
        this.buildUsageParams = this.buildUsageParams.bind(this);
        this.refreshModal = this.refreshModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
        this.onClickCreateReport = this.onClickCreateReport.bind(this);
        this.closeCreateReport = this.closeCreateReport.bind(this);
        this.createReportAction = this.createReportAction.bind(this);
        this.onClickEditReport = this.onClickEditReport.bind(this);

        this.submitSearch = this.submitSearch.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.continueFilterAction = this.continueFilterAction.bind(this);
        this.resetPoolGrid = this.resetPoolGrid.bind(this);
        this.recursiveCallToCheckProdAdmin = this.recursiveCallToCheckProdAdmin.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.continueConfirmAction = this.continueConfirmAction.bind(this);
        this.closeJobsPopup = this.closeJobsPopup.bind(this);
        this.showJobsPopup = this.showJobsPopup.bind(this);
        this.downloadTemplateForLicense = this.downloadTemplateForLicense.bind(this);
        this.updateElements = this.updateElements.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.showFilterRecords = this.showFilterRecords.bind(this);
        this.exportUsageReport = this.exportUsageReport.bind(this);
        this.refreshReport = this.refreshReport.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.rowData !== this.state.rowData || this.state.session === undefined) {
            //ConfigurationUtils.hideLoadMask(this.context.intl);
            this.setState({session: FetchHelper.getSession()});
        }
    }

    componentDidMount() {
        FetchHelper.getProducts("CUSTOM_REPORT_TYPE", false);
        LicenseDetailStore.on("change", this.refreshModal);
        Store.on("change", this.loadUsageGrid);
        ProductStore.on("change", this.refreshProductItem);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.loadUsageGrid);
        ProductStore.removeListener("change", this.refreshProductItem);
        LicenseDetailStore.removeListener("change", this.refreshModal);
    }

    onSortClicked(index, order, header) {
        var sortOrder = "ASC", sortBy = this.state.sortBy, sortAscending = true;
        if(index === this.state.sortIndex && this.state.sortOrder === "ASC"){
            sortOrder = "DESC";
            sortAscending = false;
        }
        
        sortBy = header[index].columnName;
        this.setState({ sortOrder: sortOrder, sortBy: sortBy, sortIndex: index, sortAscending: sortAscending });
        let params = this.buildUsageParams(this.state.value, this.state.selPeriodFilter, 
            this.state.selReportValue, sortBy, sortOrder, undefined, undefined, this.state.filterEle);
        this.findData(params);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction);
        this.findData(params);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onClickDelete(key, event) {
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(isAdmin || this.state.prodAdmin) {
            let messageParams = {};
            messageParams["poolName"] = key;
            let msg = LocaleUtility.getLocaleMessage("resv.pool.delete.warning", messageParams);
            if(ValidationUtility.confirm(msg, "", "")) {
                FetchHelper.deleteReservationPool(this.state.request, key, this.context.intl);
            }
        }
    }

    refreshProductItem() {
        const products = ProductStore.getState().productNameSelectOptions
        const features = ProductStore.getState().customMetricOptions
        var selectedProd = undefined;
        if(products !== undefined) {
            for(var item of products.entries()){
                selectedProd = item;
                FetchHelper.getUsageReports(item[0], '');
                FetchHelper.getTenantForProducts(item[0]);
                break;
            }
            this.setState({
                productNameSelectOptions: products,
                productNameSelected: selectedProd[0],
                productName: selectedProd[1]
            });
        }
        if(features !== undefined) {
            this.setState({
                featureSelectOptions: features,
            });
        }
        if(selectedProd !== undefined){
            let params = {
                filterType: "allfields",
                productId: selectedProd[0]
            }
            this.findData(params);
        }
    }

    filterProductList(key, event){
        if(event.option.value !== ''){
            let params = {
                filterType: "allfields",
                productId: event.option.value
            }
            this.findData(params);
            //FetchHelper.getCustomMetricForProduct(event.option.value);
            FetchHelper.getTenantForProducts(event.option.value);
            FetchHelper.getUsageReports(event.option.value, '');
            this.setState({productNameSelected: event.option.value, productName: event.option.label, searchAction: false});
        }
    }
    filterFeatureList(key, event){
        if(event.option.value !== ''){
            let params = {
                filterType: "allfields",
                productId: this.state.productNameSelected,
                featureId: event.option.value
            }
            this.findData(params);
            FetchHelper.getUsageReports(this.state.productNameSelected, event.option.value);
            this.setState({selectedFeature: {value: event.option.value, label:event.option.value}, customMetric: event.option.value});
        }
    }
    filterReportList(key, event){
        let period, params=[];
        if(event.option.value !== ''){
            var fieldObj = this.state.reportFieldMap.get(event.option.value);
            if(fieldObj!==undefined){
                var report = fieldObj.report;
                if(report.periodFilter !== undefined && report.periodFilter !== null){
                    period = this.state.timeMap.get(report.periodFilter);
                    for(let itm in this.state.timePeriodOptions){
                        if(this.state.timePeriodOptions[itm].value === period){
                            period = this.state.timePeriodOptions[itm];
                            break;
                        }
                    }
                } else {
                    period = this.state.selPeriodFilter
                }
            this.setState({selReportValue: {value:event.option.value, label:report.reportName}, reportMeta: undefined,
                            searchAction: true, sortIndex: 0, sortAscending: true});
            params = this.buildUsageParams({label: fieldObj.fields.toString(), 
                value:fieldObj.fields.toString()}, period, event.option, 
                undefined, undefined, undefined, undefined, this.state.filterEle);
            }else{
                params["productId"]=this.state.productNameSelected;
                params["featureId"]=event.option.value;
                params["filterType"]= "fieldRefresh";
            }
            this.findData(params);
            FetchHelper.getTenantForProducts(this.state.productNameSelected, event.option.value);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }
    buildSearchParams(){}

    filterPeriodList(key, event){
        let params = {};
        params = this.buildUsageParams(this.state.value, event.option, this.state.selReportValue,
            this.state.sortBy, this.state.sortOrder, undefined, key, this.state.filterEle);
        this.findData(params);
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    findData(params) {
        this.setState({request: params});
        ConfigurationUtils.showLoadMask("", this.context.intl);
        FetchHelper.getfeatureIDforProducts(params.productId);
        FetchHelper.getAllUsage(params);
    }

    okAction(event) {
        let params = {};
        if(event.option.value !== ""){
            params = this.buildUsageParams(event.option, this.state.selPeriodFilter, this.state.selReportValue,
                this.state.sortBy, this.state.sortOrder, undefined, undefined, this.state.filterEle);
            this.findData(params);
        }
    }

    onColumnClicked(key, event){
        ConfigurationUtils.showLoadMask("", this.context.intl);
        FetchHelper.getLicenseDetail(key);
    }

    onModalCloseCallback() {
        this.setState({
            licenseDetail: undefined,
        });
    }

    refreshModal() {
        this.setState({
            licenseDetail: LicenseDetailStore.getState().licenseDetail,
        });
    }

    infoSectionContent(licDetail) {
    }

    onClickCreateReport() {
        this.setState({showCreatePopup: true, report: undefined});
        FetchHelper.getfeatureIDforProducts(this.state.productNameSelected);
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }
    closeCreateReport(deleted) {
        if(!deleted && this.state.selReportValue !== undefined){
            let obj = this.state.reportFieldMap.get(this.state.selReportValue.value);
            if(obj !== undefined)
                this.setState({showCreatePopup: false, report:obj.report});
            else
                this.setState({showCreatePopup: false, searchAction: false});
        } else {
            this.setState({showCreatePopup: false, searchAction: false, report: undefined});
        }

        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    onClickEditReport(){
        let obj = this.state.reportFieldMap.get(this.state.selReportValue.value);
        let report = JSON.parse(JSON.stringify(obj.report))
        this.setState({showCreatePopup: true, report:report});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    createReportAction(stateObj){
        ConfigurationUtils.showLoadMask("", this.context.intl);
        let params = {};
        params.reportName = stateObj.reportName;
        params.reportDesc = stateObj.reportDesc;
        params.fields = stateObj.reportFields;
        params.reportId = stateObj.reportId;
        params.productId = this.state.productNameSelected;
        params.featureId = stateObj.featureID;
        // params.featureId = this.state.customMetric;
        if(stateObj.tenantFilter !== undefined && stateObj.tenantFilter !== ''){
            if(stateObj.filter !== undefined && stateObj.filter !== '')
                params.reportFilter = stateObj.filter+"|std_tenant="+stateObj.tenantFilter;
            else
                params.reportFilter = "std_tenant="+stateObj.tenantFilter;
        } else {
            params.reportFilter = stateObj.filter;
        }
        params.periodFilter = stateObj.periodFilter;
        if(stateObj.minChecked){
            params.reportAggr = 'min'
        } else if(stateObj.maxChecked){
            params.reportAggr = 'max'
        }
        FetchHelper.createUsageReport(params);
        this.setState({searchAction: false});
    }

    modalLicenseDetailContent() {

        let licDetail = this.state.licenseDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(licDetail !== undefined){
            if(licDetail.featureDescription !== undefined){
                sectionInfoTitle = licDetail.featureDescription +
                    ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            } else {
                sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            }
            showModal = true
        }
        return (
            <Modal id="nameduser_lic_detailMod" title={sectionInfoTitle} showModal={showModal} styleProps={{top: "45%", overflowY: "auto"}}
                    closeLabel={LocaleUtility.getLocaleMessage("label.button.close")}
                    sectionContent={this.infoSectionContent(licDetail)}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    />
        );
    }
    recursiveCallToCheckProdAdmin(poolsData, prodAdmin) {
        poolsData.map(item => {
            if(!prodAdmin){
                item["delete"] = "";
                item["downloadIcon"] = "";
            }
            if(item.childs && item.childs.length > 0) {
                this.recursiveCallToCheckProdAdmin(item.childs, prodAdmin);
            }
            return "";
        });
    }

    loadUsageGrid() {

        let sortIndex = this.state.sortIndex;
        var sessObj = FetchHelper.getSession();
        var pagination = Store.getState().licpagination; 
        var fieldSet = Store.getState().fieldMap;
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(Store.getState().type === "allfields"){
            var filterOptions = [{value: "null", 
                                    label: LocaleUtility.getLocaleMessage("label.select.all")}];
            filterOptions.push.apply(filterOptions, Store.getState().attFilterOptions);
            var filters = ["null"];
            filters.push.apply(filters, Store.getState().headers);

            if(Store.getState().usageList!==undefined){
                const rows = Store.getState().usageList;
                this.setState({
                    rowData: rows,
                    headers: Store.getState().usageHeaders,
                    filters: filters,
                    attFilterOptions: filterOptions,
                    fieldSet: fieldSet,
                    session: sessObj,
                    selReportValue: undefined,
                    reportMeta:undefined,
                    showCreatePopup: false,
                    searchAction: false,
                    admin: isAdmin,
                    value: {value: filters.toString(), 
                            label: LocaleUtility.getLocaleMessage("label.select.all")}
                });
            } 
        } else if(Store.getState().type === "filterfields"){
            if(Store.getState().usageList!==undefined){
                var report = undefined, fieldObj = undefined;
                if(this.state.selReportValue !== undefined){
                    fieldObj = this.state.reportFieldMap.get(this.state.selReportValue.value);
                    report = fieldObj.report;
                    fieldObj.aggregators = Store.getState().repAggr;
                    // if(fieldObj.fields.length >= (this.state.filters.length-1)){
                    //     this.setState({value: {value: this.state.filters.toString(), 
                    //         label: LocaleUtility.getLocaleMessage("label.select.all")}});
                    // }
                    
                }
                const rows = Store.getState().usageList;
                var sortBy = (this.state.sortBy !== undefined ? this.state.sortBy : Store.getState().sortBy);
                this.setState({
                    rowData: rows,
                    report: report,
                    reportMeta: fieldObj,
                    reportFields: fieldSet,
                    headers: Store.getState().usageHeaders,
                    sortBy: sortBy,
                    sortIndex: sortIndex,
                    pagination: pagination,
                    session: sessObj,
                    admin: isAdmin,
                    showCreatePopup: false
                });
            } 
        } else if(Store.getState().type === "loadreport"){
            if(Store.getState().reportOptions!==undefined){
                const reportOptions = Store.getState().reportOptions;
                let reportNames = [];
                reportOptions.map((itm) => {
                    reportNames.push(itm.label);
                    return "";
                });
                this.setState({
                    reportOptions: reportOptions,
                    admin: isAdmin,
                    reportNames: reportNames,
                    reportFieldMap: Store.getState().reportFieldMap
                });
            } 
        } else if(Store.getState().type === "reportUpdate"){
            this.setState({showCreatePopup: true, admin: isAdmin, searchAction: true});
        } else if(Store.getState().type === "featureIDlist"){
            let tempArray=[{value: undefined, label:LocaleUtility.getLocaleMessage("label.select.all")}];
            let list = Store.getState().featureid;
            if(list!== undefined){
                list.forEach(element => {
                    tempArray.push({value:element.toString().split(":")[0],label:element.toString()});
                });
                this.setState({
                    featureidList:tempArray
                })
            }
        } else if(Store.getState().type === "tenantlist"){
            let tempArray=[{value: "null", label:LocaleUtility.getLocaleMessage("label.select.all")}];
            let list = Store.getState().tenantList;
            if(list!== undefined){
                list.forEach(element => {
                    tempArray.push({value:element.toString(),label:element.toString()});
                });
                this.setState({
                    tenantList:tempArray, tenants: list
                })
            }
        } else if(Store.getState().type === "fieldRefresh"){
            if(Store.getState().usageList!==undefined){
                var report = undefined, fieldObj = undefined;
                if(this.state.selReportValue !== undefined){
                    fieldObj = this.state.reportFieldMap.get(this.state.selReportValue.value);
                    report = fieldObj.report;
                    fieldObj.aggregators = Store.getState().repAggr;
                    // if(fieldObj.fields.length >= (this.state.filters.length-1)){
                    //     this.setState({value: {value: this.state.filters.toString(), 
                    //         label: LocaleUtility.getLocaleMessage("label.select.all")}});
                    // }
                    
                }

                var filterOptions = [{value: "null", 
                                    label: LocaleUtility.getLocaleMessage("label.select.all")}];
                                let ggg= Store.getState().attFilterOptions;
            filterOptions.push.apply(filterOptions, Store.getState().attFilterOptions);
                const rows = Store.getState().usageList;
                var sortBy = (this.state.sortBy !== undefined ? this.state.sortBy : Store.getState().sortBy);
                this.setState({
                    reportMeta: fieldObj,
                    reportFields: fieldSet,
                    attFilterOptions: filterOptions
                });
            } 
        }
        // let loadSpinAction = "USAGE_FETCH_SPIN_DONE";
        // Dispatcher.dispatch({
        //     type: loadSpinAction,
        //     payload: ''
        // });
    }

    submitSearch() {
        this.setState({searchAction: true});
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        this.findData(params);
    }
    buildUsageParams(fieldSelect, timePeriod, reportSel, sortBy, sortOrder, reportMeta, timeIndex, filterEle) {
        var array = fieldSelect.value.split(','), timeIds=[], timeProp, index = 1;
        var timePeriodArr = [ "year", "week", "month", "day", "hour"];
        let selIds = [], repField, temp, field, propMap = null;
        var flag = false, filterType = "allfields";
        if(array.some(v => ("null" === v))){
            array = this.state.filters;
            //flag = true; 
        }
        let reportSelectObj = this.state.reportFieldMap.get(reportSel.value);

        if(timeIndex !== undefined){
            index = timeIndex;
        }
        array.map(item => {
            if(item !== "null"){
                if(reportSel !== undefined &&
                        reportSel.value !== ""){
                    repField = this.state.reportFieldMap.get(reportSel.value);
                    temp = repField.fieldMap.get(item)
                    if(temp !== undefined){
                        field = JSON.parse(JSON.stringify(repField.fieldMap.get(item)));
                        if(timePeriodArr.indexOf(field.name) > -1){
                            index = field.showOrder;
                            field = undefined;
                        }
                    } else {
                        field = undefined;
                    }
                } else {
                    field = JSON.parse(JSON.stringify(this.state.fieldSet.get(item)));
                }

                if(field !== undefined)
                    selIds[field.showOrder] = field;
            }
            return "";
        });

        let reportAggr = (repField !== undefined && repField.report.reportAggr !== undefined ? repField.report.reportAggr: '');
        if(timePeriod !== undefined && timePeriod.value !== undefined){
            filterType = "filterfields";
            if(timeProp !== undefined && timeProp.propFieldMap !== undefined){
                propMap = timeProp.name;
            }
            var type = timePeriod.value.split(":"), periodCol;
            if(Number(type[0]) > 0){
                periodCol = "year";
                timeIds.push({"name":"year", "label":LocaleUtility.getLocaleMessage("usage.select.period.year.label"),
                "dataType":"string", "format": "yyyy", "propFieldMap": propMap});
            }
            if(Number(type[0]) > 1){
                if(reportAggr !== ''){
                    periodCol = "month";
                    timeIds.push({"name":"month", "label":LocaleUtility.getLocaleMessage("usage.select.period.month.label"),
                    "dataType":"string", "format": "Mon", "propFieldMap": propMap});
                    periodCol = "week";
                    timeIds.push({"name":"week", "label":LocaleUtility.getLocaleMessage("usage.select.period.week.label"),
                    "dataType":"string", "format": "WW", "propFieldMap": propMap});
                } else if(type[1] === 'WW'){
                    periodCol = "week";
                    timeIds.push({"name":"week", "label":LocaleUtility.getLocaleMessage("usage.select.period.week.label"),
                    "dataType":"string", "format": "WW", "propFieldMap": propMap});
                } else {
                    periodCol = "month";
                    timeIds.push({"name":"month", "label":LocaleUtility.getLocaleMessage("usage.select.period.month.label"),
                    "dataType":"string", "format": "Mon", "propFieldMap": propMap});
                }
            }
            if(Number(type[0]) > 2){
                periodCol = "day";
                timeIds.push({"name":"day", "label":LocaleUtility.getLocaleMessage("usage.select.period.day.label"),
                "dataType":"string", "format": "dd", "propFieldMap": propMap});
            }
            if(Number(type[0]) > 3){
                periodCol = "hour";
                timeIds.push({"name":"hour", "label":LocaleUtility.getLocaleMessage("usage.select.period.hour.label"),
                "dataType":"string", "format": "HH24", "propFieldMap": propMap});
            }
            selIds.splice(index, 0, ...timeIds);
        }

        var showIindex = 0, selPeriodIndex = -1, sortIndex = 0;
        selIds.map(item => {
            if(showIindex === 0 && sortBy === undefined){
                sortBy = item.name;
            }
            item.showOrder = showIindex++;
            if(periodCol === item.name){
                selPeriodIndex = item.showOrder;
            }if(sortBy === item.name){
                sortIndex = item.showOrder;
            }
            return item;
        });
        selIds = selIds.filter(item => item);
        this.setState({selPeriodFilter: timePeriod, reportMeta: reportMeta, 
            selPeriodIndex: selPeriodIndex, sortIndex: sortIndex, sortBy: sortBy, 
            sortOrder: (sortOrder !== undefined ? sortOrder: "ASC")});
        // if(this.state.filters !== undefined && array.length >= this.state.filters.length-1){
        //     flag = true;
        // }

        if(!flag && fieldSelect !== undefined){
            this.setState({value: fieldSelect});
            filterType = "filterfields";
        } else {
            this.setState({selAttrFilter: undefined});
            //filterType = "allfields";
        }

        let reportFilter = null;
        let filterObj = (repField !== undefined ? repField.report.reportFilter: undefined);
        let arr2 = [];
        if(filterEle !== undefined){
            let map = this.state.filterMap;
            arr2 = filterEle.filter(function(itm, index){
                let ele = map.get(index);
                return ele === true;
            });
        }

        if(filterObj !== undefined && filterObj !== null && filterObj !== ''){
            let arr = filterObj.split("|");
            arr.push.apply(arr, arr2);
            reportFilter = arr.join("|");
        } else {
            if(arr2.length > 0)
                reportFilter = arr2.join("|");
        }
        let featureID;
        if(reportSelectObj.report.featureId!==undefined && reportSelectObj.report.featureId!==""){
            featureID=(this.state.selectedFeature !== undefined ? this.state.selectedFeature.value: null);
            if(featureID===null){
                featureID=reportSelectObj.report.featureId;
            }
        }
        let params = {
            fields: selIds,
            productId: this.state.productNameSelected,
            // featureId: (this.state.selectedFeature !== undefined ? this.state.selectedFeature.value: null),
            featureId: featureID,
            reportFilter: reportFilter,
            filterType: filterType,
            sortBy: (sortBy !== undefined ? sortBy: null),
            sortOrder: (sortOrder !== undefined ? sortOrder: "ASC"),
        }
        return params;
    }

    onFilterClicked(index, e) {
        if(e.target !== undefined && e.target.nodeName === 'DIV'){
            let ele = document.getElementById("element_"+index);
            let arr2 = this.state.filterEle;
            if(ele){
                if(ele.classList.contains("filter-unselect")){
                    ele.classList.remove("filter-unselect");
                    ele.classList.add("filter-select");
                    this.state.filterMap.set(index, true);
                } else {
                    ele.classList.remove("filter-select");
                    ele.classList.add("filter-unselect");
                    arr2 = this.state.filterEle.filter(function(itm, row){
                        return row !== index;
                    });
                    this.state.filterMap.set(index, false);
                    //this.state.filterMap.delete(index);
                }
            }
            this.showFilterRecords(arr2);
        }
    }

    continueFilterAction(filterColumn, filterValue) {
        this.setState({searchAction: true});
        let params;
        if(filterColumn === "poolName") {
            this.setState({searchPoolName: filterValue});
            params = this.buildSearchParams(filterValue, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolDesc") {
            this.setState({searchDescription: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, filterValue,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "parent") {
            this.setState({searchParentName: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                filterValue, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolCreatedBy") {
            this.setState({searchCreatedBy: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, filterValue, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolCreationTime") {
            this.setState({searchCreatedValue: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, filterValue,
                this.state.pagination, this.state.sortIndex, true);
        }
        this.findData(params);
    }
    resetPoolGrid() {
        if(Store.getState() && Store.getState().actionType === "resetpools") {
            this.setState({rowData: []});
            this.setState({searchAction: false});
            this.setState({searchPoolName: ""});
            this.setState({searchDescription: ""});
            this.setState({searchParentName: ""});
            this.setState({searchCreatedBy: ""});
            this.setState({searchCreatedValue: ""});
        }
    }

    downloadTemplateForLicense(key, event) {
        FetchHelper.downloadNamedUsers(key, this.context.intl)
    }

    downloadTemplate() {
        let request = {
            "poolIds": []
        }
        FetchHelper.downloadReservationPoolTemplate(request, this.context.intl)
    }

    onFileChange(event) {
        let files = Array.from(event.target.files);
        if(files !== undefined && files.length > 0) {
            let fileName = files[0].name;
            if(fileName.endsWith(".csv") || fileName.endsWith(".CSV")) {
                this.setState({selectedFileName: fileName, submitFiles: files[0]});
                let fileLength = files[0].size;
                let fileSize = 0;
                if(fileLength > 0) {
                    fileSize = fileLength /(1024 * 1024);
                }
                if(fileSize > 5) {
                    ConfigurationUtils.showErrorMessage1("61004", this.context.intl);
                } else {
                    this.continueConfirmAction(files[0], fileName);
                }
            } else {
                ConfigurationUtils.showErrorMessage1("reservation.invalid.template", this.context.intl);
            }
            document.getElementById("import-file-id").value = "";
        }
    }

    exportUsageReport(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        var params = this.buildUsageParams(this.state.value, this.state.selPeriodFilter, 
                        this.state.selReportValue, this.state.sortBy, this.state.sortOrder, 
                        this.state.reportMeta, undefined, this.state.filterEle);
        FetchHelper.downloadUsageReports(params, this.context.intl, mimetype);
    }

    continueConfirmAction(file, fieName) {
        let data = new FormData();
        data.append('file', file);
        data.append('fileName', fieName);
        FetchHelper.importReservationPoolTemplate(data, this.context.intl);
    }
    closeJobsPopup() {
        this.setState({showJobsModal: false});
    }
    showJobsPopup() {
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.jobPopupRef !== undefined && this.jobPopupRef.current !== undefined) {
            this.jobPopupRef.current.loadGrid();
            this.setState({showJobsModal: true});
        }
    }

    updateElements(elements){
        let divs = [], tempEle = [], ele, clName = 'filter-unselect', fieldEle, fieldEleStr = [], fieldLabel;
        elements.map((t, index) => { 
            ele = this.state.filterMap.get(index);
            if(ele === undefined || ele === true){
                tempEle.push(t);
                this.state.filterMap.set(index, true);
                clName = "filter-select";
            } else {
                clName = "filter-unselect";
            }
            fieldLabel = t;
            fieldEleStr = t.split("=");
            fieldEle = this.state.reportFields.get(fieldEleStr[0]);
            if(fieldEle){
                fieldLabel = fieldEle.label ? fieldEle.label+"="+fieldEleStr[1] : t
            }

            divs[index] = 
                <table key={index}> 
                    <tbody> 
                    <tr> 
                    <td>
                    <Tile id={"element_"+index} separator='top' align='start' className={clName}
                        onClick={this.onFilterClicked.bind(this, index)}
                        key={index} style={{display:"inline-block"}}>
                        <Box style={{float:"left", padding:"5px"}}> 
                            {fieldLabel} 
                        </Box>
                        <div style={{padding:"7px", float:"right", width: "19px", height: "19px"}}>
                            <Anchor  title={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                onClick={this.removeElement.bind(this, index)} >
                                <CloseIcon id={"del_"+index} name="delLink" size="huge" className="grommetux-control-icon grommetux-color-index-critical"/>
                            </Anchor>                   
                        </div>
                    </Tile>
                    </td>
                    </tr>
                    </tbody>
                </table>
            ;   
            return t; 
        });
        this.setState({filterTasks: divs, filterEle: elements, update: false});
        var params = this.buildUsageParams(this.state.value, this.state.selPeriodFilter, 
                        this.state.selReportValue, this.state.sortBy, this.state.sortOrder, undefined, undefined, elements);
        // if(params.reportFilter !== undefined && 
        //     params.reportFilter !== null && params.reportFilter !== ''){
        //     let filKeys = [], upFilterArr = [];
        //     if(tempEle.length > 0){
        //         tempEle.map((itm) => {
        //             filKeys.push(itm.split("=")[0]);
        //         });
        //         let arr = params.reportFilter.split("|");
        //         upFilterArr = arr.filter(function(itm){
        //             return (filKeys.indexOf(itm.split("=")[0]) === -1);
        //         });
        //     } else {
        //         upFilterArr = params.reportFilter.split("|");
        //     }          
        //     upFilterArr.push.apply(upFilterArr, tempEle);
        //     params.reportFilter = upFilterArr.join("|");
        // } else {
        //     params.reportFilter = tempEle.join("|");
        // }

        if(tempEle.length > 0){
            params.reportFilter = tempEle.join("|");
        }

        this.findData(params);
    }

    removeElement(key, event){
        const taskArray = Object.assign([], this.state.filterEle);
        taskArray.splice(key, 1);
        this.state.filterMap.delete(key);
        this.updateElements(taskArray);
    }

    showFilterRecords(elements){
        var params = this.buildUsageParams(this.state.value, this.state.selPeriodFilter, 
                        this.state.selReportValue, this.state.sortBy, this.state.sortOrder, undefined, undefined, elements);
        this.findData(params);
    }

    refreshReport(){
        let period;
        if(this.state.selReportValue.value !== ''){
            var fieldObj = this.state.reportFieldMap.get(this.state.selReportValue.value);
            var report = fieldObj.report;
            if(report.periodFilter !== undefined && report.periodFilter !== null){
                period = this.state.timeMap.get(report.periodFilter);
                for(let itm in this.state.timePeriodOptions){
                    if(this.state.timePeriodOptions[itm].value === period){
                        period = this.state.timePeriodOptions[itm];
                        break;
                    }
                }
            } else {
                period = this.state.selPeriodFilter
            }
            this.setState({ reportMeta: undefined, searchAction: true, sortIndex: 0, sortAscending: true});
            var params = this.buildUsageParams({label: fieldObj.fields.toString(), 
                value:fieldObj.fields.toString()}, period, this.state.selReportValue, 
                undefined, undefined, undefined, undefined, this.state.filterEle);
            this.findData(params);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    render() {
        //let intl = this.context.intl;
        let noDataFoundText = this.findLabel("label.no.data.found");
        let showReportEdit = false, showCreate= false;
        const productOptions = [], featureOptions = []; 
        let selReportValue = {value:"", label:LocaleUtility.getLocaleMessage("label.select.product")};
        let selectedValue = {value:"", label:LocaleUtility.getLocaleMessage("label.select.none")};
        productOptions.push(selectedValue);
        let selectedFeature = { value:undefined, label:LocaleUtility.getLocaleMessage("label.select.product")};
        var entries = this.state.productNameSelectOptions.entries(); 
        for(var item of entries){
            productOptions.push({value:item[0], label:item[1]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedValue = {value:item[0], label:item[1]};
                selectedFeature = {value:"", label:LocaleUtility.getLocaleMessage("label.select.metric")};
                selReportValue = {value:"", label:LocaleUtility.getLocaleMessage("label.select.report")};
            }
        }
        this.state.featureSelectOptions.map(value => featureOptions.push({value:value, label:value}));

        if(this.state.selectedFeature !== undefined){
            selectedFeature = this.state.selectedFeature;
        }

        if(this.state.selReportValue !== undefined){
            selReportValue = this.state.selReportValue;
            showReportEdit = true;
        }

        let selAttrFilterValue = this.state.selPeriodFilter;
        if(selAttrFilterValue === undefined) {
            selAttrFilterValue = {value: undefined, 
            label: this.findLabel("label.select.all")}
        }

        let rightSection = "";
        if(this.state.rowData.length === 0) {
            if(this.state.filterEle !== undefined && this.state.filterEle.length > 0) {
                noDataFoundText = this.findLabel("usage.report.search.result.not.found");
            } else {
                noDataFoundText = this.findLabel("usage.report.result.not.found");
            }
        } else {
            rightSection = <div className="floatRight" style={{marginRight:"4px", float: "right"}}>
                            <a title={LocaleUtility.getLocaleMessage("label.button.refresh")} onClick={this.refreshReport}><RefreshIcon id="usage-report-refresh-icon" colorIndex="brand"/></a>
                            <span style={{borderRight: "2px solid #ccc", paddingLeft:"5px"}}></span>
                            <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportUsageReport}><DocumentExcelIcon id="usage-report-excel-icon" colorIndex="brand"/></a>
                            <span style={{borderRight: "2px solid #ccc", paddingLeft:"5px"}}></span>
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportUsageReport}><DocumentDownloadIcon id="usage-report-csv-icon" colorIndex="brand"/></a>
                        </div>
        }

        if(this.state.productNameSelected !== undefined){
            showCreate = true;
        }

        let filterColSpan = 3, showPeriod = false;
        if(this.state.reportMeta !== undefined && this.state.reportMeta.report.reportAggr === undefined){
            filterColSpan = 2; showPeriod = true;
        }

        return(
            <div style={{display:"flex"}}>
            <div id="custom-report-main" style={{paddingLeft: "5px", paddingRight: "5px", width:"auto"}} className="pivot-cont-backgroud">
                <SectionWithTitle title={this.findLabel("resv.pool.manage.title")}  helpTopic="Custom_Reports"
                    setionWidth = "4"
                />

                {this.state.showCreatePopup ? 
                <CreateUsageReport 
                    fieldOptions = {this.state.attFilterOptions}
                    report = {this.state.report}
                    reportNames = {this.state.reportNames}
                    reportFieldMap={this.state.reportFieldMap}
                    fieldSet = {this.state.fieldSet}
                    featureidlist = {this.state.featureidList}
                    tenantList = {this.state.tenantList}
                    tenants = {this.state.tenants}
                    product = {this.state.productName}
                    productId = {this.state.productNameSelected}
                    customMetric = {this.state.customMetric}
                    showModal = {this.state.showCreatePopup}
                    cancelAction = {this.closeCreateReport}
                    createAction = {this.createReportAction}
                    featureFilter = {this.filterReportList}
                /> :
                ( <div>
                    <div>
                        <div className="custom_report_table">
                            <table id="usage-report-sec" style={{float: "left", paddingBottom: "3px", marginTop: "7px", width:`${window.screen.width-50}px`}}>
                                <tbody>
                                    <tr>
                                        <td width="35%">
                                            <Select  label={LocaleUtility.getLocaleMessage("label.select.product")+": "} 
                                            id="generic-usg-report-selProduct" options={productOptions} value={selectedValue} onChange={this.filterProductList.bind(this, false)}/>
                                        </td>
                                        {/* <td>
                                            <Select width={22} label={LocaleUtility.getLocalegetMessage("label.select.metric")+": "} 
                                            id="generic-usg-report-selMetric" options={featureOptions} value={selectedFeature} onChange={this.filterFeatureList.bind(this, false)}/>
                                        </td> */}
                                        <td width="30%">
                                            <Select width={22} label={LocaleUtility.getLocaleMessage("label.select.report")+": "} 
                                            id="generic-usg-report-selection" options={this.state.reportOptions} value={selReportValue} onChange={this.filterReportList.bind(this, false)}/>
                                        </td>
                                        <td>
                                            { (showCreate && this.state.admin) ?
                                                <div className="button_wrap_primary">
                                                <Button
                                                    id="create-report-btn-id" style={{height:"30px"}}
                                                    onClick={this.onClickCreateReport}
                                                    label={this.findLabel("usage.report.field.modal.title")}
                                                    plain={false}
                                                    primary={true}
                                                />
                                                </div>
                                                :
                                                <a title={this.findLabel("usage.tooltip.create.usage.report")}>
                                                    <div className="button_wrap_primary">
                                                    <Button
                                                        className="button_wrap_primary"
                                                        id="create-report-btn-id" style={{height:"30px"}}
                                                        label={this.findLabel("usage.report.field.modal.title")}
                                                        plain={false}
                                                        primary={true}
                                                    />
                                                    </div>
                                                </a>
                                            }

                                        </td>
                                    </tr>
                                    {(this.state.admin && this.state.report !== undefined) ? 
                                    <tr>
                                            <td colSpan="3">
                                                <div><span style={{fontSize: "18px"}}>{this.state.report.reportName}</span> 
                                                <span>{(this.state.report.reportDesc !== undefined && this.state.report.reportDesc !== '')?" ("+this.state.report.reportDesc+")":""}</span>
                                                {showReportEdit?
                                                    <span id="edit-report-btn-id" style={{marginLeft:"10px"}} className="createPoolBtn" onClick={this.onClickEditReport}>
                                                        <span style={{paddingLeft: "5px"}}>{this.findLabel("usage.report.edit.modal.title")}</span>
                                                    </span>
                                                :null}
                                                </div>
                                            </td>
                                    </tr>
                                    :null}
                                    {/* {(this.state.reportMeta !== undefined) ?
                                    <tr>
                                        <td colSpan="2">
                                            <Select id="generic-usg-attribute-selection" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                                                options={this.state.attFilterOptions}
                                                {...{value:this.state.value}}
                                                value={this.state.value}
                                                onChange={this.okAction} 
                                                label= {this.findLabel("usage.graph.select.filter.label", intl)+": "}
                                                enabledSearch = {false}
                                                width= {20}
                                                // disabledScroll = {true}
                                                multiSelect = {true}
                                            />
                                        </td>
                                        <td >
                                            <Select width={10} label={LocaleUtility.getLocaleMessage("usage.select.period.label")+": "} 
                                            id="generic-usg-selperiod" options={this.state.timePeriodOptions} value={selAttrFilterValue} onChange={this.filterPeriodList.bind(this, 1)}/>
                                        </td>
                                    </tr>
                                    :null} */}
                                    <tr>
                                    {(showPeriod) ?
                                        <td style={{ width: '10em',verticalAlign: "top" }} >
                                            <Select width={20} label={LocaleUtility.getLocaleMessage("usage.select.period.label")+": "} 
                                            id="generic-usg-selperiod" options={this.state.timePeriodOptions} value={selAttrFilterValue} onChange={this.filterPeriodList.bind(this, 1)}/>
                                        </td>
                                    :null}
                                    {this.state.searchAction ?
                                        <td colSpan={filterColSpan} style={{verticalAlign: "top" }}>
                                            <div style={{width: "100%", display:"inline-block"}}>
                                            <ColumnFilter style={{float:"left"}} fieldOptions = {this.state.attFilterOptions}
                                                    {...{filters:this.state.filterEle}}
                                                    productId = {this.state.productNameSelected}
                                                    fields={this.state.fields} fieldSet={this.state.fieldSet} 
                                                    reportFields={this.state.reportFields}
                                                    updateAction={this.updateElements}
                                                ></ColumnFilter>
                                            {this.state.filterTasks.length > 0 ? 
                                                <div style={{float:"left"}}>
                                                <Tiles style={{float:"left"}} flush={false} className="filter-values" 
                                                    fill={false}>
                                                    {this.state.filterTasks}                        
                                                </Tiles>  
                                                </div>
                                            : ""}
                                            {rightSection}
                                            </div>
                                        </td>
                                    :null}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{float: "left", padding: "0px 6px 6px 6px", width: "100%"}}>
                            {(this.state.reportMeta !== undefined) ? 
                            <div id="pivot-table-grid" >
                                <TableGrid id="nameduser_lic_grid"
                                    header={this.state.headers}
                                    rowData={this.state.rowData}
                                    pagination={this.state.pagination}
                                    rowSelection={0}
                                    isExportRequired={false}
                                    reportMeta={this.state.reportMeta}
                                    onSort={this.onSortClicked}
                                    sortAscending={this.state.sortAscending}
                                    sortIndex={this.state.sortIndex}
                                    onPagination={this.onPaginationClicked}
                                    noDataFoundText={noDataFoundText}
                                    filterPeriodList = {this.filterPeriodList}
                                    selPeriodIndex = {this.state.selPeriodIndex}
                                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                                    "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                                />
                            </div>
                            :this.findLabel("label.usage.select.report")}
                        </div> 
                        <div>
                            <SpinningNotification centerPage={true} />
                        </div>
                    </div>
                </div>)
            }
            </div>
            </div>
        )
    }
}
// GenericReporting.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default GenericReporting;