import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { CheckBox } from "grommet/components/CheckBox";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import { RadioButton } from 'grommet/components/RadioButton';
import { Anchor } from 'grommet/components/Anchor';
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import UserConfigurationStore from "../../../flux/UserConfigurationStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import * as Utils from "../../../utils/Util";
import "../../../css/App.css";
import "../../../css/index.css";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var initialState = null;

const contentDivStyle = {
    "width":"50%",
    "float":"left"
}
class UserConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
        groups:"",
        users:"",
        usrPassNeverExpiredChecked: false,
        usrPassExpiredChecked: false,
        resetPasswordDisabled: false,
        changePasswordDisabled: false,
        changePassDaysDisabled: false,
        resetPasswordChecked: false,
        changePasswordChecked: false,
        enableNotificationChecked: false,
        groupLdapChecked: false,
        groupDbChecked: false,
        userLdapChecked: false,
        userDbChecked: false,
        userIdmChecked: false,
        userDbDisabled: false,
        userPasswordExpire: "",
        resetPassword: "",
        changePasswordDays: "",
        enableEmailNotificaion: "",
        groupsSource: "",
        usersSource: "",
        implemention: "",
        configUserSetting: "",
        configGroupSetting: "",
        isFormDirty: false,
        enableLdabpLink: false,
        enableIDMLink: false
        
    };
    this.findLabel = this.findLabel.bind(this);
    this.submitUMSettings = this.submitUMSettings.bind(this);
    this.cancelUMSettings = this.cancelUMSettings.bind(this);
    this.handleChangeUserPassNeverExpired = this.handleChangeUserPassNeverExpired.bind(this);
    this.changeUserPassNeverExpired = this.changeUserPassNeverExpired.bind(this);
    this.handleChangeUserPassExpired = this.handleChangeUserPassExpired.bind(this);
    this.changeUserPassExpired = this.changeUserPassExpired.bind(this);
    this.hanldeResetPassword = this.hanldeResetPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.hanldeChangePassword = this.hanldeChangePassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleEnableNotification = this.handleEnableNotification.bind(this);
    this.enableNotification = this.enableNotification.bind(this);
    this.handleChangeGroupLdap = this.handleChangeGroupLdap.bind(this);
    this.changeGroupLdap = this.changeGroupLdap.bind(this);
    this.handleChangeGroupDb = this.handleChangeGroupDb.bind(this);
    this.changeGroupDb = this.changeGroupDb.bind(this);
    this.handleChangeUserLdap = this.handleChangeUserLdap.bind(this);
    this.changeUserLdap = this.changeUserLdap.bind(this);
    this.handleChangeUserDb = this.handleChangeUserDb.bind(this);
    this.changeUserDb = this.changeUserDb.bind(this);
    this.handleChangeUserIdm = this.handleChangeUserIdm.bind(this);
    this.changeUserIdm = this.changeUserIdm.bind(this);
    this.refreshFormData = this.refreshFormData.bind(this);
    this.handlePasswordDays = this.handlePasswordDays.bind(this);
    this.makeFormDirty = this.makeFormDirty.bind(this);
    this.changePassDays = this.changePassDays.bind(this);
    this.onConfirmAction = this.onConfirmAction.bind(this);
  }
  makeFormDirty() {
    this.setState({isFormDirty: true});
  }
  handleChangeUserPassNeverExpired(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeUserPassNeverExpired(val);
  }
  changeUserPassNeverExpired(val) {
    if(val) {
      this.setState({usrPassExpiredChecked: false});
      this.setState({usrPassNeverExpiredChecked: true});
      this.setState({resetPasswordDisabled: true});
      this.setState({changePasswordDisabled: true});
      this.setState({changePasswordDays: ""});
      this.setState({changePassDaysDisabled: true});
      this.setState({resetPasswordChecked: false});
      this.setState({changePasswordChecked: false});
      this.setState({userPasswordExpire: "pne"});
    }
  }
  handleChangeUserPassExpired(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeUserPassExpired(val);
  }
  changeUserPassExpired(val) {
    if(val) {
      this.setState({usrPassExpiredChecked: true});
      this.setState({usrPassNeverExpiredChecked: false});
      this.setState({resetPasswordDisabled: false});
      this.setState({changePasswordDisabled: false});
      this.setState({changePassDaysDisabled: false});
      this.setState({userPasswordExpire: "pe"});
    }
  }
  hanldeResetPassword(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.resetPassword(val);
  }
  resetPassword(val) {
    if(val) {
      this.setState({resetPasswordChecked: true});
      this.setState({resetPassword: "FL"});
    } else {
      this.setState({resetPasswordChecked: false});
      this.setState({resetPassword: ""});
    }
  }
  hanldeChangePassword(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changePassword(val);
  }
  changePassword(val) {
    if(val) {
      this.setState({changePasswordChecked: true});
    } else {
      this.setState({changePasswordChecked: false});
      this.setState({changePasswordDays: ""});
    }
  }
  handleEnableNotification(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.enableNotification(val);
  }
  enableNotification(val) {
    if(val) {
      this.setState({enableNotificationChecked: true});
      this.setState({enableEmailNotificaion: "true"});
    } else {
      this.setState({enableNotificationChecked: false});
      this.setState({enableEmailNotificaion: "false"});
    }
  }
  handleChangeGroupLdap(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeGroupLdap(val);
    this.changeUserLdap(val);
  }
  changeGroupLdap(val) {
    if(val) {
      this.setState({groupLdapChecked: true});
      this.setState({groupDbChecked: false});
      this.setState({userDbDisabled: true});
      this.setState({groupsSource: "ldap"});
    }
  }
  handleChangeGroupDb(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeGroupDb(val);
  }
  changeGroupDb(val) {
    if(val) {
      this.setState({groupLdapChecked: false});
      this.setState({groupDbChecked: true});
      this.setState({userDbDisabled: false});
      this.setState({groupsSource: "database"});
    }
  }
  handleChangeUserLdap(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeUserLdap(val);
  }
  changeUserLdap(val) {
    if(val) {
      this.setState({userLdapChecked: true});
      this.setState({userDbChecked: false});
      this.setState({userIdmChecked: false});
      this.setState({usersSource: "ldap"});
    }
  }
  handleChangeUserDb(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeUserDb(val);
  }
  changeUserDb(val) {
    if(val) {
      this.setState({userDbChecked: true});
      this.setState({userLdapChecked: false});
      this.setState({userIdmChecked: false});
      this.setState({usersSource: "database"});
      if(this.state.groupsSource === undefined || this.state.groupsSource === "" || this.state.groupsSource !== "database") {
          this.changeGroupDb(true);
      }
    }
  }
  handleChangeUserIdm(event) {
    let val = event.target.checked;
    this.makeFormDirty();
    this.changeUserIdm(val);
  }
  changeUserIdm(val) {
    if(val) {
      this.setState({userDbChecked: false});
      this.setState({userLdapChecked: false});
      this.setState({userIdmChecked: true});
      this.setState({usersSource: "idm"});
    }
  }
  handlePasswordDays(event) {
    let val = event.target.value;
    this.makeFormDirty();
    this.changePassDays(val);
  }
  changePassDays(val) {
    this.setState({changePasswordDays: val});
  }
  componentDidMount() {
     ConfigurationUtils.showLoadMask("", this.context.intl);
     FetchHelper.findUserConfiguration(this.context.intl);
     UserConfigurationStore.on("change", this.refreshFormData);
  }
  componentWillUnmount() {
      UserConfigurationStore.removeListener("change", this.refreshFormData);
  }
  findLabel(key, intl) {
    return LocaleUtility.getLocaleMessage(key);
  }

  onConfirmAction(flag){
    if(flag === 1){
      FetchHelper.saveOrUpdateUserConfiguration(this.state, this.context.intl);
    }
  }
  submitUMSettings() {
    if(this.state.isFormDirty && ConfigurationUtils.validateUMConfig(this.state, this.context.intl, this.onConfirmAction) === true) {
      FetchHelper.saveOrUpdateUserConfiguration(this.state, this.context.intl);
    }
  }
  cancelUMSettings() {
    if(initialState) {
      this.setState(initialState);
      this.refreshFormData();
    }
  }
  refreshFormData() {
    let passwordExpire = UserConfigurationStore.getState().userPasswordExpire;
    let resetPass = UserConfigurationStore.getState().resetPassword;
    let noOfDays =  UserConfigurationStore.getState().changePasswordDays;
    let enableEmail =  UserConfigurationStore.getState().enableEmailNotificaion;
    let groupsSource =  UserConfigurationStore.getState().groupsSource;
    let usersSource =  UserConfigurationStore.getState().usersSource;
    let dbImple = UserConfigurationStore.getState().implemention;
    let configGroupSetting = UserConfigurationStore.getState().configGroupSetting;
    this.setState({changePasswordDays: noOfDays});
    this.setState({configGroupSetting: configGroupSetting});
    this.setState({configUserSetting: UserConfigurationStore.getState().configUserSetting});
    if(passwordExpire === 'pne') {
      this.changeUserPassNeverExpired(true);
    } else if(passwordExpire === 'pe') {
      this.changeUserPassExpired(true);
    }
    if(resetPass === 'FL') {
      this.resetPassword(true);
    }
    if(noOfDays) {
      this.changePassword(true);
      this.changePassDays(noOfDays);
    }
    if(groupsSource === 'database') {
      this.changeGroupDb(true);
    } else if(groupsSource === 'ldap') {
      this.changeGroupLdap(true);
    }
    if(usersSource === 'database') {
      this.changeUserDb(true);
      this.setState({enableLdabpLink: false});
      this.setState({enableIDMLink: false});
    } else if(usersSource === 'ldap') {
      this.changeUserLdap(true);
      this.setState({enableLdabpLink: true});
      this.setState({enableIDMLink: false});
    } else if(usersSource === 'idm') {
      this.changeUserIdm(true);
      this.setState({enableLdabpLink: false});
      this.setState({enableIDMLink: true});
      this.setState({groupLdapChecked: false});
      this.setState({groupDbChecked: false});
    }
    if(enableEmail === 'true') {
      this.enableNotification(true);
    }
    if(dbImple){
      this.setState({implemention: dbImple});
    }
    ConfigurationUtils.hideLoadMask(this.context.intl);
    initialState = this.state;
    this.setState({isFormDirty: false});
  }
  render() {
    //let intl = this.context.intl;
    let ldapAnchorText = "";
    let anchorPath = FrontendPagePath.LDAP_CONFIG_PATH;
    if(this.state.enableLdabpLink) {
      ldapAnchorText = this.findLabel("configpageUMLdapHyperlink");
    } else if(this.state.enableIDMLink) {
      ldapAnchorText = this.findLabel("configpageUMIDMHyperlink");
      anchorPath = FrontendPagePath.IDM_CONFIG_PATH;
    }
    return (
      <div className="config-cont-backgroud">
        <div className="border_white_backg_shadow">
          <SectionWithTitle title={this.findLabel("configpageUMTitle")}  helpTopic="UserManagement_conf"/>
          <section pad="none" wrap={false} responsive={false} justify="center">
            <Form compact={false} pad="small" id="conf_um_FormId" plain={true} onSubmit={this.configurationUMSubmit} >
              <div className="configUMPassSetting">
                {!this.state.enableIDMLink && !this.state.enableLdabpLink ? 
                  <div>
                    <h2 className="headerConfig">
                      {this.findLabel("configpageUMConfigPassSettings")}
                    </h2>
                    <div className="separator"></div>
                    <FormField style={{ width: '50em' }} >
                        <RadioButton id="conf_um_PassNeverExpId" 
                          checked={this.state.usrPassNeverExpiredChecked} 
                          onChange={this.handleChangeUserPassNeverExpired}
                          label={this.findLabel("configpageUMUserPassNeverExpire")}/>
                    </FormField>
                    <FormField style={{ width: '50em' }}  >
                        <RadioButton id="conf_um_PassExpId" 
                          checked={this.state.usrPassExpiredChecked} 
                          onChange={this.handleChangeUserPassExpired} 
                          label={this.findLabel("configpageUMUserPassExpire")}/>
                    </FormField>
                    <FormField style={{ width: '50em' }} >
                        <CheckBox id="conf_um_resetPasswordId" 
                          disabled={this.state.resetPasswordDisabled} 
                          checked={this.state.resetPasswordChecked} 
                          onChange={this.hanldeResetPassword} 
                          label={this.findLabel("configpageUMResetPassword")} />
                    </FormField>
                    <div className="inline-flex-wrap password_reset_day">
                      <div className="">
                        {/* <FormField style={{ width: '15em' }}  > */}
                        <FormField>
                          <CheckBox id="conf_um_changePasswordId" 
                            disabled={this.state.changePasswordDisabled} 
                            checked={this.state.changePasswordChecked}
                            onChange={this.hanldeChangePassword}
                            label={this.findLabel("configpageUMChangePassword")}/>
                        </FormField>
                      </div>
                      <div>
                        <FormField style={{ width: '10em' }} label='' >
                          <TextInput id="conf_um_changePasswordDaysId" value={this.state.changePasswordDays} disabled={this.state.changePasswordDisabled} onChange={event =>this.handlePasswordDays(event)} />
                        </FormField>
                      </div>
                      <div>
                        <label>{this.findLabel("configpageUMChangePasswordDays")}</label>
                        {/* <FormField style={{ width: '11em' }} label={this.findLabel("configpageUMChangePasswordDays")} >
                        </FormField> */}
                      </div>
                    </div>
                    <div style={{ width: '50%' }}>
                      <h2 className="headerConfig">
                        {this.findLabel("configpageUMConfigureEmailNotfy")}
                      </h2>
                      <div className="separator"></div>
                    </div>
                    <FormField style={{ width: '50em' }} >
                        <CheckBox id="conf_um_enableEmailNotifId" 
                          checked={this.state.enableNotificationChecked} 
                          onChange={this.handleEnableNotification} 
                          label={this.findLabel("configpageUMEnableEmailNotify")} />
                    </FormField>
                    <div style={{ width: '100%', float: "left", paddingTop:"4px"}}>
                      <h2 className="headerConfig">
                        {this.findLabel("configpageUMConfgSourceUserGroup")}
                      </h2>
                      <div className="separator"></div>
                    </div>
                  </div>
                : null}
                <div>
                  <div style={{ width: '100%', paddingLeft: '4px', float: "left"}}>
                    <h2 className="headerConfig">
                      {this.findLabel("configpageUMGroups")}
                    </h2>
                  </div>
                  <div style={{ width: '20%', paddingLeft: '4px', float: "left"}}>
                      <FormField style={{ width: '10em' }} >
                        <RadioButton id="conf_um_groupLdapId" 
                          checked={this.state.groupLdapChecked} 
                          onChange={this.handleChangeGroupLdap} 
                          label={this.findLabel("configpageUMLDAP")} />
                      </FormField>
                  </div>
                  <div style={{ width: '20%', paddingLeft: '4px', float: "left"}}>
                      <FormField style={{ width: '10em' }} >
                          <RadioButton id="conf_um_groupDbId" 
                            checked={this.state.groupDbChecked} 
                            onChange={this.handleChangeGroupDb} 
                            label={this.findLabel("configpageUMDB")} />
                      </FormField> 
                  </div>
                  <div style={{ width: '100%', paddingLeft: '4px', float: "left"}}>
                    <h2 className="headerConfig">
                      {this.findLabel("configpageUMUsers")}
                    </h2>
                  </div>
                  <div style={{ width: '20%', paddingLeft: '4px', float: "left"}}>
                      <FormField style={{ width: '10em' }} >
                        <RadioButton id="conf_um_userLdapId" 
                          checked={this.state.userLdapChecked} 
                          onChange={this.handleChangeUserLdap} 
                          label={this.findLabel("configpageUMLDAP")}/>
                      </FormField>
                  </div>
                  <div style={{ width: '20%', paddingLeft: '4px', float: "left"}}>
                      <FormField style={{ width: '10em' }} >
                          <RadioButton id="conf_um_userDbId" 
                            disabled={this.state.userDbDisabled} 
                            checked={this.state.userDbChecked} 
                            onChange={this.handleChangeUserDb} 
                            label={this.findLabel("configpageUMDB")}/>
                      </FormField> 
                  </div>
                  <div style={{ width: '20  %', paddingLeft: '4px', float: "left"}}>
                      {/* <FormField style={{ width: '10em' }} >
                          <RadioButton id="conf_um_userIdmId" 
                            checked={this.state.userIdmChecked} 
                            onChange={this.handleChangeUserIdm} 
                            label={this.findLabel("configpageUMIDM", intl)} />
                      </FormField> */}
                  </div>
                  <div style={{width: "100%", float: "left"}}>
                      <Anchor label={ldapAnchorText} href={anchorPath+Utils.buildURLQueryParam()}/>
                  </div>
                  <div style={{width: "100%", float: "left"}}>
                  <div className="saveBtnStyle button_left_wrap">
                    <div className="button_wrap_primary">
                      <Button id="conf_um_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitUMSettings} />
                      </div>
                      <div className="button_wrap_negative">
                      <Button id="conf_um_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelUMSettings} />
                  </div>
                  </div>
                  </div>
                  <div>
                      <SpinningNotification centerPage={true} />
                  </div>
                </div>
              </div>
             </Form>
          </section>
        </div>
      </div>
    );
  }
}
// UserConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UserConfiguration;