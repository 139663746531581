import React, { Component } from "react";
import { Anchor } from "grommet";

class APMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subIndex: 0,
            currentActiveIndex: this.props.currentActiveIndex,
            openedIndex: this.props.openedIndex,
            showCommuterMenu: false,
        }
        this.onActive = this.onActive.bind(this);
        this.deactiveTab = this.deactiveTab.bind(this);
        this.onMouseOverIcon = this.onMouseOverIcon.bind(this);
    }
    componentDidMount() {
        if(this.props.activeIndex === this.props.currentActiveIndex) {
            var element = document.getElementById(this.props.id);
            if(element !== null && element !== undefined &&  element.classList !== undefined) {
                element.classList.remove("Deactivated");
                element.classList.remove("grommetux-button__label");
                element.classList.add("Activated");
                element.classList.add("grommetux-button__label");
            }
        }
    }
    onMouseOverIcon() {
        this.props.onMouseOverIcon();
    }
    onActive(index, activeIndex, id) {
        this.setState({subIndex: index});
        var element = document.getElementById(this.props.id);
        if(element !== null && element !== undefined &&  element.classList !== undefined) {
            try {
                if(this.props.id === "usage") {
                    this.deactiveTab("license");
                    this.deactiveTab("reports");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("configuration");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "license") {
                    this.deactiveTab("usage");
                    this.deactiveTab("reports");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("configuration");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "reservation") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("reports");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("configuration");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "remoteCommuter") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("reports");
                    this.deactiveTab("reservation");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("configuration");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "userManagement") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("reports");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("configuration");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "configuration") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("reports");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "reports") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("configuration");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("buymore");
                    this.deactiveTab("about");
                } else if(this.props.id === "buymore") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("configuration");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("reports");
                    this.deactiveTab("about");
                } else if(this.props.id === "about") {
                    this.deactiveTab("usage");
                    this.deactiveTab("license");
                    this.deactiveTab("configuration");
                    this.deactiveTab("reservation");
                    this.deactiveTab("remoteCommuter");
                    this.deactiveTab("userManagement");
                    this.deactiveTab("reports");
                    this.deactiveTab("buymore");
                }
                element.classList.remove("Deactivated");
                element.classList.remove("grommetux-button__label");
                element.classList.add("Activated");
                element.classList.add("grommetux-button__label");
            } catch(e) {
                console.log("Error in append css class name =>"+e);
            }
        }
        this.props.onActive(Number(index), Number(activeIndex), id);
    }
    deactiveTab(val) {
        var element = document.getElementById(val);
        if(element !== null && element !== undefined &&  element.classList !== undefined) {
            try {
                element.classList.remove("Activated");
                element.classList.remove("grommetux-button__label");
                element.classList.add("Deactivated");
                element.classList.add("grommetux-button__label");
            } catch(e) {
                console.log("Error in append css class name =>"+e);
            }
        }
    }
    render() {
        let divStyle = {lineHeight: "35px", fontSize: "17.6px"};
        if(this.props.icon !== undefined && this.props.icon !== "" && this.props.icon !== '') {
            divStyle = {lineHeight: "35px", fontSize: "17.6px", paddingTop: "6px"};
        }
        let paddingStyle= {marginLeft: "0px"};
        if(this.props.activeIndex === 6) {
            paddingStyle= {marginLeft: "-50px"};
        }
        return(
            <div id={this.props.id} className="bigmenu_items_wrap" style={this.props.width !== undefined ? {width: this.props.width, marginRight: "3px"} : {}}>
                {this.props.menulist === undefined || this.props.menulist.length === 0 ? 
                    <div onMouseOver={this.onMouseOverIcon} style={divStyle} className="grommetux-box grommetux-box--direction-column grommetux-box--responsive grommetux-box--pad-none grommetux-menu grommetux-menu--row grommetux-menu--large grommetux-menu--controlled grommetux-menu__control grommetux-menu--labelled grommetux-button grommetux-button--plain" >
                        <span id={this.props.id+"-icon"} onClick={event=>this.onActive(0, this.props.activeIndex, this.props.id+"_"+this.props.activeIndex)} className="grommetux-button__label">{this.props.icon}</span>
                        <span className="grommetux-button__icon"></span>
                    </div>
                    : 
                    <div className="dropdown">
                        {this.props.icon !== undefined ? 
                            <div style={{marginTop: "2px"}} onMouseOver={this.onMouseOverIcon} className="grommetux-box grommetux-box--direction-column grommetux-box--responsive grommetux-box--pad-none grommetux-menu grommetux-menu--row grommetux-menu--large grommetux-menu--controlled grommetux-menu__control grommetux-menu--labelled grommetux-button grommetux-button--plain">
                                {this.props.icon}
                            </div>
                        : 
                            <div className="menu-label-div">                                
                                <div id={this.props.id+"-header"} onClick={event=>this.onActive(this.props.activeIndex === 1 ? 1 : 0, this.props.activeIndex, this.props.id+"_"+this.props.activeIndex+"_label")} className="slide-menu-lable">{this.props.label}</div>
                                <div>
                                    <div className={this.props.id+"-div"}></div>
                                </div>
                            </div>
                        }
                        {this.props.isSlideMenu ? 
                            <div style={this.props.width !== undefined ? {width: this.props.width} : {}} className="dropdown-content-slide" style={paddingStyle}>
                                <div className="big_menu_wrap">
                                    <ul className="menu_list">
                                        {this.props.menulist !== undefined &&(
                                            Object.entries(this.props.menulist).map(([indx, obj]) =>
                                                <li key={indx} onClick={event=>this.onActive(obj.index, this.props.activeIndex, this.props.id+"_"+this.props.activeIndex)}>
                                                    <span className="slide-menu-item" style={{padding: "0px !important"}}>
                                                        <Anchor id={this.props.id+"_"+obj.index} style={{textDecoration: "none", whiteSpace: "nowrap"}} >
                                                            {obj.value}
                                                        </Anchor>
                                                    </span>
                                                </li>
                                            ))
                                        }
                                        </ul>
                                </div>
                            </div>
                            : 
                            <div className="dropdown-content" style={paddingStyle}>
                                {this.props.menulist !== undefined &&(
                                    Object.entries(this.props.menulist).map(([indx, obj]) =>
                                        <div key={indx} id={this.props.id+"_"+obj.index} onClick={event=>this.onActive(obj.index, this.props.activeIndex, this.props.id+"_"+this.props.activeIndex)}
                                            className={(this.props.activeIndex === this.props.currentActiveIndex && Number(obj.index) === this.props.currentActiveSubIndex) 
                                            ? 'active':''}>
                                            <Anchor style={{color: "#323435", textDecoration: "none", whiteSpace: "nowrap"}} id={this.props.id+"_"+obj.index}>
                                                {obj.value}
                                            </Anchor>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        
                    </div>
                }
            </div>
        )
    }
}
export default APMenu;