import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import UsageHubStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import SectionWithTitle from "../../generic/SectionWithTitle";
import InstantOnReportGraph from "./InstantOnReportGraph";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var timer = null;

class PerInstanceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortIndex: 2,
                sortBy: "startDate",
                sortOrder: "DESC"
            },
            loadedData: false,
            executedByMap: "",
            clientInfoMap: "",
            clientInfoObj: "",
            executedByInfoObj: "",
            sortIndex: 2,
            sortAscending: false,
            poolId: "0",
            exportHistoryRecordSize: 0,
            popupTop: "800px",
            popupLeft: "550px",
            showGraphs: false,
            instanceId: undefined,
            featureId: undefined,
            searchParam: undefined,
            defaultFilter:{clientUniqueId: undefined, featureInfo: undefined}
        }
        this.findLabel = this.findLabel.bind(this);
        this.continueSearchAction = this.continueSearchAction.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.loadInstanceTableGrid = this.loadInstanceTableGrid.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.onClickExecutedByInfo = this.onClickExecutedByInfo.bind(this);
        this.onClickFeatureId = this.onClickFeatureId.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.findData = this.findData.bind(this);
        this.reload = this.reload.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        UsageHubStore.on("change", this.loadInstanceTableGrid);
        this.findData(this.state.sortIndex, this.state.pagination, undefined);
    }
    componentWillUnmount() {
        UsageHubStore.removeListener("change", this.loadInstanceTableGrid);
    }

    onClickClientInfo(key, event) {
        let clientObj = this.state.clientInfoMap[key];
        this.setState({clientInfoObj: clientObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    onClickExecutedByInfo(key, event) {
        let executedObj = this.state.executedByMap[key];
        this.setState({executedByInfoObj: executedObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({clientInfoObj: ""});
        this.setState({executedByInfoObj: ""});
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "startDate";
        index = 2;
        // if(index === 1) {
        //     sortBy = "rejectedClientInfo.licenseExpiryDate";
        // } else if(index === 2) {
        //     sortBy = "startDate";
        // } else if(index === 3) {
        //     sortBy = "rejectedClientInfo.capacity";
        // } else if(index === 5) {
        //     sortBy = "rejectedClientInfo.errorCode";
        // } else if(index === 6) {
        //     sortBy = "rejectedClientInfo.customerErrorMessage";
        // }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.findData(index, paginationTemp, this.state.searchParam);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp, this.state.searchParam);
    }
    reload(){
        this.setState({rowData: []});
        this.findData(this.state.sortIndex, this.state.pagination, undefined);
    }
    findData(sortIndex, pagination, searchParam) {
        this.setState({defaultSelIds: []});
        let params = {};
        params["pagination"] = pagination;
        params["sortIndex"] = sortIndex;
        if(searchParam !== undefined){
            params["featureId"] = searchParam.featureId;
            params["featureVersion"] = searchParam.featureVersion;
            params["featureDescription"] = searchParam.featureDescription;
            params["uniqueInstanceId"] = searchParam.uniqueInstanceId;
        }
        FetchHelper.listInstanceUsageData(this.context.intl, params);
        this.setState({searchParam: searchParam});
        this.setState({loadedData: false});
        this.setState({rowData: []});
    }
    loadInstanceTableGrid() {
        let pageType = UsageHubStore.getState().pageType;
        if(pageType === "instancedetail") {
            let sortIndex = UsageHubStore.getState().sortIndex;
            let sortAscending = UsageHubStore.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});   
            } else {
                this.setState({sortAscending: false});
            }
            if(UsageHubStore.getState().resetFilter){
                this.setState({instanceId: "", featureInfo: ""});
                this.setState({
                        featureId: undefined,
                        featureDesc:  undefined,
                        featureVersion: undefined,
                    });
            }
            this.setState({showGraphs: false, rowData: UsageHubStore.getState().instanceUsageList,
                            licenseUsageMap: UsageHubStore.getState().licenseUsageMap,
                        pagination: UsageHubStore.getState().pagination,
                    });
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }

    onClickFeatureId(value) {
        let instanceDetail = this.state.licenseUsageMap[value];
        localStorage.setItem("featureId:Version", instanceDetail.featureId);
        localStorage.setItem("licenseModel", instanceDetail.licenseModel);
        localStorage.setItem("ioStatus", instanceDetail.status);
        let params = {};
        let featArr = instanceDetail.featureId.split(":");
        params["featureId"] = featArr[0];
        params["featureVersion"] = featArr[1];
        params["uniqueInstanceId"] = instanceDetail.instanceId;
        FetchHelper.listInstanceUsageData(this.context.intl, params);
        this.setState({showGraphs: true, instanceDetail: instanceDetail});
    }

    onClickBack() {
        this.setState({showGraphs: false, instanceId: undefined, featureId: undefined});
    }

    onFilterClicked(filterColumn, filterValue, e, flag) {
        let instanceId = this.state.instanceId, featureInfo = this.state.featureInfo;

        // if(flag || e.charCode === 13) {
        //     e.target.blur();
        //     this.continueSearchAction(filterColumn, filterValue);
        // } else {
        //     if(e.target.value !== ''){
        //         if(filterColumn === "clientUniqueId") {
        //             instanceId = e.target.value;
        //         } else if(filterColumn === "featureInfo") {
        //             featureInfo = e.target.value;
        //         }
        //         this.setState({defaultFilter:{clientUniqueId: instanceId, featureInfo: featureInfo}});
        //     }
        // }
            if(e.target.value !== ''){
                if(filterColumn === "clientUniqueId") {
                    instanceId = e.target.value;
                } else if(filterColumn === "featureInfo") {
                    featureInfo = e.target.value;
                }
                this.setState({instanceId: instanceId, featureInfo: featureInfo});
            }
        if(timer !== null) {
            clearTimeout(timer);  
            
        }
        if(e.charCode === 13) {
            this.continueSearchAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueSearchAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }

    continueSearchAction(filterColumn, filterValue) {
        let instanceId  = this.state.instanceId;
        let featureId = this.state.featureId;
        let featureVersion = this.state.featureVersion;
        let featureDesc = this.state.featureDesc;
        //let searchAction = true;
        let featureInfo = this.state.featureInfo;
        let pagination = this.state.pagination;
        pagination.pageNumber = 1;
        pagination.pageSize = 10;

        if(filterColumn === "clientUniqueId") {
            this.setState({instanceId: filterValue});
            instanceId = filterValue;
        } else if(filterColumn === "featureInfo") {
            var desc = filterValue.match(/([a-zA-Z0-9\s]+)/);
            var featurID = filterValue.match(/(\d{0,}:\d{0,})/);
            featureId = undefined;
            featureVersion = undefined;
            featureDesc = undefined;
            if(desc !== null){
                featureInfo = desc[0];
                if(isNaN(featureInfo) && featureInfo.indexOf(":") === -1){
                    this.setState({featureDesc: featureInfo});
                    featureDesc = featureInfo
                } else {
                    this.setState({featureId: featureInfo, featureDesc: undefined});
                    featureId = featureInfo
                }
            } else {
                this.setState({featureDesc: undefined});
            }
            if(featurID !== null){
                featureInfo = featurID[0];
                let featArr = featureInfo.split(":");
                if(!(featureDesc !== undefined && featureId === undefined)){
                    featureId = featArr[0];
                    featureVersion = featArr[1];
                    this.setState({featureId: featArr[0], featureVersion: featArr[1]});
                }
            } else {
                featureId = featureId;
                featureVersion = undefined;
                this.setState({featureId: featureId, featureVersion: undefined});
            }
            this.setState({featureInfo: filterValue});
        }
        // if(instanceId === "" && featureInfo === "") {
        //     searchAction = false;
        // }
        let request = {
            "featureId": featureId,
            "featureVersion": featureVersion,
            "featureDescription": featureDesc,
            "uniqueInstanceId": instanceId
        }
        
        this.findData(this.state.sortIndex, pagination, request);
    }

    sectionContent() {
        //let intl = this.context.intl;
        
        return (
        <div>
            <div id="intanceon-div-id" style={{width: "100%", float: "left"}}>
                <TableGrid id="usage_instance_list_grid"
                    header={[
                        {
                            columnName: "clientUniqueId",
                            headerLabel: this.findLabel("instance.usage.info"),
                            width: "auto",
                            columnKey: "instanceId",
                            href: true,
                            sort: true,
                            onClick: this.onClickFeatureId,
                            filter: true,
                            filterPlaceHolder: this.findLabel("instance.usage.info")
                        },
                        {
                            columnName: "featureInfo",
                            headerLabel: this.findLabel("label.grid.col.feature"),
                            width: "20%",
                            sort: true,
                            filter: true,
                            filterPlaceHolder: this.findLabel("label.grid.col.feature")
                        },
                        {
                            columnName: "startDate",
                            headerLabel: this.findLabel("usage.search.startdate.label"),
                            width: "auto",
                            sort: true,
                        },
                        {
                            columnName: "expiryDate",
                            headerLabel: this.findLabel("usage.search.expdate.label") ,
                            width: "auto",
                            sort: true,
                        },
                        {
                            columnName: "availableUnits",
                            headerLabel: this.findLabel("resv.reserved.availabel.label"),
                            href: false,
                            width: "12%",
                            sort: false,
                        },
                        {
                            columnName: "liveUnits",
                            headerLabel: this.findLabel("usageReportLive"),
                            href: false,
                            width: "6%",
                            sort: false,
                        },
                        {
                            columnName: "commuterUnits",
                            headerLabel: this.findLabel("page.title.commuter"),
                            href: false,
                            width: "8%",
                            sort: false,
                        },
                        {
                            columnName: "capacity",
                            headerLabel: this.findLabel("usageReportCapacity"),
                            width: "10%",
                            href: false,
                        },
                        {   
                            columnName: "statusString",
                            headerLabel: this.findLabel("job.label.status"),
                            width: "10%",
                            href: false,
                        }
                    ]}
                    rowData={this.state.rowData}
                    pagination={this.state.pagination}
                    rowSelection={0}
                    isExportRequired={false}
                    setDefaultFilter={{clientUniqueId: this.state.instanceId, featureInfo: this.state.featureInfo}}
                    onSort={this.onSortClicked}
                    onFilter={this.onFilterClicked}
                    onPagination={this.onPaginationClicked}
                    sortIndex={this.state.sortIndex}
                    sortAscending={this.state.sortAscending}
                    rowKey="instanceId"
                    noDataFoundText={this.findLabel("no.items.found.message")}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                                        "label.grid.item.count.message", {count: this.state.rowData.length})}
                />
            </div>
            <div>
                <SpinningNotification centerPage={true} />
            </div>
        </div>
        )
    }

    render() {
        if(this.state.showGraphs){
            return (
                <InstantOnReportGraph
                    instanceDetail={this.state.instanceDetail}
                    onClickBack={this.onClickBack}
                />
            )
        } else {
            return (
                <div className="config-cont-backgroud">
                    <SectionWithTitle id="instanton_mainSec" title={LocaleUtility.getLocaleMessage("tab.title.instant.on")} 
                        sectionContent={this.sectionContent()}  helpTopic="Instant_On"/>
                </div>
            );
        }
    }
}
// PerInstanceDetail.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PerInstanceDetail;