import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
//import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

//import { Select } from "grommet/components/Select";
import Select from "../../commons/APLSSelect";
import * as LocaleUtility from "../../../utils/LocaleUtility";
//import {validateSpecialChars} from "../../../utils/ValidationUtility";
import Store from "../../../flux/ReservationManagementStore";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class FieldProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelName: "",
            fieldLabelError: "",
            filterValue: "",
            poolNames: [],
            selectedAttr: [],
            filtertypes: [],
            addAggrError: "",
            selFieldError: "",
            selectedFilter: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("usage.report.select.fields.filter.label")
            },
            selectedFDataType: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            selectedFAggr: {
              value: undefined,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            filtermap: new Map([["string", [
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")}
            ]], ["numeric", [
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")},
                {value: "greaterthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.greaterthan.label")},
                {value: "lesserthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.lesserthan.label")}
            ]]]),
            stdAttr: [
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "std_metricname", label:LocaleUtility.getDefualtString("usage.select.mapping.metricname.label")},
                {value: "std_usagetime", label:LocaleUtility.getDefualtString("usage.select.mapping.usagetime.label")},
                {value: "std_usage", label:LocaleUtility.getDefualtString("usage.select.mapping.usage.label")},
            ],
            dataTypes: [
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "string", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.string.label")},
                {value: "int", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.int.label")},
                {value: "bigint", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.bigint.label")},
                {value: "precision", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.dprecision.label")},
                {value: "datetime", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.date.label")}
            ],
            fieldAggr:[
                {value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")},
                {value: "count", label:LocaleUtility.getLocaleMessage("usage.select.agg.count.label")},
                {value: "sum", label:LocaleUtility.getLocaleMessage("usage.select.agg.sum.label")},
                {value: "max", label:LocaleUtility.getLocaleMessage("usage.select.agg.max.label")},
                {value: "min", label:LocaleUtility.getLocaleMessage("usage.select.agg.min.label")},
                {value: "avg", label:LocaleUtility.getLocaleMessage("usage.select.agg.avg.label")}
            ],
        }
        this.findLabel = this.findLabel.bind(this);
        this.onChangeFieldDataType = this.onChangeFieldDataType.bind(this);
        this.handleChangeLabelName = this.handleChangeLabelName.bind(this);
        this.onChangeFieldMapping = this.onChangeFieldMapping.bind(this);
        this.loadPoolNames = this.loadPoolNames.bind(this);
        this.onChangeFieldAggr = this.onChangeFieldAggr.bind(this);
        this.submitAction = this.submitAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }

    handleChangeLabelName(event) {
        let field= this.state.field, msg = "";
        if(event.target.value !== ''){
           field.label = event.target.value;
        } else {
            msg = LocaleUtility.getLocaleMessage("value.cannot.be.empty");
        }
        this.setState({labelName: event.target.value, field: field, fieldLabelError:msg});
    }
    onChangeFieldMapping(event){
        let arr1 = this.state.stdAttr, attr = [], propMap = undefined, remAttr, field= this.state.field;
        if(event.option.value === undefined && this.state.propMap !== undefined){
            propMap = arr1[0];
            remAttr = this.state.propMap.value;
        } else if(event.option.value !== undefined && this.state.propMap !== undefined){
            if(event.option.value !== this.state.propMap.value){
                propMap = event.option;
            }
            remAttr = this.state.propMap.value;
        }
        this.state.attributes.map(itm => {
            if(remAttr === undefined || remAttr !== itm){
                attr.push(itm);
            }
            return "";
        });
        if(propMap.value !== undefined){
            attr.push(propMap.value);
            field.propFieldMap = propMap.value;
        } else {
            field.propFieldMap = null;
        }

        arr1 = arr1.filter(function(itm){
            return !(attr.some(v => (itm.value === v)));
        });

        this.setState({attributes: attr, propMap: propMap, selectedAttr: arr1, field: field});
    }

    onChangeFieldAggr(event){
        let field = this.state.field;
        if(event.option.value === undefined || (this.state.selectedFDataType.value !== undefined && 
            ((this.state.selectedFDataType.value === 'string' && event.option.value === 'count') || 
            (this.state.selectedFDataType.value !== 'datetime' && this.state.selectedFDataType.value !== 'string' && event.option.value !== 'count')))){
                field.aggregator = event.option.value;
                field.dataType = (this.state.selectedFDataType.value !== undefined ? this.state.selectedFDataType.value: 'string');
                this.setState({selectedFAggr: event.option, field: field, addAggrError:"", selFieldError: ""})
        } else {
            this.setState({selectedFAggr: event.option, selFieldError: "", addAggrError: LocaleUtility.getLocaleMessage("usage.report.create.alert4")});
        }
    }

    onChangeFieldDataType(event){
        let field = this.state.field, type;
        if(this.state.selectedFAggr.value === undefined ||
            (event.option.value !== undefined && ((this.state.selectedFAggr.value === 'count' && event.option.value === 'string') || 
            (event.option.value !== 'datetime' && this.state.selectedFAggr.value !== 'count' && event.option.value !== 'string')))){
                if(event.option.value !== undefined && event.option.value !== ''){
                    field.dataType = event.option.value;
                    field.aggregator = this.state.selectedFAggr.value;
                    if(field.dataType === 'string')
                        type = "string";
                    else if(field.dataType === 'datetime')
                        type = "datetime";
                    else
                        type = "numeric";
                } else {
                    field.dataType = 'string';
                }
                this.setState({selectedFDataType: event.option, field: field,
                    filtertypes: this.state.filtermap.get(type), addAggrError:"", selFieldError: ""});
        } else {
            this.setState({selectedFDataType: event.option, addAggrError:"", selFieldError: LocaleUtility.getLocaleMessage("usage.report.create.alert4")});
        }
    }

    submitAction(){
        if(this.state.addAggrError === '' && this.state.selFieldError === '' && this.state.fieldLabelError === ''){
            this.setState({addAggrError:"", selFieldError: ""});
            this.props.creatAction(this.state.field);
        }
    }
    
    componentDidMount() {
        let arr1 = [{value: undefined, label:LocaleUtility.getLocaleMessage("label.select.none")}], fieldLabel, propMap, 
        fieldMeta, attr, arr2 = [], selectedFDataType, filter, filtertype, type, selFieldAggr;

        if(this.props.field !== undefined){
            fieldMeta = this.props.field;

            if(!(["std_metricname", "std_usagetime", "std_usage"].some(v => (fieldMeta.name === v)))){
                arr1 = this.state.stdAttr
            } 
            attr = this.props.attributes;
            fieldLabel = fieldMeta.label !== undefined ? fieldMeta.label : "";
            if(fieldMeta.propFieldMap === undefined){
                arr1 = arr1.filter(function(itm){
                    return !(attr.some(v => (itm.value === v)));
                });
                propMap = arr1[0];
            } else {
                arr1.map(itm => {
                    if(attr.some(v => (itm.value === v))){
                        if(fieldMeta.propFieldMap === itm.value)
                            propMap = itm;
                    } else {
                        arr2.push(itm);
                    }
                    return "";
                })
                arr1 = arr2;
            }
            if(fieldMeta.dataType === 'string'){
                type = "string";
            } else if(fieldMeta.dataType === 'datetime'){
                type = "datetime";
            } else {
                type = "numeric";
            }
            this.state.dataTypes.map(item => {
                if(item.value === fieldMeta.dataType){
                    selectedFDataType = item;
                }
                return "";
            });
            this.state.fieldAggr.map(item => {
                if(item.value === fieldMeta.aggregator){
                    selFieldAggr = item;
                }
                return "";
            });
            if(fieldMeta.filter !== undefined && fieldMeta.filter !== ''){
                let split = fieldMeta.filter.split(":");
                filter = split[1];
                this.state.filtermap.get(type).map(itm => {
                    if(itm.value === split[0]){
                        filtertype = itm;
                    }
                    return "";
                });
            }
        }
        this.setState({filtertypes: this.state.filtermap.get(type), selectedFDataType: selectedFDataType, 
            selectedFAggr: selFieldAggr, selectedAttr: arr1, labelName: fieldLabel, propMap: propMap, 
            attributes: attr, field: fieldMeta, selectedFilter: filtertype, filterValue: filter});
        Store.on("change", this.loadPoolNames);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.loadPoolNames);
    }
    loadPoolNames() {
        if(Store.getState() && Store.getState().actionType === "poolnames") {
            this.setState({poolNames: Store.getState().poolNames});
        }
    }
    render() {
        //let intl = this.context.intl, 
        let selFError, aggrFError; //,selFieldError, selAggrError;
        let showHideModalStyle = this.props.showModal ? "modal display-block" : "modal display-none";
        if(this.state.addAggrError !== ''){
            aggrFError =<span className="grommetux-form-field__error" style={{top:"40px"}}>
            {this.state.addAggrError}</span>;
            //selAggrError='grommetux-form-field--error';
        } 
        if(this.state.selFieldError !== ''){
            selFError =<span className="grommetux-form-field__error" style={{top:"40px"}}>
            {this.state.selFieldError}</span>;
            //selFieldError='grommetux-form-field--error';
        } 

        return(
            <div id="fieldPopupId" className={showHideModalStyle}>
                <div id="fieldDivId" className="modal">
                    <div className="modal-main panel panel-default" style={{width: "70%"}}>
                        <div>
                            <div className="createPollWindowTitle">
                                {this.findLabel("usage.report.field.properties.popup.title")}
                            </div>
                            <div>
                                <section pad="none" wrap={false} responsive={false} justify="center">
                                {/* <Form compact={false} id="field_form_id" pad="small" plain={true}> */}
                                    <table >
                                        <tbody>
                                        <tr>
                                            <td colSpan="3">
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("usage.report.field.name.label")} error={this.state.fieldLabelError} >
                                                    <TextInput id="field_label_id" autoFocus={true}
                                                        value={this.state.labelName} 
                                                        onChange={event =>this.handleChangeLabelName(event)}
                                                    />
                                                </FormField>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{verticalAlign:"top", paddingLeft: "10px"}}>
                                                {/* <FormField style={{ width: '40em' }} label={LocaleUtility.getLocaleMessage("usage.field.mapping.std.label")}> */}
                                                    <Select id="field_map_type" width={20}
                                                    label={LocaleUtility.getLocaleMessage("usage.field.mapping.std.label")}
                                                    options={this.state.selectedAttr}
                                                    value={this.state.propMap}
                                                    onChange={this.onChangeFieldMapping} />
                                                {/* </FormField> */}
                                            </td> 
                                            <td style={{verticalAlign:"top", paddingLeft: "10px"}}>
                                                {/* <FormField style={{ width: '40em' }} className={selFieldError} > */}
                                                    <Select id="field_datatype" width={20}
                                                    label={LocaleUtility.getLocaleMessage("usage.report.select.fields.type.label")}
                                                    options={this.state.dataTypes}
                                                    value={this.state.selectedFDataType}
                                                    onChange={this.onChangeFieldDataType} />
                                                    {selFError}
                                                {/* </FormField> */}
                                            </td>
                                        {/* </tr><tr> */}
                                            <td style={{verticalAlign:"top", paddingLeft: "10px"}}>
                                                {/* <FormField style={{ width: '40em' }} className={selAggrError} > */}
                                                <Select id="usage_rep_field_aggr" width={20}
                                                    label={LocaleUtility.getLocaleMessage("usage.report.select.fields.aggregator.label")}
                                                    options={this.state.fieldAggr}
                                                    value={this.state.selectedFAggr}
                                                    onChange={this.onChangeFieldAggr} />
                                                    {aggrFError}
                                                {/* </FormField> */}
                                            </td>
                                            {/* <td></td> */}
                                        </tr>
                                        <tr>
                                            <td colSpan="3" >
                                            <div className="button_left_wrap" style={{float: "right"}}>
                                                <div className="button_wrap_primary"><Button id="sumit_fld_btn" label={this.findLabel("label.button.submit")} plain={false} primary={true} onClick={(event)=>this.submitAction()} /></div>
                                                <span style={{padding: "5px"}}></span>
                                                <div className="button_wrap_secondary"><Button id="canel_fld_btn" label={this.findLabel("label.button.cancel")} plain={false} secondary={true} onClick={this.props.cancelAction} /></div>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                {/* </Form> */}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// FieldProperty.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default FieldProperty;