import React, { Component } from "react";
import { Button } from "grommet/components/Button";

import { Form } from "grommet/components/Form";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import { Select } from "grommet/components/Select";
import { Trash as TrashIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import ComponentNotification from "../../generic/ComponentNotification";
import Dispatcher from "../../../flux/Dispatcher";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class AddFeatures extends Component {
     constructor(props) {
        super(props);
        this.state = {
            dataForFetares:[],
            dataForProductCode:[],
            dataForProductVersion:[],
            addFeaturesList:[],
            tempAddPFList:[],
            tempDelPFList:[],
            selectedPdcode: {
              label: LocaleUtility.getLocaleMessage("label.select.none"),
              value: undefined
            },
            selectedPdversion: {
                label: LocaleUtility.getLocaleMessage("label.select.none"),
              value: undefined
            },
            selectedFeatureOpt: {
              label: LocaleUtility.getLocaleMessage("label.select.none"),
              value: undefined
            },
            selectedid:"",
            selectedMap:"",
            addFeaturesList:this.props.addpList,
            dataForProductCode:this.setDataForProductCode()
        }
        this.findLabel = this.findLabel.bind(this);
        this.onChangeProductCode=this.onChangeProductCode.bind(this);
        this.onChangeProductVersion=this.onChangeProductVersion.bind(this);
        this.onChangeProductFeature=this.onChangeProductFeature.bind(this);
        this.addProductFeature=this.addProductFeature.bind(this);
        this.deleteFeature=this.deleteFeature.bind(this);
        this.applyproductFeature=this.applyproductFeature.bind(this);
        this.cancelPopup=this.cancelPopup.bind(this);
        this.setDataForProductCode = this.setDataForProductCode.bind(this);
       // this.getUnique=this.getUnique.bind(this);
         
     }
     findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }

    setDataForProductCode(){
        var fetlist=this.props.pdrowData;
        var tempdataForProductCode=[];
        let tempdataForProductVersion=[];
        var tempdataForFetares=[];
        fetlist.forEach(element => {     
            tempdataForProductCode.push({label:element.productCode, value:element.productCode});
            tempdataForProductVersion.push({label: LocaleUtility.getLocaleMessage("label.select.none"), value: undefined});
            tempdataForFetares.push({label: LocaleUtility.getLocaleMessage("label.select.none"), value: undefined});
        });
        tempdataForProductCode=this.getUnique(tempdataForProductCode,"value");
        return tempdataForProductCode;
    }

    // UNSAFE_componentWillMount()
    // {
    //       var fetlist=this.props.pdrowData;
    //       var tempdataForProductCode=[];
    //       let tempdataForProductVersion=[];
    //       var tempdataForFetares=[];
    //       fetlist.forEach(element => {     
    //            tempdataForProductCode.push({value:element.productCode,label:element.productCode});
    //            tempdataForProductVersion.push({value: undefined,label: LocaleUtility.getLocaleMessage("label.select.none")});
    //            tempdataForFetares.push({value: undefined,label: LocaleUtility.getLocaleMessage("label.select.none")});
    //         });
    //     tempdataForProductCode=this.getUnique(tempdataForProductCode,"value");
    //     this.setState({dataForProductCode:tempdataForProductCode,addFeaturesList:this.props.addpList});
    // }
    //  componentWillUnmount() {
    //  }
    onChangeProductCode(event){
        var fetlist=this.props.pdrowData;
        var tempdataForProductVersion=[];
        fetlist.forEach(element => { 
            if(element.productCode===event.option.label){
                tempdataForProductVersion.push({label:element.productCurrentVersion, value:element.productCurrentVersion});
            }
        });
        tempdataForProductVersion=this.getUnique(tempdataForProductVersion,"value");
        
        this.setState({selectedPdcode:event.option.value,dataForProductVersion:tempdataForProductVersion,selectedPdversion:LocaleUtility.getLocaleMessage("label.select.none"),selectedFeature:LocaleUtility.getLocaleMessage("label.select.none")});        
    }
    onChangeProductVersion(event){
        var fetlist=this.props.pdrowData;
        var tempdataForFetares=[];
        fetlist.forEach(element => { 
            if(element.productCode===this.state.selectedPdcode && element.productCurrentVersion === event.option.label){
                //console.log(element.featureDescription+" ("+element.featureId+":"+element.featureVersion+")");
                tempdataForFetares.push({value:element.featureId,label:element.featureDescription+" ("+element.featureId+":"+element.featureVersion+")"});
            }
        });
        tempdataForFetares=this.getUnique(tempdataForFetares,"value");
        this.setState({selectedPdversion:event.option.value,dataForFetares:tempdataForFetares,selectedFeatureOpt:LocaleUtility.getLocaleMessage("label.select.none")});        
    }
    onChangeProductFeature(event){
        var fetlist=this.props.pdrowData;
        let tempselectedid="";
        let tempselectedMap="";
        fetlist.forEach(element => {
            if(element.productCode===this.state.selectedPdcode && element.productCurrentVersion === this.state.selectedPdversion && element.featureId ===  event.option.value){
                tempselectedid=element.id;
                tempselectedMap=element.mappedValue;
            }
        });
        this.setState({selectedFeature:event.option.label,selectedFeatureOpt:event.option.value,selectedid:tempselectedid,selectedMap:tempselectedMap});
    }
    addProductFeature(){
        var productcodevalue=document.getElementById("usage_token_feature_productcode").value;
       var productversionvalue=document.getElementById("usage_token_feature_productversion").value;
       var pfeaturevalue=document.getElementById("usage_token_feature_pfeature").value;
       let sameObjectCount=0;
       if(LocaleUtility.getLocaleMessage("label.select.none") === productcodevalue){
           this.showMessageAction(this.findLabel("selectproductcode"), "COMP_INVALID");         
      }else if(LocaleUtility.getLocaleMessage("label.select.none") === productversionvalue){
            this.showMessageAction(this.findLabel("selectproductversion"), "COMP_INVALID");
      }else if(LocaleUtility.getLocaleMessage("label.select.none") === pfeaturevalue){
            this.showMessageAction(this.findLabel("selectproductfeature"), "COMP_INVALID");
      }
      else{
        var tempaddFeaturesList=this.state.addFeaturesList;
        var tpfList=this.state.tempAddPFList;
         for( var i = 0; i < tempaddFeaturesList.length; i++){ 
          if ( tempaddFeaturesList[i].id === this.state.selectedid) {
          sameObjectCount++;
        }
      }
      if(sameObjectCount > 0)
      {
        this.showMessageAction(this.findLabel("token.adding.same.feature.mapping"), "COMP_INVALID");  
      }else{
        tempaddFeaturesList.push({productCode:this.state.selectedPdcode,productCurrentVersion:this.state.selectedPdversion,featureId:this.state.selectedFeature,id:this.state.selectedid,mappedValue:this.state.selectedMap});
        tpfList.push({productCode:this.state.selectedPdcode,productCurrentVersion:this.state.selectedPdversion,featureId:this.state.selectedFeature,id:this.state.selectedid,mappedValue:this.state.selectedMap});
         this.setState({addFeaturesList:tempaddFeaturesList,
                        selectedPdversion:LocaleUtility.getLocaleMessage("label.select.none"),
                        selectedPdcode:LocaleUtility.getLocaleMessage("label.select.none"),
                        selectedFeature:LocaleUtility.getLocaleMessage("label.select.none"),
                        selectedid:"",
                        selectedMap:"",
                        dataForFetares:[],
                        dataForProductVersion:[],
                        tempAddPFList:tpfList
                    });
                }
     }
    }
    deleteFeature(itemId){
        
        let plist=this.state.addFeaturesList;
        let newplist=[];
         plist.forEach(element => {
              if(element.id !== itemId){
                newplist.push(element);
              }else{
                  this.state.tempDelPFList.push(element);
              }
         });
         this.setState({addFeaturesList:newplist});
    }
    getUnique(arr, comp) {
        const unique = arr
       .map(e => e[comp])

     // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

   return unique;
    }
    cancelPopup(){
        let tempaddFeaturesList=this.state.addFeaturesList;
        let tpfList=this.state.tempAddPFList;
        let spl=this.state.tempDelPFList;
        let idstring="";     
        tpfList.forEach(element => {
            idstring=idstring+","+element.id;
        });              
         spl.forEach(element => {
            idstring=idstring+","+element.id;
        });
        //console.log(idstring);
        var compeletList=[];
        tempaddFeaturesList.forEach(element => {
           // console.log(idstring.indexOf(element.id));
            
            if( idstring.indexOf(element.id) <= -1 ){
                compeletList.push(element);
            }
        });
        spl.forEach(element => {            
                compeletList.push(element);           
        });
        //console.log(compeletList.length);
        this.setState({addFeaturesList:compeletList,tempAddPFList:[],tempDelPFList:[]});
        this.props.cancelAction(compeletList);
    }
    applyproductFeature(){
        if(this.state.addFeaturesList.length>0){
        this.props.applyAction(this.state.addFeaturesList);
        }else{
             this.showMessageAction(this.findLabel("selectFeature"), "COMP_INVALID"); 
        }
    }
    showMessageAction(payload, type) {
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
    
     render(){
          //let intl = this.context.intl;
          let showHideModalStyle = this.props.showModal ? "add-dep-feature-modal display-block" : "add-dep-feature-modal display-none";
          return(
                 <div id="tokenMain" className={showHideModalStyle}>
                    <div id="tokenMainDivId" className="add-dep-feature-modal">
                         <div  className="modal-main panel panel-default" style={{width: "50%"}}>
                            <div className="createPollWindowTitle">
                                {this.props.title}
                            </div>
                       
                        <section pad="none" wrap={false} responsive={false} justify="center">
                             <div>
                                <ComponentNotification />
                            </div>
                        {/* <Form compact={false} id="create_pool_form_id2" pad="small" plain={true}>                         */}
                          
                             <div> 
                        <section pad="none" wrap={false} responsive={false} justify="center">
                               <Form compact={false} id="usage_list_formId2" pad="small" plain={true}>
                                 <div>
                                    <table>
                                        <tr>
                                            <td>{LocaleUtility.getLocaleMessage("label.productCode")}</td>
                                            <td>{LocaleUtility.getLocaleMessage("productVersion")}</td>
                                            <td style={{width: "40%"}}>{LocaleUtility.getLocaleMessage("featurebasedReservation")}</td>
                                            <td><span style={{paddingRight: "10px"}}></span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Select id="usage_token_feature_productcode" placeholder={this.findLabel("label.productCode")} 
                                                    options={this.state.dataForProductCode}
                                                    value={this.state.selectedPdcode}
                                                    onChange={this.onChangeProductCode} 
                                                    labelKey="label"
                                                    valueKey={{ key: 'value', reduce: true }}
                                                    />
                                            </td>
                                            <td>
                                                
                                                 <Select id="usage_token_feature_productversion" placeholder={this.findLabel("productVersion")} 
                                                    options={this.state.dataForProductVersion}
                                                    value={this.state.selectedPdversion}
                                                    onChange={this.onChangeProductVersion} 
                                                    labelKey="label"
                                                    valueKey={{ key: 'value', reduce: true }}
                                                    />
                                            </td>
                                            <td>
                                                
                                                 <Select id="usage_token_feature_pfeature" placeholder={this.findLabel("featurebasedReservation")}
                                                    options={this.state.dataForFetares}
                                                    value={this.state.selectedFeatureOpt}
                                                    onChange={this.onChangeProductFeature} 
                                                    labelKey="label"
                                                    valueKey={{ key: 'value', reduce: true }}
                                                    />
                                                
                                            </td>
                                            <td>
                                                <div className="button_wrap_primary">
                                                 <Button id="usage_token_restBtn" label={this.findLabel("label.token.add")} plain={false} primary={true} onClick={this.addProductFeature} />
                                                 </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"> <span style={{padding: "10px"}}></span></td>
                                        </tr>
                                        </table>
                                        <div className="token_modal_tableBody" style={{height: "163px",overflow:"auto"}}>
                                        <table>
                                         {this.state.addFeaturesList.map((item, index) => (
                                            <tr key={index}>
                                            <td style={{maxWidth: "30%"}}>{item.productCode}</td>
                                            <td style={{width: "10%"}}>{item.productCurrentVersion}</td>
                                            <td style={{width: "50%"}}>{item.featureId}</td>
                                            <td style={{width: "1rem"}}> 
                                                <Anchor onClick={(event) => this.deleteFeature(item.id)}>
                                                    <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
                                                </Anchor>
                                            </td>
                                            </tr>
                                        ))}                                        
                                    </table>
                                    </div>
                                </div>                               
                                </Form>
                              <div>
                                <SpinningNotification centerPage={true} />
                                </div>
                            
                        </section>
                        </div>
                        {/* </Form> */}
                        
                        </section>
                             <Form compact={false} id="token_popup__buttons_form_id" className="button_left_wrap" pad="small" plain={true}>  
                             <div className="button_wrap_primary">
                         <Button   id="token_popup_applyBtn" label={LocaleUtility.getLocaleMessage("label.button.apply")}
                            plain={false} primary={true} onClick={this.applyproductFeature} />
                            </div>
                            <div className="button_wrap_negative">
                        <Button id="token_popup_canelBtn" label={this.findLabel("label.button.cancel")} plain={false} secondary={true} onClick={this.cancelPopup} />
                       </div>
                        </Form>
                       </div>
                    </div>
                </div>
          );
     }

}
// AddFeatures.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AddFeatures;