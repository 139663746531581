import React, { Component } from "react";
import Aboutpic from '../../../img/aboutpic.png';
import OTGrey from '../../../img/OT_grey.png';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Modal from "../../generic/Modal";
import {getSession} from  "../../../utils/FetchHelper";

import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class About extends Component {
    constructor(props) {
      super(props);
	  this.state = { count: 1, secondCount: 1,showModal: false , session: {}};
	  this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
	  this.renderModal = this.renderModal.bind(this);
    }

	componentDidMount(){
        var data = getSession(true);
        data.then(session => {
            if(session !== undefined) {
				this.setState({session: session})
            }
        })
        .catch(error => {
            // handle error
        });
    }

    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
	}

	onModalCloseCallback(){
		this.props.onCallBack();
	}

	renderModal() {
		return (<div id="about_modal_wrap" className="button_wrap_negative button_right_wrap"><Modal title={LocaleUtility.getLocaleMessage("label.reset.password.success.title")} showModal={this.props.showModal}  styleProps={{width: "auto"}}
					closeLabel={LocaleUtility.getLocaleMessage("label.button.close")} 
					sectionContent={this.aboutModelContent()} 
					primary={true}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    /></div>);
		
	}

	render() {
		 return (
            <div>                
                {this.renderModal()}
            </div>
        );

	}

    aboutModelContent() {
      return (<div className="about-div-cls"> 
	  {/* <div className="abouttable">
		<div className="header1"></div>
	</div> */}
<div className="abouttable flex_spcbtn">
	<div id="versionInfo">
			{LocaleUtility.getLocaleMessage("label.about.version")}
		  { /*<s:property value="%{getText(\'label.version\')}" />*/ } 
		  &nbsp;
		  { this.state.session.aplsVersion }
		</div>
		<div id="buildNumberInfo">
			{LocaleUtility.getLocaleMessage("label.about.build.number")}					
		&nbsp;
		{ this.state.session.aplsBuildNumber }		
		</div>
</div>
<div className="abouttable">
	<div><img src={Aboutpic} alt="" /></div>
</div>
<div className="abouttable">
	<div id="aboutPagetitle1">
			{LocaleUtility.getLocaleMessage("label.about.apls.license.info")}
		{ /*<s:label
				value="%{getText('loginPage.licinfo')}" cssclassName="appName"></s:label>*/ }
				
		</div>
</div>
<div className="abouttable">
	<div>
	<div className="aboutPage_title2">
			{ /*<s:property
					value="%{getText('label.license')}" />:</td>*/ }
					{LocaleUtility.getLocaleMessage("license")}:
					</div>
					
			<div className="aboutPage_title2">
			{LocaleUtility.getLocaleMessage("label.reset.password.success.title")}
			</div>
			</div>
			<div>
		<div className="aboutPage_title2">
			
					{LocaleUtility.getLocaleMessage("usageSearchStartDate")}
					:</div>			
				<div className="aboutPage_title2">
					{ this.state.session.aplsLicStDate }				
						</div>
						</div>
						<div>
		<div className="aboutPage_title2">
				{LocaleUtility.getLocaleMessage("usageSearchExpdate")}
					:</div>
			<div className="aboutPage_title2">
				{ this.state.session.aplsLicExpDate }
					</div>
					</div>
	{ /*</s:iterator>*/ }
	</div>
<div className="abouttable">
	{/* <tbody>
	<tr bgcolor="white" style={{"width": "760px"}}>
		<td className="linecss"></td>
	</tr> */}
		<div nowrap="true" className="aboutPage_title5">
		{/*<s:property value="%{getText('about.copyright_updated')}" escapeHtml="false"/> */}
		{LocaleUtility.getLocaleMessage("about.copyright")}		
		</div>
	
	{/* <tr bgcolor="white" style={{"width": "760px"}}>
		<td className="linecss"></td>
	</tr> */}
	<div className="about_page_logo">
			<img className="headerlogo" alt={LocaleUtility.getDefualtString("Logo")} src={OTGrey} />
	
		{/* <td style={{"float": "right", "height": "35px", "textAlign": "center"}}><span 
			style={{"float": "right"}}> 

			</span></td> */}
	</div>
</div>
	  </div>);
    }
}
// About.contextTypes = {
//   intl: _propTypes2.default.object,
// };

export default About;