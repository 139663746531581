import React, { Component } from "react";
import "../../../css/index.css";

import TableGrid from "../../../components/commons/table/APTable";
import { Button } from "grommet/components/Button";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import ComponentNotification from "../../generic/ComponentNotification";
import * as FetchHelper from "../../../utils/FetchHelper";

class ProductSearch extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            productSearch: this.props.productSearch,
            prodList: [],
            sortIndex: 0,
            sortAscending: true,
            selectAllRows: false,
            selectedRowKeys:"",
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            }
           
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.closeSearchButton = this.closeSearchButton.bind(this);
        this.handleAddButton = this.handleAddButton.bind(this);
       
    }

    closeSearchButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        this.setState({
            selectedRowKeys: []
        })
        this.props.closeSearchButton();
    }


    handleAddButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.state.prodList.length > 0){
            const formPayload = {
                productList:this.state.prodList,
                groupName:this.state.groupName
            };

            this.props.handleAddButton(formPayload);
            this.setState({
                selectedRowKeys: []
            })
        } else {
            Dispatcher.dispatch({
                type: "COMP_INVALID",
                payload: LocaleUtility.getLocaleMessage("um.alert15")
            });
        }
    }
    onRowClicked(keys, event){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
       this.setState({
        prodList:keys,
        groupName:this.props.groupName
       })      
    }

    infoSectionContent() {

        const showHideModalStyle = this.props.productSearch ? "modal display-block" : "modal display-none";

           return (
            <div id="umProdSerchResultDiv" className={showHideModalStyle}>
                <div className="modal">

                     <div className="modal-main panel panel-default" style={{maxWidth: "50%", marginTop: "20px", maxHeight: "96%"}}>
                        <div className="panel-heading">
                            <h2 className="panel-title">{this.props.title}</h2>
                        </div>
                        <div className="panel-body maxHeight-scroll">
                        <ComponentNotification />
                        <section pad="none" wrap={false} responsive={false} justify="center">
                            <TableGrid id="product_list"
                                    header={[
                                        {
                                            columnName: "product",
                                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.productname"),
                                            width: "auto",
                                            columnKey: "key",
                                        }
                                        
                                    ]}
                                    {...{keys:this.state.selectedRowKeys}}
                                    rowData={this.props.productData}
                                    pagination={this.props.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.onPaginationClicked}
                                    sortIndex={this.props.sortIndex}
                                    sortAscending={this.props.sortAscending}
                                    rowKey="key"
                                    onChangeCheckBoxCallback={this.onRowClicked}
                                    selectAllRows={false}
                                />
               
                        </section>
                        
                        </div>
                        <div id="umProdSearchButtonContainer" style={{ width: '30em'}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="button_left_wrap">
                                                <div className="button_wrap_primary">
                                                    <Button id="grp_prod_AddBtn" label={LocaleUtility.getLocaleMessage("label.button.add")}
                                                        type="button" plain={false} primary={true} onClick={this.handleAddButton}
                                                    />
                                                </div>
                                                <div className="button_wrap_negative">
                                                    <Button id="grp_prod_Cancelbtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" accent={true} plain={false} onClick={this.closeSearchButton} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
        
    }

    render() {
        var showContent;
        if(this.props.productData !== undefined){
            showContent=  this.infoSectionContent();
        }
        return (
            <div>
               {showContent}
            </div>
        );
    }
}

export default ProductSearch;