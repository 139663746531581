import React, { Component } from "react";
import { Notification } from 'grommet/components/Notification';
import MessageStore from "../../flux/SampleStore";

class ComponentNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      secondCount: 1,
      message: "",
      style: this.props.style,
      status:undefined
    };

    this.setMessage = this.setMessage.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  setMessage() {
    this.setState({ 
      message: MessageStore.getState().message, 
      status: MessageStore.getState().status });
  }

  onCloseHandle() {
    this.setState({ 
      message: "", 
      status: undefined });
  }


  componentDidMount() {
    MessageStore.on("change", this.setMessage);
  }

  componentWillUnmount() {
    MessageStore.removeListener("change", this.setMessage);
  }

  render() {
    return (
      <section pad="small" justify="center" align="center">
        <div className={`${this.state.status} notification-message-comp`} style={this.state.style}>
          {this.state.message !== "" && this.state.status !== undefined && (
            <Notification id="message-id" message={this.state.message} status={this.state.status} size="medium" closer={true} onClose={this.onCloseHandle}/>
          )}
        </div>
      </section>
    );
  }
}

export default ComponentNotification;