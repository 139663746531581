import React, { Component } from "react";
import "../../../../css/index.css";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class TenantCheckedOutLicenseDetail extends Component {

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <div id="lic_bor_detailModal">
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr className="lic-detail-head">
                                <td>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.featureid.version")}</td>
                                <td>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity")}</td>
                                <td>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate")}</td>
                                <td>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate")}</td>
                                <td>{LocaleUtility.getLocaleLabel(this.context.intl, "label.tenant.col.tenantFrom")}</td>
                            </tr>
                            <tr>
                                <td>{licDetail.featureID +' : '+licDetail.featureVersion}</td>
                                <td>{licDetail.capacity}</td>
                                <td>{licDetail.startDate}</td>
                                <td>{licDetail.expiryDate}</td>
                                <td>{licDetail.userName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    render() {
        return (
             this.infoSectionContent(this.props.licenseDetail)
        );
    }
}

// TenantCheckedOutLicenseDetail.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default TenantCheckedOutLicenseDetail;