import ReduceStore from "./ReduceStore";

class CommuterStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "PROCESS_CHECKOUT_SUCCESS": {
                return {
                    data: action.payload.data,
                    actionType: "checkout",
                    showListView: action.payload.showListView
                };
            }
            case "PROCESS_CHECKIN_SUCCESS": {
                return {
                    data: action.payload.data,
                    clientInfoMap: action.payload.clientInfoMap,
                    actionType: "checkin",
                    showCheckinListView: action.payload.showCheckinListView
                };
            }
            case "PROCESS_DOWNLOAD_SUCCESS": {
                return {
                    data: action.payload.data,
                    actionType: "commuterdownload"
                };
            }
            default:
                return state;
        }
    }
}
export default new CommuterStore();