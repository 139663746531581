import React from "react";
import { TextInput } from 'grommet/components/TextInput';
import { CheckBox } from "grommet/components/CheckBox";
import { Anchor } from 'grommet/components/Anchor';
import { RadioButton } from 'grommet/components/RadioButton';
import TableConstant from "./TableConstant";
import { Ascend } from 'grommet-icons';
import { Descend } from 'grommet-icons';
import "../css/APTable.scss";
import { FormNext as FormNextIcon } from 'grommet-icons';
import { FormDown as FormDownIcon } from 'grommet-icons';
import { DateInput } from 'grommet/components/DateInput';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { getLocaleMessage, LOCALE } from "../../../../utils/LocaleUtility";
import {FlexEllipsisText} from "@noxy/react-flex-ellipsis-text";

export function createHead(header, rowSelection, sortIndex, sortAscending,
     sortCallbackMethod, isSelectAllClicked, onChangeCheckBoxCallback, selectAllRows, childKey) {
    let headerData = [];
	let checkBoxWidth = "3em";
	let checkBoxHeaderCls = "table-grid-th-checkbox";
    if (rowSelection === 1 || rowSelection === 2) {
        headerData.push(<th className={checkBoxHeaderCls} key="1" className="secondnthirdTh"/>);
    } else if (rowSelection === 3) {
        var checkBoxElement;

        if(selectAllRows !== undefined){
            var classVar="grommetux-check-box";
            if(isSelectAllClicked !== undefined && !isSelectAllClicked &&
                selectAllRows.length > 0){
                classVar="grommetux-check-box partial";
            }
            checkBoxElement = <CheckBox className={classVar} checked={(selectAllRows.length > 0)} onChange={onChangeCheckBoxCallback.bind(this)}/>
        } else {
            checkBoxElement = <CheckBox onChange={onChangeCheckBoxCallback.bind(this)}/>
        }
        headerData.push(
            <th className={checkBoxHeaderCls} key="1" style={{ width: checkBoxWidth }}>
                {checkBoxElement}
            </th>
        );
    } else if (rowSelection === 0) {
		headerData.push(<th key="1" className="firstHead"/>);
	}
	let headerLength = header.length;
    header.map((value, index) => {
        let width = (value[TableConstant.__WIDTH]) ? value[TableConstant.__WIDTH] : "";
        let headerLabel = value[TableConstant.__LABEL];
		let columnName = value[TableConstant.__NAME];
		let infoIcon = value[TableConstant.__INFOICON];
		let infoIconText = value[TableConstant.__INFOICONTEXT];
		let tootipAlignment = value[TableConstant.__TOOL_TIP_ALIGNMENT];
        
        var sorting = "";
        var sortOrder = true;
        if (index === sortIndex) {
          sorting = sortAscending ? true : false;
          sortOrder = !sortAscending;
        }
		let sortClassName=""
		if(sorting!==""){
			sortClassName = sorting?"itsAscending":"itsDescending";
		}
		let floatTipDirection = tootipAlignment !== undefined && tootipAlignment !== "" ? tootipAlignment :"";
        if(value[TableConstant.__SORT] === true) {
            headerData.push(
                <td style={{cursor: 'pointer'}} key={columnName}
                    className={`table-row-header ${sortClassName}`} width={width}
                    onClick={(e) => {
                        sortCallbackMethod(index, sortOrder, header);
                    }}
                >
                    <span>
                        {headerLabel}
                    </span>
					{/* <span style={{paddingLeft: "2px",position:"absolute"}}>
						{infoIcon !== undefined && infoIcon === true ? 
							<div className="Infotooltip"><CircleInformationIcon colorIndex="brand" />
							<span className={"InfotooltipText " + floatTipDirection}>
								{infoIconText}
							</span>
							</div>
						:null}
					</span>
                    <span style={{float: 'right', marginLeft: "1px"}}>
                        {sorting}
                    </span> */}
                </td>
            );
        } else {
            headerData.push(
                <td style={{cursor: 'pointer'}} key={columnName}
                    className="table-row-header" width={width}>
                    <span>
                        {headerLabel}
                    </span>
					<span style={{paddingLeft: "2px",position:"absolute"}}>
						{infoIcon !== undefined && infoIcon === true ? 
							<div className="Infotooltip"><CircleInformationIcon colorIndex="brand" />
							<span className={"InfotooltipText " + floatTipDirection}>
								{infoIconText}
							</span>
							</div>
						:null}
					</span>
                </td>
            );
		}  
		if(index !== headerLength-1) {
			headerData.push(<td className="colResizer"/>);
		}
		return "";
    });
    return headerData;
};

//Create Table Filter based on filter props
export function createFilter(header, rowSelection, defaultFilter, filterCallbackMethod) {
    let headerFilter = [];
    let showFilter = false;
    //if (rowSelection !== 0) {
        headerFilter.push(<td key="100" />);
	//}
	let headerLength = header.length;
    header.map((value,index) => {
		let width = (value[TableConstant.__WIDTH]) ? value[TableConstant.__WIDTH] : "";
		let colSpan = "1";
		if(index !== headerLength-1) {
			colSpan = "2";
		}
        if (value[TableConstant.__FILTER]) {
			let placeHolder = value[TableConstant.__FILTERPLACEHOLDER];
			if(placeHolder === undefined) {
				placeHolder = value[TableConstant.__LABEL];
			}
			let fieldType = value[TableConstant.__FILTERFIELDTYPE];
			let element;
			if(fieldType === 'date') {
				element = <DateInput id={value[TableConstant.__LABEL]} className="datepicker"
					format={value[TableConstant.__FILTERFIELDFORMAT]}
					name={value[TableConstant.__NAME]}
					value={value[TableConstant.__FILTERFIELDVALUE]}
					placeholder={placeHolder}
					calendarProps={({locale:LOCALE})}
					onChange={filterCallbackMethod.bind(this, value[TableConstant.__NAME])}
				/>
			} else {
				if(defaultFilter !== undefined){
					element = <TextInput
						id={value[TableConstant.__LABEL]}
						name={value[TableConstant.__NAME]}
						value={defaultFilter[value[TableConstant.__NAME]]}
						clearButtonMode='always'
						suggestions={undefined}
						placeholder={placeHolder}
						onChange={(e) => {
							filterCallbackMethod(e.target.name, e.target.value, e, false);
						}}
					/>
				} else {
					element = <TextInput
						id={value[TableConstant.__LABEL]}
						name={value[TableConstant.__NAME]}
						suggestions={undefined}
						placeholder={placeHolder}
						onKeyUp={(e) => {
							filterCallbackMethod(e.target.name, e.target.value, e);
						}}
					/>
				}
			}
            headerFilter.push(
                <td colSpan={colSpan} className="table-filter-td" width={width} key={value[TableConstant.__LABEL]}>{element}</td>
            );
            showFilter = true;
        }
        else {
            headerFilter.push(
                <td colSpan={colSpan} className="table-filter-td" width={width} key={value[TableConstant.__LABEL]}> </td>
            );
		}
		return "";
    });
    if (showFilter) {
        return headerFilter;
    }
	return "";
};

//Create Table Body based on rowData
export function createBody(rowData, rowSelection, header, rowKey,
    onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, noDataFoundText, addExtraRow) {
    let rows = [], columnLength;
	let noDataFound = getLocaleMessage("label.no.data.found");
	if(noDataFoundText !== undefined && noDataFoundText !== '') {
		noDataFound = noDataFoundText;
	}
    if (rowData!==undefined && rowData.length > 0) {
		if(addExtraRow !== undefined && addExtraRow === true) {
			columnLength = header.length;
			if (rowSelection === 0 || rowSelection === 1 || rowSelection === 2 || rowSelection === 3) {
				columnLength = header.length + 1;
			}
			columnLength = columnLength + (header.length - 1);
			rows.push(<tr className="table-grid-tr" key={0}><td style={{textAlign: "center"}} colSpan={columnLength} key={header.length}>{noDataFoundText}</td></tr>);
		}
        rowData.map((row, index) => {
			if(row) {
				let columns = createRowData(row, index, rowSelection, header, rowKey, onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, false, 0);
				let trId = "grid-tr-"+(row[rowKey] !== undefined ? row[rowKey] : index);
				rows.push(<tr className="table-grid-tr" key={trId} id={trId}>{columns}</tr>);
				if(row[childKey] && row[childKey].length > 0) {
					recursiveCall(rows, row[childKey], row, index, rowSelection, header, rowKey,
						onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, true, 1);
				}
			}
			return "";
        });
    } else {
		columnLength = header.length;
		if (rowSelection === 0 || rowSelection === 1 || rowSelection === 2 || rowSelection === 3) {
			columnLength = header.length + 1;
		}
		columnLength = columnLength + (header.length - 1);
        rows.push(<tr className="table-grid-tr" key={0}><td style={{textAlign: "center"}} colSpan={columnLength} key={header.length}>{noDataFound}</td></tr>);
    }
    return rows;
}
export function recursiveCall(rows, rowData, row, index, rowSelection, header, rowKey,
    onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, childRowPadding, paddCount) {
	let paddingCount = paddCount;
	rowData.map((row, index) => {
		if(row) {
			let columns = createRowData(row, index, rowSelection, header, rowKey, onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, childRowPadding, paddingCount);
			let trId = "grid-tr-"+row[rowKey];
			if(expandAll) {
				rows.push(<tr className="table-grid-tr" key={trId} id={trId}>{columns}</tr>);
			} else {
				rows.push(<tr className="table-grid-tr" key={trId} style={{"display": "none"}} id={trId}>{columns}</tr>);
			}
			if(row[childKey] && row[childKey].length > 0) {
				recursiveCall(rows, row[childKey], row, index, rowSelection, header, rowKey,
							onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, true, (paddingCount+1));
			}
		}
		return "";
	});
}
export function createRowData(row, index, rowSelection, header, rowKey,
    onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, childRowPadding, paddingCount) {
	var checkBoxElement;
	/*Create checkbox selected based on selectAllRows property*/
	if(defaultSelectIds !== undefined && defaultSelectIds.length > 0) {
		if(selectAllRows === undefined || rowSelection === 1) {
			selectAllRows = [];
		}
		defaultSelectIds.map( e => {
			if(selectAllRows.indexOf(e) === -1) {
				selectAllRows.push(e);
			}
			return "";
		});
	}
	let toolTipValue = "";
	if(row["toolTipKey"] !== undefined && row["toolTipKey"] !== "") {
		toolTipValue = row["toolTipKey"];
	}
	if(selectAllRows === undefined) {
		selectAllRows = [];
	}
	if(disabledRowIds === undefined) {
		disabledRowIds = [];
	}
	checkBoxElement = <CheckBox checked={selectAllRows.indexOf(row[rowKey]) > -1} disabled={disabledRowIds.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
	/*if(selectAllRows !== undefined && disabledRowIds !== undefined){
		if(uncheckTheDisabled && disabledRowIds.indexOf(row[rowKey]) > -1){
			checkBoxElement = <CheckBox disabled={disabledRowIds.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
		} else {
			checkBoxElement = <CheckBox checked={selectAllRows.indexOf(row[rowKey]) > -1} disabled={disabledRowIds.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
		}
	} else if(selectAllRows !== undefined){
		checkBoxElement = <CheckBox checked={selectAllRows.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
	} else if(disabledRowIds !== undefined){
		checkBoxElement = <CheckBox disabled={disabledRowIds.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
	}  else {
		checkBoxElement = <CheckBox onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/>
	}*/

	let columns = [];
	if(row["hiddenCheckBox"] !== undefined && row["hiddenCheckBox"] === "Y") {
		rowSelection = 0;
	}
	/*Create first column based on rowSelection*/
	if (rowSelection === 1) {
		let radioButtonId = "radio-"+row[rowKey];
		columns.push(<td key={row[rowKey]} className="table-grid-radio-button"><RadioButton label="" id={radioButtonId} name="radio" checked={selectAllRows.indexOf(row[rowKey]) > -1} onChange={onChangeCheckBoxCallback.bind(this, row[rowKey])}/></td>);
		
	} else if (rowSelection === 2 || rowSelection === 3) {
		if(toolTipValue !== "") {
			columns.push(<td key={row[rowKey]} className="table-grid-checkbok-button"><a title={toolTipValue}>{checkBoxElement}</a></td>);
		} else {
			columns.push(<td key={row[rowKey]} className="table-grid-checkbok-button">{checkBoxElement}</td>);
		}
		
	} else if (rowSelection === 0) {
		columns.push(<td key={row[rowKey]} className="tableRowStyle1">&nbsp;</td>);
	}

	/*Create rest of the column based on header*/
	let i = 1, value, isHREF, isCOL_KEY, isON_CLICK_CALLBACK, element, editable, editableType, afterEditCallBack, beforeEditCallBack,enabledToolTip,colToolTipText, textAlignValue;
	for (let col of header) {
		if (i <= header.length) {
			value = row[col[TableConstant.__NAME]];
			isHREF = col[TableConstant.__HREF];
			isCOL_KEY = col[TableConstant.__COLUMNKEY];
			isON_CLICK_CALLBACK = col[TableConstant.__ONCLICK];
			editable = col[TableConstant.__EDITABLE];
			editableType = col[TableConstant.__EDITFIELDTYPE];
			afterEditCallBack = col[TableConstant.__AFTEREDIT];
			beforeEditCallBack = col[TableConstant.__BEFOREEDIT];
			enabledToolTip = col[TableConstant.__ENABLED_TOOL_TIP];
			textAlignValue = col[TableConstant.__TEXT_ALIGN_VALUE];
			if(enabledToolTip) {
				colToolTipText = row[col[TableConstant.__NAME]+TableConstant.__TOOL_TIP_TEXT];
			}
			let clsName = "tableRowStyle";
			if(col[TableConstant.__CLASSNAME]) {
				clsName = col[TableConstant.__CLASSNAME];
			}
			element = undefined;
			if (isHREF) {
				//element = <a href={isHREF}>{value}</a>;
				element = <Anchor>{value}</Anchor>
			} 
			if (isCOL_KEY && isON_CLICK_CALLBACK) {
				//element = <a onClick={isON_CLICK_CALLBACK.bind(this, row[isCOL_KEY])}>{value}</a>;
				element = <Anchor onClick={isON_CLICK_CALLBACK.bind(this, row[isCOL_KEY])}>{value}</Anchor>;
			} 
			if(isHREF && (isCOL_KEY && isON_CLICK_CALLBACK)){
				//element = <a href={isHREF} onClick={isON_CLICK_CALLBACK.bind(this, row[isCOL_KEY])}>{value}</a>;
				element = <Anchor onClick={isON_CLICK_CALLBACK.bind(this, row[isCOL_KEY])}>{value}</Anchor>;
			}
			if(element){
				value = element;
			}
			let colkey = "col"+row[rowKey]+"-"+i;
			let colSpanVal = "1";
			if(i !== header.length) {
				colSpanVal = "2";
			}
			let alignTextV = textAlignValue !== undefined ? textAlignValue : "left";
			if(i === 1 && childRowPadding) {
				let paddingCss = {paddingLeft: (paddingCount*20)+"px"};
				if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
					if(afterEditCallBack) {
						if(row[childKey] && row[childKey].length > 0) {
							if(expandAll) {
								columns.push(<td colSpan={colSpanVal} key={colkey}><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></span></td>);
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey}><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand"/></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></span></td>);
							}
						} else {
							columns.push(<td colSpan={colSpanVal} key={colkey}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></span></td>);
						}
					} else {
						if(row[childKey] && row[childKey].length > 0) {
							if(expandAll) {
								columns.push(<td colSpan={colSpanVal} key={colkey}><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} /></span></td>);
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey}><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} /></span></td>);
							}
						} else {
							columns.push(<td colSpan={colSpanVal} key={colkey}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={paddingCss}><TextInput className={clsName} type={editableType} defaultValue={value} /></span></td>);
						}
					}
				} else {
					if(row[childKey] && row[childKey].length > 0) {
						if(expandAll) {
							columns.push(<td colSpan={colSpanVal} key={colkey}><p style={paddingCss} className="treegrid-p"><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;{value}</p></td>);
						} else {
							columns.push(<td colSpan={colSpanVal} key={colkey}><p style={paddingCss} className="treegrid-p"><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;{value}</p></td>);
						}
					} else {
						paddingCss = {paddingLeft: (paddingCount*25)+"px"};
						if(paddingCount === 1) {
							paddingCss = {paddingLeft: (paddingCount*40)+"px"};
						}
						columns.push(<td colSpan={colSpanVal} key={colkey} className={clsName}><p style={paddingCss} className="treegrid-p">{value}</p></td>);
					}
				}
			} else {
				if(i === 1) {
					if(row[childKey] && row[childKey].length > 0) {
						if(expandAll) {
							if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
								if(afterEditCallBack) {
									columns.push(<td colSpan={colSpanVal} key={colkey} ><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></td>);
								} else {
									columns.push(<td colSpan={colSpanVal} key={colkey} ><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} /></td>);
								}
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey} className={clsName}><p className="treegrid-p"><span id={"Expand-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;{value}</p></td>);
							}
						} else {
							if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
								if(afterEditCallBack) {
									columns.push(<td colSpan={colSpanVal} key={colkey} ><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></td>);
								} else {
									columns.push(<td colSpan={colSpanVal} key={colkey} ><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} /></td>);
								}
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey} className={clsName}><p className="treegrid-p"><span id={"Expand-"+row[rowKey]} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormNextIcon colorIndex="brand" /></span><span id={"Collapse-"+row[rowKey]} style={{"display": "none"}} onClick={onClickExpand.bind(this,childKey, row, rowKey)}><FormDownIcon colorIndex="brand" /></span>&nbsp;{value}</p></td>);
							}
						}
					} else if(childKey !== undefined) {
						if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
							if(afterEditCallBack) {
								columns.push(<td colSpan={colSpanVal} key={colkey} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></td>);
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<TextInput className={clsName} type={editableType} defaultValue={value} /></td>);
							}
						} else {
							columns.push(<td colSpan={colSpanVal} tooltiptext={enabledToolTip ? colToolTipText : undefined} key={colkey} className={clsName}><p className="treegrid-p">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value}</p></td>);
						}
					} else {
						if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
							if(afterEditCallBack) {
								columns.push(<td colSpan={colSpanVal} key={colkey} ><TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></td>);
							} else {
								columns.push(<td colSpan={colSpanVal} key={colkey} ><TextInput className={clsName} type={editableType} defaultValue={value} /></td>);
							}
						} else {
							columns.push(<td colSpan={colSpanVal} tooltiptext={enabledToolTip ? colToolTipText : undefined} key={colkey} style={{textAlign: alignTextV}} className={clsName}>
									{value instanceof Object ? value : <FlexEllipsisText placement="bottom" tooltip={value}>
										<span className="grid-cell-value-span">{value}</span>
									</FlexEllipsisText>}
								</td>);
						}
					}
				} else {
					if(editable === true && editableType && beforeEditCallBack && afterEditCallBack) {
						if(afterEditCallBack) {
							columns.push(<td colSpan={colSpanVal} key={colkey} ><TextInput className={clsName} type={editableType} defaultValue={value} onBlur= {afterEditCallBack.bind(this, row)} onClick={beforeEditCallBack.bind(this, row)} /></td>);
						} else {
							columns.push(<td colSpan={colSpanVal} key={colkey} ><TextInput className={clsName} type={editableType} defaultValue={value} /></td>);
						}
					} else {
						columns.push(<td colSpan={colSpanVal} tooltiptext={enabledToolTip ? colToolTipText : undefined} key={colkey} style={{textAlign: alignTextV}} className="tableColumnStyle">
								{value instanceof Object ? value : <FlexEllipsisText placement="bottom" tooltip={value}>
									<span className="grid-cell-value-span">{value}</span>
								</FlexEllipsisText>}
							</td>); 
					}
				}
			}
		}
		i++;
	}
	return columns;
}
function onClickExpand(childKey, rowData, rowKey, event) {
	let expand = document.getElementById("Expand-"+rowData[rowKey]);
	let disabled = false;
	if(expand.style.display !== "none") {
		document.getElementById("Collapse-"+rowData[rowKey]).style.display = "inline-block";
		document.getElementById("Expand-"+rowData[rowKey]).style.display = "none";
		//selRow.style.display = 'table-row';
	} else {
		document.getElementById("Expand-"+rowData[rowKey]).style.display = "inline-block";
		document.getElementById("Collapse-"+rowData[rowKey]).style.display = "none";
		//selRow.style.display = 'none';
		disabled = true;
	}
	if(rowData[childKey] && rowData[childKey].length > 0) {
		enableOrDisable(disabled, rowData[childKey], rowKey, childKey);
	}
}
function enableOrDisable(disabled, rowData, rowKey, childKey) {
	rowData.map((row, index) => {
		var childRow = document.getElementById("grid-tr-"+row[rowKey]);
		if(childRow !== null) {
			if(disabled) {
				childRow.style.display = 'none';
				let expandEl = document.getElementById("Expand-"+row[rowKey]);
				let collapseEl = document.getElementById("Collapse-"+row[rowKey]);
				if(expandEl) {
					expandEl.style.display = "inline-block";
				}
				if(collapseEl) {
					collapseEl.style.display = "none";
				}
			} else {
				childRow.style.display = 'table-row';
			}
		}
		if(row[childKey] && row[childKey].length > 0 && disabled) {
			enableOrDisable(disabled, row[childKey], rowKey, childKey);
		}
		return "";
	});
}