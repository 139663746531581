import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Anchor } from 'grommet/components/Anchor';
import TableGrid from "../../../components/commons/table/APTable";
import Store from "../../../flux/APTableStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { getLocaleMessage }from "../../../utils/LocaleUtility";
import { Search as SearchIcon } from 'grommet-icons';
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import SpinningNotification from '../../generic/SpinningNotification';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import "../../../css/index.css";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      rowData: [],
      originalContent:true,
      searchContent:false,
      searchResult:false,
      sortIndex: 3,
      sortAscending: false,
      pagination: {
              required: true,
              style: "default",
              totalCount: 0,
              pageNumber: 1,
              pageSize: FetchHelper.getPageSize()
          },
        }
        this.refreshState = this.refreshState.bind(this);
        this.searchReport = this.searchReport.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleProductNameChange = this.handleProductNameChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleIPChange = this.handleIPChange.bind(this);
        this.handleSearchBackButton = this.handleSearchBackButton.bind(this);
        this.exportExcelAction = this.exportExcelAction.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
  }

componentDidMount() {
      let loadSpinAction = "LICENSE_REPORT_FETCH_INPROGRESS_LOADING_SPIN";
      Dispatcher.dispatch({
          type: loadSpinAction,
          payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
      });
      var postData = {
          pagination: this.state.pagination
      }
      FetchHelper.getLicenseReport(postData);
      Store.on("change", this.refreshState);
  }

componentWillUnmount() {
      Store.removeListener("change", this.refreshState);
    }

    refreshState() {
      let pagination = Store.getState().searchPagination;
      this.setState({
            rowData: Store.getState().tableData,
            pagination: pagination,
            sortAscending : (pagination !== undefined && pagination.sortOrder === "ASC"? true:false),
            sortIndex: (pagination !== undefined && pagination.sortBy === "clientLastAccessTime" ? 3: this.state.sortIndex),
            originalContent:true,
            searchContent:false,
            searchResult:Store.getState().searchResult !== undefined? Store.getState().searchResult: false,
        });
        ValidationUtility.hideLoadMask("", "REPORTS_PAGE_SPIN_DONE");
    }

  onPaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.pagination
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    var postData = {
      pagination: paginationTemp
    }
    FetchHelper.getLicenseReport(postData);
  } 

  onSortClicked(index, order, header) {
    var sortOrder = "ASC";
    let paginationTemp = this.state.pagination;
    let productName = this.state.productNameSelected;
    let sortAscending = true;
    let sortBy = header[index].columnName;

    if(paginationTemp.sortOrder === "ASC"){
        sortOrder = "DESC";
        sortAscending = false;
    }
    
    paginationTemp.sortBy = sortBy;
    if( sortBy === "reqIpAdd" ){ 
      paginationTemp.sortBy = "requesterIpAddress";
    } else if(sortBy === "lastAcsTime"){
      paginationTemp.sortBy = "clientLastAccessTime"; 
    }

    paginationTemp.sortOrder = sortOrder;
    paginationTemp.pageNumber = paginationTemp.pageNumber;
    this.setState({ pagination: paginationTemp, sortAscending: sortAscending, sortIndex: index});
    var postData = {
      pagination: paginationTemp
    }
    FetchHelper.getLicenseReport(postData);
  }


  callback1(loca, hist) {
    if (this.routerLocation.pathname === "/sublink2") {
      this.setState({ activeIndex: 1 });
    }
  }

  searchReport(){
    this.setState({
      //originalContent:false,
      searchContent:true,
      searchProdName: '',
      searchProdVersion: '',
      searchClientIP: ''
    });
  }
  handleSearchButton(){
    this.setState({
      originalContent:false,
      searchContent:false,
      searchResult:true,
      sortIndex: 3,
      sortAscending: false,
      rowData:[]
    });

    let pagination = this.state.pagination;
    if(this.state.pagination === undefined){
      pagination = {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize()
        }
    }
    const payload={
      productName : this.state.searchProdName !== undefined ? this.state.searchProdName: '',
      productVersion : this.state.searchProdVersion !== undefined ? this.state.searchProdVersion: '',
      reqIpAdd : this.state.searchClientIP !== undefined ? this.state.searchClientIP: '',
      pagination: pagination,
      isExportExcel: false
    }

    FetchHelper.getSearchResult(payload);

  }
  exportExcelAction(e) {
    let mimetype = e.currentTarget.attributes.mimetype.value;
    const payload = {
      productName : this.state.searchProdName,
      productVersion : this.state.searchProdVersion,
      reqIpAdd : this.state.searchClientIP,
      isExportExcel: true
    }
    FetchHelper.downloadLicenseReports(payload, undefined, mimetype);
  }
  handleBackButton(){
    this.setState({
      originalContent:true,
      searchContent:false,
      searchResult:false,
    });
    var postData = {
        pagination: this.state.pagination
    }
    FetchHelper.getLicenseReport(postData);
  }
  handleProductNameChange(e){
    this.setState({ searchProdName: e.target.value });
  }

  handleVersionChange(e){
     this.setState({ searchProdVersion: e.target.value });
  }

  handleIPChange(e){
    this.setState({ searchClientIP: e.target.value });
  }
  handleSearchBackButton(){
     this.setState({
      //originalContent:false,
      searchContent:true,
    });
  }
  sectionContent(){
    const originalContent = this.state.originalContent ? "display-block" : "display-none";
    const searchResult = this.state.searchResult ? "display-block" : "display-none";
    let showSearchContent = this.state.searchContent ? "license-report-search-popup display-block" : "license-report-search-popup display-none";
    return(
      <div className={originalContent+" license_report_content_wrap"}>
         <div >
                  <TableGrid
                                        header={[
                                            {
                                                columnName: "productName",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.productname"),
                                                width: "auto",
                                                columnKey: "productName",
                                                sort: true,
                                                //onClick: this.onColumnClicked
                                            },
                                            {
                                                columnName: "productVersion",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.productversion"),
                                                href: false,
                                                sort: true,
                                                width: "auto",
                                            },
                                            {
                                                columnName: "reqIpAdd",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.reqIpAddress"),
                                                href: false,
                                                sort: true,
                                                width: "auto",
                                            },
                                            {
                                                columnName: "lastAcsTime",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.lastaccesstime"),
                                                href: false,
                                                sort: true,
                                                width: "auto",
                                            }
                                        ]}
                                        rowData={this.state.rowData}
                                        pagination={this.state.pagination}
                                        isExportRequired={false}
                                        onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        onPagination={this.onPaginationClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        //rowKey="productNumber"
                                        onChangeCheckBoxCallback={this.onRowClicked}
                                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                        selectAllRows={false}
                                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                        "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                                    />
                      
                        <div className={searchResult}>
                            <div className="button_wrap_secondary">
                          <Button label={LocaleUtility.getLocaleMessage("label.button.back")} type="button" accent={true} plain={false} onClick={this.handleSearchBackButton} />
                        </div>
                        </div>
                        </div>
                        <div className={showSearchContent}>        
                          <Form compact={false} pad="small" plain={true}>
                            <FormField label={LocaleUtility.getLocaleMessage("label.grid.col.productname")} >
                                  <TextInput id='productName_report' value={this.state.searchProdName} name='productName_report' onChange={event =>this.handleProductNameChange(event)} />
                              </FormField>
                              
                              <FormField label={LocaleUtility.getLocaleMessage("label.grid.col.productversion")}>
                                  <TextInput id='productVersion_report' value={this.state.searchProdVersion} name='productVersion' onChange={event =>this.handleVersionChange(event)} />
                              </FormField>
                              <FormField label={LocaleUtility.getLocaleMessage("label.grid.col.reqIpAddress")} >
                                  <TextInput id='requesterIPAddress_report' value={this.state.searchClientIP} name='requesterIPAddress' onChange={event =>this.handleIPChange(event)}/>
                                
                              </FormField>                              
                              <div className="button_right_wrap">
                                  <div className="button_wrap_primary">
                                    <Button id="searchButton" label={LocaleUtility.getLocaleMessage("label.button.search")} type="button" plain={false} primary={true} onClick={this.handleSearchButton}        />
                                  </div>
                                  <div className="button_wrap_negative">
                                      <Button label={LocaleUtility.getLocaleMessage("usage.report.cancel.button.label")} type="button" accent={true} plain={false} onClick={this.handleBackButton} />
                                  </div>
                              </div>
                          </Form>
                      </div>
              </div>
    );
  }

  render() {

    let rightSection, title=LocaleUtility.getLocaleMessage("label.report.title"), sectionContent = this.sectionContent();
    if(!this.state.searchResult && this.state.rowData.length > 0) {
      rightSection = <div className="section_right_1">
                            {/* <a mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.props.exportExcel}><DocumentExcelIcon/></a>
                            <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                            <a mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="report-export-icon" colorIndex="brand"/></a>
                            <span style={{padding: "5px"}}></span>                            
                        <Anchor title={getLocaleMessage("label.tooltip.search")}><SearchIcon colorIndex="brand" onClick={this.searchReport}/></Anchor>
                      </div>
    } else {
      rightSection = <div className="right_search_icon">
                        <Anchor title={getLocaleMessage("label.tooltip.search")}><SearchIcon colorIndex="brand" onClick={this.searchReport}/></Anchor>
                      </div>
    }

    if(this.state.searchResult){
      title=LocaleUtility.getLocaleMessage("usageSearchResultsTitle");
    }
    if(this.state.searchContent){
      title=LocaleUtility.getLocaleMessage("usageSearchTitle");
      //rightSection = undefined;
      //sectionContent = this.searchContent();
    }
    return (
    <div className="config-cont-backgroud"> 
      <div className="action_filter_wrapper">
        <SectionWithTitle title={title}  rightSection={rightSection}  helpTopic="IssuedLicense_Report" sectionContent={sectionContent}/>
      </div>
      <div>
          <SpinningNotification centerPage={true} />
      </div>
    </div>
    );
  }
}

export default Report;
