import ReduceStore from "./ReduceStore"

class ProductUsageStore extends ReduceStore{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    reduce(action, state){
        switch(action.type){
            case "PRODUCT_HOST_LIST" : {
                return{
                actionType:"productHostList",
                data:action.payload.data
                };
            }
            case "PROD_HOST_LICENSE_LIST" : {
                return{
                    actionType:"prodLicenseList",
                    licenseList:action.payload.data,
                    pagination:action.payload.pagination
                };
            }
            case "PRODUCT_LICENSE_DETAILS" :{
                return{
                    actionType:"prodLicenseDetails",
                    licenseDetails:action.payload.data
                    
                };
            }
            case "PRODUCT_CUSTOM_METRIC":{
                return{
                    actionType:"customMetric",
                    metricData:action.payload.data
                }
            }            
            default:
                return state;
        }

    }
}

export default new ProductUsageStore();