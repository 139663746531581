import React, { Component } from "react";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import "../../../../css/index.css";
import * as LicenseUtility from "../../../../utils/LicenseUtility";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseDetail extends Component {

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {

            let lockValues;
            if(licDetail.ipAddressList && licDetail.ipAddressList.value.length > 0){
                lockValues = <span id="detail_ip"><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.ip.address")}: </b>
                {licDetail.ipAddressList.value.toString()}</span>;
            }
            if(licDetail.deviceIdList && licDetail.deviceIdList.value.length > 0){
                lockValues = <span id="detail_devId"><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.device.id")}: </b>
                {licDetail.deviceIdList.value.toString()}</span>;
            }
            if(licDetail.hostIdList && licDetail.hostIdList.value.length > 0){
                lockValues = <span id="detail_host"><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.host.id")}: </b>
                {licDetail.hostIdList.value.toString()}</span>;
            }
            if(licDetail.cloudIdList && licDetail.cloudIdList.value.length > 0){
                lockValues = <span id="detail_cloudId"><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.cloud.id")}: </b>
                {licDetail.cloudIdList.value.toString()}</span>;
            }
            if(licDetail.macAddressList && licDetail.macAddressList.value.length > 0){
                lockValues = <span id="detail_mac"><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.mac.address")}: </b>
                {licDetail.macAddressList.value.toString()}</span>;
            }

            var safekey = false;
            if(licDetail.licenseFormat === 'HPLICENSE' || licDetail.licenseFormat === 'APLICENSE'){
                safekey = true;
            }

            return (
                <div id={this.props.id} style={{margin: "10px"}}>
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.license.key")}
                                </td>
                                <td>
                                    {Buffer.from(licDetail.rawLicenseKey, 'base64').toString('ascii')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr className="lic-detail-head">
                                <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber")}</td>
                                {licDetail.environment !== undefined && licDetail.environment !== 'NA' && (
                                    <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.environment")}</td>
                                )}
                                <td width="5%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu")}</td>
                                <td width="5%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity")}</td>
                                <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate")}</td>
                                <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate")}</td>
                                <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.lockValues")}</td>
                                <td colSpan="2" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.licTracking")}</td>
                            </tr>
                            <tr>
                                <td>{licDetail.productID}</td>
                                {licDetail.environment !== undefined && licDetail.environment !== 'NA' && (
                                    <td>
                                    {LicenseUtility.getEnvLocalLabel(licDetail.environment)}
                                    </td>
                                )}
                                <td>{licDetail.ltuCapacity}</td>
                                <td>{licDetail.capacity}</td>
                                <td>{licDetail.startDate}</td>
                                <td>{licDetail.expiryDate}</td>
                                <td>{lockValues}</td>
                                <td><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.orderNumber")}: </b>
                                {licDetail.orderNumber}</td>
                                <td><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.user")}: </b>{licDetail.userName}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.featureid.version")}
                                </td>
                                <td>
                                    {licDetail.featureID +' : '+licDetail.featureVersion}
                                </td>
                            </tr>
                            { licDetail.ipAddressList && licDetail.ipAddressList.value.length > 1 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.ip.address")}</td>
                                <td>{licDetail.ipAddressList.value.toString()}</td>
                                </tr>
                            )}
                            { licDetail.deviceIdList && licDetail.deviceIdList.value.length > 1 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.device.id")}</td>
                                <td>{licDetail.deviceIdList.value.toString()}</td>
                                </tr>
                            )}
                            { licDetail.hostIdList && licDetail.hostIdList.value.length > 1 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.host.id")}</td>
                                <td>{licDetail.hostIdList.value.toString()}</td>
                                </tr>
                            )}
                            { licDetail.cloudIdList && licDetail.cloudIdList.value.length > 1 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.cloud.id")}</td>
                                <td>{licDetail.cloudIdList.value.toString()}</td>
                                </tr>
                            )}
                            { licDetail.macAddressList && licDetail.macAddressList.value.length > 1 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.mac.address")}</td>
                                <td>{licDetail.macAddressList.value.toString()}</td>
                                </tr>
                            )}
                            {licDetail.productVersion !== undefined && licDetail.productVersion !== '' && (
                                <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.productversion")}
                                </td>
                                 <td>
                                    {licDetail.productVersion}
                                </td>
                                </tr>
                            )}
                            {licDetail.licenseFormat !== 'HPLICENSE' && licDetail.isPAC && (
                                <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.product.activation")}
                                </td>
                                 <td>
                                    {LicenseUtility.processLicenseKey(licDetail.productBundle)}
                                </td>
                                </tr>
                            )}
                            {licDetail.passwordType !== undefined && licDetail.passwordType !== 'PERMANENT' && (
                                <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.password.type")}
                                </td>
                                <td>
                                    {licDetail.passwordType}
                                </td>
                                </tr>
                            )}
                            {licDetail.ioDuration !== undefined && licDetail.ioDuration !== -1 && licDetail.ioDuration !== 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.io.duration")}
                                </td>
                                <td>
                                    {licDetail.ioDuration}
                                </td>
                                </tr>
                            )}
                            {licDetail.ioStartDate !== undefined && licDetail.ioStartDate !== -1 && licDetail.ioStartDate !== 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.io.startdate")}
                                </td>
                                <td>
                                    {licDetail.ioStartDate}
                                </td>
                                </tr>
                            )}
                            {licDetail.annotation !== undefined && licDetail.annotation !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.annotation")}
                                    </td>
                                    <td>
                                        {licDetail.annotation}
                                    </td>
                                </tr>
                            )}
                            {licDetail.licenseType !== undefined && licDetail.licenseType !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.licenseType")}
                                    </td>
                                    <td>
                                        {licDetail.licenseType == "TERM" ? LocaleUtility.getLocaleLabel(this.context.intl, "label.term") : LocaleUtility.getLocaleLabel(this.context.intl, "label.perpetual")}
                                    </td>
                                </tr>
                            )}
                            { safekey && licDetail.system !== undefined && licDetail.system !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.system.name")}
                                    </td>
                                    <td>
                                        {licDetail.system}
                                    </td>
                                </tr>
                            )}
                            { safekey && licDetail.purpose !== undefined && licDetail.purpose !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.purpose")}
                                    </td>
                                    <td>
                                        {licDetail.purpose}
                                    </td>
                                </tr>
                            )}
                            { safekey && licDetail.said !== undefined && licDetail.said !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.said")}
                                    </td>
                                    <td>
                                        {licDetail.said}
                                    </td>
                                </tr>
                            )}
                            { safekey && licDetail.uom !== undefined && licDetail.uom !== '' && (
                                <tr>
                                    <td className="lic-detail-head" width="20%">
                                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.capacity.unit.measure")}
                                    </td>
                                    <td>
                                        {licDetail.uom}
                                    </td>
                                </tr> 
                            )}
                            { safekey && licDetail.countryList && licDetail.countryList.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl,"label.countries")}</td>
                                <td>{licDetail.countryList.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.regionList && licDetail.regionList.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.regions")}</td>
                                <td>{licDetail.regionList.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.continentList && licDetail.continentList.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.continents")}</td>
                                <td>{licDetail.continentList.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.machinesBlocked && licDetail.machinesBlocked.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.machine.blocked")}</td>
                                <td>{licDetail.machinesBlocked.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.machinesAllowed && licDetail.machinesAllowed.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.machine.allowed")}</td>
                                <td>{licDetail.machinesAllowed.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.supportedOS && licDetail.supportedOS.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.os")}</td>
                                <td>{licDetail.supportedOS.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.overridesLicenseList && licDetail.overridesLicenseList.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.override.license")}</td>
                                <td>{licDetail.overridesLicenseList.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.dependsOnLicenseList && licDetail.dependsOnLicenseList.value.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.depend.license")}</td>
                                <td>{licDetail.dependsOnLicenseList.value.toString()}</td>
                                </tr>
                            )}
                            { safekey && licDetail.licenseID && licDetail.licenseID !== '' && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.unique.licenseId")}</td>
                                <td>{licDetail.licenseID}</td>
                                </tr>
                            )}
                            { licDetail.attributeList && licDetail.attributeList.entry.length > 0 && (
                                <tr>
                                <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.extended.attr")}</td>
                                <td>
                                    <table className="lic-detail-table table-bordered">
                                        <tbody>
                                        {licDetail.attributeList.entry.map(item => {
                                            return <tr>
                                                    <td>{item.key}</td>
                                                    <td>{LicenseUtility.processLicenseKey(item.value.value)}</td>
                                                    </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    render() {
        return (
             this.infoSectionContent(this.props.licenseDetail)
        );
    }
}

// LicenseDetail.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseDetail;