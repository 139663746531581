import React, { Component } from "react";
import { DataSearch as Search } from 'grommet/components/DataSearch';
import { FormDown as FormDownIcon } from 'grommet-icons';
import { CheckBox } from "grommet/components/CheckBox";
import * as Util from "../../utils/Util";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { useIntl, injectIntl } from 'react-intl';
import { Data } from "grommet/components";
//var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class APLSSelect extends Component {
  constructor(props) {
      super(props);
      this.state = {
        closeDropDown: false,
        compWidth: "auto",
        options: [],
        isSearchAction: false,
        searchedValue: '',
        multiSelectedValues: undefined,
        multiSelectedLabels: [],
        defSelValues: undefined,
        defSelLabels: [],
      };
      this.wrapperRef = React.createRef();
      this.showOptions = this.showOptions.bind(this);
      this.onOptionsSelect = this.onOptionsSelect.bind(this);
      this.handleMouseOutside = this.handleMouseOutside.bind(this);
      this.onSearchAction = this.onSearchAction.bind(this);
      this.handleScrollAction = this.handleScrollAction.bind(this);
      this.checkBoxAction = this.checkBoxAction.bind(this);
      this.cancelAction = this.cancelAction.bind(this);
      this.buildMultiSelectdValues = this.buildMultiSelectdValues.bind(this);
      this.okAction = this.okAction.bind(this);
      this.findLabel = this.findLabel.bind(this);
      this.clearAction = this.clearAction.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevProps.value !== undefined && this.props.value !== undefined &&
         prevProps.value !== this.props.value)){
        this.buildMultiSelectdValues();
    }
  }

  findLabel(key, intl) {
    return LocaleUtility.getLocaleMessage(key);
  }
  showOptions(event) {
    this.setState({closeDropDown: true, compWidth: this.props.width+"rem", searchedValue: '', 
                  isSearchAction: false});
  }
  onOptionsSelect(event, option) {
    event.option = option;
    this.setState({closeDropDown: false, compWidth: "auto"});
    this.props.onChange(event);
  }
  checkBoxAction(event, value, label) {
    let vList = this.state.multiSelectedValues;
    let currentValue = event.target.checked;
    let lList = this.state.multiSelectedLabels;
    if(currentValue === false) {
      if(value !== "null"){
        vList = this.state.multiSelectedValues.filter(x=> x !== value);
        lList = this.state.multiSelectedLabels.filter(x=> x !== label);
        let index = vList.indexOf('null');
        if(index > -1){
          if(vList.length === 1){
            vList = [];
            lList = [];
          } else {
            vList.splice(index, 1);
            lList.splice(index, 1);
          }
        }
      } else {
        vList = this.state.defSelValues.filter(x=> x === 'NA');
        lList = this.state.defSelLabels.filter(x=> x === 'NA');
      } 
    } else {
      if(value === "null"){
        vList = this.state.defSelValues;
        lList = this.state.defSelLabels;
      } else {
        if(this.state.multiSelectedValues.indexOf(value) < 0) {
          vList.push(value);
        }
        if(this.state.multiSelectedLabels.indexOf(label) < 0) {
          lList.push(label);
        }
      
        if(vList.length === (this.state.defSelValues.length - 1) && vList.indexOf("null") === -1){
          vList = this.state.defSelValues;
          lList = this.state.defSelLabels;
        }
      }
    }
    this.setState({multiSelectedValues: vList, multiSelectedLabels: lList});
  }
  handleMouseOutside() {
    if(!(this.props.disableMouseOut !== undefined && this.props.disableMouseOut) && this.state.closeDropDown && ((this.props.multiSelect !== undefined && this.props.multiSelect === false)
      || this.props.multiSelect === undefined)) {
      this.setState({closeDropDown: false, compWidth: "auto"});
    }
  }
  cancelAction(event) {
    this.buildMultiSelectdValues();
    this.setState({closeDropDown: false, compWidth: "auto"});
  }
  clearAction() {
    this.setState({multiSelectedValues: [], multiSelectedLabels: []});
  }
  okAction(event) {
    let l = Util.convertArrayToString(this.state.multiSelectedLabels, ",", true);
    let v = Util.convertArrayToString(this.state.multiSelectedValues, ",", false);
    event.option = {label: l, value: v};
    this.props.onChange(event);
    this.setState({closeDropDown: false, compWidth: "auto"});
  }
  handleScrollAction() {
    this.handleMouseOutside();
  }
  onSearchAction(event) {
    let value = event.target.value;
    if(value !== undefined && value !== '') {
      let opts = [];
      let options = this.props.options;
      if(options !== undefined && options.length > 0) {
        options.map((object, index)=>{
          let compValue = object.label.toLowerCase();
          if(compValue.indexOf(value.toLowerCase()) > -1) {
            opts.push(object);
          }
          return "";
        });
        this.setState({options: opts, isSearchAction: true, searchedValue: value});
      }
    } else {
      this.setState({isSearchAction: false, searchedValue: value});
    }
  }
  buildMultiSelectdValues() {
    let compSelectedValue = this.props.value;
    let multiCompSelectedValues = [];
    let multiCompSelectedLabels = [];
    if(this.props.multiSelect !== undefined && this.props.multiSelect === true) {
      if(compSelectedValue !== undefined && compSelectedValue.value !== undefined) {
        let vArray = compSelectedValue.value.split(",");
        let options = this.props.options;
        for(let i=0; i<vArray.length;i++) {
          multiCompSelectedValues.push(vArray[i]);
          if(options !== undefined) {
            options.filter(object=>object.value === vArray[i]).map((object, index)=> 
              multiCompSelectedLabels.push(object.label)
            )
          }
        }
        this.setState({multiSelectedValues: multiCompSelectedValues, multiSelectedLabels: multiCompSelectedLabels,
        defSelValues: multiCompSelectedValues, defSelLabels: multiCompSelectedLabels});
      }
    }
  }

  /**
   * If clicked outside of dropdown element
   */
  handleClickOutside(event) {
      if (
          this.state.closeDropDown && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({closeDropDown: false, compWidth: "auto"});
      }
  }

  render() {
    let intl = this.context.intl;
    let width = "20rem";
    let serachWidth="20rem";
    let compWidth = this.state.compWidth;
    let options = this.props.options;
    let displayValue = "block";
    let multiSelect = false;
    let compSelectedValue = this.props.value;
    if(this.state.isSearchAction) {
      options = this.state.options;
    }
    let dropDownClassName = "select-options-div display-none";
    if(this.state.closeDropDown) {
      dropDownClassName = "select-options-div display-block";
      displayValue = "none";
    }
    if(this.props.width !== undefined) {
      width = this.props.width+"rem";
      compWidth = this.props.width+"rem";
      serachWidth = this.props.width+"rem"; //(Number(this.props.width)-0.5)+"em";
    }
    let enabledSearch = true;
    if(this.props.enabledSearch !== undefined && !this.props.enabledSearch) {
      enabledSearch = false;
    }
    // let scrollY = "auto";
    // if(this.props.disabledScroll !== undefined && this.props.disabledScroll === true) {
    //   scrollY = "none";
    // }
    if(this.props.multiSelect !== undefined && this.props.multiSelect === true) {
      multiSelect = true;
    }
    let multiSelValues = this.state.multiSelectedValues;
    if(multiSelValues === undefined) {
      multiSelValues = this.buildMultiSelectdValues();
    }

    let parentDivSty = {}, selectListSty = {maxHeight: "300px", width: width}, multiBtnSty;
    let defaultSty = {width: width, marginTop: "2px", marginLeft: "0px"};
    if(multiSelect){
      parentDivSty = {position:"relative", width:"100%"};
      selectListSty = {maxHeight: "300px", overflow: "auto", position: "relative", width: width};
      multiBtnSty = { bottom: "0"};
    }

    if(this.state.closeDropDown){
      let screen = window.screen.availHeight; //document.documentElement.scrollHeight + 100;
      let eleHeight = 20;
      let topOffset = this.wrapperRef.current.getBoundingClientRect().top;// + window.pageYOffset;
      if((topOffset > 0 && topOffset > (screen * 0.625))){
          defaultSty = {width: width, bottom: eleHeight}
      } else if(window.pageYOffset > 0){
        eleHeight = (window.pageYOffset - 22)
        defaultSty = {width: width}
      }
    }
    return(
      <div ref={this.wrapperRef} className="select_component_wrap" id="select-component-id" style={{width: "auto", minWidth: compWidth, maxWidth: "fit-content"}}>
        <div style={{width: "auto", minWidth: compWidth}} className="select-drop-down">
          <div id={this.props.id} className="dropdown_select" onClick={event=>this.showOptions(event)}>
            <div style={{float: "left"}}>
              <span id="select-component-label-id" style={{color: "rgba(0, 0, 0, 0.56)", float: "left"}}>{this.props.label}</span>
              <span id="select-component-value-id" style={{float: "left", paddingLeft: "4px", display: displayValue}}>{compSelectedValue !== undefined && compSelectedValue !== null && compSelectedValue !== 'null' ? compSelectedValue.label : ""}</span>
            </div>
            <div className="select_dropdown_icon_wrap" style={{float: "right", display: displayValue}}>
              <span><FormDownIcon /></span>
            </div>
          </div>
          <div id="select-option-div-id" style={defaultSty} className={
dropDownClassName}>
            <div className="grommetux-select__drop" style={parentDivSty}>
              {enabledSearch && (
               <Data ><Search placeholder={LocaleUtility.getLocaleMessage("label.type.to.search")}
                inline={true}
                //dropdown={false}
                responsive={false}
                size='small'
                dropAlign={{"right": "right"}}
                value={this.state.searchedValue}
                onChange={event=>this.onSearchAction(event)} 
                id="select-search-id"
              /></Data>)}
              {options !== undefined && options.length > 0 && (
                <ol className="grommetux-select__options" style={selectListSty}>
                  {options.map((object, index)=> 
                    multiSelect === false ?
                    <li key={index} className={compSelectedValue !== undefined && compSelectedValue.label === object.label ? "grommetux-select__option grommetux-select__option--selected" : "grommetux-select__option"} onClick={event=>this.onOptionsSelect(event, object)}>{object.label}</li>
                    :
                    <li key={index} className="multi-slection-options-li">
                      <CheckBox id={this.props.id+"-"+index} checked={multiSelValues !== undefined && multiSelValues.indexOf(object.value) > -1 ? true : false}
                        label={object.label}
                        onChange={event=>this.checkBoxAction(event, object.value, object.label)} />
                    </li>
                    )}
                </ol>
              )}
              {options !== undefined && options.length > 0 && multiSelect === true && (
                <div className="multi-selection-options-buttons" style={multiBtnSty}>
                  <span style={{marginRight: "6px"}} className="grommetux-button grommetux-button--primary" onClick={event=>this.okAction(event)}>{this.findLabel("label.button.ok", intl)}</span>
                  <span style={{marginRight: "6px"}} className="grommetux-button grommetux-button--secondary" onClick={event=>this.clearAction()}>{this.findLabel("label.button.clean", intl)}</span>
                  <span className="grommetux-button grommetux-button--secondary" onClick={event=>this.cancelAction()}>{this.findLabel("label.button.cancel", intl)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
// APLSSelect.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default APLSSelect;