import React, { Component } from "react";
import * as DateUtility from "../../utils/DateUtility";
import * as LocaleUtility from "../../utils/LocaleUtility";
import Select from "../commons/APLSSelect";
//import { FormField } from "grommet/components/FormField";

class DateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropDown: false
        }
        this.createDates = this.createDates.bind();
    }
    createDates(noOfdays, opt) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else if(opt === 6) {
            previousDate.setMonth(currentDate.getMonth() - 3);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        /*if(opt === 0 || opt === 2 || opt === 3 || opt === 4 || opt === 6) {
            if(opt === 0) {
                previousDate = DateUtility.convertToServerTimeZone(previousDate);
            }
            currentDate = DateUtility.convertToServerTimeZone(currentDate);
        }*/
        if(opt === 1) {
            return DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(previousDate);
        } else {
            return DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate);
        }
    }
    render() {
        const dateRangeData = [];
        if(this.props.renderFor === "history") {
            dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.today"), "value": this.createDates(0, 0)});
            dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.yesterday"), "value": this.createDates(1, 1)});
            dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.last.seven.days"), "value": this.createDates(6, 2)});
        }
        dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.last.thirty.days"), "value": this.createDates(29, 3)});
        dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.this.month"), "value": this.createDates(29, 4)});
        dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.last.month"), "value": this.createDates(29, 5)});
        if(this.props.renderFor === "advancedreports") {
            dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.last.three.months"), "value": this.createDates(29, 6)});
        }
        dateRangeData.push({"label": LocaleUtility.getLocaleMessage("label.date.cutom.range"), "value": "custom"});
        
        let defaultValue = this.props.defaultValue;
        let selValue = this.props.value;
        let displayValue = this.props.displayValue;
        if(defaultValue && (!selValue || selValue === "")) {
            if(defaultValue === 0) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.today"), "value": this.createDates(0, 0)};
            } else if(defaultValue === 1) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.yesterday"), "value": this.createDates(1, 1)};
            } else if(defaultValue === 2) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.last.seven.days"), "value": this.createDates(6, 2)};
            } else if(defaultValue === 3) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.last.thirty.days"), "value": this.createDates(29, 3)};
            } else if(defaultValue === 4) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.this.month"), "value": this.createDates(29, 4)};
            } else if(defaultValue === 5) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.last.month"), "value": this.createDates(29, 5)};
            } else if(defaultValue === 6) {
                selValue = {"label": LocaleUtility.getLocaleMessage("label.date.last.three.months"), "value": this.createDates(29, 6)};
            }
            displayValue = selValue.value;
        }
        dateRangeData.map((obj, index) => {
            if((obj.value === selValue.value && obj.label === selValue.label) 
                || selValue.value === "custom") {
                    let dateLocalRange = displayValue;
                    if((!this.props.value || this.props.value === "")){
                        dateLocalRange = DateUtility.getLocalFormatedDateRangeFromDateRange(displayValue);
                    }
                let newLabel = <span>{obj.label}<span style={{fontWeight: "bold", fontSize: "14px"}}>{" ["+dateLocalRange+"]"}</span></span>;
                selValue.label = newLabel;
            }
            return "";
        });
        return(
            <div>
                {/*<FormField id="usage_list_form_seletDateRange" style={{ width: this.props.width }} label = {this.props.label} >
                    <Select id="usage_list_seletDateRange"
                        options={dateRangeData}
                        value={selValue}
                        onChange ={this.props.onChange}
                    />
                    {<span style={{padding: "10px", fontWeight: "bold"}}>{displayValue}</span>}
                </FormField>*/}
                <Select id="usage_list_seletDateRange"
                    options={dateRangeData}
                    value={selValue}
                    onChange ={this.props.onChange}
                    label = {this.props.label}
                    enabledSearch= {false}
                    width = {this.props.width !== undefined ? this.props.width.replace("em", "") : 22}
                    dropdownWidth = {this.props.dropdownWidth}
                />
            </div>
        )
    }

}
export default DateRange;