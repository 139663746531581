import React,{ Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import PropTypes from 'prop-types';
import Login from "../generic/Login";
import { LoginGrommetForm } from '../generic/LoginForm';
import Dispatcher from "../../flux/Dispatcher";
import AppStore from  "../../flux/AppStore";
import SpinningNotification from '../generic/SpinningNotification';
import ComponentNotification from '../generic/ComponentNotification';
import * as LocaleUtility from "../../utils/LocaleUtility";
import * as FrontendPagePath from "../../utils/FrontendPagePath";
import { checkLoginStatus, resetPassword, getCurrentTenantName, getUiRquestValue } from "../../utils/FetchHelper";
import { confirm } from "../../utils/ValidationUtility";
import { APLS_WS_LOGIN_STATUS, APLS_WS_LOGIN_DETAIL } from "../../utils/RestWebServicePaths";
import ResetForm from "./ResetForm";
import * as Utils from "../../utils/Util";
import * as LicenseUtility from "../../utils/LicenseUtility";
import withRouter from "./withRouter";

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);
var resetTid = '';

export class LoginForm extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            showForgot: false,
            reset: false,
            logout: false,
            user: "", 
            tenantNotFound: false,
            errorcode: [],        
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.onConfrimAction = this.onConfrimAction.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.modalResetContent = this.modalResetContent.bind(this);
        this.redirectLogin = this.redirectLogin.bind(this);
    }

    // UNSAFE_componentWillMount() {
    //     if(!(this.props.location.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 || 
    //         this.props.location.pathname.indexOf("/ls/login/tenant/password/create/") > -1 ||
    //         this.props.location.pathname.indexOf("/ls/login/user/reset/") > -1 ||
    //         this.props.location.pathname.indexOf("/ls/samlSuccess") > -1 ||
    //         this.props.location.pathname.indexOf(FrontendPagePath.LOGOUT_PATH) > -1 ||
    //         (this.props.location.pathname.indexOf(FrontendPagePath.LOGIN_PATH) > -1 
    //                    && (this.props.idm || this.props.saml)))){
    //         let tid = Utils.findTidFn(this.props.location);
    //         fetchAsync(tid, this.props.setSaml).catch(error => {
                
    //         }); 
    //     }
    //     // if( this.props.location.pathname.indexOf(FrontendPagePath.LOGOUT_PATH) > -1 
    //     //                ){
    //     //     window.location.href = FrontendPagePath.LOGIN_PATH;
    //     // }
    // }
    
    componentDidMount() {
        if(!(this.props.location.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 || 
            this.props.location.pathname.indexOf("/ls/login/tenant/password/create/") > -1 ||
            this.props.location.pathname.indexOf("/ls/login/user/reset/") > -1 ||
            this.props.location.pathname.indexOf("/ls/samlSuccess") > -1 ||
            this.props.location.pathname.indexOf(FrontendPagePath.LOGOUT_PATH) > -1 ||
            (this.props.location.pathname.indexOf(FrontendPagePath.LOGIN_PATH) > -1 
                       && (this.props.idm || this.props.saml)))){
            let tid = Utils.findTidFn(this.props.location);
            fetchAsync(tid, this.props.setSaml).catch(error => {
                
            }); 
        }
        AppStore.on("change", this.refreshState);
    }
    
    componentWillUnmount() {
        AppStore.removeListener("change", this.refreshState);
    }

    refreshState(){
        this.setState({tenantNotFound: false});
        if(AppStore.getState().reset !== undefined &&
                AppStore.getState().reset === true){
            this.setState({showForgot: false});
        }
        if(AppStore.getState().actionType !== undefined && AppStore.getState().actionType === "TENANT_NOT_FOUND"){
            this.setState({tenantNotFound: true});
        }
        this.setState({idm: AppStore.getState().idm, saml: AppStore.getState().saml, datasource: AppStore.getState().datasource,
        mail: AppStore.getState().mail, defaultmail: AppStore.getState().defaultmail, 
        defaultuser: AppStore.getState().defaultuser, servermail: AppStore.getState().servermail,
        reset: AppStore.getState().reset});
        // if(AppStore.getState().user === undefined && AppStore.getState().saml !== undefined && AppStore.getState().saml){
        //     window.location.href = FrontendPagePath.LOGIN_PATH;
        // }
    }

    onSubmit(loginObj){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        let loadSpinAction = "SIGN_IN_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.please.wait")
        });
        let tid = Utils.findTidFn(this.props.location);
        checkLoginStatus(loginObj, APLS_WS_LOGIN_STATUS, tid, this.props.setSaml);
    }

    onConfrimAction(flag){
        if(flag === 1){
            var user = this.state.user;
            this.setState({ user: ''});
            let tid = Utils.findTidFn(this.props.location);
            resetPassword(user, tid);
        }
    }

    formSubmit(event){
        event.preventDefault();
        var elements = event.target.elements;
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(elements.username.value === ''){
            let index = errors.indexOf(elements.username.name)
            if (index === -1) {
                errors.push(elements.username.name);
            }
            flag = false;
        }

        if(flag){
            if(this.state.mail !== undefined && this.state.mail ){
                if(this.state.defaultmail !== undefined && this.state.defaultuser !== undefined
                    && this.state.defaultmail && elements.username.value === this.state.defaultuser){
                    this.setState({user: elements.username.value});
                    confirm(
                        LocaleUtility.getLocaleMessage("label.reset.pwd.form.alert9", {mail: this.state.servermail}), this.onConfrimAction);
                } else {
                    let tid = Utils.findTidFn(this.props.location);
                    resetPassword(elements.username.value, tid);
                }
            } else {
                Dispatcher.dispatch({
                    type: "MAIL_SERVER_NOT_CONFIGURED_ALERT_SHOW_MODAL",
                    payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert8")
                });
            }
        } else {
            this.setState({errorcode: errors});
        }

    }

    forgotPassword(){
        this.setState({showForgot: true});
    }

    redirectLogin(){
        window.location.href = FrontendPagePath.LOGIN_PATH;
        if(resetTid !== undefined && resetTid !== '') {
            window.location.search = "?tenant="+resetTid;
        } else {
            let tid = Utils.findTidFn(this.props.location);
            if(tid !== undefined && tid !== '' && tid !== null && tid !== 'null') {
                window.location.search = "?tenant="+tid;
            }
        }
    }

    validateTextInput(event){

        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });

        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)

        if (event.target.value === '') {
            if (index === -1) {
                errors.push(event.target.name);
            }
            this.setState({errorcode: errors});
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            this.setState({errorcode: errors});
        }
    }

    modalResetContent(username, id, tid, tenantReset) {
        return (
            <ResetForm formSubmit={this.onModalCloseCallback} user={username} id={id} location={this.props.location} tid={tid} tenantReset={tenantReset}/>
        );
    }

    modalForgotContent() {

        let repwdFormFieldError, repwdError;
        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('username') !== -1){
                repwdError =<span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
                repwdFormFieldError='grommetux-form-field--error';
            } 
        }

        return (
            <div id="modalDiv" className="opacquemodal reset_password">
        <div className="modal-main panel panel-default" style={{minWidth: "30em", width: "40%"}}>
            <div className="panel-heading">
                <h2 id="modal_title" className="panel-title">{LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.password.title")}</h2>
            </div>
            <ComponentNotification />
            <Form id="reset_formDetail"compact={false} pad="small" plain={true} onSubmit={this.formSubmit}>
                <div id="modal_content" className="panel-body">
                    {/* <Section id="reset_formSec" pad="none" wrap={false} responsive={false} justify="center"> */}
                        <FormField 
                        // style={styleField2} 
                        label={LocaleUtility.getLocaleLabel(this.context.intl, "usage.search.username.label")} 
                            className={repwdFormFieldError}
                            >
                            <TextInput id='forgot_username' name='username' onChange={event =>this.validateTextInput(event)} />
                            {repwdError}
                        </FormField>
                    {/* </Section> */}
                </div>
                <div className="panel-footer panel-button-footer" style={{width: '100%'}}>
                    <div className="button_right_wrap">
                    <div className="button_wrap_primary">
                        <Button id="modal_Login" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.login")} 
                            accent={true} plain={false} onClick={this.redirectLogin}/>
                    </div>
                    <div className="button_wrap_secondary">
                        <Button id="modal_Btn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.reset.password")} 
                        type="submit" plain={false} primary={true} />
                    </div> 
                    </div>                               
                </div>
            </Form>
            </div>
            <div>
                <SpinningNotification centerPage={true} />
            </div>
        </div>
        );
    }

    modalResetSuccess() {

        let content = LocaleUtility.getLocaleMessage("label.reset.pwd.link.msg", {email: ""});
        if(AppStore.getState().changed !== undefined && AppStore.getState().changed){
            content = LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.success");
            if("Y" === AppStore.getState().tenantReset) {
                content = LocaleUtility.getLocaleLabel(this.context.intl, "label.create.pwd.success");
            }
            if(AppStore.getState().firstlogin){
                content = LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.firstlogin.pwd.success");
            }
            if(AppStore.getState().error !== undefined){
                content = AppStore.getState().error;
                if("Y" === AppStore.getState().tenantReset) {
                    content = LocaleUtility.getLocaleLabel(this.context.intl, "create.link.expired");
                }
            }
        }
        return (
        <div id="modalDiv" className="opacquemodal reset_success">
        <div className="modal-main panel panel-default" style={{minWidth: "30em", width: "40%"}}>
            <div className="panel-heading">
                <h2 id="modal_title" className="panel-title">{LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.password.success.title")}</h2>
            </div>
            <div id="modal_content" style={{padding: "10px 24px"}} className="panel-body">
                {content}
            </div>
            <div className="panel-footer panel-button-footer" style={{width: '100%'}}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div className="button_wrap_primary" style={{float: 'right'}}>
                                <Button id="modal_Login" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.login")} 
                                    accent={true} plain={false} onClick={this.redirectLogin}/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table> 
            </div>
        </div>
        </div>
        );
    }

    render(){
        let content;
        var id = this.props.params.id;
        const query = new URLSearchParams(this.props.location.search);
        var tid = query.get('tenant');
        if(this.state.tenantNotFound === true) {
            content = <div style={{backgroundColor: "#FFFFFF", margin: "5px"}}>
                <div style={{color: "#333", fontSize: "25px", marginBottom: "30px", paddingTop: "100px", textAlign: "center"}}>{LocaleUtility.getLocaleLabel(this.context.intl, "invalid.tenant.msg")}</div>
                <div style={{opacity: "0.9", color: "#666", fontSize: "22px", marginBottom: "80px", textAlign: "center", paddingBottom: "8%"}}>
                    {LocaleUtility.getLocaleLabel(this.context.intl, "invalid.tenant.full.msg")}
                </div>
            </div>
        } else if(this.props.location.pathname.indexOf("/login/tenant/password/create") > -1) {
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            if(tid !== undefined && tid !== '' && tid !== null && tid !== 'null') {
                resetTid = tid;
            }
            let startIndex = this.props.location.pathname.lastIndexOf("/");
            let endIndex = this.props.location.pathname.length;
            if(startIndex > 0 && endIndex > 0 && id === undefined) {
                let pid = this.props.location.pathname.substring(startIndex+1, endIndex);
                if(pid !== undefined && pid !== '' && pid !== null && pid !== 'null') {
                    id = pid;
                }
            }
            content = this.modalResetContent(AppStore.getState().username, id, resetTid, "Y");
        } else if((AppStore.getState().url !== undefined && 
                    AppStore.getState().url === FrontendPagePath.RESET_PATH) || (id !== undefined)){
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            if(tid !== undefined && tid !== '' && tid !== null && tid !== 'null') {
                resetTid = tid;
            }
            let startIndex = this.props.location.pathname.lastIndexOf("/");
            let endIndex = this.props.location.pathname.length;
            if(startIndex > 0 && endIndex > 0 && id === undefined && this.props.location.pathname.indexOf("/login/user/reset") > -1) {
                let pid = this.props.location.pathname.substring(startIndex+1, endIndex);
                if(pid !== undefined && pid !== '' && pid !== null && pid !== 'null') {
                    id = pid;
                }
            }
            if(AppStore.getState().firstlogin){
                content = this.modalResetSuccess();
            } else {
                content = this.modalResetContent(AppStore.getState().username, id, resetTid, "");
            }
        } else if(this.state.showForgot !== undefined && 
                    this.state.showForgot === true){
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            content = this.modalForgotContent();
        } else if(this.state.reset !== undefined && 
                    this.state.reset === true 
                    && !(AppStore.getState().saml || AppStore.getState().idm || 
                    this.props.location.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 || 
                    this.props.location.pathname.indexOf(FrontendPagePath.LOGOUT_PATH) > -1)
                    ){
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            content = this.modalResetSuccess();
        } else if(AppStore.getState().datasource !== undefined && 
                ((AppStore.getState().setSaml !== undefined && AppStore.getState().setSaml) ||
                !(AppStore.getState().saml || AppStore.getState().idm || this.props.location.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 || 
                    this.props.location.pathname.indexOf(FrontendPagePath.LOGOUT_PATH) > -1))){
            content = <section id="login_Sec" className="grommetux-box grommetux-box--direction-column grommetux-box--responsive grommetux-box--pad-vertical-medium grommetux-section">
                <Login title={LocaleUtility.getDefualtString("License.Server")} apptitle="Autopass" icon="LS" char="+" 
                        onloginsubmit={this.onSubmit.bind(this)}
                        forgotPwdId = "login_forgotPwd" forgotPassword={this.forgotPassword.bind(this)}
                        forgotPwdLabel={LocaleUtility.getLocaleLabel(this.context.intl, "label.anchor.forgot.password")}
                        datasource={AppStore.getState().datasource}
                        usernameLabel = {LocaleUtility.getLocaleLabel(this.context.intl, "label.login.text.username")}
                        passwordLabel = {LocaleUtility.getLocaleLabel(this.context.intl, "label.login.text.password")}
                        buttonLabel = {LocaleUtility.getLocaleLabel(this.context.intl, "label.login.button.login")}
                ></Login>
            </section>
        } else {
            content = <SpinningNotification centerPage={false} />
        }
        return(
            <div>{content}</div>
        );
    }
    
}

export default withRouter(LoginForm);

async function fetchAsync(tid, setSaml) {
    // await response of fetch call
    let response = await fetch(APLS_WS_LOGIN_DETAIL, {
        method: 'GET',
        headers: {
         "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache, no-store",
          "Tenant-Name": getCurrentTenantName(),
          "Tenant-Id": tid,
          "Apls-CA": getUiRquestValue(),
          Accept: "application/json"
        },
        
    
    
    });
    if(response.status === 403){
        var status=403;
        let error="";      
        LicenseUtility.processError(status,error); 
    }
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    
   if(data.status === 'SUCCESS'){
        var map = {};
        if(data.responseMap !== undefined){
            data.responseMap.entry.forEach(item => {
                map[item.key] = item.value;
            });
        }
        var respData = {"sessionMap": map, setSaml: setSaml}
        Dispatcher.dispatch({
            type: "INDEX_DETAIL",
            payload: respData
        });
    } else if(data.status === 'FAIL') {
        let errorDetail = data.errorDetail;
        if(errorDetail !== null && errorDetail !== undefined && errorDetail.errorCode !== undefined && Number(errorDetail.errorCode) === 19000) {
            Dispatcher.dispatch({
                type: "TENANT_NOT_FOUND",
                payload: {}
            });
        }
    }
}

LoginForm.propTypes={
    onSubmit: PropTypes.func   
};

// LoginForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };

