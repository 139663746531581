import React, { Component } from "react";
import { FormPrevious as FormPreviousIcon } from 'grommet-icons';
import { getLocaleMessage }from "../../utils/LocaleUtility";

class APLSBack extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return(
            <div id={this.props.id} tooltiptext={getLocaleMessage("label.go.back")} className="back-button-container" onClick={this.props.onClick}>
                <FormPreviousIcon 
                    size="huge"
                />
            </div>
        ) 
    }
}
export default APLSBack;