import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PoolMembersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "",
                sortOrder: "DESC"
            },
            selectedRowKeys: []
        }
        this.onRowClicked = this.onRowClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.clearSelections = this.clearSelections.bind(this);
        this.findLabel = this.findLabel.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
    }
    onRowClicked(keys, event) {
        this.props.selectedAction(keys);
    }
    clearSelections() {
        this.setState({selectedRowKeys: []});
    }
    render() {
        //let intl = this.context.intl;
        let paginationObj = this.state.pagination;
        let pageNumber = 1;
        let pageSize = 10;
        if(this.props.totalRecords !== undefined) {
            paginationObj.totalCount = this.props.totalRecords;
        }
        let rowData = [];
        if(paginationObj !== undefined) {
            pageNumber = paginationObj.pageNumber;
            pageSize = paginationObj.pageSize;
        }
        if(this.props.rows !== undefined && this.props.rows.length > pageSize) {
            rowData = this.props.rows.slice((pageNumber-1)*pageSize, (pageSize*pageNumber));
        } else {
            rowData = this.props.rows;
        }
        if(rowData !== undefined ){
            rowData.forEach(function (item) {
                if (item.type === "User") {
                    item.type = LocaleUtility.getLocaleMessage("resv.pool.username.tab.label");
                } else if (item.type === "Client") {
                    item.type = LocaleUtility.getLocaleMessage("resv.pool.clientid.tab.label");
                } else if (item.type === "Host") {
                    item.type = LocaleUtility.getLocaleMessage("resv.pool.hostid.tab.label");
                } else if (item.type === "IP") {
                    item.type = LocaleUtility.getLocaleMessage("resv.pool.ipaddr.tab.label");
                } 
            });
        }
        return(
            <div>
                {/*<div style={{paddingLeft: "2px", fontSize: "18px"}}>
                    {this.props.header}
                </div>*/}
                <div>
                    <TableGrid id="pool-memebers-grid-id"
                        header={[
                            {
                                columnName: "value",
                                headerLabel: this.findLabel("label.value"),
                                href: false,
                                width: "auto",
                                columnKey: "id"
                            },
                            {
                                columnName: "type",
                                headerLabel: this.findLabel("label.type"),
                                href: false,
                                width: "auto",
                                columnKey: "id"
                            },
                            {
                                columnName: "createdBy",
                                headerLabel: this.findLabel("resv.grid.header.pool.by"),
                                href: false,
                                width: "auto",
                                columnKey: "id"
                            },
                            {
                                columnName: "createdOnDate",
                                headerLabel: this.findLabel("job.lebel.created.on"),
                                href: false,
                                width: "auto",
                                columnKey: "id"
                            }
                        ]}
                        {...{keys:this.state.selectedRowKeys}}
                        rowData={rowData}
                        pagination={paginationObj}
                        rowSelection={3}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="id"
                        onChangeCheckBoxCallback={this.onRowClicked}
                        selectAllRows={false}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: rowData.length})}
                    />
                </div>
            </div>
        )
    }
}
// PoolMembersTable.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PoolMembersTable;