import React, { Component} from "react";
import { createBody, setPivotTotalResults, onPivotColClick } from "./PivotTableUtils";
import * as ConfigurationUtils from "../../../../utils/ConfigurationUtils";
let nav;
let isScrolling;

class PivotTableRows extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      rowResponse: [],
      scroll: false,
      colEnabled: -1,
      headerL: 0,
      recordInd: -1,
      dataSize: 0,
    });
    this.virtualScroll = this.virtualScroll.bind(this);
  }

  setTableBody = (rowData, pivotRoots, scroll) => {
      this.setState({rowResponse: rowData, dataSize: this.props.rows.length});
      setPivotTotalResults(pivotRoots, this.props.header.length);
      if(this.props.rows.length > 0 && this.props.sortIndex > 0 && 
          this.props.sortIndex < (this.props.header.length - 1)){
        onPivotColClick(this.props.sortIndex, this.props.header.length, 0, 100, false, undefined);
      } else if(this.props.rows.length > 0 && this.props.selPeriodIndex > 0 && 
          this.props.selPeriodIndex < (this.props.header.length - 1)){
        onPivotColClick(this.props.selPeriodIndex, this.props.header.length, 0, 100, false, undefined);
      }
  };

  loadTableBody = (rowData) => {
      this.setState({rowResponse: rowData});
  };

  setScrollParams = (colIndex, length, recordInd, scroll, rowSize, type) => {
    if(scroll){
      if(type === 'expand'){
        this.setState({scroll: scroll, colEnabled: colIndex, 
          headerL: length, recordInd: recordInd, rowSize: rowSize});
      } else {
        this.setState({scroll: scroll, colEnabled: colIndex, rowSize: rowSize});
      }
    } else {
      this.setState({scroll: false, colEnabled: -1, 
        headerL: length, recordInd: -1});
    }
  };

  getTableBody = (rowData, rowSelection, header, 
    rowKey, onChangeCheckBoxCallback, selectedCheckBoxKey, 
    uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll,
     noDataFoundText, addExtraRow, pivot, reportMeta) => {
      return createBody(rowData, rowSelection, header, rowKey, 
        onChangeCheckBoxCallback, selectedCheckBoxKey, uncheckTheDisabled,
        defaultSelectIds, disabledRowIds, childKey, expandAll, noDataFoundText, 
        addExtraRow, pivot, reportMeta, this.setTableBody, this.setScrollParams, this.loadTableBody);
  };

  componentDidMount(){
    nav = document.getElementById('pivot-main');
    if(nav.style.position !== undefined && nav.style.position === 'sticky'){
      nav = undefined;
    }
    this.getTableBody(
      this.props.rows,
      this.props.rowSelection,
      this.props.header,
      this.props.rowKey,
      this.props.onChangeCheckBoxCallback,
      this.props.selectedCheckBoxKey,
      this.props.uncheckTheDisabled,
      this.props.defaultSelectIds,
      this.props.disabledRowIds,
      this.props.childKey,
      this.props.expandAll,
      this.props.noDataFoundText,
      this.props.addExtraRow,
      this.props.pivot,
      this.props.reportMeta,
    );
    window.addEventListener('scroll', this.virtualScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.virtualScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    var prevRow = prevProps.rows[0];
    var curRow =  this.props.rows[0];

    if((prevProps.rows !== undefined && this.props.rows !== undefined &&
          prevProps.rows.length !== this.props.rows.length) ||
       ( prevRow !== undefined && curRow !== undefined &&
         prevRow[this.props.rowKey] !== curRow[this.props.rowKey])){

        this.getTableBody(
          this.props.rows,
          this.props.rowSelection,
          this.props.header,
          this.props.rowKey,
          this.props.onChangeCheckBoxCallback,
          this.props.selectedCheckBoxKey,
          this.props.uncheckTheDisabled,
          this.props.defaultSelectIds,
          this.props.disabledRowIds,
          this.props.childKey,
          this.props.expandAll,
          this.props.noDataFoundText,
          this.props.addExtraRow,
          this.props.pivot,
          this.props.reportMeta,
        );  
    }
  }

  virtualScroll(){
    let scrollTop = window.pageYOffset;
    if(this.state.scroll && (window.scrollY >= 0) && scrollTop > 0)
      //ConfigurationUtils.showLoadMask("", this.context.intl);
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      if(this.state.scroll && scrollTop >= 0){
        /* START:- Position:sticky effect in IE 11*/
        if(nav !== undefined){
          const offset = nav.getBoundingClientRect();
          if (window.pageYOffset > offset.top) {
            nav.style.position = 'fixed';
          } else {
            nav.style.removeProperty('position');
          }
        }
        /* END:- Position:sticky effect in IE 11*/

        let viewportHeight = window.screen.height, rowHeight = 25, nodePadding = 20, itemCount = this.state.rowSize;
        //const totalContentHeight = itemCount * rowHeight;

        let startNode = Math.floor(scrollTop / rowHeight) - nodePadding;
        startNode = Math.max(0, startNode);
        let visibleNodesCount = Math.ceil(viewportHeight / rowHeight) + 2 * nodePadding;
        visibleNodesCount = Math.min(itemCount - startNode, visibleNodesCount);
        onPivotColClick(this.state.colEnabled, this.state.headerL, startNode, visibleNodesCount, false, undefined);
       // ConfigurationUtils.hideLoadMask(this.context.intl);
      }
    }, 150);
  }

  render() {
    return (
      <tbody id="pivot_tbody" >
        {this.state.rowResponse}
      </tbody>
      
    );
  }
}
export default PivotTableRows;
