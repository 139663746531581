import React, { Component } from "react";
import "../../../css/index.css";

import TableGrid from "../../../components/commons/table/APTable";
import { Button } from "grommet/components/Button";
import Store from "../../../flux/APTableStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import ComponentNotification from "../../generic/ComponentNotification";
import * as FetchHelper from "../../../utils/FetchHelper";

class UserSearch extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            userSearch: this.props.userSearch,
            email:"",
            userList: [],
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            selectAllRows: false,
            userDetail: undefined,
            selectedRowKeys:"",
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            }
           
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.closeUserSearchButton = this.closeUserSearchButton.bind(this);
        this.handleAddButton = this.handleAddButton.bind(this);
       
    }

    closeUserSearchButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        this.setState({
            selectedRowKeys: []
        })
        this.props.closeUserSearchButton();
    }


    handleAddButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.state.userList.length > 0){
            const formPayload = {
                userList:this.state.userList,
                groupName:this.state.groupName
            };

            this.props.handleAddButton(formPayload);
                    this.setState({
                selectedRowKeys: [],
                userList:[]
            })
        } else {
            Dispatcher.dispatch({
                type: "COMP_INVALID",
                payload: LocaleUtility.getLocaleMessage("um.alert16")
            });
        }
    }
    onRowClicked(keys, event){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
       this.setState({
        userList:keys,
        groupName:this.props.groupName
       })      
    }

    infoSectionContent() {

        const showHideModalStyle = this.props.userSearch ? "modal display-block" : "modal display-none";
       
           return (
            <div id="umGroupSerchResultDiv" className={showHideModalStyle}>
                <div className="modal">

                     <div className="modal-main panel panel-default" style={{maxWidth: "50%", maxHeight: "85%", border: "0px"}}>
                        <div className="panel-heading">
                            <h2 className="panel-title">{this.props.title}</h2>
                        </div>
                        <div style={{height: "370px", overflow: "hidden scroll"}} className="panel-body">
                        <ComponentNotification />
                        <section pad="none" wrap={false} responsive={false} justify="center">
                            <TableGrid id="user_list"
                                    header={[
                                        {
                                            columnName: "email",
                                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.email"),
                                            width: "auto",
                                            columnKey: "email",
                                        }
                                        
                                    ]}
                                    {...{keys:this.state.selectedRowKeys}}
                                    rowData={this.props.userGrpData}
                                    //pagination={this.props.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.onPaginationClicked}
                                    sortIndex={this.props.sortIndex}
                                    sortAscending={this.props.sortAscending}
                                    rowKey="email"
                                    onChangeCheckBoxCallback={this.onRowClicked}
                                    selectAllRows={false}
                                />
               
                        </section>
                        
                        </div>
                        <div id="umSearchResultButtonContainer" style={{ width: '100%', backgroundColor: "#fff"}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="button_left_wrap">
                                                <div className="button_wrap_primary">
                                                    <Button id="user_search_addBtn" label={LocaleUtility.getLocaleMessage("label.button.add")}
                                                        type="button" plain={false} primary={true} onClick={this.handleAddButton}
                                                    />
                                                </div>
                                                <div className="button_wrap_negative">
                                                    <Button id="user_search_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                                    type="button" accent={true} plain={false} onClick={this.closeUserSearchButton} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
        
    }

    render() {
        var showContent;
        if(this.props.userGrpData !== undefined){
            showContent=  this.infoSectionContent();
        }
        return (
            <div>
                 {showContent}
            </div>
            
        );
    }
}

export default UserSearch;