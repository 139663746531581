import ReduceStore from "./ReduceStore";

class DatabaseConfigurationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            dbhost: "",
            dbport: "",
            dbname: "",
            dburl: "",
            dbtype: "",
            dbuser: "",
            dbDatapasswd: "",
            dbTypeList: null,
            status: "",
            errorDetail: null,
            fileName: "",
            dbSSL: ""
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_DB_CONFIG_SETTING_DETAILS": {
                return {
                    dbhost: action.payload.dbhost,
                    dbport: action.payload.dbport,
                    dbname: action.payload.dbname,
                    dburl: action.payload.dburl,
                    dbtype: action.payload.dbtype,
                    dbuser: action.payload.dbuser,
                    dbschema: action.payload.dbschema,
                    dbDatapasswd: action.payload.dbDatapasswd,
                    dbTypeList: action.payload.dbTypeList,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    enableMultiTenancy: action.payload.enableMultiTenancy,
                    fileName: action.payload.dbcertfilename,
                    dbSSL:action.payload.dbSSL
                };
            }
            default:
                return state;
        }
    }
}
export default new DatabaseConfigurationStore();
