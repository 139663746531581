import React, { Component } from "react";
import { Form } from "grommet/components/Form";

import SectionWithTitle from "../../generic/SectionWithTitle";
import DateRange from "../../generic/DateRange";
import DatesPopup from "../../generic/DatesPopup";
import { Button } from "grommet/components/Button";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as FonttendPagePath from "../../../utils/FrontendPagePath";
import * as DateUtility from "../../../utils/DateUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import { Anchor } from 'grommet/components/Anchor';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class AdvancedUsageReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selDateRangeVal: "",
            usageStartDate: "",
            usageEndDate: "",
            startTime: "",
            endTime: "",
            showModal: false,
            displayValue: "",
            showUsagePopup: false,
            datesErrorMessage: ""
        }
        this.findLabel = this.findLabel.bind(this);
        this.onSelectDateRange = this.onSelectDateRange.bind(this);
        this.handleGenerateAction = this.handleGenerateAction.bind(this);
        this.handleSendUsageAction = this.handleSendUsageAction.bind(this);
        this.handleCancelAction = this.handleCancelAction.bind(this);
        this.datesCancelAction = this.datesCancelAction.bind(this);
        this.datesOkAction = this.datesOkAction.bind(this);
        this.continueUsageAction = this.continueUsageAction.bind(this);
        this.closePopuAction = this.closePopuAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    handleGenerateAction() {
        let startDate = new Date(this.state.usageStartDate);
        let endDate = new Date(this.state.usageEndDate);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);
        let request = {
            usageStartDate: startDate.getTime(),
            usageEndDate: endDate.getTime()
        }
        FetchHelper.downloadAdvancedReports(request, this.context.intl);
    }
    handleSendUsageAction() {
        this.setState({showUsagePopup: true});
    }
    continueUsageAction() {
        FetchHelper.sendUsageReport(this.state.startTime, this.state.endTime, true, this.context.intl);
        this.closePopuAction();
    }
    closePopuAction() {
        this.setState({showUsagePopup: false});
    }
    handleCancelAction() {
        let currentDate = new Date();
        let previousDate = new Date();
        previousDate.setDate( currentDate.getDate() - 29 );
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        this.setState({selDateRangeVal: {"label": this.findLabel("label.date.last.thirty.days"), 
        "value": DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate)}});
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        this.setState({usageEndDate: DateUtility.getDate(currentDate)});
        previousDate.setHours(0);
        previousDate.setMinutes(0);
        previousDate.setSeconds(0);
        previousDate.setMilliseconds(0);
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        this.setState({startTime: previousDate.getTime()});
        this.setState({endTime: currentDate.getTime()});
        this.setState({displayValue: DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate)});
    }
    componentDidMount() {
        let currentDate = new Date();
        let previousDate = new Date();
        previousDate.setDate( currentDate.getDate() - 29 );
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        this.setState({selDateRangeVal: {"label": this.findLabel("label.date.last.thirty.days"), 
        "value": DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate)}});
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        this.setState({usageEndDate: DateUtility.getDate(currentDate)});
        previousDate.setHours(0);
        previousDate.setMinutes(0);
        previousDate.setSeconds(0);
        previousDate.setMilliseconds(0);
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        this.setState({startTime: previousDate.getTime()});
        this.setState({endTime: currentDate.getTime()});
        this.setState({displayValue: DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate)});
    }
    datesCancelAction() {
        this.setState({showModal: false});
    }
    datesOkAction(event, s) {
        let sDate = s.fromDate;
        let eDate = s.toDate;
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let previousDate = new Date(sDate);
            let currentDate = new Date(eDate);
            previousDate.setHours(0);
            previousDate.setMinutes(0);
            previousDate.setSeconds(0);
            previousDate.setMilliseconds(0);
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            currentDate.setMilliseconds(0);
            let fDate = previousDate.getTime();
            let tDate = currentDate.getTime();
            if(tDate < fDate) {
                //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation1", this.context.intl);
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                this.setState({displayValue: sDate+" - "+eDate});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate});
                this.setState({startTime: fDate});
                this.setState({endTime: tDate});
                this.setState({showModal: false});
            }
        } else {
            //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation2", this.context.intl);
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }
    onSelectDateRange(event) {
        let v = event.option.value;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            this.setState({displayValue: v});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            let previousDate = new Date(val[0]);
            let currentDate = new Date(val[1]);
            previousDate.setHours(0);
            previousDate.setMinutes(0);
            previousDate.setSeconds(0);
            previousDate.setMilliseconds(0);
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            currentDate.setMilliseconds(0);
            this.setState({startTime: previousDate.getTime()});
            this.setState({endTime: currentDate.getTime()});
        }
    }
    render() {
        //let intl = this.context.intl;
        let showHideUsagePopup = this.state.showUsagePopup ? "usagePopupDiv display-block" : "usagePopupDiv display-none";
        return(
            <div className="config-cont-backgroud">
                <div>
                    <SectionWithTitle title={this.findLabel("usage.advance.report.title")}  helpTopic="Advanced_Reports"/>
                    <section pad="none" wrap={false} responsive={false} justify="center">
                        <Form compact={false} pad="small" id="usage_adv_report_form_id" plain={true} onSubmit={this.configurationSubmit}>
                            <div className="flex_fw_fdc border_white_backg_shadow">
                                        <div>
                                            <DateRange 
                                                label = {this.findLabel("usage.advance.report.timerange.label")+": "}
                                                onChange = {this.onSelectDateRange}
                                                value = {this.state.selDateRangeVal}
                                                displayValue = {this.state.displayValue}
                                                width = "30em"
                                                defaultValue = {3}
                                                renderFor = "advancedreports"
                                                dropdownWidth = "fit-content"
                                            />
                                        </div>
                                        <div>
                                            <p><b>{this.findLabel("usage.advance.report.note")}</b> {this.findLabel("usage.advance.report.note1")}<br/>
                                            {this.findLabel("usage.advance.report.note2")}
                                            </p>
                                        </div>
                                        <div className="button_left_wrap">
                                            <div className="button_wrap_primary">
                                                <Button id="usage_advreport_generateReportBtn" label={this.findLabel("usage.report.generate.button.label")} plain={false} primary={true} onClick={this.handleGenerateAction} />
                                            </div> 
                                            {/* <div className="button_wrap_primary">
                                                <Button id="usage_advreport_usageBtn" label={this.findLabel("usage.report.usage.button.label")} plain={false} primary={true} onClick={this.handleSendUsageAction} />
                                            </div>
                                            <div className="button_wrap_negative">
                                                <Button id="usage_advreport_cancelBtn" label={this.findLabel("usage.report.cancel.button.label")} plain={false} secondary={true} onClick={this.handleCancelAction} />
                                            </div> */}
                                        </div>
                                    </div>
                        </Form>
                    </section>
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
                {this.state.showModal ? <DatesPopup 
                    cancelAction = {this.datesCancelAction}
                    startDateLabel = {this.findLabel("usage.graph.from.label")}
                    endDateLabel = {this.findLabel("usage.graph.to.label")}
                    showModal = {this.state.showModal}
                    okAction={this.datesOkAction}
                    fromDate = {this.state.usageStartDate}
                    toDate = {this.state.usageEndDate} 
                    errorMessage = {this.state.datesErrorMessage}
                /> : ""}
                <div id="usagePopupDivid" className={showHideUsagePopup}>
                    <div id="usagePopDivid" className="usagePopupDiv">
                        <span className="usageClose" onClick={this.closePopuAction}>X</span>
                                <div className="usagePopup_info">
                                    <span><Anchor label={this.findLabel("usage.advance.popup.line1")} onClick={this.handleGenerateAction}/> </span><br/><br />
                                    <span>{this.findLabel("usage.advance.popup.line2")}</span><br/>
                                    <span>{this.findLabel("usage.advance.popup.line3")}</span><br/>
                                    <span><a href={FonttendPagePath.MF_PRIVACY_PAGE_LINK} target="_blank" rel="noopener noreferrer" >{this.findLabel("usage.advance.popup.line4")}</a></span><br /><br />
                                </div>
                                {/* <div className="button_right_wrap">
                                    <Button id="usage_adv_popup_usageBtn" label={this.findLabel("usage.report.usage.button.label")} plain={false} primary={true} onClick={this.continueUsageAction} />
                                    <span style={{padding: "5px"}}></span>
                                    <Button id="usage_adv_popup_cancelBtn" label={this.findLabel("usage.report.cancel.button.label")} plain={false} secondary={true} onClick={this.closePopuAction} />
                                </div> */}
                    </div>
                </div>
            </div>
        )
    }
}
// AdvancedUsageReports.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AdvancedUsageReports;