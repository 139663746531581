import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import Select from "../../commons/APLSSelect";

import SectionWithTitle from "../../generic/SectionWithTitle";
import { Button } from "grommet/components/Button";
//import { FormField } from "grommet/components/FormField";
import SpinningNotification from "../../generic/SpinningNotification";
import * as FetchHelper from "../../../utils/FetchHelper";
//import * as TableUtility from "../../../utils/TableUtility";
import Store from "../../../flux/APTableStore";
import ProductStore from "../../../flux/ProductStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FrontendPagePath from "../../../utils/FrontendPagePath";
import * as Utils from "../../../utils/Util";
import "../../../css/index.css";
// var _propTypes = require("prop-types");
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseBorrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBorrowData: [],
      activeSortIndex: Store.getState().activeSortIndex,
      activeSortAscending: Store.getState().activeSortAscending,
      archivedBorrowData: [],
      archivedSortIndex: Store.getState().archivedSortIndex,
      archivedSortAscending: Store.getState().archivedSortAscending,
      productNameSelected: undefined,
      productNameSelectOptions: ProductStore.getState()
        .productNameSelectOptions,
      selectAllRows: false,
      session: {},
      activePagination: {
        deleted: false,
        sortBy: "installedOn",
        sortOrder: "DESC",
        pageNumber: "1",
        totalCount: 0,
        pageSize: 5
      },
      archivedPagination: {
        deleted: false,
        sortBy: "installedOn",
        sortOrder: "DESC",
        pageNumber: "1",
        totalCount: 0,
        pageSize: 5
      },
      pagination: {
        required: true,
        style: "default",
        totalCount: 0,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize()
      },
      disableIds:[]
    };
    this.refreshState = this.refreshState.bind(this);
    this.refreshProductItem = this.refreshProductItem.bind(this);
    this.filterLicenseList = this.filterLicenseList.bind(this);
    this.onActiveTableRowClicked = this.onActiveTableRowClicked.bind(this);
    this.onArchiveTableRowClicked = this.onArchiveTableRowClicked.bind(this);
    this.onActivePaginationClicked = this.onActivePaginationClicked.bind(this);
    this.onArchivePaginationClicked = this.onArchivePaginationClicked.bind(
      this
    );
    this.returnLicense = this.returnLicense.bind(this);
    this.deleteLicense = this.deleteLicense.bind(this);
  }

  componentDidMount() {
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    FetchHelper.getProducts("borrow_init", true);
    Store.on("change", this.refreshState);
    ProductStore.on("change", this.refreshProductItem);
  }

  componentWillUnmount() {
    Store.removeListener("change", this.refreshState);
    ProductStore.removeListener("change", this.refreshProductItem);
  }

  onSortClicked(columnName, order, header) {
    // TableUtility.sort(columnName, order, header);
  }

  filterLicenseList(event) {
    if (event.option.value !== undefined) {
      let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
      Dispatcher.dispatch({
        type: loadSpinAction,
        payload: LocaleUtility.getLocaleMessage(
          "label.spinner.loading"
        )
      });
      FetchHelper.getBorrowLicenses(event.option.value, "ACTIVE");
      FetchHelper.getBorrowLicenses(event.option.value, "ARCHIVED");
      this.setState({ productNameSelected: event.option.value });
      //FetchHelper.getProducts("borrow_init");
    } else {
      this.setState({
        activeBorrowData: [],
        archivedBorrowData: [],
        productNameSelected: undefined
      });
    }
  }

  onActiveTableRowClicked(keys, event) {
    this.setState({
      selectedActiveKeys: keys
    });
  }

  onArchiveTableRowClicked(keys, event) {
    this.setState({
      selectedArhivedKeys: keys
    });
  }

  onActivePaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.activePagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    FetchHelper.getBorrowLicenses(
      this.state.productNameSelected,
      "ACTIVE",
      paginationTemp
    );
  };

  onArchivePaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.archivedPagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    FetchHelper.getBorrowLicenses(
      this.state.productNameSelected,
      "ARCHIVED",
      paginationTemp
    );
  };

  returnLicense() {
    if (
      this.state.selectedActiveKeys !== undefined &&
      this.state.selectedActiveKeys.length > 0
    ) {
      var selectedKeys = this.state.selectedActiveKeys;
      FetchHelper.returnBorrowedLicenseList(
        this.state.productNameSelected,
        selectedKeys
      );
      this.setState({
        selectedActiveKeys: []
      });
    } else {
      if (
        this.state.activeBorrowData !== undefined &&
        this.state.activeBorrowData.length > 0
      ) {
        // Dispatcher.dispatch({
        //     type: "LICENSE_NOT_CHECKED_ALERT_SHOW_MODAL",
        //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.borrow.alert1")
        // });
        /*alert(
          LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.license.borrow.alert1"
          )
        );*/
        Dispatcher.dispatch({
              type: "ALERT_SHOW_MODAL",
              payload: LocaleUtility.getLocaleLabel(
                this.context.intl,
                "label.license.borrow.alert1"
              )
        });
      }
    }
  }

  deleteLicense() {
    if (
      this.state.selectedArhivedKeys !== undefined &&
      this.state.selectedArhivedKeys.length > 0
    ) {
      var selectedKeys = this.state.selectedArhivedKeys;
      FetchHelper.removeBorrowedLicenseList(
        this.state.productNameSelected,
        selectedKeys
      );
      this.setState({
        selectedArhivedKeys: []
      });
    } else {
      if (
        this.state.archivedBorrowData !== undefined &&
        this.state.archivedBorrowData.length > 0
      ) {
        // Dispatcher.dispatch({
        //     type: "LICENSE_NOT_CHECKED_ALERT_SHOW_MODAL",
        //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.borrow.alert2")
        // });
        /*alert(
          LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.license.borrow.alert2"
          )
        );*/
        Dispatcher.dispatch({
              type: "ALERT_SHOW_MODAL",
              payload: LocaleUtility.getLocaleLabel(
                this.context.intl,
                "label.license.borrow.alert2"
              )
        });
      }
    }
  }

  refreshState() {
    const activeRows = Store.getState().activeBorrowData;
    const archiveRows = Store.getState().archivedBorrowData;
    var actPagination = Store.getState().actPagination;
    var archPagination = Store.getState().archPagination;

    if (actPagination !== undefined) {
      actPagination.required = true;
      actPagination.style = "default";
      if (activeRows.length === 0) actPagination.totalCount = 0;
    }
    if (archPagination !== undefined) {
      archPagination.required = true;
      archPagination.style = "default";
      if (archiveRows.length === 0) archPagination.totalCount = 0;
    }

    if (activeRows !== undefined) {
      this.setState({
        activePagination: actPagination,
        activeBorrowData: Store.getState().activeBorrowData,
        activeSortIndex: Store.getState().activeSortIndex,
        activeSortAscending: Store.getState().activeSortAscending,
        productNameSelected: Store.getState().productNameSelected,
        disableIds: Store.getState().disableIds
      });
    }
    if (archiveRows !== undefined) {
      this.setState({
        archivedPagination: archPagination,
        archivedBorrowData: Store.getState().archivedBorrowData,
        archivedSortIndex: Store.getState().archivedSortIndex,
        archivedSortAscending: Store.getState().archivedSortAscending,
        productNameSelected: Store.getState().productNameSelected
      });
    }
    Dispatcher.dispatch({
      type: "LICENSE_FETCH_SPIN_DONE",
      payload: ""
    });
  }

  refreshProductItem() {
    this.setState({
      productNameSelectOptions: ProductStore.getState()
        .productNameSelectOptions,
      productNameSelected: ProductStore.getState().productNameSelected,
      session: FetchHelper.getSession()
    });
  }

  sectionContent() {
    const options = [];
    let selectedValue = {
      value: undefined,
      label: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.select.none"
      )
    };
    options.push(selectedValue);
    var entries = this.state.productNameSelectOptions.entries();
    let count = 0;
    for (var item of entries) {
      count++;
      options.push({ value: item[0], label: item[1] });
      if (
        this.state.productNameSelected !== undefined &&
        item[0] === this.state.productNameSelected
      ) {
        selectedValue = { value: item[0], label: item[1] };
      }
    }

    let borrowLicenseBtn, returnButton, removeButton;
    if ((this.state.session.admin || this.state.session.roletype === 'product') && count > 0) {
      borrowLicenseBtn = (
        <div className="button_wrap_primary">
          <Button
            id="lic_bor_borrowBtn"
            label={LocaleUtility.getLocaleLabel(
              this.context.intl,
              "label.button.borrow.license"
            )}
            //href={{pathname: FrontendPagePath.LICENSE_BORROW_FORM_PATH, search: Utils.buildURLQueryParam(), state: {}}}
            href={FrontendPagePath.LICENSE_BORROW_FORM_PATH+Utils.buildURLQueryParam()}
            plain={false}
            primary={true}
          />
        </div>
      );        
      if(this.state.disableIds.length<this.state.activeBorrowData.length){
      returnButton = (<div className="button_wrap_negative">
        <Button
          id="lic_bor_returnBtn"
          label={LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.button.return"
          )}
          accent={true}
          plain={false}
          onClick={this.returnLicense.bind(this)}
        />
      </div>);
      } else {
        returnButton = (<div className="button_wrap_negative">
        <Button
          id="lic_bor_returnBtn"
          label={LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.button.return"
          )}
          accent={true}
          plain={false}
          disabled={true}
        />
      </div>);
    }
        
      removeButton = (
        <div className="button_wrap_negative">
          <Button
            id="lic_bor_delBtn"
            label={LocaleUtility.getLocaleLabel(
              this.context.intl,
              "label.button.delete"
            )}
            accent={true}
            plain={false}
            onClick={this.deleteLicense.bind(this)}
          />
        </div>
      );
    }

    return (
      <section
        id="lic_bor_listSec"
        className="page_section_wrap_1"
        pad="none"
        wrap={false}
        responsive={false}
        justify="center"
      >
        <div className="action_filter_wrapper">
                {/*<FormField
                  style={{ width: "30em" }}
                  label={LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.select.product"
                  )}
                >
                  <Select
                    id="lic_bor_selProduct"
                    options={options}
                    value={selectedValue}
                    onChange={this.filterLicenseList}
                  />
                  </FormField>*/}
                  <div className="action_wrap_left">
                  {borrowLicenseBtn}
                  <Select className="select_component_wrap"
                    width={25}
                    id="lic_bor_selProduct"
                    options={options}
                    value={selectedValue}
                    onChange={this.filterLicenseList}
                    label={LocaleUtility.getLocaleLabel(
                      this.context.intl,
                      "label.select.product"
                    )+": "}
                  />
                  </div>
              {<div style={{width: "4%"}}>
                <SectionWithTitle
                  id="lic_bor_mainSec"
                  title={LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.manage.borrowed.license"
                  )}
                  helpTopic="C_Borrow_License"
                  setionWidth = "100"
                />
              </div>}
            </div>
            <section
                  id="lic_bor_listSec1"
                  className="page_subsection_wrap"
                  pad="medium"
                  wrap={false}
                  responsive={false}
                  justify="center"
                >
                  <div>
                    <span className="subheader">
                      {LocaleUtility.getLocaleLabel(
                        this.context.intl,
                        "label.borrow.license.list"
                      )}
                    </span>
                  </div>
                  <TableGrid
                    id="lic_bor_borrowGrid"
                    header={[
                      {
                        columnName: "featureDesc",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.featureid.version"
                        ),
                        href: false,
                        width: "40%"
                      },
                      {
                        columnName: "capacity",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.capacity"
                        ),
                        href: false,
                        width: "10%"
                      },
                      {
                        columnName: "startDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.startDate"
                        ),
                        href: false,
                        width: "auto"
                      },
                      {
                        columnName: "expiryDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.expDate"
                        ),
                        href: false,
                        width: "auto"
                      },
                      {
                        columnName: "serverHostName",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.borrowedFrom"
                        ),
                        href: false,
                        width: "auto"
                      }
                    ]}
                    {...{ keys: this.state.selectedActiveKeys }}
                    rowData={this.state.activeBorrowData}
                    pagination={this.state.activePagination}
                    rowSelection={3}
                    isExportRequired={false}
                    onSort={this.onSortClicked}
                    onPagination={this.onActivePaginationClicked}
                    sortIndex={this.state.activeSortIndex}
                    sortAscending={this.state.activeSortAscending}
                    rowKey="licenseSignature"
                    disabledRowIds={this.state.disableIds}
                    uncheckTheDisabled={true}
                    onChangeCheckBoxCallback={this.onActiveTableRowClicked}
                    noDataFoundText={LocaleUtility.getLocaleMessage(
                      "no.items.found.message"
                    )}
                    selectAllRows={false}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                      "label.grid.item.count.message",
                      { count: this.state.activePagination.totalCount }
                    )}
                  />
                  <div style={{ clear: "both" }} />
                  {returnButton}
                </section>
                <section
                  id="lic_bor_listChkSec"
                  className="page_subsection_wrap"
                  pad="medium"
                  wrap={false}
                  responsive={false}
                  justify="center"
                >
                  <div>
                    <span className="subheader">
                      {LocaleUtility.getLocaleLabel(
                        this.context.intl,
                        "label.borrow.checkedin.list"
                      )}
                    </span>
                  </div>
                  <TableGrid
                    id="lic_bor_checkedGrid"
                    header={[
                      {
                        columnName: "featureDesc",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.featureid.version"
                        ),
                        href: false,
                        width: "40%"
                      },
                      {
                        columnName: "capacity",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.capacity"
                        ),
                        href: false,
                        width: "10%"
                      },
                      {
                        columnName: "startDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.startDate"
                        ),
                        href: false,
                        width: "auto"
                      },
                      {
                        columnName: "expiryDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.expDate"
                        ),
                        href: false,
                        width: "auto"
                      },
                      {
                        columnName: "serverHostName",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.borrowedFrom"
                        ),
                        href: false,
                        width: "auto"
                      }
                    ]}
                    {...{ keys: this.state.selectedArhivedKeys }}
                    rowData={this.state.archivedBorrowData}
                    pagination={this.state.archivedPagination}
                    rowSelection={3}
                    isExportRequired={false}
                    onSort={this.onSortClicked}
                    onPagination={this.onArchivePaginationClicked}
                    sortIndex={this.state.archivedSortIndex}
                    sortAscending={this.state.archivedSortAscending}
                    rowKey="licenseSignature"
                    onChangeCheckBoxCallback={this.onArchiveTableRowClicked}
                    noDataFoundText={LocaleUtility.getLocaleMessage(
                      "no.items.found.message"
                    )}
                    selectAllRows={false}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                      "label.grid.item.count.message",
                      { count: this.state.archivedPagination.totalCount }
                    )}
                  />
                  <div>
                    <SpinningNotification centerPage={true} />
                  </div>
                  <div style={{ clear: "both" }} />
                  {removeButton}
                </section>
      </section>
    );
  }

  render() {
    return (
      <div className="config-cont-backgroud">
        {this.sectionContent()}
      </div>
    );
  }
}

// LicenseBorrow.contextTypes = {
//   intl: _propTypes2.default.object
// };
export default LicenseBorrow;
