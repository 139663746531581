import React, { Component } from "react";
import { FormField } from "grommet/components/FormField";
import { RadioButton } from 'grommet/components/RadioButton';
import { Button } from "grommet/components/Button";
import { CheckBox } from "grommet/components/CheckBox";
import { TextInput } from 'grommet/components/TextInput';
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import Dispatcher from "../../../../flux/Dispatcher";
import "../../../../css/index.css";
import {validateSpecialChars, isNumber} from "../../../../utils/ValidationUtility";
import * as LicenseUtility from "../../../../utils/LicenseUtility";
import ComponentNotification from "../../../generic/ComponentNotification";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseRenewForm extends Component {

    constructor(props) {
      super(props);
      this.state = {
        update: true,
        //formDirty: false,
        latestVerChecked:false,
        currentVerChecked:true,
        renewStatus: false,
        renewForm: 0
      }
        this.handleChangeCurrrent = this.handleChangeCurrrent.bind(this);
        this.handleChangeLatest = this.handleChangeLatest.bind(this);
        this.handleRenewDays = this.handleRenewDays.bind(this);
        this.onChangeDefaultCheck = this.onChangeDefaultCheck.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.showModal && this.state.update){
            //console.log(" >>>> "+this.props.renewDetail.pasActivationId);
            Dispatcher.dispatch({
                type: "COMP_CLOSE"
            });

            let latestVerChecked, currentVerChecked;
            if(this.props.renewDetail !== undefined && this.props.renewDetail.enableNewVersion){
                latestVerChecked = true;
                currentVerChecked = false;
            } else {
                latestVerChecked = false;
                currentVerChecked = true;
            }

            let disableAutoRenew = false;
            if(this.props.renewDetail !== undefined && 
                    this.props.renewDetail.disableAutoRenew !== undefined){
                disableAutoRenew = true;
            }

            this.setState({
                latestVerChecked:latestVerChecked,
                currentVerChecked:currentVerChecked, fieldLabelError:"", 
                noOfDays: ((this.props.renewDetail !== undefined && 
                                this.props.renewDetail.daysBeforeToRenew !== undefined) ? 
                                this.props.renewDetail.daysBeforeToRenew.toString() : "30"),
                defaultCheck: disableAutoRenew,
                disableAutoRenew: !disableAutoRenew,
                renewStatus: ((this.props.renewDetail !== undefined && 
                                    this.props.renewDetail.renewStatus !== undefined && 
                                    this.props.renewDetail.renewStatus > 0) ? 
                                    true : false),
                renewForm: ((this.props.renewDetail !== undefined && 
                                    this.props.renewDetail.renewStatus !== undefined ) ? 
                                    this.props.renewDetail.renewStatus : 0),
               // formDirty: false,
                update: false
            });
        }
    }

    onChangeDefaultCheck(event) {
        this.setState({
            defaultCheck: event.target.checked,
            disableAutoRenew: !event.target.checked,
            // formDirty: ((this.props.renewDetail.disableAutoRenew === undefined && event.target.checked) ||
            //     !this.props.renewDetail.disableAutoRenew !== event.target.checked)
        });
    }

    handleChangeCurrrent(e){
        this.setState({ latestVerChecked:false,
                        currentVerChecked:true });
        // if(!this.state.formDirty)
        //    this.setState({formDirty: (this.props.renewDetail.enableNewVersion ? true: false)});
    }

    handleChangeLatest(e){
        this.setState({ currentVerChecked:false,
                        latestVerChecked:true });
        
        // if(!this.state.formDirty)
        //    this.setState({formDirty: (this.props.renewDetail.enableNewVersion ? false: true)});
    }

    handleRenewDays(event) {
        let msg = "", fieldfilter, value = event.target.value;
        if(value === '') {
          msg = LocaleUtility.getLocaleMessage("label.input.field.alert");
        } else if(!validateSpecialChars(value)) {
          msg = LocaleUtility.getLocaleMessage("enter.valid.chars.for.text");
        } else if(!isNumber(value)) {
          msg = LocaleUtility.getLocaleMessage("label.borrow.license.form.alert1");
        } else if(Number(value) > 90 || Number(value) < 30){
          msg = LocaleUtility.getLocaleMessage("license.renew.details.create.alert1");
        }
        this.setState({noOfDays: value, 
                        fieldLabelError: msg });
        // if(!this.state.formDirty)
        //    this.setState({ formDirty: this.props.renewDetail.daysBeforeToRenew !== Number(value)});
    }

    updateState(){
        this.setState({update: true});
    }

    infoSectionContent(licDetail) {
        let intl = this.context.intl;
        let lockValues;var safekey = false;
        let msg = LocaleUtility.getLocaleLabel(intl, "label.license.renew.form.enable.checkbox");
        
        if(this.props.renewDetail !== undefined && this.props.renewDetail.disableAutoRenew !== undefined &&
            !this.props.renewDetail.disableAutoRenew && !this.state.defaultCheck){
            msg = LocaleUtility.getLocaleLabel(intl, "label.license.renew.form.disable.renew");
        }else if(this.props.renewDetail !== undefined && this.props.renewDetail.disableAutoRenew !== undefined &&
            !this.props.renewDetail.disableAutoRenew && this.state.renewStatus){
            if(this.props.renewDetail.renewStatus === 1)
                msg = LocaleUtility.getLocaleLabel(intl, "label.license.renew.form.renew.success");
            else
                msg = LocaleUtility.getLocaleLabel(intl, "label.license.renew.form.disable.renew.edit");
        }else if(this.state.defaultCheck){
            msg = LocaleUtility.getLocaleLabel(intl, "label.license.renew.form.disable.checkbox");
        }
        return <div>
                { licDetail !== undefined && ( <table className="lic-detail-table table-bordered">
                    <tbody>
                        <tr className="lic-detail-head">
                            <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber")}</td>
                            {licDetail.environment !== undefined && licDetail.environment !== 'NA' && (
                                <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.environment")}</td>
                            )}
                            <td width="5%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu")}</td>
                            <td width="5%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity")}</td>
                            <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate")}</td>
                            <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate")}</td>
                            <td width="10%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.lockValues")}</td>
                            <td colSpan="2" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.licTracking")}</td>
                        </tr>
                        <tr>
                            <td>{licDetail.productID}</td>
                            {licDetail.environment !== undefined && licDetail.environment !== 'NA' && (
                                <td>{licDetail.environment}</td>
                            )}
                            <td>{licDetail.ltuCapacity}</td>
                            <td>{licDetail.capacity}</td>
                            <td>{licDetail.startDate}</td>
                            <td>{licDetail.expiryDate}</td>
                            <td>{lockValues}</td>
                            <td><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.orderNumber")}: </b>
                            {licDetail.orderNumber}</td>
                            <td><b>{LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.user")}: </b>{licDetail.userName}</td>
                        </tr>
                    </tbody>
                </table>
                )}
                { licDetail !== undefined && ( <table className="lic-detail-table table-bordered">
                    <tbody>
                        <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.featureid.version")}
                            </td>
                            <td>
                                {licDetail.featureID +' : '+licDetail.featureVersion}
                            </td>
                        </tr>
                        { licDetail.ipAddressList && licDetail.ipAddressList.value.length > 1 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.ip.address")}</td>
                            <td>{licDetail.ipAddressList.value.toString()}</td>
                            </tr>
                        )}
                        { licDetail.deviceIdList && licDetail.deviceIdList.value.length > 1 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.device.id")}</td>
                            <td>{licDetail.deviceIdList.value.toString()}</td>
                            </tr>
                        )}
                        { licDetail.hostIdList && licDetail.hostIdList.value.length > 1 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.host.id")}</td>
                            <td>{licDetail.hostIdList.value.toString()}</td>
                            </tr>
                        )}
                        { licDetail.cloudIdList && licDetail.cloudIdList.value.length > 1 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.cloud.id")}</td>
                            <td>{licDetail.cloudIdList.value.toString()}</td>
                            </tr>
                        )}
                        { licDetail.macAddressList && licDetail.macAddressList.value.length > 1 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.mac.address")}</td>
                            <td>{licDetail.macAddressList.value.toString()}</td>
                            </tr>
                        )}
                        {licDetail.productVersion !== undefined && licDetail.productVersion !== '' && (
                            <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.productversion")}
                            </td>
                                <td>
                                {licDetail.productVersion}
                            </td>
                            </tr>
                        )}
                        {licDetail.licenseFormat !== 'HPLICENSE' && licDetail.isPAC && (
                            <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.product.activation")}
                            </td>
                                <td>
                                {LicenseUtility.processLicenseKey(licDetail.productBundle)}
                            </td>
                            </tr>
                        )}
                        {licDetail.passwordType !== undefined && licDetail.passwordType !== 'PERMANENT' && (
                            <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.password.type")}
                            </td>
                            <td>
                                {licDetail.passwordType}
                            </td>
                            </tr>
                        )}
                        {licDetail.ioDuration !== undefined && licDetail.ioDuration !== -1 && licDetail.ioDuration !== 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.io.duration")}
                            </td>
                            <td>
                                {licDetail.ioDuration}
                            </td>
                            </tr>
                        )}
                        {licDetail.ioStartDate !== undefined && licDetail.ioStartDate !== -1 && licDetail.ioStartDate !== 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">
                                {LocaleUtility.getLocaleLabel(this.context.intl, "label.io.startdate")}
                            </td>
                            <td>
                                {licDetail.ioStartDate}
                            </td>
                            </tr>
                        )}
                        {licDetail.annotation !== undefined && licDetail.annotation !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.annotation")}
                                </td>
                                <td>
                                    {licDetail.annotation}
                                </td>
                            </tr>
                        )}
                        {licDetail.licenseType !== undefined && licDetail.licenseType !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.licenseType")}
                                </td>
                                <td>
                                    {licDetail.licenseType}
                                </td>
                            </tr>
                        )}
                        { safekey && licDetail.system !== undefined && licDetail.system !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.system.name")}
                                </td>
                                <td>
                                    {licDetail.system}
                                </td>
                            </tr>
                        )}
                        { safekey && licDetail.purpose !== undefined && licDetail.purpose !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.purpose")}
                                </td>
                                <td>
                                    {licDetail.purpose}
                                </td>
                            </tr>
                        )}
                        { safekey && licDetail.said !== undefined && licDetail.said !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.said")}
                                </td>
                                <td>
                                    {licDetail.said}
                                </td>
                            </tr>
                        )}
                        { safekey && licDetail.uom !== undefined && licDetail.uom !== '' && (
                            <tr>
                                <td className="lic-detail-head" width="20%">
                                    {LocaleUtility.getLocaleLabel(this.context.intl, "label.capacity.unit.measure")}
                                </td>
                                <td>
                                    {licDetail.uom}
                                </td>
                            </tr> 
                        )}
                        { safekey && licDetail.countryList && licDetail.countryList.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl,"label.countries")}</td>
                            <td>{licDetail.countryList.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.regionList && licDetail.regionList.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.regions")}</td>
                            <td>{licDetail.regionList.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.continentList && licDetail.continentList.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.continents")}</td>
                            <td>{licDetail.continentList.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.machinesBlocked && licDetail.machinesBlocked.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.machine.blocked")}</td>
                            <td>{licDetail.machinesBlocked.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.machinesAllowed && licDetail.machinesAllowed.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.machine.allowed")}</td>
                            <td>{licDetail.machinesAllowed.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.supportedOS && licDetail.supportedOS.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.os")}</td>
                            <td>{licDetail.supportedOS.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.overridesLicenseList && licDetail.overridesLicenseList.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.override.license")}</td>
                            <td>{licDetail.overridesLicenseList.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.dependsOnLicenseList && licDetail.dependsOnLicenseList.value.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.depend.license")}</td>
                            <td>{licDetail.dependsOnLicenseList.value.toString()}</td>
                            </tr>
                        )}
                        { safekey && licDetail.licenseID && licDetail.licenseID !== '' && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.unique.licenseId")}</td>
                            <td>{licDetail.licenseID}</td>
                            </tr>
                        )}
                        { licDetail.attributeList && licDetail.attributeList.entry.length > 0 && (
                            <tr>
                            <td className="lic-detail-head" width="20%">{LocaleUtility.getLocaleLabel(this.context.intl, "label.extended.attr")}</td>
                            <td>
                                <table className="lic-detail-table table-bordered">
                                    <tbody>
                                    {licDetail.attributeList.entry.map(item => {
                                        return <tr>
                                                <td>{item.key}</td>
                                                <td>{LicenseUtility.processLicenseKey(item.value.value)}</td>
                                                </tr>
                                    })}
                                    </tbody>
                                </table>
                            </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                )}
            <table >
            <tbody>
            <tr><td colSpan="3"><ComponentNotification style={{top: "45px"}} /></td></tr>
            <tr>
                <td colSpan="3">
                <FormField  style={{ width: '100%' }} >
                    <CheckBox label= {msg} disabled={this.state.renewStatus}
                    id="lic_renew_enableChk" name="renewEnableCheck" checked={this.state.defaultCheck} 
                    onChange={this.onChangeDefaultCheck.bind(this)}/>
                </FormField>
                </td>
            </tr>
            </tbody>
            </table>
            { this.state.renewForm !== 1 && this.state.defaultCheck && (
            <table >
            <tbody>
                <tr>
                    <td colSpan="3">
                        <FormField className="customTextInput" 
                            label={LocaleUtility.getLocaleMessage("label.license.renew.form.renew.number.of.days")} 
                            error={this.state.fieldLabelError} >
                            <TextInput id="field_label_id" autoFocus={true} disabled={this.state.renewStatus}
                                value={this.state.noOfDays} 
                                onChange={event =>this.handleRenewDays(event)}
                            />
                        </FormField>
                    </td>
                </tr>
                <tr><td><label style={{ paddingLeft: '24px' }}>{LocaleUtility.getLocaleMessage("label.license.renew.form.radio.product.version")} :</label></td>
                    <td><FormField  >
                            <RadioButton id="renew-cur-version" checked={this.state.currentVerChecked} disabled={this.state.renewStatus}
                            onChange={this.handleChangeCurrrent} label={LocaleUtility.getLocaleMessage("label.current.product.version")}/>
                        </FormField></td>
                    <td><FormField >
                            <RadioButton id="renew-new-version" checked={this.state.latestVerChecked} disabled={this.state.renewStatus}
                            onChange={this.handleChangeLatest} label={LocaleUtility.getLocaleMessage("label.latest.product.version")}/>
                        </FormField></td>
                    </tr>
            </tbody>
            </table>
            )}
        </div>
    }

    render() {
        return (
            this.infoSectionContent(this.props.licenseDetail)
        );
    }
}

// LicenseRenewForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseRenewForm;