import React, { Component } from "react";
import {createHead, createFilter} from "./utill/TableUtills";

let move = false;
const buildResizableColumn = function(col, colResizer) {
    let xMousePt = 0;
    let colWidth = 0;
    let cursor;

    const mouseDownHandler = function(e) {
        xMousePt = e.clientX; //Current Mouse Position

        const styles = window.getComputedStyle(col);
        colWidth = parseInt(styles.width, 10);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function(e) {
        move = true;
        cursor = e.target;
        const updatedXPosition = e.clientX - xMousePt; //Difference of width
        if(cursor.className === "table-row-header")
          cursor.style.cursor = "col-resize";
        col.style.width = `${colWidth + updatedXPosition}px`; //Update width
    };

    // Remove event listeners
    const mouseUpHandler = function() {
        move = false;
        const cols = document.getElementsByClassName('table-row-header');
        [].forEach.call(cols, function(col) {
          col.style.cursor = "pointer";
        });
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    colResizer.addEventListener('mousedown', mouseDownHandler);
};

class TableHeader extends Component {

  constructor(props) {
    super(props);
    this.buildColumnResizer = this.buildColumnResizer.bind(this);
  }

  componentDidMount(){
    this.buildColumnResizer();
  }

  buildColumnResizer(){
    const table = document.getElementById(this.props.id);
    const head = table.querySelectorAll('thead')[0];
    const cols = head.querySelectorAll('td');

    let index=0, itm, temp;
    [].forEach.call(cols, function(col) {
        if(col.className === 'colResizer'){
          if(temp)
            buildResizableColumn(temp, col);
        } else
          temp = col;  
    });
  }
  getTableHead = (header, rowSelection, sortIndex, sortAscending, 
    sortCallbackMethod, isSelectAllClicked,onChangeCheckBoxCallback, selectedCheckBoxKey, childKey) => {
   return createHead(header, rowSelection, sortIndex, sortAscending, 
    sortCallbackMethod, isSelectAllClicked, onChangeCheckBoxCallback, selectedCheckBoxKey, childKey);
  };

  getTableFilter = (header, rowSelection, defaultFilter, filterCallbackMethod) => {
    return createFilter(header, rowSelection, defaultFilter, filterCallbackMethod);
  };


  render() {
    return (
      <thead>
        <tr className="table_header_row">
          {this.getTableHead(
            this.props.header,
            this.props.rowSelection,
            this.props.sortIndex,
            this.props.sortAscending,
            this.props.onSort,
            this.props.isSelectAllClicked,
            this.props.onChangeCheckBoxCallback,
            this.props.selectedCheckBoxKey,
			      this.props.childKey
          )}
        </tr>
        <tr className="table_header_row_2">
          {this.getTableFilter(
            this.props.header,
            this.props.rowSelection,
            this.props.setDefaultFilter,
            this.props.onFilter
          )}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
