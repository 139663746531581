import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { RadioButton } from 'grommet/components/RadioButton';
import CTConfigStore from "../../../flux/CTConfigStore";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import ProductStore from "../../../flux/ProductStore";
import CTFeatureTenantMap from "./CTFeatureTenantMap";
import { Edit as EditIcon } from 'grommet-icons';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from "../../commons/grommet/Header";
import TableGrid from "../../../components/commons/table/APTable";
import { CheckBox } from "grommet/components/CheckBox";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher"
import * as ValidationUtil from "../../../utils/ValidationUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';

class ControlTowerTenantConfig extends Component{
    render(){
        return(
           
                <div className="tab_1_wrapper">
                    <section pad="none" wrap={false} responsive={false} justify="center" class="border_white_backg_shadow">
                        <Form compact={false} id="conf_ct_tenant_idm_formId" pad="small" plain={true}>
                            <h2 className="headerConfig">
                                    {this.props.findLabel("configpageCTConnectionAttributes", this.props.intl)}
                            </h2>
                
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.freequencyError} required={true} label= {this.props.findLabel("usageSendingFreequency", this.props.intl)} >
                                            <TextInput id="conf_ct_usage_freequency" value={this.props.ctUsageFreequency.toString()} onChange={event =>this.props.handleChangeFrequency(event)} />
                                        </FormField>
                                        </div>
                                        </td>
                                    </tr>
                        
                                    <tr>
                                        <td>
                                            <div className="horizantal_label_input ct_input_wrap">
                                            <FormField error={this.props.hostnameError} required={true} label= {this.props.findLabel("configpageIDMServerURL", this.props.intl)} >
                                                <TextInput id="conf_ct_tenant_idm_ServerUrlId" value={this.props.tenantIdmServerUrlValue} onChange={event =>this.props.handleTenantChangeIdmServerUrl(event)} />
                                            </FormField>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="horizantal_label_input ct_input_wrap">
                                            <FormField error={this.props.usernameError} required={true} label= {this.props.findLabel("configpageIDMUserNameforCT", this.props.intl)} >
                                                <TextInput id="conf_ct_tenant_idm_UserNameId" value={this.props.tenantIdmUserNameValue} onChange={event =>this.props.handleTenantChangeUserName(event)}/>
                                            </FormField>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="wrapper">
                                            <div className="horizantal_label_input ct_input_wrap">
                                            <FormField error={this.props.passwordError}  required={true} label= {this.props.findLabel("configpageIDMPasswordforCT", this.props.intl)} >
                                                <Password  className = "password_icon" id="conf_ct_tenant_idm_PasswordId" value={this.props.tenantIdmPasswordValue} onChange={this.props.handleTenantChangePassword} disabled={this.props.updateFlagTenant} />
                                            </FormField>
                                            {this.props.editTenantPasswordButton}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="horizantal_label_input ct_input_wrap">
                                            <FormField error={this.props.ctHostError}  required={true} label= {this.props.findLabel("configpageCTServerURL", this.props.intl)} >
                                                <TextInput id="conf_ct_tenant_ServerUrlId" value={this.props.ctTenantServerUrlValue} onChange={event =>this.props.handleChangeCTTenantServerUrl(event)} />
                                            </FormField>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="button_wrap_secondary" style={{marginTop:"-10px"}}>
                                            <Button id="conf_ct_tenant_idm_testConnetion" label={this.props.findLabel("configpageLDAPTestConnection", this.props.intl)} plain={false} primary={true} onClick={this.props.submitTestTenantIDMConnection} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>      
                                            <div className="horizantal_label_input ct_input_wrap">
                                                <FormField  style={{ width: '38em' }} label= {this.props.findLabel("usageType", this.props.intl)}>
                                                    <RadioButton id="conf_ct_tenant_usage_max" 
                                                    checked={this.props.maxTenantUsageChecked} 
                                                    label={this.props.findLabel("ctMaxUsage", this.props.intl)} />
                                                </FormField>
                                            </div> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>   
                        
                            <div>
                                <CTFeatureTenantMap ref={this.props.featureTenantRef} featuresTenantsList = {this.props.featuresTenantsList} tenantConfig = {true}/>
                            </div>    
                            <div className="button_left_wrap">
                                <div className="button_wrap_primary">
                                    <Button id="conf_ct_tenant_idm_saveBtn" label={this.props.findLabel("label.button.save", this.props.intl)} plain={false} primary={true} onClick={this.props.submitTenantIdmConfigSettings} />
                                </div>
                                    <span style={{padding: "5px"}}></span>
                                <div className="button_wrap_negative">  
                                    <Button id="conf_ct_tenant_idm_cancelBtn" plain={false} secondary={true} label={this.props.findLabel("label.button.cancel", this.props.intl)} primary={false} onClick={this.props.cancelTenantCTIdmConfigSettings} />
                                </div> 
                            </div>                                
                        </Form>
                    </section>
                </div>
        )
    }
}

export default ControlTowerTenantConfig;