import React, { Component } from "react";

class ColorLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        let poolName = this.props.poolName;
        let isConsumption = false;
        if(this.props.isConsumption) {
            isConsumption = true;
        }
        if(isConsumption) {
             return (
                <div>
                    <div style={{width: "100%", float: "left", color: this.props.poolNameIndicationColor}}>
                        {poolName}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="colorOption">
                    <div className="morePoolOptions" style={{width: "90%", float: "left"}}>{poolName}</div>
                    <div style={{width: "7%", backgroundColor: this.props.poolNameIndicationColor, height: "20px", float: "left"}}>
                        &nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            )
        }
    }
}
export default ColorLabel;