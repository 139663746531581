import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as Utils from "../../../utils/Util";
import Store from "../../../flux/ReservationManagementStore";
import SpinningNotification from '../../generic/SpinningNotification';
//import { Button } from "grommet/components/Button";
import Select from "../../commons/APLSSelect";
import ManagePool from "./ManagePools";
import ReservationStore from "../../../flux/ReservationManagementStore";
import { Anchor } from 'grommet/components/Anchor';
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { DataSearch as Search } from 'grommet/components/DataSearch';
import { useIntl } from 'react-intl';
import { Data } from "grommet/components";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class FeatureBaseReservation extends Component {
    constructor(props) {
        super(props);
        this.managePoolRef = React.createRef();
        this.state = {
            productNameList: [],
            gridData: [],
             pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            sortIndex: 0,
            sortAscending: true,
            defaultSelectIds: [],
            selProductName: "",
            defaultFeatureId: "",
            selProduct: "",
            enableManage: false,
            featureId: "",
            featureVersion: "",
            featureObjectsMap: {},
            reservedStatus: 0,
            groupList: [],
            featureSearchedValue: "",
            searchAction: false,
            searchResultList: []
        }
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.selectProductName = this.selectProductName.bind(this);
        this.handleManageAction = this.handleManageAction.bind(this);
        this.backToList = this.backToList.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.loadProdutNameDropDown = this.loadProdutNameDropDown.bind(this);
        this.backToPage = this.backToPage.bind(this);
        this.loadProductDropDown = this.loadProductDropDown.bind(this);
        this.onFeatureSearchAction = this.onFeatureSearchAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    handleManageAction(value) {
        let featureObj = this.state.featureObjectsMap[value];
        if(featureObj) {
            this.setState({defaultSelectIds: [featureObj.featureID]});
            this.setState({featureId: featureObj.featureID});
            this.setState({featureVersion: featureObj.featureVersion});
            this.setState({enableManage: true});
        }
    }
    backToList() {
        this.setState({enableManage: false, searchAction: false, featureSearchedValue: ""});
    }
    backToPage() {
        if(Store.getState() && Store.getState().actionType === "resetfeaturepools") {
            this.backToList();
        }
    }
    selectProductName(event) {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        this.setState({selProduct: event.option});
        this.setState({selProductName: event.option.value});
        this.findData(event.option.value, this.state.pagination, this.state.sortIndex);
    }
    onRowClicked(keys, event) {
        let featureObj = this.state.featureObjectsMap[keys[0]];
        if(featureObj) {
            this.setState({defaultSelectIds: [featureObj.featureID]});
            this.setState({featureId: featureObj.featureID});
            this.setState({featureVersion: featureObj.featureVersion});
        }
    }
    componentDidMount() {
        Store.on("change", this.refreshGrid);
        Store.on("change", this.backToPage);
        FetchHelper.listProductNamesForDropDown();
        ReservationStore.on("change", this.loadProdutNameDropDown);
        FetchHelper.listPorductNames(this.context.intl, this.state.sortIndex, this.state.pagination, false, "");
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
        Store.removeListener("change", this.backToPage);
        ReservationStore.removeListener("change", this.loadProdutNameDropDown);
    }
    findData(productName, pagination, index) {
        let request = {
            "productName": productName,
            "pagenation": pagination,
            "sortIndex": index
        }               
        FetchHelper.listFeaturesByProductName(this.context.intl, request);
    }
    loadProdutNameDropDown() {
        if(ReservationStore.getState() && ReservationStore.getState().actionType === "productNameList") {
            let data = ReservationStore.getState().data;
            if(data !== undefined) {
                this.loadProductDropDown(data.productNameList);
            }
        }
    }
    loadProductDropDown(productNameList) {
        if(productNameList !== undefined) {
            let pList = [];
            let defaultName = "";
            let count = 0;
            let defalultSelected = "";
            pList.push({label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"});
            if(productNameList.value !== undefined) {
                productNameList.value.map(element => {
                    let val = element.split(":");
                    if(count === 0) {
                        defaultName = val[0];
                        defalultSelected = val[1];
                    }
                    count++;
                    pList.push({label: val[1], value: val[0]});
                    return "";
                });
            } else {
                defaultName = "1";
                defalultSelected = LocaleUtility.getLocaleMessage("label.select.token.license.feature");
            }
            this.setState({productNameList: pList});
            this.setState({selProduct: {label: defalultSelected, value: defaultName}});
            this.setState({selProductName: defaultName});
        }
    }
    refreshGrid() {
        if(Store.getState() && Store.getState().actionType === "listproductfeatures") {
            let featureList = Store.getState().data.features;
            let groupList = Store.getState().data.groupList;
            let objectsMap = Store.getState().featureObjectsMap;
            let mappingData = Store.getState().data.featureGroupMaps;
            let poolMapping = {};
            if(mappingData.entry !== undefined) {
                Object.entries(mappingData.entry).map(([key, value]) =>{
                    poolMapping[value.key] = value.value;
                    return "";
                });
            } else {
                poolMapping = mappingData;
            }
            featureList.map(item => {
                item["poolMapping"] = poolMapping[item.featureID];
                item["featureIDToolTipText"] = this.findLabel("resv.feature.manage.note");
                return "";
            });
            this.setState({gridData: featureList});
            if(groupList !== undefined) {
                this.setState({groupList: groupList});
            }
            this.setState({defaultSelectIds: [Store.getState().defalultSelFeature]});
            this.setState({defaultFeatureId: Store.getState().defalultSelFeature});
            this.setState({featureId: Store.getState().defalultSelFeature});
            this.setState({featureObjectsMap: objectsMap, searchAction: false});
            if(objectsMap !== undefined && objectsMap[Store.getState().defalultSelFeature] !== undefined) {
                this.setState({featureVersion: objectsMap[Store.getState().defalultSelFeature].featureVersion})
            }
            this.loadProductDropDown(Store.getState().productNameList);
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    onFeatureSearchAction(event) {
        let sValue = event.target.value;
        let searchList = [];
        if(sValue !== '') {
            let comValue = sValue.toLowerCase();
            this.state.gridData.map(item => {
                let featureId = item.featureID+"";
                let featureDesc = item.featureDescription;
                let compFeatureDesc = featureDesc.toLowerCase();
                if(featureId.indexOf(comValue) > -1 || compFeatureDesc.indexOf(comValue) > -1) {
                    searchList.push(item);
                }
                return "";
            });
            this.setState({featureSearchedValue: sValue, searchAction: true, searchResultList: searchList});
        } else {
            this.setState({featureSearchedValue: sValue, searchAction: false});
        }
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "featureId";
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        paginationTemp.pageNumber = 1;
        this.findData(this.state.selProductName, paginationTemp, index);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.selProductName, paginationTemp, this.state.sortIndex);
    }
    render() {
        //let intl = this.context.intl;
        let noOfProducts = this.state.productNameList.length;
        if(noOfProducts === 1 && this.state.selProductName === "1" && this.state.gridData.length === 0) {
            noOfProducts = 0;
        }
        let paddingLeft = "5px";
        if(this.state.enableManage === true) {
            paddingLeft = "0px";
        }
        let tableData = this.state.gridData;
        if(this.state.searchAction === true) {
            tableData = this.state.searchResultList;
        }

        let locale = LocaleUtility.getCurrentLocale();
        let searchHeight = "30px", placeHolderCSS="search_input_wrap";
        if (locale == 'de' || locale == 'fr'){
            searchHeight = "50px";
            placeHolderCSS = "search_input_wrap expandPlaceHolder";
        }

        return(
            <div style={{paddingLeft: paddingLeft, paddingRight: "5px"}} className="config-cont-backgroud">
                {!this.state.enableManage ? 
                    <div >
                        {noOfProducts > 0 ?
                        <div className="action_filter_wrapper">
                            <div className="flex_start">
                                <div id="pool-attributes-search-div" className={placeHolderCSS}>
                                    <Data >
                                    <Search style={{ height:searchHeight}} placeholder={this.findLabel('label.type.to.search')}
                                        inline={true}
                                        noForm={false}
                                        drop={false}
                                        responsive={false}
                                        size='small'
                                        dropAlign={{"right": "right"}}
                                        value={this.state.featureSearchedValue}
                                        onChange={event=>this.onFeatureSearchAction(event)} 
                                        id="features-search-id"
                                    />
                                    </Data>
                                </div>
                                <div className="mar_left_10">
                                    <Select id="feature_base_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect")}
                                        options={this.state.productNameList}
                                        value={this.state.selProduct}
                                        onChange={this.selectProductName} 
                                        label= {this.findLabel("usageReportSelProduct")+": "}
                                        width= {30}
                                    />
                                </div>
                            </div>
                             <SectionWithTitle title={this.findLabel("resv.feature.base.title")}  helpTopic="R_Feature_Reservation_Tab"
                                setionWidth = "4"
                            />
                        </div>
                        : null }
                        {this.state.gridData.length > 0 ?
                        <div style={{float: "left"}}>
                            <div style={{marginTop: "3px"}}>
                            <TableGrid id="feature_base_list_grid"
                                header={[
                                    {
                                        columnName: "featureID",
                                        headerLabel: this.findLabel("usageReportFeatId"),
                                        width: "10%",
                                        columnKey: "featureID",
                                        enabledToolTip: true,
                                        onClick: this.handleManageAction,
                                        href: true,
                                    },
                                    {
                                        columnName: "featureVersion",
                                        headerLabel: this.findLabel("usageReportFeaVer"),
                                        width: "13%"
                                    },
                                    {
                                        columnName: "featureDescription",
                                        headerLabel: this.findLabel("usageReportFeatDesc") ,
                                        href: false,
                                        width: "auto"
                                    },
                                    {
                                        columnName: "poolMapping",
                                        headerLabel: this.findLabel("resv.pool.mapping.label") ,
                                        href: false,
                                        width: "auto"
                                    }
                                ]}
                                rowData={tableData}
                                pagination={this.state.pagination}
                                rowSelection={0}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                //onChangeCheckBoxCallback={this.onRowClicked}
                                rowKey="featureID"
                                defaultSelectIds={this.state.defaultSelectIds}
                            />
                            </div>
                              <div className="manage-pool-btnwrap">
                            {this.state.groupList.length === 0 ? 
                                <p className="reservation-emtpy-message">{this.findLabel("no.groups")}&nbsp;<Anchor label={this.findLabel("no.groups.create.pool")} href={FrontendPagePath.RESERVATION_POOL_MANAGE_PATH+Utils.buildURLQueryParam()}/></p>
                            :
                                /*<table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <Button id="feature_base_manageBtn" label={this.findLabel("label.button.manage", intl)} plain={false} primary={true} onClick={this.handleManageAction} />
                                        </td>
                                        <td>
                                            {this.findLabel("resv.feature.manage.note", intl)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>*/
                                null
                            }
                            </div>
                            
                        </div>
                        : <p>{this.findLabel("resv.feature.not.found.message")}</p> }
                    </div>
                    : 
                    <ManagePool ref={this.managePoolRef}
                        backButtonAction = {this.backToList}
                        featureId = {this.state.featureId}
                        featureVersion = {this.state.featureVersion}
                        productName = {this.state.selProductName}
                        reservedStatus = {this.state.reservedStatus}
                    />
                }
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// FeatureBaseReservation.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default FeatureBaseReservation;