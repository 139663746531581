import React, { Component } from "react";
import { Tiles } from '../../commons/grommet/Tiles';
import { Tile } from '../../commons/grommet/Tile';
import { Box } from 'grommet/components/Box';
import { Heading } from 'grommet/components/Heading';
import { Header } from 'grommet/components/Header';
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import { Trash as TrashIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import Select from "../../commons/APLSSelect";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as DateUtility from "../../../utils/DateUtility";
import {validateSpecialChars, isNumber} from "../../../utils/ValidationUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import Store from "../../../flux/GenericUsageStore";
import DateRange from "../../generic/DateRange";
import DatesPopup from "../../generic/DatesPopup";
import Dispatcher from "../../../flux/Dispatcher";

class ReportFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {        
                tasks: [],
                type: "",
                update: false,
                showFieldPopup: false,
                stdAttrbutes:[],
                fieldProp: undefined,
                fields: [{value: "", label:LocaleUtility.getLocaleMessage("label.select.none")}],
                colValueOptions: [],
                addFieldError:"",
                filterValueError:"",
                filters: [],
                showModal: false,
                usageStartDate: "",
                usageEndDate: "",
                selDateRangeVal: "",
                datesErrorMessage: "",
                displayValue: undefined, 
                selDateRange: {"label": LocaleUtility.getLocaleMessage("label.date.last.thirty.days"), "value": 3},
                // selFilterValue:{
                //     value: "", label:LocaleUtility.getLocaleMessage("label.select.none")
                // },
                selectedFilter:{
                    value: "", label:LocaleUtility.getLocaleMessage("label.select.none")
                },
                selectedFName: {
                    value: "", label:LocaleUtility.getLocaleMessage("label.select.none")
                },
                selectedFDataType: {
                    value: "",
                    label: LocaleUtility.getLocaleMessage("label.select.none")
                },
                filtermap: new Map([["string", [
                    {value: "", label:LocaleUtility.getLocaleMessage("label.select.none")},
                    {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")}
                ]], ["numeric", [
                    {value: "", label:LocaleUtility.getLocaleMessage("label.select.none")},
                    {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")},
                    {value: "greaterthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.greaterthan.label")},
                    {value: "lesserthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.lesserthan.label")}
                ]]]),
            }
        this.updateElements = this.updateElements.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.editActionCall = this.editActionCall.bind(this);
        this.onChangeFieldFilterType = this.onChangeFieldFilterType.bind(this);
        this.handleChangeFilterValue = this.handleChangeFilterValue.bind(this);
        this.onChangeFieldName = this.onChangeFieldName.bind(this);
        this.okAction = this.okAction.bind(this);
        this.loadFieldVal = this.loadFieldVal.bind(this);
        this.addFilterFields = this.addFilterFields.bind(this);
        this.populateDates = this.populateDates.bind(this);
        this.datesCancelAction = this.datesCancelAction.bind(this);
        this.datesOkAction = this.datesOkAction.bind(this);
        this.onSelectDateRange = this.onSelectDateRange.bind(this);
        this.onChangeFieldDataType = this.onChangeFieldDataType.bind(this);
        this.deepEqual = this.deepEqual.bind(this);
        this.isObject = this.isObject.bind(this);
    }

    componentDidMount(){

        var map = new Map(), flds = this.state.fields, field, upFilterArr=[];
        if(this.props.fieldSet !== undefined && this.props.report !== undefined){
            for (let [key, value] of this.props.fieldSet) { 
                field = this.props.report.fields.filter(function(itm){
                    return itm.name === value.name;
                });

                if(field !== undefined && field[0] !== undefined){
                    map.set(value.name, field[0]);
                    if(field[0].dataType !== value.dataType && this.state.filters.length > 0){
                        upFilterArr = this.state.filters.filter(function(itm){
                            return (itm.indexOf(value.name+"=") === -1);
                        });
                        this.updateElements(upFilterArr);
                    }
                } else {
                    map.set(value.name, value);
                }
            }
        }
        let filters = [];
        if(this.props.filter !== undefined){
            filters = this.props.filter;
        }
        flds.push.apply(flds, this.props.fields);
        this.setState({fields: flds, fieldMap: map, 
            filtertypes: this.state.filtermap.get('string'), filters: filters});
        Store.on("change", this.loadFieldVal);
    }

    componentWillUnmount() {
      Store.removeListener("change", this.loadFieldVal);
    }

    componentDidUpdate(prevProps, prevState) {
        // if(prevProps.fields.length !== this.props.fields.length || this.state.update){
        if(!this.deepEqual(this.props.fields, prevProps.fields) || this.state.update){
            let flds = this.state.fields, field, upFilterArr=[];
            let defVal = {value: "", label:LocaleUtility.getLocaleMessage("label.select.none")}
            // flds.push.apply(flds, this.props.fields);
            flds = this.props.fields;
            var map = new Map();
            if(this.props.fieldSet !== undefined){
                for (let [key, value] of this.props.fieldSet) { 
                    field = this.props.reportFields.filter(function(itm){
                        return itm.name === value.name;
                    });

                    if(field !== undefined && field[0] !== undefined){
                        map.set(value.name, field[0]);
                        if(field[0].dataType !== value.dataType && this.state.filters.length > 0){
                            upFilterArr = this.state.filters.filter(function(itm){
                                return (itm.indexOf(value.name+"=") === -1);
                            });
                            this.updateElements(upFilterArr);
                        }
                    } else {
                        map.set(value.name, value);
                    }
                }
            }
            if(this.state.filters !== undefined && this.state.filters.length ===0 &&
                this.props.filter !== undefined && this.props.filter.length > 0){
                this.updateElements(this.props.filter);
            }
            this.setState({fields: flds, fieldMap: map, update: false, selFilterValue: undefined, fieldfilter: undefined, type: "",
                        filterValue : "", selectedFName:defVal, selectedFilter: defVal});
        }
    }

    loadFieldVal(){
        let type = Store.getState().type, colValueOptions=[];
        if(type === 'fieldName'){
            let fieldVal = Store.getState().fieldVal;
            fieldVal.map(itm => {
                colValueOptions.push({value: itm, label:itm});
                return "";
            });
            this.setState({colValueOptions: colValueOptions, selFilterValue: {
                    value: "", label:LocaleUtility.getLocaleMessage("label.select.none")
                }});
        }
    }

    updateComponent(flag){
        this.setState({update:true});
    }

    updateElements(elements){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        let divs = [], fieldEle, fieldEleStr = [], fieldLabel;
        elements.map((t, index) => { 

            fieldLabel = t;
            fieldEleStr = t.split("=");
            fieldEle = this.state.fieldMap.get(fieldEleStr[0]);
            if(fieldEle){
                fieldLabel = fieldEle.label ? fieldEle.label+"="+fieldEleStr[1] : t
            }

            divs[index] = 
                <table key={index}> 
                    <tbody> 
                    <tr> 
                    <td>
                    <Tile id={"element_"+index} separator='top' align='start' 
                        key={index} style={{display:"inline-block"}}>
                        <Box pad='small' style={{float:"left"}}> 
                            {fieldLabel} 
                        </Box>
                        <div style={{padding:"12px", float:"right"}}>
                            <Anchor  title={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                onClick={this.removeElement.bind(this, index)} >
                                <TrashIcon id={"del_"+index} name="delLink" size="huge" className="grommetux-control-icon grommetux-color-index-critical"/>
                            </Anchor>                   
                        </div>
                    </Tile>
                    </td>
                    </tr>
                    </tbody>
                </table>
            ;   
            return t; 
        });
        this.setState({tasks: divs, filters: elements, update: false});
        this.props.updateAction(elements);
    }

    editActionCall(key, event){
        let element = {...this.state.elements[key]};
        this.setState({showFieldPopup: true, fieldProp: element});
    }

    removeElement(key, event){
        const taskArray = Object.assign([], this.state.filters);
        taskArray.splice(key, 1);
        this.updateElements(taskArray);
    }

    handleChangeFilterValue(event) {
        let msg = "";
        if(!validateSpecialChars(event.target.value)) {
            msg = LocaleUtility.getLocaleMessage("enter.valid.chars.for.text");
        } else if(!isNumber(event.target.value)) {
            msg = LocaleUtility.getLocaleMessage("label.borrow.license.form.alert1");
        }
        this.setState({filterValue: event.target.value, filterValueError: msg, addFieldError:""});
    }

    onChangeFieldFilterType(event){
        let fieldfilter;
        if(this.state.filterValue !== undefined && this.state.filterValue !== '' &&
            event.option.value !== undefined && event.option.value !== ''){
            fieldfilter = event.option.value+":"+this.state.filterValue;
        }
        this.setState({selectedFilter: event.option, fieldfilter: fieldfilter, addFieldError:""});
    }

    onChangeFieldName(event){
        let type, datatype, displayValue = undefined, field;
        if(event.option.value !== ''){
            field = this.state.fieldMap.get(event.option.value);
            datatype = field.dataType;
            if(datatype === 'string'){
                type = "string";
                FetchHelper.getCustomPropValue(event.option.value, this.props.product);
            }
            else if(datatype === 'datetime'){
                type = "datetime";
                displayValue = this.populateDates(29, 3, true);
            }
            else
                type = "numeric";
            datatype = this.props.dataTypes.filter(function(itm){
                                return (itm.value === datatype);
                            });
            this.setState({selectedFName: event.option, addFieldError:"", displayValue: displayValue,
                    filtertypes: this.state.filtermap.get(type), type: type, selectedFDataType: datatype[0], selFilterValue: undefined});
        }
    }

    onChangeFieldDataType(event){
        let type, datatype = event.option.value, displayValue = undefined;
        if(datatype !== undefined){
            this.setState({displayValue: undefined});
            if(datatype === 'string'){
                type = "string";
                FetchHelper.getCustomPropValue(event.option.value, this.props.product);
            }
            else if(datatype === 'datetime'){
                type = "datetime";
                displayValue = this.populateDates(29, 3, true);
            } else
                type = "numeric";
            this.setState({selectedFDataType: event.option, filtertypes: this.state.filtermap.get(type), addFieldError: "",
                displayValue:displayValue, type: type});
        }
    }

    okAction(event){
        if(event.option.value !== ''){
            this.setState({selFilterValue: event.option, addFieldError:""});
        }
    }

    addFilterFields(event){
        let str = "", fieldArrayOption = [], flag=true, msg, type, field;
        let defVal = {value: "", label:LocaleUtility.getLocaleMessage("label.select.none")}
        if(this.state.selectedFName !== undefined && this.state.selectedFName.value !== '' &&
         (this.state.fieldfilter !== undefined || this.state.displayValue !== undefined || 
            (this.state.selFilterValue !== undefined && this.state.selFilterValue.value !== ''))){
            if(this.state.selFilterValue !== undefined && this.state.selFilterValue.value !== ''){
                str = this.state.selectedFName.value +"="+ this.state.selFilterValue.value.replace(/(^\s*,)|(,\s*$)/g, '');
            } else if(this.state.fieldfilter !== undefined){
                str = this.state.selectedFName.value +"="+ this.state.fieldfilter
            } else {
                str = this.state.selectedFName.value +"="+this.state.displayValue.replace(" - ", " --- ");
            }
            this.state.filters.map(itm => {
                field = this.state.fieldMap.get(this.state.selectedFName.value);
                type = field.dataType;
                if(itm.indexOf(this.state.selectedFName.value +"=") > -1){
                    if(type === 'string' || type === 'datetime' || 
                        itm.indexOf(this.state.fieldfilter.split(":")[0]) > -1){
                        fieldArrayOption.push(str);
                        flag = false;
                    } else {
                        fieldArrayOption.push(itm);
                    }
                } else {
                   fieldArrayOption.push(itm);
                }
                return "";
            });
            if(flag)
                fieldArrayOption.push(str);
            this.updateElements(fieldArrayOption);
            this.setState({selFilterValue: undefined, fieldfilter: undefined, type: "", selectedFDataType: defVal, addFieldError: "",
                        filterValue : "", selectedFName:defVal, selectedFilter: defVal, displayValue: undefined});
        } else {
            if(this.state.type === ""){
                msg = LocaleUtility.getLocaleMessage("usage.report.filter.alert1");
            } else if(this.state.type === 'string'){
                msg = LocaleUtility.getLocaleMessage("usage.report.filter.alert2");
            } else {
                msg = LocaleUtility.getLocaleMessage("usage.report.filter.alert3");
            }
            this.setState({addFieldError : msg});
        }
    }

    populateDates(noOfdays, opt, flag) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        if(opt === 0) {
            previousDate = DateUtility.convertToServerTimeZone(previousDate);
        }
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        if(opt === 1) {
            this.setState({usageEndDate: DateUtility.getDate(previousDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(previousDate));
            }
        } else {
            this.setState({usageEndDate: DateUtility.getDate(currentDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(currentDate));
            }
        }
        let v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate);
        if(!flag) {
            v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(previousDate);
            this.setState({selDateRangeVal: {"label": LocaleUtility.getLocaleMessage("label.date.today"), "value": v}});
        }
        return v;
    }

    onSelectDateRange(event) {
        let v = event.option.value;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            this.setState({displayValue: v});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            this.selectDateRange(val[0], val[1]);
        }
    }
    selectDateRange(usageStartDate, usageEndDate) {
        this.setState({usageStartDate: usageStartDate});
        this.setState({usageEndDate: usageEndDate})
    }

    datesCancelAction() {
        this.setState({showModal: false});
    }

    datesOkAction(event, s) {
        let sDate = s.fromDate;
        let eDate = s.toDate;
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let fDate = new Date(sDate).getTime();
            let tDate = new Date(eDate).getTime();
            if(tDate < fDate) {
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                this.setState({displayValue: sDate+" - "+eDate});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate})
                this.selectDateRange(sDate, eDate);
                this.setState({showModal: false});
            }
        } else {
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }

    deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (areObjects && !this.deepEqual(val1, val2) ||!areObjects && val1 !== val2) {
                return false;
            }
        }
        return true;
    
    }
    isObject(object) {
    return object != null && typeof object === 'object';
    }

    render () {  
        let addFieldError, addFError, selFError, selFieldError;
        if(this.state.addFieldError !== ''){
            addFError =<span className="grommetux-form-field__error" style={{position:"relative"}}>
            {this.state.addFieldError}</span>;
            addFieldError='grommetux-form-field--error';
        } if(this.state.filterValueError !== ''){
            selFError =<span className="grommetux-form-field__error" style={{position:"relative", width:"15em"}}>
            {this.state.filterValueError}</span>;
            selFieldError='grommetux-form-field--error';
        } 
        return (
                <div className="wip">
                <Header size='small'
                    pad={{"horizontal": "small"}}>
                    <Heading level="4"
                        size="medium"
                        margin='none'>
                        {LocaleUtility.getLocaleMessage("label.report.filter.settings")}  
                    </Heading>
                </Header>
                <div style={{padding: "10px", border: "2px solid #323435"}}>
                    <table><tbody><tr>
                    <td style={{ width: '20%',verticalAlign: "top" }}>
                        <Select  id="rep-filter-name" 
                            label={LocaleUtility.getLocaleMessage("usage.report.select.fields.label")}
                            options={this.state.fields}
                            value={this.state.selectedFName}
                            disabledScroll = {false}
                            onChange={this.onChangeFieldName.bind(this)} />
                    </td>
                    <td style={{ width: '20%',verticalAlign: "top" }}>
                        <Select id="usage_rep_field_datatype" 
                            label={LocaleUtility.getLocaleMessage("usage.report.select.fields.type.label")}
                            options={this.props.dataTypes}
                            value={this.state.selectedFDataType}
                            onChange={this.onChangeFieldDataType} />
                    </td>
                    {this.state.type === 'string' && (
                        <td style={{width: "30%", verticalAlign: "top" }}>
                            <Select id="rep-filter-val-selection" placeholder={LocaleUtility.getLocaleMessage("configpageIpHostSelect")}
                                options={this.state.colValueOptions}
                                {...{value:this.state.selFilterValue}}
                                value={this.state.selFilterValue}
                                onChange={this.okAction} 
                                label= {LocaleUtility.getLocaleMessage("usage.report.select.filter.label")+": "}
                                enabledSearch = {false}
                                //width= {20}
                                multiSelect = {true}
                            />
                        </td> )}
                    {this.state.type === 'numeric' && (
                        <td colSpan="2" style={{width:"40%", verticalAlign: "top"}}>
                        <div style={{float:"left"}}>
                            <div style={{float:"left", marginTop: "10px"}}>
                                {LocaleUtility.getLocaleMessage("usage.report.fields.filter.label")}:
                            </div>
                            <div className={selFieldError} style={{float:"right", marginRight: "6px", marginLeft: "6px", width:"15em"}}>
                                <TextInput style={{ width: '15em'}} id="rep-field-filter-value" 
                                    value={this.state.filterValue} 
                                    onChange={event =>this.handleChangeFilterValue(event)}
                                />
                                {selFError}
                            </div>
                        </div>
                        <div style={{float:"right"}}>
                            <Select id="rep-field-filter" label={LocaleUtility.getLocaleMessage("usage.report.select.fields.filter.label")}
                            options={this.state.filtertypes}
                            value={this.state.selectedFilter}
                            disabledScroll = {false}
                            onChange={this.onChangeFieldFilterType.bind(this)} />
                        </div>
                        </td>
                     )}
                     {this.state.type === 'datetime' && (
                        <td style={{width:"30%", verticalAlign: "top"}}>
                         <DateRange 
                            label = {LocaleUtility.getLocaleMessage("usage.graph.daterange.label")+" "}
                            onChange = {this.onSelectDateRange}
                            value = {this.state.selDateRangeVal}
                            displayValue = {this.state.displayValue}
                            // width = "22em"
                            defaultValue = {3}
                            renderFor = "history"
                        />
                        </td>
                     )}
                    <td style={{ width: '12%' }}>
                        <div className={addFieldError}>
                            {/* <span style={{paddingRight: "10px"}}></span> */}
                            <div className="button_wrap_primary_wofloat">
                            <Button id="rep-filter-addBtn" label={LocaleUtility.getLocaleMessage("usage.report.add.filter.label")} plain={false} primary={true} onClick={this.addFilterFields} />
                            </div>
                            {addFError}
                        </div>
                    </td> 
                    </tr></tbody></table>
                    <div>
                        {this.state.tasks.length > 0 ? 
                        <Tiles flush={false} style={{border: "1px solid #ddd"}}
                            fill={false}>
                            {this.state.tasks}                        
                        </Tiles>
                        : LocaleUtility.getLocaleMessage("label.no.filters.added.for.report")} 
                    </div>
                    {this.state.showModal ? <DatesPopup 
                        cancelAction = {this.datesCancelAction}
                        startDateLabel = {LocaleUtility.getLocaleMessage("usage.graph.from.label")}
                        endDateLabel = {LocaleUtility.getLocaleMessage("usage.graph.to.label")}
                        showModal = {this.state.showModal}
                        fromDate = {this.state.usageStartDate}
                        toDate = {this.state.usageEndDate}
                        errorMessage = {this.state.datesErrorMessage}
                        okAction={this.datesOkAction} /> : ""}
                </div>
            </div> 
        );
        
    }
}
export default ReportFilter;