import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from "grommet/components/TextInput";
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { CheckBox } from "grommet/components/CheckBox";
import { Anchor } from "grommet/components/Anchor";
import TableGrid from "../../commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import { encryptText } from "../../../utils/Util";
import Store from "../../../flux/APTableStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import AuditStore from "../../../flux/AuditStore";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Dispatcher from "../../../flux/Dispatcher";
import SpinningNotification from "../../generic/SpinningNotification";
import { Copy as CopyIcon } from 'grommet-icons';
import Select from "../../commons/APLSSelect";
import { DateInput as DateTime} from "grommet/components/DateInput";
import { getLocaleMessage } from "../../../utils/LocaleUtility";
import * as DateUtility from "../../../utils/DateUtility";
import { DocumentCsv as DocumentCsvIcon } from 'grommet-icons';

// var _Intl = require("grommet/utils/Intl");
// var _propTypes = require("prop-types");
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
var timer = null;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
class Audits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormDirty: false,
      enableexpireIndaysChecked: false,
      enableEmailNotificaionIndays: "",
      enableexpireForCapcityChecked: false,
      enableEmailNotificaionForCapacity: "",
      expDaysError: "",
      expInDays: 0,
      capacityError: "",
      capacityPersentage: 0,
      enteredAttrValue: "",
      rowData: [],
      impFeatureTableData: [],
      impFeatureModalData: [],
      sortIndex: Store.getState().sortIndex,
      sortAscending: Store.getState().sortAscending,
      productNameSelected: undefined,
      productNameSelectOptions: [],
      selectAllRows: false,
      licenseDetail: undefined,
      pagination: {
        required: true,
        style: "default",
        sortOrder: "DESC",
        totalCount: 0,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize(),
      },
      disableApplyBtnIndex: 0,
      modalVisible: false,
      nuberOfDaysDisabled: true,
      capacityNotificationDisabled: true,
      selectedRowKeys: [],
      notifyBy: 0,
      emailIdsList: "",
      emailError: "",
      doSave: true,
      serverConfigured: true,
      doAdd: true,
      doDelete: true,
      selectedEntity: "com.hp.autopassj.ls.database.model.User",
      entityId: "",
      attribute: "",
      operation: "",
      userName: "",
      oldValue: "",
      newValue: "",
      startDate: "",
      endDate: "",
      enableAudits: true,
    };
    this.refreshFormData = this.refreshFormData.bind(this);
    this.onPaginationClicked = this.onPaginationClicked.bind(this);
    this.onEntityFltrChange = this.onEntityFltrChange.bind(this);
    this.onFilterClicked = this.onFilterClicked.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.exportExcelAction = this.exportExcelAction.bind(this);
    this.clearFilterData = this.clearFilterData.bind(this);
    this.getStartDateFilterVal = this.getStartDateFilterVal.bind(this);
    this.getEndDateFilterVal = this.getEndDateFilterVal.bind(this);
    this.loadDataOnComponentMount = this.loadDataOnComponentMount.bind(this);
  }
  findLabel(key, intl) {
    return LocaleUtility.getLocaleMessage(key);
  }
  componentDidMount() {
    ConfigurationUtils.showLoadMask("", this.context.intl);
    AuditStore.on("change", this.refreshFormData);
    let currentDate = this.getStartDateFilterVal();
    let previousDate = this.getEndDateFilterVal(currentDate);
    this.setState({endDate:  DateUtility.getDate(currentDate),
          startDate: DateUtility.getDate(previousDate)
      }, this.loadDataOnComponentMount);
  }

  loadDataOnComponentMount() {
    let pageData = {
      pagination: this.state.pagination,
      entityFilterVal: this.state.selectedEntity,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    FetchHelper.listAudits(this.context.intl, pageData);
  }

  componentWillUnmount() {
    AuditStore.removeListener("change", this.refreshFormData);
  }

  refreshFormData() {
    let sortIndex = 7;
    this.setState({
      rowData: AuditStore.getState().audits,
      sortIndex,
      pagination: AuditStore.getState().pagination,
      selectedEntity: AuditStore.getState().entityFilterVal,
      entityId: AuditStore.getState().entityIdVal,
      attribute: AuditStore.getState().attributeVal,
      operation: AuditStore.getState().operationVal,
      userName: AuditStore.getState().userVal,
      oldValue: AuditStore.getState().oldVal,
      newValue: AuditStore.getState().newVal,
      startDate: AuditStore.getState().startDate,
      endDate: AuditStore.getState().endDate,
      enableAudits: AuditStore.getState().auditEnabled === 'Y' ? true : false,
    });
    ConfigurationUtils.hideLoadMask(this.context.intl);
  }

  onPaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.pagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    let pageData = {
      pagination: this.state.pagination,
      entityFilterVal: this.state.selectedEntity,
      entityIdVal: this.state.entityId,
      attributeVal: this.state.attribute,
      operationVal: this.state.operation,
      userVal: this.state.userName,
      oldVal: this.state.oldValue,
      newVal: this.state.newValue,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    FetchHelper.listAudits(this.context.intl, pageData);
  };

  onEntityFltrChange = (event) => {
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleMessage(
        "label.spinner.loading"
      ),
    });
    this.clearFilterData();
    let currentDate = this.getStartDateFilterVal();
    let previousDate = this.getEndDateFilterVal(currentDate);
    let pageData = {
      pagination: {
        required: true,
        style: "default",
        sortOrder: "DESC",
        totalCount: 0,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize(),
      },
      entityFilterVal: event.option.value,
      startDate: DateUtility.getDate(previousDate),
      endDate: DateUtility.getDate(currentDate),
    };
    FetchHelper.listAudits(this.context.intl, pageData);
  };

  clearFilterData() {
    this.setState({
      entityId: AuditStore.getState().entityIdVal,
      attribute: AuditStore.getState().attributeVal,
      operation: AuditStore.getState().operationVal,
      userName: AuditStore.getState().userVal,
      oldValue: AuditStore.getState().oldVal,
      newValue: AuditStore.getState().newVal,
      startDate: AuditStore.getState.startDate,
      endDate: AuditStore.getState.endDate,
    });
  }

  onFilterClicked(filterColumn, filterValue, e) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    if (e.charCode === 13) {
      this.continueSearchAction(filterColumn, filterValue);
    } else {
      timer = setTimeout(() => {
        this.continueSearchAction(filterColumn, filterValue);
      }, 1000);
    }
  }

  changeStartDate(e) {
    let dateVal = e.value;
    if (dateVal) {
      dateVal = DateUtility.getDate(new Date(dateVal));
      this.setState({ startDate: dateVal });
      this.continueSearchAction("startDate", dateVal);
    }
  }
  changeEndDate(e) {
    let dateVal = e.value;
    if(dateVal){
      dateVal = DateUtility.getDate(new Date(dateVal));
      this.setState({ endDate: dateVal });
      this.continueSearchAction("endDate", dateVal);
    }
  }

  continueSearchAction(filterColumn, filterValue) {
    let entityIdVal = this.state.entityId;
    let attributeVal = this.state.attribute;
    let operationVal = this.state.operation;
    let userVal = this.state.userName;
    let oldVal = this.state.oldValue;
    let newVal = this.state.newValue;
    let startDate;
    let endDate;
    let searchAction = true;
    if (filterColumn === "entityId") {
      entityIdVal = filterValue;
    } else if (filterColumn === "attribute") {
      attributeVal = filterValue;
    } else if (filterColumn === "operation") {
      operationVal = filterValue;
    } else if (filterColumn === "oldValue") {
      oldVal = filterValue;
    } else if (filterColumn === "newValue") {
      newVal = filterValue;
    } else if (filterColumn === "userName") {
      userVal = filterValue;
    } else if (filterColumn === "startDate") {
      startDate = filterValue;
      endDate = this.state.endDate;
    } else if (filterColumn === "endDate") {
      endDate = filterValue;
      startDate = this.state.startDate;
    }

    let pageData = {
      pagination: this.state.pagination,
      entityFilterVal: this.state.selectedEntity,
      isSearchAction: searchAction,
      entityIdVal,
      attributeVal,
      operationVal,
      userVal,
      oldVal,
      newVal,
      startDate,
      endDate,
    };
    FetchHelper.listAudits(this.context.intl, pageData);
  }

  exportExcelAction(e) {
    let mimetype = e.currentTarget.attributes.mimetype.value;
    let pageData = {
      entityFilterVal: this.state.selectedEntity,
      entityIdVal: this.state.entityId,
      attributeVal: this.state.attribute,
      operationVal: this.state.operation,
      userVal: this.state.userName,
      oldVal: this.state.oldValue,
      newVal: this.state.newValue,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    FetchHelper.downloadAuditReports(pageData, this.context.intl, mimetype);
  }

  onToggleChangeHandler(isChecked){
        if(isChecked){
          this.setState({enableAudits: true});
          FetchHelper.updateAuditConfig(this.context.intl, "Y");
        }else{
          this.setState({enableAudits: false});
          FetchHelper.updateAuditConfig(this.context.intl, "N");
        }
    }

  getStartDateFilterVal() {
      let currentDate = new Date();
      return DateUtility.convertToServerTimeZone(currentDate);
  }

  getEndDateFilterVal(currentDate) {
    let previousDate = new Date();
    previousDate.setDate( currentDate.getDate() - 29 );
    return DateUtility.convertToServerTimeZone(previousDate);
  }

  render() {
    let intl = this.context.intl;
    let selectedValue = {
      value: "com.hp.autopassj.ls.database.model.User",
      label: LocaleUtility.getLocaleMessage(
        "label.audits.fltr.entityselect.user"
      ),
    };
    const options = [
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.user"
        ),
        value: "com.hp.autopassj.ls.database.model.User",
      },
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.licenseDetail"
        ),
        value: "com.hp.autopassj.ls.database.model.LicenseDetails",
      },
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.configSetting"
        ),
        value: "com.hp.autopassj.ls.database.model.ConfigurationSettings",
      },
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.lDAPConfigurationInfo"
        ),
        value: "com.hp.autopassj.ls.database.model.LDAPConfigurationInfo",
      },
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.configurationEntry"
        ),
        value: "com.hp.autopassj.ls.database.model.ConfigurationEntry",
      },
      // {
      //   label: LocaleUtility.getLocaleMessage(
      //     "label.audits.fltr.entityselect.resvGrp"
      //   ),
      //   value: "com.hp.autopassj.ls.database.model.ReservationGroups",
      // },
      // {
      //   label: LocaleUtility.getLocaleMessage(
      //     "label.audits.fltr.entityselect.grpValues"
      //   ),
      //   value: "com.hp.autopassj.ls.database.model.GroupValues",
      // },
      // {
      //   label: LocaleUtility.getLocaleMessage(
      //     "label.audits.fltr.entityselect.prodFeatGrp"
      //   ),
      //   value: "com.hp.autopassj.ls.database.model.ProductFeatureGroup",
      // },
      {
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.configAttr"
        ),
        value: "com.hp.autopassj.ls.database.model.ConfigurationAttributes",
      },
    ];
    
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;

    if(currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y"){
      options.push({
        label: LocaleUtility.getLocaleMessage(
          "label.audits.fltr.entityselect.multiTenantName"
        ),
        value: "com.mf.autopassj.ls.mt.model.MultiTenantName",
      });
    }

    for (let item of options) {
      if (item.value === this.state.selectedEntity) {
        selectedValue = { label: item.label, value: item.value };
      }
    }

    return (
      <div className="config-cont-backgroud">
        <SectionWithTitle
          title={this.findLabel("label.audits")}
          helpTopic="Audits"
        />
        <section pad="none" wrap={false} responsive={false} justify="center">
          <div className="">
            <div>
                <div className="action_filter_wrapper">
                  <div className="action_wrap_left">
                      <div className="action_flex_wrap app_switch">
                          <CheckBox id="conf_enable_audit_checkbox"                 
                              checked={this.state.enableAudits}
                              label={this.state.enableAudits ? this.findLabel("audit.label.enabled") : this.findLabel("audit.label.disabled")}
                              value={this.state.enableAudits} onChange={(event) => this.onToggleChangeHandler(event.target.checked)} 
                              toggle={true} />     
                      </div>
                      <div className="action_flex_wrap">
                        <Select
                          label={
                            LocaleUtility.getLocaleMessage(
                              "label.audits.filter.entity"
                            ) + ": "
                          }
                          id="audit_filter_selEntity"
                          options={options}
                          value={selectedValue}
                          onChange={this.onEntityFltrChange}
                        />
                      </div>
                      <div className="action_flex_wrap horizantal_form_item">
                        <FormField
                          label={
                            LocaleUtility.getLocaleMessage(
                              "label.audits.filter.startDate"
                            ) + ": "
                          }
                        >
                          <DateTime className="datepicker"
                            id="audit_timestamp_startDateId"
                            format={DateUtility.getDateFormat()}
                            placeholder={DateUtility.getDateFormatPlaceholder()}
                            value={this.state.startDate}
                            onChange={this.changeStartDate}
                            calendarProps={({locale:LocaleUtility.LOCALE})}
                          />
                        </FormField>
                      </div>
                      <div className="action_flex_wrap horizantal_form_item">
                        <FormField
                          label={
                            LocaleUtility.getLocaleMessage(
                              "label.audits.filter.endDate"
                            ) + ": "
                          }
                        >
                          <DateTime className="datepicker"
                            id="audit_timestamp_endDateId"
                            format={DateUtility.getDateFormat()}
                            placeholder={DateUtility.getDateFormatPlaceholder()}
                            value={this.state.endDate}
                            onChange={this.changeEndDate}
                            calendarProps={({locale:LocaleUtility.LOCALE})}
                          />
                        </FormField>
                      </div>
                  </div>
                  
                      {
                        this.state.rowData && this.state.rowData.length > 0 ?
                        <div className="flexBox">
                          <div className="floatRight">
                            <a mimetype="CSV" 
                              title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} 
                              onClick={this.exportExcelAction}>
                                <DocumentCsvIcon id="audit-report-export-icon" colorIndex="brand"/>
                            </a>                         
                          </div>
                        </div>
                        : <div/>
                      }
                </div>
                <div>
                  <div>
                    <TableGrid
                      id="auidt_table"
                      header={[
                        // {
                        //   columnName: "key",
                        //   headerLabel: LocaleUtility.getLocaleLabel(
                        //     this.context.intl,
                        //     "label.audits.grid.id"
                        //   ),
                        //   href: false,
                        //   style:{{ display: "none"}}
                        // },
                        // {
                        //   columnName: "entity",
                        //   headerLabel: LocaleUtility.getLocaleLabel(
                        //     this.context.intl,
                        //     "label.audits.grid.entity"
                        //   ),
                        //   href: false,
                        //   width: "30%",
                        // },
                        {
                          columnName: "entityId",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.entityId"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.entityId",
                          //   this.context.intl
                          // ),
                        },
                        {
                          columnName: "attribute",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.attribute"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.attribute",
                          //   this.context.intl
                          // ),
                        },
                        {
                          columnName: "oldValue",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.oldValue"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.oldValue",
                          //   this.context.intl
                          // ),
                        },
                        {
                          columnName: "newValue",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.newValue"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.newValue",
                          //   this.context.intl
                          // ),
                        },
                        {
                          columnName: "timestamp",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.timestamp"
                          ),
                          href: false,
                          width: "30%",
                        },
                        {
                          columnName: "operation",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.operation"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.operation",
                          //   this.context.intl
                          // ),
                        },
                        {
                          columnName: "userName",
                          headerLabel: LocaleUtility.getLocaleMessage(
                            "label.audits.grid.userName"
                          ),
                          href: false,
                          width: "30%",
                          // filter: true,
                          // filterPlaceHolder: this.findLabel(
                          //   "label.audits.grid.userName",
                          //   this.context.intl
                          // ),
                        },
                      ]}
                      rowData={this.state.rowData}
                      rowSelection={0}
                      isExportRequired={false}
                      onSort={this.onSortClicked}
                      sortIndex={this.state.sortIndex}
                      sortAscending={this.state.sortAscending}
                      pagination={this.state.pagination}
                      rowKey="key"
                      onPagination={this.onPaginationClicked}
                      // onFilter={this.onFilterClicked}
                      noDataFoundText={LocaleUtility.getLocaleMessage(
                        "no.items.found.message"
                      )}
                      itemsCountMessage={LocaleUtility.getLocaleMessage(
                        "label.grid.item.count.message",
                        {
                          count:
                            this.state.pagination !== undefined
                              ? this.state.pagination.totalCount
                              : 0,
                        }
                      )}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div>
            <SpinningNotification centerPage={true} />
          </div>

          <SectionWithTitle title="" helpTopic="Audits" setionWidth="100" />
        </section>
      </div>
    );
  }
}

// Audits.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default Audits;
