import ReduceStore from "./ReduceStore";

class LicenseDetailStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            licenseDetail: undefined
        };
    }
    reduce(action, state) {
        switch (action.type) {
            case "LICENSE_DETAIL_FETCH_COMPLETE": {
                    return {
                        ...state,
                        type: (action.payload.type !== undefined ? action.payload.type: "licenseDetail"),
                        licenseDetail: action.payload.licenseDetail,
                    };
                }
            case "LICENSE_BORROW_FETCH_COMPLETE": {
                    return {
                        ...state,
                        licenseDetail: action.payload.licenseDetail,
                    };
                }
            case "RENEW_LICENSE_DETAIL_FETCH_COMPLETE": {
                    return {
                        ...state,
                        type: "renewDetail",
                        showRenewForm: true,
                        operation: action.payload.operation,
                        renewDetail: action.payload.renewDetail,
                    };
                }
            case "RENEW_LICENSE_DETAIL_ERROR_RESPONSE": {
                    return {
                        ...state,
                        type: "renewDetail",
                        showRenewForm: true
                    };
                }
            case "RENEW_LICENSE_DETAIL_SUCCESS": {
                    return {
                        ...state,
                        type: "renewDetail",
                        showRenewForm: false
                    };
                }
            default:
                    return state;
        }
    }
}

export default new LicenseDetailStore();