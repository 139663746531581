import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { CheckBox } from "grommet/components/CheckBox";
import { Anchor } from 'grommet/components/Anchor';
import TableGrid from "../../commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import { encryptText } from "../../../utils/Util";
import Store from "../../../flux/APTableStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import AuthTokenStore from "../../../flux/AuthTokenStore";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Dispatcher from "../../../flux/Dispatcher";
import SpinningNotification from '../../generic/SpinningNotification';
import { Copy as CopyIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
import { Checkmark as UpdateIcon } from 'grommet-icons';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class ServerAuthToken extends Component {
    constructor(props) {
        super(props);
        this.state ={
            isFormDirty: false,
            enableexpireIndaysChecked: false,
            enableEmailNotificaionIndays:"",
            enableexpireForCapcityChecked: false,
            enableEmailNotificaionForCapacity:"",
            expDaysError: "",
            expInDays:0,
            capacityError: "",
            capacityPersentage:0,
            enteredAttrValue:"",
            rowData: [],
            impFeatureTableData: [],
            impFeatureModalData:[],
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            productNameSelected: undefined,
            productNameSelectOptions: [],
            selectAllRows: false,
            licenseDetail: undefined,
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            disableApplyBtnIndex:0,
            modalVisible:false,
            nuberOfDaysDisabled:true,
            capacityNotificationDisabled:true,
            selectedRowKeys: [],
            notifyBy:0,
            emailIdsList :"",
            emailError:"",
            doSave:true,
            serverConfigured:true,
            doAdd:true,
            doDelete:true,
            map:new Map(),
            updateFlag:false,
            appExpiryDays:0

        };
     this.addActionCall = this.addActionCall.bind(this);
     this.onDeleteClicked = this.onDeleteClicked.bind(this);
     this.onConfirmDelete = this.onConfirmDelete.bind(this);
     this.refreshFormData = this.refreshFormData.bind(this); 
     this.copyToClipboard = this.copyToClipboard.bind(this);   
     this.copyClipboard = this.copyClipboard.bind(this);
     this.paintTextBox = this.paintTextBox.bind(this);
     this.showEditTextBox = this.showEditTextBox.bind(this);
     this.closeEditTextBox = this.closeEditTextBox.bind(this);
     this.handleChangeEditValue = this.handleChangeEditValue.bind(this);
     this.changeValue = this.changeValue.bind(this);
     
    }
     findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        AuthTokenStore.on("change", this.refreshFormData);
        FetchHelper.listAuthToken(this.context.intl);
    }
    
    componentWillUnmount() {
        AuthTokenStore.removeListener("change", this.refreshFormData);                         
    }

    componentDidUpdate(){
        if(this.state.updateFlag){
             var item;
            let array = document.getElementsByName("txtBox");
            for(let i=0; i<array.length;i++) {
                item=this.state.map.get(i);
                if(item.renewFlag===true){
                array[i].style.display = "inline-block";
                document.getElementById("text_"+ i).value =item.renewDays;
                document.getElementById("edit_"+i).checked = true;
                }
                else{
                     array[i].style.display = "none"
                     document.getElementById("edit_"+i).checked = false;
                     document.getElementById("text_"+ i).value = 0;
                     
                }
               document.getElementById("error-"+ i).style.display= "none";
               document.getElementById("save_"+ i).style.display= "none";
            }
            this.setState({updateFlag:false});
        }
    }
    refreshFormData(){

           var map=this.state.map;
        const rows = AuthTokenStore.getState().tokens.map((item, index) => {
                if(!item.isexpired){
                    map.set(index, item);
                    item.autorenew = this.paintTextBox(index, item.renewFlag, item.renewDays)
                    item.token = <div className="copy_token_icon_wrap">{item.token}&nbsp;&nbsp;<div id={index} className="copiedTooltip">
                  <Anchor title={LocaleUtility.getLocaleMessage("label.grid.col.copy")}><CopyIcon onClick={event => this.copyToClipboard(event, index, item.key)}/></Anchor>
                  <span className="copiedTooltipText" style={{position:"fixed"}}>{LocaleUtility.getLocaleMessage("tooltip.copied.text")}</span></div></div>;
                } else {
                    item.token = <div className="copiedTooltipText" style={{position:"fixed"}}>{item.token}</div>
                }
                return item;
            });
        this.setState({rowData: rows, updateFlag : true, map:map, appExpiryDays : AuthTokenStore.getState().appExpiryDays});
        this.forceUpdate();
        ConfigurationUtils.hideLoadMask(this.context.intl);
    }

    addActionCall(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        let loadSpinAction = "DELETING_TOKEN_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        FetchHelper.addAuthToken(this.context.intl,undefined,undefined,undefined);
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    onConfirmDelete(flag, key){
        if(flag === 1){
            let loadSpinAction = "DELETING_TOKEN_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            
            FetchHelper.removeAuthToken(key, this.context.intl);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    onDeleteClicked(key, event){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        ValidationUtility.confirm(LocaleUtility.getLocaleMessage("token.remove.confirm"), this.onConfirmDelete, key);
    }

    copyToClipboard(event, index, key){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if (navigator.clipboard !== undefined) {//Chrome
            navigator.clipboard.writeText(key);
        } else if(window.clipboardData) { // Internet Explorer
            this.copyClipboard(key);
        }
        const span = document.getElementsByClassName('copiedTooltipText')[index];
        if(span){
            var x = event.clientX;
            var y = event.clientY;
            setTimeout(function(){
                span.style.visibility = "hidden";
                span.style.top = y;
                span.style.left = x+40;
            }, 2000);
            span.style.visibility = "visible";
        }
    }

    copyClipboard(message) {
        var textArea = document.createElement("textarea");
        textArea.value = message;
        textArea.style.opacity = "0"; 
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand('copy');
        } catch (err) {
        }

        document.body.removeChild(textArea);
    }

    closeEditTextBox(key, event){
        let array = document.getElementsByName("toggleButton");
        for(let i=0; i<array.length;i++) {
            array[i].style.display = "inline-block";
        }
        document.getElementById("text_"+key).style.display = "none";
        document.getElementById("edit_"+key).checked = false;
        document.getElementById("error-"+ key).style.display= "none";
        document.getElementById("save_"+ key).style.display= "none";
    }
     showEditTextBox(event, key){
        if(event.target != undefined && event.target.checked){
        let array = document.getElementsByName("toggleButton");
        var item;
        var item2;
        item2 = this.state.map.get(key);
        for(let i=0; i<array.length;i++) {
            array[i].style.display = "none";
        }

        let array1 = document.getElementsByName("txtBox");
        for(let i=0; i<array1.length;i++) {
            item = this.state.map.get(i);
            if(item.renewDays==0){
            array1[i].blur();
            array1[i].value = "";
            array1[i].style.display="none";
            document.getElementById("edit_"+i).checked = false;
            document.getElementById("save_"+i).style.display="none";
            document.getElementById("error-"+i).style.display="none";
          }
        }
        document.getElementById("text_"+key).style.display = "inline-block";
        document.getElementById("save_"+key).style.display = "inline-block"
        document.getElementById("text_"+key).value ="";
        document.getElementById("text_"+key).focus();
    }
    else{ if(event.target != undefined && event.target.checked != undefined)
        this.closeEditTextBox(key, event);
        var token=this.state.map.get(key);
        if(token.renewFlag)
        FetchHelper.addAuthToken(this.context.intl,false,0,token.key);

    }
     document.getElementById("edit_"+key).checked = event.target.checked;
}
     paintTextBox(key, renewFlag, renewDays){
        let locale = LocaleUtility.getCurrentLocale();
        let searchHeight = "2em", placeHolderCSS="action_wrap_left search_input_wrap", widthText="26em";
        if ( locale == 'ja' || locale == 'es' || locale == 'fr' || locale == 'de'){
            searchHeight = "4em";
            placeHolderCSS="action_wrap_left search_input_wrap expandPlaceHolder";
            widthText = "24em"
        }
        return (
            <div id={"div_"+key} style={{width: "100%",display: "inline-block"}}>
                 <div className={placeHolderCSS}>
                    <CheckBox id={"edit_"+key} name="toggleButton"
                                                    defaultChecked={renewFlag}
                                                    value={renewFlag===true?"true":"false"} onChange={(event) =>this.showEditTextBox(event, key)} 
                                                    toggle={true} 
                                                    data-title={this.findLabel("label.toggle.button")}
                                                    key={key}
                                                    />
               
                <TextInput className="tbl_inside_row_input" id={"text_"+key} name="txtBox"
                            style={{width : widthText, marginLeft:"10px", height : searchHeight}}
                           // onBlur={event =>this.handleChangeEditValue(key, event.target.value)}
                            onKeyPress={(event)=>this.changeValue(event,key, event.target.value)} 
                            onChange={(event)=>this.onTextChange(event,key)}
                            //onFocus={(event)=>this.onTextChange(event,key)}
                            placeholder={LocaleUtility.getLocaleMessage("type.value.to.edit")}
                            />

                            <div className="button_wrap_primary">
                           <Button id={"save_"+key} name="btnBox" label={LocaleUtility.getLocaleMessage("label.button.save")} 
                            style={{display:"none"}} plain={false} 
                            primary={true} onClick={(event)=>this.handleChangeEditValue(key)}/>
                            </div>
             </div>
                 <span
                            id={"error-" + key}
                            style={{ float: "inherit" }}
                            className="grommetux-form-field__error"
                        ></span>
            </div>
        )
     }
      handleChangeEditValue(key) {
        var currentValue = document.getElementById("text_"+key).value;
        if(currentValue==="" || currentValue===undefined){
            document.getElementById("error-"+key).style.display="inline-block";
            document.getElementById("error-"+key).innerHTML =  LocaleUtility.getLocaleMessage("configAuthTokenAlert", {appExpiryDays: this.state.appExpiryDays});
        }
        else{
        var token=this.state.map.get(key);
        var prevValue=token.renewDays;
        if(prevValue != currentValue){
           FetchHelper.addAuthToken(this.context.intl,true,currentValue,token.key);
        }
     }
    }
    changeValue(event, key, currentValue) {
        if(event.charCode === 13 && !(this.state.isFormDirty)) {
            this.handleChangeEditValue(key);   
        }
    }
    onTextChange(event,key){
        var val=event.target.value;
        var item;
       if((val !== undefined && val !== '') && (isNaN(val) || Number(val) <=0 || Number(val) > (this.state.appExpiryDays-1) || val.toString().indexOf(".")>=0)){
        document.getElementById("error-"+key).style.display="inline-block";
        document.getElementById("error-"+key).innerHTML =  LocaleUtility.getLocaleMessage("configAuthTokenAlert", {appExpiryDays: this.state.appExpiryDays});
        document.getElementById("save_"+key).style.display="none";
        this.setState({isFormDirty: "true"});
       }
       else{
         let array = document.getElementsByName("txtBox");
         this.setState({isFormDirty: "false"});
        for(let i=0; i<array.length;i++) {
            item = this.state.map.get(i);
            if(item.renewDays==0){
            array[i].blur();
            array[i].value = "";
            array[i].style.display="none";
            document.getElementById("edit_"+i).checked = false;
          }
          else{
            array[i].value = item.renewDays;
          }
          document.getElementById("save_"+i).style.display="none";
          document.getElementById("error-"+i).style.display="none";
        }
        document.getElementById("text_"+key).style.display="inline-block";
        document.getElementById("save_"+key).style.display="inline-block";
        document.getElementById("text_"+key).focus();
        document.getElementById("text_"+key).value=val;
        document.getElementById("edit_"+key).checked = true;
        document.getElementById("error-"+key).style.display="none";
       }
    }

    render() {
        //let intl = this.context.intl; 
       
       return (<div className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("configpageDBtitle")}  helpTopic="R_DataBase_Configuration"/>
                <section pad="none" wrap={false} responsive={false} justify="center">
                   <div>
                        <div className="border_white_backg_shadow button_right_wrap">
                                    <div className="button_wrap_primary">
                                            <Button id="token_add_btn" label={this.findLabel("button.label.generate.token")} plain={false} primary={true} onClick={this.addActionCall}/>
                                    </div> 
                                    </div>
                                    <TableGrid
                                        id="token_table"
                                        header={[
                                            {
                                            columnName: "token",
                                            headerLabel: LocaleUtility.getLocaleMessage(
                                                "label.grid.col.token"),
                                            href: false,
                                            width: "40%"
                                            },
                                            {
                                            columnName: "expiryDate",
                                            headerLabel: LocaleUtility.getLocaleMessage(
                                                "label.grid.col.expDate"),
                                            href: false,
                                            width: "15%"
                                            },
                                            // {
                                            //     columnName: "copy",
                                            //     headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.copy"),
                                            //     //columnKey: "token",
                                            //     href: false,
                                            //     width: "20%",
                                            //     textAlign: "left",
                                            //     //onClick: this.copyToClipboard
                                            // },
                                            {
                                                columnName: "delete",
                                                headerLabel: LocaleUtility.getLocaleMessage("resv.grid.header.pool.delete"),
                                                columnKey: "key",
                                                href: false,
                                                width: "10%",
                                                textAlign: "left",
                                                onClick: this.onDeleteClicked
                                            },
                                            {
                                                columnName: "autorenew",
                                                headerLabel: LocaleUtility.getLocaleMessage("resv.grid.header.pool.autorenew"),
                                                columnKey: "key",
                                                href: false,
                                                width: "auto",
                                                textAlign: "left",
                                                onClick: this.onClicked
                                            }
                                        ]}
                                        rowData={this.state.rowData}
                                        rowSelection={0}
                                        isExportRequired={false}
                                        onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        rowKey="token"
                                        noDataFoundText={LocaleUtility.getLocaleMessage(
                                            "no.items.found.message"
                                        )}
                                        
                                        />
                        </div>
                         <div>
                            <SpinningNotification centerPage={true} />
                        </div>

                 <SectionWithTitle title="" helpTopic="Auth_Tokens"
                            setionWidth = "100"
                        />
                </section>
                </div>);
    }
    

}


// ServerAuthToken.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ServerAuthToken;