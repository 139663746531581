import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Select } from "grommet/components/Select";
import SectionWithTitle from "../../generic/SectionWithTitle";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { TextInput } from 'grommet/components/TextInput';
import { RadioButton } from 'grommet/components/RadioButton';
import { Button } from "grommet/components/Button";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { CheckBox } from "grommet/components/CheckBox";
import SpinningNotification from '../../generic/SpinningNotification';
import AppConfigSettingStore from "../../../flux/AppConfigSettingStore";
import TableGrid from "../../../components/commons/table/APTable";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { Trash as FormTrashIcon } from 'grommet-icons';
import * as DateUtility from "../../../utils/DateUtility";
import "../../../css/App.css";
import "../../../css/index.css";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let initialState;// = this.state;

const widthStyle = {
    "width":"100%",
    "float":"left"
};
class MainConfiguration extends Component {
    constructor(props) {
      super(props);
      this.state = {
        mailServerName: "",
        portNumber:25,
        adminEmail:"",
        instanceIdentifier:"",
        userName:"",
        password:"",
        maxRecrodsRetain: 200000,
        purgeRecordsOlder: 0,
        proxyPortNumber: 8080,
        proxyAddress:"",
        testEmailId:"",
        isPurgeRecordOlder: true,
        productAthentication: "N",
        isAutoPurge: false,
        isEnableUsageHub: false,
        isBorrowSettings: false,
        auditType: "0",
        isAdvanceSetting: false,
        noOfNotifyAdvance: 2,
        useClientSetting: true,
        protocolType: "https",
        serverConfigPort: 5814,
        serverConfigIpOrHost:"",
        isEnableDiscovery: false,
        excludeClients:[],
        excludeClientstoSubmit:[],
        discoveryStatus: "0",
        noAuditIdChecked: true,
        reponseAuditIdChecked: false,
        requestAuditIdChecked: false,
        reqResAuditIdChecked: false,
        purgeRecOldIdChecked: true,
        maxRecordRetainIdChecked: false,
        borrowSetAllowIdChecked: true,
        advanceSettingIdChecked: false,
        useClientSetChinChoutChecked: true,
        enableUsageHubConnIdChecked: false,
        excluedeClientValidation: "",
        prodAuthYesIdChecked: false,
        prodAuthNoIdChecked: true,
        protoTypeHttpsIdChecked: true,
        protoTypeHTTPIdChecked: false,
        systemInfoList:[],
        selectedExcludeClient:"",
        excludeClientValue:"",
        isFormDirty: false,
        serverScheme: "",
        enableTestMainBtn: false,
        adminmailError: "",
        mailserverError: "",
        usernameError: "",
        mailPortError: "",
        mailInstanceError: "",
        proxyAddressError: "",
        proxyPortError: "",
        serverConfigIpOrHostError: "",
        serverConfigPortError: "",
        notifyDaysError: "",
        noOfNotifyAdvanceError: "",
        purgeRecordsOlderError: "",
        maxRecrodsRetainError: "",
        purgeTypeError: "",
        updatedUsageHubFlag: false,
        isEnableCT:false,
        updatedCTFlag: false,
        enableCTConfigarationChecked: false,
        apiKey: "",
        apiKeyError: "",
        hostNameList: [],
        hostOrIpValue: "",
        newHostIpId: -1,
        selectedHostIPs: [],
        apiKeyExpireDays: "",
        apiKeyExpireError: "",
        apiKeyExpireOn: "",
        hostIpspagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "",
            sortOrder: "DESC"
        },
        enabledTenancy: false,
        databaseType: "HSQL",
        changedTenancyFlag: false
      }
      this.excludeClientText = React.createRef();
      this.handleChangeServerName = this.handleChangeServerName.bind(this);
      this.handleChangePortNumber = this.handleChangePortNumber.bind(this);
      this.handleChangeAdminEmail = this.handleChangeAdminEmail.bind(this);
      this.handleChangeInstanceIdentifier = this.handleChangeInstanceIdentifier.bind(this);
      this.handleChangeUserName = this.handleChangeUserName.bind(this);
      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.handleChangeTestEmail = this.handleChangeTestEmail.bind(this);
      this.handleChangeAutoPurge = this.handleChangeAutoPurge.bind(this);
      this.handleChangePurgeRecOld = this.handleChangePurgeRecOld.bind(this);
      this.handleChangeMaxRetain = this.handleChangeMaxRetain.bind(this);
      this.handleChangePurgeRecordValue = this.handleChangePurgeRecordValue.bind(this);
      this.handleChangeMaxRetainValue = this.handleChangeMaxRetainValue.bind(this);
      this.handleChangeEnableUsageHub = this.handleChangeEnableUsageHub.bind(this);
      this.handleChangeProxyAddress = this.handleChangeProxyAddress.bind(this);
      this.handleChangeProxyPort = this.handleChangeProxyPort.bind(this);
      this.handleChangeProdAuthYes = this.handleChangeProdAuthYes.bind(this);
      this.handleChangeProdAuthNo = this.handleChangeProdAuthNo.bind(this);
      this.handleChangeBorrowSetting = this.handleChangeBorrowSetting.bind(this);
      this.handleChangeNoAudit = this.handleChangeNoAudit.bind(this);
      this.handleChangeResponseAudit = this.handleChangeResponseAudit.bind(this);
      this.handleChangeRequestAudit = this.handleChangeRequestAudit.bind(this);
      this.handleChangeReqResAudit = this.handleChangeReqResAudit.bind(this);
      this.handleChangeAdvanceSetting = this.handleChangeAdvanceSetting.bind(this);
      this.handleChangeNoOfNotifyAdvance = this.handleChangeNoOfNotifyAdvance.bind(this);
      this.handleChangeUseClienSettings = this.handleChangeUseClienSettings.bind(this);
      this.handleChangeHttp = this.handleChangeHttp.bind(this);
      this.handleChangeHttps = this.handleChangeHttps.bind(this);
      this.handleChangeServerConfigPortNumber = this.handleChangeServerConfigPortNumber.bind(this);
      this.handleChangeServerConfigIpOrHost = this.handleChangeServerConfigIpOrHost.bind(this);
      this.handleChangeEnableDiscoveryId = this.handleChangeEnableDiscoveryId.bind(this);
      this.submitTestMail = this.submitTestMail.bind(this);
      this.refreshFormData = this.refreshFormData.bind(this);
      this.submitConfigSettings = this.submitConfigSettings.bind(this);
      this.addExcludeClientAction = this.addExcludeClientAction.bind(this);
      this.cancelConfigSettings = this.cancelConfigSettings.bind(this);
      this.resetAuditConfigInfo = this.resetAuditConfigInfo.bind(this);
      this.findLabel = this.findLabel.bind(this);
      this.handleSelectIp = this.handleSelectIp.bind(this);
      this.onRowClicked = this.onRowClicked.bind(this);
      this.removeExcludeClients = this.removeExcludeClients.bind(this);
      this.handleChangExcludeClientValue = this.handleChangExcludeClientValue.bind(this);
      this.makeFormDirty = this.makeFormDirty.bind(this);
      this.onClickDelete = this.onClickDelete.bind(this);
      this.clearErrors = this.clearErrors.bind(this);
      this.handleChangeEnableCT = this.handleChangeEnableCT.bind(this);
      this.onConfirmRemove = this.onConfirmRemove.bind(this);
      this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
      this.handleChangeApiKey = this.handleChangeApiKey.bind(this);
      this.handlehostOrIpValue = this.handlehostOrIpValue.bind(this);
      this.addHostIpAction = this.addHostIpAction.bind(this);
      this.onHostIPRowClicked = this.onHostIPRowClicked.bind(this);
      this.removeHostOrIps = this.removeHostOrIps.bind(this);
      this.handleChangeApiKeyExpire = this.handleChangeApiKeyExpire.bind(this);
      this.generateApiKeyAction = this.generateApiKeyAction.bind(this);
      this.onHostIPPaginationClicked = this.onHostIPPaginationClicked.bind(this);
      this.removeHostIPsCallBack = this.removeHostIPsCallBack.bind(this);
      this.handleChangeMultiTenancy = this.handleChangeMultiTenancy.bind(this);
    }
    makeFormDirty() {
        this.setState({isFormDirty: true});
    }
    handleChangeServerName (event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({mailServerName: val});
    }
    onHostIPPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.hostIpspagination;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ hostIpspagination: paginationTemp });
    }
    handleChangePortNumber (event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({portNumber: val});
    }
    handleChangeApiKey (event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({apiKey: val});
    }
    handleChangeAdminEmail (event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({adminEmail: val});
    }
    handleChangeInstanceIdentifier (event) {
        let val = event.target.value;
        if(!ValidationUtility.validateSpecialChars(val)){
            this.setState({mailInstanceError: this.findLabel("enter.valid.chars.for.text"),
                            instanceIdentifier: val}); ;
        } else{
            this.makeFormDirty();
        this.setState({instanceIdentifier: val,mailInstanceError:""});
        }
        
    }
    handleChangeUserName(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({userName: val});
    }
    handleChangePassword(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({password: val});
    }
    handleChangeTestEmail(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({testEmailId: val});
        this.setState({enableTestMainBtn: true});
    }
    handleChangeAutoPurge(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        this.setState({isAutoPurge: val});
    }
    handleChangePurgeRecOld(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({maxRecordRetainIdChecked: false});
            this.setState({purgeRecOldIdChecked: true});
        } else {
            this.setState({purgeRecOldIdChecked: false});
        }
        this.setState({isPurgeRecordOlder: val});
    }
    handleChangeMaxRetain(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({maxRecordRetainIdChecked: true});
            this.setState({purgeRecOldIdChecked: false});
            this.setState({isPurgeRecordOlder: false});
        } else {
            this.setState({maxRecordRetainIdChecked: false});
        }
    }
    handleChangePurgeRecordValue(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({purgeRecordsOlder: val});
    }
    handleChangeMaxRetainValue(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({maxRecrodsRetain: val});
    }
    handleChangeEnableUsageHub(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({enableUsageHubConnIdChecked: true});
        } else {
            this.setState({enableUsageHubConnIdChecked: false});
        }
        this.setState({isEnableUsageHub: val, updatedUsageHubFlag: true});
    }

     handleChangeEnableCT(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({enableCTConfigarationChecked: true});
        } else {
            this.setState({enableCTConfigarationChecked: false});
        }
        this.setState({isEnableCT: val, updatedCTFlag: true});
    }

    handleChangeProxyAddress(event) {
        let val = event.target.value.trim();
        this.makeFormDirty();
        this.setState({proxyAddress: val});
    }
    handleChangeProxyPort(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({proxyPortNumber: val});
    }
    handleChangeProdAuthYes(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({prodAuthYesIdChecked: true});
            this.setState({prodAuthNoIdChecked: false});
        }
        this.setState({productAthentication: "Y"});
    }
    handleChangeProdAuthNo(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({prodAuthYesIdChecked: false});
            this.setState({prodAuthNoIdChecked: true});
        }
        this.setState({productAthentication: "N"});
    }
    handleChangeBorrowSetting(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({borrowSetAllowIdChecked: true});
        } else {
            this.setState({borrowSetAllowIdChecked: false});
        }
        this.setState({isBorrowSettings: val});
    }
    handleChangeApiKeyExpire(event) {
        this.setState({apiKeyExpireDays: event.target.value});
        this.makeFormDirty();
    }
    handleChangeMultiTenancy(event) {
        this.setState({enabledTenancy: event.target.checked, changedTenancyFlag: true});
        localStorage.setItem("changedMTFlag", true);
        this.makeFormDirty();
    }
    generateApiKeyAction() {
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
        let result = "";
        let charactersLength = characters.length;
        for ( var i = 0; i < 20; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.makeFormDirty();
        this.setState({apiKey: result});
    }
    handleChangeNoAudit(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.resetAuditConfigInfo();
            this.setState({noAuditIdChecked: true});
        }
        this.setState({auditType: "0"});
    }
    handleChangeResponseAudit(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.resetAuditConfigInfo();
            this.setState({reponseAuditIdChecked: true});
        }
        this.setState({auditType: "2"});
    }
    handleChangeRequestAudit(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.resetAuditConfigInfo();
            this.setState({requestAuditIdChecked: true});
        }
        this.setState({auditType: "1"});
    }
    handleChangeReqResAudit(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.resetAuditConfigInfo();
            this.setState({reqResAuditIdChecked: true});
        }
        this.setState({auditType: "3"});
    }
    handleChangeAdvanceSetting(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({advanceSettingIdChecked: true});
        } else {
            this.setState({advanceSettingIdChecked: false});
        }
        this.setState({isAdvanceSetting: val});
    }
    handleChangeNoOfNotifyAdvance(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({noOfNotifyAdvance: val});
    }
    handleChangeUseClienSettings(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({useClientSetChinChoutChecked: true});
        } else {
            this.setState({useClientSetChinChoutChecked: false});
        }
        this.setState({useClientSetting: val});
    }
    handleChangeHttp(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({protoTypeHTTPIdChecked: true});
            this.setState({protoTypeHttpsIdChecked: false});
            this.setState({protocolType: "http"});
        }
    }
    handleChangeHttps(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        if(val) {
            this.setState({protoTypeHTTPIdChecked: false});
            this.setState({protoTypeHttpsIdChecked: true});
            this.setState({protocolType: "https"});
        }
    }
    handleChangeServerConfigPortNumber(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({protocolPort: val});
    }
    handleChangeServerConfigIpOrHost(event) {
        let val = event.target.value;
        this.makeFormDirty();
        this.setState({protocolHost: val});
    }
    handleChangeEnableDiscoveryId(event) {
        let val = event.target.checked;
        this.makeFormDirty();
        this.setState({isEnableDiscovery: val});
        if(val) {
            this.setState({discoveryStatus: "1"});
        } else {
            this.setState({discoveryStatus: "0"});
        }
    }
    handleSelectIp(target, option) {
        this.makeFormDirty();
        this.setState({protocolHost: option});
    }
    onRowClicked(keys, event) {
        this.setState({selectedExcludeClient: keys});
    }

    onConfirmRemove(flag){
        if(flag === 1){
            let selExcludedClients = this.state.selectedExcludeClient;
            let filterList = this.state.excludeClients;
            for(var i=0;i<selExcludedClients.length;i++) {
                filterList = filterList.filter((obj, index) => selExcludedClients[i] !== obj.excludeClient);
            }
            this.setState({excludeClients: filterList});
        }
    }
    onHostIPRowClicked(keys, event) {
        this.setState({selectedHostIPs: keys});
    }
    removeHostIPsCallBack(flag) {
        if(flag === 1) {
            let selValues = this.state.selectedHostIPs;
            this.makeFormDirty();
            let filterList = this.state.hostNameList;
            for(var i=0;i<selValues.length;i++) {
                filterList = filterList.filter((obj, index) => selValues[i] !== obj.hostName);
            }
            this.setState({hostNameList: filterList});
        }
    }
    removeHostOrIps() {
        let selValues = this.state.selectedHostIPs;
        if(selValues && selValues.length > 0) {
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            ValidationUtility.confirm(
                LocaleUtility.getLocaleMessage("configpageExcludeClientWarning"),
                this.removeHostIPsCallBack, undefined, "","");
        } else {
           ConfigurationUtils.showErrorMessage1("configpageExcludeClientValidation", this.context.intl);
        }
    }
    removeExcludeClients() {
        this.makeFormDirty();
        if(this.state.selectedExcludeClient && this.state.selectedExcludeClient.length > 0) {
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
            ValidationUtility.confirm(
                LocaleUtility.getLocaleMessage("configpageExcludeClientWarning"),
                this.onConfirmRemove, undefined, "","");
        } else {
           ConfigurationUtils.showErrorMessage1("configpageExcludeClientValidation", this.context.intl);
        }
    }
    onClickDelete(clientId) {
        if(clientId && clientId !== "") {
            let filterList = this.state.excludeClients;
            filterList = filterList.filter((obj, index) => clientId !== obj.excludeClient);
            this.setState({excludeClients: filterList});
            this.makeFormDirty();
        }
    }
    submitTestMail() {
        if(ConfigurationUtils.validEmail(this.state, this.context.intl)) {
            FetchHelper.testMainConfiguration(this.state, this.context.intl);
        }
    }

    onConfirmSubmit(flag){
        if(flag === 1){
            if(this.state.isFormDirty) {
                let exClients = this.state.excludeClientstoSubmit
                if(this.state.excludeClients.length > 0){
                    this.state.excludeClients.map((obj, index) => exClients.push(obj.excludeClient) );
                    this.setState({excludeClientstoSubmit: exClients});
                }
                FetchHelper.saveOrUpdateConfigurationSetting(this.state, this.context.intl, this.state.changedTenancyFlag);
                this.setState({excludeClientstoSubmit: []});
            }
        }
    }

    submitConfigSettings() {
        let errorMap = ConfigurationUtils.validate(this.state, this.context.intl);
        let flag = false;
        this.clearErrors();
        if(errorMap["adminmail"] !== undefined && errorMap["adminmail"] !== "") {
            this.setState({adminmailError: errorMap["adminmail"]});
            flag = true;
        }
        if(errorMap["mailserver"] !== undefined && errorMap["mailserver"] !== "") {
            this.setState({mailserverError: errorMap["mailserver"]});
            flag = true;
        }
        // if(errorMap["username"] !== undefined && errorMap["username"] !== "") {
        //     this.setState({usernameError: errorMap["username"]});
        //     flag = true;
        // }
        if(errorMap["mailPort"] !== undefined && errorMap["mailPort"] !== "") {
            this.setState({mailPortError: errorMap["mailPort"]});
            flag = true;
        }
        if(errorMap["mailInstance"] !== undefined && errorMap["mailInstance"] !== "") {
            this.setState({mailInstanceError: errorMap["mailInstance"]});
            flag = true;
        }
        if(errorMap["proxyAddress"] !== undefined && errorMap["proxyAddress"] !== "") {
            this.setState({proxyAddressError: errorMap["proxyAddress"]});
            flag = true;
        }
        if(errorMap["proxyPort"] !== undefined && errorMap["proxyPort"] !== "") {
            this.setState({proxyPortError: errorMap["proxyPort"]});
            flag = true;
        }
        if(errorMap["serverConfigIpOrHost"] !== undefined && errorMap["serverConfigIpOrHost"] !== "") {
            this.setState({serverConfigIpOrHostError: errorMap["serverConfigIpOrHost"]});
            flag = true;
        }
        if(errorMap["serverConfigPort"] !== undefined && errorMap["serverConfigPort"] !== "") {
            this.setState({serverConfigPortError: errorMap["serverConfigPort"]});
            flag = true;
        }
        if(errorMap["notifyDays"] !== undefined && errorMap["notifyDays"] !== "") {
            this.setState({notifyDaysError: errorMap["notifyDays"]});
            flag = true;
        }
        if(errorMap["noOfNotifyAdvance"] !== undefined && errorMap["noOfNotifyAdvance"] !== "") {
            this.setState({noOfNotifyAdvanceError: errorMap["noOfNotifyAdvance"]});
            flag = true;
        }
        if(errorMap["purgeRecordsOlder"] !== undefined && errorMap["purgeRecordsOlder"] !== "") {
            this.setState({purgeRecordsOlderError: errorMap["purgeRecordsOlder"]});
            flag = true;
        }
        if(errorMap["maxRecrodsRetain"] !== undefined && errorMap["maxRecrodsRetain"] !== "") {
            this.setState({maxRecrodsRetainError: errorMap["maxRecrodsRetain"]});
            flag = true;
        }
        if(errorMap["purgeType"] !== undefined && errorMap["purgeType"] !== "") {
            this.setState({purgeTypeError: errorMap["purgeType"]});
            flag = true;
        }
        if(errorMap["apiKey"] !== undefined && errorMap["apiKey"] !== "") {
            this.setState({apiKeyError: errorMap["apiKey"]});
            flag = true;
        }
        if(errorMap["apiKeyExpireDays"] !== undefined && errorMap["apiKeyExpireDays"] !== "") {
            this.setState({apiKeyExpireError: errorMap["apiKeyExpireDays"]});
            flag = true;
        }
        if(flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        if(!flag && errorMap["protocoTypeChanged"] === "Y") {
            ValidationUtility.confirm(
                LocaleUtility.getLocaleMessage("configpageProtocolTypeConfirm"), this.onConfirmSubmit, "", "");
        } else {
            if(this.state.isFormDirty && !flag) {
                let exClients = this.state.excludeClientstoSubmit
                if(this.state.excludeClients.length > 0){
                    this.state.excludeClients.map((obj, index) => exClients.push(obj.excludeClient) );
                    this.setState({excludeClientstoSubmit: exClients});
                }
                FetchHelper.saveOrUpdateConfigurationSetting(this.state, this.context.intl, this.state.changedTenancyFlag);
                this.setState({excludeClientstoSubmit: []});
            }
        }
    }
    clearErrors() {
        this.setState({adminmailError: ""});
        this.setState({mailserverError: ""});
        this.setState({usernameError: ""});
        this.setState({mailPortError: ""});
        this.setState({mailInstanceError: ""});
        this.setState({proxyAddressError: ""});
        this.setState({proxyPortError: ""});
        this.setState({serverConfigIpOrHostError: ""});
        this.setState({serverConfigPortError: ""});
        this.setState({notifyDaysError: ""});
        this.setState({noOfNotifyAdvanceError: ""});
        this.setState({purgeRecordsOlderError: ""});
        this.setState({maxRecrodsRetainError: ""});
        this.setState({purgeTypeError: "", apiKeyError: "", apiKeyExpireError: ""});
    }
    cancelConfigSettings() {
        if(initialState) {
            this.setState(initialState);
            this.refreshFormData();
        }
    }
    handleChangExcludeClientValue(event) {
        this.setState({excludeClientValue: event.target.value});
    }
    handlehostOrIpValue(event) {
        this.setState({hostOrIpValue: event.target.value});
    }
    addHostIpAction() {
        let val = this.state.hostOrIpValue;
        this.makeFormDirty();
        this.setState({hostOrIpValueValidation: ""});
        let newId = this.state.newHostIpId;
        newId = newId - 1;
        let validationMsg =<span className="grommetux-form-field__error">{this.findLabel("message.host.ip.alert", this.context.intl)}</span>;
        if('' !== val){
            var list = this.state.hostNameList;
            let duplicateFlag = false;
            for(let index=0;index < list.length;index++) {
                let obj = list[index];
                let hostIpName = obj.hostName.toLowerCase();
                let selValue = val.toLowerCase();
                if(hostIpName === selValue) {
                    duplicateFlag = true;
                }
            }
            if(duplicateFlag) {
                validationMsg =<span className="grommetux-form-field__error">{this.findLabel("message.host.ip.exist")}</span>;
                this.setState({hostOrIpValueValidation: validationMsg});
            } else {
                if(ConfigurationUtils.fnValidateIPAddress(val)){
                    list.push({id: newId, hostName: this.state.hostOrIpValue});
                    this.setState({hostNameList: list, hostOrIpValue: ""});
                } else {
                    validationMsg =<span className="grommetux-form-field__error">{this.findLabel("message.host.ip.alert")}</span>;
                    this.setState({hostOrIpValueValidation: validationMsg});
                }
            }
        } else {
            this.setState({hostOrIpValueValidation: validationMsg});
        }
    }
    addExcludeClientAction() {
        var val = this.state.excludeClientValue;
        this.makeFormDirty();
        this.setState({excluedeClientValidation: ""});
        let validationMsg =<span className="grommetux-form-field__error">{this.findLabel("configpageAlert2")}</span>;
        if('' !== val){
            if(ConfigurationUtils.fnValidateIPAddress(val)){
                var exClients = this.state.excludeClients;
                exClients.push({excludeClient: val, delete: <FormTrashIcon />});
                this.setState({excludeClients: exClients});
                document.getElementById('conf_excludeClientTextId').value='';
                this.setState({excludeClientValue: ""});
            } else {
               this.setState({excluedeClientValidation: validationMsg});
            }
        } else {
            this.setState({excluedeClientValidation: validationMsg});
        }
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        AppConfigSettingStore.on("change", this.refreshFormData);
        FetchHelper.findConfigurationSetting(this.context.intl);
    }
    componentWillUnmount() {
        AppConfigSettingStore.removeListener("change", this.refreshFormData);
    }
    refreshFormData() {
        let formData = AppConfigSettingStore.getState().formData;
        if(formData !== undefined){
            this.clearErrors();
            if(this.state.updatedUsageHubFlag) {
                this.setState({updatedUsageHubFlag: false});
                window.location.reload();
            }
            this.setState({excluedeClientValidation: ""});
            this.setState({excludeClientValue: ""});
            this.setState({userName: formData.userName});
            this.setState({password: ""});
            this.setState({testEmailId: ""});
            this.setState({selectedExcludeClient: [], selectedHostIPs: []});
            this.setState({mailServerName: formData.mailServer});
            this.setState({portNumber: formData.mailPort});
            this.setState({adminEmail: formData.mailAdmin});
            this.setState({instanceIdentifier:  formData.instanceIdentifier});
            this.setState({proxyPortNumber: formData.proxyPort});
            this.setState({proxyAddress: formData.proxyAddress});
            if(formData.enableMultiTenancy !== undefined && Number(formData.enableMultiTenancy) === 1) {
                this.setState({enabledTenancy: true});
            } else {
                this.setState({enabledTenancy: false});
            }
            var autoPurg = formData.autoPurgRequired;
            if(autoPurg === "true") {
                this.setState({isAutoPurge: true});
            } else {
                this.setState({isAutoPurge: false});
            }
            var purgeRecordsOld = formData.purgeInDays;
            var maxRecrodsRet = formData.purgeInSize;
            this.setState({purgeRecordsOlder: purgeRecordsOld});
            this.setState({maxRecrodsRetain: maxRecrodsRet});
            if(purgeRecordsOld && Number(purgeRecordsOld) > 0) {
                this.setState({isPurgeRecordOlder: true});
                this.setState({purgeRecOldIdChecked: true});
                this.setState({maxRecordRetainIdChecked: false});
            } else if(maxRecrodsRet && Number(maxRecrodsRet) > 0) {
                this.setState({isPurgeRecordOlder: false});
                this.setState({maxRecordRetainIdChecked: true});
                this.setState({purgeRecOldIdChecked: false});
            }
            if(formData.usageHubConnector === 'true') {
                this.setState({isEnableUsageHub: true});
                this.setState({enableUsageHubConnIdChecked: true});
                localStorage.setItem("enableUsageHub", true);
            } else {
                this.setState({isEnableUsageHub: false});
                this.setState({enableUsageHubConnIdChecked: false});
                localStorage.setItem("enableUsageHub", false);
            }

            if(formData.ctConnector === 'true') {
                this.setState({isEnableCT: true});
                this.setState({enableCTConfigarationChecked: true});
                localStorage.setItem("enableCT", true);
            } else {
                this.setState({isEnableCT: false});
                this.setState({enableCTConfigarationChecked: false});
                localStorage.setItem("enableCT", false);
            }

            var auditType = formData.auditType;
            this.resetAuditConfigInfo();
            if("1" === auditType) {
                this.setState({requestAuditIdChecked: true});
            } else if("2" === auditType) {
                this.setState({reponseAuditIdChecked: true});
            } else if("3" === auditType) {
                this.setState({reqResAuditIdChecked: true});
            } else if("0" === auditType) {
            this.setState({noAuditIdChecked: true});
            }
            this.setState({auditType: auditType});
            var borrowed = formData.borrowAllowed;
            if(borrowed === 'true'){
                this.setState({borrowSetAllowIdChecked: true});
                this.setState({isBorrowSettings: true});
            } else {
                this.setState({borrowSetAllowIdChecked: false});
                this.setState({isBorrowSettings: false});
            }
            if('true' === formData.preNotificationCheck) {
                this.setState({advanceSettingIdChecked: true});
                this.setState({isAdvanceSetting: true});
            } else {
                this.setState({advanceSettingIdChecked: false});
                this.setState({isAdvanceSetting: false});
            }
            this.setState({noOfNotifyAdvance: formData.preNotificationDays});
            var overClntAllowd = formData.overrideClientAllowed;
            if('true' === overClntAllowd) {
                this.setState({useClientSetChinChoutChecked: true});
                this.setState({useClientSetting: true});
            } else {
                this.setState({useClientSetChinChoutChecked: false});
                this.setState({useClientSetting: false});
            }
            var protType = formData.protocolType;
            if("https" === protType){
                this.setState({protocolType: "https"});
                this.setState({protoTypeHTTPIdChecked: false});
                this.setState({protoTypeHttpsIdChecked: true});
            } else {
                this.setState({protocolType: "http"});
                this.setState({protoTypeHTTPIdChecked: true});
                this.setState({protoTypeHttpsIdChecked: false});
            }
            this.setState({protocolHost: formData.protocolHost});
            this.setState({protocolPort: formData.protocolPort});
            let systemInfo = formData.systemInfoList;
            this.setState({systemInfoList: systemInfo});
            let exClients = formData.excludeClients;
            if(exClients && '' !== exClients) {
                let exClientArr = [];
                exClients.forEach(element => {
                    exClientArr.push({excludeClient: element, delete:<FormTrashIcon />});
                });
                this.setState({excludeClients: exClientArr});
            } else {
                this.setState({excludeClients: []});
            }
            if(formData.discoveryStatus === "true") {
                this.setState({isEnableDiscovery: true});
                this.setState({discoveryStatus: "1"});
            }
            if(formData.productAthentication === "Y") {
                this.setState({prodAuthYesIdChecked: true});
                this.setState({prodAuthNoIdChecked: false});
                this.setState({productAthentication: "Y"});
            } else if(formData.productAthentication === "N") {
                this.setState({prodAuthYesIdChecked: false});
                this.setState({prodAuthNoIdChecked: true});
                this.setState({productAthentication: "N"});
            }
            this.setState({serverScheme: formData.serverScheme});
            this.setState({apiKey: formData.apiKey, apiKeyExpireDays: formData.apiKeyExpireDays, apiKeyExpireOn: formData.apiKeyExpire});
            if(formData.apiKeyExpireDays !== undefined && formData.apiKeyExpireDays === 0) {
                this.setState({apiKeyExpireDays: ""});
            }
            if(formData.hostNames !== undefined) {
                this.setState({hostNameList: formData.hostNames, newHostIpId: -1});
            }
            ConfigurationUtils.hideLoadMask(this.context.intl);
            initialState = this.state;
            ValidationUtility.showPersistedSuccessMessage();
            this.setState({isFormDirty: false, databaseType: formData.dbType, changedTenancyFlag: false});
        }
    }
    resetAuditConfigInfo() {
        this.setState({noAuditIdChecked: false});
        this.setState({requestAuditIdChecked: false});
        this.setState({reqResAuditIdChecked: false});
        this.setState({reponseAuditIdChecked: false});
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    render() {
        //let intl = this.context.intl;
        let purgeTypeDivCls = 'disanblePurgeType';
        if(this.state.isAutoPurge !== undefined) {
            let val = this.state.isAutoPurge;
            if(val && document.getElementById('conf_autoPurgeId')) {
                document.getElementById('conf_autoPurgeId').checked=val;
                purgeTypeDivCls = 'enablePurgeType';
            }
        }
        // let advanceSettingsDivCls = 'disanbleAdvanceSettings';
        // if(this.state.isAdvanceSetting !== undefined) {
        //     let val = this.state.isAdvanceSetting;
        //     if(val) {
        //         advanceSettingsDivCls = 'enableAdvanceSettings';
        //     }
        // }
        let excludeClientSectionCls = 'disanbleExcludeClientsSection';
        if(this.state.isEnableDiscovery) {
            document.getElementById('conf_enableDiscoveryId').checked=true;
            excludeClientSectionCls = 'enableExcludeClientsSection';
        }
        let hostIpspaginationObj = this.state.hostIpspagination;
        let pageNumber = 1;
        let pageSize = 10;
        let hostIpsRowData = [];
        if(hostIpspaginationObj !== undefined) {
            hostIpspaginationObj.totalCount = this.state.hostNameList.length;
            pageNumber = hostIpspaginationObj.pageNumber;
            pageSize = hostIpspaginationObj.pageSize;
        }
        if(this.state.hostNameList !== undefined && this.state.hostNameList.length > pageSize) {
            hostIpsRowData = this.state.hostNameList.slice((pageNumber-1)*pageSize, (pageSize*pageNumber));
        } else {
            hostIpsRowData = this.state.hostNameList;
        }
        var currentTenantName = FetchHelper.getSession().currentTenantName;
        return (
          <div className="config-cont-backgroud">
            <SectionWithTitle title={this.findLabel("configpageMainTitle")} helpTopic="main_conf"
                setionWidth = "2"
            />
                <Form compact={false} pad="small" id="conf_main_form_id" className="border_white_backg_shadow" plain={true} onSubmit={this.configurationSubmit}>
                    <div className="left_grid">
                        <h2 className="headerConfig">
                            {this.findLabel("configpageMainMailconfigTitle")}
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField error={this.state.mailserverError} required={true} label={this.findLabel("configpageMainMailserver")}>
                                <TextInput id="conf_mailServerName" value={this.state.mailServerName} onChange={event =>this.handleChangeServerName(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField error={this.state.mailPortError} label={this.findLabel("configpageMainMailserverport")} >
                                <TextInput id="conf_portNumber" value={this.state.portNumber} onChange={event =>this.handleChangePortNumber(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField error={this.state.adminmailError} required={true}  label={this.findLabel("configpageMainMailserveradminemail")}>
                                <TextInput id="conf_adminEmailId" value={this.state.adminEmail} onChange={event =>this.handleChangeAdminEmail(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField error={this.state.mailInstanceError}  label={this.findLabel("configpageMainMailserverInstanceIdentifier")}>
                                <TextInput id="conf_instanceIdentifier" value={this.state.instanceIdentifier} onChange={event =>this.handleChangeInstanceIdentifier(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField /*error={this.state.usernameError} required={true}*/ label={this.findLabel("configpageMainMailserverusername")} >
                                <TextInput id="conf_userNameId" value={this.state.userName} onChange={event =>this.handleChangeUserName(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField label={this.findLabel("configpageMainMailserverpassword")} >
                                <Password className = "password_icon" id="conf_passwordId" autoComplete="new-password" value={this.state.password} onChange={this.handleChangePassword} />
                            </FormField>
                        </div>
                        <div className="test_mail_wrap">
                            <FormField label={this.findLabel("configpageMainMailservertestmail")} >
                                <TextInput id="conf_testEmailId" value={this.state.testEmailId} onChange={event =>this.handleChangeTestEmail(event)} />
                            </FormField>                                    
                            <div className="button_right_wrap button_wrap_primary">
                                {this.state.enableTestMainBtn ? 
                                    <Button id="conf_testMailButton" label={this.findLabel("configpageMainMailserversendmail")} plain={false} primary={true} onClick={this.submitTestMail} />
                                    :
                                    <Button id="conf_testMailButton" label={this.findLabel("configpageMainMailserversendmail")} plain={false} primary={true} />
                                }
                            </div>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageConLicPurgeSettings")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField error={this.state.purgeTypeError}>
                                <CheckBox id="conf_autoPurgeId" 
                                    checked={this.state.isAutoPurge}
                                    label={this.findLabel("configpageAutoPurge")}
                                    onChange={this.handleChangeAutoPurge} />
                            </FormField>
                        </div>
                        <div id="purgeTypeDiv" className={purgeTypeDivCls}>
                            <h3 className="headerConfig purge">
                                {this.findLabel("configpageSelectPurgeType")}  
                            </h3>
                            <div className="select_purge_type_wrap">
                                    <div className="overrideTableBottom">
                                        <div className="config_radio_button">
                                            <RadioButton name="conf_purgeRecOldId" id="conf_purgeRecOldId" label={this.findLabel("configpagePurgeRecordsOlderThan")} 
                                                checked={this.state.purgeRecOldIdChecked} onChange={this.handleChangePurgeRecOld}/>
                                        </div>
                                        <div>
                                            <FormField error={this.state.purgeRecordsOlderError} >
                                                <TextInput id="conf_purgeRecOldValueId" value={this.state.purgeRecordsOlder} onChange={event=> this.handleChangePurgeRecordValue(event)} />
                                            </FormField>
                                        </div>
                                        <div>
                                            {this.findLabel("configpageUMChangePasswordDays")}
                                        </div>
                                    </div>
                                    <div className="overrideTableBottom">
                                        <div>
                                            <RadioButton id="conf_maxRecordRetainId" name="conf_maxRecordRetainId"
                                                checked={this.state.maxRecordRetainIdChecked} 
                                                onChange={this.handleChangeMaxRetain}
                                                label={this.findLabel("configpageMaximumRecordsRatain")} />
                                        </div>
                                        <div>
                                            <FormField error={this.state.maxRecrodsRetainError}>
                                                <TextInput id="conf_maxRecordRetainValueId" value={this.state.maxRecrodsRetain} onChange={event =>this.handleChangeMaxRetainValue(event)} />
                                            </FormField>
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageControlTowerSettings")}  
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }} >
                                <CheckBox id="conf_enableCTConnId" 
                                    checked={this.state.enableCTConfigarationChecked} 
                                    label={this.findLabel("configpageEnableCTConnector")}
                                    onChange={this.handleChangeEnableCT} />
                            </FormField>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageProxyServerSetting")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }} error={this.state.proxyAddressError} label={this.findLabel("configpageProxyAddress")} >
                                <TextInput id="conf_proxyAddressId" value={this.state.proxyAddress}  onChange={event =>this.handleChangeProxyAddress(event)} />
                            </FormField>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }} error={this.state.proxyPortError} label={this.findLabel("configpageProxyPort")} >
                                <TextInput id="conf_proxyPortId" value={this.state.proxyPortNumber}  onChange={event =>this.handleChangeProxyPort(event)} />
                            </FormField>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageProductAuthTitle")}
                            <div className="dbInfotooltip">
                                &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                                <span style={{"width": "500px !important"}} className="dbInfotooltipText">
                                    {this.findLabel("config.client.validation.info")}
                                </span>
                            </div>
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap" className="purge_conf_wrap">
                            <div className="autoPurgeConfigLeftDesc prodAuthentication">
                                <FormField style={{ width: '7em' }} >
                                    <RadioButton id="conf_prodAuthYesId" name="conf_prodAuthYesId"
                                        checked={this.state.prodAuthYesIdChecked}
                                        label={this.findLabel("configpageProductAuthYes")} 
                                        onChange={this.handleChangeProdAuthYes}/>
                                </FormField>
                            </div>
                            <div className="autoPurgeConfigLeftDesc prodAuthentication">
                                <FormField style={{ width: '7em' }} >
                                    <RadioButton id="conf_prodAuthNoId" name="conf_prodAuthNoId" 
                                        checked={this.state.prodAuthNoIdChecked} 
                                        label={this.findLabel("configpageProductAuthNo")}
                                        onChange={this.handleChangeProdAuthNo}/>
                                </FormField>
                            </div>
                        </div>
                        <div style={{borderRight: "1px solid #ccc"}}>
                            <div className="separator"></div>
                            <h2 className="headerConfig">
                                {this.findLabel("label.whitelisting.ips")} 
                                <div className="dbInfotooltip">
                                &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                                <span style={{"width": "450px !important"}} className="dbInfotooltipText">
                                    {this.findLabel("white.listing.ips.info")}
                                </span>
                            </div>
                            </h2>
                            <div className="ipaddress_input_wrap">
                                <div className="flex_start">
                                <div className="excludeClientLeftDesc ">
                                    <FormField label={this.findLabel("label.host.ip")} >
                                        <TextInput id="host_or_ip_id" value={this.state.hostOrIpValue} onDOMChange={this.handlehostOrIpValue}/>
                                        {this.state.hostOrIpValueValidation}
                                    </FormField>
                                </div>
                                <div className="excludeClientRightDesc button_left_wrap">
                                    <div className="button_wrap_primary">
                                     <Button id="conf_hostOrIpValueBtn" label={this.findLabel("label.button.add")} plain={false} primary={true} onClick={this.addHostIpAction} /></div>
                                     <div className="button_wrap_negative">
                                     <Button id="conf_hostOrIpRemoveBtn" label={this.findLabel("configpageExcludedClientRemove")} plain={false} primary={true} onClick={this.removeHostOrIps} /></div>
                                </div>
                            </div>
                            </div>
                            <div className="ipAddress_tbl_wrap">
                                <TableGrid id="conf_hostIpGrid"
                                    header={[
                                        {
                                            columnName: "hostName",
                                            headerLabel: this.findLabel("label.host.ip"),
                                            href: false,
                                            width: "auto",
                                            columnKey: "hostName"
                                        }
                                    ]}
                                    rowData={hostIpsRowData}
                                    pagination={hostIpspaginationObj}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.onHostIPSortClicked}
                                    onFilter={this.onHostIPFilterClicked}
                                    onPagination={this.onHostIPPaginationClicked}
                                    sortIndex={this.state.sortIndex}
                                    sortAscending={this.state.sortAscending}
                                    rowKey="hostName"
                                    onChangeCheckBoxCallback={this.onHostIPRowClicked}
                                    selectAllRows={false}
                                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                                                "label.grid.item.count.message", {count: hostIpsRowData.length})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="right_grid">
                        <h2 className="headerConfig">   
                            {this.findLabel("configpageAuditConfiguration")}
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap" className="audit_conf_wrap" >
                            <div className="autoPurgeConfigLeftDesc">
                                <FormField style={{ width: '15em' }} >
                                    <RadioButton id="conf_noAuditId" name="conf_noAuditId"
                                    checked={this.state.noAuditIdChecked} 
                                    onChange={this.handleChangeNoAudit}
                                    label={this.findLabel("configpageNoAudit")}/>
                                </FormField>
                                <FormField style={{ width: '15em' }}  >
                                    <RadioButton id="conf_reponseAuditId" name="conf_reponseAuditId"
                                        checked={this.state.reponseAuditIdChecked} 
                                        onChange={this.handleChangeResponseAudit}
                                        label={this.findLabel("configpageResponse")}/>
                                </FormField>
                            </div>
                            <div className="autoPurgeConfigRightDesc">
                                <FormField style={{ width: '24em' }}  >
                                    <RadioButton id="conf_requestAuditId" name="conf_requestAuditId"
                                        checked={this.state.requestAuditIdChecked} 
                                        onChange={this.handleChangeRequestAudit}
                                        label={this.findLabel("configpageRequest")}/>
                                </FormField>
                                <FormField style={{ width: '24em' }} >
                                    <RadioButton id="conf_reqResAuditId" name="conf_reqResAuditId" 
                                        checked={this.state.reqResAuditIdChecked} 
                                        onChange={this.handleChangeReqResAudit}
                                        label={this.findLabel("configpageRequestAndResponse")}/>
                                </FormField>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageBorrowSetting")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }} >
                                <CheckBox id="conf_borrowSetAllowId" 
                                    checked={this.state.borrowSetAllowIdChecked}  
                                    onChange={this.handleChangeBorrowSetting} 
                                    label={this.findLabel("configpageBorrowSettingAllow")}/>
                            </FormField>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageConcurrentLicSetting")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }}  >
                                <CheckBox id="conf_useClientSetChinChout" 
                                    checked={this.state.useClientSetChinChoutChecked}  
                                    onChange={this.handleChangeUseClienSettings} 
                                    label={this.findLabel("configpageUseClientSettings")}/>
                            </FormField>
                        </div>
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageServerConfigurations")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <div className="flex_jc_fs config_prototype_wrap">
                            <div className="autoPurgeConfigLeftDesc protoTypeLabel">
                                <label>{this.findLabel("configpageProtocolType")}</label>
                                {/* <FormField style={{ width: '30em' }} label={this.findLabel("configpageProtocolType")} >
                                </FormField> */}
                            </div>
                            <div className="autoPurgeConfigRightDesc protoTypeRight flex_jc_fs">
                                <div className="autoPurgeConfigLeftDesc protoTypeCls">
                                    <FormField style={{ width: '7em' }}  >
                                        <RadioButton id="conf_protoTypeHTTPId" name="conf_protoTypeHTTPId" 
                                            checked={this.state.protoTypeHTTPIdChecked} 
                                            onChange={this.handleChangeHttp}
                                            label={this.findLabel("confipageProtocolHTTP")}/>
                                    </FormField>
                                </div>
                                <div className="autoPurgeConfigLeftDesc protoTypeCls">
                                    <FormField style={{ width: '9em' }}  >
                                        <RadioButton id="conf_protoTypeHttpsId" name="conf_protoTypeHttpsId" 
                                            checked={this.state.protoTypeHttpsIdChecked} 
                                            onChange={this.handleChangeHttps}
                                            label={this.findLabel("confipageProtocolHTTPS")}/>
                                    </FormField>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <div className="flex_fw_fdc">
                                <div className="flex_jc_fs">
                                    <div>
                                        <FormField style={{ width: '25em' }} error={this.state.serverConfigIpOrHostError} label={this.findLabel("configpageIpHost")} >
                                            <TextInput id="conf_serverIpOrHostId" value={this.state.protocolHost} onChange={event =>this.handleChangeServerConfigIpOrHost(event)} />
                                        </FormField>
                                    </div>
                                    <div>
                                        <Select id="conf_idphostSelId" placeholder={this.findLabel("configpageIpHostSelect")}
                                            options={this.state.systemInfoList}
                                            onChange={event=>{this.setState({protocolHost: event.option, isFormDirty: true})}} 
                                        />
                                    </div>
                                </div>
                                 <div className="flex_jc_fs">
                                    <div>
                                        <FormField style={{ width: '25em' }} error={this.state.serverConfigPortError} label={this.findLabel("configpageIpHostPort")} >
                                            <TextInput id="conf_serverConfiPortNumber" value={this.state.protocolPort} onChange={event =>this.handleChangeServerConfigPortNumber(event)} />
                                        </FormField>
                                    </div>
                                    <div>
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <h2 style={{width: "25%"}} className="headerConfig">
                            {this.findLabel("label.api.key.settings")} 
                        </h2>
                        <div style={{paddingTop: "2px"}} className="dbInfotooltip">
                            &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                            <span style={{"width": "450px"}} className="dbInfotooltipText">
                                {this.findLabel("message.api.key.info")}
                            </span>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <div className="flex_jc_fs">
                            <div id="apikeydivid" className="excludeClientLeftDesc">
                                <FormField error={this.state.apiKeyError} style={{ width: '25em' }} label={this.findLabel("label.api.key")} >
                                    <TextInput id="conf_apikey_id" value={this.state.apiKey} onChange={event =>this.handleChangeApiKey(event)} />
                                </FormField>
                            </div>
                            <div className="excludeClientRightDesc button_wrap_primary button_left_wrap">
                                <Button id="conf_apikey_generate_id" label={this.findLabel("label.button.generate")} plain={false} primary={true} onClick={this.generateApiKeyAction} />
                            </div>
                            </div>
                        </div>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <div id="apikeyexpiredivid" className="excludeClientLeftDesc">
                                <FormField error={this.state.apiKeyExpireError} style={{ width: '25em' }} label={this.findLabel("label.api.key.expire")} >
                                    <TextInput id="conf_apikey_expire_id" value={this.state.apiKeyExpireDays} onChange={event =>this.handleChangeApiKeyExpire(event)} />
                                </FormField>
                            </div>
                            {this.state.apiKeyExpireDays !== undefined && this.state.apiKeyExpireDays !== "" &&(
                                // <div className="excludeClientRightDesc ">
                                    <div className="dbInfotooltip">
                                        &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                                        <span style={{"width": "200px"}} className="dbInfotooltipText">
                                            {this.findLabel("label.expire.on")+': '+DateUtility.getFormatedDate(new Date(this.state.apiKeyExpireOn))}
                                        </span>
                                    </div>
                                // </div>
                            )}
                        </div>
                        {/*(currentTenantName === "0" || Number(currentTenantName) === 0) && (this.state.databaseType === "POSTGRES" || this.state.databaseType === "ORACLE") &&(
                            <div>
                                <div className="separator"></div>
                                <h2 style={{width: "35%"}} className="headerConfig">
                                    {this.findLabel("label.multi.tenancy.settings", intl)} 
                                </h2>
                                <div style={{paddingTop: "2px", float: "left"}} className="dbInfotooltip">
                                    &nbsp;&nbsp;<CircleInformationIcon colorIndex="brand" />
                                    <span style={{"width": "350px"}} className="dbInfotooltipText">
                                        {this.findLabel("message.multi.tenant.info", intl)}
                                    </span>
                                </div>
                                <div className="horizantal_label_input system_cinfig_inp_wrap">
                                    <FormField style={{ width: '30em' }} >
                                        <CheckBox id="enable_multi_tenancy_id" 
                                            checked = {this.state.enabledTenancy}
                                            onChange={this.handleChangeMultiTenancy}
                                            label={this.findLabel("label.multi.tenancy.enable", intl)} />
                                    </FormField>
                                </div>
                            </div>
                        )*/}
                        <div className="separator"></div>
                        <h2 className="headerConfig">
                            {this.findLabel("configpageDiscovery")} 
                        </h2>
                        <div className="horizantal_label_input system_cinfig_inp_wrap">
                            <FormField style={{ width: '30em' }} >
                                <CheckBox id="conf_enableDiscoveryId" 
                                    checked={this.state.isEnableDiscovery}
                                    onChange={this.handleChangeEnableDiscoveryId}
                                    label={this.findLabel("configpageEnableDiscovery")} />
                            </FormField>
                        </div>
                        <div className={excludeClientSectionCls}>
                            <div className="horizantal_label_input system_cinfig_inp_wrap">
                                <div className="flex_jc_fs">
                                <div className="excludeClientLeftDesc">
                                    <FormField style={{ width: '30em' }} label={this.findLabel("configpageExcludedClients")} >
                                        <TextInput id="conf_excludeClientTextId" value={this.state.excludeClientValue} onChange={event =>this.handleChangExcludeClientValue(event)}/>
                                        {this.state.excluedeClientValidation}
                                    </FormField>
                                </div>
                                <div className="excludeClientRightDesc button_left_wrap">
                                    <div className="button_wrap_primary">
                                     <Button id="conf_addExcludeClientBtn" label={this.findLabel("label.button.add")} plain={false} primary={true} onClick={this.addExcludeClientAction} />
                                     </div>
                                     <div className="button_wrap_negative">
                                     <Button id="conf_removeExcludeClientBtn" label={this.findLabel("configpageExcludedClientRemove")} plain={false} primary={true} onClick={this.removeExcludeClients} />
                                     </div>
                                </div>
                                </div>
                            </div>
                           
                            {this.state.excludeClients.length > 0 ?
                            <div>
                                <div className="separator"></div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <TableGrid id="conf_exClientGrid"
                                                    header={[
                                                        {
                                                            columnName: "excludeClient",
                                                            headerLabel: this.findLabel("configpageExcludedClients"),
                                                            href: false,
                                                            width: "auto",
                                                            columnKey: "excludeClient"
                                                        }
                                                    ]}
                                                    rowData={this.state.excludeClients}
                                                    pagination="false"
                                                    rowSelection={3}
                                                    isExportRequired={false}
                                                    onSort={this.onSortClicked}
                                                    onFilter={this.onFilterClicked}
                                                    onPagination={this.onPaginationClicked}
                                                    sortIndex={this.state.sortIndex}
                                                    sortAscending={this.state.sortAscending}
                                                    rowKey="excludeClient"
                                                    onChangeCheckBoxCallback={this.onRowClicked}
                                                    selectAllRows={false}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : null }
                        </div>
                    </div>
                    <div className="saveBtnStyle button_left_wrap">
                        <div className="button_wrap_primary">
                        <Button id="conf_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitConfigSettings} />
                        </div>
                        <div className="button_wrap_negative">
                        <Button id="conf_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelConfigSettings} />
                        </div>
                    </div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </Form>
          </div>
      );
    }
}
// MainConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default MainConfiguration;