import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import Store from "../../../flux/ReservationManagementStore";
import ComponentNotification from "../../generic/ComponentNotification";
import { useIntl } from 'react-intl';

// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const paginationObj = {
    required: true,
    style: "default",
    totalCount: 0,
    pageNumber: 1,
    pageSize: FetchHelper.getPageSize(),
    sortBy: "id",
    sortOrder: "DESC"
}

class AplsJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: paginationObj,
            category: this.props.category,
            jobName: this.props.jobName,
            deleteId:undefined
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadGrid = this.loadGrid.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.refreshGridData = this.refreshGridData.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        Store.on("change", this.refreshGridData);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGridData);
    }
    refreshGridData() {
        if(Store.getState() && (Store.getState().actionType === "delete_job"
        || Store.getState().actionType === "resv_job")) {
            let jobData = Store.getState().data;
            if(jobData !== undefined) {
                let pagination = Store.getState().pagination;
                pagination.required = true;
                pagination.style = "default";
                this.setState({rowData: jobData, pagination: pagination});
                ConfigurationUtils.hideLoadMask(this.context.intl);
            }
        }
    }
    loadGrid() {
        let request = {
            category: this.state.category,
            pagination: this.state.pagination,
            jobName: this.state.jobName
        }
        FetchHelper.listJobsByCategory(request, this.context.intl);
    }
    onClickDownload(key, event) {
        FetchHelper.downloadJobFile(key, this.context.intl);
    }

    onConfirmDelete(flag, key){
        if(flag === 1 && this.state.deleteId !== undefined){
            let jobIds = [];
            jobIds.push(this.state.deleteId);
            let request = {
                category: this.state.category,
                pagination: this.state.pagination,
                jobIds: jobIds,
                jobName: this.state.jobName
            }
            this.setState({deleteId:undefined});
            FetchHelper.deleteJob(request, this.context.intl);
        }
    }
    onClickDelete(key, event) {  
        let msg = LocaleUtility.getLocaleMessage("resv.pool.delete.job.warning");
        this.setState({deleteId:key});
        ValidationUtility.confirm(msg, this.onConfirmDelete, undefined, "", "");
    }
    onRowClicked(key, event) {
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        let request = {
            category: this.state.category,
            pagination: paginationTemp,
            jobName: this.state.jobName
        }
        FetchHelper.listJobsByCategory(request, this.context.intl);
    }
    render() {
        // let intl = this.context.intl;
        let divheight = "auto";
        let divOverflow = "hidden hidden";
        //if(this.state.rowData !== undefined && this.state.rowData.length > 10) {
            divheight = "432px";
            divOverflow = "hidden auto";
        //}
        return(
            <div style={{height: divheight, overflow: divOverflow}}>
                <div>
                    <ComponentNotification />
                </div>
                <div>
                    <TableGrid id="usage_report_list_grid"
                        header={[
                            {
                                columnName: "jobName",
                                headerLabel: this.findLabel("job.label.job.name") ,
                                href: false,
                                width: "auto"
                            },
                            {
                                columnName: "fileName",
                                headerLabel: this.findLabel("job.label.file.name") ,
                                href: false,
                                width: "auto"
                            },
                            {
                                columnName: "createdBy",
                                headerLabel: this.findLabel("job.label.created.by"),
                                href: false,
                                width: "auto"
                            },
                            {
                                columnName: "createdOn",
                                headerLabel: this.findLabel("job.lebel.created.on"),
                                href: false,
                                width: "auto"
                            },
                            {
                                columnName: "updatedOn",
                                headerLabel: this.findLabel("job.label.updated.on"),
                                href: false,
                                width: "auto"
                            },
                            {
                                columnName: "status",
                                headerLabel: this.findLabel("job.label.status"),
                                href: false,
                                width: "10%",
                            },
                            {
                                columnName: "downloadIcon",
                                headerLabel: this.findLabel("job.label.download.icon"),
                                href: false,
                                width: "8%",
                                columnKey: "id",
                                textAlign: "center",
                                onClick: this.onClickDownload,
                            },
                            {
                                columnName: "deleteColumn",
                                headerLabel: this.findLabel("job.label.delete.icon"),
                                href: false,
                                columnKey: "id",
                                width: "6%",
                                textAlign: "center",
                                onClick: this.onClickDelete,
                            }
                        ]}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={0}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="id"
                        onChangeCheckBoxCallback={this.onRowClicked}
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div style={{ clear: 'both' }}></div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// AplsJobs.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AplsJobs;