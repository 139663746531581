
import React, { Component } from "react";
// import BreadCrumb from "@micro-focus/quantum-ux-grommet/components/BreadcrumbsComponent";
import { Button } from "grommet/components/Button";
import { CheckBox } from "grommet/components/CheckBox";
import TableGrid from "../../../../components/commons/table/APTable";
import PageStore from "../../../../flux/PageWizardStore";
import "../../../../css/index.css";
import FileUploadBox from "../../../generic/FileUploadBox";
import * as FetchHelper from "../../../../utils/FetchHelper";
import Dispatcher from "../../../../flux/Dispatcher";
//import * as FrontendPagePath from  "../../../../utils/FrontendPagePath";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as LicenseUtility from "../../../../utils/LicenseUtility";
import * as ValidationUtility from "../../../../utils/ValidationUtility";
import Select from "../../../commons/APLSSelect";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { DocumentCsv as DocumentCsvIcon } from 'grommet-icons';
import * as DateUtility from "../../../../utils/DateUtility";
import ProductStore from "../../../../flux/ProductStore";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);
var timer = null;
class WizardStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            licenses: [],
            rowData: [],
            selectedRowKeys: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            productNameSelectOptions: ProductStore.getState().productNameSelectOptions,
            searchIDVersion: "",
            searchCapacity: "",
            searchHostName: "",
            searchUserName: "",
            searchClientName: "",
            searchIPAddress:"",
            searchSharedBy:"",
            searchProductFeature:"",
            searchCreatedTime: ""
        };
        this.uploadCleanupLicense = this.uploadCleanupLicense.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.cleanLicense = this.cleanLicense.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.paintCheckBox = this.paintCheckBox.bind(this);
        this.onConfrimAction = this.onConfrimAction.bind(this);
        this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
        this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
		
		this.refreshProductItem=this.refreshProductItem.bind(this);
        this.filterLicenseList = this.filterLicenseList.bind(this);
        this.buildSearchParams = this.buildSearchParams.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
    }

    refreshState() {

        if(PageStore.getState().cleanupData !== undefined){
            var sessObj = FetchHelper.getSession(); 
            if(sessObj.admin !== undefined && sessObj.admin) {
                const rows = PageStore.getState().cleanupData.map(item => {
                        var key = item.tokenID;
                        item.checkBoxColumn = this.paintCheckBox(key, false);
                        if (item.sharedUserCount === 0) {
                          item.sharedUserCount = 1;
                        }
                        return item;
                    });
                this.setState({
                    rowData: rows,
                    //pagination: PageStore.getState().pagination,
                    currentStep: PageStore.getState().currentStep,
                    rowMapData: PageStore.getState().cleanupTokenMap,
                    sortIndex: PageStore.getState().sortIndex,
                    sortAscending: PageStore.getState().sortAscending,
                    licDetail: PageStore.getState().licenseDetail,
					cleanupKeyType:PageStore.getState().cleanupKeyType,
                    error: PageStore.getState().error
                });
            } else if(sessObj.admin !== undefined && !sessObj.admin && 
                sessObj.productList !== undefined ){
                var features = [];
                var validFeatures = FetchHelper.getUserFeatures();
                validFeatures.then(data => {
                    features = data;
                    const rows = PageStore.getState().cleanupData.map(item => {
                        var key = item.tokenID;
                        if(data !== undefined && data.indexOf(item.featureID+":"+item.featureVersion) > -1){
                            item.checkBoxColumn = this.paintCheckBox(key, false);
                        }else{
                            item.checkBoxColumn = "";
                        }
                        return item;
                    });
                    this.setState({
                        features: features,
                        rowData: rows,
                        //pagination: PageStore.getState().pagination,
                        currentStep: PageStore.getState().currentStep,
                        rowMapData: PageStore.getState().cleanupTokenMap,
                        sortIndex: PageStore.getState().sortIndex,
                        sortAscending: PageStore.getState().sortAscending,
                        licDetail: PageStore.getState().licenseDetail,
                        error: PageStore.getState().error
                    });
                }).catch((status, error) => {
                    LicenseUtility.processError(status, error);
                });
            }

            if(PageStore.getState().licenseDetail === undefined){
                this.setState({
                    currentStep: 1
                });
            }
        }
    }

    componentDidMount() {
        PageStore.on("change", this.refreshState);
		FetchHelper.getProducts("", false);
        ProductStore.on("change", this.refreshProductItem);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.rowData !== undefined && 
            this.state.rowData !== undefined && 
            prevState.rowData.length !== this.state.rowData.length) {
            this.refreshState();
        }
    }

    componentWillUnmount() {
        PageStore.removeListener("change", this.refreshState);
		ProductStore.removeListener("change", this.refreshProductItem);
    }

    onRowClicked(keys, event){
        this.setState({
            selectedRowKeys: keys
        });
    }

    onHeaderCheckBoxClick(event){
        var keys = [];
        if(event.target !== undefined && event.target.checked){
            this.state.rowData.map(item => {
                keys.push(item["tokenID"]);
                return "";
            });
        } else if(event.target !== undefined && !event.target.checked) {
            keys = [];
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
                    var key = item.tokenID;
                    var index = keys.indexOf(key);
                    if(this.state.features === undefined || 
                        (this.state.features !== undefined && 
                            this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                        item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                        count++;
                    } else if(this.state.features !== undefined && index !== -1)
                        keys.splice(index, 1);
                    return item;
                });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

filterLicenseList(event){
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        this.setState({
            productNameSelected:event.option.value
        });
       FetchHelper.getAllLicenseToken(this.state.cleanupKeyType,"SUCCESS",event.option.value,this.state.request);    
    }

       onFilterClicked(filterColumn, filterValue, e) {
        if(timer !== null) {
            clearTimeout(timer);
        }
        if(e !== undefined && e.charCode === 13) {
            this.continueFilterAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueFilterAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }
       continueFilterAction(filterColumn, filterValue) {
        this.setState({searchAction: true});
        let params;
        if(filterColumn === "featureDesc") {
            this.setState({searchIDVersion: filterValue});
            params = this.buildSearchParams(filterValue, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        }
         else if(filterColumn === "capacity") {
            this.setState({searchCapacity: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion,filterValue, 
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        }
        else if(filterColumn === "startDate") {
            this.setState({searchCreatedTime: filterValue.value});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                filterValue.value,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        } 
        else if(filterColumn === "hostname") {
            this.setState({searchHostName: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,filterValue, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        } 
        else if(filterColumn === "username") {
            this.setState({searchUserName: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, filterValue,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        } 
         else if(filterColumn === "clientuniquename") {
            this.setState({searchClientName: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                filterValue,this.state.searchIPAddress,this.state.searchSharedBy,
                this.state.searchProductFeature);
        } 
         else if(filterColumn === "ipAddress") {
            this.setState({searchIPAddress: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,filterValue,this.state.searchSharedBy,
                this.state.searchProductFeature);
        } 
         else if(filterColumn === "sharedUserCount") {
            this.setState({searchSharedBy: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,filterValue,
                this.state.searchProductFeature);
        } 
         else if(filterColumn === "productFeature") {
            this.setState({searchProductFeature: filterValue});
            params = this.buildSearchParams(this.state.searchIDVersion, this.state.searchCapacity,
                this.state.searchCreatedTime,this.state.searchHostName, this.state.searchUserName,
                this.state.searchClientName,this.state.searchIPAddress,this.state.searchSharedBy,
                filterValue);
        } 

        this.findData(params);
    }
    buildSearchParams(IDVersion,capacity,createdOn,hostName,userName,clientName,ipAddress,sharedby,productFeature) {
        let createdTime = null;
        if(createdOn && createdOn !== "") {
            createdTime = new Date(createdOn).getTime()/1000;
        }
        let params = {

            "featureID":IDVersion,
            "capacity":capacity,
            "startDate":createdTime,
            "hostIdList":hostName,
            "userNameList":userName,
            "clientUniqueIdList":clientName,
            "ipAddressList":ipAddress,
            "sharedUserCount":sharedby,
            "productFeature":productFeature,
 

       }
        return params;
    }

     findData(params) {
        this.setState({request: params});
        FetchHelper.getAllLicenseToken(this.state.cleanupKeyType,"SUCCESS",this.state.productNameSelected,params);
    }

     findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onRowCheckBoxClick(key, event){
        var keys = Object.assign([], this.state.selectedRowKeys);
        if(event.target.type === 'radio'){
            keys = [];
            keys.push(key);
        } else if(event.target.type === 'checkbox'){
            if(event.target.checked){
                keys.push(key);
            } else {
                var index = keys.indexOf(key)
                if (index !== -1) {
                    keys.splice(index, 1);
                }
            }
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
            var key = item.tokenID;
            if(this.state.features === undefined || 
                (this.state.features !== undefined && 
                    this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                count++;
            }
            return item;
        });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

    paintCheckBox(key, flag){
        return (
            <CheckBox checked={flag} onChange={this.onRowCheckBoxClick.bind(this, key)}/>
        )
    }

    prevPage(){
        let currentStep = this.state.currentStep;
        if (currentStep <= 1) {
            currentStep = 1;
        } else {
            currentStep = currentStep - 1;
        }

        this.setState({
            currentStep: currentStep
        });
        this.props.prevPage();
    }

    onConfrimAction(flag){
        if(flag === 1){
            var selectedKeys = this.state.selectedRowKeys;
            FetchHelper.requestCleanupCommuter(this.state.licDetail.rawCleanupKey,
                    this.state.rowMapData, selectedKeys);
            this.setState({
                selectedRowKeys: [],
                currentStep: 1
            });
        }
    }

    cleanLicense() {

        if( this.state.selectedRowKeys !== undefined && this.state.selectedRowKeys.length > 0){
            var cap=this.state.licDetail.capacity-this.state.selectedRowKeys.length;            
           if( cap <0){
               ValidationUtility.confirm(LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert5", 
                    {capacity: this.state.licDetail.capacity}), ()=>{})
                //alert(LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert5",{capacity: this.state.licDetail.capacity}));
           }else{
            ValidationUtility.confirm(LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert4", 
                    {capacity: this.state.licDetail.capacity, selected:this.state.selectedRowKeys.length }), this.onConfrimAction)
           }
        }else {
            if(this.state.rowData !== undefined && this.state.rowData.length > 0){
                // Dispatcher.dispatch({
                //     type: "LICENSE_NOT_CHECKED_ALERT_SHOW_MODAL",
                //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.file.upload.cleanup.alert3")
                // });
                //alert(LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert3"));
                Dispatcher.dispatch({
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert3")
                });
            }
        }
    }

    uploadCleanupLicense(event, size, uploadObjects) {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });

        const files = uploadObjects.map(item => item.file);
        var data = new FormData();
        for (var i = 0; i < files.length; i++) {
            data.append('file', files[i]);
        }

        FetchHelper.requestCleanupLicense( data );
        this.props.nextPage();
    }
	
	refreshProductItem() {
        const products = ProductStore.getState().productNameSelectOptions
        const features = ProductStore.getState().featureSelectOptions

        if(products !== undefined) {
            this.setState({
                productNameSelectOptions: products,
            });
        }
        if(features !== undefined) {
            this.setState({
                featureSelectOptions: features,
                productName: ProductStore.getState().productNameSelected,
                productNameSelected: ProductStore.getState().productNameSelected
            });
        }
    }

    showLicenseTableGridWizard() {

        const options = []; 
        let selectedValue = {value:undefined, label:LocaleUtility.getLocaleMessage("label.select.all")};
       
        let addButtonLabel;
        if (this.state.rowData.length > 0) {
            addButtonLabel =
            <div className="button_wrap_primary">
                <Button id="lic_clen_clnLicBtn" label={LocaleUtility.getLocaleMessage("label.button.cleanup.license")}
                    //path={FrontendPagePath.LICENSE_CLEANUP_PATH} 
                    plain={false} primary={true} onClick={this.cleanLicense.bind(this)} />
            </div>;
        } else {
            addButtonLabel =
            <div className="button_wrap_primary">
                <Button id="lic_clen_clnLicBtn" label={LocaleUtility.getLocaleMessage("label.button.cleanup.license")}
                    plain={false} primary={true} disabled={true} />
            </div>
        }

        var classVar="grommetux-check-box";
        if(this.state.selectAllRows !== undefined && !this.state.selectAllRows &&
            this.state.selectedRowKeys.length > 0){
            classVar="grommetux-check-box partial";
        }

        //let intl = this.context.intl;
    const productOptions = [];
    let selectedVal = {value:"", label:LocaleUtility.getLocaleMessage("label.select.all")};
    productOptions.push(selectedVal);

    var entries = this.state.productNameSelectOptions.entries()
        for(var item of entries){
            productOptions.push({value:item[0], label:item[1]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedVal = {value:item[0], label:item[1]};
            }
        }
        return (
            <div className="wrapper" style={{display: "block"}}>
                <div style={{width: "100%"}}>
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr>
                                <td className="lic-detail-head" width="7%">
                                    {LocaleUtility.getLocaleMessage("label.grid.col.featureid.version")}
                                </td>
                                <td>
                                    {this.state.licDetail.featureDesc}
                                </td>
                                <td className="lic-detail-head" width="7%">
                                    {LocaleUtility.getLocaleMessage("label.grid.col.description")}
                                </td>
                                <td>
                                    {this.state.licDetail.description}
                                </td>
                            </tr>
                            <tr>
                                <td className="lic-detail-head" width="7%">
                                    {LocaleUtility.getLocaleMessage("label.grid.col.startDate")}
                                </td>
                                <td>
                                    {this.state.licDetail.startDate}
                                </td>
                                <td className="lic-detail-head" width="7%">
                                    {LocaleUtility.getLocaleMessage("label.grid.col.expDate")}
                                </td>
                                <td>
                                    {this.state.licDetail.expiryDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
					<Select className="dropdown_select" label={LocaleUtility.getLocaleMessage("label.select.product")+": "} id="lic_view_selProduct" options={productOptions} value={selectedVal} onChange={this.filterLicenseList}/>
                    <TableGrid id="lic_clen_tokenGrid"
                        header={[
                            {
                                columnName: "checkBoxColumn",
                                headerLabel: <CheckBox className={classVar} checked={(this.state.selectedRowKeys.length > 0)} onChange={this.onHeaderCheckBoxClick.bind(this)}/>,
                                width: "22px",
                            },
                            {
                                columnName: "featureDesc",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.featureid.version"),
                                href: "true",
                                width: "auto",
								filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.featureid.version")
                            },
                       /*   {
                                columnName: "productID",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                href: false,
                                width: "auto",
                            }, */
                            {
                                columnName: "capacity",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.capacity"),
                                href: false,
                                width: "auto",
								filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.capacity")
                            },
                            {
                                columnName: "startDate",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.startDate"),
                                href: false,
                                width: "auto",
								filter: true,
                                filterFieldType: "date",
                                filterFieldFormat: DateUtility.getDateFormat(),
                                filterFieldValue: this.state.searchCreatedTime
                            },
                            {
                                columnName: "expiryDate",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.expDate"),
                                href: false,
                                width: "auto",
                            },
							{
                                columnName: "hostname",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.hostName"),
                                href: false,
                                width: "auto",
                                filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.hostName")
                            },
                             {
                                columnName: "username",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.userName"),
                                href: false,
                                width: "auto",
                                filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.userName")
                            },
                             {
                                columnName: "clientuniquename",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.cltUnqName"),
                                href: false,
                                width: "auto",
                                filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.cltUnqName")
                            },
                            {
                                columnName: "ipAddress",
                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.ipAddress"),
                                href: false,
                                width: "auto",
								filter: true,
                                filterPlaceHolder: this.findLabel("search.label.grid.col.ipAddress")
                            },
                            {
                              columnName: "sharedUserCount",
                              headerLabel: LocaleUtility.getLocaleMessage("usage.search.sharedby.label"),
                              href: false,
                              width: "auto",
							//   filter: true,
                            //   filterPlaceHolder: this.findLabel("search.usage.search.sharedby.label", intl)
                            },
                            {
                              columnName: "productFeature",
                              headerLabel: LocaleUtility.getLocaleMessage("label.product.feature.license"),
                              href: false,
                              width: "auto",
							  filter: true,
                              filterPlaceHolder: this.findLabel("search.label.product.feature.license")
                            }
                        ]}
                        {...{keys:this.state.selectedRowKeys}}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowSelection={0}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        rowKey="tokenID"
                        onChangeCheckBoxCallback={this.onRowClicked}
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        selectAllRows={false}
                    />
                </div>
                <div style={{ clear: 'both' }}></div>
                <div>
                    <div className="button_left_wrap">
                        {addButtonLabel}
                                <div className="button_wrap_negative">
                                    <div className="button_wrap_secondary">
                                    <Button id="lic_clen_backBtn" label={LocaleUtility.getLocaleMessage("label.button.back")}
                                     type="submit" accent={true} plain={false} onClick={this.prevPage} />
                                     </div>
                                </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let renderContent;
        if (this.state.currentStep === 1) {
            renderContent = <FileUploadBox id="lic_clen_upload" className="file_upload_wrap" isMultiFile={false}
                                uploadBtnLabel={LocaleUtility.getLocaleMessage("label.button.next")}
                                labelText={LocaleUtility.getLocaleMessage("label.select.files.click.add.files")}
                                fileExtFilter=".txt,.dat,.xml" handleUpload={this.uploadCleanupLicense}
                                cancelBtn={true}
                                alertNonfileSelection={LocaleUtility.getLocaleMessage("label.file.upload.cleanup.alert1")}
                                />
        } else {
            renderContent = this.showLicenseTableGridWizard();
        }

        return (
            <div id="lic_cle_wizSec">
                {renderContent}
            </div>

        );
    }
}

export default class CleanupPageWizard extends Component {

    constructor() {
        super();
        this.state = {
            currentStep: 1
        };

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
    }

    nextStep() {
        let currentStep = this.state.currentStep;
        // Make sure currentStep is set to something reasonable
        if (currentStep >= 2) {
            currentStep = 2;
        } else {
            currentStep = currentStep + 1;
        }

        this.setState({
            currentStep: currentStep
        });
    }

    prevStep() {
        let currentStep = this.state.currentStep;
        if (currentStep <= 1) {
            currentStep = 1;
        } else {
            currentStep = currentStep - 1;
        }

        this.setState({
            currentStep: currentStep
        });
    }

    render() {

        let stepOne, stepTwo;
        if (this.state.currentStep === 1) {
            stepOne = "active";
            stepTwo = "";
        } else {
            stepOne = "";
            stepTwo = "active";
        }
        return (
            <div className="border_white_backg_shadow">
                <div className="breadcrumb-div">
                    <ul className="breadcrumb">
                        <li className={stepOne}><span>{LocaleUtility.getLocaleMessage("label.browse.cleanup.files")}</span></li>
                        <li className={stepTwo}><span>{LocaleUtility.getLocaleMessage("label.cleanup.licenses")}</span></li>
                    </ul>
                </div>
                <WizardStep currentStep={this.state.currentStep} nextPage={this.nextStep.bind(this)} prevPage={this.prevStep.bind(this)} />
                {/* <SpinningNotification centerPage={true} /> */}
            </div>
        )
    }
}

// WizardStep.contextTypes = {
//   intl: _propTypes2.default.object,
// };
// CleanupPageWizard.contextTypes = {
//   intl: _propTypes2.default.object,
// };