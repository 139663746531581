import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import Select from "../../commons/APLSSelect";

import SectionWithTitle from "../../generic/SectionWithTitle";
import { Button } from "grommet/components/Button";
import SpinningNotification from "../../generic/SpinningNotification";
import * as FetchHelper from "../../../utils/FetchHelper";
import Store from "../../../flux/APTableStore";
import ProductStore from "../../../flux/ProductStore";
import MTStore from "../../../flux/MultiTenantStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
//import * as FrontendPagePath from "../../../utils/FrontendPagePath";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import "../../../css/index.css";
// var _propTypes = require("prop-types");
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class TenantLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenantValueObj:{},
      selectedTenantValue:'',
      tenantNames:[],
      activeTenantData: [],
      activeSortIndex: Store.getState().activeSortIndex,
      activeSortAscending: Store.getState().activeSortAscending,
      archivedBorrowData: [],
      archivedSortIndex: Store.getState().archivedSortIndex,
      archivedSortAscending: Store.getState().archivedSortAscending,
      productNameSelected: "ALL",
      productNameSelectOptions: ProductStore.getState()
        .productNameSelectOptions,
      selectAllRows: false,
      session: {},
      sortIndex: 0,
      sortAscending: false,
      activePagination: {
        deleted: false,
        sortBy: "installedOn",
        sortOrder: "DESC",
        pageNumber: "1",
        totalCount: 0,
        pageSize: FetchHelper.getPageSize()
      },
      archivedPagination: {
        deleted: false,
        sortBy: "installedOn",
        sortOrder: "DESC",
        pageNumber: "1",
        totalCount: 0,
        pageSize: 5
      },
      pagination: {
        required: true,
        sortBy: "serverLicenseTable.licenseStartDate",
        style: "default",
        sortOrder: "DESC",
        totalCount: 0,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize()
      },
      disableIds:[],
      productNameSelObj: undefined
    };
    this.refreshState = this.refreshState.bind(this);
    this.refreshProductItem = this.refreshProductItem.bind(this);
    this.filterLicenseList = this.filterLicenseList.bind(this);
    this.filterTenantList = this.filterTenantList.bind(this);
    this.onActiveTableRowClicked = this.onActiveTableRowClicked.bind(this);
    this.onArchiveTableRowClicked = this.onArchiveTableRowClicked.bind(this);
    this.onActivePaginationClicked = this.onActivePaginationClicked.bind(this);
    this.onArchivePaginationClicked = this.onArchivePaginationClicked.bind(
      this
    );
    this.returnLicense = this.returnLicense.bind(this);
    
    this.loadTenant = this.loadTenant.bind(this);
    this.continueFilterAction = this.continueFilterAction.bind(this);
    this.onSortClicked = this.onSortClicked.bind(this);
  }

  componentDidMount() {
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    this.setState({activeTenantData:[]});
    FetchHelper.getTenantProducts("borrow_init", true);
    FetchHelper.loadTenantNames({}, this.context.intl, false);
    Store.on("change", this.refreshState);
    MTStore.on("change", this.loadTenant);
    ProductStore.on("change", this.refreshProductItem);
    
  }
  onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC";
        if(sortAsc) {
            sortOrder = "DESC";
            this.setState({sortAscending: false});
        } else {
            this.setState({sortAscending: true});
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "serverLicenseTable.licenseStartDate";
        if(index === 0) {
            sortBy = "serverLicenseTable.redundantServerHostName";
        } else if(index === 1) {
            sortBy = "serverLicenseTable.capacity";
        } else if(index === 3) {
            sortBy = "serverLicenseTable.licenseExpiryDate";
        } else if(index === 4) {
            sortBy = "tenant.name";
        }
        this.setState({sortIndex: index});
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.setState({pagination: paginationTemp});
        FetchHelper.getTenantSpecificLicenses(this.state.productNameSelected,"ACTIVE", this.state.tenantName, paginationTemp);
    }
  componentWillUnmount() {
    Store.removeListener("change", this.refreshState);
    MTStore.removeListener("change", this.loadTenant);
    ProductStore.removeListener("change", this.refreshProductItem);
  }

 loadTenant(){
   if(MTStore.getState().type==='DROPDOWN_LIST'){
    let refreshAction = MTStore.getState().refreshAction;
    let selObject = {label: LocaleUtility.getLocaleLabel(this.context.intl,"label.select.all"), value: "ALL"};
    //if(refreshAction === true) {
      this.setState({selectedTenantValueObj: selObject, tenantName: "ALL"});
    //}
    let tenantList = [];
    tenantList.push(selObject);
    let existData = MTStore.getState().ddData;
    existData.map(obj=>{
      tenantList.push(obj);
      return "";
    });
    this.setState({
      tenantNames: tenantList,
      productNameSelectOptions: ProductStore.getState().productNameSelectOptions
    })
   }
 }

  filterLicenseList(event) {
    if (event.option.value !== undefined) {
      this.setState({ productNameSelected: event.option.value, productNameSelObj: event.option});
      //if(this.state.tenantName !== undefined && this.state.tenantName !== '' && this.state.tenantName !== null) {
        this.continueFilterAction(event.option.value, this.state.tenantName);
      //}
    } else {
      this.setState({
        activeTenantData: [],
        archivedBorrowData: [],
        productNameSelected: "ALL"
      });
      this.continueFilterAction("ALL", this.state.tenantName);
    }
  }

  filterTenantList(event) {
    /*if(this.state.productNameSelected!==undefined && this.state.productNameSelected!==null
      && this.state.productNameSelected!==''){
        this.continueFilterAction(this.state.productNameSelected, event.option.value);
      }else{
        ConfigurationUtils.showErrorMessage1("please.select.product.error", this.context.intl);
      }*/
      this.continueFilterAction(this.state.productNameSelected, event.option.value);
     this.setState({tenantName: event.option.value});
     this.setState({selectedTenantValueObj: event.option});
  }
  continueFilterAction(productName, tenantName) {
   if(productName!==undefined && productName!==""){
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    FetchHelper.getTenantSpecificLicenses(productName,"ACTIVE",tenantName);
  }
}
  onActiveTableRowClicked(keys, event) {
    this.setState({
      selectedActiveKeys: keys
    });
  }

  onArchiveTableRowClicked(keys, event) {
    this.setState({
      selectedArhivedKeys: keys
    });
  }

  onActivePaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.activePagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ activePagination: paginationTemp });
    FetchHelper.getTenantSpecificLicenses(
      this.state.productNameSelected,
      "ACTIVE",
      this.state.tenantName,
      paginationTemp
    );
  };

  onArchivePaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.archivedPagination;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    FetchHelper.getTenantSpecificLicenses(
      this.state.productNameSelected,
      "ARCHIVED",
      paginationTemp
    );
  };

  returnLicense() {
    if (
      this.state.selectedActiveKeys !== undefined &&
      this.state.selectedActiveKeys.length > 0
    ) {
      var selectedKeys = this.state.selectedActiveKeys;
      var selectedTenant = this.state.tenantName;
      FetchHelper.returnTenantLicenseList(
        this.state.productNameSelected,
        selectedKeys,
        selectedTenant,
       this.state.activePagination
      );
      this.setState({
        selectedActiveKeys: []
      });
    } else {
      if (
        this.state.activeTenantData !== undefined &&
        this.state.activeTenantData.length > 0
      ) {
       
        Dispatcher.dispatch({
              type: "ALERT_SHOW_MODAL",
              payload: LocaleUtility.getLocaleLabel(
                this.context.intl,
                "label.license.borrow.alert1"
              )
        });
      }
    }
  }

  refreshState() {
    const activeRows = Store.getState().activeTenantData;
    const archiveRows = Store.getState().archivedBorrowData;
    var actPagination = Store.getState().actTenantPagination;
    var archPagination = Store.getState().archPagination;

    if (actPagination !== undefined) {
      actPagination.required = true;
      actPagination.style = "default";
      if (activeRows!==undefined && activeRows.length === 0) actPagination.totalCount = 0;
    }
    if (archPagination !== undefined) {
      archPagination.required = true;
      archPagination.style = "default";
      if (activeRows!==undefined && archiveRows.length === 0) archPagination.totalCount = 0;
    }

    if (activeRows !== undefined) {
      this.setState({
        activePagination: actPagination,
        activeTenantData: Store.getState().activeTenantData,
        activeSortIndex: Store.getState().activeSortIndex,
        activeSortAscending: Store.getState().activeSortAscending,
        //productNameSelected: Store.getState().productNameSelected,
        disableIds: Store.getState().disableIds
      });
    }
    if (archiveRows !== undefined) {
      this.setState({
        archivedPagination: archPagination,
        archivedBorrowData: Store.getState().archivedBorrowData,
        archivedSortIndex: Store.getState().archivedSortIndex,
        archivedSortAscending: Store.getState().archivedSortAscending,
        //productNameSelected: Store.getState().productNameSelected
      });
    }
    Dispatcher.dispatch({
      type: "LICENSE_FETCH_SPIN_DONE",
      payload: ""
    });
  }

  refreshProductItem() {
    this.setState({
      productNameSelectOptions: ProductStore.getState()
        .productNameSelectOptions,
      //productNameSelected: ProductStore.getState().productNameSelected,
      session: FetchHelper.getSession()
    });
    let pageRefreshCall = ProductStore.getState().pageRefreshCall;
    if(pageRefreshCall !== undefined && pageRefreshCall === true) {
      this.setState({productNameSelObj: undefined});
    }
  }

  sectionContent() {
    const options = [];
    let selectedValue = {
      value: "ALL",
      label: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.select.all"
      )
    };
    options.push(selectedValue);
    var entries = this.state.productNameSelectOptions.entries();
    let count = 0;
    for (var item of entries) {
      count++;
      options.push({ value: item[0], label: item[1] });
      if (
        this.state.productNameSelected !== undefined &&
        item[0] === this.state.productNameSelected
      ) {
        //selectedValue = { value: item[0], label: item[1] };
      }
    }
    if(this.state.productNameSelObj !== undefined) {
      selectedValue = this.state.productNameSelObj;
    }

    let returnButton;
    //let borrowLicenseBtn, removeButton;
    if ((this.state.session.admin || this.state.session.roletype === 'product') && count > 0) {
      // borrowLicenseBtn = (
      //   <div style={{ float: "left", paddingTop: "6px"}}>
      //     <Button
      //       id="lic_bor_borrowBtn"
      //       label={LocaleUtility.getLocaleLabel(
      //         this.context.intl,
      //         "label.button.tenant.license"
      //       )}
      //       href={ FrontendPagePath.LICENSE_TENANT_FORM_PATH+Utils.buildURLQueryParam()}
      //       plain={false}
      //       primary={true}
      //     />
      //   </div>
      // ); 
          
      if(this.state.tenantName === "ALL" || this.state.productNameSelected === "ALL") {
        returnButton = (<div className="button_wrap_negative">
          <Button
            id="lic_bor_returnBtn"
            label={LocaleUtility.getLocaleLabel(
              this.context.intl,
              "label.button.return"
            )}
            accent={true}
            plain={false}
            disabled={true}
          />
        </div>);
      } else if(this.state.activeTenantData.length>0){
      returnButton = (<div className="button_wrap_negative">
        <Button
          id="lic_bor_returnBtn"
          label={LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.button.return"
          )}
          accent={true}
          plain={false}
          onClick={this.returnLicense.bind(this)}
        />
      </div>);
      } else {
        returnButton = (<div className="button_wrap_negative">
        <Button
          id="lic_bor_returnBtn"
          label={LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.button.return"
          )}
          accent={true}
          plain={false}
          disabled={true}
        />
      </div>);
    }}

    return (
      <section
        id="lic_tenant_listSec" className="page_section_wrap_1"
        pad="none"
        wrap={false}
        responsive={false}
        justify="center"
      >
        <div className="action_filter_wrapper">
          <div className="action_wrap_left">
              <div>
                  <Select
                    width={24}
                    id="lic_tenant_selProduct"
                    name="productName"
                    options={options}
                    value={selectedValue}
                    onChange={this.filterLicenseList}
                    label={LocaleUtility.getLocaleLabel(
                      this.context.intl,
                      "label.select.product"
                    )+": "}
                  /> 
              </div>
              <div>
                <Select
                    width={24}
                    id="tenants-dropdown-id"
                    options={this.state.tenantNames}
                    value={this.state.selectedTenantValueObj}
                    onChange={this.filterTenantList}
                    label={LocaleUtility.getLocaleLabel(
                      this.context.intl,
                      "label.select.tenant.product"
                    )}
                  /></div>
                  </div>
              {<div>
                <SectionWithTitle
                  id="lic_bor_mainSec"
                  title={LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.manage.tenant.license"
                  )}
                  helpTopic="C_Borrow_License"
                  setionWidth = "100"
                />
              </div>}
            </div>
            <div className="page_subsection_wrap">
                <section
                  id="lic_tenant_listSec1"
                  pad="medium"
                  wrap={false}
                  responsive={false}
                  justify="center"
                >
                  <div>
                    <span className="subheader">
                      {LocaleUtility.getLocaleLabel(
                        this.context.intl,
                        "label.tenant.license.list"
                      )}
                    </span>
                  </div>
                  <TableGrid
                    id="lic_bor_borrowGrid"
                    header={[
                      {
                        columnName: "featureDesc",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.featureid.version"
                        ),
                        href: false,
                        width: "40%",
                        sort: true,
                      },
                      {
                        columnName: "capacity",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.capacity"
                        ),
                        href: false,
                        width: "10%",
                        sort: true,
                      },
                      {
                        columnName: "startDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.startDate"
                        ),
                        href: false,
                        width: "auto",
                        sort: true,
                      },
                      {
                        columnName: "expiryDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.expDate"
                        ),
                        href: false,
                        width: "auto",
                        sort: true,
                      },
                      {
                        columnName: "userName",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.tenant.col.tenantFrom"
                        ),
                        href: false,
                        width: "auto",
                        sort: true,
                      }
                    ]}
                    {...{ keys: this.state.selectedActiveKeys }}
                    rowData={this.state.activeTenantData}
                    pagination={this.state.activePagination}
                    rowSelection={3}
                    isExportRequired={false}
                    onSort={this.onSortClicked}
                    onPagination={this.onActivePaginationClicked}
                    sortIndex={this.state.sortIndex}
                    sortAscending={this.state.sortAscending}
                    rowKey="licenseSignature"
                    //disabledRowIds={this.state.disableIds}
                    uncheckTheDisabled={true}
                    onChangeCheckBoxCallback={this.onActiveTableRowClicked}
                    noDataFoundText={LocaleUtility.getLocaleMessage(
                      "no.tenant.items.found.message"
                    )}
                    selectAllRows={false}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                      "label.grid.item.count.message",
                      { count: this.state.activeTenantData.length }
                    )}
                  />
                  <div style={{ clear: "both" }} />
                  <div className="button_left_wrap">
                    {returnButton}
                     {(this.state.tenantName === "ALL" || this.state.productNameSelected === "ALL") && (
                      <div className="select_license_meg">{LocaleUtility.getLocaleMessage("lable.return.tenant.lic")}</div>
                    )}
                  </div>
                </section>
            </div>
      </section>
    );
  }

  render() {
    return (
      <div className="config-cont-backgroud">
        {this.sectionContent()}
        <div>
            <SpinningNotification centerPage={true} />
        </div>
        <div>
            <SectionWithTitle title="" helpTopic="Tenants_Manage"/>
        </div>
      </div>
    );
  }
}

// TenantLicense.contextTypes = {
//   intl: _propTypes2.default.object
// };
export default TenantLicense;
