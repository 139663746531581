import React, { Component } from "react";
import "../../../css/index.css";

import TableGrid from "../../../components/commons/table/APTable";
import { Button } from "grommet/components/Button";
import Store from "../../../flux/APTableStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import ComponentNotification from "../../generic/ComponentNotification";
import * as FetchHelper from "../../../utils/FetchHelper";

class GroupSearch extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            groupSearch: this.props.groupSearch,
            groupData:"",
            resultGroup:[],
            email:"",
            rowData: Store.getState().tableData,
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            selectAllRows: false,
            userDetail: undefined,
            selectedRowKeys:"",
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            }
           
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.closeGroupSearchButton = this.closeGroupSearchButton.bind(this);
        this.handleAddButton = this.handleAddButton.bind(this);
       
    }

    closeGroupSearchButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        this.setState({
            selectedRowKeys: []
        })
        this.props.closeGroupSearchButton();
    }


    handleAddButton(){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.state.resultGroup.length > 0){
            const formPayload = {
                resultGroup:this.state.resultGroup,
                username:this.props.username
            };

            this.props.handleAddButton(formPayload);
            this.setState({
                selectedRowKeys: []
            }) 
        } else {
            Dispatcher.dispatch({
                type: "COMP_INVALID",
                payload: LocaleUtility.getLocaleMessage("um.alert14")
            });
        }
    }
    onRowClicked(keys, event){
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
       this.setState({
        resultGroup:keys,
        username:this.props.username
       })      
    }

    infoSectionContent() {

        const showHideModalStyle = this.props.groupSearch ? "modal display-block" : "modal display-none";

           return (
            <div id="umGroupSerchResultDiv" className={showHideModalStyle}>
                <div className="modal">

                     <div className="modal-main panel panel-default" style={{maxWidth: "50%", maxHeight: "85%"}}>
                        <div className="panel-heading">
                            <h2 className="panel-title">{this.props.title}</h2>
                        </div>
                        <div style={{height: "370px", overflow: "hidden scroll"}} className="panel-body">
                        <ComponentNotification />
                        <section pad="none" wrap={false} responsive={false} justify="center">
                            <TableGrid id="group_list"
                                    header={[
                                        {
                                            columnName: "groupName",
                                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.groupname"),
                                            width: "auto",
                                            columnKey: "groupName",
                                        }
                                        
                                    ]}
                                    {...{keys:this.state.selectedRowKeys}}
                                    rowData={this.props.groupData}
                                    //pagination={this.props.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.onPaginationClicked}
                                    sortIndex={this.props.sortIndex}
                                    sortAscending={this.props.sortAscending}
                                    rowKey="groupName"
                                    onChangeCheckBoxCallback={this.onRowClicked}
                                    selectAllRows={false}
                                />
               
                        </section>
                        
                        </div>
                        <div id="umSearchResultButtonContainer" style={{ width: '30em', padding: '24px 24px'}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="button_left_wrap">
                                                <div className="button_wrap_primary">
                                                    <Button id="user_grp_addBtn" label={LocaleUtility.getLocaleMessage("label.button.add")}
                                                        type="button" plain={false} primary={true} onClick={this.handleAddButton}
                                                    />
                                                </div>
                                                <div className="button_wrap_negative">
                                                    <Button id="user_grp_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" accent={true} plain={false} onClick={this.closeGroupSearchButton} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
        
    }

    render() {
        var showContent;
        if(this.props.groupData !== undefined){
            showContent=  this.infoSectionContent();
        }
        return (
            <div>
                 {showContent}
            </div>
            
        );
    }
}

export default GroupSearch;