import ReduceStore from "./ReduceStore";

class MessageStore extends ReduceStore {
  constructor() {
    super();
    this.state = {
      message: ""
    };
  }

  reduce(action, state) {
    if (action.type.endsWith("ERROR")) {
      return { ...state, message: action.payload, status:'critical' };
    } else if (action.type.endsWith("SUCCESS_OK")) {
      return { ...state, message: action.payload, status:'normal'  };
    } else if (action.type.endsWith("CLOSE")) {
      return { ...state, message: "", status:undefined  };
    }
    return state;
  }
}

export default new MessageStore();
