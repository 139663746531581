import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { DateInput } from 'grommet/components/DateInput';
import { RadioButton } from 'grommet/components/RadioButton';
import * as DateUtility from "../../../utils/DateUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { Select } from 'grommet/components/Select';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: "",
            historyChecked: true,
            inuseChecked: false
        }
        this.findLabel = this.findLabel.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeExpDate = this.changeExpDate.bind(this);
        this.handleChangeInuse = this.handleChangeInuse.bind(this);
        this.handleChangeHistory = this.handleChangeHistory.bind(this);
    }
    changeStartDate(e) {
        this.setState({startDate: e.value});
    }
    changeExpDate(e) {
        this.setState({expDate: e.value});
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    handleChangeInuse(event) {
        this.setState({inuseChecked: true, historyChecked: false});
    }
    handleChangeHistory(event) {
        this.setState({inuseChecked: false, historyChecked: true});
    }
    render() {
        //let intl = this.context.intl;
        return(
            <div>
                <div style={{width: "100%", float: "left"}} className="action_filter_wrapper">
                    <section pad="none" wrap={false} responsive={false} justify="center">
                    <Form compact={false} id="usage_search_formId" onSubmit={this.props.searchSubmitAction} pad="small" plain={true}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <FormField id="usage_search_form_seletProdId" style={{ width: '25em' }} label= {this.findLabel("usageReportSelProduct")} >
                                            <Select id="usage_search_seletProdId" placeholder={this.findLabel("configpageIpHostSelect")}
                                                options={this.props.productNameList}
                                                value={this.props.selProductValue}
                                                onChange={this.props.selectProductName} />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField id="usage_search_form_seletProdId" style={{ width: '25em', marginRight: "24px" }} label= {this.findLabel("usageSearchFeatId")} >
                                            <TextInput id="usage_search_FeatId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchFeatVer")} >
                                            <TextInput id="usage_search_FeatVerId" />
                                        </FormField>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchFeatDesc")} >
                                            <TextInput id="usage_search_FeatDescId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchIPAddr")} >
                                            <TextInput id="usage_search_IpAddrId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchHostName")} >
                                            <TextInput id="usage_search_HostNameId" />
                                        </FormField>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchUserName")} >
                                            <TextInput id="usage_search_UserNameId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchClientId")} >
                                            <TextInput id="usage_search_ClientId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchStartDate")} >
                                            <DateInput id="usage_search_StartDateId" className="datepicker" format={DateUtility.getDateFormat()} placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})} value={this.state.startDate} onChange={this.changeStartDate} />
                                        </FormField>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchExpdate")} >
                                            <DateInput id="usage_search_ExpdateId" className="datepicker" format={DateUtility.getDateFormat()} placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})} value={this.state.expDate} onChange={this.changeExpDate} />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageSearchAppName")} >
                                            <TextInput id="usage_search_AppNameId" />
                                        </FormField>
                                    </td>
                                    <td>
                                        <FormField style={{ width: '25em' }} label= {this.findLabel("usageReportsLabel")}>
                                            <span><RadioButton id="usage_search_inuse" 
                                                checked={this.state.inuseChecked} 
                                                onChange={this.handleChangeInuse} 
                                                label={this.findLabel("inuseLabel")} /> </span>
                                            <span>
                                                <RadioButton id="usage_search_history" 
                                                    checked={this.state.historyChecked} 
                                                    onChange={this.handleChangeHistory} 
                                                    label={this.findLabel("historyLabel")} />
                                            </span>
                                        </FormField>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {this.findLabel("usageSearchNote")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="button_left_wrap">
                                            <div className="button_wrap_primary">
                                            <Button type="submit" id="usage_searchBtn" label={this.findLabel("label.button.search")} plain={false} primary={true} />
                                            </div>
                                            <span style={{padding: "5px"}}></span>
                                            <div className="button_wrap_secondary">
                                            <Button id="usage_search_backBtn" label={this.findLabel("label.button.back")} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Form>
                    </section>
                </div>
            </div>
        )
    }
}
// UsageReportSearchForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportSearchForm;