import ReduceStore from "./ReduceStore";

class ReservationManagementStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_RESERVATION_POOL_LIST": {
                return {
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    actionType: "poolmanagement",
                    pagination: action.payload.pagination,
                    poolList: action.payload.poolList,
                    poolsMap: action.payload.poolsMap,
                    defaultPoolId: action.payload.defaultPoolId,
                    showPoolMapping: action.payload.showPoolMapping
                };
            }
            case "POPULATE_RESERVATION_POOL_NAMES": {
                return {
                    actionType: "poolnames",
                    poolNames: action.payload.poolNames
                };
            }
            case "POPULATE_RESERVATION_DELETE_POOL": {
                return {
                    actionType: "deletedpool"
                };
            }
            case "POPULATE_RESERVATION_POOL_MEMBERS": {
                return {
                    actionType: "poolmembers",
                    data: action.payload.data
                };
            }
            case "ADD_DEETE_MEMBER_TO_POOL": {
                return {
                    actionType: "adddeletemember",
                    data: action.payload.data,
                    action: action.payload.action,
                    type: action.payload.type,
                    value: action.payload.value
                };
            }
            case "PROCESS_RESERVATION_PRODCUT_NAMES": {
                return {
                    actionType: "listproductnames",
                    productNameList: action.payload.productNameList
                };
            }
            case "PROCESS_RESERVATION_PRODCUT_FEATURE_LIST": {
                return {
                    actionType: "listproductfeatures",
                    //allFeatureList: action.payload.features,
                    //featureGroupMaps: action.payload.featureGroupMaps,
                    defalultSelFeature: action.payload.defalultSelFeature,
                    featureObjectsMap: action.payload.featureObjectsMap,
                    sortIndex: action.payload.sortIndex,
                    productNameList: action.payload.productNameList,
                    data: action.payload.data,
                    //groupList: action.payload.groupList
                };
            }
            case "PROCESS_RESERVATION_POOL_MAPPING_PRODUCTS": {
                return {
                    actionType: "poolMappingProducts",
                    listData : action.payload.listData,
                    groupDescription: action.payload.groupDescription
                };
            }
            case "PROCESS_RESERVATION_ASSOCIATED_POOLS": {
                return {
                    actionType: "associatedpools",
                    //poolsList : action.payload.poolsList,
                    //totalCapacity: action.payload.totalCapacity,
                    //availableCapacity: action.payload.availableCapacity,
                    sortIndex: action.payload.sortIndex,
                    //pagination: action.payload.pagination,
                    //sortOrder: action.payload.pagination.sortOrder
                    data: action.payload.data
                };
            }
            case "PROCESS_RESERVATION_ASSOCIATED_POOLS_FOR_POPUP": {
                return {
                    actionType: "associatedpopup",
                    popUpPoolsList: action.payload.poolsList,
                    sortIndex: action.payload.sortIndex,
                    pagination: action.payload.pagination,
                    sortOrder: action.payload.pagination.sortOrder
                };
            }
            case "PROCESS_RESERVATION_ASSOCIATED_DISASSOCIATE_POOL_ACTION": {
                return {
                    actionType: "associateORDisassociate",
                    data : action.payload.data
                };
            }
            case "PROCESS_RESERVATION_PRODUCT_FEATURE_SAVE": {
                return {
                    actionType: "productfeatureSave",
                    data : action.payload.data
                };
            }
            case "PROCESS_RESERVATION_PRODUCT_DISASSOCIATE": {
                return {
                    actionType: "productPoolDisassociate",
                    data : action.payload.data
                };
            }
            case "PROCESS_CREATE_POOL_SUCCESS": {
                return {
                    actionType: "createPool"
                };
            }
            case "PROCESS_PRODUCT_NAME_LIST_FOR_DROP_DOWN": {
                return {
                    actionType: "productNameList",
                    data: action.payload.data
                };
            }
            case "PROCESS_RESET_POOLS_GRID": {
                return {
                    actionType: action.payload.actionType
                };
            }
            case "REDIRECT_FEATURE_BASE_RESERVATION": {
                return {
                    actionType: "resetfeaturepools"
                };
            }
            case "PROCESS_JOB_LIST": {
                return {
                    actionType: action.payload.actionType,
                    data: action.payload.data,
                    pagination: action.payload.pagination
                };
            }
            default:
                    return state;
        }
    }
}
export default new ReservationManagementStore();