import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import LicenseUsageInfo from "../reports/LicenseUsageInfo";
import { Select } from 'grommet/components/Select';
import ColorLabel from "../reports/ColorLabel";
import * as DateUtility from "../../../utils/DateUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
import { LineChart, Line, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
//var ReactD3 = require('react-d3-components');
var d3 = require("d3-scale");
var d3time1 = require("d3-time");
var d3time = require("d3-time-format");
//var LineChart = ReactD3.LineChart;
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ConsumptionUsageGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            loadedData: false,
            licenseUsageMap: "",
            licenseUsageObj: "",
            executedByInfoObj: "",
            exportHistoryRecordSize: 0,
            graphData: [],
            prodData: [],
            showTooltip: false,
            xAxisFormat: [],
            xAxisLabel: [],
            lineColors: [],
            visualFilters: [],
            changeFilterValues: [],
            filterValues: [],
            graphDataMap: {},
            graphDataColorMap: {},
            graphStartDate: new Date(),
            graphEndDate: new Date(),
            dayscount: 1,
            yAxisMaxValue: 100,
            popupTop: "800px",
            popupLeft: "550px"
        }
        this.findLabel = this.findLabel.bind(this);
        this.setCustomMetric = this.setCustomMetric.bind(this);
        this.loadConsumptionGraphData = this.loadConsumptionGraphData.bind(this);
        this.findGraphData = this.findGraphData.bind(this);
        this.onClickLicenseUsage = this.onClickLicenseUsage.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.tooltipLine = this.tooltipLine.bind(this);
        this.changeFilterAction = this.changeFilterAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadConsumptionGraphData);       
        this.findGraphData(this.props.customMetricVal, this.props.usageStartDate, this.props.usageEndDate, this.props.hostID);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadConsumptionGraphData);      
    }
    setCustomMetric(metric, usageStartDate, usageEndDate, host) {       
        this.setState({metric: metric});
        this.findGraphData(metric, usageStartDate, usageEndDate,host);
    }
    onClickLicenseUsage(key, event) {
        let clientObj = this.state.licenseUsageMap[key];
        this.setState({licenseUsageObj: clientObj});
        this.setState({showTooltip: true});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({showTooltip: false});
    }
    findGraphData(customMetricVal, usageStartDate, usageEndDate, host) {       
            this.setState({defaultSelIds: []});         
            let params = {};
            params["featureId"] = customMetricVal; 
            params["featureVersion"] = customMetricVal;
            params["tenant"] = host;
            params["startTime"] = usageStartDate;
            params["endTime"] = usageEndDate;
            ConfigurationUtils.showLoadMask("", this.context.intl);
            FetchHelper.findHostConsumptionGraphData(this.context.intl, params, false);               
    }    
    loadConsumptionGraphData() {
        if(APTableStore.getState().pageType === "unitcons_graph") {
            let graphData = APTableStore.getState().graphData;
            if(graphData) {
                this.setState({graphData: graphData});
            }
            if(APTableStore.getState().prodGraphData) {
                this.setState({prodData: APTableStore.getState().prodGraphData});
            }
            if(APTableStore.getState().xAxisFormat) {
                this.setState({xAxisFormat: APTableStore.getState().xAxisFormat});
            }
            if(APTableStore.getState().lineColors) {
                this.setState({lineColors: APTableStore.getState().lineColors});
            }
            this.setState({graphStartDate: APTableStore.getState().graphStartDate});
            this.setState({graphEndDate: APTableStore.getState().graphEndDate});
            this.setState({dayscount: APTableStore.getState().dayscount});
            this.setState({yAxisMaxValue: APTableStore.getState().yAxisMaxValue});
            if(APTableStore.getState().visualFilters) {
                this.setState({visualFilters: APTableStore.getState().visualFilters});
                let fList = [];
                let fValues = [];
                APTableStore.getState().visualFilters.map(item =>{
                    if(item.value !== 0) {
                        fList.push({label: <ColorLabel isConsumption={true} poolName={item.label} poolNameIndicationColor={item.color}/>, value: item.value});
                        fValues.push(item.value);
                    }
                    return "";
                });
                this.setState({filterValues: fList});
                this.setState({changeFilterValues: fValues});
            }
            if(APTableStore.getState().xAxisLabel) {
               this.setState({xAxisLabel: APTableStore.getState().xAxisLabel});
            }
            if(APTableStore.getState().graphDataMap) {
                this.setState({graphDataMap: APTableStore.getState().graphDataMap});
            }
            if(APTableStore.getState().graphDataColorMap) {
                this.setState({graphDataColorMap: APTableStore.getState().graphDataColorMap});
            }
            ConfigurationUtils.hideLoadMask("", this.context.intl);
        }
    }
    tooltipLine(axisLabel, axisObject) {
        if(axisObject.y !== undefined) {
            return axisLabel+"[ "+axisObject.y+" ]";
        } else {
            return "";
        }
    }
    changeFilterAction(event) {
        let currentValues = this.state.changeFilterValues;
        let newValue = [];
        let gData = [];
        let gDataColor = [];
        let gDataMap = this.state.graphDataMap;
        let gDataColorMap = this.state.graphDataColorMap;
        this.state.changeFilterValues.map(val => {
            if(val !== event.option.value && val !== 0) {
                newValue.push(val);
                gData.push(gDataMap[val]);
                gDataColor.push(gDataColorMap[val]);
            }
            return "";
        });
        if(currentValues.indexOf(event.option.value) === -1) {
            newValue.push(event.option.value);
            gData.push(gDataMap[event.option.value]);
            gDataColor.push(gDataColorMap[event.option.value]);
        }
        newValue.push(0);
        gData.push(gDataMap[0]);
        gDataColor.push(gDataColorMap[0]);
        this.setState({changeFilterValues: newValue});
        this.setState({lineColors: gDataColor});
        this.setState({graphData: gData});
    }
    render() {
        //let intl = this.context.intl;
        let showTooltipCls = "tooltipContent tooltipContentHide";
        if(this.state.showTooltip) {
            showTooltipCls = "tooltipContent tooltipContentshow";
        }
        let colorScale = ""; //d3.scale.category20().range(this.state.lineColors);
        let yMaxValue = 100;
        if(this.state.prodData && this.state.prodData[0] && this.state.prodData[0].values[0] && 
            this.state.prodData[0].values[0].y >=0 && this.state.prodData[0].values[0].y > 100){
            yMaxValue = this.state.prodData[0].values[0].y
        }
        // let yScale = d3.scale.linear()
        //     .domain([0, yMaxValue])
        //     .range([337, 27]);
        let yScale = "";
        let xScale = d3.scaleTime().domain([this.state.graphStartDate , this.state.graphEndDate]); //d3.time.scale().domain([this.state.graphStartDate , this.state.graphEndDate]).range([0, 900]);
        let xAxisTicks = "";//xScale.ticks(1);
        let xOffset = 12;
        if(this.state.dayscount === 1) {
            xAxisTicks = xScale.ticks(24);
        } else if(this.state.dayscount <= 31) {
            xAxisTicks = xScale.ticks(this.state.dayscount);
        } else if(this.state.dayscount > 31 && this.state.dayscount < 218) {
            xAxisTicks = xScale.ticks(d3time1.timeWeek.every(1));
        } else if(this.state.dayscount >= 218 && this.state.dayscount < 931) {
            xAxisTicks = xScale.ticks(d3time1.timeMonth.every(1));
            xOffset = 22;
        } else if(this.state.dayscount >= 931) {
            xAxisTicks = xScale.ticks(d3time1.timeYear.every(1));
            xOffset = 22;
        }
        let svg; //= d3.selectAll("svg");
        // svg.selectAll(".historyXAxis text").attr("transform", "rotate(-40)");
        // svg.selectAll(".historyYAxis text").attr("transform", "rotate(0)");
        // svg.selectAll(".historyYAxis .label").attr("y", "0").attr("x", "15");
        let defatulStrokeWidth = 2;
        if(this.state.graphData !== undefined && this.state.graphData.length > 1) {
            let lineObject = this.state.graphData[0];
            if(lineObject !== undefined && lineObject.values !== undefined && lineObject.values.length === 1) {
                defatulStrokeWidth = 9;
            }
        }
        return (
            <div className="inuse-cont-backgroud">
                {this.state.graphData.length > 0 || this.state.filterValues.length > 0 ? <div style={{width: "100%", float: "left", paddingTop: "5px", marginLeft: "7px", marginRight: "6px", marginBottom: "6px"}}>
                    <div id="usage_rejected_graph_id" style={{width: "90%", float: "left", paddingTop: "5px"}}>
                        {/* <LineChart
                            data={this.state.graphData}
                            stroke={{strokeWidth: defatulStrokeWidth, strokeLinecap: "round"}}
                            yScale={yScale}
                            xScale={xScale}
                            width={900}
                            height={400}
                            colorScale={colorScale}
                            xAxis={{label: this.findLabel("configpageUMChangePasswordDays",intl), tickValues: xAxisTicks, tickFormat: d3.time.format(this.state.xAxisFormat), className: "historyXAxis"}}
                            yAxis={{label: this.findLabel("usage", intl), className: "historyYAxis"}}
                            tooltipHtml={this.tooltipLine}
                            margin={{top: 10, bottom: 50, left: 50, right: 10}}
                            tooltipMode={"element"}
                        /> */}

                        <LineChart
                            data={this.state.graphData}
                            width={900}
                            height={400}
                            // colorScale={colorScale}
                            // xAxis={{label: this.findLabel("configpageUMChangePasswordDays",intl), tickValues: xAxisTicks, tickFormat: d3.time.format(this.state.xAxisFormat), className: "historyXAxis"}}
                            // yAxis={{label: this.findLabel("usage", intl), className: "historyYAxis"}}
                            // tooltipHtml={this.tooltipLine}
                            // margin={{top: 10, bottom: 50, left: 50, right: 10}}
                            // tooltipMode={"element"}
                            margin={{top: 25, bottom: 35, left: 10, right: 10}}
                        >
                        <XAxis dataKey="x" label={{value: this.state.xAxisLabel, offset:xOffset, position:"bottom"}} 
                            className="historyXAxis" interval={0} angle={-40}
                            domain={[this.state.graphStartDate , this.state.graphEndDate]}
                            name="hour"
                            tickFormatter={d3time.timeFormat(this.state.xAxisFormat)}
                            type='number'
                            ticks={xAxisTicks}
                            minTickGap={60*60*1000}/>
                        <YAxis type="number" domain={[0, yMaxValue]} tickCount={10} tickSize={5} interval={0} className="historyYAxis" label={{value: this.findLabel("usage"), offset:12, position:"top"}}/>
                        <Tooltip />
                        {/* <Legend /> */}
                        {Object.entries(this.state.graphDataMap).map(([key, value]) => (
                            <Line dataKey="y" data={value.values} name={value.label} key={value.label} stroke={this.state.graphDataColorMap[key]} />
                        ))}
                        {/* <Line dataKey="No. of users" stroke="#000" /> */}
                        </LineChart>
                    </div>
                    {/* <div style={{width: "23%", float: "left", paddingTop: "5px", "padding-left": "70px", height: "407px", "overflow-x": "auto", "display": "inline-block"}}>
                         <div style={{"font-size": "0.875rem", "font-family": "Metric", color: "rgba(0, 0, 0, 0.56)"}}>{this.findLabel("usage.consumption.visual.filter.label", intl)}</div>
                         <div style={{"padding-left": "15px"}}>
                             {this.state.filterValues.length > 0 ?
                                <Select id="usage_legendsId"
                                    options={this.state.filterValues}
                                    value={this.state.changeFilterValues}
                                    onChange={this.changeFilterAction}
                                    multiple={true}
                                    inline={true}
                                />
                                : null }
                        </div>
                    </div> */}
                </div>
                 : 
                 <div style={{width: "100%", float: "left", height: "200px", "text-align": "center", border: "1px solid #ccc"}}>
                    <div style={{paddingTop: "70px"}}>
                        <span id="no_data_found_for_rejected_graph_id" style={{paddingTop: "50px", "font-size": "26px", "color": "rgb(180, 180, 180)"}}>{this.findLabel("usage.graph.history.show.msg2")}
                        </span>
                    </div>
                </div> }
                <div className="tooltipMain">
                    <div id="rejected_license_info_id" style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={showTooltipCls}>
                        <LicenseUsageInfo 
                            operation = {this.state.licenseUsageObj.operation}
                            repUsage = {this.state.licenseUsageObj.repUsage}
                            closeTootipAction={this.closeTootipAction}
                        />
                    </div>
                </div>                
               
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// ConsumptionUsageGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ConsumptionUsageGraph;