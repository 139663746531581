
import React, { Component } from "react";
import { Anchor } from 'grommet/components/Anchor';
import { Help as HelpIcon } from 'grommet-icons';
import * as Util from "../../utils/Util.js";
import * as FetchHelper from "../../utils/FetchHelper";

class HelpLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            sectionContent: "",
            session: undefined
        };
        this.openHelp = this.openHelp.bind(this);
    }
    componentDidMount() {
        this.setState({session: FetchHelper.getSession()});
    }
    openHelp() {
        let version = "";
        if(this.state.session !== undefined && this.state.session.helpDocLinkAppVersion !== undefined) {
            version = this.state.session.helpDocLinkAppVersion;
        }
        let helpTopicValue = localStorage.getItem("helpTopic");
        if(helpTopicValue !== undefined && helpTopicValue !== "") {
            Util.gethelp(helpTopicValue, version);
        }
        return false;
    }
    render() {
        return(
            <Anchor>
                <div className="helpIconCls">
                    <HelpIcon id="help-icon-id" colorIndex="neutral-5" strokeWidth="3" onClick={this.openHelp}/>
                </div>
            </Anchor>
        )
    }
}
export default HelpLink;