import React, { Component } from "react";
import { DataSearch as Search } from 'grommet/components/DataSearch';
import { Add as AddIcon } from 'grommet-icons';
import { CheckBox } from "grommet/components/CheckBox";
import { TextInput } from 'grommet/components/TextInput';
import * as LocaleUtility from "../../utils/LocaleUtility";
import * as DateUtility from "../../utils/DateUtility";
import {validateSpecialChars, isNumber} from "../../utils/ValidationUtility";
import * as FetchHelper from "../../utils/FetchHelper";
import Store from "../../flux/GenericUsageStore";
import Select from "../commons/APLSSelect";
import DateRange from "./DateRange";
import DatesPopup from "./DatesPopup";
import { useIntl } from 'react-intl';
import { Data } from "grommet/components";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ColumnFilter extends Component {
  constructor(props) {
      super(props);
      this.state = {
        closeDropDown: false,
        compWidth: "auto",
        options: [],
        isSearchAction: false,
        searchedValue: '',
        multiSelect: false,
        multiSelectedValues: [],
        multiSelectedLabels: [],
        filters: [],
        fields: [],
        value: {value:"", label:""},
        selectedOption: undefined,
        datatype: undefined,
        selectedFName: undefined,
        showModal: false,
        usageStartDate: "",
        usageEndDate: "",
        selDateRangeVal: "",
        datesErrorMessage: "",
        displayValue: undefined,
        enabledSearch: true,
        filterValueError:"",
        label: LocaleUtility.getLocaleMessage("usage.report.add.filter.label"),
        selectedFilter:{
                    value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")
                },
        filtermap: new Map([["string", [
                    {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")}
                ]], ["numeric", [
                    {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")},
                    {value: "greaterthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.greaterthan.label")},
                    {value: "lesserthan", label:LocaleUtility.getLocaleMessage("usage.select.filter.lesserthan.label")}
                ]]]),
        dataTypes: [
                {value: "string", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.string.label")},
                {value: "int", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.int.label")},
                {value: "bigint", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.bigint.label")},
                {value: "precision", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.dprecision.label")},
                {value: "datetime", label:LocaleUtility.getLocaleMessage("usage.select.fdtype.date.label")}
            ]
      };
      this.wrapperRef = React.createRef();
      this.dateRef = React.createRef();
      this.showOptions = this.showOptions.bind(this);
      this.onOptionsSelect = this.onOptionsSelect.bind(this);
      this.handleMouseOutside = this.handleMouseOutside.bind(this);
      this.onSearchAction = this.onSearchAction.bind(this);
      this.handleScrollAction = this.handleScrollAction.bind(this);
      this.checkAction = this.checkAction.bind(this);
      this.cancelAction = this.cancelAction.bind(this);
      this.buildMultiSelectdValues = this.buildMultiSelectdValues.bind(this);
      this.okAction = this.okAction.bind(this);
      this.findLabel = this.findLabel.bind(this);
      this.clearAction = this.clearAction.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.populateDates = this.populateDates.bind(this);
      this.selectDateRange = this.selectDateRange.bind(this);
      this.loadFieldVal = this.loadFieldVal.bind(this);
      this.handleChangeFilterValue = this.handleChangeFilterValue.bind(this);
      this.datesCancelAction = this.datesCancelAction.bind(this);
      this.datesOkAction = this.datesOkAction.bind(this);
      this.onSelectDateRange = this.onSelectDateRange.bind(this);
      this.onChangeFieldFilterType = this.onChangeFieldFilterType.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    var map = new Map(); //, field, upFilterArr=[];
    if(this.props.fieldSet !== undefined){
        for (let [key, value] of this.props.fieldSet) { 
            // field = this.props.reportFields.filter(function(itm){
            //     return itm.name === value.name;
            // });

            // if(field !== undefined && field[0] !== undefined){
            //     map.set(value.name, field[0].dataType);
            //     if(field[0].dataType !== value.dataType && this.state.filters.length > 0){
            //         upFilterArr = this.state.filters.filter(function(itm){
            //             return (itm.indexOf(value.name+"=") === -1);
            //         });
            //         //this.updateElements(upFilterArr);
            //     }
            // } else {
                map.set(value.name, value.dataType);
            // }
        }
    }

    let fields = this.props.fieldOptions.filter(function(itm){
        return itm.value !== "null";
    });
    this.setState({options: fields, fieldMap: map, fields: fields,
            filtertypes: this.state.filtermap.get('string')});
    Store.on("change", this.loadFieldVal);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
      Store.removeListener("change", this.loadFieldVal);
  }

//   componentDidUpdate(prevProps, prevState) {
//     if((prevState.value !== undefined && this.state.value !== undefined &&
//          prevState.value !== this.state.value)){
//         this.buildMultiSelectdValues();
//     }
//   }

  findLabel(key, intl) {
    return LocaleUtility.getLocaleMessage(key);
  }
  showOptions(event) {
    let filters = this.props.filters !== undefined ? this.props.filters : [];
    this.setState({closeDropDown: true, compWidth: this.props.width+"rem", searchedValue: '', enabledSearch: true,
                  isSearchAction: false, label:LocaleUtility.getLocaleMessage("usage.report.select.fields.label"), options: this.state.fields, filters: filters, displayValue: undefined,
                  selectedOption: undefined, multiSelect: false, datatype: undefined, selectedFName: undefined, multiSelectedValues: [], type: undefined});
  }
  onOptionsSelect(event, option) {
    event.option = option;
    let type, datatype = this.state.datatype, selectOpt = this.state.selectedOption, search = true;
    if(event.option.value !== ''){
        if(datatype === undefined){
            datatype = this.state.fieldMap.get(event.option.value);
            selectOpt = 'datatype';
            this.setState({selectedFName: event.option, addFieldError:"", selectedOption: selectOpt,
                    options: this.state.dataTypes, datatype: datatype, selectedFName: event.option.value,
                    label: LocaleUtility.getLocaleMessage("usage.report.select.fields.type.label") + " : " + event.option.value
                });
        } else if(selectOpt === 'datatype'){
            datatype = event.option.value;
            if(datatype === 'string'){
                type = "string";
                FetchHelper.getCustomPropValue(this.state.selectedFName, this.props.productId);
            } else {
                search = false;
                if(datatype === 'datetime'){
                    type = "datetime";
                    this.populateDates(29, 3, true);
                } else 
                    type = "numeric";
            }
            this.setState({addFieldError:"", selectedOption: selectOpt,
                    type: type, selectedFDataType: datatype[0], enabledSearch: search,
                    filtertypes: this.state.filtermap.get(type), options: [], filterValueError:"",
                    label: LocaleUtility.getLocaleMessage("usage.report.fields.filter.label")+ " : " + this.state.selectedFName,
                    value: { value: "", label:LocaleUtility.getLocaleMessage("label.select.none")
                }});
        }
        
    }
    //this.setState({closeDropDown: false, compWidth: "auto"});
    //this.props.onChange(event);
  }
  checkAction(value, label, index, event) {
    let vList = this.state.multiSelectedValues;
    let currentValue = event.target.checked;
    let lList = this.state.multiSelectedLabels;
    if(currentValue === false) {
      vList = this.state.multiSelectedValues.filter(x=> x !== value);
      lList = this.state.multiSelectedLabels.filter(x=> x !== label);
    } else {
      if(this.state.multiSelectedValues.indexOf(value) < 0) {
        vList.push(value);
      }
      if(this.state.multiSelectedLabels.indexOf(label) < 0) {
        lList.push(label);
      }
    }
    this.setState({value: {value:vList.join(",")}});
    this.setState({multiSelectedValues: vList, multiSelectedLabels: lList, value: {value:vList.join(",")}});
  }
  handleMouseOutside() {
    if(!(this.props.disableMouseOut !== undefined && this.props.disableMouseOut) && this.state.closeDropDown && ((this.state.multiSelect !== undefined && this.state.multiSelect === false)
      || this.state.multiSelect === undefined)) {
      this.setState({closeDropDown: false, compWidth: "auto"});
    }
  }
  cancelAction(event) {
    //this.buildMultiSelectdValues();
    this.setState({closeDropDown: false, compWidth: "auto", filterValue: "", 
    label: LocaleUtility.getLocaleMessage("usage.report.add.filter.label")});
  }
  clearAction() {
    this.setState({multiSelectedValues: [], multiSelectedLabels: [], filterValue: "", filterValueError:"",
  selectedFilter:{value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")}});
  }
 
  handleScrollAction() {
    this.handleMouseOutside();
  }
  onSearchAction(event) {
    let value = event.target.value;
    let options = this.state.fields;
    if(value !== undefined && value !== '') {
      let opts = [];
      if(options !== undefined && options.length > 0) {
        options.map((object, index)=>{
          let compValue = object.label.toLowerCase();
          if(compValue.indexOf(value.toLowerCase()) > -1) {
            opts.push(object);
          }
          return "";
        });
        this.setState({options: opts, isSearchAction: true, searchedValue: value});
      }
    } else {
      this.setState({isSearchAction: false, searchedValue: value, options: options});
    }
  }
  buildMultiSelectdValues() {
    let compSelectedValue = this.state.value;
    let multiCompSelectedValues = [];
    let multiCompSelectedLabels = [];
    if(this.state.multiSelect !== undefined && this.state.multiSelect === true) {
      if(compSelectedValue !== undefined && compSelectedValue.value !== undefined) {
        let vArray = compSelectedValue.value.split(",");
        let options = this.state.options;
        for(let i=0; i<vArray.length;i++) {
          multiCompSelectedValues.push(vArray[i]);
          if(options !== undefined) {
            options.filter(object=>object.value === vArray[i]).map((object, index)=> 
              multiCompSelectedLabels.push(object.label)
            )
          }
        }
        this.setState({multiSelectedValues: multiCompSelectedValues, multiSelectedLabels: multiCompSelectedLabels});
      }
    }
  }

  populateDates(noOfdays, opt, flag) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        if(opt === 0) {
            previousDate = DateUtility.convertToServerTimeZone(previousDate);
        }
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        if(opt === 1) {
            this.setState({usageEndDate: DateUtility.getDate(previousDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(previousDate));
            }
        } else {
            this.setState({usageEndDate: DateUtility.getDate(currentDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(currentDate));
            }
        }
        let v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate);
        if(!flag) {
            v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(previousDate);
            this.setState({selDateRangeVal: {"label": LocaleUtility.getLocaleMessage("label.date.today"), "value": v}});
        }
        this.setState({displayValue: v});
    }

    selectDateRange(usageStartDate, usageEndDate) {
        this.setState({usageStartDate: usageStartDate});
        this.setState({usageEndDate: usageEndDate})
    }

    loadFieldVal(){
        let type = Store.getState().type, colValueOptions=[];
        if(type === 'fieldName'){
            let fieldVal = Store.getState().fieldVal;
            fieldVal.map(itm => {
                colValueOptions.push({value: itm, label:itm});
                return "";
            });
            this.setState({options: colValueOptions, multiSelect: true, selectedOption: 'multiselect'});
            //this.buildMultiSelectdValues();
        }
    }
  /**
   * If clicked outside of dropdown element
   */
  handleClickOutside(event) {
      if (
          this.state.closeDropDown && !this.state.showModal && this.wrapperRef && !this.wrapperRef.current.contains(event.target) 
          ) {
          this.setState({closeDropDown: false, compWidth: "auto",
            label: LocaleUtility.getLocaleMessage("usage.report.add.filter.label"),});
      }
  }

    onSelectDateRange(event) {
        let v = event.option.value;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            this.setState({displayValue: v});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            this.selectDateRange(val[0], val[1]);
        }
    }

    datesCancelAction() {
        this.setState({showModal: false});
    }

    datesOkAction(event, s) {
        let sDate = s.fromDate;
        let eDate = s.toDate;
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let fDate = new Date(sDate).getTime();
            let tDate = new Date(eDate).getTime();
            if(tDate < fDate) {
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                this.setState({displayValue: sDate+" - "+eDate});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate})
                this.selectDateRange(sDate, eDate);
                this.setState({showModal: false});
            }
        } else {
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }

    handleChangeFilterValue(event) {
        let msg = "", fieldfilter;
        if(!validateSpecialChars(event.target.value)) {
          msg = LocaleUtility.getLocaleMessage("enter.valid.chars.for.text");
        } else if(!isNumber(event.target.value)) {
          msg = LocaleUtility.getLocaleMessage("label.borrow.license.form.alert1");
        } else if(event.target.value !== ''){
          fieldfilter= this.state.selectedFilter.value+":"+event.target.value
        }
        this.setState({filterValue: event.target.value, 
                        fieldfilter: fieldfilter,
                        filterValueError: msg, addFieldError:""});
    }

    onChangeFieldFilterType(event){
        let fieldfilter, label = this.state.selectedFName;
        if(this.state.filterValue !== undefined && this.state.filterValue !== '' &&
            event.option.value !== undefined && event.option.value !== ''){
            if(event.option.value  === 'equalto'){
              label+=" = "+this.state.filterValue;
            }else if(event.option.value  === 'greaterthan'){
              label+=" > "+this.state.filterValue;
            }else if(event.option.value  === 'lesserthan'){
              label+=" < "+this.state.filterValue;
            }
            fieldfilter = event.option.value+":"+this.state.filterValue;
        }
        this.setState({fieldfilter: fieldfilter, addFieldError:"", selectedFilter: event.option,
            label: LocaleUtility.getLocaleMessage("usage.report.fields.filter.label")+ " : " + label});
    }

    okAction(event) {
        let str = "", fieldArrayOption = [], flag=true, msg, type;
        let defVal = {value: "", label:LocaleUtility.getLocaleMessage("label.select.none")}
        let defEVal = {value: "equalto", label:LocaleUtility.getLocaleMessage("usage.select.filter.equal.label")}
        if(this.state.filterValueError === '' && this.state.selectedFName !== undefined && this.state.selectedFName !== '' &&
         (this.state.fieldfilter !== undefined || this.state.displayValue !== undefined || 
            (this.state.value !== undefined && this.state.value.value !== ''))){
            if(this.state.value !== undefined && this.state.value.value !== ''){
                str = this.state.selectedFName +"="+ this.state.value.value.replace(/(^\s*,)|(,\s*$)/g, '');
            } else if(this.state.fieldfilter !== undefined){
                str = this.state.selectedFName +"="+ this.state.fieldfilter
            } else {
                str = this.state.selectedFName +"="+this.state.displayValue.replace(" - ", " --- ");
            }
            this.state.filters.map(itm => {
                type = this.state.fieldMap.get(this.state.selectedFName);
                if(itm.indexOf(this.state.selectedFName +"=") > -1){
                    if(type === 'string' || type === 'datetime' || 
                        itm.indexOf(this.state.fieldfilter.split(":")[0]) > -1){
                        fieldArrayOption.push(str);
                        flag = false;
                    } else {
                        fieldArrayOption.push(itm);
                    }
                } else {
                   fieldArrayOption.push(itm);
                }
                return "";
            });
            if(flag)
                fieldArrayOption.push(str);
            this.props.updateAction(fieldArrayOption);
            this.setState({value: defVal, fieldfilter: undefined, type: "", selectedFDataType: defVal,
                        closeDropDown: false, compWidth: "auto", filters: fieldArrayOption,
                        label: LocaleUtility.getLocaleMessage("usage.report.add.filter.label"),
                        filterValue : "", selectedFName:defVal, selectedFilter: defEVal});
        } else {
            if(this.state.type === "numeric" && this.state.filterValueError === ''){
                msg = LocaleUtility.getLocaleMessage("usage.report.filter.alert3");
                this.setState({filterValueError : msg});
            }
        }
    }

  render() {
    let intl = this.context.intl;
    let width = "22rem";
    let serachWidth="22rem";
    let compWidth = this.state.compWidth;
    let options = this.state.options;
    let displayValue = "block";
    let multiSelect = false;
    let mainStyle = this.props.style;
    let compSelectedValue = this.state.value;
    if(this.state.isSearchAction || this.state.selectedOption !== undefined) {
      options = this.state.options;
    }
    let dropDownClassName = "filter-dropdown select-options-div display-none";
    if(this.state.closeDropDown) {
      dropDownClassName = "filter-dropdown select-options-div display-block";
      displayValue = "none";
    }
    if(this.props.width !== undefined) {
      width = this.props.width+"rem";
      serachWidth = this.props.width+"rem"; //(Number(this.props.width)-0.5)+"rem";
    }

    if(this.state.multiSelect !== undefined && this.state.multiSelect === true) {
      multiSelect = true;
    }
    let newItems =  Object.assign([], this.state.multiSelectedValues);

    let parentDivSty = {}, selectListSty = {maxHeight: "300px"}, multiBtnSty;
    let defaultSty = { width: "max-content", overflow: "auto", marginTop: "2px", marginLeft: "0px"};
    if(multiSelect){
      parentDivSty = {position:"relative", width:"100%"};
      selectListSty = {maxHeight: "300px", overflow: "auto", position: "relative"};
      multiBtnSty = { bottom: "0"};
    }

    if(this.state.closeDropDown){
      let screen = window.screen.availHeight; //document.documentElement.scrollHeight + 100;
      let eleHeight = 20;
      let topOffset = this.wrapperRef.current.getBoundingClientRect().top;// + window.pageYOffset;
      if((topOffset > 0 && topOffset > (screen * 0.625))){
          defaultSty = {width: "max-content", overflow: "auto", bottom: eleHeight}
      } else if(window.pageYOffset > 0){
        eleHeight = (window.pageYOffset - 22)
        defaultSty = {width: "max-content", overflow: "auto"}
      }
    }

    let selFError, selFieldError;
    if(this.state.filterValueError !== ''){
        selFError =<span className="grommetux-form-field__error" style={{position:"relative", width:"20em", left:"-60px"}}>
        {this.state.filterValueError}</span>;
        selFieldError='grommetux-form-field-nozindex--error';
    } 
    return(
      <div ref={this.wrapperRef} id="filter-component-id" style={mainStyle}>
        <div style={{width: "auto", minWidth: compWidth}} className="select-drop-down">
          <div id="filter-div" className="dropdown_select" onClick={event=>this.showOptions(event)}>
            <div style={{float: "left"}}>
              <span id="filter-component-label-id" style={{color: "rgba(0, 0, 0, 0.56)", float: "left"}}>{this.state.label}</span>
              {/* <span id="select-component-value-id" style={{float: "left", paddingLeft: "4px", display: displayValue}}>{compSelectedValue !== undefined && compSelectedValue !== null && compSelectedValue !== 'null' ? compSelectedValue.label : ""}</span> */}
            </div>
            <span style={{padding: "5px"}}></span>
            <div style={{float: "right", display: displayValue}}>
              <span><AddIcon colorIndex="brand"/></span>
            </div>
          </div>
          <div id="filter-option-div-id" style={defaultSty} className={dropDownClassName}>
            <div className="grommetux-select__drop" style={parentDivSty}>
              {this.state.enabledSearch && (<Data><Search placeholder={LocaleUtility.getLocaleMessage("label.type.to.search")}
                inline={true}
                // dropdown={false}
                responsive={false}
                size='small'
                dropAlign={{"right": "right"}}
                value={this.state.searchedValue}
                onChange={event=>this.onSearchAction(event)} 
                id="filter-search-id"
              /></Data>)}

                {this.state.type === 'numeric' && (
                    <div className={selFieldError} style={{maxHeight: "300px"}}>
                    <Select  width={18} id="rep-field-filter" label={LocaleUtility.getLocaleMessage("usage.report.select.fields.filter.label")}
                            options={this.state.filtertypes}
                            value={this.state.selectedFilter}
                            disabledScroll = {false}
                            onChange={this.onChangeFieldFilterType.bind(this)} />
                    <TextInput id="filter-value" placeholder={LocaleUtility.getLocaleMessage("usage.filter.value.numeric")}
                        value={this.state.filterValue} 
                        onChange={event =>this.handleChangeFilterValue(event)}
                    />
                    {selFError}
                    </div>
                )}

                {this.state.type === 'datetime' && (
                    <div style={selectListSty}>
                      {this.state.showModal ? <DatesPopup ref={this.dateRef}
                      cancelAction = {this.datesCancelAction}
                      startDateLabel = {LocaleUtility.getLocaleMessage("usage.graph.from.label")}
                      endDateLabel = {LocaleUtility.getLocaleMessage("usage.graph.to.label")}
                      showModal = {this.state.showModal}
                      fromDate = {this.state.usageStartDate}
                      toDate = {this.state.usageEndDate}
                      errorMessage = {this.state.datesErrorMessage}
                      okAction={this.datesOkAction} /> : 
                      <DateRange 
                        label = {LocaleUtility.getLocaleMessage("usage.graph.daterange.label")+" "}
                        onChange = {this.onSelectDateRange}
                        value = {this.state.selDateRangeVal}
                        displayValue = {this.state.displayValue}
                        defaultValue = {3}
                        renderFor = "history"
                    />
                    }
                    </div>
                )}
                {options !== undefined && options.length > 0 && (
                    this.state.selectedOption === undefined || this.state.selectedOption !== 'multiselect' ? 
                    (
                        <ol className="grommetux-select__options" style={selectListSty}>
                            {options.map((obj, index)=> 
                                <li key={index} className={compSelectedValue !== undefined && compSelectedValue.label === obj.label ? "grommetux-select__option grommetux-select__option--selected" : "grommetux-select__option"} onClick={event=>this.onOptionsSelect(event, obj)}>{obj.label}</li>
                            )}
                        </ol>
                    ) 
                :
                    (
                        <ol className="grommetux-select__options" style={selectListSty}>
                            {options.map((object, index) => 
                             <li key={object.value} className="multi-slection-options-li">
                            <CheckBox class
                                checked={newItems.includes(object.value)}
                                label={object.label}
                                onChange={this.checkAction.bind(this, object.value, object.label, index)} />
                            </li>
                            )}
                        </ol>
                    )        
                )}
              
              {(this.state.selectedOption === 'datatype' || this.state.selectedOption === 'multiselect') &&
                this.state.type !== undefined && (
                <div className="multi-selection-options-buttons" style={multiBtnSty}>
                  <span style={{marginRight: "6px"}} className="grommetux-button grommetux-button--primary" onClick={event=>this.okAction(event)}>{this.findLabel("label.button.ok", intl)}</span>
                  <span style={{marginRight: "6px"}} className="grommetux-button grommetux-button--secondary" onClick={event=>this.clearAction()}>{this.findLabel("label.button.clean", intl)}</span>
                  <span className="grommetux-button grommetux-button--secondary" onClick={event=>this.cancelAction()}>{this.findLabel("label.button.cancel", intl)}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
// ColumnFilter.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ColumnFilter;