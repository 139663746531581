import React, { Component } from "react";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import SectionWithTitle from "../../generic/SectionWithTitle";
import store from "../../../flux/MultiTenantStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import TableGrid from "../../../components/commons/table/APTable";
import { Button } from "grommet/components/Button";
import AddTenant from "./AddTenant";
import { Send as SendIcon } from 'grommet-icons';
import SpinningNotification from '../../generic/SpinningNotification';
import { Trash as TrashIcon } from 'grommet-icons';
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as DateUtils from "../../../utils/DateUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import Dispatcher from "../../../flux/Dispatcher";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class MultiTenants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "id",
                sortOrder: "DESC",
                editObject: undefined,
                activateObj: undefined
            },
            showAddTenant: false,
            selectedObj: undefined,
            mailConfigured: false
        };
        this.loadGridData = this.loadGridData.bind(this);
        this.showAddTenantFn = this.showAddTenantFn.bind(this);
        this.closeCreateTenantPopup = this.closeCreateTenantPopup.bind(this);
        this.createTenantAction = this.createTenantAction.bind(this);
        this.onClickTenantName = this.onClickTenantName.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.findData = this.findData.bind(this);
        this.onClickSendIcon = this.onClickSendIcon.bind(this);
        this.continueClickAction = this.continueClickAction.bind(this);
        this.onConfirmTenantDelete = this.onConfirmTenantDelete.bind(this);
        this.exportTenants = this.exportTenants.bind(this);
        this.retryTenantMigration = this.retryTenantMigration.bind(this);
        this.onClickStatusCol = this.onClickStatusCol.bind(this);
        this.continueIncativeOrActiveAction = this.continueIncativeOrActiveAction.bind(this);
    }
    componentDidMount() {
        store.on("change", this.loadGridData);
        this.findData(this.state.pagination);
    }
    componentWillUnmount() {
        store.removeListener("change", this.loadGridData);
    }
    onPaginationClicked(pageNumber, pageSize) {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp});
        this.findData(paginationTemp);
    }
    findData(pagination) {
        let request = {
            pagination: pagination
        }
        FetchHelper.listTenantNames(request, this.context.intl);
    }
    retryTenantMigration() {
        FetchHelper.tenantMigrationRetry(this.context.intl);
    }
    loadGridData() {
        if(store.getState().actionType === 'listtenants') {
            let data = store.getState().gridData;
            this.setState({mailConfigured: data.mailConfigured});
            if(data !== undefined) {
                let pagination = data.pagination;
                if(pagination !== undefined) {
                    pagination.required = true;
                    pagination.style = "default";
                } else {
                    pagination = this.state.pagination;
                }
                if(data.tenant !== undefined) {
                    data.tenant.map(obj=>{
                        let statusMsg = LocaleUtility.getLocaleMessage("tenant.status."+obj.status);
                        if(Number(obj.status) === 3 || Number(obj.status) === 4) {
                            let titleTxt = LocaleUtility.getLocaleMessage("tenant.inactive.tooltip");
                            if(Number(obj.status) === 4) {
                                titleTxt = LocaleUtility.getLocaleMessage("tenant.active.tooltip");
                            }
                            obj.statusStr = <a title={titleTxt} onClick={event=>this.onClickStatusCol(obj.id)} class="StyledAnchor cursor-pointer" >{statusMsg}</a>
                        } else {
                            obj.statusStr = statusMsg;
                        }
                        if(Number(obj.status) === 3) {
                            obj.generateIcon = <a title={LocaleUtility.getLocaleMessage("tenant.send.icon.tooltip")}><SendIcon colorIndex="brand" /></a>
                        } else if(Number(obj.status) === 1 || Number(obj.status) === 4) {
                            obj.generateIcon = <a title={LocaleUtility.getLocaleMessage("tenant.delete.icon.tooltip")}><TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/></a>
                        }
                        let migrationStatus = "";
                        if(obj.migrationStatus === "I") {
                            migrationStatus = LocaleUtility.getLocaleMessage("label.status.inprogress");
                        } else if(obj.migrationStatus === "Y") {
                            migrationStatus = LocaleUtility.getLocaleMessage("label.status.completed");
                        } else if(obj.migrationStatus === "N") {
                            migrationStatus = LocaleUtility.getLocaleMessage("label.status.failed");
                        } else if(obj.migrationStatus === "P") {
                            migrationStatus = LocaleUtility.getLocaleMessage("label.status.pending");
                        }
                        if(obj.migrationStartTime !== undefined) {
                            obj.migrationStartTime = DateUtils.getFormatedDate(new Date(obj.migrationStartTime));
                        }
                        obj.migrationStatusStr = migrationStatus;
                        return "";
                    });
                }
                this.setState({rowData: data.tenant, pagination: pagination});
            }
        }
    }
    showAddTenantFn() {
        if(this.state.mailConfigured !== undefined && this.state.mailConfigured === "Y"){
            this.setState({showAddTenant: true});
        } else {
            var payload = {
                title: LocaleUtility.getLocaleMessage("um.createusertitle"),
                content: LocaleUtility.getLocaleMessage("tenant.createUsermailnotconfiguredmessage"),
            }
            Dispatcher.dispatch({
                type: "MAIL_SERVER_NOT_CONF_ALERT_SHOW_TITLE_MODAL",
                payload: payload
            });
        }
    }
    closeCreateTenantPopup() {
        this.setState({showAddTenant: false, editObject: undefined});
    }
    createTenantAction(data) {
        let actions = 0;
        let status = 1;
        if(this.state.editObject !== undefined) {
            status = Number(data.status);
        }
        if(data.generateDataSource === true) {
            actions = 1;
            status = 2;
        }
        let addrequest = {
            id: data.id,
            name: data.name,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            schemaName: data.schemaName,
            status: status,
            action: actions,
            pagination: this.state.pagination
        }
        FetchHelper.createTenantName(addrequest, this.context.intl);
        this.closeCreateTenantPopup();
    }
    onClickTenantName(id) {
        if(this.state.rowData !== undefined) {
            this.state.rowData.map(obj=>{
                if(id === obj.id) {
                    this.setState({editObject: obj});
                }
                return "";
            });
            this.setState({showAddTenant: true});
        }
    }
    onClickStatusCol(id) {
        if(this.state.rowData !== undefined) {
            this.state.rowData.map(obj=>{
                if(id === obj.id) {
                    this.setState({activateObj: obj});
                    let okayLabel = LocaleUtility.getLocaleMessage("label.button.proceed");
                    if(Number(obj.status) === 3) {
                        ValidationUtility.confirm(LocaleUtility.getLocaleMessage("tenant.inactive.warn"), this.continueIncativeOrActiveAction, undefined, okayLabel, "");
                    } else {
                        ValidationUtility.confirm(LocaleUtility.getLocaleMessage("tenant.active.warn"), this.continueIncativeOrActiveAction, undefined, okayLabel, "");
                    }
                }
                return "";
            });
        }
    }
    continueIncativeOrActiveAction(flag) {
        if(flag === 1) {
            let actions = 4;
            let status = 1;
            let data = this.state.activateObj;
            if(Number(data.status) === 3) {
                actions = 5;
            }
            if(data !== undefined) {
                status = Number(data.status);
            }
            let addrequest = {
                id: data.id,
                name: data.name,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                schemaName: data.schemaName,
                status: status,
                action: actions,
                pagination: this.state.pagination,
                forceInactive: true
            }
            FetchHelper.createTenantName(addrequest, this.context.intl);
        }
    }
    onClickSendIcon(id) {
        if(this.state.rowData !== undefined) {
            this.state.rowData.map(obj=>{
                if(id === obj.id) {
                    if(Number(obj.status) === 3) {
                        this.continueClickAction(2, obj);
                    } else if(Number(obj.status) === 1) {
                        this.setState({selectedObj: obj});
                        ValidationUtility.confirm(LocaleUtility.getLocaleMessage("tenant.delete.warn"), this.onConfirmTenantDelete, undefined, "", "");
                    } else if(Number(obj.status) === 4) {
                        this.setState({selectedObj: obj});
                        ValidationUtility.confirm(LocaleUtility.getLocaleMessage("tenant.delete.warn"), this.onConfirmTenantDelete, undefined, "", "");
                    }
                }
                return "";
            });
        }
    }
    continueClickAction(action, obj) {
        let addrequest = {
            id: obj.id,
            name: obj.name,
            firstName: obj.firstName,
            lastName: obj.lastName,
            email: obj.email,
            schemaName: obj.schemaName,
            status: obj.status,
            action: action,
            pagination: this.state.pagination
        }
        FetchHelper.createTenantName(addrequest, this.context.intl);
    }
    onConfirmTenantDelete(flag) {
        if(flag === 1 && this.state.selectedObj !== undefined) {
            this.continueClickAction(3, this.state.selectedObj);
            this.setState({selectedObj: undefined});
        }
    }
    exportTenants(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        FetchHelper.downloadTenantsList(this.context.intl, mimetype);
    }
    render() {
        return(
            <div className="config-cont-backgroud">
                <div className="action_filter_wrapper">
                <div id="tenants_list_buttons_div" className="button_left_wrap" >
                    <div id="create-tenant-btn-div" className="button_wrap_primary">
                        <Button id= "create-tenant-btn-id" label={LocaleUtility.getLocaleMessage("label.button.create.tenant")} plain={false} primary={true} onClick={this.showAddTenantFn} />
                    </div>
                    <div id="tenant-migration-btn-div" className="button_wrap_primary"
                        toolTipText={LocaleUtility.getLocaleMessage("mirgate.tenant.button.tooltip")}>
                        <Button id= "tenant-migration-btn-id" label={LocaleUtility.getLocaleMessage("label.button.tenant.retry.migration")} plain={false} primary={true} onClick={this.retryTenantMigration} />
                    </div>
                    </div>
                    <div>
                    {this.state.rowData !== undefined && this.state.rowData.length > 0 &&(
                        <div id="export-tenants-icon-div" style={{float: "right", marginRight: "10px"}}>
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportTenants}><DocumentDownloadIcon id="tenants-export-icon" colorIndex="brand"/></a>
                        </div>
                    )}
                </div>
                </div>
                <div id="tenants_list_grid_div">
                    <TableGrid id="tenants_list_grid"
                        header={[
                            {
                                columnName: "name",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.name"),
                                width: "13%",
                                columnKey: "id",
                                href: true,
                                onClick: this.onClickTenantName,
                                sort: true
                            },
                            {
                                columnName: "firstName",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.first.name"),
                                href: false,
                                width: "auto",
                                sort: true
                            },
                            {
                                columnName: "lastName",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.last.name"),
                                href: false,
                                width: "auto",
                                sort: true
                            },
                            {
                                columnName: "email",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.email"),
                                href: false,
                                width: "auto",
                                sort: true
                            },
                            {
                                columnName: "schemaName",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.schema.name"),
                                href: false,
                                width: "auto",
                                sort: true
                            },
                            {
                                columnName: "statusStr",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.status"),
                                href: false,
                                width: "7%",
                                sort: true
                            },
                            {   
                                columnName: "migrationStatusStr",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.migration.status"),
                                href: false,
                                width: "10%",
                                sort: true
                            },
                            {   
                                columnName: "migrationStartTime",
                                headerLabel: LocaleUtility.getLocaleMessage("multi.tenant.migration.start.time"),
                                href: false,
                                width: "10%",
                                sort: true
                            },
                            {
                                columnName: "generateIcon",
                                headerLabel: LocaleUtility.getLocaleMessage("tenant.actions.label"),
                                href: false,
                                width: "5%",
                                sort: false,
                                columnKey: "id",
                                onClick: this.onClickSendIcon
                            }
                        ]}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={0}
                        onSort={this.state.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        rowKey="id"
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
                <div>
                    <SectionWithTitle title="" helpTopic="Tenants_Overview"/>
                </div>
                {this.state.showAddTenant &&(
                    <AddTenant 
                        showModal = {this.state.showAddTenant}
                        cancelAction = {this.closeCreateTenantPopup}
                        creatAction = {this.createTenantAction}
                        tenantObj = {this.state.editObject}
                    />
                )}
            </div>
        )
    }
}
// MultiTenants.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default MultiTenants;