import { EventEmitter } from "events";
import Dispatcher from "./Dispatcher";

class AbstractStore extends EventEmitter {
  constructor() {
    super();
    this.state = {};
    this.handleActions = this.handleActions.bind(this);
    this.getState = this.getState.bind(this);
    Dispatcher.register(this.handleActions);
  }
  handleActions(actions) {}
  getState() {
    return this.state;
  }
}

export default AbstractStore;
