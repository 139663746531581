import React, { Component } from "react";
import { DateInput } from 'grommet/components/DateInput';
import { Button } from "grommet/components/Button";
import { FormField } from "grommet/components/FormField";
import * as DateUtility from "../../utils/DateUtility";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class DatesPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: this.props.fromDate,
            toDate: this.props.toDate
        }
        this.findLabel = this.findLabel.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    changeStartDate(e) {
        this.setState({fromDate: e.value});
    }
    changeEndDate(e) {
        this.setState({toDate: e.value});
    }
    render() {
        //let intl = this.context.intl;
        let showHideModalStyle = this.props.showModal ? "create-pool-modal display-block" : "create-pool-modal display-none";
        return(
            <div id={this.props.id} className={showHideModalStyle}>
                <div id="datesDivId" className="create-pool-modal">
                    <div className="modal-main panel panel-default" style={{width: "50%"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <FormField style={{ width: '23em' }} label= {this.props.startDateLabel} >
                                        <DateInput id="dates_popup_StartDateId" className="datepicker"
                                            format={DateUtility.getDateFormat()} 
                                            value={this.state.fromDate} 
                                            onChange={this.changeStartDate} 
                                            placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})}
                                        />
                                    </FormField>
                                </td>
                                <td>
                                    <FormField style={{ width: '23em' }} label= {this.props.endDateLabel} >
                                        <DateInput id="dates_popup_EndDateId" className="datepicker"
                                            format={DateUtility.getDateFormat()} 
                                            value={this.state.toDate} 
                                            onChange={this.changeEndDate} 
                                            placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})}
                                        />
                                    </FormField>
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingTop: "10px"}}>
                                    <div className="button_left_wrap">
                                        <div className="button_wrap_primary">
                                        <Button id="dates_popup_submitBtn" label={this.findLabel("label.button.ok")} plain={false} primary={true} onClick={(event)=>this.props.okAction(event, this.state)} />
                                        </div>
                                        <div className="button_wrap_negative">
                                        <Button id="dates_popup_canelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.props.cancelAction} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{padding: "5px", color: "#E5004C"}}>{this.props.errorMessage}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
// DatesPopup.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default DatesPopup;