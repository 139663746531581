import * as LicenseUtility from "../utils/LicenseUtility";
import * as RestWebServicePaths from "../utils/RestWebServicePaths";
import Dispatcher from "../flux/Dispatcher";
import * as ConfigurationUtils from "../utils/ConfigurationUtils";
import * as UserUtility from "../utils/UserUtility";
import * as UsageReportsUtils from "../utils/UsageReportsUtils"; 
import * as UsageTenantReportUtils from "../utils/UsageTenantReportUtils";
import * as LocaleUtility from "./LocaleUtility";
import * as ReservationUtils from "../utils/ReservationUtils";
import * as FrontendPagePath from "../utils/FrontendPagePath";
import * as DownloadUtils from "../utils/DownloadUtils";
import * as CommuterUtils from "../utils/CommuterUtils";
import { getServerUnavailableErrors, encryptText, encryptLead, findTidFn, buildURLQueryParam, encryptTextForKey } from "../utils/Util";
import * as TokenUtility from "../utils/TokenFeatureUtils";
import * as MultiTenantUtils from "../utils/MultiTenantUtils";
import * as DateUtility from "./DateUtility";

const { v1: uuidv1 } = require("uuid");
var CryptoJS = require("crypto-js");
var xsrfValue = "";
var sessionMap = {};
var sharedLead = "";
var currentTntName = "";
var currentTntId = "";
var urRequestValue = "ui";
var usageProductId = undefined;
					
export function getToken() {
  if (xsrfValue !== "") return xsrfValue;

  // var lid = localStorage.getItem('LID');
  // if(lid != undefined){
  //     var data = JSON.parse(Utils.decrypt(lid));
  //     return data.xid;
  // }
}
export function getPageSize() {
  let v = 10;
  let s = getSession(false);
  if(s !== undefined && s.pageSize !== undefined) {
    v = s.pageSize;
  }
  return v;
}
export function getCurrentTenantName() {
  return currentTntName;
}
export function setCurrentTenantName(value) {
  currentTntName = value;
}

export function getCurrentTenantId() {
  return findTidFn(window.location);
}
export function getUiRquestValue() {
  return urRequestValue;
}


export function setToken(value) {
  xsrfValue = value;
}

export function getLead() {
  if (sharedLead !== "") return sharedLead;
}
export function setLead(value) {
  sharedLead = value;
}

async function fetchIndexReq(url) {
  //var url = RestWebServicePaths.APLS_WS_LOGIN_IDM;

  // await response of fetch call
  let response = await fetch(url, {
    method: "GET",
    headers: {
      "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
      Accept: "application/json"
    },
    credentials: "include"
  });

  if (response.status === 401 || response.status === 403) {
    return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
  }

  var xsrfToken = response.headers.get("XSRF-TOKEN");
  if (xsrfToken !== undefined && xsrfToken !== "") {
    // only proceed once promise is resolved
    let data = await response.json();

    // only proceed once second promise is resolved

    if (data.status === "SUCCESS") {
      xsrfValue = xsrfToken;
      var map = {};
      data.responseMap.entry.forEach(item => {
        if(item.key === "currentTenantName" && item.value !== undefined  && item.value !== null && item.value !== 'null') {
            currentTntName = item.value.value;
            sessionStorage.setItem("loggedInTenant", currentTntName);
        } else if(item.key === "defaultIDMTenant" && item.value !== undefined  && item.value !== null && item.value !== 'null') {
            sessionStorage.setItem("defaultIDMTenant", item.value.value);
        }
        map[item.key] = item.value;
      });
      if(currentTntName !== undefined && currentTntName !== "" && (currentTntName !== "0" || Number(currentTntName) !== 0)) {
        let searParam = window.location.search;
        if(searParam !== undefined && searParam === "") {
          //window.location.search = buildURLQueryParam();
          var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + buildURLQueryParam();    
          window.history.pushState({ path: refresh }, '', refresh);
        }
      }
      var respData = {
        user: data.user,
        xid: xsrfToken,
        sessionMap: map,
        status: data.loginStatus,
        responseMap: data.responseMap
      };
      return respData;
    }
    if (data !== undefined && data.errorDetail !== undefined) {
      throw new TypeError(
        LocaleUtility.getLocaleMessage(data.errorDetail.errorCode)
      );
    }
    throw new TypeError("Login Failed !");
  } else {
    return undefined;
  }
}

async function fetchAsync(url) {
  if (getToken() === undefined) {
    let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        //'XSRF-TOKEN': getToken(),
        Accept: "application/json"
      }
    });

    if (resp.status === 401) {
      currentTntName = "";
      return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    var xsrfToken = resp.headers.get("XSRF-TOKEN");
    xsrfValue = xsrfToken;
    let sessionData = await resp.json();
    setSession(sessionData);
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "XSRF-TOKEN": xsrfToken,
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      }
    });
    if (response.status === 401 || response.status === 403) {
      return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  } else {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "XSRF-TOKEN": getToken(),
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      }
    });
    if (response.status === 401 || response.status === 403) {
      return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  }
}

async function fetchPOST(url, postData) {
  if (getToken() === undefined) {
    let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        //'XSRF-TOKEN': getToken(),
        Accept: "application/json"
      }
    });
    if (resp.status === 401) {
      currentTntName = "";
      return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
    }
     if (resp.status === 403) {
      return Promise.reject(403, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    var xsrfToken = resp.headers.get("XSRF-TOKEN");
    xsrfValue = xsrfToken;
    let sessionData = await resp.json();
    setSession(sessionData);
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": xsrfToken,
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  } else {
    // await response of fetch call
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": getToken(),
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
    	return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  }
}

async function fetchPOSTMultiFormData(url, postData) {
  if (getToken() === undefined) {
    let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        //'XSRF-TOKEN': getToken(),
        Accept: "application/json"
      }
    });
    if (resp.status === 401) {
      currentTntName = "";  
      return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    var xsrfToken = resp.headers.get("XSRF-TOKEN");
    xsrfValue = xsrfToken;
    let sessionData = await resp.json();
    setSession(sessionData);
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": xsrfToken,
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  } else {
    // await response of fetch call
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": getToken(),
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  }
}

async function fetchDELETE(url, postData) {
  if (getToken() === undefined) {
    let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        //'XSRF-TOKEN': getToken(),
        Accept: "application/json"
      }
    });
    if (resp.status === 401) {
      currentTntName = "";
      return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    var xsrfToken = resp.headers.get("XSRF-TOKEN");
    xsrfValue = xsrfToken;
    let sessionData = await resp.json();
    setSession(sessionData);
    let response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": xsrfToken,
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  } else {
    // await response of fetch call
    let response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": getToken(),
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  }
}

async function fetchPUT(url, postData) {
  if (getToken() === undefined) {
    let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
      method: "GET",
      credentials: "include",
      headers: {
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        //'XSRF-TOKEN': getToken(),
        Accept: "application/json"
      }
    });
    if (resp.status === 401) {
      currentTntName = "";
      return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
    }
    var xsrfToken = resp.headers.get("XSRF-TOKEN");
    xsrfValue = xsrfToken;
    let sessionData = await resp.json();
    setSession(sessionData);
    let response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": xsrfToken,
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
        return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  } else {
    // await response of fetch call
    let response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        "XSRF-TOKEN": getToken(),
        "Content-Type": "application/json",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
        Accept: "application/json"
      },
      body: postData
    });

    if (response.status === 401 || response.status === 403) {
    	return Promise.reject(response.status, LocaleUtility.getDefualtString("Session.Timeout"));
    }

    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return data;
  }
}

async function fetchLogin(url, postData, tid) {
  // await response of fetch call
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "XSRF-TOKEN": encryptLead(),
      "Tenant-Name": getCurrentTenantName(),
      "Tenant-Id": tid,
      Accept: "application/json"
    },
    credentials: "include",
    body: postData
  });

  var xsrfToken = response.headers.get("XSRF-TOKEN");
  // only proceed once promise is resolved
  let data = await response.json();

  // only proceed once second promise is resolved
  if (xsrfToken !== undefined && xsrfToken !== "") {
    if (data.status === "SUCCESS") {
      xsrfValue = xsrfToken;
      var map = {};
      if (data.responseMap !== undefined) {
        data.responseMap.entry.forEach(item => {
          if(item.key === "currentTenantName" && item.value !== undefined && item.value !== null && item.value !== 'null') {
            currentTntName = item.value.value;
            sessionStorage.setItem("loggedInTenant", currentTntName);
          }
          map[item.key] = item.value;
        });
      }
      var respData = {
        user: data.user,
        xid: xsrfToken,
        sessionMap: map,
        status: data.loginStatus,
        responseMap: data.responseMap
      };
      return respData;
    }
  }

  if (data !== undefined && data.errorDetail !== undefined) {
    var errObj = {
      status: data.loginStatus,
      message: LocaleUtility.getLocaleErrorMessage(
        data.errorDetail.errorCode,
        data.errorDetail.parameters
      )
    };
    return Promise.reject(
      errObj,
      LocaleUtility.getLocaleMessage(data.errorDetail.errorCode)
    );
    //throw new TypeError(data.loginStatus, LocaleUtility.getLocaleMessage(data.errorDetail.errorCode));
  }
  throw new TypeError("Login Failed !");
}

async function fetchLogout(url) {
  // await response of fetch call
  let response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
      "XSRF-TOKEN": getToken() !== undefined? getToken(): "",
      Accept: "application/json"
    }
  });

  if (response.status === 401) {
    return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
  }

  if (response.status === 200) {
    return Promise.resolve(LocaleUtility.getDefualtString("Logout.Success"));
  }
}

async function fetchResetReq(url, postData, lead, tid) {
  // await response of fetch call
  var key;
  if(lead !== undefined){
    key = encryptLead(lead);
  } else {
    key = encryptLead();
  }

  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "XSRF-TOKEN": key,
      "Content-Type": "application/json",
      "Tenant-Name": getCurrentTenantName(),
      "Tenant-Id": tid,
      Accept: "application/json"
    },
    body: postData
  });

  if (response.status === 401) {
    return Promise.reject(401, LocaleUtility.getDefualtString("Session.Timeout"));
  }

  // only proceed once promise is resolved
  let data = await response.json();
  // only proceed once second promise is resolved
  return data;
}

export function fetchIndex() {
  var url = RestWebServicePaths.APLS_WS_LOGIN_IDM;
  let loadSpinAction = "SIGN_IN_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.please.wait")
  });
  fetchIndexReq(url)
    .then(function(data) {
      if (data !== undefined) {
        let payload = {
          user: data
        };

        Dispatcher.dispatch({
          type: "IDM_SUCCESS",
          payload: payload
        });
      }
    })
    .catch(error => {
      LicenseUtility.processError(error);
    });
}

export function fetchIndexSaml(){
   var url = RestWebServicePaths.APLS_WS_LOGIN_SAML;
  let loadSpinAction = "SIGN_IN_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.please.wait")
  });
  fetchIndexReq(url)
    .then(function(data) {
      if (data !== undefined) {
         let type = "LOGIN_SUCCESS",
        payload;
      if (
        data.status !== undefined &&
        (data.status === "REDIRECT" || data.status === "ACTIVE")
      ) {
        payload = {
          user: data
        };
      }
      if(data.responseMap !== undefined){

         var map = {};
        if(data.responseMap !== undefined){
            data.responseMap.entry.forEach(item => {
                if(item.key === "currentTenantName" && item.value !== undefined  && item.value !== null && item.value !== 'null') {
                    currentTntName = item.value.value;
                    sessionStorage.setItem("loggedInTenant", currentTntName);
                } else if(item.key === "defaultIDMTenant" && item.value !== undefined  && item.value !== null && item.value !== 'null') {
                    sessionStorage.setItem("defaultIDMTenant", item.value.value);
                }
                map[item.key] = item.value;
            });
        }

      if(currentTntName !== undefined && currentTntName !== "" && (currentTntName !== "0" || Number(currentTntName) !== 0)) {
        let searParam = window.location.search;
        if(searParam !== undefined && searParam === "") {
          //window.location.search = buildURLQueryParam();
          var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + buildURLQueryParam();    
          window.history.pushState({ path: refresh }, '', refresh);
        }
      }

        var checkflag=true;
        var message=data.user.userName+map.isadmin.value+map.roleList.value;
        var key=encryptTextForKey(map.token.value);
        //alert(key);
        var hash = CryptoJS.HmacSHA256(message, key);
          var hashValue = CryptoJS.enc.Base64.stringify(hash);
            //alert("---message--"+message +"---"+hashValue+">>message<<"+map.message.value);
            if(hashValue !== map.message.value){
              checkflag=false;

            }
        }
        if(checkflag){
      Dispatcher.dispatch({
        type: type,
        payload: payload
      });
    }
      }
    })
    .catch(error => {
      LicenseUtility.processError(error);
    });
}

export function checkLoginStatus(formData, WS_ENDPOINT, tid, setSaml) {
  var url = WS_ENDPOINT;
  var request = {
	username: encryptText(formData.username),
    password: encryptText(formData.password),
    resetpwd: encryptText(formData.resetpwd)
  };

  fetchLogin(url, JSON.stringify(request), tid)
    .then(function(data) {
      let type, payload;
      if (data.status !== undefined && data.status === "RESET") {
        type = "PWD_RESET";
        payload = {
          user: data
        };
        Dispatcher.dispatch({
          type: type,
          payload: payload
        });
      }
      if (data.status !== undefined && data.status === "FL_SUCCESS") {
        type = "PWD_FIRSTLOGIN_RESET_SUCCESS";
        payload = {
          user: data,
          changed: true,
        };
        Dispatcher.dispatch({
          type: type,
          payload: payload
        });
      }
      if (
        data.status !== undefined &&
        (data.status === "REDIRECT" || data.status === "ACTIVE")
      ) {
        if (request.resetpwd !== undefined) {
          request.password = request.resetpwd;
          request.resetpwd = null;
        }
        requestLogin(request, tid, setSaml);
      }
    })
    .catch((status, error) => {
      let payload = status.message,
        type = "STATUS_ERROR";
      var session = getSession();
      if (getServerUnavailableErrors().indexOf(payload) > -1) {
        Dispatcher.dispatch({
          type: "LOGOUT_SUCCESS",
          payload: { user: undefined, idm: session.isidm, saml: session.saml }
        });
        Dispatcher.dispatch({
          type: "DISABLE_LOADING_SPIN_DONE",
          payload: ""
        });
        return;
      }
      if (status.status !== undefined) type = "COMP_INVALID";
      Dispatcher.dispatch({
        type: type,
        payload: payload
      });

      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
    });
}

export function requestLogin(formData, tid, setSaml) {
  var url = RestWebServicePaths.APLS_WS_LOGIN;
  var request = {
    username: formData.username,
    password: formData.password,
    setsaml: setSaml,
    claim: {
      id: formData.username,
      issuer: "react",
      subject: "login",
      ttlMillis: 1200000
    }
  };

  fetchLogin(url, JSON.stringify(request), tid)
    .then(function(data) {
      let type = "LOGIN_SUCCESS",
        payload;
      if (data.status !== undefined && data.status === "RESET") {
        type = "PWD_RESET";
      } else if (
        data.status !== undefined &&
        (data.status === "REDIRECT" || data.status === "ACTIVE")
      ) {
        payload = {
          user: data,
          setSaml:(setSaml !== undefined ? setSaml : false)
        };
      }
      if(data.responseMap !== undefined){
      
         var map = {};
        if(data.responseMap !== undefined){
            data.responseMap.entry.forEach(item => {
                map[item.key] = item.value;
            });
        }
        var checkflag=true;
        var message=data.user.userName+map.isadmin.value+map.roleList.value;
        var key=encryptTextForKey(map.token.value);
        //alert(key);
        var hash = CryptoJS.HmacSHA256(message, key);
          var hashValue = CryptoJS.enc.Base64.stringify(hash);
            //alert("---message--"+message +"---"+hashValue+">>message<<"+map.message.value);
            if(hashValue !== map.message.value){
              checkflag=false;
             
            }
        }
        if(checkflag){
      Dispatcher.dispatch({
        type: type,
        payload: payload
      });
    }else{
     
      Dispatcher.dispatch({
      type: "NOTIFICATION_CLOSE"
      });
      Dispatcher.dispatch({
        type: "COMP_CLOSE"
      });
     requestLogout();
      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });

    }
    })
    .catch(error => {
      LicenseUtility.processError(error);
    });
}

export function requestLogout(setSaml, isSamlEnabled) {
  var url = RestWebServicePaths.APLS_WS_LOGOUT;
  var session = getSession();
  fetchLogout(url)
    .then(function(data) {
      sessionStorage.removeItem("loggedInTenant");
      xsrfValue = "";
      /* Close the active AppNotification component.*/
      Dispatcher.dispatch({
          type: "NOTIFICATION_CLOSE"
      });
      if(!LicenseUtility.getLogout()){
        if(setSaml){
          Dispatcher.dispatch({
            type: "LOGOUT_SUCCESS",
            payload: { user: undefined, idm: session.isidm, saml: false, setSaml: true }
          });
        } 
        else {
          Dispatcher.dispatch({
            type: "LOGOUT_SUCCESS",
            payload: { user: undefined, idm: session.isidm, saml: session.saml}
          });
        }
        
        LicenseUtility.setLogout(true);
        currentTntName = "";
      }
    })
    .catch(error => {
      LicenseUtility.processError(error);
    });
}

export function resetPassword(username, tid) {
  var url = RestWebServicePaths.APLS_WS_PWD_RESET;
  var request = { username: encryptText(username) };
  let loadSpinAction = "SIGN_IN_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.please.wait")
  });
  fetchResetReq(url, JSON.stringify(request), undefined, tid)
    .then(function(data) {
      if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if(errorDetail !== null && errorDetail !== undefined && errorDetail.errorCode !== undefined && Number(errorDetail.errorCode) === 19000) {
            Dispatcher.dispatch({
                type: "TENANT_NOT_FOUND",
                payload: {}
            });
        } else {
          Dispatcher.dispatch({
            type: "CONFIGURATION_PAGE_ERROR",
            payload: LocaleUtility.getLocaleErrorMessage(
              data.errorDetail.errorCode
            )
          });
        }
      } else {
        Dispatcher.dispatch({
          type: "PWD_RESET_SUCCESS",
          payload: {}
        });
      }
      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function resetPasswordFromUser(username) {
  var url = RestWebServicePaths.APLS_WS_PWD_RESET;
  var request = { username: encryptText(username) };
  let loadSpinAction = "SIGN_IN_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.please.wait")
  });
  fetchPOST(url, JSON.stringify(request))
    .then(function(data) {
      if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if(errorDetail !== null && errorDetail !== undefined && errorDetail.errorCode !== undefined && Number(errorDetail.errorCode) === 19000) {
            Dispatcher.dispatch({
                type: "TENANT_NOT_FOUND",
                payload: {}
            });
        } else {
          Dispatcher.dispatch({
            type: "RESET_ERROR",
            payload: LocaleUtility.getLocaleErrorMessage(
              data.errorDetail.errorCode
            )
          });
        }
      } else {
        Dispatcher.dispatch({
          type: "ALERT_SHOW_MODAL",
          payload: LocaleUtility.getLocaleMessage("label.reset.pwd.link.msg", {
            email: username
          })
        });
      }
      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function resetLogin(formData, url, tid, tenantReset) {
  var key = uuidv1()+formData.username;
  var request = { username: encryptText(formData.username,key), password: encryptText(formData.password, key) };
  let loadSpinAction = "SIGN_IN_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.please.wait")
  });
  fetchResetReq(url, JSON.stringify(request), key, tid)
    .then(function(data) {
      var payload;
      if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if(errorDetail !== null && errorDetail !== undefined && errorDetail.errorCode !== undefined && Number(errorDetail.errorCode) === 19000) {
            Dispatcher.dispatch({
                type: "TENANT_NOT_FOUND",
                payload: {}
            });
        } else {
          payload = {
            changed: true,
            tenantReset: tenantReset,
            error: LocaleUtility.getLocaleErrorMessage(data.errorDetail.errorCode)
          };
          Dispatcher.dispatch({
            type: "PWD_RESET_SUCCESS",
            payload: payload
          });
        }
      } else {
        Dispatcher.dispatch({
          type: "PWD_RESET_SUCCESS",
          payload: { changed: true, tenantReset: tenantReset }
        });
      }
      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestValidateLicense(formData, method) {
  fetchPOSTMultiFormData(
    RestWebServicePaths.APLS_WS_REQ_VALIDATE_LICENSE,
    formData
  )
    .then(responseData => {
      LicenseUtility.processData(responseData, "LICENSE_VALIDATE_COMPLETE", 2);
    })
    .catch(function(status, error) {
      LicenseUtility.processError(status, error);
    });
}

export function requestValidateLicenseList(licenseList) {
  var postData = LicenseUtility.processToValidateLicense(licenseList);
  var url = RestWebServicePaths.APLS_WS_REQ_VALIDATE_LICENSE;

  fetchPOST(url, postData)
    .then(data => {
      LicenseUtility.processData(data, "PAC_ENTITLEMENTS_FETCH_COMPLETE", 3);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function installLicenseList(licenseList) {
  var postData;
  let featureTenantList = [];
  let ctFeatureMap;
  if(licenseList.installData!==undefined){
    postData = LicenseUtility.processToAddLicense(licenseList.installData);
    licenseList.installData.map((item)=>{
    let featureTenantItem = {
        pdfileId: item.productID,
        featureId: item.featureId,
        featureVersion: item.featureVersion,
        tenant: item.tenantID,
        featureDescription: item.featureDesc
      };
      featureTenantList.push(featureTenantItem);
      return "";
    });

    if(licenseList.CTdata!==undefined){
      ctFeatureMap= {
      idmserver: licenseList.CTdata.idmServerUrlValue,
      username: licenseList.CTdata.idmUserNameValue,
      freequency: licenseList.CTdata.ctUsageFreequency,
      ctUrl: licenseList.CTdata.ctServerUrlValue,
      usageType: licenseList.CTdata.type,
      ctHostName: licenseList.CTdata.ctHostName,
      featuresTenantsList: featureTenantList
      }
    }
  }else{
    postData = LicenseUtility.processToAddLicense(licenseList);
  } 
  let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(RestWebServicePaths.APLS_WS_REQ_INSTALL_LICENSE, postData)
    .then(responseData => {
      if(responseData.status==="SUCCESS" && ctFeatureMap!==undefined){
          fetchPOST(RestWebServicePaths.APLS_WS_REQ_CT_FEATURE_MAP_SAVE, JSON.stringify(ctFeatureMap))
          .then(respData => {
          })
          .catch(function(status, error) {
            LicenseUtility.processError(status, error);
          });
      }
      LicenseUtility.refreshActiveLicenseListView(responseData, "ADDED");
    })
    .catch(function(status, error) {
      LicenseUtility.processError(status, error);
    });
}

export function requestDecodeLicense(formData, url) {
  fetch(url, {
    method: "GET",
    headers: {
      "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache, no-store",
        "Tenant-Name": getCurrentTenantName(),
        "Tenant-Id": getCurrentTenantId(),
        "Apls-CA": getUiRquestValue(),
      Accept: "application/json"
    },
    body: formData
  })
    .then(response => {
      return response.json();
    })
    .then(data => {})
    .catch(error => {});
}

export function getAllLicenses(selectedOption, type, pagination) {
  var postData,
    licenseStatusType = "ACTIVE";
  var paginationObj = {
    deleted: false,
    sortBy: "installedOn",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };
  if (type !== undefined) {
    licenseStatusType = type;
  }

  if (licenseStatusType === "ACTIVE") {
    paginationObj.sortBy = "installedOn";
  } else if (licenseStatusType === "ARCHIVED") {
    paginationObj.sortBy = "archivedOn";
  }

  if (pagination !== undefined) {
    paginationObj = pagination;
  }



  if (selectedOption === undefined) {
    postData = {
      requestLicenseType: licenseStatusType,
      pagination: paginationObj
    };
  } else {
    postData = {
      requestLicenseType: licenseStatusType,
      productNameList: { value: [selectedOption] },
      pagination: paginationObj
    };
  }

  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_VIEW_LIST;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processActiveLicenseList(data, type);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });

}


export function archiveLicenseList(licenseIds) {
  var url = RestWebServicePaths.APLS_WS_REQ_ARCHIVE_LICENSE;
  var postData = { archiveLicenseList: { licenseSignature: licenseIds } };

  let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshActiveLicenseListView(data, "ARCHIVED");
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function restoreLicenseList(licenseIds) {
  var url = RestWebServicePaths.APLS_WS_REQ_RESTORE_LICENSE;
  var postData = { restoreLicenseList: { licenseSignature: licenseIds } };

  let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshArchiveLicenseView(data, "ADDED");
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function removeLicenseList(licenseIds) {
  var url = RestWebServicePaths.APLS_WS_REQ_REMOVE_LICENSE;
  var postData = { removeLicenseList: { licenseSignature: licenseIds } };

  let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshArchiveLicenseView(data, "DELETED");
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getLicenseDetail(licenseId, showRenewDetail) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_LICENSE_DETAIL +
    "?licenseId=" +
    licenseId;

  fetchAsync(url)
    .then(data => {
      LicenseUtility.getLicenseDetail(data, showRenewDetail);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestCleanupLicense(formData, method) {
  fetchPOSTMultiFormData(
    RestWebServicePaths.APLS_WS_REQ_CLEANUP_LICENSE,
    formData
  )
    .then(responseData => {
      LicenseUtility.processLicenseCleanupData(responseData);
    })
    .catch(function(status, error) {
      LicenseUtility.processError(status, error);
    });
}

export function getAllLicenseToken(type, status,productName,params) {
  var postData,
    tokenLicenseType = "ALL",
    tokeStatus = "CHECKED_OUT";

  if (type !== undefined) {
      if(type===8){
        tokenLicenseType = "CONCURRENT_LICENSE";
      }
  }
  // if (status !== undefined) {
  //   tokeStatus = status;
  // }

  var url = RestWebServicePaths.APLS_WS_REQ_CHECKED_OUT_TOKEN_LIST;
  postData = {
    tokenLicenseType: tokenLicenseType,
    tokenStatus: tokeStatus,
	productName: productName,
    pagination: {
      deleted: false,
      sortBy: "featureId",
      sortOrder: "ASC",
      pageNumber: "1",
      pageSize: getPageSize()
    }
  };
  if(params!==undefined)
  {
  postData.token= {
    featureID:params.featureID,
    capacity:params.capacity,
    startDate:params.startDate,
    hostIdList:params.hostIdList,
    userNameList:params.userNameList,
    clientUniqueIdList:params.clientUniqueIdList,
    ipAddressList:params.ipAddressList,
    sharedUserCount:params.sharedUserCount,
    productFeature:params.productFeature,
    };
  }
  
  if(productName!==undefined)
  {
    postData.productName=productName;
  }
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processLicenseTokenList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestCleanupCommuter(rawCleanupKey, data, tokens) {
  var url = RestWebServicePaths.APLS_WS_REQ_CLEANUP_COMMUTER;
  var rawTokens = [];
  tokens.forEach(key => rawTokens.push(data.get(key)));
  var postData = {
    rawCleanupKey: rawCleanupKey,
    licenseTokens: { value: rawTokens }
  };

  let loadSpinAction = "TOKEN_CLEANUP_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processCleanupSuccess(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getBorrowLicenses(selectedOption, type, pagination) {
  var postData,
    licenseStatusType = "ACTIVE";

  var paginationObj = {
    deleted: false,
    sortBy: "installedOn",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: 5
  };

  if (type !== undefined) {
    licenseStatusType = type;
  }

  if (pagination !== undefined) {
    paginationObj = pagination;
  }

  var url = RestWebServicePaths.APLS_WS_REQ_BORROW_LICENSE_LIST;
  postData = {
    requestLicenseType: licenseStatusType,
    productNameList: { value: [selectedOption] },
    pagination: paginationObj
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processBorrowLicenseList(data, licenseStatusType);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getProducts(operation, showFirstProdOption) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_IDS + "?operation=" + operation;

  fetchAsync(url)
    .then(data => {
      LicenseUtility.getAllProducts(data, showFirstProdOption);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getAllProductFeatures(productName, filterNamedFeature) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_FEATURES +
    "?productIdentifier=" + productName+"&filterNamedFeature=" +filterNamedFeature;
  fetchAsync(url)
    .then(data => {
      LicenseUtility.getAllProductFeatures(data, productName);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestBorrowLicense(elements, productName) {
  var url = RestWebServicePaths.APLS_WS_REQ_CHECKOUT_BORROW_LICENSE;
  var featureDetails = elements.feature.value.split(":");
  var d = new Date(elements.expiryDate.value);
  var postData = {
    productName: productName,
    featureID: featureDetails[0],
    featureVersion: featureDetails[1],
    capacity: parseInt(elements.capacity.value),
    expiryDate: d.getTime(),
    licenseServerDetails: {
      hostName: elements.serverHostName.value,
      serverPort: elements.serverPort.value,
      httpProtocol: elements.httpProtocol.value
    }
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processBorrowLicense(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function returnBorrowedLicenseList(productName, licenseIds) {
  var url = RestWebServicePaths.APLS_WS_REQ_CHECKIN_BORROW_LICENSE;
  var postData = {
    productName: productName,
    borrowedLicenses: { licenseSignature: licenseIds }
  };

  let loadSpinAction = "RETURN_BORROW_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshBorrowedLicenseListView(data, productName);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function removeBorrowedLicenseList(productName, licenseIds) {
  var url = RestWebServicePaths.APLS_WS_REQ_REMOVE_BORROW_LICENSE;
  var postData = {
    productName: productName,
    borrowedLicenses: { licenseSignature: licenseIds }
  };

  let loadSpinAction = "REMOVE_BORROW_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshBorrowedLicenseListView(data, productName);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function testMainConfiguration(data, intl) {
  var url = RestWebServicePaths.APLS_CONFIG_MAIN_TEST_MAIL;

  var postData = {
    mailPort: data.portNumber,
    mailAdmin: data.adminEmail,
    mailToId: data.testEmailId,
    mailServer: data.mailServerName,
    instanceIdentifier: data.instanceIdentifier
  };
  ConfigurationUtils.showLoadMask("configpageLoadSpin", intl);
  fetchPOST(url, JSON.stringify(postData, intl))
    .then(data => {
      if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      } else {
        ConfigurationUtils.showSuccessMessage("configpageMailSent", intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}

export function saveOrUpdateConfigurationSetting(data, intl, requireRedirect) {
  var url = RestWebServicePaths.APLS_UPDATE_CONFIG_SETTING;
  let enableTenancy = 0;
  if(data.enabledTenancy === true) {
    enableTenancy = 1;
  }
  var postData = {
    mailPort: data.portNumber,
    mailAdmin: data.adminEmail,
    instanceIdentifier: data.instanceIdentifier,
    mailToId: data.testEmailId,
    mailServer: data.mailServerName,
    userName: data.userName,
    password: data.password,
    autoPurgRequired: data.isAutoPurge + "",
    purgType: data.isPurgeRecordOlder ? "0" : "1",
    purgeInDays: data.purgeRecordsOlder,
    purgeInSize: data.maxRecrodsRetain,
    usageHubConnector: data.isEnableUsageHub + "",
    proxyAddress: data.proxyAddress,
    proxyPort: data.proxyPortNumber,
    auditType: data.auditType,
    borrowAllowed: data.isBorrowSettings + "",
    preNotificationCheck: data.isAdvanceSetting + "",
    preNotificationDays: data.noOfNotifyAdvance,
    overrideClientAllowed: data.useClientSetting + "",
    protocolType: data.protocolType,
    protocolHost: data.protocolHost,
    protocolPort: data.protocolPort,
    excludeClients: data.excludeClientstoSubmit,
    discoveryStatus: data.discoveryStatus,
    productAthentication: data.productAthentication,
    serverPort: url.port,
    isPurgeRecordOlder: data.isPurgeRecordOlder,
    ctConnector:  data.isEnableCT + "",
    apiKey: data.apiKey,
    hostNames: data.hostNameList,
    apiKeyExpireDays: data.apiKeyExpireDays,
    enableMultiTenancy: enableTenancy
  };
  ConfigurationUtils.showLoadMask("configpageLoadSpin", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      } else {
        if(data.errorDetail!==undefined && data.errorDetail.errorCode===11055){
          if(data.errorDetail.errorMessage !== undefined && data.errorDetail.errorMessage.indexOf("IP/Host")>-1){
            ConfigurationUtils.showErrorMessage1(LocaleUtility.getLocaleMessage("error.invalid.ip.host"), intl);
          }else{
            ConfigurationUtils.showErrorMessage1(data.errorDetail.errorMessage, intl);
          }
        }else{
        if (data.errorDetail && data.errorDetail.customMessage) {
          ConfigurationUtils.showSuccessMessageWithCustom(
            data.errorDetail.customMessage,
            "configpageUpdateSuccess",
            intl
          );
        } else {
          if(requireRedirect === true) {
            ConfigurationUtils.showSuccessPersisitMessage("configpageUpdateSuccess", intl);
            window.location.reload();
          } else {
            ConfigurationUtils.showSuccessMessage("configpageUpdateSuccess",intl);
          }
        }
        findConfigurationSetting(intl);
        getSession(true);
        }
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function findConfigurationSetting(intl) {
  var url = RestWebServicePaths.APLS_FING_CONFIG_SETTINGS;
  fetchAsync(url)
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.processConfigurationSettings(data);
      } else {
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function findUserConfiguration(intl) {
  var url = RestWebServicePaths.APLS_FING_USER_CONFIG;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processUserConfiguration(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function saveOrUpdateUserConfiguration(data, intl) {
  var url = RestWebServicePaths.APLS_UPDATE_USER_CONFIG;
  var postData = {
    userPasswordExpire: data.userPasswordExpire,
    resetPassword: data.resetPassword,
    changePasswordDays: data.changePasswordDays,
    enableEmailNotificaion: data.enableEmailNotificaion,
    groupsSource: data.groupsSource,
    usersSource: data.usersSource,
    implemention: data.implemention,
    changePasswordChecked: data.changePasswordChecked
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      } else {
        ConfigurationUtils.showSuccessMessage(
          "configpageUserUpdateSuccess",
          intl
        );
        findUserConfiguration(intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function findLDAPConfiguration(intl, ldapServer, isTestConn) {
  var url =
    RestWebServicePaths.APLS_FIND_LDAP_CONFIG + "?ldapServer=" + ldapServer;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processLDAPConfiguration(data, isTestConn);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function saveOrUpdateLDAPConfiguration(data, intl) {
  var url = RestWebServicePaths.APLS_UPDATE_LDAP_CONFIG;
  var postData = {
    protocol: data.ldapProtocolKey,
    hostname: data.ldapHostNameValue,
    port: data.ldapPortValue,
    authenticationmethod: data.ldapAuthMethodKey,
    username: data.ldapAuthUserName,
    password: data.ldapAuthPassword,
    basedn: data.ldapUserBaseValue,
    usersubtree: data.ldapSubtreeKey,
    useridattr: data.uniqueIdAttributeValue,
    usersFilter: data.userFilterValue,
    fnameattr: data.firstNameAttributeValue,
    lnameattr: data.lastNameAttributeValue,
    eamilattr: data.emailAttributeValue,
    gbasedn: data.ldapGroupBaseValue,
    groupsubtree: data.ldapGroupSubtreeValue,
    grmemattr: data.groupMemberAttributeValue,
    groupsFilter: data.groupFilterAttributeValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      } else {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUpdateSuccess",
          intl
        );
        findLDAPConfiguration(intl, "", false);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function testLDAPConnection(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_LDAP_CONNECTION;
  let el = document.getElementById("conf_ldap_captcha");
  var postData = { hostname: data.ldapHostNameValue, port: data.ldapPortValue,captcha:el.value };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      let type = "POPULATE_LDAP_CAPTCHA_DETAILS";
      let payload = {
          captcha: data.captch
      };
      Dispatcher.dispatch({
          type: type,
          payload: payload
      });
      findLDAPConfiguration(intl, "", true);
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function testLDAPAuthentication(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_LDAP_AUTHENTICATION;
  var postData = {
    hostname: data.ldapHostNameValue,
    port: data.ldapPortValue,
    authenticationmethod: data.ldapAuthMethodKey,
    username: data.ldapAuthUserName,
    password: data.ldapAuthPassword,
    protocol: data.ldapProtocolKey
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function testLDAPMapping(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_LDAP_MAPPING;
  var postData = {
    hostname: data.ldapHostNameValue,
    protocol: data.ldapProtocolKey,
    port: data.ldapPortValue,
    authenticationmethod: data.ldapAuthMethodKey,
    username: data.ldapAuthUserName,
    password: data.ldapAuthPassword,
    basedn: data.ldapUserBaseValue,
    usersubtree: data.ldapSubtreeKey,
    useridattr: data.uniqueIdAttributeValue,
    usersFilter: data.userFilterValue,
    fnameattr: data.firstNameAttributeValue,
    lnameattr: data.lastNameAttributeValue,
    eamilattr: data.emailAttributeValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function findIDMConfiguration(intl) {
  var url = RestWebServicePaths.APLS_FIND_IDM_CONFIG;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processIDMConfiguration(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function saveIDMConfiguration(data, intl) {
  var url = RestWebServicePaths.APLS_UPDATE_IDM_CONFIG;
  var postData = {
    idmserver: data.idmServerUrlValue,
    username: data.idmUserNameValue,
    password: data.idmPasswordValue,
    tenantinfo: data.idmTenentIdValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUpdateSuccess",
          intl
        );
        findIDMConfiguration(intl, "");
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function testIDMConnection(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_IDM_CONNECTION;
  var postData = {
    idmserver: data.idmServerUrlValue,
    username: data.idmUserNameValue,
    password: data.idmPasswordValue,
    tenantinfo: data.idmTenentIdValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function findUsageHubConfiguration(intl) {
  var url = RestWebServicePaths.APLS_FIND_USAGE_HUB_CONFIG;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processUsageHubConfiguration(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function saveUsageHubConfiguration(data, intl) {
  var url = RestWebServicePaths.APLS_UPDATE_USAGE_HUB_CONFIG;
  var postData = {
    usageHubPort: data.usageHubPortValue,
    analyticusageflag: data.usageEnableReport,
    allowcredentialFlag: data.usageHubCredentials,
    usageIP: data.usageHubHostValue,
    usageinterval: data.usageSyncIntervalValue,
    usagehubUserName: data.usageHubUserNameValue,
    usagehubUserPassword: data.usageHubPasswordValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUpdateSuccess",
          intl
        );
        findUsageHubConfiguration(intl, "");
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function testUsageHubConnection(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_USAGE_HUB_CONNECTION;
  var postData = {
    usageHubPort: data.usageHubPortValue,
    analyticusageflag: data.usageEnableReport,
    allowcredentialFlag: data.usageHubCredentials,
    usageIP: data.usageHubHostValue,
    usageinterval: data.usageSyncIntervalValue,
    usagehubUserName: data.usageHubUserNameValue,
    usagehubUserPassword: data.usageHubPasswordValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function findDatabaseConfiguration(intl) {
  var url = RestWebServicePaths.APLS_FIND_DB_CONFIG;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processDatabaseConfiguration(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listUsageReports(intl, params) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_LIST;
  var postData = {
    productName: params.productName,
    pagination: params.pagination
  };
  ConfigurationUtils.showLoadMask("", intl);
  let sortIndex = params.sortIndex;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UsageReportsUtils.processUsageReportList(data, sortIndex);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listTenantUsageReports(intl, params) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_LIST_TENANT_WISE+"?tenantname="+params.tenantName;
  var postData = {
    productName: params.productName,
    pagination: params.pagination
  };
  ConfigurationUtils.showLoadMask("", intl);
  let sortIndex = params.sortIndex;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UsageTenantReportUtils.processTenantUsageReportList(data, sortIndex);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listUsageReportSearchResults(intl, data, actionType,format) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_SEARCH;
  if (actionType === "purge") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_PURGE;
  } else if (actionType === "revoke") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_REVOKE;
  } else if (actionType === "report") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_SEND_REPORT;
  } else if (actionType === "export") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_SEARCH_EXPORT;
  }
  let sortIndex = data.sortIndex;
  var postData = {
    productName: data.productId,
    featureId: data.featureId,
    featureVersion: data.featureIdVersion,
    featureDescription: data.featureDescription,
    usageStartDate: data.startDateValue,
    usageEndDate: data.expiryDateValue,
    userName: data.userName,
    macAdd: data.clientId,
    ipAddress: data.ipAddress,
    hostID: data.hostName,
    applicationName: data.applicationName,
    serverLicenses: data.serverLicenses,
    reportMailId: data.reportMailId,
    searchReportType: data.searchReportType,
    pagination: data.pagination,
    format: format
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        if (actionType === "export") {
          DownloadUtils.downloadFile(
            data.fileData,
            data.fileName,
            DownloadUtils.CSV_MIMETYPE
          );
        } else if (actionType === "report") {
          ConfigurationUtils.showSuccessMessage(
            "usage.search.email.success",
            intl
          );
          ConfigurationUtils.hideLoadMask(intl);
        } else {
          if (actionType === "purge") {
            ConfigurationUtils.showSuccessMessage(
              "usage.search.purge.success",
              intl
            );
          } else if (actionType === "revoke") {
            ConfigurationUtils.showSuccessMessage(
              "usage.search.revoke.success",
              intl
            );
          }
          UsageReportsUtils.processUsageReportSearchResults(data, sortIndex);
        }
      } else if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if (errorDetail && errorDetail.errorCode === 11045) {
          ConfigurationUtils.showErrorWithMessageParam(
            buildErrorParams(errorDetail.parameters),
            errorDetail.errorCode,
            intl
          );
          ConfigurationUtils.hideLoadMask(intl);
        } else {
          ConfigurationUtils.showErrorMessage1(
            data.errorDetail.errorCode,
            intl
          );
          ConfigurationUtils.hideLoadMask(intl);
        }
      } else {
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function listUsageDataForGraph(intl, data, actionType) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_INUSE;
  if (actionType === "history") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_HISTORY;
  } else if (actionType === "rejected") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_REJECTED;
  }
  let sortIndex = data.sortIndex;
  let requestData = data;
  var postData = {
    pagination: data.pagination,
    featureId: data.featureId,
    showall: data.showall,
    featureVersion: data.featureVersion,
    groupId: data.groupId,
    minCapacity: data.minCapacity,
    licenseModel: data.licenseModel,
    filterPool: data.filterPool,
    usageStartDate: data.usageStartDate,
    usageEndDate: data.usageEndDate,
    uniqueInstanceId: data.uniqueInstanceId
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        if (actionType === "history") {
          let params = {};
          params["featureId"] = requestData.featureId;
          params["featureVersion"] = requestData.featureVersion;
          params["startTime"] = requestData.usageStartDate;
          params["endTime"] = requestData.usageEndDate;
          params["groupId"] = requestData.groupId;
          params["licensetotalcapacity"] = requestData.licensetotalcapacity;
          params["uniqueInstanceId"] = requestData.uniqueInstanceId;
          let resultRequestMap = {};
          if (data.requestMap.entry) {
            Object.entries(data.requestMap.entry).map(([index, obj]) => {
              if (obj.value && obj.value !== undefined) {
                resultRequestMap[obj.key] = obj.value.value;
              } else {
                resultRequestMap[obj.key] = obj.value;
              }
              return "";
            });
          } else {
            resultRequestMap = data.requestMap;
          }
          let reservCap = Number(resultRequestMap["reservedcapacity"]);
          findHistoryGraphData(
            intl,
            params,
            true,
            requestData.poolColorIndexById,
            requestData.usageStartDate,
            requestData.usageEndDate,
            reservCap
          );
        }
        UsageReportsUtils.processInUseResults(data, sortIndex, actionType);
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function listUsageConsumptionData(intl, data) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_UNIT_CONSUMPTION;
  let sortIndex = data.sortIndex;
  var postData = {
    pagination: data.pagination,
    featureId: data.featureId,
    showall: data.showall,
    featureVersion: data.featureVersion,
    groupId: data.groupId,
    minCapacity: data.minCapacity,
    licenseModel: data.licenseModel,
    usageStartDate: data.usageStartDate,
    usageEndDate: data.usageEndDate,
    cumulativeUsage: data.cumulativeUsage,
    tenentId: data.tenentId
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        UsageReportsUtils.processConsumptionResults(data, sortIndex);
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function findHistoryGraphData(
  intl,
  data,
  maximumChecked,
  poolColorIndexById,
  usageStartDate,
  usageEndDate,
  reservedcapacity
) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_HISTORY_GRAPH;
  let groupId = data.groupId;
  let licensetotalcapacity = data.licensetotalcapacity;
  var postData = {
    featureId: data.featureId,
    featureVersion: data.featureVersion,
    startTime: data.startTime,
    endTime: data.endTime,
    groupId: data.groupId,
    uniqueInstanceId: data.uniqueInstanceId,
    uiType: "react"
  };
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (groupId === "0") {
        UsageReportsUtils.processHistoryGraphResults(
          data,
          maximumChecked,
          groupId,
          poolColorIndexById,
          usageStartDate,
          usageEndDate,
          licensetotalcapacity,
          reservedcapacity
        );
      } else {
        UsageReportsUtils.processHistoryGraphResultsForRejected(
          data,
          maximumChecked,
          groupId,
          poolColorIndexById
        );
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
  if (groupId !== "0") {
    url = RestWebServicePaths.APLS_WS_USAGE_REPORT_HISTORY_GRAPH_BY_POOL;
    postData = {
      featureId: data.featureId,
      featureVersion: data.featureVersion,
      startTime: data.startTime,
      endTime: data.endTime,
      groupId: groupId,
      uniqueInstanceId: data.uniqueInstanceId,
      uiType: "react"
    };
    fetchPOST(url, JSON.stringify(postData))
      .then(data => {
        UsageReportsUtils.processHistoryGraphResults(
          data,
          maximumChecked,
          groupId,
          poolColorIndexById,
          usageStartDate,
          usageEndDate,
          licensetotalcapacity,
          reservedcapacity
        );
      })
      .catch((status, error) => {
        LicenseUtility.processError(status, error);
      });
  }
}
export function findConsumptionGraphData(intl, data) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_CONSUMPTION_GRAPH;
  let usageStartDate = data.startTime;
  let usageEndDate = data.endTime;
  var postData = {
    featureId: data.featureId,
    featureVersion: data.featureVersion,
    startTime: data.startTime,
    endTime: data.endTime,
    tenant: data.tenant
  };
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UsageReportsUtils.processConsumptionGraphResults(
        data,
        usageStartDate,
        usageEndDate
      );
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function revokeInuseRecords(intl, data) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_INUSE_REVOKE;
  let sortIndex = data.sortIndex;
  var postData = {
    serverLicenses: data.serverLicenses,
    pagination: data.pagination,
    licenseModel: data.licenseModel,
    featureId: data.featureId,
    showall: data.showall,
    featureVersion: data.featureVersion,
    groupId: data.groupId,
    minCapacity: data.minCapacity,
    filterPool: data.filterPool
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "usage.search.revoke.success",
          intl
        );
        UsageReportsUtils.processInUseResults(data, sortIndex);
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function manageCommuter(intl, featureIds) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_MANAGE_COMMUTER;
  var postData = { featureIds: featureIds };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        UsageReportsUtils.processManageCommuterSave();
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function saveDatabasefiguration(data, intl) {
  var url = RestWebServicePaths.APLS_UPDATE_DB_CONFIG;
  if(data.databaseTypeValue !== "Postgres"){
    data.databaseSchemaValue="";
  }
  var postData = {
    dbhost: data.databaseHostValue,
    dbport: data.databasePortValue,
    dbname: data.databaseNameValue,
    dburl: data.databaseURLValue,
    dbtype: data.databaseTypeValue,
    dbDatapasswd: data.databasePasswordValue,
    dbuser: data.databaseUserValue,
    dbschema: data.databaseSchemaValue,
    dbcertfilename: data.fileName,
    dbcertfilecontent: data.fileContent,
    dbSSL:data.dbSSLValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageDBUdpateSuccess",
          intl
        );
        findDatabaseConfiguration(intl, "");
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function testDatabaseConnection(data, intl) {
  var url = RestWebServicePaths.APLS_TEST_DB_CONNECTION;
  var postData = {
    dbhost: data.databaseHostValue,
    dbport: data.databasePortValue,
    dbname: data.databaseNameValue,
    dburl: data.databaseURLValue,
    dbtype: data.databaseTypeValue,
    dbDatapasswd: data.databasePasswordValue,
    dbuser: data.databaseUserValue,
    dbschema: data.databaseSchemaValue,
    dbcertfilename: data.fileName,
    dbcertfilecontent: data.fileContent,
    dbSSL:data.dbSSLValue
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageDBTestConnSuccess",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function sendUsageReport(
  usageStartDate,
  usageEndDate,
  userConsent,
  intl
) {
  var url = RestWebServicePaths.APLS_WS_USAGE_ADVANCED_REPORT_SEND_USAGE;
  url = url + "?startTime=" + usageStartDate + "";
  url = url + "&endTime=" + usageEndDate + "";
  url = url + "&isUserConsent=" + userConsent;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url)
    .then(data => {
      let errorMsg, errorcode;
      if (data.status === "FAIL") {
        errorMsg = data.errorDetail.errorMessage;
        errorcode = data.errorDetail.errorCode;
        if (errorcode === 9035) {
          ConfigurationUtils.showErrorMessage1("info.proxy.error", intl);
        } else if (
          errorcode === 6517 ||
          errorcode === 6518 ||
          errorcode === 6519 ||
          errorcode === 16000
        ) {
          ConfigurationUtils.showErrorMessage1(
            data.errorDetail.errorCode,
            intl
          );
        } else if (errorcode === 12010) {
          ConfigurationUtils.showErrorMessage1(errorMsg, intl);
        } else if (errorMsg === "info.proxy.error") {
          ConfigurationUtils.showErrorMessage1(errorMsg, intl);
        } else {
          ConfigurationUtils.showSuccessMessage(
            "usage.advanced.send.usage.success",
            intl
          );
        }
      } else {
        ConfigurationUtils.showSuccessMessage(
          "usage.report.sent.success",
          intl
        );
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}

export function getAllUsers(postData) {
  if (postData === undefined) {
    postData = {
      pagenation: {
        required: true,
        style: "default",
        totalCount: 0,
        pageNumber: 1,
        pageSize: getPageSize(),
        sortBy: "createdtime",
        sortOrder: "ASC"
      }
    };
  }
  var url = RestWebServicePaths.APLS_WS_REQ_USER_LIST;

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserList(data, undefined);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function addUser(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_CREATE;
  let email = postData["email"];
  postData["tenantAdmin"] = encryptText(postData["tenantAdmin"]);
  postData["firstName"] = encryptText(postData["firstName"]);
  postData["lastName"] = encryptText(postData["lastName"]);
  postData["email"] = encryptText(postData["email"]);
  postData["passwordResetBy"] = encryptText(postData["passwordResetBy"]);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserList(data, undefined);
      let errorDetail = data.errorDetail;
      if (errorDetail !== undefined) {
        Dispatcher.dispatch({
          type: "USER_CREATE_SUCCESS_ERROR",
          payload: LocaleUtility.getLocaleErrorMessage(
            errorDetail.errorCode,
            errorDetail.parameters
          )
        });
      } else {
        Dispatcher.dispatch({
          type: "USER_CREATE_SUCCESS_OK",
          payload: LocaleUtility.getLocaleMessage("um.create.user", {
            user: email
          })
        });
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestPACEntitlements(elements, validateNumberInput) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_PAC_ENTITLEMENTS +
    "?activationCode=" +
    elements.pacCode.value;

  fetchAsync(url)
    .then(data => {
      LicenseUtility.getPACEntitlements(
        data,
        elements.pacCode.value,
        validateNumberInput
      );
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function requestQuantityActivate(activationCode, environment, elements) {
  var url = RestWebServicePaths.APLS_WS_REQ_PAC_ACTIVATE_QTY;
  var postData = {
    activationCode: activationCode,
    environment: environment,
    entitlementWiseQuantityList: { entitlementWiseQuantity: elements }
  };

  let loadSpinAction = "FETCH_PAC_LICENSE_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processPACLicenseList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getUserAggrement(changedDB) {
  if (changedDB) {
    let type = "USER_PRIVACY_SUCCESS_OK";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleMessage("label.saved.successfully")
    });
  }

  var url = RestWebServicePaths.APLS_WS_REQ_USER_AGREEMENT;
  fetchAsync(url)
    .then(data => {
      LicenseUtility.getUserAggrement(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function saveUserAggrement(elements) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_AGREEMENT;
  var postData = { agreementName: elements };
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      getUserAggrement(true);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function deleteUser(userData) {
  var url = '';
  if(getSession(false).dbType==='LDAP'){
    url = RestWebServicePaths.APLS_WS_REQ_LDAP_USER_DELETE;
  }else{
    url = RestWebServicePaths.APLS_WS_REQ_USER_DELETE;
  }
  //var userData ={"email": key}
  fetchPOST(url, JSON.stringify(userData))
    .then(data => {
      UserUtility.displayUserList(data, undefined);
      Dispatcher.dispatch({
        type: "USER_DELETE_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage("um.delete.user", {
          user: userData.email
        })
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function updateUser(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_UPDATE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserList(data, postData.username);
      Dispatcher.dispatch({
        type: "USER_UPDATE_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage("um.update.user", {
          user: postData.username
        })
      });
      var data = getSession(true);
            data.then(session => {
				 Dispatcher.dispatch({
			         type: "REFRESH_AVATAR"
			      });
            })
            .catch(error => {
                // handle error
            });
     
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function changeStatus(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_STATUS;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserList(data, postData.username);
      let msg = LocaleUtility.getLocaleMessage("um.alert19", {
        user: postData.username
      });
      if (postData.status === "inactive") {
        msg = LocaleUtility.getLocaleMessage("um.alert20", {
          user: postData.username
        });
      }
      Dispatcher.dispatch({
        type: "CHANGE_STATUS_SUCCESS_OK",
        payload: msg
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function searchGroup(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_SEARCH;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function addGroupUser(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_ADD_GROUP;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.addGroupUser(data, postData.username);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getAllGroups(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_GROUPLIST;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function createGroupUser(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_GROUP_CREATE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
      let errorDetail = data.errorDetail;
      if (errorDetail !== undefined) {
        let params = {};
            params["group"] = errorDetail.parameters.entry[0].value.value !== undefined ? 
                              errorDetail.parameters.entry[0].value.value : 
                              errorDetail.parameters.entry[0].value;
          ConfigurationUtils.showErrorWithMessageParam(
            params,
            errorDetail.errorCode,
            intl
          );
      } else {
        Dispatcher.dispatch({
          type: "GROUP_CREATE_SUCCESS_OK",
          payload: LocaleUtility.getLocaleMessage("um.create.group", {
            grpName: postData.groupName
          })
        });
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function deleteUserGroup(userData) {
  let postData = {
    groupName: (userData.groupName===undefined)?null:userData.groupName,
    groupDesc: (userData.groupDesc===undefined)?null:userData.groupDesc,
    groupEmail: (userData.groupEmail===undefined)?null:userData.groupEmail,
    groupCreatedTime: (userData.groupCreatedTime===undefined)?null:userData.groupCreatedTime,
    groupModifiedTime: (userData.groupModifiedTime===undefined)?null:userData.groupModifiedTime,
    roleList: [],
    pagenation:userData.pagenation,
    isAdmin:(userData.isAdmin===undefined)?null:userData.isAdmin,
    roleName:(userData.roleName===undefined)?null:userData.roleName
  };
  var url = RestWebServicePaths.APLS_WS_REQ_GROUP_DELETE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
      Dispatcher.dispatch({
        type: "GROUP_DELETE_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage("um.delete.group", {
          grpName: userData.groupName
        })
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function updateGroup(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_GROUP_UPDATE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
      Dispatcher.dispatch({
        type: "GROUP_UPDATE_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage("um.update.group", {
          grpName: postData.groupName
        })
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function searchUser(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_SEARCH_USER_BY_GROUP;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserForGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function addUserToGroup(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_ADD_USER_TO_GROUP;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function addProductToGroup(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_PRODUCT_TO_GROUP;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function removeProductFromGroup(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_PRODUCT_TO_GROUP;
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      var prodMap = new Map();

      data.productList.map(item => {
        var prods = item.split(":");
        if (prods.length === 2) {
          prodMap.set(prods[0], prods[1]);
        } else {
          prodMap.set(item, item);
        }
        return "";
      });

      var payload = {
        productList: prodMap,
        groupName: data.groupName
      };

      Dispatcher.dispatch({
        type: "PRODUCT_FETCH_FOR_GROUP",
        payload: payload
      });

      Dispatcher.dispatch({
        type: "PRODUCT_REMOVE_GROUP_SPIN_DONE",
        payload: ""
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function removeGroupFromUser(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_GROUP_DEL;
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayUserList(data, postData.userName);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function removeUserFromGroup(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_DEL_FROM_GROUP;
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.showSuccessMessage("disassociate.message", intl);
      UserUtility.userGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function buyMoreLicenses(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_BUY_MORE_LIC;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayLicenseList(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(undefined);
      LicenseUtility.processError(status, error);
    });
}

export function updateLicense(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_UPDATE_REQUEST_LIC;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.displayLicensResp(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getLicenseReport(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_REPORT;

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.getLicenseReport(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getSearchResult(postData) {
  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_REPORT_SEARCH;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.getLicenseReport(data, true);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function reservationPoolList(request, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_POOL_LIST;
  var postData = {
    pool: request,
    pagenation: request.pagenation,
    isTreeView: true
  };
  ConfigurationUtils.showLoadMask("", intl);
  let sortIndex = request.sortIndex;
  let showPoolMapping = request.showPoolMapping;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        //if(data.poolList && data.poolList.pool) {
        data["showPoolMapping"] = showPoolMapping;
        ReservationUtils.processPoolsList(data, sortIndex);
        //}
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function deleteReservationPool(request, poolName, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_DELETE_POOL;
  var postData = { name: poolName };
  ConfigurationUtils.showLoadMask("", intl);
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      let messageParams = {};
      messageParams["poolName"] = poolName;
      if (data.status === "SUCCESS") {
        ReservationUtils.processDeletePool(data);
        /*ConfigurationUtils.showSuccessMessageWithParams(
          messageParams,
          "info.reservation.delete",
          intl
        );*/
      } else if (data.status === "FAIL") {
        if (data.errorList && data.errorList.errorDetail) {
          let errorDetail = data.errorList.errorDetail[0];
          if (errorDetail.errorCode === 20010) {
            ConfigurationUtils.showErrorWithMessageParam(
              messageParams,
              errorDetail.errorCode,
              intl
            );
          }
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function findPoolNames(poolName, intl) {
  var url =
    RestWebServicePaths.APLS_WS_RESERVATION_POOL_NAMES +
    "?poolName=" +
    poolName;
  fetchAsync(url)
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processPoolNames(data);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function createReservationPool(postData, request, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_CREATE_POOL;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      let params = {};
      params["poolName"] = postData.name;
      params["parentName"] = postData.parent;
      if (data.status === "SUCCESS") {
        reservationPoolList(request, intl);
        ReservationUtils.processCreatePool();
        /*ConfigurationUtils.showSuccessMessageWithParams(
          params,
          "info.reservation.add",
          intl
        );*/
      } else if (data.status === "FAIL") {
        if (data.errorList && data.errorList.errorDetail) {
          let errorDetail = data.errorList.errorDetail[0];
          if (errorDetail.errorCode === 12001) {
            ConfigurationUtils.showErrorWithMessageParam(
              params,
              errorDetail.errorCode,
              intl
            );
          } else if (errorDetail.errorCode === 12012) {
            ConfigurationUtils.showErrorWithMessageParam(
              params,
              errorDetail.errorCode,
              intl
            );
          } else if (errorDetail.errorCode === 12013) {
            ConfigurationUtils.showErrorWithMessage(
              errorDetail.errorMessage,
              intl
            );
          }
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function findPoolMembers(poolName, intl) {
  var url =
    RestWebServicePaths.APLS_WS_RESERVATION_POOL_MEMBERS;
  let postData = {
    name: poolName
  }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processPoolMembers(data);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function addMemeberToPool(postData, intl, action, type, value) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_ADD_MEMBER_TO_POOL;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processAddDeleteMemberToPool(
          data,
          action,
          type,
          value
        );
      } else {
        let errorList = data.errorList;
        let errorCode = undefined;
        if(errorList !== undefined) {
          let errorDetails = errorList.errorDetail;
          if(errorDetails !== undefined && errorDetails.length >= 1) {
            for(let i=0;i<errorDetails.length;i++) {
              if(errorDetails[i].errorCode === 12002) {
                errorCode = 12002;
              }
            }
          }
        }
        if(errorCode!== undefined) {
          let t = LocaleUtility.getLocaleMessage("resv.add.user.label");
          if(type === "ipaddress") {
              t = LocaleUtility.getLocaleMessage("resv.add.ip.label");
          } else if(type === "hostid"){
              t = LocaleUtility.getLocaleMessage("resv.add.host.label");
          } else if(type === "clientId"){
              t = LocaleUtility.getLocaleMessage("resv.add.client.label");
          }
          let params = {};
            params["type"] = t;
            params["value"] = value;
            ConfigurationUtils.showErrorWithMessageParam(params, errorCode, intl );
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function deleteMemeberToPool(postData, intl, action, type, value) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_DELETE_MEMBER_TO_POOL;
  ConfigurationUtils.showLoadMask("", intl);
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processAddDeleteMemberToPool(
          data,
          action,
          type,
          value
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listPorductNames(
  intl,
  sortIndex,
  pagination,
  showMsg,
  prdName, redirect
) {
  let operationParamenter = (prdName!=="")?prdName:"borrow_init";
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_IDS + "?operation="+operationParamenter;
  fetchAsync(url)
    .then(data => {
      if (
        data !== undefined &&
        data.productNameList !== undefined) {
        //ReservationUtils.processProductName(data);
        let count = 0;
        let defaultName = prdName;
        if(data.productNameList.value !== undefined && (defaultName === undefined || defaultName === "")) {
          data.productNameList.value.map(element => {
            if (count === 0) {
              let val = element.split(":");
              defaultName = val[0];
            }
            count++;
            return "";
          });
        }
        if(defaultName === "") {
          defaultName = "1";
        }
        if (defaultName !== "") {
          let request = {
            productName: defaultName,
            pagenation: pagination,
            sortIndex: sortIndex
          };
          listFeaturesByProductName(intl, request, data.productNameList);
          if (showMsg) {
            if(prdName === "1") {
              prdName = "";
            }
            let params = {};
            params["productName"] = prdName;
            ConfigurationUtils.showSuccessMessageWithParams(
              params,
              "resv.product.mapping.success",
              intl
            );
          }
          if(redirect) {
            let type = "REDIRECT_FEATURE_BASE_RESERVATION";
            let payload = {};
            Dispatcher.dispatch({
                type: type,
                payload: payload
            });
          }
        }
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function listFeaturesByProductName(intl, params, productNameList) {
  if (params.productName !== undefined) {
    var url =
      RestWebServicePaths.APLS_WS_RESERVATION_FEATURE_BASE_LIST +
      "?productName=" +
      params.productName;
    let sortIndex = params.sortIndex;
    ConfigurationUtils.showLoadMask("", intl);
    fetchAsync(url)
      .then(data => {
        if (data.status === "SUCCESS") {
          ReservationUtils.processProductNameFeatures(
            data,
            sortIndex,
            productNameList
          );
        }
      })
      .catch((status, error) => {
        LicenseUtility.processError(status, error);
        ConfigurationUtils.hideLoadMask(intl);
      });
  }
}
export function listPoolMappingProducts(poolName, intl) {
  var url =
    RestWebServicePaths.APLS_WS_RESERVATION_POOL_MAPPING_PRODUCTS +
    "?poolName=" +
    poolName;
  ConfigurationUtils.showLoadMask("", intl);
  fetchAsync(url)
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processPoolMappingProducts(data);
      } else {
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function listAssociatedPools(postData, intl) {
  var url =
    RestWebServicePaths.APLS_WS_RESERVATION_GET_ASSOCIATE_DISASSOCIATE_POOLS;
  ConfigurationUtils.showLoadMask("", intl);
  let sortIndex = postData.sortIndex;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        localStorage.setItem("hasFeaureConfigured", data.hasFeaureConfigured);
        ReservationUtils.processAssociatedPools(data, sortIndex);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function allowOrBlockPool(postData, intl, searchPoolName, searchParentName, searchDescription) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_ASSOCIATE_POOLS;
    ConfigurationUtils.showLoadMask("", intl);
    fetchPOST(url, JSON.stringify(postData))
        .then(data => {
            if(data.status === "SUCCESS") {
                let searchAction = true;
                if(searchPoolName === "" && searchParentName === "" && searchDescription === "") {
                    searchAction = false;
                }
                let request = {
                    featureId: postData.featureId,
                    featureVersion: postData.featureVersion,
                    uniqueProductDefinitionKey: postData.uniqueProductDefinitionKey,
                    reservedStatus: 0,
                    treeView: true,
                    isSearchAction: searchAction,
                    pool: {
                        poolName: searchPoolName,
                        poolDesc: searchDescription,
                        parent: searchParentName
                    }
                }
                listAssociatedPools(request, intl);
            } else {
                if(data.errorDetail && data.errorDetail.errorCode === 30001) {
                  ConfigurationUtils.showErrorWithMessage(LocaleUtility.getLocaleMessage("reserved.capacity.less.than.available"), intl);
                  ConfigurationUtils.hideLoadMask(intl);
                } else if(data.errorDetail && data.errorDetail.errorCode !== 1000 && data.errorDetail.errorCode !== 30001) {
                  ConfigurationUtils.showErrorWithMessage(data.errorDetail.customMessage, intl);
                  ConfigurationUtils.hideLoadMask(intl);
                } else {
                  ConfigurationUtils.showErrorMessage1("10000", intl);
                  ConfigurationUtils.hideLoadMask(intl);
                }
            }
            
        }).catch((status, error) => {
            ConfigurationUtils.hideLoadMask(intl);
            LicenseUtility.processError(status, error);
        });
}
export function listPoolsForAssociatedPopup(
  featureId,
  featureVersion,
  productName,
  reservedStatus,
  intl,
  sortIndex,
  pagination,
  pool
) {
  let postData = {
    featureId: featureId,
    featureVersion: featureVersion,
    uniqueProductDefinitionKey: productName,
    reservedStatus: reservedStatus,
    pagenation: pagination,
    pool: pool
  };
  var url =
    RestWebServicePaths.APLS_WS_RESERVATION_GET_ASSOCIATE_DISASSOCIATE_POOLS;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processAssociatedPoolsForPopup(data, sortIndex);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function associateOrDisassociatePools(
  featureId,
  featureVersion,
  productName,
  poolNames,
  intl,
  isAssociated
) {
  let postData = {
    featureId: featureId,
    featureVersion: featureVersion,
    uniqueProductDefinitionKey: productName,
    groupIds: poolNames
  };
  var url = RestWebServicePaths.APLS_WS_RESERVATION_ASSOCIATE_POOLS;
  if (!isAssociated) {
    url = RestWebServicePaths.APLS_WS_RESERVATION_DISASSOCIATE_POOLS;
  }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      /*if(data.status === "SUCCESS") {
                ReservationUtils.processAssociateORDisassociate(data);
            } else {
                if(data.errorDetail && data.errorDetail.errorCode !== 1000) {
                    ConfigurationUtils.showErrorWithMessage(data.errorDetail.customMessage, intl);
                    ConfigurationUtils.hideLoadMask(intl);
                } else {
                    ConfigurationUtils.showErrorMessage1("10000", intl);
                    ConfigurationUtils.hideLoadMask(intl);
                }
            }*/
      ReservationUtils.processAssociateORDisassociate(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function saveProductFeatureMapings(groupValues, prodcutName, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_PRODUCT_MAPPING_SAVE;
  let postData = {
    groupSelectedValues: groupValues,
    productName: prodcutName
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ReservationUtils.processProductFeatureSave(data);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function disassociateProductMapings(groupValues, productName, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_PRODUCT_DISASSOCIATE_POOLS;
  let postData = {
    groupSelectedValues: groupValues,
    productName: productName
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
              "disassociate.message",
              intl
            );
        ReservationUtils.processProductPoolDisassociate(data);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadAdvancedReports(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_USAGE_ADVANCED_REPORT_DOWNLOAD;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.EXCEL_MIMETYPE
        );
      } else {
        let errorDetail = data.errorDetail;
        if (errorDetail !== undefined && errorDetail.errorCode !== 10000) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function updateAdminCommuterDetails(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_UPDATE_COMMUTER;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        UsageReportsUtils.processUpdateCommuterDetails(data);
        ConfigurationUtils.showSuccessMessage("11043", intl);
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1("11044", intl);
        ConfigurationUtils.hideLoadMask(intl);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageInuseReports(postData, intl, format) {
  var url = RestWebServicePaths.APLS_WS_USAGE_EXPORT_INUSE;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageHistoryReports(postData, intl, format) {
  var url = RestWebServicePaths.APLS_WS_USAGE_EXPORT_HISTORY;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageRejectedReports(postData, intl,format) {
  var url = RestWebServicePaths.APLS_WS_USAGE_EXPORT_REJECTED;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageConsumptionReports(postData, intl, format) {
  var url = RestWebServicePaths.APLS_WS_USAGE_EXPORT_CONSUMPTION;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function refreshPage(location) {
  if (location === FrontendPagePath.LICENSE_PATH) {
    getUserAggrement(false);
  } else if (
    location === FrontendPagePath.LICENSE_VIEW_PATH ||
    location === FrontendPagePath.CONTEXT_PATH + "/" ||
    location === FrontendPagePath.CONTEXT_PATH ||
    location === FrontendPagePath.HOME_PATH
  ) {
    getAllLicenses(undefined, "ACTIVE", undefined);
  } else if (location === FrontendPagePath.LICENSE_ARCHIVE_PATH) {
    getAllLicenses(undefined, "ARCHIVED", undefined);
  } else if (
    location === FrontendPagePath.LICENSE_BORROW_PATH ||
    location === FrontendPagePath.LICENSE_BORROW_FORM_PATH 
  ) {
    getProducts("borrow_enabled", true);
  } else if (
    location === FrontendPagePath.LICENSE_NAMEDUSER_PATH 
  ) {
    let params = {
            "productName": undefined
        }
    getAllNamedUserLicenses(params, undefined);
  }else if (location === FrontendPagePath.REPORTS_PATH) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      sortBy: "clientLastAccessTime",
      sortOrder: "DESC",
      pageSize: getPageSize()
    };
    var postData = {
      pagination: pagination
    }
    getLicenseReport(postData);
  } else if (location === FrontendPagePath.USER_MGMT_PATH) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "createdtime",
      sortOrder: "ASC"
    };
    let request = {
      pagenation: pagination
    };
    getAllUsers(request);
  } else if (location === FrontendPagePath.USER_GROUPS_PATH) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "groupCreatedTime",
      sortOrder: "ASC"
    };
    let request = {
      pagenation: pagination
    };
    getAllGroups(request);
  } else if (location === FrontendPagePath.USER_TENANTS_PATH) {
    let request = {
        pagination: {
          required: true,
          style: "default",
          totalCount: 0,
          pageNumber: 1,
          pageSize: getPageSize(),
          sortBy: "id",
          sortOrder: "DESC",
          editObject: undefined
      }
    }
    listTenantNames(request, undefined);
  } else if (location === FrontendPagePath.BUYMORE_PATH) {
    const formPayload = {
      productName: null
    };
    buyMoreLicenses(formPayload);
  } else if (
    location === FrontendPagePath.CONFIG_PATH ||
    location === FrontendPagePath.MAIN_CONFIG_PATH
  ) {
    findConfigurationSetting(undefined);
  } else if (location === FrontendPagePath.USER_CONFIG_PATH) {
    findUserConfiguration(undefined);
  } else if (location === FrontendPagePath.LDAP_CONFIG_PATH) {
    findLDAPConfiguration(undefined, "", false);
  } else if (location === FrontendPagePath.IDM_CONFIG_PATH) {
    findIDMConfiguration(undefined);
  } else if (location === FrontendPagePath.CONTROL_TOWER_PATH) {
    findCTAssetConfiguration(undefined);
  } else if (location === FrontendPagePath.USAGE_CONFIG_PATH) {
    findUsageHubConfiguration(undefined);
  } else if (location === FrontendPagePath.DB_CONFIG_PATH) {
    findDatabaseConfiguration(undefined);
  } else if (location === FrontendPagePath.APP_LOGS_CONFIG) {
    listAppLogs({}, undefined);
  } else if (location === FrontendPagePath.APP_CONFIG_ENTRIES) {
    listConfigEntries({}, undefined);
  } else if (location === FrontendPagePath.APP_CONFIG_SAML_ENTRIES) {
    //findLDAPConfiguration(undefined, "", false);
  } else if (location === FrontendPagePath.USER_TENANT_LICENSE_PATH) {
    getTenantProducts("borrow_init", true, true);
    loadTenantNames({}, undefined, true);
  } else if (location === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION) {
    listSubNotification({}, undefined); 
  } else if (location === FrontendPagePath.APP_CONFIG_AUTH_TOKEN) {
    listAuthToken(undefined); 
  } 
  else if (location === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
    let params = {};
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "featureId",
      sortOrder: "DESC"
    };
    params["productName"] = "";
    params["pagination"] = pagination;
    params["sortIndex"] = 0;
    //listUsageReports(undefined, params);
    let type = "REFRESH_USAGE_LIST_PAGE";
    let payload = {
        actionType: "refreshAction"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
  } else if (location === FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH) {
    let params = {};
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "featureId",
      sortOrder: "DESC"
    };
    params["productName"] = "";
    params["tenant"] = "";
    params["pagination"] = pagination;
    params["sortIndex"] = 0;
    let type = "REFRESH_TENANT_USAGE_LIST_PAGE";
    let payload = {
        actionType: "refreshAction"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
  } else if (
    location === FrontendPagePath.RESERVATION_POOL_MANAGE_PATH
  ) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "poolCreationTime",
      sortOrder: "DESC"
    };
    let params = {
      poolName: "",
      poolDesc: "",
      parent: "",
      poolCreatedBy: "",
      poolCreationTime: "",
      pagenation: pagination,
      sortIndex: 3,
      searchAction: false
    };
    params["showPoolMapping"] = false;
    reservationPoolList(params, undefined);
  } else if (
    location === FrontendPagePath.RESERVATION_FEATURE_BASE_PATH
  ) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize()
    };
    listPorductNames(undefined, 0, pagination, false, "", true);
  } else if (
    location === FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH
  ) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize()
    };
    listPorductNames(undefined, 0, pagination, false, "");
  } else if (location === FrontendPagePath.COMMUTER_CHECKOUT_PATH) {
    ConfigurationUtils.hideLoadMask(undefined);
    let data = {};
    let resultsList = {};
    data["resultsList"] = resultsList;
    CommuterUtils.processCheckoutSuccess(data, false);
  } else if (location === FrontendPagePath.COMMUTER_CHECKIN_PATH) {
    ConfigurationUtils.hideLoadMask(undefined);
    let data = {};
    let resultsList = {};
    data["resultsList"] = resultsList;
    CommuterUtils.processCheckInSuccess(data, false);
    }else if(location === FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH){
        getProductFeatureLicenses(undefined, "ACTIVE", undefined);
  } else if (
    location === FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH
  ) {
    let pagination = {
      required: true,
      style: "default",
      totalCount: 0,
      pageNumber: 1,
      pageSize: getPageSize(),
      sortBy: "startDate",
      sortOrder: "DESC"
    };
    let params = {
      pagination: pagination,
      sortIndex: 2,
    };
    listInstanceUsageData(undefined, params, true);
  } else if (
    location === FrontendPagePath.USAGE_REPORT_GENERIC_PATH
  ) {
    let params = {
      productId: usageProductId
    };
    getAllUsage(params, "allfields");
  }else {
    Dispatcher.dispatch({
      type: "OK_SPIN_DONE",
      payload: ""
    });
  }
}
export function downloadInuseLicense(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_DOWNLOAD_USAGE_LICENSE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.TEXT_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageSummaryReports(postData, intl, format) {
  var url = RestWebServicePaths.APLS_WS_DOWNLOAD_USAGE_SUMMARY;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadUsageTenantWiseSummaryReport(postData, intl, format, tenant) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_LIST_TENANT_WISE_DOWNLOAD+"?tenantname="+tenant;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadInstalledLicenses(
  selectedOption,
  intl,
  isAllKeys,
  licenseStatusType,
  pagination,
  mimetype
) {
  var postData;
  var url = RestWebServicePaths.APLS_WS_INSTALLED_LICENSE_DOWNLOAD;
  if (isAllKeys) {
    url = RestWebServicePaths.APLS_WS_ALL_LICENSE_KEYS_DOWNLOAD;
  }
  if (selectedOption === undefined) {
    postData = {
      requestLicenseType: licenseStatusType,
      pagination:pagination,
      format:mimetype
    };
  } else {
    postData = {
      requestLicenseType: licenseStatusType,
      productNameList: { value: [selectedOption] },
      pagination:pagination,
      format:mimetype
    };
  }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadLicenseReports(postData, intl, mimetype) {
  var url = RestWebServicePaths.APLS_WS_LICENSE_REPORT_DOWNLOAD;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format=mimetype;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function getUserDetails(userName) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_USER_DETAIL; //+ "?username=" + userName

  fetchAsync(url)
    .then(data => {
      UserUtility.getUserDetail(data, false);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function downloadUserOrGroupList(actionType, intl, mimetype) {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_LIST_EXPORT;
  if (actionType === "group") {
    url = RestWebServicePaths.APLS_WS_REQ_GROUP_LIST_EXPORT;
  }
  let postData = {};
  postData.format = mimetype;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function udpateUserDetail(elements) {
  var encryptedresetpwd;
  var encryptedpwd;
  var email;
  var firstName;
  var lastName;
  var userName;
  var url = RestWebServicePaths.APLS_WS_REQ_UPDATE_USER_DETAILS;
  var message = elements.userName.value;
  if(elements.firstName.value !== undefined && elements.firstName.value !== '') {
    message += elements.firstName.value;
    firstName=encryptText(elements.firstName.value);
  }
  if(elements.lastName.value !== undefined && elements.lastName.value !== '') {
    message += elements.lastName.value;
    lastName=encryptText(elements.lastName.value);
  }
  if(elements.email.value !== undefined && elements.email.value !== '') {
    message += elements.email.value;
    email=encryptText(elements.email.value);
  }
  if(elements.password.value !== undefined && elements.password.value !== '') {
    message += elements.password.value;
    encryptedpwd=encryptText(elements.password.value);
    
  }
  if(elements.repassword.value !== undefined && elements.repassword.value !== '') {
    message += elements.repassword.value;
    encryptedresetpwd=encryptText(elements.repassword.value);
  }
  if(elements.userName.value !== undefined && elements.userName.value !== '') {
    userName=encryptText(elements.userName.value);
  }
  var hash = CryptoJS.HmacSHA256(message, getToken()+elements.userName.value);
  var hashValue = CryptoJS.enc.Base64.stringify(hash);
  var postData = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    username: userName,
    password: encryptedpwd,
    resetpwd: encryptedresetpwd,
    validationStr: hashValue
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.getUserDetail(data, true);   
      var data = getSession(true);
            data.then(session => { 
				Dispatcher.dispatch({
			                     	type: "REFRESH_AVATAR"
			                });                
            })
            .catch(error => {
                // handle error
            });   
    })    
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function submitCommuterCheckout(formData, intl) {
  var url = RestWebServicePaths.APLS_WS_SUBMIT_COMMUTER_CHECKOUT;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOSTMultiFormData(url, formData)
    .then(data => {
      if (data.status === "SUCCESS") {
        CommuterUtils.processCheckoutSuccess(data, true);
      } else if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if (
          errorDetail &&
          (errorDetail.errorCode === 16001 ||
            errorDetail.errorCode === 16002 ||
            errorDetail.errorCode === 16003 ||
            errorDetail.errorCode === 16004 ||
            errorDetail.errorCode === 19008 ||
            errorDetail.errorCode === 30117788)
        ) {
          ConfigurationUtils.showErrorWithMessageParam(
            buildErrorParams(errorDetail.parameters),
            errorDetail.errorCode,
            intl
          );
        } else {
          if (
            errorDetail !== undefined &&
            errorDetail.errorCode !== undefined
          ) {
            if (errorDetail.errorCode === 16015) {
              ConfigurationUtils.showErrorWithMessage(
                errorDetail.errorMessage,
                intl
              );
            } else {
              ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
            }
          } else {
            ConfigurationUtils.showErrorMessage1("10000", intl);
          }
        }
        ConfigurationUtils.hideLoadMask(intl);
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function submitCommuterCheckin(formData, intl) {
  var url = RestWebServicePaths.APLS_WS_SUBMIT_COMMUTER_CHECKIN;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOSTMultiFormData(url, formData)
    .then(data => {
      if (data.status === "SUCCESS") {
        CommuterUtils.processCheckInSuccess(data, true);
      } else {
        let errorDetail = data.errorDetail;
        if (errorDetail !== undefined && errorDetail.errorCode !== undefined) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadCommuterLicense(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_DOWNLOAD_COMMUTER_LICENSE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        CommuterUtils.processDownloadSuccess(data);
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.TEXT_MIMETYPE
        );
      } else if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if (errorDetail) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
          ConfigurationUtils.hideLoadMask(intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
          ConfigurationUtils.hideLoadMask(intl);
        }
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function discardCommuterLicense(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_DOWNLOAD_COMMUTER_LICENSE_DISCARD;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        CommuterUtils.processCheckoutSuccess(data, true);
        ConfigurationUtils.showSuccessMessage("16008", intl);
      } else if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if (errorDetail) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
          ConfigurationUtils.hideLoadMask(intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
          ConfigurationUtils.hideLoadMask(intl);
        }
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function buildErrorParams(values) {
  let errorParams = {};
  let field = "";
  if (values !== undefined) {
    values.entry.map(item => {
      field = "";
      Object.entries(item).map(([key, value]) => {
        if (key === "key") {
          field = value;
        } else if (key === "value" && field !== "") {
          errorParams[field] = (value.value !== undefined ? value.value: value);
        }
        return "";
      });
      return "";
    });
  }
  return errorParams;
}

export function sendMailCommuterLicense(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_DOWNLOAD_COMMUTER_LICENSE_SEND_MAIL;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        CommuterUtils.processDownloadSuccess(data);
        ConfigurationUtils.showSuccessMessage("16011", intl);
      } else if (data.status === "FAIL") {
        let errorDetail = data.errorDetail;
        if (errorDetail) {
          if (errorDetail.errorCode === 11045) {
            ConfigurationUtils.showErrorWithMessageParam(
              buildErrorParams(errorDetail.parameters),
              errorDetail.errorCode,
              intl
            );
          } else {
            ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
          }
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function getUserFeatures() {
  var url = RestWebServicePaths.APLS_WS_REQ_USER_PRODUCTS;
  var products = getSession().productList.split(",");

  var validFeatures = [], reqData = {
          "productNameList": { "value": products }
      }

  return fetchPOST(url, JSON.stringify(reqData)).then(data => {
      if (data.entityList !== undefined && data.entityList.value.length > 0) {
          validFeatures = data.entityList.value;
      }
      return validFeatures;
  }).catch((status, error) => {
      LicenseUtility.processError(status, error);
  });
}

export function getProductGroups(groupName) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_PRODUCT_TO_GROUP +
    "?groupname=" +
    groupName;

  fetchAsync(url)
    .then(data => {
      var prodMap = new Map();

      data.productList.map(item => {
        var prods = item.split(":");
        if (prods.length === 2) {
          prodMap.set(prods[0], prods[1]);
        } else {
          prodMap.set(item, item);
        }
        return "";
      });

      var payload = {
        productList: prodMap,
        groupName: data.groupName
      };

      Dispatcher.dispatch({
        type: "PRODUCT_FETCH_FOR_GROUP",
        payload: payload
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function setSession(data) {
  var session,
    container = false,
    admin = false,
    dbType = "",
    aplsVersion = "",
    helpDocLinkAppVersion="",
    aplsBuildNumber = "",
    aplsLicStDate = "",
    aplsLicExpDate = "",
    currentUser = "",
    lockCode = "",
    isIDM = false,
    isSaml = false,
    mail = false,
    roletype = "",
    enableTokenCalculator=false,
    instantOnTab=false,
    productList = "",
    currentTenantName="",
    currentTenantId="",
    pageSize = 10,
    isTenantAdminUser="N",
    ctFlag=false,
    documentationUrl = "";

  var map = [];
  if (data.responseMap !== undefined) {
    data.responseMap.entry.forEach(item => {
      map[item.key] = item.value;
      if(item.key === 'borrowFlag') {
        if(item.value !== undefined) {
          localStorage.setItem("borrowFlag", item.value.value);
        }
      }
    });
  }

  if (map !== []) {
    var message=data.user.userName+map.isadmin.value+map.roleList.value;
    var key=encryptTextForKey(map.token.value);
    //alert(key);
    var hash = CryptoJS.HmacSHA256(message, key);
    //  var hash = CryptoJS.HmacSHA256(message, data.user.userName);
    var hashValue = CryptoJS.enc.Base64.stringify(hash);  
    // alert("---message session --"+message +"---"+hashValue+">>message<<"+map.message.value);
    if(hashValue !== map.message.value){
      Dispatcher.dispatch({
      type: "NOTIFICATION_CLOSE"
      });
      Dispatcher.dispatch({
        type: "COMP_CLOSE"
      });
      requestLogout();
      Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
    }
    session = map;
    container =
      session.iscontainer.value !== undefined
        ? session.iscontainer.value
        : false;
    admin = session.isadmin.value !== undefined ? session.isadmin.value : false;
    dbType =
      session.iseditable.value !== undefined ? session.iseditable.value : "";
    isIDM = session.isidm.value !== undefined ? session.isidm.value : false;
    isSaml = session.isSaml.value !== undefined ? session.isSaml.value : false;
    mail = session.mail.value !== undefined ? session.mail.value : false;
    currentUser =
      session.currentUser.value !== undefined ? session.currentUser.value : "";
    if(session.pageSize !== undefined) {
      pageSize = session.pageSize.value;
    }
    aplsVersion =
      session.AppVersion.value !== undefined ? session.AppVersion.value : "";
    helpDocLinkAppVersion = session.helpDocAppVersion.value !== undefined ? session.helpDocAppVersion.value : "";
    currentTenantName = session.currentTenantName !== undefined ? session.currentTenantName.value : "";
    currentTenantId = session.currentTenantId !== undefined ? session.currentTenantId.value : "";
    isTenantAdminUser = session.tenantAdminUserFlag !== undefined ? session.tenantAdminUserFlag.value : "";
    enableTokenCalculator = ( session.enableTokenCalculator !== undefined && session.enableTokenCalculator.value !== undefined ) ? session.enableTokenCalculator.value : false;
    aplsBuildNumber =
      session.AplsBuildNumber.value !== undefined
        ? session.AplsBuildNumber.value
        : "";
    aplsLicStDate = (session.AplsLicStDate !== undefined &&
      session.AplsLicStDate.value !== undefined) ? session.AplsLicStDate.value : "";
    aplsLicExpDate =
      session.AplsLicExpDate.value !== undefined
        ? session.AplsLicExpDate.value
        : "";
    lockCode =
      session.DeviceHostAddress.value !== undefined
        ? session.DeviceHostAddress.value
        : "";
    productList = 
      session.productList.value !== undefined 
        ? session.productList.value 
        : "";
    if (session.roletype !== undefined) {
      roletype =
        session.roletype.value !== undefined ? session.roletype.value : "";
    }
    if (session.ServerTimeZone.value !== undefined) {
      localStorage.setItem("ServerTimeZone", session.ServerTimeZone.value);
    }
    if (session.ServerTimeZoneOffset.value !== undefined) {
      localStorage.setItem(
        "ServerTimeZoneOffset",
        session.ServerTimeZoneOffset.value
      );
    } 
    if(session.leadPointer !== undefined && session.leadPointer.value !== undefined){
        setLead(session.leadPointer.value);
    }
    instantOnTab = (session.instantonflag !== undefined && 
                  session.instantonflag.value !== undefined) ? 
                    session.instantonflag.value : false;   
    ctFlag = (session.ctFlag !== undefined && 
                  session.ctFlag.value !== undefined && session.ctFlag.value === "true") ? 
                    true : false;  
    documentationUrl = (session.DocumentationURL !== undefined && 
                  session.DocumentationURL.value !== undefined) ? 
                    session.DocumentationURL.value : "";
  }
  currentTntName = currentTenantName;
  currentTntId = currentTenantId;
  sessionMap = {
    container: container,
    admin: admin,
    dbType: dbType,
    isidm: isIDM,
    saml: isSaml,
    mail: mail,
    currUser: currentUser,
    lockCode: lockCode,
    aplsLicExpDate: aplsLicExpDate,
    aplsLicStDate: aplsLicStDate,
    aplsBuildNumber: aplsBuildNumber,
    aplsVersion: aplsVersion,
    helpDocLinkAppVersion: helpDocLinkAppVersion,
    user: data.user,
    roletype: roletype,
    productList:productList,
    enableTokenCalculator:enableTokenCalculator,
    instantOnTab: instantOnTab,
    currentTenantName: currentTenantName,
    isTenantAdminUser: isTenantAdminUser,
    pageSize: pageSize,
    ctFlag: ctFlag,
    documentationURL:documentationUrl
  };

  return sessionMap;
}

export function getSession(reload) {
  if (reload !== undefined && reload) {
    const request = async () => {
      let resp = await fetch(RestWebServicePaths.APLS_WS_USER_SESSION, {
        method: "GET",
        credentials: "include",
        headers: {
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache, no-store",
          "Tenant-Name": getCurrentTenantName(),
          "Tenant-Id": getCurrentTenantId(),
          "Apls-CA": getUiRquestValue(),
          //'XSRF-TOKEN': getToken(),
          Accept: "application/json"
        }
      });

      if (resp.status === 200) {
        let data = await resp.json();
        return setSession(data);
      } else {
        return sessionMap;
      }
    };

    return request();
  } else {
    //if(sessionMap !== undefined && sessionMap.user !== undefined){
    return sessionMap;
    //}
  }
}
export function listProductNamesForDropDown() {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_IDS + "?operation=borrow_init";
  fetchAsync(url)
    .then(data => {
      UsageReportsUtils.processProdutNamesDropDown(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function listProductNamesForDropDownTenantWise(tenantName) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_TENANT + "?operation=borrow_init&tenantname="+tenantName;
  fetchAsync(url)
    .then(data => {
      UsageReportsUtils.processProdutNamesDropDown(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getUserPriviledge(operation){
    var url = 
        RestWebServicePaths.APLS_WS_REQ_USER_ROLE_STATUS+'?operation='+operation;

    fetchAsync(url).then(data => {
            UserUtility.processUserPriviledge(data);
        }).catch((status, error) => {
            LicenseUtility.processError(status, error);
        }); 
}

export function downloadLicensesProductFeatureExcel(
  selectedOption,
  intl,
  selectedData,
  format,
  data1,
  pagination
) {
  var postData;
  var url = "";
  url = RestWebServicePaths.APLS_WS_PRODUCT_FEATURE_DOWNLOAD;
  if (selectedOption === undefined || selectedOption === null) {
    postData = {
      requestLicenseType: selectedData,
      tableToExport: JSON.parse(data1).table,
      format:JSON.parse(data1).format,
      pagination:pagination
    };
  } else {
    postData = {
      requestLicenseType: selectedData,
      productNameList: { value: [selectedOption] },
      tableToExport: JSON.parse(data1).table,
      format:JSON.parse(data1).format,
      pagination:pagination
    };
  }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

  export function getProductFeatureLicenses(selectedOption, type, pagination) {
  var postData,
    licenseStatusType = "ACTIVE";
  var paginationObj = {
    deleted: false,
    sortBy: "installedOn",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };
  if (type !== undefined) {
    licenseStatusType = type;
  }

  if (licenseStatusType === "ACTIVE") {
    paginationObj.sortBy = "installedOn";
  } else if (licenseStatusType === "ARCHIVED") {
    paginationObj.sortBy = "archivedOn";
  }

  if (pagination !== undefined) {
    paginationObj = pagination;
  }

  if (selectedOption === undefined) {
    postData = {
      requestLicenseType: licenseStatusType,
      pagination: paginationObj,
      menuEnable:false
    };
  } else {
    postData = {
      requestLicenseType: licenseStatusType,
      productNameList: { value: [selectedOption] },
      pagination: paginationObj,
      menuEnable:false
    };
  }

  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_PRODUCT_FEATURE_LIST;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processProductFeatureLicenseList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getProductFeatureLicensesCount(selectedOption, type, pagination) {
  var postData,
    licenseStatusType = "ACTIVE";
  var paginationObj = {
    deleted: false,
    sortBy: "installedOn",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };
  if (type !== undefined) {
    licenseStatusType = type;
  }

  if (pagination !== undefined) {
    paginationObj = pagination;
  }

  if (selectedOption === undefined) {
    postData = {
      requestLicenseType: licenseStatusType,
      pagination: paginationObj,
      menuEnable:true
    };
  } else {
    postData = {
      requestLicenseType: licenseStatusType,
      productNameList: { value: [selectedOption] },
      pagination: paginationObj,
      menuEnable:true
    };
  }

  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_PRODUCT_FEATURE_LIST;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
       let tempCnt = (data.isProductFeatureMenuEnable)?data.isProductFeatureMenuEnable:false;
       let isNamedFeature = (data.isNamedFeatureMenuEnable)?data.isNamedFeatureMenuEnable:false;
       let isCustomReport = (data.isCustomReportMenuEnable)?data.isCustomReportMenuEnable:false;
       let isProductReport = (data.isProductReportMenuEnable)?data.isProductReportMenuEnable:false;
        var payload = {
            productFeatureCount: tempCnt,
            isNamedFeature: isNamedFeature,
            isCustomReport: isCustomReport,
            isProductReport: isProductReport
        }

        Dispatcher.dispatch({
            type: "PRODUCT_FEATURE_COUNT",
            payload: payload
        });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function updateAddImplimentProductFeature(productNames,LicenseKey) {
let postDat;
postDat = {
    productNamesLst:productNames,
    LicenseKeys:LicenseKey
}
  var url = RestWebServicePaths.APLS_WS_REQ_LICENSE_ADD_IMP_FEATURE;
  fetchPOST(url,JSON.stringify(postDat))
    .then(data => {
      let type = "LICENSE_INSTALL_SUCCESS_OK";
      getProductFeatureLicenses(undefined, "ACTIVE", undefined);
        let msg = LocaleUtility.getLocaleMessage("label.license.product.feature.install",{count:data.count});

        // if (errorDetails !== undefined) {
        //     msg = msg + " " + LocaleUtility.getLocaleErrorMessage(errorDetails.errorDetail[0].errorCode,
        //             errorDetails.errorDetail[0].parameters)
        // }
        Dispatcher.dispatch({
            type: type,
            payload:  msg
        });
        
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getPoolsByUser(){
    var url = 
        RestWebServicePaths.APLS_WS_REQ_USER_POOL_LIST;
    var pools = [];

    return fetchAsync(url).then(data => {
        if (data.entityList !== undefined && data.entityList.value.length > 0) {
            pools = data.entityList.value;
        }
        return pools;
    }).catch((status, error) => {
        LicenseUtility.processError(status, error);
    }); 
}

export function getAllProductNames() {
  var url = RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_IDS + "?operation=none";
  fetchAsync(url)
    .then(data => {
      UsageReportsUtils.processGetAllProductNames(data);
    }).catch((status, error) => {
      LicenseUtility.processError(status, error);
  });
}

export function resetInstallLicense(archived){
  if(archived==="archived"){
    let payload = {
                redirectPage:"archived"
            }
            Dispatcher.dispatch({
                type:"REDIRECT_PAGE",
                payload:payload
            });
  }else{
    let payload = {
                redirectPage:""
            }
            Dispatcher.dispatch({
                type:"REDIRECT_PAGE",
                payload:payload
            });
  }
    
}
export function listTokenFeatures(postData) {
   ConfigurationUtils.showLoadMask("configpageLoadSpin", undefined);
  var url =
    RestWebServicePaths.APLS_WS_TOKEN_FEATURE_LIST;
    fetchPOST(url,JSON.stringify(postData))
    .then(data => {
      TokenUtility.processTokenFeatures(data);

    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
       ConfigurationUtils.hideLoadMask(undefined);
    });

}
export function listMappedFeturesForToken(postData) {
   ConfigurationUtils.showLoadMask("configpageLoadSpin", undefined);
  var url =
    RestWebServicePaths.APLS_WS_TOKEN_FEATURE_PD_LIST;
    fetchPOST(url,JSON.stringify(postData))
    .then(data => {
      TokenUtility.processMappedFeaturesForToken(data);

    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
       ConfigurationUtils.hideLoadMask(undefined);
    });

}

export function listInstanceUsageData(intl, data, resetFilter) {
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_INSTANCE_DETAIL;
  var postData = {};
  let sortIndex = undefined;
  if(data.pagination !== undefined){
    sortIndex = data.sortIndex;
    postData = {
      pagination: data.pagination,
      uniqueInstanceId: data.uniqueInstanceId,
      featureVersion: data.featureVersion,
      featureId: data.featureId,
      featureDescription: data.featureDescription
    };
  } else {
    postData = {
      uniqueInstanceId: data.uniqueInstanceId,
      featureVersion: data.featureVersion,
      featureId: data.featureId
    };
  }
  
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        UsageReportsUtils.processInstanceResults(data, sortIndex, resetFilter);
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}
export function downloadReservationPoolTemplate(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_DOWNLOAD_TEMPLATE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function importReservationPoolTemplate(formData, intl) {
  var url = RestWebServicePaths.APLS_WS_RESERVATION_IMPORT_TEMPLATE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOSTMultiFormData(url, formData)
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage("61006", intl);
        ConfigurationUtils.hideLoadMask(intl);
      } else {
        let errorDetail = data.errorDetail;
        if(errorDetail !== undefined && errorDetail.errorCode !== undefined) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function downloadNamedUserTemplate(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_NAMEDUSER_DOWNLOAD_TEMPLATE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function importNamedUserIdTemplate(formData, intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_NAMEDUSER_IMPORT;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOSTMultiFormData(url, formData)
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage("61006", intl);
        ConfigurationUtils.hideLoadMask(intl);
      } else {
        let errorDetail = data.errorDetail;
        if(errorDetail !== undefined && errorDetail.errorCode !== undefined) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listJobsByCategory(postData, intl) {
  var url = RestWebServicePaths.APLS_LIST_JOBS;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.processJobsLit(data, "resv_job", intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadJobFile(jobId, intl) {
  var url = RestWebServicePaths.APLS_JOB_FILE_DOWNLOAD +"?jobId=" +jobId;
  ConfigurationUtils.showLoadMask("", intl);
  fetchAsync(url)
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        Dispatcher.dispatch({
            type: "COMP_INVALID",
            payload: LocaleUtility.getLocaleMessage("10000")
        });
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function deleteJob(postData, intl) {
  var url = RestWebServicePaths.APLS_JOB_DELETE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.processJobsLit(data, "delete_job", intl);
        Dispatcher.dispatch({
            type: "COMP_OK",
            payload: LocaleUtility.getLocaleMessage("delete.job.success")
        });
      } else {
        let errorDetail = data.errorDetail;
        let erroCode = "10000";
        if(errorDetail !== undefined && errorDetail.errorCode !== undefined) {
          erroCode = errorDetail.errorCode;
        }
        Dispatcher.dispatch({
            type: "COMP_INVALID",
            payload: LocaleUtility.getLocaleMessage(erroCode)
        });
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}


export function getAllNamedUserLicenses(params, pagination, selectedLicenseId) {
  var postData;
  var paginationObj = {
    deleted: false,
    sortBy: "installedOn",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };

  if (params.pagination !== undefined) {
    paginationObj = params.pagination;
  }

  if (params.productName === "" || params.productName === undefined) {
    postData = {
      pagination: paginationObj,
      loadExpiredLicenses: params.loadExpiredLicenses
    };
  } else {
    postData = {
      productNameList: { value: [params.productName] },
      featureID: params.featureId,
      featureVersion: params.featureVersion,
      pagination: paginationObj,
      loadExpiredLicenses: params.loadExpiredLicenses
    };
  }

  var url = RestWebServicePaths.APLS_WS_REQ_NAMEDUSER_LIC_LIST;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processNamedUserLicenseList(data, selectedLicenseId);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });

}

export function findNamedUsers(licDetails, intl, loadExpiredLicenseUsers) {
  var paginationObj = {
    deleted: false,
    sortBy: "createdDate",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };
  if (licDetails.pagination !== undefined) {
    paginationObj = licDetails.pagination;
  }

  var url =
    RestWebServicePaths.APLS_WS_REQ_NAMEDUSER_LIST;
  let postData = {
      "licenseID":licDetails.licId,
      "featureID":licDetails.featId,
      "featureVersion": licDetails.featVersion,
      "pagination": paginationObj,
      "featureLicenseIds": licDetails.featureLicenseIds,
      "loadExpiredLicenseUsers": loadExpiredLicenseUsers
  }

  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        LicenseUtility.processNamedUsers(data);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function addNamedUser(licDetails, intl, loadExpiredLicenseUsers) {
  var url = RestWebServicePaths.APLS_WS_REQ_NAMEDUSER;

  var postData = {
    namedUserLicenseList:{
      namedUserLicense:[
        {
          licenseID:licDetails.licId,
          featureID:licDetails.featId,
          featureVersion:licDetails.featVersion,
          namedUsers: [licDetails.namedUsers],
          featureLicenseIds: licDetails.featureLicenseIds
        }
      ]
    }
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processNamedUserResp(data, "ADD");
      if(data.status === "SUCCESS") {
        findNamedUsers(licDetails, intl, loadExpiredLicenseUsers);
        let msg = LocaleUtility.getLocaleMessage("named.user.addition.success");
        if(licDetails.namedUsers.oldValue !== undefined){
          // Update
          msg = LocaleUtility.getLocaleMessage("named.user.update.success");
        }
        Dispatcher.dispatch({
          type: "NAMEDUSER_ADDITION_SUCCESS_OK",
          payload: msg
        });
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function deleteNamedUser(licDetails, values, intl, loadExpiredLicenseUsers) {
  var url = RestWebServicePaths.APLS_WS_REQ_NAMEDUSER;
  if(loadExpiredLicenseUsers){
    url = RestWebServicePaths.APLS_WS_REQ_DELETE_EXPIRED_NAMEDUSER;
  }else{
    url = RestWebServicePaths.APLS_WS_REQ_NAMEDUSER;
  }
  let selIds = [];
  values.map(item => {
          selIds.push({value: item});
          return "";
        });
  var postData = {
    namedUserLicenseList:{
      namedUserLicense:[
        {
          licenseID:licDetails.licId,
          featureID:licDetails.featId,
          featureVersion:licDetails.featVersion,
          namedUsers: selIds,
          featureLicenseIds: licDetails.featureLicenseIds
        }
      ]
    }
  };
  fetchDELETE(url, JSON.stringify(postData))
    .then(data => {
      findNamedUsers(licDetails, intl, loadExpiredLicenseUsers);
      LicenseUtility.processNamedUserResp(data);
      if(data.status === "SUCCESS") {
          
        Dispatcher.dispatch({
          type: "NAMEDUSER_DELETION_SUCCESS_OK",
          payload: LocaleUtility.getLocaleMessage("named.user.deletion.success")
        });
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getAllNamedFeatures(productName) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_NAMEDUSER_FEATURE_LIST +
    "?productIdentifier=" + productName;
  fetchAsync(url)
    .then(data => {
      LicenseUtility.getAllProductFeatures(data, productName);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function processMoveNamedUser(licDetails, intl, loadExpiredLicenseUsers) {
  var url = RestWebServicePaths.APLS_WS_MOVE_NAMEDUSER;

  var postData = {
    featureID:licDetails.featId,
    featureVersion:licDetails.featVersion,
    namedUsers: licDetails.namedUsers
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processNamedUserResp(data, "ADD");
      if(data.status === "SUCCESS" || data.status === "PARTIAL_SUCCESS") {
        findNamedUsers(licDetails, intl, loadExpiredLicenseUsers);
        let msg = LocaleUtility.getLocaleMessage(data.responseMessage,{SUCCESS_COUNT:data.successUserCount,TOTAL_COUNT:licDetails.namedUsers.length});
        Dispatcher.dispatch({
          type: "NAMEDUSER_ADDITION_SUCCESS_OK",
          payload: msg
        });
      }
    })
    .catch((status, error) => {
      status.message = LocaleUtility.getLocaleMessage("18013");
      LicenseUtility.processError(status, error);
    });
}

export function findCTTenantConfiguration(intl) {
  var url = RestWebServicePaths.APLS_WS_FETCH_IDM_CONF_FOR_TENANT_CT;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processIDMCTConfigurationTenant(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function findCTAssetConfiguration(intl){
  var url = RestWebServicePaths.APLS_WS_FETCH_IDM_CONF_FOR_ASSET_CT;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processIDMCTConfigurationAsset(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function testIDMForCTAsset(data, intl) {
  var url = RestWebServicePaths.APLS_WS_IDM_TEST;
  var postData = {
    idmserver: data.assetIdmServerUrlValue.trim(),
    username: data.assetIdmUserNameValue.trim(),
    password: data.assetIdmPasswordValue,
    ctUrl:data.ctAssetServerUrlValue.trim()    
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1("badRequest", intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function testIDMForCTTenant(data, intl) {
  var url = RestWebServicePaths.APLS_WS_IDM_TEST;
  var postData = {
    idmserver: data.tenantIdmServerUrlValue.trim(),
    username: data.tenantIdmUserNameValue.trim(),
    password: data.tenantIdmPasswordValue,
    ctUrl:data.ctTenantServerUrlValue.trim()    
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "configpageLDAPUTestConnection",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1("badRequest", intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function saveIDMCTConnectionDetails(data, intl, submitType) {
  var url = RestWebServicePaths.APLS_WS_SAVE_IDM_CONF; 
  var usageType = "max";
  let featureTenants=[];
  data.featuresTenantsList.map((item)=>{
    let featureTenant={};
    featureTenant["featureDescription"] = item.featureDescription;
    featureTenant["featureId"] = item.featureId;
    featureTenant["featureVersion"] = item.featureVersion;
    featureTenant["tenant"] = item.tenant;
    featureTenant["assetId"] = item.assetId;
    featureTenants.push(featureTenant);
    return "";
  });
  
  var postData = {
    idmserver: submitType == "tenant" ? data.tenantIdmServerUrlValue.trim() : data.assetIdmServerUrlValue.trim(),
    username: submitType == "tenant" ? data.tenantIdmUserNameValue.trim() : data.assetIdmUserNameValue.trim(),
    password: submitType == "tenant" ? data.tenantIdmPasswordValue : data.assetIdmPasswordValue,
    freequency: data.ctUsageFreequency,
    ctUrl:  submitType == "tenant" ? data.ctTenantServerUrlValue.trim() : data.ctAssetServerUrlValue.trim(),
   // ctHostName: data.ctHostName.trim(),
    usageType: usageType,
    submitType:submitType,
    featuresTenantsList:featureTenants
    
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "ctConfigpageSuccessMsg",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorMessage, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function updateTenantList(data, intl) {
  var url = RestWebServicePaths.APLS_WS_UPDATE_CT_CONF;
  var postData = {
    featuresTenantsList:data    
  };
  
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "ctConfigpageSuccessMsg",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorMessage, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function downloadNamedUsers(key, intl) {
  var postData = {
      // "productNameList": { "value": [""] },
    	// "featureID": "",
      // "featureVersion": "",
      "licenseID": key
  };
  callDownloadNamedUsersApi(postData, intl);
}

export function exportNamedUsers(licDetails, intl, loadExpiredLicenses) {
  let postData = {
      "licenseID":licDetails.licId,
      "featureID":licDetails.featId,
      "featureVersion": licDetails.featVersion,
      "featureLicenseIds": licDetails.featureLicenseIds,
      "loadExpiredLicenses": loadExpiredLicenses
    }
  callDownloadNamedUsersApi(postData, intl);
}

function callDownloadNamedUsersApi(postData, intl){
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(RestWebServicePaths.APLS_WS_REQ_NAMEDUSER_DOWNLOAD, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listTenantNames(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_LIST;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        MultiTenantUtils.processTenantsList(data);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function createTenantName(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_CREATE;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        if(postData !== undefined && Number(postData.id) > 0) {
          if(Number(postData.action) === 2) {
            ConfigurationUtils.showSuccessMessage("19002", intl);
          } else if(Number(postData.action) === 3) {
            ConfigurationUtils.showSuccessMessage("19003", intl);
          } else if(Number(postData.action) === 4) {
            ConfigurationUtils.showSuccessMessage("19009", intl);
          } else if(Number(postData.action) === 5) {
            ConfigurationUtils.showSuccessMessage("19011", intl);
          } else {
            ConfigurationUtils.showSuccessMessage("19001", intl);
          }
        } else {
          ConfigurationUtils.showSuccessMessage("19000", intl);
        }
        MultiTenantUtils.processTenantsList(data);
        if(Number(postData.action) === 1) {
          var url = RestWebServicePaths.APLS_WS_TENANTS_DATA_CREATE+"?tenantName="+postData.name;
          fetchPOST(url, JSON.stringify(""))
            .then(data => {
            });
        }
        ConfigurationUtils.hideLoadMask(intl);
      } else {
        let errorDetails = data.errorDetail;
        if(errorDetails !== undefined) {
           let errorCode = errorDetails.errorCode;
           let errorMsg = errorDetails.errorMessage;
           if(errorCode === 19006 || errorCode === "19006") {
             ConfigurationUtils.showErrorMessage1("19006", intl);
           } else if(errorCode === 19007 || errorCode === "19007") {
             ConfigurationUtils.showErrorMessage1("19007", intl);
           } else if(errorCode === 19022 || errorCode === "19022") {
             ConfigurationUtils.showErrorMessage1("19022", intl);
           } else {
             ConfigurationUtils.showErrorWithMessage(errorMsg);
           }
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function downloadTenantsList(intl, mimetype) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_LIST_DOWNLOAD;
  let postData = {
            downlaodFormat: mimetype
        }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.hideLoadMask(intl);
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        let errorDetails = data.errorDetail;
        if(errorDetails !== undefined) {
           let errorCode = errorDetails.errorCode;
           let errorMsg = errorDetails.errorMessage;
           if(errorCode === 19010 || errorCode === "19010") {
             ConfigurationUtils.showErrorMessage1("19010", intl);
           } else {
             ConfigurationUtils.showErrorWithMessage(errorMsg);
           }
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listAppLogs(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_LOGS_LIST;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        MultiTenantUtils.processAppLogsList(data);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function downloadLogFile(intl, folderName, fileName, action) {
  var url = RestWebServicePaths.APLS_WS_LOGS_DOWNLOAD;
  let postData = {
      parentName: encryptText(folderName, getToken()),
      fileName: encryptText(fileName, getToken())
  }
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.hideLoadMask(intl);
      if (data.status === "SUCCESS") {
        if(action === "D") {
          DownloadUtils.downloadFile(
            data.fileData,
            data.fileName,
            DownloadUtils.TEXT_MIMETYPE
          );
        } else if(action === "V") {
           DownloadUtils.openFileInWindow(
            data.fileData,
            data.fileName,
            DownloadUtils.TEXT_MIMETYPE
          );
        }
      } else {
        if (data.status === "FAIL") {
          let errorDetails = data.errorDetail;
          if(errorDetails !== undefined) {
            let errorCode = errorDetails.errorCode;
            let errorMsg = errorDetails.errorMessage;
            if(errorCode === 19012 || errorCode === "19012") {
              ConfigurationUtils.showErrorMessage1("19012", intl);
            } else {
              ConfigurationUtils.showErrorWithMessage(errorMsg);
            }
          } else {
            ConfigurationUtils.showErrorMessage1("10000", intl);
          }
          ConfigurationUtils.hideLoadMask(intl);
        }
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function listConfigEntries(postData, intl) {
  var url = RestWebServicePaths.APLS_CONFIG_ENTRY_LIST;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        MultiTenantUtils.processConfigEntryList(data);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}
export function submitConfigEntries(list, isSamlReq, isSamlEnabled, intl) {
  var url = RestWebServicePaths.APLS_CONFIG_ENTRY_UPDATE;
  ConfigurationUtils.showLoadMask("", intl);
  let postData = {
    list: list,
    isSamlEnabled: isSamlReq
  }
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.hideLoadMask(intl); 
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage("config.keys.success", intl);
        getSession(true);
        if(isSamlReq){
          setTimeout(function() {
            requestLogout(isSamlReq, isSamlEnabled);
          }, 2000);
        } 
        Dispatcher.dispatch({
          type: "PROCESS_CONFIG_ENTRIES",
          payload: {},
        });
      } else {
        if(data.errorDetail !== undefined)
          ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode, intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function uploadMetaData(formData,method) {
  fetchPOSTMultiFormData(
    RestWebServicePaths.APLS_WS_UPLOAD_METADATA,
    formData
  )
    .then(responseData => {
      if(responseData.status=="SUCCESS"){
         MultiTenantUtils.processConfigSamlEntryList(responseData);
      }
      else if(responseData.errorDetail!== undefined){
      let type = "LICENSE_FETCH_ERROR";
       Dispatcher.dispatch({
       type: type,
       payload: LocaleUtility.getLocaleErrorMessage(
        responseData.errorDetail.errorCode
      )
    });
      }
    })
    .catch(function(status, error) {
      LicenseUtility.processError(status, error);
    });
}

export function downloadSamlSpMetaData(intl) {
  var url = RestWebServicePaths.APLS_WS_EXPORT_METADATA ;
  ConfigurationUtils.showLoadMask("", intl);
  fetchAsync(url)
    .then(data => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.downloadFile
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}


export function getAllUsage(params) {
  var postData;
  usageProductId = params.productId;
  if(params.fields !== undefined && params.fields.length > 0){										  
     postData = {
      fields: params.fields,
      productId: params.productId,
      featureId: params.featureId,
      reportFilter: params.reportFilter,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder
    };
  } else {
    postData = {
      fields: [],
      productId: params.productId,
      featureId: params.featureId
    };
  }

  var url = RestWebServicePaths.APLS_WS_REQ_GENERIC_USAGE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UsageReportsUtils.processGenericUsage(data, params.filterType);
    })
    .catch((status, error) => {
											
      LicenseUtility.processError(status, error);
    });

}

export function getUsageReports(productName, metricname) {
   var url =
    RestWebServicePaths.APLS_WS_REQ_GENERIC_REPORT_LIST +
    "?product=" + productName;
    if(metricname !== undefined && productName !== undefined &&
       metricname !== '' && productName !== ''){
      url =
        RestWebServicePaths.APLS_WS_REQ_GENERIC_REPORT_LIST +
        "?product=" + productName+"&metricname=" + metricname;
    }
  fetchAsync(url)
    .then(data => {
      UsageReportsUtils.processUsageReport(data, productName);										
    })
    .catch((status, error) => {
											
      LicenseUtility.processError(status, error);
    });
}

export function createUsageReport(params){
  var url = RestWebServicePaths.APLS_WS_REQ_ADD_USAGE_REPORT;										
  var postData = params;

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        getUsageReports(params.productId);
        getAllUsage({productId: params.productId, featureId:params.featureId, fields:[], filterType:"allfields"});
        if(postData.reportId !== undefined && postData.reportId !== null && postData.reportId !== ''){
          Dispatcher.dispatch({
            type: "REPORT_CREATE_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("usage.update.report.success", {
              repName: postData.reportName
            })
          }); 
        } else {
          Dispatcher.dispatch({
            type: "REPORT_CREATE_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("usage.create.report.success", {
              repName: postData.reportName
            })
          }); 
        }
      } else {
          Dispatcher.dispatch({
            type: "REPORT_CREATE_FAILURE",
            payload: {
              showModal: false
            }
          }); 
          Dispatcher.dispatch({
            type: "REPORT_CREATE_ERROR",
            payload: LocaleUtility.getLocaleErrorMessage(
              data.errorDetail.errorCode,
              data.errorDetail.parameters
            )
          }); 
      }							
    })
    .catch((status, error) => {								
      LicenseUtility.processError(status, error);
    });
  }

export function listSubNotification(data, intl){

   var url = RestWebServicePaths.APLS_WS_SUB_NOTIFICATION_DETAILS;
   fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processNotificationData(data);

    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function loadTenantNames(postData, intl, refresAction) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_DROPDOWN_LIST;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        MultiTenantUtils.processTenantDropDown(data, refresAction);
      }
      ConfigurationUtils.hideLoadMask(intl);
 })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}



export function getCustomMetricForProduct(product) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_CUSTOM_METRIC_LIST + "?productIdentifier=" + product;

  fetchAsync(url)
    .then(data => {
      UsageReportsUtils.getCustomMetric(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getTenantSpecificLicenses(selectedOption,type,tenantName,pagination) {
  var postData,
    licenseStatusType = "ACTIVE";

  var paginationObj = {
    deleted: false,
    sortBy: "serverLicenseTable.licenseStartDate",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };

  if (type !== undefined) {
    licenseStatusType = type;
  }

  if (pagination !== undefined) {
    paginationObj = pagination;
  }
  var url = RestWebServicePaths.APLS_WS_REQ_TENANT_LICENSE_LIST;
  postData = {
    requestLicenseType: licenseStatusType,
    productNameList: { value: [selectedOption] },   
    tenantName:tenantName,
    pagination: paginationObj
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if(data.status==="SUCCESS"){
      LicenseUtility.processTenantLicenseList(data, licenseStatusType);
      }
      ConfigurationUtils.hideLoadMask(undefined);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function requestTenantLicenseCheckout(postData, productName) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_LICENSE_CHECKOUT;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.processTenanticenseCheckOut(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function getTenantProducts(operation, showFirstProdOption, refeshCall) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_GET_PRODUCT_IDS + "?operation=" + operation;

  fetchAsync(url)
    .then(data => {
      LicenseUtility.getTenantAllProducts(data, showFirstProdOption, refeshCall);
      ConfigurationUtils.hideLoadMask(undefined);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}
export function returnTenantLicenseList(productName, licenseIds,tenantName,pagination) {
  var url = RestWebServicePaths.APLS_WS_TENANTS_LICENSE_CHECKIN;
  var postData = {
    productName: productName,
    tenantName:tenantName,
    returnLicenses: { licenseSignature: licenseIds }
  };

  let loadSpinAction = "RETURN_BORROW_INPROGRESS_LOADING_SPIN";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
  });

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      LicenseUtility.refreshTenantLicenseListView(data, productName,tenantName,pagination);
      ConfigurationUtils.hideLoadMask(undefined);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function deleteUsageReport(params){
  var url = RestWebServicePaths.APLS_WS_REQ_DEL_USAGE_REPORT;										
  var postData = params;

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        getUsageReports(params.productId);
        getAllUsage({productId: params.productId, fields:[], filterType:"allfields"});
        Dispatcher.dispatch({
          type: "REPORT_DELETE_SUCCESS_OK",
          payload: LocaleUtility.getLocaleMessage("usage.delete.report.success", {
            repName: postData.reportName
          })
        });
      } else {
          Dispatcher.dispatch({
            type: "REPORT_CREATE_ERROR",
            payload: LocaleUtility.getLocaleErrorMessage(
              data.errorDetail.errorCode,
              data.errorDetail.parameters
            )
          }); 
      }											
    })
    .catch((status, error) => {									
      LicenseUtility.processError(status, error);
    });
  }

export function saveSubNotification(data, intl){

  var url = RestWebServicePaths.APLS_WS_SUB_NOTIFICATION_SAVE;
  var postData = {
    notifyBy: data.notifyBy,
    notifyInDays: data.expInDays,
    notifyCapacity: data.capacityPersentage,
    emailIds: data.emailIdsList
    
  };
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.showSuccessMessage(
          "notificationPageSuccessMsg",
          intl
        );
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorMessage, intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function getCustomPropValue(propName, product, metricname){
  var url = RestWebServicePaths.APLS_WS_REQ_FIELD_USAGE_REPORT +"?fieldName="+ propName;
    if(metricname !== undefined && metricname !== ''){
      url +="&metricname=" + metricname;
    }
    if(product !== undefined && product !== ''){
      url += "&productIdentifier=" + product;
    }
  fetchAsync(url)
    .then(data => {
     UsageReportsUtils.getCustomPropValue(data);										
    })
    .catch((status, error) => {							
      LicenseUtility.processError(status, error);
    });
}

export function downloadUsageReports(params, intl, format) {
  var postData;
  if(params.fields !== undefined && params.fields.length > 0){										  
     postData = {
      fields: params.fields,
      productId: params.productId,
      featureId: params.featureId,
      reportFilter: params.reportFilter,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      download: format
    };
  } 
  var url = RestWebServicePaths.APLS_WS_USAGE_REPORT_DOWNLOAD;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        let outputFormat;
        if(format === 'excel')
          outputFormat = DownloadUtils.EXCEL_MIMETYPE;
        else if(format === 'CSV')
          outputFormat = DownloadUtils.CSV_MIMETYPE;
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          outputFormat
        );
      } else {
        let errorDetail = data.errorDetail;
        if (errorDetail !== undefined && errorDetail.errorCode !== 10000) {
          ConfigurationUtils.showErrorMessage1(errorDetail.errorCode, intl);
        } else {
          ConfigurationUtils.showErrorMessage1("10000", intl);
        }
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function searchLdapGroup(postData, intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_LDAP_GROUP_SEARCH;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if(data.length > 0){
        UserUtility.displayLdapGroupList(data);
      }else{
        ConfigurationUtils.showErrorMessage1("no.user.group.found", intl);
        Dispatcher.dispatch({
        type: "DISABLE_LOADING_SPIN_DONE",
        payload: ""
      });
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function deleteLdapUserGroup(userData) {
  let postData = {
    groupName: (userData.groupName===undefined)?null:userData.groupName,
    groupDesc: (userData.groupDesc===undefined)?null:userData.groupDesc,
    groupEmail: (userData.groupEmail===undefined)?null:userData.groupEmail,
    groupCreatedTime: (userData.groupCreatedTime===undefined)?null:userData.groupCreatedTime,
    groupModifiedTime: (userData.groupModifiedTime===undefined)?null:userData.groupModifiedTime,
    roleList: [],
    pagenation:userData.pagenation,
    isAdmin:(userData.isAdmin===undefined)?null:userData.isAdmin,
    roleName:(userData.roleName===undefined)?null:userData.roleName
  };
  var url = RestWebServicePaths.APLS_WS_REQ_LDAP_GROUP_DELETE;
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UserUtility.userGroupList(data);
      Dispatcher.dispatch({
        type: "GROUP_DELETE_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage("um.delete.group", {
          grpName: userData.groupName
        })
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function assignLdapGroup(data, intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_LDAP_GROUP_ASSAIGN;
   var paginationObj = {
    deleted: false,
    sortBy: "groupName",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: 25
  };
  let postData = {
      groupName: data[0].groupName,
      groupDesc: data[0].groupDesc,
      groupEmail: data[0].groupEmail,
      pagenation:paginationObj
  }
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.showSuccessMessageWithParams(postData, "Group.created.from.LDAP", intl);
      UserUtility.userGroupList(data);
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}


export function fetchFeatures(intl) {
  var url = RestWebServicePaths.APLS_WS_REQ_FETCH_FEATURES;
  ConfigurationUtils.showLoadMask("", intl);
  fetchAsync(url)
    .then(data => {
      if (data.featuresTenantsList!==undefined) {
        MultiTenantUtils.fetchFeaturesMap(data);
      } else {
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
    });
}

export function findCTRejectedList(intl, pageData,rejectedType){
  var url = RestWebServicePaths.APLS_WS_FETCH_CT_REJECTED;
  var paginationObj = {
    deleted: false,
    sortBy: "lastUpdatedDate",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: getPageSize()
  };
  if (pageData) {
    paginationObj = pageData;
  }
  let postData = {      
      pagination: paginationObj,
      type:rejectedType
    };
  
  fetchPOST(url,JSON.stringify(postData)).then(data => {
      ConfigurationUtils.processRejectedCTList(data);
      ConfigurationUtils.hideLoadMask(intl);
    }).catch((status,error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);
  });
}

export function deleteCTRejectedRec(intl,req, rejectedType){
  var url = RestWebServicePaths.APLS_WS_DELETE_CT_REJECTED;
  let postData = {      
      rejectedCTList: req,
      type: rejectedType
    };

   
  fetchDELETE(url,JSON.stringify(postData)).then(data => {
      if (data.status === "SUCCESS") {
        ConfigurationUtils.loadRejectedRecords(intl, null, rejectedType);
        ConfigurationUtils.showSuccessMessage("resv.member.delete.success",intl);       
      }
    }
  ).catch((status,error)=>{
    ConfigurationUtils.hideLoadMask(intl);
    LicenseUtility.processError(status, error);
  })
}

export function resendCTRejectedRec(intl,req, rejectedType){
  var url = RestWebServicePaths.APLS_WS_RESEND_CT_REJECTED;
  let postData = {      
      rejectedCTList: req,
      type:rejectedType
    };
 
  fetchPOST(url,JSON.stringify(postData)).then(data => {
      if (data.status === "SUCCESS") {
       ConfigurationUtils.loadRejectedRecords(intl, null, rejectedType);
       ConfigurationUtils.showSuccessMessage("configpageUpdateSuccess",intl);
        
      }
    }
  ).catch((status,error)=>{
    ConfigurationUtils.hideLoadMask(intl);    
    LicenseUtility.processError(status, error);
  })
}

export function exportCTRejectedList(intl, mimetype, rejectedType){
  var url = RestWebServicePaths.APLS_WS_EXPORT_CT_REJECTED;
  var paginationObj = {
    deleted: false,
    sortBy: "lastUpdatedDate",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: 10000
  };
   let postData = {      
      pagination: paginationObj,
      format:mimetype,
      type: rejectedType
    };
  ConfigurationUtils.showLoadMask("",intl);
  fetchPOST(url,JSON.stringify(postData)).then(data => {
      // if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      // } else {
      //   ConfigurationUtils.showErrorMessage1("10000", intl);
      //   ConfigurationUtils.hideLoadMask(intl);
      // }
    }).catch((status,error) => {
    ConfigurationUtils.hideLoadMask(intl);
    LicenseUtility.processError(status, error);
  });
}

export function tenantMigrationRetry(intl) {
  var url = RestWebServicePaths.APLS_WS_TENANT_MIGRATION_RETRY;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify({}))
    .then(data => {
      if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1("tenant.migration.initiation.failed", intl);
      } else {
        ConfigurationUtils.showSuccessMessage("tenant.migration.initiation.succes", intl);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function getLicenseRenewDetail(licKey, activationId, licenseExp) {
  var url =
    RestWebServicePaths.APLS_WS_REQ_RENEW_LICENSE_DETAIL +
    "?activationId=" +
    activationId;

  fetchAsync(url)
    .then(data => {
      getLicenseDetail(licKey, true);
      let type = "RENEW_LICENSE_DETAIL_FETCH_COMPLETE";
      let payload = {
        operation: "new",
        renewDetail: {
          pasActivationId: activationId,
          licExpiryDate: licenseExp,
        }
      };
      if (data.status === "SUCCESS" && data.licenseRenewInfo !== undefined) {
        payload = {
          operation: "update",
          renewDetail: data.licenseRenewInfo
        };
      }
      Dispatcher.dispatch({
        type: type,
        payload: payload
      });
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function submitLicenseRenewDetails(params){
  var url = RestWebServicePaths.APLS_WS_REQ_RENEW_LICENSE_DETAIL;										
  var postData = {
    licenseRenewInfo: params.licenseRenewInfo
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
         Dispatcher.dispatch({
            type: "RENEW_LICENSE_DETAIL_SUCCESS"
          });
          let msg = '';
          if(params.operation === 'new'){
            msg = LocaleUtility.getLocaleMessage("license.renew.details.create.success");
          }else if(params.operation === 'update'){
            msg = LocaleUtility.getLocaleMessage("license.renew.details.update.success");
          }else if(params.operation === 'delete'){
            msg = LocaleUtility.getLocaleMessage("license.renew.details.remove.success");
          }
          Dispatcher.dispatch({
            type: "RENEW_DETAILS_SUCCESS_OK",
            payload: msg
          });
      } else {
          Dispatcher.dispatch({
            type: "RENEW_LICENSE_DETAIL_ERROR_RESPONSE"
          });
          Dispatcher.dispatch({
            type: "COMP_INVALID",
            payload: LocaleUtility.getLocaleErrorMessage(
              data.errorDetail.errorCode,
              data.errorDetail.parameters
            )
          }); 
      }							
    })
    .catch((status, error) => {								
      LicenseUtility.processError(status, error);
    });
  }

  export function getfeatureIDforProducts(productName){
    var postData;
      if(productName !== undefined && productName !== ""){										  
         postData = {
          productId: productName
        };
      } 
      var url = RestWebServicePaths.APLS_WS_REQ_GET_FEATURE_FORPRODUCT;
      //ConfigurationUtils.showLoadMask("", intl);
      fetchPOST(url, JSON.stringify(postData))
        .then(data => {
          if (data.status === "SUCCESS") {
            let type = "USAGE_FEATURE_ID";
            let payload = {
              featureid: data.features
            };
            Dispatcher.dispatch({
              type: type,
              payload: payload
            });
          }
        })
        .catch((status, error) => {
         // ConfigurationUtils.hideLoadMask(intl);
          LicenseUtility.processError(status, error);
        });
      }
export function getProdHostList(intl) {
  var url = RestWebServicePaths.APLS_WS_GET_PROD_HOST;
  ConfigurationUtils.showLoadMask(intl);
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processProductHostList(data);
      ConfigurationUtils.hideLoadMask(intl);   
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);      
    });
}

export function getProdLicenseList(id, pageData, intl){
  var url = RestWebServicePaths.APLS_WS_GET_PROD_HOST_LICENSE;
  ConfigurationUtils.showLoadMask(intl);
  var postData = {
    licenseID: id,
    pagination: pageData
  };

  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      ConfigurationUtils.processProductHostLicenseList(data);
      ConfigurationUtils.hideLoadMask(intl);   
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);      
    });
}

export function getProductReportLicenseDetail(id,intl){
  var url = RestWebServicePaths.APLS_WS_GET_PROD_HOST_LICENSE_VIEW+"?id="+id;
  ConfigurationUtils.showLoadMask(intl);
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processProductHostLicenseDetails(data);
      ConfigurationUtils.hideLoadMask(intl);   
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);      
    });
}

export function searchProductHost(searchVal,intl){
   var url = RestWebServicePaths.APLS_WS_SEARCH_PROD_HOST+"?key="+searchVal;
  ConfigurationUtils.showLoadMask(intl);
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processProductHostList(data);
      ConfigurationUtils.hideLoadMask(intl);   
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
      ConfigurationUtils.hideLoadMask(intl);      
    });

}

export function getTenantForProducts(productName, metricname){
    var url = RestWebServicePaths.APLS_WS_GET_USAGE_TENANT+
                "?product=" + productName;
    if(metricname !== undefined && productName !== undefined &&
       metricname !== '' && productName !== ''){

      var feats = metricname.split(":");
      if (feats.length === 2) {
          url = RestWebServicePaths.APLS_WS_GET_USAGE_TENANT +
                "?product=" + productName+"&featureId=" + feats[0]+"&featureVersion=" + feats[1];
      } else {
          url = RestWebServicePaths.APLS_WS_GET_USAGE_TENANT +
                "?product=" + productName+"&metricname=" + feats[0];
      }
    }
  fetchAsync(url)
      .then(data => {
          if (data.entityList !== undefined && data.entityList.value.length > 0) {
            let type = "USAGE_TENANT_LIST";
            let payload;
            payload = {
              tenantList: data.entityList.value
            };
            Dispatcher.dispatch({
              type: type,
              payload: payload
            });
          }
      })
      .catch((status, error) => {
        LicenseUtility.processError(status, error);
      });
    }

export function findHostConsumptionGraphData(intl, data, isLicCapReq) {
  var url = RestWebServicePaths.APLS_WS_PROD_USAGE_CONSUMPTION_GRAPH;
  let usageStartDate = data.startTime;
  let usageEndDate = data.endTime;
  var postData = {
    featureId: data.featureId,
    featureVersion: data.featureVersion,
    startTime: data.startTime,
    endTime: data.endTime,
    tenant: data.tenant
  };
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      UsageReportsUtils.processConsumptionGraphResults(
        data,
        usageStartDate,
        usageEndDate,isLicCapReq
      );
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}

export function getCustomMetrics(hostID){
  var url = RestWebServicePaths.APLS_WS_PROD_CUSTOM_METRIC+"?id="+hostID;
  fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processProductHostCustomMetric(data);        
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);          
    });
}

export function exportProductLicenseList(intl, host){
  var url = RestWebServicePaths.APLS_WS_EXPORT_PROD_LICENSE;   
  ConfigurationUtils.showLoadMask("",intl);
   var paginationObj = {
    deleted: false,
    sortBy: "installedDate",
    sortOrder: "DESC",
    pageNumber: "1",
    pageSize: 10000
  };
  var postData = {
    licenseID: host,
    pagination: paginationObj
  };

  fetchPOST(url, JSON.stringify(postData)).then(data => {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
        ConfigurationUtils.hideLoadMask(intl);
    }).catch((status,error) => {
    ConfigurationUtils.hideLoadMask(intl);
    LicenseUtility.processError(status, error);
  });
}

export function exportProductHost(intl){  
  var url = RestWebServicePaths.APLS_WS_EXPORT_PROD_HOST;   
  ConfigurationUtils.showLoadMask("Downloading",intl);  
   fetchAsync(url).then(data => {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
        ConfigurationUtils.hideLoadMask(intl);
    }).catch((status,error) => {
    ConfigurationUtils.hideLoadMask(intl);
    LicenseUtility.processError(status, error);
  });
}

export function listAuthToken(intl){

   var url = RestWebServicePaths.APLS_WS_FETCH_AUTH_TOKEN_LIST;
   fetchAsync(url)
    .then(data => {
      ConfigurationUtils.processAuthTokenData(data);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function addAuthToken(intl,renewFlag,renewDays,key){
   var postData = {
    renewFlag:renewFlag,
    renewDays:renewDays,
    token:key
   };
   var url = RestWebServicePaths.APLS_WS_ADD_AUTH_TOKEN;
   fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS"){
        listAuthToken(intl);
         if(key!==undefined){
          if(renewFlag===true){
        Dispatcher.dispatch({
            type: "TOKEN_GENERATED_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("token.renew.success")
        });
      }
      else{
        Dispatcher.dispatch({
            type: "TOKEN_GENERATED_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("token.renew.disabled")
        });
       }
      }
      else{
        Dispatcher.dispatch({
            type: "TOKEN_GENERATED_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("add.token.success")
        });
      }
        
      } 
      else {
        ConfigurationUtils.hideLoadMask(intl);
        let errorDetail = data.errorDetail;
        Dispatcher.dispatch({
          type: "TOKEN_ADD_ERROR",
          payload: LocaleUtility.getLocaleErrorMessage(
            data.errorDetail.errorCode
          )
        });
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function removeAuthToken(key, intl){
    let postData = {
      "token": encryptText(key, getToken()),
    }
   var url = RestWebServicePaths.APLS_WS_REMOVE_AUTH_TOKEN;
   fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS"){
        listAuthToken(intl);
        Dispatcher.dispatch({
            type: "TOKEN_REMOVED_SUCCESS_OK",
            payload: LocaleUtility.getLocaleMessage("remove.token.success")
        });
      } else {
        ConfigurationUtils.hideLoadMask(intl);
        let errorDetail = data.errorDetail;
        Dispatcher.dispatch({
          type: "TOKEN_REMOVE_ERROR",
          payload: LocaleUtility.getLocaleErrorMessage(
            data.errorDetail.errorCode
          )
        });
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function listConfigSamlEntries(postData, intl) {
  var url = RestWebServicePaths.APLS_CONFIG_ENTRY_LIST;
  ConfigurationUtils.showLoadMask("", intl);
  fetchPOST(url, JSON.stringify(postData))
    .then(data => {
      if (data.status === "SUCCESS") {
        MultiTenantUtils.processConfigSamlEntryList(data);
      }
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function listAudits(intl, pageData) {
  var paginationObj = {
    required: true,
    style: "default",
    totalCount: 0,
    pageNumber: 1,
    pageSize: getPageSize(),
  };
  if (pageData.pagination) {
    paginationObj = pageData.pagination;
  }
  let postData = {
    pagination: paginationObj,
    entityFilterVal: !pageData.entityFilterVal
      ? "com.hp.autopassj.ls.database.model.User"
      : pageData.entityFilterVal,
    isSearchAction: pageData.searchAction,
    entityIdVal: pageData.entityIdVal,
    attributeVal: pageData.attributeVal,
    operationVal: pageData.operationVal,
    userVal: pageData.userVal,
    oldVal: pageData.oldVal,
    newVal: pageData.newVal,
    startDate: pageData.startDate,
    endDate: pageData.endDate,
  };
  var url = RestWebServicePaths.APLS_WS_FETCH_AUDIT_LIST;
  fetchPOST(url, JSON.stringify(postData))
    .then((data) => {
      ConfigurationUtils.processAuditData(data, intl);
      ConfigurationUtils.hideLoadMask(intl);
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function downloadAuditReports(postData, intl, format) {
  var url = RestWebServicePaths.APLS_WS_FETCH_AUDIT_REPORT;
  ConfigurationUtils.showLoadMask("", intl);
  postData.format = format;
  fetchPOST(url, JSON.stringify(postData))
    .then((data) => {
      if (data.status === "SUCCESS") {
        DownloadUtils.downloadFile(
          data.fileData,
          data.fileName,
          DownloadUtils.CSV_MIMETYPE
        );
      } else {
        ConfigurationUtils.showErrorMessage1("10000", intl);
        ConfigurationUtils.hideLoadMask(intl);
      }
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function updateAuditConfig(intl, val) {
  var url = RestWebServicePaths.APLS_WS_UPDATE_AUDIT_CONFIG+"?auditEnabled="+val;
  fetchPOST(url, JSON.stringify(""))
    .then((data) => {
      ConfigurationUtils.hideLoadMask(intl);
      ConfigurationUtils.showSuccessMessage(
          "label.audits.config.change.success",
          intl
        );
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask(intl);
      LicenseUtility.processError(status, error);
    });
}

export function updateAutoAddNamedUserFlag(postData) {
  var url = RestWebServicePaths.APLS_WS_ENABLE_AUTOADD_NAMEDUSER;
  fetchPOST(url, JSON.stringify(postData))
    .then((data) => {
       if (data.status === "SUCCESS") {
        let params = {};
        params["feature"] = data.featureID+":"+data.featureVersion;
        if(data.autoAddUser){
          ConfigurationUtils.showSuccessMessageWithParams(
            params,
            "auto.add.nameduser.flag.enabled"
          );
        } else {
          ConfigurationUtils.showSuccessMessageWithParams(
            params,
            "auto.add.nameduser.flag.disabled"
          );
        }
      } else if (data.status === "FAIL") {
        ConfigurationUtils.showErrorMessage1(data.errorDetail.errorCode);
      }
      ConfigurationUtils.hideLoadMask();
    })
    .catch((status, error) => {
      ConfigurationUtils.hideLoadMask();
      LicenseUtility.processError(status, error);
    });
}

export function getAutoEnabledForFeature(licDetails) {
  var url =
    RestWebServicePaths.APLS_WS_ENABLE_AUTOADD_NAMEDUSER +
    "?featureId=" + licDetails.featId +"&featureVersion="+licDetails.featVersion;

  fetchAsync(url)
    .then(data => {
        if (data.status === "SUCCESS") {
          Dispatcher.dispatch({
            type: "GET_AUTOADD_NAMEDUSER_FLAG",
            payload: data.autoAddUser
          });
        }
    })
    .catch((status, error) => {
      LicenseUtility.processError(status, error);
    });
}