import * as ConfigurationUtils from "../utils/ConfigurationUtils";
export const EXCEL_MIMETYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
export const DAT_OR_BIN_MIMETYPE = "application/octet-stream";
export const PDF_MIMETYPE = "application/pdf";
export const PNG_MIMETYPE = "image/png";
export const TEXT_MIMETYPE = "text/plain";
export const CSV_MIMETYPE = "text/csv";

export function downloadFile(fileData, fileName, mimeType, intl) {
    /*const byteChars = atob(data.fileDataInStream);
    const byteNumbers = new Array(byteChars.length);
    for(var i=0;i<byteChars.length;i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });*/
    let blob = convertBlob(fileData, mimeType);
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
    } else {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    ConfigurationUtils.hideLoadMask(intl);
}
const convertBlob = (encodedByteData, contentType, sliceSize=512) => {
    const byteCharacters = atob(encodedByteData);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
export function openFileInWindow(fileData, fileName, mimeType, intl) {
    let blob = convertBlob(fileData, mimeType);
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
    } else {
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
    }
    ConfigurationUtils.hideLoadMask(intl);
}