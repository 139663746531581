import React, { Component } from "react";
import { Anchor } from 'grommet/components/Anchor';
import { TextInput } from 'grommet/components/TextInput';
import { Edit as EditIcon } from 'grommet-icons';
import { Close as CloseIcon } from 'grommet-icons';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from 'grommet/components/Header';
import { FormField } from "grommet/components/FormField";
import { CheckBox } from "grommet/components/CheckBox";
import MembersTable from "./NamedUserTable";
import * as FetchHelper from "../../../../utils/FetchHelper";
import Store from "../../../../flux/APTableStore";
import * as ValidationUtility from "../../../../utils/ValidationUtility";
import * as ConfigurationUtils from "../../../../utils/ConfigurationUtils";
import SpinningNotification from '../../../generic/SpinningNotification';
import Dispatcher from "../../../../flux/Dispatcher";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import { Button } from "grommet/components/Button";
//import { DataSearch as Search } from 'grommet/components/DataSearch';
import { useIntl } from 'react-intl';
import { History } from 'grommet-icons';

// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PoolMembers extends Component {
    constructor(props) {
        super(props);
        this.memeberTabelRef = React.createRef();
        this.state = {
            activeIndex: this.props.activeIndex,
            addUserNameError: "",
            licDetails: this.props.licDetails,
            selectedAttributes: [],
            selAttrType: undefined,
            enteredAttrValue: "",
            editAttrValue:"",
            oldAttrValue:"",
            dataMap: {},
            enableAutoAdd: false,
            selAttrFilter: undefined,
            attributeSearchedValue: undefined,
            isUserAddedOrDeleted: false,
            activeLicenseTabView: this.props.activeLicenseTabView,
        }
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.refreshMembersData = this.refreshMembersData.bind(this);
        this.refreshMembers = this.refreshMembers.bind(this);
        this.selectedActionCall = this.selectedActionCall.bind(this);
        this.handleChangeAttributeValue = this.handleChangeAttributeValue.bind(this);
        this.handleChangeEditValue = this.handleChangeEditValue.bind(this);
        this.addActionCall = this.addActionCall.bind(this);
        this.okAction = this.okAction.bind(this);
        this.onAttributeSearchAction = this.onAttributeSearchAction.bind(this);
        this.showEditTextBox = this.showEditTextBox.bind(this);
         this.editActionCall = this.editActionCall.bind(this);
        this.paintTextBox = this.paintTextBox.bind(this);
        this.paginationCall = this.paginationCall.bind(this);
        this.onConfirmDeleteUser = this.onConfirmDeleteUser.bind(this);
        this.refreshAutoEnabled = this.refreshAutoEnabled.bind(this);
        this.clickMoveUserButton = this.clickMoveUserButton.bind(this);
        this.processMoveButton = this.processMoveButton.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        this.state.isUserAddedOrDeleted = false;
        Store.on("change", this.refreshGrid);
        Store.on("change", this.refreshMembersData);
        Store.on("change", this.refreshAutoEnabled);
        this.findData(this.props.licDetails);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
        Store.removeListener("change", this.refreshMembersData);
        Store.removeListener("change", this.refreshAutoEnabled);
    }

    refreshMembers(licDetails, isPoolChanged) {
        this.setState({licName: licDetails.licId, enteredAttrValue: "", editAttrValue:""});
        this.findData(licDetails);
    }

    refreshAutoEnabled() {
        if(Store.getState().type === "REQ_AUTOADD_NAMEDUSER"){
            var flag = Store.getState().autoAddNamedUser;
            this.setState({enableAutoAdd: flag});
        }
    }

    findData(licDetails) {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({licDetails: licDetails});
        FetchHelper.findNamedUsers(licDetails, this.context.intl, !this.props.activeLicenseTabView);
        FetchHelper.getAutoEnabledForFeature(licDetails, this.context.intl);
    }

    selectedActionCall(selectedValue) {
        this.setState({selectedAttributes: selectedValue, enteredAttrValue: "", editAttrValue:""});
    }

    paginationCall(licDetails){
        this.setState({licDetails: licDetails});
        FetchHelper.findNamedUsers(licDetails, this.context.intl, !this.props.activeLicenseTabView);
    }

    refreshMembersData() {
        if(Store.getState() && Store.getState().actionType === "adddeletemember") {
            this.setState({userName: ""});
            this.setState({ipAddress: ""});
            this.setState({hostId: ""});
            this.setState({clientId: ""});
            this.setState({enteredAttrValue: "", selectedAttributes: []});
            this.findData(this.props.licDetails);
            let params = {};
            let type = Store.getState().type;
            let value = Store.getState().value;
            let action= Store.getState().action;
            let t = LocaleUtility.getDefualtString("User");
            if(type === "ip") {
                t = LocaleUtility.getDefualtString("IP");
            } else if(type === "hostid"){
                t = LocaleUtility.getDefualtString("Host");
            } else if(type === "clientid"){
                t = LocaleUtility.getDefualtString("Client");
            }
            params["type"] = t;
            params["value"] = value;
            if(action === "delete") {
                ConfigurationUtils.showSuccessMessageWithParams(params, "resv.member.delete.success", this.context.intl);
            } else {
                ConfigurationUtils.showSuccessMessageWithParams(params, "resv.member.added.success", this.context.intl);
            }
            if(this.memeberTabelRef !== undefined && this.memeberTabelRef.current !== undefined) {
                this.memeberTabelRef.current.clearSelections();
            }
        }
    }

    refreshGrid() {
        if(Store.getState().type === 'NAMEDUSER_LIST' &&
            Store.getState() && Store.getState().namedUserData !== undefined) {

            Store.getState().namedUserData.map(item => {
                var key = item.value;
                item.columnComp = this.paintTextBox(key, key);
                return item;
            });

            this.memeberTabelRef.current.refreshMembers(Store.getState().namedUserData, Store.getState().userpagination);
            this.setState({totalCount: Store.getState().userpagination.totalCount, enteredAttrValue: "", editAttrValue:"", selectedAttributes:[]});
            let array = document.getElementsByName("closeLink");
            for(let i=0; i<array.length;i++) {
                array[i].style.display = "none";
            }
            array = document.getElementsByName("txtBox");
            for(let i=0; i<array.length;i++) {
                array[i].style.display = "none";
            }
            array = document.getElementsByName("btnBox");
            for(let i=0; i<array.length;i++) {
                array[i].style.display = "none";
            }
            array = document.getElementsByName("valueSpan");
            for(let i=0; i<array.length;i++) {
                array[i].style.display = "inline-block";
            }
            array = document.getElementsByName("editIcon");
            for(let i=0; i<array.length;i++) {
                array[i].style.display = "inline-block";
            }

            if(this.state.isUserAddedOrDeleted){
                this.props.refreshLicenseList();
            }
        }
    }

    handleChangeAttributeValue(event) {
        let enteredValue = event.target.value;
        this.setState({enteredAttrValue: enteredValue});
    }

    handleChangeEditValue(event) {
        let enteredValue = event.target.value;
        this.setState({editAttrValue: enteredValue});
    }

    showEditTextBox(key, event){
        let array = document.getElementsByName("editIcon");
        for(let i=0; i<array.length;i++) {
            array[i].style.display = "none";
        }
        document.getElementById("text_"+key).style.display = "inline-block";
        document.getElementById("close_"+key).style.display = "inline-block";
        document.getElementById("save_"+key).style.display = "inline-block";
        document.getElementById("value_"+key).style.display = "none";
        document.getElementById("text_"+key).value = "";
        document.getElementById("text_"+key).focus();
        this.setState({oldAttrValue: key});
    }
    closeEditTextBox(key, event){
        this.setState({oldAttrValue: "",editAttrValue: "",enteredAttrValue: ""});
        let array = document.getElementsByName("editIcon");
        for(let i=0; i<array.length;i++) {
            array[i].style.display = "inline-block";
        }
        document.getElementById("save_"+key).style.display = "none";
        document.getElementById("text_"+key).style.display = "none";
        document.getElementById("close_"+key).style.display = "none";
        document.getElementById("value_"+key).style.display = "inline-block";
    }

    editActionCall() {
        this.addActionCall(true);
    }

    addActionCall(isEdit) {
        this.state.isUserAddedOrDeleted = true;
        let licDetails = this.state.licDetails;
        var flag = true;
        if(!isEdit && this.state.enteredAttrValue.trim() === ''){
            flag = false;
        } else if(isEdit && this.state.editAttrValue.trim() === ''){
            flag = false;
        }
        
        if(flag === true){
            this.setState({errorcode: []});
            let loadSpinAction = "NAMEDUSER_OPS_LOADING_SPIN";
            let params = {
                        licId:licDetails.licId,
                        featId:licDetails.featId,
                        featVersion:licDetails.featVersion,
                        featureLicenseIds:licDetails.featureLicenseIds,
                        };

            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            if(isEdit){
                params.namedUsers = {  value: this.state.editAttrValue.trim(), oldValue: this.state.oldAttrValue.trim() };
            } else {
                params.namedUsers = {  value: this.state.enteredAttrValue.trim() };
            }
            FetchHelper.addNamedUser(params, this.context.intl, !this.props.activeLicenseTabView); 
            
        } else {
            Dispatcher.dispatch({
                type: "FORM_SUBMIT_ERROR",
                payload: LocaleUtility.getLocaleMessage("named.user.alert2")
            });
        }
    }

    onConfirmDeleteUser(flag){
        if(flag === 1){
            this.state.isUserAddedOrDeleted = true;
            let loadSpinAction = "NAMEDUSER_OPS_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });

            FetchHelper.deleteNamedUser(this.state.licDetails, this.state.selectedAttributes, this.context.intl, !this.props.activeLicenseTabView);
        }
    }

    deleteUserAction() {
        this.setState({enteredAttrValue: ""});
        if(this.state.selectedAttributes.length !== 0){
            ValidationUtility.confirm(
                LocaleUtility.getLocaleMessage("named.user.delete.warn"),
                this.onConfirmDeleteUser, undefined, "", "");  
        } else {
            Dispatcher.dispatch({
                type: "FORM_SUBMIT_ERROR",
                payload: LocaleUtility.getLocaleMessage("named.user.alert1")
            });
        }  
    }

    paintTextBox(key, value){
        return (
            <div id={"div_"+key} style={{width: "100%",display: "inline-block"}}>
                <span id={"value_"+key} name="valueSpan"
                     style={{display:"inline-block"}}>{value}</span>
                <Anchor  title={LocaleUtility.getLocaleMessage("label.button.edit")} 
                     onClick={this.showEditTextBox.bind(this, key)} >
                    {this.props.activeLicenseTabView ? 
                        <EditIcon id={"edit_"+key} name="editIcon"
                                size="huge" colorIndex="brand" style={{}}/>
                    : null}    
                </Anchor>
                <div className="action_wrap_left">
                <TextInput className="tbl_inside_row_input" id={"text_"+key} name="txtBox"
                            style={{display:"none"}}
                            onChange={event =>this.handleChangeEditValue(event)}
                            placeholder={LocaleUtility.getLocaleMessage("type.value.to.edit.named.user", {value: key})}
                            />
                            <div className="button_wrap_primary">
                <Button id={"save_"+key} name="btnBox" label={LocaleUtility.getLocaleMessage("label.button.update")} 
                        style={{display:"none"}} plain={false} 
                            primary={true} onClick={this.editActionCall}/>
                            </div>
                <Anchor  title={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                     onClick={this.closeEditTextBox.bind(this, key)} >
                    <CloseIcon id={"close_"+key} name="closeLink"
                                size="huge" colorIndex="brand" style={{display:"none"}}/>
                </Anchor>
                </div>
            </div>
        )
    }

    okAction(event) {
        this.setState({selAttrFilter: event.option});
    }
    onAttributeSearchAction(event) {
        this.setState({attributeSearchedValue: event.target.value});
    }

    onToggleChangeHandler(isChecked){
        var flag, postData;
        if(isChecked){
            this.setState({enableAutoAdd: true});
            flag = true;
        }else{
            this.setState({enableAutoAdd: false});
            flag = false;
        }
        postData = {
            featureID: this.props.licDetails.featId,
            featureVersion: this.props.licDetails.featVersion,
            autoAddUser: flag
        }
        FetchHelper.updateAutoAddNamedUserFlag(postData);
    }

    sectionContent() {
        //let intl = this.context.intl;
        return(
            <div>
                {this.props.poolSize > 0 ?
                <div>
                    <table id="nameduser_lic_info" className="reservationMemTitle">
                        <tbody>
                            <tr>
                                <td id="reservation_pool_name_id" style={{width: "10%", color: "rgba(0, 0, 0, 0.56)", fontWeight: "normal"}}>
                                    {this.findLabel("named.user.license.label")}
                                </td>
                                <td id="nameduser_lic_name">
                                    :&nbsp;{this.props.licDetails.licName}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                : null }
                {this.props.poolSize > 0 ?
                <div id="resv_member_tabs_div_id">
                    <Header fixed={false} margin="none" id="named_user_details_wrap">
                        <Tabs style={{width: "100%"}} activeIndex={this.state.activeIndex} justify='start' >
                            <Tab id="nameduser_tab_id" title={this.findLabel("named.user.tab.label")}
                                className='subtab_title primary'>
                                <div className="reservation-attribute-tab">
                                    {this.props.activeLicenseTabView ? 
                                        <div className="align_center">
                                            {!this.props.fullView && (
                                            <div className="float_left_mar_5">
                                                {this.findLabel("named.user.field.label")}:
                                            </div>)}
                                            {!this.props.fullView ? 
                                                <div className="float_left_mar_5">
                                                <TextInput id="nameduser_add_user_id" style={{width: "270px"}}
                                                    value={this.state.enteredAttrValue} 
                                                    onChange={event =>this.handleChangeAttributeValue(event)}
                                                    placeholder={this.findLabel("type.value.to.add")}
                                                />
                                                </div>
                                            : null}
                                            {!this.props.fullView && (
                                                <div className="button_wrap_primary float_left_mar_5">
                                                    <Button id="nameduser_add_btn" label={this.findLabel("configpageExcludedClientADD")} plain={false} primary={true} onClick={this.addActionCall.bind(this,false)}/>
                                                </div>
                                            )}
                                            {!this.props.fullView && (
                                                <div className="button_wrap_negative float_left_mar_5">
                                                    <Button id="nameduser_delete_btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true} onClick={event=>this.deleteUserAction()} />
                                                </div>
                                            )}
                                            {!this.props.fullView && (
                                                <div className="autonameduser_radio">
                                                    <FormField style={{ width: '1000em', marginLeft:"20px" }} label= {this.findLabel("label.autoadd.nameduser.radio")} >
                                                    <CheckBox id="conf_autonamed_checkbox"
                                                        checked={this.state.enableAutoAdd}
                                                        label={this.state.enableAutoAdd ? this.findLabel("label.enabled") : this.findLabel("label.disabled")}
                                                        value={this.state.enableAutoAdd} onChange={(event) => this.onToggleChangeHandler(event.target.checked)} 
                                                        toggle={true} /> 
                                                    </FormField>
                                                </div>
                                            )}
                                        </div>
                                    : 
                                        <div className="align_center" style={{padding: "4px 0px 2px 3px"}} >
                                            {!this.props.fullView && (
                                                <div className="button_wrap_negative float_left_mar_5">
                                                    <Button id="nameduser_delete_btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true} onClick={event=>this.deleteUserAction()} />
                                                </div>
                                            )}
                                            {!this.props.fullView && (
                                                <div className="button_wrap_primary float_left_mar_5">
                                                    <Button id="nameduser_move_btn" label={this.findLabel("label.button.move")} plain={false} primary={true} onClick={event=>this.clickMoveUserButton()} />
                                                </div>
                                            )}
                                            
                                            {!this.props.fullView && (
                                                <div className="button_wrap_primary with_icon" title={this.findLabel("label.move.nameduser.history.tooltip")}>
                                                    <Button className="pool-import-history" onClick={this.props.showJobsPopupFromExpiredTab}>
                                                        <span id="history-pool-icon-id"><History colorIndex="brand" /></span>
                                                    </Button>
                                                </div>
                                            )}
                                            
                                        </div>
                                    }
                                    <div className="tableBody">
                                        <MembersTable 
                                            ref = {this.memeberTabelRef}
                                            //header = {this.findLabel("Associated Members", intl)}
                                            licDetails = {this.state.licDetails}
                                            label = {this.findLabel("resv.pool.username.tab.label")}
                                            selectedAction = {this.selectedActionCall}
                                            paginationCall = {this.paginationCall}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </Header>
                </div>
                : null }
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
    render() {
        return (
            <div >
                {this.sectionContent()}
                <SpinningNotification centerPage={true} />
            </div>
        );
    }

    clickMoveUserButton(){
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({enteredAttrValue: ""});
        if(this.state.selectedAttributes.length !== 0){
            this.processMoveButton(); 
        } else {
            Dispatcher.dispatch({
                type: "FORM_SUBMIT_ERROR",
                payload: LocaleUtility.getLocaleMessage("named.user.alert3")
            });
        }  
    }

    processMoveButton(){
            this.setState({errorcode: []});
            let loadSpinAction = "NAMEDUSER_OPS_LOADING_SPIN";
            let namedUserObjList = [];
            this.state.selectedAttributes.map(item => {
                namedUserObjList.push({value: item});
                return "";
            });
            let params ={
                            licId: this.state.licDetails.licId,
                            featId : this.state.licDetails.featId,
                            featVersion : this.state.licDetails.featVersion,
                            featureLicenseIds: this.state.licDetails.featureLicenseIds,
                            namedUsers: namedUserObjList
                        };

            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            
            //FetchHelper.moveNamedUser(params, this.context.intl, !this.props.activeLicenseTabView); 
            FetchHelper.processMoveNamedUser(params, this.context.intl, !this.props.activeLicenseTabView);
        }
}
// PoolMembers.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PoolMembers;