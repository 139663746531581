import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";

import SectionWithTitle from "../../generic/SectionWithTitle";
import { Button } from "grommet/components/Button";
//import { FormField } from "grommet/components/FormField";
import SpinningNotification from '../../generic/SpinningNotification';
import Select from "../../commons/APLSSelect";
import { CheckBox } from "grommet/components/CheckBox";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as TableUtility from "../../../utils/TableUtility";
import Store from "../../../flux/APTableStore";
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as LicenseUtility from "../../../utils/LicenseUtility";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import "../../../css/index.css";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { DocumentCsv as DocumentCsvIcon } from 'grommet-icons';
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class ArchivedLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            productNameSelected: undefined,
            productNameSelectOptions: [],
            selectAllRows: false,
            selectedRowKeys: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: 20
            }
        }
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.rowData = [];
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.refreshState = this.refreshState.bind(this);
        this.filterLicenseList = this.filterLicenseList.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.restoreLicense = this.restoreLicense.bind(this);
        this.removeLicense = this.removeLicense.bind(this);
        this.exportInstalledLicense = this.exportInstalledLicense.bind(this);
        this.exportLicenseAllKeys = this.exportLicenseAllKeys.bind(this);
        this.onConfrimRestoreAction = this.onConfrimRestoreAction.bind(this);
        this.onConfrimRemoveAction = this.onConfrimRemoveAction.bind(this)
        this.paintCheckBox = this.paintCheckBox.bind(this);
        this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
        this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
    }

    componentDidMount() {
        let loadSpinAction = "ARCHIVE_LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getAllLicenses(undefined, "ARCHIVED", undefined);
        Store.on("change", this.refreshState);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.refreshState);
    }

    onSortClicked(index, order, header) {
        var sortOrder = "ASC";
        let paginationTemp = this.state.pagination;
        let productName = this.state.productNameSelected;
        
        if(paginationTemp.sortOrder === "ASC"){
            sortOrder = "DESC";
        }
        
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = header[index].columnName;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.setState({ pagination: paginationTemp });
        FetchHelper.getAllLicenses(productName, "ARCHIVED", paginationTemp);
        //TableUtility.sort(columnName, order, header);
    }

    onFilterClicked(filterColumn, filterValue) {
        if (filterValue !== "") {
            TableUtility.filter(filterColumn, filterValue);
        }
        else {

        }
    }

    filterLicenseList(event){
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getAllLicenses(event.option.value, "ARCHIVED", undefined);
    }

    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        FetchHelper.getAllLicenses(undefined, "ARCHIVED", paginationTemp);
    }

    onRowClicked(keys, event){
        this.setState({
            selectedRowKeys: keys
        });
    }

    onConfrimRestoreAction(flag){
        if(flag === 1){
            var selectedKeys = this.state.selectedRowKeys;
            this.setState({
                selectedRowKeys: []
            });
            FetchHelper.restoreLicenseList(selectedKeys)
        }
    }

    onConfrimRemoveAction(flag){
        if(flag === 1){
            var selectedKeys = this.state.selectedRowKeys;
            this.setState({
                selectedRowKeys: []
            });
            FetchHelper.removeLicenseList(selectedKeys)
        }
    }

    restoreLicense() {
        if (this.state.selectedRowKeys !== undefined && 
                this.state.selectedRowKeys.length > 0) {

            ValidationUtility.confirm(
                LocaleUtility.getLocaleLabel(this.context.intl, "label.license.archive.alert3"), this.onConfrimRestoreAction);
            
        } else {
            if(this.state.rowData !== undefined && this.state.rowData.length > 0){
                Dispatcher.dispatch({
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.archive.alert1")
                });
            }
        }
    }

    
    removeLicense() {
        if (this.state.selectedRowKeys !== undefined && 
                this.state.selectedRowKeys.length > 0) {
            ValidationUtility.confirm(LocaleUtility.getLocaleLabel(this.context.intl, "label.license.archive.alert4"), this.onConfrimRemoveAction)
        } else {
            if(this.state.rowData !== undefined && this.state.rowData.length > 0){
                Dispatcher.dispatch({
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.archive.alert2")
                });
            }
        }
    }

    exportInstalledLicense(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let paginationTemp = this.state.pagination;
        FetchHelper.downloadInstalledLicenses(this.state.productNameSelected,this.context.intl, false, "ARCHIVED",paginationTemp,mimetype);
    }

    exportLicenseAllKeys(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let paginationTemp = this.state.pagination;
        FetchHelper.downloadInstalledLicenses(this.state.productNameSelected,this.context.intl, true, "ARCHIVED",paginationTemp,mimetype);
    }

    onHeaderCheckBoxClick(event){
        var keys = [];
        if(event.target !== undefined && event.target.checked){
            this.state.rowData.map(item => {
                keys.push(item["licenseSignature"]);
                return "";
            });
        } else if(event.target !== undefined && !event.target.checked) {
            keys = [];
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
                    var key = item.licenseSignature;
                    var index = keys.indexOf(key);
                    if(this.state.features === undefined || 
                        (this.state.features !== undefined && 
                            this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                        item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                        count++;
                    } else if(this.state.features !== undefined && index !== -1)
                        keys.splice(index, 1);
                    return item;
                });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

    onRowCheckBoxClick(key, event){
        var keys = Object.assign([], this.state.selectedRowKeys);
        if(event.target.type === 'radio'){
            keys = [];
            keys.push(key);
        } else if(event.target.type === 'checkbox'){
            if(event.target.checked){
                keys.push(key);
            } else {
                var index = keys.indexOf(key)
                if (index !== -1) {
                    keys.splice(index, 1);
                }
            }
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
            var key = item.licenseSignature;
            if(this.state.features === undefined ||
                 (this.state.features !== undefined &&
                    this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                count++;
            }
            return item;
        });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

    paintCheckBox(key, flag){
        return (
            <CheckBox checked={flag} onChange={this.onRowCheckBoxClick.bind(this, key)}/>
        )
    }
    
    refreshState() {
        var pagination = Store.getState().pagination;
                let sortIndex = 6;
                let indexx = Store.getState().sortIndex;
                switch (indexx) {
                    case "featureDesc":
                        sortIndex=1;
                        break;
                    case "productID":
                        sortIndex=2;
                        break;
                    case "ltuCapacity":
                        sortIndex=3;
                        break;
                    case "capacity":
                        sortIndex=4;
                        break;
                    case "startDate":
                        sortIndex=5;
                        break;
                    case "expiryDate":
                        sortIndex=6;
                        break;
                    case "installedDate":
                        sortIndex=7;
                        break;
                    case "installedBy":
                        sortIndex=8;
                        break;
                    case "lockCode":
                        sortIndex=9;
                        break;          
                    default:
                        sortIndex=7;
                        break;
                }
        if(pagination!==undefined){
            pagination.required = true;
            pagination.style = "default";
        }else if(pagination===undefined){
            pagination = {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: 20
            }
        }
        var sessObj = FetchHelper.getSession(); 
        if(sessObj.admin !== undefined && sessObj.admin) {
            let rows;
            if(Store.getState().type === "LICENSE_ARCHIVE_PAGE_FETCH_COMPLETE" &&
                Store.getState().licViewtableData!==undefined){
               rows = Store.getState().licViewtableData.map(item => {
                    var key = item.licenseSignature;
                    item.checkBoxColumn = this.paintCheckBox(key, false);
                    return item;
                });
                            this.setState({
                rowData: rows,
                pagination: pagination,
                sortIndex: sortIndex,
                sortAscending: Store.getState().sortAscending,
                productNameSelected: Store.getState().productNameSelected,
                productNameSelectOptions: Store.getState().productNameSelectOptions,
                selectedRowKeys: [],
                session: sessObj
            });
            }
            

        } else if(sessObj.admin !== undefined && !sessObj.admin && 
            sessObj.productList !== undefined ){
            var features = [];
            var validFeatures = FetchHelper.getUserFeatures();
            validFeatures.then(data => {
                features = data;
                let rows;
                if(Store.getState().licViewtableData!==undefined){
                rows = Store.getState().licViewtableData.map(item => {
                    var key = item.licenseSignature;
                    if(data !== undefined && data.indexOf(item.featureID+":"+item.featureVersion) > -1){
                        item.checkBoxColumn = this.paintCheckBox(key, false);
                    }else{
                        item.checkBoxColumn = "";
                    }
                    return item;
                });
                 this.setState({
                    rowData: rows,
                    features: features,
                    pagination: pagination,
                    sortIndex: sortIndex,
                    sortAscending: Store.getState().sortAscending,
                    productNameSelected: Store.getState().productNameSelected,
                    productNameSelectOptions: Store.getState().productNameSelectOptions,
                    selectedRowKeys: [],
                    session: sessObj
                });
                }
               
            }).catch((status, error) => {
                LicenseUtility.processError(status, error);
            });
        }

        let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }

    sectionContent() {

        const options = []; 
         let selectedValue = {value:undefined, label:LocaleUtility.getLocaleLabel(this.context.intl, "label.select.all")};
        options.push(selectedValue);
        let tokenLicenseFeature = {label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"};
        options.push(tokenLicenseFeature);
        if(this.state.productNameSelected === "1") {
            selectedValue = tokenLicenseFeature;
        }
        var entries = this.state.productNameSelectOptions.entries()
        for(var item of entries){
            options.push({value:item[0], label:item[1]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedValue = {value:item[0], label:item[1]};
            }
        }

        var classVar="grommetux-check-box";
        if(this.state.selectAllRows !== undefined && !this.state.selectAllRows &&
            this.state.selectedRowKeys.length > 0){
            classVar="grommetux-check-box partial";
        }

        return (
            <section id="lic_arch_listSec" className="page_section_wrap_1" pad="none" wrap={false} responsive={false} justify="center">
                <div className="lic_arch_wrap action_filter_wrapper">
                                {/*<FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.select.product")}>
                                <Select id="lic_arch_selProduct" options={options} value={selectedValue} onChange={this.filterLicenseList}/>
                                </FormField>*/}
                                <div className="action_wrap_left">
                                {this.state.rowData.length > 0 ?
                                <div className="license-view-buttons button_left_wrap">
                                    <div className="button_wrap_primary">
                                    <Button id="lic_arch_restBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.restore")}
                                        plain={false} primary={true} onClick={this.restoreLicense} />
                                    </div>
                                    <div className="button_wrap_negative">
                                    <Button id="lic_arch_remBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.delete")} 
                                        plain={false} secondary={true} critical={true} onClick={this.removeLicense} />
                                        </div>
                                </div>
                                :null}
                                <Select width={25} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.select.product")+": "} id="lic_arch_selProduct" options={options} value={selectedValue} onChange={this.filterLicenseList}/>
                                </div>
                                <div className="flexBox">
                                {this.state.rowData.length > 0 ? 
                                <React.Fragment>                         
                                    <div className="floatRight"> 
                                        {/* <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportInstalledLicense}><DocumentExcelIcon/></a>
                                        <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                                        <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportInstalledLicense}><DocumentDownloadIcon id="archive-download-license" colorIndex="brand"/></a>
                                    </div>
                                    <div style={{marginLeft: "8px"}} className="floatRight">  
                                        {/* <span style={{margin: "0 4px 0 0",padding: "0px 3px"}}>{LocaleUtility.getLocaleMessage("label.license.export.allkeys")} : </span>
                                        <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportLicenseAllKeys}><DocumentExcelIcon/></a> 
                                        <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                                        <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.license.export.allkeys")} onClick={this.exportLicenseAllKeys}><DocumentCsvIcon id="archive-download-allkeys" colorIndex="brand"/></a>                         
                                    </div>
                                </React.Fragment>
                                :null}
                                <SectionWithTitle id="lic_arch_mainSec" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.archived.license")} 
                                     helpTopic="C_ArchiveRestore_License"/>
                                     
                                </div>
                            </div>
                            {/*<td>
                                <SectionWithTitle id="lic_arch_mainSec" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.archived.license")} 
                                     helpTopic="C_ArchiveRestore_License"/>
                            </td>*/}
                                <div className="lic_arch_chk_wrap">
                                    <TableGrid id="lic_arch_grid"
                                        header={[
                                            {
                                                columnName: "checkBoxColumn",
                                                headerLabel: <CheckBox className={classVar} checked={(this.state.selectedRowKeys.length > 0)} onChange={this.onHeaderCheckBoxClick.bind(this)}/>,
                                                width: "4%"
                                            },
                                            {
                                                columnName: "featureDesc",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.feature"),
                                                href: false,
                                                width: "25%",
                                                sort:true
                                            },
                                            {
                                                columnName: "productID",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                                href: false,
                                                width: "10%",
                                                sort:true
                                            },
                                            {
                                                columnName: "capacity",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity"),
                                                href: false,
                                                width: "auto",
                                                sort:true
                                            },
                                            {
                                                columnName: "ltuCapacity",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu"),
                                                href: false,
                                                width: "5%",
                                                sort:true
                                            },
                                            {
                                                columnName: "startDate",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate"),
                                                href: false,
                                                width: "8%",
                                                sort:true
                                            },
                                            {
                                                columnName: "expiryDate",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate"),
                                                href: false,
                                                width: "8%",
                                                sort:true
                                            },
                                            {
                                                columnName: "installedDate",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.installedOn"),
                                                href: false,
                                                width: "9%",
                                                sort:true
                                            },
                                            {
                                                columnName: "installedBy",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.installedBy"),
                                                href: false,
                                                width: "8%",
                                                sort:true
                                            },
                                            {
                                                columnName: "lockCode",
                                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.lockcode"),
                                                href: false,
                                                width: "auto"
                                            }
                                        ]}
                                        rowData={this.state.rowData}
                                        pagination={this.state.pagination}
                                        rowSelection={0}
                                        isExportRequired={false}
                                        onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        onPagination={this.onPaginationClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        rowKey="licenseSignature"
                                        onChangeCheckBoxCallback={this.onRowClicked}
                                        selectAllRows={false}
                                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                                    />
                                </div>
                                <div>
                                    <SpinningNotification centerPage={true} />
                                </div>
                                <div style={{ clear: 'both' }}></div>          
            </section>
        );
    }

    render() {
        return (
            <div className="config-cont-backgroud">
                {this.sectionContent()}
            </div>
        );
    }
}

// ArchivedLicense.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ArchivedLicense;