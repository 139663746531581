import ReduceStore from "./ReduceStore";

class PageWizardStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            currentStep: 1
        };
    }
    reduce(action, state) {
        switch (action.type) {
            case "LICENSE_VALIDATE_COMPLETE": {
                return {
                    ...state,
                    type: action.type,
                    tableData: action.payload.tableData,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    disableIds: action.payload.disableIds,
                    currentStep: 2,
                    pagination: action.payload.pagination,
                    error: action.payload.error
                };
            } case "USER_AGREEMENT_FETCH_COMPLETE": {
                return {
                    ...state,
                    type: action.type,
                    currentStep: 1,
                    eulaCheck: action.payload.eulaCheck,
                    privacyCheck: action.payload.privacyCheck,
                    defaultCheck: action.payload.defaultCheck,
                    isStandAlone: action.payload.isStandAlone,
                    error: action.payload.error
                };
            }
            case "PAC_ENTITLEMENTS_FETCH_COMPLETE": {
                return {
                    ...state,
                    type: action.type,
                    entitlementData: action.payload.entitlementData,
                    tableData: action.payload.tableData,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    activationCode: action.payload.activationCode,
                    environments: action.payload.environments,
                    environmentSelected: action.payload.environmentSelected,
                    environmentDesc: action.payload.environmentDesc,
                    licenseList: action.payload.licenseList,
                    currentStep: action.payload.currentStep,
                    disableIds: action.payload.disableIds,
                    error: action.payload.error
                };
            }
            case "LICENSE_TOKEN_FETCH_COMPLETE": {
                 return { ...state,
                    cleanupData: action.payload.tableData,
                    cleanupTokenMap: action.payload.cleanupTokenMap,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    currentStep: 2,
                    pagination: action.payload.pagination,
                    error: action.payload.error
                };
            }
            case "LICENSE_CLEANUP_COMPLETE": {
                 return { ...state,
                    licenseDetail: action.payload.licenseDetail, 
                    cleanupKeyType:action.payload.cleanupKeyType};
            }
            case "LICENSE_FETCH_FEATURE": {
                 return { ...state,
                    featureDetails: action.payload.featureDetails };
            }
            // case "LICENSE_VALIDATION_ERROR": {
            //     return { error: action.payload };
            // }
            default:
                return state;
        }
    }
}

export default new PageWizardStore();