import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { DateInput } from "grommet/components/DateInput";
import { Select } from "grommet/components/Select";
import * as FetchHelper from "../../../../utils/FetchHelper";
import ProductStore from "../../../../flux/ProductStore";
import Dispatcher from "../../../../flux/Dispatcher";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import TenantCheckedOutLicenseDetail from "./TenantCheckedOutLicenseDetail";
import MultiTenantStore from "../../../../flux/MultiTenantStore";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as ValidationUtility from "../../../../utils/ValidationUtility";
import * as DateUtility from "../../../../utils/DateUtility";
import { CheckBox } from "grommet/components/CheckBox";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseTenantForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
                productNameSelected: undefined,
                productNameSelectOptions: ProductStore.getState().productNameSelectOptions,
                featureSelectOptions: [],
                licenseDetail: undefined,
                selectedTenantValue:'',
                tenantNames:[],
                serverPort:"5814",
                showFormPage: true,
                defaultExpiryDate: false,
                defaultTenants:[]
                
            }
        this.refreshProductItem = this.refreshProductItem.bind(this);
        this.assignFormSubmit = this.assignFormSubmit.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.onExpiryDateChange = this.onExpiryDateChange.bind(this);
        this.refreshTenantModal = this.refreshTenantModal.bind(this);
        this.filterTenantList = this.filterTenantList.bind(this);
        this.loadTenant = this.loadTenant.bind(this);
        this.hanldeDefaultExpiryDate = this.hanldeDefaultExpiryDate.bind(this);
        this.filterProductList = this.filterProductList.bind(this);
        this.setOptions = this.setOptions.bind(this);
    }

    componentDidMount() {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        let featureInfo = "";
        let selRecord = this.props.selectedRecord;
        if(selRecord !== undefined && selRecord !== null) {
            featureInfo = selRecord.featureID+":"+selRecord.featureVersion;
        }
        FetchHelper.getProducts(featureInfo, true);
        FetchHelper.loadTenantNames({}, this.context.intl, false);
        ProductStore.on("change", this.refreshProductItem);
        MultiTenantStore.on("change", this.refreshTenantModal);
        MultiTenantStore.on("change", this.loadTenant);
    }

    componentWillUnmount() {
        ProductStore.removeListener("change", this.refreshProductItem);
        MultiTenantStore.removeListener("change", this.refreshTenantModal);
        MultiTenantStore.removeListener("change", this.loadTenant);
    }

    loadTenant(){
        if(MultiTenantStore.getState().type==='DROPDOWN_LIST'){
            this.setState({
            tenantNames:MultiTenantStore.getState().ddData,
            defaultTenants: MultiTenantStore.getState().ddData
            })
        }
    }
 hanldeDefaultExpiryDate(event) {
    if(event.target.checked) {
        this.setState({defaultExpiryDate: true, expiryDate: ''});
    } else {
        let dateValue = '';
        let selRec = this.props.selectedRecord;
        if(selRec !== undefined && selRec !== null && selRec.expiryOriginalValue !== undefined) {
            dateValue = DateUtility.getDate(new Date(selRec.expiryOriginalValue * 1000));
        }
        this.setState({defaultExpiryDate: false, expiryDate: dateValue});
    }
 }
 filterTenantList(event) {
    const errors = Object.assign([], this.state.errorcode);
    let index = errors.indexOf('tenantName');
    if (index !== -1) {
        errors.splice(index, 1);
    }
    this.setState({selectedTenantValue: event.option.value, errorcode: errors});
  }
  filterProductList(event) {
    const errors = Object.assign([], this.state.errorcode);
    let index = errors.indexOf('tenantName');
    if (index !== -1) {
        errors.splice(index, 1);
    }
    this.setState({productNameSelected: event.option, errorcode: errors, productName: event.option.value});
  }
    refreshProductItem() {
        const products = ProductStore.getState().productNamesForTenant
        const features = ProductStore.getState().featureSelectOptions

        if(products !== undefined) {
            let selValue = ProductStore.getState().productNameSelected;
            if(selValue !== undefined) {
                products.map(item =>{
                    if(item !== undefined && item.value !== undefined 
                        && item.value === selValue) {
                            this.setState({productNameSelected: item});
                    }
                    return "";
                })
            }
            this.setState({
                productNameSelectOptions: products,
            });
        }
        if(features !== undefined) {
            this.setState({
                featureSelectOptions: features,
                productName: ProductStore.getState().productNameSelected
            });
        }
    }
    
    refreshTenantModal() {
        if(MultiTenantStore.getState().actionType === "assignlicense") {
            this.setState({ licenseDetail: MultiTenantStore.getState().licenseDetail, showFormPage: false});
        }
    }

    assignFormSubmit(event){
        event.preventDefault();
        var elements = event.target.elements;
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(elements.expiryDate.value === '' && this.state.defaultExpiryDate === false){
            let index = errors.indexOf(elements.expiryDate.name)
            if (index === -1) {
                errors.push(elements.expiryDate.name);
            }
            flag = false;
        }
        if(this.state.selectedTenantValue === undefined || this.state.selectedTenantValue === ''){
            let index = errors.indexOf("tenantName")
            if (index === -1) {
                errors.push("tenantName");
            }
            flag = false;
        }
        if(this.state.productName === undefined || this.state.productName === ''){
            let index = errors.indexOf("pdFileName")
            if (index === -1) {
                errors.push("pdFileName");
            }
            flag = false;
        }
        if(elements.capacity.value === '' || isNaN(elements.capacity.value)){
            let index = errors.indexOf(elements.capacity.name)
            if (index === -1) {
                errors.push(elements.capacity.name);
            }
            flag = false;
        }
        if(flag === true){
            this.setState({errorcode: []});
            let loadSpinAction = "LICENSE_BORROW_INPROGRESS_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            let selRecord = this.props.selectedRecord;
            if(selRecord !== undefined && selRecord !== null) {
                var d = '';
                if(this.state.defaultExpiryDate === true) {
                    d = selRecord.expiryOriginalValue*1000;
                } else {
                    d = new Date(this.state.expiryDate).getTime();
                }
                var postData = {
                    productName: this.state.productName,
                    featureID: selRecord.featureID,
                    featureVersion: selRecord.featureVersion,
                    tenantName:this.state.selectedTenantValue,
                    capacity: parseInt(elements.capacity.value),
                    expiryDate: d,
                    licenseSignature: selRecord.licenseSignature
                };
                FetchHelper.requestTenantLicenseCheckout(postData, this.state.productName);
            }
        } else {
            Dispatcher.dispatch({
                type: "FORM_SUBMIT_ERROR",
                payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
            });
            this.setState({errorcode: errors});
        }

    }

    onExpiryDateChange(value){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('expiryDate');
        if (value === ''){
            errors.push('expiryDate');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({expiryDate: value.value, errorcode: errors});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    validateTextInput(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)
        let field = event.target.name;
        this.setState({ [field]: event.target.value });
        if (event.target.value === '' || !ValidationUtility.isNumber(event.target.value)) {
            if (index === -1) {
                errors.push(event.target.name);
            }
            this.setState({errorcode: errors});
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            index = errors.indexOf('invalidPort')
            if(event.target.name === 'serverPort' && 
                    event.target.value.length > 4 ){
                if (index === -1) {
                    errors.push('invalidPort');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
                this.setState({port: event.target.value});
            }
            index = errors.indexOf('invalidCapacity')
            if(event.target.name === 'capacity' && 
                    parseInt(event.target.value) === 0 ){
                if (index === -1) {
                    errors.push('invalidCapacity');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            this.setState({errorcode: errors});
        }
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    setOptions(filteredList){
        this.setState({tenantNames: filteredList});
    }

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <TenantCheckedOutLicenseDetail licenseDetail={licDetail}/>
            );
        }
    }

    modalLicenseDetailContent() {

        let licDetail = this.state.licenseDetail;

        return (
            <div>
                <div style={{float: "left"}}>
                    {this.infoSectionContent(licDetail)}
                </div>
                {this.state.showFormPage === false && (<div style={{ width: '100%'}}>
                    <table style={{float: "right"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="button_wrap_secondary">
                                        <Button id="lic_assign_closeBtn" label={LocaleUtility.getLocaleMessage("label.button.close")}
                                            onClick={this.props.cancelAction} plain={false} secondary={true} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>)}
            </div>
        );
    }

    sectionContent() {
        let capacityFormFieldError, expDateFormFieldError,
        capacityError, expDateError,
        tenantError, tenantFormFieldError,pdFileFormFieldError, pdFileError;
        //const productOptions = [], featureOptions = []; 

        // let selectedValue = {value:undefined, label:LocaleUtility.getLocaleMessage("label.select.none")};
        
        // var entries = this.state.productNameSelectOptions.entries()
        // for(var item of entries){
        //     productOptions.push({value:item[0], label:item[1]})
        //     if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
        //         selectedValue = {value:item[0], label:item[1]};
        //     }
        // }
        // this.state.featureSelectOptions.map(value => featureOptions.push({value:value, label:value}));

        // if(this.state.protocol !== undefined){
        //     protocol = this.state.protocol;
        // }
        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('capacity') !== -1){
                capacityError =<span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert1")}</span>;
                capacityFormFieldError='grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidCapacity') !== -1){
                capacityError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert2")}</span>;
                capacityFormFieldError = 'grommetux-form-field--error';
            } 
            if(this.state.errorcode.indexOf('expiryDate') !== -1){
                expDateError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert5")}</span>;
                expDateFormFieldError = 'grommetux-form-field--error';
            } 
            if(this.state.errorcode.indexOf('tenantName') !== -1){
                tenantError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.license.form.tenant")}</span>;
                tenantFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('pdFileName') !== -1){
                pdFileError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.license.form.pd.file")}</span>;
                pdFileFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            capacityFormFieldError = '';
            expDateFormFieldError = '';
            capacityError = '';
            expDateError = '';
            tenantError = '';
            tenantFormFieldError = '';
            pdFileFormFieldError = '';
            pdFileError='';
        }
        let defExpDate = this.state.defaultExpiryDate;
        let selRecord = this.props.selectedRecord;
        let showCheckBox = true;
        if(selRecord !== undefined && selRecord !== null) {
            if(selRecord.expiryOriginalValue === "900703" || Number(selRecord.expiryOriginalValue) === 900703) {
                defExpDate = false;
                showCheckBox = false;
            }
        }
        let licDetail = this.props.selectedRecord;
        let disClassName = "enabledTextField";
        let svgEl = document.querySelector("#lic_assign_form_expirydate button");
        if(defExpDate === true) {
            disClassName = "disabledTextField";
            if(svgEl !== undefined && svgEl !== null && svgEl !== 'null') {
                svgEl.style.display = 'none';
            }
        } else if(svgEl !== undefined && svgEl !== null && svgEl !== 'null') {
            svgEl.style.display = 'block';
        }
        
        return (
            <section id="lic_bor_formSec" pad="none" wrap={false} responsive={false} justify="center">
                <div id="lic_bor_detailModal">
                    <table className="lic-detail-table table-bordered">
                        <tbody>
                            <tr className="lic-detail-head">
                                <td style={{width: "250px"}}>{LocaleUtility.getLocaleMessage("label.grid.col.feature")}</td>
                                <td>{LocaleUtility.getLocaleMessage("label.grid.col.prodNumber")}</td>
                                <td>{LocaleUtility.getLocaleMessage("label.grid.col.capacity")}</td>
                                <td>{LocaleUtility.getLocaleMessage("usage.report.admin.available.cap")}</td>
                                <td>{LocaleUtility.getLocaleMessage("label.grid.col.startDate")}</td>
                                <td>{LocaleUtility.getLocaleMessage("label.grid.col.expDate")}</td>
                            </tr>
                            {<tr>
                                <td>{licDetail.featureDesc}</td>
                                <td>{licDetail.productID}</td>
                                <td>{licDetail.capacity}</td>
                                <td>{licDetail.availableCapacity}</td>
                                <td>{licDetail.startDate}</td>
                                <td>{licDetail.expiryDate}</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <Form id="lic_bor_formDetail"compact={false} pad="small" plain={true} onSubmit={this.assignFormSubmit}>
                    <FormField style={{ width: '35em' }} label={LocaleUtility.getLocaleMessage("label.select.product")}
                        className={pdFileFormFieldError}>
                        <Select id="lic_assign_tenant_pd" name="pdFileName" options={this.state.productNameSelectOptions} 
                        value={this.state.productNameSelected} onChange={this.filterProductList}/>
                        {pdFileError}
                    </FormField>
                    <FormField style={{ width: '35em' }} label={LocaleUtility.getLocaleMessage("label.select.tenant.product")}
                        className={tenantFormFieldError}>
                        <Select id="lic_assign_tenant_name" name="tenantName" options={this.state.tenantNames} 
                        value={this.state.selectedTenantValue} onChange={this.filterTenantList}
                        onClose={() => this.setOptions(this.state.defaultTenants)}
                        emptySearchMessage={LocaleUtility.getLocaleMessage("usage.report.search.result.not.found")}
                        searchPlaceholder={LocaleUtility.getLocaleMessage("label.type.to.search")}
                        onSearch={(text) => {
                            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                            const exp = new RegExp(escapedText, 'i');
                            this.setOptions(this.state.defaultTenants.filter((o) => exp.test(o.label)));
                        }}/>
                        {tenantError}
                    </FormField>
                    <FormField style={{ width: '35em' }} label={LocaleUtility.getLocaleMessage("label.form.text.capacity")} 
                        className={capacityFormFieldError}>
                        <TextInput id='lic_assign_capacity' name='capacity' value={this.state.capacity} onChange={event =>this.validateTextInput(event)} />
                        {capacityError}
                    </FormField>
                    <FormField id="lic_assign_form_expirydate" style={{ width: '35em' }} label={LocaleUtility.getLocaleMessage("label.form.text.expDate")}
                        className={expDateFormFieldError}>
                        <DateInput id="lic_assign_expiryDate" name="expiryDate"
                            disabled={defExpDate}
                            className={disClassName+" datepicker"}
                            format={DateUtility.getDateFormat()} placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})} onChange={this.onExpiryDateChange} value={this.state.expiryDate} />
                        {expDateError}
                    </FormField>
                    {showCheckBox && (<FormField style={{ width: '30em' }}>
                        <CheckBox id="lic_assign_expiryDate_checkbox" 
                          checked={defExpDate} 
                          onChange={this.hanldeDefaultExpiryDate} 
                          label={LocaleUtility.getLocaleMessage("label.default.expiry.date")} />
                    </FormField>)}
                    <div className="button_left_wrap">
                        <div className="button_wrap_primary">
                            <Button id="lic_assign_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.submit")}
                                type="submit" plain={false} primary={true}
                            />
                        </div>
                        <div className="button_wrap_negative">
                            <Button id="lic_assign_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")}
                                onClick={this.props.cancelAction} plain={false} secondary={true} />
                        </div>
                    </div>
                </Form>
            </section>
        );
    }

  render() {
    let showHideModalStyle = this.props.showModal ? "create-pool-modal display-block" : "create-pool-modal display-none";
    return (
        <div id="assignLicTenantPopupId" className={showHideModalStyle}>
            <div id="assignLicTenantDivId" className="create-pool-modal">
                <div className="modal-main panel panel-default" style={{overflowY: "auto",maxHeight: "80%", top: "50%"}}>
                    <div>
                        {this.state.showFormPage && (this.sectionContent())}
                        {this.modalLicenseDetailContent()}
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

// LicenseTenantForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseTenantForm;