import React, { Component } from "react";
import { Box } from "grommet/components/Box";
import Table from "./utill/Table";
import TableHeader from "./pivot/PivotTableHeader";
import TableRows from "./pivot/PivotTableRows";
import Pagination from "./Pagination";
import APPagination from "./APPagination";
import PropTypes from "prop-types";
import "./css/APTable.scss";
import Select from "../APLSSelect";
import {getLocaleMessage, getLocaleLabel} from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PivotTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uncheckTheDisabled: this.props.uncheckTheDisabled,
      rowData: this.props.rowData,
      rowKey: this.props.rowKey,
      selectAllRows: this.props.selectAllRows,
      display:"none",
      showMenu: false,
      selPeriodFilter: undefined,
      colIndex: 2,
      selectedCheckBoxKey: this.getSelectedCheckboxKeys(this.props.selectAllRows, this.props),
      timePeriodOptions: [
                    {value: "", label:getLocaleMessage("label.select.none")},
                    {value: "4:HH24", label:getLocaleMessage("usage.select.period.hour.label")},
                    {value: "3:dd", label:getLocaleMessage("usage.select.period.day.label")},
                    {value: "2:WW", label:getLocaleMessage("usage.select.period.week.label")},
                    {value: "2:MMM", label:getLocaleMessage("usage.select.period.month.label")},
                    {value: "1:yyyy", label:getLocaleMessage("usage.select.period.year.label")}]
    };
    this.contextRef = React.createRef();
    this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
    this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.contextMenuTasks = this.contextMenuTasks.bind(this);
    this.filterPeriodList = this.filterPeriodList.bind(this);
    this.generateId = this.generateId.bind(this);
  }

  componentDidMount(){
    //document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    //document.removeEventListener("click", this.handleClick);
  }

  componentDidUpdate(prevProps, prevState) {
    var prevRow = prevProps.rowData[0];
    var curRow = this.props.rowData[0];
    if((prevProps.rowData.length !== this.props.rowData.length ||
       ( prevRow !== undefined && curRow !== undefined &&
         prevRow[this.props.rowKey] !== curRow[this.props.rowKey]))){
        if(this.state.selectAllRows === true || 
          (this.state.selectAllRows === false && this.state.selectedCheckBoxKey.length === 0)){
            this.setState({
              //selectedCheckBoxKey: this.getSelectedCheckboxKeys(this.state.selectAllRows, this.props),
              didUpdate: false
            });
          }
    } 
    if((prevProps.keys !== undefined && this.props.keys !== undefined &&
         prevProps.keys !== this.props.keys && this.props.keys.length === 0)){
      this.setState({
        selectedCheckBoxKey: [],
        didUpdate: true
      }); 
    }
  }

  getSelectedCheckboxKeys(selectAllRows, props){
    let disabledIds = props.disabledRowIds !== undefined ? 
                      props.disabledRowIds : [];
    if(selectAllRows === true){
      var keys = [];
      props.rowData.map(item => {
          if(props.uncheckTheDisabled === undefined || 
              props.uncheckTheDisabled === false){
            keys.push(item[props.rowKey]);
          } else {
            if(!disabledIds.includes(item[props.rowKey])) {
              keys.push(item[props.rowKey]);
            }
          }
          return "";
        });
      this.props.onChangeCheckBoxCallback(keys);
      return keys;
    } else {
      return [];
    }
  }

  onHeaderCheckBoxClick(event){
   // var selectAll = undefined;
    var keys = [];
    let disabledIds = this.props.disabledRowIds !== undefined ? 
                      this.props.disabledRowIds : [];
	let defaultSelectedIds = this.props.defaultSelectIds !== undefined ? this.props.defaultSelectIds : [];
    if(event.target !== undefined && event.target.checked){
        //selectAll = event.target.checked;
        this.props.rowData.map(item => {
          if(this.props.uncheckTheDisabled === undefined || 
              this.props.uncheckTheDisabled === false){
            keys.push(item[this.props.rowKey])
          } else {
            if(!disabledIds.includes(item[this.props.rowKey])) {
              keys.push(item[this.props.rowKey]);
            }
          }
          return "";
        });
		defaultSelectedIds.map(id =>{
			if(keys.indexOf(id) === -1) {
				keys.push(id);
			}
      return "";
		});
    } else if(event.target !== undefined && !event.target.checked) {
      let resKeys = [];
      if(disabledIds && disabledIds.length > 0) {
        if(this.props.uncheckTheDisabled === undefined || 
            this.props.uncheckTheDisabled === false){
            disabledIds.map(key => {
              resKeys.push(key);
              return "";
            });
        }
        keys = resKeys;
      }
	  defaultSelectedIds.map(id =>{
      if(disabledIds.indexOf(id) > -1) {
        keys.push(id);
      }
      return "";
	  });
	}
	
    this.setState({
        selectAllRows: (keys.length === this.props.rowData.length),
        selectedCheckBoxKey: keys,
        didUpdate: true
    });
    this.props.onChangeCheckBoxCallback(keys, event);
  }

  onRowCheckBoxClick(key, event){
    var keys = Object.assign([], this.state.selectedCheckBoxKey);
    if(event.target.type === 'radio'){
      keys = [];
      keys.push(key);
    } else if(event.target.type === 'checkbox'){
      if(event.target.checked){
        keys.push(key);
      } else {
          var index = keys.indexOf(key)
          if (index !== -1) {
            keys.splice(index, 1);
          }
      }
    }
    this.setState({
        selectAllRows: (keys.length === this.props.rowData.length),
        selectedCheckBoxKey: keys,
        didUpdate: true
    });
    this.props.onChangeCheckBoxCallback(keys, event);
  }
 
 getPagination = (pagination) => {
   if (pagination.required) {
     if (pagination.style === 'basic') {
       return <Pagination  
       totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
           pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
           />;
     } else if (pagination.style === 'default') {

      return <APPagination  
      totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
          pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
          />;
          
     } else {
      return <APPagination  
      totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
          pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
          />;
     }
   }
 };

  getHeaderOption = isExportRequired => {
    if (isExportRequired) {
      return (
        <div className="headerOptions">
          <div className="options">
            <a>
              <img src={require("./img/pdf.png")} alt={"Export to pdf"} />
            </a>
          </div>
          <div className="options">
            <a>
              <img
                src={require("./img/xls.png")}
                alt={"Export to excel sheet"}
              />
            </a>
          </div>
        </div>
      );
    }
  };

  renderTable(flag){
    this.setState({display: flag});
  }

  handleClick(e){
    if (this.state.showMenu && this.contextRef && !this.contextRef.current.contains(e.target)) this.setState({ showMenu: false });
  }

  handleContextMenu(index, event){
    event.preventDefault();
    this.setState({
        xPos: `${event.pageX}px`,
        yPos: `${event.pageY}px`,
        showMenu: true,
        colIndex: index
    });
  }

  filterPeriodList(event){
    this.props.filterPeriodList(this.state.colIndex, event);
  }

  contextMenuTasks(){
    return (
      <Select width={10} label={getLocaleMessage("usage.select.period.label")+": "} 
        id="context-menu-selperiod" options={this.state.timePeriodOptions} 
        value={{value: "", label:getLocaleMessage("label.select.none")}} onChange={this.filterPeriodList.bind(this)}/>
    )
  }

  generateId() {
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
    let result = "";
    let charactersLength = characters.length;
    for ( var i = 0; i < 5; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  render() {

    var showPagination, showCount, tableId;
    if (this.props.pagination !== undefined && this.props.pagination.totalCount > this.props.pagination.pageSize) {
        showPagination = this.getPagination(this.props.pagination);
    } else {
      if (this.props.itemsCountMessage !== undefined && this.props.pagination!==undefined && this.props.pagination.totalCount > 0)
        showCount = <div id="items_found_count_id" style={{float: 'right', paddingTop: "5px"}}>{this.props.itemsCountMessage}</div>
    }
    tableId = (this.props.id !== undefined ? this.props.id: this.generateId());

    return (
      <div id="pivot-main" className="tableBody" style={{position:"sticky", top:"95px"}}>
        <div id="pivot-spin" className="pivot-spin-div" style={{display:"none"}}>
            <div id="spinnerIcon" ><Box className="OtLoadingSpinnerSmall" /></div>
        </div>
        {this.getHeaderOption(this.props.isExportRequired)}
        <Table id={tableId} selectable={true} responsive={false} className="pivot-table">
          <TableHeader
            id={tableId}
            header={this.props.header}
            rowSelection={this.props.rowSelection}
            onSort={this.props.onSort}
            setDefaultFilter={this.props.setDefaultFilter}
            onFilter={this.props.onFilter}
            sortIndex={this.props.sortIndex}
            sortAscending={this.props.sortAscending}
            onChangeCheckBoxCallback={this.onHeaderCheckBoxClick}
            isSelectAllClicked={this.state.selectAllRows}
            selectedCheckBoxKey={this.state.selectedCheckBoxKey}
            childKey = {this.props.childKey}
            pivot = {true}
            reportMeta = {this.props.reportMeta}
            handleContextMenu = {this.handleContextMenu}
          />
          <TableRows
            header={this.props.header}
            rows={this.props.rowData}
            rowSelection={this.props.rowSelection}
            rowKey={this.props.rowKey}
            onChangeCheckBoxCallback={this.onRowCheckBoxClick}
            selectedCheckBoxKey={this.state.selectedCheckBoxKey}
            uncheckTheDisabled={this.props.uncheckTheDisabled}
            defaultSelectIds={this.props.defaultSelectIds}
            disabledRowIds={this.props.disabledRowIds}
            childKey = {this.props.childKey}
            expandAll = {this.props.expandAll}
            noDataFoundText = {this.props.noDataFoundText}
            addExtraRow = {this.props.addExtraRow}
            pivot = {true}
            reportMeta = {this.props.reportMeta}
            selPeriodIndex = {this.props.selPeriodIndex}
            sortIndex={this.props.sortIndex}
          />
        </Table>
        {/* <div ref={this.contextRef} ><ContextMenu top={this.state.xPos} left={this.state.yPos}
          showMenu={this.state.showMenu} tasks={this.contextMenuTasks()}/></div> */}
        <div className="aptable-pagination border_white_backg_shadow">
          {showCount}
          {showPagination}
        </div>
      </div>
    );
  }
}

PivotTable.propTypes = {
  header: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  sortIndex: PropTypes.number,
  sortAscending: PropTypes.bool
};
// PivotTable.contextTypes = {
//   intl: _propTypes2.default.object,
// };

export default PivotTable;
