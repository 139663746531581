import ReduceStore from "./ReduceStore";

class NotificationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            
            notifyBy: 0,
            expInDays: 0,
            capacityPersentage: 0,
            impFeatureTableData: [],
            
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_NOTIFICATION_DETAILS": {
                return {
                    notifyBy: action.payload.notifyBy,
                    expInDays: action.payload.expInDays,
                    capacityPersentage: action.payload.capacityPersentage,
                    emailIds: action.payload.emailIds,
                    adminEmail:action.payload.adminEmail
                                        
                };
            }
            default:
                return state;
        }
    }
}
export default new NotificationStore();