import ReduceStore from "./ReduceStore";

class CTConfigStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {            
            idmServerUrlValue: "",
            idmUserNameValue: "",
            idmPasswordValue: "",
            ctServerUrlValue:"",
            ctUsageFreequency:"",
            maxUsageChecked:true,
            errorDetail: null,
            ctHostName:""

        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_IDM_CT_TENANT_CONFIG_SETTING_DETAILS": {
                return {
                    tenantIdmServerUrlValue: action.payload.idmServerUrlValue,
                    tenantIdmUserNameValue: action.payload.idmUserNameValue,
                    tenantIdmPasswordValue: action.payload.idmPasswordValue,
                    ctTenantServerUrlValue: action.payload.ctServerUrlValue,
                    ctUsageFreequency: action.payload.ctUsageFreequency,
                    maxTenantUsageChecked: action.payload.maxUsageChecked,
                   // ctHostName:action.payload.ctHostName,
                    type:'CTTENANTSETTINGS',
                    featuresTenantsList:action.payload.featuresTenantsList                  
                };
            }
            case "POPULATE_REJECTED_DETAILS":{
                return{
                    ...state,
                    type:'REJECTEDCT',
                    rejectedList:action.payload.tableData,
                    pagination:action.payload.pagination,
                    mapData : action.payload.mapData
                }
            }
             case "POPULATE_IDM_CT_ASSET_CONFIG_SETTING_DETAILS": {
                return {
                    assetIdmServerUrlValue: action.payload.idmServerUrlValue,
                    assetIdmUserNameValue: action.payload.idmUserNameValue,
                    assetIdmPasswordValue: action.payload.idmPasswordValue,
                    ctAssetServerUrlValue: action.payload.ctServerUrlValue,
                    ctUsageFreequency: action.payload.ctUsageFreequency,
                    maxAssetUsageChecked: action.payload.maxUsageChecked,
                    type:'CTASSETSETTINGS',
                    featuresTenantsList:action.payload.featuresTenantsList,    
                    activeIndex: 0          
                };
            }
           
            default:
                return state;
        }
    }
}
export default new CTConfigStore();