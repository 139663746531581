import React, { Component } from "react";
// import BreadCrumb from "@micro-focus/quantum-ux-grommet/components/BreadcrumbsComponent";
import { Button } from "grommet/components/Button";
import TableGrid from "../../../../components/commons/table/APTable";
import "../../../../css/index.css";
import FileUploadComponent from "./FileUploadComponent";
import { List } from "../../../../components/commons/grommet/List";
import { ListItem } from "../../../../components/commons/grommet/ListItem";
//import Modal from "../../../generic/Modal";
import PageStore from "../../../../flux/PageWizardStore";
import * as FetchHelper from "../../../../utils/FetchHelper";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as FrontendPagePath from  "../../../../utils/FrontendPagePath";
import * as Utils from "../../../../utils/Util";
import Dispatcher from "../../../../flux/Dispatcher";
import CTConfigStore from "../../../../flux/CTConfigStore";
import * as ConfigurationUtils from "../../../../utils/ConfigurationUtils";
import { createSearchParams } from "react-router-dom";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class WizardStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenses: this.props.rowData,
            showModal: false,
            productIds:[],
            featureMap:[],
            ctflag:false,
            CTData:[]
        };
        this.installLicense = this.installLicense.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
      //  this.handleChangeProductId = this.handleChangeProductId.bind(this);
        this.validateLicenses = this.validateLicenses.bind(this);
        this.refreshState = this.refreshState.bind(this);        
    }

    installLicense() {
        if (this.state.licenses !== undefined) {
            if(this.validateLicenses()){
                const keys = Object.assign([], this.state.selectedRowKeys);
                if(keys.length === 0) {
                    // Dispatcher.dispatch({
                    //     type: "LICENSE_NOT_CHECKED_ALERT_SHOW_MODAL",
                    //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert6")
                    // });
                    //alert(LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert6"));
                    Dispatcher.dispatch({
                        type: "ALERT_SHOW_MODAL",
                        payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert6")
                    });
                    this.props.navigate({ pathname:FrontendPagePath.LICENSE_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
                } else {
                    var selectedDataList = [];
                    if(keys.length !== this.state.licenses.length){
                        this.state.licenses.forEach(element => {
                            var index = keys.indexOf(element.licenseID)
                            if (index !== -1) {
                                selectedDataList.push(element);
                            }
                        });
                    } else {
                    selectedDataList = this.state.licenses;
                    }
                    let requestData=[];
                    if(this.state.ctflag){
                        requestData["CTdata"] = this.state.CTData;
                    }                    
                    requestData["installData"] = selectedDataList;
                    FetchHelper.installLicenseList(requestData);
                }
            }
        } else {
           console.error("License Key List is empty");
        }
    }

    onRowClicked(keys, event){
        this.setState({
            selectedRowKeys: keys
        });
    }

    // handleChangeProductId(event, index, item){
    //     this.state.licenses.map(i => {
    //         if(i.licenseID===item.licenseID){
    //             i["tenantID"] = event.target.value;
    //         }
    //         return i;
    //     });
    // }
    validateLicenses(){
        let isvalid = true;
        /*if(this.state.ctflag){
            let keys = this.state.selectedRowKeys;
            let lic = this.state.licenses.map(i => {
                var index = keys.indexOf(i.licenseID)
                if (index !== -1) {
                    if(i.tenantID==="" || isNaN(i.tenantID)){
                        i["errorValue"] = true;
                        isvalid=false;
                    }else{
                        i["errorValue"] = false;
                    }
                }else{
                    i["errorValue"] = false;
                }
                return i;            
            });
            if(!isvalid){
                ConfigurationUtils.showErrorMessage1("tenantError", this.context.intl);
            }
            this.setState({licenses: lic});
        }*/
        return isvalid;
    }

    componentDidMount() {
        // let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        // Dispatcher.dispatch({
        //     type: loadSpinAction,
        //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        // });
        if(this.props.currentStep!==1){
            FetchHelper.fetchFeatures(this.context.intl);
           // FetchHelper.findCTConfiguration(this.context.intl);
        }
        PageStore.on("change", this.refreshState);
        CTConfigStore.on("change", this.refreshState);
    }

    componentWillUnmount() {
        PageStore.removeListener("change", this.refreshState);
        CTConfigStore.removeListener("change", this.refreshState);
    }

    refreshState(){
        if(PageStore.getState().featureDetails!==undefined && PageStore.getState().featureDetails.featuresTenantsList!==undefined){
            let featuremap = PageStore.getState().featureDetails.featuresTenantsList;
            //let features = this.state.featureMap;
            if(this.state.licenses!==undefined){
            let lic = this.state.licenses.map((item, index) => {
                let featureInput;
                let findData = featuremap.find((i) => String(item.featureId) === String(i.featureId));
                let classname = (item.errorValue)? "installTabInput inputError" : "installTabInput";
                // if(findData!==undefined){
                //     featureInput = findData.tenant;//<input className={classname} type="text" value={findData.tenant} onChange={(e)=>this.handleChangeProductId(e, index, item)} disabled={true}/>;
                // }else{
                //     featureInput = <input className={classname} type="text" data_class={classname} onChange={(e)=>this.handleChangeProductId(e, index, item)}/>;
                // }     
                // item["tenantID"] = featureInput;
                return item;    
            });
            this.setState({licenses: lic});
            }
        }
        let CTData = CTConfigStore.getState();
        if(CTData!==undefined){
            if(CTData.idmServerUrlValue!== undefined && CTData.idmServerUrlValue!==""){
                this.setState({ctflag: true,CTData:CTData});
            }
        }
        
        // let loadSpinAction = "LICENSE_VIEW_PAGE_FETCH_COMPLETE";
        // Dispatcher.dispatch({
        //     type: loadSpinAction,
        //     payload: ''
        // });
    }

    showLicenseTableGridWizard() {
        // if(this.state.licenses!==undefined){
        // this.state.licenses.map((item, index) => {
        //         let featureInput;
        //         let classname = (item.errorValue!== undefined && item.errorValue)? "installTabInput inputError" : "installTabInput";
        //         if(item.errorValue){
        //             featureInput = <div className={classname}><input className={classname} type="text" data-errorMsg="" onChange={(e)=>this.handleChangeProductId(e, index, item)}/><span>{LocaleUtility.getLocaleLabel(this.context.intl,"enter.the.tenant.ID")}</span></div>;
        //             item["tenantID"] = featureInput;
        //         }
        //         return item;    
        //     });
        // }
        let addButtonLabel, buttonLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license");
        if (this.state.licenses.length > 0) {
            addButtonLabel =
                <Button id="lic_inst_instLicBtn" label={buttonLabel} plain={false} primary={true} onClick={this.installLicense.bind(this)} />;
        } else {
            addButtonLabel =
                <Button id="lic_inst_instLicBtn" label={buttonLabel} plain={false} primary={true} disabled={true} />
        }
        let headerData = [
                            {
                                columnName: "featureDesc",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.feature"),
                                href: false,
                                width: "40%",
                            },
                            {
                                columnName: "productID",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                href: false,
                                width: "10%",
                            },
                            {
                                columnName: "ltuCapacity",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu"),
                                href: false,
                                width: "5%",
                            },
                            {
                                columnName: "capacity",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "startTimeInUTCSeconds",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "expiryTimeInUTCSeconds",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "remarks",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.remarks"),
                                href: false,
                                width: "auto",
                            }
                        ];
        /*if(this.state.ctflag){
            headerData.push({
                                columnName: "tenantID",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "lable.tenant"),
                                href: false,
                                width: "auto",                                
                                columnKey: "licenseID",
                                infoIcon: true,
                                infoIconText:LocaleUtility.getLocaleLabel(this.context.intl, "tenant.info"),
                                tootipAlignment: "left"
                            });
        }*/
        return (
            <div className="lic_inst_list_wrapper">
                <div>
                    <TableGrid id="lic_inst_licGrid"
                        header={headerData}
                        rowData={this.state.licenses}
                        pagination={this.props.pagination}
                        sortIndex={this.props.sortIndex}
                        rowSelection={3}
                        sortAscending={this.props.sortAscending}
                        rowKey="licenseID"
                        onChangeCheckBoxCallback={this.onRowClicked}
                        disabledRowIds={this.props.disabledRows}
                        uncheckTheDisabled={true}
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        selectAllRows={this.state.licenses.length > 0 ? true: false}
                    />
                </div>
                <div style={{ clear: 'both' }}></div>
                <div>
                    <div className="button_left_wrap">
                                <div className="button_wrap_primary">
                                    {addButtonLabel}
                                </div>
                                <div className="button_wrap_negative">
                                    <Button id="lic_inst_btnBack" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.back")} type="submit" accent={true} plain={false} onClick={this.props.prevPage} />
                                </div>
                            </div>
                </div>
                {this.showInvalidLicenseGrid()}
            </div>
        );
    }

    showInvalidLicenseGrid() {
        if (this.props.error.length > 0) {
            return (
                <div className="panel panel-default">
                    <div className="panel-heading">
                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.list.invalid.licenses")}
                    </div>
                    <div className="panel-body">
                        <List id="lic_inst_listItems" selectable={false}>
                            {this.props.error.map((item, index) => (
                                <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            )
        }
    }

    render() {
        let renderContent, nextBtnLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.next");
        if (this.props.currentStep === 1) {
            renderContent = <FileUploadComponent uploadBtnLabel={nextBtnLabel} />
        } else {
            renderContent = this.showLicenseTableGridWizard();
        }

        return (
            <div id="lic_inst_byFile">
                {renderContent}
            </div>
        );
    }
}

export default class InstallPageWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1
        };

        this.rowData = [];
        this.prevStep = this.prevStep.bind(this);
    }

    prevStep() {
        this.props.prevPage(this.props.currentStep, this.props.pageType);
    }

    render() {

        let stepOne, stepTwo;
        if (this.props.currentStep === 1) {
            stepOne = "active";
            stepTwo = "";
        } else {
            stepOne = "";
            stepTwo = "active";
        }
        return (
            <div>
                <div className="breadcrumb-div">
                    <ul className="breadcrumb">
                        <li className={stepOne}><span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.browse.license.files")}</span></li>
                        <li className={stepTwo}><span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license")}</span></li>
                    </ul>
                </div>
                <WizardStep currentStep={this.props.currentStep} {...this.props} 
                prevPage={this.prevStep.bind(this)} />
            </div>
        )
    }
}

// InstallPageWizard.contextTypes = {
//   intl: _propTypes2.default.object,
// };
// WizardStep.contextTypes = {
//   intl: _propTypes2.default.object,
// };