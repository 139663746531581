import React, { Component } from "react";
import { Notification } from 'grommet/components/Notification';
import MessageStore from "../../flux/MessageStore";

class AppNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      secondCount: 1,
      message: "",
      status:undefined
    };

    this.setMessage = this.setMessage.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  // setMessage() {
  //   if (this.handle) {
  //     clearInterval(this.handle);
  //   }
  //   console.log("MESSAGE STORE" + MessageStore.getState().message);
  //   this.setState({ 
  //     message: MessageStore.getState().message, 
  //     downCounter: 9,
  //     status: MessageStore.getState().status });

  //   this.handle = setInterval(() => {
  //     if (this.state.downCounter === 0) {
  //       this.setState({ message: "" });
  //       clearInterval(this.handle);
  //     } else {
  //       this.setState({ downCounter: this.state.downCounter - 1 });
  //     }
  //   }, 1000);
  // }

  setMessage() {
    //console.log("MESSAGE STORE" + MessageStore.getState().message);
    this.setState({ 
      message: MessageStore.getState().message, 
      status: MessageStore.getState().status });
  }

  onCloseHandle() {
    this.setState({ 
      message: "", 
      status: undefined });
  }


  componentDidMount() {
    MessageStore.on("change", this.setMessage);
  }

  componentWillUnmount() {
    MessageStore.removeListener("change", this.setMessage);
  }

  render() {
    //    if (this.state.message !== "") {
    // var divStyle = {
    //   border1: "5px solid pink",
    //   verticalAlign: "middle",
    //   minWidth: "40%",
    //   maxWidth: "80%",
    //   width: "auto",
    //   textAlign: "center",
    //   minHeight: "10px",
    //   height: "auto",
    //   overflowWrap: "break-word"
    // };
    //const message = this.state.message + " " + this.state.downCounter;
    return (
      <div className={`${this.state.status} notification-message`} >
        {this.state.message !== "" && this.state.status !== undefined && (
          <Notification id="message-id" message={this.state.message} status={this.state.status} size="large" closer={true} onClose={this.onCloseHandle}/>
        )}
      </div>
    );
  }
}

export default AppNotification;
