export const APLS_INDEX = "/autopass/index.html";
export const APLS_CONTEXT =
  (process.env.REACT_APP_PUBLIC_URL !== "" && process.env.REACT_APP_PUBLIC_URL !== undefined)? process.env.REACT_APP_PUBLIC_URL : process.env.PUBLIC_URL;
export const APLS_WS_REQ_CLEANUP_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/cleanup";
export const APLS_WS_REQ_VALIDATE_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/validate";
export const APLS_WS_REQ_INSTALL_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/install";
export const APLS_WS_REQ_REMOVE_BORROW_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/borrow/delete";
export const APLS_WS_REQ_CHECKIN_BORROW_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/borrow/checkin";
export const APLS_WS_REQ_CHECKOUT_BORROW_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/borrow";
export const APLS_WS_REQ_GET_PRODUCT_FEATURES =
  APLS_CONTEXT + "/wsservices/v11/product/features";
export const APLS_WS_REQ_GET_PRODUCT_IDS =
  APLS_CONTEXT + "/wsservices/v11/product/list";
export const APLS_WS_REQ_USER_PRODUCTS =
  APLS_CONTEXT + "/wsservices/v11/product/feature/user";
export const APLS_WS_REQ_BORROW_LICENSE_LIST =
  APLS_CONTEXT + "/wsservices/v11/license/borrow/list";
export const APLS_WS_REQ_CLEANUP_COMMUTER =
  APLS_CONTEXT + "/wsservices/v11/license/cleanup/token";
export const APLS_WS_REQ_CHECKED_OUT_TOKEN_LIST =
  APLS_CONTEXT + "/wsservices/v11/license/list/tokens";
export const APLS_WS_REQ_GET_LICENSE_DETAIL =
  APLS_CONTEXT + "/wsservices/v11/license/detail";
export const APLS_WS_REQ_REMOVE_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/remove";
export const APLS_WS_REQ_RESTORE_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/restore";
export const APLS_WS_REQ_ARCHIVE_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/license/archive";
export const APLS_WS_REQ_LICENSE_VIEW_LIST =
  APLS_CONTEXT + "/wsservices/v11/license/view";
export const APLS_WS_REQ_LICENSE_PRODUCT_FEATURE_LIST =
  APLS_CONTEXT + "/wsservices/v11/license/product/feature";
export const APLS_WS_REQ_PAC_ENTITLEMENTS =
  APLS_CONTEXT + "/wsservices/v11/license/entitlements";
export const APLS_WS_REQ_PAC_ACTIVATE_QTY =
  APLS_CONTEXT + "/wsservices/v11/license/entitlement/activate";
export const APLS_CONFIG_MAIN_TEST_MAIL =
  APLS_CONTEXT + "/wsservices/v11/appconfig/main/test/mail";
export const APLS_UPDATE_CONFIG_SETTING =
  APLS_CONTEXT + "/wsservices/v11/appconfig/main/update";
export const APLS_FING_CONFIG_SETTINGS =
  APLS_CONTEXT + "/wsservices/v11/appconfig/main/find";
export const APLS_FING_USER_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/user/find";
export const APLS_UPDATE_USER_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/user/update";
export const APLS_FIND_LDAP_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/ldap/find";
export const APLS_UPDATE_LDAP_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/ldap/update";
export const APLS_TEST_LDAP_CONNECTION =
  APLS_CONTEXT + "/wsservices/v11/appconfig/ldap/test/connection";
export const APLS_TEST_LDAP_AUTHENTICATION =
  APLS_CONTEXT + "/wsservices/v11/appconfig/ldap/test/authentication";
export const APLS_TEST_LDAP_MAPPING =
  APLS_CONTEXT + "/wsservices/v11/appconfig/ldap/test/mapping";
export const APLS_FIND_IDM_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/idm/find";
export const APLS_UPDATE_IDM_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/idm/update";
export const APLS_TEST_IDM_CONNECTION =
  APLS_CONTEXT + "/wsservices/v11/appconfig/idm/test/connection";
export const APLS_FIND_USAGE_HUB_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/usagehub/find";
export const APLS_UPDATE_USAGE_HUB_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/usagehub/update";
export const APLS_TEST_USAGE_HUB_CONNECTION =
  APLS_CONTEXT + "/wsservices/v11/appconfig/usagehub/test/connection";
export const APLS_FIND_DB_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/database/find";
export const APLS_UPDATE_DB_CONFIG =
  APLS_CONTEXT + "/wsservices/v11/appconfig/database/update";
export const APLS_TEST_DB_CONNECTION =
  APLS_CONTEXT + "/wsservices/v11/appconfig/database/test/connection";
export const APLS_WS_REQ_USER_AGREEMENT =
  APLS_CONTEXT + "/wsservices/v11/useragreement";
export const APLS_WS_USAGE_REPORT_LIST =
  APLS_CONTEXT + "/wsservices/v11/usage/list";
export const APLS_WS_USAGE_REPORT_LIST_TENANT_WISE =
  APLS_CONTEXT + "/wsservices/v23.4/usage/report/listtenatwise"; 
export const APLS_WS_USAGE_REPORT_LIST_TENANT_WISE_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v23.4/usage/report/summary/download";
export const APLS_WS_USAGE_PRODUCT_NAMES =
  APLS_CONTEXT + "/wsservices/v11/usage/productNames";
export const APLS_WS_USAGE_REPORT_SEARCH =
  APLS_CONTEXT + "/wsservices/v11/usage/search";
export const APLS_WS_USAGE_REPORT_SEARCH_EXPORT =
  APLS_CONTEXT + "/wsservices/v11/usage/search/download";
export const APLS_WS_USAGE_REPORT_MANAGE_COMMUTER =
  APLS_CONTEXT + "/wsservices/v11/usage/manage/commuter";
export const APLS_WS_USAGE_REPORT_PURGE =
  APLS_CONTEXT + "/wsservices/v11/usage/purge";
export const APLS_WS_USAGE_REPORT_REVOKE =
  APLS_CONTEXT + "/wsservices/v11/usage/revoke";
export const APLS_WS_USAGE_REPORT_SEND_REPORT =
  APLS_CONTEXT + "/wsservices/v11/usage/sendReport";
export const APLS_WS_USAGE_REPORT_INUSE =
  APLS_CONTEXT + "/wsservices/v11/usage/inuse";
export const APLS_WS_USAGE_REPORT_HISTORY =
  APLS_CONTEXT + "/wsservices/v11/usage/history";
export const APLS_WS_USAGE_REPORT_REJECTED =
  APLS_CONTEXT + "/wsservices/v11/usage/rejected";
export const APLS_WS_USAGE_REPORT_INUSE_REVOKE =
  APLS_CONTEXT + "/wsservices/v11/usage/inuse/revoke";
export const APLS_WS_USAGE_REPORT_HISTORY_GRAPH =
  APLS_CONTEXT + "/wsservices/json/HistoryFeatureUsageGraphTableJson";
export const APLS_WS_USAGE_REPORT_HISTORY_GRAPH_BY_POOL =
  APLS_CONTEXT + "/wsservices/json/HistoryFeatureUsageJson";
export const APLS_WS_USAGE_REPORT_UNIT_CONSUMPTION =
  APLS_CONTEXT + "/wsservices/v11/usage/unitcons";
export const APLS_WS_USAGE_REPORT_CONSUMPTION_GRAPH =
  APLS_CONTEXT + "/wsservices/json/v10.5/feature/usage/peak";
export const APLS_WS_USAGE_ADVANCED_REPORT_SEND_USAGE =
  APLS_CONTEXT + "/wsservices/json/v10.8/feature/usage/upload";
export const APLS_WS_USAGE_ADVANCED_REPORT_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/usage/advanced/reports/download";
export const APLS_WS_LOGIN_STATUS =
  APLS_CONTEXT + "/wsservices/v11/login/status";
export const APLS_WS_USER_PWD_RESET =
  APLS_CONTEXT + "/wsservices/v11/login/user/reset";
export const APLS_WS_PWD_CHANGE = APLS_CONTEXT + "/wsservices/v11/login/change";
export const APLS_WS_LOGIN_DETAIL =
  APLS_CONTEXT + "/wsservices/v11/login/details";
export const APLS_WS_PWD_RESET = APLS_CONTEXT + "/wsservices/v11/login/reset";
export const APLS_WS_LOGIN = APLS_CONTEXT + "/wsservices/v11/jwt/createcookie";
export const APLS_WS_LOGOUT = APLS_CONTEXT + "/wsservices/v11/jwt/blacklist";
export const APLS_WS_LOGIN_IDM = APLS_CONTEXT + "/wsservices/v11/jwt/idm";
export const APLS_WS_USER_SESSION =
  APLS_CONTEXT + "/wsservices/v11/jwt/user/session";
export const APLS_WS_REQ_USER_LIST =
  APLS_CONTEXT + "/wsservices/v11/user/userList";
export const APLS_WS_REQ_USER_CREATE =
  APLS_CONTEXT + "/wsservices/v11/user/create";
export const APLS_WS_REQ_USER_DELETE =
  APLS_CONTEXT + "/wsservices/v11/user/delete";
export const APLS_WS_REQ_USER_UPDATE =
  APLS_CONTEXT + "/wsservices/v11/user/update";
export const APLS_WS_REQ_USER_STATUS =
  APLS_CONTEXT + "/wsservices/v11/user/status";
export const APLS_WS_REQ_USER_SEARCH =
  APLS_CONTEXT + "/wsservices/v11/user/search";
export const APLS_WS_REQ_USER_ADD_GROUP =
  APLS_CONTEXT + "/wsservices/v11/user/addGroup";
export const APLS_WS_REQ_USER_GROUPLIST =
  APLS_CONTEXT + "/wsservices/v11/user/userGroupList";
export const APLS_WS_REQ_GROUP_CREATE =
  APLS_CONTEXT + "/wsservices/v11/user/creatGroup";
export const APLS_WS_REQ_GROUP_DELETE =
  APLS_CONTEXT + "/wsservices/v11/user/deleteGroup";
export const APLS_WS_REQ_GROUP_UPDATE =
  APLS_CONTEXT + "/wsservices/v11/user/updateGroup";
export const APLS_WS_REQ_SEARCH_USER_BY_GROUP =
  APLS_CONTEXT + "/wsservices/v11/user/searchUserByGroup";
export const APLS_WS_REQ_ADD_USER_TO_GROUP =
  APLS_CONTEXT + "/wsservices/v11/user/addUserToGroup";
export const APLS_WS_REQ_PRODUCT_TO_GROUP =
  APLS_CONTEXT + "/wsservices/v11/user/group/product";
export const APLS_WS_REQ_USER_GROUP_DEL =
  APLS_CONTEXT + "/wsservices/v11/user/group/list/item";
export const APLS_WS_REQ_USER_DEL_FROM_GROUP =
  APLS_CONTEXT + "/wsservices/v11/user/list/item";
export const APLS_WS_REQ_USER_LIST_EXPORT =
  APLS_CONTEXT + "/wsservices/v11/user/export/userList";
export const APLS_WS_REQ_GROUP_LIST_EXPORT =
  APLS_CONTEXT + "/wsservices/v11/user/export/groupList";
export const APLS_WS_REQ_BUY_MORE_LIC =
  APLS_CONTEXT + "/wsservices/v11/bMLic/licenses";
export const APLS_WS_REQ_UPDATE_REQUEST_LIC =
  APLS_CONTEXT + "/wsservices/v11/bMLic/requestLic";
export const APLS_WS_REQ_LICENSE_REPORT =
  APLS_CONTEXT + "/wsservices/v11/LicRep/getReport";
export const APLS_WS_REQ_LICENSE_REPORT_SEARCH =
  APLS_CONTEXT + "/wsservices/v11/LicRep/searchLic";
export const APLS_WS_RESERVATION_POOL_LIST =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/search";
export const APLS_WS_RESERVATION_DELETE_POOL =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool";
export const APLS_WS_RESERVATION_POOL_NAMES =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool";
export const APLS_WS_RESERVATION_CREATE_POOL =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool";
export const APLS_WS_RESERVATION_POOL_MEMBERS =
  APLS_CONTEXT + "/wsservices/v9.3/reservation/pool/members/list";
export const APLS_WS_RESERVATION_ADD_MEMBER_TO_POOL =
  APLS_CONTEXT + "/wsservices/v9.3/reservation/pool/members";
export const APLS_WS_RESERVATION_DELETE_MEMBER_TO_POOL =
  APLS_CONTEXT + "/wsservices/v9.3/reservation/pool/members";
export const APLS_WS_RESERVATION_POOL_MAPPING_PRODUCTS =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/mapping/products";
export const APLS_WS_RESERVATION_FEATURE_BASE_LIST =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/feature/list";
export const APLS_WS_RESERVATION_GET_ASSOCIATE_DISASSOCIATE_POOLS =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/feature";
export const APLS_WS_RESERVATION_ASSOCIATE_POOLS =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/feature/associatePool";
export const APLS_WS_RESERVATION_DISASSOCIATE_POOLS =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/feature/disassociatePool";
export const APLS_WS_RESERVATION_PRODUCT_MAPPING_SAVE =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/feature/product/mapping"; 
export const APLS_WS_RESERVATION_PRODUCT_DISASSOCIATE_POOLS =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/product/disassociatePool";
export const APLS_WS_USAGE_REPORT_UPDATE_COMMUTER =
  APLS_CONTEXT + "/wsservices/v11/usage/update/commuter";
export const APLS_WS_USAGE_EXPORT_INUSE =
  APLS_CONTEXT + "/wsservices/v11/usage/inuse/reports/download";
export const APLS_WS_USAGE_EXPORT_HISTORY =
  APLS_CONTEXT + "/wsservices/v11/usage/history/reports/download";
export const APLS_WS_USAGE_EXPORT_REJECTED =
  APLS_CONTEXT + "/wsservices/v11/usage/rejected/reports/download";
export const APLS_WS_USAGE_EXPORT_CONSUMPTION =
  APLS_CONTEXT + "/wsservices/v11/usage/consumption/reports/download";
export const APLS_WS_DOWNLOAD_USAGE_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/usage/license/download";
export const APLS_WS_DOWNLOAD_USAGE_SUMMARY =
  APLS_CONTEXT + "/wsservices/v11/usage/summary/download";
export const APLS_WS_INSTALLED_LICENSE_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/license/export/installed";
export const APLS_WS_ALL_LICENSE_KEYS_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/license/export/allkeys";
export const APLS_WS_LICENSE_REPORT_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/LicRep/searchLic";
export const APLS_WS_REQ_GET_USER_DETAIL =
  APLS_CONTEXT + "/wsservices/v11/user/detail";
export const APLS_WS_REQ_UPDATE_USER_DETAILS =
  APLS_CONTEXT + "/wsservices/v11/user/reset";
export const APLS_WS_SUBMIT_COMMUTER_CHECKOUT =
  APLS_CONTEXT + "/wsservices/v11/remote/checkout";
export const APLS_WS_SUBMIT_COMMUTER_CHECKIN =
  APLS_CONTEXT + "/wsservices/v11/remote/checkin";
export const APLS_WS_DOWNLOAD_COMMUTER_LICENSE =
  APLS_CONTEXT + "/wsservices/v11/remote/download";
export const APLS_WS_DOWNLOAD_COMMUTER_LICENSE_DISCARD =
  APLS_CONTEXT + "/wsservices/v11/remote/discard";
export const APLS_WS_DOWNLOAD_COMMUTER_LICENSE_SEND_MAIL =
  APLS_CONTEXT + "/wsservices/v11/remote/sendmail";
export const APLS_WS_IN_USAGE_GRAPH =
  APLS_CONTEXT + "/wsservices/json/UseFeatureUsageJson";
export const APLS_WS_IN_USAGE_GRAPH_LIC =
  APLS_CONTEXT + "/wsservices/json/UseConsumptionFeatureUsageJson";
export const APLS_WS_REQ_USER_ROLE_STATUS = 
  APLS_CONTEXT + "/wsservices/v11/user/role";
export const APLS_WS_REQ_USER_POOL_LIST = 
  APLS_CONTEXT + "/wsservices/v11/user/reservation/pools";
  export const APLS_WS_TOKEN_FEATURE_LIST = 
  APLS_CONTEXT + "/wsservices/v11/token/features";
export const APLS_WS_PRODUCT_FEATURE_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/license/export/product/feature";
export const APLS_WS_REQ_LICENSE_ADD_IMP_FEATURE = 
  APLS_CONTEXT + "/wsservices/v11/license/product/feature/addImplicitFeature";
export const APLS_WS_TOKEN_FEATURE_PD_LIST = 
  APLS_CONTEXT + "/wsservices/v11/token/product/features";
export const APLS_WS_USAGE_REPORT_INSTANCE_DETAIL =
  APLS_CONTEXT + "/wsservices/v11/usage/instance/list";
export const APLS_WS_RESERVATION_DOWNLOAD_TEMPLATE =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/template/download";
export const APLS_WS_RESERVATION_IMPORT_TEMPLATE =
  APLS_CONTEXT + "/wsservices/v10.9/reservation/pool/template/import";
export const APLS_LIST_JOBS =
  APLS_CONTEXT + "/wsservices/v11/appconfig/job/list";
export const APLS_JOB_FILE_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11/appconfig/job/download/file";
export const APLS_JOB_DELETE =
  APLS_CONTEXT + "/wsservices/v11/appconfig/job/delete";
export const APLS_WS_IDM_TEST =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/idmForCT/test/connection";
export const APLS_WS_SAVE_IDM_CONF =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/idmForCT/save"; 
export const APLS_WS_FETCH_IDM_CONF_FOR_TENANT_CT =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/idmForTenant/fetch"; 
export const APLS_WS_REQ_NAMEDUSER_LIC_LIST =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/license";
export const APLS_WS_REQ_NAMEDUSER_IMPORT =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/template/import";
export const APLS_WS_NAMEDUSER_DOWNLOAD_TEMPLATE =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/template/download";
export const APLS_WS_REQ_NAMEDUSER_FEATURE_LIST =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/features";
export const APLS_WS_REQ_NAMEDUSER =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser";
export const APLS_WS_REQ_NAMEDUSER_LIST =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/list";
export const APLS_WS_REQ_NAMEDUSER_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v11.7/wsnameduser/download";
export const APLS_WS_MOVE_NAMEDUSER =
  APLS_CONTEXT + "/wsservices/v24.3/wsnameduser/move";
export const APLS_WS_REQ_DELETE_EXPIRED_NAMEDUSER = 
  APLS_CONTEXT + "/wsservices/v24.3/wsnameduser/delete/expiredusers";
export const APLS_WS_TENANTS_LIST =
  APLS_CONTEXT + "/wsservices/v12/tenant/name/list";
export const APLS_WS_TENANTS_CREATE =
  APLS_CONTEXT + "/wsservices/v12/tenant/create/ui";
export const APLS_WS_TENANTS_DATA_CREATE =
  APLS_CONTEXT + "/wsservices/v12/tenant/schema/data";
export const APLS_WS_TENANTS_LIST_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v12/tenant/list/download";
export const APLS_WS_LOGS_LIST =
  APLS_CONTEXT + "/wsservices/v12/tenant/logs/list";
export const APLS_WS_LOGS_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v12/tenant/logs/download";
export const APLS_CONFIG_ENTRY_LIST =
  APLS_CONTEXT + "/wsservices/v12/tenant/config/entry";
export const APLS_CONFIG_ENTRY_UPDATE =
  APLS_CONTEXT + "/wsservices/v12/tenant/config/entry/update";
export const APLS_WS_REQ_GENERIC_USAGE =
  APLS_CONTEXT + "/wsservices/v12/usage/report";
export const APLS_WS_REQ_GENERIC_REPORT_LIST =
  APLS_CONTEXT + "/wsservices/v12/usage/report/list";
export const APLS_WS_REQ_ADD_USAGE_REPORT =
  APLS_CONTEXT + "/wsservices/v12/usage/report/add";
export const APLS_WS_TENANTS_LICENSE_CHECKOUT =
  APLS_CONTEXT + "/wsservices/v12/tenant/license/assign";
export const APLS_WS_TENANTS_LICENSE_CHECKIN =
  APLS_CONTEXT + "/wsservices/v12/tenant/license/return";
export const APLS_WS_REQ_CUSTOM_METRIC_LIST =
  APLS_CONTEXT + "/wsservices/v12/usage/metric";
export const APLS_WS_TENANTS_DROPDOWN_LIST =
  APLS_CONTEXT + "/wsservices/v12/tenant/name/dropdown";
export const APLS_WS_REQ_TENANT_LICENSE_LIST =
  APLS_CONTEXT + "/wsservices/v12/tenant/license/list";
export const APLS_WS_REQ_DEL_USAGE_REPORT =
  APLS_CONTEXT + "/wsservices/v12/usage/report/remove";
export const APLS_WS_SUB_NOTIFICATION_SAVE =
  APLS_CONTEXT + "/wsservices/v12.0/subScrition/save";
export const APLS_WS_SUB_NOTIFICATION_DETAILS =
  APLS_CONTEXT + "/wsservices/v12.0/subScrition/notificationDetails";
export const APLS_WS_REQ_FIELD_USAGE_REPORT =
  APLS_CONTEXT + "/wsservices/v12/usage/report/field";
export const APLS_WS_USAGE_REPORT_DOWNLOAD =
  APLS_CONTEXT + "/wsservices/v12/usage/report/download";
  export const APLS_WS_UPDATE_CT_CONF =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/ctfmap/update";
export const APLS_WS_REQ_LDAP_GROUP_SEARCH =
  APLS_CONTEXT + "/wsservices/v11/user/searchLDAPgroup";
export const APLS_WS_REQ_LDAP_GROUP_ASSAIGN =
  APLS_CONTEXT + "/wsservices/v11/user/assignLdapGroup";
export const APLS_WS_REQ_FETCH_FEATURES =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/idmForCT/featuresMap";
export const APLS_WS_REQ_CT_FEATURE_MAP_SAVE =
  APLS_CONTEXT + "/wsservices/v11.7.0/ct/ctfmap/save";
export const APLS_WS_REQ_LDAP_GROUP_DELETE =
  APLS_CONTEXT + "/wsservices/v11/user/removeLDAPgroup";
export const APLS_WS_REQ_LDAP_USER_DELETE =
  APLS_CONTEXT + "/wsservices/v11/user/removeLDAPUser";
export const APLS_WS_FETCH_CT_REJECTED = APLS_CONTEXT +"/wsservices/v12.1.0/ct/fetch";
export const APLS_WS_DELETE_CT_REJECTED = APLS_CONTEXT +"/wsservices/v12.1.0/ct/remove";
export const APLS_WS_RESEND_CT_REJECTED = APLS_CONTEXT +"/wsservices/v12.1.0/ct/retry";
export const APLS_WS_EXPORT_CT_REJECTED = APLS_CONTEXT +"/wsservices/v12.1.0/ct/export";
export const APLS_WS_TENANT_MIGRATION_RETRY = 
  APLS_CONTEXT + "/wsservices/v12/tenant/migration/retry";
export const APLS_WS_REQ_RENEW_LICENSE_DETAIL =
  APLS_CONTEXT + "/wsservices/v12.1.0/license/renewinfo";
export const APLS_WS_REQ_GET_FEATURE_FORPRODUCT =  APLS_CONTEXT + "/wsservices/v12.3/usage/feature/list";
export const APLS_WS_GET_PROD_HOST =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/fetch"; 
export const APLS_WS_GET_PROD_HOST_LICENSE =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/license";
export const APLS_WS_GET_PROD_HOST_LICENSE_VIEW =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/license/view";
export const APLS_WS_SEARCH_PROD_HOST =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/search";
export const APLS_WS_PROD_CUSTOM_METRIC =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/custommetric";
export const APLS_WS_PROD_USAGE_CONSUMPTION_GRAPH =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/peak";
export const APLS_WS_EXPORT_PROD_LICENSE =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/export/license";
export const APLS_WS_EXPORT_PROD_HOST =  APLS_CONTEXT + "/wsservices/v12.3.0/productusage/export";
export const APLS_WS_GET_USAGE_TENANT =  APLS_CONTEXT + "/wsservices/v12.3/usage/tenant/list";
export const APLS_WS_FETCH_IDM_CONF_FOR_ASSET_CT =  APLS_CONTEXT + "/wsservices/v11.7.0/ct/idmForAsset/fetch";
export const APLS_WS_FETCH_AUTH_TOKEN_LIST =  APLS_CONTEXT + "/wsservices/v2022.05/wstoken/list";
export const APLS_WS_ADD_AUTH_TOKEN =  APLS_CONTEXT + "/wsservices/v2022.05/wstoken";
export const APLS_WS_REMOVE_AUTH_TOKEN =  APLS_CONTEXT + "/wsservices/v2022.05/wstoken/remove";
export const APLS_WS_LOGIN_SAML = APLS_CONTEXT + "/wsservices/v11/jwt/saml";
export const APLS_WS_REQ_GET_PRODUCT_TENANT = APLS_CONTEXT + "/wsservices/v23.4/product/listtenatwise";
export const APLS_WS_FETCH_AUDIT_LIST = APLS_CONTEXT + "/wsservices/v23.4/audit/list";
export const APLS_WS_FETCH_AUDIT_REPORT = APLS_CONTEXT + "/wsservices/v23.4/audit/report";
export const APLS_WS_UPDATE_AUDIT_CONFIG = APLS_CONTEXT + "/wsservices/v23.4/audit/updateAuditConfig";
export const APLS_WS_UPLOAD_METADATA =
  APLS_CONTEXT + "/wsservices/v24.1/saml/upload/metadata";
export const APLS_WS_EXPORT_METADATA =
  APLS_CONTEXT + "/wsservices/v24.1/saml/export/spmeta";
export const APLS_WS_ENABLE_AUTOADD_NAMEDUSER =
  APLS_CONTEXT + "/wsservices/v24.1/wsnameduser/autoadd";
