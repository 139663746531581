import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import { CheckBox } from "grommet/components/CheckBox";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import Store from "../../../flux/APTableStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import NotificationStore from "../../../flux/NotificationStore";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class SubNotification extends Component {
    constructor(props) {
        super(props);
        this.state ={
            isFormDirty: false,
            enableexpireIndaysChecked: false,
            enableEmailNotificaionIndays:"",
            enableexpireForCapcityChecked: false,
            enableEmailNotificaionForCapacity:"",
            expDaysError: "",
            expInDays:0,
            capacityError: "",
            capacityPersentage:0,
            enteredAttrValue:"",
            rowData: [],
            impFeatureTableData: [],
            impFeatureModalData:[],
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            productNameSelected: undefined,
            productNameSelectOptions: [],
            selectAllRows: false,
            licenseDetail: undefined,
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            disableApplyBtnIndex:0,
            modalVisible:false,
            nuberOfDaysDisabled:true,
            capacityNotificationDisabled:true,
            selectedRowKeys: [],
            notifyBy:0,
            emailIdsList :"",
            adminEmail:"",
            emailError:"",
            doSave:true,
            serverConfigured:true,
            doAdd:true,
            doDelete:true

        };
     this.enableNotificationIndays = this.enableNotificationIndays.bind(this);
     this.handleEnableNotificationInDays = this.handleEnableNotificationInDays.bind(this);
     this.enableNotificationforCapacity = this.enableNotificationforCapacity.bind(this);
     this.handleEnableNotificationforCapacity = this.handleEnableNotificationforCapacity.bind(this);
     this.handleChangeexpInDays = this.handleChangeexpInDays.bind(this);
     this.handleChangecapacityPersentage = this.handleChangecapacityPersentage.bind(this);
     this.selectAttributeType = this.selectAttributeType.bind(this);
     this.handleChangeAttributeValue = this.handleChangeAttributeValue.bind(this);
     this.addActionCall = this.addActionCall.bind(this);
     this.onRowClicked = this.onRowClicked.bind(this);
     this.submitNotificationSettings = this.submitNotificationSettings.bind(this);
     this.refreshFormData = this.refreshFormData.bind(this);
     this.validateFormFields = this.validateFormFields.bind(this);
     this.clearErrors = this.clearErrors.bind(this);
    
    
    }
     findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        NotificationStore.on("change", this.refreshFormData);
        FetchHelper.listSubNotification(this.context.intl);
       
    }
    componentWillUnmount() {
      NotificationStore.removeListener("change", this.refreshFormData);             
    }

    refreshFormData(){

        let notifyBy = NotificationStore.getState().notifyBy;
        let expInDays = NotificationStore.getState().expInDays;
        let capacityPersentage = NotificationStore.getState().capacityPersentage;
        let emailIds = NotificationStore.getState().emailIds;
        let adminEmail=NotificationStore.getState().adminEmail;


        this.setState({notifyBy: notifyBy});
        this.setState({expInDays: expInDays});
        this.setState({capacityPersentage: capacityPersentage});
        this.setState({adminEmail:adminEmail});
        if(typeof(emailIds) !== "undefined" && emailIds.length > 0){
            this.setState({emailIdsList : emailIds[0]});
        }
        
        //this.setState({ctUsageFreequency: freequency});
        if(notifyBy === -1){
            this.setState({serverConfigured :false});
            this.setState({doSave : false});
            this.setState({doAdd : false});
            this.setState({doDelete : false});
            
        }
        if(notifyBy === 0){
            this.setState({enableexpireIndaysChecked : false});
            this.setState({enableexpireForCapcityChecked : false});
            this.setState({capacityNotificationDisabled : true});
            this.setState({nuberOfDaysDisabled : true});
        }else if(notifyBy === 3){
            this.setState({enableexpireIndaysChecked : true});
            this.setState({enableexpireForCapcityChecked : true});
            this.setState({capacityNotificationDisabled : false});
            this.setState({nuberOfDaysDisabled : false});
        }else if(notifyBy === 2){
            this.setState({enableexpireIndaysChecked : false});
            this.setState({enableexpireForCapcityChecked : true});
             this.setState({capacityNotificationDisabled : false});
        }else if(notifyBy === 1){
            this.setState({enableexpireIndaysChecked : true});
            this.setState({enableexpireForCapcityChecked : false});
             this.setState({nuberOfDaysDisabled : false});
        }
        let tempArray = [];
        if(emailIds){
            emailIds= emailIds + '';
            tempArray = emailIds.split(",");
        }
       
        let emailArray = [];
        if(tempArray.length>0){
            for(var i=0; i<tempArray.length;i++){
                if(tempArray[i] !== ""){
                     emailArray.push({email : tempArray[i] ,Product_Number : tempArray[i] });
                }
               
            }
        }
        
        this.setState({impFeatureTableData :emailArray });

        ConfigurationUtils.hideLoadMask(this.context.intl);

    }
    selectAttributeType(event) {
        this.setState({selAttrType: event.option, enteredAttrValue: ""});
    }
    render() {
        //let intl = this.context.intl; 
       
       return (<div className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("configpageDBtitle")}  helpTopic="R_DataBase_Configuration"/>
                <section pad="none" wrap={false} responsive={false} justify="center">
                   
                    <fieldset className="border_white_backg_shadow" disabled={!this.state.serverConfigured}>
                    <Form compact={false} id="conf_subn_formId" pad="small" plain={true} >
                        <div>
                            <h2 className="headerConfig">
                                {this.findLabel("notification")}
                            </h2>
                            
                            <div className="imp_note">
                                <b>{this.findLabel("usage.advance.report.note")}</b> {this.findLabel("notification.notes")} 
                            </div>
                            <div className="db_config_wrap">
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                         <FormField style={{ width: '50em' }} >
                                            <CheckBox id="conf_sub_mailexpdays" 
                                            checked={this.state.enableexpireIndaysChecked} 
                                            onChange={this.handleEnableNotificationInDays.bind(this)} 
                                            label={this.findLabel("licensePreExpiryNotification")} />
                                        </FormField>
                                    </div>                                   
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input" style={{paddingLeft:"3rem"}}>
                                        <FormField style={{ width: '60em' }} error={this.state.expDaysError} label={this.findLabel("numberofdaysbeforetonotifyinadvance")} >
                                            <TextInput id="sub_expInDaysId" style={{ width: '5em'}}  disabled={this.state.nuberOfDaysDisabled} value={this.state.expInDays} onChange={event=>this.handleChangeexpInDays(event)} />
                                        </FormField>
                                    </div>
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '50em' }} >
                                            <CheckBox id="conf_sub_mailexpcapacity" 
                                            checked={this.state.enableexpireForCapcityChecked} 
                                            onChange={this.handleEnableNotificationforCapacity.bind(this)} 
                                            label={this.findLabel("capacityThresholdNotification")} />
                                        </FormField>
                                    </div>
                                </div>
                               <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input" style={{paddingLeft:"3rem"}}>
                                        <FormField style={{ width: '60em' }} error={this.state.capacityError} label={this.findLabel("thresholdpercentage")} >
                                            <TextInput id="sub_capacityPersentage" style={{ width: '5em' }}  disabled={this.state.capacityNotificationDisabled} value={this.state.capacityPersentage} onChange={event=>this.handleChangecapacityPersentage(event)} />
                                        </FormField>
                                    </div>                 
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <h2 className="headerConfig">
                                            {this.findLabel("notificationMailAddress")}
                                        </h2>
                                    </div>
                                    
                                </div>
                                <div className="notification_wrapper">
                                    {this.state.doAdd ?
                                    <div>
                                        <div  className="button_left_wrap">
                                        {!this.props.fullView && (
                                        <div style={{float: "left"}}>
                                            {this.findLabel("subscription.email.field.label")}:
                                        </div>)}
                                        {!this.props.fullView ? 
                                            <div className="horizantal_label_input" style={{float: "left", marginRight: "6px", marginLeft: "6px"}}>
                                                <FormField error={this.state.emailError} style={{ width: '21em' }}>
                                                    <TextInput id="subscription_add_email_id" style={{width: "270px"}}
                                                        value={this.state.enteredAttrValue} 
                                                        onChange={event =>this.handleChangeAttributeValue(event)}
                                                        placeholder={this.findLabel("type.value.to.add")}
                                                    />
                                                </FormField>
                                            </div> 
                                        : null}
                                        {!this.props.fullView && (
                                            <div className="button_wrap_primary">
                                                <Button id="nameduser_add_btn" label={this.findLabel("configpageExcludedClientADD")} plain={false} primary={true} onClick={this.addActionCall.bind(this,false)}/>
                                            </div>
                                        )}
                                        {!this.props.fullView && (
                                            <div className="button_wrap_negative">
                                                {!this.state.doDelete ? 
                                                <Button id="nameduser_delete_btn" label={this.findLabel("label.button.delete")} plain={false}  critical={true} onClick={event=>this.deleteUserAction()} />
                                               :
                                               <Button id="nameduser_delete_btn" label={this.findLabel("label.button.delete")} plain={false}  critical={true}  />
                                               }
                                                </div>
                                        )}
                                        </div>
                                        <TableGrid
                                            id="ExplicitFeatureTable"
                                            header={[
                                                {
                                                columnName: "Product_Number",
                                                headerLabel: LocaleUtility.getLocaleMessage(
                                                    "label.grid.col.feature1"),
                                                    href: false,
                                                width: "auto"
                                                }
                                            ]}
                                            {...{ keys: this.state.selectedRowKeys }}
                                            rowData={this.state.impFeatureTableData}
                                            rowSelection={3}
                                            isExportRequired={false}
                                            onSort={this.onSortClicked}
                                            onFilter={this.onFilterClicked}
                                            sortIndex={this.state.sortIndex}
                                            sortAscending={this.state.sortAscending}
                                            rowKey="email"
                                            disabledRowIds={this.state.adminEmail}
                                            uncheckTheDisabled={true}
                                            onChangeCheckBoxCallback={this.onRowClicked}
                                            selectAllRows={false}
                                            noDataFoundText={LocaleUtility.getLocaleMessage(
                                                "no.items.found.message"
                                            )}
                                            
                                            />
                                    </div>
                                    :
                                    <div>
                                       {!this.props.fullView && (
                                        <div style={{float: "left"}}>
                                            {this.findLabel("subscription.email.field.label")}:
                                        </div>)}
                                        {!this.props.fullView ? 
                                            <div style={{float: "left", marginRight: "6px", marginLeft: "6px"}}>
                                                <FormField style={{ width: '21em' }} error={this.state.emailError}  >
                                                    <TextInput id="subscription_add_email_id" style={{width: "270px"}}
                                                        value={this.state.enteredAttrValue} 
                                                        onChange={event =>this.handleChangeAttributeValue(event)}
                                                        placeholder={this.findLabel("type.value.to.add")}
                                                    />
                                                </FormField>
                                            </div>
                                        : null}
                                        {!this.props.fullView && (
                                        <div >
                                            <div className="button_wrap_primary" style={{float: "left", marginRight: "12px"}}>
                                                <Button id="nameduser_add_btn" label={this.findLabel("configpageExcludedClientADD")} plain={false} primary={true} />
                                            </div>
                                        {/* // )}
                                        // {!this.props.fullView && ( */}
                                            <div className="button_wrap_negative" style={{float: "left", marginRight: "12px", textTransform: "uppercase"}}>
                                                <Button id="nameduser_delete_btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true}  />
                                            </div>
                                        </div>
                                        )}
                                        <TableGrid
                                            id="ExplicitFeatureTable"
                                            header={[
                                                {
                                                columnName: "Product_Number",
                                                headerLabel: LocaleUtility.getLocaleMessage(
                                                    "label.grid.col.feature1"),
                                                    href: false,
                                                width: "auto"
                                                }
                                            ]}
                                            {...{ keys: this.state.selectedRowKeys }}
                                            rowData={this.state.impFeatureTableData}
                                            rowSelection={3}
                                            isExportRequired={false}
                                            onSort={this.onSortClicked}
                                            onFilter={this.onFilterClicked}
                                            sortIndex={this.state.sortIndex}
                                            sortAscending={this.state.sortAscending}
                                            rowKey="email"
                                            onChangeCheckBoxCallback={this.onRowClicked}
                                            selectAllRows={false}
                                            noDataFoundText={LocaleUtility.getLocaleMessage(
                                                "no.items.found.message"
                                            )}
                                            
                                            />
                                    </div>
                                    }
                                </div>
                                </div>
                        </div>
                         <div>
                            <SpinningNotification centerPage={true} />
                        </div>
                    </Form>
                     <div className="saveBtnStyle button_wrap_primary">
                         {this.state.doSave ? 
                                                <Button id="notification_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitNotificationSettings} />
                                                :
                                                <Button id="notification_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} />
                        }
                        <div style={{ paddingLeft: "0px", paddingTop: "10px", color: "rgba(0, 0, 0, 0.56)"}}>
                            <b>{this.findLabel("usage.advance.report.note")}</b> {this.findLabel("notification.page.save.note")}
                        </div>
                     </div>

                 </fieldset>    
                 <SectionWithTitle title="" helpTopic="Notification"
                            setionWidth = "100"
                        />
                </section>
                </div>);
    }
    
handleEnableNotificationInDays(event) {
    let val = event.target.checked;
    //this.makeFormDirty();
    if(val === true){
       this.setState({nuberOfDaysDisabled: false});
       if(!this.state.capacityNotificationDisabled){
           this.setState({notifyBy : 3})
       }else{
           this.setState({notifyBy : 1})
       }
    }else{
       this.setState({nuberOfDaysDisabled: true});
       this.setState({expInDays :0, expDaysError: ""});
        if(!this.state.capacityNotificationDisabled){
            this.setState({notifyBy : 2});
        } else {
            this.setState({notifyBy : 0});
        }
    }
    
    this.enableNotificationIndays(val);
  }
  makeFormDirty() {
    this.setState({isFormDirty: true});
  }
   enableNotificationIndays(val) {
    if(val) {
      this.setState({enableexpireIndaysChecked: true});
      this.setState({enableEmailNotificaionIndays: "true"});
    } else {
      this.setState({enableexpireIndaysChecked: false});
      this.setState({enableEmailNotificaionIndays: "false"});
    }
  }
  enableNotificationforCapacity(val) {
    if(val) {
      this.setState({enableexpireForCapcityChecked: true});
      this.setState({enableEmailNotificaionForCapacity: "true"});
    } else {
      this.setState({enableexpireForCapcityChecked: false});
      this.setState({enableEmailNotificaionForCapacity: "false"});
    }
  }
  handleEnableNotificationforCapacity(event) {
    let val = event.target.checked;
    if(val === true){
        this.setState({capacityNotificationDisabled : false});
        if(!this.state.nuberOfDaysDisabled){
           this.setState({notifyBy : 3})
        }else{
           this.setState({notifyBy : 2})
        }
    }else{
        this.setState({capacityNotificationDisabled : true});
        this.setState({capacityPersentage:0, capacityError:""});
        if(!this.state.nuberOfDaysDisabled){
           this.setState({notifyBy : 1});
        } else {
            this.setState({notifyBy : 0});
        }
    }
    
    this.makeFormDirty();
    this.enableNotificationforCapacity(val);
  }
  
  handleChangeexpInDays(event) {
        let val = event.target.value;
        this.setState({expInDays: val});
        if(parseInt(val)===0){
            this.setState({expDaysError: this.findLabel("zero.is.not.valid.number")});
            this.setState({expInDays: val});
        }else{
            this.makeFormDirty();
            this.setState({expInDays: val});
            this.setState({expDaysError: ""});
        }      
    }
     handleChangecapacityPersentage(event) {
        //let intl = this.context.intl;
        let val = event.target.value;
        if(parseInt(val)===0){
            this.setState({capacityError: this.findLabel("zero.is.not.valid.number")});
            this.setState({capacityPersentage: val});
        }else{
            this.makeFormDirty();
            this.setState({capacityPersentage: val});
            this.setState({capacityError: ""});
        }        
    }
    handleChangeAttributeValue(event) {
        let enteredValue = event.target.value;
        let selType = "user";
        if(this.state.selAttrType !== undefined) {
            selType = this.state.selAttrType.value;
        }
        if(selType === "user") {
            this.setState({userName: enteredValue});
        } else if(selType === "ip") {
            this.setState({ipAddress: enteredValue});
        } else if(selType === "hostid") {
            this.setState({hostId: enteredValue});
        } else if(selType === "clientid") {
            this.setState({clientId: enteredValue});
        }
        this.setState({enteredAttrValue: enteredValue});
    }
    addActionCall(isEdit) {
        let emailArray = Object.assign([], this.state.impFeatureTableData);
        this.setState({emailError: ""});
        if(ValidationUtility.emailValidation(this.state.enteredAttrValue.toLowerCase())){
            if(this.state.emailIdsList.indexOf(this.state.enteredAttrValue.toLowerCase()) === -1){
                emailArray.push({email : this.state.enteredAttrValue ,Product_Number : this.state.enteredAttrValue })
                this.setState({ impFeatureTableData: emailArray });
                
                if (this.state.emailIdsList === "") {
                    this.setState({emailIdsList :  this.state.enteredAttrValue.toLowerCase() });
                }else{
                let email = this.state.emailIdsList.concat(",").concat(this.state.enteredAttrValue.toLocaleLowerCase());
                    this.setState({emailIdsList : email }); 
                }
                this.setState({enteredAttrValue: ""});
            }else{
                this.setState({emailError: this.findLabel("emailError1")});
            }
            
        }else{
             this.setState({emailError: this.findLabel("emailError")});
        }
        
        
      
        
    }

    onRowClicked(keys, event) {
        //this.props.selectedAction(keys);
        if(keys.length>0){
            this.setState({doDelete:false});
        }else{
            this.setState({doDelete:true});
        }
        this.state.selectedRowKeys = [];
        let selectedEmailArray = Object.assign([], this.state.selectedRowKeys);
        for(var i = 0; i< keys.length ; i++){
            if(event.currentTarget.checked){
                selectedEmailArray.push({email : keys[i], Product_Number: keys[i]}); 
                this.setState({ selectedRowKeys : selectedEmailArray });
             }
        }
        //selectedEmailArray
    }
    deleteUserAction(){
        //selectedRowKeys
        let selectedEmailIdsArr = this.state.selectedRowKeys;
        let emailArray = Object.assign([], this.state.impFeatureTableData);
        let emailIdsList = this.state.emailIdsList.split(",");
        for(var i=0; i<selectedEmailIdsArr.length; i++){
            for(var j=0; j<emailArray.length; j++){
                if(emailArray[j].email === selectedEmailIdsArr[i].email){
                    emailArray.splice(j,1);
                    emailIdsList.splice(j,1);
                    break;
                }
            }
            
            
        }
        this.setState({emailIdsList : emailIdsList.join(",")});
        this.setState({impFeatureTableData:emailArray});
        this.setState({selectedRowKeys:[]});
        

    }

    submitNotificationSettings(){
        let noOfDaysDisabled = this.state.nuberOfDaysDisabled;
        let capacityDisabled = this.state.capacityNotificationDisabled;
        if(!noOfDaysDisabled  &&  !capacityDisabled){
            this.setState({notifyBy : 3})
        }
        if(!noOfDaysDisabled  &&  capacityDisabled){
            this.setState({notifyBy : 1})
        }
        if(noOfDaysDisabled  &&  !capacityDisabled){
            this.setState({notifyBy : 2})
        }
       if(this.validateFormFields(this.state, this.context.intl)) {
            this.clearErrors();
            FetchHelper.saveSubNotification(this.state, this.context.intl);
       }
    }

    validateFormFields(data, intl) {
        let errorsMap = ConfigurationUtils.validateNotificationData(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["numberOfDays"] !== undefined && errorsMap["numberOfDays"] !== "") {
            this.setState({expDaysError: errorsMap["numberOfDays"]});
            flag = false;
        }
        if(errorsMap["capacityPersentage"] !== undefined && errorsMap["capacityPersentage"] !== "") {
            this.setState({capacityError: errorsMap["capacityPersentage"]});
            flag = false;
        }
               
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        return flag;
    }

    clearErrors(){
        this.setState({emailError: ""});
        this.setState({capacityError: ""});
        this.setState({expDaysError: ""});
    }
}


// SubNotification.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default SubNotification;