import Dispatcher from "../flux/Dispatcher";
export function processTokenFeatures(data) {
    let type = "PROCESS_TOKEN_FEATURE_LIST";
    let payload = {
        data : data,
        actionType: "tokenList"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processMappedFeaturesForToken(data) {
    let type = "PROCESS_TOKEN_FEATURE_PD_LIST";
    let payload = {
        data : data,
        actionType: "tokenMappedList"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}