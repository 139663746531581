import React, { Component } from "react";
import { useIntl } from 'react-intl';
import * as LocaleUtility from '../../../utils/LocaleUtility';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ExecutedByInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.findLabel = this.findLabel.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    render() {
        //let intl = this.context.intl;
        return(
            <div style={{display:"flex"}}>
                <div style={{width:"95%", justifyContent:"flex-start"}}>
                    <table style={{padding: "5px"}}>
                    <tbody>
                        <tr>
                            <td>
                                {this.findLabel("usage.search.checkedout.label")}
                            </td>
                            <td>
                                : {this.props.checkedOutBy}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {this.findLabel("usage.search.checkedin.label")}
                            </td>
                            <td>
                                : {this.props.checkedInBy}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="section_right">
                    <span onClick={this.props.closeTootipAction} style={{color: "#fff", padding: "5px", cursor: "pointer"}}>X</span>
                </div>
            </div>
        )
    }

    
}
// ExecutedByInfo.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ExecutedByInfo;