import React from 'react';
import Dispatcher from "../flux/Dispatcher";
import { Trash as TrashIcon } from 'grommet-icons';
import { Download } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import * as FrontendPagePath from  "../utils/FrontendPagePath";
import {getPoolsByUser} from  "../utils/FetchHelper";
import * as DateUtility from "./DateUtility";
import * as Utils from "./Util";
import * as LocaleUtility from "./LocaleUtility";
import { isEmpty, isNotEmpty } from './ValidationUtility';

export function processPoolsList(data, sortIndex) {
    let type = "POPULATE_RESERVATION_POOL_LIST";
    let pList = [];
    let poolNamesMap = {};
    let payload = {
                status: data.status,
                errorDetail: data.errorDetail,
                sortIndex: sortIndex,
                pagination: data.pagenation,
                sortOrder: data.pagenation.sortOrder,
                poolList: pList,
                poolsMap: poolNamesMap,
                defaultPoolId: data.defaultPoolId,
                showPoolMapping: data.showPoolMapping
            };
    if(data.poolList !== undefined && data.poolList.pool !== undefined) {
        pList = data.poolList.pool;

        var poolObj = getPoolsByUser();
        poolObj.then(pools => {
            pList.map(item => {
                Object.entries(item).map(([key, value]) => {
                    if(key === "poolCreationTime") {
                        if(value === 0) {
                            item[key] = "";
                        } else {
                            item[key] = DateUtility.getFormatedDate(new Date(value*1000));
                        }
                    }
                    return "";
                });
                if(pools === undefined || pools.indexOf(item.poolName) === -1){
                    item["delete"] = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
                }
                item.downloadIcon = <Download colorIndex="brand"/>;
                if(item.childs && item.childs.length > 0) {
                    recursiveCall(item.childs, pools);
                }
                return "";
            });

            
            if(data.poolsMap !== undefined) {
                Object.entries(data.poolsMap.entry).map(([index, obj])=> {
                    poolNamesMap[obj.key] = obj.value;
                    return "";
                });
            }
            
            payload = {
                status: data.status,
                errorDetail: data.errorDetail,
                sortIndex: sortIndex,
                pagination: data.pagenation,
                sortOrder: data.pagenation.sortOrder,
                poolList: pList,
                poolsMap: poolNamesMap,
                defaultPoolId: data.defaultPoolId,
                showPoolMapping: data.showPoolMapping
            };
            Dispatcher.dispatch({
                type: type,
                payload: payload
            });
        });
    } else {
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }  

}
function recursiveCall(poolList, validPools) {
    poolList.map(item => {
         Object.entries(item).map(([key, value]) => {
            if(key === "poolCreationTime") {
                if(value === 0) {
                    item[key] = "";
                } else {
                    item[key] = DateUtility.getFormatedDate(new Date(value*1000));
                }
            }
            return "";
        });
        if(validPools === undefined || validPools.indexOf(item.poolName) === -1){
            item["delete"] = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
        }
        item.downloadIcon = <Download colorIndex="brand"/>;
        if(item.childs && item.childs.length > 0) {
            recursiveCall(item.childs);
        }
        return "";
    });
}
export function processPoolNames(data) {
    let type = "POPULATE_RESERVATION_POOL_NAMES";
    let payload = {
        poolNames : data.poolName
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processDeletePool(data) {
    let type = "POPULATE_RESERVATION_DELETE_POOL";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processPoolMembers(data) {
    let type = "POPULATE_RESERVATION_POOL_MEMBERS";

    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processAddDeleteMemberToPool(data, action, memberType, value) {
    let type = "ADD_DEETE_MEMBER_TO_POOL";
    let payload = {
        data: data,
        action: action,
        type: memberType,
        value: value
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processProductName(data) {
    let type = "PROCESS_RESERVATION_PRODCUT_NAMES";
    let payload = {
        productNameList : data.productNameList
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processProductNameFeatures(data, sortIndex, productNameList) {
    let type = "PROCESS_RESERVATION_PRODCUT_FEATURE_LIST";
    let count = 0;
    let defalultSelFeature = 0;
    let mappingData = data.featureGroupMaps;
    let poolMapping = {};
    let featureObjectsMap = {};
    if(mappingData.entry !== undefined) {
        Object.entries(mappingData).map(([key, value]) =>{
            poolMapping[key] = value;
            return "";
        });
    } else {
        poolMapping = mappingData;
    }
    data.features.map(item => {
        if(count === 0) {
            defalultSelFeature = item.featureID;
        }
        if(mappingData) {
            item["poolMapping"] = poolMapping[item.featureID];
        }
        featureObjectsMap[item.featureID] = item;
        count++;
        return "";
    });
    let payload = {
        //features : data.features,
        //featureGroupMaps: data.featureGroupMaps,
        defalultSelFeature: defalultSelFeature,
        featureObjectsMap: featureObjectsMap,
        sortIndex: sortIndex,
        productNameList: productNameList,
        //groupList: data.groupList,
        data: data
        //pagination: data.pagenation,
        //sortOrder: data.pagenation.sortOrder
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processPoolMappingProducts(data, sortIndex) {
    let type = "PROCESS_RESERVATION_POOL_MAPPING_PRODUCTS";
    let listData = data.poolMappingValues;
    if(listData) {
        listData.map(item=>{
            Object.entries(item).map(([key, value])=>{
                if(key === "product") {
                    if(value === "1") {
                        value = "Token License Feature";
                    }
                    item[key] = <Anchor label={value} href={FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH+Utils.buildURLQueryParam()}/>
                } else if(key === "feature") {
                    item[key] = <Anchor label={value} href={FrontendPagePath.RESERVATION_FEATURE_BASE_PATH+Utils.buildURLQueryParam()}/>
                }
                return "";
            });
            return "";
        });
    }
    let payload = {
        listData : listData,
        groupDescription: data.groupDescription
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processAssociatedPools(data, sortIndex) {
    let type = "PROCESS_RESERVATION_ASSOCIATED_POOLS";
    let payload = {
        sortIndex: sortIndex,
        data: data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processAssociatedPoolsForPopup(data, sortIndex) {
    let type = "PROCESS_RESERVATION_ASSOCIATED_POOLS_FOR_POPUP";
    let poolsList = data.poolFeatureReservationList.pools;
    let payload = {
        poolsList : poolsList,
        sortIndex: sortIndex,
        pagination: data.pagenation,
        sortOrder: data.pagenation.sortOrder
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processAssociateORDisassociate(data) {
    let type = "PROCESS_RESERVATION_ASSOCIATED_DISASSOCIATE_POOL_ACTION";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processProductFeatureSave(data) {
    let type = "PROCESS_RESERVATION_PRODUCT_FEATURE_SAVE";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processProductPoolDisassociate(data) {
    let type = "PROCESS_RESERVATION_PRODUCT_DISASSOCIATE";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processCreatePool() {
    let type = "PROCESS_CREATE_POOL_SUCCESS";
    let payload = {
        actionType : "createPool"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function resetPoolsGrid(actionType) {
    let type = "PROCESS_RESET_POOLS_GRID";
    let payload = {
        actionType : actionType
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}