import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import AssoicatePools from "./AssociatePools";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as Util from "../../../utils/Util";
import Store from "../../../flux/ReservationManagementStore";
import Toggle from '../../generic/APLSToggle';
import { FormLock as FormLockIcon } from 'grommet-icons';
import APLSBack from "../../generic/APLSBack";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timer = null;

class ManagePools extends Component {
    constructor(props) {
        super(props);
        this.associatePoolRef = React.createRef();
        this.state = {
            poolsRowData: [],
            showAssociatePool: false,
            featureId: this.props.featureId,
            featureVersion: this.props.featureVersion,
            productName: this.props.productName,
            reservedStatus: this.props.reservedStatus,
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            sortIndex: 0,
            sortAscending: true,
            totalAvailableCapacity: 0,
            totalCapacity: 0,
            poolObjectsMap: {},
            restriValueMap: {},
            requestProcessing: false,
            isAssocited: true,
            associatedAction: true,
            searchPoolName: "",
            searchDescription: "",
            searchParent: "",
            admin:false
        }
        this.findLabel = this.findLabel.bind(this);
        this.closeAssociatePoolWindow = this.closeAssociatePoolWindow.bind(this);
        this.openAssociatePoolWindow = this.openAssociatePoolWindow.bind(this);
        this.opendisAssociatePoolWindow = this.opendisAssociatePoolWindow.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.changeCapacityValue = this.changeCapacityValue.bind(this);
        this.onAllowBlockAction = this.onAllowBlockAction.bind(this);
        this.buildRequestParams = this.buildRequestParams.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.refreshAction = this.refreshAction.bind(this);
        this.processData = this.processData.bind(this);
        this.onAllowBlockCheckBoxAction = this.onAllowBlockCheckBoxAction.bind(this);
        this.afterEditReservedCapacity = this.afterEditReservedCapacity.bind(this);
        this.beforeEditReservedCapacity = this.beforeEditReservedCapacity.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.buildAvailableCapacity = this.buildAvailableCapacity.bind(this);
        this.continueSearchAction = this.continueSearchAction.bind(this);
        this.continueCapacitySaveAction = this.continueCapacitySaveAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    closeAssociatePoolWindow() {
        this.setState({showAssociatePool: false});
        this.setState({validationErrorMessage: ""});
        this.refreshAction();
        if(this.associatePoolRef && this.associatePoolRef.current) {
            this.associatePoolRef.current.clearSelections();
            this.associatePoolRef.current.setState({selectedGridIds: []});
            this.associatePoolRef.current.setState({selectedPools: []});
            this.associatePoolRef.current.setState({rowData: []});
        }
    }
    openAssociatePoolWindow() {
        this.setState({showAssociatePool: true});
        this.setState({isAssocited: true});
        this.setState({associatedAction: true});
        if(this.associatePoolRef && this.associatePoolRef.current) {
            this.associatePoolRef.current.reloadPools(true);
        }
    }
    opendisAssociatePoolWindow() {
        this.setState({showAssociatePool: true});
        this.setState({isAssocited: false});
        this.setState({associatedAction: false});
        if(this.associatePoolRef && this.associatePoolRef.current) {
            this.associatePoolRef.current.reloadPools(false);
        }
    }
    componentDidMount() {
        Store.on("change", this.refreshGrid);
        this.findData(this.buildRequestParams("", 0, this.props.reservedStatus, this.state.sortIndex, this.state.pagination));
    }
    
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
    }
    refreshAction() {
        this.findData(this.buildRequestParams("", 0, this.props.reservedStatus, this.state.sortIndex, this.state.pagination));
    }
    findData(request) {
        this.setState({poolsRowData: []});
        FetchHelper.listAssociatedPools(request, this.context.intl);
    }
    changeCapacityValue(id, groupId, event, prevValue) {
        if(event.charCode === 13) {
            this.continueCapacitySaveAction(id, groupId, event, prevValue);
        }
    }
    continueCapacitySaveAction(id, groupId, event, prevValue) {
        let el = document.getElementById(id);
        let val = el.value;
        if(this.state.admin){
          if(ValidationUtility.isNumber(val)) {
              let resvCap = Number(val);
              el.value = resvCap;
              let poolObjet = this.state.poolObjectsMap[groupId];
              if(poolObjet && prevValue !== resvCap) {
                  this.setState({requestProcessing: true});
                  let restrict = this.state.restriValueMap[groupId];
                  let request = this.buildRequestParams(poolObjet, resvCap, restrict, this.state.sortIndex, this.state.pagination);
                  FetchHelper.allowOrBlockPool(request, this.context.intl, this.state.searchPoolName, this.state.searchParent, this.state.searchDescription);
              }
          } else {
              ConfigurationUtils.showErrorMessage1("configpageAlert511", this.context.intl);
          }
        }
    }
    afterEditReservedCapacity(rowObject, event) {
        let val = event.target.value;
        let restrict = this.state.restriValueMap[rowObject.id];
        event.target.value = val;
        if(restrict !== 1 && rowObject.id !== -1 && rowObject.id !== -2) {
            if(ValidationUtility.isNumber(val)) {
                let resvCap = Number(val);
                let poolObjet = rowObject;
                if(poolObjet) {
                    let restrict = this.state.restriValueMap[poolObjet.id];
                    let request = this.buildRequestParams(poolObjet, resvCap, restrict, this.state.sortIndex, this.state.pagination);
                    FetchHelper.allowOrBlockPool(request, this.context.intl, this.state.searchPoolName, this.state.searchParent, this.state.searchDescription);
                }
            } else {
                ConfigurationUtils.showErrorMessage1("configpageAlert511", this.context.intl);
            }
        }
    }
    beforeEditReservedCapacity(rowObject, event) {
        let restrict = this.state.restriValueMap[rowObject.id];
        if(restrict === 1 || rowObject.id === -1 || rowObject.id === -2) {
            event.target.value = 0;
            event.target.disabled = true;
        } else {
            event.target.disabled = false;
        }
    }
    onAllowBlockAction(value) {
        let poolObjet = this.state.poolObjectsMap[value];
        if(this.state.admin && poolObjet) {
            let restrict = 1;
            if(this.state.restriValueMap[value] === 1 && poolObjet.reservedCapacity === 0) {
                restrict = 0;
            }
            let request = this.buildRequestParams(poolObjet, 0, restrict, this.state.sortIndex, this.state.pagination);
            FetchHelper.allowOrBlockPool(request, this.context.intl, this.state.searchPoolName, this.state.searchParent, this.state.searchDescription);
        }
    }
    onAllowBlockCheckBoxAction(value) {
        /*let restrict = this.state.restriValueMap[value];
        if(restrict === 0) {
            this.onAllowBlockAction(value);
        }*/
        let poolObjet = this.state.poolObjectsMap[value]
        if(this.state.admin && poolObjet) {
            let rst = this.state.restriValueMap[value];
            if(rst === 1 && poolObjet.reservedCapacity === 0) {
                return false;
            }
            let restrict = rst === 0 ? 1 : 0;
            let request = this.buildRequestParams(poolObjet, poolObjet.reservedCapacity, restrict, this.state.sortIndex, this.state.pagination);
            FetchHelper.allowOrBlockPool(request, this.context.intl, this.state.searchPoolName, this.state.searchParent, this.state.searchDescription);
        }
    }
    buildRequestParams(poolObjet, reservedCapacity, restrict, sortIndex, pagination) {
        let request = {
            "poolName": poolObjet.poolName,
            "restrict": restrict,
            "reservedCapacity": reservedCapacity,
            "featureId": this.state.featureId,
            "featureVersion": this.state.featureVersion,
            "uniqueProductDefinitionKey": this.state.productName,
            "parentName": poolObjet.parentName,
            sortIndex: sortIndex,
            pagenation: pagination,
            treeView: true
        }
        return request;
    }
    onFilterClicked(filterColumn, filterValue, e) {
        if(timer !== null) {
            clearTimeout(timer);
        }
        if(e.charCode === 13) {
            this.continueSearchAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueSearchAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }
    continueSearchAction(filterColumn, filterValue) {
        let poolName  = this.state.searchPoolName;
        let desc = this.state.searchDescription;
        let parentName = this.state.searchParent;
        let searchAction = true;
        if(filterColumn === "poolName") {
            this.setState({searchPoolName: filterValue});
            poolName = filterValue;
        } else if(filterColumn === "poolDescription") {
            this.setState({searchDescription: filterValue});
            desc = filterValue;
        } else if(filterColumn === "parentName") {
            this.setState({searchParent: filterValue});
            parentName = filterValue;
        }
        if(poolName === "" && desc === "" && parentName === "") {
            searchAction = false;
        }
        let request = {
            "featureId": this.state.featureId,
            "featureVersion": this.state.featureVersion,
            "uniqueProductDefinitionKey": this.state.productName,
            "reservedStatus": 0,
            sortIndex: this.state.sortIndex,
            pagenation: this.state.pagination,
            treeView: true,
            isSearchAction: searchAction,
            pool: {
                poolName: poolName,
                poolDesc: desc,
                parent: parentName
            }
        }
        this.findData(request);
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "id";
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.findData(this.buildRequestParams("", 0, this.props.reservedStatus, index, paginationTemp));
    }

    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.buildRequestParams("", 0, this.props.reservedStatus, this.state.sortIndex, paginationTemp));
    }
    processData(poolList, poolsObjectsMap, restriValueMap, count, spanId, reservedCapacityMap) {
        poolList.map(item => {
            poolsObjectsMap[item.id] = item;
            restriValueMap[item.id] = item["restrict"];
            if(item.allocatedCapacity === undefined || item.allocatedCapacity === null 
                    || item.allocatedCapacity === "") {
                item["allocatedCapacity"] = "NA";
            }
            Object.entries(item).map(([key, value])=>{
               if(key === "restrict") {
                    if(item["id"] === -1 || item["id"] === -2) {
                        item["allowBlock"] = "";
                        item["restrictCheckBox"] = "";
                    } else if(item[key] === 1 && item["reservedCapacity"] === 0) {
                        item["restrictCheckBox"] = <span className="pool-restric-col"><Toggle className="toggle-classname" id={"toggle-"+item["id"]}  checked={true} disabled={true} icons={false} rightIcon={<FormLockIcon />}/></span>
                        if(!this.state.admin){//Disable for View Only user
                            item["allowBlock"] = <span className="allowBlockTd" >{this.findLabel("resv.allow.label")}</span>
                        } else {
                            /*item["allowBlock"] = <span className="allowBlockTd" onClick={(event)=>this.onAllowBlockAction(item["id"])}>{this.findLabel("resv.allow.label", this.context.intl)}</span>*/
                            item["allowBlock"] = <Toggle
                                                    id = {"allow-block-"+item["id"]} 
                                                    checked = {true} 
                                                    disabled = {false} 
                                                    value = {item["id"]}
                                                    onChange = {this.onAllowBlockAction}
                                                    toolTipText={this.findLabel("resv.block.label")}
                                                />
                        }
                    } else {
                        if(item[key] === 1) {
                            if(!this.state.admin){//Disable for View Only user
                                item["restrictCheckBox"] = <span className="pool-restric-col"><Toggle className="toggle-classname" id={"toggle-"+item["id"]}  checked={true} disabled={true} icons={false} rightIcon={<FormLockIcon />}/></span>
                            } else {
                                item["restrictCheckBox"] = <span className="pool-restric-col"><Toggle className="toggle-classname" id={"toggle-"+item["id"]}  checked={true} disabled={false} icons={false} rightIcon={<FormLockIcon />}/></span>
                            }
                        } else {
                            if(!this.state.admin){//Disable for View Only user
                                item["restrictCheckBox"] = <Toggle className="toggle-classname" id={"toggle-"+item["id"]}  checked={false} disabled={true} icons={false}/>
                            } else {
                                item["restrictCheckBox"] = <Toggle className="toggle-classname" id={"toggle-"+item["id"]}  checked={false} disabled={false} icons={false}/>
                            }
                        }
                        if(!this.state.admin){//Disable for View Only user
                            item["allowBlock"] = <span className="allowBlockTd" >{this.findLabel("resv.block.label")}</span>
                        } else{
                            /*item["allowBlock"] = <span className="allowBlockTd" onClick={(event)=>this.onAllowBlockAction(item["id"])}>{this.findLabel("resv.block.label", this.context.intl)}</span>*/
                            item["allowBlock"] = <Toggle
                                                    id = {"allow-block-"+item["id"]} 
                                                    checked = {false} 
                                                    disabled = {false} 
                                                    value = {item["id"]}
                                                    onChange = {this.onAllowBlockAction}
                                                    toolTipText={this.findLabel("resv.allow.label")}
                                                />
                        }
                    }
                } else if(key === "parentName") {
                    if(value === "Shared" || value === "Total") {
                        item[key] = <b>{this.findLabel(value)}</b>
                    } else if(reservedCapacityMap !== undefined) {
                        let val = reservedCapacityMap[item.parentName];
                        let text = "["+this.findLabel("label.avail.capacity")+" = ";
                        let closeBrace = "]";
                        val = Number(Util.roundOfValue(val));
                        if(val > 0) {
                            item[key] = <span>{value}<br/><b style={{opacity: "0.7"}}>{text}{val}{closeBrace}</b></span>;
                        }
                    }
                } else if(key === "reservedCapacity") {
                    //let id = "rescap-"+spanId;
                    let id = "rescap-"+item.id;
                  
                    if(this.state.admin && (item["restrict"] === 0 || item["reservedCapacity"] >0) &&  item["id"] > 0) {
                        item["reservedCapacityEl"] = <input className="reserveCapacityEdit" defaultValue={value} type="text" name={id} id={id} onBlur={(event)=>this.continueCapacitySaveAction(id, item.id, event, value)} onKeyPress={(event)=>this.changeCapacityValue(id, item.id, event, value)} />
                    } else {
                        item["reservedCapacityEl"] = <span className="reserveCapacityDisabled">{value}</span>
                    }
                    spanId++;
                } else if(key === "usedCapacity" || key === "availableCapacity" || key === "allocatedCapacity") {
                    if(value >= 0) {
                        item[key] = <span className="reserveCapacityDisabled">{Util.roundOfValue(value)}</span>
                    }
                }
                return "";
            });
            if(item.id < 0) {
                item["allocatedCapacity"] = "";
            }
            if(item.childs !== undefined && item.childs.length > 0) {
                this.processData(item.childs, poolsObjectsMap, restriValueMap, count, spanId, reservedCapacityMap);
            }
            return "";
        });
    }
    buildAvailableCapacity(poolsList, reservedCapacityMap) {
        poolsList.map(item => {
            let parentAvailableCapacity = 0;
            let availabelCapacity = Number(item.availableCapacity);
            let allocatedCapacity = Number(item.allocatedCapacity);
            if(availabelCapacity >= allocatedCapacity) {
                parentAvailableCapacity = availabelCapacity - allocatedCapacity;
            }
            reservedCapacityMap[item.poolName] = parentAvailableCapacity;
            if(item.childs !== undefined && item.childs.length > 0) {
                this.buildAvailableCapacity(item.childs, reservedCapacityMap);
            }
            return "";
        }); 
    }
    refreshGrid() {
        if(Store.getState() && Store.getState().actionType === "associatedpools") {
            /*let session = FetchHelper.getSession();
            if(session.admin || session.roletype === 'product'){
                this.setState({admin: true});
            }*/
            var data = FetchHelper.getSession(true);
            data.then(session => {
                if(session.admin || session.roletype === 'product'){
                    this.setState({admin: true});
                }
                let poolList = Store.getState().data.poolFeatureReservationList.pools;
                if(poolList !== undefined) {
                    poolList.map(item=>{
                        Object.entries(item).map(([key, value])=>{
                            try {
                                if(key === "availableCapacity" && value !== undefined) {
                                    item[key] = Number(Util.roundOfValue(value));
                                } else if(key === "usedCapacity" && value !== undefined) {
                                    item[key] = Number(Util.roundOfValue(value));
                                }
                            } catch (error){
                                console.log("Error in reservation pool"+error);
                            }
                            return "";
                        });
                        return "";
                    });
                }
                let poolsObjectsMap = {};
                let restriValueMap = {};
                let spanId = 1;
                let count = 0;
                if(poolList) {
                    let reservedCapacityMap = {};
                    var validFeatures = FetchHelper.getUserFeatures();
                    validFeatures.then(data => {
                        if(data !== undefined && data.indexOf(this.props.featureId+":"+this.props.featureVersion) > -1){
                            this.setState({admin: true});
                            this.buildAvailableCapacity(poolList, reservedCapacityMap);
                            this.processData(poolList, poolsObjectsMap, restriValueMap, count, spanId, reservedCapacityMap);
                            this.setState({poolObjectsMap: poolsObjectsMap});
                            this.setState({poolsRowData: poolList});
                            this.setState({restriValueMap: restriValueMap})
                        } else {
                            this.setState({admin: false});
                            this.buildAvailableCapacity(poolList, reservedCapacityMap);
                            this.processData(poolList, poolsObjectsMap, restriValueMap, count, spanId, reservedCapacityMap);
                            this.setState({poolObjectsMap: poolsObjectsMap});
                            this.setState({poolsRowData: poolList});
                            this.setState({restriValueMap: restriValueMap})
                        }     
                    });
                }
            }).catch(error => {
            });
            this.setState({totalAvailableCapacity: Store.getState().data.totalCapacity});
            this.setState({totalCapacity: Store.getState().data.availableCapacity});
            this.setState({requestProcessing: false});
            let sortIndex = Store.getState().sortIndex;
            let sortAscending = Store.getState().data.pagenation.sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            let pagination = Store.getState().data.pagenation;
            pagination.required = true;
            pagination.style = "default";
            try{
                this.setState({pagination: pagination});
            } catch (error) {

            }
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }

    render() {
        //let intl = this.context.intl;
        let expandable = false;
        let addExtraRow = false;
        let disabled = !this.state.admin;
        if(this.state.searchPoolName !== "" || this.state.searchParent !== "" || this.state.searchDescription !== "") {
            expandable = true;
        }
        if(this.state.poolsRowData.length === 2) {
            addExtraRow = true;
        }
        return(
            <div className="associate_pool_wrap">
                <APLSBack 
                    id="feature_base_manage_backBtn" 
                    onClick={this.props.backButtonAction}
                />
                <div className="border_white_backg_shadow">
                    {/* <div className=""> */}
                            {disabled ?
                                <div className="button_left_wrap">
                                    <div className="button_wrap_primary">
                                    <Button id="feature_base_associatePoolBtn" label={this.findLabel("label.button.associate")} plain={false} primary={true} disabled={true}/>
                                    </div>
                                    <div className="button_wrap_negative">
                                        <Button id="feature_base_disassociatePoolBtn" label={this.findLabel("label.button.disassociate")} plain={false} primary={true} disabled={true}/>
                                    </div>
                                </div>
                            : 
                                <div className="button_left_wrap">
                                    <div className="button_wrap_primary">
                                        <Button id="feature_base_associatePoolBtn" label={this.findLabel("label.button.associate")} plain={false} primary={true} onClick={this.openAssociatePoolWindow} />
                                    </div>
                                    <div className="button_wrap_negative">
                                        <Button id="feature_base_disassociatePoolBtn" label={this.findLabel("label.button.disassociate")} plain={false} primary={true} onClick={this.opendisAssociatePoolWindow} />
                                    </div>
                                </div>
                            }
                        {/* </div> */}
                </div>
                <div className="reservationMemTitle border_white_backg_shadow">
                        <div className="managePoolHeadertd">
                            {this.findLabel("resv.feature.manage.feature")}
                            <span className="managePoolHeader">{this.props.featureId}:{this.props.featureVersion}</span>
                        </div>
                        <div className="managePoolHeadertd">
                            {this.findLabel("resv.feature.manage.available.capacity")}
                            <span className="managePoolHeader">{this.state.totalAvailableCapacity}</span>
                        </div>
                        <div className="managePoolHeadertd">
                            {this.findLabel("resv.feature.manage.total.capacity")}
                            <span className="managePoolHeader">{this.state.totalCapacity}</span>
                        </div>
                        {/* <td width="70%">&nbsp;</td> */}
                </div>
                <div className="border_white_backg_shadow">
                <div className="table_wrapper">
                    <TableGrid id="associated_list_grid"
                        header={[
                            {
                                columnName: "poolName",
                                headerLabel: this.findLabel("resv.grid.header.poolname"),
                                width: "12%",
                                columnKey: "poolName",
                                filter: true,
                                filterPlaceHolder: this.findLabel("resv.placeholder.name.label")
                            },
                            {
                                columnName: "poolDescription",
                                headerLabel: this.findLabel("resv.grid.header.pooldesc"),
                                width: "auto",
                                filter: true,
                                filterPlaceHolder: this.findLabel("resv.placeholder.desc.label")
                            },
                            {
                                columnName: "parentName",
                                headerLabel: this.findLabel("resv.grid.header.poolparent") ,
                                href: false,
                                width: "12%",
                                filter: true,
                                filterPlaceHolder: this.findLabel("resv.pool.placeholder.parent.label")
                            },
                            {
                                columnName: "reservedCapacityEl",
                                headerLabel: this.findLabel("resv.reserved.capacity.label") ,
                                href: false,
                                width: "7%",
                                //editable: true,
                                //editFieldType: "text",
                                //className: "reserveCapacityEdit",
                                //afterEdit: this.afterEditReservedCapacity,
                                //beforeEdit: this.beforeEditReservedCapacity
                            },
                            {
                                columnName: "allocatedCapacity",
                                headerLabel: this.findLabel("resv.reserved.allowed.label") ,
                                href: false,
                                width: "7%",
                            },
                            {
                                columnName: "usedCapacity",
                                headerLabel: this.findLabel("resv.reserved.in.use.label") ,
                                href: false,
                                width: "7%",
                            },
                            {
                                columnName: "availableCapacity",
                                headerLabel: this.findLabel("resv.reserved.availabel.label") ,
                                href: false,
                                width: "7%",
                            },
                            {
                                columnName: "allowBlock",
                                headerLabel: this.findLabel("resv.allow.block.label") ,
                                href: false,
                                width: "7%",
                                columnKey: "id",
                                textAlign: "center",
                            },
                            {
                                columnName: "restrictCheckBox",
                                headerLabel: this.findLabel("resv.restrict.label") ,
                                href: false,
                                width: "5%",
                                className: "restricTd",
                                onClick: this.onAllowBlockCheckBoxAction,
                                columnKey: "id"
                            }
                        ]}
                        rowData={this.state.poolsRowData}
                        pagination={this.state.pagination}
                        rowSelection={0}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        rowKey="id"
                        childKey="childs"
                        expandAll={expandable}
                        addExtraRow={addExtraRow}
                        noDataFoundText={this.findLabel("no.pools.associated.to.feature")}
                    />
                </div>
                <div>
                    <AssoicatePools ref={this.associatePoolRef}
                        showModal = {this.state.showAssociatePool}
                        closeAction = {this.closeAssociatePoolWindow}
                        featureId= {this.state.featureId}
                        featureVersion= {this.state.featureVersion}
                        productName= {this.state.productName}
                        assoicatePoolAction = {this.assoicatePoolSubmit}
                        associatedAction = {this.state.associatedAction}
                        validationErrorMessage = {this.state.validationErrorMessage}
                    />
                </div>
                <div className="button_left_wrap">
                    {/*<Button id="feature_base_manage_backBtn" label={this.findLabel("label.button.back", intl)} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                    <span style={{padding: "5px"}}></span>*/}
                    <div className="button_wrap_secondary">
                        <Button id="feature_base_manage_backBtn" label={this.findLabel("label.button.refresh")} plain={false} secondary={true} onClick={this.refreshAction} />
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
// ManagePools.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ManagePools;