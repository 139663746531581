import ReduceStore from "./ReduceStore";

class TokenCalStore extends ReduceStore {
     constructor() {
        super();
        this.state = {
           
        };
    }
    reduce(action, state) {
        switch (action.type) {
             case "PROCESS_TOKEN_FEATURE_LIST": {
                return { ...state, data: action.payload.data, 
                    actionType: action.payload.actionType 
                };
            }
            case "PROCESS_TOKEN_FEATURE_PD_LIST": {
                return { ...state, pddata: action.payload.data, 
                    actionType: action.payload.actionType 
                };
            }
            default:
                return state;
        }
    }
}
export default new TokenCalStore();