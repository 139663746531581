
import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import DatabaseConfigurationStore from "../../../flux/DatabaseConfigurationStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import File from "../../generic/APFile";
import { CheckBox } from "grommet/components/CheckBox";
import {injectIntl} from 'react-intl';

// var _Intl = intl;
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

let initialState = null;
class DatabaseConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormDirty: false,
            databaseURLValue: "",
            databaseHostValue: "",
            databasePortValue: "",
            databaseNameValue: "",
            databaseUserValue: "",
            databaseSchemaValue: "",
            databasePasswordValue: "",
            databaseTypeValue: "HSQL",
            listDatabaseTypes: [],
            databaseTypeSelValue: "",
            disabledFields: false,
            enableTestButton: false,
            urlError: "",            
            dbhostError: "",
            dbportError: "",
            dbuserError: "",
            dbpassError: "",
            dbNameError: "",
            dbSchemaError:"",
            multiTenancyEnabled: 0,
            fileName:"",
            fileContent: undefined, 
            filearr:[],
            connectionType:"Non SSL Connection",
            dbSSLValue:false
        }
        this.uploadFileRef = React.createRef();
        this.findLabel = this.findLabel.bind(this);
        this.handleChangeDbURL = this.handleChangeDbURL.bind(this);
        this.changeDbURL = this.changeDbURL.bind(this);
        this.handleChangeDbHost = this.handleChangeDbHost.bind(this);
        this.changeDbHost = this.changeDbHost.bind(this);
        this.handleChangeDbPort = this.handleChangeDbPort.bind(this);
        this.changeDbPort = this.changeDbPort.bind(this);
        this.handleChangeDbName = this.handleChangeDbName.bind(this);
        this.changeDbName = this.changeDbName.bind(this);
        this.handleChangeDbUser = this.handleChangeDbUser.bind(this);
        this.changeDbUser = this.changeDbUser.bind(this);
        this.handleChangeDbPassword = this.handleChangeDbPassword.bind(this);
        this.changeDbPassword = this.changeDbPassword.bind(this);
        this.makeFormDirty = this.makeFormDirty.bind(this);
        this.submitTestDataBaseConnection = this.submitTestDataBaseConnection.bind(this);
        this.submitUsageDatabaseConfigSettings = this.submitUsageDatabaseConfigSettings.bind(this);
        this.cancelUsageDatabaseConfigSettings = this.cancelUsageDatabaseConfigSettings.bind(this);
        this.refreshFormData = this.refreshFormData.bind(this);
        this.handleChangeDbType = this.handleChangeDbType.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.handleChangeDbSchema = this.handleChangeDbSchema.bind(this);
        this.changeFileName=this.changeFileName.bind(this);
        this.changeDBConnectionType=this.changeDBConnectionType.bind(this);
        
        
      //  this.uploadFileRef = React.createRef();
    }

    changeFileName() {
    if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state) {
    let files = this.uploadFileRef.current.state.selectedFiles;

    var file = files[0];
    console.log("inside changeFileName ");

    var map=this.state.map; 
    // var item = map.get("saml2.idp.file.name");   
    // item.entryValue=file.name;
    // map.set("saml2.idp.file.name",item);


    // var item2 = map.get("saml2.idp.x509cert");   
    // item2.entryValue=this.state.fileContent;
    // map.set("saml2.idp.x509cert",item2);

    this.setState({fileName: file.name, map:map});
  }
}

    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    makeFormDirty() {
        this.setState({isFormDirty: true});
    }
    changeDBConnectionType(val) {
        this.setState({dbSSLValue:val});
        if(val){
            this.setState({connectionType:"SSL Connection"});
        }else{
            this.setState({connectionType:"Non SSL Connection"});
        }
      
    }
    handleChangeDbURL(event) {
        this.makeFormDirty();
        this.changeDbURL(event.target.value);
    }
    changeDbURL(val) {
        this.setState({databaseURLValue: val});
    }
    handleChangeDbHost(event) {
        this.makeFormDirty();
        this.changeDbHost(event.target.value);
    }
    changeDbHost(val) {
        this.setState({databaseHostValue: val});
    }
    handleChangeDbPort(event) {
        this.makeFormDirty();
        this.changeDbPort(event.target.value);
    }
    changeDbPort(val) {
        this.setState({databasePortValue: val});
    }
    handleChangeDbName(event) {
        this.makeFormDirty();
        this.changeDbName(event.target.value);
    }
    changeDbName(val) {
        this.setState({databaseNameValue: val});
    }
    handleChangeDbSchema(event) {
        this.makeFormDirty();
        this.changeDbSchema(event.target.value);
    }

    changeDbSchema(val) {
        this.setState({databaseSchemaValue: val});
    }
    handleChangeDbUser(event) {
        this.makeFormDirty();
        this.changeDbUser(event.target.value);
    }
    changeDbUser(val) {
        this.setState({databaseUserValue: val});
    }
    handleChangeDbPassword(event) {
        this.makeFormDirty();
        this.changeDbPassword(event.target.value);
    }
    changeDbPassword(val) {
        this.setState({databasePasswordValue: val});
    }
    handleChangeDbType(event) {
        this.makeFormDirty();
        this.clearErrors();
        this.changeDbType(event.currentTarget.value);
    }
    changeDbType(obj) {
        this.setState({databaseTypeSelValue: obj});
        this.setState({databaseTypeValue: obj});
        if(obj === "HSQL" && Number(this.state.multiTenancyEnabled) !== 1) {
            this.changeDbHost("");
            this.changeDbPort("");
            this.changeDbName("");
            this.changeDbURL("");
            this.changeDbUser("");
            this.changeDBConnectionType(false);
            this.setState({disabledFields: true});
            this.setState({enableTestButton: false});
        } else {
            this.setState({disabledFields: false});
            this.setState({enableTestButton: true});
        }
    }
    changeFileName(val){

         if(val !==undefined && val.length >0){
             let file=val[0];
            const reader = new FileReader();
                        reader.readAsText(file);
                        reader.onload = () => {
                        this.setState({
                            fileName: file.name,
                            fileContent: reader.result,
                        });
        }; reader.onerror = () => {
                        alert('error');
                        };
    } else{
            this.setState({fileName: "", filearr: [val]});
        }
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        DatabaseConfigurationStore.on("change", this.refreshFormData);
        FetchHelper.findDatabaseConfiguration(this.context.intl);
    }
    componentWillUnmount() {
        DatabaseConfigurationStore.removeListener("change", this.refreshFormData);
    }
    validateFormFields(data, intl) {
        let errorsMap = ConfigurationUtils.validateDatabaseConfig(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["url"] !== undefined && errorsMap["url"] !== "") {
            this.setState({urlError: errorsMap["url"]});
            flag = false;
        }
        if(errorsMap["dbhost"] !== undefined && errorsMap["dbhost"] !== "") {
            this.setState({dbhostError: errorsMap["dbhost"]});
            flag = false;
        }
        if(errorsMap["dbport"] !== undefined && errorsMap["dbport"] !== "") {
            this.setState({dbportError: errorsMap["dbport"]});
            flag = false;
        }        
        if(errorsMap["dbuser"] !== undefined && errorsMap["dbuser"] !== "") {
            this.setState({dbuserError: errorsMap["dbuser"]});
            flag = false;
        }
        if(errorsMap["dbpass"] !== undefined && errorsMap["dbpass"] !== "") {
            this.setState({dbpassError: errorsMap["dbpass"]});
            flag = false;
        }
        if(errorsMap["dbname"] !== undefined && errorsMap["dbname"] !== "") {
            this.setState({dbNameError: errorsMap["dbname"]});
            flag = false;
        }
        if(errorsMap["schemaname"] !== undefined && errorsMap["schemaname"] !== "") {
            this.setState({dbSchemaError: errorsMap["schemaname"]});
            flag = false;
        }
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        return flag;
    }
    clearErrors() {
        this.setState({urlError: ""});
        this.setState({dbhostError: "", dbportError: "", dbNameError: "", dbSchemaError: ""});
        this.setState({dbuserError: ""});
        this.setState({dbpassError: ""});
    }
    submitTestDataBaseConnection() {
        if(this.validateFormFields(this.state, this.context.intl)) {
            var flag=true;
            if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state) {
                    let files = this.uploadFileRef.current.state.selectedFiles;
                     var file = files[0];
                     var isValidFile=true;
                     let flag_Size = true;
                     
                    if(file!==undefined){
                        flag=false;
                        
                        let fileName = this.state.fileName;
                        let extIndex = fileName.lastIndexOf(".");
                        let fileExtn = fileName.substr(extIndex+1, fileName.length);
                        if(fileExtn !== "pem" && fileExtn !== "der" 
                            && fileExtn !== "crt" && fileExtn !== "cer" && fileExtn !== "cert" ) {
                            isValidFile = false;
                        }
                        if(file.size>1048576)
                        {
                            flag_Size=false;
                        }
                          if(isValidFile && flag_Size) {
                                FetchHelper.testDatabaseConnection(this.state, this.context.intl);
                                
                            } else {
                                ConfigurationUtils.showErrorMessage1("commuter.invalid.file", this.context.intl);
                            }
                        
                    }             
                  
                }
            
            if(flag){
                FetchHelper.testDatabaseConnection(this.state, this.context.intl);
            }
            
               }
    }
    submitUsageDatabaseConfigSettings() {
        if(this.state.isFormDirty && this.validateFormFields(this.state, this.context.intl)) {
            if (this.state.databaseURLValue === '' && this.state.databasePortValue === '' 
                && this.state.databaseTypeValue ==='Postgres') {
                this.setState({databasePortValue: "5432"});
            }
            if (this.state.databaseURLValue === '' && this.state.databasePortValue === '' 
                && this.state.databaseTypeValue ==='Oracle') {
                this.setState({databasePortValue: "1521"});
            }
            if (this.state.databaseURLValue === '' && this.state.databasePortValue === '' 
                && this.state.databaseTypeValue ==='MSSQLServer') {
                this.setState({databasePortValue: "1433"});
            }

                var flag=true;
              if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state) {
                    let files = this.uploadFileRef.current.state.selectedFiles;
                     var file = files[0];
                     var isValidFile=true;
                     let flag_Size = true;
                     
                    if(file!==undefined){
                        flag=false;
                        
                        let fileName = this.state.fileName;
                        let extIndex = fileName.lastIndexOf(".");
                        let fileExtn = fileName.substr(extIndex+1, fileName.length);
                        if(fileExtn !== "pem" && fileExtn !== "der" 
                            && fileExtn !== "crt" && fileExtn !== "cer"  && fileExtn !== "cert"  ) {
                            isValidFile = false;
                        }
                        if(file.size>1048576)
                        {
                            flag_Size=false;
                        }

                          if(isValidFile && flag_Size) {
                               FetchHelper.saveDatabasefiguration(this.state, this.context.intl);
                                this.changeDbUser("");
                                this.changeDbPassword("");
                            } else {
                                ConfigurationUtils.showErrorMessage1("commuter.invalid.file", this.context.intl);
                            }
                        
                        
                    }
                          
                }

              //  this.changeFileName();
            //this.setState(databaseURLValue: escape(this.state.databaseURLValue));
            if(flag){
                FetchHelper.saveDatabasefiguration(this.state, this.context.intl);
                 this.changeDbUser("");
                 this.changeDbPassword("");
            }
           
        }
    }

    onToggleChangeHandler(isChecked){
        this.makeFormDirty();
        if(isChecked){
             this.setState({
                            connectionType: 'SSL Connection',
                            dbSSLValue: true,
                        });
        }else{

              this.setState({
                            connectionType: 'Non SSL Connection',
                            dbSSLValue: false,
                        });
        }
    }

    cancelUsageDatabaseConfigSettings() {
        if(initialState) {
            this.setState(initialState);
            this.refreshFormData();
        }
    }
    refreshFormData() {
        this.clearErrors();
        let dbtype = DatabaseConfigurationStore.getState().dbtype;
        let dbhost = DatabaseConfigurationStore.getState().dbhost;
        let dbport = DatabaseConfigurationStore.getState().dbport;
        let dbname = DatabaseConfigurationStore.getState().dbname;
        let dburl = DatabaseConfigurationStore.getState().dburl;
        let dbuser = DatabaseConfigurationStore.getState().dbuser;
        let dbschema = DatabaseConfigurationStore.getState().dbschema;
        let dbTypeList = DatabaseConfigurationStore.getState().dbTypeList;
        let mtEnable = DatabaseConfigurationStore.getState().enableMultiTenancy;
        let fileName = DatabaseConfigurationStore.getState().fileName;
        let dbSSL = DatabaseConfigurationStore.getState().dbSSL;
        if(mtEnable !== undefined) {
            this.setState({multiTenancyEnabled: mtEnable});
            this.setState({enableTestButton: true});
            this.setState({disabledFields: false});
        }
        if(dbtype === undefined) {
            dbtype = "HSQL";
        }
        if(dbtype === "HSQL" && Number(mtEnable) === 1) {
            dbtype = "Postgres";
        }
        this.changeDbHost(dbhost);
        this.changeDbPort(dbport);
        this.changeDbName(dbname);
        this.changeDbURL(dburl);
        this.changeDbUser(dbuser);
        this.changeDbSchema(dbschema);
        this.changeDbPassword("");
        //this.changeFileName(fileName);
        this.changeDBConnectionType(dbSSL);
        if(dbTypeList) {
            let dbLists = [];
            dbTypeList.entry.map(element => {
                let obj = {label: element.value, value: element.key};
                if(dbtype === element.key) {
                    this.setState({databaseTypeSelValue: element.key});
                    this.setState({databaseTypeValue: element.key});
                }
                if(dbtype !== "HSQL" || Number(this.state.multiTenancyEnabled) === 1) {
                    this.setState({enableTestButton: true});
                    this.setState({disabledFields: false});
                } else {
                    this.setState({enableTestButton: false});
                }
                 switch (element.value.toLowerCase()) {
                    case "in-built database":
                        obj.label = LocaleUtility.getLocaleMessage("config.database.type.inbuiltdb");
                        break;
                    default:
                        obj.label=element.value;
                        break;
                }
                dbLists.push(obj);
                return "";
            });
            this.setState({listDatabaseTypes: dbLists});
        }
        this.setState({isFormDirty: false});
        ConfigurationUtils.hideLoadMask(this.context.intl);
        initialState = this.state;
    }
    render() {
        let intl = this.context.intl;
        let disClassName = "enabledTextField";
        if(this.state.databaseTypeValue === "HSQL" && Number(this.state.multiTenancyEnabled) !== 1) {
            disClassName = "disabledTextField";
        }

        let displayFile = (this.state.dbSSLValue? "flex" : "none" );

        return(
            <div className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("configpageDBtitle")}  helpTopic="R_DataBase_Configuration" className="border_white_backg_shadow"/>
                <section id="conf_db_formId_wrap" pad="none" wrap={false} responsive={false} justify="center" className="border_white_backg_shadow">
                    <Form compact={false} id="conf_db_formId" pad="small" plain={true}>
                        <div>
                            <h2 className="headerConfig">
                                {this.findLabel("configpageDBConnAttributes")}
                            </h2>
                            <div className="db_config_wrap">
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block" }} required={true} label= {this.findLabel("configpageDBType")} >
                                            {/*<Select id="conf_db_typeId" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                                                options={this.state.listDatabaseTypes}
                                                value={this.state.databaseTypeSelValue}
                                                onChange={this.handleChangeDbType} /> */}
                                            <select id="conf_db_typeId" placeholder={this.findLabel("configpageIpHostSelect")}
                                                value={this.state.databaseTypeSelValue}
                                                onChange={this.handleChangeDbType} className="customSelect">
                                            {this.state.listDatabaseTypes.map((item, key) => 
                                            <option key={key} value={item.value}>{item.label}</option>)}
                                        </select>
                                        </FormField>
                                    </div>
                                </div>
                                {(this.state.databaseTypeSelValue!=="HSQL")?
                                 <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block" }} error={this.state.urlError} label={this.findLabel("configpageDBConnectionType")} >
                                        <CheckBox 
                                            checked={this.state.connectionType=="SSL Connection" ? true : false}
                                            label={this.state.connectionType=="Non SSL Connection" ? this.findLabel("configpageNonSSLConnection") : this.findLabel("configpageSSLConnection")}
                                            value={this.state.dbSSLValue} onChange={(event) => this.onToggleChangeHandler(event.target.checked)} 
                                            toggle={true} /> </FormField>
                                    </div>
                                </div>:""
                                }
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                    <div className="db_url_tooltip">
                                        <div className="dbInfotooltip">
                                            <CircleInformationIcon  colorIndex="brand"/>
                                            <span className="dbInfotooltipText">
                                                <b>{this.findLabel("dbconfig.dburl.info")}</b><br/> <br/>
                                                {this.findLabel("dbconfig.dburl.info1")} <br/>&nbsp;&nbsp;
                                                {this.findLabel("dbconfig.dburl.info7")}<br/>&nbsp;&nbsp;
                                                {this.findLabel("dbconfig.dburl.info2")}<br/>&nbsp;&nbsp;
                                                {this.findLabel("dbconfig.dburl.info3")}<br/>
                                                {this.findLabel("dbconfig.dburl.info4")}<br/>
                                                {this.findLabel("dbconfig.dburl.info5")}<br />
                                                &nbsp;&nbsp; {this.findLabel("dbconfig.dburl.info8")}<br/> {this.findLabel("dbconfig.dburl.info6")}<br/>
                                                    {this.findLabel("dbconfig.dburl.info9")}<br />
                                                &nbsp;&nbsp; {this.findLabel("dbconfig.dburl.info10")}<br/> {this.findLabel("dbconfig.dburl.info11")}
                                            
                                            </span>
                                        </div>
                                    </div>
                                        <FormField style={{ width: '45em', display:"block" }} error={this.state.urlError} label= {this.findLabel("configpageDBURL")} >
                                            <TextInput id="conf_db_URLId" disabled={this.state.disabledFields} className={disClassName} value={this.state.databaseURLValue} onChange={event =>this.handleChangeDbURL(event)}/>
                                        </FormField>
                                    </div>
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                         <FormField style={{ width: '45em', display:"block" }} error={this.state.dbhostError} label= {this.findLabel("configpageDBHost")} >
                                            <TextInput id="conf_db_HostId" disabled={this.state.disabledFields} className={disClassName} value={this.state.databaseHostValue} onChange={event =>this.handleChangeDbHost(event)}/>
                                        </FormField>
                                    </div>
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block" }} error={this.state.dbportError} label= {this.findLabel("configpageDBPort")} >
                                            <TextInput id="conf_db_PortId" disabled={this.state.disabledFields} className={disClassName} value={this.state.databasePortValue} onChange={event =>this.handleChangeDbPort(event)}/>
                                        </FormField>
                                    </div>
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block"}} error={this.state.dbNameError} label= {this.findLabel("configpageDBName")} >
                                            <TextInput id="conf_db_NameId" disabled={this.state.disabledFields} className={disClassName} value={this.state.databaseNameValue} onChange={event =>this.handleChangeDbName(event)}/>
                                        </FormField>
                                    </div>
                                </div>
                                {(this.state.databaseTypeSelValue==="Postgres" || Number(this.state.multiTenancyEnabled) === 1)?
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block" }} error={this.state.dbSchemaError} label= {this.findLabel("configpageDBSchema")} >
                                            <TextInput id="conf_db_schema" disabled={this.state.disabledFields} className={disClassName} value={this.state.databaseSchemaValue} onChange={event =>this.handleChangeDbSchema(event)}/>
                                        </FormField>
                                    </div>
                                </div>:""
                                }
                                
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block"}} error={this.state.dbuserError} required={true} label= {this.findLabel("configpageDBUser")} >
                                            <TextInput id="conf_db_UserId" disabled={this.state.disabledFields} className={disClassName} value={this.state.databaseUserValue} onChange={event =>this.handleChangeDbUser(event)}/>
                                        </FormField>
                                    </div>
                                </div>
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display:"block"}} error={this.state.dbpassError} required={true} label= {this.findLabel("configpageDBPassword")} >
                                            <Password id="conf_db_PasswordId" autoComplete="new-password" value={this.state.databasePasswordValue} disabled={this.state.disabledFields} className={disClassName + " password_icon"} onChange={this.handleChangeDbPassword}/>
                                        </FormField>
                                    </div>
                                </div>
                                {(this.state.databaseTypeSelValue!=="HSQL" && this.state.dbSSLValue)?
                                <div className="db_config_wrap_item">
                                    <div className="horizantal_label_input">
                                        <FormField style={{ width: '45em', display: displayFile  }}
                                        label= {this.findLabel("configpageDBcert")} >
                                        <File  ref={this.uploadFileRef}
                                                    id = "certificate_browse"
                                                    name = "certificate_browse"
                                                    filesList={[]}
                                                    label = {this.findLabel("label.button.add.file")} panelWidth ="20em"
                                                    
                                                    onFileChange={this.changeFileName}
                                                />
                                        </FormField>
                                    </div> 
                                </div> : ""}
                                </div>
                        </div>
                      
                        <div style={{ width: '45em' }} className="button_right_wrap">
                            <div className="button_wrap_secondary">
                                {this.state.enableTestButton ? 
                                    <Button id="conf_db_TectConnBtnId" label={this.findLabel("configpageLDAPTestConnection")} plain={false} primary={true} onClick={this.submitTestDataBaseConnection} />
                                    :
                                    <Button id="conf_db_TectConnBtnId" label={this.findLabel("configpageLDAPTestConnection")} plain={false} primary={true} />
                                }
                            </div>
                        </div>
                        <div className="button_left_wrap">
                            <div className="button_wrap_primary">
                                <Button id="conf_db_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitUsageDatabaseConfigSettings} />
                            </div>
                            <div className="button_wrap_negative">
                                <Button id="conf_db_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelUsageDatabaseConfigSettings} />
                            </div>
                        </div>
                        <div style={{ paddingTop: "10px",marginTop:"10px", color: "rgba(0, 0, 0, 0.56)"}}>
                           <b>{this.findLabel("usage.advance.report.note")}</b> {this.findLabel("confgpageDBNote")}
                        </div>
                        <div>
                            <SpinningNotification centerPage={true} />
                        </div>
                    </Form>
                </section>
            </div>
        )
    }
}
// DatabaseConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default DatabaseConfiguration;