import * as LocaleUtility from "../utils/LocaleUtility";
import { getLead, getCurrentTenantName, setCurrentTenantName, getSession } from "./FetchHelper";

var crypto = require('crypto'),
    algorithm = 'aes-256-ctr',
    password = 'd6F3Efeq';
var START = [45, 45, 45, 45, 45, 66, 69, 71, 73, 78, 32, 80, 85, 66, 76, 73, 67, 32, 75, 69, 89, 45, 45, 45, 45, 45];
var END = [45, 45, 45, 45, 45, 69, 78, 68, 32, 80, 85, 66, 76, 73, 67, 32, 75, 69, 89, 45, 45, 45, 45, 45];
var CENTER = [77, 73, 71, 98, 77, 65, 48, 71, 67, 83, 113, 71, 83, 73, 98, 51, 68, 81, 69, 66, 65, 81, 85, 65,
65, 52, 71, 74, 65, 68, 67, 66, 104, 81, 74, 43, 65, 76, 67, 66, 111, 105, 56, 106, 112, 112, 49,
66, 100, 99, 51, 69, 78, 81, 68, 97, 100, 112, 122, 113, 76, 118, 122, 90, 57, 103, 73, 87, 101,
81, 107, 49, 73, 86, 50, 111, 70, 122, 99, 112, 74, 114, 78, 55, 85, 114, 50, 99, 49, 47, 74, 70,
55, 79, 106, 72, 106,53, 106, 108, 56, 77, 79, 110, 74, 57, 83, 72, 47, 89, 112, 76, 54, 65, 67,
89, 53, 49, 89, 113, 48, 121, 98, 107, 113, 88, 106, 102, 103, 84, 105, 54, 52, 56, 68, 102, 71,
85, 103, 117, 101, 65, 57, 57, 112, 75, 78, 113, 107, 52, 99, 76, 80, 67, 71, 113, 77, 80, 48, 69,
79, 80, 68, 121, 48, 68, 80, 57, 118, 53, 97, 113, 105, 120, 57, 84, 71, 89, 122, 71, 103, 78, 75,
90, 117, 97, 72, 103, 113, 47, 71, 74, 67, 103, 66, 104, 83, 122, 103, 88, 43, 99, 82, 122, 65,
103, 77, 66, 65, 65, 69, 61];
var CENTER_PVT = [77,73,73,67,90,103,73,66,65,68,65,78,66,103,107,113,104,107,105,71,57,119,48,66,65,
  81,69,70,65,65,83,67,65,108,65,119,103,103,74,77,65,103,69,65,65,110,52,65,104,76,72,99,90,66,74,48,
  71,103,113,50,51,65,122,102,120,105,74,71,70,52,100,51,88,82,75,89,80,104,88,47,71,53,51,119,122,115,
  51,119,112,65,43,50,66,71,118,54,111,112,86,85,49,66,112,108,108,120,70,75,65,99,72,101,56,85,106,78,
  121,75,87,67,56,90,76,72,77,87,82,84,104,106,105,97,97,105,43,54,53,100,85,83,90,74,52,69,50,49,111,
  49,116,82,74,81,67,110,109,103,50,87,66,98,115,49,106,82,112,50,108,122,47,67,114,87,84,53,49,117,47,
  97,51,120,108,74,67,82,43,82,54,98,122,118,83,52,82,68,119,50,54,80,108,51,53,73,103,112,67,66,98,102,
  67,108,112,76,65,120,99,67,65,119,69,65,65,81,74,57,85,65,84,49,56,55,56,82,70,112,99,53,101,71,43,110,
  68,108,79,105,48,120,79,86,68,113,74,107,47,113,80,68,75,73,78,82,77,86,85,78,100,100,73,110,111,72,107,
  66,78,115,81,52,50,73,78,109,109,102,103,75,71,103,56,119,103,50,54,48,56,102,101,108,104,69,112,81,74,
  116,47,113,89,110,108,51,56,106,117,52,49,71,104,79,109,85,81,111,85,98,74,100,113,55,103,73,115,87,50,
  119,51,112,97,86,98,119,78,105,69,109,119,100,54,90,102,113,71,79,122,77,47,108,69,52,82,77,82,90,87,85,
  99,85,73,109,49,48,49,52,85,103,116,110,81,120,47,106,103,117,117,111,90,119,114,85,103,105,122,119,69,
  67,80,119,119,49,87,79,54,114,87,104,75,100,106,100,79,90,71,43,65,99,54,102,47,88,107,74,56,117,68,56,
  102,68,121,77,76,81,115,50,90,103,108,102,111,65,53,47,53,69,72,90,73,51,52,98,117,67,86,113,70,84,66,
  49,49,82,66,114,111,113,112,105,66,78,81,73,86,85,104,117,100,109,52,117,76,57,108,119,73,47,67,116,54,
  65,98,112,77,116,83,48,56,79,68,114,105,110,122,109,84,100,68,52,108,113,90,111,101,85,52,74,70,114,80,
  116,71,47,103,56,82,87,53,53,89,65,90,73,56,112,69,72,57,114,68,74,115,101,71,70,88,47,82,117,98,119,122,
  68,71,77,108,78,57,101,71,80,100,56,76,86,79,82,71,57,97,66,65,106,56,76,71,103,103,66,73,88,107,76,76,
  74,76,111,78,72,99,81,50,98,112,65,109,80,111,43,104,108,104,55,82,67,75,119,90,98,66,43,68,74,55,86,100,
  84,87,67,70,116,98,57,80,67,118,100,111,54,121,55,106,120,70,72,88,111,78,76,106,55,84,53,56,115,89,86,101,
  77,81,107,99,88,56,112,65,52,107,67,80,119,107,70,82,115,80,43,103,43,51,110,105,51,119,117,82,112,54,108,
  50,85,51,71,116,122,97,109,117,97,108,122,74,118,87,52,56,71,65,48,101,65,101,49,49,49,101,100,57,79,47,76,
  81,66,88,122,79,120,80,112,99,51,98,116,118,100,86,75,72,105,109,69,66,68,49,109,85,72,110,120,122,66,71,49,
  103,81,73,47,65,81,101,114,109,118,105,118,106,89,108,90,119,65,122,109,110,49,89,49,109,119,66,65,113,116,
  99,74,104,103,110,82,86,86,102,49,76,105,75,75,56,115,55,114,53,84,104,113,102,76,66,119,120,76,117,116,43,
  120,43,66,77,76,111,50,88,67,78,50,74,54,98,89,120,98,87,116,73,79,68,116,98,66,47,121];
var START_PVT = [45, 45, 45, 45, 45, 66, 69, 71, 73, 78, 32, 80, 82, 73, 86, 65, 84, 69, 32, 75, 69, 89, 45, 45, 45, 45, 45];
var END_PVT = [45, 45, 45, 45, 45, 69, 78, 68, 32, 80, 82, 73, 86, 65, 84, 69, 32, 75, 69, 89, 45, 45, 45, 45, 45];

export function encrypt(text){
  var cipher = crypto.createCipher(algorithm,password)
  var crypted = cipher.update(text,'utf8','hex')
  crypted += cipher.final('hex');
  return crypted;
}
 
export function decrypt(text){
  var decipher = crypto.createDecipher(algorithm,password)
  var dec = decipher.update(text,'hex','utf8')
  dec += decipher.final('utf8');
  return dec;
}

export function encryptText(text, key){
  if(text !== undefined && text !== null && text !== ''){
    var hash = crypto.createHash("sha256");
    hash.update((key !== undefined ? key: decryptText(getLead())), "utf8");
    var sha256key = hash.digest();
    var keyBuffer = new Buffer(sha256key);

    var iv = crypto.randomBytes(16);
    var cipher = crypto.createCipheriv('aes-256-ctr', keyBuffer, iv);
    var enc = cipher.update(text, 'utf8', 'hex');
    enc = '.' + enc + cipher.final('hex');
    var encBy = Buffer.concat([new Buffer(iv.toString('hex')), new Buffer(enc)]);
    return encBy.toString('base64'); 
  }
  return text;
}

export function encryptLead(key){
  var publicKey = String.fromCharCode.apply(null, new Uint8Array(START))+'\n'
    +String.fromCharCode.apply(null, new Uint8Array(CENTER))+'\n'
    +String.fromCharCode.apply(null, new Uint8Array(END));

  var buffer = Buffer.from((key !== undefined ? key: decryptText(getLead())));
  var encrypted = crypto.publicEncrypt({key:publicKey, oaepHash:'sha1', padding: crypto.constants.RSA_PKCS1_OAEP_PADDING}, buffer);
  return encrypted.toString("base64");
}

export function decryptText(text){
  if(text !== undefined && text !== null){
    var privateKey = String.fromCharCode.apply(null, new Uint8Array(START_PVT))+'\n'
      +String.fromCharCode.apply(null, new Uint8Array(CENTER_PVT))+'\n'
      +String.fromCharCode.apply(null, new Uint8Array(END_PVT));
    var buffer = Buffer.from(text, 'base64');
    var encrypted = crypto.privateDecrypt({key:privateKey, oaepHash:'sha1', padding: crypto.constants.RSA_PKCS1_OAEP_PADDING}, buffer);
    return encrypted.toString("utf-8");
  }
  return text;
}
/* BEGIN : For Opening MediaWiki Help Pages */
/*!
  * Gethelp v1.0.0 (https://docs.microfocus.com/modules/gethelp.js)
  * Copyright 2018-2019 
  */
 var mf_help_online = true;
  // var mf_help_onlineBaseURL = 'https://docs.microfocus.com';
  var mf_help_offlineBaseURL = 'https://localhost:80';
  var mf_help_productID = 'AutoPass_License_Server';
  var serverUnavailabelErrors = [LocaleUtility.getDefualtString("Failed.fetch"), LocaleUtility.getDefualtString("NetworkError.fetch.resource"), LocaleUtility.getDefualtString("Network.request.failed"), LocaleUtility.getDefualtString("label.status.unexpected.response"), LocaleUtility.getDefualtString("label.status.unexpected.response2")];
var idmpoints = ['/license/borrow', '/remotecommuter/', 
                  '/config/ldap', '/config/saml', '/config/idm', '/config/dataBase', '/user/update', '/config/user','/config/ct'];
var nonadminpoints = ['/userManagement', '/configuration', '/config/'];
var viewonlypoints = ['/license/archived', '/license/borrow/form', '/license/cleanup', '/license/nameduser'];
var tenantpoints = ['/license/tenant', '/userManagement/tenants'];
  
  //Hard coded version for test

  //var mf_help_productVersionID = '';
  //Default to home page if topic was not given
  //var mf_help_screenID = 'home';
export function gethelp(mf_help_screenID, mf_help_productVersionID) {

  var mf_help_onlineBaseURL = getSession().documentationURL;
	
	var intHelpOnline = ( mf_help_online === true ) ? 1 : 0;
	var help_href = ( mf_help_online === true ) ? mf_help_onlineBaseURL : mf_help_offlineBaseURL;
	
	if ( navigator.onLine !== true){
		alert ("We are unable to connect to OpenText backend to open the Help requested. Please ensure that you're online or access https://docs.microfocus.com from a machine that has active internet connection.");
		return;
	}
	
	//alert(mf_help_screenID);
	//alert(mf_help_productVersionID);
	help_href += '/gethelparticle.html?productID=' + mf_help_productID + '&versionID=' + mf_help_productVersionID + '&screenID=' + mf_help_screenID + '&onlineHelp=' + intHelpOnline;
	window.open( help_href , '_blank' );
}
/* END : For Opening MediaWiki Help Pages */
export function roundOfValue(val){
 try {
      let trunkFlag = false;
      if(!isNaN(val)) {
        let currentValue = val;
        if(currentValue.toString().indexOf(".") !== -1) {
          let strList = currentValue.toString().split(".");
          if(strList.length > 0) {
            let precisionValue = strList[1];
            if(precisionValue.length > 12) {
              trunkFlag = true;
            }
          }
        }
      } else {
        val = 0;
      }
      if(trunkFlag) {
        val = Number(val).toFixed(12).replace(/\.?0+$/,"");
      } else {
        val = Number(val).toString();
      }
  } catch(error) {
      console.log("Units Conversion Error =>"+error);
  }
  return val;
}

export function getServerUnavailableErrors(){
  return serverUnavailabelErrors;
}
export function localNumberformat(val){
  try {
        //var num = new Number(val).toLocaleString(navigator.language);
        var num = new Number(val).toString();
    } catch(error) {
        console.log("Units Conversion Error =>"+error);
    }
    return num;
  }
export function convertArrayToString(list, delimeter, requiredSpace) {
  let str = "";
  if(list !== undefined && delimeter !== undefined) {
    let len = list.length;
    for(let i = 0;i < len;i++) {
      if(i > 0) {
        str = str + delimeter;
        if(requiredSpace === true) {
          str = str + " ";
        }
      }
      str = str + list[i];
    }
  }
  return str;
}

export function getIdmPoints(){
  return idmpoints;
}
export function getNonadminPoints(){
  return nonadminpoints;
}
export function getViewonlyPoints(){
  return viewonlypoints;
}
export function getTenantPoints(){
  return tenantpoints;
}
export function findTidFn(location) {
  location = window.location;
  let currentTenantName = getCurrentTenantName();
  if(location !== undefined && location.search !== undefined) {
    const query = new URLSearchParams(location.search);
    let tid = query.get('tenant');
    let currentSessionTenant = sessionStorage.getItem('currentSessionTenant');
    if(tid === undefined || tid === null || tid === 'null') {
      tid = currentSessionTenant;
      if(tid === undefined || tid === null || tid === 'null') {
        tid = "";
      }
    }
    if(currentTenantName !== undefined && currentTenantName !== tid) {
      sessionStorage.setItem('currentSessionTenant', tid);
    }
    if(location.search === "") {
      tid = "";
      setCurrentTenantName("0");
    }
    return tid;
  }
  return '';
}
export function buildURLQueryParam() {
  let currentTenantName = getCurrentTenantName();
  if(currentTenantName !== undefined && currentTenantName !== "" && Number(currentTenantName) !== 0) {
    return '?tenant='+currentTenantName;
  } else {
    const query = new URLSearchParams(window.location.search);
    let queryTenant = query.get('tenant');
    if(queryTenant !== undefined && queryTenant !== "" && Number(queryTenant) !== 0) {
      return '?tenant='+queryTenant;
    }
  }
  return "";
}
export function encryptTextForKey(text){
 return decryptText(text);
}

