import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
//import FormField from "grommet/components/FormField";
import Select from "../../commons/APLSSelect";
import { Anchor } from "grommet/components/Anchor";
import { Search as SearchIcon } from 'grommet-icons';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { Clock as ClockIcon } from 'grommet-icons';
import * as LocaleUtility from "../../../utils/LocaleUtility"
//import DocumentExcelIcon from 'grommet/components/icons/base/DocumentExcel';
import Button from "grommet/components/Button";
import { min } from "lodash";

// var _Intl = require('grommet/utils/Intl');
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageTenantWiseReportHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: null,
            productNameList: [],
            tenantNameList: [],
            productNameSelValue: "",
            isSearchEnable: false,
            isClockTampered: false
        }
        this.findLabel = this.findLabel.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    render() {
        //let intl = this.context.intl, 
        let rightSection;

        if(this.props.isClockTampered && this.props.rowData.length > 0) {
            rightSection = <div style={{marginRight:"4px", float: "right"}}>
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.props.exportExcel}><DocumentDownloadIcon id="usage-report-export-icon" colorIndex="brand"/></a>
                            <span style={{padding: "4px"}}></span>
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.clock.tampered")}><ClockIcon colorIndex="brand" /></a>
                        </div>
        } else if(this.props.rowData.length > 0) {
            rightSection = <div style={{marginRight:"4px", float: "right"}}>
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.props.exportExcel}><DocumentDownloadIcon id="usage-report-export-icon"  colorIndex="brand"/></a>
                        </div>
        }

        return(
            <div>
                <div style={{width: "100%", float: "left", paddingLeft: "2px"}}>
                    <div  style={{float:"right"}}>
                        <SectionWithTitle title={this.findLabel("usageReportListHeadr")} rightSection={rightSection}/>
                    </div>
                  
                    <div style={{minWidth: '10%', float:"left", paddingRight: "21px"}}>
                        <Select id="tenants-dropdown-id"
                            options={this.props.tenantNameList}
                            value={this.props.selTenant}
                            onChange={this.props.changeTenantName}
                            label={LocaleUtility.getLocaleMessage("label.select.tenant.product")}
                            width={24} 
                        />
                    </div>
                    <div style={{minWidth: '10%', float:"left"}}>
                        <Select id="usage_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect")}
                            options={this.props.productNameList}
                            value={this.props.selProduct}
                            onChange={this.props.selectProductName} 
                            label= {this.findLabel("usageReportSelProduct")+": "}
                            width={22}
                        />
                    </div>
                    
                </div>
            </div>
        )
    }
}
// UsageTenantWiseReportHeader.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageTenantWiseReportHeader;