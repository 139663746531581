import React, { Component } from "react";
import TableGrid from "../../../commons/table/APTable";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as FetchHelper from "../../../../utils/FetchHelper";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class NamedUserTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "",
                sortOrder: "DESC"
            },
            rows: [],
            selectedRowKeys: []
        }
        this.onRowClicked = this.onRowClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.clearSelections = this.clearSelections.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.refreshMembers = this.refreshMembers.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        let licDetails = this.props.licDetails;
        licDetails.pagination = paginationTemp;
        this.props.paginationCall(licDetails);
    }
    onRowClicked(keys, event) {
        this.props.selectedAction(keys);
    }
    clearSelections() {
        this.setState({selectedRowKeys: []});
    }
    refreshMembers(members, pagination) {
        this.setState({rows:members, pagination:pagination, selectedRowKeys: []});
    }
    render() {
        //let intl = this.context.intl;
        let paginationObj = this.state.pagination;
        // let pageNumber = 1;
        // let pageSize = 10;
        let totalCount = 0;
        if(paginationObj !== undefined) {
            paginationObj.required = true;
            // pageNumber = paginationObj.pageNumber;
            // pageSize = paginationObj.pageSize;
            totalCount = paginationObj.totalCount;
        }
        
        return(
            <div>
                <div style={{paddingLeft: "2px"}}>
                    <TableGrid id="nameduser_grid_id"
                        header={[
                            {
                                columnName: "columnComp",
                                headerLabel: this.findLabel("reportpage.users"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "createdBy",
                                headerLabel: this.findLabel("resv.grid.header.pool.by"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "createdDate",
                                headerLabel: this.findLabel("job.lebel.created.on"),
                                href: false,
                                width: "auto",
                            }
                        ]}
                        {...{keys:this.state.selectedRowKeys}}
                        rowData={this.state.rows}
                        pagination={paginationObj}
                        rowSelection={3}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="value"
                        onChangeCheckBoxCallback={this.onRowClicked}
                        selectAllRows={false}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: totalCount})}
                    />
                </div>
            </div>
        )
    }
}
// NamedUserTable.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default NamedUserTable;