import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { Search as SearchIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import UsageReportSearchForm from "./UsageReportSearchForm";
import UsageReportsSearchView from "./UsageReportsSearchView";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import {getLocaleMessage} from  "../../../utils/LocaleUtility";
import { Navigate } from 'react-router-dom';
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UserConfigurationStore";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as Utils from "../../../utils/Util";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
import { createSearchParams } from "react-router-dom";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportsSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            expDate: "",
            selProductValue: {label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""},
            isSearchEnable: true,
            backToPreviousPage: false,
            productId: "",
            formData: "",
            productNameList: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
        }
        this.searchSubmit = this.searchSubmit.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.clearSearchForm = this.clearSearchForm.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeExpDate = this.changeExpDate.bind(this);
        this.selectProductName = this.selectProductName.bind(this);
        this.showSearhPanel = this.showSearhPanel.bind(this);
        this.backBtnAction = this.backBtnAction.bind(this);
        this.getPagination = this.getPagination.bind(this);
        this.exportData = this.exportData.bind(this);
        this.loadProductNamesDropDown = this.loadProductNamesDropDown.bind(this);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadProductNamesDropDown);
        FetchHelper.getAllProductNames();
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadProductNamesDropDown);
    }
    loadProductNamesDropDown() {
        if("searchform" === APTableStore.getState().actionType) {
            let data = APTableStore.getState().data;
            if(data !== undefined) {
                if(data.productNameList !== undefined) {
                    let prdNameList = [];
                    prdNameList.push({label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""});
                    prdNameList.push({label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"});
                    if(data.productNameList.value !== undefined) {
                        data.productNameList.value.map(item => {
                            var prods = item.split(":");
                            if (prods.length === 2) {
                                prdNameList.push({label: prods[1], value: prods[0]});
                            }
                            return "";
                        });
                    }
                    this.setState({productNameList: prdNameList});
                }
            }
        }
    }
    searchSubmit(event) {
        event.preventDefault();
        let dataSet = event.target;
        let dataMap = {};
        for(let i=0; i<dataSet.length;i++) {
            let obj = dataSet[i];
            if(obj.id === "usage_search_seletProdId") {
                dataMap["productId"] = this.state.productId;
            } else if(obj.id === "usage_search_FeatId") {
                dataMap["featureId"] = obj.value;
            } else if(obj.id === "usage_search_FeatVerId") {
                dataMap["featureIdVersion"] = obj.value;
            } else if(obj.id === "usage_search_FeatDescId") {
                dataMap["featureDescription"] = obj.value;
            } else if(obj.id === "usage_search_IpAddrId") {
                dataMap["ipAddress"] = obj.value;
            } else if(obj.id === "usage_search_HostNameId") {
                dataMap["hostName"] = obj.value;
            } else if(obj.id === "usage_search_UserNameId") {
                dataMap["userName"] = obj.value;
            } else if(obj.id === "usage_search_ClientId") {
                dataMap["clientId"] = obj.value;
            } else if(obj.id === "usage_search_StartDateId") {
                dataMap["startDateValue"] = obj.value;
            } else if(obj.id === "usage_search_ExpdateId") {
                dataMap["expiryDateValue"] = obj.value;
            } else if(obj.id === "usage_search_AppNameId") {
                dataMap["applicationName"] = obj.value;
            } else if(obj.id === "usage_search_inuse" && obj.checked === true) {
                dataMap["searchReportType"] = "I";
            } else if(obj.id === "usage_search_history" && obj.checked === true) {
                dataMap["searchReportType"] = "H";
            }
        }
        this.setState({formData: dataMap});
        this.setState({isSearchEnable: false});
    }
    selectProductName(event) {
        this.setState({productId: event.option.value});
        this.setState({selProductValue: event.option});
    }
    showSearhPanel() {
        if(this.state.isSearchEnable) {
            this.setState({isSearchEnable: false});
        } else {
            this.setState({isSearchEnable: true});
        }
    }
    backBtnAction() {
        this.setState({backToPreviousPage: true});
    }
    clearSearchForm() {
        document.getElementById("usage_search_formId").reset();
        this.setState({startDate: ""});
        this.setState({expDate: ""});
    }
    changeStartDate(e) {
        this.setState({startDate: e});
    }
    changeExpDate(e) {
        this.setState({expDate: e});
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    getPagination() {
        let pagination = APTableStore.getState().pagination;
        pagination.required = true;
        pagination.style = "default";
        this.setState({pagination: pagination});
    }
    exportData(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let params = this.state.formData;
        params["pagination"] = this.state.pagination;
        params["sortIndex"] = 0;
        FetchHelper.listUsageReportSearchResults(this.context.intl, params, "export", mimetype);
    }
    render() {
        //let intl = this.context.intl;
        if(this.state.backToPreviousPage) {
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_LIST_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}}}/>
        } else if(!this.state.isSearchEnable) {
            let rightSection = <div style={{float: "right"}}>
                            {/* <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportData}><DocumentExcelIcon/></a>
                            <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportData}><DocumentDownloadIcon id="usage-search-export-icon"  colorIndex="brand"/></a>
                            <span style={{padding: "4px"}}></span>
                            <Anchor title={getLocaleMessage("label.tooltip.search")}><SearchIcon colorIndex="brand" onClick={this.showSearhPanel}/></Anchor>
                        </div>
            return (
                <div style={{paddingRight: "0px"}} className="config-cont-backgroud">
                    <div className="border_white_backg_shadow">
                        <div style={{width: "100%", float: "left"}}>
                            <SectionWithTitle title={this.findLabel("usageSearchResultsTitle")} rightSection={rightSection}/>
                        </div>
                        <div style={{width: "100%", float: "left", paddingLeft: "8px", paddingRight: "8px"}}>
                        <UsageReportsSearchView 
                                backButtonAction={this.showSearhPanel}
                                searchParams = {this.state.formData}
                                getPagination = {this.getPagination}
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div className="config-cont-backgroud">
                    <div style={{width: "100%", float: "left"}}>
                        <div style={{width: "100%", float: "left"}}>
                            <SectionWithTitle title={this.findLabel("usageSearchTitle")} />
                        </div>
                    </div>
                    <div>
                        <UsageReportSearchForm 
                            clickSearchAction={this.showSearhPanel} 
                            productNameList={this.state.productNameList} 
                            backButtonAction={this.backBtnAction} 
                            searchSubmitAction={this.searchSubmit} 
                            selectProductName={this.selectProductName} 
                            selProductValue={this.state.selProductValue}
                        />
                    </div>
                </div>
            )
        }
        
    }
}
// UsageReportsSearch.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportsSearch;