import React, { Component } from "react";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { Trash as TrashIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';

class APFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileNames: "",
            selectedFiles: [],
            flag:true,
            filesList: (this.props.multiple !== undefined && this.props.multiple ) ? [LocaleUtility.getLocaleMessage("label.select.files.click.add.files")] : [LocaleUtility.getLocaleMessage("label.select.file.click.add.file")],
            currentEvent: ""
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.onDeleteFileAction = this.onDeleteFileAction.bind(this);
        this.onFileUpdate = this.onFileUpdate.bind(this);
    }

    componentDidMount()
    {
        if(this.props.filesList!==undefined && this.props.filesList.length>0 && this.state.flag){
            let fileNames = "";
            let fList = [];
            let files = this.props.filesList;
            for(let i=0;i<files.length;i++) {
                if(i>0) {
                    fileNames = fileNames + ", ";
                }
                fileNames = fileNames + files[i];
                fList.push(<div style={{display:"flex"}}><a style={{cursor:"default", color:"rgba(0, 0, 0, 0.56)"}} title={files[i]}><span className="apfiletext">{files[i]}</span></a><Anchor id="delete-file-id" onClick={this.onDeleteFileAction}>
                    <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
                </Anchor></div>)
            }
            this.setState({
                filesList : fList,
                fileNames: fileNames,
                flag : false
            })
        }
    }
    onFileUpdate(filesList)
    {
        if(filesList!==undefined && filesList.length>0){
            let fileNames = "";
            let fList = [];
            let files = filesList;
            for(let i=0;i<files.length;i++) {
                if(i>0) {
                    fileNames = fileNames + ", ";
                }
                fileNames = fileNames + files[i];
                fList.push(<div style={{display:"flex"}}><a style={{cursor:"default", color:"rgba(0, 0, 0, 0.56)"}} title={files[i]}><span className="apfiletext">{files[i]}</span></a><Anchor id="delete-file-id" onClick={this.onDeleteFileAction}>
                    <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
                </Anchor></div>)
            }
            this.setState({
                filesList : fList,
                fileNames: fileNames,
            })
        }
    }
    onFileChange(event) {
        let files = Array.from(event.target.files);
        this.setState({currentEvent: event});
        if(files !== undefined && files.length > 0) {
            let fileNames = "";
            let fList = [];
            for(let i=0;i<files.length;i++) {
                if(i>0) {
                    fileNames = fileNames + ", ";
                }
                fileNames = fileNames + files[i].name;
                fList.push(<div style={{display:"flex"}}><a style={{cursor:"default", color:"rgba(0, 0, 0, 0.56)"}} title={files[i].name}><span className="apfiletext">{files[i].name}</span></a><Anchor id="delete-file-id" title={files[i].name} onClick={this.onDeleteFileAction}>
                    <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
                </Anchor></div>)
            }
            this.setState({filesList: fList});
            this.setState({fileNames: fileNames});
            this.setState({selectedFiles: files});
        }
        if(this.props.onFileChange !== undefined)
            this.props.onFileChange(files);
    }
    onDeleteFileAction() {
        const fList = Object.assign([], this.state.filesList);
        fList.splice(0, 1);
       fList.push((this.props.multiple !== undefined && this.props.multiple ) ? LocaleUtility.getLocaleMessage("label.select.files.click.add.files") : LocaleUtility.getLocaleMessage("label.select.file.click.add.file"));
        this.setState({filesList: fList});
        this.setState({fileNames: ""});
        this.setState({selectedFiles: []});
        let id = "fileId";
        if(this.props.id !== "" && this.props.id !== undefined) {
            id = this.props.id;
        }
        let fEl = document.getElementById(id);
        if(fEl !== undefined) {
            fEl.value = null;
        }
        if(this.props.onFileChange !== undefined)
            this.props.onFileChange([]);
    }
    render() {
        let name = "fileName";
        let id = "fileId";
        let multiple = false;
        let fileExtension = ".txt, .data";
        let filesList;
        let label = LocaleUtility.getDefualtString("Choose.File");
        let buttonWidth;
        let panelWidth;
        if(this.props.name !== "" && this.props.name !== undefined) {
            name = this.props.name;
        }
        if(this.props.id !== "" && this.props.id !== undefined) {
            id = this.props.id;
        }
        if(this.props.multiple !== undefined && this.props.multiple) {
            multiple = true;
        }
        if(this.props.fileExtension !== undefined) {
            fileExtension = this.props.fileExtension;
        }
        if(this.props.label !== undefined) {
            label = this.props.label;
        }
        if(this.props.panelWidth !== undefined) {
            panelWidth = this.props.panelWidth;
        }
        return(
            // <table width="100%">
            //     <tbody>
            //         <tr>
            <div width="100%" style={{display: "block"}}>
                    <div style={{float:"left", width:"77%"}} className="panel panel-default">
                        <div style={{color: "rgba(0, 0, 0, 0.56)", paddingLeft:"10px"}} className="panel-body">
                            {this.state.filesList}
                        </div>
                    </div>
                    {multiple ? 
                        <div style={{float:"right", width:"20%"}}><span id="file-label-span-id" className="fileFieldLabel">
                                <label htmlFor={name}>{label}</label>
                            </span>
                            <input hidden={true} type="file" id={id} name={name} file_extension={fileExtension} multiple onChange={this.onFileChange}></input>
                        </div>
                    :
                        <div style={{float:"right", width:"20%"}}><span id="file-label-span-id" className="fileFieldLabel">
                                <label htmlFor={name}>{label}</label>
                            </span>
                            <input hidden={true} type="file" id={id} name={name} file_extension={fileExtension} onChange={this.onFileChange}></input>
                        </div>
                    }
                {/* </tr>
            </tbody>
        </table> */}
        </div>
        )
    }

}
export default APFile;