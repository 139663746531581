import ReduceStore from "./ReduceStore";

class UserConfigurationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            userPasswordExpire: "",
            resetPassword: "",
            changePasswordDays: "",
            enableEmailNotificaion: "",
            groupsSource: "",
            usersSource: "",
            implemention: "",
            configGroupSetting:"",
            configUserSetting:""
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_USER_CONFIG_SETTING_DETAILS": {
                return {
                    userPasswordExpire: action.payload.userPasswordExpire,
                    resetPassword: action.payload.resetPassword,
                    changePasswordDays: action.payload.changePasswordDays,
                    enableEmailNotificaion: action.payload.enableEmailNotificaion,
                    groupsSource: action.payload.groupsSource,
                    usersSource: action.payload.usersSource,
                    implemention: action.payload.implemention,
                    configGroupSetting: action.payload.configGroupSetting,
                    configUserSetting: action.payload.configUserSetting
                };
            }
            case "POPULATE_USAGE_REPORT_SEARCH_RESULTS": {
                return {
                    ...state,
                    pagination: action.payload.pagination,
                    reportSearchList: action.payload.reportSearchList,
                    clientInfoMap: action.payload.clientInfoMap,
                    executedByMap: action.payload.executedByMap,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    defaultIds: action.payload.defaultIds,
                    defDisabledIds: action.payload.defDisabledIds,
                    enablePurgeBtn: action.payload.enablePurgeBtn,
                    enableRevokeBtn: action.payload.enableRevokeBtn
                };
            }
            case "POPULATE_TENANT_USAGE_REPORT_SEARCH_RESULTS": {
                return {
                    ...state,
                    pagination: action.payload.pagination,
                    reportSearchList: action.payload.reportSearchList,
                    clientInfoMap: action.payload.clientInfoMap,
                    executedByMap: action.payload.executedByMap,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    defaultIds: action.payload.defaultIds,
                    defDisabledIds: action.payload.defDisabledIds,
                    enablePurgeBtn: action.payload.enablePurgeBtn,
                    enableRevokeBtn: action.payload.enableRevokeBtn
                };
            }
            case "PROCESS_PRODUCT_NAME_LIST_FOR_SEARCH": {
                return {
                    actionType: "searchform",
                    data: action.payload.data
                };
            }
            default:
                return state;
        }
    }
}
export default new UserConfigurationStore();