import React, { PureComponent } from "react";
import { Box } from "grommet/components/Box";
import { Header } from 'grommet/components/Header';
import { Tabs } from "grommet/components/Tabs";
import { Tab } from "grommet/components/Tab";
import Avatar from "../generic/Avatar";
import About from "../main/about/About";
import Dispatcher from "../../flux/Dispatcher";
import * as FrontendPagePath from "../../utils/FrontendPagePath";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { requestLogout } from "../../utils/FetchHelper";
import * as FetchHelper from "../../utils/FetchHelper";
import AppConfigSettingStore from "../../flux/AppConfigSettingStore";
import APMenu from "./APMenu.js";
import { SettingsOption as ConfigIcon } from 'grommet-icons';
import { UserSettings as UsersIcon } from 'grommet-icons';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { Menu as MenuIcon } from 'grommet-icons';
import { Cart as CartIcon } from 'grommet-icons';
import HelpLink from "../generic/HelpLink.js";
import * as ConfigurationUtils from "../../utils/ConfigurationUtils";
import { useIntl, injectIntl } from 'react-intl';
import withRouter from "./withRouter";
// import {ReactComponent as OT_logo} from "../../img/OT_White.svg";
import OT_logo from "../../img/OT_logo_white.png";
var intl;
// var _Intl2 = _interopRequireDefault(_Intl);
// var _propTypes = require("prop-types");
// var _propTypes2 = _interopRequireDefault(_propTypes);
// function _interopRequireDefault(obj) {
//   return obj && obj.__esModule ? obj : { default: obj };
// }

class Menu extends PureComponent {
  constructor(props) {
    super(props);
    var activeIndex = 0;
    var subIndex = 0;
    this.state = {
      activeIndex: activeIndex,
      subIndex: subIndex,
      licenseIndex: 0,
      reportsIndex: 0,
      userIndex: 0,
      reserveIndex: 0,
      configIndex: 0,
      usageIndex: 0,
      remotecommuterIndex: 0,
      buymoreIndex: 0,
      productFeatureCount : false,
      isNamedFeature: false,
      isCustomReport: false,
      isProductReport: false,
      instantOnTab: FetchHelper.getSession().instantOnTab,
      oldView: false,
      isCTenabled:false
    };
    this.tabClicked = this.tabClicked.bind(this);
    this.subTabClicked = this.subTabClicked.bind(this);
    this.getSubMenuForLicense = this.getSubMenuForLicense.bind(this);
    this.getSubMenuForReports = this.getSubMenuForReports.bind(this);
    this.getSubMenuForUsers = this.getSubMenuForUsers.bind(this);
    this.getSubMenuForUsage = this.getSubMenuForUsage.bind(this);
    this.getSubMenuForReservation = this.getSubMenuForReservation.bind(this);
    this.getSubMenuForBuyMore = this.getSubMenuForBuyMore.bind(this);
    this.getSubMenuForConfig = this.getSubMenuForConfig.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.menuChangeAction = this.menuChangeAction.bind(this);
    this.getSubMenuForUsageTab = this.getSubMenuForUsageTab.bind(this);
    this.getSubMenuForLicenseTab = this.getSubMenuForLicenseTab.bind(this);
    this.getSubMenuForReservationTab = this.getSubMenuForReservationTab.bind(this);
    this.getSubMenuForRemoteCommuterTab = this.getSubMenuForRemoteCommuterTab.bind(this);
    this.getSubMenuForUsersTab = this.getSubMenuForUsersTab.bind(this);
    this.getSubMenuForConfigTab = this.getSubMenuForConfigTab.bind(this);
    this.enableMenuFn = this.enableMenuFn.bind(this);
    this.onMouseLeaveSlide = this.onMouseLeaveSlide.bind(this);
    this.onMouseOverIcon = this.onMouseOverIcon.bind(this);
  }
  menuChangeAction(index, activeIndex, id) {
    this.subTabClicked(index, activeIndex);
    let el = document.getElementById("slide-menu-id");
    if(el !== null && el !== undefined && el.style !== null && el.style !== undefined) {
      el.style.display = "none";
    }
  }
  onMouseOverIcon() {
    let el = document.getElementById("slide-menu-id");
    if(el !== null && el !== undefined && el.style !== null && el.style !== undefined) {
      el.style.display = "none";
    }
  }
  enableMenuFn() {
    if(!this.props.setSaml){
      window.scrollTo(0, 0);
      let el = document.getElementById("slide-menu-id");
      if(el !== null && el !== undefined && el.style !== null && el.style !== undefined) {
        let display = el.style.display;
        if(display === "none") {
          el.style.display = "block";
        } else {
          el.style.display = "none";
        }
      }
    }
  }
  onMouseLeaveSlide() {
    let el = document.getElementById("slide-menu-id");
    if(el !== null && el !== undefined && el.style !== null && el.style !== undefined) {
      el.style.display = "none";
    }
  }
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.showError
      );
      navigator.geolocation.watchPosition(this.showPosition, this.showError);
      console.log("called correctly");
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  showError = errorCode => {
    console.log("ERROR: " + errorCode);
  };

  showPosition = position => {
    console.log("entered showPosition ");
    console.log(
      "Latitude: " +
        position.coords.latitude +
        "<br>Longitude: " +
        position.coords.longitude
    );
    console.log("Accuracy:" + position.coords.accuracy);
    console.log("altitude:" + position.coords.altitude);
    console.log("speed: " + position.coords.speed);
  };

  tabClicked(index, event = null) {
    var page = "", subIndex = 0;
    this.setState({ activeIndex: index, subIndex: subIndex });

    if (index === 0) {
      var data = FetchHelper.getSession(true);
      data.then(session => {
          this.setState({instantOnTab: session.instantOnTab});
      })
      .catch(error => {
          // handle error
      });
      page = FrontendPagePath.USAGE_REPORT_LIST_PATH;
    } else if (index === 1) {
      subIndex = 1;
      page = FrontendPagePath.LICENSE_VIEW_PATH;
      this.setState({ subIndex: subIndex });
    } else if (index === 2) {
      page = FrontendPagePath.REPORTS_PATH;
    } else if (index === 3) {
      page = FrontendPagePath.RESERVATION_POOL_MANAGE_PATH;
    } else if (index === 4) {
      page = FrontendPagePath.COMMUTER_CHECKOUT_PATH;
    } else if (index === 5) {
      page = FrontendPagePath.USER_MGMT_PATH;
    } else if (index === 6) {
      page = FrontendPagePath.CONFIG_PATH;
    } else if (index === 7) {
      page = FrontendPagePath.BUYMORE_PATH;
    } else if (index === 8) {
      /* Fix to remain in same page when opening the About page.*/
      this.setState({ aboutClicked: true });
      page = this.props.location.pathname;
    }

    /* Close the active AppNotification component.*/
    Dispatcher.dispatch({
      type: "NOTIFICATION_CLOSE"
    });
    Dispatcher.dispatch({
      type: "COMP_CLOSE"
    });

    this.props.callbackMethod(index, subIndex, page, this.props.user);
  }

  subTabClicked(index, activeIndex, event = null) {
    var page = "";
    let actIndex = this.state.activeIndex;
    let isIDM = this.props.session !== undefined && this.props.session.isidm.value !== undefined ? this.props.session.isidm.value : false;
    if(activeIndex !== undefined && Number(activeIndex) >= 0) {
      actIndex = activeIndex;
    }
    if (actIndex === 0) {
      var data = FetchHelper.getSession(true);
      data.then(session => {
          this.setState({instantOnTab: session.instantOnTab});
      })
      .catch(error => {
          // handle error
      });
      if (index === 0) {
        page = FrontendPagePath.USAGE_REPORT_LIST_PATH;
      } else if (index === 1) {
        page = FrontendPagePath.USAGE_REPORT_ADVANCED_PATH;
      } else if (index === 2){
        page = FrontendPagePath.REPORTS_PATH;
      } else if (index === 3){
        page = FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH;
      }else if (index === 4){
        page = FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH;
      }else if (index === 5){
        page = FrontendPagePath.USAGE_REPORT_GENERIC_PATH;
      }else if (index === 6){
        page = FrontendPagePath.USAGE_PRODUCT_REPORT_PATH;
      }else if (index === 7){
        page = FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH;
      }
      this.setState({ subIndex: index });
    } else if (actIndex === 1) {
      if (index === 0) {
        page = FrontendPagePath.LICENSE_PATH;
      } else if (index === 1) {
        page = FrontendPagePath.LICENSE_VIEW_PATH;
      } else if (index === 2) {
        page = FrontendPagePath.LICENSE_ARCHIVE_PATH;
      } else if (index === 3) {
        page = FrontendPagePath.LICENSE_BORROW_PATH;
      } else if (index === 4) {
        page = FrontendPagePath.LICENSE_CLEANUP_PATH;
      } else if (index === 5) {
        page = FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH;
      } else if (index === 6) {
        page = FrontendPagePath.LICENSE_NAMEDUSER_PATH;
      } else if (index === 7) {
        page = FrontendPagePath.USER_TENANT_LICENSE_PATH;
      }
      this.setState({ subIndex: index });
    } else if (actIndex === 2) {
      page = FrontendPagePath.REPORTS_PATH;
      this.setState({ subIndex: index });
    } else if (actIndex === 3) {
      if (index === 0) {
        page = FrontendPagePath.RESERVATION_POOL_MANAGE_PATH;
      } else if (index === 1) {
        page = FrontendPagePath.RESERVATION_FEATURE_BASE_PATH;
      } else if (index === 2) {
        page = FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH;
      }
      this.setState({ subIndex: index });
    } else if (actIndex === 4) {
      if (index === 0) {
        page = FrontendPagePath.COMMUTER_CHECKOUT_PATH;
      } else if (index === 1) {
        page = FrontendPagePath.COMMUTER_CHECKIN_PATH;
      }
    } else if (actIndex === 5) {
      if (index === 0) {
        if(isIDM === true) {
          page = FrontendPagePath.USER_TENANTS_PATH;
        } else {
          page = FrontendPagePath.USER_MGMT_PATH;
        }
      } else if (index === 1) {
        page = FrontendPagePath.USER_GROUPS_PATH;
      } else if (index === 2) {
        page = FrontendPagePath.USER_TENANTS_PATH;
      }
      this.setState({ subIndex: index });
    } else if (actIndex === 6) {
      if (index === 4 && localStorage.getItem("enableUsageHub") === "false") {
        index = 4;
      }
      if (index === 0) {
        page = FrontendPagePath.MAIN_CONFIG_PATH;
      } else if (index === 1) {
        page = FrontendPagePath.USER_CONFIG_PATH;
      } else if (index === 2) {
        if(isIDM === true) {
          page = FrontendPagePath.APP_CONFIG_SUBNOTIFICATION;
        } else {
          page = FrontendPagePath.LDAP_CONFIG_PATH;
        }
      } 
      // else if (index === 3) {
      //   page = FrontendPagePath.IDM_CONFIG_PATH;
      // } 
      else if (index === 3) {
        page = FrontendPagePath.USAGE_CONFIG_PATH;
      } else if (index === 4) {
        page = FrontendPagePath.DB_CONFIG_PATH;
      }else if (index === 5) {
        page = FrontendPagePath.CONTROL_TOWER_PATH;
      } else if (index === 6) {
        page = FrontendPagePath.APP_CONFIG_ENTRIES;
      } else if (index === 7) {
        page = FrontendPagePath.APP_CONFIG_SUBNOTIFICATION;
      } else if (index === 8) {
        page = FrontendPagePath.APP_CONFIG_AUTH_TOKEN;
      } else if (index === 9) {
        page = FrontendPagePath.APP_CONFIG_SAML_ENTRIES;
      } else if (index === 10) {
        page = FrontendPagePath.APP_CONFIG_AUDITS;
      }
      this.setState({ subIndex: index });
    } else if (actIndex === 7) {
      page = FrontendPagePath.BUYMORE_PATH;
      this.setState({ subIndex: index });
    } else if (actIndex === 8) {
      this.setState({ aboutClicked: true });
      page = this.props.location.pathname;
      this.setState({ subIndex: index });
    }

    /* Close the active AppNotification component.*/
    Dispatcher.dispatch({
      type: "NOTIFICATION_CLOSE"
    });
    Dispatcher.dispatch({
      type: "COMP_CLOSE"
    });
    this.props.callbackMethod(
      actIndex,
      index,
      page,
      this.props.user
    );
  }

  onLogout(event) {
    /* Close the active AppNotification component.*/
    Dispatcher.dispatch({
      type: "NOTIFICATION_CLOSE"
    });
    Dispatcher.dispatch({
      type: "COMP_CLOSE"
    });
    requestLogout();
  }

  onModalCloseCallback() {
    this.setState({ aboutClicked: false });
  }

  componentDidMount() {
    const { intl } = this.props;
    //intl = intl;
    var location = this.props.location;
    let isIDM = this.props.session !== undefined && this.props.session.isidm.value !== undefined ? this.props.session.isidm.value : false;
    var index = 0,
      subIndex = 0;
    if (location.pathname === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
      index = 0;
    } else if (location.pathname === FrontendPagePath.LICENSE_PATH) {
        index = 1;
    } else if (
      location.pathname === FrontendPagePath.LICENSE_VIEW_PATH ||
      location.pathname === FrontendPagePath.CONTEXT_PATH + "/" ||
      location.pathname === FrontendPagePath.CONTEXT_PATH ||
      location.pathname === FrontendPagePath.HOME_PATH 
    ) {
      index = 1;
      subIndex = 1;
    } else if (location.pathname === FrontendPagePath.LICENSE_ARCHIVE_PATH) {
      index = 1;
      subIndex = 2;
    } else if (
      location.pathname === FrontendPagePath.LICENSE_BORROW_PATH ||
      location.pathname === FrontendPagePath.LICENSE_BORROW_FORM_PATH
    ) {
      index = 1;
      subIndex = 3;
    } else if (location.pathname === FrontendPagePath.LICENSE_CLEANUP_PATH) {
      index = 1;
      subIndex = 4;
    } else if (
      location.pathname === FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH
    ) {
      index = 1;
      subIndex = 5;
    } else if (
      location.pathname === FrontendPagePath.LICENSE_NAMEDUSER_PATH
    ) {
      index = 1;
      subIndex = 6;
    }else if (location.pathname === FrontendPagePath.REPORTS_PATH) {
      index = 0;
      subIndex = 2;
    } else if (
      location.pathname === FrontendPagePath.RESERVATION_POOL_MANAGE_PATH
    ) {
      index = 3;
      subIndex = 0;
    } else if (location.pathname === FrontendPagePath.COMMUTER_CHECKOUT_PATH) {
      index = 4;
      subIndex = 0;
    } else if (location.pathname === FrontendPagePath.USER_MGMT_PATH) {
      index = 5;
      subIndex = 0;
    } else if (location.pathname === FrontendPagePath.USER_GROUPS_PATH) {
      index = 5;
      subIndex = 1;
    } else if (location.pathname === FrontendPagePath.USER_TENANTS_PATH) {
      index = 5;
      if(isIDM === true) {
        subIndex = 0;
      } else {
        subIndex = 2;
      }
    } else if (location.pathname === FrontendPagePath.USER_TENANT_LICENSE_PATH ||
      location.pathname === FrontendPagePath.LICENSE_TENANT_FORM_PATH) {
      index = 1;
      subIndex = 7;
    }else if (location.pathname === FrontendPagePath.CONFIG_PATH) {
      index = 6;
    } else if (location.pathname === FrontendPagePath.BUYMORE_PATH) {
      index = 7;
    } else if (location.pathname === FrontendPagePath.MAIN_CONFIG_PATH) {
      index = 6;
      subIndex = 0;
    } else if (location.pathname === FrontendPagePath.USER_CONFIG_PATH) {
      index = 6;
      subIndex = 1;
    } else if (location.pathname === FrontendPagePath.LDAP_CONFIG_PATH) {
      index = 6;
      subIndex = 2;
    } else if (location.pathname === FrontendPagePath.IDM_CONFIG_PATH) {
      index = 6;
      subIndex = 3;
    } else if (location.pathname === FrontendPagePath.CONTROL_TOWER_PATH) {
      index = 6;
      subIndex = 5;
    } else if (location.pathname === FrontendPagePath.USAGE_CONFIG_PATH) {
      index = 6;
      subIndex = 4;
    } else if (location.pathname === FrontendPagePath.DB_CONFIG_PATH) {
      index = 6;
      subIndex = 4;
    } else if (location.pathname === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
      index = 0;
      subIndex = 0;
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_ADVANCED_PATH
    ) {
      index = 0;
      subIndex = 1;
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH
    ) {
      index = 0;
      subIndex = 3;
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH
    ) {
      index = 0;
      subIndex = 4;
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_GENERIC_PATH
    ) {
      index = 0;
      subIndex = 5;
    } else if(location.pathname === FrontendPagePath.USAGE_PRODUCT_REPORT_PATH){
      index = 0;
      subIndex = 6;
    } else if(location.pathname === FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH){
      index = 0;
      subIndex = 7;
    }
    else if (
      location.pathname === FrontendPagePath.RESERVATION_FEATURE_BASE_PATH
    ) {
      index = 3;
      subIndex = 1;
    } else if (
      location.pathname === FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH
    ) {
      index = 3;
      subIndex = 2;
    } else if (location.pathname === FrontendPagePath.USER_UPDATE_FORM_PATH) {
      index = 5;
      subIndex = -1;
    } else if (location.pathname === FrontendPagePath.COMMUTER_CHECKIN_PATH) {
      index = 4;
      subIndex = 1;
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_ENTRIES) {
      index = 6;
      subIndex = 6;
    }else if (location.pathname === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION) {
      index = 6;
      if(isIDM === true) {
        subIndex = 2;
      } else {
        subIndex = 7;
      }
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_AUTH_TOKEN) {
      index = 6;
      subIndex = 8;
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_SAML_ENTRIES) {
      index = 6;
      subIndex = 9;
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_AUDITS) {
      index = 6;
      subIndex = 10;
    }
    this.setState({
      activeIndex: index,
      subIndex: subIndex,
      configIndex: subIndex
    });
    FetchHelper.getProductFeatureLicensesCount(undefined, "ACTIVE", undefined);
    ConfigurationUtils.showLoadMask("", intl);
    AppConfigSettingStore.on("change", this.refreshState);
    if(this.props.session !== undefined && this.props.session.isadmin !== undefined && this.props.session.isadmin.value){
      this.setState({isCTenabled: FetchHelper.getSession().ctFlag});
    }
  }

  componentWillUnmount() {
    AppConfigSettingStore.removeListener("change", this.refreshState);
  }
  refreshState() {
    if(AppConfigSettingStore.getState().type === "PRODUCT_FEATURE_SETUP"){
      this.setState({productFeatureCount: AppConfigSettingStore.getState().productFeatureCount});
      this.setState({isNamedFeature: AppConfigSettingStore.getState().isNamedFeature});
      this.setState({isCustomReport: AppConfigSettingStore.getState().isCustomReport});   
      this.setState({isProductReport : AppConfigSettingStore.getState().isProductReport}) ;  
      let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }
    if(FetchHelper.getSession().admin){
      this.setState({isCTenabled: FetchHelper.getSession().ctFlag});
    }

  }
  componentDidUpdate() {
    var location = this.props.location;
    let isIDM = this.props.session !== undefined && this.props.session.isidm.value !== undefined ? this.props.session.isidm.value : false;
    var index = 0,
      subIndex = 0;
    if (location.pathname === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
      index = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.usage");
    } else if (location.pathname === FrontendPagePath.LICENSE_PATH) {
      index = 1;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.license");
    } else if (
      location.pathname === FrontendPagePath.LICENSE_VIEW_PATH ||
      location.pathname === FrontendPagePath.CONTEXT_PATH + "/" ||
      location.pathname === FrontendPagePath.CONTEXT_PATH ||
      location.pathname === FrontendPagePath.HOME_PATH
    ) {
      index = 1;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.view"
      );
    } else if (location.pathname === FrontendPagePath.LICENSE_ARCHIVE_PATH) {
      index = 1;
      subIndex = 2;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.archive"
      );
    } else if (
      location.pathname === FrontendPagePath.LICENSE_BORROW_PATH ||
      location.pathname === FrontendPagePath.LICENSE_BORROW_FORM_PATH
    ) {
      index = 1;
      subIndex = 3;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.borrow"
      );
    } else if (location.pathname === FrontendPagePath.LICENSE_CLEANUP_PATH) {
      index = 1;
      subIndex = 4;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.cleanup"
      );
    } else if (
      location.pathname === FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH
    ) {
      index = 1;
      subIndex = 5;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.productfeature"
      );
    }  else if (
      location.pathname === FrontendPagePath.LICENSE_NAMEDUSER_PATH
    ) {
      index = 1;
      subIndex = 6;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.license.nameduser"
      );
    } else if (location.pathname === FrontendPagePath.REPORTS_PATH) {
      index = 0;
      subIndex = 2;
      document.title = LocaleUtility.getLocaleMessage("page.title.report");
    } else if (
      location.pathname === FrontendPagePath.RESERVATION_POOL_MANAGE_PATH
    ) {
      index = 3;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.reservation");
    } else if (location.pathname === FrontendPagePath.COMMUTER_CHECKOUT_PATH) {
      index = 4;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.commuter");
    } else if (location.pathname === FrontendPagePath.USER_MGMT_PATH) {
      index = 5;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.user");
    } else if (location.pathname === FrontendPagePath.USER_GROUPS_PATH) {
      index = 5;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage("page.title.group");
    } else if (location.pathname === FrontendPagePath.USER_TENANTS_PATH) {
      index = 5;
      if(isIDM === true) {
        subIndex = 2;
      } else {
        subIndex = 2;
      }
      document.title = LocaleUtility.getLocaleMessage("page.title.tenant");
    }else if (location.pathname === FrontendPagePath.USER_TENANT_LICENSE_PATH ||
      location.pathname === FrontendPagePath.LICENSE_TENANT_FORM_PATH) {
      index = 1;
      subIndex = 7;
      document.title = LocaleUtility.getLocaleMessage("mtlicenses");
    } else if (location.pathname === FrontendPagePath.CONFIG_PATH) {
      index = 6;
      document.title = LocaleUtility.getLocaleMessage("page.title.config");
    } else if (location.pathname === FrontendPagePath.BUYMORE_PATH) {
      index = 7;
      document.title = LocaleUtility.getLocaleMessage("page.title.buymore");
    } else if (location.pathname === FrontendPagePath.MAIN_CONFIG_PATH) {
      index = 6;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage("page.title.config.main");
    } else if (location.pathname === FrontendPagePath.USER_CONFIG_PATH) {
      index = 6;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage("page.title.config.user");
    } else if (location.pathname === FrontendPagePath.LDAP_CONFIG_PATH) {
      index = 6;
      subIndex = 2;
      document.title = LocaleUtility.getLocaleMessage("page.title.config.ldap");
    } else if (location.pathname === FrontendPagePath.IDM_CONFIG_PATH) {
      index = 6;
      subIndex = 3;
      document.title = LocaleUtility.getLocaleMessage("page.title.config.idm");
    } else if (location.pathname === FrontendPagePath.USAGE_CONFIG_PATH) {
      index = 6;
      subIndex = 4;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.config.usage"
      );
    } else if (location.pathname === FrontendPagePath.DB_CONFIG_PATH) {
      index = 6;
      subIndex = 4;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.config.database"
      );
    }else if (location.pathname === FrontendPagePath.CONTROL_TOWER_PATH) {
      index = 6;
      subIndex = 5;
      document.title = LocaleUtility.getLocaleMessage("page.title.config.ct");
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_ENTRIES) {
      index = 6;
      subIndex = 6;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.config.entires"
      );
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION) {
      index = 6;
      if(isIDM === true) {
        subIndex = 7;
      } else {
        subIndex = 7;
      }
      document.title = LocaleUtility.getLocaleMessage(
        "SubscriptionNotificationConfiguration"
      );
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_AUTH_TOKEN) {
      index = 6;
      subIndex = 8;
      document.title = LocaleUtility.getLocaleMessage(
        "serverauthtoken.config.label"
      );
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_SAML_ENTRIES) {
      index = 6;
      if(this.props.setSaml)
        subIndex = 0;
      else
        subIndex = 9;
      document.title = LocaleUtility.getLocaleMessage(
        "label.saml"
      );
    } else if (location.pathname === FrontendPagePath.APP_CONFIG_AUDITS) {
      index = 6;
      subIndex = 10;
      document.title = LocaleUtility.getLocaleMessage("label.audits");
    } else if (location.pathname === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
      index = 0;
      subIndex = 0;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.usage.report"
      );
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_ADVANCED_PATH
    ) {
      index = 0;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.advanced.report"
      );
    } else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH
    ) {
      index = 0;
      subIndex = 3;
      document.title = LocaleUtility.getLocaleMessage(
        "page.title.token.calculator"
      );
    }else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH
    ) {
      index = 0;
      subIndex = 4;
      document.title = LocaleUtility.getLocaleMessage(
        "tab.title.instant.on"
      );
    }else if (
      location.pathname === FrontendPagePath.USAGE_REPORT_GENERIC_PATH
    ) {
      index = 0;
      subIndex = 5;
      document.title = LocaleUtility.getLocaleMessage(
        "tab.title.generic.usage"
      );
    }else if(location.pathname === FrontendPagePath.USAGE_PRODUCT_REPORT_PATH){
      index = 0;
      subIndex = 6;
      document.title = LocaleUtility.getLocaleMessage("tab.title.usage.product.report");
    } else if(location.pathname === FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH){
      index = 0;
      subIndex = 7;
      document.title = LocaleUtility.getLocaleMessage("tab.title.usage.tenantwise.report");
    } else if (
      location.pathname === FrontendPagePath.RESERVATION_FEATURE_BASE_PATH
    ) {
      index = 3;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage("page.title.reservation");
    } else if (
      location.pathname === FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH
    ) {
      index = 3;
      subIndex = 2;
      document.title = LocaleUtility.getLocaleMessage("page.title.reservation");
    } else if (location.pathname === FrontendPagePath.USER_UPDATE_FORM_PATH) {
      index = 5;
      subIndex = -1;
      document.title = LocaleUtility.getLocaleMessage("page.title.user");
    } else if (location.pathname === FrontendPagePath.COMMUTER_CHECKIN_PATH) {
      index = 4;
      subIndex = 1;
      document.title = LocaleUtility.getLocaleMessage("page.title.commuter");
    }
    this.setState({
      activeIndex: index,
      subIndex: subIndex,
      configIndex: subIndex
    });
  }

  render() {
    var license = LocaleUtility.getLocaleMessage("license");
    var reports = LocaleUtility.getLocaleMessage("reports");
    var userManagement = LocaleUtility.getLocaleMessage("userManagement");
    var reservation = LocaleUtility.getLocaleMessage("reservation");
    var configuration = LocaleUtility.getLocaleMessage("configuration");
    var about = LocaleUtility.getLocaleMessage("about");
    var usage = LocaleUtility.getLocaleMessage("usage");
    var remoteCommuter = LocaleUtility.getLocaleMessage("remoteCommuter");
    var buymore = LocaleUtility.getLocaleMessage("buymore");

    var container = false,
      admin = false,
      dbType = "",
      currentUser = "",
      isIDM = false, isSaml = false,
      showUserLink = false,
      prodAdmin = false,
      roletype = "";
    if (this.props.session !== undefined) {
      container =
        this.props.session.iscontainer.value !== undefined
          ? this.props.session.iscontainer.value
          : false;
      admin =
        this.props.session.isadmin.value !== undefined
          ? this.props.session.isadmin.value
          : false;
      dbType =
        this.props.session.iseditable.value !== undefined
          ? this.props.session.iseditable.value
          : "";
      isIDM =
        this.props.session.isidm.value !== undefined
          ? this.props.session.isidm.value
          : false;
      isSaml =
        this.props.session.isSaml.value !== undefined
          ? this.props.session.isSaml.value
          : false;
      currentUser =
        this.props.session.currentUser.value !== undefined
          ? this.props.session.currentUser.value
          : "";
      roletype = (this.props.session.roletype !== undefined && 
                  this.props.session.roletype.value !== undefined) ? 
                    this.props.session.roletype.value : '';
      if(roletype === 'product'){
        prodAdmin = true;
      }
      showUserLink =
        (currentUser !== "aplsuser" && dbType === "DB" && isSaml === false) ||
        (currentUser === "admin" && dbType === "DB" && isIDM === false);
    }
    let usageReportMenuList = this.getSubMenuForUsageTab(admin, prodAdmin);
    let licenseMenuList = this.getSubMenuForLicenseTab(admin, prodAdmin, container);
    let reservationMenuList = this.getSubMenuForReservationTab();
    let commuterMenuList = this.getSubMenuForRemoteCommuterTab();
    let userMenuList = this.getSubMenuForUsersTab();
    let confiMenuList = this.getSubMenuForConfigTab(admin, container, isIDM);
    let currentActiveIndex = 0;
    let currentActiveSubIndex = 0;
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    let showUsersTab = false;
    if(container === false && isIDM === false) {
      showUsersTab = true;
    }
    if(currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y") {
      showUsersTab = true;
    }
    try {
      let pName = this.props.location.pathname;
      if (pName === FrontendPagePath.LICENSE_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 0;
      } else if (pName === FrontendPagePath.LICENSE_VIEW_PATH ||
                    pName === FrontendPagePath.CONTEXT_PATH + "/" ||
                      pName === FrontendPagePath.CONTEXT_PATH ||
                        pName === FrontendPagePath.HOME_PATH || pName === "/autopass/ls/license/view"
                        || pName === "/autopass/ls/login") {
        currentActiveIndex = 1;
        currentActiveSubIndex = 1;
      } else if (pName === FrontendPagePath.LICENSE_ARCHIVE_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 2;
      } else if (pName === FrontendPagePath.LICENSE_BORROW_PATH ||
                  pName === FrontendPagePath.LICENSE_BORROW_FORM_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 3;
      } else if (pName === FrontendPagePath.LICENSE_CLEANUP_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 4;
      } else if (pName === FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 5;
      } else if (pName === FrontendPagePath.REPORTS_PATH) {
        currentActiveIndex = 0;
        currentActiveSubIndex = 2;
      } else if (pName === FrontendPagePath.RESERVATION_POOL_MANAGE_PATH) {
        currentActiveIndex = 3;
      } else if (pName === FrontendPagePath.COMMUTER_CHECKOUT_PATH) {
        currentActiveIndex = 4;
        currentActiveSubIndex = 0;
      } else if (pName === FrontendPagePath.USER_MGMT_PATH) {
        currentActiveIndex = 5;
        currentActiveSubIndex = 0;
      } else if (pName === FrontendPagePath.USER_GROUPS_PATH) {
        currentActiveIndex = 5;
        currentActiveSubIndex = 1;
      } else if (pName === FrontendPagePath.USER_TENANTS_PATH) {
        currentActiveIndex = 5;
        if(isIDM === true) {
          currentActiveSubIndex = 0;
        } else {
          currentActiveSubIndex = 2;
        }
        
      } else if (pName === FrontendPagePath.USER_TENANT_LICENSE_PATH ||
        pName === FrontendPagePath.LICENSE_TENANT_FORM_PATH) {
        currentActiveIndex = 1;
        currentActiveSubIndex = 7;
      } else if (pName === FrontendPagePath.CONFIG_PATH) {
        currentActiveIndex = 6;
      } else if (pName === FrontendPagePath.BUYMORE_PATH) {
        currentActiveIndex = 7;
      } else if (pName === FrontendPagePath.MAIN_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 0;
      } else if (pName === FrontendPagePath.USER_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 1;
      } else if (pName === FrontendPagePath.LDAP_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 2;
      } else if (pName === FrontendPagePath.IDM_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 3;
      } else if (pName === FrontendPagePath.USAGE_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 4;
      } else if (pName === FrontendPagePath.DB_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 4;
      } else if (pName === FrontendPagePath.RESERVATION_FEATURE_BASE_PATH) {
        currentActiveIndex = 3;
        currentActiveSubIndex = 1;
      } else if (pName === FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH) {
        currentActiveIndex = 3;
        currentActiveSubIndex = 2;
      } else if (pName === FrontendPagePath.COMMUTER_CHECKIN_PATH) {
        currentActiveIndex = 4;
        currentActiveSubIndex = 1;
      } else if(pName === FrontendPagePath.USAGE_REPORT_LIST_PATH) {
        currentActiveIndex = 0;
        currentActiveSubIndex = 0;
      } else if(pName === FrontendPagePath.USAGE_REPORT_ADVANCED_PATH) {
        currentActiveIndex = 0;
        currentActiveSubIndex = 1;
      } else if(pName === FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH) {
        currentActiveIndex = 0;
        currentActiveSubIndex = 3;
      } else if(pName === FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH) {
        currentActiveIndex = 0;
        currentActiveSubIndex = 4;
      } else if (pName === FrontendPagePath.DB_CONFIG_PATH) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 7;
      } else if (pName === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION) {
        currentActiveIndex = 6;
        if(isIDM === true) {
          currentActiveSubIndex = 2;
        } else {
          currentActiveSubIndex = 8;
        }
      } else if (pName === FrontendPagePath.APP_CONFIG_AUTH_TOKEN) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 9;
      } else if (pName === FrontendPagePath.APP_CONFIG_SAML_ENTRIES) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 9;
      } else if (pName === FrontendPagePath.APP_CONFIG_AUDITS) {
        currentActiveIndex = 6;
        currentActiveSubIndex = 10;
      }    
    } catch (e) {

    }
    let homeText = license;
    if(this.state.activeIndex === 0) {
      homeText = usage+" & "+reports;
    } else if(this.state.activeIndex === 3) {
      homeText = reservation;
    } else if(this.state.activeIndex === 7) {
      homeText = buymore;
    } else if(this.state.activeIndex === 5) {
      homeText = userManagement;
    } else if(this.state.activeIndex === 6) {
      homeText = configuration;
    } else if(this.state.activeIndex === 4) {
      homeText = remoteCommuter;
    }
    return (
      <React.Fragment>
        <Box>
            <Header 
              className="masthead-bg-color sticky-header"
              sticky={false}
              margin="none"
              id="header"
            >
              
              <div id="menuTitle" style={{paddingTop: "0px"}} >
                <div className="menu-icon-cls" onClick={event=>this.enableMenuFn()}>
                  <MenuIcon id="menu-icon-id" colorIndex="light-1" size="" /> 
                </div>
                <div className="logo_wrap">
                  {/* <OT_logo /> */}
                  <img src={OT_logo} alt="OpenText" />
                </div>
                <title id="dcaTitle" onClick={event=>this.enableMenuFn()}  >
                LS
              </title>
                <div id="menu-text-id" >
                  {homeText}
                </div>
              </div>
              {this.state.oldView &&( 
                <Box direction="row" flex="grow" justify="start">
                  <Tabs
                    activeIndex={this.state.activeIndex}
                    id="tabs"
                    onActive={this.tabClicked}
                    style={{ margin: 0 }}
                  >
                    <Tab title={usage} id="usage" />
                    <Tab title={license} id="license" />
                    <Tab title={reports} id="reports" />
                    {(
                      <Tab title={reservation} id="reservation" />
                    )}
                    {container === false && (
                      <Tab title={remoteCommuter} id="remoteCommuter" />
                    )}
                    {container === false && isIDM === false && admin === true && (
                      <Tab title={userManagement} id="userManagement" />
                    )}
                    {admin === true && (
                      <Tab title={configuration} id="configuration" />
                    )}
                    {admin === true && (
                    <Tab title={buymore} id="buymore" />
                    )}
                    <Tab title={about} id="about" />
                  </Tabs>
                </Box>
              )}
            {this.state.oldView || this.props.setSaml ? 
              <Box id="avatarBox" direction="row" flex="grow" justify="end">
                <div style={{marginRight: "12px"}} className="userTabTooltip">
                <Avatar
                  user={this.props.user}
                  showUserLink={false}
                  onAvatarClosed={this.onLogout}
                />
                </div>
              </Box>
            :
              <Box id="avatarBox" direction="row" flex="grow" justify="end">
                {!this.props.setSaml && admin === true && (
                <div style={{marginRight: "12px"}} className="userTabTooltip">
                  <span className="userTabTooltipText">{buymore}</span>
                  <APMenu 
                    id = "buymore"
                    label = {""}
                    menulist = {[]}
                    primary = {false}
                    activeIndex = {7}
                    currentActiveIndex = {currentActiveIndex}
                    currentActiveSubIndex = {currentActiveSubIndex}
                    onActive = {this.menuChangeAction}
                    icon = {<CartIcon id="buymore-icon" colorIndex="neutral-5" size="large" style={{stroke:"#f5f7f8"}}/>}
                    isSlideMenu = {false}
                    onMouseOverIcon = {this.onMouseOverIcon}
                  />
                </div>
                )}
                {admin === true && showUsersTab === true && (
                  <div style={{marginRight: "12px"}} className="userTabTooltip">
                    <APMenu 
                      id = "userManagement"
                      label = {""}
                      menulist = {userMenuList}
                      primary = {false}
                      activeIndex = {5}
                      currentActiveIndex = {currentActiveIndex}
                      currentActiveSubIndex = {currentActiveSubIndex}
                      onActive = {this.menuChangeAction}
                      icon = {<UsersIcon id="userManagement-icon" size="large" colorIndex="neutral-5" onClick={event=>this.menuChangeAction(0, 5, "")} style={{stroke:"#f5f7f8"}}/>}
                      isSlideMenu = {false}
                      onMouseOverIcon = {this.onMouseOverIcon}
                    />
                  </div>
                )}
                {admin === true && (
                  <div style={{marginRight: "12px"}} className="userTabTooltip">
                    <APMenu 
                      id = "configuration"
                      label = {""}
                      menulist = {confiMenuList}
                      primary = {false}
                      activeIndex = {6}
                      currentActiveIndex = {currentActiveIndex}
                      currentActiveSubIndex = {currentActiveSubIndex}
                      onActive = {this.menuChangeAction}
                      icon = {<ConfigIcon id="configuration-icon" size="large" colorIndex="neutral-5" onClick={event=>this.menuChangeAction(0, 6, "")} style={{stroke:"#f5f7f8"}}/>}
                      isSlideMenu = {false}
                      onMouseOverIcon = {this.onMouseOverIcon}
                    />
                  </div>
                )}
                <div style={{marginRight: "12px"}} className="userTabTooltip">
                  <span className="userTabTooltipText">{about}</span>
                  <APMenu 
                    id = "about"
                    label = {""}
                    menulist = {[]}
                    primary = {false}
                    activeIndex = {8}
                    currentActiveIndex = {currentActiveIndex}
                    currentActiveSubIndex = {currentActiveSubIndex}
                    onActive = {this.menuChangeAction}
                    icon = {<CircleInformationIcon id="about-icon" colorIndex="neutral-5" size="large" style={{stroke:"#f5f7f8"}}/>}
                    isSlideMenu = {false}
                    onMouseOverIcon = {this.onMouseOverIcon}
                  />
                </div>
                <div style={{marginRight: "12px"}} className="userTabTooltip">
                  <span className="userTabTooltipText">{LocaleUtility.getLocaleMessage("label.tooltip.help")}</span>
                  <HelpLink 
                    helpTopic = ""
                    onMouseOver = {this.onMouseOverIcon}
                  />
                </div>
                <div>
                  <Avatar
                    user={this.props.user}
                    showUserLink={showUserLink}
                    onAvatarClosed={this.onLogout}
                  />
                </div>
              </Box>
            }
          </Header>
        </Box>
        <div id="slide-menu-id" style={{display: "none"}} className="slideMenu" onMouseLeave={event=>this.onMouseLeaveSlide()}>
          <Box direction="row" flex="grow" justify="start" className="masthead-bg-color bigmenu_wrapper">
            <APMenu 
              id = "license"
              label = {license}
              menulist = {licenseMenuList}
              primary = {false}
              activeIndex = {1}
              currentActiveIndex = {currentActiveIndex}
              currentActiveSubIndex = {currentActiveSubIndex}
              onActive = {this.menuChangeAction}
              isSlideMenu = {true}
              // width = "11%"
            />
            {(
              <APMenu 
                id = "reservation"
                label = {reservation}
                menulist = {reservationMenuList}
                primary = {false}
                activeIndex = {3}
                currentActiveIndex = {currentActiveIndex}
                currentActiveSubIndex = {currentActiveSubIndex}
                onActive = {this.menuChangeAction}
                isSlideMenu = {true}
                // width = "18%"
              />
            )}
            {container === false && (
              <APMenu 
                id = "remoteCommuter"
                label = {remoteCommuter}
                menulist = {commuterMenuList}
                primary = {false}
                activeIndex = {4}
                currentActiveIndex = {currentActiveIndex}
                currentActiveSubIndex = {currentActiveSubIndex}
                onActive = {this.menuChangeAction}
                isSlideMenu = {true}
                // width = "12%"
              />
            )}
            <APMenu 
              id = "usage"
              label = {usage+" & "+reports}
              menulist = {usageReportMenuList}
              primary = {false}
              activeIndex = {0}
              currentActiveIndex = {currentActiveIndex}
              currentActiveSubIndex = {currentActiveSubIndex}
              onActive = {this.menuChangeAction}
              isSlideMenu = {true}
              // width = "20%"
            />
          </Box>
        </div>
        {this.state.activeIndex !== 7 &&(<div className="sub_menu_wrap" >
          <div className="">
          <Header colorIndex="neutral-5" fixed={false} margin="none" id="header2" sticky={false}
            className="">
            <Box direction="row" align="end" flex="grow" justify="start"
            className="">
              {this.state.activeIndex === 0 && this.getSubMenuForUsage(admin, prodAdmin)}
              {this.state.activeIndex === 1 &&
                this.getSubMenuForLicense(admin, prodAdmin, container)}
              {this.state.activeIndex === 2 && this.getSubMenuForReports()}
              {this.state.activeIndex === 3 && this.getSubMenuForReservation()}
              {this.state.activeIndex === 4 &&
                container === false &&
                this.getSubMenuForRemoteCommuter()}
              {this.state.activeIndex === 5 && this.state.subIndex > -1 &&
                admin === true &&
                this.getSubMenuForUsers()}
              {this.state.activeIndex === 6 &&
                this.getSubMenuForConfig(admin, container, isIDM, this.props.setSaml)}
              {this.state.activeIndex === 7 && this.getSubMenuForBuyMore()}
              {this.state.activeIndex === 8 && this.getSubMenuForAbout()}
            </Box>
          </Header>
          </div>
        </div>)}
        <About
          showModal={this.state.aboutClicked}
          onCallBack={this.onModalCloseCallback}
        />
      </React.Fragment>
    );
  }
  
  getSubMenuForUsage(admin, prodAdmin) {
    var intl = intl;
    var licenseUsage = LocaleUtility.getLocaleMessage("licenseUsage");
    var advReport = LocaleUtility.getLocaleMessage("advanceReports");
    var tokenCal = LocaleUtility.getLocaleMessage("tokenCalculator");
    var instantOn = LocaleUtility.getLocaleMessage("tab.title.instant.on");
    var genericUsage = LocaleUtility.getLocaleMessage("tab.title.generic.usage");
    var tokentabenable=FetchHelper.getSession().enableTokenCalculator;
    var reports = LocaleUtility.getLocaleMessage("license.reports");
    var productUsage = LocaleUtility.getLocaleMessage("tab.title.usage.product.report");
    var licenseUsageTenantWise = LocaleUtility.getLocaleMessage("tab.title.usage.tenantwise.report");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;

    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        <Tab title={licenseUsage} id="licenseUsage" />
        <Tab title={advReport} id="advReport" />
        <Tab title={reports} id="reports" />
        { ( tokentabenable === true) && (
           <Tab title={tokenCal} id="tokenCal" />
        )}
        { ( this.state.instantOnTab === true) && (
          <Tab title={instantOn} id="instantOnReport" />
        )}
        { ( this.state.isCustomReport === true) && (
        <Tab title={genericUsage} id="genericUsage" />
        )}
        { ( this.state.isProductReport === true) && (
        <Tab title={productUsage} id="productUsage" />
        )} 
        {(admin === true || prodAdmin === true) && currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y" &&(
          <Tab title={licenseUsageTenantWise} id="licenseUsageTenantWise" />  
        )}     
      </Tabs>
    );
  }

  getSubMenuForUsageTab(admin, prodAdmin) {
    var licenseUsage = LocaleUtility.getLocaleMessage("licenseUsage");
    var advReport = LocaleUtility.getLocaleMessage("advanceReports");
    var tokenCal = LocaleUtility.getLocaleMessage("tokenCalculator");
    var instantOn = LocaleUtility.getLocaleMessage("tab.title.instant.on");
    var genericUsage = LocaleUtility.getLocaleMessage("tab.title.generic.usage");
    var licenseReport = LocaleUtility.getLocaleMessage("license.reports");
    var tokentabenable=FetchHelper.getSession().enableTokenCalculator;
    var productUsage = LocaleUtility.getLocaleMessage("tab.title.usage.product.report");
    var licenseUsageTenantWise = LocaleUtility.getLocaleMessage("tab.title.usage.tenantwise.report");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;

    var list = [];
    list.push({index: 0, value: licenseUsage});
    list.push({index: 1, value: advReport});
    list.push({index: 2, value: licenseReport});    
    if(tokentabenable === true) {
      list.push({index: 3, value: tokenCal});
    }
    if(this.state.instantOnTab === true) {
      list.push({index: 4, value: instantOn});
    }
    if(this.state.isCustomReport === true){
      list.push({index: 5, value: genericUsage});
    }
    if(this.state.isProductReport === true){
      list.push({index: 6, value: productUsage});
    }
    if((admin === true || prodAdmin === true) && currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y"){
      list.push({index: 7, value: licenseUsageTenantWise})
    }
    return list;
  }
  getSubMenuForLicense(admin, prodAdmin, container) {
    var intl = intl;
    var installLicenses = LocaleUtility.getLocaleMessage("installLicenses");
    var viewLicenses = LocaleUtility.getLocaleMessage("viewLicenses");
    var archivedLicenses = LocaleUtility.getLocaleMessage("archivedLicenses");
    var borrowLicense = LocaleUtility.getLocaleMessage("borrowLicense");
    var licenseCleanUp = LocaleUtility.getLocaleMessage("licenseCleanUp");
    var productFeature = LocaleUtility.getLocaleMessage("productFeature");
    var namedFeature = LocaleUtility.getLocaleMessage("namedFeature");
    let showBorrow = true;
    let borrowFlag = localStorage.getItem("borrowFlag");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var mtlicenses = LocaleUtility.getLocaleMessage("mtlicenses");
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    if(borrowFlag !== undefined && (borrowFlag === "0" || borrowFlag === 0)) {
      showBorrow = false;
    }
    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        <Tab title={installLicenses} id="installLicenses" />
        <Tab title={viewLicenses} id="viewLicenses" />
        { (admin === true || prodAdmin === true ) && (
          <Tab title={archivedLicenses} id="archivedLicenses" />
        )}
        {(admin === true || prodAdmin === true) && container === false && showBorrow === true && (currentTenantName === "" || currentTenantName === "0" || Number(currentTenantName) === 0) && (
          <Tab title={borrowLicense} id="borrowLicense" />
        )}
        {(admin === true || prodAdmin === true) && (<Tab title={licenseCleanUp} id="licenseCleanUp" />)}
        {this.state.productFeatureCount && <Tab title={productFeature} id="productFeature" />}
        {(admin === true || prodAdmin === true) && this.state.isNamedFeature && (<Tab title={namedFeature} id="namedFeature" />)}
        {(admin === true || prodAdmin === true) && currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y" &&(
          <Tab title={mtlicenses} id="mtlicenses" />
        )}
      </Tabs>
      
    );
  }
  getSubMenuForLicenseTab(admin, prodAdmin, container) {
    var intl = intl;
    var installLicenses = LocaleUtility.getLocaleMessage("installLicenses");
    var viewLicenses = LocaleUtility.getLocaleMessage("viewLicenses");
    var archivedLicenses = LocaleUtility.getLocaleMessage("archivedLicenses");
    var borrowLicense = LocaleUtility.getLocaleMessage("borrowLicense");
    var licenseCleanUp = LocaleUtility.getLocaleMessage("licenseCleanUp");
    var productFeature = LocaleUtility.getLocaleMessage("productFeature");
    var namedFeature = LocaleUtility.getLocaleMessage("namedFeature");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var tenantLicenses = LocaleUtility.getLocaleMessage("manage.tenant.licenses");
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    var list = [];
    list.push({index: 0, value: installLicenses});
    list.push({index: 1, value: viewLicenses});
    if(admin === true || prodAdmin === true ) {
      list.push({index: 2, value: archivedLicenses});
    }
    if((admin === true || prodAdmin === true) && container === false && (currentTenantName === "" || currentTenantName === "0" || Number(currentTenantName) === 0)) {
      list.push({index: 3, value: borrowLicense});
    }
    if(admin === true || prodAdmin === true) {
      list.push({index: 4, value: licenseCleanUp});
    }
    if(this.state.productFeatureCount) {
      list.push({index: 5, value: productFeature});
    }
    if((admin === true || prodAdmin === true ) && this.state.isNamedFeature) {
      list.push({index: 6, value: namedFeature});
    }
    if((admin === true || prodAdmin === true ) && currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y") {
      list.push({index: 7, value: tenantLicenses});
    }
    return list;
  }
  getSubMenuForBuyMore() {
    /**  return (
       <Tabs
         activeIndex={this.state.subIndex}
         id="tabsWhite"
         onActive={this.subTabClicked}
       >
         <Tab title="submenu1" id="submenu1" />
         <Tab title="submenu2" id="submenu2" />
         <Tab title="submenu3 1" id="submenu3" />
         <Tab title="submenu4 2" id="submenu4" />
       </Tabs>
     );*/
  }
  getSubMenuForReports() {
    /**
    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        <Tab title="Usage Reports" id="usageReport" />
        <Tab title="License Reports" id="licenseReports" />
        <Tab title="submenu3 1" id="submenu3" />
        <Tab title="submenu4 2" id="submenu4" />
      </Tabs>
    ); */
  }
  getSubMenuForRemoteCommuter() {
    var intl = intl;
    var remoteCommuterCheckOut = LocaleUtility.getLocaleMessage("remoteCommuterCheckOut");
    var remoteCommuterCheckIn = LocaleUtility.getLocaleMessage("remoteCommuterCheckIn");
    
    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        <Tab title={remoteCommuterCheckOut} id="remoteCommuterCheckOut" />
        <Tab title={remoteCommuterCheckIn} id="remoteCommuterCheckIn" />
      </Tabs>
    );
  }
  getSubMenuForRemoteCommuterTab() {
    var intl = intl;
    var remoteCommuterCheckOut = LocaleUtility.getLocaleMessage("remoteCommuterCheckOut");
    var remoteCommuterCheckIn = LocaleUtility.getLocaleMessage("remoteCommuterCheckIn");
    let list = [];
    list.push({index: 0, value: remoteCommuterCheckOut});
    list.push({index: 1, value: remoteCommuterCheckIn});
    return list;
  }
  getSubMenuForUsers() {
    var intl = intl;
    var userGroups = LocaleUtility.getLocaleMessage("userGroups");
    var users = LocaleUtility.getLocaleMessage("users");
    var tenants = LocaleUtility.getLocaleMessage("tenants");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    let isIDM = this.props.session !== undefined && this.props.session.isidm.value !== undefined ? this.props.session.isidm.value : false;
    let container = this.props.session !== undefined && this.props.session.iscontainer.value !== undefined ? this.props.session.iscontainer.value : false;
    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        {container === false && isIDM === false && (<Tab title={users} id="users" />)}
        {container === false && isIDM === false && (<Tab title={userGroups} id="userGroups" />)}
        {currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y" &&(
          <Tab title={tenants} id="tenants" />
        )}
      </Tabs>
    );
  }
  getSubMenuForUsersTab() {
    var intl = intl;
    var userGroups = LocaleUtility.getLocaleMessage("manage.groups.label");
    var users = LocaleUtility.getLocaleMessage("manage.users.label");
    var tenants = LocaleUtility.getLocaleMessage("manage.users.tenants");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    let isIDM = this.props.session !== undefined && this.props.session.isidm.value !== undefined ? this.props.session.isidm.value : false;
    let container = this.props.session !== undefined && this.props.session.iscontainer.value !== undefined ? this.props.session.iscontainer.value : false;
    let list = [];
    if(container === false && isIDM === false) {
      list.push({index: 0, value: users});
      list.push({index: 1, value: userGroups});
    }
    if(currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y") {
      list.push({index: 2, value: tenants});
    }
    return list;
  }

  /**
   * Below function is an example of autobind to the class this and hence
   * explict bind in the construtor is not necessary.
   * This is ES6 syntax
   */
  getSubMenuForReservation = () => {
    var intl = intl;
    var poolManagement = LocaleUtility.getLocaleMessage("poolManagement");
    var featurebasedReservation = LocaleUtility.getLocaleMessage("featurebasedReservation");
    var productbasedReservation = LocaleUtility.getLocaleMessage("productbasedReservation");
    return (
      <Tabs
        activeIndex={this.state.subIndex}
        id="tabsWhite"
        onActive={this.subTabClicked}
      >
        <Tab title={poolManagement} id="poolManagement" />
        <Tab title={featurebasedReservation} id="featurebasedReservation" />
        <Tab title={productbasedReservation} id="productbasedReservation" />
      </Tabs>
    );
  };

  getSubMenuForReservationTab() {
    var intl = intl;
    var poolManagement = LocaleUtility.getLocaleMessage("manage.pools.label");
    var featurebasedReservation = LocaleUtility.getLocaleMessage("feature.based.reservation.label");
    var productbasedReservation = LocaleUtility.getLocaleMessage("product.based.reservation.label");
    let list = [];
    list.push({index: 0, value: poolManagement});
    list.push({index: 1, value: featurebasedReservation});
    list.push({index: 2, value: productbasedReservation});
    return list;
  };

  getSubMenuForConfig(admin, container, isidm, setSaml) {
    var intl = intl;
    var main = LocaleUtility.getLocaleMessage("main");
    var idm = LocaleUtility.getLocaleMessage("idm");
    var ldap = LocaleUtility.getLocaleMessage("ldap");
    var usageHub = LocaleUtility.getLocaleMessage("usageHub");
    var dataBaseConfiguration = LocaleUtility.getLocaleMessage("dataBaseConfiguration");
    var userManagement = LocaleUtility.getLocaleMessage("userManagement");
    var ControlTower = LocaleUtility.getLocaleMessage("controlTower");
    //var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    //var logConfig = LocaleUtility.getLocaleMessage("logConfig");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var configEntries = LocaleUtility.getLocaleMessage("configEntries");
    var saml = LocaleUtility.getLocaleMessage("label.saml");
    var authtoken = LocaleUtility.getLocaleMessage("serverauthtoken.config.label");
    var audits = LocaleUtility.getLocaleMessage("label.audits");
    var configSubNotifications=LocaleUtility.getLocaleMessage("SubscriptionNotificationConfiguration");
    if(setSaml)
        return (
          <Tabs
            activeIndex={this.state.configIndex}
            id="tabsWhite"
            onActive={this.subTabClicked}
          >
            {admin=== true && container === false && isidm === false &&  (
              <Tab title={saml} id="saml" />
            )}
          </Tabs>
        );
    else 
        return (
          <Tabs
            activeIndex={this.state.configIndex}
            id="tabsWhite"
            onActive={this.subTabClicked}
          >
            <Tab title={main} id="main" />
            {container === false && (
              <Tab title={userManagement} id="userManagement" />
            )}
            {container === false && isidm === false && (
              <Tab title={ldap} id="ldap" />
            )}
            {/* {container === false && <Tab title={idm} id="idm" />} */}
            {localStorage.getItem("enableUsageHub") !== undefined &&
              localStorage.getItem("enableUsageHub") === "true" && (
                <Tab title={usageHub} id="usageHubId" />
              )}
            {container === false && (currentTenantName === "" || currentTenantName === "0" || Number(currentTenantName) === 0) && (
              <Tab title={dataBaseConfiguration} id="dataBaseConfiguration" />
            )}
            {this.state.isCTenabled !== undefined &&
              this.state.isCTenabled && (
                <Tab title={ControlTower} id="ControlTower" />
            )}
            {/*container === false && admin === true && currentTenantName !== "" && isTenantAdminUser !== "" && (
              <Tab title={logConfig} id="logConfig" />
            )*/}
            {admin === true &&  (
              <Tab title={configEntries} id="configEntries" />
            )}
            <Tab title={configSubNotifications} id="configSubNotifications" />
            {admin === true &&  (
              <Tab title={authtoken} id="authtoken" />
            )}
            {admin=== true && container === false && isidm === false &&  (
              <Tab title={saml} id="saml" />
            )}
            {admin === true && (
              <Tab title={audits} id="audits" />)
            }
          </Tabs>
        );
  }
  getSubMenuForConfigTab(admin, container, isidm) {
    var intl = intl;
    var main = LocaleUtility.getLocaleMessage("system.config.label");
    // var idm = LocaleUtility.getLocaleMessage("idm.config.label");
    var ldap = LocaleUtility.getLocaleMessage("ldap.config.label");
    var usageHub = LocaleUtility.getLocaleMessage("usage.config.label");
    var dataBaseConfiguration = LocaleUtility.getLocaleMessage("database.config.label");
    var userManagement = LocaleUtility.getLocaleMessage("user.config.label");
    var controlTower = LocaleUtility.getLocaleMessage("ct.config.lebel");
    //var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
    //var logConfig = LocaleUtility.getLocaleMessage("log.label.config");
    var currentTenantName = FetchHelper.getSession().currentTenantName;
    var configEntries = LocaleUtility.getLocaleMessage("entries.label.config");
    var saml = LocaleUtility.getLocaleMessage("label.saml");
    var authtoken = LocaleUtility.getLocaleMessage("serverauthtoken.config.label");
    var audits = LocaleUtility.getLocaleMessage("label.audits");
    var configSubNotifications=LocaleUtility.getLocaleMessage("SubscriptionNotificationConfiguration");
    let list = [];
    list.push({index: 0, value: main});
    if(container === false) {
      list.push({index: 1, value: userManagement});
    }
    if(container === false && isidm === false) {
      list.push({index: 2, value: ldap});
    }
    if(container === false) {
      // list.push({index: 3, value: idm});
    }
    if(localStorage.getItem("enableUsageHub") !== undefined &&
          localStorage.getItem("enableUsageHub") === "true") {
      list.push({index: 3, value: usageHub});
    }
    if(container === false && (currentTenantName === "" || currentTenantName === "0" || Number(currentTenantName) === 0)) {
      list.push({index: 4, value: dataBaseConfiguration});
    }
     if(this.state.isCTenabled !== undefined &&
          this.state.isCTenabled) {
      list.push({index: 5, value: controlTower});
    }
    /*if(container === false && admin === true && currentTenantName !== "" && isTenantAdminUser !== "") {
      list.push({index: 7, value: logConfig});
    }*/
    if(admin === true) {
      list.push({index: 6, value: configEntries});
    }
    if(container === false && admin === true) {
    list.push({index: 7, value: configSubNotifications});
    }else if(admin === true){
      list.push({index: 2, value: configSubNotifications});
    }
    if(admin === true) {
      list.push({index: 8, value: authtoken});
    }
    if(admin == true && container===false && isidm===false)
    {
      list.push({index: 9, value:saml});
    }
    if (admin === true) {
      list.push({ index: 10, value: audits });
    }
    return list;
  }

  getSubMenuForAbout() {
    return;
  }
}
// Menu.contextTypes = {
//   intl: _propTypes2.default.object
// };
export default withRouter(Menu);