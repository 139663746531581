import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import SpinningNotification from '../../generic/SpinningNotification';
import { Button } from "grommet/components/Button";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Store from "../../../flux/ReservationManagementStore";
import TableGrid from "../../../components/commons/table/APTable";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PoolMappingProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            poolName: this.props.poolName,
            poolDescription: "",
            rowData: []
        }
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshByPoolName = this.refreshByPoolName.bind(this);
        this.refresGridData = this.refresGridData.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    refreshByPoolName(paramPoolName) {
        this.setState({poolName: paramPoolName})
        this.findData(paramPoolName);
    }
    componentDidMount() {
        Store.on("change", this.refresGridData);
        this.findData(this.state.poolName);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refresGridData);
    }
    findData(poolName) {
        FetchHelper.listPoolMappingProducts(poolName, this.context.intl);
    }
    refresGridData() {
        if(Store.getState() && Store.getState().actionType === "poolMappingProducts") {
            let listData = Store.getState().listData;
            this.setState({rowData: listData});
            this.setState({poolDescription: Store.getState().groupDescription});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    render() {
        //let intl = this.context.intl;
        return(
            <div className="config-cont-backgroud">
                <div>
                    <SectionWithTitle title={this.findLabel("resv.pool.mapping.title")}  helpTopic="reservation_management"/>
                    <table id="pool_mapping_table_id">
                        <tbody>
                        <tr>
                            <td style={{width: "20%", fontWeight: "bold"}}>
                                {this.findLabel("resv.pool.mapping.pool.name")}
                            </td>
                            <td style={{width: "20%"}}>
                                {this.state.poolName}
                            </td>
                            <td style={{width: "20%", fontWeight: "bold"}}>
                                {this.findLabel("resv.pool.mapping.pool.desc")}
                            </td>
                            <td style={{width: "20%"}}>
                                {this.state.poolDescription}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {this.state.rowData.length > 0 ? 
                        <div id="pool_mapping_grid_div_id">
                            <TableGrid id="poolMappingListGridId"
                                header={[
                                    {
                                        columnName: "product",
                                        headerLabel: this.findLabel("resv.pool.mapping.product.label"),
                                        width: "auto"
                                    },
                                    {
                                        columnName: "feature",
                                        headerLabel: this.findLabel("resv.pool.mapping.feature.label"),
                                        width: "auto"
                                    }
                                ]}
                                rowData={this.state.rowData}
                                pagination={this.state.pagination}
                                rowSelection={1}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                rowKey="feature"
                            />
                        </div>
                    :
                        <div>
                            <p>{this.findLabel("mapping.pool.reservaton.not.found")}</p>
                        </div>
                    }
                </div>
                <div className="button_wrap_secondary">
                    <Button id="pool_mapping_backBtn" label={this.findLabel("label.button.back")} plain={false} secondary={true} onClick={this.props.onClickBackButton} style={{border:"0.0625rem solid #4c4e50", borderRadius:"inherit"}}/>
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// PoolMappingProducts.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PoolMappingProducts;