import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { Anchor } from 'grommet/components/Anchor';
import Store from "../../flux/UserDetailStore";
import { USER_UPDATE_FORM_PATH } from  "../../utils/FrontendPagePath";
import * as LocaleUtility from "../../utils/LocaleUtility";
import * as FetchHelper from "../../utils/FetchHelper";
import * as Utils from "../../utils/Util";

class Avatar extends Component {
  constructor(props) {
    super(props);
    this.tipsDiv = React.createRef();
    this.arrowDiv = React.createRef();
    this.state = {
      avatarEnabled: 0,
      firstTime: 0,
      currentTenentName: FetchHelper.getCurrentTenantName(),
      firstName: "",
      lastName: "",
      email: "",
      isUserUpdated : false
    };
    this.avatarClicked = this.avatarClicked.bind(this);
    this.avatarClosed = this.avatarClosed.bind(this);
    this.divBlured = this.divBlured.bind(this);
    this.divFocus = this.divFocus.bind(this);
    this.refreshState = this.refreshState.bind(this);    
  }

  avatarClicked() {
	this.props.user.email = FetchHelper.getSession().user.email;
    this.props.user.firstName = FetchHelper.getSession().user.firstName;
    this.props.user.lastName = FetchHelper.getSession().user.lastName;
    if (this.state.avatarEnabled === 1) {
      //this.setState({ avatarEnabled: 0 });
    } else {
      this.setState({ avatarEnabled: 1 });
      this.setState({ avatarClickedFirst: 1 });
      setTimeout(() => {
        // Now set a transition on the opacity
        this.tipsDiv.current.style.transition = "opacity 300ms";
        // and set the opacity to 1
        this.tipsDiv.current.style.opacity = 1;
        this.arrowDiv.current.style.transition = "opacity 300ms";
        this.arrowDiv.current.style.opacity = 1;
      }, 200);
      //   window.requestAnimationFrame(() => {
      //     // Now set a transition on the opacity
      //     this.tipsDiv.current.style.transition = "opacity 1000ms";
      //     // and set the opacity to 1
      //     this.tipsDiv.current.style.opacity = 1;
      //   });
    }
    //document.elementFromPoint.tipsDiv;
  }

  avatarClosed(event) {
    this.props.onAvatarClosed(event);
  }

  divBlured(event) {
    setTimeout(() => {
      var activeId = document.activeElement.id;
      if (activeId !== "logoutButton" && activeId !== "tipsDiv") {
        window.requestAnimationFrame(() => {
          // Now set a transition on the opacity
          if(this.tipsDiv.current !== undefined && this.tipsDiv.current !== null && this.tipsDiv.current !== 'null'){
            this.tipsDiv.current.style.transition = "opacity 300ms";
            // and set the opacity to 1
            this.tipsDiv.current.style.opacity = 0;
          }
          if(this.arrowDiv.current !== undefined && this.arrowDiv.current !== null && this.arrowDiv.current !== 'null'){
            this.arrowDiv.current.style.transition = "opacity 300ms";
            // and set the opacity to 1
            this.arrowDiv.current.style.opacity = 0;
          }
          setTimeout(() => {
            this.setState({ avatarEnabled: 0 });
            this.setState({ firstTime: 0 });
          }, 300);
        });
      }
    }, 150);
  }

  refreshState() { 
     if(Store.getState().type==='REFRESH_STATE'){
       this.setState({firstName: FetchHelper.getSession().user.firstName}); 
       this.setState({lastName: FetchHelper.getSession().user.lastName}); 
       this.setState({email: FetchHelper.getSession().user.email}); 
       this.setState({isUserUpdated:true});
     }     
   }
   
  componentWillUnmount() {
    Store.removeListener("change", this.refreshState);
  }
   
  componentDidMount() {
    if (this.state.avatarEnabled === 1) {
      this.tipsDiv.current.focus();
    }
    Store.on("change", this.refreshState);
  }
  componentDidUpdate() {
    if (this.state.avatarEnabled === 1) {
      setTimeout(() => {
        this.divFocus();
      }, 200);
    }
  }

  divFocus() {
    if (this.state.firstTime === 0 && this.state.avatarEnabled === 1) {
      this.tipsDiv.current.focus();
      this.setState({ firstTime: 1 });
    }
  }

  render() {
	if(this.state.isUserUpdated){
	   this.props.user.firstName = this.state.firstName;
       this.props.user.lastName = this.state.lastName;
       this.props.user.email = this.state.email;
	}
    
    var popup = {
      position: "relative",
      paddingTop: "3px"      
    };

    var divStyle1 = {
      content: "",
      position: "absolute",
      top: "78%",
      left: "20%",
      borderWidth: "10px",
      borderStyle: "solid",
      borderColor: "transparent transparent #FFFFFF transparent",
      opacity: 0
    };

    var id = "avatarDiv";

    // var data = localStorage.getItem('LID');
    // var session;
    // if(data != undefined){
    //   var obj = JSON.parse(Utils.decrypt(data));
    //   session = obj.sessionMap;
    // }

    var loggedInUser = "";
    //let fullName = "";
    let email = "";
    let changeText = "";
    if(this.props.user.firstName !== undefined && this.props.user.lastName !== undefined &&
    this.props.user.firstName !== '' && this.props.user.lastName !== ''){
      loggedInUser = this.props.user.lastName +", "+this.props.user.firstName;
      //fullName = this.props.user.lastName +" "+this.props.user.firstName;
    } else if(this.props.user.firstName !== undefined && this.props.user.firstName !== ''){
      loggedInUser = this.props.user.firstName;
      //fullName = this.props.user.firstName;
    } else {
      loggedInUser = this.props.user.userName;
    }
    if(this.props.user.email !== undefined && this.props.user.email !== "") {
      email = this.props.user.email;
    }

    if(this.props.showUserLink === true){
        changeText = <Anchor id="avatar-change-id" style={{color: "#0073E7"}} label={LocaleUtility.getLocaleMessage("avatar.section.change")} href={USER_UPDATE_FORM_PATH+Utils.buildURLQueryParam()}/>
    }                    

    return (
      <div id={id} onClick={() => this.avatarClicked()} style={popup} className="avtarTooltip">
       <span className="avtarTooltipText">{loggedInUser}</span>
       <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="-288 380 34 34"
        >
        <path
            d="M-271 413.5c-9.098 0-16.5-7.402-16.5-16.5 0-9.098 7.402-16.5 16.5-16.5 9.098 0 16.5 7.402 16.5 16.5 0 9.098-7.402 16.5-16.5 16.5z"
            fill="#FFF"
        />
        <path
            d="M-271 381c8.822 0 16 7.178 16 16s-7.178 16-16 16-16-7.178-16-16 7.178-16 16-16m0-1c-9.39 0-17 7.61-17 17s7.61 17 17 17 17-7.61 17-17-7.61-17-17-17z"
            fill="#AAA"
        />
        <path
            d="M-260.218 405.376c-1.833-.728-7.383-3.51-7.518-3.63-.358-.324-.23-1.266-.188-1.313.77-.872 1.3-2.688 1.64-3.797.043-.126.132-.252.23-.343.733-.66.875-1.726.352-2.56-.07-.112-.118-.264-.123-.396-.02-.99.007-1.466-.04-2.455-.1-2.192-1.432-3.716-3.595-4.116-.515-.096-1.028-.136-1.54-.14-.512.005-1.025.044-1.54.14-2.163.4-3.494 1.924-3.594 4.116-.048.99-.022 1.464-.04 2.455-.006.132-.055.284-.124.397-.523.833-.38 1.898.35 2.56.1.09.19.216.23.342.34 1.11.87 2.925 1.642 3.797.042.047.17.99-.188 1.312-.135.12-5.685 2.903-7.518 3.63"
            fill="none"
            stroke="#AAA"
            strokeMiterlimit="10"
        />
        </svg>
        {this.state.avatarEnabled === 1 && (
          <div
            className="avatarCls"
            onBlur={event => this.divBlured(event)}
            id="tipsDiv"
            tabIndex={0}
            ref={this.tipsDiv}
          >
            <div className="avatar_div1">
              <div>
                    {loggedInUser} <br />
                    {changeText}
                </div>
                  
                <div className="button_wrap_primary">
                    <Button style={{padding:"0 7px"}}
                        label={LocaleUtility.getLocaleMessage("label.login.button.logout")}
                        method="push"
                        primary={true}
                        onClick={event => this.avatarClosed(event)}
                        id="logoutButton"
                    />
                </div>
            </div>
            <div className="avatar_div flex_fw_fdc">
                {/*<tr>
                    <td>
                      {LocaleUtility.getLocaleMessage("avatar.section.name")}
                    </td>
                    <td>
                      {fullName !== undefined && fullName !== null ? fullName : ""}
                    </td>
                </tr>*/}
                  <div>
                    <div>
                      {LocaleUtility.getLocaleMessage("avatar.section.user.name")}
                    </div>
                    <div>
                      {this.props.user.userName !== undefined && this.props.user.userName !== null ? this.props.user.userName : ""}
                    </div>
                  </div>
                  <div>
                    <div>
                      {LocaleUtility.getLocaleMessage("avatar.section.email")}
                    </div>
                    <div>
                      {email !== undefined && email !== null ? email : ""}
                    </div>
                  </div>
              </div>
          </div>
        )}
        {this.state.avatarEnabled === 1 && (
          <div style={divStyle1} id="arrowDiv" ref={this.arrowDiv} />
        )}
      </div>
    );
  }
}

export default Avatar;
