import ReduceStore from "./ReduceStore";
import * as FrontendPagePath from "../utils/FrontendPagePath";
import { setSession, setLead } from "../utils/FetchHelper";
import { setLogout } from "../utils/LicenseUtility";

class AppStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            message: "",
            user: undefined
        };
    }

    reduce(action, state) {
        if (action.type.endsWith("LOGIN_SUCCESS")) {
            //var sessData = {user:action.payload.user.user, responseMap: action.payload.user.responseMap }
            setSession(action.payload.user);
            setLogout(false);
            return { ...state, container: action.payload.user.sessionMap.iscontainer.value,
                    idm: action.payload.user.sessionMap.isidm.value, 
                    sessionMap: action.payload.user.sessionMap,
                    saml: action.payload.user.sessionMap.isSaml.value,
                    user: action.payload.user.user, message: action.payload.message,
                    url: action.payload.user.status, appVersion: action.payload.user.sessionMap.AppVersion.value, 
                 aplsBuildNumber: action.payload.user.sessionMap.AplsBuildNumber.value,
                aplsLicStDate: action.payload.user.sessionMap.AplsLicStDate.value,
            aplsLicExpDate: action.payload.user.sessionMap.AplsLicExpDate.value, setSaml: action.payload.setSaml };
        } else if (action.type.endsWith("IDM_SUCCESS")) {
            //var sessData = {user:action.payload.user.user, responseMap: action.payload.user.responseMap }
            setSession(action.payload.user);
            setLogout(false);
            return { ...state, container: action.payload.user.sessionMap.iscontainer.value,
                    idm: action.payload.user.sessionMap.isidm.value, 
                    sessionMap: action.payload.user.sessionMap,
                    url: action.payload.user.status,
                    user: action.payload.user.user, message: action.payload.message, 
                    appVersion: action.payload.user.sessionMap.AppVersion.value,
                aplsBuildNumber: action.payload.user.sessionMap.AplsBuildNumber.value,
                aplsLicStDate: action.payload.user.sessionMap.AplsLicStDate.value,
            aplsLicExpDate: action.payload.user.sessionMap.AplsLicExpDate.value };
        } else if (action.type.endsWith("LOGIN_FAILURE")) {
            return { ...state, user: undefined, message: action.payload };
        } else if (action.type.endsWith("IDM_REDIRECT")) {
            return { ...state, idm: true};
        } else if (action.type.endsWith("LOGOUT_SUCCESS")) {
            if(action.payload.setSaml)
                return { ...state, user: undefined, url: undefined, 
                    idm: action.payload.idm, status: false, saml: action.payload.saml, setSaml: false };
            else
                return { ...state, user: undefined, url: undefined, 
                    idm: action.payload.idm, status: false, saml: action.payload.saml};  
        } else if (action.type.endsWith("PWD_RESET")) {
            return { ...state, url: FrontendPagePath.RESET_PATH,
            username: action.payload.user.sessionMap.user.value};
        } else if (action.type.endsWith("INDEX_DETAIL")) {
            let idm = undefined;
            if(action.payload.sessionMap.leadPointer !== undefined){
                setLead(action.payload.sessionMap.leadPointer.value);
            }
            if(action.payload.sessionMap.datasource !== undefined && 
                action.payload.sessionMap.datasource.value === 'IDM'){
                    idm = true;
                    if(Object.keys(action.payload.sessionMap).length === 2){
                        return {...state, datasource: action.payload.sessionMap.datasource.value,
                            status: action.payload.sessionMap.isidm.value,
                            idm: idm, user: undefined};
                    }
                }
            return { ...state, datasource: action.payload.sessionMap.datasource.value,
            mail: action.payload.sessionMap.mail !== undefined ? 
                            action.payload.sessionMap.mail.value : undefined, 
            defaultuser: action.payload.sessionMap.defaultusername !== undefined ? 
                            action.payload.sessionMap.defaultusername.value : undefined,
            defaultmail: action.payload.sessionMap.defaultusermailnull !== undefined ? 
                            action.payload.sessionMap.defaultusermailnull.value : undefined,
            servermail: action.payload.sessionMap.servermail !== undefined ? 
                action.payload.sessionMap.servermail.value : undefined, idm: idm,
            saml: action.payload.sessionMap.isSaml !== undefined ? 
                action.payload.sessionMap.isSaml.value : undefined, setSaml: action.payload.setSaml
            };
        } else if (action.type.endsWith("PWD_RESET_SUCCESS")) {
            return { ...state, reset: true, changed: action.payload.changed, error: action.payload.error, tenantReset: action.payload.tenantReset };
        } else if (action.type.endsWith("PWD_FIRSTLOGIN_RESET_SUCCESS")) {
            return { ...state, reset: true, firstlogin: true, changed: action.payload.changed, error: action.payload.error, tenantReset: action.payload.tenantReset };
        } else if (action.type.endsWith("APP_FETCH_EXCEPTION")) {
            return { ...state, reset: true, error: "APP_FETCH_EXCEPTION", user: undefined };
        }  else if (action.type.endsWith("USER_SESSION")) {
            return { ...state, user: action.payload.user, url: action.payload.url, setSaml: action.payload.setSaml };
        } else if (action.type.endsWith("TENANT_NOT_FOUND")) {
            return { ...state, actionType: "TENANT_NOT_FOUND"};
        } 
        return state;
    }
}

export default new AppStore();