import React, { Component } from "react";
import {createHead, createFilter} from "./PivotTableUtils";

let move = false;
const buildResizableColumn = function(col, colResizer) {
  let xMousePt = 0;
  let colWidth = 0;
  let minColWidth = 0;
  let cursor;

  const mouseDownHandler = function(e) {
    xMousePt = e.clientX; //Current Mouse Position
    const styles = window.getComputedStyle(col);
    colWidth = parseInt(styles.width, 10);
    minColWidth = parseInt(styles.minWidth, 10);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function(e) {
      move = true;
      const updatedXPosition = e.clientX - xMousePt; //Difference of width
      cursor = e.target;
      if(cursor.className === "pivot-table-row-header")
        cursor.style.cursor = "col-resize";
      if((updatedXPosition < 0 && Number(colWidth + updatedXPosition) > minColWidth) ||
            updatedXPosition > 0){
        col.style.width = `${colWidth + updatedXPosition}px`; //Update width
      }
  };

  // Remove event listeners
  const mouseUpHandler = function() {
      move = false;
      const cols = document.getElementsByClassName('pivot-table-row-header');
      [].forEach.call(cols, function(col) {
        col.style.cursor = "pointer";
      });
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
  };

  colResizer.addEventListener('mousedown', mouseDownHandler);
};

class PivotTableHeader extends Component {

  constructor(props) {
    super(props);
    this.buildColumnResizer = this.buildColumnResizer.bind(this);
  }

  componentDidMount(){
    this.buildColumnResizer();
  }

  buildColumnResizer(){
    const table = document.getElementById(this.props.id);
    const head = table.querySelectorAll('thead')[0];
    const cols = head.querySelectorAll('th');

    let index=0, itm, temp;
    [].forEach.call(cols, function(col) {
        if(col.className === 'colResizer'){
          if(temp)
            buildResizableColumn(temp, col);
        } else
          temp = col;  
    });
  }

  getTableHead = (header, rowSelection, sortIndex, sortAscending, 
    sortCallbackMethod, isSelectAllClicked,onChangeCheckBoxCallback, selectedCheckBoxKey, childKey, pivot, reportMeta, handleContextMenu) => {
   return createHead(header, rowSelection, sortIndex, sortAscending, 
    sortCallbackMethod, isSelectAllClicked, onChangeCheckBoxCallback, selectedCheckBoxKey, childKey, pivot, reportMeta, handleContextMenu);
  };

  getTableFilter = (header, rowSelection, defaultFilter, filterCallbackMethod) => {
    return createFilter(header, rowSelection, defaultFilter, filterCallbackMethod);
  };

  render() {
    return (
      <thead>
        <tr style={{borderTop: "1px solid #cccccc", borderLeft: "1px solid #cccccc", borderRight: "1px solid #cccccc"}}>
          {this.getTableHead(
            this.props.header,
            this.props.rowSelection,
            this.props.sortIndex,
            this.props.sortAscending,
            this.props.onSort,
            this.props.isSelectAllClicked,
            this.props.onChangeCheckBoxCallback,
            this.props.selectedCheckBoxKey,
            this.props.childKey,
            this.props.pivot,
            this.props.reportMeta,
            this.props.handleContextMenu
          )}
        </tr>
        <tr style={{borderLeft: "1px solid #cccccc", borderRight: "1px solid #cccccc"}}>
          {this.getTableFilter(
            this.props.header,
            this.props.rowSelection,
            this.props.setDefaultFilter,
            this.props.onFilter
          )}
        </tr>
      </thead>
    );
  }
}

export default PivotTableHeader;
