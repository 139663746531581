import ReduceStore from "./ReduceStore";

class GenericUsageStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            message: "",
            startSpin: false
        };
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_USAGE_RESULTS": {
                return {
                    usageList: action.payload.usageList,
                    usageHeaders: action.payload.usageHeaders,
                    headers: action.payload.headers,
                    attFilterOptions: action.payload.attFilterOptions,
                    fieldMap: action.payload.fieldMap,
                    repAggr: action.payload.repAggr,
                    sortBy: action.payload.sortBy,
                    type: action.payload.showFields
                };
            } case "POPULATE_USAGE_REPORT": {
                return {
                    reportOptions: action.payload.reportOptions,
                    reportFieldMap: action.payload.fieldMap,
                    type: action.payload.showFields
                };
            } case "FIELD_VALUE_FETCH_COMPLETE": {
                return {
                    fieldVal: action.payload.fieldVal,
                    type: 'fieldName'
                };
            } case "REPORT_CREATE_FAILURE": {
                return {
                    showModal: action.payload.showModal,
                    type: "reportUpdate"
                }
            } case "USAGE_FEATURE_ID": {
                return {
                    featureid: action.payload.featureid,
                    type:"featureIDlist"
                }
            } case "USAGE_TENANT_LIST": {
                return {
                    tenantList: action.payload.tenantList,
                    type:"tenantlist"
                }
            }
            default:
                return state;
        }
    }
}

export default new GenericUsageStore();