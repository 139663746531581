import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Button } from "grommet/components/Button";
import "../../css/index.css";
import * as LocaleUtility from "../../utils/LocaleUtility";
import {pwdValidation} from "../../utils/ValidationUtility";
import * as Utils from "../../utils/Util";
import { checkLoginStatus, resetLogin} from "../../utils/FetchHelper";
import { APLS_WS_PWD_CHANGE, APLS_WS_USER_PWD_RESET } from "../../utils/RestWebServicePaths";
import Dispatcher from "../../flux/Dispatcher";
import SpinningNotification from '../generic/SpinningNotification';
import ComponentNotification from "../generic/ComponentNotification";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class ResetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorcode: [],
        }
        this.validateTextInput = this.validateTextInput.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    validateTextInput(event){

        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });

        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)

        if (event.target.value === '') {
            if (index === -1) {
                errors.push(event.target.name);
            }
            this.setState({errorcode: errors});
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            
            if(event.target.form.elements.newpassword.value.length > 0 ){
                if(event.target.form.elements.newpassword.value.length < 8 ){
                    index = errors.indexOf('invalidLenghtNewPwd')
                    if (index === -1) {
                        errors.push('invalidLenghtNewPwd');
                    }
                } else if(!pwdValidation(event.target.form.elements.newpassword.value)){
                    index = errors.indexOf('invalidLenghtNewPwd')
                    if (index !== -1)
                        errors.splice(index, 1);
                    index = errors.indexOf('invalidNewPwd')
                    if (index === -1) {
                        errors.push('invalidNewPwd');
                    }
                } else {
                    index = errors.indexOf('invalidLenghtNewPwd')
                    errors.splice(index, 1);
                    index = errors.indexOf('invalidNewPwd')
                    errors.splice(index, 1);
                }
            } 
           
            if((event.target.name === 'password' || event.target.name === 'newpassword'
                || event.target.name === 'repassword')){
                if(this.props.id === undefined){
                    index = errors.indexOf('invalidOldNew')
                    if((event.target.form.elements.password.value.length > 0 && 
                        event.target.form.elements.newpassword.value.length > 0) &&
                        event.target.form.elements.password.value === event.target.form.elements.newpassword.value ){
                        if(index === -1)
                            errors.push('invalidOldNew');
                    } else {
                        if (index !== -1) {
                            errors.splice(index, 1);
                        }
                    }
                }

                index = errors.indexOf('confirmInvalid')
                if((event.target.form.elements.repassword.value.length > 0 && 
                    event.target.form.elements.newpassword.value.length > 0) &&
                    event.target.form.elements.repassword.value !== event.target.form.elements.newpassword.value ){
                    if(index === -1)
                        errors.push('confirmInvalid');
                }else {
                    if (index !== -1) {
                        errors.splice(index, 1);
                    }
                }
            }

            this.setState({errorcode: errors});
        }
    }
    
    formSubmit(event){
        event.preventDefault();
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });

        var elements = event.target.elements;

        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(this.props.id === undefined){
            if(elements.password.value === ''){
                let index = errors.indexOf(elements.password.name)
                if (index === -1) {
                    errors.push(elements.password.name);
                }
                flag = false;
            }
        }

        if(elements.newpassword.value === ''){
            let index = errors.indexOf(elements.newpassword.name)
            if (index === -1) {
                errors.push(elements.newpassword.name);
            }
            flag = false;
        }

        if(elements.repassword.value === ''){
            let index = errors.indexOf(elements.repassword.name)
            if (index === -1) {
                errors.push(elements.repassword.name);
            }
            flag = false;
        }

        if(flag === true && errors.length === 0){
            this.setState({errorcode: []});
            let loadSpinAction = "SIGN_IN_LOADING_SPIN";
            let tid = Utils.findTidFn(this.props.location);
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.please.wait")
            });

            var loginObj;
            if(this.props.id === undefined){
                loginObj = { "username": this.props.user, 
                             "password": elements.password.value,
                             "resetpwd": elements.repassword.value }
            } else {
                loginObj = { "username": this.props.id, 
                             "password": elements.repassword.value}
            }

            if(this.props.id === undefined)
                checkLoginStatus(loginObj, APLS_WS_PWD_CHANGE, tid, false);
            if(this.props.id !== undefined) {
                let rtid = "";
                if(this.props.tid !== undefined && this.props.tid !== '' && this.props.tid !== null && this.props.tid !== 'null') {
                    rtid = this.props.tid;
                }
                resetLogin(loginObj, APLS_WS_USER_PWD_RESET, rtid, this.props.tenantReset); 
            }
        } else {
            this.setState({errorcode: errors});
        }
    }

    infoSectionContent() {
    let pwdFormFieldError, newpwdFormFieldError, repwdFormFieldError,
        pwdError, newpwdError, repwdError, styleField1 = { width: '30em'}, styleField2 = { width: '30em'};

    if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
        if(this.state.errorcode.indexOf('password') !== -1){
            pwdError =<span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
            pwdFormFieldError='grommetux-form-field--error';
        } 
        if(this.state.errorcode.indexOf('repassword') !== -1){
            repwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
            repwdFormFieldError = 'grommetux-form-field--error';
        }
        if(this.state.errorcode.indexOf('newpassword') !== -1){
            newpwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.input.field.alert")}</span>;
            newpwdFormFieldError = 'grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidLenghtNewPwd') !== -1){
            newpwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert4")}</span>;
            newpwdFormFieldError = 'grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidNewPwd') !== -1){
            newpwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert7")}</span>;
            newpwdFormFieldError = 'grommetux-form-field--error';
            styleField1 = { width: '30em' , minHeight: '95px'}
        } 
        if(this.state.errorcode.indexOf('invalidRePwd') !== -1){
            newpwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert7")}</span>;
            newpwdFormFieldError = 'grommetux-form-field--error';
            styleField2 = { width: '30em' , minHeight: '95px'}
        } 
        if(this.state.errorcode.indexOf('confirmInvalid') !== -1){
            repwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert5")}</span>;
            repwdFormFieldError = 'grommetux-form-field--error';
            styleField2 = { width: '30em' , minHeight: '95px'}
        }
        if(this.state.errorcode.indexOf('invalidOldNew') !== -1){
            newpwdError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.pwd.form.alert6")}</span>;
            newpwdFormFieldError = 'grommetux-form-field--error';
        }

    } else {
        pwdFormFieldError = '';
        newpwdFormFieldError = '';
        repwdFormFieldError = '';
        pwdError = '';
        newpwdError = '';
        repwdError = '';
    }
    let btnLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.change.password");
    let popTitle = LocaleUtility.getLocaleLabel(this.context.intl, "label.reset.password.title");
    if(this.props.tenantReset === "Y") {
        btnLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.create.password");
        popTitle = LocaleUtility.getLocaleLabel(this.context.intl, "label.create.password.title");
    }

    return (
    <div id="modalDiv" className="opacquemodal">
        <div className="modal-main panel panel-default" style={{minWidth: 0, width: "40%"}}>
            <div className="panel-heading">
                <h2 id="modal_title" className="panel-title">{popTitle}</h2>
            </div>
            <ComponentNotification />
            <Form id="reset_formDetail"compact={false} pad="small" plain={true} onSubmit={this.formSubmit}>
                <div id="modal_content" className="panel-body">
                    <section id="reset_formSec" pad="none" wrap={false} responsive={false} justify="center">
                        {this.props.id === undefined && (
                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.oldpassword")} 
                                className={pwdFormFieldError}>
                                <input id='reset_password' autoFocus={true} type="password" name='password' onChange={this.validateTextInput} />
                                {pwdError}
                            </FormField>
                        )}
                        <FormField style={styleField1} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.newpassword")} 
                            className={newpwdFormFieldError}>
                            <input id='reset_newpassword' type="password" name='newpassword' onChange={this.validateTextInput} />
                            {newpwdError}
                        </FormField>
                        <FormField style={styleField2} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.form.text.reenterpassword")} 
                            className={repwdFormFieldError}>
                            <input id='reset_repassword' type="password" name='repassword' onChange={this.validateTextInput} />
                            {repwdError}
                        </FormField>
                    </section>
                </div>
                <div className="panel-footer panel-button-footer">
                    <div className="button_wrap_primary">
                    <Button id="modal_Btn" label={btnLabel} 
                     type="submit" plain={false} primary={true}></Button></div>
                </div>
            </Form>
            </div>
            <div>
                <SpinningNotification centerPage={true} />
            </div>
        </div>
        );
    }

    render() {
        return (
             this.infoSectionContent()
        );
    }
}

// ResetForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ResetForm;