
import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtil from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import UsageHubConfigurationStore from "../../../flux/UsageHubConfigurationStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { CheckBox } from "grommet/components/CheckBox";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

let initialState = null;
class UsageHubConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormDirty: false,
            usageHubHostValue: "",
            usageHubPortValue: "",
            enableUsageHubCredChecked: false,
            usageHubUserNameValue: "",
            usageHubPasswordValue: "",
            disableDataUsageReportChecked: false,
            usageHubCredentials: "",
            usageEnableReport: "",
            usageSyncIntervalValue: "",
            userNameDisabled: true,
            passwordDisabled: true
        };
        this.findLabel = this.findLabel.bind(this);
        this.submitUsageHubConfigSettings = this.submitUsageHubConfigSettings.bind(this);
        this.cancelUsageHubConfigSettings = this.cancelUsageHubConfigSettings.bind(this);
        this.submitTestUsageHubConnection = this.submitTestUsageHubConnection.bind(this);
        this.makeFormDirty = this.makeFormDirty.bind(this);
        this.refreshFormData = this.refreshFormData.bind(this);
        this.handleChangeHubHost = this.handleChangeHubHost.bind(this);
        this.changeHubHost = this.changeHubHost.bind(this);
        this.handleChangeHubPort = this.handleChangeHubPort.bind(this);
        this.changeHubPort = this.changeHubPort.bind(this);
        this.handleChangeHubCredentials = this.handleChangeHubCredentials.bind(this);
        this.changeHubCredentials = this.changeHubCredentials.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.changeUserName = this.changeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleChangeDataUsageReport = this.handleChangeDataUsageReport.bind(this);
        this.changeDataUsageReport = this.changeDataUsageReport.bind(this);
        this.handleChangeSyncInterval = this.handleChangeSyncInterval.bind(this);
        this.changeSyncInterval = this.changeSyncInterval.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    handleChangeHubHost(event) {
        this.makeFormDirty();
        this.changeHubHost(event.target.value);
    }
    changeHubHost(val) {
        this.setState({usageHubHostValue: val});
    }
    handleChangeHubPort(event) {
        this.makeFormDirty();
        this.changeHubPort(event.target.value);
    }
    changeHubPort(val) {
        this.setState({usageHubPortValue: val});
    }
    handleChangeHubCredentials(event) {
        this.makeFormDirty();
        this.changeHubCredentials(event.target.checked);
    }
    changeHubCredentials(val) {
        if(val) {
            this.setState({enableUsageHubCredChecked: true});
            this.setState({usageHubCredentials: 'true'});
            this.setState({userNameDisabled: false});
            this.setState({passwordDisabled: false});
        } else {
            this.setState({usageHubCredentials: 'false'});
            this.setState({enableUsageHubCredChecked: false});
            this.setState({userNameDisabled: true});
            this.setState({passwordDisabled: true});
        }
    }
    handleChangeUserName(event) {
        this.makeFormDirty();
        this.changeUserName(event.target.value);
    }
    changeUserName(val) {
        this.setState({usageHubUserNameValue: val});
    }
    handleChangePassword(event) {
        this.makeFormDirty();
        this.changePassword(event.target.value);
    }
    changePassword(val) {
        this.setState({usageHubPasswordValue: val});
    }
    handleChangeDataUsageReport(event) {
        this.makeFormDirty();
        this.changeDataUsageReport(event.target.checked);
    }
    changeDataUsageReport(val) {
        if(val) {
            this.setState({disableDataUsageReportChecked: true});
            this.setState({usageEnableReport: 'true'});
        } else {
            this.setState({usageEnableReport: 'false'});
            this.setState({disableDataUsageReportChecked: false});
        }
    }
    handleChangeSyncInterval(event) {
        this.makeFormDirty();
        this.changeSyncInterval(event.target.value);
    }
    changeSyncInterval(val) {
        this.setState({usageSyncIntervalValue: val});
    }
    makeFormDirty() {
        this.setState({isFormDirty: true});
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        FetchHelper.findUsageHubConfiguration(this.context.intl, "");
        UsageHubConfigurationStore.on("change", this.refreshFormData);
    }
    componentWillUnmount() {
        UsageHubConfigurationStore.removeListener("change", this.refreshFormData);
    }

    onConfirmSubmit(flag){
        if(this.state.isFormDirty && flag === 1){
            FetchHelper.saveUsageHubConfiguration(this.state, this.context.intl);
        }
    }
    submitUsageHubConfigSettings() {
        ValidationUtil.confirm(
            this.findLabel("configUsageHugWarn", this.context.intl),
            this.onConfirmSubmit, undefined, "", "");
    }
    cancelUsageHubConfigSettings() {
        if(initialState) {
            this.setState(initialState);
            this.refreshFormData();
        }
    }
    submitTestUsageHubConnection() {
        FetchHelper.testUsageHubConnection(this.state, this.context.intl);
    }
    refreshFormData() {
        let usageHubPort = UsageHubConfigurationStore.getState().usageHubPort;
        let analyticusageflag = UsageHubConfigurationStore.getState().analyticusageflag;
        let allowcredentialFlag = UsageHubConfigurationStore.getState().allowcredentialFlag;
        let usageIP = UsageHubConfigurationStore.getState().usageIP;
        let usageinterval = UsageHubConfigurationStore.getState().usageinterval;
        let usagehubUserName = UsageHubConfigurationStore.getState().usagehubUserName;
        this.changeHubPort(usageHubPort);
        this.changeHubHost(usageIP);
        this.changeUserName(usagehubUserName);
        if(allowcredentialFlag === "false") {
            this.changeHubCredentials(false);
        } else {
            this.changeHubCredentials(true);
        }
        if(analyticusageflag === "false") {
            this.changeDataUsageReport(false);
        } else {
            this.changeDataUsageReport(true);
        }
        this.changeSyncInterval(usageinterval);
        this.setState({isFormDirty: false});
        ConfigurationUtils.hideLoadMask(this.context.intl);
        initialState = this.state;
    }
    render() {
        //let intl = this.context.intl;
        let disableReportSection = 'disanblePurgeType';
        if(this.state.usageEnableReport === 'false') {
            disableReportSection = 'enablePurgeType';
        }
        let disClassName = "enabledTextField";
        if(this.state.userNameDisabled) {
            disClassName = "disabledTextField";
        }
        return(
            <div className="config-cont-backgroud">
                <div>
                    <SectionWithTitle title={this.findLabel("configpageUHtitle")}  helpTopic="Usage_Hub"/>
                    <section pad="none" wrap={false} responsive={false} justify="center">
                        <Form compact={false} id="conf_uh_formId" pad="small" plain={true}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <FormField style={{ width: '40em' }} label= {this.findLabel("configpageUHHostname")} >
                                            <TextInput id="conf_uh_HostNameId" value={this.state.usageHubHostValue} onChange={event =>this.handleChangeHubHost(event)}/>
                                        </FormField>
                                    </td>
                                    <td>
                                        : 
                                    </td>
                                    <td style={{paddingLeft: "5px"}}>
                                        <FormField style={{ width: '10em' }} >
                                            <TextInput id="conf_uh_PortId" value={this.state.usageHubPortValue} onChange={event =>this.handleChangeHubPort(event)}/>
                                        </FormField>
                                    </td>
                                    <td>
                                        <Button id="conf_uh_TectConnBtnId" label={this.findLabel("configpageLDAPTestConnection")} plain={false} primary={true} onClick={this.submitTestUsageHubConnection} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <FormField style={{ width: '60em' }}  >
                                <CheckBox id="conf_uh_enableCredentailsId" 
                                    checked={this.state.enableUsageHubCredChecked} 
                                    onChange={this.handleChangeHubCredentials} 
                                    label={this.findLabel("configpageUHUserCredentials")}/>
                            </FormField>
                            <span style={{paddingLeft: "15px", fontSize:"13px"}}>{this.findLabel("configpageUHUserCredentialsCap")}</span>
                            <FormField style={{ width: '40em', }} label= {this.findLabel("configpageUHUserName")} >
                                <TextInput id="conf_uh_UserNameId" className={disClassName} disabled={this.state.userNameDisabled} value={this.state.usageHubUserNameValue} onChange={event =>this.handleChangeUserName(event)}/>
                            </FormField>
                            <FormField style={{ width: '40em' }} label= {this.findLabel("configpageUHPassword")} >
                                <Password id="conf_uh_PasswordId" className={disClassName} disabled={this.state.passwordDisabled} onChange={this.handleChangePassword}/>
                            </FormField>
                            <FormField style={{ width: '60em' }} >
                                <CheckBox id="conf_uh_disableDataUsageReportId" 
                                    checked={this.state.disableDataUsageReportChecked} 
                                    onChange={this.handleChangeDataUsageReport} 
                                    label={this.findLabel("configpageUHDisableUsageData")}/>
                            </FormField>
                            <div className={disableReportSection} >
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={{paddingLeft: "24px"}} className="usage-hub-sync-field">
                                            {this.findLabel("configpageUHDataSync")}
                                        </td>
                                        <td>
                                            <TextInput style={{width: "22rem"}} id="conf_uh_dataSyncIntervalId" value={this.state.usageSyncIntervalValue} onChange={event =>this.handleChangeSyncInterval(event)}/>
                                        </td>
                                        <td>
                                            {this.findLabel("configpageUHMinutes")}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="saveBtnStyle button_left_wrap">
                                <div className="button_wrap_primary">
                                <Button id="conf_uh_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitUsageHubConfigSettings} />
                                </div>
                                <span style={{padding: "5px"}}></span>
                                <div className="button_wrap_negative"> 
                                <Button id="conf_uh_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelUsageHubConfigSettings} />
                                </div>
                            </div>
                        </Form>
                    </section>
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        );
    }
}
// UsageHubConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageHubConfiguration;