import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";
import Store from "../../../flux/APTableStore";
import APSelect from "../../commons/APLSSelect";
import Dispatcher from "../../../flux/Dispatcher";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as Utils from "../../../utils/Util";
import SpinningNotification from '../../generic/SpinningNotification';
import {validateEmail, isNumber, isAlphaNumeric, alphanumericAndSpaceCheck} from "../../../utils/ValidationUtility";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Select } from 'grommet/components/Select';
import { Anchor } from 'grommet/components/Anchor';
import { TextInput } from 'grommet/components/TextInput';
import { RadioButton } from 'grommet/components/RadioButton';
import { Button } from "grommet/components/Button";

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class Buymore extends Component {
    constructor(props) {
      super(props);
      this.state = {
        count: 1, 
        secondCount: 1,
        rowData: [],
        resStatus: "",
        sortIndex: Store.getState().sortIndex,
        sortAscending: Store.getState().sortAscending,
        selectAllRows: false,
        productNameSelected: undefined,
        productNameList: [],
        selectedRowKeys:"",
        additionalQtyList:[],
        buyMore:true,
        buyMoreResp:false,
        productMap:[],
        productList:[],
        stateOptions:[],
        country: LocaleUtility.getLocaleCountry("United States"), 
        emailChecked:true,
        prefferedContact: 'email',
        onLine : false,
        pagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize()
        },
    
    };

    this.refreshState = this.refreshState.bind(this);
    this.filterBuyMoreList = this.filterBuyMoreList.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
    this.filterStateList = this.filterStateList.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
}

     componentDidMount() {
      let loadSpinAction = "PRODUCT_FETCH_INPROGRESS_LOADING_SPIN";
      Dispatcher.dispatch({
          type: loadSpinAction,
          payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
      });
      const formPayload = {
            productName:null
       };
      FetchHelper.buyMoreLicenses(formPayload);
      Store.on("change", this.refreshState);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.refreshState);
    }

    refreshState() {
        let regions = LocaleUtility.getRegions('United States')
        this.setState({
            email: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            countrySelected: LocaleUtility.getLocaleCountry("United States"), 
            region: 'Alabama' ,
            emailChecked:true,
            prefferedContact: 'email',
            productMap: [],
            additionalQtyList: [],
            stateOptions: regions,
            regionSelected: {label: regions[0].value, value: regions[0].label}
        });
        if(Store.getState().licenseData !== undefined){
            Store.getState().licenseData.map((item, index) => {
                item.aditionalQuantity=<input style={{ width: '100%' }} type="text" 
                id={item.productNumber} value={this.state.additionalQtyList[index]} onChange= {this.handleQuantityChange.bind(this, index)} />
                return "";
            });
            this.setState({
                buyMore:true,
                buyMoreResp:false,
                rowData: Store.getState().licenseData,
                sortIndex: Store.getState().sortIndex,
                sortAscending: Store.getState().sortAscending,
                productNameList: Store.getState().productNameList,
                productMap : Store.getState.productMap
            });
        } else if(Store.getState().tableData !== undefined){
            this.setState({
                buyMore:false,
                buyMoreResp:true,
                defaultMail: Store.getState().defaultMail,
                rowData: Store.getState().tableData,
                sortIndex: Store.getState().sortIndex,
                sortAscending: Store.getState().sortAscending,
                resStatus: Store.getState().resStatus
            });
        }

        if(Store.getState().mailServerConfigured !== undefined){
                this.setState({ onLine : Store.getState().mailServerConfigured
            });
        }
        
        Dispatcher.dispatch({
            type: "LOADING_SPIN_DONE",
            payload: ''
        });
    }
  
    callback1(loca, hist) {
      if (this.routerLocation.pathname === "/sublink2") {
        this.setState({ activeIndex: 1 });
      }
    }

    filterBuyMoreList(event){
        this.setState({
            productNameSelected:event.option.value,
           
        });
         const formPayload = {
            productName:event.option.value
       };
        FetchHelper.buyMoreLicenses(formPayload);
    }

    handleEmailChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('businessEmail');
        if (event.target.value === ''){
            if (index === -1)
                errors.push('businessEmail');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }

            index = errors.indexOf('invalidLength')
            if(event.target.name === 'businessEmail' && 
                    !(event.target.value.length > 5 &&  event.target.value.length < 64)){
                if (index === -1) {
                    errors.push('invalidLength');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            index = errors.indexOf('invalidEmail')
            if(event.target.name === 'businessEmail' && 
                !validateEmail(event.target.value) ){
                if (index === -1) {
                    errors.push('invalidEmail');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
        }

        this.setState({errorcode: errors});
        this.setState({ email: event.target.value });
    }
    handlePhoneNumberChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('businessPhone');
        if (event.target.value !== '' && !isNumber(event.target.value) && isAlphaNumeric(event.target.value)){
            if (index === -1)
                errors.push('businessPhone');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
        this.setState({ phoneNumber: event.target.value });
    }

    handleFirstNameChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('firstName');
        if (event.target.value !== '' && isNumber(event.target.value)){
            if (index === -1)
                errors.push('firstName');
        } else if (event.target.value !== '' && !alphanumericAndSpaceCheck(event.target.value)){
            if (index === -1)
                errors.push('invalidFName');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
        this.setState({ firstName: event.target.value });
    }
    handleLastNameChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('lastName');
        if (event.target.value !== '' && isNumber(event.target.value)){
            if (index === -1)
                errors.push('lastName');
        } else if (event.target.value !== '' && !alphanumericAndSpaceCheck(event.target.value)){
            if (index === -1)
                errors.push('invalidLName');
        } else{
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
        this.setState({ lastName: event.target.value });
    }

    handleChangeEmail(e){
        this.setState({ prefferedContact: "email",
                        phoneNumberChecked:false,
                        emailChecked:true});
        //this.state.emailChecked
    }

    handleChangePhoneNumber(e){
        this.setState({ prefferedContact: "phoneNumber",
                        emailChecked:false,
                        phoneNumberChecked:true
                    });
    }

    handleQuantityChange(index, e){
        if(e.target.value !== '' && isNaN(parseInt(e.target.value, 10))){
           e.currentTarget.value="";  
            //alert(LocaleUtility.getLocaleMessage("buyMore.alert1"));
            Dispatcher.dispatch({
                    type: "ALERT_SHOW_MODAL",
                    payload: LocaleUtility.getLocaleLabel(this.context.intl, "buyMore.alert1")
            });
        }else if(e.target.value === ''){
            e.currentTarget.value="";
        }else{
            e.currentTarget.value=parseInt(e.target.value, 10);
        }
        const additionalQtyList = Object.assign([], this.state.additionalQtyList);
        const products = Object.assign([], this.state.productMap);
        var found = products.find(function(element) { 
                        return (element.productNumber === e.target.id); 
                    }); 
        if(found){
            let index = products.indexOf(e.target.id);
            products.splice(index, 1);
            index = additionalQtyList.indexOf(e.target.id);
            additionalQtyList.splice(index, 1);
            additionalQtyList[index] = e.target.value;
            products.push({
                productNumber: e.target.id,
                requestedCapacity: parseInt(e.target.value, 10)
            });
        } else {
            additionalQtyList[index] = e.target.value;
            products.push({
                productNumber: e.target.id,
                requestedCapacity: parseInt(e.target.value, 10)
            });
        }

        this.setState({productMap: products, additionalQtyList: additionalQtyList});
        
    }

    handleSubmitButton(){

        const errors = Object.assign([], this.state.errorcode);
        var flag = true, success= false;
        if(errors.length > 0){
            flag = false;
        }
        let alrtMessage = "";
        if(this.state.productMap === undefined || this.state.productMap.length === 0){
            //alert(LocaleUtility.getLocaleMessage("buyMore.alert3"));
            alrtMessage = LocaleUtility.getLocaleMessage("buyMore.alert3");
        } else if(this.state.prefferedContact === 'email' && 
                    (this.state.email === undefined || this.state.email === "")){
            //alert(LocaleUtility.getLocaleMessage("buyMore.alert4"));
            alrtMessage = LocaleUtility.getLocaleMessage("buyMore.alert4");
        } else if(this.state.prefferedContact === 'phoneNumber' && 
                    (this.state.phoneNumber === undefined || this.state.phoneNumber === "")){
            //alert(LocaleUtility.getLocaleMessage("buyMore.alert5"));
            alrtMessage = LocaleUtility.getLocaleMessage("buyMore.alert5");
        } else {
            success = true;
        }
        if(success !== true) {
            Dispatcher.dispatch({
                    type: "ALERT_SHOW_MODAL",
                    payload: alrtMessage
            });
        }

        if(flag === true && success === true){
            const formPayload = {
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                email:this.state.email,
                prefferedContact:this.state.prefferedContact,
                phoneNumber:this.state.phoneNumber,
                country:this.state.country,
                state:this.state.region,
                // productList:this.state.productList,
                // additionalQtyList:this.state.additionalQtyList
                requestBuymoreItems: this.state.productMap
            };

            Dispatcher.dispatch({
                type: "UPDATE_QTY_REQ_LOADING_SPIN",
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            FetchHelper.updateLicense(formPayload);

        } else {
            if(flag === false){
                Dispatcher.dispatch({
                    type: "USER_UPDATE_ERROR",
                    payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
                });
                this.setState({errorcode: errors});
            }
        }
    }

    selectCountry (val) {
        this.setState({ country: val });
    }
    
    selectRegion (event) {
        if(event.option.value !== undefined){
            this.setState({ region: event.option.value, 
                regionSelected: {value:event.option.value, label:event.option.label}});
        }
    }

    filterStateList(event){
        if(event.option.value !== undefined){
            let regions = LocaleUtility.getRegions(event.option.value);
            this.setState({stateOptions:regions, 
                countrySelected:{value:event.option.value, label:event.option.label}, 
                regionSelected: {value:regions[0].value, label:regions[0].label},
                country: event.option.value,
                region: regions[0].key
            });
        }
    }

 sectionContent() {
    const showHideBuyMore = this.state.buyMore ? "display-nonflex" : "display-none";
    const options = []; 
    let selectedValue = {value:undefined, label:LocaleUtility.getLocaleLabel(this.context.intl, "label.select.all")};
    options.push(selectedValue);

    var entries = this.state.productNameList.entries()
    for(var item of entries){
        options.push({value:item[0], label:item[1]})
        if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
            selectedValue = {value:item[0], label:item[1]};
        }
    }

    let phoneFormFieldError, emailFormFieldError, fNFormFieldError, lNFormFieldError, phoneError, emailError, fNError, lNError;

     if(this.state.errorcode !== undefined &&
        this.state.errorcode.length > 0){
        if(this.state.errorcode.indexOf('email') !== -1){
            emailError =<span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("label.input.field.alert")}</span>;
            emailFormFieldError='buymoreError grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidLength') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert5")}</span>;
            emailFormFieldError = 'buymoreError grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidEmail') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert2")}</span>;
            emailFormFieldError = 'buymoreError grommetux-form-field--error';
        }
        if(this.state.errorcode.indexOf('lastName') !== -1){
            lNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert4")}</span>;
            lNFormFieldError = 'buymoreError grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidLName') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
            emailFormFieldError = 'buymoreError grommetux-form-field--error';
        } 
        if(this.state.errorcode.indexOf('firstName') !== -1){
            fNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert3")}</span>;
            fNFormFieldError = 'buymoreError grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidFName') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
            emailFormFieldError = 'buymoreError grommetux-form-field--error';
        }
        if(this.state.errorcode.indexOf('businessPhone') !== -1){
            phoneError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("buyMore.alert2")}</span>;
            phoneFormFieldError = 'buymoreError grommetux-form-field--error';
        }
    } else {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        phoneFormFieldError = '';
        emailFormFieldError = '';
        fNFormFieldError = '';
        lNFormFieldError = '';
        phoneError = '';
        emailError = '';
        fNError = '';
        lNError = '';
    }

    let countryOptions = LocaleUtility.getCountries();

   return(
    
    <div className={showHideBuyMore}>
    <SectionWithTitle title={LocaleUtility.getLocaleMessage("label.request.more.capacity")} helpTopic="buymore_capacity"/>
    <h2 className="buymore_header">
        {LocaleUtility.getLocaleMessage("label.request.more.capacity")} 
    </h2>
    <div className="separator"></div>
     {/* <Form compact={false} pad="small" plain={true}  > */}
          {/*<FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.select.product")} >
              <Select id="productList" options={options} value={selectedValue} onChange={this.filterBuyMoreList}/>
          </FormField>*/}
          <div className="action_filter_wrapper">
          <APSelect id="productList" label={LocaleUtility.getLocaleMessage("label.select.product")+": "} options={options} value={selectedValue} onChange={this.filterBuyMoreList}/>
          </div>
          {/* <FormField> */}
            <div className="border_white_backg_shadow">
              <div className="updateUserDiv">
                  <TableGrid
                                        header={[
                                            {
                                                columnName: "productNumber",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.prodNumber"),
                                                width: "auto",
                                                columnKey: "productNumber",
                                            },
                                            {
                                                columnName: "productDesc",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.product.desc"),
                                                href: false,
                                                width: "auto",
                                            },
                                            {
                                                columnName: "availableLTU",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.ltu.qty"),
                                                href: false,
                                                width: "auto",
                                            },
                                            {
                                                columnName: "aditionalQuantity",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.add.more"),
                                                href: false,
                                                width: "auto",
                                            }
                                        ]}
                                        rowData={this.state.rowData}
                                        pagination={this.state.pagination}
                                        isExportRequired={false}
                                        onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        onPagination={this.onPaginationClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        rowKey="productNumber"
                                        onChangeCheckBoxCallback={this.onRowClicked}
                                        selectAllRows={false}
                                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                    />
              </div>
              <div className ="updateUserDiv">
                <div className="buymore_right_wrap">
                        <div className="flex_parent_2">
                            <div><label>{LocaleUtility.getLocaleMessage("label.first.name")}</label><FormField className={fNFormFieldError}>
                                <TextInput id='firstName' name='firstName' style={{ width: "100%" }}  value={this.state.firstName}  onChange={event =>this.handleFirstNameChange(event)} />
                                {fNError}
                                </FormField></div>
                            <div><label>{LocaleUtility.getLocaleMessage("label.last.name")} </label><FormField className={lNFormFieldError}>
                                <TextInput id='lastName' name='lastName' style={{ width: "100%"}} value={this.state.lastName}  onChange={event =>this.handleLastNameChange(event)} />
                                {lNError}
                                </FormField></div>
                        </div>
                        <div className="flex_parent_3">
                            <div><label>{LocaleUtility.getLocaleMessage("label.preferred.contact")} :</label></div>
                                <div><FormField  >
                                        <RadioButton id="buy_email" checked={this.state.emailChecked} 
                                        onChange={this.handleChangeEmail} label={LocaleUtility.getLocaleMessage("label.Email")}/>
                                    </FormField></div>
                                <div><FormField >
                                        <RadioButton id="buy_phone" checked={this.state.phoneNumberChecked} 
                                        onClick={this.handleChangePhoneNumber} label={LocaleUtility.getLocaleMessage("label.Phone")}/>
                                    </FormField>
                                    </div>
                            </div>
                        <div className="flex_parent_2">
                            <div><label>{LocaleUtility.getLocaleMessage("label.business.mail")} *</label><FormField  className={emailFormFieldError}>
                                <TextInput id='businessEmail' name='businessEmail' style={{ width: "100%" }} value={this.state.email}  onChange={event =>this.handleEmailChange(event)} />
                                {emailError}
                                </FormField></div>
                            <div><label>{LocaleUtility.getLocaleMessage("label.phone.number")}</label><FormField  className={phoneFormFieldError}>
                                <TextInput id='businessPhone' name='businessPhone' style={{ width: "100%" }} value={this.state.phoneNumber}  onChange={event =>this.handlePhoneNumberChange(event)} />
                                {phoneError}
                                </FormField></div>
                        </div>
                        <div className="flex_parent_2">
                            <div><label>{LocaleUtility.getLocaleMessage("label.country")}</label>
                            <Select id="buy_country" name="country" options={countryOptions} 
                                value={this.state.countrySelected} onChange={this.filterStateList}/></div>
                            <div><label>{LocaleUtility.getLocaleMessage("label.state.region")}</label>
                            <Select id="buy_region" name="region" options={this.state.stateOptions} 
                                value={this.state.regionSelected} onChange={this.selectRegion}/></div>
                        </div>
                    </div>
                <div className="buymore_term_and_condition">
                    <p>
                        {LocaleUtility.getLocaleMessage("label.by.clicking.submit")} <a href="https://software.microfocus.com/en-us/about/software-licensing" target="_blank" rel="noopener noreferrer">{LocaleUtility.getLocaleMessage("label.terms.conditions")}</a>
                    </p>
                    <p>
                        {LocaleUtility.getLocaleMessage("label.privacy.info")}
                    </p>
                </div>

                <div className="button_right_wrap">
                    <div className="button_wrap_primary">
                        <Button id="submitBuyMore" label={LocaleUtility.getLocaleMessage("label.button.submit")} type="button" plain={false} primary={true} onClick={this.handleSubmitButton}       />
                    </div>
                </div>
                  
              </div>
            </div>
          {/* </FormField> */}
                                           
     {/* </Form> */}
     
     </div>
   );
 }

 responseSection(){
     let viewLicenseBtn = <div className="button_left_wrap saveBtnStyle">
                            <div className="button_wrap_primary">
                                <Button id="returnToLicense" label={LocaleUtility.getLocaleMessage("label.license.management")}
                                href={FrontendPagePath.LICENSE_VIEW_PATH+Utils.buildURLQueryParam()} plain={false} primary={true} />
                            </div>
                        </div>
    if(this.state.defaultMail !== undefined){
        var mailDetail = this.state.defaultMail;
        document.getElementById("offlineLink").href = "mailto:"
					+ mailDetail.tomail + "?subject="
					+ mailDetail.subject + "&cc="
					+ escape(mailDetail.businessMail)
					+ "&body=" + mailDetail.defaultMailMsg;
		window.open(document.getElementById("offlineLink").href,'_blank', [
			                                            'width=' + window.innerWidth/2,
			                                            'height=' + window.innerHeight/2
                                                      ]);
        this.setState({defaultMail: undefined});
    }
     const showHideBuyMoreResp = this.state.buyMoreResp ? "display-block" : "display-none";
      return(
          <div style={{padding: "7px"}} className={showHideBuyMoreResp+" border_white_backg_shadow"}>
            <a id="offlineLink" style={{display: "none"}}></a>
          <SectionWithTitle title={LocaleUtility.getLocaleMessage("label.confirm")}/>
          <h2 className="buymore_header">
                {LocaleUtility.getLocaleMessage("label.confirm")} 
          </h2>
          <div className="separator"></div>
          <div style={{paddingLeft: "4px"}}>
               <p style={{ maxWidth: "100%"}}> <label style={{fontWeight:"bold" }}>{LocaleUtility.getLocaleMessage("confirm.default.mail.msg")}</label></p>
               {this.state.resStatus === "SUCCESS" ? 
                    <p style={{ maxWidth: "100%"}}> {LocaleUtility.getLocaleMessage("confirm.default.mail.msg7")}</p>
               :
                    <span>
                        <p style={{ maxWidth: "100%"}}> {LocaleUtility.getLocaleMessage("confirm.default.mail.msg1")}</p>
                        {!(this.state.onLine) && (
                                <div>
                                    <p style={{ maxWidth: "100%"}}> {LocaleUtility.getLocaleMessage("confirm.default.mail.msg2")} </p>
                                    <p style={{ maxWidth: "100%"}}> {LocaleUtility.getLocaleMessage("confirm.default.mail.msg3")} 
                                    {(this.props.session!==undefined && this.props.session.isadmin.value)?<Anchor label={LocaleUtility.getLocaleMessage("confirm.default.mail.msg4")} href={FrontendPagePath.MAIN_CONFIG_PATH+Utils.buildURLQueryParam()}/>:""}
                                    
                                    {LocaleUtility.getLocaleMessage("confirm.default.mail.msg6")} </p>
                                </div>
                        )}
                        {this.state.onLine && (
                            <p style={{ maxWidth: "100%"}}>{LocaleUtility.getLocaleMessage("confirm.default.mail.msg5")}</p>
                        )}
                    </span>
               }
            </div>
          <div className="updateUserDiv," style={{ width: "60%", paddingLeft: "4px"}}>
                  <TableGrid
                                        header={[
                                            {
                                                columnName: "productNumber",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.prodNumber"),
                                                width: "auto",
                                                columnKey: "productNumber",
                                            },
                                            {
                                                columnName: "productDesc",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.product.desc"),
                                                href: false,
                                                width: "auto",
                                            },
                                            {
                                                columnName: "additionalQtyList",
                                                headerLabel: LocaleUtility.getLocaleMessage("label.add.more"),
                                                href: false,
                                                width: "auto",
                                            }
                                        ]}
                                        rowData={this.state.rowData}
                                        pagination={this.state.pagination}
                                        isExportRequired={false}
                                        onSort={this.onSortClicked}
                                        onFilter={this.onFilterClicked}
                                        onPagination={this.onPaginationClicked}
                                        sortIndex={this.state.sortIndex}
                                        sortAscending={this.state.sortAscending}
                                        onChangeCheckBoxCallback={this.onRowClicked}
                                        selectAllRows={false}
                                    />
              </div>
              {viewLicenseBtn}
              </div>
      );
 }

    render() {
        return (
            <div className="config-cont-backgroud">
                <div className="buymore_wrapper">
                {this.sectionContent()}
                {this.responseSection()}
                </div>
                <SpinningNotification centerPage={true} />
            </div>
        );
    }
}

// Buymore.contextTypes = {
//   intl: _propTypes2.default.object,
// };

export default Buymore;