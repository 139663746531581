import React, { Component } from "react";
import ConfirmModalStore from "../../flux/ConfirmModalStore";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { Button } from "grommet/components/Button";
import { Alert as AlertIcon } from 'grommet-icons';
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class ConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            title: "",
            alertText: "",
            confirmed: false,
            selectedOption: "none",
            okLabel: "",
            cancelLabel: ""
        };
        this.setModal = this.setModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
        this.onModalOkCallback = this.onModalOkCallback.bind(this);
    }

    setModal() {
        this.setState({
            showModal: ConfirmModalStore.getState().showModal,
            title: ConfirmModalStore.getState().title,
            alertText: ConfirmModalStore.getState().message,
            callBack: ConfirmModalStore.getState().callBack,
            data: ConfirmModalStore.getState().data,
            okLabel: ConfirmModalStore.getState().okLabel,
            cancelLabel: ConfirmModalStore.getState().cancelLabel
        });
    }

    componentDidMount() {
        ConfirmModalStore.on("change", this.setModal);
    }

    componentWillUnmount() {
        ConfirmModalStore.removeListener("change", this.setModal);
    }

    onModalCloseCallback() {
        this.setState({
            showModal: false,
            title: '',
            alertText: '',
            confirmed: false,
            selectedOption: "2",
            okLabel: '',
            cancelLabel: ''
        });
        window.confirmAction = "2";
        this.state.callBack(0);
    }
    onModalOkCallback() {
        this.setState({
            showModal: false,
            title: '',
            alertText: '',
            confirmed: true,
            selectedOption: "1",
            okLabel: '',
            cancelLabel: ''
        });
        window.confirmAction = "1";
        this.state.callBack(1, this.state.data);
    }

    render() {
        // let title = LocaleUtility.getLocaleLabel(this.context.intl, "label.modal.title.alert")
        // if(this.state.title !== undefined){
        //     title = this.state.title
        // }
        const showHideModalStyle = this.state.showModal ? "modal display-block" : "modal display-none";
        var htmlRegex = new RegExp("/<\/?[a-z][\s\S]*>/i");
        var tempVar;
        if(htmlRegex.test(this.state.alertText)){
             tempVar = this.state.alertText;
         }else{
              tempVar = this.state.alertText;
            }
        let okayLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.ok");
        let cancelLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.button.cancel");
        if(this.state.okLabel !== undefined && this.state.okLabel !== '') {
            okayLabel = this.state.okLabel;
        }
        if(this.state.cancelLabel !== undefined && this.state.cancelLabel !== '') {
            cancelLabel = this.state.cancelLabel;
        }
        return (
            <div id={this.props.id} className={showHideModalStyle}> 
                <div id="modalDiv" className="modal">
                    <div className="alert-modal-main panel panel-default" style={this.props.styleProps}>
                        <div style={{width: "100%"}}>
                        <div id="alert_modal_content" className="alert-panel-body">
                            <div style={{float:"left", margin:"0px 10px"}} ><AlertIcon id="alert-icon-id" className="grommetux-control-icon grommetux-color-index-critical"/></div>
                            <div id="alert_modal_msg" style={{marginLeft:"40px"}}>{tempVar}</div>
                        </div>
                        <div className="alert-panel-footer panel-button-footer button_right_wrap">
                            <div className="button_wrap_primary">
                                <Button 
                                style={{margin: "0px 5px"}}
                                id="alert_modal_Btn"
                                label={okayLabel}  
                                plain={false}  
                                primary={true} 
                                onClick={this.onModalOkCallback} 
                            /></div>
                            <div className="button_wrap_negative">
                            <Button 
                                id="alert_cancel_Btn"
                                style={{margin: "0px 5px"}}
                                label={cancelLabel} 
                                plain={false}  
                                primary={true} 
                                onClick={this.onModalCloseCallback} 
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ConfirmModal.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ConfirmModal;