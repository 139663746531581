
import React, { Component } from "react";
// import BreadCrumb from "@micro-focus/quantum-ux-grommet/components/BreadcrumbsComponent";
import { Button } from "grommet/components/Button";
import TableGrid from "../../../../components/commons/table/APTable";
import Dispatcher from "../../../../flux/Dispatcher";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Anchor } from 'grommet/components/Anchor';
import { TextInput } from 'grommet/components/TextInput';
import { List } from "../../../../components/commons/grommet/List";
import { ListItem } from "../../../../components/commons/grommet/ListItem";
import { Select } from "grommet/components/Select";
import * as FetchHelper from "../../../../utils/FetchHelper";
import * as Utils from "../../../../utils/Util";
import * as ValidationUtility from "../../../../utils/ValidationUtility";
import Modal from "../../../generic/Modal";
import "../../../../css/index.css";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as FrontendPagePath from  "../../../../utils/FrontendPagePath";
import { createSearchParams } from "react-router-dom";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

var errorFlag = false;
class WizardStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenses: [],
            showModal: false,
            currentStep: 1,
            entitlementVersion:[] 
        };

        this.requestQuantityActivate = this.requestQuantityActivate.bind(this);
        this.validateLicense = this.validateLicense.bind(this);
        this.installLicense = this.installLicense.bind(this);
        this.filterEnviroment = this.filterEnviroment.bind(this);
        this.pacCodeFormSubmit = this.pacCodeFormSubmit.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.validateNumberInput = this.validateNumberInput.bind(this);
        this.paintTextInput = this.paintTextInput.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
        this.onLicenseIdClicked = this.onLicenseIdClicked.bind(this);
        this.onEntitlementIdClicked = this.onEntitlementIdClicked.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);        
        this.refreshState = this.refreshState.bind(this);
        this.paintSelect = this.paintSelect.bind(this);
        this.selectVersionProduct = this.selectVersionProduct.bind(this);
        this.showEntitlementTableGridWizard = this.showEntitlementTableGridWizard.bind(this);
    }

    requestQuantityActivate(event) {
        event.preventDefault();
        if (this.props.environmentSelected.value !== 'ALL') {
            if (this.props.rowData !== undefined) {
                const keys = Object.assign([], this.state.selectedEntitlementIds);
                if(keys.length === 0) {
                    this.setState({
                        showModal: true,
                        alertText: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert5")
                    });
                } else {
                    var selectedDataList = [];
                    var flag = false;
                    keys.forEach(entitlementId => {
                        var id = event.target.elements["text_"+entitlementId];
                        if(id !== undefined && id){
                            var value = event.target.elements["text_"+entitlementId].value;
                            var prodVersionValue = this.state.entitlementVersion[entitlementId];
                            if(prodVersionValue !== undefined && prodVersionValue)
                                prodVersionValue = this.state.entitlementVersion[entitlementId].value;
                            else
                                prodVersionValue = '';
                            if (!errorFlag && value !== '' && prodVersionValue !== '') {
                                selectedDataList.push({
                                    "entitlementId": entitlementId,
                                    "requestedActivationQuantity": value,
                                    "versionProductId": prodVersionValue,
                                    "answersList": []
                                });
                                flag = true;
                                errorFlag = false;
                            } else {
                                flag = false;
                                errorFlag = true;
                                let msg = '';
                                if(prodVersionValue === undefined || prodVersionValue === ''){
                                    msg = LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert7")
                                    event.target.elements["sel_"+entitlementId].classList.add("select-error");
                                } else if (value === '') {
                                    msg = LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert2");
                                } else if (!ValidationUtility.isNumber(value)) {
                                    msg = LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert4");
                                }
                                this.setState({
                                    showModal: true,
                                    alertText: msg
                                });
                            }
                        } 
                    });
                
                    if(flag){
                        FetchHelper.requestQuantityActivate(this.props.activationCode, 
                            event.target.elements.environment.value, selectedDataList);
                    }
                }
            }
        } else {
           this.setState({
                showModal: true,
                alertText: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert3")
            });
        }
    }

    validateLicense() {

        if (this.props.licenseList !== undefined && this.props.licenseList.length > 0) {
            FetchHelper.requestValidateLicenseList(this.props.licenseList);
        } else {
            console.error("License Key List is empty");
        }
    }
    componentDidMount(){
    }

    refreshState(){

    }
    componentWillUnmount() {
    }
    installLicense() {

        if (this.props.rowData !== undefined) {
            const keys = Object.assign([], this.state.selectedLicenseIds);

            if(keys.length === 0) {
                this.setState({
                    showModal: true,
                    alertText: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert6")
                });
                this.props.navigate({ pathname:FrontendPagePath.LICENSE_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
            } else {
                var selectedDataList = [];
                if(keys.length !== this.props.rowData.length){
                    this.props.rowData.forEach(element => {
                        var index = keys.indexOf(element.licenseID)
                        if (index !== -1) {
                            selectedDataList.push(element);
                        }
                    });
                } else {
                    selectedDataList = this.props.rowData;
                }
                FetchHelper.installLicenseList(selectedDataList);
            }
        } else {
           console.error("Licnese Key List is empty");
        }
    }

    filterEnviroment(event){
        let loadSpinAction = "ENTITLEMENT_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });

        var tableData = this.props.tableData;
        let type = "PAC_ENTITLEMENTS_FETCH_COMPLETE";
        let payload = {
            activationCode: this.props.activationCode,
            environments: this.props.options,
            entitlementData: tableData,
            environmentSelected: {value:event.option.value, label:event.option.label},
            currentStep: 2,
        };
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
        
        loadSpinAction = "ENTITLEMENT_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }

    showEntitlementTableGridWizard() {

        this.props.rowData.map(item => {
            let vers = [];
            item.versionProductList.map(itm => {
                vers.push({label: itm.productVersionName, value: itm.productId});
            });

            item.versionProduct = this.paintSelect(item.entitlementId, vers);
        });

        let addAnchor;
        if (this.props.licenseList !== undefined &&
                 this.props.licenseList.length > 0) {
            addAnchor =
                <Anchor id="lic_inst_linkLic" style={{ float: 'right' }} label={LocaleUtility.getLocaleLabel(this.context.intl, "label.anchor.install.existing.licenses")}
                onClick={this.validateLicense.bind(this)} />;
        }
        return (
            <div className="pac_code_wrapper">
            <form id="lic_inst_qtyActForm" onSubmit={this.requestQuantityActivate}>
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: '70em' }}>
                            <FormField label={LocaleUtility.getLocaleLabel(this.context.intl, "label.activation.code")}>
                                <label id="lic_inst_pacCode">{this.props.activationCode}</label>
                            </FormField>
                        </td>
                        <td style={{ width: '30em' }}></td>
                    </tr>
                    <tr>
                        <td style={{ width: '70em' }}>
                            <FormField label={LocaleUtility.getLocaleLabel(this.context.intl, "label.select.environment")}>
                            <Select id="lic_inst_environment" name="environment" options={this.props.options} value={this.props.environmentSelected} onChange={this.filterEnviroment}/>
                            </FormField>
                        </td>
                        <td style={{ width: '30em'}}>{addAnchor}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div>
                                <TableGrid id="lic_inst_entGrid"
                                    header={[
                                        {
                                            columnName: "productNumber",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                            href: false,
                                            onClick: this.onColumnClicked
                                        },
                                        {
                                            columnName: "productName",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.productname"),
                                            href: false,
                                            width: "30%",
                                        },
                                        {
                                            columnName: "environmentDesc",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.environment"),
                                            href: false,
                                            width: "10%",
                                        },
                                        {
                                            columnName: "entitlementQuantity",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.totalqty"),
                                            href: false,
                                            width: "10%",
                                        },
                                        {
                                            columnName: "availableQuantityToActivate",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.availqty"),
                                            href: false,
                                            width: "10%",
                                        },
                                        {
                                            columnName: "versionProduct",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.productversion"),
                                            href: false,
                                            width: "15%",
                                        },
                                        {
                                            columnName: "quantityToActivate",
                                            headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.qtytoactivate"),
                                            href: false,
                                            width: "10%",
                                        }
                                    ]}
                                    rowData={this.props.rowData}
                                    pagination={this.props.pagination}
                                    sortIndex={this.props.sortIndex}
                                    rowSelection={3}
                                    sortAscending={this.props.sortAscending}
                                    rowKey="entitlementId"
                                    onChangeCheckBoxCallback={this.onEntitlementIdClicked}
                                    noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                    selectAllRows={true}
                                />
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <table>
                            <tbody>
                                <tr className="button_left_wrap">
                                    <td className="button_wrap_primary">
                                        <Button id="lic_inst_btnNext" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.next")} 
                                        type="submit" plain={false} primary={true} />
                                    </td>
                                    <td className="button_wrap_secondary">
                                        <Button id="lic_inst_btnBack" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.back")} 
                                        accent={true} plain={false} onClick={this.props.prevPage} />
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                        </td>
                    </tr>
                </tbody>
            </table>
            </form>
            </div>
        );
    }

    showLicenseTableGridWizard() {

        let addButtonLabel, buttonLabel = LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license");
        if (this.props.rowData.length > 0) {
            addButtonLabel =
                <Button id="lic_inst_btnInstLic" label={buttonLabel}
                plain={false} primary={true} onClick={this.installLicense.bind(this)} />;
        } else {
            addButtonLabel =
                <Button id="lic_inst_btnInstLic" label={buttonLabel} plain={false} primary={true} disabled={true} />
        }
        return (
            <div className="LicenseTableGrid_wrapper">
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: '70em' }}>
                            <FormField label={LocaleUtility.getLocaleLabel(this.context.intl, "label.activation.code")}>
                                <label id="lic_inst_pacCode">{this.props.activationCode}</label>
                            </FormField>
                        </td>
                        <td style={{ width: '30em' }}></td>
                    </tr>
                    <tr>
                        <td colSpan="2">          
                <div>
                    <TableGrid id="lic_inst_licGrid"
                        header={[
                            {
                                columnName: "featureDesc",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.feature"),
                                href: false,
                                width: "30%",
                            },
                            {
                                columnName: "productID",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                href: false,
                                width: "10%",
                            },
                            {
                                columnName: "ltuCapacity",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu"),
                                href: false,
                                width: "5%",
                            },
                            {
                                columnName: "capacity",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "startTimeInUTCSeconds",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "expiryTimeInUTCSeconds",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "lockCode",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.lockcode"),
                                href: false,
                                width: "auto",
                            },
                            {
                                columnName: "remarks",
                                headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.remarks"),
                                href: false,
                                width: "auto",
                            }
                        ]}
                        rowData={this.props.rowData}
                        pagination={this.props.pagination}
                        sortIndex={this.props.sortIndex}
                        rowSelection={3}
                        sortAscending={this.props.sortAscending}
                        disabledRowIds={this.props.disabledRows}
                        uncheckTheDisabled={true}
                        rowKey="licenseID"
                        onChangeCheckBoxCallback={this.onLicenseIdClicked}
                        noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                        selectAllRows={true}
                    />
                </div>
                <div style={{ clear: 'both' }}></div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tbody>
                            <tr className="button_left_wrap">
                                <td className="button_wrap_primary">
                                    {addButtonLabel}
                                </td>
                                <td className="button_wrap_secondary">
                                    <Button id="lic_inst_btnBack" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.back")}
                                     type="button" plain={false} secondary={true} onClick={this.props.prevPage} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
               </td>
            </tr>
        </tbody>
    </table>
        {this.showInvalidLicenseGrid()}
    </div>
        );
    }

    showInvalidLicenseGrid() {
        if (this.props.error.length > 0) {
            return (
                <div className="panel panel-default">
                    <div className="panel-heading">
                        {LocaleUtility.getLocaleLabel(this.context.intl, "label.list.invalid.licenses")}
                    </div>
                    <div className="panel-body">
                        <List id="lic_inst_listItems" selectable={false}>
                            {this.props.error.map((item, index) => (
                                <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            )
        }
    }

    onLicenseIdClicked(keys, event){
        this.setState({
            selectedLicenseIds: keys
        });
    }

    onEntitlementIdClicked(keys, event){
        this.setState({
            selectedEntitlementIds: keys
        });
    }

    validateTextInput(event){
        var errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)

        if (ValidationUtility.isAlphaNumeric(event.target.value)) {
            errors = [];
        } else {
            if (index === -1) {
                errors.push(event.target.name);
            }
        }
        this.setState({errorcode: errors});
        this.setState({ pacCode: event.target.value });
    }

    validateNumberInput(event){
        if (!ValidationUtility.isNumber(event.target.value)) {
            Dispatcher.dispatch({
                type: "ALERT_SHOW_MODAL",
                payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert2")
            });
            event.target.className = "input-error";
            errorFlag = true;
        } else {
            event.target.className = "";
            errorFlag = false;
        }
    }

    paintTextInput(key){
        return (
            <input style={{ width: '80%' }} type="text" id={key} name={key} onChange={this.validateNumberInput}/>
        )
    }

    selectVersionProduct(entId, event){
        if(event.option !== undefined){
            let vers = Object.assign([], this.state.entitlementVersion);
            vers[entId] = event.option;
            event.target.classList.remove("select-error");
            this.setState({entitlementVersion: vers});
        }
    }

    paintSelect(key, options){
        return (
            <Select id={key} name={"sel_"+key} placeholder= {LocaleUtility.getLocaleMessage("label.select.entitlement.product.version")}
                    options={options}
                    value={this.state.entitlementVersion[key]}
                  //  onSearch={this.onSearch}
                    onChange={this.selectVersionProduct.bind(this, key)}
                    />
        )
    }

    pacCodeFormSubmit(event){
        event.preventDefault();

        if(event.target.elements.pacCode.value !== '' && this.state.errorcode.length === 0){
            let loadSpinAction = "PAC_ENTITLEMENTS_FETCH_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
            });
            FetchHelper.requestPACEntitlements(event.target.elements, this.paintTextInput.bind(this));
        } else {
            const errors = Object.assign([], this.state.errorcode);
            let index = errors.indexOf(event.target.elements.pacCode.name)
            if (index === -1) {
                errors.push(event.target.elements.pacCode.name);
            }
            this.setState({errorcode: errors});
        }
        
    }

    handleCancelButton() {
        this.setState({ pacCode: '', errorcode: []});
    }

    showPACCodePage() {

        let pacFormFieldError, pacCodeError;
        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('pacCode') !== -1){
                pacCodeError =<span id="lic_inst_pacError" className="grommetux-form-field__error">
                {LocaleUtility.getLocaleLabel(this.context.intl, "label.pac.install.license.alert1")}</span>;
                pacFormFieldError='grommetux-form-field--error';
            }
        } else {
            pacFormFieldError = '';
        }

        return (
            <div className="PACCodePage_wrapper">
            <Form id="lic_inst_pacForm" compact={false} pad="small" plain={true} onSubmit={this.pacCodeFormSubmit}>
                    <div className="PAC_form_wrap">
                                <FormField label={LocaleUtility.getLocaleLabel(this.context.intl, "label.enter.activation.code")} 
                                    className={pacFormFieldError}>
                                          <TextInput id='lic_inst_pacInput' name='pacCode' value={this.state.pacCode} onChange={event =>this.validateTextInput(event)} />
                                    {pacCodeError}
                                </FormField>
                    </div>
                    <div className="button_left_wrap">
                        <div className="button_wrap_primary">
                                        <Button id="lic_inst_pacNextBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.next")}
                                            type="submit" accent={true} plain={false}/> 
                                    </div>
                                    <div className="button_wrap_negative">
                                        <Button id="lic_inst_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                    </div>
                                    </div>     
            </Form>
            </div>
        )
    }

    onModalCloseCallback() {
        this.setState({
            showModal: false,
            alertText: ''
        });
    }

    render() {
        let renderContent;
        if (this.props.currentStep === 1) {
            renderContent = this.showPACCodePage();
        } else if(this.props.currentStep === 2){
            renderContent = this.showEntitlementTableGridWizard();
        } else {
            renderContent = this.showLicenseTableGridWizard();
        } 

        return (
            <div id="lic_inst_byPac">
                {renderContent}
                <Modal styleProps={{width: "50%"}} title={LocaleUtility.getLocaleLabel(this.context.intl, "label.modal.title.alert")} showModal={this.state.showModal} 
                    closeLabel={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.ok")} sectionContent={this.state.alertText} 
                    onCloseButtonCallback={this.onModalCloseCallback}
                    />
            </div>
        );
    }
}

export default class PACPageWizard extends Component {

    constructor() {
        super();
        this.state = {
            currentStep: 1
        };

        this.rowData = [];
        this.prevStep = this.prevStep.bind(this);
    }

    prevStep() {
        this.props.prevPage(this.props.currentStep, this.props.pageType);
    }

    render() {

        let stepOne = "", stepTwo = "", stepThree = "";
        if (this.props.currentStep === 1) {
            stepOne = "active";
        } else if (this.props.currentStep === 2) {
            stepTwo = "active";
        } else{
            stepThree = "active";
        }
        return (
            <div>
                <div className="breadcrumb-div">
                    <ul className="breadcrumb">
                        <li className={stepOne}><span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.activate.products.by.pac")}</span></li>
                        <li className={stepTwo}><span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.activate.license.qty")}</span></li>
                        <li className={stepThree}><span>{LocaleUtility.getLocaleLabel(this.context.intl, "label.install.license")}</span></li>
                    </ul>
                </div>
                <WizardStep currentStep={this.props.currentStep} {...this.props} prevPage={this.prevStep.bind(this)} />
            </div>
        )
    }
}

// PACPageWizard.contextTypes = {
//   intl: _propTypes2.default.object,
// };
// WizardStep.contextTypes = {
//   intl: _propTypes2.default.object,
// };