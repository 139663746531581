import React, { Component } from "react";
import UsageReportsView from "../../../components/main/reports/UsageReportsView";
import UsageReportListHeader from "../../../components/main/reports/UsageReportListHeader";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/APTableStore";
import ReservationStore from "../../../flux/ReservationManagementStore";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import { Navigate } from 'react-router-dom';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as Utils from "../../../utils/Util";
import { Anchor } from 'grommet/components/Anchor';
import SpinningNotification from '../../generic/SpinningNotification';
import SectionWithTitle from "../../generic/SectionWithTitle";
//import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as Util from "../../../utils/Util";
import { useIntl } from 'react-intl';
import { createSearchParams } from "react-router-dom";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const paginationObj = {
    required: true,
    style: "default",
    totalCount: 0,
    pageNumber: 1,
    pageSize: FetchHelper.getPageSize(),
    sortBy: "featureId",
    sortOrder: "DESC"
}

class UsageReportsList extends Component {
    constructor(props) {
        super(props);
        this.usageViewRef = React.createRef();
        this.state = {
            rowData: [],
            pagination: paginationObj,
            productNameList: [],
            productNameSelValue: {label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""},
            isSearchEnable: false,
            machineTampered: false,
            productName: "",
            isProductNameSel: false,
            selectedRows: [],
            disabledRows: [],
            showGraphs: false,
            loadData: false,
            licenseModelMap: {},
            featrueDescMap: {},
            cumulateUsageMap: {},
            sortIndex: 0,
            sortAscending: false,
            selProductValue: "",
            featureIdVersionMap: {},
            session: undefined,
            dependableFeatures: [],
            tokenBaseFeatures: {}
        }
        this.loadListTableGrid = this.loadListTableGrid.bind(this);
        this.showSearhPanel = this.showSearhPanel.bind(this);
        this.changeProductName = this.changeProductName.bind(this);
        this.findData = this.findData.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.onClickFeatureId = this.onClickFeatureId.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.manageCommuterUpdates = this.manageCommuterUpdates.bind(this);
        this.exportUsageSummaryReport = this.exportUsageSummaryReport.bind(this);
        this.loadProdutNameDropDown = this.loadProdutNameDropDown.bind(this);
        this.resetFeaturesGrid = this.resetFeaturesGrid.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleManageCommuter = this.handleManageCommuter.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    changeProductName(event) {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        this.setState({productNameSelValue: event.option, pagination: paginationObj, rowData:[]});
        this.findData(event.option.value, paginationObj, this.state.sortIndex);
        this.setState({isProductNameSel: true});
        this.setState({selProductValue: event.option.value});

    }
    findData(prodName, pagination, sortIndex) {
        let params = {};
        params["productName"] = prodName;
        params["pagination"] = pagination;
        params["sortIndex"] = sortIndex;
        FetchHelper.listUsageReports(this.context.intl, params);
        this.setState({loadData: false});
    }
    exportUsageSummaryReport(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let request = {
            "productName": this.state.selProductValue
        }
        FetchHelper.downloadUsageSummaryReports(request, this.context.intl, mimetype);
    }
    showSearhPanel() {
        if(this.state.isSearchEnable) {
            this.setState({isSearchEnable: false});
        } else {
            this.setState({isSearchEnable: true});
        }
    }
    onClickFeatureId(value) {
        localStorage.setItem("featureId:Version", value);
        let cumulativeUsage = this.state.cumulateUsageMap[value];
        localStorage.setItem("cumulativeUsage", cumulativeUsage);
        let licModel = 1;
        let isTokenFeature = this.state.tokenBaseFeatures[value];
        if(this.state.licenseModelMap[value]) {
            licModel = this.state.licenseModelMap[value];
        }
        if(this.state.dependableFeatures !== undefined && this.state.dependableFeatures.indexOf(value) !== -1) {
            localStorage.setItem("isDependableFeature", "true");
        } else {
            localStorage.setItem("isDependableFeature", "false");
        }
        localStorage.setItem("licenseModel", licModel);
        localStorage.setItem("isClockTampered", this.state.machineTampered);
        localStorage.setItem("featureDescription", this.state.featrueDescMap[value]);
        localStorage.setItem("isTokenBaseFeature", isTokenFeature);
        this.setState({showGraphs: true});
    }
    componentDidMount() {
        APTableStore.on("change", this.loadListTableGrid);
        ReservationStore.on("change", this.loadProdutNameDropDown);
        APTableStore.on("change", this.resetFeaturesGrid);
        APTableStore.on("change", this.refreshPage);
        
        FetchHelper.listProductNamesForDropDown();
        this.findData(this.state.selProductValue, this.state.pagination, this.state.sortIndex);        
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadListTableGrid);
        ReservationStore.removeListener("change", this.loadProdutNameDropDown);
        APTableStore.removeListener("change", this.resetFeaturesGrid);
        APTableStore.removeListener("change", this.refreshPage);
    }
    manageCommuterUpdates() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "managecommuter") {
            //this.setState({selectedRows: []});
            //this.setState({disabledRows: []});
            //this.setState({rowData: []});
            ConfigurationUtils.showLoadMask("", this.state.sortIndex);
            this.findData(this.state.selProductValue, this.state.pagination, this.state.sortIndex);
            ConfigurationUtils.showSuccessMessage("usageManageCommuterSuccess", this.context.intl);
        }
    }
    refreshPage() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "refreshAction") {
            this.setState({productNameSelValue: {label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""},
                            pagination: paginationObj, rowData: []});
            this.findData("", paginationObj, this.state.sortIndex);
            this.setState({selProductValue: "", });
        }
    }
    loadProdutNameDropDown() {
        if(ReservationStore.getState() && ReservationStore.getState().actionType === "productNameList") {
            let data = ReservationStore.getState().data;
            if(data !== undefined && data.productNameList !== undefined) {
                let pList = [];
                pList.push({label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""});
                pList.push({label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"});
                if(data.productNameList.value !== undefined) {
                    data.productNameList.value.map(element => {
                        let val = element.split(":");
                        pList.push({label: val[1], value: val[0]});
                        return "";
                    });
                }
                this.setState({productNameList: pList});
            }
        }
    }
    loadListTableGrid() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "usagepagelist") {
            let rowData = APTableStore.getState().allFeatureList;
            /*let productNames = APTableStore.getState().productNameList;
            if(productNames && productNames.length > 0) {
                let pList = [];
                pList.push({label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""});
                productNames.map(element => {
                    pList.push({label: element.productDescription+" - "+element.productCurrentVersionStr, value: element.uniqueProductIdentifier});
                    return "";
                });
                this.setState({productNameList: pList});
            }*/
            var sessionData = FetchHelper.getSession(true);
            sessionData.then(sData => {
                var validFeatures = FetchHelper.getUserFeatures();
                validFeatures.then(data => {
                    if(rowData.length === 0) {
                        this.setState({isProductNameSel: true});
                    } else {
                        
                        // isAdmin = ValidationUtility.isAdminCheck(this.state.session);
                        rowData.map(e => {
                            if(e.toolTipKey !== undefined && e.toolTipKey !== "") {
                                e.toolTipKey = this.findLabel(e.toolTipKey);
                            }
                            e.availableCap = Util.roundOfValue(e.availableCap);
                            e.totalCap = Util.roundOfValue(e.totalCap);
                            e.commuteCap = Util.roundOfValue(e.commuteCap);
                            if(e.lastCumulativeUsage !== null && e.lastCumulativeUsage !== undefined) {
                                if(e.lastCumulativeUsage > e.totalInstalledLicenseCap) {
                                    e.commuteCap = <span style={{color: "red"}}><b>{e.lastCumulativeUsage}</b></span>
                                } else {
                                    e.commuteCap = e.lastCumulativeUsage;
                                }
                            }
                            e.issuedCap = Util.roundOfValue(e.issuedCap);
                            /* if(!isAdmin && defDisRows.indexOf(e.featureId) === -1) {
                                defDisRows.push(e.featureId);
                            } */
                            if(data !== undefined && data.indexOf(e.featureId+":"+e.featureVersion) === -1){
                                e["hiddenCheckBox"] = "Y";
                            }
                            if(Number(e.availableCap) < 0) {
                                e["totalCapToolTipText"] = "Overage: "+e.availableCap;
                                e.totalCap = <span style={{color: "red"}}><b>{e.totalCap}</b></span>
                                e.availableCap = 0;
                            } else {
                                let cTotalCap = Number(e.totalCap);
                                let cInstalledCap = Number(e.totalInstalledLicenseCap);
                                if(cTotalCap > cInstalledCap) {
                                    let toolTipValue = cInstalledCap - cTotalCap;
                                    e["totalCapToolTipText"] = "Overage: "+toolTipValue;
                                    e.totalCap = <span style={{color: "red"}}><b>{e.totalCap}</b></span>
                                }
                            }
                            return "";
                        });
                        this.setState({licenseModelMap: APTableStore.getState().licModelMap,
                        featrueDescMap: APTableStore.getState().featMap, 
                        tokenBaseFeatures: APTableStore.getState().tokenBaseFeatures,
                        selectedRows: APTableStore.getState().selRows,
                        disabledRows: APTableStore.getState().disRows});
                    }
                    let sortIndex = APTableStore.getState().sortIndex;
                    let sortAscending = APTableStore.getState().sortOrder;
                    if(sortAscending === "ASC") {
                        this.setState({sortAscending: true});
                    } else {
                        this.setState({sortAscending: false});
                    }
                    let pagination = APTableStore.getState().pagination;
                    pagination.required = true;
                    pagination.style = "default";
                    this.setState({session: FetchHelper.getSession(),
                                    rowData: rowData,
                                    machineTampered: APTableStore.getState().machineTampered,
                                    cumulateUsageMap: APTableStore.getState().cumulateUsageMap,
                                    featureIdVersionMap: APTableStore.getState().featureVersionMap,
                                    dependableFeatures: APTableStore.getState().dependableFeatures,
                                    sortIndex: sortIndex,
                                    pagination: pagination});
                    ConfigurationUtils.hideLoadMask(this.context.intl);
                });
            }).catch(error => {
            });
        }
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "featureId";
        if(index === 1) {
            sortBy = "featureDescription";
        } else if(index === 2) {
            sortBy = "availableCap";
        } else if(index === 3) {
            sortBy = "issuedCap";
        } else if(index === 4) {
            sortBy = "commuteCap";
        } else if(index === 5) {
            sortBy = "totalCap";
        } else if(index === 6) {
            sortBy = "totalInstalledLicenseCap";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        paginationTemp.pageNumber = 1;
        this.findData(this.state.selProductValue, paginationTemp, index);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.selProductValue, paginationTemp, this.state.sortIndex);
    }
    resetFeaturesGrid() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "resetProductFeaturesGrid") {
            this.setState({rowData: [], selProductValue: "", productNameSelValue: {label: LocaleUtility.getLocaleMessage("label.select.all"), value: ""}});
        }
    }
    handleManageCommuter() {
        if(this.usageViewRef !== undefined && this.usageViewRef.current !== undefined) {
            this.usageViewRef.current.handleManageCommuter();
        }
    }
    render() {
        //let intl = this.context.intl;
        if(this.state.showGraphs){
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_GRAPHS_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}}} />;
        } else if(this.state.isSearchEnable) {
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_LIST_SEARCH_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {productNameList: this.state.productNameList}}} />;
        } else if(this.state.rowData.length === 0 && this.state.isProductNameSel){
            return(
                <div className="config-cont-backgroud">
                    <SectionWithTitle title={this.findLabel("usageReportListHeadr")}/>
                    <div style={{width: "100%", float: "left", padding: "15px"}} id="license_usage_msg">
                        {this.findLabel("usageNavigateLicPage")} <Anchor label={this.findLabel("usageNavigateHere")} href={FrontendPagePath.LICENSE_PATH+Utils.buildURLQueryParam()}/> {this.findLabel("usageNavigateInstall")}
                    </div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </div>
            )
        } else {
            return(
                <div className="config-cont-backgroud">
                    <div style={{width: "100%", float: "left", paddingLeft: "4px", paddingRight: "3px"}}>
                        <UsageReportListHeader 
                            productNameList={this.state.productNameList} 
                            onClickSearchIcon={this.showSearhPanel} 
                            selectProductName={this.changeProductName} 
                            isClockTampered={this.state.machineTampered} 
                            selProduct={this.state.productNameSelValue}
                            exportExcel = {this.exportUsageSummaryReport}
                            handleManageCommuter = {this.handleManageCommuter}
                        />
                    </div>
                    {this.state.rowData.length > 0 ? (<div style={{width: "100%", float: "left", paddingLeft: "4px", paddingRight: "3px"}}>
                        <UsageReportsView
                            ref = {this.usageViewRef}
                            rowData={this.state.rowData} 
                            selectedRowsKey={this.state.selectedRows} 
                            disabledRowsKey={this.state.disabledRows} 
                            onClickFeatureId={this.onClickFeatureId} 
                            onSortClicked={this.onSortClicked}
                            onPaginationClicked={this.onPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            pagination={this.state.pagination}
                            featureIdVersionMap= {this.state.featureIdVersionMap}
                            manageCommuterUpdates={this.manageCommuterUpdates}
                        />
                    </div> ) : ""}
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </div>
            )
        }
    }
}
// UsageReportsList.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportsList;