import Dispatcher from "../flux/Dispatcher";
import * as DateUtility from "./DateUtility";
import {getLocaleMessage} from "./LocaleUtility";

export function processCheckoutSuccess(data, showListView) {
    let type = "PROCESS_CHECKOUT_SUCCESS";
    if(data && data.resultsList.length > 0) {
        let lockType = data.lockType;
        data.resultsList.map(item => {
            Object.entries(item).map(([key, value]) => {
                if(key === "extExpiryDate") {
                    if(value === 0) {
                        item[key] = "";
                    } else {
                        item[key] = DateUtility.getFormatedDate(new Date(value*1000));
                    }
                }
                return "";
            });
            if(lockType === 1) {
                item["clientInfo"] = item.ipaddress;
            } else if(lockType === 2) {
                item["clientInfo"] = item.hostID;
            } else if(lockType === 4) {
                item["clientInfo"] = item.deviceID;
            } else {
                item["clientInfo"] = item.macadd;
            }
            return "";
        });
    }
    let payload = {
        data: data,
        showListView: showListView
    }
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processCheckInSuccess(data, showCheckinListView) {
    let type = "PROCESS_CHECKIN_SUCCESS";
    let clientInfoMap = {};
    if(data && data.resultsList.length > 0) {
        data.resultsList.map(item => {
            clientInfoMap[item.licenseID] = item;
            item["clientInfo"] = getLocaleMessage("label.click.here");
            if(item.valid === false) {
                item["status"] = item.customMessage
            } else {
                item["status"] = getLocaleMessage("label.checkedin.success");
            }
            Object.entries(item).map(([key, value]) => {
                if(key === "extExpiryDate" || key === "extStartDate") {
                    if(value === 0) {
                        item[key] = "";
                    } else {
                        item[key] = DateUtility.getFormatedDate(new Date(value*1000));
                    }
                }
                return "";
            });
            return "";
        });
    }
    let payload = {
        data: data,
        clientInfoMap: clientInfoMap,
        showCheckinListView: showCheckinListView
    }
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processDownloadSuccess(data) {
    let type = "PROCESS_DOWNLOAD_SUCCESS";
    let payload = {
        data: data
    }
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}