import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import ClientInfoPopup from "./ClientInfoPopup";
import ExecutedByInfo from "./ExecutedByInfo";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
//var ReactD3 = require('react-d3-components');
//var d3 = require("d3");
//var d3 = require("d3-scale");
//var BarChart = ReactD3.BarChart;
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const CustomTooltip = ({ active, payload, label }) => {
  let obj = (payload && payload.length) ? payload[0].payload.toolTipValue: undefined;
  if (obj) {
    return (
      <div className="tooltip">
        {`${payload[0].payload.toolTipValue}`}
      </div>
    );
  }

  return null;
};

class HistoryUsageGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "serverLicenseTable.lastCheckedInDate",
                sortOrder: "DESC"
            },
            selectedIds: [],
            defaultSelIds: [],
            loadedData: false,
            executedByMap: "",
            clientInfoMap: "",
            clientInfoObj: "",
            executedByInfoObj: "",
            defaultSelIdsToValidate:[],
            sortIndex: 1,
            sortAscending: false,
            poolId: "0",
            exportHistoryRecordSize: 0,
            graphData: [],
            loadedGraphData: false,
            maximumChecked: true,
            avarageChecked: false,
            barRejectedCapacity: 0,
            maximumCapacity: 0,
            averageCapacity: 0,
            rejectedCapacity: 0,
            selGroupId: "0",
            poolColorIndexById: [],
            toolTipMap: {},
            licensetotalcapacity: 0,
            yAxisMaxValue: 0,
            reservedcapacity: 0,
            popupTop: "800px",
            popupLeft: "550px",
            rangeAverage: 0,
            viewType: "day",
            productFeatureFlag : false
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadHistoryTableGrid = this.loadHistoryTableGrid.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.onClickExecutedByInfo = this.onClickExecutedByInfo.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.setPoolId = this.setPoolId.bind(this);
        this.findData = this.findData.bind(this);
        this.findGraphData = this.findGraphData.bind(this);
        this.loadHistoryGraph = this.loadHistoryGraph.bind(this);
        this.handleChangeMaximum = this.handleChangeMaximum.bind(this);
        this.handleChangeAverage = this.handleChangeAverage.bind(this);
        this.tooltipLine = this.tooltipLine.bind(this);
        this.buildHeadersForGrid = this.buildHeadersForGrid.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadHistoryTableGrid);
        APTableStore.on("change", this.props.listPoolNamesAction);
        APTableStore.on("change", this.props.callToExportRequest);
        APTableStore.on("change", this.loadHistoryGraph);
        this.setState({poolId: this.props.selectPoolId+""});
        this.findData(this.state.sortIndex, this.state.pagination, 
            this.props.selectPoolId, this.props.usageStartDate, this.props.usageEndDate, this.props.instanceId);
        //this.findGraphData(this.props.usageStartDate, this.props.usageEndDate, this.props.selectPoolId, this.state.maximumChecked);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadHistoryTableGrid);
        APTableStore.removeListener("change", this.props.listPoolNamesAction);
        APTableStore.removeListener("change", this.props.callToExportRequest);
        APTableStore.removeListener("change", this.loadHistoryGraph);
    }
    setPoolId(val, usageStartDate, usageEndDate, instanceId) {
        this.setState({poolId: val});
        let defaultPagination = {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "serverLicenseTable.lastCheckedInDate",
            sortOrder: "DESC"
        }
        this.setState({pagination: defaultPagination, maximumChecked: true, avarageChecked: false});
        this.findData(this.state.sortIndex, defaultPagination, val, usageStartDate, usageEndDate, instanceId);
        //this.findGraphData(usageStartDate, usageEndDate, val, this.state.maximumChecked);
    }
    onClickClientInfo(key, event) {
        this.setState({executedByInfoObj: ""});
        let clientObj = this.state.clientInfoMap[key];
        this.setState({clientInfoObj: clientObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    onClickExecutedByInfo(key, event) {
        this.setState({clientInfoObj: ""});
        let executedObj = this.state.executedByMap[key];
        this.setState({executedByInfoObj: executedObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    handleChangeMaximum(event) {
        this.setState({maximumChecked: true});
        this.setState({avarageChecked: false});
        this.findGraphData(this.props.usageStartDate, this.props.usageEndDate, this.state.poolId, true, this.props.instanceId);
    }
    handleChangeAverage(event) {
        this.setState({maximumChecked: false});
        this.setState({avarageChecked: true});
        this.findGraphData(this.props.usageStartDate, this.props.usageEndDate, this.state.poolId, false, this.props.instanceId);
    }
    closeTootipAction() {
        this.setState({clientInfoObj: ""});
        this.setState({executedByInfoObj: ""});
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "serverLicenseTable.lastCheckedInDate";
        if(index === 0) {
            sortBy = "serverLicenseTable.licenseStartDate";
        } else if(index === 2) {
            sortBy = "serverLicenseTable.licenseType";
        } else if(index === 3) {
            sortBy = "serverLicenseTable.units";
        } else if(index === 1) {
            sortBy = "serverLicenseTable.licenseExpiryDate";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.findData(index, paginationTemp, this.state.poolId, this.props.usageStartDate, 
            this.props.usageEndDate, this.props.instanceId);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp, this.state.poolId, 
            this.props.usageStartDate, this.props.usageEndDate, this.props.instanceId);
    }
    findGraphData(usageStartDate, usageEndDate, groupId, isMax, instanceId) {
        let featInfo = localStorage.getItem("featureId:Version");
        ConfigurationUtils.showLoadMask("", this.context.intl);
        if(featInfo) {
            let featArr = featInfo.split(":");
            let params = {};
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["startTime"] = usageStartDate;
            params["endTime"] = usageEndDate;
            params["groupId"] = groupId;
            params["uniqueInstanceId"] = instanceId;
            params["licensetotalcapacity"] = this.state.licensetotalcapacity;
            this.setState({selGroupId: groupId});
            FetchHelper.findHistoryGraphData(this.context.intl, params, isMax, this.props.poolColorIndexById,
                 usageStartDate, usageEndDate, this.state.reservedcapacity);
            this.setState({loadedGraphData: false});
        }
    }
    loadHistoryGraph() {
        if(APTableStore.getState().actionType === "usagehistory_graph") {
            let results = APTableStore.getState().results;
            if(results) {
                this.setState({graphData: results});
            }
            if(APTableStore.getState().maximumCapacity) {
                this.setState({maximumCapacity: APTableStore.getState().maximumCapacity});
            }
            this.setState({rangeAverage: APTableStore.getState().rangeAverage, viewType: APTableStore.getState().viewType});
            if(APTableStore.getState().averageCapacity) {
                this.setState({averageCapacity: APTableStore.getState().averageCapacity});
            }
            this.setState({rejectedCapacity: APTableStore.getState().rejectedCapacity});
            if(APTableStore.getState().poolColorIndexById) {
                this.setState({poolColorIndexById: APTableStore.getState().poolColorIndexById});
            }
            if(APTableStore.getState().toolTipMap) {
                this.setState({toolTipMap: APTableStore.getState().toolTipMap});
            }
            if(APTableStore.getState().yAxisMaxValue) {
                this.setState({yAxisMaxValue: APTableStore.getState().yAxisMaxValue})
            }
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    findData(sortIndex, pagination, groupId, usageStartDate, usageEndDate, instanceId) {
        let featInfo = localStorage.getItem("featureId:Version");
        let licenseModel = localStorage.getItem("licenseModel");
        if(featInfo) {
            this.setState({defaultSelIds: []});
            let featArr = featInfo.split(":");
            let params = {};
            params["pagination"] = pagination;
            params["sortIndex"] = sortIndex;
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["groupId"] = groupId;
            params["minCapacity"] = "0";
            params["licenseModel"] = licenseModel;
            params["showall"] = "true";
            params["usageStartDate"] = usageStartDate;
            params["usageEndDate"] = usageEndDate;
            params["poolColorIndexById"] = this.props.poolColorIndexById;
            params["reservedcapacity"] = this.state.reservedcapacity;
            params["uniqueInstanceId"] = instanceId;
            if(Number(groupId) !== 0) {
                params["filterPool"] = "true";
            }
            this.setState({selGroupId: groupId});
            FetchHelper.listUsageDataForGraph(this.context.intl, params, "history");
            this.setState({loadedData: false});
            this.setState({rowData: []});
        }
    }
    loadHistoryTableGrid() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "history") {
            let requestMap = APTableStore.getState().requestMap;
            let barRejected = requestMap["barRejectedCapacity"];
            let totalCap = requestMap["licensetotalcapacity"];
            let resvCap = requestMap["reservedcapacity"];
            let rowDataStore = APTableStore.getState().reportSearchList;
            this.setState({licensetotalcapacity: totalCap});
            this.setState({barRejectedCapacity: barRejected});
            this.setState({reservedcapacity: resvCap});
            if(rowDataStore){
                rowDataStore.every((item,index)=>{if(item.productFeature){
                    this.setState({productFeatureFlag:true});
                    return false;
                    }
                });                
            }
            this.setState({rowData: rowDataStore});
            let sortIndex = APTableStore.getState().sortIndex;
            let sortAscending = APTableStore.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            this.setState({defaultSelIds: APTableStore.getState().defaultIds});
            this.setState({disabledRows: APTableStore.getState().defDisabledIds});
            this.setState({defaultSelIdsToValidate: APTableStore.getState().defaultIds});
            this.setState({clientInfoMap: APTableStore.getState().clientInfoMap});
            this.setState({executedByMap: APTableStore.getState().executedByMap});
            let pagination = APTableStore.getState().pagination;
            pagination.required = true;
            pagination.style = "default";
            this.setState({pagination: pagination});
            this.setState({exportHistoryRecordSize: APTableStore.getState().exportHistoryRecordSize});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    tooltipLine(xValue) {
        if(this.state.toolTipMap) {
            return this.state.toolTipMap[xValue];
        } else {
            return "";
        }
    }

    buildHeadersForGrid() {
        let headers = [];
        //let intl = this.context.intl;
        headers.push({
            columnName: "licenseStartDate",
            headerLabel: this.findLabel("usage.search.startdate.label"),
            width: "auto",
            columnKey: "licenseStartDate",
            sort: true,
        });
        headers.push({
            columnName: "licenseExpiryDate",
            headerLabel: this.findLabel("usage.search.expdate.label"),
            href: false,
            width: "auto",
            sort: true,
        });
        headers.push({
            columnName: "licenseType",
            headerLabel: this.findLabel("usage.search.checkouttype.label") ,
            href: false,
            width: "auto",
            sort: true,
        });
        headers.push({
            columnName: "units",
            headerLabel: this.findLabel("usage.search.capacity.label"),
            href: false,
            width: "auto",
            sort: true,
        });
        headers.push({
            columnName: "ipAddress",
            headerLabel: this.findLabel("usage.search.clientinfo.label"),
            width: "auto",
            href: true,
            columnKey: "id",
            onClick: this.onClickClientInfo,
        });
        headers.push({
            columnName: "checkedInBy",
            headerLabel: this.findLabel("usage.search.executedby.label"),
            width: "auto",
            href: true,
            columnKey: "id",
            onClick: this.onClickExecutedByInfo,
        });
        headers.push({
            columnName: "lastCheckedInDate",
            headerLabel: this.findLabel("usage.search.checkintime.label"),
            href: false,
            width: "auto",
            sort: true,
        });
        headers.push({
            columnName: "groupsId",
            headerLabel: this.findLabel("usage.search.pool.label"),
            href: false,
            width: "auto",
        });
        //if(localStorage.getItem("isTokenBaseFeature") === "0") {
        if(this.state.productFeatureFlag){
            headers.push({
                columnName: "productFeature",
                headerLabel: this.findLabel("usage.search.product.info.label"),
                href: false,
                width: "auto"
            });
        }
       // }
        return headers;
    }
    onRowClicked(keys, event){
        
    }
    render() {
        //let intl = this.context.intl;
        let hideInfo = "tooltipContent tooltipContentHide";
        let hideExcutedInfo = "tooltipContent tooltipContentHide";
        let infoObj = "";
        let executeObj = "";
        if(this.state.clientInfoObj !== "") {
            infoObj = this.state.clientInfoObj;
            hideInfo = "tooltipContent tooltipContentshow";
        }
        if(this.state.executedByInfoObj !== "") {
            executeObj = this.state.executedByInfoObj;
            hideExcutedInfo = "tooltipContent tooltipContentshow";
        }
        let gDataLength = this.state.graphData.length;
        /*let xAxisVal = this.state.maximumCapacity;
        if(this.state.selGroupId === "0") {
            xAxisVal = xAxisVal + this.state.rejectedCapacity;
        }*/
        let colorScale = ""; //d3.scale.category20().range(["#1668c1", "rgb(229, 0, 76)"]);
        if(this.state.poolId !== "0" && this.props.groupColorPalette) {
            //colorScale = d3.scale.category20().range([this.props.groupColorPalette[this.state.poolColorIndexById[this.state.poolId]]]);
        }
        let xAxisLabel = this.findLabel("graph.year.lable");
        if(this.props.usageStartDate && this.props.usageEndDate) {
            let start = new Date(this.props.usageStartDate);
            let end = new Date(this.props.usageEndDate);
            var oneDay = 1000*60*60*24;
            var daysRange = end.getTime() - start.getTime();
            var dayscount =  Math.round(daysRange/oneDay);
            if(dayscount === 1){
                xAxisLabel = this.findLabel("graph.hours.lable");
            } else if (dayscount <= 31) {
                xAxisLabel = this.findLabel("graph.day.lable");
            } else if (dayscount > 31 && dayscount < 218) {
                xAxisLabel = this.findLabel("graph.week.lable");
            } else if (dayscount >= 218 && dayscount < 931) {
                xAxisLabel = this.findLabel("graph.month.lable");
            }
        }
        let yMaxValue = this.state.yAxisMaxValue;
        let resvCapValue = 0;
        if(this.state.reservedcapacity !== undefined) {
            resvCapValue = Number(this.state.reservedcapacity);
            if(resvCapValue > 0) {
                yMaxValue = resvCapValue;
            }
        }
        // const yScale = d3.scaleLinear()
        //     .domain([0, yMaxValue])
        //     .range([240, 27]);
        //const yScale =""; 
        //let xScale = d3.time.scale();
        //let svg;// = d3.selectAll("svg");
        if(resvCapValue > 0) {
            // svg.append("line")
            //     .attr("id","thresholdcapacity")
            //     .attr("x1", 50).attr("y1", yScale(yMaxValue))
            //     .attr("x2", 800)
            //     .attr("y2",	yScale(yMaxValue))
            //     .attr("stroke-width", 2)
            //     .style("font-family", "Metic_Light")
            //     .attr("stroke", "#eb6a42");
                //yScale.range([240, 17]);
        } else {
            //svg.selectAll("line").remove();
        }
        // svg.selectAll(".historyXAxis text").attr("transform", "rotate(-40)").attr("dy", ".50em");
        // svg.selectAll(".historyXAxis .label").attr("transform", "rotate(0)").attr("x", "0").attr("y", "12");
        // svg.selectAll(".historyYAxis text").attr("transform", "rotate(0)");
        // svg.selectAll(".historyYAxis .label").attr("y", "-10");
        let headers = this.buildHeadersForGrid()
        return (
            <div style={{float: "left", padding: "7px", marginBottom: "6px"}} className="inuse-cont-backgroud">
                {gDataLength > 0 ? <div style={{width: "100%", float: "left"}}>
                    <div style={{width: "70%", float: "left", height: "340px", paddingTop: "46px", whiteSpace: "nowrap", display: "inline-block", backgroundColor: "#FFFFFF"}}>
                        <div style={{width: "100%", float: "left"}}>
                            <table>
                                <tbody>
                                <tr>
                                    <td id="inuse_history_graph_id">
                                        {/* <BarChart
                                            //groupedBars
                                            data={this.state.graphData}
                                            width={800}
                                            height={320}
                                            yAxis={{innerTickSize: 10, label: this.findLabel("graph.max.lable", intl), className: "historyYAxis"}}
                                            xAxis={{label: xAxisLabel, className: "historyXAxis"}}
                                            colorScale={colorScale}
                                            tooltipHtml={this.tooltipLine}
                                            tooltipMode="mouse"
                                            tooltipContained ={true}
                                            yScale={yScale}
                                            margin={{top: 10, bottom: 70, left: 50, right: 10}}
                                        /> */}
                                        {/* <ResponsiveContainer width="100%" height="100%"> */}
                                        <BarChart
                                            //groupedBars
                                            data={this.state.graphData}
                                            width={800}
                                            height={320}
                                            // yAxis={{innerTickSize: 10, label: this.findLabel("graph.max.lable"), className: "historyYAxis"}}
                                            // xAxis={{label: xAxisLabel, className: "historyXAxis"}}
                                            // colorScale={colorScale}
                                            // tooltipHtml={this.tooltipLine}
                                            // tooltipMode="mouse"
                                            // tooltipContained ={true}
                                            // yScale={yScale}
                                            margin={{top: 25, bottom: 25, left: 10, right: 10}}
                                        >
                                        <XAxis dataKey="label" label={{value: xAxisLabel, offset:12, position:"bottom"}} className="historyXAxis" interval={0} angle={-40}/>
                                        <YAxis type="number" domain={[0, dataMax => (yMaxValue+this.state.rejectedCapacity)]} tickCount={10} interval={0} className="historyYAxis" label={{value: this.findLabel("graph.max.lable"), offset:12, position:"top"}}/>
                                        <Tooltip content={<CustomTooltip />} cursor={false}/>
                                        <ReferenceLine y={yMaxValue} stroke="red" />
                                        {/* <Legend /> */}
                                        <Bar dataKey="max" stackId="a" fill="rgb(22, 104, 193)" />
                                        <Bar dataKey="reject" stackId="a" fill="rgb(229, 0, 76)" />
                                        </BarChart>
                                        {/* </ResponsiveContainer> */}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{width: "100%", float: "left", backgroundColor: "#FFFFFF"}}>
                            <table>
                                <tbody>
                                <tr>
                                    {this.state.maximumChecked && this.state.poolId === "0" ? <td align="right">
                                        {this.findLabel("usage.graph.history.legend.label")}
                                    </td> : null}
                                    {this.state.maximumChecked && this.state.poolId === "0" ? <td style={{paddingLeft: "10px"}}>
                                        <div id="history_maximum_label_legend_id" style={{"width": "10px", "height": "10px", "background": "#1668c1", "opacity": "0.8", float: "left", marginTop: "5px", marginRight: "2px", border: "2px solid #9e9c9c"}}></div>
                                        <span id="history_maximum_label_id" style={{"color": "#1668c1", paddingLeft: "0.5%", "opacity": "0.8"}}>{this.findLabel("usage.graph.history.maxusage.label")}</span>
                                    </td> : null}
                                    {this.state.maximumChecked && this.state.poolId === "0" ? <td style={{paddingLeft: "10px"}}>
                                        <div id="history_rejected_label_legend_id" style={{"width": "10px", "height": "10px", "background": "#e5004c", "opacity": "0.8", float: "left", marginTop: "5px", marginRight: "2px", border: "2px solid #9e9c9c"}}></div>
                                        <span id="history_rejected_label_id" className="rejectedCls" style={{paddingLeft: "0.5%", "opacity": "0.8", "color": "#e5004c", "cursor": "pointer", textDecoration: "underline"}} onClick={this.props.rejectedAction}>{this.findLabel("usage.graph.history.rejected.label")} </span>
                                    </td> : null}
                                    <td style={{paddingLeft: "300px"}}>
                                        {/*<RadioButton id="history_maximum_radio_id" label={this.findLabel("usage.graph.history.maximum.label", intl)} reverse="true" checked={this.state.maximumChecked} onClick={this.handleChangeMaximum}/>*/}
                                    </td>
                                    <td>
                                        {/*<RadioButton id="history_average_radio_id" label={this.findLabel("usage.graph.history.average.label", intl)} reverse="true" checked={this.state.avarageChecked} onClick={this.handleChangeAverage}/>*/}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{width: "24%", float: "left", padding: "10px", paddingTop: "120px", backgroundColor: "#FFFFFF"}}>
                        <table style={{width: "100%"}}>
                            <tbody>
                            <tr>
                                <td id="histroy_graph_max_capacity_label_id" style={{width: "35%"}}>
                                    {this.findLabel("usage.graph.history.maximum.label")}
                                </td>
                                <td id="histroy_graph_max_capcity_value_id" align="right" style={{color: "#0073E7", fontSize: "30px", textAlign: "left"}}>
                                    : {this.state.maximumCapacity}
                                </td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #ccc"}}>
                                <td id="histroy_graph_max_average_label_id" style={{width: "35%"}}>
                                    {this.state.viewType !== "day" ? this.findLabel("usage.graph.history.average.maximum.label") : this.findLabel("usage.graph.history.average.label")} 
                                </td>
                                <td id="histroy_graph_max_average_value_id" align="right" style={{color: "#0073E7", fontSize: "30px", textAlign: "left"}}>
                                    : {this.state.averageCapacity}
                                </td>
                            </tr>
                            {this.state.viewType !== "day" &&(
                                <tr>
                                    <td id="histroy_graph_range_avg_label_id" style={{width: "35%"}}>
                                        {this.findLabel("usage.graph.history.average.label")} 
                                    </td>
                                    <td id="histroy_graph_range_avg_label_value_id" align="right" style={{color: "#0073E7", fontSize: "30px", textAlign: "left"}}>
                                       : {this.state.rangeAverage}
                                    </td>
                                </tr>
                            )}
                                <tr>
                                <td id="histroy_graph_max_rejected_label_id" style={{width: "35%"}}>
                                    {this.findLabel("usage.graph.history.rejected.label")} 
                                </td>
                                <td id="histroy_graph_max_rejected_value_id" align="right" style={{color: "#0073E7", fontSize: "30px", textAlign: "left"}}>
                                    : {this.state.rejectedCapacity}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> : 
                <div style={{width: "100%", float: "left", height: "200px", textAlign: "center", backgroundColor: "#FFFFFF"}}>
                    <div style={{paddingTop: "70px"}}>
                        <span id="no_history_found_content_id" style={{paddingTop: "50px", fontSize: "26px", "color": "rgb(180, 180, 180)"}}>{this.findLabel("usage.graph.history.show.msg1")}<br/><br/>
                        {this.findLabel("usage.graph.history.show.msg2")}</span>
                    </div>
                </div> }
                <div className="tooltipMain">
                    <div style={{"top": this.state.popupTop, "left": this.state.popupLeft}} id="history_client_info_id" className={hideInfo}>
                        <ClientInfoPopup 
                            ipAddress = {infoObj.ipAddress}
                            userName = {infoObj.userName}
                            hostID = {infoObj.hostID}
                            productCode = {infoObj.productCode}
                            clientUniqueId = {infoObj.clientUniqueId}
                            namedUserId = {infoObj.namedUserId}
                            applicationName = {infoObj.applicationName}
                            closeTootipAction = {this.closeTootipAction}
                            showApplicationName = {true}
                        />
                    </div>
                    <div style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={hideExcutedInfo} id="history_executed_by_info_id">
                        <ExecutedByInfo 
                            checkedOutBy = {executeObj.checkedOutBy}
                            checkedInBy = {executeObj.checkedInBy}
                            closeTootipAction={this.closeTootipAction}
                        />
                    </div>
                </div>
                <div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <TableGrid id="usage_history_list_grid"
                        header={headers}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={3}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        defaultSelectIds={this.state.defaultSelIds}
                        disabledRowIds={this.state.disabledRows}
                        rowKey="idRevokedId"
                        noDataFoundText={this.findLabel("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div id="history_export_limit_value_id" style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    {this.findLabel("usage.graph.export.limit.label")} {this.state.exportHistoryRecordSize}
                </div>
                {/*<div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <Button id="usage_history_backBtn" label={this.findLabel("label.button.back", intl)} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                </div>*/}
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// HistoryUsageGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default HistoryUsageGraph;