import ReduceStore from "./ReduceStore";

class UserDetailStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            userDetail: undefined,
            user: undefined,
            groups: undefined
        };
    }
    reduce(action, state) {
        switch (action.type) {
            // case "USER_VIEW_PAGE_FETCH_COMPLETE": {
            //         return {
            //             ...state,
            //             userDetail: action.payload.userDetail,
            //         };
            //     }
            case "USER_DETAIL_FETCH_COMPLETE": {
                    return { ...state, user: action.payload.user, 
                        groups: action.payload.groupList 
                    };
                }
            case "REFRESH_AVATAR": {
	                return { ...state, type: "REFRESH_STATE"
	                };
             	}
            case "USER_SEARCH_PAGE_FETCH_COMPLETE" :{
                return{

                    ...state,
                    userGrpData: action.payload.tableData,
                    userMap : action.payload.userMap,
                    //pagination: action.payload.pagination,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    };
            } case "USER_ROLE_FETCH_COMPLETE": {
                return { status: action.payload };
            }
            default:
                    return state;
        }
    }
}

export default new UserDetailStore();