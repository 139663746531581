import ReduceStore from "./ReduceStore";

class AlertModalStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            message: "",
            title: "",
            showModal: false
        };
    }
    reduce(action, state) {
        if (action.type.endsWith("ALERT_SHOW_MODAL")) {
            return { ...state, showModal: true, message: action.payload };
        } else if (action.type.endsWith("CLOSE_MODAL")) {
            return { ...state, showModal: false, message: action.payload };
        } else if (action.type.endsWith("ALERT_SHOW_TITLE_MODAL")) {
            return { ...state, showModal: true, message: action.payload.content,
                        title: action.payload.title };
        }
        return state;
    }
}

export default new AlertModalStore();