import React from 'react';
import Dispatcher from "../flux/Dispatcher";
import * as ValidationUtility from "../utils/ValidationUtility";
import * as DateUtility from "./DateUtility";
import { Download } from 'grommet-icons';
import { Trash as TrashIcon } from 'grommet-icons';
import * as FetchHelper from "../utils/FetchHelper";
import { decryptText } from "../utils/Util";
import { getLocaleMessage, getLocaleLabel } from "../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

export function fnValidateIPAddress(ipaddr) {
    ipaddr = ipaddr.replace( /\s/g, ""); //remove spaces for checking
    var reIPV4 = /^((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*])))$/;
    var reIPV4Range  = /^(((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*])))-((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|[1-9])\.((?:(?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))\.){2}((?:(2(([0-4]{1}[0-9]{1})|(5[0-5]{1})))|1\d{2}|[1-9]\d|\d)|([*]))))$/;

    if (reIPV4.test(ipaddr)) {
        return true;
    } else {
        if (reIPV4Range.test(ipaddr)) {
            var ipaddra = ipaddr.split('-');
            if (((ipaddra[1].indexOf("*")===-1) && (ipaddra[0].indexOf("*")===-1)) && compare(ipaddra[1],ipaddra[0])>0) {
                return true;
            }else if (compare(ipaddra[1],ipaddra[0])===0) {
                return false;
            }else if ((ipaddra[1].indexOf("*")!==-1) && (ipaddra[0].indexOf("*")===-1)) {
                    let index  = ipaddra[1].indexOf("*") ;
                    ipaddra[1] = ipaddra[1].substring(0,index) ;
                    ipaddra[0] = ipaddra[0].substring(0,index) ; 
                    if(compare(ipaddra[1],ipaddra[0])>=0){
                        return true;
                    } else {
                        return false;
                    }
            }else if ((ipaddra[0].indexOf("*")!==-1)) {
                    let index  = ipaddra[0].indexOf("*") ;
                    ipaddra[1] = ipaddra[1].substring(0,index) ;
                    ipaddra[0] = ipaddra[0].substring(0,index) ; 
                    if(compare(ipaddra[1],ipaddra[0])>=0){
                        return true;
                    } else {
                        return false;
                    }
            }else {
                return false;
            }
        } else if (ipaddr.indexOf(":") !== -1 && ipaddr.indexOf("[") !== -1 && ipaddr.indexOf("]") !== -1) {
            return true;
        }else if (ipaddr === "*.*.*.*") {
            return true;
        }else {
            return false;
        }
    }
}
export function validate(data, intl) {						
    var mailserver = data.mailServerName;
    var adminmail 	= data.adminEmail;	
    var port = data.portNumber;
    var proxyPort = data.proxyPortNumber;
    var instanceIdentifier= data.instanceIdentifier;
    //var username = data.userName;
    //var searchReg = new RegExp("^[a-zA-Z0-9._-~@;,%:#/*|]+$");
    var serverHostPattern = new RegExp("[a-zA-Z0-9\\-._/~@;:#|%,=()*% \"//\\\\s%20]*");    
    var proxyAddressReg = /^[a-zA-Z0-9~@#%*_\-;:\\|,.\/]*$/;
    let errorsMap = {};
    let apiKey = data.apiKey;
    let apiKeyExpireDays = data.apiKeyExpireDays;
    if(isEmpty(mailserver)=== false && isEmpty(adminmail) === false){     			
        errorsMap["adminmail"] = findLabel("configpageAlert4");
        errorsMap["mailserver"] = findLabel("configpageAlert3");
        //showErrorMessage2("configpageAlert4", "configpageAlert3", intl, " ");
    }
    if(isEmpty(mailserver)=== true && isEmpty(adminmail) === false){					
        //showErrorMessage1("configpageAlert4");
         errorsMap["adminmail"] = findLabel("configpageAlert4");
    }	 
    if(isEmpty(mailserver)=== false && isEmpty(adminmail)=== true){					
        //showErrorMessage1("configpageAlert3");
        errorsMap["mailserver"] = findLabel("configpageAlert3");
    }	
    if(!(validateEmail (adminmail))){  
        //showErrorMessage1("configpageAlert6");
        errorsMap["adminmail"] = findLabel("configpageAlert6");
    }	
    
    // if((isEmpty(username) === false && !searchReg.test(username))){
    //     //alert("Please enter User Name with out using special characters");
    //     errorsMap["username"] = findLabel("config.util.alert1");
    // }

    if(!isEmpty (port)){  
        //showErrorMessage1("configpageAlert47");			        
        errorsMap["mailPort"] = findLabel("configpageAlert47");
    } else if (isNaN(port)) {				
        //showErrorMessage1("configpageAlert46");	               
        errorsMap["mailPort"] = findLabel("configpageAlert46");
    }
    if(isEmpty (instanceIdentifier)){  
        if( instanceIdentifier.length>64){ 			        
            errorsMap["mailInstance"] = findLabel("configpageAlert48");
        }
        if(!ValidationUtility.validateSpecialChars(instanceIdentifier)){
            errorsMap["mailInstance"] = findLabel("enter.valid.chars.for.text");
        }
    }
    if((isEmpty(data.proxyAddress)=== true && !proxyAddressReg.test(data.proxyAddress))){
        //alert("Please enter Proxy Address with out using special characters");
        errorsMap["proxyAddress"] = findLabel("config.util.alert2");
    }
    
    if (isEmpty(data.proxyAddress) && !isEmpty(proxyPort.trim())) {				
        //showErrorMessage1("configpageAlert96");             
        errorsMap["proxyAddress"] = findLabel("configpageAlert96");
    }
    if((isEmpty(data.protocolHost)=== true && !serverHostPattern.test(data.protocolHost))){
        ///alert("Please enter Server IP/Host with out using special characters");
        errorsMap["serverConfigIpOrHost"] = findLabel("config.util.alert2");
    }
    /*if(apiKey === "") {
        errorsMap["apiKey"] = findLabel("message.api.key.not.empty");
    }*/

    if(apiKey !== "" && apiKey !== undefined && apiKey.length > 20) {
        errorsMap["apiKey"] = findLabel("message.api.key.length");
    }
    /*if(apiKeyExpireDays ==="") {
        errorsMap["apiKeyExpireDays"] = findLabel("message.api.key.expire.not.empty");
    }*/

    if(apiKeyExpireDays !== "" && apiKeyExpireDays !== undefined && apiKeyExpireDays.length > 7) {
        errorsMap["apiKeyExpireDays"] = findLabel("message.api.key.expire.length");
    }

    if(apiKeyExpireDays !== "" && apiKeyExpireDays !== undefined && isNaN(apiKeyExpireDays)) {
        errorsMap["apiKeyExpireDays"] = findLabel("message.api.key.expire.number");
    }
    if(apiKeyExpireDays !== "" && apiKeyExpireDays !== undefined && Number(apiKeyExpireDays) <= 0) {
        errorsMap["apiKeyExpireDays"] = findLabel("label.api.key.expire.negative");
    }

    if (isEmpty (proxyPort) && isNaN(proxyPort)) {				
        //showErrorMessage1("configpageAlert95");              
        errorsMap["proxyPort"] = findLabel("configpageAlert95");
    }

    var notifyDays = data.noOfNotifyAdvance;  
    if(isNaN(notifyDays)){	
        //showErrorMessage1("configpageAlert511");
        errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert511");
    } else if(data.isAdvanceSetting && !isEmpty(notifyDays)){				
        //showErrorMessage1("configpageAlert511");
        errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert511");
    }
    if((data.noOfNotifyAdvance === null || data.noOfNotifyAdvance === "") && data.isAdvanceSetting){
        //showErrorMessage1("configpageAlert511");
        errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert511");
    } else if(data.noOfNotifyAdvance < 1  && data.isAdvanceSetting){
        //showErrorMessage1("configpageAlert521");
        errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert521");
    }
    if(notifyDays > 0 && data.isAdvanceSetting){
        if(isOnlyWhitespace(data.noOfNotifyAdvance) !== true){				
            let port = data.noOfNotifyAdvance;	
            if(isNaN(port)){	
                //showErrorMessage1("configpageAlert5");
                errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert5");
            }
        }	
        else{
            //showErrorMessage1("configpageAlert7");
            errorsMap["noOfNotifyAdvance"] = findLabel("configpageAlert7");
        }
    }
    
    if(data.isAutoPurge){	
        if(data.isPurgeRecordOlder) {	
            var purgedays = data.purgeRecordsOlder;  
            if(purgedays.length > 0){							
                if(isOnlyWhitespace(data.purgeRecordsOlder) !== true){
                    if(isNaN(purgedays)){	
                        //showErrorMessage1("configpageAlert5");
                        errorsMap["purgeRecordsOlder"] = findLabel("configpageAlert5");
                    }
                    else{
                        if(Number(data.purgeRecordsOlder)<=0 
                                || Number(data.purgeRecordsOlder)>2147483647){
                            //showErrorMessage1("configpageAlert8");
                            errorsMap["purgeRecordsOlder"] = findLabel("configpageAlert8");
                        }	
                    }
                }	
                else{
                    //showErrorMessage1("configpageAlert7");
                    errorsMap["purgeRecordsOlder"] = findLabel("configpageAlert7");	
                }
            }
            else{					
                //showErrorMessage1("configpageAlert8");
                errorsMap["purgeRecordsOlder"] = findLabel("configpageAlert8");
            }	    
        }				
        else if(!data.isPurgeRecordOlder){
            var purgesize = data.maxRecrodsRetain;  
            if(purgesize.length > 0){							
                if(isOnlyWhitespace(purgesize) !== true){
                    if(isNaN(purgesize)){	
                        //showErrorMessage1("configpageAlert5");
                        errorsMap["maxRecrodsRetain"] = findLabel("configpageAlert5");
                    }
                    else{
                        if(Number(purgesize)<=0
                                || Number(purgesize)>2147483647){
                            //showErrorMessage1("configpageAlert10");
                            errorsMap["maxRecrodsRetain"] = findLabel("configpageAlert10");
                        }	
                    }
                }	
                else{
                    //showErrorMessage1("configpageAlert7");
                    errorsMap["maxRecrodsRetain"] = findLabel("configpageAlert7");
                }
            }
            else{	
                //showErrorMessage1("configpageAlert10");
                errorsMap["maxRecrodsRetain"] = findLabel("configpageAlert10");
            }   
        }
        else{	
            //showErrorMessage1("configpageAlert13");
            errorsMap["purgeType"] = findLabel("configpageAlert13");
        }							
    }	    			
    var portlength = data.protocolPort;      
    if(portlength !== undefined && portlength.length > 0){
        if(isOnlyWhitespace(portlength) !== true){				
            let port = portlength;
            if(isNaN(port)){	
                //showErrorMessage1("configpageAlert14");
                errorsMap["serverConfigPort"] = findLabel("configpageAlert14");
            }
        }	
        else{
            //showErrorMessage1("configpageAlert15");
            errorsMap["serverConfigPort"] = findLabel("configpageAlert15");
        }
    }
    
    let protocolType = data.serverScheme;
    let changed = false;
    if(data.protoTypeHTTPIdChecked){	
        if(protocolType === 'https'){
            changed = true;
        }
    }else if(data.protoTypeHttpsIdChecked){	
        if(protocolType === 'http'){
                changed = true;
            }
    }
    if(changed === true){
        errorsMap["protocoTypeChanged"] = "Y";
    }
    return errorsMap;
}
export function validateUMConfig(data, intl, onConfirmAction) {	
    var typeofimpl = data.implemention;
    if(typeofimpl==='DB'){
        var radioselected = data.userPasswordExpire;	
        if(radioselected ==='pe'){
            if(!data.resetPasswordChecked && !data.changePasswordChecked){
                //alert('<s:property value="%{getText(\'config.alert17\')}" escapeHtml="false" />');
                showErrorMessage1("configpageUMAlert17");						  
                return false;
            }   
            
            if(data.changePasswordChecked) {				 
                if(data.changePasswordDays && Number(data.changePasswordDays)>=0 ){
                    if(Number(data.changePasswordDays)<=5){
                        //alert('<s:property value="%{getText(\'config.alert18\')}" escapeHtml="false" />');
                        showErrorMessage1("configpageUMAlert18");						  
                        return false;
                    }
                }else{
                    //alert('<s:property value="%{getText(\'config.alert19\')}" escapeHtml="false" />');
                    showErrorMessage1("configpageUMAlert19");
                    return false;
                }					 
            }     
        }
        if(data.resetPasswordChecked || data.changePasswordChecked){         
            if(!data.usrPassExpiredChecked || data.usrPassNeverExpiredChecked){
                //alert('<s:property value="%{getText(\'config.alert20\')}" escapeHtml="false" />');
                showErrorMessage1("configpageUMAlert20");
                return false;
            }
        }
        if(data.changePasswordChecked){	
            if(data.changePasswordDays && Number(data.changePasswordDays)>=0 ){
                if(Number(data.changePasswordDays)<=5){
                    //alert('<s:property value="%{getText(\'config.alert18\')}" escapeHtml="false" />');
                    showErrorMessage1("configpageUMAlert18");	
                    return false;
                }
            }else{
                //alert('<s:property value="%{getText(\'config.alert19\')}" escapeHtml="false" />');
                showErrorMessage1("configpageUMAlert19");				
                return false;
            }
        } else {
            if(data.changePasswordDays && Number(data.changePasswordDays)>=0 ){
                //alert('<s:property value="%{getText(\'config.alert23\')}" escapeHtml="false" />');
                showErrorMessage1("configpageUMAlert23");	
                return false;
            }		         
        }	
    }
    var configGroup = data.configGroupSetting;
    var configUser = data.configUserSetting;
    var result = null;
    var user = data.usersSource;
    var group = data.groupsSource;	
    if(configGroup !== group){                 
        if(configGroup === "database" && group === "ldap"){
            let msg = getLocaleMessage("configpageUMAlert24")+
                '\n'+getLocaleMessage("configpageUMAlert25")+
                '\n'+getLocaleMessage("configpageUMAlert26");
            ValidationUtility.confirm(msg,onConfirmAction,"","");
            return false;/** ValidationUtility.confirm() decides if user accepts send true as response.*/
        }
        if(configGroup === "ldap" && group === "database"){
            let msg = getLocaleMessage("configpageUMAlert27")+
                '\n'+getLocaleMessage("configpageUMpage")+
                '\n'+getLocaleMessage("configpageUMAlert28")+
                '\n'+getLocaleMessage("configpageUMAlert29")+
                '\n'+getLocaleMessage("configpageUMAlert30");
            ValidationUtility.confirm(msg,onConfirmAction,"",""); 
            return false;/** ValidationUtility.confirm() decides if user accepts send true as response.*/          	 
        }
        if(result === false){
            return false;
        }
    }
    if(configUser !== user){            	 
        if(configUser === "database" && user === "ldap"){
            let msg = getLocaleMessage("configpageUMAlert31")+
                '\n'+getLocaleMessage("configpageUMAlert32")+
                '\n'+getLocaleMessage("configpageUMAlert33")+
                '\n'+getLocaleMessage("configpageUMAlert30");
            ValidationUtility.confirm(msg,onConfirmAction,"","");
            return false;/** ValidationUtility.confirm() decides if user accepts send true as response.*/
        }
        if(configUser === "ldap" && user === "database"){
            let msg = getLocaleMessage("configpageUMAlert35")+
                '\n'+getLocaleMessage("configpageUMpage")+
                '\n'+getLocaleMessage("configpageUMAlert28")+
                '\n'+getLocaleMessage("configpageUMAlert29")+
                '\n'+getLocaleMessage("configpageUMAlert30");
            ValidationUtility.confirm(msg,onConfirmAction,"","");
            return false;/** ValidationUtility.confirm() decides if user accepts send true as response.*/
        }
        if(user === "idm"){
            let msg = getLocaleMessage("configpageUMAlert26");
            ValidationUtility.confirm(msg,onConfirmAction,"","");
            return false;/** ValidationUtility.confirm() decides if user accepts send true as response.*/
        }
    }
    return true;	
}
export function validateLDAPConfiguration(data, intl) {
    let errorMap = {};
    errorMap = validateLDAPConn(data, intl, errorMap);
    errorMap = validateLDAPauth(data,intl, errorMap);
    errorMap = validateUserElement(data, intl, errorMap);
    errorMap = validateGroupElement(data, intl, errorMap);
    return errorMap;
}
function validateLDAPConn(data, intl, errorMap) {
    let hostname = data.ldapHostNameValue;
    let port = data.ldapPortValue;
    if(!isEmpty(hostname)){
        //showErrorMessage1("configpageLDAPAlert70");
        errorMap["hostname"] = findLabel("configpageLDAPAlert70");
    }
    if(!isEmpty(port)){
        //showErrorMessage1("configpageAlert47");
        errorMap["port"] = findLabel("configpageAlert47");
    }
    if (isNaN(port)) {				
        //showErrorMessage1("configpageAlert46");
        errorMap["port"] = findLabel("configpageAlert46");
    }
    if(port<1){
        //showErrorMessage1("configpageUMAlert521");
        errorMap["port"] = findLabel("configpageUMAlert521");
    }
    return errorMap;
}
function validateLDAPauth(data, intl, errorMap){
    let AuthMethod = data.ldapAuthMethodValue;
    let ldapAuthUser = data.ldapAuthUserName;
    let ldapUserPass = data.ldapAuthPassword;
    if(AuthMethod.label==="Simple" && AuthMethod.value==="true"){
        if(!isEmpty(ldapAuthUser)){
            errorMap["ldapAuthUserName"] = findLabel("configpageIDMAlert72");
        }
        if(!isEmpty(ldapUserPass)){
            errorMap["ldapAuthPassword"] = findLabel("configpageIDMAlert73");
        }
    }
    
    return errorMap;
}
function validateUserElement(data, intl, errorMap) {
    let basedn = data.ldapUserBaseValue;
    let usersubtree = data.ldapSubtreeKey;			
    let useridattr = data.uniqueIdAttributeValue;
    let fnameattr = data.firstNameAttributeValue;
    let emailidattr = data.emailAttributeValue;
    let lnameattr = data.lastNameAttributeValue;
    let userFilter = data.userFilterValue;
    if(!isEmpty(basedn)){
        //showErrorMessage1("configpageLDAPAlert74");
        errorMap["basedn"] = findLabel("configpageLDAPAlert74");
    }
    if(!isEmpty(usersubtree)){
        //showErrorMessage1("configpageLDAPAlert82");
        errorMap["usersubtree"] = findLabel("configpageLDAPAlert82");
    }
    
    if(!isEmpty(useridattr)){
        //showErrorMessage1("configpageLDAPAlert76");
        errorMap["useridattr"] = findLabel("configpageLDAPAlert76");
    }
    if(!isEmpty(fnameattr)){
        //showErrorMessage1("configpageLDAPAlert77");
        errorMap["fnameattr"] = findLabel("configpageLDAPAlert77");
    }
    if(!isEmpty(lnameattr)){
        //showErrorMessage1("configpageLDAPAlert93");
        errorMap["lnameattr"] = findLabel("configpageLDAPAlert93");
    }
    if(!isEmpty(emailidattr)){
        //showErrorMessage1("configpageLDAPAlert78");
        errorMap["emailidattr"] = findLabel("configpageLDAPAlert78");
    }
    if(!isEmpty(emailidattr)){
        //showErrorMessage1("configpageLDAPAlert78");
        errorMap["emailidattr"] = findLabel("configpageLDAPAlert78");
    }
    if(!isEmpty(userFilter)){
        //showErrorMessage1("configpageLDAPAlert78");
        errorMap["userFilter"] = findLabel("configpageLDAPAlert86");
    }
    return errorMap;
}
export function validateIDMConfiguration(data, intl) {
    let hostname = data.idmServerUrlValue;
    let username = data.idmUserNameValue;
    let password = data.idmPasswordValue;
    let tenantinfo = data.idmTenentIdValue;
    let errorMap = {};
    if (!isEmpty(hostname)) {
        //showErrorMessage1("configpageIDMAlert1184");
        errorMap["hostname"] = findLabel("configpageIDMAlert1184");
    }

    if (!isEmpty(username)) {
        //showErrorMessage1("configpageIDMAlert72");
        errorMap["username"] = findLabel("configpageIDMAlert72");
    }
    if (!isEmpty(password)) {
        //showErrorMessage1("configpageIDMAlert73");
        errorMap["password"] = findLabel("configpageIDMAlert73");
    }
    if (!isEmpty(tenantinfo)) {
        //showErrorMessage1("configpageIDMAlert1185");
        errorMap["tenantinfo"] = findLabel("configpageIDMAlert1185");
    }
    return errorMap;
}

export function validateIDMConfigurationForCTTenant(data, intl) {
    let hostname = data.tenantIdmServerUrlValue;
    let username = data.tenantIdmUserNameValue;
    let password = data.tenantIdmPasswordValue;
    let ctUsageURL = data.ctTenantServerUrlValue;
    let errorMap = {};
  
    if (!isEmpty(hostname)) {
        //showErrorMessage1("configpageIDMAlert1184");
        errorMap["hostname"] = findLabel("configpageIDMAlert1184");
    }

    if (!isEmpty(username)) {
        //showErrorMessage1("configpageIDMAlert72");
        errorMap["username"] = findLabel("configpageIDMAlert72");
    }
    if (!isEmpty(password)) {
        //showErrorMessage1("configpageIDMAlert73");
        errorMap["password"] = findLabel("configpageIDMAlert73");
    }
    if (!isEmpty(ctUsageURL)) {
        //showErrorMessage1("configpageIDMAlert73");
        errorMap["ctUrlInfo"] = findLabel("ct.url.error");
    }     
    return errorMap;
}

export function validateIDMConfigurationForCTAsset(data, intl) {
    let hostname = data.assetIdmServerUrlValue;
    let username = data.assetIdmUserNameValue;
    let password = data.assetIdmPasswordValue;
    let ctUsageURL = data.ctAssetServerUrlValue;
    let errorMap = {};
  
    if (!isEmpty(hostname)) {
        errorMap["hostname"] = findLabel("configpageIDMAlert1184");
    }

    if (!isEmpty(username)) {
        errorMap["username"] = findLabel("configpageIDMAlert72");
    }
    if (!isEmpty(password)) {
        errorMap["password"] = findLabel("configpageIDMAlert73");
    }
    if (!isEmpty(ctUsageURL)) {
        errorMap["ctUrlInfo"] = findLabel("ct.url.error");
    }     
    return errorMap;
}

export function validateCTAssetUrl(data, intl) {
    let ctUrlInfo = data.ctAssetServerUrlValue;
    let freequency = data.ctUsageFreequency;
    let ctError ={};

    let hostname = data.assetIdmServerUrlValue;
    let username = data.assetIdmUserNameValue;
    let password = data.assetIdmPasswordValue;

    let featureList = data.featuresTenantsList;
    
    if (!ValidationUtility.isFieldNumber(freequency) || ValidationUtility.notZero(freequency)) { 
        ctError["freequency"] = findLabel("freequencyErrorMsg");
    }
    if (!isEmpty(hostname)) {
       ctError["hostname"] = findLabel("configpageIDMAlert1184");
    }

    if (!isEmpty(username)) {
        ctError["username"] = findLabel("configpageIDMAlert72");
    }
    if (!isEmpty(password)) {
        ctError["password"] = findLabel("configpageIDMAlert73");
    }

    if (!isEmpty(ctUrlInfo)) {
        ctError["ctUrlInfo"] = findLabel("ct.url.error");
    }
    //Aset ID unique validation
    // featureList.map((item,index) => {
    //    if(item.tenant === "" || isNaN(item.tenant)){
    //        item["tenantError"] = true;
    //        ctError["tenantError"] = findLabel("tenantError");
    //    }else{
    //        item["tenantError"] = false;
    //    }
    //    return item;
    // });
    // ctError["tenantErrorMap"] = featureList;
    return ctError;
}

export function validateCTUrl(data, intl) {
    let ctUrlInfo = data.ctTenantServerUrlValue;
    let freequency = data.ctUsageFreequency;
    let ctError ={};

    let hostname = data.tenantIdmServerUrlValue;
    let username = data.tenantIdmUserNameValue;
    let password = data.tenantIdmPasswordValue;

  //  let ctHost   = data.ctHostName;

    let featureList = data.featuresTenantsList;
    
    if (!ValidationUtility.isFieldNumber(freequency) || ValidationUtility.notZero(freequency)) { 

        //showErrorMessage1("configpageIDMAlert73");
        ctError["freequency"] = findLabel("freequencyErrorMsg"); 

    }
    if (!isEmpty(hostname)) {
        //showErrorMessage1("configpageIDMAlert1184");
        ctError["hostname"] = findLabel("configpageIDMAlert1184");
    }

    if (!isEmpty(username)) {
        //showErrorMessage1("configpageIDMAlert72");
        ctError["username"] = findLabel("configpageIDMAlert72");
    }
    if (!isEmpty(password)) {
        //showErrorMessage1("configpageIDMAlert73");
        ctError["password"] = findLabel("configpageIDMAlert73");
    }

    if (!isEmpty(ctUrlInfo)) {
        //showErrorMessage1("configpageIDMAlert73");
        ctError["ctUrlInfo"] = findLabel("ct.url.error");
    }
    
//    if (!ValidationUtility.validateHostName(ctHost)) {
//         ctError["ctHost"] = findLabel("ct.host.error");
//    }
    featureList.map((item,index) => {
       if(item.tenant && isNaN(item.tenant)){
           item["tenantError"] = true;
           ctError["tenantError"] = findLabel("tenantError");
       }else{
           item["tenantError"] = false;
       }
       return item;
    });
    ctError["tenantErrorMap"] = featureList;
    return ctError;
}

export function processIDMCTConfigurationTenant(data) {
    let type = "POPULATE_IDM_CT_TENANT_CONFIG_SETTING_DETAILS";
    let payload = {
        idmServerUrlValue: data.idmserver,
        idmPasswordValue: data.password,
        idmUserNameValue: data.username,
        ctServerUrlValue: data.ctUrl,
        ctUsageFreequency: data.freequency,
        maxUsageChecked: true,
      //  ctHostName:data.ctHostName,
        featuresTenantsList:data.featuresTenantsList

    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function processIDMCTConfigurationAsset(data) {
    let type = "POPULATE_IDM_CT_ASSET_CONFIG_SETTING_DETAILS";
    let payload = {
        idmServerUrlValue: data.idmserver,
        idmPasswordValue: data.password,
        idmUserNameValue: data.username,
        ctServerUrlValue: data.ctUrl,
        ctUsageFreequency: data.freequency,
        maxUsageChecked: true,
        featuresTenantsList:data.featuresTenantsList
        };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function validateSamlConfig(data, intl) {
    let errorMap = {};
        let identity = data.identity;
        let adminEmail = data.adminEmail==undefined? data.adminMail : data.adminEmail;
        let signonUrl = data.signonUrl;
        let logoutUrl = data.logoutUrl;
        let fileName1 = data.fileName1===undefined ? data.fileName : data.fileName1;
        if (identity === '' || identity === undefined) {
            errorMap["identity"] = findLabel("configpageSamlAlert1");
        }
        if (adminEmail === '' || adminEmail === undefined) {
            errorMap["adminEmail"] = findLabel("configpageSamlAlert2");
        }
        if (signonUrl === '' || signonUrl === undefined) {
            errorMap["signonUrl"] = findLabel("configpageSamlAlert3");
        }
        if (logoutUrl === '' || logoutUrl === undefined) {
            errorMap["logoutUrl"] = findLabel("configpageSamlAlert4");
        }
        if (fileName1 === '' || fileName1 === undefined) {
            errorMap["fileName1"] = findLabel("configpageSamlAlert5");
        }

    return errorMap;
}

export function validateDatabaseConfig(data, intl) {
    let errorMap = {};
    if (data.databaseTypeValue !== 'HSQL') {
        let url = data.databaseURLValue;
        let dbname = data.databaseNameValue;
        let dbhost = data.databaseHostValue;
        let dbport = data.databasePortValue;
        let dbpass = data.databasePasswordValue;
        let dbuser = data.databaseUserValue;
        //let schemaName = data.databaseSchemaValue;
        //let enableMultiTenant = data.multiTenancyEnabled;
                
        let filter = /^([a-zA-Z0-9_\.\-:\/;@=/s]+)$/;
        // if (url !== '' && !filter.test(url)) {
        //     errorMap["url"] = findLabel("configpageDBAlert9");
        // }
        if (url === '' && dbname === '') {
            errorMap["dbname"] = findLabel("configpageDBAlert1");
        }

        if (url === '' && dbhost === '') {
            errorMap["dbhost"] = findLabel("configpageDBAlert2");
        }
        if(url === ''  || url === undefined) {
            if(dbport === ''  || dbport === undefined) {
                errorMap["dbport"] = findLabel("database.port.not.empty");
            } else if (dbport !== undefined && dbport !== '' && !new RegExp("^[0-9]+$").test(dbport)) {
                errorMap["dbport"] = findLabel("configpageDBAlert7");
            }
            if(dbname === ''  || dbname === undefined) {
                errorMap["dbname"] = findLabel("configpageDBAlert1");
            }
        }
        
        if (dbuser === undefined || dbuser === '') {
            errorMap["dbuser"] = findLabel("configpageDBAlert4");
        }
        if (dbpass === '') {
            errorMap["dbpass"] = findLabel("configpageDBAlert5");
        }
        /*if((schemaName === undefined || schemaName === '') && Number(enableMultiTenant) === 1 && data.databaseTypeValue === 'Postgres') {
            errorMap["schemaname"] = findLabel("db.config.scheman.name");
        }*/
        /*var passfilter = /^([a-zA-Z0-9_\.\-:@#/s]+)$/;
        if (!passfilter.test(dbpass)) {
            errorMap["dbpass"] = findLabel("configpageDBAlert8");
        }*/
    }
    return errorMap;
}
function validateGroupElement(data, intl, errorMap) {
    let gbasedn = data.ldapGroupBaseValue;
    let gMemberAttr = data.groupMemberAttributeValue;
    let gFilter = data.groupFilterAttributeValue;
    if(!isEmpty(gbasedn)){
        //showErrorMessage1("configpageLDAPAlert80");
        errorMap["gbasedn"] = findLabel("configpageLDAPAlert80");
    }
    if(!isEmpty(gMemberAttr)){
        //showErrorMessage1("configpageLDAPAlert80");
        errorMap["gMemberAttr"] = findLabel("configpageLDAPAlert84");
    }
    if(!isEmpty(gFilter)){
        //showErrorMessage1("configpageLDAPAlert80");
        errorMap["gFilter"] = findLabel("configpageLDAPAlert85");
    }
    return errorMap;
}
export function processConfigurationSettings(data) {
    let type = "POPULATE_APP_CONFIG_SETTING_DETAILS";
    let payload = {
        formData: data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processUserConfiguration(data) {
    let type = "POPULATE_USER_CONFIG_SETTING_DETAILS";
    let payload = {
        userPasswordExpire: data.userPasswordExpire,
        resetPassword: data.resetPassword,
        changePasswordDays: data.changePasswordDays,
        enableEmailNotificaion: data.enableEmailNotificaion,
        groupsSource: data.groupsSource,
        usersSource: data.usersSource,
        implemention: data.implemention,
        configGroupSetting: data.configGroupSetting,
        configUserSetting: data.configUserSetting
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processLDAPConfiguration(data, isTestConn) {
    let type = "POPULATE_LDAP_CONFIG_SETTING_DETAILS";
    let payload = {
        listprotocols: data.listprotocols,
        listauthmethods: data.listauthmethods,
        listdefProp: data.listdefProp,
        grouptype: data.grouptype,
        listusersearchscope: data.listusersearchscope,
        status: data.status,
        errorDetail: data.errorDetail,
        protocol: data.protocol,
        hostname: data.hostname,
        port: data.port,
        searchbase: data.searchbase,
        authenticationmethod: data.authenticationmethod,
        username: data.username,
        basedn: data.basedn,
        usersubtree: data.usersubtree,
        useridattr: data.useridattr,
        usersFilter: data.usersFilter,
        fnameattr: data.fnameattr,
        lnameattr: data.lnameattr,
        eamilattr: data.eamilattr,
        passattr: data.passattr,
        gbasedn: data.gbasedn,
        groupsubtree: data.groupsubtree,
        groupidattr: data.groupidattr,
        grmemattr: data.grmemattr,
        grformatattr: data.grformatattr,
        groupsFilter: data.groupsFilter,
        captcha: data.captch,
        isTestConn: isTestConn
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processIDMConfiguration(data) {
    let type = "POPULATE_IDM_CONFIG_SETTING_DETAILS";
    let payload = {
        tenantinfo: data.tenantinfo,
        idmservicename: data.idmservicename,
        idmport: data.idmport,
        idmserver: data.idmserver,
        password: data.password,
        status: data.status,
        errorDetail: data.errorDetail,
        username: data.username
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processUsageHubConfiguration(data) {
    let type = "POPULATE_USAGE_HUB_CONFIG_SETTING_DETAILS";
    let payload = {
        usageHubPort: data.usageHubPort,
        analyticusageflag: data.analyticusageflag,
        allowcredentialFlag: data.allowcredentialFlag,
        usagediscover:data.usagediscover,
        usageIP: data.usageIP,
        usageinterval: data.usageinterval,
        usagehubUserName: data.usagehubUserName,
        usagehubUserPassword: data.usagehubUserPassword,
        status: data.status,
        errorDetail: data.errorDetail
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processDatabaseConfiguration(data) {
    let type = "POPULATE_DB_CONFIG_SETTING_DETAILS";
    let payload = {
        dbhost: data.dbhost,
        dbport: data.dbport,
        dbname: data.dbname,
        dburl:data.dburl,
        dbtype: data.dbtype,
        dbuser: data.dbuser,
        dbschema: data.dbschema,
        dbDatapasswd: data.dbDatapasswd,
        dbTypeList: data.dbTypeList,
        status: data.status,
        errorDetail: data.errorDetail,
        enableMultiTenancy: data.enableMultiTenancy,
        dbcert: data.dbcert,
        dbcertfilename: data.dbcertfilename,
        dbSSL:data.dbSSL
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processJobsLit(data, actionType, intl) {
    let type = "PROCESS_JOB_LIST";
    let jobsData = data.list;
    Object.entries(jobsData).map(([index, obj]) =>{
        obj.createdOn = DateUtility.getFormatedDate(new Date(obj.createdOn));
        obj.updatedOn = DateUtility.getFormatedDate(new Date(obj.updatedOn));
        let statusKey = "label.status.inprogress";
        if(obj.status === "NOT_INITIATED") {
            statusKey = "label.status.notinitiated";
        } else if(obj.status === "COMPLETED") {
            statusKey = "label.status.completed";
        } else if(obj.status === "FAILED") {
            statusKey = "label.status.failed";
        } else if(obj.status === "PARTIALLY_COMPLETED") {
            statusKey = "label.status.partially.completed";
        }
        obj.status = findLabel(statusKey);
        obj.downloadIcon = <Download colorIndex="brand" />
        obj.deleteColumn = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
        return "";
    });
    let payload = {
        data: jobsData,
        actionType: actionType,
        pagination: data.pagination
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function compare(item1,item2) {	
    return ValidationUtility.compare(item2);
}
function isEmpty(s) {			
    return ValidationUtility.isEmpty(s);
}
export function validEmail(data, intl){	
    if(data.testEmailId === '' || !(ValidationUtility.validateEmail(data.testEmailId)) || data.adminEmail === '' || !(ValidationUtility.validateEmail(data.adminEmail)))	{
        //alert('<s:property value="%{getText(\'config.alert6\')}" escapeHtml="false" />');
        showErrorMessage1("configpageAlert6");
        return false;
    }
    return true;
}
function isOnlyWhitespace(string_value) {			
    var whitespace = " \n\r\t";
    for (var counter = 0; counter < string_value.length; counter++) {
        var current_char = string_value.charAt(counter);
        if (whitespace.indexOf(current_char) === -1) {		        	
            return false;
        }		        
    }
    return true;
}
export function validateEmail(sEmail) {
    return ValidationUtility.validateEmail(sEmail);
}
export function showErrorMessage1(key, intl) {
    ValidationUtility.showErrorMessage(getLocaleMessage(key), "CONFIGURATION_PAGE_ERROR");
}
function findLabel(key, intl) {
    return getLocaleMessage(key);
}
export function showErrorMessage2(key1, key2, intl, seperator) {
    let payload = getLocaleMessage(key1)+seperator+getLocaleMessage(key2);
    ValidationUtility.showErrorMessage(payload, "CONFIGURATION_PAGE_ERROR");
}
export function showErrorWithMessage(message, intl) {
    ValidationUtility.showErrorMessage(message, "CONFIGURATION_PAGE_ERROR");
}
export function showErrorWithMessageParam(params, key, intl) {
    ValidationUtility.showSuccessMessage(getLocaleMessage(key, params), "CONFIGURATION_PAGE_ERROR");
}
export function showSuccessMessage(key, intl) {
    ValidationUtility.showSuccessMessage(getLocaleMessage(key), "CONFIGURATION_PAGE_SUCCESS_OK");
}
export function showSuccessPersisitMessage(key, intl) {
    ValidationUtility.showSuccessMessage(getLocaleMessage(key), "CONFIGURATION_PAGE_SUCCESS_OK", true);
}
export function showSuccessMessageWithCustom(message, key, intl) {
    ValidationUtility.showSuccessMessage(getLocaleMessage(key)+" "+message, "CONFIGURATION_PAGE_SUCCESS_OK");
}
export function showSuccessMessageWithParams(params, key, intl) {
    ValidationUtility.showSuccessMessage(getLocaleMessage(key, params), "CONFIGURATION_PAGE_SUCCESS_OK");
}
export function showLoadMask(key, intl) {
        ValidationUtility.showLoadMask("", "CONFIGURATION_PAGE_LOADING_SPIN");
}
export function hideLoadMask(intl) {
    ValidationUtility.hideLoadMask("", "CONFIGURATION_PAGE_SPIN_DONE");
}

export function processNotificationData(data) {
    let type = "POPULATE_NOTIFICATION_DETAILS";
    let payload = {
       notifyBy: data.notifyBy,
       expInDays: data.notifyInDays,
       capacityPersentage: data.notifyCapacity,
       emailIds: data.emailIds,
       adminEmail:data.adminEmail

    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function validateNotificationData(data, intl){

    let numberOfDays = data.expInDays;
    let capacityPersentage = data.capacityPersentage;
    let notificationError ={};

    if (!ValidationUtility.isNumber(numberOfDays) ) { 
         notificationError["numberOfDays"] = findLabel("numberErrorMsg"); 
    }
    if (!ValidationUtility.isNumber(capacityPersentage)) {
        notificationError["capacityPersentage"] = findLabel("numberErrorMsg");         
    } else {  
        if(data.enableexpireForCapcityChecked && parseInt(capacityPersentage)===0)          
            notificationError["capacityPersentage"] = findLabel("zero.is.not.valid.number"); 
        if(data.enableexpireIndaysChecked && parseInt(numberOfDays)===0)
            notificationError["numberOfDays"] = findLabel("zero.is.not.valid.number"); 
    }

   
    return notificationError;
}
export function processRejectedCTList(data){
    if (data !== undefined) {
        let type = "POPULATE_REJECTED_DETAILS";
        data.pagination.required =true;
        data.pagination.style = "default";
         var sortOrder;
        if (data.pagination.sortOrder === "ASC") {
            sortOrder = true;
        } else {
            sortOrder = false;
        }
        var mapData = new Map();
        var i = 0;
    const rows = data.rejectedCTList.map(item => {
        i++;        
      Object.entries(item).map(([key, value]) => {
        if (key.indexOf("lastUpdatedDate") > -1) {
          item[key] = DateUtility.getFormatedDate(new Date(value));          
        }
        return "";
      });
       item.featureDesc = item.featureId + ":" + item.featureVersion;
      if (item.featureDescription !== undefined)
        item.featureDesc =
          item.featureDescription +
          " (" +
          item.featureId +
          ":" +
          item.featureVersion +
          ")";
        item.usageID = item.featureId+"$$"+item.featureVersion+"$$"+i;      
        mapData.set(item.usageID, item);
        item.recordCount = item.count;
        return item;
    });
     let payload = {
        tableData: rows,
        pagination: data.pagination,
        sortAscending: sortOrder,
        mapData : mapData
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
        });
    }
}
export function loadRejectedRecords(intl,pagedata,type){        
        FetchHelper.findCTRejectedList(intl,pagedata,type);
    }

export function processProductHostList(data){
    let type = "PRODUCT_HOST_LIST";
    let prodHostList = data.poolList.pool;
    let payload = {
        data: prodHostList
    };
    Dispatcher.dispatch({
        type: type,
        payload:payload
    });
}

export function processProductHostLicenseList(data){
    let type = "PROD_HOST_LICENSE_LIST";
    let licenseList = data.licenseList.license;
    const rows = licenseList.map(item => {        
      Object.entries(item).map(([key, value]) => {
        if (key.indexOf("startDate") > -1) {
          item[key] = DateUtility.getFormatedDate(new Date(value*1000));          
        }
        if (key.indexOf("installedDate") > -1) {
          item[key] = DateUtility.getFormatedDate(new Date(value));          
        }
        if (key.indexOf("restoredDate") > -1) {
          item[key] = DateUtility.getFormatedDate(new Date(value));          
        }
        if (key.indexOf("expiryDate") > -1) {
            if(value == 900703){
                item[key] = "Perpetual"; 
            }else{
                item[key] = DateUtility.getFormatedDate(new Date(value*1000)); 
                item["exVal"] = new Date(value*1000);
            }                   
        }
        return "";
      });
      if(item.status=='0'){
          var exDate = item.exVal;        
          var today = new Date();          
          if(item.expiryDate != "Perpetual" && (exDate < today)){
            item.status = "EXPIRED";
          }else{
              item.status = "ACTIVE";
          }          
      }else if(item.status == '1'){
          item.status = "DELETED";
      }
        return item;
    });
    let payload = {
        data: rows,
        pagination:data.pagination
    };
    Dispatcher.dispatch({
        type: type,
        payload:payload
    });
}

export function processProductHostLicenseDetails(data){
    if (data.status === "SUCCESS" && data.licenseList.license.length === 1) {
    var item = data.licenseList.license[0];
    Object.entries(item).map(([key, value]) => {
      if (key.toLowerCase().indexOf("date") > -1) {
        if (value > 0 && value !== 900703)
          item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
        else {
          if (value === 900703) item[key] = "Perpetual";
        }
      }
      return "";
    });

    let type = "PRODUCT_LICENSE_DETAILS";
    let payload = {
      data: item
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
}

export function processProductHostCustomMetric(metricData){
     if (metricData.status === "SUCCESS"){
         let type = "PRODUCT_CUSTOM_METRIC";
        let payload = {
        data: metricData.entityList.value
        };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
}

export function processAuthTokenData(tokenData){
     if (tokenData.status === "SUCCESS"){
        let type = "PROCESS_AUTH_TOKEN";
        let payload = {tokens: []};
        const d = new Date();
        let currenttime = d.getTime();
        if(tokenData.tokenList !== undefined &&
            tokenData.tokenList.length >= 0){
            const rows = tokenData.tokenList.map((item, index) => {
                let row = {};
                row.key = decryptText(item.token);
                if(item.expiryDate < currenttime){
                    row.token = <span style={{color: "red"}}><b>{row.key}</b></span>;
                    row.isexpired = true;
                } else {
                    row.token = row.key;
                    row.isexpired = false;
                }
                row.expiryDate = DateUtility.getFormatedDate(new Date(item.expiryDate));
                row.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
                row.renewFlag = item.renewFlag==="true" ? true : false;
                row.renewDays = item.renewDays;
                // row.copy = <div id={index} className="copiedTooltip">
                //   <CopyIcon style={{paddingLeft:"5px"}} id={index}/><span className="copiedTooltipText">{getLocaleMessage("tooltip.copied.text")}</span></div>;
                return row;
            });
            payload = {tokens: rows, appExpiryDays : tokenData.appExpiryDays};
        }

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
}

export function processAuditData(auditData, intl) {
  let type = "PROCESS_AUDIT_DATA";
  let payload = { audits: [] };
  const d = new Date();
  let currenttime = d.getTime();
  if (auditData.audits !== undefined && auditData.audits.length >= 0) {
    const rows = auditData.audits.map((item, index) => {
      let row = {};
      // row.key = item.id;
      row.entity = item.entity;
      row.entityId = item.displayAttr;
    //   row.attribute = getLocaleLabel(
    //     intl,
    //     "label.audits." + item.entity + ".attr." + item.attribute
    //   );
      row.attribute = item.attribute;
      row.oldValue = item.attrType
        ? getValueForAudit(item.attrType, item.oldValue)
        : item.oldValue;
      row.newValue = item.attrType
          ? getValueForAudit(item.attrType, item.newValue)
          : item.newValue;
      row.timestamp = DateUtility.getFormatedDate(new Date(item.timestamp));
      row.operation = item.operation;
      row.userName = item.userName;
      return row;
    });
    auditData.pagination.required = true;
    auditData.pagination.style = "default";
    payload = {
      audits: rows,
      pagination: auditData.pagination,
      entityFilterVal: auditData.entityFilterVal,
      entityIdVal: auditData.entityIdVal,
      attributeVal: auditData.attributeVal,
      operationVal: auditData.operationVal,
      userVal: auditData.userVal,
      oldVal: auditData.oldVal,
      newVal: auditData.newVal,
      startDate: auditData.startDate,
      endDate: auditData.endDate,
      auditEnabled: auditData.auditEnabled,
    };
  }

  Dispatcher.dispatch({
    type: type,
    payload: payload,
  });
}

export function getValueForAudit(attrType, value) {
  if (attrType === "DATE") {
    if (value > 0){
        if(value.length < 11){
            value *= 1000;
        }
        return DateUtility.getFormatedDate(new Date(Number(value)));
    } 
    else return value;
  }
}