import ReduceStore from "./ReduceStore";

class TableStore extends ReduceStore {
  constructor() {
    super();
    this.state = {
      tableData: [],
      sortIndex: 0,
      sortAscending: true
    };
  }
    reduce(action, state) {
        switch (action.type) {
            case "TABLE_FETCH_COMPLETE": {
                return {
                    ...state,
                    tableData: action.payload.tableData,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                };
            }
            case "ARCHIVE_BORROW_LICENSE_FETCH_COMPLETE": {
              return {
                ...state,
                archPagination: action.payload.pagination,
                archivedBorrowData: action.payload.tableData,
                archivedSortIndex: action.payload.sortIndex,
                archivedSortAscending: action.payload.sortAscending,
                productNameSelected: action.payload.productNameSelected
              };
            }
            case "ACTIVE_BORROW_LICENSE_FETCH_COMPLETE": {
              return {
                ...state,
                actPagination: action.payload.pagination,
                activeBorrowData: action.payload.tableData,
                activeSortIndex: action.payload.sortIndex,
                activeSortAscending: action.payload.sortAscending,
                productNameSelected: action.payload.productNameSelected,
                disableIds:action.payload.disableIds
              };
            }
            case "LICENSE_VIEW_PAGE_FETCH_COMPLETE": {
                return {
                    ...state,
                    type: "LICENSE_VIEW_PAGE_FETCH_COMPLETE",
                    licViewtableData: action.payload.tableData,
                    pagination: action.payload.pagination,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    productNameSelected: action.payload.productNameSelected,
                    productNameSelectOptions: action.payload.productNameSelectOptions,
                };
            }
            case "LICENSE_ARCHIVE_PAGE_FETCH_COMPLETE": {
                return {
                    ...state,
                    type: "LICENSE_ARCHIVE_PAGE_FETCH_COMPLETE",
                    licViewtableData: action.payload.tableData,
                    pagination: action.payload.pagination,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                    productNameSelected: action.payload.productNameSelected,
                    productNameSelectOptions: action.payload.productNameSelectOptions,
                };
            }
            case "POPULATE_USAGE_REPORT_LIST": {
              return {
                ...state,
                pagination: action.payload.pagination,
                allFeatureList: action.payload.allFeatureList,
                productNameList: action.payload.productNameList,
                status: action.payload.status,
                errorDetail: action.payload.errorDetail,
                machineTampered: action.payload.machineTampered,
                selRows: action.payload.selRows,
                disRows: action.payload.disRows,
                licModelMap: action.payload.licModelMap,
                featMap: action.payload.featMap,
                tokenBaseFeatures: action.payload.tokenBaseFeatures,
                cumulateUsageMap: action.payload.cumulateUsageMap,
                sortIndex: action.payload.sortIndex,
                sortOrder: action.payload.sortOrder,
                featureVersionMap: action.payload.featureVersionMap,
                actionType: "usagepagelist",
                dependableFeatures: action.payload.dependableFeatures
              };
            }
            case "POPULATE_TENANT_USAGE_REPORT_LIST": {
              return {
                ...state,
                pagination: action.payload.pagination,
                allFeatureList: action.payload.allFeatureList,
                productNameList: action.payload.productNameList,
                status: action.payload.status,
                errorDetail: action.payload.errorDetail,
                machineTampered: action.payload.machineTampered,
                selRows: action.payload.selRows,
                disRows: action.payload.disRows,
                licModelMap: action.payload.licModelMap,
                featMap: action.payload.featMap,
                tokenBaseFeatures: action.payload.tokenBaseFeatures,
                cumulateUsageMap: action.payload.cumulateUsageMap,
                sortIndex: action.payload.sortIndex,
                sortOrder: action.payload.sortOrder,
                featureVersionMap: action.payload.featureVersionMap,
                actionType: "tenantusagepagelist",
                dependableFeatures: action.payload.dependableFeatures
              };
            }
            case "TABLE_FETCHING": {
              return { ...state, fetching: action.payload.fetching, error: "" };
            }
            case "USER_VIEW_PAGE_FETCH_COMPLETE": {
              return {
                ...state,
                userData: action.payload.tableData,
                userMap: action.payload.userMap,
                selectedUser: action.payload.selectedUser,
                userMgpagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "GROUP_VIEW_PAGE_FETCH_COMPLETE": {
              return {
                ...state,
                // tableData: action.payload.tableData,
                groupData: action.payload.groupData,
                groupMap: action.payload.groupMap,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "ADD_GROUP_TO_USER_COMPLETE": {
              return {
                ...state,
                userData: action.payload.tableData,
                groupData: action.payload.groupData,
                groupMap: action.payload.groupMap,
                selectedUser: action.payload.selectedUser,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "USER_GROUP_VIEW_PAGE_FETCH_COMPLETE": {
              return {
                ...state,
                productList: action.payload.productList,
                groupName: action.payload.groupName,
                tableData: action.payload.tableData,
                groupMap: action.payload.groupMap,
                roleList: action.payload.roleList,
                grppagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "LICENSE_FETCH_COMPLETE": {
              return {
                ...state,
                tableData: action.payload.tableData,
                productMap: action.payload.productMap,
                productNameList: action.payload.productNameList,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "BUYMORE_LICENSE_FETCH_COMPLETE": {
              return {
                ...state,
                tableData: undefined,
                licenseData: action.payload.tableData,
                productMap: action.payload.productMap,
                productNameList: action.payload.productNameList,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "LICENSE_UPDATE_RESPONSE": {
              return {
                ...state,
                licenseData: undefined,
                tableData: action.payload.tableData,
                mailServerConfigured: action.payload.mailServerConfigured,
                defaultMail: action.payload.defaultMail,
                resStatus : action.payload.resStatus,
                //productNameList:action.payload.productNameList,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "LICENSE_REPORT_RESPONSE": {
              return {
                ...state,
                tableData: action.payload.tableData,
                searchPagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending,
                searchResult: action.payload.searchResult
              };
            }
            case "PROCESS_MANAGE_COMMUTER_SAVE": {
              return {
                actionType: action.payload.actionType
              };
            }
            case "PRODUCT_FETCH_FOR_GROUP": {
              return {
                ...state,
                productList: action.payload.productList,
                groupName: action.payload.groupName
              };
            }
            case "LICENSE_PRODUCT_FEATURE_PAGE_FETCH_COMPLETE": {
                return {
                ...state,
                tableData: action.payload.tableData,
                impFeatureTableData: action.payload.impFeatureTableData,
                impFeatureModalData: action.payload.impFeatureModalData,
                PFpagination: action.payload.PFpagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending,
                productNameSelected: action.payload.productNameSelected,
                productNameSelectOptions: action.payload.productNameSelectOptions,
                disableApplyBtnIndex:action.payload.notAppliedCount
                };
            }
            case "REDIRECT_PAGE" :{
                return{
                    ...state,
                    redirectPage: action.payload.redirectPage
                }
            }
            case "REFRESH_USAGE_LIST_PAGE" : {
              return{
                    actionType: action.payload.actionType
                }
            }
            case "REFRESH_TENANT_USAGE_LIST_PAGE" : {
              return{
                    actionType: action.payload.actionType
                }
            }
            case "NAMEDUSER_LIC_FETCH_COMPLETE": {
                return {
                    ...state,
                    type: "NAMEDUSER_LIC_LIST",
                    namedUserLicData: action.payload.tableData,
                    defaultLicId: action.payload.defaultLicId,
                    licpagination: action.payload.pagination,
                    sortIndex: action.payload.sortIndex,
                    sortAscending: action.payload.sortAscending,
                };
            }
            case "POPULATE_NAMEDUSER_MEMBERS": {
                return {
                    ...state,
                    type: "NAMEDUSER_LIST",
                    namedUserData: action.payload.data,
                    userpagination: action.payload.pagination,
                };
            }
            case "ACTIVE_TENANT_SPECIFIC_LICENSE_FETCH_COMPLETE": {
              return {
                ...state,
                actTenantPagination: action.payload.pagination,
                activeTenantData: action.payload.tableData,
                activeSortIndex: action.payload.sortIndex,
                activeSortAscending: action.payload.sortAscending,
                productNameSelected: action.payload.productNameSelected,
                disableIds:action.payload.disableIds,
                actionType:"TENANT_LICENSE_LIST"
              };
            }
            case "GROUP_VIEW_LDAP_FETCH_COMPLETE": {
              return {
                ...state,
                // tableData: action.payload.tableData,
                ldapGroupData: action.payload.ldapGroupData,
                //groupMap: action.payload.groupMap,
                //pagination: action.payload.pagination,
                sortIndex: action.payload.sortIndex,
                sortAscending: action.payload.sortAscending
              };
            }
            case "GET_AUTOADD_NAMEDUSER_FLAG": {
                return {
                    ...state,
                    type: "REQ_AUTOADD_NAMEDUSER",
                    autoAddNamedUser: action.payload
                };
            }
            default:
                return state;
        }
    }
}

export default new TableStore();