import React, { Component } from "react";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import "../../../../css/index.css";

// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class ImplicitFeatureDetails extends Component {
    implicitFeatureContent(licDetails) {

        return (
            <div id={this.props.id}>
                 <table className="impLic-detail-table table-bordered">
                     <thead>
                         <tr className="lic-detail-head"> 
                         <th>{LocaleUtility.getLocaleLabel(
                            this.context.intl,
                            "label.grid.col.feature"
                        )}</th>
                        <th>{LocaleUtility.getLocaleLabel(
                            this.context.intl,
                            "label.grid.col.capacity"
                        )}</th>
                        <th>{LocaleUtility.getLocaleLabel(
                            this.context.intl,
                            "label.grid.col.startDate"
                        )}</th>
                         <th>{LocaleUtility.getLocaleLabel(
                            this.context.intl,
                            "label.grid.col.expDate"
                        )}</th>
                  </tr>
                     </thead>
                        <tbody>
                            {licDetails.map((item,ind)=>{
                                return <tr key={ind}>
                                    <td>{item.Product_Number}</td>
                                    <td>{item.Capacity}</td>
                                    <td>{item.Start_Date}</td>
                                    <td>{item.Expiry_Date}</td>
                                    </tr>
                            })
                            }                            
                        </tbody>
                    </table>
                </div>
        );
    }

    render() {
        return (
             this.implicitFeatureContent(this.props.licenseDetails)
        );
    }
}
// ImplicitFeatureDetails.contextTypes = {
//   intl: _propTypes2.default.object,
// };

export default ImplicitFeatureDetails;