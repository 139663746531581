import './polyfills';
import React from "react";
import ReactDOM from 'react-dom/client';
import "./com/apls/ui/css/index.css";
import App from "./com/apls/ui/components/app/App";
import { BrowserRouter as Router } from 'react-router-dom';
import { unregister } from "./com/apls/ui/utils/registerServiceWorker"
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import locale_en from '@formatjs/intl-listformat/locale-data/en';
import locale_de from '@formatjs/intl-listformat/locale-data/de';
import locale_fr from '@formatjs/intl-listformat/locale-data/fr';
import locale_ja from '@formatjs/intl-listformat/locale-data/ja';
import locale_es from '@formatjs/intl-listformat/locale-data/es';
import locale_zh from '@formatjs/intl-listformat/locale-data/zh';
import messages_de from "./com/apls/ui/translations/de.json";
import messages_en from "./com/apls/ui/translations/en.json";
import messages_fr from "./com/apls/ui/translations/fr.json";
import messages_ja from "./com/apls/ui/translations/ja.json";
import messages_es from "./com/apls/ui/translations/es.json";
import messages_zh from "./com/apls/ui/translations/zh.json";

const messages = {
  'de': messages_de,
  'en': messages_en,
  'fr': messages_fr,
  'es': messages_es,
  'ja': messages_ja,
  'zh': messages_zh
};
var language = navigator.language.split(/[-_]/)[0];
if (messages[language] === undefined){
language='en';
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <IntlProvider locale={language} messages={messages[language]}><Router><App /></Router></IntlProvider></React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

unregister();
