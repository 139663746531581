import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { Button } from "grommet/components/Button";
import File from "../../generic/APFile";
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import TableGrid from "../../../components/commons/table/APTable";
import Store from "../../../flux/CommuterStore";
import CheckinClientInfo from "./CheckinClientInfo";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class CommuterCheckin extends Component {
    constructor(props) {
        super(props);
        this.uploadFileRef = React.createRef();
        this.state = {
            lockType: 1,
            showListView: false,
            showClientInfo: false,
            clientInfoMap: {},
            infoIpAddress: "",
            infoHostName: "",
            infoDeviceId: "",
            infoUserName: "",
            popupTop: "800px",
            popupLeft: "550px"
        }
        this.findLabel = this.findLabel.bind(this);
        this.submitCheckIn = this.submitCheckIn.bind(this);
        this.showListViewFn = this.showListViewFn.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        Store.on("change", this.showListViewFn);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.showListViewFn);
    }
    submitCheckIn() {
        if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state) {
            let files = this.uploadFileRef.current.state.selectedFiles;
            if(files && files.length === 0) {
                ConfigurationUtils.showErrorMessage1("commuter.checkout.alert", this.context.intl);
            } else {
                let data = new FormData();
                let flag = true;
                let flag_Size = true;
                for (var i = 0; i < files.length; i++) {
                    let fileObj = files[i];
                    let fileName = fileObj.name;
                    let extIndex = fileName.lastIndexOf(".");
                    let fileExtn = fileName.substr(extIndex+1, fileName.length);
                    if(fileExtn !== "dat" && fileExtn !== "xml" 
                        && fileExtn !== "pdf" && fileExtn !== "txt" && fileExtn !== "lcor" &&  fileExtn !== "lcir") {
                        flag = false;
                    }
                     if(fileObj.size>102400)
                    {
                        flag_Size=false;
                    }
                    data.append('file', fileObj);
                }
                if(flag && flag_Size) {
                    FetchHelper.submitCommuterCheckin(data, this.context.intl);
                } else {
                    ConfigurationUtils.showErrorMessage1("commuter.invalid.file", this.context.intl);
                }
            }
        }
    }
    showListViewFn() {
        if(Store.getState() && Store.getState().actionType === "checkin") {
            this.setState({showListView: Store.getState().showCheckinListView});
            let data = Store.getState().data;
            let results = data.resultsList;
            this.setState({gridData: results});
            this.setState({clientInfoMap: Store.getState().clientInfoMap});
            let fEl = document.getElementById("commuter_checkin_browse");
            if(fEl !== undefined && fEl !== null) {
                fEl.value = null;
            }
            if(this.uploadFileRef !== undefined && this.uploadFileRef.current !== undefined && this.uploadFileRef.current !== null) {
                const fList = Object.assign([], this.uploadFileRef.current.state.filesList);
                fList.splice(0, 1);
                fList.push(this.findLabel("label.select.files.click.add.files"));
                this.uploadFileRef.current.setState({filesList: fList});
                this.uploadFileRef.current.setState({fileNames: ""});
                this.uploadFileRef.current.setState({selectedFiles: []});
            }
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    onClickClientInfo(value, event) {
        this.setState({showClientInfo: true});
        let infoObj = this.state.clientInfoMap[value];
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
        if(infoObj) {
            this.setState({infoIpAddress: infoObj.ipaddress});
            this.setState({infoHostName: infoObj.hostID});
            this.setState({infoDeviceId: infoObj.deviceID});
            this.setState({infoUserName: infoObj.userName});
        }
    }
    closeTootipAction() {
        this.setState({showClientInfo: false});
        this.setState({infoIpAddress: ""});
        this.setState({infoHostName: ""});
        this.setState({infoDeviceId: ""});
        this.setState({infoUserName: ""});
    }
    render() {
        //let intl = this.context.intl;
        let gData = [];
        if(this.state.gridData !== undefined) {
            gData = this.state.gridData;
        }
        let hideInfo = "tooltipContent tooltipContentHide";
        if(this.state.showClientInfo) {
            hideInfo = "tooltipContent tooltipContentshow";
        }
        return(
            <div className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("commuter.checkin.title")} helpTopic="remote_commuter"/>
                <div>
                    {this.state.showListView ? 
                        <div>
                            <div>
                                <div className="tooltipMain">
                                    <div id="commuter_client_info_id" style={{"top": this.state.popupTop, "left": this.state.popupLeft, width: "auto"}} className={hideInfo}>
                                        <CheckinClientInfo 
                                            closeTootipAction = {this.closeTootipAction}
                                            ipAddress = {this.state.infoIpAddress}
                                            hostName = {this.state.infoHostName}
                                            deviceId = {this.state.infoDeviceId}
                                            username = {this.state.infoUserName}
                                        />
                                    </div>
                                </div>
                                <TableGrid id="commuter_checkin_grid_id"
                                    header={[
                                        {
                                            columnName: "featureDescription",
                                            headerLabel: this.findLabel("commuter.feature.description.label"),
                                            width: "auto",
                                            columnKey: "featureDescription"
                                        },
                                        {
                                            columnName: "capacity",
                                            headerLabel: this.findLabel("commuter.capacity.label"),
                                            href: false,
                                            width: "auto"
                                        },
                                        {
                                            columnName: "clientInfo",
                                            headerLabel: this.findLabel("commuter.client.info.label"),
                                            href: false,
                                            width: "auto",
                                            columnKey: "licenseID",
                                            onClick: this.onClickClientInfo,
                                        },
                                        {
                                            columnName: "extStartDate",
                                            headerLabel: this.findLabel("commuter.startDate.label"),
                                            href: false,
                                            width: "auto"
                                        },
                                        {
                                            columnName: "extExpiryDate",
                                            headerLabel: this.findLabel("commuter.expirtydate.label"),
                                            href: false,
                                            width: "auto"
                                        },
                                        {
                                            columnName: "status",
                                            headerLabel: this.findLabel("commuter.status.label"),
                                            href: false,
                                            width: "auto"
                                        }
                                        
                                    ]}
                                    rowData={gData}
                                    pagination={this.state.pagination}
                                    rowSelection={0}
                                    isExportRequired={false}
                                    onSort={this.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.onPaginationClicked}
                                    sortIndex={this.state.sortIndex}
                                    sortAscending={this.state.sortAscending}
                                    //onChangeCheckBoxCallback={this.onRowClicked}
                                    rowKey="licenseID"
                                />
                            </div>
                        </div>
                    :
                        <div className="border_white_backg">
                            <div className="file_upload_wrap">
                                <div id="attach_file_table_id">
                                    <div className="commuter_file_upload_wrap" style={{width: "50%"}}>
                                        <File ref={this.uploadFileRef}
                                            id = "commuter_checkin_browse"
                                            name = "commuter_checkin_browse"
                                            label = {this.findLabel("label.button.add.files")}
                                        />
                                    </div>
                                    <div className="button_wrap_primary">
                                        <Button id="commuter_checkInBtn" label={this.findLabel("label.button.checkin")} plain={false} primary={true} onClick={this.submitCheckIn} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// CommuterCheckin.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default CommuterCheckin;