import React, { Component } from "react";
import { Anchor } from 'grommet/components/Anchor';
import { Button } from "grommet/components/Button";
import Dispatcher from "../../flux/Dispatcher";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { Trash as TrashIcon } from 'grommet-icons';
import SpinningNotification from './SpinningNotification';
import "../../css/index.css";

class FileUploadUnit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileListSize: this.props.listSize
        }
    }

    render() {
        let resultList;
        if (this.state.fileListSize > 0) {
            resultList = <Anchor id={this.props.title} onClick={this.props.delete}>
                <TrashIcon className="grommetux-control-icon grommetux-color-index-critical" />
            </Anchor>;
        }
        return (
            <li>
                <div className="selected_file_div">
                    <label id={this.props.title}>{this.props.title}</label>
                    {resultList}
                </div>
            </li>
        );
    }
}

export default class FileUploadBox extends Component {

    constructor(props) {
        super(props);
        this.size = 0;
        this.isMultiFile = this.props.isMultiFile
        this.state = {
            lableText: this.props.labelText,
            isMultiFile: this.isMultiFile, 
            fileListSize: this.size,
            uploadObjects: [],
        }
        this.handleUpload = this.handleUpload.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.uploadObjects !== undefined && 
            prevState.uploadObjects.length !== 0) {
            this.setState({uploadObjects: [], fileListSize: 0,
            lableText: LocaleUtility.getLocaleMessage("label.select.files.click.add.files")});
        }
    }
handleClick = (e) => {
    this.inputElement.click();
  }
    handleUpload(ev) {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        }); 
        ev.preventDefault();
        if (this.state.fileListSize > 0) {
            this.props.handleUpload(ev, this.state.fileListSize, this.state.uploadObjects);
        } else {
            let type = "LICENSE_VALIDATION_ERROR";
            Dispatcher.dispatch({
                type: type,
                payload: this.props.alertNonfileSelection
            });
        }
    }

    addFiles = event => {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        }); 
        var exists;
        const fileObjs = Array.from(event.target.files);
        const fileArray = Object.assign([], this.state.uploadObjects);
        const keys = fileArray.map(item => item.key);
        for (var i = 0; i < fileObjs.length; i++) {
            exists = validateFile(keys, fileObjs[i], this.props.fileExtFilter);
            if (exists === '') {
                this.size = this.size + 1;
                fileArray.push({
                    key: fileObjs[i].name,
                    file: fileObjs[i]
                })
            }
        }
        if (exists === 'null' || exists === '') {
            exists = '';
        } else {
            let type = "LICENSE_VALIDATION_ERROR";
            Dispatcher.dispatch({
                type: type,
                payload: exists
            });
        }
        this.setState({
            uploadObjects: fileArray,
            fileListSize: this.size,
            lableText: exists
        })
    }

    removeFiles = (index) => {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        }); 
        const fileArray = Object.assign([], this.state.uploadObjects);
        fileArray.splice(index, 1);
        this.size = this.size - 1;
        this.setState({
            uploadObjects: fileArray,
            fileListSize: this.size,
            lableText: LocaleUtility.getLocaleMessage("label.select.files.click.add.files")
        })
    }

    handleCancelButton() {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        }); 
        this.setState({ uploadObjects: [], fileListSize: 0,
            labelText: LocaleUtility.getLocaleLabel(this.context.intl, "label.select.files.click.add.files")});
    }

    render() {
        var divStyle, fileWidth = '100%';
        if(this.props.fileWidth !== undefined && this.props.fileWidth !== ""){
            fileWidth = this.props.fileWidth;
        }
        var inputFileStyle = {
            position: 'absoulte',
            opacity: '0',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            height: '100%',
            cursor: 'pointer',
            width: '100%'
        };

        var labelStyle = {
            // position: 'absolute',
            cursor: 'pointer',
            border: '2px solid #0073E7',
            borderRadius: '0px',
            fontSize: '0.875rem',
            fontWeight: '700',
            textAlign: 'center',
            outline: 'none',
            padding: '5px 8px',
            textTransform: 'none',
            letterSpacing: '0.5px',
            backgroundColor: '#0073E7',
            color: '#FFFFFF'
        }

        let resultList, addButtonLabel, fileInputElement;
        addButtonLabel = LocaleUtility.getLocaleMessage("label.button.add.files");
        if (this.state.fileListSize > 0) {
            addButtonLabel = LocaleUtility.getLocaleMessage("label.button.add.more.files");
            divStyle = {
                position: 'relative',
                height: '35px'
            };
            resultList = this.state.uploadObjects.map((uploadItem, index) => {
                return (
                    <FileUploadUnit
                        key={uploadItem.key}
                        title={uploadItem.key}
                        listSize={this.state.fileListSize}
                        delete={this.removeFiles.bind(this, index)} />
                )
            })

            if(this.props.isMultiFile){
                fileInputElement = <React.Fragment>
                                        <Button id="file-input-label-id" htmlFor="file-input" onClick={this.handleClick}>{addButtonLabel}</Button>
                                        <input hidden={true} style={inputFileStyle} ref={input => this.inputElement = input}
                                            value='' type="file" id="file-input" name="file-input"
                                            multiple onChange={this.addFiles}
                                            accept={this.props.fileExtFilter} />
                                    </React.Fragment>;
            }
        } else {
            divStyle = {
                position: 'relative',
                height: '35px',
                width: '98px'
            };
            resultList = <li> <label style={{ padding: '6px 22px' }}>{this.state.lableText}</label> </li>;
            if(this.props.isMultiFile){
                fileInputElement = <React.Fragment>
                                    <Button id="file-input-label-id" htmlFor="file-input" onClick={this.handleClick}>{addButtonLabel}</Button>
                                    <input hidden={true} style={inputFileStyle} ref={input => this.inputElement = input}
                                        value='' type="file" id="file-input" name="file-input"
                                        multiple onChange={this.addFiles}
                                        accept={this.props.fileExtFilter} />
                                </React.Fragment>;
            } else {
                fileInputElement = <React.Fragment>
                                    <Button id="file-input-label-id" htmlFor="file-input" onClick={this.handleClick}>{addButtonLabel}</Button>
                                    <input hidden={true} style={inputFileStyle} ref={input => this.inputElement = input}
                                        value='' type="file" id="file-input" name="file-input"
                                        onChange={this.addFiles}
                                        accept={this.props.fileExtFilter} />
                                </React.Fragment>;
            }
        }

        return (
            
            <div className="fileupload-wrapper">
                <form id={this.props.id} onSubmit={this.handleUpload} className={this.props.className}>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <ul style={{ listStyleType: 'none' }}>
                                {resultList}
                                </ul>
                            </div>
                        </div>
                        <div className="button_left_wrap">
                            <div className="button_wrap_primary">
                                {fileInputElement}
                            </div>
                            <div className="button_wrap_secondary">
                                <Button id="uploadFileBtn" label={this.props.uploadBtnLabel}
                                    type="submit" accent={true} plain={false}
                                    disabled={this.state.fileListSize == 0}
                                />
                            </div>
                            {this.props.cancelBtn !== undefined && this.props.cancelBtn && (
                                <div className="button_wrap_negative">
                                    <Button id="lic_inst_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                </div>
                            )}
                            <div>
                                <SpinningNotification centerPage={true} />
                            </div>
                        </div>
                </form>
            </div >
        )
    }
}

function validateFile(fileNames, fileItem, fileExtFilter) {

    var exists = fileNames.some(v => (v === fileItem.name));
    if (!exists) {
        const ext = fileItem.name.slice((fileItem.name.lastIndexOf(".") - 1 >>> 0) + 2);
        if (fileExtFilter.toLowerCase().indexOf(ext.toLowerCase()) > -1) {
            exists = '';
        } else {
            exists = this.props.alertFileExtFilter;
        }
    } else {
        exists = 'null';
    }
    return exists;
}