import React, { Component } from "react";
import "../../../css/index.css";
import { TextInput } from 'grommet/components/TextInput';
import { CheckBox } from 'grommet/components/CheckBox';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Button } from "grommet/components/Button";
import {validateEmail} from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";

class CreateGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createGroup: false,
            groupName:'',
            groupDesc:'',
            groupEmail:'',
            isAdmin:''
        };

        this.handleEmailChange= this.handleEmailChange.bind(this);
        this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
    }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     this.setState({
    //         createGroup: nextProps.createGroup
    //     });
    // }

    handleGroupNameChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('groupName');

        if(event.target.name === 'groupName' && 
                !(event.target.value.length > 1 &&  event.target.value.length < 64)){
            if (index === -1) {
                errors.push('groupName');
            }
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }

        this.setState({errorcode: errors});
		this.setState({ groupName: event.target.value });
    }
    handleDescriptionChange(e) {
		this.setState({ groupDesc: e.target.value });
	}

    handleEmailChange(event) {
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('invalidLength');

        if(event.target.value.length > 0){
            if(event.target.name === 'email' &&  
                !(event.target.value.length > 5 &&  event.target.value.length < 64)){
                if (index === -1) {
                    errors.push('invalidLength');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            index = errors.indexOf('invalidEmail')
            if(event.target.name === 'email' && 
                !validateEmail(event.target.value) ){
                if (index === -1) {
                    errors.push('invalidEmail');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            index = errors.indexOf('invalidEmail');
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }

        this.setState({errorcode: errors});
		this.setState({ groupEmail: event.target.value });
	}

    handleCheckBoxChange(e){
        this.setState({isAdmin: e.target.checked});
    }
    
    handleSubmitButton(event) {
        event.preventDefault();
        var elements = event.target.elements;
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(errors.length > 0){
            flag = false;
        } else if(elements.groupName.value === ''){
            let index = errors.indexOf(elements.groupName.name)
            if (index === -1) {
                errors.push(elements.groupName.name);
            }
            flag = false;
        }

        if(flag === true){
            this.setState({
                createGroup: false
            })
    
            const formPayload = {
                groupName:this.state.groupName,
                groupDesc:this.state.groupDesc,
                groupEmail:this.state.groupEmail,
                isAdmin: this.state.isAdmin
            };
    
            this.props.handleSubmitButton(formPayload);
            this.setState({
                groupName:'',
                groupDesc:'',
                groupEmail:'',
                isAdmin:'',
                errorcode: []
            })
        } else {
            this.setState({errorcode: errors});
        }
    }

    handleCancelButton(){
        this.setState({
            createGroup: false,
            errorcode: []
        }) 

        this.props.handleGroupCancelButton();
        this.setState({
            groupName:'',
            groupDesc:'',
            groupEmail:'',
            isAdmin:'',
            errorcode: []
        })
    }

    infoSectionContent() {

        const showHideModalStyle = this.props.showCreateGroupModal ? "modal display-block" : "modal display-none";
        let emailFormFieldError, gNFormFieldError, emailError, grpNameError;

        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('invalidLength') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert5")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidEmail') !== -1){
                emailError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert2")}</span>;
                emailFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('groupName') !== -1){
                grpNameError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("um.alert7")}</span>;
                gNFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            emailFormFieldError = '';
            gNFormFieldError = '';
            emailError = '';
            grpNameError = '';
        }


           return (
            <div id="umCreateGroupDiv" className={showHideModalStyle}>
                <div className="modal">
                <div className="modal-main panel panel-default" style={{minWidth: "40%", width:"40%"}}>
                <div className="panel-heading">
                    <h2 className="panel-title">{this.props.title}</h2>
                </div>
                <Form compact={false} pad="small" plain={true} onSubmit={this.handleSubmitButton}>
                    <div className="panel-body">
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.groupname")} className={gNFormFieldError}>
                            <TextInput id='groupName' autoFocus={true} name='groupName' value={this.state.groupName} onChange={event =>this.handleGroupNameChange(event)} />
                            {grpNameError}
                        </FormField>
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("resv.create.pool.desc.label")}>
                            <TextInput id='lastName' name='description' value={this.state.groupDesc} onChange={event =>this.handleDescriptionChange(event)} />
                        </FormField>
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.admin")}>
                            <CheckBox id='checkBox' name='administrator' checked={this.state.isAdmin} onChange={this.handleCheckBoxChange} />
                        </FormField>
                        <FormField /*style={{ width: '30em' }}*/ label={LocaleUtility.getLocaleMessage("label.form.text.groupemail")} className={emailFormFieldError}>
                            <TextInput id='email' name='email' value={this.state.groupEmail} onChange={event =>this.handleEmailChange(event)}/>
                            {emailError}
                        </FormField> 
                    </div>
                    <div className="panel-footer panel-button-footer">
                                <div className="button_right_wrap">
                                    <div className="button_wrap_primary">
                                        <Button id="group_add_createBtn" label={LocaleUtility.getLocaleMessage("resv.create.pool.button.label")}
                                            type="submit" plain={false} primary={true}
                                        />
                                    </div>
                                    <div className="button_wrap_negative">
                                        <Button id="group_add_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} type="button" plain={false} secondary={true} onClick={this.handleCancelButton} />
                                    </div>
                                </div>
                    </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
        
    }

    render() {
        return (
             this.infoSectionContent()
        );
    }
}

export default CreateGroup;