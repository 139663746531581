import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { FormField } from "grommet/components/FormField";
import { Button } from "grommet/components/Button";
import File from "../../generic/APFile";
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Store from "../../../flux/CommuterStore";
import Dispatcher from "../../../flux/Dispatcher";
import UserDetailStore from "../../../flux/UserDetailStore";
import TableGrid from "../../../components/commons/table/APTable";
import { TextInput } from 'grommet/components/TextInput';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class CommuterCheckOut extends Component {
    constructor(props) {
        super(props);
        this.uploadFileRef = React.createRef();
        this.state = {
            roleView: false,
            showListView: false,
            lockType: 1,
            checkoutDescription: "",
            licenseUniqueId: "",
            selectedIds: [],
            invalidLicense: [],
            isDownloaded: "false",
            emailIds: "",
            session: undefined
        }
        this.findLabel = this.findLabel.bind(this);
        this.submitCheckOut = this.submitCheckOut.bind(this);
        this.showListViewFn = this.showListViewFn.bind(this);
        this.downloadAction = this.downloadAction.bind(this);
        this.submitDiscard = this.submitDiscard.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.sendEmailAction = this.sendEmailAction.bind(this);
        this.handleChangeEmailId = this.handleChangeEmailId.bind(this);
        this.isEmail = this.isEmail.bind(this);
        this.updateDownloadFlag = this.updateDownloadFlag.bind(this);
        this.checkUserRole = this.checkUserRole.bind(this);
        this.onConfirmSubDiscard = this.onConfirmSubDiscard.bind(this);
        this.onConfirmDownload = this.onConfirmDownload.bind(this);
        this.onConfirmSendEmail = this.onConfirmSendEmail.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onRowClicked(keys, event) {
        this.setState({selectedIds: keys});
    }
    handleChangeEmailId(event) {
        this.setState({emailIds: event.target.value});
    }

    onConfirmSendEmail(flag){
        if(flag === 1){
            let request = {
                resultsList: this.state.gridData,
                emailIds: this.state.emailIds,
                checkoutId: this.state.licenseUniqueId
            }
            FetchHelper.sendMailCommuterLicense(request, this.context.intl);
        }
    }

    sendEmailAction() {
        if(this.state.emailIds === "") {
            ConfigurationUtils.showErrorMessage1("commuter.email.alert", this.context.intl);
        } else if(this.isEmail(this.state.emailIds) === false) {
            ConfigurationUtils.showErrorMessage1("commuter.email.invalid.alert", this.context.intl);
        } else {
            ValidationUtility.confirm(
                this.findLabel("commuter.email.confim.message"), 
                this.onConfirmSendEmail, undefined, "", "");
        }
    }
    updateDownloadFlag() {
        if(Store.getState() && Store.getState().actionType === "commuterdownload") {
            this.setState({isDownloaded: "true"});
            this.setState({emailIds: ""});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    isEmail(s){  	
		var r = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
		return (s.match(r) == null) ? false : true;
	    // var i = 1;
	    // var sLength = s.length;			
	    // while ((i < sLength) && (s.charAt(i) !== "@"))
	    // { i++;			    
	    // }
	   
	    // if ((i >= sLength) || (s.charAt(i) !== "@")) 
		// return false;
	    // else i += 2;		
	    // while ((i < sLength) && (s.charAt(i) !== "."))
	    // { i++;		    	 
	    // }
	    // if ((i >= sLength - 1) || (s.charAt(i) !== ".")) return false;
	    // else return true;		  
	}
    submitCheckOut() {
        if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state) {
            let files = this.uploadFileRef.current.state.selectedFiles;
            if(files && files.length === 0) {
                ConfigurationUtils.showErrorMessage1("commuter.checkout.alert", this.context.intl);
            } else {
                let data = new FormData();
                let flag = true;
                let flag_Size = true;
                for (var i = 0; i < files.length; i++) {
                    let fileObj = files[i];
                    let fileName = fileObj.name;
                    let extIndex = fileName.lastIndexOf(".");
                    let fileExtn = fileName.substr(extIndex+1, fileName.length);
                    if(fileExtn !== "dat" && fileExtn !== "xml" 
                        && fileExtn !== "pdf" && fileExtn !== "txt" && fileExtn !== "lcor" &&  fileExtn !== "lcir") {
                        flag = false;
                    }
                    if(fileObj.size>102400)
                    {
                        flag_Size=false;
                    }
                    data.append('file', fileObj);
                }
                if(flag && flag_Size) {
                    FetchHelper.submitCommuterCheckout(data, this.context.intl);
                } else {
                    ConfigurationUtils.showErrorMessage1("commuter.invalid.file", this.context.intl);
                }
            }
        }
    }

    onConfirmDownload(flag){
        if(flag === 1){
            let request = {
                resultsList: this.state.gridData,
                checkoutId: this.state.licenseUniqueId
            }
            FetchHelper.downloadCommuterLicense(request, this.context.intl);
        }
    }
    downloadAction() {
        ValidationUtility.confirm(
            this.findLabel("commuter.download.warning"),
            this.onConfirmDownload, undefined, "", "");
    }
    componentDidMount() {
        Store.on("change", this.showListViewFn);
        Store.on("change", this.updateDownloadFlag);
        let loadSpinAction = "USER_ROLE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: this.findLabel("label.spinner.loading")
        });
        UserDetailStore.on("change", this.checkUserRole);
        FetchHelper.getUserPriviledge("REMOTE_CO");
        this.setState({session: FetchHelper.getSession()});
    }
    componentWillUnmount() {
        Store.removeListener("change", this.showListViewFn);
        Store.removeListener("change", this.updateDownloadFlag);
        UserDetailStore.removeListener("change", this.checkUserRole);
    }

    onConfirmSubDiscard(flag){
        if(flag === 1){
            let reqeust = {
                resultsList: this.state.gridData,
                licensesToDiscard: this.state.selectedIds,
                isDownloaded: this.state.isDownloaded,
            }
            FetchHelper.discardCommuterLicense(reqeust, this.context.intl);
        }
    }
    submitDiscard() {
        if(this.state.selectedIds.length > 0) {
            ValidationUtility.confirm(
                this.findLabel("commuter.discard.warning"),
                this.onConfirmSubDiscard, undefined, "", "");
        } else {
            ConfigurationUtils.showErrorMessage1("commuter.select.license.alert", this.context.intl);
        }
    }
    showListViewFn() {
        if(Store.getState() && Store.getState().actionType === "checkout") {
            this.setState({showListView: Store.getState().showListView});
            let data = Store.getState().data;
            let results = data.resultsList;
            let invLicenses = [];
            let rowDataList = [];
            if(results.length > 0) {
                results.map(e => {
                    if(e.valid === false) {
                        let element = <tr>
                            <td>{(e.errorMessage!==e.customMessage)?(<p>{e.errorMessage}<br/>{e.customMessage}</p>):(e.errorMessage)}</td>
                        </tr>
                        invLicenses.push(element);
                    } else {
                        rowDataList.push(e);
                    }
                    return "";
                });
            }
            this.setState({invalidLicense: invLicenses});
            this.setState({gridData: rowDataList});
            this.setState({lockType: data.lockType});
            if(data.decription !== undefined && data.decription !== null) {
                this.setState({checkoutDescription: data.decription});
            } else {
                this.setState({checkoutDescription: "RCOMMUTER"});
            }
            if(data.uniqueId !== undefined && data.uniqueId !== null) {
                this.setState({licenseUniqueId: data.uniqueId});
            } else {
                this.setState({licenseUniqueId: "NA"});
            }
            
            this.setState({selectedIds: []});
            let fEl = document.getElementById("commuter_checkout_browse");
            if(fEl !== undefined && fEl !== null) {
                fEl.value = null;
            }
            if(this.uploadFileRef !== undefined && this.uploadFileRef.current !== undefined && this.uploadFileRef.current !== null) {
                const fList = Object.assign([], this.uploadFileRef.current.state.filesList);
                fList.splice(0, 1);
                fList.push(this.findLabel("label.select.files.click.add.files"));
                this.uploadFileRef.current.setState({filesList: fList});
                this.uploadFileRef.current.setState({fileNames: ""});
                this.uploadFileRef.current.setState({selectedFiles: []});
            }
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }

    checkUserRole() {
        this.setState({roleView: UserDetailStore.getState().status});
    }

    render() {
        let showView = this.state.showListView;
        let gData = [];
        if(this.state.gridData !== undefined) {
            gData = this.state.gridData;
        }
        if(gData.length === 0 && this.state.invalidLicense.length === 0) {
            showView = false;
        }
        let clientInfoLabel = this.findLabel("commuter.client.mac.label");
        if(this.state.lockType === 1) {
            clientInfoLabel = this.findLabel("commuter.client.ip.label");
        } else if(this.state.lockType === 2) {
            clientInfoLabel = this.findLabel("commuter.client.host.label");
        } else if(this.state.lockType === 4) {
            clientInfoLabel = this.findLabel("commuter.client.clientid.label");
        }
        /* let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(!isAdmin) {
            let msg = _Intl2.default.getMessage(intl, "commuter.checkout.no.admin.access");
            let userName = "";
            let ss = this.state.session;
            if(ss !== undefined && ss.user !== undefined) {
                msg = msg + "("+ss.user.userName+")";
            }
            ConfigurationUtils.showErrorMessage1(msg, intl);
        } */
        return(
            <div className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("commuter.checkout.title")} helpTopic="remote_commuter"/>
                {showView ? 
                    <div>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {this.findLabel("commuter.description.label")}
                                        </td>
                                        <td>
                                             - {this.state.checkoutDescription}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {this.findLabel("commuter.unique.licenseid.label")}
                                        </td>
                                        <td>
                                             - {this.state.licenseUniqueId}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <TableGrid id="commuter_checkout_grid_id"
                                header={[
                                    {
                                        columnName: "featureDescription",
                                        headerLabel: this.findLabel("commuter.feature.description.label"),
                                        width: "auto",
                                        columnKey: "featureDescription"
                                    },
                                    {
                                        columnName: "capacity",
                                        headerLabel: this.findLabel("commuter.capacity.label"),
                                        href: false,
                                        width: "auto"
                                    },
                                    {
                                        columnName: "extExpiryDate",
                                        headerLabel: this.findLabel("commuter.expirtydate.label"),
                                        href: false,
                                        width: "auto"
                                    },
                                    {
                                        columnName: "clientInfo",
                                        headerLabel: clientInfoLabel,
                                        href: false,
                                        width: "auto"
                                    }
                                ]}
                                rowData={gData}
                                pagination={this.state.pagination}
                                rowSelection={3}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                onChangeCheckBoxCallback={this.onRowClicked}
                                rowKey="licenseID"
                                noDataFoundText=" "
                            />
                        </div>
                        {this.state.invalidLicense.length > 0 ?  
                        (<div className="invalilicenses"> 
                            {this.findLabel("label.invalid.licenses")}
                        </div>)
                        : <></>
                        }
                        <div>
                            <table id="invalid_license_table_id">
                                <tbody>
                                    {this.state.invalidLicense}
                                </tbody>
                            </table>
                        </div>
                        {gData.length > 0 ?
                            <div className="saveBtnStyle">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="button_wrap_primary">
                                                <Button id="commuter_checkout_discardBtn" label={this.findLabel("label.button.discard")} plain={false} primary={true} onClick={this.submitDiscard} style={{float:"left", marginRight:"1.1em"}}/>
                                                <Button id="commuter_checkout_downloadBtn" label={this.findLabel("label.button.dowload")} plain={false} primary={true} onClick={this.downloadAction} style={{float:"left", marginRight:"1.1em"}}/>
                                                <span style={{float:"left", marginRight:"0.1em", marginTop:"0.4em"}}>{LocaleUtility.getLocaleMessage("subscription.email.field.label")}</span>
                                                <FormField style={{ width: '20em', float:"left", marginRight:"1.1em" }} label= "" >
                                                    <TextInput id="commuter_checkout_emailId" value={this.state.emailIds} onChange={event =>this.handleChangeEmailId(event)}/>
                                                </FormField>
                                                <Button id="commuter_checkout_sendmailBtn" label={this.findLabel("label.button.send.email")} plain={false} primary={true} onClick={this.sendEmailAction} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        :null}
                    </div>
                : null }
                {this.state.roleView && !showView ?
                    <div className="border_white_backg">
                        <div className="file_upload_wrap">
                            <div id="attach_file_table_id">
                                        <div className="commuter_file_upload_wrap" style={{width: "50%"}}>
                                            <File ref={this.uploadFileRef}
                                                id = "commuter_checkout_browse"
                                                name = "commuter_checkout_browse"
                                                multiple = {false}
                                                label = {this.findLabel("label.button.add.files")}
                                            />
                                        </div>
                                        <div className="button_wrap_primary">
                                            <Button id="commuter_checkOutBtn" label={this.findLabel("label.button.checkout")} plain={false} primary={true} onClick={this.submitCheckOut} />
                                        </div>
                            </div>
                        </div>
                    </div>
                :null}
                
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// CommuterCheckOut.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default CommuterCheckOut;