import React, { Component } from "react";
import { Anchor } from 'grommet/components/Anchor';
import { TextInput } from 'grommet/components/TextInput';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from 'grommet/components/Header';
import MembersTable from "./PoolMembersTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import Store from "../../../flux/ReservationManagementStore";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as DateUtility from "../../../utils/DateUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import Dispatcher from "../../../flux/Dispatcher";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { Button } from "grommet/components/Button";
import Select from "../../commons/APLSSelect";
import { DataSearch as Search } from 'grommet/components/DataSearch';
import { Data } from "grommet/components";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class PoolMembers extends Component {
    constructor(props) {
        super(props);
        this.memeberTabelRef = React.createRef();
        this.state = {
            activeIndex: this.props.activeIndex,
            addUserNameError: "",
            userName: "",
            ipAddress: "",
            hostId: "",
            clientId: "",
            userRows: [],
            ipAddressRows: [],
            hostIdRows: [],
            clientIdRows: [],
            poolName: this.props.poolName,
            selectedAttributes: [],
            selAttrType: undefined,
            enteredAttrValue: "",
            fullList: [],
            dataMap: {},
            selAttrFilter: undefined,
            attributeSearchedValue: undefined
        }
        this.findLabel = this.findLabel.bind(this);
        this.tabClickAction = this.tabClickAction.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleChangeIpAddress = this.handleChangeIpAddress.bind(this);
        this.handleChangeHostId = this.handleChangeHostId.bind(this);
        this.handleChangeClientId = this.handleChangeClientId.bind(this);
        this.findData = this.findData.bind(this);
        this.deleteUserAction = this.deleteUserAction.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.deleteClientIdAction = this.deleteClientIdAction.bind(this);
        this.deleteIpAddressAction = this.deleteIpAddressAction.bind(this);
        this.deleteHostIdAction = this.deleteHostIdAction.bind(this);
        this.addUserToPool = this.addUserToPool.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.addIpAddressToPool = this.addIpAddressToPool.bind(this);
        this.addHostIdToPool = this.addHostIdToPool.bind(this);
        this.addClientIdToPool = this.addClientIdToPool.bind(this);
        this.refreshMembersData = this.refreshMembersData.bind(this);
        this.refreshMembers = this.refreshMembers.bind(this);
        this.selectedActionCall = this.selectedActionCall.bind(this);
        this.selectAttributeType = this.selectAttributeType.bind(this);
        this.handleChangeAttributeValue = this.handleChangeAttributeValue.bind(this);
        this.addActionCall = this.addActionCall.bind(this);
        this.okAction = this.okAction.bind(this);
        this.refrshCall = this.refrshCall.bind(this);
        this.onAttributeSearchAction = this.onAttributeSearchAction.bind(this);
        this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        Store.on("change", this.refreshGrid);
        Store.on("change", this.refreshMembersData);
        this.findData(this.props.poolName);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
        Store.removeListener("change", this.refreshMembersData);
    }
    tabClickAction(index, event) {
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({activeIndex: index});
    }
    handleChangeUser(event) {
        this.setState({userName: event.target.value});
    }
    handleChangeIpAddress(event) {
        this.setState({ipAddress: event.target.value});
    }
    handleChangeHostId(event) {
        this.setState({hostId: event.target.value});
    }
    handleChangeClientId(event) {
        this.setState({clientId: event.target.value});
    }
    refreshMembers(poolName, isPoolChanged) {
        this.setState({poolName: poolName});
        this.findData(poolName);
        if(isPoolChanged !== undefined && isPoolChanged === true) {
            this.setState({activeIndex: 0});
        }
    }
    findData(poolName) {
        FetchHelper.findPoolMembers(poolName, this.context.intl);
    }
    deleteUserAction() {
        this.submitRequest("user", "", false);
    }
    deleteClientIdAction() {
        this.submitRequest("clientid", "", false);
    }
    deleteIpAddressAction() {
        this.submitRequest("ip", "", false);
    }
    deleteHostIdAction() {
        this.submitRequest("hostid", "", false);
    }
    selectedActionCall(selectedValue) {
        this.setState({selectedAttributes: selectedValue});
    }
    addUserToPool() {
        this.submitRequest("user", this.state.userName, true);
    }
    addIpAddressToPool() {
        this.submitRequest("ip", this.state.ipAddress, true);
    }
    addHostIdToPool() {
        this.submitRequest("hostid", this.state.hostId, true);
    }
    addClientIdToPool() {
        this.submitRequest("clientid", this.state.clientId, true);
    }

    onConfirmSubmit(flag, data){
        if(flag === 1){
            let usersList = [];
            let hostIdList = [];
            let ipAddressList = [];
            let clientIdList = []
            let dataMap = this.state.dataMap;
            this.state.selectedAttributes.map(id => {
                if(dataMap !== undefined) {
                    let mapObj = dataMap[id];
                    if(mapObj !== undefined) {
                        let attrValue = mapObj.attributeValue;
                        let attrType = mapObj.attributeType;
                        if(attrType === "user") {
                            usersList.push(attrValue);
                        } else if(attrType === "hostid") {
                            hostIdList.push(attrValue);
                        } else if(attrType === "ip") {
                            ipAddressList.push(attrValue);
                        } else if(attrType === "clientid") {
                            clientIdList.push(attrValue);
                        }
                    }
                }
                return "";
            });
            let request = {
                name: this.state.poolName,
                members: {
                    clientIDList: {
                        clientID: clientIdList
                    },
                    hostList: {
                        host: hostIdList 
                    },
                    ipAddressList: {
                        ipAddress: ipAddressList
                    },
                    userNameList: {
                        userName: usersList
                    }
                }
            }
            FetchHelper.deleteMemeberToPool(request, this.context.intl, "delete", data.type, data.value);
        }
    }
    submitRequest(type, value, addAction) {
        let request;
        if(!ValidationUtility.isEmpty(value) && addAction) {
            if(type === "user") {
                ConfigurationUtils.showErrorMessage1("resv.member.user.mandatory", this.context.intl);
            } else if(type === "ip") {
                ConfigurationUtils.showErrorMessage1("resv.member.ip.mandatory", this.context.intl);
            } else if(type === "hostid") {
                ConfigurationUtils.showErrorMessage1("resv.member.host.mandatory", this.context.intl);
            } else if(type === "clientid") {
                ConfigurationUtils.showErrorMessage1("resv.member.client.mandatory", this.context.intl);
            }
            return false;
        } else if(type === "ip" && addAction) {
            if(!ValidationUtility.validateIPAddress(value)) {
                ConfigurationUtils.showErrorMessage1("reservation.alert8", this.context.intl);
                return false;
            }
        }else if(type === "user" && addAction){
            if(!ValidationUtility.validateSpecialChars(value)) {
                ConfigurationUtils.showErrorMessage1("reservation.member.user.invalid", this.context.intl);
                return false;
            }
        }else if(type === "hostid" && addAction) {
                if(!ValidationUtility.validateSpecialChars(value)) {
                ConfigurationUtils.showErrorMessage1("reservation.member.host.invalid", this.context.intl);
                return false;
            }
        } else if(type === "clientid" && addAction) {
                if(!ValidationUtility.validateSpecialChars(value)) {
                ConfigurationUtils.showErrorMessage1("reservation.member.client.invalid", this.context.intl);
                return false;
            }
        }
        if(type === "user" && addAction){
            request = {
                name: this.state.poolName,
                members: {
                    userNameList: {
                        userName: [
                            value
                        ]
                    }
                }
            }
        } else if(type === "ip" && addAction){
            request = {
                name: this.state.poolName,
                members: {
                    ipAddressList: {
                        ipAddress: [
                            value
                        ]
                    }
                }
            }
        } else if(type === "hostid" && addAction){
            request = {
                name: this.state.poolName,
                members: {
                    hostList: {
                        host: [
                            value
                        ]
                    }
                }
            }
        } else if(type === "clientid" && addAction){
            request = {
                name: this.state.poolName,
                members: {
                    clientIDList: {
                        clientID: [
                            value
                        ]
                    }
                }
            }
        }
        if(addAction) {
            FetchHelper.addMemeberToPool(request, this.context.intl, "add", type, value);
        } else {
            if(this.state.selectedAttributes !== undefined && this.state.selectedAttributes.length > 0) {
                let msg = LocaleUtility.getLocaleMessage("resv.member.delete.warn");
                let data = {type: type, value: value};
                ValidationUtility.confirm(msg, this.onConfirmSubmit, data, "", "");
            } else {
                ConfigurationUtils.showErrorMessage1("please.select.record", this.context.intl);
            }
        }
        
    }
    refreshMembersData() {
        if(Store.getState() && Store.getState().actionType === "adddeletemember") {
            this.setState({userName: ""});
            this.setState({ipAddress: ""});
            this.setState({hostId: ""});
            this.setState({clientId: ""});
            this.setState({enteredAttrValue: "", selectedAttributes: []});
            this.findData(this.state.poolName);
            let params = {};
            let type = Store.getState().type;
            let value = Store.getState().value;
            let action= Store.getState().action;
            let t = LocaleUtility.getLocaleMessage("resv.add.user.label");
            if(type === "ip") {
                t = LocaleUtility.getLocaleMessage("resv.add.ip.label");
            } else if(type === "hostid"){
                t = LocaleUtility.getLocaleMessage("resv.add.host.label");
            } else if(type === "clientid"){
                t = LocaleUtility.getLocaleMessage("resv.add.client.label");
            }
            params["type"] = t;
            params["value"] = value;
            if(action === "delete") {
                ConfigurationUtils.showSuccessMessageWithParams(params, "resv.member.delete.success", this.context.intl);
            } else {
                ConfigurationUtils.showSuccessMessageWithParams(params, "resv.member.added.success", this.context.intl);
            }
            if(this.memeberTabelRef !== undefined && this.memeberTabelRef.current !== undefined) {
                this.memeberTabelRef.current.clearSelections();
            }
        }
    }
    refrshCall(selAttrFilter, searchedValue) {
        let data = Store.getState().data;
        if(data) {
            let usersList = [];
            let hostIdList = [];
            let ipAddressList = [];
            let clientIdList = [];
            let fullList = [];
            let memb = data.poolAttributes;
            let dataMap = {};
            let selFiterValues = [];
            if(selAttrFilter !== undefined && selAttrFilter.value !== undefined) {
                selFiterValues = selAttrFilter.value.split(",");
            } else if(selAttrFilter === undefined) {
                selFiterValues.push("hostid");
                selFiterValues.push("user");
                selFiterValues.push("ip");
                selFiterValues.push("clientid");
            }
            if(memb) {
                memb.map((obj, index) => {
                    let attType = "hostid";
                    if(obj.type === "User" || obj.type === LocaleUtility.getLocaleMessage("resv.pool.username.tab.label")) {
                        attType = "user";
                    } else if(obj.type === "IP" || obj.type === LocaleUtility.getLocaleMessage("resv.pool.ipaddr.tab.label")) {
                        attType = "ip";
                    } else if(obj.type === "Client" || obj.type === LocaleUtility.getLocaleMessage("resv.pool.clientid.tab.label")) {
                        attType = "clientid";
                    }
                    dataMap[obj.id] = {attributeValue: obj.value, attributeType: attType};
                    if(selFiterValues.indexOf(attType) > -1) {
                        if(obj.createdOn !== undefined) {
                             obj.createdOnDate = DateUtility.getFormatedDate(new Date(obj.createdOn));
                        }
                        if(searchedValue !== undefined && obj.value !== undefined && searchedValue !== "") {
                            let attValue = obj.value.toLowerCase();
                            let sValue = searchedValue.toLowerCase();
                            if(attValue.indexOf(sValue) > -1) {
                                fullList.push(obj);
                            }
                        } else {
                            fullList.push(obj);
                        }
                    }
                    return "";
                });
            }
            if(this.memeberTabelRef !== undefined && this.memeberTabelRef.current !== undefined) {
                this.memeberTabelRef.current.clearSelections();
            }
            this.setState({hostIdRows: hostIdList});
            this.setState({userRows: usersList});
            this.setState({ipAddressRows: ipAddressList});
            this.setState({clientIdRows: clientIdList, fullList: fullList, attributeSearchedValue: undefined});
            this.setState({dataMap: dataMap});
        }
    }
    refreshGrid() {
        if(Store.getState() && Store.getState().actionType === "poolmembers") {
            this.refrshCall(this.state.selAttrFilter, undefined);
        }
    }
    selectAttributeType(event) {
        this.setState({selAttrType: event.option, enteredAttrValue: ""});
    }
    handleChangeAttributeValue(event) {
        let enteredValue = event.target.value;
        let selType = "user";
        if(this.state.selAttrType !== undefined) {
            selType = this.state.selAttrType.value;
        }
        if(selType === "user") {
            this.setState({userName: enteredValue});
        } else if(selType === "ip") {
            this.setState({ipAddress: enteredValue});
        } else if(selType === "hostid") {
            this.setState({hostId: enteredValue});
        } else if(selType === "clientid") {
            this.setState({clientId: enteredValue});
        }
        this.setState({enteredAttrValue: enteredValue});
    }
    addActionCall() {
        let selType = "user";
        if(this.state.selAttrType !== undefined) {
            selType = this.state.selAttrType.value;
        }
        if(selType === "user") {
            this.addUserToPool();
        } else if(selType === "ip") {
            this.addIpAddressToPool();
        } else if(selType === "hostid") {
            this.addHostIdToPool();
        } else if(selType === "clientid") {
            this.addClientIdToPool();
        }
    }
    okAction(event) {
        this.setState({selAttrFilter: event.option});
        this.refrshCall(event.option, undefined);
    }
    onAttributeSearchAction(event) {
        this.setState({attributeSearchedValue: event.target.value});
        this.refrshCall(this.state.selAttrFilter, event.target.value);
    }
    render() {
        //let intl = this.context.intl;
        let attFilterOptions = [];
        let selAttrType = this.state.selAttrType;
        let selAttrFilterValue = this.state.selAttrFilter;
        let userNameLabel = this.findLabel("resv.pool.username.tab.label");
        let hostIdLabel = this.findLabel("resv.pool.hostid.tab.label");
        let ipAddressLabel = this.findLabel("resv.pool.ipaddr.tab.label");
        let clientIdLabel = this.findLabel("resv.pool.clientid.tab.label");
        if(selAttrType === undefined) {
            selAttrType = {value: "user", label: userNameLabel};
        }
        if(selAttrFilterValue === undefined) {
            selAttrFilterValue = {value: "user,ip,hostid,clientid", 
            label: this.findLabel("label.select.all")}
        }else{
            let attributeTypeArr = selAttrFilterValue.value.split(',');
            attributeTypeArr.sort();
            let sortedStr = attributeTypeArr.join(',');
            if(sortedStr === "clientid,hostid,ip,user"){
                selAttrFilterValue = {value: "user,ip,hostid,clientid", 
                label: this.findLabel("label.select.all")}
            }
        }
        attFilterOptions.push({value: "user", label: userNameLabel});
        attFilterOptions.push({value: "ip", label: ipAddressLabel});
        attFilterOptions.push({value: "hostid", label: hostIdLabel});
        attFilterOptions.push({value: "clientid", label: clientIdLabel});

        let locale = LocaleUtility.getCurrentLocale();
        let searchHeight = "30px", placeHolderCSS="search_input_wrap";
        if (locale == 'de' || locale == 'fr'){
            searchHeight = "50px";
            placeHolderCSS = "search_input_wrap expandPlaceHolder";
        }

        return(
            <div className="reservationMemTitle_wrapper">
                {this.props.poolSize > 0 ?
                <div className="reservationMemTitle_wrap">
                    <div id="reservation_pool_name_id">
                        {this.findLabel("label.pool")}
                    
                    <div>
                        :&nbsp;{this.props.poolName}
                    </div>
                    </div>
                    <div id="reservation_href_id">
                        <Anchor 
                            label={this.findLabel("resv.label.reservation")} 
                            onClick={this.props.onClickReservationLink} 
                        />
                    </div>
                </div>
                : null }
                {this.props.poolSize > 0 ?
                <div id="resv_member_tabs_div_id">
                    <Header fixed={false} margin="none" id="resrv_user_details_wrap">
                        <Tabs style={{width: "100%"}} activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction} >
                            <Tab id="resv_user_name_tab_id" title={this.findLabel("reservation.attributes.title")}
                                className='subtab_title primary'>
                                <div className="reservation-attribute-tab">
                                    <div className="attribute_action_wrap">
                                        {!this.props.fullView && (
                                        <div className="select_attribute">
                                            <Select id="resv-add-attribute-selection" placeholder={this.findLabel("configpageIpHostSelect")}
                                                options={attFilterOptions}
                                                value={selAttrType}
                                                onChange={this.selectAttributeType} 
                                                label= {this.findLabel("label.button.add")+": "}
                                                enabledSearch = {false}
                                                disabledScroll = {true}
                                                width= {10}
                                            />
                                        </div>)}
                                        {!this.props.fullView ? 
                                            <div className="attribute_input">
                                                <TextInput id="resv_add_user_id" style={{width: "270px"}}
                                                    value={this.state.enteredAttrValue} 
                                                    onChange={event =>this.handleChangeAttributeValue(event)}
                                                    placeholder={this.findLabel("type.value.to.add")}
                                                />
                                            </div>
                                        : null}
                                        {!this.props.fullView && (
                                            <div className="attribute_add_wrap button_wrap_primary">
                                                <Button id="user-attribute-add-btn" label={this.findLabel("label.button.add")} plain={false} primary={true} onClick={this.addActionCall}/>
                                            </div>
                                        )}
                                        {!this.props.fullView && (
                                            <div className="attribute_delect_wrap button_wrap_negative">
                                                {this.state.selectedAttributes.length > 0 ?
                                                <Button id="user-attribute-delete-btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true} onClick={event=>this.deleteUserAction()} />
                                                :
                                                <Button id="user-attribute-delete-btn" label={this.findLabel("label.button.delete")} plain={false} secondary={true} critical={true} />
                                                }
                                            </div>
                                        )}
                                        {!this.props.fullView && (
                                            <div id="pool-attributes-search-div" className={placeHolderCSS}>
                                                <Data>
                                                <Search style={{ height:searchHeight}} placeholder={this.findLabel('label.type.to.search')}
                                                    inline={true}
                                                    dropdown={false}
                                                    responsive={false}
                                                    size='small'
                                                    dropAlign={{"right": "right"}}
                                                    value={this.state.attributeSearchedValue}
                                                    onChange={event=>this.onAttributeSearchAction(event)} 
                                                    id="pool-attributes-search-id"
                                                />
                                                </Data>
                                            </div>
                                        )}
                                        {!this.props.fullView && (
                                        <div>
                                            <Select id="resv-search-attribute-selection" placeholder={this.findLabel("configpageIpHostSelect")}
                                                options={attFilterOptions}
                                                value={selAttrFilterValue}
                                                onChange={this.okAction} 
                                                label= {this.findLabel("label.type")+": "}
                                                enabledSearch = {false}
                                                width= {20}
                                                disabledScroll = {true}
                                                multiSelect = {true}
                                            />
                                        </div>)}
                                    </div>
                                    <div className="memebers_list_wrap">
                                        <MembersTable 
                                            ref = {this.memeberTabelRef}
                                            //header = {this.findLabel("Associated Members", intl)}
                                            rows = {this.state.fullList}
                                            totalRecords = {this.state.fullList !== undefined ? this.state.fullList.length : 0}
                                            label = {this.findLabel("resv.pool.username.tab.label")}
                                            selectedAction = {this.selectedActionCall}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </Header>
                </div>
                : null }
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// PoolMembers.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PoolMembers;