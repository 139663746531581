import ReduceStore from "./ReduceStore";

class AppConfigSettingStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            mailServerName: "",
            portNumber:0,
            adminEmail:"",
            maxRecrodsRetain: 200000,
            purgeRecordsOlder: 0,
            proxyPortNumber: 8080,
            proxyAddress:"",
            auditType: "0",
            noOfNotifyAdvance: 2,
            serverConfigPort: 5814,
            serverConfigIpOrHost:"",
            autoPurgRequired:"0",
            usageHubConnector: "false",
            ctConnector:"false",
            borrowAllowed: "0",
            preNotificationCheck: "false",
            overrideClientAllowed: "0",
            protocolType: "",
            protocolHost: "",
            protocolPort: 0,
            discoveryStatus: "0",
            productAthentication: "",
            systemInfoList:[],
            serverScheme: ""
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_APP_CONFIG_SETTING_DETAILS": {
                return {
                    formData: action.payload.formData
                };
            } case "PRODUCT_FEATURE_COUNT" :{
                return{
                    type: "PRODUCT_FEATURE_SETUP",
                    productFeatureCount: action.payload.productFeatureCount,
                    isNamedFeature: action.payload.isNamedFeature,
                    isCustomReport: action.payload.isCustomReport,
                    isProductReport: action.payload.isProductReport
                }
            }
            default:
                return state;
        }
    }
}
export default new AppConfigSettingStore();