import React, { Component } from "react";
import SpinningStore from "../../flux/SpinningStore";
import * as FetchHelper from "../../utils/FetchHelper";
import * as Utils from "../../utils/Util";
import {USER_UPDATE_FORM_PATH} from "../../utils/FrontendPagePath";
import { Box } from "grommet/components/Box";
import { Label } from "../commons/grommet/Label";
var idmpoints = Utils.getIdmPoints();
var nonadminpoints = Utils.getNonadminPoints();
var viewonlypoints = Utils.getViewonlyPoints();

class SpinningNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startSpin: false,
            message: ""
        };

        this.setSpinningProgress = this.setSpinningProgress.bind(this);
    }

    setSpinningProgress() {
        this.setState({
            startSpin: SpinningStore.getState().startSpin,
            message: SpinningStore.getState().message,
        });
    }

    componentDidMount() {
        SpinningStore.on("change", this.setSpinningProgress);
    }

    componentWillUnmount() {
        SpinningStore.removeListener("change", this.setSpinningProgress);
    }

    showSpinningDiv() {
        var divSpinStyle, spinBoxStyle, spinLabelStyle, spinLableSize, 
            viewOnlyBg = 'rgba(255,255,255,255)', defaultBg = 'rgba(50,52,53,0.5)';
        var spinSize = 'OtLoadingSpinnerSmall';
        var session = FetchHelper.getSession();
        if (this.props.centerPage) {
            spinSize = 'OtLoadingSpinner';
            if(session !== undefined && session.roletype !== undefined ){
                var roletype = session.roletype === 'product' || session.roletype === 'fullview';
                if(roletype && nonadminpoints.some(v => (window.location.pathname.indexOf(v) > -1))){
                    defaultBg = viewOnlyBg;
                } else if(session.roletype === 'fullview' && viewonlypoints.some(v => (window.location.pathname.indexOf(v) > -1))){
                    defaultBg = viewOnlyBg;
                } else if(session.isidm !== undefined && session.isidm 
                    && idmpoints.some(v => (window.location.pathname.indexOf(v) > -1))){
                    defaultBg = viewOnlyBg;
                } else if(session.dbType !== undefined && 
                    session.dbType !== 'DB' && 
                    window.location.pathname.indexOf(USER_UPDATE_FORM_PATH) > -1){
                    defaultBg = viewOnlyBg;
                }
            } else {
                defaultBg = viewOnlyBg;
            }
            divSpinStyle = {
                position: 'fixed',
                background: defaultBg,
                left: 0,
                bottom: 0,
                right: 0,
                top: 0,
                zIndex: 999,
                textAlign: 'center',
                width: '100%',
                height: '100%'
            };

            spinBoxStyle = {
                marginTop: '30%',
                verticalAlign: 'middle'
            };

            spinLabelStyle = {
                marginTop: '1em',
                verticalAlign: 'middle'
            };

            spinLableSize = 'spinLabelLarge';

        } else {
            divSpinStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                marginTop: "10px",
                marginBottom: "20px",
                position: "absolute",
                height: "1px",
                top: "-25px",
                width: "100%"
            };
            spinLabelStyle = {
                float: 'left',
                marginLeft: '1em'
            };
            spinLableSize = 'spinLableSmall';
        }
        return (
            <div style={divSpinStyle}>
                <div id="spinnerIcon" style={spinBoxStyle}><Box className={spinSize} /></div>
                <div id="spinnerMsg" style={spinLabelStyle}><Label className={spinLableSize}>{this.state.message}</Label></div>
            </div>
        );
    }
    render() {

        let showSpinComponent;
        if (this.state.startSpin) {
            showSpinComponent = this.showSpinningDiv();
        }

        return (
            <div className="spinner_wrap">{showSpinComponent}</div>

        )
    }
}

export default SpinningNotification;