import ReduceStore from "./ReduceStore";

class AuthTokenStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            tokens: [],
        };
    }
    reduce(action, state) {
        switch (action.type) {
            case "PROCESS_AUTH_TOKEN": {
                    return { ...state, 
                        tokens: action.payload.tokens,
                        appExpiryDays : action.payload.appExpiryDays
                    };
                }
            
            default:
                    return state;
        }
    }
}

export default new AuthTokenStore();