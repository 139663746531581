import * as LocaleUtility from "./LocaleUtility";

const locale = {
  'en': 'en',
  'es': 'es',
  'de': 'de',
  'fr': 'fr',
  'ja': 'ja',
  'zh': 'zh'
};
var language = navigator.language.split(/[-_]/)[0];
if (locale[language] === undefined){
    language='en';
}

export const DATE_FORMAT = "m/d/yyyy";
export const DATE_FORMAT_PLACEHOLDER = "M/D/YYYY";
export function getDate(date) {
    return (date.getMonth()+1)+"/"+date.getDate()+"/"+date.getFullYear();
}
export function getFormatedDate(date) {
    if(date !== undefined && date > 0) {
        const months = ["short.month.name.Jan", "short.month.name.Feb", "short.month.name.Mar",
                        "short.month.name.Apr", "short.month.name.May", "short.month.name.Jun", 
                        "short.month.name.Jul", "short.month.name.Aug", "short.month.name.Sep",
                        "short.month.name.Oct", "short.month.name.Nov", "short.month.name.Dec"];
        //Few browsers/OSs provide the timezone name in their local string
        var tzsregex = /\b(ACDT|ACST|ACT|ADT|AEDT|AEST|AFT|AKDT|AKST|AMST|AMT|ART|AST|AWDT|AWST|AZOST|AZT|BDT|BIOT|BIT|BOT|BRT|BST|BTT|CAT|CCT|CDT|CEDT|CEST|CET|CHADT|CHAST|CIST|CKT|CLST|CLT|COST|COT|CST|CT|CVT|CXT|CHST|DFT|EAST|EAT|ECT|EDT|EEDT|EEST|EET|EST|FJT|FKST|FKT|GALT|GET|GFT|GILT|GIT|GMT|GST|GYT|HADT|HAEC|HAST|HKT|HMT|HST|ICT|IDT|IRKT|IRST|IST|JST|KRAT|KST|LHST|LINT|MART|MAGT|MDT|MET|MEST|MIT|MSD|MSK|MST|MUT|MYT|NDT|NFT|NPT|NST|NT|NZDT|NZST|OMST|PDT|PETT|PHOT|PKT|PST|RET|SAMT|SAST|SBT|SCT|SGT|SLT|SST|TAHT|THA|UYST|UYT|VET|VLAT|WAT|WEDT|WEST|WET|WST|YAKT|YEKT)\b/gi;
        //In other browsers the timezone needs to be estimated based on the offset:
        var timezonenames = {"UTC+0":"GMT","UTC+1":"CET","UTC+2":"EET","UTC+3":"EEDT","UTC+3.5":"IRST","UTC+4":"MSD","UTC+4.5":"AFT","UTC+5":"PKT","UTC+5.5":"IST","UTC+6":"BST","UTC+6.5":"MST","UTC+7":"THA","UTC+8":"AWST","UTC+9":"AWDT","UTC+9.5":"ACST","UTC+10":"AEST","UTC+10.5":"ACDT","UTC+11":"AEDT","UTC+11.5":"NFT","UTC+12":"NZST","UTC-1":"AZOST","UTC-2":"GST","UTC-3":"BRT","UTC-3.5":"NST","UTC-4":"CLT","UTC-4.5":"VET","UTC-5":"EST","UTC-6":"CST","UTC-7":"MST","UTC-8":"PST","UTC-9":"AKST","UTC-9.5":"MIT","UTC-10":"HST","UTC-11":"SST","UTC-12":"BIT"};
        let usertime;
        let serverTimeZone = localStorage.getItem("ServerTimeZone");
        let timezone = localStorage.getItem("ServerTimeZoneOffset");
        if(serverTimeZone !== undefined && serverTimeZone !== "") {
            try {
                let needToConvert = date;
                let convertedDate = needToConvert.toLocaleString("en-US", {timeZone: serverTimeZone});
                date = new Date(convertedDate);
            } catch(error) {
                /**
                 * Converting to browser time zone, if browser does not support for server time zone conversion.
                 */
                usertime = date.toLocaleString();
                timezone = usertime.match(tzsregex);
                if (timezone) {
                    timezone = timezone[timezone.length-1];
                } else {
                    var offset = -1*date.getTimezoneOffset()/60;
                    offset = "UTC" + (offset >= 0 ? "+" + offset : offset);
                    timezone = timezonenames[offset];
                }
            }
        }
        /*usertime = date.toLocaleString();
        var timezone = usertime.match(tzsregex);
        if (timezone) {
            timezone = timezone[timezone.length-1];
        } else {
            var offset = -1*date.getTimezoneOffset()/60;
            offset = "UTC" + (offset >= 0 ? "+" + offset : offset);
            timezone = timezonenames[offset];
        }*/
        
        if(timezone === undefined) {
            timezone = "";
        }
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let day = date.getDate();
        if(day < 10) {
            day = "0"+day;
        }
        if(hours < 10) {
            hours = "0"+hours;
        }
        if(minutes < 10) {
            minutes = "0"+minutes;
        }
        if(seconds < 10) {
            seconds = "0"+seconds;
        }
        let formattedDate =  day+" "+LocaleUtility.getLocaleMessage(months[date.getMonth()])+" "+date.getFullYear().toString()+" "+hours+":"+minutes+":"+seconds+" "+timezone;
        if(language === 'de'){
            formattedDate =  day+". "+LocaleUtility.getLocaleMessage(months[date.getMonth()])+". "+date.getFullYear().toString().substr(-2)+" "+hours+":"+minutes+":"+seconds+" "+timezone;
        } else if(language === 'fr'){
            formattedDate =  day+" "+LocaleUtility.getLocaleMessage(months[date.getMonth()])+" "+date.getFullYear().toString().substr(-2)+" "+hours+":"+minutes+":"+seconds+" "+timezone;
        } else if(language === 'ja'){
            formattedDate =  date.getFullYear().toString()+"/"+((date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1))+"/"+day+" "+hours+":"+minutes+":"+seconds+" "+timezone;
        } else if(language === 'zh'){
            formattedDate =  date.getFullYear().toString()+LocaleUtility.getLocaleMessage("graph.year.lable")+((date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1))+LocaleUtility.getLocaleMessage("graph.month.lable")+day+LocaleUtility.getLocaleMessage("graph.day.lable")+" "+hours+":"+minutes+":"+seconds+" "+timezone;
        }
        return formattedDate;
    } else {
        return "";
    }
}

export function getLocalFormatedDateFromDate(date){
    return getFormatedLocalizationDate(convertToServerTimeZone(date));
}
export function getLocalFormatedDateRangeFromDateRange(date){
    let dateArr = date.split(" - ");
    return (getFormatedLocalizationDate(convertToServerTimeZone(dateArr[0])) +" - "+ getFormatedLocalizationDate(convertToServerTimeZone(dateArr[1])));
}

export function getFormatedLocalizationDate(date) {
    if(date !== undefined && date > 0) {
       
        //Few browsers/OSs provide the timezone name in their local string
        var tzsregex = /\b(ACDT|ACST|ACT|ADT|AEDT|AEST|AFT|AKDT|AKST|AMST|AMT|ART|AST|AWDT|AWST|AZOST|AZT|BDT|BIOT|BIT|BOT|BRT|BST|BTT|CAT|CCT|CDT|CEDT|CEST|CET|CHADT|CHAST|CIST|CKT|CLST|CLT|COST|COT|CST|CT|CVT|CXT|CHST|DFT|EAST|EAT|ECT|EDT|EEDT|EEST|EET|EST|FJT|FKST|FKT|GALT|GET|GFT|GILT|GIT|GMT|GST|GYT|HADT|HAEC|HAST|HKT|HMT|HST|ICT|IDT|IRKT|IRST|IST|JST|KRAT|KST|LHST|LINT|MART|MAGT|MDT|MET|MEST|MIT|MSD|MSK|MST|MUT|MYT|NDT|NFT|NPT|NST|NT|NZDT|NZST|OMST|PDT|PETT|PHOT|PKT|PST|RET|SAMT|SAST|SBT|SCT|SGT|SLT|SST|TAHT|THA|UYST|UYT|VET|VLAT|WAT|WEDT|WEST|WET|WST|YAKT|YEKT)\b/gi;
        //In other browsers the timezone needs to be estimated based on the offset:
        var timezonenames = {"UTC+0":"GMT","UTC+1":"CET","UTC+2":"EET","UTC+3":"EEDT","UTC+3.5":"IRST","UTC+4":"MSD","UTC+4.5":"AFT","UTC+5":"PKT","UTC+5.5":"IST","UTC+6":"BST","UTC+6.5":"MST","UTC+7":"THA","UTC+8":"AWST","UTC+9":"AWDT","UTC+9.5":"ACST","UTC+10":"AEST","UTC+10.5":"ACDT","UTC+11":"AEDT","UTC+11.5":"NFT","UTC+12":"NZST","UTC-1":"AZOST","UTC-2":"GST","UTC-3":"BRT","UTC-3.5":"NST","UTC-4":"CLT","UTC-4.5":"VET","UTC-5":"EST","UTC-6":"CST","UTC-7":"MST","UTC-8":"PST","UTC-9":"AKST","UTC-9.5":"MIT","UTC-10":"HST","UTC-11":"SST","UTC-12":"BIT"};
        let usertime;
        let serverTimeZone = localStorage.getItem("ServerTimeZone");
        let timezone = localStorage.getItem("ServerTimeZoneOffset");
        if(serverTimeZone !== undefined && serverTimeZone !== "") {
            try {
                let needToConvert = date;
                let convertedDate = needToConvert.toLocaleString("en-US", {timeZone: serverTimeZone});
                date = new Date(convertedDate);
            } catch(error) {
                /**
                 * Converting to browser time zone, if browser does not support for server time zone conversion.
                 */
                usertime = date.toLocaleString();
                timezone = usertime.match(tzsregex);
                if (timezone) {
                    timezone = timezone[timezone.length-1];
                } else {
                    var offset = -1*date.getTimezoneOffset()/60;
                    offset = "UTC" + (offset >= 0 ? "+" + offset : offset);
                    timezone = timezonenames[offset];
                }
            }
        }

        let day = date.getDate();
        if(day < 10 && language != 'zh') {
            day = "0"+day;
        }

        let month = date.getMonth() + 1;
        if(month < 10 && language != 'zh') {
            month = "0"+month;
        }

        let year = date.getFullYear().toString();
// Date formate to be display in date picker
// Default  dd/MM/yyyy
// German   dd.MM.yyyy
// France   dd/MM/yyyy
// Japan    yyyy/MM/dd
// China    yyyy/M/d
// Spanish  dd/MM/yyyy
        let formattedDate =  day+"/"+month+"/"+year;
        if(language === 'de'){
            formattedDate =  day+"."+month+"."+year;
        } else if(language === 'fr'){
            formattedDate =  day+"/"+month+"/"+year;
        } else if(language === 'ja'){
            formattedDate = year+"/"+month+"/"+day;
        } else if(language === 'zh'){
            formattedDate = year+"/"+month+"/"+day;
        } else if(language === 'es'){
            formattedDate = day+"/"+month+"/"+year;
        }
        return formattedDate;
    } else {
        return "";
    }
}
export function convertToServerTimeZone(date) {
    let serverTimeZone = localStorage.getItem("ServerTimeZone");
    if(serverTimeZone !== undefined && serverTimeZone !== "") {
        try {
            let needToConvert = date;
            let convertedDate = needToConvert.toLocaleString("en-US", {timeZone: serverTimeZone});
            date = new Date(convertedDate);
            return date;
        } catch(error) {
           return date;
        }
    }
    return date;
}
/*
* get date format wrt localization
*/
export function getDateFormat() {
    let dateFormat =  "mm/dd/yyyy";
    if(language === 'de'){
        dateFormat =  "dd.mm.yyyy";
    } else if(language === 'fr'){
        dateFormat =  "dd/mm/yyyy";
    } else if(language === 'ja'){
        dateFormat = "yyyy/mm/dd";
    } else if(language === 'zh'){
        dateFormat = "yyyy/m/d";
    } else if(language === 'es'){
        dateFormat = "dd/mm/yyyy";
    }
    return dateFormat;
} 
/*
* get placeholder of date wrt localization
*/
export function getDateFormatPlaceholder() {
    let dateFormat =  "MM/DD/YYYY";
    if(language === 'de'){
        dateFormat =  "DD.MM.YYYY";
    } else if(language === 'fr'){
        dateFormat =  "DD/MM/YYYY";
    } else if(language === 'ja'){
        dateFormat = "YYYY/MM/DD";
    } else if(language === 'zh'){
        dateFormat = "YYYY/M/D";
    } else if(language === 'es'){
        dateFormat = "DD/MM/YYYY";
    }
    return dateFormat;
}