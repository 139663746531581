import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import ClientInfoPopup from "./ClientInfoPopup";
import ExecutedByInfo from "./ExecutedByInfo";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class RejectedUsageGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            loadedData: false,
            executedByMap: "",
            clientInfoMap: "",
            clientInfoObj: "",
            executedByInfoObj: "",
            sortIndex: 0,
            sortAscending: true,
            poolId: "0",
            exportHistoryRecordSize: 0,
            popupTop: "800px",
            popupLeft: "550px"
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadRejectedTableGrid = this.loadRejectedTableGrid.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.onClickExecutedByInfo = this.onClickExecutedByInfo.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.setPoolId = this.setPoolId.bind(this);
        this.findData = this.findData.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadRejectedTableGrid);
        APTableStore.on("change", this.props.callToExportRequest);
        this.findData(this.state.sortIndex, this.state.pagination, this.props.selectPoolId,
             this.props.usageStartDate, this.props.usageEndDate, this.props.instanceId);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadRejectedTableGrid);
        APTableStore.removeListener("change", this.props.callToExportRequest);
    }
    setPoolId(val, usageStartDate, usageEndDate, instanceId) {
        this.setState({rowData: []});
        this.setState({poolId: val});
        this.findData(this.state.sortIndex, this.state.pagination,
             val, usageStartDate, usageEndDate, instanceId);
    }
    onClickClientInfo(key, event) {
        let clientObj = this.state.clientInfoMap[key];
        this.setState({clientInfoObj: clientObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    onClickExecutedByInfo(key, event) {
        let executedObj = this.state.executedByMap[key];
        this.setState({executedByInfoObj: executedObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({clientInfoObj: ""});
        this.setState({executedByInfoObj: ""});
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "rejectedClientInfo.licenseStartDate";
        if(index === 1) {
            sortBy = "rejectedClientInfo.licenseExpiryDate";
        } else if(index === 2) {
            sortBy = "rejectedClientInfo.checkoutType";
        } else if(index === 3) {
            sortBy = "rejectedClientInfo.capacity";
        } else if(index === 5) {
            sortBy = "rejectedClientInfo.errorCode";
        } else if(index === 6) {
            sortBy = "rejectedClientInfo.customerErrorMessage";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.findData(index, paginationTemp, this.state.poolId, this.props.usageStartDate, 
            this.props.usageEndDate, this.props.instanceId);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp, this.state.poolId, 
            this.props.usageStartDate, this.props.usageEndDate, this.props.instanceId);
    }
    findData(sortIndex, pagination, groupId, usageStartDate, usageEndDate, instanceId) {
        let featInfo = localStorage.getItem("featureId:Version");
        let licenseModel = localStorage.getItem("licenseModel");
        if(featInfo) {
            this.setState({defaultSelIds: []});
            let featArr = featInfo.split(":");
            let params = {};
            params["pagination"] = pagination;
            params["sortIndex"] = sortIndex;
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["groupId"] = groupId;
            params["minCapacity"] = "0";
            params["licenseModel"] = licenseModel;
            params["showall"] = "true";
            params["usageStartDate"] = usageStartDate;
            params["usageEndDate"] = usageEndDate;
            params["uniqueInstanceId"] = instanceId;
            if(groupId > 0) {
                params["filterPool"] = "true";
            }
            this.setState({rowData: []});
            FetchHelper.listUsageDataForGraph(this.context.intl, params, "rejected");
            this.setState({loadedData: false});
        }
    }
    loadRejectedTableGrid() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "rejected") {
            this.setState({rowData: APTableStore.getState().reportSearchList});
            let sortIndex = APTableStore.getState().sortIndex;
            let sortAscending = APTableStore.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            this.setState({clientInfoMap: APTableStore.getState().clientInfoMap});
            this.setState({executedByMap: APTableStore.getState().executedByMap});
            let pagination = APTableStore.getState().pagination;
            pagination.required = true;
            pagination.style = "default";
            this.setState({pagination: pagination});
            this.setState({exportHistoryRecordSize: APTableStore.getState().exportHistoryRecordSize});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    render() {
        //let intl = this.context.intl;
        let hideInfo = "tooltipContent tooltipContentHide";
        let hideExcutedInfo = "tooltipContent tooltipContentHide";
        let infoObj = "";
        let executeObj = "";
        if(this.state.clientInfoObj !== "") {
            infoObj = this.state.clientInfoObj;
            hideInfo = "tooltipContent tooltipContentshow";
        }
        if(this.state.executedByInfoObj !== "") {
            executeObj = this.state.executedByInfoObj;
            hideExcutedInfo = "tooltipContent tooltipContentshow";
        }
        return (
            <div style={{float: "left", padding: "7px", marginBottom: "6px"}} className="inuse-cont-backgroud">
                <div style={{width: "100%", float: "left"}}>
                    <TableGrid id="usage_rejected_list_grid"
                        header={[
                            {
                                columnName: "requestedStartDate",
                                headerLabel: this.findLabel("usage.search.requested.date.label"),
                                width: "15%",
                                columnKey: "requestedStartDate",
                                sort: true,
                            },
                            {
                                columnName: "requestedDuration",
                                headerLabel: this.findLabel("usage.search.requested.duration.label"),
                                href: false,
                                width: "13%",
                                sort: true,
                            },
                            {
                                columnName: "rejectedCheckoutType",
                                headerLabel: this.findLabel("usage.search.checkouttype.label") ,
                                href: false,
                                width: "10%",
                                sort: true,
                            },
                            {
                                columnName: "rejectedCapacity",
                                headerLabel: this.findLabel("usage.search.rejected.capacity.label"),
                                href: false,
                                width: "12%",
                                sort: true,
                            },
                            {
                                columnName: "ipAddress",
                                headerLabel: this.findLabel("usage.search.clientinfo.label"),
                                width: "8%",
                                href: true,
                                columnKey: "id",
                                onClick: this.onClickClientInfo,
                            },
                            {
                                columnName: "rejectedCode",
                                headerLabel: this.findLabel("usage.search.rejected.code.label"),
                                href: false,
                                width: "11%",
                            },
                            {
                                columnName: "rejectedReason",
                                headerLabel: this.findLabel("usage.search.rejected.reason.label"),
                                href: false,
                                width: "auto",
                                sort: true,
                            }
                        ]}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={0}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        rowKey="id"
                        noDataFoundText={this.findLabel("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div id="rejected_export_limit_value_id" style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    {this.findLabel("usage.graph.export.limit.label")} {this.state.exportHistoryRecordSize}
                </div>
                {/*<div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <Button id="usage_rejected_backBtn" label={this.findLabel("label.button.back", intl)} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                </div>*/}
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
                <div className="tooltipMain">
                    <div id="rejected_client_info_id" style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={hideInfo}>
                        <ClientInfoPopup 
                            ipAddress = {infoObj.ipAddress}
                            userName = {infoObj.userName}
                            hostID = {infoObj.hostID}
                            productCode = {infoObj.productCode}
                            clientUniqueId = {infoObj.clientUniqueId}
                            namedUserId = {infoObj.namedUserId}
                            applicationName = {infoObj.applicationName}
                            closeTootipAction={this.closeTootipAction}
                            showApplicationName = {false}
                        />
                    </div>
                    <div style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={hideExcutedInfo} id="rejected_executed_by_info_id">
                        <ExecutedByInfo 
                            checkedOutBy = {executeObj.checkedOutBy}
                            checkedInBy = {executeObj.checkedInBy}
                            closeTootipAction={this.closeTootipAction}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
// RejectedUsageGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default RejectedUsageGraph;