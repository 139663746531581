import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { RadioButton } from 'grommet/components/RadioButton';
import CTConfigStore from "../../../flux/CTConfigStore";
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import ProductStore from "../../../flux/ProductStore";
import CTFeatureTenantMap from "./CTFeatureTenantMap";
import { Edit as EditIcon } from 'grommet-icons';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from "../../commons/grommet/Header";
import TableGrid from "../../../components/commons/table/APTable";
import { CheckBox } from "grommet/components/CheckBox";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher"
import * as ValidationUtil from "../../../utils/ValidationUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';

class ControlTowerAssetConfig extends Component{
    render(){
        return(
            <div className="tab_1_wrapper">
                <section pad="none" wrap={false} responsive={false} justify="center" class="border_white_backg_shadow">
                    <Form compact={false} id="conf_ct_asset_idm_formId" pad="small" plain={true}>
                        <h2 className="headerConfig">{this.props.findLabel("configpageCTConnectionAttributes", this.props.intl)}</h2>                               
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.freequencyError} required={true} label= {this.props.findLabel("usageSendingFreequency", this.props.intl)} >
                                            <TextInput id="conf_asset_ct_usage_freequency" value={this.props.ctUsageFreequency.toString()} onChange={event =>this.props.handleChangeFrequency(event)} />
                                        </FormField>
                                        </div>
                                    </td>
                                </tr>
        
                                <tr>
                                    <td>
                                        <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.hostnameError} required={true} label= {this.props.findLabel("configpageIDMServerURL", this.props.intl)} >
                                            <TextInput id="conf_asset_ct_idm_ServerUrlId" value={this.props.assetIdmServerUrlValue} onChange={event =>this.props.handleAssetChangeIdmServerUrl(event)} />
                                        </FormField>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.usernameError} required={true} label= {this.props.findLabel("configpageIDMUserNameforCT", this.props.intl)} >
                                            <TextInput id="conf_asset_ct_idm_UserNameId" value={this.props.assetIdmUserNameValue} onChange={event =>this.props.handleAssetChangeUserName(event)}/>
                                        </FormField>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="wrapper">
                                        <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.passwordError}  required={true} label= {this.props.findLabel("configpageIDMPasswordforCT", this.props.intl)} >
                                            <Password  className = "password_icon" id="conf_asset_ct_idm_PasswordId" value={this.props.assetIdmPasswordValue} onChange={this.props.handleAssetChangePassword} disabled={this.props.updateFlagAsset} />
                                        </FormField>
                                        {this.props.editAssetPasswordButton}</div>
                                    </td>
                                </tr>                                            
                                <tr>
                                    <td>
                                        <div className="horizantal_label_input ct_input_wrap">
                                        <FormField error={this.props.ctHostError}  required={true} label= {this.props.findLabel("configpageCTServerURL", this.props.intl)} >
                                            <TextInput id="conf_asset_ct_ServerUrlId" value={this.props.ctAssetServerUrlValue} onChange={event =>this.props.handleChangeCTTAssetServerUrl(event)} />
                                        </FormField>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="button_wrap_secondary" style={{marginTop:"-7px"}}>
                                            <Button id="conf_asset_ct_idm_testConnetion" label={this.props.findLabel("configpageLDAPTestConnection", this.props.intl)} plain={false} primary={true} onClick={this.props.submitTestAssetIDMConnection} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>      
                                        <div className="horizantal_label_input ct_input_wrap">
                                            <FormField label= {this.props.findLabel("usageType", this.props.intl)}>
                                                <RadioButton id="conf_asset_ct_usage_max" 
                                                checked={this.props.maxAssetUsageChecked} 
                                                label={this.props.findLabel("ctMaxUsage", this.props.intl)} />
                                            </FormField>
                                        </div> 
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>   
            
                        <div>
                            <CTFeatureTenantMap ref={this.props.featureAssetRef} featuresTenantsList = {this.props.featuresTenantsList} assetConfig ={true}/>
                        </div>
                        <div className="button_left_wrap">
                            <div className="button_wrap_primary">
                                <Button id="conf_asset_ct_idm_saveBtn" label={this.props.findLabel("label.button.save", this.props.intl)} plain={false} primary={true} onClick={this.props.submitAssetIdmConfigSettings} />
                            </div>
                            <div className="button_wrap_negative">
                                <Button id="conf_asset_ct_idm_cancelBtn" plain={false} secondary={true} label={this.props.findLabel("label.button.cancel", this.props.intl)} primary={false} onClick={this.props.cancelTenantCTIdmConfigSettings} />
                            </div> 
                        </div>                               
                    </Form>
                </section>
            </div>
        );
    }
}

export default ControlTowerAssetConfig;