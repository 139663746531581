import Dispatcher from "../flux/Dispatcher";

var tableData;
var sortAscending = true;
var sortIndex = 0;

export function filter(filterColumn, filterValue) {
    // var url = new URL("https://jsonplaceholder.typicode.com/albums?" + filterColumn + "=" + filterValue);
    // fetchAsync(url)
    //     .then(data => {
    //         tableData = data;
    //         refreshData();
    //     });
}

export function sort(columnIndex, order, header) {

    sortIndex = columnIndex;
    sortAscending = order;

    let key = header[columnIndex]['columnName'].toString().toLowerCase();
    let tempRowData = tableData;

    tempRowData.sort((a, b) => {
        if (a[key] < b[key]) { return order ? -1 : 1 };
        if (a[key] > b[key]) { return order ? 1 : -1 };
        return 0;
    });

    tableData = tempRowData;
    refreshData();
}

function refreshData() {
    let type = "TABLE_FETCH_COMPLETE";
    let payload = {
        tableData: tableData,
        sortAscending: sortAscending,
        sortIndex: sortIndex,
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}