import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";

import SectionWithTitle from "../../generic/SectionWithTitle";
import { Button } from "grommet/components/Button";
import SpinningNotification from "../../generic/SpinningNotification";
import Modal from "../../generic/Modal";
import Select from "../../commons/APLSSelect";
//import { CheckBox } from "grommet/components/CheckBox";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as TableUtility from "../../../utils/TableUtility";
import Store from "../../../flux/APTableStore";
import LicenseDetailStore from "../../../flux/LicenseDetailStore";
import Dispatcher from "../../../flux/Dispatcher";
import ImplicitFeatureDetails from "./subcomponents/ImplicitFeatureDetails";
import LicenseDetail from "./subcomponents/LicenseDetail";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import "../../../css/index.css";
// var _propTypes = require("prop-types");
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class ProductFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      impFeatureTableData: [],
      impFeatureModalData:[],
      sortIndex: Store.getState().sortIndex,
      sortAscending: Store.getState().sortAscending,
      productNameSelected: undefined,
      productNameSelectOptions: [],
      selectAllRows: false,
      licenseDetail: undefined,
      pagination: {
        required: true,
        style: "default",
        totalCount: 0,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize()
      },
      disableApplyBtnIndex:0,
      modalVisible:false
    };
    this.onSortClicked = this.onSortClicked.bind(this);
    this.onFilterClicked = this.onFilterClicked.bind(this);
    this.onPaginationClicked = this.onPaginationClicked.bind(this);
    this.rowData = [];
    this.refreshState = this.refreshState.bind(this);
    this.filterLicenseList = this.filterLicenseList.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    this.onColumnClicked = this.onColumnClicked.bind(this);
    this.archiveLicense = this.archiveLicense.bind(this);
    this.refreshModal = this.refreshModal.bind(this);
    this.refreshImplicitModal =this.refreshImplicitModal.bind(this);
    this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
    this.exportProductFeatureLicense = this.exportProductFeatureLicense.bind(this);
    this.applyImpFeature = this.applyImpFeature.bind(this);
    this.disableApplyBtn = this.disableApplyBtn.bind(this);
    this.modalImplicitFeatureContent = this.modalImplicitFeatureContent.bind(this);
    this.modalImplicitFeatureClick = this.modalImplicitFeatureClick.bind(this);
    this.exportLicenseAllKeys = this.exportLicenseAllKeys.bind(this);
    this.onModalCloseCallback2 = this.onModalCloseCallback2.bind(this);
    // this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
    // this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
  }

  componentDidMount() {
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    FetchHelper.getProductFeatureLicenses(undefined, "ACTIVE", undefined);
    Store.on("change", this.refreshState);
    Store.on("change", this.refreshImplicitModal);
    LicenseDetailStore.on("change", this.refreshModal);
  }

  componentWillUnmount() {
    Store.removeListener("change", this.refreshState);
    Store.removeListener("change", this.refreshImplicitModal);
    LicenseDetailStore.removeListener("change", this.refreshModal);
  }

  onSortClicked(index, order, header) {
    var sortOrder = "ASC";
    let paginationTemp = this.state.pagination;
    let productName = this.state.productNameSelected;

    if (paginationTemp.sortOrder === "ASC") {
      sortOrder = "DESC";
    }

    paginationTemp.sortOrder = sortOrder;
    paginationTemp.sortBy = header[index].columnName;
    paginationTemp.pageNumber =  paginationTemp.pageNumber;
    this.setState({ pagination: paginationTemp });
    FetchHelper.getProductFeatureLicenses(productName, "ACTIVE", paginationTemp);
  }

  onPaginationClicked = (pageNumber, pageSize) => {
    let paginationTemp = this.state.pagination;
    let productName = this.state.productNameSelected;
    paginationTemp.pageNumber = pageNumber;
    paginationTemp.pageSize = pageSize;
    this.setState({ pagination: paginationTemp });
    FetchHelper.getProductFeatureLicenses(productName, "ACTIVE", paginationTemp);
  };

  onFilterClicked(filterColumn, filterValue) {
    if (filterValue !== "") {
      TableUtility.filter(filterColumn, filterValue);
    } else {
    }
  }

  filterLicenseList(event) {
    let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    FetchHelper.getProductFeatureLicenses(event.option.value, "ACTIVE", undefined);
  }
   onRowClicked(){
    }

  onColumnClicked(key, event) {
    let loadSpinAction = "LICENSE_DETAIL_FETCH_INPROGRESS_LOADING_SPIN";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: LocaleUtility.getLocaleLabel(
        this.context.intl,
        "label.spinner.loading"
      )
    });
    FetchHelper.getLicenseDetail(key);
  }

  onModalCloseCallback() {
    this.setState({
      licenseDetail: undefined
    });
  }
   onModalCloseCallback2() {
    this.setState({
      modalVisible:false
    });
  }

  archiveLicense() {
    if (
      this.state.selectedRowKeys !== undefined &&
      this.state.selectedRowKeys.length > 0
    ) {
      var selectedKeys = this.state.selectedRowKeys;
      this.setState({
        selectedCheckBoxKey: []
      });
      FetchHelper.archiveLicenseList(selectedKeys);
    } else {
      if (this.state.rowData !== undefined && this.state.rowData.length > 0) {
        // Dispatcher.dispatch({
        //     type: "LICENSE_NOT_CHECKED_ALERT_SHOW_MODAL",
        //     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.view.alert1")
        // });
        /*alert(
          LocaleUtility.getLocaleLabel(
            this.context.intl,
            "label.license.view.alert1"
          )
        );*/
        Dispatcher.dispatch({
            type: "ALERT_SHOW_MODAL",
            payload: LocaleUtility.getLocaleLabel(
                this.context.intl,
                "label.license.view.alert1"
              )
        });
      }
    }
  }
applyImpFeature(){
  let productName="",licenseKey="";
  if(this.state.productNameSelectOptions!==undefined){
    let selectedPname = this.state.productNameSelectOptions;
    productName=selectedPname.entries().next().value[0];
  //   this.state.productNameSelectOptions.forEach((item,key,array)=>{
  //   productName+=key+'_';    
  //   });
    productName = productName.trim();
  }
  if(this.state.rowData!==undefined || this.state.rowData.length > 0){}
    this.state.rowData.forEach((item,key)=>{
    licenseKey+=item.licenseSignature+',';    
  });
  licenseKey = licenseKey.substring(0, licenseKey.length - 1);
  FetchHelper.updateAddImplimentProductFeature(productName,licenseKey);
  this.setState({modalVisible:false});
}
disableApplyBtn(){
  return true;
}
  refreshState() {
    var pagination = Store.getState().PFpagination;
    let allKeys=[];
    let sortIndex = 6;
    let indexx = Store.getState().sortIndex;
    switch (indexx) {
        case "featureDesc":
            sortIndex=0;
            break;
        case "productID":
            sortIndex=1;
            break;
        case "ltuCapacity":
            sortIndex=2;
            break;
        case "capacity":
            sortIndex=3;
            break;
        case "startDate":
            sortIndex=4;
            break;
        case "expiryDate":
            sortIndex=5;
            break;
        case "installedDate":
            sortIndex=6;
            break;
        case "installedBy":
            sortIndex=7;
            break;
        case "lockCode":
            sortIndex=8;
            break;
        case "Status":
            sortIndex=9;
            break;              
        default:
            sortIndex=6;
            break;
    }
    this.setState({
      rowData: Store.getState().tableData,
      impFeatureTableData: Store.getState().impFeatureTableData,
      impFeatureModalData: Store.getState().impFeatureModalData,
      pagination: pagination,
      sortIndex: sortIndex,
      sortAscending: Store.getState().sortAscending,
      productNameSelected: Store.getState().productNameSelected,
      productNameSelectOptions: Store.getState().productNameSelectOptions,
      selectedRowKeys: allKeys,
      session: FetchHelper.getSession(),
      disableApplyBtnIndex: Store.getState().disableApplyBtnIndex
    });
    if(Store.getState().tableData!==undefined){
      Store.getState().tableData.forEach((data)=>{
        allKeys.push(data.licenseSignature)
      });
    }
   this.setState({
      selectedRowKeys: allKeys
    });
    let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: ""
    });
  }

  refreshModal() {
    this.setState({
      licenseDetail: LicenseDetailStore.getState().licenseDetail    
    });
    let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: ""
    });
  }
   refreshImplicitModal() {
    this.setState({
      impFeatureModalData: Store.getState().impFeatureModalData      
    });
    let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: loadSpinAction,
      payload: ""
    });
  }
  exportProductFeatureLicense(e) {
    let format = e.currentTarget.getAttribute("data");
    FetchHelper.downloadLicensesProductFeatureExcel(
      this.state.productNameSelected,
      this.context.intl,
      false,
      "ACTIVE",
      format,
      this.state.pagination
    );
  }
  exportLicenseAllKeys() {
    FetchHelper.downloadInstalledLicenses(
      this.state.productNameSelected,
      this.context.intl,
      true,
      "ACTIVE"
    );
  } 
  sectionContent() {
    const options = [];
    let selectedValue = {
      value: undefined,
      label: LocaleUtility.getLocaleLabel(this.context.intl, "label.select.all")
    };
    options.push(selectedValue);
    if(this.state.productNameSelectOptions !== undefined && this.state.productNameSelectOptions.size>0){
       var entries = this.state.productNameSelectOptions.entries();
    for (var item of entries) {
      options.push({ value: item[0], label: item[1] });
      if (
        this.state.productNameSelected !== undefined &&
        item[0] === this.state.productNameSelected
      ) {
        selectedValue = { value: item[0], label: item[1] };
      }
    }
    }
   
  

    return (
      <section id="lic_view_listSec" className="page_section_wrap_1" pad="none" wrap={false} responsive={false} justify="center" >
        <div className="action_filter_wrapper">
          <div className="action_wrap_left">
                <div className="button_wrap_negative">
                  {this.state.session !== undefined &&
                    this.state.session.roletype === "fullview" && (
                      <Button
                        id="lic_view_archBtn"
                        label={LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.button.archive"
                        )}
                        plain={false}
                        primary={true}
                        disabled={true}
                      />
                    )}
                  {this.state.session !== undefined &&
                    this.state.session.roletype !== "fullview" && (
                      <Button
                        id="lic_view_archBtn"
                        label={LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.button.archive"
                        )}
                        plain={false}
                        primary={true}
                        onClick={this.archiveLicense.bind(this)}
                      />
                      
                    )}                   
                  </div>
                  <div className="button_wrap_primary">
                     { (this.state.disableApplyBtnIndex <= 0)?<Button
                        id="lic_view_applyBtn"
                        label={LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.button.apply"
                        )}
                        plain={false}
                        primary={true}                      
                        disabled={true}
                      />
                    :<span><Button
                        id="lic_view_applyBtn"
                        label={LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.button.apply"
                        )}
                        plain={false}
                        primary={true}
                        onClick={this.modalImplicitFeatureClick.bind(this)}
                        //onClick={this.applyImpFeature.bind(this)}
                      />&nbsp;&nbsp;<span>{LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.button.click.apply"
                        )}</span>
                      </span>
                    }
                  </div>
              
                <Select
                  id="lic_view_selProduct"
                  className="dropdown_select"
                  options={options}
                  value={selectedValue}
                  onChange={this.filterLicenseList}
                  label={LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.select.product"
                  )+": "}
                  width= {24}
                />
                </div>
              {this.state.rowData !== undefined && this.state.rowData.length > 0 ? (
                <div className="feature_excel_wrap">
                  <a style={{paddingTop: "4px"}}
                    className="excel"
                    data='{"table":"explicit","format":"CSV"}'
                    onClick={this.exportProductFeatureLicense}
                    title={LocaleUtility.getLocaleMessage(
                      "label.tooltip.exportCSV"
                    )}
                  >
                    <DocumentDownloadIcon id="license-feature-download-id" colorIndex="brand" />
                  </a>
                  </div>
            ) : null
            }
           </div>
                <div className="page_subsection_wrap">
                  <div>
                    <span className="subheader">
                      {LocaleUtility.getLocaleLabel(
                        this.context.intl,
                        "page.title.license.feature"
                      )}
                    </span>
                  </div>
                  <div className="lineseparator"><hr className="thin" /></div>
                  <TableGrid
                    id="lic_view_grid"
                    header={[                    
                      {
                        columnName: "featureDesc",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.feature"
                        ),
                        width: "25%",
                        columnKey: "licenseSignature",
                        onClick: this.onColumnClicked,
                        sort: true
                      },
                      {
                        columnName: "productID",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.prodNumber"
                        ),
                        href: false,
                        width: "10%",
                        sort: true
                      },
                      {
                        columnName: "ltuCapacity",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.ltu"
                        ),
                        href: false,
                        width: "5%",
                        sort: true
                      },
                      {
                        columnName: "capacity",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.capacity"
                        ),
                        href: false,
                        width: "auto",
                        sort: true
                      },
                      {
                        columnName: "startDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.startDate"
                        ),
                        href: false,
                        width: "auto",
                        sort: true
                      },
                      {
                        columnName: "expiryDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.expDate"
                        ),
                        href: false,
                        width: "8%",
                        sort: true
                      },
                      {
                        columnName: "installedDate",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.installedOn"
                        ),
                        href: false,
                        sort: true,
                        width: "8%"
                      },
                      {
                        columnName: "installedBy",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.installedBy"
                        ),
                        href: false,
                        width: "8%",
                        sort: true
                      },
                      {
                        columnName: "lockCode",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.lockcode"
                        ),
                        href: false,
                        width: "auto"
                      },{
                        columnName: "Status",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.status"
                        ),
                        href: false,
                        width: "auto",
                        sort: true
                      },{
                        columnName: "licenseType",
                        headerLabel: LocaleUtility.getLocaleLabel(
                          this.context.intl,
                          "label.grid.col.licenseType"
                        ),
                        href: false,
                        width: "auto",
                        sort: true
                      }
                    ]}
                    {...{ keys: this.state.selectedRowKeys }}
                    rowData={this.state.rowData}
                    pagination={this.state.pagination}
                    rowSelection={0}
                    isExportRequired={false}
                    onSort={this.onSortClicked}
                    onFilter={this.onFilterClicked}
                    onPagination={this.onPaginationClicked}
                    sortIndex={this.state.sortIndex}
                    sortAscending={this.state.sortAscending}
                    rowKey="licenseSignature"
                    onChangeCheckBoxCallback={this.onRowClicked}
                    selectAllRows={true}
                    noDataFoundText={LocaleUtility.getLocaleMessage(
                      "no.items.found.message"
                    )}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                      "label.grid.item.count.message",
                      {count: this.state.pagination !== undefined && this.state.pagination.totalCount }
                    )}
                  />
                </div>
                <div>
                  <SpinningNotification centerPage={true} />
                </div>
                <div style={{ clear: "both" }} />              
          { (this.state.impFeatureTableData !== undefined && this.state.impFeatureTableData.length > 0 && this.state.disableApplyBtnIndex <= 0)? 
          
        <div className="implicitFeaturesTbl page_subsection_wrap">
                <div>
                  <div>
                    <span className="subheader">
                      {LocaleUtility.getLocaleLabel(
                        this.context.intl,
                        "label.product.features"
                      )}
                    </span>
                  </div>
                  <div className="lineseparator"><hr className="thin" /></div>
             <TableGrid
              id="ExplicitFeatureTable"
              header={[
                // {
                //     columnName: "checkBoxColumn",
                //     headerLabel: <CheckBox className={classVar} checked={(this.state.selectAllRows.length > 0)} onChange={this.onHeaderCheckBoxClick.bind(this)}/>,
                //     width: "5%",
                // },
                {
                  columnName: "Product_Number",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.feature"
                  ),
                  width: "30%",
                  columnKey: "licenseSignature"
                },                
                {
                  columnName: "Capacity",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.capacity"
                  ),
                  href: false,
                  width: "auto"
                },
                {
                  columnName: "Start_Date",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.startDate"
                  ),
                  href: false,
                  width: "auto"
                },
                {
                  columnName: "Expiry_Date",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.expDate"
                  ),
                  href: false,
                  width: "auto"
                },
                {
                  columnName: "Installed_On",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.installedOn"
                  ),
                  href: false,
                  width: "auto"
                },
                {
                  columnName: "Installed_By",
                  headerLabel: LocaleUtility.getLocaleLabel(
                    this.context.intl,
                    "label.grid.col.installedBy"
                  ),
                  href: false,
                  width: "auto"
                }
              ]}
              {...{ keys: this.state.selectedRowKeys }}
              rowData={this.state.impFeatureTableData}
              rowSelection={0}
              isExportRequired={false}
              onSort={this.onSortClicked}
              onFilter={this.onFilterClicked}
              sortIndex={this.state.sortIndex}
              sortAscending={this.state.sortAscending}
              rowKey="licenseSignature"
              onChangeCheckBoxCallback={this.onRowClicked}
              selectAllRows={false}
              noDataFoundText={LocaleUtility.getLocaleMessage(
                "no.items.found.message"
              )}
              itemsCountMessage={LocaleUtility.getLocaleMessage(
                "label.grid.item.count.message",
                { count: this.state.pagination.totalCount }
              )}
            />
            <div className="implicitFeaturesTblExport">
            {/* <a
              className="excel"
              data='{"table":"implicit","format":"Excel"}'
              onClick={this.exportProductFeatureLicense}
              title={LocaleUtility.getLocaleMessage(
                "label.tooltip.exportexcel"
              )}
            >
              <DocumentExcelIcon />
            </a>
            <span style={{ paddingRight: "10px" }} /> */}
            <a
              className="csv"
              data='{"table":"implicit","format":"CSV"}'
              onClick={this.exportProductFeatureLicense}
              title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")}
            >
              <DocumentDownloadIcon id="prodfeatlic-download-id" colorIndex="brand" />
            </a>
          </div>
            </div></div>:
            <div></div>
            
            }
            
          
          {/* {(this.state.impFeatureTableData !== undefined && this.state.impFeatureTableData.length > 0 && this.state.disableApplyBtnIndex <= 0) && } */}
        
      </section>
    );
  }

  infoSectionContent(licDetail) {
    if (licDetail !== undefined) {
      return <LicenseDetail id="lic_view_detail" licenseDetail={licDetail} />;
    }
  }

  implicitFeatureContent(licenseDetails){
 if (licenseDetails!==undefined && licenseDetails.length > 0) {
      return <ImplicitFeatureDetails id="impLic_view_detail" licenseDetails={licenseDetails} />;
    }
  }

  modalLicenseDetailContent() {
    let licDetail = this.state.licenseDetail;
    let sectionInfoTitle = "",
      showModal = false;

    if(licDetail !== undefined){
            if(licDetail.featureDescription !== undefined){
                sectionInfoTitle = licDetail.featureDescription +
                    ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            } else {
                sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            }
            showModal = true
        }
    return (
      <Modal
        id="lic_view_detailMod"
        title={sectionInfoTitle}
        showModal={showModal}
        styleProps={{ top: "35%" }}
        closeLabel={LocaleUtility.getLocaleMessage(
          "label.button.close"
        )}
        sectionContent={this.infoSectionContent(licDetail)}
        onCloseButtonCallback={this.onModalCloseCallback}
      />
    );
  }
modalImplicitFeatureClick(){
  this.setState({modalVisible:true});
}

 modalImplicitFeatureContent() {
    let licenseDetails = this.state.impFeatureModalData;
    let sectionInfoTitle = "";
    if(licenseDetails!==undefined && licenseDetails.length > 0){         
                sectionInfoTitle = LocaleUtility.getDefualtString("Product.Features");       
        }
        
    return (
      <Modal
        id="ImplicitFeatureModal"
        title={sectionInfoTitle}
        showModal={this.state.modalVisible}
        styleProps={{ top: "35%" }}
        closeLabel={LocaleUtility.getLocaleMessage(
          "label.button.close"
        )}
        sectionContent={this.implicitFeatureContent(licenseDetails)}
        onCloseButtonCallback={this.onModalCloseCallback2}
        installBtn={{"label":"Install","type":"primary"}}
        installBtnClick = {this.applyImpFeature}
      />
    );
  }

  render() {
    return (
      <div className="config-cont-backgroud">
        <SectionWithTitle
          id="lic_view_mainSec"
          title={LocaleUtility.getLocaleLabel(
            this.context.intl,
            "page.title.license.feature"
          )}
          sectionContent={this.sectionContent()}
          helpTopic="manage_license"
        />{" "}
        {this.modalImplicitFeatureContent()}
        {this.modalLicenseDetailContent()}
        
      </div>
    );
  }
}

// ProductFeature.contextTypes = {
//   intl: _propTypes2.default.object
// };
export default ProductFeature;
