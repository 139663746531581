import React, { Component } from "react";
import { createBody } from "./utill/TableUtills";

class TableRows extends Component {
  constructor() {
    super();
    this.state = ({
      rowResponse: []
    });
  }

  getTableBody = (rowData, rowSelection, header, 
    rowKey, onChangeCheckBoxCallback, selectedCheckBoxKey, 
    uncheckTheDisabled, defaultSelectIds, disabledRowIds, childKey, expandAll, noDataFoundText, addExtraRow) => {
      return createBody(rowData, rowSelection, header, rowKey, 
        onChangeCheckBoxCallback, selectedCheckBoxKey, uncheckTheDisabled,
        defaultSelectIds, disabledRowIds, childKey, expandAll, noDataFoundText, addExtraRow);
  };

  render() {
    return (
      <tbody>
        {this.getTableBody(
          this.props.rows,
          this.props.rowSelection,
          this.props.header,
          this.props.rowKey,
          this.props.onChangeCheckBoxCallback,
          this.props.selectedCheckBoxKey,
          this.props.uncheckTheDisabled,
          this.props.defaultSelectIds,
          this.props.disabledRowIds,
		  this.props.childKey,
		  this.props.expandAll,
		  this.props.noDataFoundText,
		  this.props.addExtraRow
        )}
      </tbody>
    );
  }
}
export default TableRows;
