import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as Utils from "../../../utils/Util";
import Store from "../../../flux/ReservationManagementStore";
import SpinningNotification from '../../generic/SpinningNotification';
import { Button } from "grommet/components/Button";
//import { FormField } from "grommet/components/FormField";
import Select from "../../commons/APLSSelect";
import { Anchor } from 'grommet/components/Anchor';
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import ReservationStore from "../../../flux/ReservationManagementStore";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher";
import Toggle from '../../generic/APLSToggle';
import { FormLock as FormLockIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ProductBaseReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productNameList: [],
            gridData: [],
            poolsGridData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "featureId",
                sortOrder: "DESC"
            },
            selProductName: "",
            sortIndex: 0,
            sortAscending: false,
            selProduct: "",
            selectedPoolsIds: [],
            rectricCheckBoxIds: {},
            allowBlockBoxIds: {},
            reservedCapacityValues: {},
            selectedGridIds: [],
            session: {},
            mappingAction: false
        }
        this.findLabel = this.findLabel.bind(this);
        this.selectProductName = this.selectProductName.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.reloadPoolsGrid = this.reloadPoolsGrid.bind(this);
        this.onPoolsRowClicked = this.onPoolsRowClicked.bind(this);
        this.onAllowBlockCheckBoxAction = this.onAllowBlockCheckBoxAction.bind(this);
        this.onAllowBlockAction = this.onAllowBlockAction.bind(this);
        this.onReserevCapacityChange = this.onReserevCapacityChange.bind(this);
        this.beforeEditReservation = this.beforeEditReservation.bind(this);
        this.submitProductMapping = this.submitProductMapping.bind(this);
        this.disassoicatePoolSubmit = this.disassoicatePoolSubmit.bind(this);
        this.refreshGridAfterSave = this.refreshGridAfterSave.bind(this);
        this.loadProdutNameDropDown = this.loadProdutNameDropDown.bind(this);
        this.loadProdutDropDown = this.loadProdutDropDown.bind(this);
        this.resetPoolGridData = this.resetPoolGridData.bind(this);
        this.onConfirmSubmit = this.onConfirmSubmit.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    selectProductName(event) {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        this.setState({selProduct: event.option});
        this.setState({selProductName: event.option.value});
        this.findData(event.option.value, this.state.pagination, this.state.sortIndex);
    }
    componentDidMount() {
        Store.on("change", this.refreshGrid);
        Store.on("change", this.refreshGridAfterSave);
        FetchHelper.listProductNamesForDropDown();
        ReservationStore.on("change", this.loadProdutNameDropDown);
        FetchHelper.listPorductNames(this.context.intl, this.state.sortIndex, this.state.pagination, false, "");
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
        Store.removeListener("change", this.refreshGridAfterSave);
        ReservationStore.removeListener("change", this.loadProdutNameDropDown);
    }
    findData(productName, pagination, index) {
        let request = {
            "productName": productName,
            "pagination": pagination,
            "sortIndex": index
        }               
        FetchHelper.listFeaturesByProductName(this.context.intl, request);
    }
    reloadPoolsGrid(groupList, restricMap, allowBlockMap) {
        let restricValueMap = this.state.rectricCheckBoxIds;
        //skippedrecords-dont show MLR flows in product tab -Dsiplays single level flow.
        let skippedRecords = [];
        let finalGroupList = [];
        groupList.map(item => {
            if(Number(item["parentGroupId"]) > 0) {
                skippedRecords.push(Number(item["parentGroupId"]));
                skippedRecords.push(Number(item["groupId"]));
            }
            return "";
        });
        groupList.map(item => {
            let gId = Number(item["groupId"]);
            if(skippedRecords.indexOf(gId) < 0) {
                let val = restricMap[item["groupId"]];
                let allowVal = undefined;
                if(allowBlockMap !== undefined) {
                    allowVal = allowBlockMap[item["groupId"]];
                }
                item["reservation"] = 0;
                Object.entries(item).map(([key, value])=>{
                    if(allowVal !== undefined) {
                        if(allowVal === 1) {
                            //item["allowBlock"] =<span className="allowBlockTd" onClick={(event)=>this.onAllowBlockAction(item["groupId"])}>{this.findLabel("resv.allow.label", this.context.intl)}</span>
                            val = 1;
                            item["allowBlock"] = <Toggle
                                id = {"allow-block-"+item["groupId"]} 
                                checked = {true} 
                                disabled = {false} 
                                value = {item["groupId"]}
                                onChange = {this.onAllowBlockAction}
                                toolTipText={this.findLabel("resv.block.label")}
                            />
                        } else {
                            //item["allowBlock"] = <span className="allowBlockTd" onClick={(event)=>this.onAllowBlockAction(item["groupId"])}>{this.findLabel("resv.block.label", this.context.intl)}</span>
                            item["allowBlock"] = <Toggle
                                                    id = {"allow-block-"+item["groupId"]} 
                                                    checked = {false} 
                                                    disabled = {false} 
                                                    value = {item["groupId"]}
                                                    onChange = {this.onAllowBlockAction}
                                                    toolTipText={this.findLabel("resv.allow.label")}
                                                />
                        }
                    } else {
                        //item["allowBlock"] = <span className="allowBlockTd" onClick={(event)=>this.onAllowBlockAction(item["groupId"])}>{this.findLabel("resv.block.label", this.context.intl)}</span>
                        item["allowBlock"] = <Toggle
                                                id = {"allow-block-"+item["groupId"]} 
                                                checked = {false} 
                                                disabled = {false} 
                                                value = {item["groupId"]}
                                                onChange = {this.onAllowBlockAction}
                                                toolTipText={this.findLabel("resv.allow.label")}
                                            />
                    }
                    if(val === 1) {
                        if(allowVal !== undefined && allowVal === 1) {
                            item["restrict"] = <span><Toggle className="toggle-classname" id={"toggle-"+item["groupId"]}  checked={true} disabled={true} onChange={this.onAllowBlockCheckBoxAction} value={item["groupId"]} rightIcon={<FormLockIcon />}/></span>
                        } else {
                            item["restrict"] = <span><Toggle className="toggle-classname" id={"toggle-"+item["groupId"]}  checked={true} disabled={false} onChange={this.onAllowBlockCheckBoxAction} value={item["groupId"]} rightIcon={<FormLockIcon />}/></span>
                        }
                    } else {
                        item["restrict"] = <Toggle className="toggle-classname" id={"toggle-"+item["groupId"]}  checked={false} disabled={false} onChange={this.onAllowBlockCheckBoxAction} value={item["groupId"]}/>
                    }
                    return "";
                });
                finalGroupList.push(item);
            }
            return "";
        });
        this.setState({rectricCheckBoxIds: restricValueMap});
        this.setState({poolsGridData: finalGroupList});
    }
    loadProdutNameDropDown() {
        if(ReservationStore.getState() && ReservationStore.getState().actionType === "productNameList") {
            let data = ReservationStore.getState().data;
            if(data !== undefined) {
                this.loadProdutDropDown(data.productNameList);
            }
        }
    }
    loadProdutDropDown(productNameList) {
        if(productNameList !== undefined && !this.state.mappingAction) {
            let pList = [];
            let defaultName = "";
            let count = 0;
            let defalultSelected = "";
            pList.push({label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"});
            if(productNameList.value !== undefined) {
                productNameList.value.map(element => {
                    let val = element.split(":");
                    if(count === 0) {
                        defaultName = val[0];
                        defalultSelected = val[1];
                    }
                    count++;
                    pList.push({label: val[1], value: val[0]});
                    return "";
                });
            } else {
                defaultName = "1";
                defalultSelected = LocaleUtility.getLocaleMessage("label.select.token.license.feature");
            }
            this.setState({productNameList: pList});
            this.setState({selProduct: {label: defalultSelected, value: defaultName}});
            this.setState({selProductName: defaultName});
        } else {
            this.setState({mappingAction: false});
        }
    }
    refreshGrid() {
        if(Store.getState() && Store.getState().actionType === "listproductfeatures") {
            this.resetPoolGridData();
            let featureList = Store.getState().data.features;
            let mappingData = Store.getState().data.featureGroupMaps;
            let poolMapping = {};
            if(mappingData.entry !== undefined) {
                Object.entries(mappingData.entry).map(([key, value]) =>{
                    poolMapping[value.key] = value.value;
                    return "";
                });
            } else {
                poolMapping = mappingData;
            }
            featureList.map(item => {
                item["poolMapping"] = poolMapping[item.featureID];
                return "";
            });
            var session = FetchHelper.getSession();
            if(session.admin || session.roletype === 'product'){
               this.setState({admin: true});
            }
            this.setState({session: session});
            this.setState({gridData: featureList});
            //let productNames = Store.getState().productNameList;
            let groupList = Store.getState().data.groupList;
            this.reloadPoolsGrid(groupList, this.state.rectricCheckBoxIds, this.state.allowBlockBoxIds);
            this.loadProdutDropDown(Store.getState().productNameList);
            /*if(productNames && productNames.length > 0) {
                let pList = [];
                let defaultName = "";
                let count = 0;
                let defalultSelected = "";
                productNames.map(element => {
                    if(count === 0) {
                        defaultName = element.uniqueProductIdentifier;
                        defalultSelected = element.productDescription+" - "+element.productCurrentVersionStr;
                    }
                    count++;
                    pList.push({label: element.productDescription+" - "+element.productCurrentVersionStr, value: element.uniqueProductIdentifier});
                    return "";
                });
                this.setState({productNameList: pList});
                this.setState({selProduct: defalultSelected});
                this.setState({selProductName: defaultName});
                //this.findData(defaultName, this.state.pagination, this.state.sortIndex);
            }*/
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    onPoolsRowClicked(keys, event) {
        this.setState({selectedPoolsIds: keys});
        this.setState({selectedGridIds: keys});
    }
    onAllowBlockCheckBoxAction(groupId) {
        if(this.state.admin){
            let dataMap = this.state.rectricCheckBoxIds;
            let gridData = this.state.poolsGridData;
            this.setState({poolsGridData: []});
            let val = dataMap[groupId];
            if(val) {
                if(val === 1) {
                    dataMap[groupId] = 0;
                } else {
                    dataMap[groupId] = 1;
                }
            } else {
            dataMap[groupId] = 1;
            }
            this.setState({rectricCheckBoxIds: dataMap});
            this.reloadPoolsGrid(gridData, dataMap, this.state.allowBlockBoxIds);
        }
    }
    onAllowBlockAction(groupId) {
        if(this.state.admin){
            let dataMap = this.state.allowBlockBoxIds;
            let gridData = this.state.poolsGridData;
            let checkBoxMap = this.state.rectricCheckBoxIds;
            this.setState({poolsGridData: []});
            if(dataMap !== undefined) {
                let val = dataMap[groupId];
                if(val) {
                    if(val === 1) {
                        dataMap[groupId] = 0;
                        checkBoxMap[groupId] = 0;
                    } else {
                        dataMap[groupId] = 1;
                        checkBoxMap[groupId] = 1;
                    }
                } else {
                    dataMap[groupId] = 1;
                    checkBoxMap[groupId] = 1;
                }
                this.setState({allowBlockBoxIds: dataMap});
            }
            let reservedMap = this.state.reservedCapacityValues;
            reservedMap[groupId] = 0;
            this.setState({reservedCapacityValues: reservedMap, rectricCheckBoxIds:checkBoxMap});
            this.reloadPoolsGrid(gridData, this.state.rectricCheckBoxIds, dataMap);
        }
    }
    onReserevCapacityChange(rowObject, event) {
        let val = event.target.value;
        if(val && Number(val)) {
            let map = this.state.reservedCapacityValues;
            map[rowObject.groupId] = Number(val);
            this.setState({reservedCapacityValues: map});
        }
    }
    beforeEditReservation(rowObject, event) {
        let dataMap = this.state.allowBlockBoxIds;
        if(dataMap !== undefined) {
            let val = dataMap[rowObject.groupId];
            if(val === 1) {
                event.target.value = 0;
                event.target.disabled = true;
            } else {
                event.target.disabled = false;
            }
        } else {
            event.target.disabled = false;
        }
    }

    onConfirmSubmit(flag, groupValues){
        if(flag === 1){
            localStorage.setItem("hasFeaureConfigured", "0");
            this.setState({selectedGridIds: []});
            FetchHelper.saveProductFeatureMapings(groupValues.toString(), this.state.selProductName, this.context.intl);
        }
    }
    submitProductMapping() {
        let selIds = this.state.selectedPoolsIds;
        let groupValues = [];
        if(selIds.length === 0) {
            ConfigurationUtils.showErrorMessage1("reservation.alert3", this.context.intl);
            return false;
        } else {
            let resMap = this.state.reservedCapacityValues;
            let restricMap = this.state.rectricCheckBoxIds;
            selIds.map(id=>{
                let reservCap = resMap[id];
                if(reservCap === undefined) {
                    reservCap = 0;
                }
                let restricVal = restricMap[id];
                let retric = false;
                if(restricVal === 1) {
                    retric = true;
                }
                if(reservCap < 0) {
                    ConfigurationUtils.showErrorMessage1("reservation.alert18", this.context.intl);
                    return false;
                } else if(reservCap === undefined) {
                    ConfigurationUtils.showErrorMessage1("reservation.alert2", this.context.intl);
                    return false;
                }
                groupValues.push(id+"|"+reservCap+"|"+retric);
                return "";
            });
        }
        if(groupValues.length > 0) {
            let hasConfig = localStorage.getItem("hasFeaureConfigured");
            if(hasConfig === "1") {
                Dispatcher.dispatch({
                    type: "NOTIFICATION_CLOSE"
                });
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleMessage("resv.product.mapping.warning"),
                    this.onConfirmSubmit, groupValues, "","");
            } else {
                this.setState({selectedGridIds: []});
                FetchHelper.saveProductFeatureMapings(groupValues.toString(), this.state.selProductName, this.context.intl);
            }
        }
    }

    disassoicatePoolSubmit() {
 let selIds = this.state.selectedPoolsIds;
        let groupValues = [];
        if(selIds.length === 0) {
            ConfigurationUtils.showErrorMessage1("reservation.alert3", this.context.intl);
            return false;
        }else {
            let resMap = this.state.reservedCapacityValues;
            let restricMap = this.state.rectricCheckBoxIds;
            selIds.map(id=>{
                let reservCap = resMap[id];
                if(reservCap === undefined) {
                    reservCap = 0;
                }
                let restricVal = restricMap[id];
                let retric = false;
                if(restricVal === 1) {
                    retric = true;
                }
               
                groupValues.push(id);
                return "";
            });
        }
        if(groupValues.length > 0) {
            let hasConfig = localStorage.getItem("hasFeaureConfigured");
            if(hasConfig === "1") {
                Dispatcher.dispatch({
                    type: "NOTIFICATION_CLOSE"
                });
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleMessage("resv.product.mapping.warning"),
                    this.onConfirmSubmit, groupValues, "","");
            } else {
                this.setState({selectedGridIds: []});
                FetchHelper.disassociateProductMapings(groupValues.toString(), this.state.selProductName, this.context.intl);
            }
        }
    }
    refreshGridAfterSave() {
        if(Store.getState() && Store.getState().actionType === "productfeatureSave") {
            this.resetPoolGridData();
            this.setState({mappingAction: true});
            FetchHelper.listPorductNames(this.context.intl, this.state.sortIndex, this.state.pagination, true, this.state.selProductName);
        }else if(Store.getState() && Store.getState().actionType === "productPoolDisassociate"){
            this.resetPoolGridData();
            this.setState({mappingAction: true});
            FetchHelper.listPorductNames(this.context.intl, this.state.sortIndex, this.state.pagination, false, this.state.selProductName);
        }
    }
    resetPoolGridData() {
        let map = {};
        let resMap = {};
        let allowBlockMap = {};
        this.setState({reservedCapacityValues: map});
        this.setState({rectricCheckBoxIds: resMap});
        this.setState({allowBlockBoxIds: allowBlockMap});
        this.setState({poolsGridData: []});
        this.setState({selectedPoolsIds: []});
    }
    render() {
        //let intl = this.context.intl;
        let isAdmin = this.state.admin;
        let noOfProducts = this.state.productNameList.length;
        if(noOfProducts === 1 && this.state.selProductName === "1" && this.state.gridData.length === 0) {
            noOfProducts = 0;
        }
        return(
            <div style={{paddingLeft: "5px", paddingRight: "5px"}} className="config-cont-backgroud">
                <SectionWithTitle title={this.findLabel("resv.product.base.title")}  helpTopic="R_Product_Reservation_Tab"/>
                {noOfProducts > 0 ?
                <div>
                    <div className="action_filter_wrapper">
                        <div className="flex_start">
                        {/*<FormField id="product_base_list_form_seletProdId" style={{ width: '30em' }} label= {this.findLabel("usageReportSelProduct", intl)} >
                            <Select id="product_base_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                                options={this.state.productNameList}
                                value={this.state.selProduct}
                                onChange={this.selectProductName} />
                        </FormField>*/}
                        <Select id="product_base_list_seletProdId" placeholder={this.findLabel("configpageIpHostSelect")}
                            options={this.state.productNameList}
                            value={this.state.selProduct}
                            onChange={this.selectProductName} 
                            label= {this.findLabel("usageReportSelProduct")+": "}
                            width= {26}
                        />
                        </div>
                    </div>
                    <div className="productBaseFeatures">
                        <TableGrid id="product_base_list_grid"
                            header={[
                                {
                                    columnName: "featureID",
                                    headerLabel: this.findLabel("usageReportFeatId"),
                                    width: "12%",
                                    columnKey: "featureID"
                                },
                                {
                                    columnName: "featureVersion",
                                    headerLabel: this.findLabel("usageReportFeaVer"),
                                    width: "12%"
                                },
                                {
                                    columnName: "featureDescription",
                                    headerLabel: this.findLabel("usageReportFeatDesc") ,
                                    href: false,
                                    width: "auto"
                                },
                                {
                                    columnName: "poolMapping",
                                    headerLabel: this.findLabel("resv.pool.mapping.label") ,
                                    href: false,
                                    width: "auto"
                                }
                            ]}
                            rowData={this.state.gridData}
                            pagination={this.state.pagination}
                            rowSelection={0}
                            isExportRequired={false}
                            onSort={this.state.onSortClicked}
                            onFilter={this.onFilterClicked}
                            onPagination={this.state.onPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="featureID"
                            onChangeCheckBoxCallback={this.onRowClicked}
                        />
                    </div>
                    <div className="border_white_backg_shadow">
                        {/* {this.state.poolsGridData.length > 0 ? 
                            <div style={{border: "1px solid #ccc", width: "100%", marginTop: "8px", marginBottom: "10px"}}></div>
                        : null} */}
                        {this.state.poolsGridData.length > 0 ? 
                            <div style={{paddingTop: "5px"}} className="productBasePools">
                                <TableGrid id="product_base_pools_list_grid"
                                    header={[
                                        {
                                            columnName: "allowBlock",
                                            headerLabel: this.findLabel("resv.allow.block.label") ,
                                            href: false,
                                            width: "7%",
                                            columnKey: "groupId",
                                            textAlign: "center"
                                        },
                                        {
                                            columnName: "groupName",
                                            headerLabel: this.findLabel("resv.create.pool.name.label"),
                                            width: "auto",
                                            columnKey: "groupName"
                                        },
                                        {
                                            columnName: "reservation",
                                            headerLabel: this.findLabel("resv.reserved.capacity.label"),
                                            width: "15%",
                                            columnKey: "reservation",
                                            editable: true,
                                            editFieldType: "text",
                                            className: "reserveCapacityEdit",
                                            afterEdit: this.onReserevCapacityChange,
                                            beforeEdit: this.beforeEditReservation
                                        },
                                        {
                                            columnName: "restrict",
                                            headerLabel: this.findLabel("resv.restrict.label") ,
                                            href: false,
                                            width: "5%",
                                            className: "restricProductTd",
                                            columnKey: "groupId"
                                        }
                                    ]}
                                    rowData={this.state.poolsGridData}
                                    pagination={this.state.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.state.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.state.onPaginationClicked}
                                    sortIndex={this.state.sortIndex}
                                    sortAscending={this.state.sortAscending}
                                    rowKey="groupId"
                                    onChangeCheckBoxCallback={this.onPoolsRowClicked}
                                    selectAllRows={this.state.selectedGridIds}
                                />
                            </div>
                        : 
                            <p className="reservation-emtpy-message">{this.findLabel("no.groups")}&nbsp;<Anchor label={this.findLabel("no.groups.create.pool")} href={FrontendPagePath.RESERVATION_POOL_MANAGE_PATH+Utils.buildURLQueryParam()}/></p>
                        }
                        {this.state.poolsGridData.length > 0 && isAdmin && 
                            this.state.session.productList.split(",").indexOf(this.state.selProductName) > -1 ? 
                            <div className="button_left_wrap">
                                <div className="button_wrap_primary">
                                    <Button id="product_feature_mapping_submitBtn" label={this.findLabel("label.button.submit")} plain={false} primary={true} onClick={this.submitProductMapping} />
                                </div>
                                <div className="button_wrap_negative">
                                    <Button id="product_feature_mapping_submitBtn" label={this.findLabel("label.button.disassociate")} plain={false} primary={true} onClick={this.disassoicatePoolSubmit} />
                                </div>
                            </div>
                        :null}   
                    </div>                 
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </div>
                : <p>{this.findLabel("resv.feature.not.found.message")}</p> }
            </div>
        )
    }
}
// ProductBaseReservation.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ProductBaseReservation;
