import ReduceStore from "./ReduceStore";

class ConfirmModalStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            message: "",
            title: "",
            showModal: false,
            okLabel: "",
            cancelLabel: ""
        };
        this.setFlag = this.setFlag.bind(this);
    }
    reduce(action, state) {
        if (action.type.endsWith("CONFIRM_SHOW_MODAL")) {
            return { ...state, showModal: true, message: action.payload.message, 
                callBack: action.payload.callBack, data: action.payload.data, okLabel: action.payload.okLabel, cancelLabel: action.payload.cancelLabel};
        } else if (action.type.endsWith("CLOSE_MODAL")) {
            return { ...state, showModal: false, message: action.payload };
        } else if (action.type.endsWith("CONFIRM_SHOW_TITLE_MODAL")) {
            return { ...state, showModal: true, message: action.payload.content,
                        title: action.payload.title, callBack: action.payload.callBack,
                    data: action.payload.data };
        }
        return state;
    }

    setFlag(flag){
        this.state.flag = flag;
    }

    getFlag(){
        return this.state.flag;
    }
}

export default new ConfirmModalStore();