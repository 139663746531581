import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as FetchHelper from "../../../utils/FetchHelper";
import SpinningNotification from '../../generic/SpinningNotification';
import store from "../../../flux/MultiTenantStore";
import { Folder as FolderIcon } from 'grommet-icons';
import { DocumentText as DocumentIcon } from 'grommet-icons';
import { View as ViewIcon } from 'grommet-icons';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class AppLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: []
        }
        this.findData = this.findData.bind(this);
        this.loadGridData = this.loadGridData.bind(this);
        this.downloadAction = this.downloadAction.bind(this);
    }
    componentDidMount() {
        store.on("change", this.loadGridData);
        this.findData();
    }
    componentWillUnmount() {
        store.removeListener("change", this.loadGridData);
    }
    downloadAction(folderName, fileName, action) {
        FetchHelper.downloadLogFile(this.context.intl, folderName, fileName, action);
    }
    loadGridData() {
        if(store.getState().actionType === 'logslist') {
            let gData = store.getState().logsData;
            gData.map(obj=>{
                if(obj.parentName === "" || obj.parentName === undefined || obj.parentName === null) {
                    obj.fileNameIcon = <span><FolderIcon colorIndex="brand"/> <span>{obj.fileName}</span></span>
                }
                if(obj.files !== undefined) {
                    obj.files.map(child => {
                        child["viewIconCol"] = <a title={LocaleUtility.getLocaleMessage("log.view.tool.tip")}><ViewIcon onClick={event=>this.downloadAction(child.parentName, child.fileName, "V")} colorIndex="brand"/></a>
                        child["downloadIconCol"] = <a title={LocaleUtility.getLocaleMessage("log.download.tool.tip")}><DocumentDownloadIcon onClick={event=>this.downloadAction(child.parentName, child.fileName, "D")} colorIndex="brand" /></a>
                        child.fileNameIcon = <span><DocumentIcon/> {child.fileName}</span>
                        return "";
                    });
                }
                return "";
            });
            this.setState({rowData: gData});
        }
    }
    findData() {
        let request = {
            fileName: ""
        }
        FetchHelper.listAppLogs(request, this.context.intl);
    }
    render() {
        return(
            <div>
                <div id="logs_list_div_id">
                    <TableGrid id="logs_list_gird"
                            header={[
                                {
                                    columnName: "fileNameIcon",
                                    headerLabel: LocaleUtility.getLocaleMessage("log.file.name") ,
                                    href: false,
                                    width: "auto"
                                },
                                {
                                    columnName: "size",
                                    headerLabel: LocaleUtility.getLocaleMessage("log.file.size") ,
                                    href: false,
                                    width: "auto"
                                },
                                {   
                                    columnName: "downloadIconCol",
                                    headerLabel: "",
                                    href: false,
                                    width: "2%",
                                    sort: false,
                                    textAlign: "center"
                                }
                            ]}
                            rowData={this.state.rowData}
                            pagination={this.state.pagination}
                            rowSelection={0}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="id"
                            childKey="files"
                            onChangeCheckBoxCallback={this.onRowClicked}
                            noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                    "label.grid.item.count.message", {count: this.state.rowData.length})}
                        />
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// AppLogs.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AppLogs;