import React, { Component } from "react";
import AlertModalStore from "../../flux/AlertModalStore";
import * as LocaleUtility from "../../utils/LocaleUtility";
import { Button } from "grommet/components/Button";
import { Alert as AlertIcon } from 'grommet-icons';
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            title: "",
            alertText: ""
        };

        this.setModal = this.setModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
    }

    setModal() {
        this.setState({
            showModal: AlertModalStore.getState().showModal,
            title: AlertModalStore.getState().title,
            alertText: AlertModalStore.getState().message,
        });
    }

    componentDidMount() {
        AlertModalStore.on("change", this.setModal);
    }

    componentWillUnmount() {
        AlertModalStore.removeListener("change", this.setModal);
    }

    onModalCloseCallback() {
        this.setState({
            showModal: false,
            title: '',
            alertText: ''
        });
    }

    render() {
        // let title = LocaleUtility.getLocaleLabel(this.context.intl, "label.modal.title.alert")
        // if(this.state.title !== undefined){
        //     title = this.state.title
        // }
        const showHideModalStyle = this.state.showModal ? "modal display-block" : "modal display-none";
        

        return (
            <div id={this.props.id} className={showHideModalStyle}>
                <div id="modalDiv" className="modal">
                    <div className="alert-modal-main panel panel-default" style={this.props.styleProps}>
                        <div style={{width: "100%"}}>
                        <div id="alert_modal_content" className="alert-panel-body">
                            <div style={{float:"left", margin:"0px 10px"}} ><AlertIcon id="alert-icon-id" className="grommetux-control-icon grommetux-color-index-critical"/></div>
                            <div id="alert_modal_msg" style={{marginLeft:"40px"}}>{this.state.alertText}</div>
                        </div>
                        <div className="alert-panel-footer panel-button-footer button_right_wrap">
                            <div className="button_wrap_primary">
                                <Button 
                                    id="alert_modal_Btn" 
                                    label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.ok")} 
                                    plain={false}  
                                    primary={true} 
                                    onClick={this.onModalCloseCallback} 
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// AlertModal.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AlertModal;