import React, { Component } from "react";
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from 'grommet/components/Header';
import InUseGraph from "./InUseGraph";
import HistoryUsageGraph from "./HistoryUsageGraph";
import RejectedUsageGraph from "./RejectedUsageGraph";
import ConsumptionUsageGraph from "./ConsumptionUsageGraph";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import Select from "../../commons/APLSSelect";
import ColorLabel from './ColorLabel';
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import * as Utils from "../../../utils/Util";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { Navigate } from 'react-router-dom';
import SectionWithTitle from "../../generic/SectionWithTitle";
import * as DateUtility from "../../../utils/DateUtility";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from '../../../utils/FetchHelper';
import DateRange from "../../generic/DateRange";
import DatesPopup from "../../generic/DatesPopup";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { getLocaleMessage }from "../../../utils/LocaleUtility";
import { Clock as ClockIcon } from 'grommet-icons';
import APLSBack from "../../generic/APLSBack";
import { useIntl } from 'react-intl';
import { DocumentCsv as DocumentCsvIcon } from 'grommet-icons';
import { createSearchParams } from "react-router-dom";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportGraphs extends Component {
    constructor(props) {
        super(props);
        this.inUseRef = React.createRef();
        this.historyRef = React.createRef();
        this.rejectedRef = React.createRef();
        this.consumeRef = React.createRef();
        let actIndex = 3;
        let cumulativeUsage = localStorage.getItem("cumulativeUsage");
        if(cumulativeUsage === undefined || cumulativeUsage === "undefined"
            || cumulativeUsage === null || cumulativeUsage === "null") {
            actIndex = 0;
        }
        this.state = {
            activeIndex: actIndex,
            selPoolName: [],
            selectPoolId: "0",
            selectCurrentVal: "",
            backToPreviousPage: false,
            usageStartDate: "",
            usageEndDate: "",
            selDateRange: {"label": getLocaleMessage("label.date.last.thirty.days"), "value": 3},
            poolColorIndexById: {},
            groupColorPalette: {},
            selDateRangeVal: "",
            showModal: false,
            tenentNameList: [],
            selTenantName: null,
            selTenantId: "0",
            datesErrorMessage: "",
            minCapacity: "",
            pdfFeature: "",
            adminCommuterCapacity: "",
            adminCommuterDays: "",
            showAdminCommuterDetails: false,
            commuterValue: 0,
            commuterNoOfDays: 0,
            editCommuterDaysValue: 0,
            editaAdminCommuterCapacityValue: 0,
            errorMessage: "",
            featurePkId: 0,
            exportExcelRequest: {},
            showExportExcelIcon: false,
            enableMorePools: false,
            limitpoolNamesWithColor: [],
            morePoolsList: [],
            morePoolMap: {},
            poolNamesIdsMap: {},
            isNamedFeature: false
        }
        this.tabClickAction = this.tabClickAction.bind(this);
        this.listPoolNamesAction = this.listPoolNamesAction.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.selectPoolName = this.selectPoolName.bind(this);
        this.backBtnAction = this.backBtnAction.bind(this);
        this.populateDates = this.populateDates.bind(this);
        this.selectDateRange = this.selectDateRange.bind(this);
        this.rejectedAction = this.rejectedAction.bind(this);
        this.onSelectDateRange = this.onSelectDateRange.bind(this);
        this.datesCancelAction = this.datesCancelAction.bind(this);
        this.datesOkAction = this.datesOkAction.bind(this);
        this.listTenantNames = this.listTenantNames.bind(this);
        this.selectTenantName = this.selectTenantName.bind(this);
        this.closePopuAction = this.closePopuAction.bind(this);
        this.showPopuAction = this.showPopuAction.bind(this);
        this.editOnClickAction = this.editOnClickAction.bind(this);
        this.onSaveChangesAction = this.onSaveChangesAction.bind(this);
        this.changeCommuterDays = this.changeCommuterDays.bind(this);
        this.changeCommuterCapacity = this.changeCommuterCapacity.bind(this);
        this.callBackCommuterUpdates = this.callBackCommuterUpdates.bind(this);
        this.callToExportRequest = this.callToExportRequest.bind(this);
        this.exportExcelAction = this.exportExcelAction.bind(this);
        this.closeMorePopupAction = this.closeMorePopupAction.bind(this);
        this.buildMorePools = this.buildMorePools.bind(this);
        this.clickMorePool = this.clickMorePool.bind(this);
        this.onConfirmCapacity = this.onConfirmCapacity.bind(this);
    }
    // static propTypes = {
    //     children: _propTypes.node.isRequired,
    //     onClickOutside: _propTypes.func.isRequired
    // }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    backBtnAction() {
        this.setState({backToPreviousPage: true});
    }
    tabClickAction(index, event) {
        // if(index === 3) {
        //     this.populateDates(0, 0, false);
        // }
        if(this.state.activeIndex === index) {
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(this.state.selectPoolId+"", "", "", undefined);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(this.state.selectPoolId+"", this.state.usageStartDate, this.state.usageEndDate, undefined);
            } else if(this.state.activeIndex === 2) {
                this.rejectedRef.current.setPoolId(this.state.selectPoolId, this.state.usageStartDate, this.state.usageEndDate, undefined);
            } else if(this.state.activeIndex === 3) {
                this.consumeRef.current.setTenantId(this.state.selTenantId, this.state.usageStartDate, this.state.usageEndDate);
            }
        }
        this.setState({activeIndex: index});
    }
    rejectedAction() {
        this.setState({activeIndex: 2});
    }
    selectPoolName(event) {
        if(event.option.value === "moreoptions") {
            this.setState({enableMorePools: true});
            this.buildMorePools();
        } else {
            this.setState({selectPoolId: event.option.value+""});
            this.setState({selPoolName: event.option});
            this.setState({selectCurrentVal: event.option});
            this.setState({activeIndex: this.state.activeIndex});
            this.setState({enableMorePools: false});
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(event.option.value+"", "", "", undefined);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(event.option.value+"", this.state.usageStartDate, this.state.usageEndDate, undefined);
            }
        }
    }
    clickMorePool(value) {
        let pMap = this.state.morePoolMap;
        if(pMap) {
            this.setState({selectPoolId: value+""});
            this.setState({selPoolName: pMap[value]});
            this.setState({selectCurrentVal: pMap[value]});
            this.setState({activeIndex: this.state.activeIndex});
            this.setState({enableMorePools: false});
            if(this.state.activeIndex === 0) {
                this.inUseRef.current.setPoolId(value+"", "", "", undefined);
            } else if(this.state.activeIndex === 1) {
                this.historyRef.current.setPoolId(value+"", this.state.usageStartDate, this.state.usageEndDate, undefined);
            }
        }
    }
    buildMorePools() {
        let pools = this.state.poolNamesIdsMap;
        let pList = [];
        if(pools) {
            Object.entries(pools).map(([key, value]) => {
                pList.push(<div className="more-all-pool-display" onClick={(event)=>this.clickMorePool(key)}>{value}</div>);
                return "";
            });
            this.setState({morePoolsList: pList});
        }
    }
    datesCancelAction() {
        this.setState({showModal: false});
    }
    datesOkAction(event, s) {
        let sDate = DateUtility.getDate(new Date(s.fromDate));
        let eDate = DateUtility.getDate(new Date(s.toDate));
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let fDate = new Date(sDate).getTime();
            let tDate = new Date(eDate).getTime();
            if(tDate < fDate) {
                //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation1", this.context.intl);
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                let displayDate = DateUtility.getLocalFormatedDateFromDate(sDate)+" - "+DateUtility.getLocalFormatedDateFromDate(eDate);
                this.setState({displayValue: displayDate});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate})
                this.selectDateRange(sDate, eDate);
                this.setState({showModal: false});
            }
        } else {
            //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation2", this.context.intl);
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }
    onSelectDateRange(event) {
        let v = event.option.value;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            let displayDate = DateUtility.getLocalFormatedDateFromDate(val[0])+" - "+DateUtility.getLocalFormatedDateFromDate(val[1]);
            this.setState({displayValue: displayDate});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            this.selectDateRange(val[0], val[1]);
        }
    }
    selectDateRange(usageStartDate, usageEndDate) {
        this.setState({usageStartDate: usageStartDate});
        this.setState({usageEndDate: usageEndDate})
       if(this.state.activeIndex === 1) {
            this.historyRef.current.setPoolId(this.state.selectPoolId, usageStartDate, usageEndDate, undefined);
        } else if(this.state.activeIndex === 2) {
            this.rejectedRef.current.setPoolId(this.state.selectPoolId, usageStartDate, usageEndDate, undefined);
        } else if(this.state.activeIndex === 3) {
            this.consumeRef.current.setTenantId(this.state.selTenantId, usageStartDate, usageEndDate);
        }
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.callBackCommuterUpdates);
    }
    componentDidMount() {
        APTableStore.on("change", this.callBackCommuterUpdates);
        if(this.state.activeIndex === 3) {
            this.populateDates(0, 0, false);
        } else {
            this.populateDates(29, 3, true);
        }
        this.setState({selTenantName: {"label": this.findLabel("label.select.all"), "value": null}});
    }
    populateDates(noOfdays, opt, flag) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        if(opt === 0) {
            previousDate = DateUtility.convertToServerTimeZone(previousDate);
        }
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        if(opt === 1) {
            this.setState({usageEndDate: DateUtility.getDate(previousDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(previousDate));
            }
        } else {
            this.setState({usageEndDate: DateUtility.getDate(currentDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(currentDate));
            }
        }
        let v = DateUtility.getLocalFormatedDateFromDate(previousDate)+" - "+DateUtility.getLocalFormatedDateFromDate(currentDate);
        if(!flag) {
            v = DateUtility.getLocalFormatedDateFromDate(previousDate)+" - "+DateUtility.getLocalFormatedDateFromDate(currentDate);
            this.setState({selDateRangeVal: {"label": this.findLabel("label.date.today"), "value": v}});
        }
        this.setState({displayValue: v});
    }
    listPoolNamesAction() {
        if(APTableStore.getState().actionType !== "usagehistory_graph") {
            let listReservationGroups = APTableStore.getState().listReservationGroups;
            let requestMap = APTableStore.getState().requestMap;
            let groupColorPalette = APTableStore.getState().groupColorPalette;
            let poolColorMap = {};
            if(requestMap) {
                let poolColore = requestMap["groupColors"];
                let poolColorList = poolColore.split(",");
                for(let i=0;i<poolColorList.length;i++) {
                    let p = poolColorList[i].split(":");
                    poolColorMap[p[0]] = p[1];
                }
            }
            let poolColorsById = {};
            let minDataSet = [];
            let counter = 1;
            let poolIdMap = {};
            if(listReservationGroups) {
                let pMap = {};
                Object.entries(listReservationGroups).map(item => {
                    let obj;
                    if(item[0] === "0") {
                        obj = {"label": <ColorLabel poolName={this.findLabel("label.select.all")} poolNameIndicationColor="#fff" />, "value": item[0]};
                        if(this.state.selectCurrentVal === "") {
                            this.setState({selPoolName: obj});
                        }
                    } else {
                        poolColorsById[item[0]] = poolColorMap[item[1]];
                        obj = {"label": <ColorLabel poolName={item[1]} poolNameIndicationColor={groupColorPalette[poolColorMap[item[1]]]} />, "value": item[0]};
                    }
                    if(counter <= 7) {
                        minDataSet.push(obj);
                    } else {
                        pMap[item[0]] = obj;
                        poolIdMap[item[0]] = item[1];
                    }
                    counter++;
                    return "";
                });
                this.setState({morePoolMap: pMap});
            }
            if(counter > 7) {
                minDataSet.push({label: <span id="pool_more_options_id">{this.findLabel("label.more.opitons")}</span>, value: "moreoptions"});
            }
            this.setState({poolNamesIdsMap: poolIdMap});
            this.setState({limitpoolNamesWithColor: minDataSet});
            this.setState({poolColorIndexById: poolColorsById});
            this.setState({groupColorPalette: groupColorPalette});
            let dataObj = APTableStore.getState().data;
            if(dataObj) {
                this.setState({minCapacity: dataObj.minCapacity});
                this.setState({featurePkId: dataObj.featurePkId});
                this.setState({adminCommuterDays: dataObj.adminCommuterDays});
                this.setState({adminCommuterCapacity: dataObj.adminCommuterCapacity});
                if(dataObj.adminCommuterCapacity < 1) {
                    this.setState({editaAdminCommuterCapacityValue: 0});
                } else {
                    this.setState({editaAdminCommuterCapacityValue: dataObj.adminCommuterCapacity});
                }
                this.setState({pdfFeature: dataObj.pdfFeature});
                if(dataObj.pdfFeature) {
                    this.setState({commuterNoOfDays: dataObj.pdfFeature.commuterNoOfDays});
                    if(dataObj.adminCommuterDays < 1) {
                        this.setState({editCommuterDaysValue: dataObj.pdfFeature.commuterNoOfDays});
                    } else {
                        this.setState({editCommuterDaysValue: dataObj.adminCommuterDays});
                    }
                    if(dataObj.pdfFeature.featurePropertyList !== undefined && 
                        dataObj.pdfFeature.featurePropertyList.entry !== undefined){
                        let isNamedFeature = false;
                        Object.entries(dataObj.pdfFeature.featurePropertyList.entry).map(([key, val]) => {
                            if(val.key === "AP_ENABLE_SYSTEM_IS_NAMED_USER"){
                                isNamedFeature = (val.value === "true"?true:false);
                            }
                            return "";
                        });
                        this.setState({isNamedFeature: isNamedFeature });
                    }
                }
                this.setState({commuterValue: dataObj.commuter});
            }
        }
    }
    callBackCommuterUpdates() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "updatecommuter") {
            let dataObj = APTableStore.getState().data;
            if(dataObj) {
                this.closePopuAction();
                this.setState({minCapacity: dataObj.minCapacity});
                this.setState({featurePkId: dataObj.featurePkId});
                this.setState({adminCommuterDays: dataObj.adminCommuterDays});
                this.setState({adminCommuterCapacity: dataObj.adminCommuterCapacity});
                if(dataObj.adminCommuterCapacity < 1) {
                    this.setState({editaAdminCommuterCapacityValue: 0});
                } else {
                    this.setState({editaAdminCommuterCapacityValue: dataObj.adminCommuterCapacity});
                }
                this.setState({commuterValue: dataObj.commuter});
            }
            this.setState({editaAminCommuterCapacity: false});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    listTenantNames() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "unitcons_graph") {
            this.setState({tenentNameList: APTableStore.getState().tenantsData});
        }
    }
    selectTenantName(event) {
        this.setState({selTenantName: event.option});
        if(this.state.activeIndex === 3) {
            this.setState({selTenantId: event.option.value});
            this.consumeRef.current.setTenantId(event.option.value, this.state.usageStartDate, this.state.usageEndDate);
        }
    }
    closePopuAction() {
        this.setState({showAdminCommuterDetails: false});
        this.setState({errorMessage: ""});
        this.setState({editaAminCommuterCapacity: false});
    }
    showPopuAction() {
        this.setState({showAdminCommuterDetails: true});
    }
    editOnClickAction() {
        this.setState({editaAminCommuterCapacity: true});
    }

    onConfirmCapacity(flag){
        if(flag === 1){
            let request = {
                featurePkId: this.state.featurePkId,
                maxCommNoDays: this.state.editCommuterDaysValue,
                maxCommNocapacity: this.state.editaAdminCommuterCapacityValue
            }
            FetchHelper.updateAdminCommuterDetails(request, this.context.intl);
        }
    }
    onSaveChangesAction() {
        let commuterDays = this.state.editCommuterDaysValue;
        let commuterCapacity = this.state.editaAdminCommuterCapacityValue;
        if(commuterDays === "" || !ValidationUtility.isNumber(commuterDays) 
            || Number(commuterDays) <= 0) {
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn1")});
        } else if(commuterCapacity === "" || !ValidationUtility.isNumber(commuterCapacity) 
            || Number(commuterCapacity) <= -1) {
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn2")});
        } else if(Number(commuterDays) > Number(this.state.commuterNoOfDays)) {
            let messageParams = {};
            messageParams["maxValue"] = this.state.commuterNoOfDays;
            this.setState({errorMessage: LocaleUtility.getLocaleMessage("usage.report.admin.warn3", messageParams)});
        } else {
            if(Number(commuterCapacity) === 0) {
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleMessage("usage.report.admin.warn4"),
                    this.onConfirmCapacity, undefined, "","");
            } else {
                let request = {
                    featurePkId: this.state.featurePkId,
                    maxCommNoDays: this.state.editCommuterDaysValue,
                    maxCommNocapacity: this.state.editaAdminCommuterCapacityValue
                }
                FetchHelper.updateAdminCommuterDetails(request, this.context.intl);
            }
        }
    }
    changeCommuterDays(event) {
        this.setState({editCommuterDaysValue: event.target.value});
    }
    changeCommuterCapacity(event) {
        this.setState({editaAdminCommuterCapacityValue: event.target.value});
    }
    callToExportRequest() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "history" || pageType === "rejected" || pageType === "unitcons" 
            || pageType === "inuse") {
            let licenseModel = localStorage.getItem("licenseModel");
            let featureDesc = localStorage.getItem("featureDescription");
            let grpTotalCap,usedCapacity;
            if(pageType !== "unitcons") {
                let requestMap = APTableStore.getState().requestMap;
                grpTotalCap = requestMap["grptotalcapacity"];
                usedCapacity = requestMap["usedcapcity"];
                let list = APTableStore.getState().reportSearchList;
                if(list && list.length > 0) {
                    this.setState({showExportExcelIcon: true});
                } else {
                    this.setState({showExportExcelIcon: false});
                }
            } else {
                let list = APTableStore.getState().cumulativeUsageList;
                if(list && list.length > 0) {
                    this.setState({showExportExcelIcon: true});
                } else {
                    this.setState({showExportExcelIcon: false});
                }
            }
            let featInfo = localStorage.getItem("featureId:Version");
            let featArr = featInfo.split(":");
            let featureId = featArr[0];
            let featureVersion = featArr[1];
            let request = {
                usageStartDate: this.state.usageStartDate,
                usageEndDate: this.state.usageEndDate,
                featureId: featureId,
                featureVersion: featureVersion,
                featureDescription: featureDesc,
                licenseModel: licenseModel,
                grptotalcapacity: grpTotalCap,
                overallusagecapacity: usedCapacity,
                tenentId: this.state.selTenantId,
                groupId: this.state.selectPoolId
            }

            if(pageType === "rejected"){
                request.groupId = "0";
            }
            this.setState({exportExcelRequest: request});
        }
    }
    exportExcelAction(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        if(this.state.activeIndex === 1) {
            FetchHelper.downloadUsageHistoryReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 2) {
            FetchHelper.downloadUsageRejectedReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 3) {
            FetchHelper.downloadUsageConsumptionReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        } else if(this.state.activeIndex === 0) {
            FetchHelper.downloadUsageInuseReports(this.state.exportExcelRequest, this.context.intl, mimetype);
        }
    }
    closeMorePopupAction() {
        this.setState({enableMorePools: false});
    }
    render() {
        //let intl = this.context.intl, 
        let rightSection, midSection;
        let curHelpTopic = "R_Feature_Report_Page";
        let inUseTabCls = "inuseInActive";
        let historyTabCls = "inuseInActive";
        let rejectedTabCls = "inuseInActive";
        let consumptionTabCls = "inuseInActive";
        let featureDesc = localStorage.getItem("featureDescription");
        //let featureIdVersion = localStorage.getItem("featureId:Version");
        let cumulativeUsage = localStorage.getItem("cumulativeUsage");
        if(cumulativeUsage === undefined || cumulativeUsage === "undefined"
            || cumulativeUsage === null || cumulativeUsage === "null") {
            cumulativeUsage = false;
        }
        let licModel = localStorage.getItem("licenseModel");
        let inuseTitle = this.findLabel("usage.graph.inuse.title");
        if(licModel === "6" || licModel === 6) {
            licModel = 6;
            if(!cumulativeUsage) {
                inuseTitle = this.findLabel("usage.graph.consumption.title");
            }
        }
        if(licModel === "7" || licModel === 7) {
            licModel = 7;
            if(!cumulativeUsage) {
                inuseTitle = this.findLabel("usage.graph.consumption.title");
            }
        }
        if(this.state.activeIndex === 1){
            curHelpTopic = "R_Feature_Report_Page";
            historyTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{float: "right"}}>
                        <Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>
                        <span style={{borderRight: "0px solid #CCC"}}></span>
                        {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcelAction}><DocumentExcelIcon/></Anchor> */}
                        <span style={{padding: "4px"}}></span>
                        <div className="flexBox" style={{float: "inherit"}}>
                          <div className="floatRight"></div>
                            <a mimetype="CSV" 
                                title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} 
                                onClick={this.exportExcelAction}>
                                    <DocumentCsvIcon id="usage-export-icon" colorIndex="brand"/>
                            </a>
                        </div> 
                        {/* <Anchor mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="usage-export-icon" colorIndex="brand"/></Anchor> */}
                    </div>
            } else {
                rightSection = <div style={{float: "right"}}>
                        <Anchor  mimetype="CSV" title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>
                    </div>
            }
                    
        }else if(this.state.activeIndex === 2){
            curHelpTopic = "R_Feature_Report_Page";
            rejectedTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{float: "right"}}>
                            {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcelAction}><DocumentExcelIcon/></Anchor>
                            <span style={{borderRight: "2px solid #ccc"}}></span> */}
                            <div className="flexBox" style={{float: "inherit"}}>
                            <div className="floatRight">
                                <a mimetype="CSV" 
                                title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} 
                                onClick={this.exportExcelAction}>
                                    <DocumentCsvIcon id="usage-export-icon1" colorIndex="brand"/>
                                </a>                         
                            </div>
                            </div>
                            {/* <Anchor mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="usage-export-icon1" colorIndex="brand"/></Anchor> */}
                        </div>
            }            
        }else if(this.state.activeIndex === 3){
            curHelpTopic = "Consumption_Tab";
            consumptionTabCls = "inuseActive";
            rightSection = <div style={{float: "right"}}>
                            {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcelAction}><DocumentExcelIcon/></Anchor>
                            <span style={{borderRight: "2px solid #ccc"}}></span> */}
                            <div className="flexBox" style={{float: "inherit"}}>
                            <div className="floatRight">
                                <a mimetype="CSV" 
                                title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} 
                                onClick={this.exportExcelAction}>
                                    <DocumentCsvIcon id="usage-export-icon2" colorIndex="brand"/>
                                </a>                         
                            </div>
                            </div>
                            {/* <Anchor mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="usage-export-icon" colorIndex="brand"/></Anchor> */}
                    </div>
        } else if(this.state.activeIndex === 0) {
            inUseTabCls = "inuseActive";
            if(this.state.showExportExcelIcon) {
                rightSection = <div style={{float: "right"}}>
                    {(licModel !== 6 && licModel !== 7) ? 
                        <span>
                            <Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand" onClick={this.showPopuAction}/></Anchor>
                        </span>
                    : null}
                    {/* <Anchor mimetype="excel" title={getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcelAction}><DocumentExcelIcon/></Anchor>*/}
                    <span style={{padding: "4px"}}></span>
                    <div className="flexBox" style={{float: "inherit"}}>
                    <div className="floatRight">
                        <a mimetype="CSV" 
                        title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} 
                        onClick={this.exportExcelAction}>
                            <DocumentCsvIcon id="usage-export-icon3" colorIndex="brand"/>
                        </a>                         
                    </div>
                    </div> 
                    {/* <Anchor mimetype="CSV" title={getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcelAction}><DocumentDownloadIcon id="usage-export-icon" colorIndex="brand"/></Anchor> */}
                    </div>
            } else {
                rightSection = <div style={{float: "right"}}>
                        {(licModel !== 6 && licModel !== 7) ? 
                            <Anchor title={getLocaleMessage("label.tooltip.info")}><CircleInformationIcon colorIndex="brand"  onClick={this.showPopuAction}/></Anchor>
                        : null}
                        </div>
            }
        }
        let isClockTampered = localStorage.getItem("isClockTampered");
        if(isClockTampered !== undefined && (isClockTampered === "true" || isClockTampered === true)) {
            midSection = <div className="clockTamperPopup">
                            <div style={{float: "left"}}><Anchor title={getLocaleMessage("label.tooltip.clock.tampered")}><ClockIcon /></Anchor></div>
                            <div classNam="clockTamperText" style={{paddingTop: "2px"}}>{getLocaleMessage("label.machine.clock.tampered")}</div>
                        </div>
        }
        let poolWidth = "30%";
        let dateWidth = "50%";
        if(this.state.activeIndex === 0) {
            poolWidth = "30%";
            dateWidth = "0%";
        } else if(this.state.activeIndex === 1) {
            poolWidth = "30%";
            dateWidth = "50%";
        } else if(this.state.activeIndex === 2) {
            poolWidth = "0%";
            dateWidth = "50%";
        } else if(this.state.activeIndex === 3) {
            poolWidth = "30%";
            dateWidth = "50%";
        }
        if(this.state.backToPreviousPage) {
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_LIST_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}}}/>
        } else {
            let showMorePools = this.state.enableMorePools ? "modal display-block" : "modal display-none";
            let showCommuterWindow = this.state.showAdminCommuterDetails ? "modal display-block" : "modal display-none";
            return (
                <div style={{backgroundColor: "#F5F7F8"}} className="config-cont-backgroud">
                {/*<SectionWithTitle title={featureDesc} />*/}
                <div>
                    <APLSBack 
                        id="usage_backBtn_id"
                        className="back-button-container" 
                        onClick={this.backBtnAction}
                    />
                    <div className="border_white_backg_shadow">
                        <div className="inuse-title">
                            {/*<span style={{paddingRight: "4px", color: "rgb(0, 115, 231)", borderRight: "2px solid #cccccc"}}>{featureIdVersion}</span>*/}
                            <span style={{paddingLeft: "4px"}}>{featureDesc}</span>
                        </div>
                        <div style={{width: "58%", float: "left"}}>
                            <div className="inuse-filter-table">
                                <div style={{float: "left", minWidth: dateWidth, width: "auto"}}>
                                            {this.state.activeIndex === 0 ? null : <DateRange 
                                        label = {this.findLabel("usage.graph.daterange.label")+" "}
                                                onChange = {this.onSelectDateRange}
                                                value = {this.state.selDateRangeVal}
                                                displayValue = {this.state.displayValue}
                                                width = "22em"
                                                defaultValue = {3}
                                                renderFor = "history"
                                            />}
                                </div>
                                <div style={{float: "left", width: poolWidth, textOverflow: "ellipsis"}}>
                                    {this.state.activeIndex === 2 || this.state.activeIndex === 3 || this.state.isNamedFeature ? "" : <div>
                                        {/*<FormField id="usage_list_form_seletPoolName" style={{ width: '20em' }} label= {this.findLabel("usage.graph.select.pool.label", intl)} >
                                                        <Select id="usage_list_seletPoolName"
                                                            options={this.state.limitpoolNamesWithColor}
                                                            value={this.state.selPoolName}
                                                            onChange={this.selectPoolName}
                                                        />
                                            </FormField>*/}
                                        <Select id="usage_list_seletPoolName"
                                            options={this.state.limitpoolNamesWithColor}
                                            value={this.state.selPoolName}
                                            onChange={this.selectPoolName}
                                            enabledSearch={false}
                                            width= {15}
                                            label= {this.findLabel("usage.graph.select.pool.label")+" "}
                                        />
                                    </div>} 
                                    {this.state.activeIndex === 3 ? 
                                        /*<FormField id="usage_consumption_form_seletTenantName" style={{ width: '20em' }} label= {this.findLabel("usage.consumption.tenent.label", intl)} >
                                                    <Select id="usage_consumption_seletTenantName"
                                                        options={this.state.tenentNameList}
                                                        value={this.state.selTenantName}
                                                        onChange={this.selectTenantName}
                                                    />
                                        </FormField>*/
                                        <Select id="usage_consumption_seletTenantName"
                                            options={this.state.tenentNameList}
                                            value={this.state.selTenantName}
                                            onChange={this.selectTenantName}
                                            label= {this.findLabel("usage.consumption.tenent.label")+": "}
                                            width= {22}
                                        />
                                            : ""
                                            }
                                </div>
                                <div style={{float: "right", width: "15%"}}>
                                    <SectionWithTitle 
                                        title={""} 
                                        midSection = {midSection} 
                                        rightSection={rightSection} 
                                        helpTopic={curHelpTopic}
                                        setionWidth = "auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inuse-container">
                        <Header fixed={false} margin="none" id="header2" sticky={false} >
                            <Tabs activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction} >
                                {(!cumulativeUsage || licModel === 6) ? <Tab title={inuseTitle}
                                    className={inUseTabCls}>
                                    <InUseGraph ref={this.inUseRef}
                                        listPoolNamesAction = {this.listPoolNamesAction}
                                        backButtonAction={this.backBtnAction}
                                        selectPoolId = {this.state.selectPoolId}
                                        poolColorIndexById = {this.state.poolColorIndexById}
                                        callToExportRequest = {this.callToExportRequest}
                                        instanceId = {undefined}
                                        />
                                </Tab> : null}
                                {(!cumulativeUsage && (licModel!== 6 && licModel!== 7)) ? <Tab title={this.findLabel("usage.graph.history.title")} 
                                    className={historyTabCls}>
                                    <HistoryUsageGraph ref={this.historyRef}
                                        listPoolNamesAction = {this.listPoolNamesAction}
                                        backButtonAction={this.backBtnAction}
                                        selectPoolId = {this.state.selectPoolId}
                                        usageStartDate = {this.state.usageStartDate}
                                        usageEndDate = {this.state.usageEndDate}
                                        rejectedAction = {this.rejectedAction}
                                        poolColorIndexById = {this.state.poolColorIndexById}
                                        groupColorPalette = {this.state.groupColorPalette}
                                        callToExportRequest = {this.callToExportRequest}
                                        instanceId = {undefined}
                                    />
                                </Tab> : null }
                                {(!cumulativeUsage || licModel === 6 || licModel === 7) ? <Tab title={this.findLabel("usage.graph.rejected.title")}
                                    className={rejectedTabCls}>
                                    <RejectedUsageGraph  ref={this.rejectedRef}
                                        backButtonAction={this.backBtnAction}
                                        selectPoolId = {this.state.selectPoolId}
                                        usageStartDate = {this.state.usageStartDate}
                                        usageEndDate = {this.state.usageEndDate}
                                        callToExportRequest = {this.callToExportRequest}
                                        instanceId = {undefined}
                                    />
                                </Tab> : null }
                                {cumulativeUsage ? <Tab title={this.findLabel("usage.graph.consumption.title")}
                                    className={consumptionTabCls}>
                                    <ConsumptionUsageGraph  ref={this.consumeRef}
                                        backButtonAction={this.backBtnAction}
                                        selectPoolId = {this.state.selectPoolId}
                                        usageStartDate = {this.state.usageStartDate}
                                        usageEndDate = {this.state.usageEndDate}
                                        listTenantNames = {this.listTenantNames}
                                        callToExportRequest = {this.callToExportRequest}
                                        instanceId = {undefined}
                                    />
                                </Tab> : null }
                            </Tabs>
                        </Header>
                    </div>
                    {this.state.showModal ? <DatesPopup 
                        cancelAction = {this.datesCancelAction}
                        startDateLabel = {this.findLabel("usage.graph.from.label")}
                        endDateLabel = {this.findLabel("usage.graph.to.label")}
                        showModal = {this.state.showModal}
                        fromDate = {this.state.usageStartDate}
                        toDate = {this.state.usageEndDate}
                        errorMessage = {this.state.datesErrorMessage}
                        okAction={this.datesOkAction} /> : ""}
                    <div>
                        <div id="commuter-info-popup-id" className={showCommuterWindow}>
                            <div id="commuterInfoDivId" className="modal">
                                <div style={{minWidth: "30%", backgroundColor: "#fff", width: "30%"}} className="modal-main panel panel-default">
                                    <span className="usageClose" onClick={this.closePopuAction}>X</span>
                                    {this.state.editaAminCommuterCapacity ? 
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <span style={{color: "red"}}>
                                                            {this.state.errorMessage}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                    </td>
                                                    <td>
                                                        <TextInput id="admin_commuter_days_id" value={this.state.editCommuterDaysValue} onChange={event =>this.changeCommuterDays(event)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {this.findLabel("usage.report.admin.commuter.capicty.allowed")}
                                                    </td>
                                                    <td>
                                                        <TextInput id="admin_commuter_capacity_id" value={this.state.editaAdminCommuterCapacityValue} onChange={event =>this.changeCommuterCapacity(event)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" align="right">
                                                        <Button id="admin_commuter_changes_savebtn" label={this.findLabel("admin.commuter.save.changes")} plain={false} primary={true} onClick={this.onSaveChangesAction} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    : 
                                        <table>
                                            <tbody>
                                                <tr id="tr-min-cap-id">
                                                    <td>{this.findLabel("usage.report.admin.commuter.min.cap")}</td>
                                                    <td>{this.state.minCapacity}</td>
                                                </tr>
                                                {this.state.commuterValue === 4 ? 
                                                    <tr>
                                                        <td>
                                                            <b>{this.findLabel("usage.report.admin.checkout.settings")}</b>
                                                        </td>
                                                        <td>
                                                            <Anchor label={this.findLabel("label.button.edit")} onClick={this.editOnClickAction} />
                                                        </td>
                                                    </tr>
                                                : null}
                                                {this.state.commuterValue === 4 ? 
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.adminCommuterDays < 1 ? 
                                                                this.state.commuterNoOfDays
                                                            :   this.state.adminCommuterDays }
                                                        </td>
                                                    </tr>
                                                : null}
                                                {this.state.commuterValue === 4 ?
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.capicty.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.adminCommuterCapacity < 1 ? 
                                                                <span>{this.findLabel("usage.report.admin.available.cap")}</span>
                                                            :   this.state.adminCommuterCapacity }
                                                        </td>
                                                    </tr>
                                                : null}
                                                {localStorage.getItem("isTokenBaseFeature") !== "0" && this.state.commuterValue === 1 ? 
                                                    <tr>
                                                        <td>
                                                            {this.findLabel("usage.report.admin.commuter.max.allowed")}
                                                        </td>
                                                        <td>
                                                            {this.state.commuterNoOfDays}
                                                        </td>
                                                    </tr>
                                                : null}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="more-pools-div-id" className={showMorePools}>
                        <div id="more-pools-sub-id" className="modal">
                            <div style={{minWidth: "30%"}} className="modal-main panel panel-default">
                                <span className="usageClose" onClick={this.closeMorePopupAction}>X</span>
                                <div className="more-all-groups">{this.findLabel("label.all.groups")}</div>
                                <div className="more-all-groups-display">{this.findLabel("label.select.group.display")}</div>
                                <div style={{height: "450px", overflow: "auto"}}>
                                    {this.state.morePoolsList}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
    }
}
// UsageReportGraphs.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportGraphs;