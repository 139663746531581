import SpinningNotification from '../../generic/SpinningNotification';
import Modal from "../../generic/Modal";
import Select from "../../commons/APLSSelect";
import TableGrid from "../../../components/commons/table/APTable";

import SectionWithTitle from "../../generic/SectionWithTitle";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as TableUtility from "../../../utils/TableUtility";
import * as LicenseUtility from "../../../utils/LicenseUtility";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Store from "../../../flux/APTableStore";
import LicenseDetailStore from "../../../flux/LicenseDetailStore";
import Dispatcher from "../../../flux/Dispatcher";
import LicenseDetail from "./subcomponents/LicenseDetail";
import LicenseRenewForm from "./subcomponents/LicenseRenewForm";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import "../../../css/index.css";
import LicenseTenantForm from "../../main/license/subcomponents/LicenseTenantForm";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { CheckBox } from "grommet/components/CheckBox";
import { Anchor } from 'grommet/components/Anchor';
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
//import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import { DocumentCsv as DocumentCsvIcon } from 'grommet-icons';
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseView extends Component {
    constructor(props) {
        super(props);
        this.selectProduct = React.createRef();
        this.state = {
            rowData: [],
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,
            productNameSelected: undefined,
            productNameSelectOptions: [],
            selectAllRows: false,
            licenseDetail: undefined,
            selectedRowKeys: [],
            showAssinPopup: false,
            showRenewForm: false,
            pacLicenseCntMap: new Map(),
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            licenseObjForAssign: {}
        }
        this.renewFormRef = React.createRef();
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.rowData = [];
        this.refreshState = this.refreshState.bind(this);
        this.filterLicenseList = this.filterLicenseList.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.onColumnClicked = this.onColumnClicked.bind(this);
        this.archiveLicense = this.archiveLicense.bind(this);
        this.refreshModal = this.refreshModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
        this.exportInstalledLicense = this.exportInstalledLicense.bind(this);
        this.exportLicenseAllKeys = this.exportLicenseAllKeys.bind(this);

        this.paintCheckBox = this.paintCheckBox.bind(this);
        this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
        this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
        this.onConfrimArchiveAction = this.onConfrimArchiveAction.bind(this);
        this.showAssignLicAction = this.showAssignLicAction.bind(this);
        this.cancelAssignLicAction = this.cancelAssignLicAction.bind(this);
        this.openRenewForm = this.openRenewForm.bind(this);
        this.onSubmitRenewForm = this.onSubmitRenewForm.bind(this);
        this.onRenewFormCloseCallback = this.onRenewFormCloseCallback.bind(this);
    }

    componentDidMount() {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getAllLicenses(undefined, "ACTIVE", undefined);
        Store.on("change", this.refreshState);
        LicenseDetailStore.on("change", this.refreshModal);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.refreshState);
        LicenseDetailStore.removeListener("change", this.refreshModal);
    }
    showAssignLicAction() {
        if (this.state.selectedRowKeys !== undefined && this.state.selectedRowKeys.length > 0) {
            if(this.state.selectedRowKeys.length > 1) {
                ConfigurationUtils.showErrorMessage1("error.please.select.single.record", this.context.intl);
            } else {
                let lObj = {};
                this.state.rowData.map(item => {
                    var key = item.licenseSignature;
                    if(this.state.selectedRowKeys[0] === key) {
                        lObj = item;
                    }
                    return "";
                });
                if(lObj.assignLicense === "Y") {
                    this.setState({showAssinPopup: true, licenseObjForAssign: lObj});
                } else if(lObj.assignLicense === "NA") {
                    ConfigurationUtils.showErrorMessage1("license.assign.ment.pdf.warn", this.context.intl);
                }else {
                    ConfigurationUtils.showErrorMessage1("license.assign.ment.warn", this.context.intl);
                }
            }
        } else {
            ConfigurationUtils.showErrorMessage1("error.please.select.record", this.context.intl);
        }
    }
    cancelAssignLicAction() {
        this.setState({showAssinPopup: false});
    }
    onSortClicked(index, order, header) {
        var sortOrder = "ASC";
        let paginationTemp = this.state.pagination;
        let productName = this.state.productNameSelected;
        
        if(paginationTemp.sortOrder === "ASC"){
            sortOrder = "DESC";
        }
        
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = header[index].columnName;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.setState({ pagination: paginationTemp });
        FetchHelper.getAllLicenses(productName, "ACTIVE", paginationTemp);
    }

    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        let productName = this.state.productNameSelected;
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        FetchHelper.getAllLicenses(productName, "ACTIVE", paginationTemp);
    }

    onFilterClicked(filterColumn, filterValue) {
        if (filterValue !== "") {
            TableUtility.filter(filterColumn, filterValue);
        }
        else {

        }
    }

    filterLicenseList(event){
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getAllLicenses(event.option.value, "ACTIVE", undefined);
    }

    onRowClicked(keys, event){
        this.setState({
            selectedRowKeys: keys
        });
    }

    onColumnClicked(key, event){
        let loadSpinAction = "LICENSE_DETAIL_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getLicenseDetail(key);
    }

    openRenewForm(key, actId, licExp, event){
        let loadSpinAction = "LICENSE_DETAIL_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.spinner.loading")
        });
        FetchHelper.getLicenseRenewDetail(key, actId, licExp);
    }

    onModalCloseCallback() {
        this.setState({
            licenseDetail: undefined
        });
    }

    onRenewFormCloseCallback() {
        this.setState({
            showRenewForm: false
        });
        this.renewFormRef.current.updateState();
    }

    onConfrimArchiveAction(flag){
        if(flag === 1){
            var selectedKeys = this.state.selectedRowKeys;
            this.setState({
                selectedRowKeys: []
            });
            FetchHelper.archiveLicenseList(selectedKeys);
        }
    }

    archiveLicense() {

        if (this.state.selectedRowKeys !== undefined && 
                this.state.selectedRowKeys.length > 0) {
            var selectedKeys = this.state.selectedRowKeys;
            var namedLicExists = false, subLicExist = false;
            var namedLicCheck = (this.state.namedLicMap !== undefined && this.state.namedLicMap);
            var subLicCheck = (this.state.pacLicenseCntMap !== undefined && this.state.pacLicenseCntMap);
            if(namedLicCheck || subLicCheck){
                for(var item of selectedKeys){
                    if( namedLicCheck && this.state.namedLicMap.indexOf(item) > -1){
                        namedLicExists = true;
                        break;
                    } else if(subLicCheck && this.state.pacLicenseCntMap.get(item) !== undefined){
                        if(Number(this.state.pacLicenseCntMap.get(item)) === 1){
                            subLicExist = true;
                            break;
                        }
                    }
                }
            }

            if(namedLicExists){
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleLabel(this.context.intl, "named.license.archive.alert1"), 
                    this.onConfrimArchiveAction);
            } else if(subLicExist){
                ValidationUtility.confirm(
                    LocaleUtility.getLocaleLabel(this.context.intl, "license.renew.details.archive.alert1"), 
                    this.onConfrimArchiveAction);
            } else {
                this.setState({
                    selectedCheckBoxKey: []
                });
                FetchHelper.archiveLicenseList(selectedKeys)
            }
        } else {
            if(this.state.rowData !== undefined && this.state.rowData.length > 0){
                 Dispatcher.dispatch({
                     type: "ALERT_SHOW_MODAL",
                     payload: LocaleUtility.getLocaleLabel(this.context.intl, "label.license.view.alert1")
                 });
                //alert(LocaleUtility.getLocaleLabel(this.context.intl, "label.license.view.alert1"));
            }
        }
    }

    refreshState() {
        var pagination = Store.getState().pagination;
        let sortIndex = 7;
                let indexx = Store.getState().sortIndex;
                switch (indexx) {
                    case "featureDesc":
                        sortIndex=1;
                        break;
                    case "productID":
                        sortIndex=2;
                        break;
                    case "ltuCapacity":
                        sortIndex=3;
                        break;
                    case "capacity":
                        sortIndex=4;
                        break;
                    case "startDate":
                        sortIndex=5;
                        break;
                    case "expiryDate":
                        sortIndex=6;
                        break;
                    case "installedDate":
                        sortIndex=7;
                        break;
                    case "installedBy":
                        sortIndex=8;
                        break;
                    case "lockCode":
                        sortIndex=9;
                        break;          
                    default:
                        sortIndex=7;
                        break;
                }
        var sessObj = FetchHelper.getSession();
        var namedLicMap = [], paclicmap = new Map();
        if(sessObj.admin !== undefined && sessObj.admin) {
            if(Store.getState().type === "LICENSE_VIEW_PAGE_FETCH_COMPLETE" &&
                Store.getState().licViewtableData!==undefined){
             const rows = Store.getState().licViewtableData.map(item => {
                    var key = item.licenseSignature;
                    if(item.isNamedLicense)
                        namedLicMap.push(key);
                    item.checkBoxColumn = this.paintCheckBox(key, false);
                    if(item.tenantName!== undefined){
                        item.checkBoxColumn = "";
                    }

                    /* Auto renew activation form detail hyper link. Deffered to APLS 12.2 release. */
                    if(item.licenseType === 'SUBSCRIPTION' && item.isPAC && item.activationId !== ''){
                        let lic = paclicmap.get(item.activationId);
                        if(lic !== undefined){
                            lic = Number(lic);
                            paclicmap.set(item.activationId, ++lic);
                        } else {
                            paclicmap.set(item.activationId, 1);
                        }
                        item.licenseType = <Anchor title={item.activationId} 
                                                onClick={(event)=>this.openRenewForm(key, item.activationId, (item.expiryOriginalValue*1000))}>
                                                    {item.licenseType}</Anchor>;
                    }

                    return item;
                });
               
            this.setState({
                rowData: rows,
                pagination: pagination,
                sortIndex: sortIndex,
                sortAscending: Store.getState().sortAscending,
                productNameSelected: Store.getState().productNameSelected,
                productNameSelectOptions: Store.getState().productNameSelectOptions,
                selectedRowKeys: [],
                namedLicMap: namedLicMap,
                session: sessObj,
                pacLicenseCntMap: paclicmap
            });
        }
            // if(rows.length===0 && window.location.pathname.endsWith(FrontendPagePath.LICENSE_VIEW_PATH)){
            //    this.props.history.push(FrontendPagePath.LICENSE_PATH);      
            // }
            // }else{
            //         this.setState({
            //             pagination: pagination,
            //             sortIndex: indexx,
            //             sortAscending: Store.getState().sortAscending,
            //             productNameSelected: Store.getState().productNameSelected,
            //             productNameSelectOptions: Store.getState().productNameSelectOptions,
            //             selectedRowKeys: [],
            //             session: sessObj
            //         });
            //     }
        } else if(sessObj.admin !== undefined && !sessObj.admin && 
            sessObj.productList !== undefined ){
            var features = [];
            var validFeatures = FetchHelper.getUserFeatures();
            validFeatures.then(data => {
                features = data;
                if(Store.getState().licViewtableData!==undefined){
                    const rows = Store.getState().licViewtableData.map(item => {
                        var key = item.licenseSignature;
                         if(data !== undefined && data.indexOf(item.featureID+":"+item.featureVersion) > -1 && sessObj.roletype!=="fullview"){
                             item.checkBoxColumn = this.paintCheckBox(key, false);
                         }else{
                            item.checkBoxColumn = "";
                         }
                        return item;
                    });
                    
                    this.setState({
                        rowData: rows,
                        features: features,
                        pagination: pagination,
                        sortIndex: indexx,
                        sortAscending: Store.getState().sortAscending,
                        productNameSelected: Store.getState().productNameSelected,
                        productNameSelectOptions: Store.getState().productNameSelectOptions,
                        selectedRowKeys: [],
                        session: sessObj
                    });
                }
                // }else{
                //     this.setState({
                //         features: features,
                //         pagination: pagination,
                //         sortIndex: indexx,
                //         sortAscending: Store.getState().sortAscending,
                //         productNameSelected: Store.getState().productNameSelected,
                //         productNameSelectOptions: Store.getState().productNameSelectOptions,
                //         selectedRowKeys: [],
                //         session: sessObj
                //     });
                // }
            }).catch((status, error) => {
                LicenseUtility.processError(status, error);
            });
        }    

        let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }

    refreshModal() {
        let type = LicenseDetailStore.getState().type;
        if(type === 'licenseDetail'){
            this.setState({
                licenseDetail: LicenseDetailStore.getState().licenseDetail,
                renewDetail: undefined,
                operation: LicenseDetailStore.getState().operation
            });
        } else if(type === 'licRenewDetail'){
            this.setState({
                licenseDetail: LicenseDetailStore.getState().licenseDetail,
                operation: LicenseDetailStore.getState().operation
            });
        } else if(type === 'renewDetail'){
            this.setState({
                renewDetail: LicenseDetailStore.getState().renewDetail,
                showRenewForm: LicenseDetailStore.getState().showRenewForm,
                operation: LicenseDetailStore.getState().operation
            });
            //this.renewFormRef.current.updateRenewForm(LicenseDetailStore.getState().renewDetail);
        }
        
        let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }
    exportInstalledLicense(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let paginationTemp = this.state.pagination;
        FetchHelper.downloadInstalledLicenses(this.state.productNameSelected,this.context.intl, false, "ACTIVE",paginationTemp,mimetype);
    }

    exportLicenseAllKeys(e) {
        let mimetype = e.currentTarget.attributes.mimetype.value;
        let paginationTemp = this.state.pagination;
        FetchHelper.downloadInstalledLicenses(this.state.productNameSelected,this.context.intl, true, "ACTIVE",paginationTemp,mimetype);
    }

    onHeaderCheckBoxClick(event){
        var keys = [];
        if(event.target !== undefined && event.target.checked){
            this.state.rowData.map(item => {
                keys.push(item["licenseSignature"]);
                return "";
            });
        } else if(event.target !== undefined && !event.target.checked) {
            keys = [];
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
                    var key = item.licenseSignature;
                    var index = keys.indexOf(key);
                    if(this.state.features === undefined || 
                        (this.state.features !== undefined && 
                            this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                                if(this.state.session.roletype!=="fullview"){
                                    item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                                    count++;
                                }                        
                    } else if(this.state.features !== undefined && index !== -1)
                        keys.splice(index, 1);
                if(item.tenantName!== undefined){
                    item.checkBoxColumn = "";
                 }
                    return item;
                });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

    onRowCheckBoxClick(key, event){
        var keys = Object.assign([], this.state.selectedRowKeys);
        if(event.target.type === 'radio'){
            keys = [];
            keys.push(key);
        } else if(event.target.type === 'checkbox'){
            if(event.target.checked){
                keys.push(key);
            } else {
                var index = keys.indexOf(key)
                if (index !== -1) {
                    keys.splice(index, 1);
                }
            }
        }
        var count = 0;
        const rows = this.state.rowData.map(item => {
            var key = item.licenseSignature;
            if(this.state.features === undefined || 
                (this.state.features !== undefined && 
                    this.state.features.indexOf(item.featureID+":"+item.featureVersion) > -1)){
                item.checkBoxColumn = this.paintCheckBox(key, keys.indexOf(key) > -1);
                count++;
            }
             if(item.tenantName!== undefined){
                    item.checkBoxColumn = "";
            }
            return item;
        });
        this.setState({
            rowData: rows,
            selectAllRows: (keys.length === count),
            selectedRowKeys: keys,
            didUpdate: true
        });
    }

    paintCheckBox(key, flag){
        return (
            <CheckBox checked={flag} onChange={this.onRowCheckBoxClick.bind(this, key)}/>
        )
    }

    sectionContent() {

        const options = []; 
        let selectedValue = {value:undefined, label:LocaleUtility.getLocaleLabel(this.context.intl, "label.select.all")};
        options.push(selectedValue);
        let tokenLicenseFeature = {label: LocaleUtility.getLocaleMessage("label.select.token.license.feature"), value: "1"};
        options.push(tokenLicenseFeature);
        if(this.state.productNameSelected === "1") {
            selectedValue = tokenLicenseFeature;
        }
        var entries = this.state.productNameSelectOptions.entries();
        for(var item of entries){
            options.push({value:item[0], label:item[1]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedValue = {value:item[0], label:item[1]};
            }
        }

        var classVar="grommetux-check-box";
        if(this.state.selectAllRows !== undefined && !this.state.selectAllRows &&
            this.state.selectedRowKeys.length > 0){
            classVar="grommetux-check-box partial";
        }
        var currentTenantName = FetchHelper.getSession().currentTenantName;
        var isTenantAdminUser = FetchHelper.getSession().isTenantAdminUser;
        return (
            <section id="lic_view_listSec" className="page_section_wrap_1" pad="none" wrap="false" responsive={false} justify="center">
               <div className="lic_view_btnwrap action_filter_wrapper">
                <div className="action_wrap_left">
                    <div className="license-view-buttons button_wrap_negative">
                        {this.state.session !== undefined && this.state.session.roletype === 'fullview' && (
                        <Button id="lic_view_archBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.archive")} 
                            plain={false} primary={true} disabled={true}/>
                        )}
                        {this.state.session !== undefined && this.state.session.roletype !== 'fullview' && (
                        <Button id="lic_view_archBtn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.archive")} 
                            plain={false} primary={true} onClick={this.archiveLicense.bind(this)} />
                        )}
                    </div>
                    {currentTenantName !== "" && currentTenantName === "0" && isTenantAdminUser === "Y" && (<div className="license-view-buttons button_wrap_primary" tooltiptext={LocaleUtility.getLocaleLabel(this.context.intl, "label.assign.lic.tenant")}>
                        {this.state.session !== undefined && this.state.session.roletype === 'fullview' && (
                        <Button id="lic_assign_Btn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.assign")} 
                            plain={false} primary={true} disabled={true}/>
                        )}
                        {this.state.session !== undefined && this.state.session.roletype !== 'fullview' && (
                        <Button id="lic_assign_Btn" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.assign")} 
                            plain={false} primary={true} onClick={this.showAssignLicAction} />
                        )}
                    </div>)}
                    <Select className="dropdown_select" label={LocaleUtility.getLocaleLabel(this.context.intl, "label.select.product")+": "} id="lic_view_selProduct" options={options} value={selectedValue} onChange={this.filterLicenseList}/>
                    </div>
                    {this.state.rowData.length > 0 ?
                        <div className="flexBox">
                            <div className="floatRight"> 
                                {/* <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportInstalledLicense}><DocumentExcelIcon/></a>
                                <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                                <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportInstalledLicense}><DocumentDownloadIcon id="view-download-license" colorIndex="brand"/></a>                             
                                {/*<span style={{margin: "0 4px 0 0",padding: "0px 3px"}}>{LocaleUtility.getLocaleMessage("label.license.export.allkeys")} : </span>
                                    <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportLicenseAllKeys}><DocumentExcelIcon/></a> 
                                <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                                <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.license.export.allkeys")} onClick={this.exportLicenseAllKeys}><DocumentCsvIcon id="view-download-allkeys" colorIndex="brand"/></a>                         
                            </div>
                        </div>
                    :null}
                    {/* <SectionWithTitle id="lic_view_mainSec" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.view.license")} helpTopic="manage_license"/> */}
                </div>
                {/*<td>
                    <SectionWithTitle id="lic_view_mainSec" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.view.license")} helpTopic="manage_license"/>
                </td>*/}
            
                    <div className='lic_table_wrap'>
                        <TableGrid id="lic_view_grid"
                            header={[
                                {
                                    columnName: "checkBoxColumn",
                                    headerLabel: <CheckBox className={classVar} checked={(this.state.selectedRowKeys.length > 0)} onChange={this.onHeaderCheckBoxClick.bind(this)}/>,
                                    width: "20px",
                                    sort: false
                                },
                                {
                                    columnName: "featureDesc",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.feature"),
                                    width: "25%",
                                    columnKey: "licenseSignature",
                                    onClick: this.onColumnClicked,
                                    sort: true
                                },
                                {
                                    columnName: "productID",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.prodNumber"),
                                    href: false,
                                    width: "10%",
                                    sort: true
                                },
                                {
                                    columnName: "ltuCapacity",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.ltu"),
                                    href: false,
                                    width: "3%",
                                    sort: true
                                },
                                {
                                    columnName: "capacity",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.capacity"),
                                    href: false,
                                    width: "5%",
                                    sort: true
                                },
                                {
                                    columnName: "startDate",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.startDate"),
                                    href: false,
                                    width: "8%",
                                    sort: true
                                },
                                {
                                    columnName: "expiryDate",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.expDate"),
                                    href: false,
                                    width: "8%",
                                    sort: true
                                },
                                {
                                    columnName: "installedDate",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.installedOn"),
                                    href: false,
                                    width: "8%",
                                    sort: true
                                },
                                {
                                    columnName: "installedBy",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.installedBy"),
                                    href: false,
                                    width: "8%",
                                    sort: true
                                },
                                {
                                    columnName: "lockCode",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.lockcode"),
                                    href: false,
                                    width: "auto",
                                    sort: false
                                },
                                {
                                    columnName: "licenseType",
                                    headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.licenseType"),
                                    href: false,
                                    width: "auto",
                                    sort: false
                                }
                            ]}
                            {...{keys:this.state.selectedRowKeys}}
                            rowData={this.state.rowData}
                            pagination={this.state.pagination}
                            rowSelection={0}
                            isExportRequired={false}
                            onSort={this.onSortClicked}
                            onFilter={this.onFilterClicked}
                            onPagination={this.onPaginationClicked}
                            sortIndex={this.state.sortIndex}
                            sortAscending={this.state.sortAscending}
                            rowKey="licenseSignature"
                            onChangeCheckBoxCallback={this.onRowClicked}
                            selectAllRows={false}
                            noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                            "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                        />
                    </div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                    {this.state.showAssinPopup &&(
                        <LicenseTenantForm 
                            showModal = {this.state.showAssinPopup}
                            cancelAction={this.cancelAssignLicAction}
                            selectedRecord={this.state.licenseObjForAssign}
                        />
                    )}
                    <div style={{ clear: 'both' }}></div>
            </section>
        );
    }

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <LicenseDetail id="lic_view_detail" licenseDetail={licDetail}/>
            );
        }
    }

    modalLicenseDetailContent() {

        let licDetail = this.state.licenseDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(licDetail !== undefined && this.state.renewDetail === undefined){
            if(licDetail.featureDescription !== undefined){
                sectionInfoTitle = licDetail.featureDescription +
                    ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            } else {
                sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            }
            showModal = true
        }
        return (
            <Modal id="lic_view_detailMod" title={sectionInfoTitle} showModal={showModal} styleProps={{top: "48%", overflowY: "auto",maxWidth: "91%", minWidth: "50%", width: "100%", maxHeight:"85%"}}
                    closeLabel={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.close")}
                    sectionContent={this.infoSectionContent(licDetail)}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    />
        );
    }

    onSubmitRenewForm(){
        let stateObj = this.renewFormRef.current.state;
        let enableNewVersion = (stateObj.latestVerChecked ? true: false);
        let formDirty = ((this.state.renewDetail.disableAutoRenew === undefined && stateObj.defaultCheck) ||
                            (this.state.renewDetail.disableAutoRenew !== undefined && 
                                !this.state.renewDetail.disableAutoRenew && !stateObj.defaultCheck) ||
                            (this.state.renewDetail.daysBeforeToRenew !== undefined && 
                                this.state.renewDetail.daysBeforeToRenew !== Number(stateObj.noOfDays)) ||
                            (this.state.renewDetail.enableNewVersion !== undefined && 
                                this.state.renewDetail.enableNewVersion !== enableNewVersion) )
        if(stateObj.fieldLabelError === '' && formDirty ){
            let params = {
                operation: (this.state.operation === "update" && stateObj.disableAutoRenew ? "delete": this.state.operation),
                licenseRenewInfo: {
                    pasActivationId: this.state.renewDetail.pasActivationId,
                    daysBeforeToRenew: Number(stateObj.noOfDays),
                    licExpiryDate: this.state.renewDetail.licExpiryDate,
                    enableNewVersion: enableNewVersion,
                    disableAutoRenew: stateObj.disableAutoRenew,
                    activatedLicCount: this.state.pacLicenseCntMap.get(this.state.renewDetail.pasActivationId)
                }
            };
            FetchHelper.submitLicenseRenewDetails(params);
            this.setState({showRenewForm: false});
            this.renewFormRef.current.updateState();
        } else {
            this.setState({showRenewForm: true});
        }
    }

    renewFormContent() {
        return (
            <LicenseRenewForm ref={this.renewFormRef} id="lic_view_detail" licenseDetail={this.state.licenseDetail}
                renewDetail={this.state.renewDetail} showModal={this.state.showRenewForm}/>
        );
    }

    modalAutoRenewForm() {

        //let licDetail = this.state.renewDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(this.state.showRenewForm){
            // if(licDetail.featureDescription !== undefined){
            //     sectionInfoTitle = licDetail.featureDescription +
            //         ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            // } else {
            //     sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            // }
            showModal = true
        }
        let disableVal = ((this.state.renewDetail !== undefined && 
                                    this.state.renewDetail.renewStatus !== undefined && 
                                    this.state.renewDetail.renewStatus > 0) ? 
                                    true : false);
        return (
            <Modal id="lic_view_detailMod" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.license.renew.form.title")} 
                    showModal={showModal} styleProps={{width: "70%", top: "45%"}}
                    closeLabel={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.cancel")}
                    sectionContent={this.renewFormContent()} 
                    installBtn={{"label":LocaleUtility.getLocaleLabel(this.context.intl, "label.button.submit"),"primary": true, "disable" : disableVal}}
                    onCloseButtonCallback={this.onRenewFormCloseCallback} installBtnClick={this.onSubmitRenewForm}
                    />
        );
    }

    render() {
        return (
            <div className="config-cont-backgroud">
                {this.sectionContent()}
                {this.modalLicenseDetailContent()}
                {this.modalAutoRenewForm()}
            </div>
        );
    }
}

// LicenseView.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseView;