
import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import IDMConfigurationStore from "../../../flux/IDMConfigurationStore";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { PasswordInput as Password } from "../../commons/grommet/PasswordInput";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let initialState = null;
class IDMConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idmServerUrlValue: "",
            idmUserNameValue: "",
            idmPasswordValue: "",
            idmTenentIdValue: "",
            isFormDirty: false,
            hostnameError: "",
            usernameError: "",
            passwordError: "",
            tenantinfoError: ""
        }
        this.findLabel = this.findLabel.bind(this);
        this.submitIdmConfigSettings = this.submitIdmConfigSettings.bind(this);
        this.cancelIdmConfigSettings = this.cancelIdmConfigSettings.bind(this);
        this.handleChangeIdmServerUrl = this.handleChangeIdmServerUrl.bind(this);
        this.changeIdmServerUrl = this.changeIdmServerUrl.bind(this);
        this.handleChangeIdmServerUrl = this.handleChangeIdmServerUrl.bind(this);
        this.changeIdmServerUrl = this.changeIdmServerUrl.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.changeUserName = this.changeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.handleChangeTenentId = this.handleChangeTenentId.bind(this);
        this.changeTenentId = this.changeTenentId.bind(this);
        this.refreshFormData = this.refreshFormData.bind(this);
        this.submitTestIDMConnection = this.submitTestIDMConnection.bind(this);
        this.makeFormDirty = this.makeFormDirty.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }
    handleChangeIdmServerUrl(event) {
        this.makeFormDirty();
        this.changeIdmServerUrl(event.target.value);
    }
    changeIdmServerUrl(val) {
        this.setState({idmServerUrlValue: val});
    }
    handleChangeUserName(event) {
        this.makeFormDirty();
        this.changeUserName(event.target.value);
    }
    changeUserName(val) {
        this.setState({idmUserNameValue: val});
    }
    handleChangePassword(event) {
        this.makeFormDirty();
        this.changePassword(event.target.value);
    }
    changePassword(val) {
        this.setState({idmPasswordValue: val});
    }
    handleChangeTenentId(event) {
        this.makeFormDirty();
        this.changeTenentId(event.target.value);
    }
    changeTenentId(val) {
        this.setState({idmTenentIdValue: val});
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    makeFormDirty() {
        this.setState({isFormDirty: true});
    }
    componentDidMount() {
        ConfigurationUtils.showLoadMask("", this.context.intl);
        IDMConfigurationStore.on("change", this.refreshFormData);
        FetchHelper.findIDMConfiguration(this.context.intl);
    }
    componentWillUnmount() {
        IDMConfigurationStore.removeListener("change", this.refreshFormData);
    }
    submitTestIDMConnection() {
        if(this.validateFormFields(this.state, this.context.intl)) {
            this.clearErrors();
            FetchHelper.testIDMConnection(this.state, this.context.intl);
        }
    }
    submitIdmConfigSettings() {
        if(this.state.isFormDirty && this.validateFormFields(this.state, this.context.intl)) {
            FetchHelper.saveIDMConfiguration(this.state, this.context.intl);
        }
    }
    validateFormFields(data, intl) {
        let errorsMap = ConfigurationUtils.validateIDMConfiguration(data, intl);
        let flag = true;
        this.clearErrors();
        if(errorsMap["hostname"] !== undefined && errorsMap["hostname"] !== "") {
            this.setState({hostnameError: errorsMap["hostname"]});
            flag = false;
        }
        if(errorsMap["username"] !== undefined && errorsMap["username"] !== "") {
            this.setState({usernameError: errorsMap["username"]});
            flag = false;
        }
        if(errorsMap["password"] !== undefined && errorsMap["password"] !== "") {
            this.setState({passwordError: errorsMap["password"]});
            flag = false;
        }
        if(errorsMap["tenantinfo"] !== undefined && errorsMap["tenantinfo"] !== "") {
            this.setState({tenantinfoError: errorsMap["tenantinfo"]});
            flag = false;
        }
        if(!flag) {
            ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
        }
        return flag;
    }
    clearErrors() {
        this.setState({hostnameError: ""});
        this.setState({usernameError: ""});
        this.setState({passwordError: ""});
        this.setState({tenantinfoError: ""});
    }
    cancelIdmConfigSettings() {
        if(initialState) {
            this.setState(initialState);
            this.refreshFormData();
        }
    }
    refreshFormData() {
        this.clearErrors();
        let idmserver = IDMConfigurationStore.getState().idmserver;
        let username = IDMConfigurationStore.getState().username;
        let tenantinfo = IDMConfigurationStore.getState().tenantinfo;
        this.setState({idmServerUrlValue: idmserver});
        this.setState({idmUserNameValue: username});
        this.setState({idmTenentIdValue: tenantinfo});
        this.setState({isFormDirty: false});
        this.setState({idmPasswordValue: ""});
        ConfigurationUtils.hideLoadMask(this.context.intl);
        initialState = this.state;
    }
    render() {
        let intl = this.context.intl;
        return(
            <div className="config-cont-backgroud">
                <div>
                    <SectionWithTitle title={this.findLabel("configpageIDMtitle")} helpTopic="IDM_Configuration"/>
                    <section pad="none" wrap={false} responsive={false} justify="center">
                        <Form compact={false} id="conf_idm_formId" pad="small" plain={true}>
                            <h2 className="headerConfig">
                                {this.findLabel("configpageIDMConnectionAttributes")}
                            </h2>
                            <FormField style={{ width: '40em' }} error={this.state.hostnameError} required={true} label= {this.findLabel("configpageIDMServerURL")} >
                                <TextInput id="conf_idm_ServerUrlId" value={this.state.idmServerUrlValue} onChange={event =>this.handleChangeIdmServerUrl(event)}/>
                            </FormField>
                            <FormField style={{ width: '40em' }} error={this.state.usernameError} required={true} label= {this.findLabel("configpageIDMUserName")} >
                                <TextInput id="conf_idm_UserNameId" value={this.state.idmUserNameValue} onChange={event =>this.handleChangeUserName(event)}/>
                            </FormField>
                            <FormField style={{ width: '40em' }} error={this.state.passwordError} required={true} label= {this.findLabel("configpageIDMPassword")} >
                                <Password className = "password_icon" id="conf_idm_PasswordId" value={this.state.idmPasswordValue} onChange={this.handleChangePassword}/>
                            </FormField>
                            <FormField style={{ width: '40em' }} error={this.state.tenantinfoError} required={true} label= {this.findLabel("configpageIDMTenentInfo")} >
                                <TextInput id="cconf_idm_TenentId" value={this.state.idmTenentIdValue} onChange={event =>this.handleChangeTenentId(event)}/>
                            </FormField>
                            <div style={{marginLeft: "32%", paddingTop: "2px", float: "left"}}>
                                <div className="button_wrap_secondary">
                                <Button id="conf_idm_testConnetion" label={this.findLabel("configpageLDAPTestConnection")} plain={false} primary={true} onClick={this.submitTestIDMConnection} />
                                </div>
                            </div>
                            <div className="saveBtnStyle button_left_wrap">
                                <div className="button_wrap_primary">
                                <Button id="conf_idm_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitIdmConfigSettings} />
                                </div>
                                <span style={{padding: "5px"}}></span>
                                <div className="button_wrap_negative">
                                <Button id="conf_idm_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelIdmConfigSettings} />
                                </div>
                            </div>
                        </Form>
                    </section>
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// IDMConfiguration.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default IDMConfiguration;