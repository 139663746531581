import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Store from "../../../flux/ReservationManagementStore";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class CreatePool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            poolName: "",
            poolDescription: "",
            poolParentName: "",
            poolNames: []

        }
        this.findLabel = this.findLabel.bind(this);
        this.selectParentName = this.selectParentName.bind(this);
        this.handleChangePoolName = this.handleChangePoolName.bind(this);
        this.handleChangePoolDesc = this.handleChangePoolDesc.bind(this);
        this.handleChangePoolParent = this.handleChangePoolParent.bind(this);
        this.loadPoolNames = this.loadPoolNames.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    selectParentName(event) {
        this.setState({poolParentName: event.suggestion});
    }
    handleChangePoolDesc(event) {
        this.setState({poolDescription: event.target.value});
    }
    handleChangePoolParent(event) {
        this.setState({poolParentName: event.target.value});
        if(event.target.value !== "") {
            FetchHelper.findPoolNames(event.target.value, this.context.intl);
        } else {
            this.setState({poolNames: []});
        }
    }
    handleChangePoolName(event) {
        this.setState({poolName: event.target.value});
    }
    componentDidMount() {
        Store.on("change", this.loadPoolNames);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.loadPoolNames);
    }
    loadPoolNames() {
        if(Store.getState() && Store.getState().actionType === "poolnames") {
            this.setState({poolNames: Store.getState().poolNames});
        }
    }
    render() {
        //let intl = this.context.intl;
        let showHideModalStyle = this.props.showModal ? "create-pool-modal display-block" : "create-pool-modal display-none";
        return(
            <div id="createPoolPopupId" className={showHideModalStyle}>
                <div id="createPoolDivId" className="create-pool-modal">
                    <div className="modal-main panel panel-default">
                        <div className="createPollWindowTitle">
                            {this.findLabel("resv.create.pool.window.title")}
                        </div>                            
                            <div>
                                <section pad="none" wrap={false} responsive={false} justify="center">
                                <Form compact={false} id="create_pool_form_id" pad="small" plain={true}>
                                    <div>
                                        <div>
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("resv.create.pool.name.label")} error={this.props.poolNameError} >
                                                    <TextInput id="create_pool_name_id" autoFocus={true}
                                                        value={this.state.poolName} 
                                                        onChange={event =>this.handleChangePoolName(event)}
                                                    />
                                                </FormField>
                                            </div>
                                        <div>
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("resv.create.pool.desc.label")} error={this.props.poolDescError} >
                                                    <TextInput id="create_pool_desc_id" 
                                                        value={this.state.poolDescription} 
                                                        onChange={event =>this.handleChangePoolDesc(event)}
                                                    />
                                                </FormField>
                                            </div>
                                        <div>
                                                <FormField style={{ width: '40em' }} label= {this.findLabel("resv.create.pool.parent.label")} error={this.props.poolParentError}>
                                                    <TextInput id="create_pool_parent_name_id" 
                                                        value={this.state.poolParentName} 
                                                        onChange={event =>this.handleChangePoolParent(event)}
                                                        suggestions = {this.state.poolNames}
                                                        onSuggestionSelect = {this.selectParentName}
                                                    />
                                                </FormField>
                                            </div>
                                        
                                    </div>
                                </Form>
                                </section>
                        </div>
                        <div className="button_left_wrap">
                                            <div className="button_wrap_primary">
                                                <Button id="create_pool_btn" label={this.findLabel("resv.create.pool.button.label")} plain={false} primary={true} onClick={(event)=>this.props.creatAction(this.state)} />
                                                </div>
                                                <div className="button_wrap_negative">
                                                <Button id="create_pool_canelBtn" label={this.findLabel("label.button.cancel")} plain={false} secondary={true} onClick={this.props.cancelAction} />
                                            </div>
                                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// CreatePool.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default CreatePool;