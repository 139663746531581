import { createIntl, createIntlCache } from 'react-intl';
import locale_en from '@formatjs/intl-listformat/locale-data/en';
import locale_de from '@formatjs/intl-listformat/locale-data/de';
import locale_fr from '@formatjs/intl-listformat/locale-data/fr';
import locale_ja from '@formatjs/intl-listformat/locale-data/ja';
import locale_es from '@formatjs/intl-listformat/locale-data/es';
import locale_zh from '@formatjs/intl-listformat/locale-data/zh';
import messages_de from "../translations/de.json";
import messages_en from "../translations/en.json";
import messages_fr from "../translations/fr.json";
import messages_ja from "../translations/ja.json";
import messages_es from "../translations/es.json";
import messages_zh from "../translations/zh.json";
import country_en from "../translations/country_en.json";
import country_es from "../translations/country_es.json";
import country_de from "../translations/country_de.json";
import country_fr from "../translations/country_fr.json";
import country_ja from "../translations/country_ja.json";
import country_zh from "../translations/country_zh.json";
import state_en from "../translations/state_en.json";
import state_es from "../translations/state_es.json";
import state_de from "../translations/state_de.json";
import state_fr from "../translations/state_fr.json";
import state_ja from "../translations/state_ja.json";
import state_zh from "../translations/state_zh.json";
import string_general from "../defaults/default_string.json";
import {injectIntl, useIntl} from 'react-intl';

//addLocaleData([...locale_en, ...locale_de, ...locale_fr, ...locale_ja, ...locale_es, ...locale_zh]);

const localMessages = {
  'de': messages_de,
  'en': messages_en,
  'fr': messages_fr,
  'es': messages_es,
  'ja': messages_ja,
  'zh': messages_zh
};

const locales = {
  'de': locale_de,
  'en': locale_en,
  'fr': locale_fr,
  'es': locale_es,
  'ja': locale_ja,
  'zh': locale_zh
};

const general_string = {
    'default_string' : string_general
}
const country_msg = {
  'en': country_en,
  'de': country_de,
  'fr': country_fr,
  'es': country_es,
  'ja': country_ja,
  'zh': country_zh
};

const state_msg = {
  'en': state_en,
  'es': state_es,
  'de': state_de,
  'fr': state_fr,
  'ja': state_ja,
  'zh': state_zh
};


var language = navigator.language.split(/[-_]/)[0];
if (localMessages[language] === undefined){
language='en';
}
export var LOCALE = language;
const messages = localMessages[language];
const country = country_msg[language];
const state = state_msg[language];
// const intlProvider = new IntlProvider({locale: language, messages: messages, language:language});
// const { intl } = intlProvider.getChildContext();
// var _Intl = intl;
// var _Intl2 = _interopRequireDefault(_Intl);
// export function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const locale = locales[language]; 
const cache = createIntlCache();
const intl = createIntl({ locale: language, messages : messages }, cache)

export function getLocaleLabel(locale, key){
    //return _Intl2.default.getMessage(locale, key);
    return intl.formatMessage({ id: key});
}

export function getLocaleErrorMessage(code, values){
    var field = "";
    var errorParams = {};
    if(values !== undefined){
        values.entry.map(item => {
            field = "";
            Object.entries(item).map(([key, value]) => {
                if(key === 'key'){
                    field = value;
                } else if(key === 'value' && field !== ''){
                    errorParams[field] = (value.value !== undefined ? value.value: value);
                }
                return "";
            });
            return "";
        });
    }
    //return _Intl2.default.getMessage(intl, code, errorParams);
    return intl.formatMessage({ id: code}, errorParams);
}

export function getLocaleMessage(code, values){
    //return _Intl2.default.getMessage(intl, code, values);
    return intl.formatMessage({ id: code}, values);
}

export function getCountries(){
    const countryOptions = []
    var keys = Object.keys(country);
    keys.forEach(function(key){
        countryOptions.push({label:country[key], value:key})
    });
    return countryOptions;
}

export function getRegions(country){
    const regionOptions = [];
    if(state[country] !== undefined || state[country] !== null){
        var stateSelected = state[country];
        var keys = Object.keys(stateSelected);
        keys.forEach(function(key){
            regionOptions.push({label:stateSelected[key], value:key})
        });
    }
    return regionOptions;
}

export function getDefualtString(code){
    let default_String = general_string.default_string[code];
    return default_String;
}

export function getLocaleCountry(key){
    let default_String = country[key];
    if(default_String !== undefined && default_String !== null ){
        return default_String;
    } else {
       return key;
    }
}

export function getCurrentLocale(){
    return language;
}