import ReduceStore from "./ReduceStore";

class ConfigEntriesStore extends ReduceStore {
  constructor() {
    super();
    this.state = {
      tokens: [],
    };
  }
  reduce(action, state) {
    switch (action.type) {
      case "PROCESS_CONFIG_ENTRIES": {
        return {
          ...state,
        };
      }

      default:
        return state;
    }
  }
}

export default new ConfigEntriesStore();
