import React, { Component } from "react";

import SectionWithTitle from "../../generic/SectionWithTitle";
import CleanupPageWizard from "./subcomponents/CleanupPageWizard";
import * as LocaleUtility from "../../../utils/LocaleUtility";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseCleanup extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 1, secondCount: 1 };
  }

  render() {
    return (
        <div className="config-cont-backgroud">
          <section id="lic_cle_listSec" className="page_section_wrap_1" pad="none" wrap={false} responsive={false} justify="center">
              {/* <SectionWithTitle id="lic_cle_mainSec" 
                title={LocaleUtility.getLocaleLabel(this.context.intl, "label.cleanup.licenses")} 
                sectionContent="" helpTopic="C_License_Cleanup"/> */}
              <CleanupPageWizard />        
          </section>
      </div>
    );
  }
}

// LicenseCleanup.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseCleanup;
