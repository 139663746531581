import Dispatcher from "../flux/Dispatcher";
import * as DateUtility from "./DateUtility";
import * as Util from "./Util";
import * as LocaleUtility from "./LocaleUtility";
import {isNumber} from "./ValidationUtility";
export function processUsageReportList(data, sortIndex) {
    let type = "POPULATE_USAGE_REPORT_LIST";
    let selRows = [];
    let disRows = [];
    let licModelMap = {};
    let featMap = {};
    let cumulateUsageMap = {};
    let featureVersionMap = {};
    let dependableFeatures = [];
    let tokenBaseFeatures = {}
    data.allFeatureList.map( e => {
        if(e.rowSelected) {
            selRows.push(e.featureId);
        }
        if(e.rowDisabled) {
            disRows.push(e.featureId);
        }
        featureVersionMap[e.featureId] = e.idVersionStr;
        licModelMap[e.featureId+":"+e.featureVersion] = e.licenseModel;
        featMap[e.featureId+":"+e.featureVersion] = e.featureDescription;
        tokenBaseFeatures[e.featureId+":"+e.featureVersion] = e.isTokenFeature;
        if(e.lastCumulativeUsage >= 0) {
            cumulateUsageMap[e.featureId+":"+e.featureVersion] = e.lastCumulativeUsage;
        }
        if(e.dependentFeatureList && e.dependentFeatureList.length > 0) {
            processDependableFeatures(e.dependentFeatureList, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, e, dependableFeatures);
        }
        if(e.licenseModel === 6) {
            e.commuteCap = e.issuedCap + e.commuteCap;
            e.issuedCap = 0;
        }
        if(e.licenseModel === 7) {
            e.commuteCap = e.issuedCap + e.commuteCap;
            e.issuedCap = 0;
        }
        return "";
    });
            
    let payload = {
        allFeatureList: data.allFeatureList,
        productNameList: data.productNameList,
        machineTampered: data.machineTampered,
        status: data.status,
        errorDetail: data.errorDetail,
        selRows: selRows,
        disRows: disRows,
        licModelMap: licModelMap,
        featMap: featMap,
        tokenBaseFeatures: tokenBaseFeatures,
        cumulateUsageMap: cumulateUsageMap,
        sortIndex: sortIndex,
        sortOrder: data.pagination.sortOrder,
        pagination: data.pagination,
        featureVersionMap: featureVersionMap,
        dependableFeatures: dependableFeatures

    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function processDependableFeatures(childData, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, parent, dependableFeatures) {
    childData.map( e => {
        e.toolTipKey = "";
        if(e.rowSelected) {
            selRows.push(e.featureId);
        }
        if(e.rowDisabled) {
            disRows.push(e.featureId);
        }
        dependableFeatures.push(e.featureId+":"+e.featureVersion);
        featureVersionMap[e.featureId] = e.idVersionStr;
        licModelMap[e.featureId+":"+e.featureVersion] = e.licenseModel;
        featMap[e.featureId+":"+e.featureVersion] = e.featureDescription;
        if(e.lastCumulativeUsage >= 0) {
            cumulateUsageMap[e.featureId+":"+e.featureVersion] = e.lastCumulativeUsage;
        }
        if(e.dependentFeatureList && e.dependentFeatureList.length > 0) {
            processDependableFeatures(e.dependentFeatureList, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, e);
        }
        e.availableCap = " - "
        if(parent.licenseModel === 6) {
            e.issuedCap = 0;
        }
        if(parent.licenseModel === 7) {
            e.issuedCap = 0;
        }
        if(e.lastCumulativeUsage !== null && e.lastCumulativeUsage !== undefined) {
            e.commuteCap = e.lastCumulativeUsage+"("+e.consumedLicenseCap+")";
        } else {
            if(parent.licenseModel === 6) {
                e.commuteCap = e.issuedCap + e.commuteCap;
            }
            if(parent.licenseModel === 7) {
                e.commuteCap = e.issuedCap + e.commuteCap;
            }
        }
        e.totalCap = " - ";
        e.totalInstalledLicenseCap = " - ";
        return "";
    });
}
export function processUsageReportSearchResults(data, sortIndex) {
    let type = "POPULATE_USAGE_REPORT_SEARCH_RESULTS";
    let clientInfoMap = {};
    let executedByMap = {};
    let defaultIds = [];
    let defDisabledIds = [];
    let enablePurgeBtn = false;
    let enableRevokeBtn = false;
    data.reportSearchList.map(item => {
        let mapKey = item["id"];
        let clientInfoObj = {
            "ipAddress": item["ipAddress"],
            "userName": item["userName"],
            "hostID": item["hostID"],
            "productCode": item["productCode"]+"_"+item["productCurrentVersion"],
            "applicationName": item["applicationName"]
        }
        let executedInfoObj = {
            "checkedOutBy": item["checkedOutBy"],
            "checkedInBy": item["checkedInBy"]
        }
        clientInfoMap[mapKey] = clientInfoObj;
        executedByMap[mapKey] = executedInfoObj;
        Object.entries(item).map(([key, value]) => {
            if(key === "licenseExpiryDate" || key === "licenseStartDate" || key === "lastCheckedInDate") {
               if(value === 0) {
                    item[key] = "";
                } else {
                    item[key] = DateUtility.getFormatedDate(new Date(value));
                }
            } else if(key === "ipAddress" || key === "checkedInBy") {
                item[key] = LocaleUtility.getLocaleMessage("label.click.here")
            }
            if(item["checkedIn"] > 0) {
                enablePurgeBtn = true;
            }
            if(key === "licenseTypeId"){
                if(item[key] !== "201" && item[key] !== "301") {
                    if(item["checkedIn"] > 0) {
                        defDisabledIds.push(item["idRevokedId"]);
                    }
                    if(item["lastCheckedInDate"] === 0 || item["lastCheckedInDate"] === "") {
                        enableRevokeBtn = true;
                    }
                    if(item["revoked"] === "1" && defaultIds.indexOf(item["idRevokedId"]) === -1) {
                        defaultIds.push(item["idRevokedId"]);
                    }
                } else {
                    item["hiddenCheckBox"] = "Y";
                }
            }
            if(key === "units" && item[key] !== undefined) {
                try {
                    item[key] = Util.roundOfValue(item[key]);
                } catch(error) {
                    console.log("Units Conversion Error =>"+error);
                }
            }
            return "";
        });
        return "";
    });
    let payload = {
        reportSearchList: data.reportSearchList,
        clientInfoMap: clientInfoMap,
        executedByMap: executedByMap,
        status: data.status,
        errorDetail: data.errorDetail,
        sortIndex: sortIndex,
        pagination: data.pagination,
        sortOrder: data.pagination.sortOrder,
        defaultIds: defaultIds,
        defDisabledIds: defDisabledIds,
        enablePurgeBtn: enablePurgeBtn,
        enableRevokeBtn: enableRevokeBtn
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processInUseResults(data, sortIndex, actionType) {
    let type = "POPULATE_IN_USE_REPORT_RESULTS";
    if(actionType === "history") {
        type = "POPULATE_HISTORY_REPORT_RESULTS";
     } else if(actionType === "rejected") {
        type = "POPULATE_REJECTED_REPORT_RESULTS";
     }
    let clientInfoMap = {};
    let executedByMap = {};
    let enablePurgeBtn = false;
    let defDisabledIds = [];
    let enableRevokeBtn = false;
    let defaultIds = [];
    let resultRequestMap = {};
    let resusltReservationGroups = {};
    let resultGroupColorPalette = {};
    if(data.requestMap.entry) {
        Object.entries(data.requestMap.entry).map(([index, obj])=> {
            if(obj.value && obj.value !== undefined) {
                resultRequestMap[obj.key] =  obj.value.value;
            } else {
                resultRequestMap[obj.key] =  obj.value;
            }
            return "";
        });
    } else {
        resultRequestMap = data.requestMap;
    }
    if(data.listReservationGroups.entry) {
        Object.entries(data.listReservationGroups.entry).map(([index, obj])=> {
            resusltReservationGroups[obj.key] = obj.value;
            return "";
        });
    } else {
        resusltReservationGroups = data.listReservationGroups;
    }
    if(data.groupColorsMap.entry) {
        Object.entries(data.groupColorsMap.entry).map(([index, obj])=> {
            resultGroupColorPalette[obj.key] = obj.value;
            return "";
        });
    } else {
        resultGroupColorPalette = data.groupColorsMap;
    }
    
    let groupColorsList = resultRequestMap["groupColors"].split(",");
    let groupColorsMap = {};
    if(groupColorsList && groupColorsList.length > 0) {
        groupColorsList.map(obj => {
            let grpColor = obj.split(":");
            groupColorsMap[grpColor[0]] = grpColor[1];
            return "";
        });
    }
    //let groupList = resultRequestMap["groupname"].split(",");
    //let groupWiseCapacity = resultRequestMap["groupwisecapacity"].split(",");
    let inUseJson = data.inuseGraphJson;
    let poolSeries = [];
    if(inUseJson !== undefined && inUseJson !== "") {
        let jsonData = JSON.parse(inUseJson);
        if(jsonData.length > 0) {
            let da = jsonData[0].data;
            if(da !== undefined && da.length > 0) {
                let seg = da[0].segments;
                if(seg !== undefined && seg.length > 0) {
                    Object.entries(seg).map(([index, obj])=> {
                        let val = Number(Util.roundOfValue(obj.value));
                        if(val > 0) {
                            poolSeries.push({"label": obj.label, "value": val, "colorIndex": obj.color, "grpcapacity": Number(Util.roundOfValue(obj.grpcapacity))});
                        }
                        return "";
                    });
                }
            }
        }
    }
    /*let poolSeries = [];
    for(let i=0; i<groupWiseCapacity.length;i++) {
        let val = Number(groupWiseCapacity[i] ? groupWiseCapacity[i] : 0);
        if(groupList[i] !== "") {
            poolSeries.push({"label": groupList[i], "value": val, "colorIndex": groupColorsMap[groupList[i]]});
        }
    }*/
    data.reportSearchList.map(item => {
        let mapKey = item["id"];
        let clientInfoObj = {
            "ipAddress": item["ipAddress"],
            "userName": item["userName"],
            "hostID": item["hostID"],
            "productCode": item["productCode"]+"_"+item["productCurrentVersion"],
            "applicationName": item["applicationName"],
            "clientUniqueId": item["clientUniqueId"],
            "namedUserId": item["namedUserId"],
        }
        let executedInfoObj = {
            "checkedOutBy": item["checkedOutBy"],
            "checkedInBy": item["checkedInBy"]
        }
        clientInfoMap[mapKey] = clientInfoObj;
        executedByMap[mapKey] = executedInfoObj;
        if(actionType === "history") {
            defDisabledIds.push(item["idRevokedId"]);
        }
        Object.entries(item).map(([key, value]) => {
            if(key === "licenseExpiryDate" || key === "licenseStartDate" || key === "lastCheckedInDate"
                || key === "requestedStartDate" || key === "requestedDuration") {
                if(value === 0) {
                    item[key] = "";
                } else {
                    if(actionType === "rejected"){
                        item[key] = DateUtility.getFormatedDate(new Date(value*1000));
                    } else {
                        item[key] = DateUtility.getFormatedDate(new Date(value));
                    }
                }
            } else if(key === "ipAddress" || key === "checkedInBy") {
                item[key] = LocaleUtility.getLocaleMessage("label.click.here");
            } else if(key === "checkedoutrefcount" && item[key] === 0) {
                item[key] = item[key] + 0;
            } else if(key === "checkedOutBy") {
                item[key] = LocaleUtility.getLocaleMessage("label.click.here");
            } else if(key === "units" && item[key] !== undefined) {
                try {
                    let v = Util.roundOfValue(item[key]);
                    if(v===0){
                        item[key]="";  
                    }else{
                        item[key] = v;
                    }
                   
                } catch(error) {
                    console.log("Units Conversion Error =>"+error);
                }
            } else if(key === "licenseType") {
                // for InUse and History page
                if(item[key] === 'Live'){
                    item[key] = LocaleUtility.getLocaleMessage("label.license.type.live");
                }else if(item[key] === 'Commuter'){
                    item[key] = LocaleUtility.getLocaleMessage("label.license.type.commuter");
                }
            }  else if(key === "rejectedCheckoutType" ){
                // for Rejected page
                if(item[key] === 'Live'){
                    item[key] = LocaleUtility.getLocaleMessage("label.license.type.live");
                }else if(item[key] === 'Commuter'){
                    item[key] = LocaleUtility.getLocaleMessage("label.license.type.commuter");
                }
            } else if(key === "groupsId"){
                if(item[key] === 'Common Pool'){
                    item[key] = LocaleUtility.getLocaleMessage("label.common.pool");
                }
            }
            if(item["checkedIn"] > 0) {
                enablePurgeBtn = true;
            }
            if(actionType !== "history" && key === "licenseTypeId" && item[key] !== "201" && item[key] !== "301") {
                if(item["checkedIn"] > 0 && item["revoked"] === "1") {
                    defDisabledIds.push(item["idRevokedId"]);
                }
                enableRevokeBtn = true;
            } else if(actionType !== "history" && actionType !== "rejected" && key === "licenseTypeId" && (item[key] === "201" || item[key] === "301")) {
                defDisabledIds.push(item["idRevokedId"]);
            }
            if(item["revoked"] === "1" && defaultIds.indexOf(item["idRevokedId"]) === -1) {
                defaultIds.push(item["idRevokedId"]);
            }
            if(key==="valid"  && item[key] ===2){
                 defDisabledIds.push(item["idRevokedId"]);
            }

            
            return "";
        });
        return "";
    });
    let payload = {
        reportSearchList: data.reportSearchList,
        requestMap: resultRequestMap,
        listReservationGroups: resusltReservationGroups,
        clientInfoMap: clientInfoMap,
        executedByMap: executedByMap,
        status: data.status,
        errorDetail: data.errorDetail,
        sortIndex: sortIndex,
        pagination: data.pagination,
        sortOrder: data.pagination.sortOrder,
        exportHistoryRecordSize: data.exportHistoryRecordSize,
        enablePurgeBtn: enablePurgeBtn,
        defDisabledIds: defDisabledIds,
        enableRevokeBtn: enableRevokeBtn,
        defaultIds: defaultIds,
        groupColorsMap: groupColorsMap,
        poolSeries: poolSeries,
        groupColorPalette: resultGroupColorPalette,
        data: data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function monthNames() {
    let monthArray = [];
    monthArray[1] = LocaleUtility.getLocaleMessage("short.month.name.Jan");
    monthArray[2] = LocaleUtility.getLocaleMessage("short.month.name.Feb");
    monthArray[3] = LocaleUtility.getLocaleMessage("short.month.name.Mar");
    monthArray[4] = LocaleUtility.getLocaleMessage("short.month.name.Apr");
    monthArray[5] = LocaleUtility.getLocaleMessage("short.month.name.May");
    monthArray[6] = LocaleUtility.getLocaleMessage("short.month.name.Jun");
    monthArray[7] = LocaleUtility.getLocaleMessage("short.month.name.Jul");
    monthArray[8] = LocaleUtility.getLocaleMessage("short.month.name.Aug");
    monthArray[9] = LocaleUtility.getLocaleMessage("short.month.name.Sep");
    monthArray[10] = LocaleUtility.getLocaleMessage("short.month.name.Oct");
    monthArray[11] = LocaleUtility.getLocaleMessage("short.month.name.Nov");
    monthArray[12] = LocaleUtility.getLocaleMessage("short.month.name.Dec");
    return monthArray;
}
function monthNameArr() {
    let monthArray = [];
    monthArray[1] = "Jan";
    monthArray[2] = "Feb";
    monthArray[3] = "Mar";
    monthArray[4] = "Apr";
    monthArray[5] = "May";
    monthArray[6] = "Jun";
    monthArray[7] = "Jul";
    monthArray[8] = "Aug";
    monthArray[9] = "Sep";
    monthArray[10] = "Oct";
    monthArray[11] = "Nov";
    monthArray[12] = "Dec";
    return monthArray;
}
export function processHistoryGraphResults(data, maximumChecked, groupIdValue, poolColorIndexById, usageStartDate, usageEndDate, licensetotalcapacity, reservedcapacity) {
    let type = "POPULATE_USE_REPORT_HISTORY_GRAPH";
    let monthArray = monthNames();
    let items = [];
    let groupId = Number(groupIdValue);
    let maximumCapacity = 0;
    let averageCapacity = 0;
    let rejectedCapacity = 0;
    let checkViewGraph = 0;
    let start = new Date(usageStartDate);
    let end = new Date(usageEndDate);
    var oneDay = 1000*60*60*24;
    var daysRange = end.getTime() - start.getTime();
    var dayscount =  Math.round(daysRange/oneDay);
    let toolTipMap = {};
    let mValue = Number(reservedcapacity);
    let resvCapacity = mValue;
    let yTickValue = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    let rangeAverage = 0;
    let viewType = "day";
    let newUsageData = [];

    if(data && data.length > 0) {
        let maxCap = 0;
        let maxAverage = 0;
        let avgCap = 0;
        let rejCap = 0;
        let noOfDays = data.length;
        let usageData = [];
        let rejectedData = [];
        let sumRejectedCap = 0;
        data.map(el => {
            if(el.groups.length > 0) {
                el.groups.map(g => {
                    if(g.maxCapacity !== 0 && g.maxCapacity > maxCap) {
                        maxCap = g.maxCapacity;
                    }
                    if(g.maxCapacity !== 0) {
                        maxAverage = maxAverage + g.maxCapacity;
                    }
                    if(g.rejectedCapacity !== 0 && g.rejectedCapacity > rejCap) {
                        rejCap = g.rejectedCapacity;
                    }
                    if(g.rangeAverageCapacity !== undefined && g.rangeAverageCapacity > 0) {
                        rangeAverage = g.rangeAverageCapacity;
                    }
                    return "";
                });
            }
            return "";
        });
        if(mValue < maxCap || (mValue === 0 && maxCap !== 0)) {
            mValue = maxCap;
        } else if(mValue === 0 && rejCap !== 0) {
            mValue = rejCap;
        }
        /*if(mValue > 100) {
            let v = Math.round(mValue/10);
            yTickValue = [];
            for(let i=1;i<=10;i++) {
                yTickValue.push(v*i);
            }
        }*/
        data.map(el => {
            let value = 0;
            let gAvg = 0
            let rCap = 0;
            let users;
            if(el.groups.length > 0) {
                el.groups.map(g => {
                    if(g.maxCapacity !== 0 && g.maxCapacity > value) {
                        value = g.maxCapacity;
                    }
                    if(g.average !== 0) {
                        gAvg = gAvg + g.average;
                    }
                    if(g.rejectedCapacity !== 0) {
                        rCap = rCap + g.rejectedCapacity;
                    }
                    users = g.users;
                    return "";
                });
                avgCap = avgCap + gAvg;
            }
            //let meterSerious = [];
            //let barValue = value + rCap;
            sumRejectedCap = sumRejectedCap + rCap;
            let localeFlag = ["zh", "ja"].includes(LocaleUtility.getCurrentLocale());
            let xAxisLabel = localeFlag == true ? monthArray[el.month]+" "+ el.day: el.day+" "+monthArray[el.month];
            if(dayscount === 1){
                xAxisLabel = localeFlag == true ? monthArray[el.month]+" "+ el.day: el.day+" "+monthArray[el.month];
            } else if (dayscount <= 32) {
                xAxisLabel = localeFlag == true ? monthArray[el.month]+" "+ el.day: el.day+" "+monthArray[el.month];
            } else if (dayscount > 32 && dayscount < 219) {
                xAxisLabel = localeFlag == true ? monthArray[el.month]+" "+ el.day: el.day+" "+monthArray[el.month];
                viewType = "week";
            } else if (dayscount >= 219 && dayscount < 931) {
                xAxisLabel = localeFlag == true ? el.year+" "+ monthArray[el.month]: monthArray[el.month]+" "+el.year;
                viewType = "month";
            } else {
                xAxisLabel = el.year+"";
                viewType = "year";
            }
            let maxCheckValue = mValue;
            if(resvCapacity > 0) {
                maxCheckValue = resvCapacity;
            }
            let itemObj = {};
            if(!maximumChecked) {
                let len = el.groups.length;
                value = Math.round((gAvg)/len*10)/10;
                let usageValue = value;
                if(value > maxCheckValue) {
                    let extendValue = findExtentedValue(usageValue);
                    usageValue = maxCheckValue + extendValue;
                }
                if(groupId > 0) {
                    usageData.push({x: xAxisLabel, y: usageValue})
                } else {
                    usageData.push({x: xAxisLabel, y: usageValue})
                }
                itemObj = {label: xAxisLabel, max: usageValue};
                //newUsageData.push({label: xAxisLabel, max: usageValue});

            } else if(Number(groupId) !== 0) {
                let usageValue = value;
                if(value > maxCheckValue) {
                    let extendValue = findExtentedValue(usageValue);
                    usageValue = maxCheckValue + extendValue;
                }
                usageData.push({x: xAxisLabel, y: usageValue});
                itemObj = {label: xAxisLabel, max: usageValue};
                //newUsageData.push({label: xAxisLabel, max: usageValue});
            } else {
                let usageValue = value;
                if(value > maxCheckValue) {
                    let extendValue = findExtentedValue(usageValue);
                    usageValue = maxCheckValue + extendValue;
                }
                usageData.push({x: xAxisLabel, y: usageValue});
                itemObj.label = xAxisLabel;  itemObj.max = usageValue;
                let totalVal = rCap + usageValue;
                if(rCap > 0 && totalVal > resvCapacity) {
                    let remainRej = totalVal - resvCapacity;
                    if(remainRej > 5) {
                        remainRej = findExtentedValue(remainRej);
                    }
                    let resRejCap = resvCapacity + remainRej;
                    rejectedData.push({x: xAxisLabel, y: resRejCap});
                    itemObj.reject = resRejCap;
                } else {
                    rejectedData.push({x: xAxisLabel, y: rCap});
                    itemObj.reject = rCap;
                }
                //newUsageData.push(itemObj);
            }
            let toolTipValue = LocaleUtility.getLocaleMessage("reportpage.usage")+": "+value+"";
            toolTipValue = toolTipValue +", "+LocaleUtility.getLocaleMessage("usage.graph.history.average.label")+": "+Math.round(gAvg*10)/10+"";
            if(groupId === 0) {
                toolTipValue = toolTipValue + ", "+LocaleUtility.getLocaleMessage("reportpage.rejected")+": "+rCap;
            }
            toolTipValue = toolTipValue +", "+LocaleUtility.getLocaleMessage("reportpage.users")+": "+users+"";
            toolTipMap[xAxisLabel] = toolTipValue;
            if(value > checkViewGraph) {
                checkViewGraph = value;
            }
            if(users !== undefined)
                itemObj.toolTipValue = toolTipValue;
            newUsageData.push(itemObj);
            return "";
        });
        if(checkViewGraph !== 0) {
            let averageVal = Math.round((maxAverage)/noOfDays*10)/10;
            maximumCapacity = maxCap;
            averageCapacity = averageVal;
            rejectedCapacity = sumRejectedCap;
            if(usageData.length > 0) {
                items.push({label: LocaleUtility.getLocaleMessage("reportpage.usage"), values: usageData});
                if(rejectedData.length > 0) {
                    items.push({label: LocaleUtility.getLocaleMessage("reportpage.rejected"), values: rejectedData});
                }
            }
        } else {
            items = [];
        }
    }
    let payload = {
        results: newUsageData, //items,
        maximumCapacity: maximumCapacity,
        averageCapacity: averageCapacity,
        rejectedCapacity: rejectedCapacity,
        poolColorIndexById: poolColorIndexById,
        toolTipMap: toolTipMap,
        yTickValue: yTickValue,
        yAxisMaxValue: mValue,
        rangeAverage: Math.round(rangeAverage*10)/10,
        viewType: viewType
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function findExtentedValue(value) {
    let val = Math.round(value/20);
    if(val > 15) {
        return findExtentedValue(val);
    } else {
        val = 5;
    }
    return val;
}
function calulateDefaultMaxValue(rCap, resRejCap) {
    let remainRej = Math.round((rCap - resRejCap)/20);
    if(remainRej > 25) {
        return calulateDefaultMaxValue(remainRej, resRejCap)
    }
    return remainRej;
}
export function processHistoryGraphResultsForRejected(data, maximumChecked, groupId, poolColorIndexById) {
    let type = "POPULATE_USE_REPORT_HISTORY_GRAPH";
    let rejectedCapacity = 0;
    if(data && data.length > 0) {
        let rejCap = 0;
        data.map(el => {
            if(el.groups.length > 0) {
                el.groups.map(g => {
                    if(g.rejectedCapacity !== 0 && g.rejectedCapacity > rejCap) {
                        rejCap = g.rejectedCapacity;
                    }
                    return "";
                });
            }
            return "";
        });
        rejectedCapacity = rejCap;
    }
    let payload = {
        rejectedCapacity: rejectedCapacity
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processConsumptionResults(data, sortIndex) {
    let type = "POPULATE_CONSUMPTION_REPORT_RESULTS";
    let licenseUsageMap = {};
    data.cumulativeUsageList.map(item => {
        let usageObj = {
            "operation": item.operation,
            "repUsage": item.repUsage
        }
        licenseUsageMap[item.id] = usageObj;
        Object.entries(item).map(([key, value]) => {
            if(key === "reportedUsageTime") {
                if(value === 0) {
                    item[key] = "";
                } else {
                    item[key] = DateUtility.getFormatedDate(new Date(value));
                }
            } else if(key === "tenantId") {
                item[key] = item["tenentUserName"];
            } else if(key === "isDependent") {
                if(!item[key]) {
                    if(item["mapddfeatureId"]) {
                        item["mapddfeatureInfo"] = item["mapdfeatureDescription"]+"("+item["mapddfeatureId"]+":"+item["mapdfeatureVersion"]+")";
                        item["repCumulativeUsageInfo"] = item["repCumulativeUsage"]
                    } else {
                        item["mapddfeatureInfo"] = "";
                        item["repCumulativeUsageInfo"] = "";
                    }
                } else {
                    item["mapddfeatureInfo"] = "";
                    item["repCumulativeUsageInfo"] = "";
                }
            }
            return "";
        });
        return "";
    });
    let payload = {
        cumulativeUsageList: data.cumulativeUsageList,
        status: data.status,
        errorDetail: data.errorDetail,
        sortIndex: sortIndex,
        pagination: data.pagination,
        sortOrder: data.pagination.sortOrder,
        exportHistoryRecordSize: data.exportHistoryRecordSize,
        licenseUsageMap: licenseUsageMap
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processConsumptionGraphResults(data, usageStartDate, usageEndDate,isLicCapReq) {
    let type = "POPULATE_CONSUMPTION_REPORT_GRAPH_RESULTS";
    let start = new Date(usageStartDate);
    let end = new Date(usageEndDate);
    var oneDay = 1000*60*60*24;
    var daysRange = end.getTime() - start.getTime();
    var dayscount =  Math.round(daysRange/oneDay)+1;
    let graphData = [];
    let results = data.peakUsageInfos;
    let tenantsData = [];
    let tenantCheck = [];
    tenantsData.push({"label": LocaleUtility.getLocaleMessage("label.select.all"), "value": 0});
    tenantCheck.push(LocaleUtility.getDefualtString("All"));
    let count = 1;
    let xAxisFormat = "%b %d";
    let xAxisLabel = LocaleUtility.getDefualtString("Day");
    let lineColors = [];
    let visualFilters = [];
    var colors = getColosList();
    let colorsMap = getColorsMap();
    let graphDataMap = {};
    let graphDataColorMap = {};
    if(dayscount === 1){
        xAxisLabel = LocaleUtility.getDefualtString("Hour");
        xAxisFormat = "%I %p";
    } else if (dayscount <= 31) {
        xAxisLabel = LocaleUtility.getDefualtString("Day");
        xAxisFormat = "%b %d";
    } else if (dayscount > 31 && dayscount < 218) {
        xAxisLabel = LocaleUtility.getDefualtString("Week");
        xAxisFormat = "%b %d";
        var day = start.getDay();
	    start= new Date(start.getFullYear(), start.getMonth(), start.getDate() - (day === 0?0:day));
    } else if (dayscount >= 218 && dayscount < 931) {
        xAxisLabel = LocaleUtility.getDefualtString("Months");
        xAxisFormat = "%b %Y";
        start.setMonth(start.getMonth(), 1);
    } else {
        xAxisLabel = LocaleUtility.getDefualtString("Year");
        xAxisFormat = "%Y";
        start = new Date(start.getFullYear(), 0, 1);
    }
    let totalCap = 0;
    let licenseCapLine = [];
    let featIdsList = [];
    let totalCapDate = "";
    let newLineData = [];
    let index=0;
    results.map(item => {
        index++;
        let peakInfoList = item.PeakUsageInfoBeanList;
        let tenantList = item.tenantInfoList;
        let featDesc = item.featureDesc;
        let featureId = item.featureId;
        let lineData = [];
        let graphObj = {};
        peakInfoList.map(e => {
            graphObj = {};
            let d = new Date(e.year, (e.month-1), e.day);
            if(dayscount === 1) {
                d = new Date(e.year, (e.month-1), e.day, e.hour, e.minute);
            }
            lineData.push({x: d, y: e.peakUsage});
            graphObj.label = d.getTime();
            graphObj[featureId] = e.peakUsage;
            newLineData.push(graphObj);
            if(e.totalCapacity > totalCap) {
                totalCap = e.totalCapacity;
                totalCapDate = d;
            }
            licenseCapLine.push({x: d, y: totalCap});
            return "";
        });

        if(totalCapDate !== "") {
            //licenseCapLine.push({x: totalCapDate, y: totalCap});
        }
        if(lineData.length > 0 && featIdsList.indexOf(featureId) === -1) {
            graphData.push({label: featDesc, values: lineData});
            graphDataMap[featureId] = {label: featDesc, values: lineData};
            graphDataColorMap[featureId] = colorsMap[colors[count]];
            lineColors.push(colorsMap[colors[count]]);
            visualFilters.push({label: featDesc, value: featureId, color: colorsMap[colors[count]]});
            if(count === 0) {
                count = 1;
            } else {
                count++;
            }
            featIdsList.push(featureId);
        }
        if(tenantList) {
            tenantList.map(e => {
                if(tenantCheck.indexOf(e.userName) === -1) {
                    if(e.userName === "UNKNOWN") {
                        tenantsData.push({"label": "COMMON", "value": e.tenantId});
                    } else {
                        tenantsData.push({"label": e.userName, "value": e.tenantId});
                    }
                    tenantCheck.push(e.userName);
                }
                return "";
            });
        }
        return "";
    });
    let isDepedableFeature = false;
    let isDepFromLocal = localStorage.getItem("isDependableFeature");
    if(isDepFromLocal !== undefined && isDepFromLocal === "true") {
        isDepedableFeature = true;
    }
    if(isLicCapReq && licenseCapLine.length > 0 && !isDepedableFeature) {
        lineColors.push("#e5004c");
        graphData.push({label: LocaleUtility.getLocaleMessage("reportpage.license.capacity"), values: licenseCapLine});
        graphDataMap[0] = {label: LocaleUtility.getLocaleMessage("reportpage.license.capacity"), values: licenseCapLine};
        graphDataColorMap[0] = "#e5004c";
        visualFilters.push({label: LocaleUtility.getLocaleMessage("reportpage.license.capacity"), value: 0, color: "#e5004c"});
    }
    var minDate = start.setHours(0);
    var maxDate = end.setHours(23);
    if(dayscount===1){
    	maxDate= end.setHours(24);
	}
    let payload = {
        graphData: newLineData, //graphData,
        prodGraphData: graphData,
        tenantsData: tenantsData,
        xAxisFormat: xAxisFormat,
        xAxisLabel: xAxisLabel,
        lineColors: lineColors,
        visualFilters: visualFilters,
        graphDataMap: graphDataMap,
        graphDataColorMap: graphDataColorMap,
        graphStartDate: minDate,
        graphEndDate: maxDate,
        dayscount: dayscount,
        yAxisMaxValue: totalCap
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processManageCommuterSave() {
    let type = "PROCESS_MANAGE_COMMUTER_SAVE";
    let payload = {
        actionType: "managecommuter"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processUpdateCommuterDetails(data) {
    let type = "PROCESS_UPDATE_COMMUTER_DETAILS";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processProdutNamesDropDown(data) {
    let type = "PROCESS_PRODUCT_NAME_LIST_FOR_DROP_DOWN";
    let payload = {
        data : data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function resetProductFeaturesGrid() {
    let type = "PROCESS_REST_PRODUCT_FEATURES_GRID";
    let payload = {
        actionType: "resetProductFeaturesGrid"
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
export function processGetAllProductNames(data) {
    let type = "PROCESS_PRODUCT_NAME_LIST_FOR_SEARCH";
    let payload = {
        data: data
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function getColorsMap() {
    let colorsMap = [];
    colorsMap["critical"] = "#e5004c";
    colorsMap["error"] = "#f48b34";
    colorsMap["warning"] = "#ffc002";
    colorsMap["ok"] = "#1aac60";
    colorsMap["unknown"] = "#c6179d";
    colorsMap["brand"] = "#0073e7";
    colorsMap["highlight-1"] = "#3939c6";
    colorsMap["shade-1"] = "#271782";
    colorsMap["accent-2"] = "#7425ad";
    colorsMap["neutral-1"] = "#656668";
    colorsMap["statushighlight-1"] = "#ff454f";
    colorsMap["statusshade-1"] = "#b21646";
    colorsMap["highlight-2"] = "#00abf3";
    colorsMap["shade-2"] = "#014272";
    colorsMap["accent-3"] = "#231ca5";
    return colorsMap;
}
function getColosList() {
    var colors = [];
    colors[1] = "accent-3";
    colors[2] = "error";
    colors[3] = "warning";
    colors[4] = "ok";
    colors[5] = "unknown";
    colors[6] = "brand";
    colors[7] = "highlight-1";
    colors[8] = "shade-1";
    colors[9] = "accent-2";
    colors[10] = "neutral-1";
    colors[11] = "statushighlight-1";
    colors[12] = "statusshade-1";
    colors[13] = "highlight-2";
    colors[14] = "shade-2";
    colors[15] = "critical";
    return colors;
}

export function processInstanceResults(data, sortIndex, resetFilter) {
    let type = "POPULATE_INSTANCE_REPORT_RESULTS";
    let payload = {}
    if(sortIndex !== undefined){
        let licenseUsageMap = {};
        data.instanceUsageList.map(item => {
            if (item.startDate > 0 && item.startDate !== 900703)
                item.startDate = DateUtility.getFormatedDate(new Date(item.startDate));
            else item.startDate = "Perpetual";
            if (item.expiryDate > 0 && item.expiryDate !== 900703)
                item.expiryDate = DateUtility.getFormatedDate(new Date(item.expiryDate));
            else item.expiryDate = "Perpetual";
            item.featureInfo = item.featureDesc+" ("+item.featureId+")";
            licenseUsageMap[item.instanceId] = 
                    {   "featureId":item.featureId,
                        "featureDesc":item.featureDesc,
                        "cumulativeUsage":item.cumulativeUsage,
                        "licenseModel":item.licenseModel,
                        "instanceId":item.clientUniqueId,
                        "startDate":item.startDate,
                        "expDate":item.expiryDate,
                        "avlCapacity":item.availableUnits,
                        "totalCapacity":item.capacity,
                        "status": item.status
                    }
            return "";
        });
        data.pagination.required = true;
        payload = {
            resetFilter: resetFilter,
            instanceUsageList: data.instanceUsageList,
            licenseUsageMap: licenseUsageMap,
            sortIndex: sortIndex,
            pagination: data.pagination,
            sortOrder: data.pagination.sortOrder
        };
    } else {
        type = "POPULATE_INSTANCE_DETAIL";
        let detail = {};
        data.instanceUsageList.map(item => {
            if (item.startDate > 0 && item.startDate !== 900703)
                item.startDate = DateUtility.getFormatedDate(new Date(item.startDate));
            else item.startDate = "Perpetual";
            if (item.expiryDate > 0 && item.expiryDate !== 900703)
                item.expiryDate = DateUtility.getFormatedDate(new Date(item.expiryDate));
            else item.expiryDate = "Perpetual";
            item.featureInfo = item.featureDesc+" ("+item.featureId+")";
            detail = 
                    {   "featureId":item.featureId,
                        "featureDesc":item.featureDesc,
                        "cumulativeUsage":item.cumulativeUsage,
                        "licenseModel":item.licenseModel,
                        "instanceId":item.clientUniqueId,
                        "startDate":item.startDate,
                        "expDate":item.expiryDate,
                        "avlCapacity":item.availableUnits,
                        "totalCapacity":item.capacity
                    }
            return "";
        });
        
        payload = {
            instanceUsageList: detail,
        };
    }

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function processGenericUsage(data, showFields){
    var usage = [], fieldMap = new Map();
    var usageHeaders = [], headers = [], filters = [], attFilterOptions = [], repAggr =[];
    let type = "POPULATE_USAGE_RESULTS";
    var payload, sortBy, contextFlag = false;
    var timePeriod = [ "year", "week", "month", "day", "hour"];
    var aggSet = [ "count", "sum", "max", "min", "avg"];
    let monthArray = monthNameArr();

    if(data.usage !== undefined){
        data.usage.map(item => {
            let usageMap = {}, time = 0;
            for(let i=0;i<item.length;i++) {
                if(item[i].dataType === 'datetime' && isNumber(item[i].value)){
                    time = Number(item[i].value);
                    usageMap[item[i].name] = DateUtility.getFormatedDate(new Date(time));
                } else if(item[i].label === 'Month'){
                    usageMap[item[i].name] = monthArray[Number(item[i].value)]; 
                } else {
                    usageMap[item[i].name] = item[i].value;
                }
                if(!filters.some(v => (item[i].name === v))){
                    contextFlag = false;
                    fieldMap.set(item[i].name, {"name":item[i].name, "label":item[i].label, "dataType":item[i].dataType, "showOrder": item[i].showOrder});
                    if(!timePeriod.some(v => (item[i].name === v))){
                        attFilterOptions.push({value: item[i].name, label: item[i].name});
                        headers.push(item[i].name);
                        if(item[i].showOrder === 0){
                            sortBy = item[i].name;
                        }
                        contextFlag = true;
                    }
                    filters.push(item[i].name);
                    if(item[i].aggregator !== undefined && aggSet.some(v => (item[i].aggregator === v))){
                        if(item[i].aggregator === 'count'){
                            let countLabel = item[i].name.replace('Count',"");
                            let obj = fieldMap.get(countLabel);
                            repAggr.push({"name": item[i].name, "aggregator": item[i].aggregator, 
                                "aggIndex":item[i].showOrder, "countAttr":countLabel, "countAttrIndex": obj.showOrder});
                        } else {
                            repAggr.push({"name": item[i].name, "aggregator": item[i].aggregator, "aggIndex":item[i].showOrder});
                        }
                        usageHeaders.push({columnName: item[i].name, headerLabel: (item[i].label !== null?item[i].label:item[i].name), sort: false, enableCxtMenu:contextFlag});
                    } else {
                        usageHeaders.push({columnName: item[i].name, headerLabel: (item[i].label !== null?item[i].label:item[i].name), sort: true, enableCxtMenu:contextFlag});
                    }
                }
            }
            usage.push(usageMap);
            return "";
        });

        payload = {
            usageList: usage,
            usageHeaders: usageHeaders,
            headers: headers,
            attFilterOptions: attFilterOptions,
            fieldMap: fieldMap,
            repAggr: repAggr,
            showFields: showFields,
            sortBy: sortBy
        };
    }

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

    type = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
}

export function processUsageReport(data, product){
    var fieldMap = new Map(), fieldMapSet;
    var reportFilterOptions = [], attFilterOptions = [];
    let type = "POPULATE_USAGE_REPORT";
    var payload = {};

    if(data.report !== undefined){
        data.report.map(item=> {
            attFilterOptions = [];
            fieldMapSet = new Map();
            reportFilterOptions.push({value: item.reportId, label: item.reportName});
            item.fields.map(field => {
                attFilterOptions[field.showOrder] = field.name;
                fieldMapSet.set(field.name, field);
                return "";
            });
            fieldMap.set(item.reportId, {"report":item, "fieldMap": fieldMapSet, 
                            "fields": attFilterOptions});
            return "";
        });

        payload = {
            reportOptions: reportFilterOptions,
            fieldMap: fieldMap,
            showFields: "loadreport"
        };
    }
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function getCustomMetric(data){
    let type = "CUSTOM_METRIC_LIST_FETCH_COMPLETE", payload;
    if (data.entityList !== undefined && data.entityList.value.length > 0) {
        payload = {
            customMetric: data.entityList.value,
        };
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    } 
}

export function getCustomPropValue(data){
    let type = "FIELD_VALUE_FETCH_COMPLETE", payload;
    if (data.entityList !== undefined && data.entityList.value !== undefined &&
        data.entityList.value.length > 0) {
        payload = {
            fieldVal: data.entityList.value,
        };
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    } 
}
