import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import { TextInput } from 'grommet/components/TextInput';
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { CheckBox } from "grommet/components/CheckBox";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class AddTenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            name: "",
            firstName: "",
            lastName: "",
            email: "",
            schemaName: "",
            nameError: "",
            schemaError: "", 
            emailError: "", 
            firstError: "",
            lastError: "",
            validationExists: false,
            isFormDirty: false,
            generateDataSource: false,
            status: 1

        }
        this.findLabel = this.findLabel.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.continueCreateAction = this.continueCreateAction.bind(this);
        this.valideFieldsFn = this.valideFieldsFn.bind(this);
        this.onConfirmSaveAction = this.onConfirmSaveAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        if(this.props.tenantObj !== undefined) {
            let object = this.props.tenantObj;
            this.setState({id: object.id, name: object.name, firstName: object.firstName,
            lastName: object.lastName, email: object.email, schemaName: object.schemaName, status: object.status});
        }
    }
    handleChangeValue(fieldType, event) {
        if(fieldType === "gs") {
            this.setState({generateDataSource: event.target.checked});
        } else {
            let value = event.target.value;
            this.clearErrors();
            this.valideFieldsFn(fieldType, value);
        }
        this.setState({isFormDirty: true});
    }
    valideFieldsFn(fieldType, value) {
        let errorExists = false;
        //let intl = this.context.intl;
        let msg = "";
        if(fieldType === "name") {
            this.setState({name: value});
            if(value === undefined || value === '') {
                msg = this.findLabel("value.cannot.be.empty");
                errorExists = true;
            } else if (value !== undefined && !value.match(/^[0-9a-zA-Z]+$/)){
                msg = this.findLabel("value.shouldb.be.alphanumeric");
                errorExists = true;
            } else if(value !== undefined && value.length > 50) {
                msg = LocaleUtility.getLocaleMessage("value.cannot.be.more",{limit: 50});
                errorExists = true;
            } else if(value === "0"){
                msg =LocaleUtility.getLocaleMessage("value.cannot.be.zero",{field: this.findLabel("multi.tenant.name")});
                errorExists = true;
            }
            if(errorExists) {
                this.setState({nameError: msg});
            }
        } else if(fieldType === "fn") {
            this.setState({firstName: value});
            if(value === undefined || value === '') {
                msg = this.findLabel("value.cannot.be.empty");
                errorExists = true;
            } else if(value !== undefined && value.length > 50) {
                msg = LocaleUtility.getLocaleMessage("value.cannot.be.more",{limit: 50});
                errorExists = true;
            } else if(value === "0"){
                msg =LocaleUtility.getLocaleMessage("value.cannot.be.zero",{field: this.findLabel("multi.tenant.first.name")});
                errorExists = true;
            }
            if(errorExists) {
                this.setState({firstError: msg});
            }
        } else if(fieldType === "ln") {
            this.setState({lastName: value});
            if(value !== "") {
                if(value !== undefined && value.length > 50) {
                    msg = LocaleUtility.getLocaleMessage("value.cannot.be.more",{limit: 50});
                    errorExists = true;
                }
            }
            if(errorExists) {
                this.setState({lastError: msg});
            }
        } else if(fieldType === "email") {
            this.setState({email: value});
            if(value === undefined || value === '') {
                msg = this.findLabel("value.cannot.be.empty");
                errorExists = true;
            } else if(value !== undefined && value.length > 50) {
                msg = LocaleUtility.getLocaleMessage("value.cannot.be.more",{limit: 50});
                errorExists = true;
            } else if(!ValidationUtility.validateEmail(value)) {
                msg = this.findLabel("configpageAlert6");
                errorExists = true;
            }
            if(errorExists) {
                this.setState({emailError: msg});
            }
        } else if(fieldType === "sn") {
            this.setState({schemaName: value});
            if(value !== "") {
                if (!value.match(/^[0-9a-zA-Z]+$/)){
                    msg = this.findLabel("value.shouldb.be.alphanumeric");
                    errorExists = true;
                } else if(value !== undefined && value.length > 50) {
                    msg = LocaleUtility.getLocaleMessage("value.cannot.be.more",{limit: 50});
                    errorExists = true;
                } else if(value === "0"){
                msg =LocaleUtility.getLocaleMessage("value.cannot.be.zero",{field: this.findLabel("multi.tenant.schema.name")});
                errorExists = true;
            }
            }
            if(errorExists) {
                this.setState({schemaError: msg});
            }
        }
        this.setState({validationExists: errorExists});
        return errorExists;
    }
    clearErrors() {
        this.setState({nameError: "", schemaError: "", emailError: "", firstError: "", lastError: ""})
    }
    continueCreateAction() {
        let errorExists = this.state.validationExists;
        if(!errorExists) {
            errorExists = this.valideFieldsFn("name", this.state.name);
        }
        if(!errorExists) {
            errorExists = this.valideFieldsFn("fn", this.state.firstName);
        }
        if(!errorExists) {
            errorExists = this.valideFieldsFn("ln", this.state.lastName);
        }
        if(!errorExists) {
            errorExists = this.valideFieldsFn("email", this.state.email);
        }
        if(!errorExists) {
            errorExists = this.valideFieldsFn("sn", this.state.schemaName);
        }
        if(errorExists === false && this.state.isFormDirty === true) {
            this.setState({isFormDirty: false});
            if(Number(this.state.id) < 0 && (this.state.schemaName === undefined || this.state.schemaName === "")) {
                ValidationUtility.confirm(
                    this.findLabel("tenant.schema.name.warn"), 
                    this.onConfirmSaveAction, undefined, "", "");
            } else {
                this.onConfirmSaveAction(1);
            }
            
        }
    }
    onConfirmSaveAction(flag) {
        if(flag === 1){
            this.props.creatAction(this.state); 
        }
    }
    render() {
        //let intl = this.context.intl;
        let disableSchemaName = true;
        if(this.state.status !== undefined && (this.state.status === "1" || Number(this.state.status) === 1)) {
            disableSchemaName = false;
        }
        let disClassName = "enabledTextField";
        if(disableSchemaName) {
            disClassName = "disabledTextField";
        }
        let showHideModalStyle = this.props.showModal ? "create-pool-modal display-block" : "create-pool-modal display-none";
        return(
            <div id="createTenantPopupId" className={showHideModalStyle}>
                <div id="createTenantDivId" className="create-pool-modal">
                    <div className="modal-main panel panel-default">
                        <div>
                            <div className="createPollWindowTitle">
                                {this.findLabel("create.tenant.window.title")}
                            </div>
                            <div className="popup_tenant_wrap">
                                <section pad="none" wrap={false} responsive={false} justify="center">
                                    <Form compact={false} id="create_tenant_form_id" pad="small" plain={true}>
                                        <div className="vertical_form_wrap">
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} label= {this.findLabel("multi.tenant.name")} error={this.state.nameError} required={true}>
                                                        <TextInput id="create_tenant_name_id" autoFocus={true}
                                                            value={this.state.name} 
                                                            disabled={disableSchemaName}
                                                            className={disClassName}
                                                            onChange={event=>this.handleChangeValue("name", event)}
                                                        />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} label= {this.findLabel("multi.tenant.first.name")} error={this.state.firstError} required={true}>
                                                        <TextInput id="create_tenant_first_name" 
                                                            value={this.state.firstName} 
                                                            disabled={disableSchemaName}
                                                            className={disClassName}
                                                            onChange={event=>this.handleChangeValue("fn", event)}
                                                        />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} label= {this.findLabel("multi.tenant.last.name")} error={this.state.lastError} >
                                                        <TextInput id="create_tenant_last_name" 
                                                            value={this.state.lastName} 
                                                            disabled={disableSchemaName}
                                                            className={disClassName}
                                                            onChange={event=>this.handleChangeValue("ln", event)}
                                                        />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} label= {this.findLabel("multi.tenant.email")} error={this.state.emailError} required={true}>
                                                        <TextInput id="create_tenant_email" 
                                                            value={this.state.email} 
                                                            onChange={event=>this.handleChangeValue("email", event)}
                                                        />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} label= {this.findLabel("multi.tenant.schema.name")} error={this.state.schemaError} >
                                                        <TextInput id="create_tenant_schema_name" 
                                                            value={this.state.schemaName} 
                                                            disabled={disableSchemaName}
                                                            className={disClassName}
                                                            onChange={event=>this.handleChangeValue("sn", event)}
                                                        />
                                                    </FormField>
                                                </div>
                                                </div>
                                            {(this.state.status === 1 || this.state.status === "1") &&(
                                            <div>
                                                <div>
                                                    <FormField style={{ width: '35em' }} >
                                                        <CheckBox id="create_tenant_generate_sorce" 
                                                        checked={this.state.generateDataSource} 
                                                        onChange={event=>this.handleChangeValue("gs", event)} 
                                                        label={this.findLabel("multi.tenant.generate.source")} />
                                                    </FormField>
                                                </div>
                                            </div>)}
                                            <div>
                                                <div className="button_right_wrap">
                                                    <div className="button_wrap_primary">
                                                        <Button id="create_tenant_btn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.continueCreateAction} />
                                                    </div>
                                                    <div className="button_wrap_negative">
                                                        <Button id="create_tenant_canelBtn" label={this.findLabel("label.button.cancel")} plain={false} secondary={true} onClick={this.props.cancelAction} />
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                    </Form>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// AddTenant.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AddTenant;