import React, {Component} from "react";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import TableGrid from "../../../components/commons/table/APTable";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Store from "../../../flux/ProductUsageStore";
import { Monitor as SystemIcon } from 'grommet-icons';
import { PlatformRaspberry as ProdIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import Modal from "../../generic/Modal";
import LicenseDetail from "../../../components/main/license/subcomponents/LicenseDetail";
import { DataSearch as Search } from 'grommet/components/DataSearch';
import SpinningNotification from '../../generic/SpinningNotification';
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import ConsumptionUsageGraph from "./ConsumptionUsageGraph";
import DateRange from "../../generic/DateRange";
import Select from "../../commons/APLSSelect";
import * as DateUtility from "../../../utils/DateUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import DatesPopup from "../../generic/DatesPopup";
import { useIntl } from 'react-intl';
import { Data } from "grommet/components";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class ProductUsage extends Component{
    constructor(props){
        super(props);
         this.state = {
            hostProdList: [],
            prodLicenseList:[],
            pagination: {
                deleted: false,
                sortBy: "installedDate",
                sortOrder: "DESC",
                pageNumber: "1",
                pageSize: FetchHelper.getPageSize()
            },
            sortIndex: Store.getState().sortIndex,
            sortAscending: Store.getState().sortAscending,            
            licenseDetail: undefined,
            activeIndex:0,
            selDateRangeVal: "",
            usageStartDate: "",
            usageEndDate: "",
            selHostProdID:"",
            selCustomMetric:"",
            showMessage: true,
            showModal: false ,
            datesErrorMessage: ""      
         }
         this.refreshProductReport = this.refreshProductReport.bind(this);
         this.onSystemClicked = this.onSystemClicked.bind(this);
         this.onFeatureClicked = this.onFeatureClicked.bind(this);
         this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
         this.onProductSearchAction = this.onProductSearchAction.bind(this);
         this.tabClickAction = this.tabClickAction.bind(this);
         this.consumeRef = React.createRef();
         this.onSelectDateRange = this.onSelectDateRange.bind(this);
         this.selectDateRange = this.selectDateRange.bind(this);
         this.selectCustomMetric = this.selectCustomMetric.bind(this);
         this.populateDates = this.populateDates.bind(this);
         this.exportProductLicense = this.exportProductLicense.bind(this);
         this.exportProductHost = this.exportProductHost.bind(this);
         this.onPaginationClicked = this.onPaginationClicked.bind(this);
         this.datesCancelAction = this.datesCancelAction.bind(this);
         this.datesOkAction = this.datesOkAction.bind(this);
         this.onSortClicked = this.onSortClicked.bind(this);
    }
    componentDidMount(){        
        Store.on("change", this.refreshProductReport);
        FetchHelper.getProdHostList(this.context.intl);
    }
    componentWillUnmount(){
        Store.removeListener("change", this.refreshProductReport);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    refreshProductReport(){
       if(Store.getState() && Store.getState().actionType ==="productHostList" && Store.getState().data !== undefined){
            let listData = Store.getState().data;
             listData.map(item => {
                var key = item.poolName;
                item.poolName = this.renderSysProdCol(item.id,key,true);
                if(item.childs !== undefined){
                    item.childs.map(childItem =>{
                        childItem.poolName = this.renderSysProdCol(childItem.id,childItem.poolName,false);
                        return childItem;
                    });
                }
                return item;
            });
            this.setState({hostProdList :  listData});           
        }else if(Store.getState() && Store.getState().actionType ==="prodLicenseList" && Store.getState().licenseList !== undefined){
            let listData = Store.getState().licenseList;
            let pageData = Store.getState().pagination;
            pageData.required = true;
            pageData.style = "default";
             let sortIndex = 5;
            let indexx = pageData.sortBy;
                switch (indexx) {
                    case "featureDescription":
                        sortIndex=0;
                        break;
                    case "startDate":
                        sortIndex=1;
                        break;
                    case "expiryDate":
                        sortIndex=2;
                        break;
                    case "capacity":
                        sortIndex=3;
                        break;  
                    case "status":
                        sortIndex = 4;
                        break;
                    case "installedDate":
                        sortIndex = 5;
                        break;  
                    case "restoredDate":
                        sortIndex = 6;
                        break;      
                    default:
                        sortIndex=5;
                        break;
                }
            this.setState({prodLicenseList : listData});
            this.setState({pagination : pageData});
            this.setState({sortIndex : sortIndex});       
        }else if(Store.getState() && Store.getState().actionType ==="prodLicenseDetails" && Store.getState().licenseDetails !== undefined){
           let ldetails = Store.getState().licenseDetails;          
            this.setState({licenseDetail : ldetails});
        }else if(Store.getState() && Store.getState().actionType ==="customMetric" && Store.getState().metricData !== undefined){
            let val = Store.getState().metricData, colValueOptions=[];            
            
            val.map(itm => {
                colValueOptions.push({value: itm, label:itm});
                return "";
            });
          this.setState({customMetricList : colValueOptions});
        } 
    }
    renderSysProdCol(key, value,flag){
        if(flag){
            return (                
                <Anchor
                onClick={this.onSystemClicked.bind(this, false,value)} >
                     <span id={"value_"+key} name="valueSpan"
                     style={{display:"inline-block"}}>{value}</span>
                    
                </Anchor>          
            ) 
        }else{
            return (          
                <Anchor 
                     onClick={this.onSystemClicked.bind(this, key,value)} >                     
                    <SystemIcon id={"edit_"+key} name="editIcon"
                                size="small" colorIndex="brand" style={{marginTop:"0.3em", marginRight:"0.4em"}}/>    
                     <span id={"value_"+key} name="valueSpan"
                     style={{display:"inline-block"}}>{value}</span>
                    
                </Anchor>
            )
        }
       
    }
    onSystemClicked(key,val,event){
        if(key==false){             
             this.setState({prodLicenseList : []});
             this.setState({showMessage : true});
        }else{
            this.setState({selHostProdID : key});
            this.setState({showMessage : false});
            let index = this.state.activeIndex;
            if(index == 1){ 
                this.setState({customMetricList : ""});
                this.setState({selCustomMetric : ""});            
                FetchHelper.getCustomMetrics(key);
                this.consumeRef.current.setCustomMetric("", "", "", "");
            }else{
                let paginationTemp = {
                deleted: false,
                sortBy: "installedDate",
                sortOrder: "DESC",
                pageNumber: "1",
                pageSize: FetchHelper.getPageSize()
                };
                FetchHelper.getProdLicenseList(key,paginationTemp,this.context.intl);
            }            
                     
        }        
    }
    onFeatureClicked(key, event){       
        FetchHelper.getProductReportLicenseDetail(key,this.context.intl);
    }
    onModalCloseCallback() {
        this.setState({
            licenseDetail: undefined
        });
    }
    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <LicenseDetail id="lic_view_detail" licenseDetail={licDetail}/>
            );
        }
    }
    modalLicenseDetailContent() {
        let licDetail = this.state.licenseDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(licDetail !== undefined){
            if(licDetail.featureDescription !== undefined){
                sectionInfoTitle = licDetail.featureDescription +
                    ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            } else {
                sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            }
            showModal = true
        }
        return (
            <Modal id="lic_view_detailMod" title={sectionInfoTitle} showModal={showModal} styleProps={{top: "45%", overflowY: "auto"}}
                    closeLabel={LocaleUtility.getLocaleMessage("label.button.close")}
                    sectionContent={this.infoSectionContent(licDetail)}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    />
        );
    }
    onProductSearchAction(event){
        if (event.key === 'Enter'){
            let searchVal = event.target.value;
            this.setState({hostProdList : []});
            this.setState({prodLicenseList : []});            
            if(searchVal != ''){                
               FetchHelper.searchProductHost(searchVal);
            }else{
                FetchHelper.getProdHostList(this.context.intl);
            }
            
        }        
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        FetchHelper.getProdLicenseList(this.state.selHostProdID, paginationTemp, this.context.intl);
    }
    tabClickAction(index, event) {
        this.setState({activeIndex: index});
        if(index == 0){
            let paginationTemp = {
                deleted: false,
                sortBy: "installedDate",
                sortOrder: "DESC",
                pageNumber: "1",
                pageSize: FetchHelper.getPageSize()
                };
                FetchHelper.getProdLicenseList(this.state.selHostProdID,paginationTemp,this.context.intl);

        }else if(index == 1){      
            this.populateDates(0, 0, false);  
            this.setState({customMetricList : ""});
            this.setState({selCustomMetric : ""});     
            FetchHelper.getCustomMetrics(this.state.selHostProdID);
        }
    }
    populateDates(noOfdays, opt, flag) {
        let currentDate = new Date();
        let previousDate = new Date();
        if(opt === 4) {
            previousDate.setDate(1);
        } else if(opt === 5) {
            let d = previousDate;
            d.setDate(0);
            let days = d.getDate();
            let month = d.getMonth();
            let year = d.getFullYear();
            previousDate = d;
            previousDate.setDate(1);
            currentDate = new Date(year, month, days);
        } else {
            previousDate.setDate( currentDate.getDate() - noOfdays );
        }
        if(opt === 0) {
            previousDate = DateUtility.convertToServerTimeZone(previousDate);
        }
        currentDate = DateUtility.convertToServerTimeZone(currentDate);
        
        this.setState({usageStartDate: DateUtility.getDate(previousDate)});
        if(opt === 1) {
            this.setState({usageEndDate: DateUtility.getDate(previousDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(previousDate));
            }
        } else {
            this.setState({usageEndDate: DateUtility.getDate(currentDate)});
            if(flag) {
                this.selectDateRange( DateUtility.getDate(previousDate),  DateUtility.getDate(currentDate));
            }
        }
        let v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(currentDate);
        if(!flag) {
            v = DateUtility.getDate(previousDate)+" - "+DateUtility.getDate(previousDate);
            this.setState({selDateRangeVal: {"label": this.findLabel("label.date.today"), "value": v}});
        }
        this.setState({displayValue: v});
    }
    onSelectDateRange(event) {
        let v = event.option.value;
        let start = this.state.usageStartDate;
        let end = this.state.usageEndDate;
        this.setState({selDateRangeVal: event.option});
        if(v === "custom") {
            this.setState({showModal: true});
            this.setState({datesErrorMessage: ""});
        } else {
            let val = event.option.value.split(" - ");
            this.setState({displayValue: v});
            this.setState({usageStartDate: val[0]});
            this.setState({usageEndDate: val[1]});
            this.selectDateRange(val[0], val[1]);
            start = val[0];
            end = val[1];
        }
        this.consumeRef.current.setCustomMetric(this.state.selCustomMetric.value, start, end, this.state.selHostProdID);
    }
    selectDateRange(usageStartDate, usageEndDate) {
        this.setState({usageStartDate: usageStartDate});
        this.setState({usageEndDate: usageEndDate});       
    }
    selectCustomMetric(event) {        
        this.setState({selCustomMetric: event.option});
        this.consumeRef.current.setCustomMetric(event.option.value, this.state.usageStartDate, this.state.usageEndDate, this.state.selHostProdID);
        
    }
    exportProductLicense(e){
        FetchHelper.exportProductLicenseList(this.context.intl, this.state.selHostProdID);
    }

    exportProductHost(e){
        FetchHelper.exportProductHost(this.context.intl);
    }
    datesCancelAction() {
        this.setState({showModal: false});
    }
    datesOkAction(event, s) {
        let sDate = DateUtility.getDate(new Date(s.fromDate));
        let eDate = DateUtility.getDate(new Date(s.toDate));
        if(sDate && eDate && sDate !== "" && eDate !== "") {
            let fDate = new Date(sDate).getTime();
            let tDate = new Date(eDate).getTime();
            if(tDate < fDate) {
                this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation1")});
            } else {
                this.setState({displayValue: DateUtility.getLocalFormatedDateFromDate(sDate)+" - "+DateUtility.getLocalFormatedDateFromDate(eDate)});
                this.setState({usageStartDate: sDate});
                this.setState({usageEndDate: eDate})
                this.selectDateRange(sDate, eDate);
                this.consumeRef.current.setCustomMetric(this.state.selCustomMetric.value, sDate, eDate, this.state.selHostProdID);
                this.setState({showModal: false});
            }
        } else {
            //ConfigurationUtils.showErrorMessage1("usage.graph.daterange.validation2", this.context.intl);
            this.setState({datesErrorMessage: LocaleUtility.getLocaleMessage("usage.graph.daterange.validation2")});
        }
    }
    onSortClicked(index, order, header) {
        var sortOrder = "ASC";
        let paginationTemp = this.state.pagination;        
        let sortAscVar = true;
        
        if(paginationTemp.sortOrder === "ASC"){
            sortOrder = "DESC";
            sortAscVar = false;       
        }        
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = header[index].columnName;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.setState({ pagination: paginationTemp});   
        this.setState({ sortAscending : sortAscVar });              
        this.setState({sortIndex : index});
        FetchHelper.getProdLicenseList(this.state.selHostProdID, paginationTemp, this.context.intl);
    }
    render() {
      //let intl = this.context.intl;
        let noDataFoundText = this.findLabel("label.no.data.found");
        let expandable = false;
        let locale = LocaleUtility.getCurrentLocale();
        let searchHeight = "30px", placeHolderCSS;
        if (locale == 'de' || locale == 'fr'){
            searchHeight = "50px";
            placeHolderCSS = "expandPlaceHolder";
        }

        return(
          <div className="config-cont-backgroud">
            <div className="action_filter_wrapper">
                <div></div>
                <div className="floatRight">          
                    <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportProductHost}><DocumentDownloadIcon id="view-download-license" colorIndex="brand"/></a>
                </div>
            </div> 
            <div className="border_white_backg_shadow" >          
          <div className="product_report_left" > 
          <div className="product_report_search">   
            {/* style={{width: LocaleUtility.getLocaleMessage("search.placeholder").length+"%", minWidth : "54%", maxWidth : "100%"}}     */}
                <div id="productReportSearchDiv" className={placeHolderCSS}>          
                    <Data>
                    <Search style={{height: searchHeight}} placeholder={LocaleUtility.getLocaleMessage("search.placeholder")}
                                        inline={true}
                                        dropdown={false}
                                        responsive={false}
                                        size='larger'
                                        dropAlign={{"right": "right"}}
                                        onKeyDown={event=>this.onProductSearchAction(event)} 
                                        id="product-sys-search-id"
                                        aria-multiline
                                        />
                    </Data>
                </div>
            </div>        
            {/* <br/> */}
           {/* <div>          
            <Search style={{height: searchHeight}} placeholder={LocaleUtility.getLocaleLabel(this.context.intl, "search.placeholder")}
                                        inline={true}
                                        dropdown={false}
                                        responsive={false}
                                        size='small'
                                        dropAlign={{"right": "right"}}
                                        onKeyDown={event=>this.onProductSearchAction(event)} 
                                        id="product-sys-search-id2"
                                        />
            </div> */}
            
            <div className="product_report_tbl">
                <TableGrid id="productSystemGrid" 
                header={[
                    {
                        columnName: "poolName",
                        headerLabel: LocaleUtility.getLocaleMessage("grid.product.host.label"),
                        width: "25%",
                        columnKey: "id"
                    }
                    ]}
                    rowData={this.state.hostProdList}
                    isExportRequired={false}
                    rowKey="id"
                    childKey="childs"
                    expandAll={expandable}
                    defaultSelectIds={this.state.defaultSelectIds}
                    noDataFoundText={noDataFoundText}
            />
            </div>
                 
            </div>
            {(this.state.showMessage) && 
            <div className="headerConfig" style={{float: "left",width: "75%", marginLeft: "5px",textAlign: "center",backgroundColor:"",marginTop:"5%" }}>
            
                <b className="blink">{LocaleUtility.getLocaleMessage("product.report.message")}</b> 
                                                     
            </div>}

            {(this.state.showMessage == false || this.state.prodLicenseList.length > 0) && <div className="product_report_right">
                {/* <br/> <br/> */}
                <Tabs activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction}>
                    <Tab title={LocaleUtility.getLocaleMessage("tabs.label.license.list")} className="licenseList">
                    {/*(this.state.prodLicenseList.length > 0) && <div className="floatRight">
                        <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportProductLicense}><DocumentDownloadIcon id="view-download-license" colorIndex="brand"/></a>
            </div>*/}
                    <TableGrid id="prod_license_list_grid" 
                    header={[
                         {
                            columnName: "featureDescription",
                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.feature"),
                            width: "30%",
                            columnKey: "licenseID",
                            onClick: this.onFeatureClicked,                                                
                            sort: true
                        },                                                                                   
                        {
                            columnName: "startDate",
                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.startDate"),
                            width: "15%",                                                
                            sort: true
                        },
                        {
                            columnName: "expiryDate",
                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.expDate"),
                             width: "15%", 
                            sort: true
                        },
                        {
                            columnName: "capacity",
                            headerLabel: LocaleUtility.getLocaleMessage("label.form.text.capacity"),
                             width: "10%", 
                            sort: true
                        },
                        {
                            columnName: "status",
                            headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.status"),
                             width: "10%",
                            sort: true
                        },
                        {
                            columnName: "installedDate",
                            headerLabel: LocaleUtility.getLocaleMessage("job.lebel.created.on"),
                             width: "15%",
                            sort: true
                        },
                        {
                            columnName: "restoredDate",
                            headerLabel: LocaleUtility.getLocaleMessage("job.label.updated.on"),
                             width: "15%", 
                            sort: true
                        }
                    ]}
                    {...{keys:this.state.selectedRowKeys}}
                    rowData={this.state.prodLicenseList}
                    pagination = {this.state.pagination}                                
                    isExportRequired={false}
                    onSort={this.onSortClicked}
                    onFilter={this.onFilterClicked}
                    onPagination={this.onPaginationClicked}
                    sortIndex={this.state.sortIndex}
                    sortAscending={this.state.sortAscending}
                    rowKey="licenseID"
                    uncheckTheDisabled={true}
                    selectAllRows={false}
                    noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                    "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                />
                {this.modalLicenseDetailContent()}
            </Tab>            
            <Tab title={LocaleUtility.getLocaleMessage("tabs.label.metric.usage")}> 
                <div style={{display: "flex"}}>
                    <DateRange 
                        label = {this.findLabel("usage.graph.daterange.label")+" "}
                        onChange = {this.onSelectDateRange}
                        value = {this.state.selDateRangeVal}
                        displayValue = {this.state.displayValue}
                        width = "22em"
                        defaultValue = {3}
                        renderFor = "history"
                    />                                            
                    <Select id="usage_consumption_selectCustomMetric"
                        options={this.state.customMetricList}
                        value={this.state.selCustomMetric}
                        onChange={this.selectCustomMetric}
                        label= {this.findLabel("label.select.metric")+": "}
                        width= {22}
                    />
                </div>
            <div style={{width: "100%", float: "left", padding: "6px"}} className="inuse-container">
          { <ConsumptionUsageGraph  ref={this.consumeRef}              
                usageStartDate = {this.state.usageStartDate}
                usageEndDate = {this.state.usageEndDate}
                customMetricVal={this.state.metric}
                hostID={this.state.selHostProdID}              
            />         }                                       
            </div>  
                                                     
            </Tab>
            </Tabs>
               </div> }  
               {this.state.showModal ? <DatesPopup 
                        cancelAction = {this.datesCancelAction}
                        startDateLabel = {this.findLabel("usage.graph.from.label")}
                        endDateLabel = {this.findLabel("usage.graph.to.label")}
                        showModal = {this.state.showModal}
                        fromDate = {this.state.usageStartDate}
                        toDate = {this.state.usageEndDate}
                        errorMessage = {this.state.datesErrorMessage}
                        okAction={this.datesOkAction} /> : ""}
           <div>
                <SpinningNotification centerPage={true} />
            </div>
            </div>       
        </div> 
             
        )       
    }
}

// ProductUsage.contextTypes = {
//     intl: _propTypes2.default.object,
// };
export default ProductUsage;