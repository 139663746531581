import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import * as FetchHelper from "../../../utils/FetchHelper";
import Store from "../../../flux/ReservationManagementStore";
import TableGrid from "../../../components/commons/table/APTable";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import ComponentNotification from "../../generic/ComponentNotification";
import Dispatcher from "../../../flux/Dispatcher";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timer = null;

class AssociatePools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            featureId: this.props.featureId,
            featureVersion: this.props.featureVersion,
            productName: this.props.productName,
            rowData: [],
            selectedPools: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize()
            },
            sortIndex: 0,
            sortAscending: true,
            dataType: 0,
            searchPoolName: "",
            searchParentName: "",
            searchDescription: "",
            poolObject: null,
            selectedGridIds: [],
            isAssocited: false
        }
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.reloadPools = this.reloadPools.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.resetPagination = this.resetPagination.bind(this);
        this.assoicatePoolSubmit = this.assoicatePoolSubmit.bind(this);
        this.callBackAssociation = this.callBackAssociation.bind(this);
        this.showMessageAction = this.showMessageAction.bind(this);
        this.clearSelections = this.clearSelections.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.continueSearchAction = this.continueSearchAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    clearSelections() {
        this.setState({selectedGridIds: []});
        this.setState({selectedPools: []});
        //this.setState({rowData: []});
    }
    componentDidMount() {
        Store.on("change", this.callBackAssociation);
        Store.on("change", this.refreshGrid);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.refreshGrid);
        Store.removeListener("change", this.callBackAssociation);
    }
    reloadPools(associated) {
        this.showMessageAction("", "COMP_CLOSE");
        this.setState({selectedGridIds: []});
        this.setState({selectedPools: []});
        //this.setState({rowData: []});
        let pool = {
            "parent": "",
            "poolDesc": "",
            "poolName": ""
        }
        this.setState({searchPoolName: ""});
        this.setState({searchDescription: ""});
        this.setState({searchParentName: ""});
        if(associated) {
            this.setState({dataType: 1});
            this.findData(1, this.state.sortIndex, this.resetPagination, pool);
        } else {
            this.setState({dataType: 0});
            this.findData(0, this.state.sortIndex, this.resetPagination, pool);
        }
        this.setState({isAssocited: associated});
    }
    assoicatePoolSubmit() {
        if(this.state.selectedPools && this.state.selectedPools.length === 0) {
            this.showMessageAction(this.findLabel("resv.associated.pool.validation.msg", this.context.intl), "COMP_INVALID");
        } else {
            FetchHelper.associateOrDisassociatePools(this.state.featureId, this.state.featureVersion,
            this.state.productName, this.state.selectedPools,this.context.intl, this.state.isAssocited);
        }
    }
    callBackAssociation() {
        if(Store.getState() && Store.getState().actionType === "associateORDisassociate") {
            let data = Store.getState().data;
            if(data) {
                if(data.status === "SUCCESS") {
                    let keyValue = "resv.associated.pool.success.msg";
                    if(!this.state.isAssocited) {
                        keyValue = "resv.disassociated.pool.success.msg";
                    }
                    this.reloadPools(this.state.isAssocited);
                    this.showMessageAction(this.findLabel(keyValue), "COMP_OK");
                } else {
                    let validationMsg = ""
                    if(data.errorDetail && data.errorDetail.errorCode !== 1000) {
                        if(data.errorDetail.errorCode === 20011) {
                            validationMsg = LocaleUtility.getLocaleMessage(20011, FetchHelper.buildErrorParams(data.errorDetail.parameters));
                        } else {
                            validationMsg = this.findLabel(data.errorDetail.customMessage);
                        }
                        this.reloadPools(this.state.isAssocited);
                    } else {
                        validationMsg = this.findLabel("10000");
                    }
                    this.showMessageAction(validationMsg, "COMP_INVALID");
                    ConfigurationUtils.hideLoadMask(this.context.intl);
                }
            }
        }
    }
    showMessageAction(payload, type) {
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
    findData(reservedStatus, sortIndex, pagination, poolObject) {
        FetchHelper.listPoolsForAssociatedPopup(this.state.featureId, this.state.featureVersion, this.state.productName, reservedStatus, this.context.intl, sortIndex, pagination, poolObject);
    }
    refreshGrid() {
        if(Store.getState() && Store.getState().actionType === "associatedpopup") {
            this.setState({rowData: Store.getState().popUpPoolsList});
            let sortIndex = Store.getState().sortIndex;
            let sortAscending = Store.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            let pagination = Store.getState().pagination;
            pagination.required = true;
            pagination.style = "default";
            pagination.sortBy = "id";
            this.setState({pagination: pagination});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    onRowClicked(keys, event) {
        if(event !== undefined) {
            this.setState({selectedPools: keys});
            this.setState({selectedGridIds: keys});
        }
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "id";
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        paginationTemp.pageNumber = 1;
        this.findData(this.state.dataType, index, paginationTemp, this.state.poolObject);
    }
    resetPagination() {
        let pagination = {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize()
        }
        return pagination;
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.dataType, this.state.sortIndex, paginationTemp, this.state.poolObject);
    }
    onFilterClicked(filterColumn, filterValue, e) {
        if(timer !== null) {
            clearTimeout(timer);
        }
        if(e.charCode === 13) {
            this.continueSearchAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueSearchAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }
    continueSearchAction(filterColumn, filterValue) {
        let poolName  = this.state.searchPoolName;
        let desc = this.state.searchDescription;
        let parentName = this.state.searchParent;
        if(filterColumn === "poolName") {
            this.setState({searchPoolName: filterValue});
            poolName = filterValue;
        } else if(filterColumn === "poolDescription") {
            this.setState({searchDescription: filterValue});
            desc = filterValue;
        } else if(filterColumn === "parentName") {
            this.setState({searchParent: filterValue});
            parentName = filterValue;
        }
        let pool = {
            "parent": parentName,
            "poolDesc": desc,
            "poolName": poolName
        }
        this.setState({poolObject: pool});
        this.findData(this.state.dataType, this.state.sortIndex, this.state.pagination, pool);
    }
    render() {
        //let intl = this.context.intl;
        let showHideModalStyle = this.props.showModal ? "modal display-block" : "modal display-none";
        return(
            <div id="associatePoolPopupId" className={showHideModalStyle}>
                <div>
                    <ComponentNotification style={{minWidth: "23%", left:"50%", transform: "translate(-50%, 31%)"}} />
                </div>
                <div id="associatePoolDivId" className="modal">
                    <div className="modal-main panel panel-default" style={{width: "60%", maxHeight: "85%"}}>
                        <div>
                            <div className="createPollWindowTitle">
                                {this.state.isAssocited === false ?
                                    this.findLabel("resv.feature.disassociate.popup.title")
                                :
                                    this.findLabel("resv.feature.associate.popup.title")
                                }
                            </div>
                            
                            <div className="associate_pool_popup">
                                <TableGrid id="associated_popup_grid"
                                    header={[
                                        {
                                            columnName: "poolName",
                                            headerLabel: this.findLabel("resv.grid.header.poolname"),
                                            width: "auto",
                                            filter: true,
                                            columnKey: "poolName",
                                            filterPlaceHolder: this.findLabel("search.pool.name.label")
                                        },
                                        {
                                            columnName: "poolDescription",
                                            headerLabel: this.findLabel("resv.grid.header.pooldesc"),
                                            width: "auto",
                                            filter: true,
                                            filterPlaceHolder: this.findLabel("search.pool.description.label")
                                        },
                                        {
                                            columnName: "parentName",
                                            headerLabel: this.findLabel("resv.grid.header.poolparent") ,
                                            href: false,
                                            width: "auto",
                                            filter: true,
                                            filterPlaceHolder: this.findLabel("search.pool.parent.label")
                                        }
                                    ]}
                                    {...{keys:this.state.selectedPools}}
                                    rowData={this.state.rowData}
                                    pagination={this.state.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.onPaginationClicked}
                                    sortIndex={this.state.sortIndex}
                                    sortAscending={this.state.sortAscending}
                                    onChangeCheckBoxCallback={this.onRowClicked}
                                    rowKey="poolName"
                                    selectAllRows={false}
                                    noDataFoundText={this.findLabel("no.pools.available")}
                                />
                            </div>
                            <div className="button_right_wrap pad_all_10">
                                {this.props.associatedAction ?<div className="button_wrap_primary">
                                <Button id="associate_pool_btn" label={this.findLabel("label.button.associate")} plain={false} primary={true} onClick={this.assoicatePoolSubmit} /></div>
                                :<div className="button_wrap_negative">
                                <Button id="associate_pool_btn" label={this.findLabel("label.button.disassociate")} plain={false} primary={true} onClick={this.assoicatePoolSubmit} /></div>
                                }
                                <div className="button_wrap_negative">
                                    <Button id="associate_pool_closeBtn" label={this.findLabel("label.button.close")} plain={false} secondary={true} onClick={this.props.closeAction} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
// AssociatePools.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default AssociatePools;