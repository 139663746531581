import React, { Component } from "react";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { DateInput } from "grommet/components/DateInput";
import { Select } from "grommet/components/Select";
import Modal from "../../../generic/Modal";
import SectionWithTitle from "../../../generic/SectionWithTitle";
import * as FetchHelper from "../../../../utils/FetchHelper";
import * as Utils from "../../../../utils/Util";
import ProductStore from "../../../../flux/ProductStore";
import Dispatcher from "../../../../flux/Dispatcher";
import { TextInput } from 'grommet/components/TextInput';
import { RadioButton } from 'grommet/components/RadioButton';
import { Button } from "grommet/components/Button";
import BorrowedLicenseDetail from "./BorrowedLicenseDetail";
import SpinningNotification from '../../../generic/SpinningNotification';
import LicenseDetailStore from "../../../../flux/LicenseDetailStore";
import * as FrontendPagePath from  "../../../../utils/FrontendPagePath";
import * as LocaleUtility from "../../../../utils/LocaleUtility";
import * as ValidationUtility from "../../../../utils/ValidationUtility";
import * as DateUtility from "../../../../utils/DateUtility";
// var _propTypes = require('prop-types');
// var _propTypes2 = LocaleUtility._interopRequireDefault(_propTypes);

class LicenseBorrowForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
                productNameSelected: undefined,
                productNameSelectOptions: ProductStore.getState().productNameSelectOptions,
                featureSelectOptions: [],
                licenseDetail: undefined,
                serverPort:"5814"
            }
        this.refreshProductItem = this.refreshProductItem.bind(this);
        this.filterFeatureList = this.filterFeatureList.bind(this);
        this.borrowFormSubmit = this.borrowFormSubmit.bind(this);
        this.onSelectOfRadio = this.onSelectOfRadio.bind(this);
        this.validateTextInput = this.validateTextInput.bind(this);
        this.onExpiryDateChange = this.onExpiryDateChange.bind(this);
        this.onServerHostNameChange = this.onServerHostNameChange.bind(this);
        this.refreshModal = this.refreshModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
    }

    componentDidMount() {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        FetchHelper.getProducts("borrow_enabled", true);
        ProductStore.on("change", this.refreshProductItem);
        LicenseDetailStore.on("change", this.refreshModal);
    }

    componentWillUnmount() {
        ProductStore.removeListener("change", this.refreshProductItem);
        LicenseDetailStore.removeListener("change", this.refreshModal);
    }

    refreshProductItem() {
        const products = ProductStore.getState().productNameSelectOptions
        const features = ProductStore.getState().featureSelectOptions

        if(products !== undefined) {
            this.setState({
                productNameSelectOptions: products,
            });
        }
        if(features !== undefined) {
            this.setState({
                featureSelectOptions: features,
                productName: ProductStore.getState().productNameSelected,
                productNameSelected: ProductStore.getState().productNameSelected
            });
        }
    }
    
    refreshModal() {
        this.setState({
            licenseDetail: LicenseDetailStore.getState().licenseDetail,
        });
    }

    borrowFormSubmit(event){
        event.preventDefault();
        var elements = event.target.elements;
        const errors = Object.assign([], this.state.errorcode);
        var flag = true;
        if(elements.expiryDate.value === ''){
            let index = errors.indexOf(elements.expiryDate.name)
            if (index === -1) {
                errors.push(elements.expiryDate.name);
            }
            flag = false;
        }

        if(this.state.productNameSelected === undefined){
            let index = errors.indexOf(elements.productName.name)
            if (index === -1) {
                errors.push(elements.productName.name);
            }
            flag = false;
        }

        if(this.state.selectedFeature === undefined){
            let index = errors.indexOf(elements.feature.name)
            if (index === -1) {
                errors.push(elements.feature.name);
            }
            flag = false;
        }

        if(elements.capacity.value === ''){
            let index = errors.indexOf(elements.capacity.name)
            if (index === -1) {
                errors.push(elements.capacity.name);
            }
            flag = false;
        }

        if(elements.serverHostName.value === ''){
            let index = errors.indexOf(elements.serverHostName.name)
            if (index === -1) {
                errors.push(elements.serverHostName.name);
            }
            flag = false;
        }

        if(elements.serverPort.value === ''){
            let index = errors.indexOf(elements.serverPort.name)
            if (index === -1) {
                errors.push(elements.serverPort.name);
            }
            flag = false;
        }

        if(flag === true){
            this.setState({errorcode: []});
            let loadSpinAction = "LICENSE_BORROW_INPROGRESS_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            FetchHelper.requestBorrowLicense(event.target.elements, this.state.productName);
        } else {
            Dispatcher.dispatch({
                type: "FORM_SUBMIT_ERROR",
                payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
            });
            this.setState({errorcode: errors});
        }

    }

    onExpiryDateChange(value){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf('expiryDate');
        if (value === ''){
            errors.push('expiryDate');
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({expiryDate: value.value, errorcode: errors});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    onServerHostNameChange(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name);
        if (!ValidationUtility.isEmpty(event.target.value) ||
                event.target.value.length > 200){
            errors.push(event.target.name);
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        this.setState({errorcode: errors});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    validateTextInput(event){
        const errors = Object.assign([], this.state.errorcode);
        let index = errors.indexOf(event.target.name)
        let field = event.target.name;
        this.setState({ [field]: event.target.value });
        if (event.target.value === '' || !ValidationUtility.isNumber(event.target.value)) {
            if (index === -1) {
                errors.push(event.target.name);
            }
            this.setState({errorcode: errors});
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
            index = errors.indexOf('invalidPort')
            if(event.target.name === 'serverPort' && 
                    event.target.value.length > 4 ){
                if (index === -1) {
                    errors.push('invalidPort');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
                this.setState({port: event.target.value});
            }
            index = errors.indexOf('invalidCapacity')
            if(event.target.name === 'capacity' && 
                    parseInt(event.target.value) === 0 ){
                if (index === -1) {
                    errors.push('invalidCapacity');
                }
            } else {
                if (index !== -1) {
                    errors.splice(index, 1);
                }
            }
            this.setState({errorcode: errors});
        }
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    onSelectOfRadio(event){
        console.log('Inside Radio Onchange....');
    }

    filterFeatureList(event){
        if(event.option.value !== undefined){

            const errors = Object.assign([], this.state.errorcode);
            let index = errors.indexOf('productName');
            if (index !== -1) {
                errors.splice(index, 1);
            }
            let loadSpinAction = "FEATURE_FETCH_INPROGRESS_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            this.setState({productName: event.option.value})
            FetchHelper.getAllProductFeatures(event.option.value, true);
            this.setState({selectedFeature: LocaleUtility.getLocaleMessage("label.select.feature"),
                            errorcode: errors});
        }
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <BorrowedLicenseDetail licenseDetail={licDetail}/>
            );
        }
    }

    onModalCloseCallback() {
        this.setState({
            licenseDetail: undefined,
        });
    }

    modalLicenseDetailContent() {

        let licDetail = this.state.licenseDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(licDetail !== undefined){
            sectionInfoTitle = LocaleUtility.getLocaleMessage("label.borrow.license.details");
            showModal = true
        }
        return (
            <Modal id="lic_borr_detailModal" title={sectionInfoTitle} path={FrontendPagePath.LICENSE_BORROW_PATH+Utils.buildURLQueryParam()} showModal={showModal} 
                    closeLabel={LocaleUtility.getLocaleMessage("label.button.close")}
                    sectionContent={this.infoSectionContent(licDetail)} onCloseButtonCallback={this.onModalCloseCallback}/>
        );
    }

    sectionContent() {
        let protocol= 'HTTPS', productFormFieldError, featureFormFieldError,
        capacityFormFieldError, serverPortFormFieldError, expDateFormFieldError, serverHostNameFieldError,
        capacityError, serverPortError, expDateError, serverHostNameError,
        productError, featureError;
        const productOptions = [], featureOptions = []; 

        let selectedFeature = {label:LocaleUtility.getLocaleMessage("label.select.product"), value:undefined};
        let selectedValue = {label:LocaleUtility.getLocaleMessage("label.select.none"), value:undefined};
        
        var entries = this.state.productNameSelectOptions.entries()
        for(var item of entries){
            productOptions.push({label:item[1], value:item[0]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedValue = {label:item[1], value:item[0]};
                selectedFeature = {label:LocaleUtility.getLocaleMessage("label.select.feature"), value:undefined};
            }
        }
        this.state.featureSelectOptions.map(value => featureOptions.push({label:value, value:value}));

        if(this.state.selectedFeature !== undefined){
            selectedFeature = this.state.selectedFeature;
        }
        if(this.state.protocol !== undefined){
            protocol = this.state.protocol;
        }
        if(this.state.errorcode !== undefined &&
            this.state.errorcode.length > 0){
            if(this.state.errorcode.indexOf('capacity') !== -1){
                capacityError =<span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert1")}</span>;
                capacityFormFieldError='grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidCapacity') !== -1){
                capacityError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert2")}</span>;
                capacityFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('serverPort') !== -1){
                serverPortError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert3")}</span>;
                serverPortFormFieldError = 'grommetux-form-field--error';
            } else if(this.state.errorcode.indexOf('invalidPort') !== -1){
                serverPortError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert4")}</span>;
                serverPortFormFieldError = 'grommetux-form-field--error';
            } 
            if(this.state.errorcode.indexOf('expiryDate') !== -1){
                expDateError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert5")}</span>;
                expDateFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('serverHostName') !== -1){
                serverHostNameError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert6")}</span>;
                serverHostNameFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('productName') !== -1){
                productError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert7")}</span>;
                productFormFieldError = 'grommetux-form-field--error';
            }
            if(this.state.errorcode.indexOf('feature') !== -1){
                featureError = <span className="grommetux-form-field__error">
                {LocaleUtility.getLocaleMessage("label.borrow.license.form.alert8")}</span>;
                featureFormFieldError = 'grommetux-form-field--error';
            }
        } else {
            capacityFormFieldError = '';
            serverPortFormFieldError = '';
            productFormFieldError = '';
            featureFormFieldError = '';
            serverHostNameFieldError = '';
            expDateFormFieldError = '';
            capacityError = '';
            serverPortError = '';
            expDateError = '';
            serverHostNameError = '';
            featureError = '';
            productError = '';
        }

        return (
            <section id="lic_bor_formSec" pad="none" wrap={false} responsive={false} justify="center">
                <div className="border_white_backg_shadow">
                <Form id="lic_bor_formDetail"compact={false} pad="small" plain={true} onSubmit={this.borrowFormSubmit}>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.select.product")}
                        className={productFormFieldError}>
                        <Select id="lic_bor_productName" name="productName" options={productOptions} 
                        value={selectedValue} onChange={this.filterFeatureList}/>
                        {productError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.featureid.version")}
                        className={featureFormFieldError}>
                        <Select id="lic_bor_feature" name="feature" options={featureOptions} value={selectedFeature} 
                                onChange={(e) => {
                                    const errors = Object.assign([], this.state.errorcode);
                                    let index = errors.indexOf('feature');
                                    if (index !== -1) {
                                        errors.splice(index, 1);
                                    }
                                    this.setState({selectedFeature: e.option.value, errorcode: errors})}}/>
                        {featureError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.capacity")} 
                        className={capacityFormFieldError}>
                        <TextInput id='lic_bor_capacity' name='capacity' value={this.state.capacity} onChange={event =>this.validateTextInput(event)} />
                        {capacityError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.expDate")}
                        className={expDateFormFieldError}>
                        <DateInput id="lic_bor_expiryDate" name="expiryDate" className="datepicker"
                            format={DateUtility.getDateFormat()} placeholder={DateUtility.getDateFormatPlaceholder()} 
                                            calendarProps={({locale:LocaleUtility.LOCALE})} onChange={this.onExpiryDateChange} value={this.state.expiryDate}  />
                        {expDateError}
                    </FormField>
                    <FormField>
                        <RadioButton id='lic_bor_configuredLS' name='configuredLS' label={LocaleUtility.getLocaleMessage("label.configure.license.server")}
                            checked={true} onChange={this.onSelectOfRadio} />
                        {/* <RadioButton id='choice1-2' name='choice1-2' label={LocaleUtility.getLocaleLabel(this.context.intl, "label.discover.license.server")}
                            checked={false}
                            onChange={...} /> */}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.hostName")}
                        className={serverHostNameFieldError}>
                        <TextInput id='lic_bor_serverHostName' name='serverHostName' onChange={event =>this.onServerHostNameChange(event)}/>
                        {serverHostNameError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.port")} className={serverPortFormFieldError}>
                        <TextInput id='lic_bor_serverPort' value={this.state.serverPort} name='serverPort' onChange={event =>this.validateTextInput(event)}/>
                        {serverPortError}
                    </FormField>
                    <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.protocol")}>
                        <Select id="lic_bor_httpProtocol" name="httpProtocol" options={[{label:'HTTP', value:'HTTP'}, {label:'HTTPS', value:'HTTPS'}]} 
                            value={protocol} onChange={(e) => this.setState({protocol: e.option.value})}/>
                    </FormField>
                    <div style={{ width: '30em'}}>
                        <div className="button_left_wrap">
                                    <div className="button_wrap_primary">
                                        <Button id="lic_bor_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.borrow")}
                                            type="submit" plain={false} primary={true}
                                        />
                                    </div>
                                    <div className="button_wrap_secondary">
                                        <Button id="lic_bor_backBtn" label={LocaleUtility.getLocaleMessage("label.button.back")}
                                            href={FrontendPagePath.LICENSE_BORROW_PATH+Utils.buildURLQueryParam()} accent={true} plain={false}
                                            style={{border:"0.0625rem solid #4c4e50", borderRadius:"inherit"}}/>
                                    </div>
                                </div>
                    </div>
                </Form>
                </div>
            </section>
        );
    }

  render() {
    return (
        <div className="config-cont-backgroud">
            <SectionWithTitle id="lic_bor_formMainSec" title={LocaleUtility.getLocaleMessage("label.borrow.licenses")} 
                sectionContent="" helpTopic="C_Borrow_License"/>
            {this.sectionContent()}
            {this.modalLicenseDetailContent()}
            <SpinningNotification centerPage={true} />
        </div>
    );
  }
}

// LicenseBorrowForm.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default LicenseBorrowForm;