import React, { Component } from "react";
import { Tiles } from '../commons/grommet/Tiles';
import { Tile } from '../commons/grommet/Tile';
import { Box } from 'grommet/components/Box';
import { Heading } from 'grommet/components/Heading';
import { Header } from 'grommet/components/Header';
import { Edit as EditIcon } from 'grommet-icons';
import { Trash as TrashIcon } from 'grommet-icons';
import { Anchor } from 'grommet/components/Anchor';
import * as LocaleUtility from "../../utils/LocaleUtility";
import FieldProperty from "../main/usage/FieldProperty";
import Dispatcher from "../../flux/Dispatcher";
class DragNDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {        
                tasks: [],
                update: false,
                showFieldPopup: false,
                stdAttrbutes:[],
                fieldProp: undefined
            }
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragExit = this.onDragExit.bind(this);
        this.updateElements = this.updateElements.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.editActionCall = this.editActionCall.bind(this);
        this.expandField = this.expandField.bind(this);
        this.closeFieldPoolPopup = this.closeFieldPoolPopup.bind(this);
        this.submitFieldAction = this.submitFieldAction.bind(this);
    }

    componentDidMount(){
        this.updateElements(this.props.elements);
    }

    componentDidUpdate(prevProps, prevState) {
        let flag = false;
        if(prevProps.elements.length === this.props.elements.length && prevState.elements !== undefined){
            let list = this.props.elements.map(v => v.name);
            prevState.elements.map((v, index) => {
                if(list.indexOf(v.name) === -1)
                    flag = true;
                return "";
            });
        }
        if(prevProps.elements.length !== this.props.elements.length || flag || this.state.update){
            this.updateElements(this.props.elements);
        }
        
    }

    onDragOver(event, id){
        event.preventDefault();
    }

    onDragStart(event, id){
        event.dataTransfer.setData("id", id);
        document.getElementById("element_"+id).style.border = "2px dotted red";
    }

    onDragEnter(event, id){
        if(id !== undefined){
            this.setState({enteredIndex: id});
            document.getElementById("span_"+id).style.backgroundColor = "red";
        }
    }

    onDragExit(event, id){
        if(id !== undefined){
            this.setState({enteredIndex: id});
            document.getElementById("span_"+id).style.backgroundColor = "";
        } else {
            this.setState({enteredIndex: undefined});
            document.getElementById("span_"+id).style.backgroundColor = "";
        }
    }

    onDrop(event, cat){   
        let idObj = event.dataTransfer.getData("id");
        let id = this.state.enteredIndex; 
        let moveObj = this.state.tasks[idObj];
        let elements = [];
        if(id !== undefined){
            if(Number(idObj) !== Number(id)) {
                const taskArray = Object.assign([], this.state.tasks);
                let arr1 = taskArray.slice(0, Number(id));
                let arr2 = taskArray.slice(Number(id), taskArray.length);

                if(Number(idObj) > Number(id)){
                    arr2 = arr2.filter(function(itm){
                        return itm.key !== idObj;
                    });
                } else {
                    arr1 = arr1.filter(function(itm){
                        return itm.key !== idObj;
                    });
                }
                let newArray = [], divs = [], obj, mapObj = new Map(), mapObjs = this.state.objs, lightEle;
                newArray.push.apply(newArray, arr1);
                newArray.push(moveObj); 
                newArray.push.apply(newArray, arr2);
                newArray.map((t, index) => {
                    obj = mapObjs.get(Number(t.key));
                    if(obj !== undefined){
                        obj.showOrder = index;
                        mapObj.set(index, obj);
                        if(moveObj.key === t.key){
                            lightEle = index;
                        }

                        elements[index] = obj;
                        divs[index] = 
                        <table key={index}>
                            <tbody>
                                <tr> 
                            {index === 0 ? <td id={"span_"+index} onDragEnter = {(e)=>this.onDragEnter(e, index)}
                            onDragLeave = {(e)=>this.onDragExit(e, index)}
                            style={{width:"5px", padding:"1px", height:"60px", display:"inline-block"}}></td>:null}
                            {/* <Tile id={"element_"+index} separator='top' align='start' style={{width:"10%"}} */}
                            <td>
                            <Tile id={"element_"+index} separator='top' align='start' 
                                key={index} 
                                onDragStart={(e)=>this.onDragStart(e, index)}  
                                onDragEnter = {(e)=>this.onDragEnter(e, index)}
                                onDragLeave = {(e)=>this.onDragExit(e, index)}
                                draggable 
                                className="draggable" >
                                <Box pad='small' style={{float:"left"}}>      
                                    {obj.label} 
                                </Box>
                                <div className="floatRight" style={{padding:"12px", float:"right"}}>
                                    {/* {  (t.pivotType === undefined || t.pivotType === 0) ?
                                        <Anchor title={LocaleUtility.getLocaleMessage("usage.report.field.expand")} 
                                            onClick={this.expandField.bind(this, index)} >
                                            <ExpandIcon id={"expand_"+index} name="expandIcon" size="huge" colorIndex="brand" />
                                        </Anchor>
                                        :
                                        <Anchor title={LocaleUtility.getLocaleMessage("usage.report.field.expand")} 
                                            onClick={this.expandField.bind(this, index)} >
                                            <ContractIcon id={"cont_"+index} name="contractIcon" size="huge" colorIndex="brand" />
                                        </Anchor>
                                    } */}
                                    <Anchor  title={LocaleUtility.getLocaleMessage("label.button.edit")} 
                                        onClick={this.editActionCall.bind(this, index)} >
                                        <EditIcon id={"edit_"+index} name="editIcon" size="huge" colorIndex="brand" />
                                    </Anchor>
                                    <Anchor  title={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                        onClick={this.removeElement.bind(this, index)} >
                                        <TrashIcon id={"del_"+index} name="delLink" size="huge" className="grommetux-control-icon grommetux-color-index-critical" />
                                    </Anchor>                  
                                </div>
                            </Tile>
                            </td>
                            <td id={"span_"+(index+1)} onDragEnter = {(e)=>this.onDragEnter(e, (index+1))}
                            onDragLeave = {(e)=>this.onDragExit(e, (index+1))}
                            style={{width:"5px", padding:"1px", height:"60px", display:"inline-block"}}>
                            </td>
                            </tr>
                            </tbody>
                        </table>
                        ;  
                    } 
                    return t; 
                }); 
                this.setState({tasks: divs, objs: mapObj, elements: elements}); 
                let ele = document.getElementById("element_"+lightEle);
                setTimeout(function(){
                    ele.style.border = "";
                }, 2000);
                ele.style.border = "thick solid yellow";
                this.props.updateReportFields(elements);
            }
            document.getElementById("element_"+idObj).style.border="";
            document.getElementById("span_"+id).style.backgroundColor = "";
        }
    }

    updateElements(elements){
        let mapObj = new Map();
        let divs = [], tempEle = [], stdAttr = [];
        elements.map((t, index) => { 
            if(t.showOrder !== undefined){
                index = Number(t.showOrder);
            } else {
                t.showOrder = index;
            }
            if(t.propFieldMap !== undefined)
                stdAttr.push(t.propFieldMap);
            mapObj.set(index, t);
            tempEle[index] = t;
            divs[index] = 
                <table key={index}> 
                    <tbody> 
                        <tr> 
                        {index === 0 ? <td id={"span_"+index} onDragEnter = {(e)=>this.onDragEnter(e, index)}
                        onDragLeave = {(e)=>this.onDragExit(e, index)}
                        style={{width:"5px", padding:"1px", height:"60px", display:"inline-block"}}/>:null} 
                    <td>
                    {/* <Tile id={"element_"+index} separator='top' align='start' style={{width:"10%"}} */}
                    <Tile id={"element_"+index} separator='top' align='start' 
                        key={index}
                        onDragStart={(e)=>this.onDragStart(e, index)} 
                        onDragEnter = {(e)=>this.onDragEnter(e, index)}
                        onDragLeave = {(e)=>this.onDragExit(e, index)}
                        draggable 
                        className="draggable">
                        <Box pad='small' style={{float:"left"}}> 
                            {t.label} 
                        </Box>
                        <div className="floatRight" style={{padding:"12px", float:"right"}}>
                            {/* {   (t.pivotType === undefined || t.pivotType === 0) ?
                                <Anchor title={LocaleUtility.getLocaleMessage("usage.report.field.expand")} 
                                    onClick={this.expandField.bind(this, index)} >
                                    <ExpandIcon id={"expand_"+index} name="expandIcon" size="huge" colorIndex="brand" />
                                </Anchor>
                                :
                                <Anchor title={LocaleUtility.getLocaleMessage("usage.report.field.expand")} 
                                    onClick={this.expandField.bind(this, index)} >
                                    <ContractIcon id={"expand_"+index} name="expandIcon" size="huge" colorIndex="brand" />
                                </Anchor>
                            } */}
                            <Anchor  title={LocaleUtility.getLocaleMessage("label.button.edit")} 
                                onClick={this.editActionCall.bind(this, index)} >
                                <EditIcon id={"edit_"+index} name="editIcon"   />
                            </Anchor>
                            <Anchor  title={LocaleUtility.getLocaleMessage("label.button.cancel")} 
                                onClick={this.removeElement.bind(this, index)} >
                                <TrashIcon id={"del_"+index} name="delLink" className="grommetux-control-icon grommetux-color-index-critical"/>
                            </Anchor>                   
                        </div>
                    </Tile>
                    </td>
                    <td id={"span_"+(index+1)} onDragEnter = {(e)=>this.onDragEnter(e, (index+1))} 
                    onDragLeave = {(e)=>this.onDragExit(e, (index+1))}
                    style={{width:"5px", padding:"1px", height:"60px", display:"inline-block"}}></td>
                    </tr>
                    </tbody>
                </table>
            ;   
            return t; 
        });
        this.setState({tasks: divs, objs: mapObj, elements: tempEle, update: false, stdAttrbutes: stdAttr});
    }

    editActionCall(key, event){
        let element = {...this.state.elements[key]};
        this.setState({showFieldPopup: true, fieldProp: element});
    }

    closeFieldPoolPopup(){
        this.setState({showFieldPopup: false});
    }

    expandField(key, event){
        let taskArray = [], tempObj;
        const tempElem = Object.assign([], this.state.elements);
        tempElem.map(itm => {
            tempObj = {...itm};
            if(tempObj.showOrder === key){
                if(tempObj.pivotType === 0)
                    tempObj.pivotType = 1;
                else    
                    tempObj.pivotType = 0;  
            } 
            taskArray[tempObj.showOrder] = tempObj;
            return "";
        });
        this.setState({update: true});
        this.props.updateReportFields(taskArray);
    }

    removeElement(key, event){
        let taskArray = [], tempObj;
        let index = 0;
        const tempElem = Object.assign([], this.state.elements);
        tempElem.map(itm => {
            tempObj = {...itm};
            if(tempObj.showOrder !== key){
                tempObj.showOrder = index;
                tempObj.pivotType = 0;
                taskArray[index] = tempObj;
                index++;
            }
            return "";
        });
        this.props.updateReportFields(taskArray);
    }

    submitFieldAction(field){
        let taskArray = [], tempObj;
        const tempElem = Object.assign([], this.state.elements);
        tempElem.map(itm => {
            tempObj = {...itm};
            if(tempObj.showOrder === field.showOrder){
                taskArray[field.showOrder] = field;
            } else {
                taskArray[itm.showOrder] = tempObj;
            }
            return "";
        });
        this.props.updateReportFields(taskArray);
        this.setState({showFieldPopup: false, update: true});
    }

    render () {  

        return (
            <div className="wip" 
                    onDragOver={(e)=>this.onDragOver(e)} 
                    onDrop={(e)=>{this.onDrop(e, "wip")}}>
                <Header size='small' sticky={false}
                    pad={{"horizontal": "small"}}>
                    <Heading level="4"
                        size="medium"
                        margin='none'>
                        {LocaleUtility.getLocaleMessage("label.report.field.settings")}  
                    </Heading>
                </Header>
                <div className="dragContainer">
                {this.state.tasks.length > 0 ? 
                 <Tiles flush={false} style={{border: "1px solid #ddd"}}
                    fill={false}>
                     {this.state.tasks}                        
                </Tiles>
                 : LocaleUtility.getLocaleMessage("label.no.fields.added.for.report")} 
               </div>
               {this.state.showFieldPopup ? 
                    <FieldProperty 
                        showModal = {this.state.showFieldPopup}
                        cancelAction = {this.closeFieldPoolPopup}
                        creatAction = {this.submitFieldAction}
                        attributes = {this.state.stdAttrbutes}
                        field = {this.state.fieldProp}
                    /> : ""
                }
            </div> 
        );
        
    }
}
export default DragNDrop;