import Dispatcher from "../flux/Dispatcher";
import * as LocaleUtility from "../utils/LocaleUtility";
import { Certificate } from "crypto";
import { log } from "util";

export function processTenantsList(data) {
    let payload = {
        gridData: data
    }
    Dispatcher.dispatch({
        type: "POPULATE_TENANTS_LIST",
        payload: payload
    });
}
export function processTenantDropDown(data, refreshAction) {
    let ddData = [];
    if(data !== undefined) {
        data.listTenantNames.map(obj=>{
            ddData.push({label: obj, value: obj});
            return "";
        })
    }
    let payload = {
        ddData: ddData,
        refreshAction: refreshAction
    }
    Dispatcher.dispatch({
        type: "POPULATE_TENANTS_DROPDOWN",
        payload: payload
    });
}
export function processTenantsForDropDown(data) {
    let ddData = [];
    if(data !== undefined) {
        data.map(obj=>{
            if(Number(obj.status) === 3) {
                ddData.push({label: obj.firstName, value: obj.name});
            }
            return "";
        })
    }
    let payload = {
        ddData: ddData
    }
    Dispatcher.dispatch({
        type: "LOAD_TENANT_DROP_DOWN",
        payload: payload
    });
}
export function processAppLogsList(data) {
    let  gData = data.files;
    let payload = {
        gridData: gData
    }
    Dispatcher.dispatch({
        type: "POPULATE_LOGS_LIST",
        payload: payload
    });
}
export function processConfigEntryList(data) {
    let rows = []
    data.list.map(item => {
        if(item.entryKey.indexOf("saml2") == -1){
            item.entryKeyLabel = LocaleUtility.getLocaleMessage(item.entryKey);
            rows.push(item);
        }
        return "";
    });
    let payload = {
         entryData:rows
     } 
    Dispatcher.dispatch({
        type: "POPULATE_CONFIG_ENTRY_LIST",
        payload: payload
    });
}

export function fetchFeaturesMap(data) {
    let payload = {
        featureDetails: data
    }
    Dispatcher.dispatch({
        type: "LICENSE_FETCH_FEATURE",
        payload: payload
    });
}

export function processConfigSamlEntryList(data) {
    var certificate, signonUrl, logoutUrl,identity,fileName1, fileName2, 
    adminMail, userId, localCert, localPvtKey, digAlg, sigAlg;
    var map = new Map();
    var flag;
    var count=0;
    data.list.map(item => {

        if(item.entryKey=="saml2.idp.x509cert")
        {
            certificate = item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.idp.single_sign_on_service.url")
        {
            signonUrl = item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.idp.single_logout_service.url")
        {
            logoutUrl=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.idp.entityid")
        {
            identity=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.idp.file.name")
        {
            fileName1=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.super.admin.email")
        {
            adminMail=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.sp.nameidformat")
        {
            userId=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.sp.x509cert")
        {
            localCert=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.sp.privatekey")
        {
            localPvtKey=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.sp.file.name")
        {
            fileName2=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.security.digest_algorithm")
        {
            digAlg=item.entryValue;
            item.show = false;
        } else if(item.entryKey=="saml2.security.signature_algorithm")
        {
            sigAlg=item.entryValue;
            item.show = false;
        } 

        if (item.entryKey.indexOf("saml2") > -1){
            if(item.entryKey==="saml2.security.logoutrequest_signed")
            {
               item.show=false;
               map.set(item.entryKey, item);     
            }
            else{
                  map.set(item.entryKey, item);
            }
        }
      return "";
    });

    let payload = {
        //entryData: rows
        fileName1 : fileName1,
        identity : identity,
        logoutUrl : logoutUrl,
        certificate : certificate,
        signonUrl : signonUrl,
        adminMail: adminMail,
        fileName2 : fileName2,
        localPvtKey: localPvtKey,
        localCert: localCert,
        userId: userId,
        digAlg: digAlg,
        sigAlg: sigAlg,
        map : map
    }
    Dispatcher.dispatch({
        type: "POPULATE_CONFIG_ENTRY_SAML_LIST",
        payload: payload
    });
}