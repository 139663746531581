import React, { Component } from "react";
import Toggle from 'react-toggle';
import "react-toggle/style.css";

class APLSToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.onChange = this.onChange.bind(this);
    }
    onChange(event) {
        if(this.props.onChange !== undefined) {
            this.props.onChange(this.props.value);
        }
    }
    render() {
        return(
            <div id={'toggle-div-'+this.props.id} tooltiptext={this.props.toolTipText !== undefined ? this.props.toolTipText : undefined}>
                <Toggle 
                    className="toggle-classname" 
                    id={this.props.id} 
                    //defaultChecked={this.props.defaultChecked}
                    checked={this.props.checked} 
                    disabled={this.props.disabled} 
                    icons={false}
                    onChange={this.onChange}
                />
                {this.props.rightIcon !== undefined &&(
                    <span>{this.props.rightIcon}</span>
                )}
            </div>
        ) 
    }
}
export default APLSToggle;