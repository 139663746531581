import ReduceStore from "./ReduceStore";

class UsageHubConfigurationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            usageHubPort: "",
            analyticusageflag: "",
            allowcredentialFlag: "",
            usagediscover: "",
            usageIP: "",
            usageinterval: "",
            usagehubUserName: "",
            usagehubUserPassword: "",
            status: "",
            errorDetail: null
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_USAGE_HUB_CONFIG_SETTING_DETAILS": {
                return {
                    usageHubPort: action.payload.usageHubPort,
                    analyticusageflag: action.payload.analyticusageflag,
                    allowcredentialFlag: action.payload.allowcredentialFlag,
                    usagediscover:action.payload.usagediscover,
                    usageIP: action.payload.usageIP,
                    usageinterval: action.payload.usageinterval,
                    usagehubUserName: action.payload.usagehubUserName,
                    usagehubUserPassword: action.payload.usagehubUserPassword,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail
                };
            }
            case "POPULATE_IN_USE_REPORT_RESULTS": {
                return {
                    pagination: action.payload.pagination,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    requestMap: action.payload.requestMap,
                    listReservationGroups: action.payload.listReservationGroups,
                    reportSearchList: action.payload.reportSearchList,
                    clientInfoMap: action.payload.clientInfoMap,
                    executedByMap: action.payload.executedByMap,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    pageType: "inuse",
                    exportHistoryRecordSize: action.payload.exportHistoryRecordSize,
                    enablePurgeBtn: action.payload.enablePurgeBtn,
                    defDisabledIds: action.payload.defDisabledIds,
                    enableRevokeBtn: action.payload.enableRevokeBtn,
                    defaultIds: action.payload.defaultIds,
                    groupColorsMap: action.payload.groupColorsMap,
                    poolSeries: action.payload.poolSeries,
                    groupColorPalette: action.payload.groupColorPalette,
                    data: action.payload.data
                };
            }
            case "POPULATE_HISTORY_REPORT_RESULTS": {
                return {
                    pagination: action.payload.pagination,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    requestMap: action.payload.requestMap,
                    listReservationGroups: action.payload.listReservationGroups,
                    reportSearchList: action.payload.reportSearchList,
                    clientInfoMap: action.payload.clientInfoMap,
                    executedByMap: action.payload.executedByMap,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    pageType: "history",
                    exportHistoryRecordSize: action.payload.exportHistoryRecordSize,
                    enablePurgeBtn: action.payload.enablePurgeBtn,
                    defDisabledIds: action.payload.defDisabledIds,
                    enableRevokeBtn: action.payload.enableRevokeBtn,
                    defaultIds: action.payload.defaultIds,
                    groupColorsMap: action.payload.groupColorsMap,
                    poolSeries: action.payload.poolSeries,
                    groupColorPalette: action.payload.groupColorPalette,
                    data: action.payload.data
                };
            }
            case "POPULATE_REJECTED_REPORT_RESULTS": {
                return {
                    pagination: action.payload.pagination,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    requestMap: action.payload.requestMap,
                    listReservationGroups: action.payload.listReservationGroups,
                    reportSearchList: action.payload.reportSearchList,
                    clientInfoMap: action.payload.clientInfoMap,
                    executedByMap: action.payload.executedByMap,
                    sortIndex: action.payload.sortIndex,
                    sortOrder: action.payload.sortOrder,
                    pageType: "rejected",
                    exportHistoryRecordSize: action.payload.exportHistoryRecordSize
                };
            }
            case "POPULATE_USE_REPORT_HISTORY_GRAPH": {
                return {
                    results: action.payload.results,
                    maximumCapacity: action.payload.maximumCapacity,
                    averageCapacity: action.payload.averageCapacity,
                    rejectedCapacity: action.payload.rejectedCapacity,
                    poolColorIndexById: action.payload.poolColorIndexById,
                    toolTipMap: action.payload.toolTipMap,
                    yTickValue: action.payload.yTickValue,
                    yAxisMaxValue: action.payload.yAxisMaxValue,
                    rangeAverage: action.payload.rangeAverage,
                    viewType: action.payload.viewType,
                    actionType: "usagehistory_graph"
                };
            }
            case "POPULATE_CONSUMPTION_REPORT_RESULTS": {
                return {
                    cumulativeUsageList: action.payload.cumulativeUsageList,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    sortIndex: action.payload.sortIndex,
                    pagination: action.payload.pagination,
                    sortOrder: action.payload.pagination.sortOrder,
                    pageType: "unitcons",
                    exportHistoryRecordSize: action.payload.exportHistoryRecordSize,
                    licenseUsageMap: action.payload.licenseUsageMap
                };
            }
            case "POPULATE_CONSUMPTION_REPORT_GRAPH_RESULTS": {
                return {
                    graphData: action.payload.graphData,
                    prodGraphData: action.payload.prodGraphData,
                    tenantsData: action.payload.tenantsData,
                    pageType: "unitcons_graph",
                    xAxisFormat: action.payload.xAxisFormat,
                    xAxisLabel: action.payload.xAxisLabel,
                    lineColors: action.payload.lineColors,
                    visualFilters: action.payload.visualFilters,
                    graphDataMap: action.payload.graphDataMap,
                    graphDataColorMap: action.payload.graphDataColorMap,
                    graphStartDate: action.payload.graphStartDate,
                    graphEndDate: action.payload.graphEndDate,
                    dayscount: action.payload.dayscount,
                    yAxisMaxValue: action.payload.yAxisMaxValue
                };
            }
            case "PROCESS_UPDATE_COMMUTER_DETAILS": {
                return {
                    data: action.payload.data,
                    pageType: "updatecommuter"
                };
            }
            case "POPULATE_INSTANCE_REPORT_RESULTS": {
                return {
                    instanceUsageList: action.payload.instanceUsageList,
                    licenseUsageMap: action.payload.licenseUsageMap,
                    sortIndex: action.payload.sortIndex,
                    pagination: action.payload.pagination,
                    sortOrder: action.payload.sortOrder,
                    resetFilter: action.payload.resetFilter,
                    pageType: "instancedetail"
                };
            }
            case "POPULATE_INSTANCE_DETAIL": {
                return {
                    instanceUsageList: action.payload.instanceUsageList,
                    pageType: "uniqueinstancedetail"
                };
            }
            default:
                return state;
        }
    }
}
export default new UsageHubConfigurationStore();