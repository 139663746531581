import ReduceStore from "./ReduceStore";

class IDMConfigurationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            tenantinfo: "",
            idmservicename: "",
            idmport: "",
            idmserver: "",
            password: "",
            username: "",
            status: "",
            errorDetail: null
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_IDM_CONFIG_SETTING_DETAILS": {
                return {
                    tenantinfo: action.payload.tenantinfo,
                    idmservicename: action.payload.idmservicename,
                    idmport: action.payload.idmport,
                    idmserver: action.payload.idmserver,
                    username: action.payload.username,
                    password: action.payload.password,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail
                };
            }
            default:
                return state;
        }
    }
}
export default new IDMConfigurationStore();