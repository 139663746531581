import React, { Component } from "react";
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from 'grommet/components/Header';
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import Store from "../../../flux/APTableStore";
import ProductStore from "../../../flux/ProductStore";
import LicenseDetailStore from "../../../flux/LicenseDetailStore";
import { Download } from 'grommet-icons';
import SpinningNotification from '../../generic/SpinningNotification';
import NamedUserMembers from "./subcomponents/NamedUserMembers";
import LicenseDetail from "./subcomponents/LicenseDetail";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as LicenseUtility from "../../../utils/LicenseUtility";
import * as Utils from "../../../utils/Util";
import Dispatcher from "../../../flux/Dispatcher";
import Modal from "../../generic/Modal";
import { Anchor } from 'grommet/components/Anchor';
import Select from "../../commons/APLSSelect";
import { Upload } from 'grommet-icons';
import { History } from 'grommet-icons';
import AplsJobs from "../config/AplsJobs";
import { Button } from "grommet/components/Button";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timer = null;
class NamedUserLicense extends Component {
    constructor(props) {
        super(props);
        this.poolMemRef = React.createRef();
        this.jobPopupRef = React.createRef();
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "poolCreationTime",
                sortOrder: "DESC"
            },
            sortIndex: 6,
            sortAscending: false,
            showCreatePopup: false,
            poolNameError: "",
            poolDescError: "",
            poolParentError: "",
            selectedPoolName: "",
            poolsMap: {},
            defaultSelectIds: [],
            productName: undefined,
            featureId: undefined,
            featureVersion: undefined,
            searchCreatedTime: "",
            searchCreatedBy: "",
            showPoolMapping: false,
            searchAction: false,
            searchCreatedValue: "",
            session: undefined,
            prodAdmin: false,
            selectedFileName: undefined,
            submitFiles: undefined,
            productNameSelected: undefined,
            productNameSelectOptions: ProductStore.getState().productNameSelectOptions,
            featureSelectOptions: [],
            licenseDetail: undefined,
            activeIndex: 0,
            jobCategory:"",
            jobName: ""
        }

        this.refreshProductItem = this.refreshProductItem.bind(this);
        this.filterFeatureList = this.filterFeatureList.bind(this);
        this.onColumnClicked = this.onColumnClicked.bind(this);
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.loadPoolsGrid = this.loadPoolsGrid.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.buildSearchParams = this.buildSearchParams.bind(this);
        this.refreshModal = this.refreshModal.bind(this);
        this.onModalCloseCallback = this.onModalCloseCallback.bind(this);

        this.submitSearch = this.submitSearch.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.continueFilterAction = this.continueFilterAction.bind(this);
        this.resetPoolGrid = this.resetPoolGrid.bind(this);
        this.recursiveCallToCheckProdAdmin = this.recursiveCallToCheckProdAdmin.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.continueConfirmAction = this.continueConfirmAction.bind(this);
        this.closeJobsPopup = this.closeJobsPopup.bind(this);
        this.showJobsPopup = this.showJobsPopup.bind(this);
        this.showJobsPopupFromExpiredTab = this.showJobsPopupFromExpiredTab.bind(this);
        this.downloadTemplateForLicense = this.downloadTemplateForLicense.bind(this);
        this.refreshLicenseList = this.refreshLicenseList.bind(this);
        this.tabClickAction = this.tabClickAction.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.rowData !== this.state.rowData || this.state.session === undefined) {
            ConfigurationUtils.hideLoadMask(this.context.intl);
            this.setState({session: FetchHelper.getSession()});
        }
    }

    componentDidMount() {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        Store.on("change", this.loadPoolsGrid);
        Store.on("change", this.resetPoolGrid);
        let params = this.buildSearchParams(this.state.productName, this.state.featureId,
                this.state.featureVersion, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
        this.findData(params);
        FetchHelper.getProducts("NAMED_USER_LIC_TYPE", false);
        ProductStore.on("change", this.refreshProductItem);
        LicenseDetailStore.on("change", this.refreshModal);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.loadPoolsGrid);
        Store.removeListener("change", this.resetPoolGrid);
        ProductStore.removeListener("change", this.refreshProductItem);
        LicenseDetailStore.removeListener("change", this.refreshModal);
    }

    onSortClicked(index, order, header) {
        var sortOrder = "ASC";
        let paginationTemp = this.state.pagination;
        
        if(paginationTemp.sortOrder === "ASC"){
            sortOrder = "DESC";
        }
        
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = header[index].columnName;
        paginationTemp.pageNumber = paginationTemp.pageNumber;
        this.setState({ pagination: paginationTemp });
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
        this.findData(params);
    }
    refreshLicenseList() {
        let paginationTemp = this.state.pagination;
        let selectedLicenseId = this.state.licDetails.licId;
       
        this.setState({ pagination: paginationTemp });
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
        this.setState({request: params});
        FetchHelper.getAllNamedUserLicenses(params, this.context.intl, selectedLicenseId);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
        this.findData(params);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onClickDelete(key, event) {
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(isAdmin || this.state.prodAdmin) {
            let messageParams = {};
            messageParams["poolName"] = key;
            let msg = LocaleUtility.getLocaleMessage("resv.pool.delete.warning", messageParams);
            if(ValidationUtility.confirm(msg, "", "")) {
                FetchHelper.deleteReservationPool(this.state.request, key, this.context.intl);
            }
        }
    }

    refreshProductItem() {
        const products = ProductStore.getState().productNameSelectOptions
        const features = ProductStore.getState().featureSelectOptions

        if(products !== undefined) {
            this.setState({
                productNameSelectOptions: products,
            });
        }
        if(features !== undefined) {
            this.setState({
                featureSelectOptions: features,
                productName: ProductStore.getState().productNameSelected,
                productNameSelected: ProductStore.getState().productNameSelected
            });
        }
    }

    filterFeatureList(key, event){
        if(event.option.value !== undefined){

            const errors = Object.assign([], this.state.errorcode);
            let index = errors.indexOf('productName');
            if (index !== -1) {
                errors.splice(index, 1);
            }
            let loadSpinAction = "FEATURE_FETCH_INPROGRESS_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            
            let params = undefined;
            if(key){
                var featureDetails = event.option.value.split(":");
                this.setState({selectedFeature: {value:event.option.value, label:event.option.label},
                                featureID: featureDetails[0], featureVersion:featureDetails[1]});
                params = this.buildSearchParams(this.state.productName, featureDetails[0],
                featureDetails[1], this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
                this.findData(params);
            } else {
                this.setState({productName: event.option.value, productNameSelected: event.option.value,
                featureID: undefined, featureVersion:undefined });   
                FetchHelper.getAllNamedFeatures(event.option.value);
                if(event.option.value === "" || event.option.value === undefined){
                    this.setState({selectedFeature: {value:undefined, label:LocaleUtility.getLocaleMessage("label.select.product")},
                            errorcode: errors});
                } else {
                    this.setState({selectedFeature: {value:"", label:LocaleUtility.getLocaleMessage("label.select.feature")},
                            errorcode: errors});
                }
                params = this.buildSearchParams(event.option.value, undefined,
                undefined, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction, this.state.activeIndex);
                this.findData(params);
            }
        }
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

    findData(params) {
        //this.setState({rowData: []});
        this.setState({request: params});
        FetchHelper.getAllNamedUserLicenses(params, this.context.intl);
    }

    onColumnClicked(key, event){
        let loadSpinAction = "LICENSE_DETAIL_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        FetchHelper.getLicenseDetail(key);
    }

    onModalCloseCallback() {
        this.setState({
            licenseDetail: undefined,
        });
    }

    refreshModal() {
        this.setState({
            licenseDetail: LicenseDetailStore.getState().licenseDetail,
        });
        let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: ''
        });
    }

    infoSectionContent(licDetail) {
        if(licDetail !== undefined) {
            return (
                <LicenseDetail id="lic_view_detail" licenseDetail={licDetail}/>
            );
        }
    }

    modalLicenseDetailContent() {

        let licDetail = this.state.licenseDetail;
        let sectionInfoTitle = '', showModal = false;
        
        if(licDetail !== undefined){
            if(licDetail.featureDescription !== undefined){
                sectionInfoTitle = licDetail.featureDescription +
                    ' ( '+licDetail.featureID +':'+licDetail.featureVersion+' )';
            } else {
                sectionInfoTitle = licDetail.featureID +':'+licDetail.featureVersion;
            }
            showModal = true
        }
        return (
            <Modal id="nameduser_lic_detailMod" title={sectionInfoTitle} showModal={showModal} styleProps={{top: "48%", overflowY: "auto",maxWidth: "91%", minWidth: "50%", width: "100%", maxHeight:"85%"}}
                    closeLabel={LocaleUtility.getLocaleMessage("label.button.close")}
                    sectionContent={this.infoSectionContent(licDetail)}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    />
        );
    }
    recursiveCallToCheckProdAdmin(poolsData, prodAdmin) {
        poolsData.map(item => {
            if(!prodAdmin){
                item["delete"] = "";
                item["downloadIcon"] = "";
            }
            if(item.childs && item.childs.length > 0) {
                this.recursiveCallToCheckProdAdmin(item.childs, prodAdmin);
            }
            return "";
        });
    }

    loadPoolsGrid() {

        let sortIndex = 6;
        let indexx = Store.getState().sortIndex;
        switch (indexx) {
            case "featureDesc":
                sortIndex=0;
                break;
            case "productID":
                sortIndex=1;
                break;
            case "ltuCapacity":
                sortIndex=2;
                break;
            case "capacity":
                sortIndex=3;
                break;
            case "assignedNamedUserCount":
                sortIndex=4;
                break;
            case "startDate":
                sortIndex=5;
                break;
            case "expiryDate":
                sortIndex=6;
                break;
            case "installedDate":
                sortIndex=7;
                break;
            case "installedBy":
                sortIndex=8;
                break;        
            default:
                sortIndex=7;
                break;
        }
        var sessObj = FetchHelper.getSession();
        var pagination = Store.getState().licpagination; 
        let defaultSelId = [Store.getState().defaultLicId];
        var slCount = 0;
        var slKeyMap = new Map();
        if(pagination !== undefined){
            slCount = ((pagination.pageNumber-1) * pagination.pageSize)+1;
        }
        if(Store.getState().type === 'NAMEDUSER_LIC_LIST'){
            if(sessObj.admin !== undefined && sessObj.admin) {
                if(Store.getState().namedUserLicData!==undefined){
                    Store.getState().namedUserLicData.map(item => {
                        item.slno = slCount;
                       
                        var featureLicenseIds = [];
                        if(item.licenseList != undefined){
                            item.licenseList.forEach(function(obj){
                                slKeyMap.set(obj.licenseID, {"licName":obj.featureDesc, 
                                        "licId":obj.licenseID,"featId":obj.featureID, "featVersion": obj.featureVersion});
                                obj.downloadIcon = <Anchor title={LocaleUtility.getLocaleMessage("named.user.tab.label")}><Download colorIndex="brand"/></Anchor>;
                                obj.assignedNamedUserCount = <span id={"namedUserCount_"+obj.licenseID}>{obj.assignedNamedUserCount}</span>
                                featureLicenseIds.push(obj.licenseID);
                            });
                        }
                        item.assignedNamedUserCount = <span id={"namedUserCount_"+item.featureID}>{item.assignedNamedUserCount}</span>

                        if (featureLicenseIds.length === 0){
                            featureLicenseIds.push(item.licenseID.replace(/_ALL/g, ""));
                        }
                        slKeyMap.set(item.licenseID, {"licName":item.featureDesc, 
                                        "licId":item.licenseID,"featId":item.featureID, "featVersion": item.featureVersion, "featureLicenseIds": featureLicenseIds});
                        item.downloadIcon = <Anchor title={LocaleUtility.getLocaleMessage("named.user.tab.label")}><Download colorIndex="brand"/></Anchor>;
                        slCount++;
                        return item;
                    });

                    this.setState({
                        rowData: Store.getState().namedUserLicData,
                        pagination: pagination,
                        sortIndex: sortIndex,
                        sortAscending: Store.getState().sortAscending,
                        defaultSelectIds: defaultSelId,
                        licDetails: slKeyMap.get(defaultSelId[0]),
                        licId: defaultSelId[0],
                        session: sessObj,
                        slKeyMap: slKeyMap
                    });
                }
            } else if(sessObj.admin !== undefined && !sessObj.admin && 
                sessObj.productList !== undefined ){
                var features = [];
                var validFeatures = FetchHelper.getUserFeatures();
                validFeatures.then(data => {
                    features = data;
                    if(Store.getState().namedUserLicData!==undefined){
                        Store.getState().namedUserLicData.map(item => {
                            var featureLicenseIds = [];
                            if(item.licenseList != undefined){
                                item.licenseList.forEach(function(obj){
                                    slKeyMap.set(obj.licenseID, {"licName":obj.featureDesc, 
                                        "licId":obj.licenseID,"featId":obj.featureID, "featVersion": obj.featureVersion});
                                    obj.assignedNamedUserCount = <span id={"namedUserCount_"+obj.licenseID}>{obj.assignedNamedUserCount}</span>
                                    featureLicenseIds.push(obj.licenseID);
                                });
                            }
                            item.slno = slCount;
                            slKeyMap.set(item.licenseID, {"licName":item.featureDesc, 
                                        "licId":item.licenseID,"featId":item.featureID, "featVersion": item.featureVersion, "featureLicenseIds": featureLicenseIds});
                            slCount++;
                            return item;
                        });
                        this.setState({
                            rowData: Store.getState().namedUserLicData,
                            features: features,
                            pagination: pagination,
                            sortIndex: indexx,
                            sortAscending: Store.getState().sortAscending,
                            defaultSelectIds: defaultSelId,
                            licDetails: slKeyMap.get(defaultSelId[0]),
                            licId: defaultSelId[0],
                            session: sessObj,
                            slKeyMap: slKeyMap
                        });
                    }
                }).catch((status, error) => {
                    LicenseUtility.processError(status, error);
                });
            }    

            if(slKeyMap !== undefined) {
                let licDetails = slKeyMap.get(defaultSelId[0]);
                if(this.poolMemRef && this.poolMemRef.current && licDetails !== undefined && !this.poolMemRef.current.state.isUserAddedOrDeleted) {
                    this.poolMemRef.current.refreshMembers(licDetails, true);
                }
            }

            var dateRE = /^grid-tr-/;
            var dates=[],els=document.getElementsByClassName('table-grid-tr');
            for (var i=els.length;i--;){
                if (dateRE.test(els[i].id)){
                    dates.push(els[i].id);
                } 
            }

            var gridComp;
            for(let k=0; k<dates.length;k++) {
                gridComp = document.getElementById(dates[k]);
                if(gridComp !== undefined && gridComp !== null && gridComp !== "null"){
                    if(gridComp.style.display != undefined && gridComp.style.display != ""){
                        gridComp.style.backgroundColor = "rgb(232 235 237)";
                    }else{
                        gridComp.style.backgroundColor = "";
                    }
                    gridComp.classList.remove("grommetux-table-row--selected");
                }
            }

            var defaultRow = document.getElementById("grid-tr-"+defaultSelId[0]);
            if(defaultRow !== undefined && defaultRow !== null && defaultRow !== "null"){
                defaultRow.style.backgroundColor = "#fad1f0";
            }
            let loadSpinAction = "LICENSE_FETCH_SPIN_DONE";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: ''
            });
        }
    }
    onRowClicked(keys, event) {
        // alert(keys);
        var dateRE = /^grid-tr-/;
        var dates=[],els=document.getElementsByClassName('table-grid-tr');
        for (var i=els.length;i--;){
            if (dateRE.test(els[i].id)){
                dates.push(els[i].id);
            } 
        }

        for(let k=0; k<dates.length;k++) {  
            let element = document.getElementById(dates[k]); 
            if(element.style.display != undefined && element.style.display != ""){
                element.style.backgroundColor = "rgb(232 235 237)";
            }else{
                element.style.backgroundColor = "";
            }
            element.classList.remove("grommetux-table-row--selected");
        }
        document.getElementById("grid-tr-"+keys[0]).style.backgroundColor = "#fad1f0";
        let licDetails = this.state.slKeyMap.get(keys[0]);
        this.setState({selectedPoolName: this.state.productName, });
        this.setState({defaultSelectIds: [keys[0]], licDetails: licDetails});
        if(this.poolMemRef && this.poolMemRef.current) {
            this.poolMemRef.current.refreshMembers(licDetails, true);
        }
    }
    submitSearch() {
        this.setState({searchAction: true});
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        this.findData(params);
    }
    buildSearchParams(productName, featureId, featureVersion, createdBy, createdOn, pagination, index, searchAction, activeTabIndex) {
        let createdTime = null;
        if(createdOn && createdOn !== "") {
            createdTime = new Date(createdOn).getTime()/1000;
        }
        if(productName === "" && featureId === "" && featureVersion === "" && createdBy === "" && createdOn === "") {
            searchAction = false;
            this.setState({searchAction: false});
        }
        let params = {
            "productName": productName,
            "featureId": featureId,
            "featureVersion": featureVersion,
            "poolCreatedBy": createdBy,
            "poolCreationTime": createdTime,
            "pagination": pagination,
            "sortIndex": index,
            "searchAction": searchAction,
            "loadExpiredLicenses": (activeTabIndex === 1 ? true : false)
        }
        return params;
    }

    onFilterClicked(filterColumn, filterValue, e) {
        if(timer !== null) {
            clearTimeout(timer);
        }
        if(e !== undefined && e.charCode === 13) {
            this.continueFilterAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueFilterAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }
    continueFilterAction(filterColumn, filterValue) {
        this.setState({searchAction: true});
        let params;
        if(filterColumn === "poolName") {
            this.setState({searchPoolName: filterValue});
            params = this.buildSearchParams(filterValue, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        } else if(filterColumn === "poolDesc") {
            this.setState({searchDescription: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, filterValue,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        } else if(filterColumn === "parent") {
            this.setState({searchParentName: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                filterValue, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        } else if(filterColumn === "poolCreatedBy") {
            this.setState({searchCreatedBy: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, filterValue, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        } else if(filterColumn === "poolCreationTime") {
            this.setState({searchCreatedValue: filterValue.value});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, filterValue,
                this.state.pagination, this.state.sortIndex, true, this.state.activeIndex);
        }
        this.findData(params);
    }
    resetPoolGrid() {
        if(Store.getState() && Store.getState().actionType === "resetpools") {
            this.setState({rowData: []});
            this.setState({searchAction: false});
            this.setState({searchPoolName: ""});
            this.setState({searchDescription: ""});
            this.setState({searchParentName: ""});
            this.setState({searchCreatedBy: ""});
            this.setState({searchCreatedValue: ""});
        }
    }

    downloadTemplateForLicense(key, event) {
        let isExpiredLicenseTabView = false;
        if(this.state.activeIndex !== undefined && this.state.activeIndex === 1 ) {
            isExpiredLicenseTabView = true;
        }
        let licDetails = this.state.slKeyMap.get(key);
        FetchHelper.exportNamedUsers(licDetails, this.context.intl, isExpiredLicenseTabView)
    }

    downloadTemplate() {
        let  postData = "";
        if (this.state.productName !== "" && this.state.productName !== undefined) {
            postData = {
                productNameList: { value: [this.state.productName] },
                featureID: this.state.featureID,
                featureVersion: this.state.featureVersion,
            };
        }else{
            postData = {
                productNameList: "",
                featureID: "",
                featureVersion: "",
            };
        }
        FetchHelper.downloadNamedUserTemplate(postData, this.context.intl)
    }

    onFileChange(event) {
        let files = Array.from(event.target.files);
        if(files !== undefined && files.length > 0) {
            let fileName = files[0].name;
            if(fileName.endsWith(".csv") || fileName.endsWith(".CSV")) {
                this.setState({selectedFileName: fileName, submitFiles: files[0]});
                let fileLength = files[0].size;
                let fileSize = 0;
                if(fileLength > 0) {
                    fileSize = fileLength /(1024 * 1024);
                }
                if(fileSize > 5) {
                    ConfigurationUtils.showErrorMessage1("61004", this.context.intl);
                } else {
                    this.continueConfirmAction(files[0], fileName);
                }
            } else {
                ConfigurationUtils.showErrorMessage1("reservation.invalid.template", this.context.intl);
            }
            document.getElementById("import-file-id").value = "";
        }
    }
    continueConfirmAction(file, fieName) {
        let data = new FormData();
        data.append('file', file);
        data.append('fileName', fieName);
        FetchHelper.importNamedUserIdTemplate(data, this.context.intl);
    }
    closeJobsPopup() {
        this.setState({showJobsModal: false});
    }
    showJobsPopup() {
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.jobPopupRef !== undefined && this.jobPopupRef.current !== undefined && this.jobPopupRef.current != null) {
            this.jobPopupRef.current.state.category="RESERVATION";
            this.jobPopupRef.current.state.jobName="IMPORT_NAMEDUSERID";
            this.jobPopupRef.current.loadGrid();
            this.setState({showJobsModal: true});
        }
    }
    showJobsPopupFromExpiredTab() {
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.jobPopupRef !== undefined && this.jobPopupRef.current !== undefined && this.jobPopupRef.current != null) {
            this.jobPopupRef.current.state.category="NAMEDUSER";
            this.jobPopupRef.current.state.jobName="MOVE_EXPIRED_NAMEDUSER";
            this.jobPopupRef.current.loadGrid();
            this.setState({showJobsModal: true});
        }
    }
    tabClickAction(index, event) {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        if(this.poolMemRef.current != undefined && this.poolMemRef.current != null){
            this.poolMemRef.current.state.isUserAddedOrDeleted=false;
        }
        this.setState({activeIndex: index});
        let params = this.buildSearchParams(this.state.productName, this.state.featureId,
                this.state.featureVersion, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction, index);
        this.findData(params);
    }
    render() {
        let expandable = false;
        if(this.state.searchAction) {
            expandable = true;
        }
        //let intl = this.context.intl;
        let fullView = false;
        let ss = this.state.session;
        if(ss !== undefined && ss.roletype !== undefined && ss.roletype === "fullview") {
            fullView = true;
        }

        //Check for Active or Expired Tab
        let activeLicenseTabView = true;
        let activeTabIndex = this.state.activeIndex;
        if(activeTabIndex !== undefined && activeTabIndex === 1 ) {
            activeLicenseTabView = false;
        }
        
        const productOptions = [], featureOptions = []; 
        let selectedValue = {value:"", label:LocaleUtility.getLocaleMessage("label.select.all")};
        productOptions.push(selectedValue);
        let selectedFeature = { value:undefined, label:LocaleUtility.getLocaleMessage("label.select.product")};
        let nolicMsg =<span style={{width: "100%", float: "left", padding: "15px"}}>
                        {this.findLabel("usageNavigateLicPage")} <Anchor id="nameduser_install_link" label={this.findLabel("usageNavigateHere")} href={FrontendPagePath.LICENSE_PATH+Utils.buildURLQueryParam()}/> {this.findLabel("usageNavigateInstall")}
                    </span>
        var entries = this.state.productNameSelectOptions.entries()
        for(var item of entries){
            productOptions.push({value:item[0], label:item[1]})
            if(this.state.productNameSelected !== undefined && item[0] === this.state.productNameSelected){
                selectedValue = {value:item[0], label:item[1]};
                selectedFeature = {value:"", label:LocaleUtility.getLocaleMessage("label.select.feature")};
            }
        }
        this.state.featureSelectOptions.map(value => featureOptions.push({value:value, label:value}));

        if(this.state.selectedFeature !== undefined){
            selectedFeature = this.state.selectedFeature;
        }

        let showJobPop = this.state.showJobsModal ? "jobs-popup-modal display-block" : "jobs-popup-modal display-none";
        if(this.state.showPoolMapping) {
            // return(
            //     <PoolMappingProducts 
            //         onClickBackButton = {this.onClickReservationBackButton}
            //         poolName = {this.state.selectedPoolName}
            //     />
            // )
        } else {
            return(
                <div className="config-cont-backgroud">
                    <SectionWithTitle title={this.findLabel("resv.pool.manage.title")}  helpTopic="NamedUser_License"
                        setionWidth = "4"
                    />
                    <div>
                        <Header fixed={false} margin="none" id="header2" sticky={false} style={{height:"50px"}} >
                                        <Tabs activeIndex={this.state.activeIndex} justify='start' onActive={this.tabClickAction} >
                                            <Tab title={LocaleUtility.getLocaleMessage("label.active")} className="inuseInActive">
                                            </Tab>
                                            <Tab title={LocaleUtility.getLocaleMessage("label.expired")}  className="inuseInActive">
                                            </Tab>
                                        </Tabs>
                        </Header>
                    </div>
                    <div>
                        {!fullView ? 
                            <div id="nameduser_filter_sec" className="action_filter_wrapper">
                                        <div className="action_wrap_left">
                                            <div>
                                                <Select label={LocaleUtility.getLocaleMessage("label.select.product")+": "} 
                                                id="nameduser_selProduct" options={productOptions} value={selectedValue} onChange={this.filterFeatureList.bind(this, false)}/>
                                            </div>
                                            <div>
                                                <Select id="nameduser_selfeature" label={LocaleUtility.getLocaleMessage("label.grid.col.featureid.version")+": "} 
                                                    name="feature" options={featureOptions} value={selectedFeature} 
                                                    onChange={this.filterFeatureList.bind(this, true)}/>
                                            </div>
                                        </div>
                                        {activeLicenseTabView ? 
                                        <div className="flexBox button_right_wrap">
                                            <div className="button_wrap_primary with_icon">
                                                <Button className="createPoolBtn" onClick={this.downloadTemplate}>
                                                    <span id="download-pool-template-id"><Download id="download-pool-template-icon-id" size="huge" colorIndex="light-1" /></span><span style={{paddingLeft: "5px", display: "block ruby"}}>{this.findLabel("label.download.button")}</span>
                                                </Button>
                                            </div>
                                            <div className="button_wrap_primary with_icon">
                                                <Button className="createPoolBtn">
                                                    <span id="import-pool-template-id">
                                                        <Upload id="import-pool-template-icon-id" size="huge" colorIndex="light-1" />
                                                    </span>
                                                    <label id="import-pool-option-id" htmlFor="import-file-id" style={{paddingLeft: "5px", display: "block ruby"}}>{this.findLabel("label.import.button")}</label>
                                                    <input hidden={true} type="file" id="import-file-id" name="import-file-id" accept=".csv" onChange={this.onFileChange}></input>
                                                </Button>
                                            </div>
                                            <div className="button_wrap_primary with_icon" title={this.findLabel("label.import.pool.history.tooltip")}>
                                                <Button className="pool-import-history" onClick={this.showJobsPopup}>
                                                    <span id="history-pool-icon-id"><History colorIndex="brand" /></span>
                                                </Button>
                                            </div>
                                        </div>
                                        : null}
                            </div>
                        :null}
                    </div>
                    <div className="tableBody">
                        {this.state.rowData.length > 0 || this.state.searchAction ?
                            <TableGrid id="nameduser_lic_grid"
                                header={[
                                    // {
                                    //     columnName: "slno",
                                    //     headerLabel: LocaleUtility.getLocaleLabel(this.context.intl, "label.grid.col.slno"),
                                    //     width: "5%",
                                    //     sort: false
                                    // },
                                    {
                                        columnName: "featureDesc",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.feature"),
                                        width: "25%",
                                        columnKey: "licenseSignature",
                                        onClick: this.onColumnClicked,
                                        sort: true
                                    },
                                    {
                                        columnName: "productID",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.prodNumber"),
                                        href: false,
                                        width: "9%",
                                        sort: true
                                    },
                                    {
                                        columnName: "ltuCapacity",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.ltu"),
                                        href: false,
                                        width: "3%",
                                        sort: true
                                    },
                                    {
                                        columnName: "capacity",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.capacity"),
                                        href: false,
                                        width: "5%",
                                        sort: true
                                    },
                                    {
                                        columnName: "assignedNamedUserCount",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.nameduser.count"),
                                        href: false,
                                        width: "6%",
                                        sort: false
                                    },
                                    {
                                        columnName: "startDate",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.startDate"),
                                        href: false,
                                        width: "8%",
                                        sort: true
                                    },
                                    {
                                        columnName: "expiryDate",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.expDate"),
                                        href: false,
                                        width: "8%",
                                        sort: true
                                    },
                                    {
                                        columnName: "installedDate",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.installedOn"),
                                        href: false,
                                        width: "8%",
                                        sort: true
                                    },
                                    {
                                        columnName: "installedBy",
                                        headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.installedBy"),
                                        href: false,
                                        width: "5%",
                                        sort: true
                                    },
                                    {
                                        columnName: "licenseType",
                                        headerLabel: LocaleUtility.getLocaleMessage(
                                        "label.grid.col.licenseType"
                                        ),
                                        href: false,
                                        width: "6%",
                                        sort: true
                                    },
                                    {
                                        columnName: "downloadIcon",
                                        headerLabel: this.findLabel("job.label.download.icon"),
                                        href: false,
                                        width: "auto",
                                        columnKey: "licenseID",
                                        textAlign: "center",
                                        onClick: this.downloadTemplateForLicense,
                                    }
                                ]}
                                rowData={this.state.rowData}
                                pagination={this.state.pagination}
                                rowSelection={1}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                rowKey="licenseID"
                                onChangeCheckBoxCallback={this.onRowClicked}
                                childKey="licenseList"
                                expandAll={expandable}
                                defaultSelectIds={this.state.defaultSelectIds}
                                selectAllRows={false}
                                noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                itemsCountMessage={LocaleUtility.getLocaleMessage(
                                "label.grid.item.count.message", {count: (this.state.pagination!==undefined)?this.state.pagination.totalCount:0})}
                            />
                        
                        : <p id="nameduser_no_lic_msg" className="reservation-emtpy-message">{ !fullView ? nolicMsg: this.findLabel("resv.pool.not.found.message")} </p>
                        }
                    </div>
                    {this.state.rowData.length > 0 ?
                    <div className="reservation-pool-attributes border_white_backg_shadow">
                        <NamedUserMembers ref={this.poolMemRef}
                            licDetails = {this.state.licDetails}
                            poolSize = {this.state.rowData.length}
                            fullView = {fullView}
                            refreshLicenseList = {this.refreshLicenseList}
                            activeLicenseTabView = {activeLicenseTabView}
                            showJobsPopupFromExpiredTab= {this.showJobsPopupFromExpiredTab}
                        />
                    </div>
                    : null}

                    <div id="jobs-popup-div-main" className={showJobPop}>
                        <div id="jobs-popup-div-id" className="jobs-popup-modal" >
                            <div className="modal-main panel panel-default" style={{width: "85%", maxHeight: "95%"}}>
                                <div>
                                    <table style={{marginBottom: "0px"}}>
                                        <tbody>
                                            <tr>
                                                <td id="history-popup-grid-td">
                                                    <AplsJobs ref={this.jobPopupRef}
                                                        rowData = {this.state.jobsData}
                                                        category= {this.state.jobCategory}
                                                        jobName= {this.state.jobName}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{float: "right", padding: "5px"}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td id="history-popup-cancel-td">
                                                    <Button id="history-popup-canelBtn" label={this.findLabel("label.button.close")} plain={false} secondary={true} onClick={this.closeJobsPopup} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                    {this.modalLicenseDetailContent()}
                </div>
            )
        }
    }
}
// NamedUserLicense.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default NamedUserLicense;