import React, { Component } from "react";
import "../../../css/App.css";
import "../../../css/index.css";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Button } from "grommet/components/Button";
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";

import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import UserStore from "../../../flux/UserDetailStore";
import { Tabs } from 'grommet/components/Tabs';
import { Tab } from 'grommet/components/Tab';
import { Header } from "../../commons/grommet/Header";
import TableGrid from "../../../components/commons/table/APTable";
import Select from "../../../components/commons/APLSSelect";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import Dispatcher from "../../../flux/Dispatcher"
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import File from "../../generic/APFile";
import store from "../../../flux/MultiTenantStore";
import { CheckBox } from "grommet/components/CheckBox";
import { useIntl } from 'react-intl';
import Modal from "../../generic/Modal";
import { Upload } from 'grommet-icons';
import { Download } from 'grommet-icons';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Saml extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isFormDirty: false,
            rowData: [],
            modifiedObjects: [],
            fileName:"",
            localfileName: "",
            identity:"",
            signonUrl:"",
            logoutUrl:"",
            adminmailError: "",
            identityError:"",
            signonUrlError:"",
            logoutUrlError:"",
            localPvtKey: "",
            fileNameError:"",
            localfileNameError:"",
            formError:"",
            pvtKeyError:"",
            map:new Map(),
            idpCert:undefined,
            spCert:undefined,
            filearr:[],
            localfilearr:[],
            metadataarr:[],
            users:[],
            enableSAML: false,
            isAdminReset: false,
            enteredUserName: "",
            adminEmail:"",
            data:undefined,
            showModal:false,
            
            
            selAttrType: {
              value: false,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            defaultNone: {
              value: false,
              label: LocaleUtility.getLocaleMessage("label.select.none")
            },
            digAlgs: [{ value: false,
                        label: LocaleUtility.getLocaleMessage("label.select.none")},
                        { value: "http://www.w3.org/2000/09/xmldsig#sha1", label: "SHA1" },
                        { value: "http://www.w3.org/2001/04/xmlenc#sha256", label: "SHA256"},
                        { value: "http://www.w3.org/2001/04/xmldsig-more#sha384", label: "SHA384"},
                        { value: "http://www.w3.org/2001/04/xmlenc#sha512", label: "SHA512"},
                        ],
            sigAlgs: [{ value: undefined,
                        label: LocaleUtility.getLocaleMessage("label.select.none")},
                        { value: "http://www.w3.org/2000/09/xmldsig#rsa-sha1", label: "RSA_SHA1" },
                        { value: "http://www.w3.org/2001/04/xmldsig-more#rsa-sha256", label: "RSA_SHA256"},
                        { value: "http://www.w3.org/2001/04/xmldsig-more#rsa-sha384", label: "RSA_SHA384"},
                        { value: "http://www.w3.org/2001/04/xmldsig-more#rsa-sha512", label: "RSA_SHA512"},
                        ],
        };
        this.findLabel = this.findLabel.bind(this);
        this.uploadFileRef = React.createRef();
        this.localFileRef = React.createRef();
        this.metadataFileRef=React.createRef();
        this.findDate = this.findData.bind(this);
        this.submitChanges = this.submitChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.changeFileName=this.changeFileName.bind(this);
        this.changeIdentity=this.changeIdentity.bind(this);
        this.changeSignonUrl=this.changeSignonUrl.bind(this);
        this.changelogoutUrl=this.changelogoutUrl.bind(this);
        this.loadGridData=this.loadGridData.bind(this);
        this.onConfirmValidate=this.onConfirmValidate.bind(this);
        this.makeFormDirty = this.makeFormDirty.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
        this.selectAttributeType = this.selectAttributeType.bind(this);
        this.handleChangeAdminEmail = this.handleChangeAdminEmail.bind(this);
        this.changeKeyValue = this.changeKeyValue.bind(this);
        this.changePrivateKey = this.changePrivateKey.bind(this);
        this.changeLocalFileName = this.changeLocalFileName.bind(this);
        this.selectDigAlg = this.selectDigAlg.bind(this);
        this.selectSigAlg = this.selectSigAlg.bind(this);
        this.importMetaData=this.importMetaData.bind(this);
        this.uploadMetaDataFile=this.uploadMetaDataFile.bind(this);
        this.exportSamlData=this.exportSamlData.bind(this);
        this.infoSectionContent=this.infoSectionContent.bind(this);
        this.importFile=this.importFile.bind(this);
        this.onModalCloseCallback=this.onModalCloseCallback.bind(this);
        this.clearFieldError = this.clearFieldError.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.clearErr = this.clearErr.bind(this);
    }

   componentDidMount() {
        store.on("change", this.loadGridData);
        //UserStore.on("change", this.refreshUser);
        this.findData();
        const groupPayload={};
        //FetchHelper.searchUser(groupPayload); 
    }
    componentWillUnmount() {
        store.removeListener("change", this.loadGridData);
        //UserStore.removeListener("change", this.refreshUser);
    }

    makeFormDirty() {
        this.setState({isFormDirty: true});
    }

     clearFieldError() {
       this.setState({ 
            adminmailError: "",
            identityError:"",
            signonUrlError:"",
            logoutUrlError:"",
            fileNameError:"",
            localfileNameError:"",
            formError:"",
            pvtKeyError:""});
    }

    changeIdentity(event)
    {    
        if(!(ValidationUtility.isValidHttpUrl(event.target.value))){  
            this.setState({identityError: this.findLabel("saml2.url.invalid"),
                            identity: event.target.value}); ;
        } else {
            this.makeFormDirty();
            var map=this.state.map; 
            var item = map.get("saml2.idp.entityid");  
            if(item !== undefined) { 
                item.entryValue=event.target.value;
                map.set("saml2.idp.entityid",item);
            }
            this.setState({identity: event.target.value, map:map, identityError:""});
        }
    }
    changeSignonUrl(event)
    {      
        if(!(ValidationUtility.isValidHttpUrl(event.target.value))){  
            this.setState({signonUrlError: this.findLabel("saml2.url.invalid"),
                            signonUrl: event.target.value}); ;
        } else {
            this.makeFormDirty(); 
            var map=this.state.map; 
            var item = map.get("saml2.idp.single_sign_on_service.url"); 
            if(item !== undefined) {  
                item.entryValue=event.target.value;
                map.set("saml2.idp.single_sign_on_service.url",item);
            }
            this.setState({signonUrl: event.target.value, map:map, signonUrlError:""});
        }
    }
    changelogoutUrl(event)
    {     
        if(!(ValidationUtility.isValidHttpUrl(event.target.value))){  
            this.setState({logoutUrlError: this.findLabel("saml2.url.invalid"),
                            logoutUrl: event.target.value}); ;
        } else {
            this.makeFormDirty(); 
            var map=this.state.map; 
            var item = map.get("saml2.idp.single_logout_service.url");  
            if(item !== undefined) { 
                item.entryValue=event.target.value;
                map.set("saml2.idp.single_logout_service.url",item);   
            }
            this.setState({logoutUrl: event.target.value, map:map, logoutUrlError:""});
        }
    }

    changePrivateKey(event)
    {     
        if(this.state.spCert !== undefined && event.target.value !== "" && 
            !ValidationUtility.testCertAndKeyMatch(this.state.spCert, event.target.value)){
            this.setState({localPvtKey: event.target.value, 
                pvtKeyError:this.findLabel("saml2.invalid.certificate.keypair")});
        } else {
            this.setState({pvtKeyError:""});
            this.makeFormDirty();   
            var map=this.state.map; 
            var item = map.get("saml2.sp.privatekey"); 
            if(item !== undefined) {  
                item.entryValue=event.target.value;
                map.set("saml2.sp.privatekey",item);   
            }
            this.setState({localPvtKey: event.target.value, map:map});
        }
    }
    // changeFileName(event)
    // {      
    //     var map=this.state.map; 
    //    var item = map.get("saml2.idp.x509cert");   
    //    item.entryValue=event.target.value;
    //    map.set("saml2.idp.x509cert",item);     
    //    this.setState({certificate: event.target.value});
    // }

    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }

    changeFileName(selectedFiles) {
        // if(this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state &&
        //     this.uploadFileRef.current.state.selectedFiles.length > 0) {
        if(selectedFiles.length > 0){
            this.setState({fileNameError:""});   
           // let files = this.uploadFileRef.current.state.selectedFiles;
            var file = selectedFiles[0]; 
            let fileName = file.name;
            var isValidFile=true, flag_Size = true;
            let extIndex = fileName.lastIndexOf(".");
            let fileExtn = fileName.substr(extIndex+1, fileName.length);
            if(fileExtn !== "pem" && fileExtn !== "der" 
                && fileExtn !== "crt" && fileExtn !== "cer"  && fileExtn !== "cert"  ) {
                isValidFile = false;
            }
            if(file.size>1048576)
            {
                flag_Size=false;
            }
            if(isValidFile && flag_Size) {
                const reader2 = new FileReader();
                reader2.readAsText(file);
                reader2.onload = () => {
                    if(ValidationUtility.isValidCertificate(reader2.result)){
                        this.setState({
                            fileName: file.name,
                            idpCert: reader2.result,
                        });
                        this.makeFormDirty();
                    } else {
                        this.setState({
                            fileName: file.name,
                            fileNameError: this.findLabel("saml2.invalid.certificate"),
                        });
                    }
                };
                reader2.onerror = () => {
                    console.log("Error adding certificate.");
                };
            } else {
                this.setState({
                            fileNameError: this.findLabel("commuter.invalid.file"),
                        });
            }
            // var map=this.state.map; 
            // var item = map.get("saml2.idp.file.name");   
            // if(item !== undefined) {
            //     item.entryValue=file.name;
            //     map.set("saml2.idp.file.name",item);
            // }
            // var item2 = map.get("saml2.idp.x509cert");   
            // if(item2 !== undefined) {
            //     item2.entryValue=this.state.idpCert;
            //     map.set("saml2.idp.x509cert",item2);
            // }
            // this.setState({fileName: file.name, map:map});
        }
        else{
             this.setState({fileNameError:this.findLabel("configpageSamlAlert5")});
        }
        this.makeFormDirty();
    }

    importMetaData(selectedFiles) {
        if (selectedFiles.length > 0) {
            this.makeFormDirty();
            var file = selectedFiles[0]; 
            let fileName = file.name;
            let extIndex = fileName.lastIndexOf(".");
            let fileExtn = fileName.substr(extIndex+1, fileName.length);
            var isValidFile=true;
            var data = new FormData();
            if(fileExtn !== "xml" && file.size>1048576) {
                isValidFile = false;
            }
            if(isValidFile) {  
                 const reader2 = new FileReader();
                reader2.readAsText(file);
                reader2.onload = () => {
                        this.setState({
                            metaDataFileName: file.name,
                            metaDataContent: reader2.result
                });
            };   
            data.append('file', file);
            this.setState({data:data});
            }
        }  
    }
    uploadMetaDataFile(){
        this.setState({showModal:true});
    }
    importFile(){
        if(this.state.data!==undefined){
             FetchHelper.uploadMetaData(this.state.data);
        }
        else{
            ConfigurationUtils.showErrorMessage1("62004", this.context.intl);
        }
    }


    exportSamlData(e) {
        FetchHelper.downloadSamlSpMetaData(this.context.intl);
    }
    changeLocalFileName(selectedFiles) {
        // if(this.localFileRef && this.localFileRef.current && this.localFileRef.current.state &&
        //     this.localFileRef.current.state.selectedFiles.length > 0) {
        if(selectedFiles.length > 0){    
            this.setState({localfileNameError:""});
           // let files = this.uploadFileRef.current.state.selectedFiles;
            var file = selectedFiles[0]; 
            var isValidFile=true, flag_Size = true;
            let fileName = file.name;
            let extIndex = fileName.lastIndexOf(".");
            let fileExtn = fileName.substr(extIndex+1, fileName.length);
            if(fileExtn !== "pem" && fileExtn !== "der" 
                && fileExtn !== "crt" && fileExtn !== "cer"  && fileExtn !== "cert"  ) {
                isValidFile = false;
            }
            if(file.size>1048576)
            {
                flag_Size=false;
            }
            if(isValidFile && flag_Size) {
                const reader2 = new FileReader();
                reader2.readAsText(file);
                reader2.onload = () => {
                    if(ValidationUtility.isValidCertificate(reader2.result)){
                        this.setState({
                            localfileName: file.name,
                            spCert: reader2.result,
                        });
                        if(reader2.result !== "" && this.state.localPvtKey !== undefined && this.state.localPvtKey !== "" && !ValidationUtility.testCertAndKeyMatch(reader2.result, this.state.localPvtKey)){
                            this.setState({pvtKeyError:this.findLabel("saml2.invalid.certificate.keypair")});
                        } else {
                            this.setState({pvtKeyError:""});
                            this.makeFormDirty();   
                        }
                    } else {
                        this.setState({
                            localfileName: file.name,
                            localfileNameError: this.findLabel("saml2.invalid.certificate"),
                        });
                    }
                };
                reader2.onerror = () => {
                    console.log("Error adding certificate.");
                };
            } else {
                this.setState({
                            localfileNameError: this.findLabel("commuter.invalid.file"),
                        }); 
           }
            // let files = this.localFileRef.current.state.selectedFiles;
            // var file = files[0];  
            // var map=this.state.map; 
            // var item = map.get("saml2.sp.file.name");   
            // if(item !== undefined) {
            //     item.entryValue=file.name;
            //     map.set("saml2.sp.file.name",item);
            // }
            // var item2 = map.get("saml2.sp.x509cert");   
            // if(item2 !== undefined) {
            //     item2.entryValue=this.state.spCert;
            //     map.set("saml2.sp.x509cert",item2);
            // }
            // this.setState({localfileName: file.name, map:map});
        } 
        this.makeFormDirty();

         var map=this.state.map; 
         if(this.uploadFileRef.current.state.selectedFiles.length > 0){
                        var item = map.get("saml2.idp.file.name");   
                        if(item !== undefined) {
                            item.entryValue=this.state.fileName;
                            map.set("saml2.idp.file.name",item);
                        }
                        var item2 = map.get("saml2.idp.x509cert");   
                        if(item2 !== undefined) {
                            item2.entryValue=this.state.idpCert;
                            map.set("saml2.idp.x509cert",item2);
                        }
                    }
    }
    
        validateFormFields(data, intl) {
        let errorsMap = ConfigurationUtils.validateSamlConfig(data, intl);
        let flag = true;
        if(errorsMap["identity"] !== undefined && errorsMap["identity"] !== "") {
            this.setState({identityError: errorsMap["identity"]});
            flag = false;
        } 
          if(errorsMap["adminEmail"] !== undefined && errorsMap["adminEmail"] !== "") {
            this.setState({adminmailError: errorsMap["adminEmail"]});
            flag = false;
        } 
          if(errorsMap["signonUrl"] !== undefined && errorsMap["signonUrl"] !== "") {
            this.setState({signonUrlError: errorsMap["signonUrl"]});
            flag = false;
        } 
          if(errorsMap["logoutUrl"] !== undefined && errorsMap["logoutUrl"] !== "") {
            this.setState({logoutUrlError: errorsMap["logoutUrl"]});
            flag = false;
        } 
          if(errorsMap["fileName1"] !== undefined && errorsMap["fileName1"] !== "") {
            this.setState({fileNameError: errorsMap["fileName1"]});
            flag = false;
        } 
        return flag;
    }

    clearErr(data) {
        this.setState({identityError: ""});
        this.setState({logoutUrlError: "", signonUrlError: ""});
        this.setState({adminmailError: ""});
        this.setState({fileNameError: ""});
        this.validateFormFields(data,this.context.intl);
    }
    submitChanges() {
         
        if(this.state.isFormDirty && this.state.map !== undefined && this.state.map.size > 0 && this.validateFormFields(this.state, this.context.intl)) {
            let msg = LocaleUtility.getLocaleMessage("saml2.on.submit");
            if(this.state.enableSAML) {
              //  this.clearErrors();
                if(
                    //this.uploadFileRef && this.uploadFileRef.current && this.uploadFileRef.current.state &&
                    ( 
                    this.uploadFileRef.current.state.fileNames !== "" && 
                    this.state.adminEmail !== '' && this.state.adminEmail!==undefined &&
                    this.state.identity !== '' && this.state.identity!==undefined &&
                    this.state.logoutUrl !== '' && this.state.logoutUrl!==undefined &&
                    this.state.signonUrl !== '' && this.state.signonUrl!==undefined) &&
                    (this.state.adminmailError == '' && this.state.fileNameError == '' &&
                    this.state.formError == '' && this.state.identityError == '' &&
                    this.state.localfileNameError == '' && this.state.logoutUrlError == '' &&
                    this.state.pvtKeyError == '' && this.state.signonUrlError == '')
                    ) {
                    let files, file;
                    var map=this.state.map; 
                    if(this.uploadFileRef.current.state.selectedFiles.length > 0){
                        // files = this.uploadFileRef.current.state.selectedFiles;
                        // file = files[0];
                        // const reader = new FileReader();
                        // reader.readAsText(file);
                        // reader.onload = () => {
                        //     this.setState({
                        //         fileName: file.name,
                        //         idpCert: reader.result,
                        //     });
                        // };
                        // reader.onerror = () => {
                        //     console.log("Error adding certificate.");
                        // };
                        var item = map.get("saml2.idp.file.name");   
                        if(item !== undefined) {
                            item.entryValue=this.state.fileName;
                            map.set("saml2.idp.file.name",item);
                        }
                        var item2 = map.get("saml2.idp.x509cert");   
                        if(item2 !== undefined) {
                            item2.entryValue=this.state.idpCert;
                            map.set("saml2.idp.x509cert",item2);
                        }
                    }
                    
                    if(this.localFileRef.current.state.fileNames !== ""){
                        // files = this.localFileRef.current.state.selectedFiles;
                        // file = files[0];
                        // const reader2 = new FileReader();
                        // reader2.readAsText(file);
                        // reader2.onload = () => {
                        //     this.setState({
                        //         localfileName: file.name,
                        //         spCert: reader2.result,
                        //     });
                        // };
                        // reader2.onerror = () => {
                        //     console.log("Error adding certificate.");
                        // };
                        
                        var item = map.get("saml2.sp.file.name");   
                        if(item !== undefined) {
                            item.entryValue=this.state.localfileName;
                            map.set("saml2.sp.file.name",item);
                        }
                        var item2 = map.get("saml2.sp.x509cert");   
                        if(item2 !== undefined) {
                            item2.entryValue=this.state.spCert;
                            map.set("saml2.sp.x509cert",item2);
                        }

                        var item3 = map.get("saml2.security.logoutrequest_signed");   
                        if(item3 !== undefined) {
                            item3.entryValue=true;
                            map.set("saml2.security.logoutrequest_signed",item3);
                        }

                    } else {
                        var item = map.get("saml2.sp.file.name");   
                        if(item !== undefined ) {
                            item.entryValue=null;
                            map.set("saml2.sp.file.name",item);
                        }
                        var item2 = map.get("saml2.sp.x509cert");   
                        if(item2 !== undefined ) {
                            item2.entryValue=null;
                            map.set("saml2.sp.x509cert",item2);
                        }
                         var item3 = map.get("saml2.security.logoutrequest_signed");   
                        if(item3 !== undefined) {
                            item3.entryValue=null;
                            map.set("saml2.security.logoutrequest_signed",item3);
                        }
                    }
                    this.setState({ map:map});
                    ValidationUtility.confirm(msg,this.onConfirmValidate, "submit", "", "");
                } else {
                    ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
                }
            } else if(!this.state.enableSAML && this.state.identity !== "") {
                msg = LocaleUtility.getLocaleMessage("saml2.on.disable");
                ValidationUtility.confirm(msg,this.onConfirmValidate, "remove", "", "");
            } else {
                ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
            }
           
        } 
         else {
                ConfigurationUtils.showErrorMessage1("generic.form.fields.error", this.context.intl);
            }
        // else if(this.state.isAdminReset){
        //     var map = this.state.map;
        //     var item = map.get("saml2.super.admin.email");   
        //     item.entryValue=this.state.adminMail;
        //     map.set("saml2.super.admin.email", item);
        //     const modifiedObjects=Array.from(map.values());
        //     FetchHelper.submitConfigEntries(modifiedObjects, this.context.intl);
        // }
    }
    onConfirmValidate(flag, method){
        if(flag === 1){
            if(method === 'submit'){
                // this.changeFileName();
                // this.changeLocalFileName();
                const modifiedObjects=Array.from(this.state.map.values());
                FetchHelper.submitConfigEntries(modifiedObjects, true, true, this.context.intl);
            } else if(method === 'remove'){
                var map=this.state.map;
                var item = map.get("saml2.idp.file.name");   
                item.entryValue=null;
                map.set("saml2.idp.file.name", item);
                item = map.get("saml2.idp.single_sign_on_service.url");   
                item.entryValue=null;
                map.set("saml2.idp.single_sign_on_service.url", item);
                item = map.get("saml2.idp.single_logout_service.url");   
                item.entryValue=null;
                map.set("saml2.idp.single_logout_service.url", item); 
                item = map.get("saml2.idp.entityid");   
                item.entryValue=null;
                map.set("saml2.idp.entityid", item); 
                item = map.get("saml2.idp.x509cert");   
                item.entryValue=null;
                map.set("saml2.idp.x509cert", item);
                item = map.get("saml2.super.admin.email");   
                item.entryValue=null;
                map.set("saml2.super.admin.email", item);
                item = map.get("saml2.sp.file.name");   
                item.entryValue=null;
                map.set("saml2.sp.file.name", item);
                item = map.get("saml2.sp.x509cert");   
                item.entryValue=null;
                map.set("saml2.sp.x509cert", item);
                item = map.get("saml2.sp.privatekey");   
                item.entryValue=null;
                map.set("saml2.sp.privatekey", item);
                item = map.get("saml2.security.digest_algorithm");   
                item.entryValue=null;
                map.set("saml2.security.digest_algorithm", item);
                item = map.get("saml2.security.signature_algorithm");   
                item.entryValue=null;
                map.set("saml2.security.signature_algorithm", item);

                for (let obj of this.state.rowData){
                    if (obj.entryKey.indexOf("saml2") > -1 && obj.show == undefined){
                        obj.entryValue=null;
                        map.set(obj.entryKey, obj);
                    }
                }

                const modifiedObjects=Array.from(map.values());
                FetchHelper.submitConfigEntries(modifiedObjects, true, false, this.context.intl);
            }
        }
    }

    changeKeyValue(event, obj) {
        let value = event.target.value;
        let modifiedObjs = this.state.map;
        if(obj.entryType === 1){
            if(!ValidationUtility.isTrueORFalse(value)){
                document.getElementById("error-"+obj.id).innerHTML = this.findLabel("saml2.invalid.true.false.value");
                document.getElementById("error-"+obj.id).style = "float: inherit";
                this.setState({formError: 'error'});
            } else {
                document.getElementById("error-"+obj.id).innerHTML = '';
                document.getElementById("error-"+obj.id).style = "float: inherit; display: none;";
                obj.entryValue = value;
                modifiedObjs.set(obj.entryKey, obj);
                this.setState({map: modifiedObjs, formError: ""});
            }
        } else if(obj.entryType === 3){
            if(!ValidationUtility.stringCheckForSAML(value)){
                document.getElementById("error-"+obj.id).innerHTML = this.findLabel("saml2.authncontext.invalid");
                document.getElementById("error-"+obj.id).style = "float: inherit";
                this.setState({formError: 'error'});
            } else {
                document.getElementById("error-"+obj.id).innerHTML = '';
                document.getElementById("error-"+obj.id).style = "float: inherit; display: none;";
                obj.entryValue = value;
                modifiedObjs.set(obj.entryKey, obj);
                this.setState({map: modifiedObjs, formError: ""});
            }
        }
        
        this.makeFormDirty();
    }
    loadGridData() {
        if(store.getState().actionType === 'configSamllist') {
            let sigAlg, digAlg;
            let gData = store.getState().map;
            let rows = [];
            // for(var item of gData){
            //     if (item[0].indexOf("saml2") > -1 && item[1].show == undefined){
            //         item[1].entryKeyLabel = LocaleUtility.getLocaleMessage(item[1].entryKey);
            //         item[1].entryValueInput = <span>
            //                 <span><TextInput className="configEntryEdit" id={"key-id-"+item[1].id} defaultValue={item[1].entryValue} onChange={event=>this.changeKeyValue(event, item[1])} /></span>
            //                 <span id={"error-"+item[1].id} style={{float: "inherit"}} className="grommetux-form-field__error"></span>
            //             </span>
            //         rows.push(item[1]);
            //     }
            // }
                for (let obj of gData.values()){
                    if (obj.entryKey.indexOf("saml2") > -1 && obj.show == undefined){
                        obj.entryKeyLabel = LocaleUtility.getLocaleMessage(obj.entryKey);
                        obj.errorLabel = "";
                        obj.entryValueInput = <span>
                                <span><TextInput className="configEntryEdit" id={"key-id-"+obj.id} defaultValue={obj.entryValue} onChange={event=>this.changeKeyValue(event, obj)} /></span>
                                <span id={"error-"+obj.id} style={{float: "inherit", display:"none"}} className="grommetux-form-field__error"></span>
                            </span>
                        rows.push(obj);
                    }
                }

                if(store.getState().sigAlg !== undefined){
                    for(var item of this.state.sigAlgs){
                        if(item.value === store.getState().sigAlg){
                            sigAlg = {value:item.value, label:item.label};
                        }
                    }
                }

                if(store.getState().digAlg !== undefined){
                    for(var item of this.state.digAlgs){
                        if(item.value === store.getState().digAlg){
                            digAlg = {value:item.value, label:item.label};
                        }
                    }
                }
                if(store.getState().fileName1 !== undefined && this.uploadFileRef.current!==undefined && this.uploadFileRef.current!==null){
                    this.uploadFileRef.current.onFileUpdate([store.getState().fileName1]);
                }
                
            this.setState({
                fileName : store.getState().fileName1,
                localfileName : store.getState().fileName2,
                idpCert : store.getState().idpCert,
                identity : store.getState().identity,
                signonUrl : store.getState().signonUrl,
                logoutUrl : store.getState().logoutUrl,
                selAttrType :(store.getState().adminMail !== undefined ? 
                                {label:store.getState().adminMail,  value: store.getState().adminMail}: 
                                this.state.defaultNone
                            ),
                adminEmail: store.getState().adminMail,
                filearr:(store.getState().fileName1 !== undefined ? [store.getState().fileName1]: []),
                localfilearr:(store.getState().fileName2 !== undefined ? [store.getState().fileName2]: []),
                localPvtKey: store.getState().localPvtKey,
                spCert: store.getState().localCert,
                userId: store.getState().userId,
                selSigAlg: (sigAlg !== undefined ? sigAlg: this.state.defaultNone),
                selDigAlg: ( digAlg !== undefined ? digAlg: this.state.defaultNone),
                enableSAML: (store.getState().identity !== undefined? true: false),
                map : store.getState().map,
                rowData: rows
            });
            const values = Array.from(store.getState().map.values());   
             this.onModalCloseCallback();
             this.clearErr(store.getState());
        }
    }

    refreshUser(){
        if(UserStore.getState().userGrpData !== undefined) {
            let users = [this.state.defaultNone];
            UserStore.getState().userGrpData.map((item, index) => {
                users.push({label: item.userName, value: item.userName});
                return "";
            });
            this.setState({ users: users });
        }
    }
    clearErrors() {
        this.state.rowData.map(obj => {
            document.getElementById("error-"+obj.id).innerHTML = '';
            return "";
        });
    }
    cancelChanges() {
        this.setState({rowData: [], modifiedObjects: []});
        this.findData();
    }
    findData() {
        let request = {
        }
        FetchHelper.listConfigSamlEntries(request, this.context.intl);
    }

    selectAttributeType(event) {
        var map=this.state.map; 
        var item = map.get("saml2.super.admin.email");   
        item.entryValue=event.option.value;
        map.set("saml2.super.admin.email",item);   
        this.setState({selAttrType: event.option, adminMail: event.option.value, map:map, isAdminReset: true});
    }

    selectDigAlg(event) {
        var map=this.state.map; 
        var item = map.get("saml2.security.digest_algorithm");   
        if(event.option.value !== undefined){
            item.entryValue=event.option.value;
        } else {
            item.entryValue=null;
        }
        map.set("saml2.security.digest_algorithm",item);   
        this.setState({selDigAlg: event.option, map:map});
        this.makeFormDirty();
    }

    selectSigAlg(event) {
        var map=this.state.map; 
        var item = map.get("saml2.security.signature_algorithm");   
        if(event.option.value !== undefined){
            item.entryValue=event.option.value;
        } else {
            item.entryValue=null;
        }
        map.set("saml2.security.signature_algorithm",item);   
        this.setState({selSigAlg: event.option, map:map});
        this.makeFormDirty();
    }

    handleChangeAdminEmail (event) {
        let val = event.target.value;
        this.makeFormDirty();
        if(!(ValidationUtility.validateEmail (val))){  
            this.setState({adminmailError: this.findLabel("configpageAlert6"),
                            adminEmail: val});
        } else {
            this.setState({adminmailError: ""});
            var map=this.state.map; 
            var item = map.get("saml2.super.admin.email"); 
            if(item !== undefined) {
                item.entryValue=val;
                map.set("saml2.super.admin.email",item);
            }   
            this.setState({adminEmail: val, map:map});  
        }	
    }

    onToggleChangeHandler(isChecked){
        this.makeFormDirty();
        this.clearFieldError();
        if(isChecked){
            this.setState({enableSAML: true});
            if(this.state.fileName !== undefined)
                this.findData();      
        }else{
            this.setState({enableSAML: false});
        }
    }
    onModalCloseCallback()
    {
        this.setState({
            showModal: false,
             
        });
        this.metadataFileRef.current.onDeleteFileAction();
    }
    infoSectionContent() {
            return (
                                            <div  style={{display:"flex"}}>
                                             <div className="db_config_wrap_item"  style={{float:"left"}}>
                                                <div className="horizantal_label_input">
                                                    <FormField
                                                    style={{ float:"right",width: '70em', display:"block" }}
                                                    label= {LocaleUtility.getLocaleMessage("label.saml.idp.metadatafile.name")} >
                                                    <File ref={this.metadataFileRef}
                                                                id = "conf_saml_metadata_browse"
                                                                name = "conf_saml_metadata_browse"
                                                                filesList={this.state.metadataarr}
                                                                label = {this.findLabel("label.button.add.file")}
                                                                onFileChange={this.importMetaData}
                                                            />
                                                            
                                                    </FormField>
                                                </div>
                                            </div>
                                            </div>
            );
    }
    render(){
        //let intl = this.context.intl;
        return(
            <div className="config-cont-backgroud">
                <div className="border_white_backg_shadow">
                <SectionWithTitle title={this.findLabel("configpageDBtitle")}  helpTopic="SAML"/>
                <section pad="none" wrap={false} responsive={false} justify="center">
                    <Form compact={false} id="conf_saml_formId" pad="small" plain={true}>   
                    <h2 className="headerConfig">{this.findLabel("configpageSAMLConnAttributes")}</h2>   
                    <div className="license-view-buttons button_left_wrap" style={{ marginBottom:"20px" }}>
                        
                         <div className="saml_check_radio">
                            <FormField style={{ width: '1000em', marginLeft:"15px" }} label= {this.findLabel("label.saml.authentication")} >
                            <CheckBox id="conf_saml_checkbox"
                                checked={this.state.enableSAML}
                                label={this.state.enableSAML ? this.findLabel("label.enabled") : this.findLabel("label.disabled")}
                                value={this.state.enableSAML} onChange={(event) => this.onToggleChangeHandler(event.target.checked)} 
                                toggle={true} /> 
                         </FormField>
                         </div>
                         <div className="button_wrap_primary with_icon"  style={{marginLeft:"5%"}}>
                            <Button className="createPoolBtn" onClick={this.uploadMetaDataFile} > 
                         
                         <span id="import-pool-template-id"><Upload id="import-pool-template-icon-id" size="huge" colorIndex="light-1" /></span>
                         <a title={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.import.tip")}>
                         <span style={{paddingLeft: "5px"}}>{this.findLabel("label.button.import")}</span></a>
                          </Button>
                        </div>
                        <div className="button_wrap_primary with_icon">
                           <Button className="createPoolBtn" onClick={this.exportSamlData} >

                            <span id="download-pool-template-id"><Download id="download-pool-template-icon-id" size="huge" colorIndex="light-1" /></span>
                         <a title={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.export.tip")}>
                         <span style={{paddingLeft: "5px"}}>{this.findLabel("label.button.export")}</span></a>
                          </Button>
                        </div>
                        </div>                         
                        
                                {(this.state.enableSAML)?
                                    <div id="config_entry_div_id">
                                         <div className="saml_conf_wrap">
                                            <div className="flexBox">
                                           </div>
                                            <div  style={{display:"flex"}}>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField  required={true} error={this.state.fileNameError}
                                                    style={{  display:"block" }}
                                                    label= {LocaleUtility.getLocaleMessage("label.saml.idp.file.name")} >
                                                    <File ref={this.uploadFileRef}
                                                                id = "conf_saml_certificate_browse"
                                                                name = "conf_saml_certificate_browse"
                                                                filesList={this.state.filearr}
                                                                label = {this.findLabel("label.button.add.file")}
                                                                onFileChange={this.changeFileName}
                                                            />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={true} error={this.state.identityError} style={{ display:"block" }} label={this.findLabel("label.saml.idp.entity.id.key")} >
                                                    <TextInput id="conf_saml_idp_entity_id" value={this.state.identity} onChange={event =>this.changeIdentity(event)} />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={true} error={this.state.signonUrlError} style={{ display:"block" }} label={this.findLabel("label.saml.idp.signon.url.key")} >
                                                    <TextInput id="conf_saml_idp_sso_url" value={this.state.signonUrl} onChange={event =>this.changeSignonUrl(event)} />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={true} error={this.state.logoutUrlError} style={{ display:"block" }} label= {this.findLabel("label.saml.idp.logout.url.key")} >
                                                    <TextInput id="conf_saml_idp_slo_url" value={this.state.logoutUrl} onChange={event =>this.changelogoutUrl(event)}/>
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={true} error={this.state.adminmailError} style={{ display:"block" }} label= {this.findLabel("label.saml.super.admin")} >
                                                    {/* <Select id="resv-add-attribute-selection" placeholder={this.findLabel("configpageIpHostSelect", intl)}
                                                        options={this.state.users}
                                                        value={this.state.selAttrType}
                                                        onChange={this.selectAttributeType} 
                                                        label= {this.findLabel("label.button.add", intl)+": "}
                                                        enabledSearch = {true}
                                                        //width= {8}
                                                        disabledScroll = {true}
                                                    /> */}
                                                        <TextInput id="conf_saml_adminemail" value={this.state.adminEmail} onChange={event =>this.handleChangeAdminEmail(event)} />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField  required={false} error={this.state.localfileNameError}
                                                    style={{  display:"block" }}
                                                    label= {LocaleUtility.getLocaleMessage("label.saml.sp.file.name")} >
                                                    <File ref={this.localFileRef}
                                                                id = "conf_saml_localcert_browse"
                                                                name = "conf_saml_localcert_browse"
                                                                filesList={this.state.localfilearr}
                                                                label = {this.findLabel("label.button.add.file")}
                                                                onFileChange={this.changeLocalFileName}
                                                            />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={false} error={this.state.pvtKeyError} style={{ display:"block" }} label= {this.findLabel("label.saml.sp.cert.key")} >
                                                    <TextInput id="conf_saml_sp_pvt_key" value={this.state.localPvtKey} onChange={event =>this.changePrivateKey(event)}/>
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={false} style={{ display:"block" }} label= {this.findLabel("saml2.security.digest_algorithm")} >
                                                    <Select id="conf_saml_digalgo_selection" placeholder={this.findLabel("configpageIpHostSelect")}
                                                        options={this.state.digAlgs}
                                                        value={this.state.selDigAlg}
                                                        onChange={this.selectDigAlg} 
                                                        label= {this.findLabel("label.button.add")+": "}
                                                        enabledSearch = {false}
                                                        //width= {8}
                                                        disabledScroll = {true}
                                                    />
                                                    </FormField>
                                                </div>
                                            </div>
                                            <div className="db_config_wrap_item">
                                                <div className="horizantal_label_input">
                                                    <FormField required={false} style={{ display:"block" }} label={this.findLabel("saml2.security.signature_algorithm")} >
                                                    <Select id="conf_saml_sigalgo_selection" placeholder={this.findLabel("configpageIpHostSelect")}
                                                        options={this.state.sigAlgs}
                                                        value={this.state.selSigAlg}
                                                        onChange={this.selectSigAlg} 
                                                        label= {this.findLabel("label.button.add")+": "}
                                                        enabledSearch = {false}
                                                        //width= {8}
                                                        disabledScroll = {true}
                                                    />
                                                    </FormField>
                                                </div>
                                            </div>
                                            </div>
                                           
                                        <TableGrid id="config_entry_gird"
                                            header={[
                                                {
                                                    columnName: "entryKeyLabel",
                                                    headerLabel: LocaleUtility.getLocaleMessage("config.entry.key.label") ,
                                                    href: false,
                                                    width: "30%"
                                                },
                                                {
                                                    columnName: "entryValueInput",
                                                    headerLabel: LocaleUtility.getLocaleMessage("config.entry.value.label") ,
                                                    href: false,
                                                    width: "auto"
                                                }
                                            ]}
                                            rowData={this.state.rowData}
                                            pagination={this.state.pagination}
                                            rowSelection={0}
                                            isExportRequired={false}
                                            onSort={this.onSortClicked}
                                            onFilter={this.onFilterClicked}
                                            sortIndex={this.state.sortIndex}
                                            sortAscending={this.state.sortAscending}
                                            rowKey="id"
                                            onChangeCheckBoxCallback={this.onRowClicked}
                                            noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                            itemsCountMessage={LocaleUtility.getLocaleMessage(
                                                    "label.grid.item.count.message", {count: this.state.rowData.length})}
                                        />
                                    </div>
                                    : ""
                                }                                          
                       <div className="saveBtnStyle button_left_wrap">
                        <div className="button_wrap_primary">
                            <Button id="conf_asset_ct_idm_saveBtn" label={this.findLabel("label.button.save")} plain={false} primary={true} onClick={this.submitChanges} />
                                </div>
                                <div className="button_wrap_negative">
                            <Button id="conf_asset_ct_idm_cancelBtn" plain={false} secondary={true} label={this.findLabel("label.button.cancel")} primary={false} onClick={this.cancelChanges} />
                            </div>
                        </div>                                 
                    </Form>
                    <div>
                        <SpinningNotification centerPage={true} />
                        <Modal id="lic_view_detailMod" title={LocaleUtility.getLocaleLabel(this.context.intl, "label.upload")} showModal={this.state.showModal} styleProps={{top: "48%", overflowY: "auto"}}
                    closeLabel={LocaleUtility.getLocaleLabel(this.context.intl, "label.button.cancel")}
                    installBtn={{"label":LocaleUtility.getLocaleLabel(this.context.intl, "label.button.upload")}}
                    sectionContent={this.infoSectionContent()}
                    onCloseButtonCallback={this.onModalCloseCallback}
                    installBtnClick={this.importFile}
                    />
                    </div>
                </section>
                </div>
            </div>
        );
    }
}
// Saml.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default Saml;