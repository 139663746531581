import React, { Component } from "react";
import AnnotatedMeter from "../../generic/AnnotatedMeter.js";

class AnnotatedMeterGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return(
            <div>
                <AnnotatedMeter legend={this.props.legend}
                    size={this.props.size}
                    type={this.props.type}
                    units={this.props.units}
                    max={this.props.max}
                    defaultMessage={this.props.defaultMessage}
                    series={this.props.series} 
                    onActive={this.props.onActive}
                    totalValuePrecision={this.props.totalValuePrecision}
                />
            </div>
        )
    }
}
export default AnnotatedMeterGraph;