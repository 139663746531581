import Dispatcher from "../flux/Dispatcher";
import { Buffer } from "buffer";
import * as FetchHelper from "./FetchHelper";
import * as LocaleUtility from "./LocaleUtility";
import * as DateUtility from "./DateUtility";
import {decrypt, getServerUnavailableErrors} from  "./Util";
import { isNotEmpty } from "./ValidationUtility";
var sendLongout = false;

export function setLogout(flag){
  sendLongout = flag;
}

export function getLogout(){
  return sendLongout;
}

export function getProductList() {
  var data = localStorage.getItem("LID");
  var session,
    products = [],
    admin = "";
  if (data !== undefined) {
    var obj = JSON.parse(decrypt(data));
    session = obj.sessionMap;
    if (session.productList !== undefined) {
      products = session.productList.value.split(",");
    }
    admin = obj.user.userName;
  }

  return { productList: products, user: admin };
}

export function processData(data, pageType, stepNumber) {
  var successDataList = [];
  var errorDataList = [];
  var disableIds = [];
  const errorDetails = data.errorList;
  if (data.LicenseInfo !== undefined) {
    data.LicenseInfo.forEach(element => {
      if (element.productID === undefined) {
        errorDataList.push(
          Buffer.from(element.LicenseString.value, "base64").toString("ascii")
        );
      } else {
        Object.entries(element).map(([key, value]) => {
          if (key.toLowerCase().indexOf("time") > -1) {
            element[key] = "";
            if (value > 0 && value !== "900703")
              element[key] = DateUtility.getFormatedDate(
                new Date(value * 1000)
              );
            else element[key] = LocaleUtility.getLocaleMessage("label.perpetual");
          }
          return "";
        });
        element.featureDesc = element.featureId + ":" + element.featureVersion;
        if (element.featureDescription !== undefined)
          element.featureDesc =
            element.featureDesc + " (" + element.featureDescription + ")";
        element.remarks = element.licenseServiceStatus.remarks;
        if (element.licenseServiceStatus.status === "FAIL") {
          disableIds.push(element.licenseID);
        }
        successDataList.push(element);
      }
    });
  }

  let loadSpinAction = "DISABLE_LOADING_SPIN_DONE";
  Dispatcher.dispatch({
    type: loadSpinAction,
    payload: ""
  });

  let type, payload;
  if (successDataList.length === 0 && errorDetails !== undefined && errorDetails !== null) {
    type = "LICENSE_FETCH_ERROR";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleErrorMessage(
        errorDetails.errorDetail[0].errorCode
      )
    });
  } else {
    type = pageType;
    payload = {
      tableData: successDataList,
      sortAscending: true,
      sortIndex: 0,
      currentStep: stepNumber,
      error: errorDataList,
      disableIds: disableIds.length > 0 ? disableIds : undefined,
      pagination: {
        required: true,
        style: "default",
        totalCount: successDataList.length,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize()
      }
    };
    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
}

export function processError(status, error) {
  let action;
  var session = FetchHelper.getSession();
  if(status === 401){
    if(!sendLongout){
      Dispatcher.dispatch({
          type: "LOGOUT_SUCCESS",
          payload: { user: undefined, idm: session.isidm, saml: session.saml }
      });
      sendLongout = true;
    }
    return;
  } else if(status === 403){
     window.location.href = "/autopass/common/error403.html";
      return;
  } else {
      action = "APP_FETCH_ERROR";
    let payload = status.message;
    if (getServerUnavailableErrors().indexOf(payload) > -1) {
      Dispatcher.dispatch({
              type: "APP_FETCH_EXCEPTION",
              payload: ''
      });
      return;
    } else {
      Dispatcher.dispatch({
        type: action,
        payload: payload
      });
    }
  }
  action = "DISABLE_LOADING_SPIN_DONE";
  Dispatcher.dispatch({
    type: action,
    payload: ""
  });
}

export function processToAddLicense(data) {
  var successDataList = [];

  data.forEach(element => {
    if (
      element.productID !== undefined &&
      element.licenseServiceStatus.status === "SUCCESS"
    ) {
      successDataList.push(
        Buffer.from(element.LicenseString.value, "base64").toString("ascii")
      );
    }
  });

  var result = { licenseList: { licenseKey: successDataList } };
  return JSON.stringify(result);
}

export function processToValidateLicense(dataList) {
  var successDataList = [];

  dataList.forEach(element => {
    if (element !== undefined) {
      successDataList.push(Buffer.from(element, "base64").toString("ascii"));
    }
  });

  var result = { licenseList: { licenseKey: successDataList } };
  return JSON.stringify(result);
}

export function processActiveLicenseList(data, licenseStatusType) {
  if (data !== undefined && data.status === "SUCCESS") {
    let type = "LICENSE_VIEW_PAGE_FETCH_COMPLETE";
    if (licenseStatusType === "ARCHIVED") {
      type = "LICENSE_ARCHIVE_PAGE_FETCH_COMPLETE";
    }

    const rows = data.licenseList.license.map(item => {
      Object.entries(item).map(([key, value]) => {
        if (key.toLowerCase().indexOf("date") > -1) {
          item[key] = "";
          if (value > 0 && value !== 900703)
            item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
          else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
        }
        if(key === "expiryDate") {
          item["expiryOriginalValue"] = value;
        }
        return "";
      });

      if (
        item.ipAddressList !== undefined &&
        item.ipAddressList.value.length > 0
      ) {
        item.lockCode = item.ipAddressList.value[0];
      }
      if (
        item.deviceIdList !== undefined &&
        item.deviceIdList.value.length > 0
      ) {
        item.lockCode = item.deviceIdList.value[0];
      }
      if (item.hostIdList !== undefined && item.hostIdList.value.length > 0) {
        item.lockCode = item.hostIdList.value[0];
      }
      if (item.cloudIdList !== undefined && item.cloudIdList.value.length > 0) {
        item.lockCode = item.cloudIdList.value[0];
      }
      if (
        item.macAddressList !== undefined &&
        item.macAddressList.value.length > 0
      ) {
        item.lockCode = item.macAddressList.value[0];
      }

      item.featureDesc = item.featureID + ":" + item.featureVersion;
      if (item.featureDescription !== undefined)
        item.featureDesc =
          item.featureDescription +
          " (" +
          item.featureID +
          ":" +
          item.featureVersion +
          ")";
      if (item.licenseType !== undefined && item.licenseType.length > 0) {
        if (item.licenseType === 'PERPETUAL') {
          item.licenseType = LocaleUtility.getLocaleMessage("label.perpetual");
        } else {
          item.licenseType = LocaleUtility.getLocaleMessage("label.term");
        }
      }
      return item;
    });

    var sortOrder;
    if (data.pagination.sortOrder === "ASC") {
      sortOrder = true;
    } else {
      sortOrder = false;
    }

    var map = new Map();
    if (
      data.productNameList !== undefined &&
      data.productNameList.value !== undefined &&
      data.productNameList.value.length > 0
    ) {
      data.productNameList.value.map(item => {
        var prods = item.split(":");
        if (prods.length === 2) {
          map.set(prods[0], prods[1]);
        } else {
          map.set(item, item);
        }
        return "";
      });
    }

    data.pagination.required =true;
    data.pagination.style = "default";
    let payload = {
      tableData: rows, //data.licenseList.license,
      pagination: data.pagination,
      productNameSelected: data.selectedProductName,
      productNameSelectOptions: map,
      sortAscending: sortOrder,
      sortIndex: data.pagination.sortBy
    };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });

        FetchHelper.getProductFeatureLicensesCount(undefined, "ACTIVE", undefined);
    }
    
}

export function refreshActiveLicenseListView(data, status) {
  var addedLicenseList = 0;
  const errorDetails = data.errorList;

  if (data.LicenseInfo !== undefined) {
    data.LicenseInfo.forEach(element => {
      if (
        element.productID !== undefined &&
        element.licenseServiceStatus.status === "SUCCESS" &&
        element.licenseServiceStatus.licenseServiceOperation.operation ===
          status
      ) {
        addedLicenseList += 1;
      }
    });
        if(data.allowImplicitFeatureCount>0){
          // let payload3 = {
          //   productFeatureCount: data.allowImplicitFeatureCount    
          // }
          //    Dispatcher.dispatch({
          //       type: "PRODUCT_FEATURE_COUNT",
          //       payload: payload3
          //   });
            FetchHelper.getProductFeatureLicenses(undefined, "ACTIVE", undefined);
            let payload2 = {
                redirectPage:"product_feature"
            }
            Dispatcher.dispatch({
                type:"REDIRECT_PAGE",
                payload:payload2
            });
            if(status==="ADDED"){
              status = LocaleUtility.getLocaleMessage("label.status.added");
            }            
        }else if(data.allowExplicitFeatureCount>0){
            let payload2 = {
              redirectPage:"view_license"
            }
            Dispatcher.dispatch({
                type:"REDIRECT_PAGE",
                payload:payload2
          });          
          FetchHelper.getAllLicenses();
            if(status==="ADDED"){
              status = LocaleUtility.getLocaleMessage("label.status.added");
            }
        }else if(status==="ARCHIVED"){
          let payload2 = {
              redirectPage:"reset"
            }
            Dispatcher.dispatch({
                type:"REDIRECT_PAGE",
                payload:payload2
          });  
           FetchHelper.getProductFeatureLicenses();
           FetchHelper.getAllLicenses();
           status = LocaleUtility.getLocaleMessage("label.status.archive");
        }
        
    let type;
    let msg;
    if(data.status==="FAIL"){
      type = "LICENSE_FETCH_ERROR";
      msg="";
    }else{
      type = "LICENSE_INSTALL_SUCCESS_OK";
      msg = LocaleUtility.getLocaleMessage("label.license.success", {
        count: addedLicenseList,
        status: status.toLowerCase()
      });
    }

    if (errorDetails !== undefined && errorDetails !== null) {
      msg =
        msg +
        " " +
        LocaleUtility.getLocaleErrorMessage(
          errorDetails.errorDetail[0].errorCode,
          errorDetails.errorDetail[0].parameters
        );
    }
    Dispatcher.dispatch({
      type: type,
      payload: msg
    });
  }
  else {
    FetchHelper.getAllLicenses();

    let type;
    if (errorDetails !== undefined && errorDetails !== null) {
      type = "LICENSE_FETCH_ERROR";
      Dispatcher.dispatch({
        type: type,
        payload: LocaleUtility.getLocaleErrorMessage(
          errorDetails.errorDetail[0].errorCode,
          errorDetails.errorDetail[0].parameters
        )
      });
    }
  }
}

export function refreshArchiveLicenseView(data, status) {
  var archivedLicenseList = 0;
  const errorDetails = data.errorList;
  if (data.LicenseInfo !== undefined) {
    data.LicenseInfo.forEach(element => {
      if (
        element.productID !== undefined &&
        element.licenseServiceStatus.status === "SUCCESS" &&
        element.licenseServiceStatus.licenseServiceOperation.operation ===
          status
      ) {
        archivedLicenseList += 1;
      }
    });
    if (status === "ADDED") {
      status = LocaleUtility.getLocaleMessage("label.status.restored");
    } else if (status === "DELETED") {
      status = LocaleUtility.getLocaleMessage("label.status.removed");
    }

    FetchHelper.getAllLicenses(undefined, "ARCHIVED", undefined);
    FetchHelper.getProductFeatureLicenses(undefined, "ARCHIVED", undefined);
    let type = "LICENSE_INSTALL_SUCCESS_OK";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleMessage("label.license.success", {
        count: archivedLicenseList,
        status: status.toLowerCase()
      }) //archivedLicenseList+" license(s) "+status.toLowerCase()+" successfully."
    });
  } else {
    FetchHelper.getAllLicenses(undefined, "ARCHIVED", undefined);
    FetchHelper.getProductFeatureLicenses(undefined, "ARCHIVED", undefined);

    let type;
    if (errorDetails !== undefined && errorDetails !== null) {
      type = "LICENSE_FETCH_ERROR";
      Dispatcher.dispatch({
        type: type,
        payload: LocaleUtility.getLocaleErrorMessage(
          errorDetails.errorDetail[0].errorCode,
          errorDetails.errorDetail[0].parameters
        )
      });
    }
  }
}

export function getLicenseDetail(data, showRenewDetail) {
  if (data.status === "SUCCESS" && data.licenseList.license.length === 1) {
    var item = data.licenseList.license[0];
    Object.entries(item).map(([key, value]) => {
      if (key.toLowerCase().indexOf("date") > -1) {
        if (value > 0 && value !== 900703)
          item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
        else {
          if (value === 900703) item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
        }
      }
      return "";
    });

    if (!isNotEmpty(item.environment)) {
      if (item.environment === 'Production') {
        item.environment = LocaleUtility.getLocaleMessage("label.production");
      }
    }

    if (!isNotEmpty(item.licenseType)) {
      if (item.licenseType === 'PERPETUAL') {
        item.licenseType = LocaleUtility.getLocaleMessage("label.perpetual");
      } else {
        item.licenseType = LocaleUtility.getLocaleMessage("label.term");
      }
    }

    let type = "LICENSE_DETAIL_FETCH_COMPLETE";
    let payload = {
      licenseDetail: item,
      type: ((showRenewDetail !== undefined && showRenewDetail) ? "licRenewDetail": "licenseDetail")
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
}

export function processLicenseCleanupData(data) {
  const errorDetails = data.errorDetail;
  if (data.status !== undefined && data.status === "SUCCESS") {
    if (data.statusCode === 0 || data.statusCode === 57016) {
      FetchHelper.getAllLicenseToken(data.cleanupKeyType,data.status,undefined,undefined);
      Object.entries(data).map(([key, value]) => {
        if (key.toLowerCase().indexOf("date") > -1) {
          data[key] = DateUtility.getFormatedDate(new Date(value * 1000));
        }
        return "";
      });

      var licenseDetail = {
        featureDesc: data.featureID + ":" + data.featureVersion,
        description: LocaleUtility.getLocaleMessage(
          "info.using.this.licensekey.you.can.clean.only",
          { capacity: data.capacity }
        ),
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        rawCleanupKey: data.rawCleanupKey,
        capacity: data.capacity
      };
      let type = "LICENSE_CLEANUP_COMPLETE";
      let payload = {
        licenseDetail: licenseDetail,
		cleanupKeyType:data.cleanupKeyType
      };

      Dispatcher.dispatch({
        type: type,
        payload: payload
      });
      if (data.statusCode === 57016) {
        Dispatcher.dispatch({
          type: "CLOCK_TAMPER_CLEANUP_SUCCESS_OK",
          payload: LocaleUtility.getLocaleMessage(data.statusCode)
        });
      }
    } else {
      let type = "LICENSE_CLEANUP_COMPLETE";
      let payload = {
        licenseDetail: undefined
      };

      Dispatcher.dispatch({
        type: type,
        payload: payload
      });
      Dispatcher.dispatch({
        type: "CLOCK_TAMPER_CLEANUP_SUCCESS_OK",
        payload: LocaleUtility.getLocaleMessage(data.statusCode)
      });
    }
  }

  let type = "DISABLE_LOADING_SPIN_DONE";
  Dispatcher.dispatch({
    type: type,
    payload: ""
  });

  if (errorDetails !== undefined) {
    type = "LICENSE_FETCH_ERROR";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleErrorMessage(
        errorDetails.errorCode,
        errorDetails.parameters
      )
    });
  }
}

export function processLicenseTokenList(data) {
  if (data !== undefined && data.status === "SUCCESS") {
    let type = "LICENSE_TOKEN_FETCH_COMPLETE";

    var rows = [];
    var map = new Map();
    if (
      data.licenseTokenList.token !== undefined &&
      data.licenseTokenList.token.length > 0
    ) {
      rows = data.licenseTokenList.token.map(item => {
        Object.entries(item).map(([key, value]) => {
          if (key.toLowerCase().indexOf("date") > -1) {
            item[key] = "";
            if (value > 0 && value !== 900703)
              item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
            else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
          }
          if (key==="ipAddressList") {
            item[key] = "";
            if (value.value.length > 0){
              item.ipAddress = value.value.toString();

            }
          }
          if (key==="hostIdList") {
            item[key] = "";
            if (value.value.length > 0){
              item.hostname = value.value.toString();

            }
          }
          if (key==="userNameList") {
            item[key] = "";
            if (value.value.length > 0){
              item.username = value.value.toString();

            }
          }
          if (key==="deviceIdList") {
            item[key] = "";
            if (value.value.length > 0){
              item.clientuniquename = value.value.toString();

            }
          }
          return "";
        });
        item.featureDesc = item.featureID + ":" + item.featureVersion;
        map.set(item.tokenID, item.rawLicenseToken);
        return item;
      });
    }

    let payload = {
      tableData: rows,
      cleanupTokenMap: map,
      sortAscending: true,
      sortIndex: 0,
      pagination: {
        required: true,
        style: "default",
        totalCount: rows.length,
        pageNumber: 1,
        pageSize: FetchHelper.getPageSize()
      }
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
}

export function processCleanupSuccess(data) {
  let action = "DISABLE_LOADING_SPIN_DONE";
  Dispatcher.dispatch({
    type: action,
    payload: ""
  });
  if (data !== undefined) {
    action = "LICENSE_CLEANUP_SUCCESS_OK";
    if (data.status === "SUCCESS") {
      Dispatcher.dispatch({
        type: action,
        payload: data.responseStatusMessage
      });
    } else {
        action = "CLEANUP_FETCH_ERROR";
        let payload = data.responseStatusMessage;
        if(data.errorDetail !== undefined) {
          if(data.errorDetail.errorMessage !== undefined) {
            payload = data.errorDetail.errorMessage;
          } else if(data.errorDetail.customMessage !== undefined) {
            payload = data.errorDetail.customMessage;
          }
        }
      Dispatcher.dispatch({
        type: action,
        payload: payload
      });
    }
  }
}

export function processBorrowLicenseList(data, licenseType) {    
  let type;
  let disableIds=[];
  if (data !== undefined) {
    if(data.selectedProductName === undefined) {
        data.selectedProductName = 'NONE';
    }

    var rows = [];
    if(data.licenseList !== undefined){
        rows = data.licenseList.license.map(item => {
            Object.entries(item).map(([key, value]) => {
                if(key.toLowerCase().indexOf('date') > -1){
                    item[key] = '';
                    if(value > 0 && value !== 900703)
                        item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
                    else 
              item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
                }
                return "";
            });
            item.featureDesc = item.featureID+':'+item.featureVersion;
            if (item.status === "false") {
            disableIds.push(item.licenseSignature);
        }
            return item
        });
    }

    let payload;
    if(licenseType === "ACTIVE"){
        type = "ACTIVE_BORROW_LICENSE_FETCH_COMPLETE";
        payload = {
            tableData: rows,
            pagination: data.pagination,
            productNameSelected: data.selectedProductName,
            sortAscending: true,
            sortIndex: 0,
            disableIds:disableIds
        };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    } else if(licenseType === "ARCHIVED"){
        type = "ARCHIVE_BORROW_LICENSE_FETCH_COMPLETE";
        payload = {
            tableData: rows,
            pagination: data.pagination,
            productNameSelected: data.selectedProductName,
            sortAscending: true,
            sortIndex: 0
        };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }

    const errorDetails = data.errorDetail
    if (errorDetails !== undefined) {
        type = "LICENSE_FETCH_ERROR";
        Dispatcher.dispatch({
            type: type,
            payload: LocaleUtility.getLocaleErrorMessage(errorDetails.errorCode, errorDetails.parameters)
        });
    }
  }
}

export function getAllProducts(data, showFirstProdOption) {
  let type = "";
  if (
    data.productNameList !== undefined &&
    data.productNameList.value !== undefined &&
    data.productNameList.value.length > 0
  ) {
    var map = new Map();
    var productList = [];
    data.productNameList.value.map(item => {
      var prods = item.split(":");
      if (prods.length === 2) {
        map.set(prods[0], prods[1]);
        productList.push({label: prods[1], value: prods[0]});
        //productSelected = prods[0];
      } else {
        map.set(item, item);
      }
      return "";
    });

    var productSelected = undefined;
    if (showFirstProdOption && map.keys() !== undefined) {
      productSelected = map.keys().next().value;
      FetchHelper.getAllProductFeatures(productSelected, true);
    }

    type = "LICENSE_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
    
    type = "PRODUCT_NAME_LIST_FETCH_COMPLETE";
    let payload = {
      selectData: map,
      productSelected: productSelected,
      productNamesForTenant: productList
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });

    if (productSelected !== undefined) {
      FetchHelper.getBorrowLicenses(productSelected, "ACTIVE");
      FetchHelper.getBorrowLicenses(productSelected, "ARCHIVED");
    }
  }else{
     type = "LICENSE_FETCH_SPIN_DONE";
      Dispatcher.dispatch({
      type: type,
      payload: ""
    });
  }

}

export function getAllProductFeatures(data, productName) {
  let payload = {
      selectData: [],
      productName: productName
    };
  let type = "PRODUCT_FEATURE_LIST_FETCH_COMPLETE";
  if (data.entityList !== undefined && data.entityList.value.length > 0) {
    payload = {
      selectData: data.entityList.value,
      productName: productName
    };
  } 
  Dispatcher.dispatch({
    type: type,
    payload: payload
  });

  type = "LICENSE_FETCH_SPIN_DONE";
  Dispatcher.dispatch({
    type: type,
    payload: ""
  });
}

export function processBorrowLicense(data) {
  let type;
  if (data !== undefined && data.status === "SUCCESS") {
    if (data.selectedProductName === undefined) {
      data.selectedProductName = "NONE";
    }

    let item = data.licenseList.license[0];
    Object.entries(item).map(([key, value]) => {
      if (key.toLowerCase().indexOf("date") > -1) {
        item[key] = "";
        if (value > 0 && value !== 900703)
          item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
        else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
      }
      return "";
    });
    item.featureDesc = item.featureID + ":" + item.featureVersion;

    let type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });

    type = "LICENSE_BORROW_FETCH_COMPLETE";
    let payload = {
      licenseDetail: item
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  } else {
    const errorDetails = data.errorDetail;
    type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
    if (errorDetails !== undefined) {
      type = "LICENSE_FETCH_ERROR";
      Dispatcher.dispatch({
        type: type,
        payload: LocaleUtility.getLocaleErrorMessage(
          errorDetails.errorCode,
          errorDetails.parameters
        )
      });
    }
  }
}

export function refreshBorrowedLicenseListView(data, productName) {
  const errorDetails = data.errorDetail;
  let statusMsg, type;
  if (errorDetails !== undefined) {
    statusMsg = LocaleUtility.getLocaleErrorMessage(
      errorDetails.errorCode,
      errorDetails.parameters
    );
    type = "LICENSE_BORROW_ERROR";
  } else {
    statusMsg = data.responseStatusMessage;
    type = "LICENSE_BORROW_SUCCESS_OK";
  }
  FetchHelper.getProducts("borrow_init", true);
  FetchHelper.getBorrowLicenses(productName, "ACTIVE", undefined);
  FetchHelper.getBorrowLicenses(productName, "ARCHIVED", undefined);
  Dispatcher.dispatch({
    type: type,
    payload: statusMsg
  });
}

export function getPACEntitlements(data, pacCode, paintTextInput) {
  let type = "PAC_ENTITLEMENTS_FETCH_COMPLETE";
  const errorDetails = data.errorDetail;
  if (errorDetails !== undefined) {
    type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });

    type = "LICENSE_FETCH_ERROR";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleErrorMessage(
        errorDetails.errorCode,
        errorDetails.parameters
      )
    });
  } else if (
    data.entitlementList !== undefined &&
    data.entitlementList.entitlements.length > 0
  ) {
    const options = [];
    var map = new Map();
    var selectedEnvironment = undefined;
    if (data.environmentList.environments !== undefined) {
      if (
        data.environmentSelect === undefined &&
        data.environmentList.environments.length > 1
      ) {
        selectedEnvironment = {
          label: LocaleUtility.getLocaleMessage("label.select.all"),
          value: "ALL"
        };
        options.push({
          label: LocaleUtility.getLocaleMessage("label.select.all"),
          value: "ALL",
        });
        data.environmentSelect = "ALL";
        map.set("ALL", data.entitlementList.entitlements);
      } else {
        data.environmentList.environments.map(
          item =>
            (selectedEnvironment = {
              label: item.environmentDesc,
              value: item.environmentCode
            })
        );
      }
      data.environmentList.environments.map(item =>{
          item.environmentDesc = getEnvLocalLabel(item.environmentDesc);
          options.push({
            label: item.environmentDesc,
            value: item.environmentCode
          })
        }
      );
    }

    var licenseList = [];
    data.entitlementList.entitlements.forEach(item => {
      if (map.has(item.environmentCode)) {
        var array = map.get(item.environmentCode);
        array.push(item);
        map.set(item.environmentCode, array);
      } else {
        map.set(item.environmentCode, [item]);
      }
      var key = "text_" + item.entitlementId;
      item.quantityToActivate = paintTextInput(key);

      item.environmentDesc = getEnvLocalLabel(item.environmentDesc);
    });

    if (
      data.targetActivationListString !== undefined &&
      data.targetActivationListString.value.length > 0
    ) {
      data.targetActivationListString.value.forEach(element => {
        licenseList.push(element);
      });
    }

    type = "PAC_ENTITLEMENTS_FETCH_COMPLETE";
    let payload = {
      entitlementData: map,
      environmentSelected: selectedEnvironment,
      activationCode: pacCode,
      licenseList: licenseList,
      currentStep: 2,
      environments: options
    };
    Dispatcher.dispatch({
      type: type,
      payload: payload
    });

    type = "PAC_ENTITLEMENTS_FETCH_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
  }
}

export function getEnvLocalLabel(labelKey){
  var localLabel = labelKey;
  if (labelKey === "Production") {
    localLabel = LocaleUtility.getLocaleMessage("label.production");
  } else if (labelKey === "Test System 1") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.test.system.1");
  }else if (labelKey === "Test System 2") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.test.system.2");
  } else if (labelKey === "Test System 3") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.test.system.3");
  } else if (labelKey === "Hot Standby System") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.hot.standby.system");
  } else if (labelKey === "Cold Standby System") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.cold.standby.system");
  } else if (labelKey === "Development System") {
    localLabel = LocaleUtility.getLocaleMessage("label.env.developemet.system");
  }
   return localLabel;
}

export function processPACLicenseList(data) {
  let type = "PAC_ENTITLEMENTS_FETCH_COMPLETE";
  const errorDetails = data.errorDetail;
  if (errorDetails !== undefined) {
    type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });

    type = "LICENSE_FETCH_ERROR";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleErrorMessage(
        errorDetails.errorCode,
        errorDetails.parameters
      )
    });
  } else if (
    data.targetActivationListString !== undefined &&
    data.targetActivationListString.value.length > 0
  ) {
    FetchHelper.requestValidateLicenseList(
      data.targetActivationListString.value
    );
  }
}

export function getUserAggrement(data) {
  let type = "";
  const errorDetails = data.errorDetail;
  if (errorDetails !== undefined) {
    type = "LICENSE_FETCH_ERROR";
    Dispatcher.dispatch({
      type: type,
      payload: LocaleUtility.getLocaleErrorMessage(
        errorDetails.errorCode,
        errorDetails.parameters
      )
    });
  } else if (
    data.agreementName !== undefined &&
    data.agreementName.length > 0
  ) {
    let eulaCheck = undefined,
      privacyCheck, defaultCheck,
      isStandAlone;

    data.agreementName.forEach(element => {
      if (element.type === "EULA" && element.value === "true") {
        eulaCheck = true;
      } else if (element.type === "CALL_HOME") {
        privacyCheck = element.value === "true";
      } else if (element.type === "MF_DEFAULT_CALL_HOME") {
        defaultCheck = element.value === "true";
      } else if (element.type === "IS_STANDALONE") {
        isStandAlone = element.value === "true";
      }
    });
    type = "USER_AGREEMENT_FETCH_COMPLETE";
    let payload = {
      eulaCheck: eulaCheck,
      privacyCheck: privacyCheck,
      defaultCheck: defaultCheck,
      isStandAlone: isStandAlone
    };
    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
  type = "DISABLE_LOADING_SPIN_DONE";
  Dispatcher.dispatch({
    type: type,
    payload: ""
  });
}

export function processProductFeatureLicenseList(data) {
  if (data !== undefined && data.status === "SUCCESS") {
    let type = "LICENSE_PRODUCT_FEATURE_PAGE_FETCH_COMPLETE";
    let notAppliedCount =0;
    let rows;
    if(data.licenseList.license !== null && data.licenseList.license.length>0){
    rows = data.licenseList.license.map(item => {
      Object.entries(item).map(([key, value]) => {
        if (key.toLowerCase().indexOf("date") > -1) {
          item[key] = "";
          if (value > 0 && value !== 900703)
            item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
            else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
        }
        return "";
      });

      if (
        item.ipAddressList !== undefined &&
        item.ipAddressList !== null &&
        item.ipAddressList.value.length > 0
      ) {
        item.lockCode = item.ipAddressList.value[0];
      }
      if (
        item.deviceIdList !== undefined &&
        item.deviceIdList !== null &&
        item.deviceIdList.value.length > 0
      ) {
        item.lockCode = item.deviceIdList.value[0];
      }
      if (
        item.hostIdList !== undefined &&
        item.hostIdList !== null &&
        item.hostIdList.value.length > 0
      ) {
        item.lockCode = item.hostIdList.value[0];
      }
      if (
        item.cloudIdList !== undefined &&
        item.cloudIdList !== null &&
        item.cloudIdList.value.length > 0
      ) {
        item.lockCode = item.cloudIdList.value[0];
      }
      if (
        item.macAddressList !== undefined &&
        item.macAddressList !== null &&
        item.macAddressList.value.length > 0
      ) {
        item.lockCode = item.macAddressList.value[0];
      }
      item.featureDesc = item.featureID + ":" + item.featureVersion;
      if (item.featureDescription !== undefined)
        item.featureDesc =
          item.featureDescription +
          " (" +
          item.featureID +
          ":" +
          item.featureVersion +
          ")";
        item["Status"] = item.status !== undefined
          ? item.status
          : item.m_status;
        if((item.status !== undefined? item.status : item.m_status)==="Not Applied") 
           notAppliedCount++;
      return item;
    });
    var featureTableData = [],featureModalData=[];
    data.productFLicenseList.forEach(tempItem => {
      let item = {};
      let productNum =tempItem.featureDescription + "(" + tempItem.featureId + ":" + tempItem.featureVersion + ")";
      item["Product_Number"] = productNum;
      item["Capacity"] = tempItem.capacity;
      let startDateTemp;
      if (tempItem.startDate !== undefined) {
        startDateTemp =
          tempItem.startDate > 0 && tempItem.startDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.startDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      } 
      item["Start_Date"] = startDateTemp;
      let expDateTemp;
      if (tempItem.expiryDate !== undefined) {
        expDateTemp =
          tempItem.expiryDate > 0 && tempItem.expiryDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.expiryDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      } 
      item["Expiry_Date"] = expDateTemp;
      item["Installed_On"]= (tempItem.installedOn > 0 && tempItem.installedOn !== 900703)? DateUtility.getFormatedDate(new Date(tempItem.installedOn)) : "Perpetual";
      item["Installed_By"] = tempItem.installedBy;
      featureTableData.push(item);
    });
    data.productFLicenseLst.forEach(tempItem => {
      let item = {};
      let productNum =
      tempItem.featureDescription !== undefined ? tempItem.featureDescription + "(" + tempItem.featureID + ":" + tempItem.featureVersion + ")" : tempItem.m_featureDescription + "(" + tempItem.m_featureID + ":" + tempItem.m_featureVersion + ")";
      item["Product_Number"] = productNum;
      item["LTU"] = tempItem.ltuCapacity !== undefined ? tempItem.ltuCapacity : tempItem.m_ltuCapacity;
      item["Capacity"] =
        tempItem.capacity !== undefined
          ? tempItem.capacity
          : tempItem.m_capacity;
      let startDateTemp;
      if (tempItem.extStartDate !== undefined) {
        startDateTemp =
          tempItem.extStartDate > 0 && tempItem.extStartDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.extStartDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      } else if(tempItem.m_extStartDate !== undefined){
        startDateTemp =
          tempItem.m_extStartDate > 0 && tempItem.m_extStartDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.m_extStartDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      }else{        
        startDateTemp =
          tempItem.startDate > 0 && tempItem.startDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.startDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      }
      item["Start_Date"] = startDateTemp;
      let expDateTemp;
      if (tempItem.extExpiryDate !== undefined) {
        expDateTemp =
          tempItem.extExpiryDate > 0 && tempItem.extExpiryDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.extExpiryDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      } else {
        expDateTemp =
          tempItem.m_extExpiryDate > 0 && tempItem.m_extExpiryDate !== 900703
            ? DateUtility.getFormatedDate(
                new Date(tempItem.m_extExpiryDate * 1000)
              )
            : LocaleUtility.getLocaleMessage("label.perpetual");
      }
      item["Expiry_Date"] = expDateTemp;
      featureModalData.push(item);
    });
    } else{
    rows=[];
    featureTableData = [];
    featureModalData=[];
    }
    featureTableData = [...new Set(featureTableData)];
    featureModalData = [...new Set(featureModalData)];
    var sortOrder;
    if (
      data.pagination !== undefined &&
      data.pagination !== null &&
      data.pagination.sortOrder === "ASC"
    ) {
      sortOrder = true;
    } else {
      sortOrder = false;     
    }
    data.pagination.required =true;
    data.pagination.style = "default";
    var map = new Map();
    if (
      data.productNameList !== undefined &&
      data.productNameList.value !== undefined && data.productNameList.value !== null &&
      data.productNameList.value.length > 0
    ) {
      data.productNameList.value.map(item => {
        var prods = item.split(":");
        if (prods.length === 2) {
          map.set(prods[0], prods[1]);
        } else {
          map.set(item, item);
        }
        return "";
      });
    }
    let payload = {
      tableData: rows, 
      impFeatureTableData: featureTableData,
      impFeatureModalData: featureModalData,
      PFpagination: data.pagination,
      productNameSelected: data.selectedProductName,
      productNameSelectOptions: map,
      sortAscending: sortOrder,
      sortIndex: data.pagination.sortBy,
      notAppliedCount:notAppliedCount
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  }
}

export function processNamedUserLicenseList(data, selectedLicenseId) {
  if (data !== undefined && data.status === "SUCCESS") {
    let type = "NAMEDUSER_LIC_FETCH_COMPLETE";
    let count = 0, defaultLicId;
    const rows = data.licenseList.license.map(item => {
      if(count === 0 && selectedLicenseId == undefined){
        defaultLicId = item.licenseID+"_ALL";
        count = 1;
      }else if(count === 0){
        count = 1;
        defaultLicId = selectedLicenseId;
      }
      item.licenseID = item.licenseID+"_ALL";
      Object.entries(item).map(([key, value]) => {
        if (key.toLowerCase().indexOf("date") > -1) {
          item[key] = "";
          if (value > 0 && value !== 900703)
            item[key] = DateUtility.getFormatedDate(new Date(value * 1000));
          else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
        } else if(key.toLowerCase().indexOf("licenselist") > -1){
          value.forEach(function(obj, index){
            // Start Date
            if (obj.startDate > 0 && obj.startDate !== 900703)
              obj.startDate = DateUtility.getFormatedDate(new Date(obj.startDate * 1000));
            else obj.startDate = LocaleUtility.getLocaleMessage("label.perpetual");
            // Expiry Date
            if (obj.expiryDate > 0 && obj.expiryDate !== 900703)
              obj.expiryDate = DateUtility.getFormatedDate(new Date(obj.expiryDate * 1000));
            else obj.expiryDate = LocaleUtility.getLocaleMessage("label.perpetual");
            // Installed On Date
            if (obj.installedDate > 0 && obj.installedDate !== 900703)
              obj.installedDate = DateUtility.getFormatedDate(new Date(obj.installedDate * 1000));
            else obj.installedDate = LocaleUtility.getLocaleMessage("label.perpetual");

            /**
             * If child available then dont show below fields in parent level
             */
            item.startDate = "";
            item.expiryDate = "";
            item.installedDate = "";
            item.installedBy = "";
            item.licenseType = "";

            setPropertiesToNamedUserObj(obj);
          });
        }
        return "";
      });

      setPropertiesToNamedUserObj(item);

      return item;
    });

    var sortOrder;
    if (data.pagination.sortOrder === "ASC") {
      sortOrder = true;
    } else {
      sortOrder = false;
    }

    // var map = new Map();
    // if (
    //   data.productNameList !== undefined &&
    //   data.productNameList.value !== undefined &&
    //   data.productNameList.value.length > 0
    // ) {
    //   data.productNameList.value.map(item => {
    //     var prods = item.split(":");
    //     if (prods.length === 2) {
    //       map.set(prods[0], prods[1]);
    //     } else {
    //       map.set(item, item);
    //     }
    //     return "";
    //   });
    // }

    data.pagination.required =true;
    data.pagination.style = "default";
    let payload = {
      tableData: rows,
      defaultLicId: defaultLicId,
      pagination: data.pagination,
      sortAscending: sortOrder,
      sortIndex: data.pagination.sortBy
    };
        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
}

function setPropertiesToNamedUserObj(item){
  if (
        item.ipAddressList !== undefined &&
        item.ipAddressList.value.length > 0
      ) {
        item.lockCode = item.ipAddressList.value[0];
      }
      if (
        item.deviceIdList !== undefined &&
        item.deviceIdList.value.length > 0
      ) {
        item.lockCode = item.deviceIdList.value[0];
      }
      if (item.hostIdList !== undefined && item.hostIdList.value.length > 0) {
        item.lockCode = item.hostIdList.value[0];
      }
      if (item.cloudIdList !== undefined && item.cloudIdList.value.length > 0) {
        item.lockCode = item.cloudIdList.value[0];
      }
      if (
        item.macAddressList !== undefined &&
        item.macAddressList.value.length > 0
      ) {
        item.lockCode = item.macAddressList.value[0];
      }

      item.featureDesc = item.featureID + ":" + item.featureVersion;
      if (item.featureDescription !== undefined)
        item.featureDesc = item.featureDescription +" (" +item.featureID +":" +item.featureVersion +
          ")";
    return item;      
}

export function processNamedUsers(data) {
    let type = "POPULATE_NAMEDUSER_MEMBERS";
    let payload = {};
    if(data.namedUserLicenseList !== undefined && 
        data.namedUserLicenseList.namedUserLicense[0] !== undefined){
        const rows = data.namedUserLicenseList.namedUserLicense[0].namedUsers.map(item => {
                      item.createdDate = DateUtility.getFormatedDate(new Date(item.createdDate));
                      return item;
                  });
      payload = {
        data : rows,
        pagination: data.pagination
      };
    }
    
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}

export function processNamedUserResp(data) {
  let type;
  type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
  if (data !== undefined && data.status !== "SUCCESS") {
    let errorDetails;

    if(data.namedUserLicenseList !== undefined && 
        data.namedUserLicenseList.namedUserLicense[0] !== undefined ){
        errorDetails = data.namedUserLicenseList.namedUserLicense[0].errorDetail;
    } 
    if(errorDetails === undefined && data.namedUserLicenseList.namedUserLicense[0].namedUsers[0] !== undefined){
        errorDetails = data.namedUserLicenseList.namedUserLicense[0].namedUsers[0].errorDetail;
    }

    if (errorDetails !== undefined) {
      type = "NAMEDUSER_ADD_ERROR";
      Dispatcher.dispatch({
        type: type,
        payload: LocaleUtility.getLocaleErrorMessage(
          errorDetails.errorCode,
          errorDetails.parameters
        )
      });
    }
  }
}

export function processLicenseKey(data){
  var res;
  if(data.constructor === Array)
  {
    res = data[0];
  }else{
    res = data;
  }
  if(res.indexOf("^") >-1){
      res = res.split("^");
      return res[1];
    }
  return res;
}
export function processTenantLicenseList(data, licenseType) {    
  let type;
  let disableIds=[];
  if (data !== undefined) {
    if(data.selectedProductName === undefined) {
        data.selectedProductName = 'NONE';
    }
    var rows = [];
    if(data.licenseList !== undefined){
        rows = data.licenseList.license.map(item => {
            Object.entries(item).map(([key, value]) => {
                if(key.toLowerCase().indexOf('date') > -1){
                    item[key] = '';
                    if(value > 0 && value !== 900703)
                        item[key] = DateUtility.getFormatedDate(new Date(value));
                    else 
                        item[key] = 'Perpetual';
                }
                return "";
            });
            item.featureDesc = item.featureID+':'+item.featureVersion;
            if (item.status === "false") {
            disableIds.push(item.licenseSignature);
        }
            return item
        });
    }

    let payload;
    if(licenseType === "ACTIVE"){
        type = "ACTIVE_TENANT_SPECIFIC_LICENSE_FETCH_COMPLETE";
        payload = {
            tableData: rows,
            pagination: data.pagination,
            productNameSelected: data.selectedProductName,
            sortAscending: true,
            sortIndex: 0,
            disableIds:disableIds
        };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });
    }
    const errorDetails = data.errorDetail
    if (errorDetails !== undefined) {
        type = "LICENSE_FETCH_ERROR";
        Dispatcher.dispatch({
            type: type,
            payload: LocaleUtility.getLocaleErrorMessage(errorDetails.errorCode, errorDetails.parameters)
        });
    }
  }
}
export function processTenanticenseCheckOut(data) {
  let type;
  if (data !== undefined && data.status === "SUCCESS") {
    if (data.selectedProductName === undefined) {
      data.selectedProductName = "NONE";
    }

    let item = data.licenseList.license[0];
    Object.entries(item).map(([key, value]) => {
      if (key.toLowerCase().indexOf("date") > -1) {
        item[key] = "";
        if (value > 0 && value !== 900703)
          item[key] = DateUtility.getFormatedDate(new Date(value));
        else item[key] = LocaleUtility.getLocaleMessage("label.perpetual");
      }
      return "";
    });
    item.featureDesc = item.featureID + ":" + item.featureVersion;

    let type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });

    type = "LICENSE_TENANT_FETCH_COMPLETE";
    let payload = {
      licenseDetail: item
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });
  } else {
    const errorDetails = data.errorDetail;
    type = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
      type: type,
      payload: ""
    });
    if (errorDetails !== undefined) {
      type = "LICENSE_FETCH_ERROR";
      let errorcode =errorDetails.errorCode;
      let msg=errorDetails.customMessage
      if(errorcode === '3011678' || errorcode === 3011678){
        msg=LocaleUtility.getLocaleErrorMessage(errorcode)
      }
      if(errorcode === '11005' || errorcode === 11005) {
        msg=errorDetails.errorMessage
      }
      Dispatcher.dispatch({
        type: type,
        payload: msg
      });
    }
  }
}
export function getTenantAllProducts(data, showFirstProdOption, refreshCall) {
  let type = "";
  if (
    data.productNameList !== undefined &&
    data.productNameList.value !== undefined &&
    data.productNameList.value.length > 0
  ) {
    var map = new Map();
    data.productNameList.value.map(item => {
      var prods = item.split(":");
      if (prods.length === 2) {
        map.set(prods[0], prods[1]);
        //productSelected = prods[0];
      } else {
        map.set(item, item);
      }
      return "";
    });

    var productSelected = undefined;
    if (showFirstProdOption && map.keys() !== undefined) {
      productSelected = map.keys().next().value;
      FetchHelper.getAllProductFeatures(productSelected, true);
    }
    type = "PRODUCT_NAME_LIST_FETCH_COMPLETE";
    let payload = {
      selectData: map,
      productSelected: productSelected,
      pageRefreshCall: true
    };

    Dispatcher.dispatch({
      type: type,
      payload: payload
    });

    if (productSelected !== undefined) {
     FetchHelper.getTenantSpecificLicenses("ALL", "ACTIVE", "ALL");
      
    }
  }

  type = "LICENSE_FETCH_SPIN_DONE";
  Dispatcher.dispatch({
    type: type,
    payload: ""
  });
}
export function refreshTenantLicenseListView(data, productName,tenantName,pagination) {
  const errorDetails = data.errorDetail;
  let statusMsg, type;
  if (errorDetails !== undefined) {
    statusMsg = LocaleUtility.getLocaleErrorMessage(
      errorDetails.errorCode,
      errorDetails.parameters
    );
    type = "LICENSE_BORROW_ERROR";
  } else {
    statusMsg = LocaleUtility.getLocaleMessage(data.responseStatusMessage);
    type = "LICENSE_BORROW_SUCCESS_OK";
    //FetchHelper.getProducts("borrow_init", true);
    FetchHelper.getTenantSpecificLicenses(productName, "ACTIVE", tenantName,pagination);
  }
  Dispatcher.dispatch({
    type: type,
    payload: statusMsg
  });
}

