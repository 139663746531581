import Dispatcher from "../flux/Dispatcher";
import { Trash as TrashIcon } from 'grommet-icons';
import React  from 'react';
import * as LocaleUtility from "./LocaleUtility";
import * as DateUtility from "./DateUtility";
import * as FetchHelper from "./FetchHelper";

export function displayUserList(data, selectedItem) {

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "USER_VIEW_PAGE_FETCH_COMPLETE";
    var session = FetchHelper.getSession();
    //const rows = data.userList;
    var map = new Map();
    var index = 0;
    let count = 0;
    data.userList.map(item => {
         if(selectedItem === undefined && count === 0) {
            selectedItem = item.userName;
            count++;
         }
        if(session.dbType === 'DB' || session.dbType === 'DBLDAP' || session.dbType === 'LDAP'){
            if(item.userName!=="admin" && item.userName !== session.currentTenantName){
                item.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
            }
        }        
        if(item.createdtime !== undefined){
            item.createdtime = DateUtility.getFormatedDate(new Date(item.createdtime));
            if(item.lastloggedintime !== undefined )
                item.lastloggedintime = DateUtility.getFormatedDate(new Date(item.lastloggedintime));
        }
        if(item.status !== undefined && item.status ==="active"){
            item.localStatus = LocaleUtility.getLocaleMessage("label.user.status.active");
        }
        else{
            item.localStatus = LocaleUtility.getLocaleMessage("label.user.status.inactive");
        }
        map.set(index, item);
        index++;
        return "";
    });
    let payload = {
        tableData: data.userList,//data.userList,
        userMap: map,
        selectedUser: selectedItem,
        pagination: data.pagenation,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}


}


export function displayGroupList(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "GROUP_VIEW_PAGE_FETCH_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var session = FetchHelper.getSession();
    var index = 0;
     data.groupList.map(item => {
        if(session.dbType === 'DB' || session.dbType === 'DBLDAP'){
            item.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
        }
        if(item.createdtime !== undefined){
            item.createdtime = DateUtility.getFormatedDate(new Date(item.createdtime));
        }
        map.set(index, item);
        index++;
        return "";
    });
    let payload = {
        //tableData:data.userList,
        groupData: data.groupList,
        groupMap: map,
        //pagination: data.pagination,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}
}
  export function displayLdapGroupList(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "GROUP_VIEW_LDAP_FETCH_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var session = FetchHelper.getSession();
    var index = 0;
     data.map(item => {
        if(session.dbType === 'DB' || session.dbType === 'DBLDAP' || session.dbType === 'LDAP'){
            item.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
        }
        if(item.createdtime !== undefined){
            item.createdtime = DateUtility.getFormatedDate(new Date(item.createdtime));
        }
        map.set(index, item);
        index++;
        return "";
    });
    let payload = {
        //tableData:data.userList,
        ldapGroupData: data,
        //groupMap: map,
        //pagination: data.pagination,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}
}
export function addGroupUser(data, selectedItem){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "ADD_GROUP_TO_USER_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var index = 0;
    let count = 0;
     data.userList.map(item => {
         if(selectedItem === undefined && count === 0) {
            selectedItem = item.userName;
            count++;
         }
        if(item.firstName!=="Administrator"){
            item.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
        }
        if(item.createdtime !== undefined){
            item.createdtime = DateUtility.getFormatedDate(new Date(item.createdtime));
            if(item.lastloggedintime !== undefined)
                item.lastloggedintime = DateUtility.getFormatedDate(new Date(item.lastloggedintime));
        }
        map.set(index, item);
        index++;
        return "";
    });
    let payload = {
        tableData:data.userList,
        groupData: data.groupList,
        groupMap: map,
        selectedUser:selectedItem,
        //pagination: data.pagination,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}
  
}

export function userGroupList(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "USER_GROUP_VIEW_PAGE_FETCH_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var index = 0;
     data.groupList.map(item => {
        item.delete = <TrashIcon className="grommetux-control-icon grommetux-color-index-critical"/>;
        if(item.groupCreatedTime !== undefined){
            item.groupCreatedTime = DateUtility.getFormatedDate(new Date(item.groupCreatedTime));
        }
        map.set(index, item);
        index++;
        return "";
    });

    var prodMap = new Map();
        
    data.productList.map(item => {
        var prods = item.split(":");
        if(prods.length === 2){
            prodMap.set(prods[0], prods[1]);
        } else {
            prodMap.set(item, item);
        }
        return "";
    });

    let payload = {
        tableData: data.groupList,//data.userList,
        roleList : data.roleList,
        productList: prodMap,
        groupName: data.groupName,
        groupMap: map,
        pagination: data.pagenation,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}
    

}

export function displayUserForGroupList(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "USER_SEARCH_PAGE_FETCH_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var index = 0;
    var session = FetchHelper.getSession();
    var users = [];
     data.userList.map(item => {
        if(item.userName !== 'admin' && item.userName !== session.currentTenantName){
            users.push(item);
            map.set(index, item);
            index++;
        }
        return "";
    });
    let payload = {
        tableData: users,//data.userList,
        userMap: map,
        //pagination: data.pagination,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

}
  
}

 export function displayLicenseList(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
    let type = "BUYMORE_LICENSE_FETCH_COMPLETE";

   
    //const rows = data.userList;
    var map = new Map();
    var prodMap = new Map();
    var index = 0;
    data.productDefList.map(item => {
       // item.aditionalQuantity= <TextField />
        map.set(index, item);
        index++;
        return "";
    });
    data.productNameList.map(item => {
        var prods = item.split(":");
        if(prods.length === 2){
            prodMap.set(prods[0], prods[1]);
        } else {
            prodMap.set(item, item);
        }
        return "";
    });
    let payload = {
        tableData: data.productDefList,
        productMap: map,
        productNameList:prodMap,
        //pagination: data.pagination,
        sortAscending: true,
        sortIndex: 0
    };

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

    Dispatcher.dispatch({
        type: "OK_SPIN_DONE",
        payload: ''
    });

}
  
}



export function displayLicensResp(data){

    if (data !== undefined /* &&
        data.status === 'SUCCESS'*/) {
        let type = "LICENSE_UPDATE_RESPONSE";

    
        //const rows = data.userList;
        var map = new Map();
        var index = 0;
        data.productDefList.map(item => {
        // item.aditionalQuantity= <TextField />
            map.set(index, item);
            index++;
            return "";
        });
        let payload = {
            tableData: data.productDefList,
            mailServerConfigured:data.mailServerConfigured,
            resStatus: data.status,
            sortAscending: true,
            sortIndex: 0
        };
        if(data.status !== 'SUCCESS' && 
        (data.mailServerConfigured === undefined || data.mailServerConfigured === false)){
            payload.defaultMail = {subject: data.subject,
                                    tomail: data.toMail,
                                    businessMail: data.businessMail,
                                    defaultMailMsg:data.defaultMailMsg };
        
        }

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });

    }
  
}

export function getLicenseReport(data, searchResult){

    if (data !== undefined ) {
    let type = "LICENSE_REPORT_RESPONSE";
    let pagination = undefined;
    data.map(item => {
        if(item.lastAcsTime !== undefined){
            item.lastAcsTime = DateUtility.getFormatedDate(new Date(item.lastAcsTime));
        }
        if(item.pagination && pagination === undefined){
            pagination = item.pagination;
            pagination.required = true;
        }
        return "";
    });


    let payload = {
        tableData: data,
        searchResult: searchResult
    };

    if(pagination !== undefined){
        payload.pagination = pagination;
    }

    Dispatcher.dispatch({
        type: type,
        payload: payload
    });

    }
}

export function getUserDetail(data, changedDB) {

    if (data.message === 'SUCCESS' && data.userList.length === 1) {
        let type = "USER_DETAIL_FETCH_COMPLETE";
        let payload = {
            user: data.userList[0],
            groupList: data.resultGroupList
        };

        Dispatcher.dispatch({
            type: type,
            payload: payload
        });

    } else {
        changedDB = false;
        Dispatcher.dispatch({
            type: "USER_DETAIL_FETCH_ERROR",
            payload: LocaleUtility.getLocaleErrorMessage(data.errorDetail.errorCode)
        });
    }

    Dispatcher.dispatch({
        type: "USER_DETAIL_SPIN_DONE",
        payload: ''
    });
    if(changedDB){
        let type = "USER_DETAIL_UPDATE_SUCCESS_OK";
        Dispatcher.dispatch({
            type: type,
            payload: LocaleUtility.getLocaleMessage("label.saved.successfully")
        });
    }

}

export function processUserPriviledge(data){
    if (data !== undefined &&
        data.status === 'SUCCESS') {
        Dispatcher.dispatch({
            type: "USER_ROLE_FETCH_COMPLETE",
            payload: true
        });
    } else {
        const errorDetails = data.errorDetail
        if (errorDetails !== undefined) {
            Dispatcher.dispatch({
                type: "USER_ROLE_FETCH_ERROR",
                payload: LocaleUtility.getLocaleErrorMessage(errorDetails.errorCode, errorDetails.parameters)
            });
        }
    }
    let action = "DISABLE_LOADING_SPIN_DONE";
    Dispatcher.dispatch({
        type: action,
        payload: ''
    }); 
}

