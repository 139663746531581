import Dispatcher from "../flux/Dispatcher";
import * as DateUtility from "./DateUtility";
import * as Util from "./Util";
import * as LocaleUtility from "./LocaleUtility";
import {isNumber} from "./ValidationUtility";
export function processTenantUsageReportList(data, sortIndex) {
    let type = "POPULATE_TENANT_USAGE_REPORT_LIST";
    let selRows = [];
    let disRows = [];
    let licModelMap = {};
    let featMap = {};
    let cumulateUsageMap = {};
    let featureVersionMap = {};
    let dependableFeatures = [];
    let tokenBaseFeatures = {}
    data.allFeatureList.map( e => {
        if(e.rowSelected) {
            selRows.push(e.featureId);
        }
        if(e.rowDisabled) {
            disRows.push(e.featureId);
        }
        featureVersionMap[e.featureId] = e.idVersionStr;
        licModelMap[e.featureId+":"+e.featureVersion] = e.licenseModel;
        featMap[e.featureId+":"+e.featureVersion] = e.featureDescription;
        tokenBaseFeatures[e.featureId+":"+e.featureVersion] = e.isTokenFeature;
        if(e.lastCumulativeUsage >= 0) {
            cumulateUsageMap[e.featureId+":"+e.featureVersion] = e.lastCumulativeUsage;
        }
        if(e.dependentFeatureList && e.dependentFeatureList.length > 0) {
            processDependableFeatures(e.dependentFeatureList, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, e, dependableFeatures);
        }
        if(e.licenseModel === 6) {
            e.commuteCap = e.issuedCap + e.commuteCap;
            e.issuedCap = 0;
        }
        if(e.licenseModel === 7) {
            e.commuteCap = e.issuedCap + e.commuteCap;
            e.issuedCap = 0;
        }
        return "";
    });
            
    let payload = {
        allFeatureList: data.allFeatureList,
        productNameList: data.productNameList,
        machineTampered: data.machineTampered,
        status: data.status,
        errorDetail: data.errorDetail,
        selRows: selRows,
        disRows: disRows,
        licModelMap: licModelMap,
        featMap: featMap,
        tokenBaseFeatures: tokenBaseFeatures,
        cumulateUsageMap: cumulateUsageMap,
        sortIndex: sortIndex,
        sortOrder: data.pagination.sortOrder,
        pagination: data.pagination,
        featureVersionMap: featureVersionMap,
        dependableFeatures: dependableFeatures

    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}
function processDependableFeatures(childData, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, parent, dependableFeatures) {
    childData.map( e => {
        e.toolTipKey = "";
        if(e.rowSelected) {
            selRows.push(e.featureId);
        }
        if(e.rowDisabled) {
            disRows.push(e.featureId);
        }
        dependableFeatures.push(e.featureId+":"+e.featureVersion);
        featureVersionMap[e.featureId] = e.idVersionStr;
        licModelMap[e.featureId+":"+e.featureVersion] = e.licenseModel;
        featMap[e.featureId+":"+e.featureVersion] = e.featureDescription;
        if(e.lastCumulativeUsage >= 0) {
            cumulateUsageMap[e.featureId+":"+e.featureVersion] = e.lastCumulativeUsage;
        }
        if(e.dependentFeatureList && e.dependentFeatureList.length > 0) {
            processDependableFeatures(e.dependentFeatureList, selRows, disRows, featureVersionMap, licModelMap, featMap, cumulateUsageMap, e);
        }
        e.availableCap = " - "
        if(parent.licenseModel === 6) {
            e.issuedCap = 0;
        }
        if(parent.licenseModel === 7) {
            e.issuedCap = 0;
        }
        if(e.lastCumulativeUsage !== null && e.lastCumulativeUsage !== undefined) {
            e.commuteCap = e.lastCumulativeUsage+"("+e.consumedLicenseCap+")";
        } else {
            if(parent.licenseModel === 6) {
                e.commuteCap = e.issuedCap + e.commuteCap;
            }
            if(parent.licenseModel === 7) {
                e.commuteCap = e.issuedCap + e.commuteCap;
            }
        }
        e.totalCap = " - ";
        e.totalInstalledLicenseCap = " - ";
        return "";
    });
}
export function processTenantUsageReportSearchResults(data, sortIndex) {
    let type = "POPULATE_TENANT_USAGE_REPORT_SEARCH_RESULTS";
    let clientInfoMap = {};
    let executedByMap = {};
    let defaultIds = [];
    let defDisabledIds = [];
    let enablePurgeBtn = false;
    let enableRevokeBtn = false;
    data.reportSearchList.map(item => {
        let mapKey = item["id"];
        let clientInfoObj = {
            "ipAddress": item["ipAddress"],
            "userName": item["userName"],
            "hostID": item["hostID"],
            "productCode": item["productCode"]+"_"+item["productCurrentVersion"],
            "applicationName": item["applicationName"]
        }
        let executedInfoObj = {
            "checkedOutBy": item["checkedOutBy"],
            "checkedInBy": item["checkedInBy"]
        }
        clientInfoMap[mapKey] = clientInfoObj;
        executedByMap[mapKey] = executedInfoObj;
        Object.entries(item).map(([key, value]) => {
            if(key === "licenseExpiryDate" || key === "licenseStartDate" || key === "lastCheckedInDate") {
               if(value === 0) {
                    item[key] = "";
                } else {
                    item[key] = DateUtility.getFormatedDate(new Date(value));
                }
            } else if(key === "ipAddress" || key === "checkedInBy") {
                item[key] = LocaleUtility.getLocaleMessage("label.click.here")
            }
            if(item["checkedIn"] > 0) {
                enablePurgeBtn = true;
            }
            if(key === "licenseTypeId"){
                if(item[key] !== "201" && item[key] !== "301") {
                    if(item["checkedIn"] > 0) {
                        defDisabledIds.push(item["idRevokedId"]);
                    }
                    if(item["lastCheckedInDate"] === 0 || item["lastCheckedInDate"] === "") {
                        enableRevokeBtn = true;
                    }
                    if(item["revoked"] === "1" && defaultIds.indexOf(item["idRevokedId"]) === -1) {
                        defaultIds.push(item["idRevokedId"]);
                    }
                } else {
                    item["hiddenCheckBox"] = "Y";
                }
            }
            if(key === "units" && item[key] !== undefined) {
                try {
                    item[key] = Util.roundOfValue(item[key]);
                } catch(error) {
                    console.log("Units Conversion Error =>"+error);
                }
            }
            return "";
        });
        return "";
    });
    let payload = {
        reportSearchList: data.reportSearchList,
        clientInfoMap: clientInfoMap,
        executedByMap: executedByMap,
        status: data.status,
        errorDetail: data.errorDetail,
        sortIndex: sortIndex,
        pagination: data.pagination,
        sortOrder: data.pagination.sortOrder,
        defaultIds: defaultIds,
        defDisabledIds: defDisabledIds,
        enablePurgeBtn: enablePurgeBtn,
        enableRevokeBtn: enableRevokeBtn
    };
    Dispatcher.dispatch({
        type: type,
        payload: payload
    });
}