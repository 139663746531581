import React, { Component } from "react";
import { Button } from "grommet/components/Button";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UserConfigurationStore";
import TableGrid from "../../../components/commons/table/APTable";

import { TextInput } from 'grommet/components/TextInput';
import { FormField } from "grommet/components/FormField";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import SpinningNotification from '../../generic/SpinningNotification';
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import ClientInfoPopup from "./ClientInfoPopup";
import ExecutedByInfo from "./ExecutedByInfo";
import { Anchor } from 'grommet/components/Anchor';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportsSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "serverLicenseTable.lastCheckedInDate",
                sortOrder: "DESC"
            },
            executedByMap: "",
            clientInfoMap: "",
            clientInfoObj: "",
            executedByInfoObj: "",
            reportEmailId: "",
            selectedIds: [],
            defaultSelIds: [],
            defaultSelIdsToValidate:[],
            disabledRows: [],
            enablePurgeBtn: false,
            enableRevokeBtn: false,
            sortIndex: 7,
            sortAscending: true,
            loadedData: false,
            session: undefined,
            popupTop: "800px",
            popupLeft: "550px",
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadSearchTableGrid = this.loadSearchTableGrid.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.onClickExecutedByInfo = this.onClickExecutedByInfo.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.handleChangeEmailId = this.handleChangeEmailId.bind(this);
        this.purgeOrRevokeButtonAction = this.purgeOrRevokeButtonAction.bind(this);
        this.findData = this.findData.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.sendMailAction = this.sendMailAction.bind(this);
        this.handleRevokeAction = this.handleRevokeAction.bind(this);
        this.handlePurgeAction = this.handlePurgeAction.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.downloadCommuterLicense = this.downloadCommuterLicense.bind(this);
        this.onConfirmMail = this.onConfirmMail.bind(this);
        this.onConfirmAction = this.onConfirmAction.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onClickClientInfo(key, event) {
        let clientObj = this.state.clientInfoMap[key];
        this.setState({clientInfoObj: clientObj});
        this.setState({popupLeft: event.pageX+"px"}); 
        this.setState({popupTop: event.pageY+"px"});
    }
    onClickExecutedByInfo(key, event) {
        let executedObj = this.state.executedByMap[key];
        this.setState({executedByInfoObj: executedObj});
        this.setState({popupLeft: event.pageX+"px"}); 
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({clientInfoObj: ""});
        this.setState({executedByInfoObj: ""});
    }
    handleChangeEmailId(event) {
        this.setState({reportEmailId: event.target.value});
    }
    onRowClicked(keys, event) {
        this.setState({selectedIds: keys});
        this.setState({defaultSelIds: keys})
    }

    onConfirmMail(flag, mailid){
        if(flag === 1){
            let params = this.props.searchParams;
            params["reportMailId"] = mailid;
            FetchHelper.listUsageReportSearchResults(this.context.intl, params, "report");
        }
    }
    sendMailAction() {
        let emailId = this.state.reportEmailId;
        if(emailId === "") {
            ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.email.alert"), "USAGE_REPORTS_PAGE_ERROR");
        } else {
            let emailArray=emailId.split(",");
            let correctMailId = "";
            let count = 0;
            for(var i=0; i<emailArray.length ; i++){
                if(emailArray[i] !== "" && ValidationUtility.validateEmail(emailArray[i].trim())) {
                    count++;
                    if(i === emailArray.length-1 || emailArray.length === 1){
                        correctMailId = correctMailId + emailArray[i];
                    } else {
                        correctMailId = correctMailId + emailArray[i] + ",";
                    }
                }
            }
            if(count < 1){
                ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.email.alert"), "USAGE_REPORTS_PAGE_ERROR");
            } else if((count > 0) && (count < emailArray.length) ){
                ValidationUtility.confirm(
                    this.findLabel("usage.search.email.warn"),
                    this.onConfirmMail, correctMailId,  "", "")
            } else {
                let params = this.props.searchParams;
                params["reportMailId"] = correctMailId;
                FetchHelper.listUsageReportSearchResults(this.context.intl, params, "report");
            }
        }
    }
    handleRevokeAction() {
        this.purgeOrRevokeButtonAction(false);
    }
    handlePurgeAction() {
        this.purgeOrRevokeButtonAction(true);
    }

    onConfirmAction(flag){
        if(flag === 1){
            let params = this.props.searchParams;
            FetchHelper.listUsageReportSearchResults(this.context.intl, params, "purge");
            this.setState({enableRevokeBtn: false});
            this.setState({enablePurgeBtn: false});
            this.setState({loadedData: false});
        }
    }
    purgeOrRevokeButtonAction(flag) {
        let params = this.props.searchParams;
        if(flag){
            ValidationUtility.confirm(
                this.findLabel("usage.search.purge.warn"),
                this.onConfirmAction, undefined, "", "");
        } else {
            if(this.state.selectedIds.length > 0) {
                let revokeIds = [];
                this.state.defaultSelIdsToValidate.map(id=>{
                    if(this.state.selectedIds.indexOf(id) === -1) {
                        revokeIds.push(id);
                    }
                    return "";
                });
                this.state.selectedIds.map(id=>{
                    if(this.state.defaultSelIdsToValidate.indexOf(id) === -1) {
                        revokeIds.push(id);
                    }
                    return "";
                });
                params["serverLicenses"] = revokeIds.join();
                if(revokeIds.length > 0) {
                    FetchHelper.listUsageReportSearchResults(this.context.intl, params, "revoke");
                    this.setState({enableRevokeBtn: false});
                    this.setState({enablePurgeBtn: false});
                    this.setState({loadedData: false});
                } else {
                    ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.revoke.alert"), "USAGE_REPORTS_PAGE_ERROR");
                }
            } else {
                ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.revoke.alert"), "USAGE_REPORTS_PAGE_ERROR");
            }
        }
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let searchParams = this.props.searchParams;
        let reportType = searchParams["searchReportType"];
        let sortBy = "serverLicenseTable.lastCheckedInDate";
        if(index === 1) {
            sortBy = "serverLicenseTable.licenseStartDate";
        } else if(index === 2) {
            sortBy = "serverLicenseTable.licenseExpiryDate";
        } else if(index === 3) {
            sortBy = "serverLicenseTable.licenseType";
        } else if(index === 4) {
            sortBy = "serverLicenseTable.units";
        } else if(index === 7) {
            sortBy = "serverLicenseTable.lastCheckedInDate";
        } else if(index === 0 && "I" === reportType) {
            sortBy = "floatingLicenseDetails.floatingFeature.featureDescription";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        paginationTemp.pageNumber = 1;
        this.findData(index, paginationTemp);
    }

    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadSearchTableGrid);
        APTableStore.on("change", this.props.getPagination);
        this.findData(this.state.sortIndex, this.state.pagination);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadSearchTableGrid);
        APTableStore.removeListener("change", this.props.getPagination);
    }
    findData(sortIndex, pagination) {
        this.setState({defaultSelIds: []});
        let params = this.props.searchParams;
        params["pagination"] = pagination;
        params["sortIndex"] = sortIndex;
        FetchHelper.listUsageReportSearchResults(this.context.intl, this.props.searchParams, "");
        this.setState({loadedData: false});
    }
    downloadCommuterLicense(key) {
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(this.state.downloadLicMap && this.state.downloadLicMap[key] && isAdmin) {
            let licInfo = this.state.downloadLicMap[key].split("####@@@@");
            let licId = licInfo[0];
            let checkoutId = licInfo[1];
            let request = {
                licenseId: licId,
                checkoutId: checkoutId
            }
            FetchHelper.downloadInuseLicense(request, this.context.intl);
        }
    }
    loadSearchTableGrid() {
        let licModel = localStorage.getItem("licenseModel");
        if(licModel === "6" || licModel === 6) {
            licModel = 6;
        }
        if(licModel === "7" || licModel === 7) {
            licModel = 7;
        }
        let sortIndex = APTableStore.getState().sortIndex;
        let sortAscending = APTableStore.getState().sortOrder;
        this.setState({sortIndex: sortIndex});
        if(sortAscending === "ASC") {
            this.setState({sortAscending: true});
        } else {
            this.setState({sortAscending: false});
        }
        let defDisIds = APTableStore.getState().defDisabledIds;
        let repList = APTableStore.getState().reportSearchList;
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        let downloadLicMap = {};
        if(repList !== undefined) {
            var validFeatures = FetchHelper.getUserFeatures();
            var showDownload = false;
            validFeatures.then(data => {
                repList.map(item=> {
                    if(defDisIds.indexOf(item.idRevokedId) === -1 && !isAdmin) {
                        defDisIds.push(item.idRevokedId);
                    }
                    showDownload = isAdmin;
                    if(data !== undefined && data.indexOf(item.featureId+":"+item.featureVersion) === -1){
                        item["hiddenCheckBox"] = "Y";
                        showDownload = false;
                    }
                    Object.entries(item).map(([key, value]) => { 
                        if(key === "licenseType") {
                            let licenseTypeLabel = item[key];
                            if(item[key] === 'Live'){
                                licenseTypeLabel = LocaleUtility.getLocaleMessage("label.license.type.live");
                            }else if(item[key] === 'Commuter'){
                                licenseTypeLabel = LocaleUtility.getLocaleMessage("label.license.type.commuter");
                            }

                            if((item["licenseTypeId"] === "201" || item["licenseTypeId"] === "301")
                                && item["checkedIn"] === 0 && (licModel !== 6 || licModel !== 7)) {
                                if(showDownload) {
                                    item[key]= <Anchor label={licenseTypeLabel} onClick={(event)=>this.downloadCommuterLicense(item["id"])}/>
                                } else {
                                    item[key] = licenseTypeLabel;
                                }
                                downloadLicMap[item["id"]] = item["licenseID"]+"####@@@@"+item["checkOutId"];
                            }else{
                                item[key] = licenseTypeLabel;
                            }
                        } else if(key === "groupsId"){
                            if(item[key] === 'Common Pool'){
                                item[key] = LocaleUtility.getLocaleMessage("label.common.pool");
                            }
                        }
                        return "";
                    });
                    return "";
                });

                let pagination = APTableStore.getState().pagination;
                pagination.required = true;
                pagination.style = "default";
                this.setState({session: FetchHelper.getSession(),
                    downloadLicMap: downloadLicMap,
                    defaultSelIds: APTableStore.getState().defaultIds,
                    disabledRows: defDisIds,
                    defaultSelIdsToValidate: APTableStore.getState().defaultIds,
                    enablePurgeBtn: APTableStore.getState().enablePurgeBtn,
                    enableRevokeBtn: APTableStore.getState().enableRevokeBtn,
                    rowData: repList,
                    clientInfoMap: APTableStore.getState().clientInfoMap,
                    executedByMap: APTableStore.getState().executedByMap,
                    pagination: pagination
                });
                ConfigurationUtils.hideLoadMask(this.context.intl);
                return "";
            });
        }
    }
    render() {
        //let intl = this.context.intl;
        let hideInfo = "tooltipContent tooltipContentHide";
        let hideExcutedInfo = "tooltipContent tooltipContentHide";
        let infoObj = "";
        let executeObj = "";
        let purgeBtnCls = "disanbleExcludeClientsSection";
        let emailFieldCls = "disanbleExcludeClientsSection";
        let emailBtnCls = "disanbleExcludeClientsSection";
        if(this.state.enablePurgeBtn) {
            purgeBtnCls = "enableExcludeClientsSection";
        }
        let revokeBtnCls = "disanbleExcludeClientsSection";
        if(this.state.enableRevokeBtn) {
            revokeBtnCls = "enableExcludeClientsSection";
        }
        if(this.state.rowData.length > 0) {
            emailFieldCls = "enableExcludeClientsSection";
            emailBtnCls = "enableExcludeClientsSection";
        }
        if(this.state.clientInfoObj !== "") {
            infoObj = this.state.clientInfoObj;
            hideInfo = "tooltipContent tooltipContentshow";
        }
        if(this.state.executedByInfoObj !== "") {
            executeObj = this.state.executedByInfoObj;
            hideExcutedInfo = "tooltipContent tooltipContentshow";
        }
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        let searParams = this.props.searchParams;
        let reportType = searParams["searchReportType"];
        return (
            <div >
                <section pad="none" wrap={false} responsive={false} justify="center">
                        <div style={{width: "100%", float: "left"}}>
                            <TableGrid id="usage_search_grid"
                                header={[
                                    {
                                        columnName: "featureDescription",
                                        headerLabel: this.findLabel("usageReportFeatDesc"),
                                        width: "auto",
                                        columnKey: "featureDescription",
                                        sort: reportType === "I" ? true : false, 
                                    },
                                    {
                                        columnName: "licenseStartDate",
                                        headerLabel: this.findLabel("usage.search.startdate.label"),
                                        width: "auto",
                                        columnKey: "licenseStartDate",
                                        sort: true,
                                    },
                                    {
                                        columnName: "licenseExpiryDate",
                                        headerLabel: this.findLabel("usage.search.expdate.label"),
                                        width: "auto",
                                        columnKey: "licenseExpiryDate",
                                        sort: true,
                                    },
                                    {
                                        columnName: "licenseType",
                                        headerLabel: this.findLabel("usage.search.checkouttype.label"),
                                        width: "auto",
                                        columnKey: "licenseType",
                                        sort: true,
                                    },
                                    {
                                        columnName: "units",
                                        headerLabel: this.findLabel("usage.search.capacity.label"),
                                        width: "7%",
                                        columnKey: "units",
                                        sort: true,
                                    },
                                    {
                                        columnName: "ipAddress",
                                        headerLabel: this.findLabel("usage.search.clientinfo.label"),
                                        width: "10%",
                                        columnKey: "id",
                                        href: true,
                                        onClick: this.onClickClientInfo,
                                    },
                                    {
                                        columnName: "checkedInBy",
                                        headerLabel: this.findLabel("usage.search.executedby.label"),
                                        width: "10%",
                                        columnKey: "id",
                                        href: true,
                                        onClick: this.onClickExecutedByInfo,
                                    },
                                    {
                                        columnName: "lastCheckedInDate",
                                        headerLabel: this.findLabel("usage.search.checkintime.label"),
                                        width: "auto",
                                        columnKey: "lastCheckedInDate",
                                        sort: true,
                                    },
                                    {
                                        columnName: "groupsId",
                                        headerLabel: this.findLabel("usage.search.pool.label"),
                                        width: "auto",
                                        columnKey: "groupsId",
                                    }
                                ]}
                                rowData={this.state.rowData}
                                pagination={this.state.pagination}
                                rowSelection={3}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                onChangeCheckBoxCallback={this.onRowClicked}
                                defaultSelectIds={this.state.disabledRows}
                                disabledRowIds={this.state.disabledRows}
                                rowKey="idRevokedId"
                                selectAllRows={(this.state.rowData.length > 0? true: false)}
                                uncheckTheDisabled={false}
                            />
                        </div>
                        <div style={{width: "100%", float: "left", padding: "3px"}}>
                            <table >
                                <tr>
                                    <td >
                                        <div className="button_left_wrap">
                                            {isAdmin ? 
                                            <div className="button_wrap_primary">
                                                <Button className={purgeBtnCls} id="usage_search_view_purgeBtn" label={this.findLabel("label.button.purge")} plain={false} primary={true} onClick={this.handlePurgeAction} />
                                            </div>
                                            :null}
                                            <span style={{padding: "5px"}}></span>
                                            {isAdmin ? 
                                            <div className="button_wrap_primary">
                                                <Button className={revokeBtnCls} id="usage_search_view_revokeBtn" label={this.findLabel("label.button.revoke")} plain={false} primary={true} onClick={this.handleRevokeAction} />
                                            </div>
                                            :null}
                                            <span style={{padding: "5px"}}></span>
                                            <div className="button_wrap_secondary">
                                            <Button id="usage_search_view_backBtn" label={this.findLabel("label.button.back")} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{float: "left", verticalAlign: "middle"}} >
                                        <div className="horizantal_label_input">
                                            <FormField style={{ width: "60em" }} label= {this.findLabel("usage.search.view.label")} >
                                                <TextInput id="usage_email_reportId" onChange={event =>this.handleChangeEmailId(event)}/>
                                            </FormField>
                                        </div>
                                    </td>
                                    <td style={{width: "20%"}} >
                                        <div className="button_left_wrap">
                                            <div className="button_wrap_primary">
                                            <Button id="usage_search_view_sendMailBtn" label={this.findLabel("label.button.sendmail")} plain={false} primary={true} onClick={this.sendMailAction} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <SpinningNotification centerPage={true} />
                        </div>
                        <div className="tooltipMain">
                            <div id="client-info-div-id" className={hideInfo} style={{"top": this.state.popupTop, "left": this.state.popupLeft}}>
                                <ClientInfoPopup 
                                    ipAddress = {infoObj.ipAddress}
                                    userName = {infoObj.userName}
                                    hostID = {infoObj.hostID}
                                    productCode = {infoObj.productCode}
                                    applicationName = {infoObj.applicationName}
                                    closeTootipAction={this.closeTootipAction}
                                />
                            </div>
                            <div id="executed-info-div-id" className={hideExcutedInfo} style={{"top": this.state.popupTop, "left": this.state.popupLeft}}>
                                <ExecutedByInfo 
                                    checkedOutBy = {executeObj.checkedOutBy}
                                    checkedInBy = {executeObj.checkedInBy}
                                    closeTootipAction={this.closeTootipAction}
                                />
                            </div>
                        </div>
                </section>
            </div>
        )
    }
}
// UsageReportsSearchView.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportsSearchView;