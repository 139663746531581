import React,{ Component } from "react";
import { Box } from 'grommet/components/Box';
import { Anchor } from 'grommet/components/Anchor';
import SpinningNotification from './SpinningNotification';
import { LoginForm } from './LoginForm';
import { Label } from '../commons/grommet/Label';
import PropTypes from 'prop-types';
import "../../css/index.css";
import OT_grey from "../../img/OT_grey.png";
import List from "../commons/grommet/List";
import DragNDrop from "./DragNDrop";
//import CharacterGrid from '@micro-focus/quantum-ux-grommet/components/CharacterGrid';

class Login extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          user: "",         
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

    onSubmit(loginObj){
        this.props.onloginsubmit(loginObj);
    }
    
    render(){
        let forgotPwdComp;
        if(this.props.datasource !== undefined && this.props.datasource === 'DB'){
            forgotPwdComp = <Anchor style={{width:"50%", paddingLeft:0}}id={this.props.forgotPwdId} onClick={this.props.forgotPassword}>
                                {this.props.forgotPwdLabel}
                            </Anchor>
        }

    return (
      <div className="login_form_wrap">
        <SpinningNotification centerPage={false} />
        
        <LoginForm
          className="grommetux-form grommetux-form--pad-medium form-title form-title-padding button_full_wrap"
          title={this.props.title}
          align="start"
          usernameType="text"
          onSubmit={this.onSubmit}
          forgotPassword={forgotPwdComp}
          usernameLabel={this.props.usernameLabel}
          passwordLabel={this.props.passwordLabel}
          emailLabel={this.props.emailLabel}
          buttonLabel={this.props.buttonLabel}
          rememberMeTitle={this.props.rememberMeTitle}
        ></LoginForm>
        <div className="login_otlogo">
          <img src={OT_grey} />
        </div>
      </div>
    );
  }
}
Login.propTypes={
    onSubmit: PropTypes.func   
};

export default Login;