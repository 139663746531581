import React from "react";
import { TextInput } from 'grommet/components/TextInput';
import { CheckBox } from "grommet/components/CheckBox";
import TableConstant from "../utill/TableConstant";
import { Ascend } from 'grommet-icons';
import { Descend } from 'grommet-icons';
import "../css/APTable.scss";
import { DateInput } from 'grommet/components/DateInput';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';
import { getLocaleMessage, LOCALE } from "../../../../utils/LocaleUtility";

let viewportWidth = window.screen.width;
let pivotValueMap, pivotParMap, pivotLevel=-1, pivotDepth, pivotHeadLength = 0, pivotColRowMap, pivotColMap, pivotCntIndex, pivotResults, pivotUniqVal,
pivotRows, pivotParChildRow, pivotCollapseIcon, pivotEleMap, pivotRowValueMap, pivotAggr, pivotMeta, pivotRoots, pivotFlag, pivotColIndex,
handleScroll, loadTableRow, pivotColRowLength, pivotRowEnd, pivotViewPortSize, pivotColNameMap;

export function createHead(header, rowSelection, sortIndex, sortAscending,
     sortCallbackMethod, isSelectAllClicked, onChangeCheckBoxCallback, selectAllRows, childKey, pivot, reportMeta, handleContextMenu) {
    let headerData = [];
	pivotColNameMap = new Map();
	let checkBoxWidth = "3em";
	let checkBoxHeaderCls = "table-grid-th-checkbox";
	var timePeriod = [ "year", "week", "month", "day", "hour"];
    if (rowSelection === 1 || rowSelection === 2) {
        headerData.push(<th className={checkBoxHeaderCls} key="1" className="secondnthirdTh"/>);
    } else if (rowSelection === 3) {
        var checkBoxElement;

        if(selectAllRows !== undefined){
            var classVar="grommetux-check-box";
            if(isSelectAllClicked !== undefined && !isSelectAllClicked &&
                selectAllRows.length > 0){
                classVar="grommetux-check-box partial";
            }
            checkBoxElement = <CheckBox className={classVar} checked={(selectAllRows.length > 0)} onChange={onChangeCheckBoxCallback.bind(this)}/>
        } else {
            checkBoxElement = <CheckBox onChange={onChangeCheckBoxCallback.bind(this)}/>
        }
        headerData.push(
            <th className={checkBoxHeaderCls} key="1" style={{ width: checkBoxWidth }}>
                {checkBoxElement}
            </th>
        );
    } else if (rowSelection === 0) {
		headerData.push(<th key="1" className="firstHead"/>);
	}

	let headerLength = header.length;

	if(reportMeta !== undefined && reportMeta.aggregators !== undefined){
		pivotHeadLength = headerLength - reportMeta.aggregators.length;
	}

	let width, headerLabel, columnName, infoIcon, infoIconText, enableCxtMenu, cxtIndex = 0, colMinWidth, totalScreenWidth = 30, colFlag = false, tempWidth, actualWidth = 40;
    
	header.map((value, index) => {
		headerLabel = value[TableConstant.__LABEL];
		colMinWidth = (headerLabel.length * 10) + 60;
		totalScreenWidth += colMinWidth;
		return "";
	});
	if(totalScreenWidth < viewportWidth){
		colFlag = true;
	}
	header.map((value, index) => {
		pivotCollapseIcon = undefined;
        width = (value[TableConstant.__WIDTH]) ? value[TableConstant.__WIDTH] : "";
        headerLabel = value[TableConstant.__LABEL];
		columnName = value[TableConstant.__NAME];
		infoIcon = value[TableConstant.__INFOICON];
		infoIconText = value[TableConstant.__INFOICONTEXT];
		enableCxtMenu = value[TableConstant.__ENABLE_CONTEXT_MENU];
    	pivotColNameMap.set(index+1, columnName);
		if(colFlag){
			colMinWidth = (viewportWidth / headerLength) - 20; 
		} else {
			tempWidth = (headerLabel.length * 10) + 50;
			colMinWidth = (totalScreenWidth / headerLength); 
			if(colMinWidth < tempWidth)
				colMinWidth = tempWidth;
		}
		actualWidth += colMinWidth;
		if(!timePeriod.some(v => (columnName === v))){
			cxtIndex++;
		}
		if(pivot && index < (pivotHeadLength - 1)){
			let elem = document.getElementsByName("col-"+(index+1))[0];
			if(elem){
				elem.classList.remove("expand");
				elem.classList.add("collapse");
			}
			pivotCollapseIcon = <span name={"col-"+(index+1)} className="collapse" onClick={onPivotColClick.bind(this,(index+1),header.length, 0, 0, false)} ></span>;
		}
        if(value[TableConstant.__SORT] === true) {

			var sorting = "";
			var sortOrder = true;
			var sortSty = {float: 'right', marginLeft: "1px"};
			if (index === sortIndex) {
				sorting = sortAscending ? <a className="ascending" onClick={(e) => {
							sortCallbackMethod(index, sortOrder, header);
						}}/> : <a className="descending" onClick={(e) => {
							sortCallbackMethod(index, sortOrder, header);
						}}/>;
				sortOrder = !sortAscending;
			} else {
				sortSty = {float: 'right', marginLeft: "1px", opacity: "30%"};
				sorting = <a className="ascending" onClick={(e) => {
                        sortCallbackMethod(index, sortOrder, header);
                    }}/>;
			}

            headerData.push(
                <th style={{cursor: 'pointer',minWidth:`${colMinWidth}px`, width:`${colMinWidth}px`}} key={columnName}
                    className="pivot-table-row-header" width={width}
					onContextMenu={(enableCxtMenu)?handleContextMenu.bind(this, cxtIndex):null}
                >
					{pivotCollapseIcon !== undefined? pivotCollapseIcon: ""}
                    <span>
                        {headerLabel}
                    </span>
					<span style={{paddingLeft: "2px",position:"absolute"}}>
						{infoIcon !== undefined && infoIcon === true ? 
							<div className="Infotooltip"><CircleInformationIcon colorIndex="brand" />
							<span className="InfotooltipText">
								{infoIconText}
							</span>
							</div>
						:null}
					</span>
                    <span style={sortSty}>
                        {sorting}
                    </span>
                </th>
            );
        } else {
            headerData.push(
                <th style={{cursor: 'pointer', minWidth:`${colMinWidth}px`, width:`${colMinWidth}px`}} key={columnName}
                    className="pivot-table-row-header" width={width}
					ononContextMenu={(enableCxtMenu)?handleContextMenu.bind(this, cxtIndex):null}
					>
					{pivotCollapseIcon !== undefined? pivotCollapseIcon: ""}
                    <span>
                        {headerLabel}
                    </span>
					<span style={{paddingLeft: "2px",position:"absolute"}}>
						{infoIcon !== undefined && infoIcon === true ? 
							<div className="Infotooltip"><CircleInformationIcon colorIndex="brand" />
							<span className="InfotooltipText">
								{infoIconText}
							</span>
							</div>
						:null}
					</span>
                </th>
            );
		}  
		if(index !== headerLength-1) {
			actualWidth += 10;
			headerData.push(<th className="colResizer"/>);
		}
		return "";
    });

	// if(actualWidth > viewportWidth){
	// 	document.getElementById("custom-report-main").style.width = `${actualWidth}px`;
	// } else {
	// 	document.getElementById("custom-report-main").style.width = "100%";
	// }

    return headerData;
};

//Create Table Filter based on filter props
export function createFilter(header, rowSelection, defaultFilter, filterCallbackMethod) {
    let headerFilter = [];
    let showFilter = false;
    //if (rowSelection !== 0) {
        headerFilter.push(<td key="100" />);
	//}
	let headerLength = header.length;
    header.map((value,index) => {
		let width = (value[TableConstant.__WIDTH]) ? value[TableConstant.__WIDTH] : "";
		let colSpan = "1";
		if(index !== headerLength-1) {
			colSpan = "2";
		}
        if (value[TableConstant.__FILTER]) {
			let placeHolder = value[TableConstant.__FILTERPLACEHOLDER];
			if(placeHolder === undefined) {
				placeHolder = value[TableConstant.__LABEL];
			}
			let fieldType = value[TableConstant.__FILTERFIELDTYPE];
			let element;
			if(fieldType === 'date') {
				element = <DateInput id={value[TableConstant.__LABEL]} className="datepicker"
					format={value[TableConstant.__FILTERFIELDFORMAT]}
					name={value[TableConstant.__NAME]}
					value={value[TableConstant.__FILTERFIELDVALUE]}
					placeholder={placeHolder}
					calendarProps={({locale:LOCALE})}
					onChange={filterCallbackMethod.bind(this, value[TableConstant.__NAME])}
				/>
			} else {
				if(defaultFilter !== undefined){
					element = <TextInput
						id={value[TableConstant.__LABEL]}
						name={value[TableConstant.__NAME]}
						value={defaultFilter[value[TableConstant.__NAME]]}
						clearButtonMode='always'
						suggestions={undefined}
						placeholder={placeHolder}
						onChange={(e) => {
							filterCallbackMethod(e.target.name, e.target.value, e, false);
						}}
					/>
				} else {
					element = <TextInput
						id={value[TableConstant.__LABEL]}
						name={value[TableConstant.__NAME]}
						suggestions={undefined}
						placeholder={placeHolder}
						onKeyUp={(e) => {
							filterCallbackMethod(e.target.name, e.target.value, e);
						}}
					/>
				}
			}
            headerFilter.push(
                <td colSpan={colSpan} className="table-filter-td" width={width} key={value[TableConstant.__LABEL]}>{element}</td>
            );
            showFilter = true;
        }
        else {
            headerFilter.push(
                <td colSpan={colSpan} className="table-filter-td" width={width} key={value[TableConstant.__LABEL]}> </td>
            );
		}
		return "";
    });
    if (showFilter) {
        return headerFilter;
    }
};

//Create Table Body based on rowData
export function createBody(rowData, rowSelection, header, rowKey,
    onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds,
	 disabledRowIds, childKey, expandAll, noDataFoundText, addExtraRow, pivot, reportMeta, setTableBody, setScrollParams, loadTableBody) {
	let rows = [];
	pivotDepth = undefined;
	pivotColRowLength = undefined;
	pivotValueMap = new Map();
	pivotParMap = new Map();
	pivotEleMap = new Map();
	pivotRowValueMap = new Map();
	pivotColMap = new Map();
	pivotColRowMap = new Map();
	pivotAggr = [];
	pivotRoots = [];
	pivotUniqVal = [];
	pivotLevel = 0;
	handleScroll = setScrollParams;
	loadTableRow = loadTableBody;
	pivotParChildRow = new Map();
	let noDataFound = getLocaleMessage("label.no.data.found");
	if(noDataFoundText !== undefined && noDataFoundText !== '') {
		noDataFound = noDataFoundText;
	}
	if(reportMeta !== undefined && reportMeta.aggregators !== undefined){
		reportMeta.aggregators.map( itm => {
			if(reportMeta.report.reportAggr !== undefined)
				itm.reportAggr = reportMeta.report.reportAggr;
			pivotAggr.push(itm.name);
			if(itm.countAttrIndex > 0)
				pivotCntIndex = Number(itm.countAttrIndex+1);
			return "";
		});
		pivotHeadLength = header.length - pivotAggr.length;
		pivotMeta = reportMeta.aggregators;
	}
    if (rowData!==undefined && rowData.length > 0) {
		let arrPromise = Promise.resolve([]);
		//var i,j,temparray = [],chunk = 1000, newArray = [];
		// for (i=0,j=rowData.length; i<j; i+=chunk) {
		// 	temparray = rowData.slice(i,i+chunk);
		// 	newArray.push.apply(newArray, temparray);
		// }
		const promises = rowData.map((arr, index) => {
			pivotDepth = false; pivotCollapseIcon = undefined;
			let trId = "grid-tr-"+(arr[rowKey]!== undefined?arr[rowKey]:index);
			let columns = createRowDatas(arr, index, rowSelection, header, rowKey, 
				onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, 
				defaultSelectIds, disabledRowIds, childKey, expandAll, false, 0, pivot);
			//var flag = (pivotDepth !== undefined && pivotDepth === 0);
			if(pivotDepth){
				pivotRoots.push(index)
			} 
			return <tr className="table-grid-tr" key={trId} id={"pvt-"+index} >{columns}</tr>;
		});
		arrPromise = Promise.all(promises).then((results) => {
			return [].concat(...results);
		});

		arrPromise.then((results) => 
			{pivotRows = results;
				return results;}
		).then(results => {
			pivotResults = getActivePivotRows(results, pivotRoots);
			setTableBody(pivotResults, pivotRoots, false);
		});
    } else {
		var columnLength = header.length;
		if (rowSelection === 0 || rowSelection === 1 || rowSelection === 2 || rowSelection === 3) {
			columnLength = header.length + 1;
		}
		columnLength = columnLength + (header.length - 1);
        rows.push(<tr className="table-grid-tr" key={0}><td style={{textAlign: "center"}} colSpan={columnLength} key={header.length}>{noDataFound}</td></tr>);
		setTableBody(rows, pivotRoots, false);
	}
    return rows;
}

export function createRowDatas(row, index, rowSelection, header, rowKey,
    onChangeCheckBoxCallback, selectAllRows, uncheckTheDisabled, defaultSelectIds, 
	disabledRowIds, childKey, expandAll, childRowPadding, paddingCount,pivot) {
	let columns = [];
	columns.push(<td key={row[rowKey]} className="tableRowStyle">&nbsp;</td>);

	/*Create rest of the column based on header*/
	let i = 1, value, isHREF, isCOL_KEY, isON_CLICK_CALLBACK, editable, editableType, afterEditCallBack, beforeEditCallBack, colToolTipText, textAlignValue;
	let arrayValue = [], pivotValue = pivotValueMap.get(0), pivotEleValue="", pivotRowArr, pivotIndex="", flag, temp, pivotSty, par, colRowIds;
	for (let col of header) {
		if (i <= header.length) {
			pivotEleValue="";
			pivotCollapseIcon = undefined;
			value = row[col[TableConstant.__NAME]];
			//if(pivot){
				if(pivotValue === undefined || !(pivotValue.some(v => (col[TableConstant.__NAME]+":"+value === v)))){
					arrayValue.push(col[TableConstant.__NAME]+":"+value);
					pivotValueMap.set(0, arrayValue);
					pivotValue = arrayValue;
					// if(pivotDepth === undefined){
					// 	pivotDepth = i-1;
					// 	if(pivotDepth === 0){
					// 		++pivotLevel;
					// 	}
					// }
					if(Number(i) === 1){
						++pivotLevel;
						pivotDepth = true;
					}
					pivotEleValue = pivotLevel+":"+i+":"+index;
					//pivotIndex = pivotLevel+":"+(i-1)+":"+index;
					//par = pivotLevel+""+(i-1)+""+index;

					pivotParMap.set(i, pivotEleValue);
					pivotIndex = pivotParMap.get(i-1);
					if(pivotIndex !== undefined){
						par = pivotIndex.replace(/:/g, "");
					}
					pivotRowArr = pivotEleMap.get(pivotIndex);

					if(pivotRowArr === undefined){
						// for(let k=index;k>=0;k--){
						// 	pivotRowArr = pivotEleMap.get(pivotLevel+":"+(i-1)+":"+k);
						// 	if(pivotRowArr !== undefined){
						// 		pivotIndex = pivotLevel+":"+(i-1)+":"+k;
						// 		par = pivotLevel+""+(i-1)+""+k;
						// 		break;
						// 	}else {
								pivotRowArr = [];
						// 	}
						// }
					}
					pivotColMap.set(i, par);
					temp = pivotEleMap.get(pivotEleValue);
					if(temp === undefined){
						pivotEleMap.set(pivotEleValue, []);
					}

					flag = (pivotDepth && Number(i) === 1);
					if(flag || Number(i) > pivotHeadLength){
						pivotSty = "";
					} else {
						pivotSty = "none";
					}

					colRowIds = pivotColRowMap.get(i);
					if(colRowIds !== undefined){
						colRowIds.push(index);
						pivotColRowMap.set(i, colRowIds);
					} else {
						colRowIds = [];
						colRowIds.push(index);
						pivotColRowMap.set(i, colRowIds);
					}
					if(i <= (pivotHeadLength-1)){
						//pivotCollapseIcon = <span name={pivotEleValue} className="collapse" onClick={onPivotEleClick.bind(this,pivotLevel,i,index, header.length, false)} ></span>;
						pivotCollapseIcon = <h1 name={pivotEleValue} className={"pivot-cell collapse row"+index+" colrow"+i} style={{display:pivotSty}} 
												onClick={onPivotEleClick.bind(this,pivotLevel,i,index, header.length, false, false)} 
												> {value}</h1>;
						pivotRowArr.push(pivotEleValue);
					} else{
						if(i > pivotHeadLength){
							//pivotCollapseIcon = <span name={pivotEleValue} ></span>;
							pivotCollapseIcon = <h1 name={pivotEleValue} className={"pivot-cell row"+index+" colrow"+i} style={{display:pivotSty}} >{value}</h1>;
						} else {
							pivotRowArr.push(pivotEleValue);
							//pivotCollapseIcon = <span name={pivotEleValue} ></span>;
							pivotCollapseIcon = <h1 name={pivotEleValue} className={"pivot-cell row"+index+" colrow"+i} style={{display:pivotSty}} >{value}</h1>;
						}
					}
					pivotEleMap.set(pivotIndex, pivotRowArr);
					colToolTipText = value;
				} else {
					arrayValue.push(col[TableConstant.__NAME]+":"+value);
					if(pivotColMap.get(i) !== undefined){
						par = pivotColMap.get(i);
					}
					if (pivotCntIndex === i){
						pivotEleValue = pivotLevel+":"+i+":"+index;
						pivotCollapseIcon = <h1 name={pivotEleValue} style={{display:"none"}} >{value}</h1>;
					}
				}
				if(pivotAggr.some(v => (col[TableConstant.__NAME] === v))){
					pivotRowValueMap.set(index+":"+i, Number(value));
				}
			//}

			if (pivotCntIndex === i){
				pivotUniqVal[index] = value;
			}
			let rowIds = pivotParChildRow.get(par);
			if(rowIds !== undefined){
				rowIds.push(index);
				pivotParChildRow.set(par, rowIds);
			} else {
				rowIds = [];
				rowIds.push(index);
				pivotParChildRow.set(par, rowIds);
			}
			isHREF = col[TableConstant.__HREF];
			isCOL_KEY = col[TableConstant.__COLUMNKEY];
			isON_CLICK_CALLBACK = col[TableConstant.__ONCLICK];
			editable = col[TableConstant.__EDITABLE];
			editableType = col[TableConstant.__EDITFIELDTYPE];
			afterEditCallBack = col[TableConstant.__AFTEREDIT];
			beforeEditCallBack = col[TableConstant.__BEFOREEDIT];
			textAlignValue = col[TableConstant.__TEXT_ALIGN_VALUE];
			
			let clsName = "tableRowStyle";
			if(col[TableConstant.__CLASSNAME]) {
				clsName = col[TableConstant.__CLASSNAME];
			}
			let colkey = "col"+row[rowKey]+"-"+i;
			let colSpanVal = "1";
			if(i !== header.length) {
				colSpanVal = "2";
			}
			let alignTextV = textAlignValue !== undefined ? textAlignValue : "left";
			if(i === 1) {
					columns.push(<td colSpan={colSpanVal} 
						title={colToolTipText} 
						key={colkey} 
						style={{textAlign: alignTextV}} className={clsName+" par-"+par}>
						{/* {value instanceof Object ? value : <div className="pivot-cell" style={{display:pivotSty}}>
						{pivotCollapseIcon !== undefined? pivotCollapseIcon: ""}
						{value}</div>} */}
						{pivotCollapseIcon}
					</td>);
			} else {
					columns.push(<td colSpan={colSpanVal} 
						title={colToolTipText} 
						key={colkey} 
						style={{textAlign: alignTextV}} className={"tableColumnStyle par-"+par}>
						{/* {value instanceof Object ? value : <div className="pivot-cell" style={{display:pivotSty}}>							
						{pivotCollapseIcon !== undefined? pivotCollapseIcon: ""}
						{value}</div>} */}
						{pivotCollapseIcon}
					</td>);
			}
		}
		i++;
	}
	return columns;
}

function onPivotEleClick(pivotLevel, pivotEleLevel, rowIndex, length, checkF, colClick, ev){
	//console.log("START>>>> "+new Date().getTime());
	//let par = pivotLevel+""+pivotEleLevel+""+rowIndex;
	let mainPromise = Promise.resolve([]);
	let eleArr = pivotEleMap.get(pivotLevel+":"+pivotEleLevel+":"+rowIndex);
	let row, cellClick = false;
	let rowEnd;
	let el, elClassName, add=false;
	if(ev.target !== undefined){
		el = ev.target;
		cellClick = true;
	} else {
		el = ev;
	}

	elClassName = el.className;
	if(elClassName.indexOf('collapse') > -1){
		add = true;
	}
	const mainpromises = eleArr.map((arr, index) => {
		row = arr.split(":");
		if(Number(row[2]) >= rowIndex){
			if(add){
				pivotResults[row[2]] = pivotRows[row[2]];
				rowEnd = Number(row[2]);
			} 
			// else if(colClick) {
			// 	pivotResults[row[2]] = undefined;
			// }
		}
		return "";
	});
	mainPromise = Promise.all(mainpromises).then(() => {
		if(rowEnd > pivotRowEnd)
			pivotRowEnd = rowEnd;
		loadTableRow(pivotResults);
	});
	mainPromise.then(() => 
		{ 
		let ele, eleTemp;
		if(!colClick && elClassName.indexOf('collapse') > -1){
			el.classList.remove('collapse');
			el.classList.add('expand');
			
			if(eleArr !== undefined){
				for(let j=0; j<eleArr.length;j++){
					setTotalValue(eleArr[j], length);
				}

				let arrPromise = Promise.resolve([]);
				const promises = eleArr.map((arr, index) => {
					let ele;
					let eleTemp = arr.split(":");
					let rowEle = document.getElementById("pvt-"+eleTemp[2]);
					if(checkF || Number(eleTemp[2]) > rowIndex){
						rowEle.style.display = "";
						ele = document.getElementsByName(arr)[0];
					} else if(Number(eleTemp[2]) === rowIndex){
						ele = document.getElementsByName(arr)[0];
					}
					if(ele)
						ele.style.display = "";
					return "";
				});
				arrPromise = Promise.all(promises);
				arrPromise.then(() => 
					{ //console.log("DONE>>>>>"+new Date().getTime()); 
					}
				);
			}
		} else {
			if(elClassName.indexOf('expand') > -1){
				el.classList.remove('expand');
				el.classList.add('collapse');
			}
			setTotalValue(pivotLevel+":"+pivotEleLevel+":"+rowIndex, length);
			let value;
			if(eleArr !== undefined){
				let arrPromise = Promise.resolve([]);
				let endNode = eleArr[eleArr.length-1];
				value = getChildElement(endNode, pivotColIndex+1, value, length);
				const promises = eleArr.map((arr, index) => {
					let ele, row;
					let eleTemp = arr.split(":");
					let rowEle = document.getElementById("pvt-"+eleTemp[2]);
					if(rowEle){
						row = Number(eleTemp[2]);
						if(checkF || row > rowIndex){
							rowEle.style.display = "none";
							pivotResults[row] = undefined;
							ele = document.getElementsByName(arr)[0];
						} else if(row === rowIndex){
							ele = document.getElementsByName(arr)[0];
						}

						ele.style.display = "none";
						if(ele.className.indexOf('expand') > -1){
							ele.classList.remove('expand');
							ele.classList.add('collapse');
						}
						disablePivotElement(ele, rowIndex, pivotEleMap);
					}
					return "";
				});
				arrPromise = Promise.all(promises);
				arrPromise.then(() => 
					{ //console.log("DONE>>>>>"+new Date().getTime()); 
					}
				);

				if(pivotEleLevel === 1 && pivotFlag){
					let rootLength = pivotRoots.length;
					if(rootLength < 150)
						eleTemp = true;
					pivotRoots.map((itm) => {
						el = document.getElementsByClassName("row"+itm+" colrow1")[0];
						if(el){
							rootLength--;
						 	if(el.className.indexOf('expand') > -1)
								eleTemp = false;
						}
						return "";
					});
					if(rootLength === 0 && eleTemp){
						pivotFlag = false;
						handleScroll(0, length, 0, pivotFlag, pivotRoots.length, 'root');
						for(let i = 1; i < length; i++){
							ele = document.getElementsByName("col-"+i)[0];
							if(ele && ele.className.indexOf('expand') > -1){
								ele.classList.remove('expand');
								ele.classList.add('collapse');
							}
						}
					}
				}

			}
			if(!add){
				loadTableRow(pivotResults);
				if(cellClick && pivotFlag && value !== undefined) {
					let startNode = value.split(":")[2];
					startNode = Number(startNode);
					if(startNode >= pivotRowEnd)
						onPivotColClick(pivotColIndex, length, (startNode+1), 
							50, true, undefined);
				}
			}
		}
	}
	);
}

function disablePivotElement(element, rowIndex, pivotEleMap){
	let rowEle, ele, rowId, eleChArr = pivotEleMap.get(element.getAttribute("name"));
	if(eleChArr !== undefined){
		for(let j=0; j<eleChArr.length;j++) {
			rowId = eleChArr[j].split(":")[2];
			if(Number(rowId) !== rowIndex){
				rowEle = document.getElementById("pvt-"+rowId);
				if(rowEle){
					rowEle.style.display = "none";
					pivotResults[rowId] = undefined;
				}
			}
			element = document.getElementsByName(eleChArr[j])[0];
			ele = element;
			if(ele){
				ele.style.display = "none";
				if(element.className.indexOf('expand') > -1){
					element.classList.remove('expand');
					element.classList.add('collapse');
					disablePivotElement(element, rowIndex, pivotEleMap);
				}
			}
		}
	}
}

function setTotalValue(elementID, length){
	if(pivotAggr !== undefined && pivotAggr.length > 0){
		
		pivotMeta.map( item =>{
			// if(setAggr !== undefined && pivotAggr !== 'count' )
			 	length = (Number(item.aggIndex) + 1);
			// else
			// 	length = (setAggr + 2);
			let value = [], total=0, name, col;
			//let eleValue = getTotalValueRoot(elementID, value, length);
			let aggr = item.aggregator;
			let repAggr = item.reportAggr
			name = elementID.replace(/:/g, "");
			let span = elementID.split(":");
			var set4 = new Set();
			let rowIDs = pivotParChildRow.get(name);
			if(rowIDs !== undefined){
				rowIDs.map((ind) =>{
					if(aggr === 'count'){
						set4.add(pivotUniqVal[ind]);
					} else
						value.push(pivotRowValueMap.get(ind+":"+length));	
					return "";
				});
			}
			if(aggr === 'count'){
				total = set4.size;
			} else if(aggr === 'sum'){
				col = Number(span[1]);
				let hourIndex = [...pivotColNameMap.entries()]
						.filter(({ 1: v }) => v === 'hour')
						.map(([k]) => k);
				if(repAggr !== undefined && col !== undefined && col < hourIndex){
					getChildValueSet(elementID, col, hourIndex, (Number(item.aggIndex) + 1), repAggr).then(function(result) {
						total = result;
						let eid = span[0]+":"+(Number(item.aggIndex) + 1)+":"+span[2];//+":x";
						var nodeEle = document.getElementsByName(eid)[0];
						if (nodeEle !== undefined && nodeEle.childNodes[0] !== undefined){
							nodeEle.childNodes[0].nodeValue = total;
						}
					});
				} else {
					for(let i=0;i<value.length;i++)	{
						total += value[i];
					}
				}
			} else if(aggr === 'max'){
				total = value.max();
			} else if(aggr === 'min'){
				total = value.min();
			}
			
			let id = span[0]+":"+length+":"+span[2];
			var node = document.getElementsByName(id)[0];
			if (node !== undefined && node.childNodes[0] !== undefined){
				node.childNodes[0].nodeValue = total;
			}
			return "";
		});
	}
}

export function onPivotColClick(colIndex, length, start, nodes, append, ev){

	if(colIndex === 0){
		let visibleNodeArr = [];
		let limit = (start === 0 ? 100: nodes);
		let rowArr = pivotRoots.slice(start, (start+limit));
		let mainPromise = Promise.resolve([]), mainpromises;
		mainpromises = rowArr.map((arr, index) => {
			if(index < limit){
				visibleNodeArr[arr] = pivotRows[arr];
			}
			return "";
		});
		mainPromise = Promise.all(mainpromises).then(() => {
			if(append){
				loadTableRow(pivotResults);
			} else {
				pivotResults.length = 0;
				pivotResults = visibleNodeArr;
				loadTableRow(pivotResults);
			}
		});

		mainPromise.then(() => 
		{ 
			let arrPromise = Promise.resolve([]);
			const promises = rowArr.map((itm, index) => {
				setTotalValueRoot((index+1)+":1:"+itm);
				let rowEle = document.getElementById("pvt-"+itm);
				if(rowEle)
					rowEle.style.display = "";
				return "";
			});
			arrPromise = Promise.all(promises);
		});
	} else {
		if(!append)
			pivotColIndex = colIndex;

		let mainPromise = Promise.resolve([]), mainpromises;
		let node, checkF, curCol, eleId, target = (ev !== undefined ? ev.target: undefined);
		let expand = (ev === undefined || ev.target.className === 'collapse');
		let eleArr = [];
		if(expand && !append){
			eleArr = pivotColRowMap.get(colIndex+1);
		} else {
			eleArr = pivotColRowMap.get(colIndex);
		}
		if(start === 0){
			pivotColRowLength = eleArr.length; 
			if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
				document.getElementById("pivot-table-grid").style.height = `${(eleArr.length * 25) + 500}px`;
			} else {
				document.getElementById("pivot-table-grid").style.height = `${eleArr.length * 25}px`;
			}
			handleScroll(colIndex, length, 0, false, pivotColRowLength, 'collapse');
		}

		let limit = (start === 0 ? 100: nodes)
		var showFull = eleArr.length <= limit;
		let visibleNodeArr = [];
		if(append && eleArr.indexOf(start) !== -1){
			start = eleArr.indexOf(start);
		}
		eleArr = eleArr.slice(start, (start+limit));
		//if(expand){
			mainpromises = eleArr.map((arr, index) => {
				// if(pivotResults[arr] === undefined && index < limit){
				// 	pivotResults[arr] = pivotRows[arr];
				// }
				if(append){
					pivotResults[arr] = pivotRows[arr];
					pivotRowEnd = arr;
				} else if(index < limit){
					visibleNodeArr[arr] = pivotRows[arr];
					pivotRowEnd = arr;
				}
				return "";
			});
			mainPromise = Promise.all(mainpromises).then(() => {
				if(append){
					loadTableRow(pivotResults);
				} else {
					pivotResults.length = 0;
					pivotResults = visibleNodeArr;
					loadTableRow(pivotResults);
				}
				pivotViewPortSize = limit;
			});
		//}
		setTimeout(function() {
			mainPromise.then(() => 
			{  
				if(expand){
					pivotFlag= true;
					var count = start, ele, maxLimit = [];
					//let arrEle = document.getElementsByClassName("colrow"+(colIndex+1));
					for(let i = colIndex; i > 0; i--){
						count = start;
						node = document.getElementsByName("col-"+i)[0];
						if(node && node.className === 'collapse'){
							node.classList.remove('collapse');
							node.classList.add('expand');
							checkF = true;
						} else {
							checkF = false;
							if(ev !== undefined)
								continue;
							else
								checkF = true;	
						}
						
						// if(showFull){
						// 	if(colIndex === 1){
						// 		pivotFlag = false;
						// 	}
						// 	arrEle = document.getElementsByClassName("colrow"+i);
						// 	for(var elem of arrEle){
						// 		if(elem){
						// 			elem.style.display = "";
						// 			elem.parentElement.parentElement.style.display = "";
						// 			if(i !== colIndex){
						// 				if(elem.className.indexOf('collapse') > -1){
						// 					elem.classList.remove('collapse');
						// 					elem.classList.add('expand');
						// 				}
						// 			} else if(checkF && elem.className.indexOf('collapse') > -1){
						// 				eleId = elem.getAttribute("name").split(":");
						// 				onPivotEleClick(Number(eleId[0]), Number(eleId[1]), Number(eleId[2]), length, false, false, elem);
						// 			}
						// 		}
						// 		count++;
						// 	}
						// } else {
							// arr = document.getElementsByClassName("colrow"+i).length;
							// arr = arr > limit ? limit: arr;
							let childs;
							eleArr.map((itm) => {
								ele = document.getElementsByClassName("row"+itm+" colrow"+i)[0];
								if(ele){
									ele.style.display = "";
									ele.parentElement.parentElement.style.display = "";
									if(i !== colIndex){
										if(ele.className.indexOf('collapse') > -1){
											ele.classList.remove('collapse');
											ele.classList.add('expand');
										}
									} else if(checkF && ele.className.indexOf('collapse') > -1){
										eleId = ele.getAttribute("name").split(":");
										onPivotEleClick(Number(eleId[0]), Number(eleId[1]), Number(eleId[2]), length, false, false, ele);
									}
								} else if(colIndex === i){
									ele = document.getElementsByClassName("row"+itm+" colrow"+(i+1))[0];
									if(ele){
										eleId = ele.getAttribute("name");
										ele.style.display = "";
										setTotalValue(eleId, length);
									}
								}
								count++;
								return "";
							});
						//}
						maxLimit.push(count);
					}
					if(showFull){
						pivotFlag = false;
					}
					count = maxLimit.max();
					handleScroll(colIndex, length, count, pivotFlag, pivotColRowLength, 'expand');
				} 
				// else if(showFull){
				// 	var count = start, ele, maxLimit = [], arrEle = [];
				// 	for(let i = colIndex; i > 0; i--){
				// 		count = start;
				// 		checkF = true;	
				// 		arrEle = document.getElementsByClassName("colrow"+i);
				// 		for(var elem of arrEle){
				// 			if(elem){
				// 				elem.style.display = "";
				// 				elem.parentElement.parentElement.style.display = "";
				// 				if(i !== colIndex){
				// 					if(elem.className.indexOf('collapse') > -1){
				// 						elem.classList.remove('collapse');
				// 						elem.classList.add('expand');
				// 					}
				// 				} else {
				// 					eleId = elem.getAttribute("name").split(":");
				// 					onPivotEleClick(Number(eleId[0]), Number(eleId[1]), Number(eleId[2]), length, false, true, elem);
				// 				}
				// 			}
				// 			count++;
				// 		}
				// 		maxLimit.push(count);
				// 	}
				// 	for(let k = colIndex; k<length; k++){
				// 		ele = document.getElementsByName("col-"+k)[0];
				// 		if(ele && ele.className === 'expand'){
				// 			ele.classList.remove('expand');
				// 			ele.classList.add('collapse');
				// 		} 
				// 	}
				// 	count = maxLimit.max();
				// 	pivotColIndex = colIndex-1;
				// 	handleScroll(colIndex-1, length, 0, false, pivotColRowLength, 'collapse');
				// } 
				else {
					if(ev !== undefined){
						curCol = target;
						curCol.classList.remove('expand');
						curCol.classList.add('collapse');
					}
					if(colIndex === 1 && pivotRoots.length < 150){
						pivotFlag = false;
					}
					let arrElem = [], flag;
					let colLength = (pivotAggr !== undefined && pivotAggr.length > 0) ? (length - pivotAggr.length) : length;
					for(let k = colIndex-1; k<=colLength; k++){
						node = document.getElementsByName("col-"+k)[0];
						if(node && node.className === 'expand'){
							node.classList.remove('expand');
							node.classList.add('collapse');
							// if(k !== colIndex)
							// 	continue;
						} 

						arrElem = document.getElementsByClassName("colrow"+k);
						for(var temp of arrElem){
							flag = temp.style.display !== "none";
							if(flag){
								if(temp.className.indexOf('expand') > -1){
									temp.classList.remove('expand');
									temp.classList.add('collapse');
								}
								if(k !== colIndex){
									temp.style.display = "none";
								} else if(k === colIndex){
									eleId = temp.getAttribute("name");
									setTotalValue(eleId, length);
									//onPivotEleClick(Number(eleId[0]), Number(eleId[1]), Number(eleId[2]), length, false, true, temp);
								}
							}
						}
					}
					pivotColIndex = colIndex-1;
					handleScroll(colIndex-1, length, 0, pivotFlag, pivotColRowLength, 'collapse');
					onPivotColClick(colIndex-1, length, 0, pivotViewPortSize, false, undefined);
				}
			}
			);
		}, 50);
	}
}

function enableOrDisable(disabled, rowData, rowKey, childKey) {
	rowData.map((row, index) => {
		var childRow = document.getElementById("grid-tr-"+row[rowKey]);
		if(childRow !== null) {
			if(disabled) {
				childRow.style.display = 'none';
				let expandEl = document.getElementById("Expand-"+row[rowKey]);
				let collapseEl = document.getElementById("Collapse-"+row[rowKey]);
				if(expandEl) {
					expandEl.style.display = "inline-block";
				}
				if(collapseEl) {
					collapseEl.style.display = "none";
				}
			} else {
				childRow.style.display = 'table-row';
			}
		}
		if(row[childKey] && row[childKey].length > 0 && disabled) {
			enableOrDisable(disabled, row[childKey], rowKey, childKey);
		}
		return "";
	});
}

function setTotalValueRoot(elementID, length){

	if(pivotAggr !== undefined && pivotAggr.length > 0){
		
		pivotMeta.map( item =>{
			// if(setAggr !== undefined && pivotAggr !== 'count' )
			 	length = (Number(item.aggIndex) + 1);
			// else
			// 	length = (setAggr + 2);
			let value = [], total=0, name;
			//let eleValue = getTotalValueRoot(elementID, value, length);
			let aggr = (item.reportAggr !== undefined ? item.reportAggr :item.aggregator);
			name = elementID.replace(/:/g, "");
			let span = elementID.split(":");
			var set4 = new Set();
			let rowIDs = pivotParChildRow.get(name);
			if(rowIDs !== undefined){
				rowIDs.map((ind) =>{
					if(aggr === 'count'){
						set4.add(pivotUniqVal[ind]);
					} else
						value.push(pivotRowValueMap.get(ind+":"+length));	
					return "";
				});
			}
			if(aggr === 'count'){
				total = set4.size;
			} else if(aggr === 'sum'){
				for(let i=0;i<value.length;i++)	{
					total += value[i];
				}
			} else if(aggr === 'max'){
				total = value.max();
			} else if(aggr === 'min'){
				total = value.min();
			}
			
			let id = span[0]+":"+length+":"+span[2];//+":x";
			var node = document.getElementsByName(id)[0];	
			if (node !== undefined && node.childNodes[0] !== undefined){
				node.childNodes[0].nodeValue = total;
			}

			return "";
		});
	}
}

// function getTotalValueRoot(elementID, value, length){
// 	let eleChArr = pivotEleMap.get(elementID), node;
// 	if(eleChArr !== undefined && eleChArr.length > 0){
// 		for(let j=0; j<eleChArr.length;j++) {
// 			getTotalValueRoot(eleChArr[j], value, length);
// 			node = document.getElementsByName(eleChArr[j])[0];
// 			if(node)
// 				node.style.display = "none";
// 		}
// 	} else {
// 		let leafId = elementID.split(":");
// 		value.push(pivotRowValueMap.get(leafId[2]+":"+length));
// 	}

// 	return (value !== undefined)?value:[];
// }

export function setPivotTotalResults(pivotRoots, length){
	if(pivotRoots !== undefined && pivotRoots.length > 0){
		if(pivotRoots.length > 150){
			if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
				document.getElementById("pivot-table-grid").style.height = `${(pivotRoots.length * 25) + 500}px`;
			} else {
				document.getElementById("pivot-table-grid").style.height = `${pivotRoots.length * 25}px`;
			}
			handleScroll(0, length, 0, true, pivotRoots.length, 'root');
			let rowArr = pivotRoots.slice(0, 100);
			let arrPromise = Promise.resolve([]);
			const promises = rowArr.map((itm, index) => {
				setTotalValueRoot((index+1)+":1:"+itm);
				let rowEle = document.getElementById("pvt-"+itm);
				if(rowEle)
					rowEle.style.display = "";
				return "";
			});
			arrPromise = Promise.all(promises);
		} else {
			let arrPromise = Promise.resolve([]);
			const promises = pivotRoots.map((itm, index) => {
				setTotalValueRoot((index+1)+":1:"+itm);
				let rowEle = document.getElementById("pvt-"+itm);
				if(rowEle)
					rowEle.style.display = "";
				return "";
			});
			arrPromise = Promise.all(promises);
		}
	}
}

export function getActivePivotRows(results, pivotRoots){
	let rows = [];
	if(pivotRoots !== undefined && pivotRoots.length > 0){

		if(pivotRoots.length > 150){
			let rowArr = pivotRoots.slice(0, 100);
			let arrPromise = Promise.resolve([]);
			const promises = rowArr.map((itm, index) => {
				rows[itm] = results[itm];
				return "";
			});
			arrPromise = Promise.all(promises);
		} else {
			let arrPromise = Promise.resolve([]);
			const promises = pivotRoots.map((itm, index) => {
				rows[itm] = results[itm];
				return "";
			});
			arrPromise = Promise.all(promises);
		}
	}
	return rows;
}

function getChildElement(elementID, colIndex, value, length){
	let eleId, ele;
	let eleChArr = pivotEleMap.get(elementID);
	if(eleChArr !== undefined){
		eleId = eleChArr[eleChArr.length-1];
		if(eleId !== undefined){
			ele = eleId.split(":")[1];
			if(Number(ele) < (length-1)){
				value = getChildElement(eleId, colIndex, value, length);
			} else {
				value = eleId;
			}
		}
	}

	return (value !== undefined)?value:undefined;
}

function getChildValueSet(elementID, column, hourIndex, length, repAggr){
	let arrPromise = Promise.resolve([]);
	let arryValue = [], rowIDs, tempValue, tempArr, eleChArr = [], arr = [];
	let index = column;
	while(index < hourIndex[0]){
		if(eleChArr.length === 0){
			eleChArr = JSON.parse(JSON.stringify(pivotEleMap.get(elementID)));
		}
		else {
			arr = JSON.parse(JSON.stringify(eleChArr));
			eleChArr.length = 0;
			arr.map((id) =>{
				tempArr = pivotEleMap.get(id);
				eleChArr = eleChArr.concat(...tempArr);
				return "";
			});
		}
		index++;
	}

	const promises = eleChArr.map((name, index) => {
		arryValue = [];
		name = name.replace(/:/g, "");
		rowIDs = pivotParChildRow.get(name);
		if(rowIDs !== undefined){
			rowIDs.map((ind) =>{
				arryValue.push(pivotRowValueMap.get(ind+":"+length));
				return "";
			});
			return arryValue.reduce((a, b) => a + b, 0);
		}
		return "";		
	});
	arrPromise = Promise.all(promises).then((results) => {
		return [].concat(...results);
	});

	return arrPromise.then((results) => 
		{return results;}
	).then(results => {
		if(repAggr === 'max'){
			tempValue = results.max();
		} else if(repAggr === 'min'){
			tempValue = results.min();
		}
		return tempValue;
	});
	
}

Array.prototype.max = function() {
	return Math.max.apply(null, this);
};	

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};