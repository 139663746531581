import ReduceStore from "./ReduceStore";

class LDAPConfigurationStore extends ReduceStore {
    constructor(props) {
        super(props);
        this.state = {
            listprotocols: null,
            listauthmethods: null,
            listdefProp: null,
            grouptype: null,
            listusersearchscope: null,
            status: null,
            errorDetail: null,
            protocol: "",
            hostname: "",
            port: "",
            searchbase: "",
            authenticationmethod: "",
            username: "",
            basedn: "",
            usersubtree: "",
            useridattr: "",
            usersFilter: "",
            fnameattr: "",
            lnameattr: "",
            eamilattr: "",
            passattr: "",
            gbasedn: "",
            groupsubtree: "",
            groupidattr: "",
            grmemattr: "",
            grformatattr: "",
            groupsFilter: ""
        }
    }
    reduce(action, state) {
        switch (action.type) {
            case "POPULATE_LDAP_CONFIG_SETTING_DETAILS": {
                return {
                    listprotocols: action.payload.listprotocols,
                    listauthmethods: action.payload.listauthmethods,
                    listdefProp: action.payload.listdefProp,
                    grouptype: action.payload.grouptype,
                    listusersearchscope: action.payload.listusersearchscope,
                    status: action.payload.status,
                    errorDetail: action.payload.errorDetail,
                    protocol: action.payload.protocol,
                    hostname: action.payload.hostname,
                    port: action.payload.port,
                    searchbase: action.payload.searchbase,
                    authenticationmethod: action.payload.authenticationmethod,
                    username: action.payload.username,
                    basedn: action.payload.basedn,
                    usersubtree: action.payload.usersubtree,
                    useridattr: action.payload.useridattr,
                    usersFilter: action.payload.usersFilter,
                    fnameattr: action.payload.fnameattr,
                    lnameattr: action.payload.lnameattr,
                    eamilattr: action.payload.eamilattr,
                    passattr: action.payload.passattr,
                    gbasedn: action.payload.gbasedn,
                    groupsubtree: action.payload.groupsubtree,
                    groupidattr: action.payload.groupidattr,
                    grmemattr: action.payload.grmemattr,
                    grformatattr: action.payload.grformatattr,
                    groupsFilter: action.payload.groupsFilter,
                    captcha: action.payload.captcha,
                    isTestConn: action.payload.isTestConn
                };
            }
            case "POPULATE_LDAP_CAPTCHA_DETAILS": {
                return {
                 captcha: action.payload.captcha
                 };
            }
            default:
                return state;
        }
    }
}
export default new LDAPConfigurationStore();