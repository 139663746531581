import React, { Component } from "react";
import SectionWithTitle from "../../generic/SectionWithTitle";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import Store from "../../../flux/ReservationManagementStore";
import { Group as GroupIcon } from 'grommet-icons';
import { Upload } from 'grommet-icons';
import { Download } from 'grommet-icons';
import { History } from 'grommet-icons';
import CreatePool from './CreatePool';
import SpinningNotification from '../../generic/SpinningNotification';
import PoolMembers from "./PoolMembers";
import * as DateUtility from "../../../utils/DateUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import PoolMappingProducts from "./PoolMappingProducts";
import Dispatcher from "../../../flux/Dispatcher";
import AplsJobs from "../../main/config/AplsJobs.js";
import { Button } from "grommet/components/Button";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var timer = null;
class PoolManagement extends Component {
    constructor(props) {
        super(props);
        this.poolMemRef = React.createRef();
        this.jobPopupRef = React.createRef();
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "poolCreationTime",
                sortOrder: "DESC"
            },
            sortIndex: 3,
            sortAscending: false,
            showCreatePopup: false,
            poolNameError: "",
            poolDescError: "",
            poolParentError: "",
            selectedPoolName: "",
            poolsMap: {},
            defaultSelectIds: [],
            searchPoolName: "",
            searchParentName: "",
            searchDescription: "",
            searchCreatedTime: "",
            searchCreatedBy: "",
            showPoolMapping: false,
            searchAction: false,
            searchCreatedValue: "",
            session: undefined,
            prodAdmin: false,
            selectedFileName: undefined,
            submitFiles: undefined,
            showJobsModal: false,
            jobsData: [],
            deletePoolName: "",
            addPoolName: "",
            addParentName: "",
            poolAddAction: false,
            poolDeleteAction: false
        }
        this.findLabel = this.findLabel.bind(this);
        this.findData = this.findData.bind(this);
        this.loadPoolsGrid = this.loadPoolsGrid.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickCreatePool = this.onClickCreatePool.bind(this);
        this.closeCreatePoolPopup = this.closeCreatePoolPopup.bind(this);
        this.createPoolAction = this.createPoolAction.bind(this);
        this.poolDeletionSuccess = this.poolDeletionSuccess.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.buildSearchParams = this.buildSearchParams.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onClickReservationAction = this.onClickReservationAction.bind(this);
        this.onClickReservationBackButton = this.onClickReservationBackButton.bind(this);
        this.onFilterClicked = this.onFilterClicked.bind(this);
        this.continueFilterAction = this.continueFilterAction.bind(this);
        this.createPoolCallBack = this.createPoolCallBack.bind(this);
        this.resetPoolGrid = this.resetPoolGrid.bind(this);
        this.recursiveCallToCheckProdAdmin = this.recursiveCallToCheckProdAdmin.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.continueConfirmAction = this.continueConfirmAction.bind(this);
        this.closeJobsPopup = this.closeJobsPopup.bind(this);
        this.showJobsPopup = this.showJobsPopup.bind(this);
        this.downloadTemplateForPool = this.downloadTemplateForPool.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "poolCreationTime";
        if(index === 0) {
            sortBy = "poolName";
        } else if(index === 1) {
            sortBy = "poolDesc";
        } else if(index === 2) {
            sortBy = "parent";
        } else if(index === 4) {
            sortBy = "poolCreatedBy";
        }
        this.setState({sortIndex: index});
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction);
        this.findData(params);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                paginationTemp, this.state.sortIndex, this.state.searchAction);
        this.findData(params);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }

    onConfirmDelete(flag, key){
        if(flag === 1){
            this.setState({deletePoolName: key, poolDeleteAction: true});
            FetchHelper.deleteReservationPool(this.state.request, key, this.context.intl);
        }
    }
    onClickDelete(key, event) {
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(isAdmin || this.state.prodAdmin) {
            let messageParams = {};
            messageParams["poolName"] = key;
            let msg = LocaleUtility.getLocaleMessage("resv.pool.delete.warning", messageParams);
            ValidationUtility.confirm(msg, this.onConfirmDelete, key, "", "");
        }
    }
    onClickCreatePool() {
        this.setState({showCreatePopup: true});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }
    closeCreatePoolPopup() {
        this.setState({showCreatePopup: false});
        this.setState({poolNameError: ""});
        this.setState({poolDescError: ""});
        this.setState({poolParentError: ""});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.rowData !== this.state.rowData || this.state.session === undefined) {
            ConfigurationUtils.hideLoadMask(this.context.intl);
            this.setState({session: FetchHelper.getSession()});
        }
    }
    componentDidMount() {
        Store.on("change", this.loadPoolsGrid);
        Store.on("change", this.poolDeletionSuccess);
        Store.on("change", this.createPoolCallBack);
        Store.on("change", this.resetPoolGrid);
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction);
        this.findData(params);
    }
    createPoolAction(creatFormData) {
        this.setState({poolNameError: ""});
        this.setState({poolDescError: ""});
        this.setState({poolParentError: ""});
        let msgParams = {};
            msgParams["min"] = "3";
            msgParams["max"] = "100";
        if(!ValidationUtility.isEmpty(creatFormData.poolName) 
            || (ValidationUtility.isEmpty(creatFormData.poolName) && (creatFormData.poolName.length < 3 || creatFormData.poolName.length > 100))) {
            let msg = LocaleUtility.getLocaleMessage("resv.pool.name.length", msgParams);
            this.setState({poolNameError: msg});
        } else if(!ValidationUtility.validateSpecialChars(creatFormData.poolName)) {
            let msg = LocaleUtility.getLocaleMessage("resv.pool.name.invalid.chars");
            this.setState({poolNameError: msg});
        } else if(ValidationUtility.isEmpty(creatFormData.poolParentName) && (creatFormData.poolParentName.length < 3 || creatFormData.poolParentName.length > 100)) {
            let msg = LocaleUtility.getLocaleMessage("resv.parent.name.length", msgParams);
            this.setState({poolParentError: msg});
        } else if(!ValidationUtility.validateSpecialChars(creatFormData.poolParentName)) {
            let msg = LocaleUtility.getLocaleMessage("resv.parent.name.invalid.chars");
            this.setState({poolParentError: msg});
        } else if(!ValidationUtility.validateSpecialChars(creatFormData.poolDescription)) {
            let msg = LocaleUtility.getLocaleMessage("resv.pool.desc.invalid.chars");
            this.setState({poolDescError: msg});
        } else {
            this.setState({selectedPoolName: creatFormData.poolName});
            let userName = "admin";
            let ss = this.state.session;
            if(ss !== undefined && ss.user !== undefined && ss.user.userName !== undefined) {
                userName = ss.user.userName;
            }
            let postData = {
                name: creatFormData.poolName,
                parent: creatFormData.poolParentName,
                description: creatFormData.poolDescription,
                createdBy: userName,
            }
            this.setState({showCreatePopup: false, addPoolName: creatFormData.poolName, addParentName: creatFormData.poolParentName, poolAddAction: true});
            FetchHelper.createReservationPool(postData, this.state.request, this.context.intl);
        }
    }
    poolDeletionSuccess() {
        if(Store.getState() && Store.getState().actionType === "deletedpool") {
            //this.setState({rowData: []});
            //FetchHelper.reservationPoolList(this.state.request, this.context.intl);
            this.findData(this.state.request);
        }
    }
    findData(params) {
        //this.setState({rowData: []});
        this.setState({request: params});
        FetchHelper.reservationPoolList(params, this.context.intl);
    }
    componentWillUnmount() {
        Store.removeListener("change", this.loadPoolsGrid);
        Store.removeListener("change", this.poolDeletionSuccess);
        Store.removeListener("change", this.createPoolCallBack);
        Store.removeListener("change", this.resetPoolGrid);
    }
    createPoolCallBack() {
        if(Store.getState() && Store.getState().actionType === "createPool") {
            this.setState({rowData: []});
        }
    }
    recursiveCallToCheckProdAdmin(poolsData, prodAdmin) {
        poolsData.map(item => {
            if(!prodAdmin){
                item["delete"] = "";
                item["downloadIcon"] = "";
            }
            if(item.childs && item.childs.length > 0) {
                this.recursiveCallToCheckProdAdmin(item.childs, prodAdmin);
            }
            return "";
        });
    }

    loadPoolsGrid() {
        if(Store.getState() && Store.getState().actionType === "poolmanagement") {
            let poolsData = Store.getState().poolList;
            let prodAdmin = false;
            if(this.state.session !== undefined){
                prodAdmin = this.state.session.admin
                if(this.state.session.roletype === 'product'){
                    prodAdmin = true;
                }
            }
            poolsData.map(item => {
                if(!prodAdmin){
                    item["delete"] = "";
                    item["downloadIcon"] = "";
                }
                if(item.childs && item.childs.length > 0) {
                    this.recursiveCallToCheckProdAdmin(item.childs, prodAdmin);
                }
                return "";
            });
            let defaultSelId = [Store.getState().defaultPoolId];
            if(this.poolMemRef && this.poolMemRef.current && Store.getState().defaultPoolId !== undefined) {
                this.poolMemRef.current.refreshMembers(Store.getState().poolsMap[defaultSelId], false);
            }
            let sortAscending = Store.getState().sortOrder;
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            let pagination = Store.getState().pagination;
            pagination.required = true;
            pagination.style = "default";
            if(Store.getState().showPoolMapping !== undefined) {
                this.setState({showPoolMapping: Store.getState().showPoolMapping});
            }
            this.setState({
                rowData: poolsData,
                poolsMap: Store.getState().poolsMap,
                defaultSelectIds: defaultSelId,
                selectedPoolName: Store.getState().poolsMap[defaultSelId],
                pagination: pagination,
                prodAdmin: prodAdmin
            });
            if(this.state.poolDeleteAction === true) {
                let messageParams = {};
                messageParams["poolName"] = this.state.deletePoolName;
                ConfigurationUtils.showSuccessMessageWithParams(
                    messageParams,
                    "info.reservation.delete",
                    this.context.intl
                );
                this.setState({poolDeleteAction: false});
            }
            if(this.state.poolAddAction === true) {
                let params = {};
                params["poolName"] = this.state.addPoolName;
                params["parentName"] = this.state.addParentName;
                ConfigurationUtils.showSuccessMessageWithParams(
                    params,
                    "info.reservation.add",
                    this.context.intl
                );
                this.setState({poolAddAction: false});
            }

        }
    }
    onRowClicked(keys, event) {
        let poolName = this.state.poolsMap[keys[0]];
        this.setState({selectedPoolName: poolName});
        this.setState({defaultSelectIds: [keys[0]]});
        if(this.poolMemRef && this.poolMemRef.current) {
            this.poolMemRef.current.refreshMembers(poolName, true);
        }
    }
    submitSearch() {
        this.setState({searchAction: true});
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        this.findData(params);
    }
    buildSearchParams(name, desc, parent, createdBy, createdOn, pagination, index, searchAction) {
        let createdTime = null;
        if(createdOn && createdOn !== "") {
            createdTime = new Date(createdOn.value).getTime()/1000;
        }
        if(name === "" && desc === "" && parent === "" && createdBy === "" && createdOn === "") {
            searchAction = false;
            this.setState({searchAction: false});
        }
        let params = {
            "poolName": name,
            "poolDesc": desc,
            "parent": parent,
            "poolCreatedBy": createdBy,
            "poolCreationTime": createdTime,
            "pagenation": pagination,
            "sortIndex": index,
            "searchAction": searchAction
        }
        return params;
    }
    onClickReservationAction() {
        this.setState({showPoolMapping: true});
    }
    onClickReservationBackButton() {
        this.setState({showPoolMapping: false});
        let params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, this.state.searchAction);
        this.findData(params);
    }
    onFilterClicked(filterColumn, filterValue, e) {
        if(timer !== null) {
            clearTimeout(timer);
        }
        if(e !== undefined && e.charCode === 13) {
            this.continueFilterAction(filterColumn, filterValue);
        } else {
            timer = setTimeout(() => {
                    this.continueFilterAction(filterColumn, filterValue);
                },
                1000
            );
        }
    }
    continueFilterAction(filterColumn, filterValue) {
        this.setState({searchAction: true});
        let params;
        if(filterColumn === "poolName") {
            this.setState({searchPoolName: filterValue});
            params = this.buildSearchParams(filterValue, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolDesc") {
            this.setState({searchDescription: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, filterValue,
                this.state.searchParentName, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "parent") {
            this.setState({searchParentName: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                filterValue, this.state.searchCreatedBy, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolCreatedBy") {
            this.setState({searchCreatedBy: filterValue});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, filterValue, this.state.searchCreatedTime,
                this.state.pagination, this.state.sortIndex, true);
        } else if(filterColumn === "poolCreationTime") {
            this.setState({searchCreatedValue: filterValue.value});
            params = this.buildSearchParams(this.state.searchPoolName, this.state.searchDescription,
                this.state.searchParentName, this.state.searchCreatedBy, filterValue,
                this.state.pagination, this.state.sortIndex, true);
        }
        this.findData(params);
    }
    resetPoolGrid() {
        if(Store.getState() && Store.getState().actionType === "resetpools") {
            this.setState({rowData: []});
            this.setState({searchAction: false});
            this.setState({searchPoolName: ""});
            this.setState({searchDescription: ""});
            this.setState({searchParentName: ""});
            this.setState({searchCreatedBy: ""});
            this.setState({searchCreatedValue: ""});
        }
    }
    downloadTemplate() {
        let request = {
            "poolIds": []
        }
        FetchHelper.downloadReservationPoolTemplate(request, this.context.intl)
    }
    downloadTemplateForPool(key, event) {
        let poolIds = [];
        poolIds.push(key);
        let request = {
            "poolIds": poolIds
        }
        FetchHelper.downloadReservationPoolTemplate(request, this.context.intl)
    }
    onFileChange(event) {
        let files = Array.from(event.target.files);
        if(files !== undefined && files.length > 0) {
            let fileName = files[0].name;
            if(fileName.endsWith(".csv") || fileName.endsWith(".CSV")) {
                this.setState({selectedFileName: fileName, submitFiles: files[0]});
                let fileLength = files[0].size;
                let fileSize = 0;
                if(fileLength > 0) {
                    fileSize = fileLength /(1024 * 1024);
                }
                if(fileSize > 5) {
                    ConfigurationUtils.showErrorMessage1("61004", this.context.intl);
                } else {
                    this.continueConfirmAction(files[0], fileName);
                }
            } else {
                ConfigurationUtils.showErrorMessage1("reservation.invalid.template", this.context.intl);
            }
            document.getElementById("import-file-id").value = "";
        }
    }
    continueConfirmAction(file, fieName) {
        let data = new FormData();
        data.append('file', file);
        data.append('fileName', fieName);
        FetchHelper.importReservationPoolTemplate(data, this.context.intl);
    }
    closeJobsPopup() {
        this.setState({showJobsModal: false});
    }
    showJobsPopup() {
        Dispatcher.dispatch({
            type: "COMP_CLOSE"
        });
        if(this.jobPopupRef !== undefined && this.jobPopupRef.current !== undefined) {
            this.jobPopupRef.current.loadGrid();
            this.setState({showJobsModal: true});
        }
    }
    render() {
        //let intl = this.context.intl;
        let noDataFoundText = this.findLabel("label.no.data.found");
        let expandable = false;
        if(this.state.searchAction) {
            expandable = true;
        }
        let fullView = false;
        let ss = this.state.session;
        if(ss !== undefined && ss.roletype !== undefined && ss.roletype === "fullview") {
            fullView = true;
        }
        
        if(this.state.rowData.length === 0) {
            if(this.state.searchAction) {
                noDataFoundText = this.findLabel("resv.searc.result.not.found");
            } else {
                noDataFoundText = this.findLabel("resv.pool.not.found.message");
            }
        }
        let showJobPop = this.state.showJobsModal ? "jobs-popup-modal display-block" : "jobs-popup-modal display-none";
        if(this.state.showPoolMapping) {
            return(
                <PoolMappingProducts 
                    onClickBackButton = {this.onClickReservationBackButton}
                    poolName = {this.state.selectedPoolName}
                />
            )
        } else {
            return(
                <div className="config-cont-backgroud">
                    <SectionWithTitle title={this.findLabel("resv.pool.manage.title")}  helpTopic="R_Pool_Management_Tab"
                        setionWidth = "4"
                    />
                    <div className="pool_btn_wrap action_filter_wrapper">
                        {!fullView ? 
                            <div className="button_left_wrap">
                                        <div className="button_wrap_primary with_icon">
                                            <Button className="createPoolBtn" onClick={this.onClickCreatePool}>
                                                <span id="create-pool-btn-id"><GroupIcon size="huge" colorIndex="light-1" /></span><span style={{paddingLeft: "5px"}}>{this.findLabel("resv.create.pool.label")}</span>
                                            </Button>
                                        </div>
                                        <div className="button_wrap_primary with_icon">
                                            <Button className="createPoolBtn" onClick={this.downloadTemplate}>
                                                <span id="download-pool-template-id"><Download id="download-pool-template-icon-id" size="huge" colorIndex="light-1" /></span><span style={{paddingLeft: "5px"}}>{this.findLabel("label.download.button")}</span>
                                            </Button>
                                        </div>
                                        <div className="button_wrap_primary with_icon">
                                            <Button className="createPoolBtn">
                                                <span id="import-pool-template-id"><Upload id="import-pool-template-icon-id" size="huge" colorIndex="light-1" /></span><label id="import-pool-option-id" htmlFor="import-file-id" style={{paddingLeft: "5px"}}>{this.findLabel("label.import.button")}</label>
                                                <input hidden={true} type="file" id="import-file-id" name="import-file-id" accept=".csv" onChange={this.onFileChange}></input>
                                            </Button>
                                        </div>
                                        {/* {(this.state.rowData.length > 0 || this.state.searchAction) &&( */}
                                        <div toolTipText={this.findLabel("label.import.pool.history.tooltip")} className="button_wrap_primary with_icon primary_text_color">
                                            <span className="pool-import-history" onClick={this.showJobsPopup}>
                                                <span id="history-pool-icon-id"><History colorIndex="brand" /></span>
                                            </span>
                                        </div>
                                        {/* } ) */}
                                    </div>
                        :null}
                    </div>
                    <div className="pool_list_tbl_wrap">
                        {this.state.rowData.length > 0 || this.state.searchAction ?
                            <TableGrid id="poolListGridId"
                                header={[
                                    {
                                        columnName: "poolName",
                                        headerLabel: this.findLabel("resv.grid.header.poolname"),
                                        width: "25%",
                                        columnKey: "poolName",
                                        sort: true,
                                        filter: true,
                                        filterPlaceHolder: this.findLabel("resv.pool.placeholder.name.label")
                                    },
                                    {
                                        columnName: "poolDesc",
                                        headerLabel: this.findLabel("resv.grid.header.pooldesc"),
                                        href: false,
                                        width: "18%",
                                        sort: true,
                                        filter: true,
                                        filterPlaceHolder: this.findLabel("resv.pool.placeholder.desc.label")
                                    },
                                    {
                                        columnName: "parent",
                                        headerLabel: this.findLabel("resv.grid.header.poolparent"),
                                        width: "18%",
                                        columnKey: "colum3",
                                        sort: true,
                                        filter: true,
                                        filterPlaceHolder: this.findLabel("resv.pool.placeholder.parent.label")
                                    },
                                    {
                                        columnName: "poolCreationTime",
                                        headerLabel: this.findLabel("resv.grid.header.pool.time"),
                                        href: false,
                                        sort: true,
                                        width: "20%",
                                        filter: true,
                                        filterFieldType: "date",
                                        filterFieldFormat: DateUtility.getDateFormat(),
                                        filterFieldValue: this.state.searchCreatedValue
                                    },
                                    {
                                        columnName: "poolCreatedBy",
                                        headerLabel: this.findLabel("resv.grid.header.pool.by"),
                                        href: false,
                                        width: "12%",
                                        sort: true,
                                        filter: true,
                                        filterPlaceHolder: this.findLabel("resv.pool.placeholder.createdby.label")
                                    },
                                    {
                                        columnName: "delete",
                                        headerLabel: this.findLabel("resv.grid.header.pool.delete"),
                                        href: false,
                                        width: "6%",
                                        columnKey: "poolName",
                                        textAlign: "center",
                                        onClick: this.onClickDelete,
                                    },
                                    {
                                        columnName: "downloadIcon",
                                        headerLabel: this.findLabel("job.label.download.icon"),
                                        href: false,
                                        width: "8%",
                                        columnKey: "id",
                                        textAlign: "center",
                                        onClick: this.downloadTemplateForPool,
                                    }
                                ]}
                                rowData={this.state.rowData}
                                pagination={this.state.pagination}
                                rowSelection={1}
                                isExportRequired={false}
                                onSort={this.onSortClicked}
                                onFilter={this.onFilterClicked}
                                onPagination={this.onPaginationClicked}
                                sortIndex={this.state.sortIndex}
                                sortAscending={this.state.sortAscending}
                                onChangeCheckBoxCallback={this.onRowClicked}
                                rowKey="id"
                                childKey="childs"
                                expandAll={expandable}
                                defaultSelectIds={this.state.defaultSelectIds}
                                noDataFoundText={noDataFoundText}
                            />
                        : <p className="reservation-emtpy-message">{ !fullView ? this.findLabel("no.groups") : this.findLabel("resv.pool.not.found.message")} </p>
                        }
                    </div>
                    {this.state.rowData.length > 0 ?
                    <div className="reservation-pool-attributes border_white_backg_shadow">
                        <PoolMembers ref={this.poolMemRef}
                            poolName = {this.state.selectedPoolName}
                            activeIndex = {0}
                            onClickReservationLink = {this.onClickReservationAction}
                            poolSize = {this.state.rowData.length}
                            fullView = {fullView}
                        />
                    </div>
                    : null}
                    {this.state.showCreatePopup ? 
                        <CreatePool 
                            showModal = {this.state.showCreatePopup}
                            cancelAction = {this.closeCreatePoolPopup}
                            creatAction = {this.createPoolAction}
                            poolNameError = {this.state.poolNameError}
                            poolDescError = {this.state.poolDescError}
                            poolParentError = {this.state.poolParentError}
                        /> : ""
                    }
                     <div id="jobs-popup-div-main" className={showJobPop}>
                        <div id="jobs-popup-div-id" className="jobs-popup-modal" >
                            <div className="modal-main panel panel-default" style={{width: "95%"}}>
                                <div>
                                    <table style={{marginBottom: "0px"}}>
                                        <tbody>
                                            <tr>
                                                <td id="history-popup-grid-td">
                                                    <AplsJobs ref={this.jobPopupRef}
                                                        rowData = {this.state.jobsData}
                                                        category= {"RESERVATION"}
                                                        jobName= {"ASSOCIATE_MEMBERS"}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="button_left_wrap" style={{float: "right", padding: "5px"}}>
                                    <div className="button_wrap_negative" id="history-popup-cancel-td">
                                        <Button id="history-popup-canelBtn" label={this.findLabel("label.button.close")} plain={false} secondary={true} onClick={this.closeJobsPopup} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SpinningNotification centerPage={true} />
                    </div>
                </div>
            )
        }
    }
}
// PoolManagement.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default PoolManagement;