import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
//import { Button } from "grommet/components/Button";
import LicenseUsageInfo from "./LicenseUsageInfo";
import { SelectMultiple, Box, CheckBox} from 'grommet';
import ColorLabel from "./ColorLabel";
import * as DateUtility from "../../../utils/DateUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import { LineChart, Line, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import { useIntl } from 'react-intl';
//var ReactD3 = require('react-d3-components');
var d3 = require("d3-scale");
var d3time1 = require("d3-time");
var d3time = require("d3-time-format");
//var LineChart = ReactD3.LineChart;
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
    //return "";
  }

  return null;
};
class ConsumptionUsageGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "cumulativeUsage.createdDate",
                sortOrder: "DESC"
            },
            loadedData: false,
            licenseUsageMap: "",
            licenseUsageObj: "",
            executedByInfoObj: "",
            sortIndex: 0,
            sortAscending: true,
            tenant: "0",
            exportHistoryRecordSize: 0,
            graphData: [],
            showTooltip: false,
            xAxisFormat: [],
            xAxisLabel: [],
            lineColors: [],
            visualFilters: [],
            changeFilterValues: [],
            filterValues: [],
            selected:[],
            graphDataMap: {},
            graphDataColorMap: {},
            graphStartDate: new Date(),
            graphEndDate: new Date(),
            dayscount: 1,
            yAxisMaxValue: 100,
            popupTop: "800px",
            popupLeft: "550px",
            lineColorBar:[],
            toolTipContent:"",
        }
        this.tooltip = React.createRef();
        this.findLabel = this.findLabel.bind(this);
        this.loadConsumptionTableGrid = this.loadConsumptionTableGrid.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.setTenantId = this.setTenantId.bind(this);
        this.findData = this.findData.bind(this);
        this.loadConsumptionGraphData = this.loadConsumptionGraphData.bind(this);
        this.findGraphData = this.findGraphData.bind(this);
        this.onClickLicenseUsage = this.onClickLicenseUsage.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.tooltipLine = this.tooltipLine.bind(this);
        this.changeFilterAction = this.changeFilterAction.bind(this);
        this.showToolTip = this.showToolTip.bind(this);
        this.removeToolTip = this.removeToolTip.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        APTableStore.on("change", this.loadConsumptionTableGrid);
        APTableStore.on("change", this.loadConsumptionGraphData);
        APTableStore.on("change", this.props.callToExportRequest);
        APTableStore.on("change", this.props.listTenantNames);
        this.findGraphData(this.props.tenant, this.props.usageStartDate, this.props.usageEndDate);
        this.findData(this.state.sortIndex, this.state.pagination, this.props.tenant, this.props.usageStartDate, this.props.usageEndDate);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadConsumptionTableGrid);
        APTableStore.removeListener("change", this.loadConsumptionGraphData);
        APTableStore.removeListener("change", this.props.callToExportRequest);
        APTableStore.removeListener("change", this.props.listTenantNames);
    }
    setTenantId(tenant, usageStartDate, usageEndDate) {
        this.setState({rowData: []});
        this.setState({tenant: tenant});
        this.findGraphData(tenant, usageStartDate, usageEndDate);
        this.findData(this.state.sortIndex, this.state.pagination, tenant, usageStartDate, usageEndDate, tenant);
    }
    onClickLicenseUsage(key, event) {
        let clientObj = this.state.licenseUsageMap[key];
        this.setState({licenseUsageObj: clientObj});
        this.setState({showTooltip: true});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({showTooltip: false});
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "cumulativeUsage.cumulativeUsage";
        if(index === 1) {
            sortBy = "cumulativeUsage.reportedUsageTime";
        } else if(index === 2) {
            sortBy = "cumulativeUsage.tenantId";
        } else if(index === 3) {
            sortBy = "cumulativeUsage.mapddfeatureId";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.findData(index, paginationTemp, this.state.poolId, this.props.usageStartDate, this.props.usageEndDate, this.state.tenant);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp, this.state.poolId, this.props.usageStartDate, this.props.usageEndDate, this.state.tenant);
    }
    findGraphData(tenant, usageStartDate, usageEndDate) {
        let featInfo = localStorage.getItem("featureId:Version");
        if(featInfo) {
            if(usageStartDate === undefined || usageStartDate === "") {
                usageStartDate = DateUtility.getDate(new Date());
            }
            if(usageEndDate  === undefined || usageEndDate === "") {
                usageEndDate = DateUtility.getDate(new Date());
            }
            this.setState({defaultSelIds: []});
            let featArr = featInfo.split(":");
            let params = {};
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["tenant"] = tenant;
            params["startTime"] = usageStartDate;
            params["endTime"] = usageEndDate;
            params["tenant"] = tenant;
            FetchHelper.findConsumptionGraphData(this.context.intl, params, true);
        }
    }
    findData(sortIndex, pagination, groupId, usageStartDate, usageEndDate, tenentId) {
        let featInfo = localStorage.getItem("featureId:Version");
        let licenseModel = localStorage.getItem("licenseModel");
        if(featInfo) {
            this.setState({defaultSelIds: []});
            let featArr = featInfo.split(":");
            let params = {};
            params["pagination"] = pagination;
            params["sortIndex"] = sortIndex;
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["groupId"] = "0";
            params["minCapacity"] = "0";
            params["licenseModel"] = licenseModel;
            params["showall"] = "false";
            params["usageStartDate"] = usageStartDate;
            params["usageEndDate"] = usageEndDate;
            params["cumulativeUsage"] = localStorage.getItem("cumulativeUsage");
            params["tenentId"] = tenentId;
            if(groupId > 0) {
                params["filterPool"] = "true";
            }
            FetchHelper.listUsageConsumptionData(this.context.intl, params);
        }
    }
    loadConsumptionGraphData() {
        if(APTableStore.getState().pageType === "unitcons_graph") {
            let graphData = APTableStore.getState().graphData;
            if(graphData) {
                this.setState({graphData: graphData});
            }
            if(APTableStore.getState().xAxisFormat) {
                this.setState({xAxisFormat: APTableStore.getState().xAxisFormat});
            }
            if(APTableStore.getState().lineColors) {
                this.setState({lineColors: APTableStore.getState().lineColors});
            }
            this.setState({graphStartDate: APTableStore.getState().graphStartDate});
            this.setState({graphEndDate: APTableStore.getState().graphEndDate});
            this.setState({dayscount: APTableStore.getState().dayscount});
            this.setState({yAxisMaxValue: APTableStore.getState().yAxisMaxValue});
            let lineChartLines = [];
            if(APTableStore.getState().visualFilters) {
                this.setState({visualFilters: APTableStore.getState().visualFilters});
                let fList = [];
                let fValues = [];
                let lineColorBar = []
                APTableStore.getState().visualFilters.map(item =>{
                    if(item.value !== 0) {
                        fList.push({label: <ColorLabel isConsumption={true} poolName={item.label} poolNameIndicationColor={item.color}/>, value: item.value});
                        fValues.push(item.value);
                        // lineColorBar.push(<Line type="monotone" dataKey={item.value} name={item.value} 
                        //                         label={item.label} stroke={item.color} 
                        //                         activeDot={{
                        //                             onMouseOver: this.showToolTip,
                        //                             onMouseLeave: this.removeToolTip
                        //                         }}/>);
                    }
                    return "";
                });
                this.setState({filterValues: fList});
                //this.setState({lineColorBar: lineColorBar});
                this.setState({changeFilterValues: fValues});
            }
            if(APTableStore.getState().xAxisLabel) {
                this.setState({xAxisLabel: APTableStore.getState().xAxisLabel});
            }
            if(APTableStore.getState().graphDataMap) {
                let gData = APTableStore.getState().graphDataMap;
                // gData.map(val => {
                //     lineChartLines.push(gData[val]);
                //     return;
                // });
                this.setState({graphDataMap: gData});
            }
            if(APTableStore.getState().graphDataColorMap) {
                this.setState({graphDataColorMap: APTableStore.getState().graphDataColorMap});
            }
        }
    }
    loadConsumptionTableGrid() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "unitcons") {
            if(APTableStore.getState().cumulativeUsageList) {
                this.setState({rowData: APTableStore.getState().cumulativeUsageList});
            }
            let sortIndex = APTableStore.getState().sortIndex;
            let sortAscending = APTableStore.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            let pagination = APTableStore.getState().pagination;
            if(pagination) {
                pagination.required = true;
                pagination.style = "default";
                this.setState({pagination: pagination});
            }
            if(APTableStore.getState().licenseUsageMap) {
                this.setState({licenseUsageMap: APTableStore.getState().licenseUsageMap});
            }
            this.setState({exportHistoryRecordSize: APTableStore.getState().exportHistoryRecordSize});
            ConfigurationUtils.hideLoadMask(this.context.intl);
        }
    }
    tooltipLine(axisLabel, axisObject) {
        // if(axisObject.payload !== undefined) {
        //     return axisLabel+"[ "+axisObject.y+" ]";
        // } else {
        //     return "";
        // }
        if(axisLabel.payload !== undefined && axisLabel.payload.length > 0) {
            let item = axisLabel.payload[0];
            let obj = Number(item.name);
            let gMapData = this.state.graphDataMap[obj];
            return gMapData.label+"[ "+item.value+" ]";
        } else {
            return "";
        }
    }
    changeFilterAction(event) {
        let currentValues = this.state.changeFilterValues;
        let newValue = [];
        let gData = [];
        let gDataColor = [];
        let gDataMap = this.state.graphDataMap;
        let gDataColorMap = this.state.graphDataColorMap;
        this.state.changeFilterValues.map(val => {
            if(val !== event.option.value && val !== 0) {
                newValue.push(val);
                gData.push(gDataMap[val]);
                gDataColor.push(gDataColorMap[val]);
            }
            return "";
        });
        if(currentValues.indexOf(event.option.value) === -1) {
            newValue.push(event.option.value);
            gData.push(gDataMap[event.option.value]);
            gDataColor.push(gDataColorMap[event.option.value]);
        }
        newValue.push(0);
        gData.push(gDataMap[0]);
        gDataColor.push(gDataColorMap[0]);
        this.setState({changeFilterValues: newValue});
        this.setState({lineColors: gDataColor});
        this.setState({graphData: gData});
    }

    showToolTip = (e) => {
        let x = Math.round(e.cx);
        let y = Math.round(e.cy);
        this.tooltip.style.opacity = "1";
        this.tooltip.childNode[0].innerHTML = e.payload["value"];
    }

    removeToolTip = (e) => {
       this.tooltip.style.opacity = "0";
    }


    render() {
        //let intl = this.context.intl;
        let headers = [];
        let isDependableFeature = false;
        let isDepFromLocal = localStorage.getItem("isDependableFeature");
        if(isDepFromLocal !== undefined && isDepFromLocal === "true") {
            isDependableFeature = true;
        }
        headers.push({
                        columnName: "cumulativeUsage",
                        headerLabel: this.findLabel("usage.consumption.usage.label"),
                        width: "auto",
                        href: true,
                        sort: true,
                        columnKey: "id",
                        onClick: this.onClickLicenseUsage,
                    });
        headers.push({
                        columnName: "reportedUsageTime",
                        headerLabel: this.findLabel("usage.consumption.usagetime.label"),
                        href: false,
                        width: "auto",
                        sort: true,
                    });
        headers.push({
                        columnName: "tenentUserName",
                        headerLabel: this.findLabel("usage.consumption.tenent.label") ,
                        href: false,
                        width: "auto",
                        sort: true,
                    });
        if(!isDependableFeature) {
            headers.push({
                            columnName: "mapddfeatureInfo",
                            headerLabel: this.findLabel("usage.consumption.productfeature.label"),
                            href: false,
                            width: "auto",
                            sort: true,
                        });
        }
        if(!isDependableFeature) {
            headers.push({
                            columnName: "repCumulativeUsageInfo",
                            headerLabel: this.findLabel("usage.consumption.prodfeature.usage.label"),
                            width: "auto",
                            sort: false,
                        });
        }
        let showTooltipCls = "tooltipContent tooltipContentHide";
        if(this.state.showTooltip) {
            showTooltipCls = "tooltipContent tooltipContentshow";
        }
        let colorScale = "";//d3.scale.category20().range(this.state.lineColors);
        let yMaxValue = 100;
        if(this.state.yAxisMaxValue > 100) {
            yMaxValue = this.state.yAxisMaxValue;
        }
        // let yScale = d3.scale.linear()
        //     .domain([0, yMaxValue])
        //     .range([337, 27]);
        let yScale = "";
        let xScale = d3.scaleTime().domain([this.state.graphStartDate , this.state.graphEndDate]); //d3.time.scale().domain([this.state.graphStartDate , this.state.graphEndDate]).range([0, 900]);
        let xAxisTicks = "";//xScale.ticks(1);
         let xOffset = 12;
        if(this.state.dayscount === 1) {
            xAxisTicks = xScale.ticks(24);
        } else if(this.state.dayscount <= 31) {
            xAxisTicks = xScale.ticks(this.state.dayscount);
        } else if(this.state.dayscount > 31 && this.state.dayscount < 218) {
            xAxisTicks = xScale.ticks(d3time1.timeWeek.every(1));
        } else if(this.state.dayscount >= 218 && this.state.dayscount < 931) {
            xAxisTicks = xScale.ticks(d3time1.timeMonth.every(1));
            xOffset = 22;
        } else if(this.state.dayscount >= 931) {
            xAxisTicks = xScale.ticks(d3time1.timeYear.every(1));
            xOffset = 22;
        }
        let svg; //d3.selectAll("svg");
        // svg.selectAll(".historyXAxis text").attr("transform", "rotate(-40)");
        // svg.selectAll(".historyYAxis text").attr("transform", "rotate(0)");
        // svg.selectAll(".historyYAxis .label").attr("y", "0");
        let defatulStrokeWidth = 2;
        if(this.state.graphData !== undefined && this.state.graphData.length > 1) {
            let lineObject = this.state.graphData[0];
            if(lineObject !== undefined && lineObject.values !== undefined && lineObject.values.length === 1) {
                defatulStrokeWidth = 9;
            }
        }
        return (
            <div className="inuse-cont-backgroud">
                {this.state.graphData.length > 0 || this.state.filterValues.length > 0 ? <div style={{width:"100%", float: "left", paddingTop: "5px", marginLeft: "7px", marginRight: "6px", marginBottom: "6px", display:"flex"}}>
                    <div id="usage_rejected_graph_id" style={{width: "75%", float: "left", paddingTop: "5px"}}>
                        {/* <LineChart
                            data={this.state.graphData}
                            stroke={{strokeWidth: defatulStrokeWidth, strokeLinecap: "round"}}
                            yScale={yScale}
                            xScale={xScale}
                            width={980}
                            height={400}
                            colorScale={colorScale}
                            xAxis={{label: this.state.xAxisLabel, tickValues: xAxisTicks, tickFormat: d3.time.format(this.state.xAxisFormat), className: "historyXAxis"}}
                            yAxis={{label: this.findLabel("commuter.capacity.label", intl), className: "historyYAxis"}}
                            tooltipHtml={this.tooltipLine}
                            margin={{top: 10, bottom: 50, left: 50, right: 10}}
                            tooltipMode={"element"}
                        /> */}
                        <LineChart
                            data={this.state.graphData}
                            width={900}
                            height={400}
                            margin={{top: 25, bottom: 35, left: 10, right: 10}}
                        >
                        <XAxis dataKey="x" label={{value: this.state.xAxisLabel, offset:xOffset, position:"bottom"}} 
                            className="historyXAxis" interval={0} angle={-40}
                            domain={[this.state.graphStartDate , this.state.graphEndDate]}
                            name="hour"
                            tickFormatter={d3time.timeFormat(this.state.xAxisFormat)}
                            type='number'
                            ticks={xAxisTicks}
                            minTickGap={60*60*1000}/>
                        <YAxis type="number" domain={[0, yMaxValue]} tickCount={10} tickSize={5} interval={0} className="historyYAxis" label={{value: this.findLabel("commuter.capacity.label"), offset:12, position:"top"}}/>
                        <Tooltip />
                        {/* <Legend /> */}
                        {/* {this.state.lineColorBar} */}
                        {Object.entries(this.state.graphDataMap).map(([key, value]) => (
                            <Line dataKey="y" data={value.values} name={value.label} key={value.label} stroke={this.state.graphDataColorMap[key]} />
                        ))}

                        {/* <Bar dataKey="reject" stackId="a" fill="rgb(229, 0, 76)" /> */}
                        </LineChart>
                    </div>
                    <div style={{width: "23%", float: "left", paddingTop: "5px", height: "407px", overflowX: "auto", display: "inline-block"}}>
                         <div style={{fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.56)"}}>{this.findLabel("usage.consumption.visual.filter.label")}</div>
                         <div style={{paddingLeft: "15px"}}>
                             {this.state.filterValues.length > 0 ?
                                <SelectMultiple id="usage_legendsId"
                                    showSelectedInline
                                    options={this.state.filterValues}
                                    value={this.state.filterValues}
                                    labelKey="label"
                                    valueKey={{ key: 'value' }}
                                    onChange={this.changeFilterAction}
                                >
                                </SelectMultiple>
                                : null }
                        </div>
                        {!isDependableFeature ? 
                            <div style={{paddingLeft: "14px"}}>
                                <div style={{"width": "14px", "height": "14px", "background": "#e5004c", "opacity": "0.8", float: "left", marginTop: "5px", marginRight: "2px", border: "2px solid #9e9c9c"}}></div>
                                <span id="rejected_license_capacity_label_id" style={{paddingLeft: "3%", "color": "#e5004c", fontSize: "0.875rem", fontFamily: "Metric"}}>License Capacity</span>
                            </div>
                        :null}
                    </div>
                </div>
                 : 
                 <div style={{width: "100%", float: "left", height: "200px", textAlign: "center", border: "1px solid #ccc"}}>
                    <div style={{paddingTop: "70px"}}>
                        <span id="no_data_found_for_rejected_graph_id" style={{paddingTop: "50px", fontSize: "26px", "color": "rgb(180, 180, 180)"}}>{this.findLabel("usage.graph.history.show.msg2")}
                        </span>
                    </div>
                </div> }
                <div className="ui-chart-tooltip" ref={ref => (this.tooltip = ref)} >
                    <div className="ui-chart-tooltip-content" />
                </div>
                <div className="tooltipMain">
                    <div id="rejected_license_info_id" style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={showTooltipCls}>
                        <LicenseUsageInfo 
                            operation = {this.state.licenseUsageObj.operation}
                            repUsage = {this.state.licenseUsageObj.repUsage}
                            closeTootipAction={this.closeTootipAction}
                        />
                    </div>
                </div>
                <div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <TableGrid id="usage_consumption_list_grid"
                        header={headers}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={0}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        rowKey="id"
                        noDataFoundText={this.findLabel("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div id="rejected_export_limit_value_id" style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    {this.findLabel("usage.graph.export.limit.label")} {this.state.exportHistoryRecordSize}
                </div>
                {/*<div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <Button id="usage_consumption_backBtn" label={this.findLabel("label.button.back", intl)} plain={false} secondary={true} onClick={this.props.backButtonAction} />
                </div>*/}
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// ConsumptionUsageGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default ConsumptionUsageGraph;