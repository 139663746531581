import React, { Component } from "react";
import Menu from "./Menu";
import LicenseHome from "../main/license/License";
import Report from "../main/reports/Report";
import AccessRoute from "./AccessRoute";
import { Router, Route, Routes, createSearchParams } from "react-router-dom";
import PoolManagement from "../main/reservation/PoolManagement";
import FeatureBaseReservation from "../main/reservation/FeatureBaseReservation";
import ProductBaseReservation from "../main/reservation/ProductBaseReservation";
import CommuterCheckout from "../main/commuter/CommuterCheckout";
import CommuterCheckin from "../main/commuter/CommuterCheckin";
import UserUpdate from "../main/user/UserUpdate";
import UsersManagmentHome from "../main/user/UsersManagment";
import UserGroupManagmentHome from "../main/user/UserGroupManagement";
import BuymoreHome from "../main/buymore/Buymore";
import AppNotification from "../generic/AppNotification";
import LicenseView from "../main/license/LicenseView";
import ArchivedLicense from "../main/license/ArchivedLicense";
import NamedUserLicense from "../main/license/NamedUserLicense";
import LicenseDetail from "../main/license/subcomponents/LicenseDetail";
import LicenseCleanup from "../main/license/LicenseCleanup";
import LicenseBorrow from "../main/license/LicenseBorrow";
import LicenseBorrowForm from "../main/license/subcomponents/LicenseBorrowForm";
import MainConfiguration from "../main/config/MainConfiguration";
import UserConfig from "../main/config/UserConfiguration";
import LDAPConfig from "../main/config/LDAPConfiguration";
import IDMConfig from "../main/config/IDMConfiguration";
import UsageHubConfig from "../main/config/UsageHubConfiguration";
import DataBaseConfig from "../main/config/DatabaseConfiguration";
import UsageReportsList from "../main/reports/UsageReportsList";
import UsageReportsListSearch from "../main/reports/UsageReportsSearch";
import UsageReportGraphs from "../main/reports/UsageReportGraphs";
import AdvancedUsageReports from "../main/reports/AdvancedUsageReports";
import * as FrontendPagePath from "../../utils/FrontendPagePath";
import * as Utils from "../../utils/Util";
import * as LocaleUtility from "../../utils/LocaleUtility";
import AppStore from "../../flux/AppStore";
import TokenCal from "../main/reports/TokenCal";
import PerInstanceDetail from "../main/reports/PerInstanceDetail";
import GenericReporting from "../main/usage/GenericReporting";												 
import ControlTower from "../main/config/ControlTower";
import TenantNames from "../main/user/MultiTenants";
import AppLogs from "../main/config/AppLogs";
import ConfigEntries from "../main/config/ConfigEntries";
import SubNotification from "../main/config/SubNotification";
import ServerAuthToken from "../main/config/ServerAuthToken";
import TenantLicense from "../main/license/TenantLicense";
import LicenseTenantForm from "../main/license/subcomponents/LicenseTenantForm";
import AppConfigSettingStore from "../../flux/AppConfigSettingStore";
import Saml from "../main/config/Saml";
import {
  fetchIndex,
  refreshPage,
  setToken,
  getSession,
  setSession,
  requestLogout,
  getCurrentTenantName,
  setCurrentTenantName,
  getUiRquestValue,
  findConfigurationSetting,
  fetchIndexSaml
} from "../../utils/FetchHelper";
import { APLS_WS_USER_SESSION } from "../../utils/RestWebServicePaths";
import Dispatcher from "../../flux/Dispatcher";
import AlertModal from "../generic/AlertModal";
import "../../css/index.css";
import LoginForm from "./LoginForm";
import ProductFeature from "../main/license/ProductFeature";
import ConfirmModal from "../generic/ConfirmModal";
import ProductUsage from "../main/usage/ProductUsage";
import UsageTenantWiseReport from "../main/reports/UsageTenantWiseReport";
import Audits from "../main/config/Audits";
import { Grommet } from "grommet";
import withRouter from "./withRouter";

var idmpoints = Utils.getIdmPoints();
var nonadminpoints = Utils.getNonadminPoints();
var viewonlypoints = Utils.getViewonlyPoints();
var tenantpoints = Utils.getTenantPoints();
const theme = {
  formField:{
    label: {
      requiredIndicator: true
    }
  },
  global: {
    colors: {
      // Overriding existing colors
      brand: '#0073e7',
    }
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      count: 1,
      secondCount: 1,
      subIndex: 0,
      refreshState: 0,
      isCTenabled: false
    };
    this.callback1 = this.callback1.bind(this);
    this.callback2 = this.callback2.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.findTidFn = this.findTidFn.bind(this);
    this.refreshCT = this.refreshCT.bind(this);
  }

  findTidFn(location) {
    return Utils.findTidFn(location);
  }

  componentDidMount() {
    AppStore.on("change", this.refreshState);
    AppConfigSettingStore.on("change", this.refreshCT);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    AppStore.removeListener("change", this.refreshState);
    AppConfigSettingStore.removeListener("change", this.refreshCT);
  }

  refreshCT() {
    let formData = AppConfigSettingStore.getState().formData;
    if(formData!==undefined){
      this.setState({isCTenabled: (formData.ctConnector === "true")});
    }
  }

  refreshState() {
    if(AppStore.getState().user !== undefined ){
      var session = AppStore.getState().sessionMap;
      this.setState({
        activeIndex: 0,
        user: AppStore.getState().user,
        container: AppStore.getState().container,
        session: session,
        idm: AppStore.getState().idm,
        saml: AppStore.getState().saml,
        setSaml: AppStore.getState().setSaml
      });

      var state = {};
      if((AppStore.getState().setSaml !== undefined && AppStore.getState().setSaml)){
          state = { index: 6, subIndex: 10, user: this.state.user };
          this.setState({activeIndex: 6});
          this.routerHistory({ pathname:FrontendPagePath.APP_CONFIG_SAML_ENTRIES, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
      } else if (
        (AppStore.getState().url !== undefined &&
          AppStore.getState().url.endsWith("REDIRECT") && session !== undefined &&
          session.isadmin !== undefined && session.isadmin.value)
      ) {
        state = { index: 6, subIndex: 0, user: this.state.user };
        this.setState({activeIndex: 6});
        this.routerHistory({ pathname: FrontendPagePath.MAIN_CONFIG_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
      } else {
        if("true" === localStorage.getItem("changedMTFlag")) {
          state = { index: 6, subIndex: 0, user: this.state.user };
          this.setState({activeIndex: 6});
          this.routerHistory({ pathname: FrontendPagePath.MAIN_CONFIG_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
        } else {
          state = { index: 1, subIndex: 1, user: this.state.user };
          this.setState({activeIndex: 1});
          this.routerHistory({ pathname: FrontendPagePath.LICENSE_VIEW_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
        }
      }
    } else if(AppStore.getState().user === undefined ){
      this.setState({
        activeIndex: -1, /*TO RESET INDEX TO DEFAULT LOGOUT*/
        user: AppStore.getState().user,
        container: AppStore.getState().container,
        idm: AppStore.getState().idm,
        saml: AppStore.getState().saml,
        setSaml: AppStore.getState().setSaml
      });

      if((AppStore.getState().setSaml !== undefined && AppStore.getState().setSaml)){
          state = { index: 6, subIndex: 10, user: this.state.user };
          this.routerHistory({ pathname: FrontendPagePath.SAML_CONFIG_LOGIN_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
      } else if(AppStore.getState().error !== undefined && AppStore.getState().error === "APP_FETCH_EXCEPTION") {
        this.routerHistory("/autopass", {});
        window.location.reload();
      } else if(AppStore.getState().idm !== undefined && AppStore.getState().idm &&
                !(AppStore.getState().status !== undefined && AppStore.getState().status)){
        let tid = this.findTidFn(this.routerLocation);
        document.title = LocaleUtility.getLocaleMessage('page.title.logout');
        this.routerHistory({ pathname: FrontendPagePath.LOGOUT_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
        window.location.href = FrontendPagePath.LOGOUT_PATH;
        //requestLogout();
      } else if(AppStore.getState().saml !== undefined && AppStore.getState().saml &&
                AppStore.getState().status !== undefined && !AppStore.getState().status){
        let tid = this.findTidFn(this.routerLocation);
        document.title = LocaleUtility.getLocaleMessage('page.title.logout');
        this.routerHistory({ pathname: FrontendPagePath.LOGOUT_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
        window.location.href = FrontendPagePath.LOGOUT_PATH + Utils.buildURLQueryParam();
      } else if (AppStore.getState().url !== undefined) {
        if (AppStore.getState().url.endsWith("/reset")) {
          document.title = LocaleUtility.getLocaleMessage("page.title.login");
          let tid = this.findTidFn(this.routerLocation);
          if(tid !== undefined && tid !== "" && tid !== null && tid !== "null") {
            this.routerHistory({ pathname: AppStore.getState().url, search: createSearchParams('?tenant='+tid).toString(), state: {}});
          } else {
            this.routerHistory({ pathname: AppStore.getState().url, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
          }
        }
      } else {
        if(!(this.routerHistory !== undefined && this.routerLocation.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 ||
        //this.routerLocation.pathname.indexOf("/ls/setsaml") > -1 ||
        (AppStore.getState().saml !== undefined && AppStore.getState().saml))){
          document.title = LocaleUtility.getLocaleMessage("page.title.login");
          let tid = this.findTidFn(this.routerLocation);
          if(tid !== undefined && tid !== "" && tid !== null && tid !== "null") {
            this.routerHistory({ pathname: FrontendPagePath.LOGIN_PATH, search: createSearchParams('?tenant='+tid).toString(), state: {}});
          } else {
            this.routerHistory({ pathname: FrontendPagePath.LOGIN_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}});
          }
        }
      }
    }
  }

  callback1(match, location, history) {
    this.match = match;
    this.routerLocation = location;
    this.routerHistory = history;
    console.log("MATCH :");
    console.log(match);
    console.log(location);
    console.log(history);
    console.log("Public URL:"+process.env.PUBLIC_URL);

    var page = this.routerLocation.pathname;


    var index = -1, subIndex = -1;

    var container = false, tenantMisMatch = false;
    let tid = this.findTidFn(location);
    const query = new URLSearchParams(window.location.search);
    let queryTenant = query.get('tenant');
    let idmDefaultTenant = sessionStorage.getItem("defaultIDMTenant");
    let loggedInTenant = sessionStorage.getItem("loggedInTenant");
    let urlQuerParam = ""; 
    if(idmDefaultTenant !== undefined && idmDefaultTenant !== null && (queryTenant === null || queryTenant === "") 
      && loggedInTenant !== null && loggedInTenant !== 'null' && "0" !== loggedInTenant) {
      urlQuerParam = "?tenant="+loggedInTenant;
      tid = loggedInTenant;
    } 
    
    if(queryTenant !== null && queryTenant !== "" && loggedInTenant !== null 
        && loggedInTenant !== 'null' && "0" !== loggedInTenant && "" !== loggedInTenant && queryTenant !== loggedInTenant){
      urlQuerParam = "?tenant="+queryTenant;
      tid = queryTenant;
      tenantMisMatch = true;
      // requestLogout();
      // return;
    }

    if(queryTenant === null && loggedInTenant !== null 
        && loggedInTenant !== 'null' && "0" !== loggedInTenant && "" !== loggedInTenant ){
     
      tenantMisMatch = true;
      // requestLogout();
      // return;
    }


    if(window.location.search !== "" && (window.location.search.indexOf("?tenant") === -1)){
      requestLogout();
    } 

  //if(!tenantMisMatch){
    if(!(this.routerLocation.pathname.indexOf("/ls/login/user/reset/") > -1 && this.routerLocation.pathname.indexOf("/ls/login/tenant/password/create/") > -1)){
      fetch(APLS_WS_USER_SESSION+urlQuerParam, {
        method: "GET",
        credentials: "include",
        headers: {
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache, no-store",
          "Tenant-Name": getCurrentTenantName(),
          "Tenant-Id": tid,
          "Apls-CA": getUiRquestValue(),
          //'XSRF-TOKEN': getToken(),
          Accept: "application/json"
        }
      })
        .then(response => {
          // if(response.status == 200){
          //   var xsrfToken = response.headers.get("XSRF-TOKEN");
          //   if (xsrfToken !== undefined && xsrfToken !== "") {
          //     setToken(xsrfToken);
          //   }
          // }
          return response.status !== 401 ? response.json() : undefined;
        })
        .then(responseJson => {
          var session;
          var roletype;
          if(responseJson === undefined){
            this.routerLocation.state = {};
            sessionStorage.setItem("loggedInTenant", "");
            sessionStorage.setItem("defaultIDMTenant", "");
            sessionStorage.setItem('currentSessionTenant', "");
            setCurrentTenantName("");
          } else {
            setSession(responseJson);
            if (!tenantMisMatch && page === FrontendPagePath.LOGIN_PATH)
              page = FrontendPagePath.LICENSE_VIEW_PATH;
            this.routerLocation.state = { user: responseJson.user };
            var map = {}, setSaml = false;
            if (responseJson.responseMap !== undefined) {
              responseJson.responseMap.entry.forEach(item => {
                if(item.key === "currentTenantName" && item.value !== undefined && item.value !== null && item.value !== 'null') {
                  sessionStorage.setItem("loggedInTenant", item.value.value);
                }
                if(item.key === "setSaml" && item.value !== undefined && item.value !== null && item.value.value !== 'null' &&
                      item.value.value) {
                  setSaml = true;
                }
                map[item.key] = item.value;
              });
            }
            session = map;
            // if(tenantMisMatch){
            //   requestLogout();
            //   return;
            // } else {
             if(!tenantMisMatch){
                 Dispatcher.dispatch({
                type: "USER_SESSION",
                payload: {user: responseJson.user, url: responseJson.loginStatus, setSaml: setSaml}
              });
             } else{
               setCurrentTenantName("");
             }
             
            // }            
          }
          
          if (
            this.routerLocation.pathname.indexOf("samlSuccess") > -1 && session === undefined
          ) {
            fetchIndexSaml();
            //page = FrontendPagePath.LICENSE_VIEW_PATH;
            index = 1;
            subIndex = 1;
          }
          else if (
            this.routerLocation.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 && session === undefined
          ) {
            fetchIndex();
            //page = FrontendPagePath.LICENSE_VIEW_PATH;
            index = 1;
            subIndex = 1;
          } else if (
            this.routerLocation.pathname.indexOf("/ls/login/user/reset/") > -1 || 
            this.routerLocation.pathname.indexOf("/ls/login/tenant/password/create/") > -1
          ) {
            /** Redirect to router path */
          } else if (
            this.routerLocation.state === undefined ||
            this.routerLocation.state.user === undefined
          ) {
            if(this.routerLocation.pathname !==
                  FrontendPagePath.SAML_CONFIG_LOGIN_PATH){
              page = FrontendPagePath.LOGIN_PATH;
              document.title = LocaleUtility.getLocaleMessage("page.title.login");
            } else {
              page = FrontendPagePath.SAML_CONFIG_LOGIN_PATH;
              document.title = LocaleUtility.getLocaleMessage("page.title.login");
            }
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.USAGE_REPORT_LIST_PATH
          ) {
            page = FrontendPagePath.USAGE_REPORT_LIST_PATH;
            index = 0;
            document.title = LocaleUtility.getLocaleMessage("page.title.usage");
          } else if (this.routerLocation.pathname === FrontendPagePath.LICENSE_PATH) {
              index = 1;
              document.title = LocaleUtility.getLocaleMessage('page.title.license');
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_DETAIL_PATH
          ) {
            index = 1;
            subIndex = 0;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license"
            );
          } else if (
            this.routerLocation.pathname ===
              FrontendPagePath.LICENSE_VIEW_PATH //||
            // this.routerLocation.pathname === "/" ||
            // this.routerLocation.pathname === "/autopass/" ||
            // this.routerLocation.pathname === "/autopass/ls/" ||
            // this.routerLocation.pathname === FrontendPagePath.LICENSE_PATH
          ) {
            page = FrontendPagePath.LICENSE_VIEW_PATH;
            index = 1;
            subIndex = 1;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.view"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_ARCHIVE_PATH
          ) {
            index = 1;
            subIndex = 2;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.archive"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_BORROW_PATH
          ) {
            index = 1;
            subIndex = 3;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.borrow"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_BORROW_FORM_PATH
          ) {
            index = 1;
            subIndex = 3;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.borrow"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_CLEANUP_PATH
          ) {
            index = 1;
            subIndex = 4;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.cleanup"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH
          ) {
            index = 1;
            subIndex = 5;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.productfeature"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_NAMEDUSER_PATH
          ) {
            index = 1;
            subIndex = 6;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.nameduser"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USAGE_REPORT_ADVANCED_PATH
          ) {
            index = 0;
            subIndex = 1;
            document.title = LocaleUtility.getLocaleMessage(
              "advanceReports"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.REPORTS_PATH
          ) {
            index = 0;
            subIndex = 2;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.report"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH
          ) {
            index = 0;
            subIndex = 3;
            document.title = LocaleUtility.getLocaleMessage(
              "advanceReports"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH
          ) {
            index = 0;
            subIndex = 4;
            document.title = LocaleUtility.getLocaleMessage(
              "advanceReports"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USAGE_REPORT_GENERIC_PATH
          ) {
            index = 0;
            subIndex = 5;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.report"
            );
          } else if(this.routerLocation.pathname === FrontendPagePath.USAGE_PRODUCT_REPORT_PATH){
            index = 0;
            subIndex = 6;
            document.title =  LocaleUtility.getLocaleMessage("page.title.report");
          } else if(this.routerLocation.pathname === FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH){
            index = 0;
            subIndex = 7;
            document.title = LocaleUtility.getLocaleMessage("page.title.report");
          }
          else if (
            this.routerLocation.pathname ===
            FrontendPagePath.LICENSE_NAMEDUSER_PATH
          ) {
            index = 1;
            subIndex = 6;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.license.nameduser"
            );          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.RESERVATION_POOL_MANAGE_PATH
          ) {
            index = 3;
            subIndex = 0;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.reservation"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.COMMUTER_CHECKOUT_PATH
          ) {
            index = 4;
            subIndex = 0;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.commuter"
            );
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USER_MGMT_PATH
          ) {
            index = 5;
            subIndex = 0;
            document.title = LocaleUtility.getLocaleMessage("page.title.user");
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USER_GROUPS_PATH
          ) {
            index = 5;
            subIndex = 1;
            document.title = LocaleUtility.getLocaleMessage("page.title.group");
          } else if (
            this.routerLocation.pathname === FrontendPagePath.USER_TENANTS_PATH
          ) {
            index = 5;
            subIndex = 2;
            document.title = LocaleUtility.getLocaleMessage("page.title.tenant");
          }else if (
            this.routerLocation.pathname === FrontendPagePath.USER_TENANT_LICENSE_PATH
          ) {
            index = 1;
            subIndex = 7;
            document.title = LocaleUtility.getLocaleMessage("mtlicenses");
          }else if (
            this.routerLocation.pathname === FrontendPagePath.LICENSE_TENANT_FORM_PATH
          ) {
            index = 5;
            subIndex = 4;
            document.title = LocaleUtility.getLocaleMessage("mtlicenses");
          }else if (
            this.routerLocation.pathname.indexOf("/autopass/ls/config") > -1
          ) {
            index = 6;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.config"
            );
            if(this.routerLocation.pathname === FrontendPagePath.MAIN_CONFIG_PATH){
              subIndex = 0;
            } else if(this.routerLocation.pathname === FrontendPagePath.USER_CONFIG_PATH){
              subIndex = 1;
            } else if(this.routerLocation.pathname === FrontendPagePath.LDAP_CONFIG_PATH ||
              this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION){
              subIndex = 2;
            } else if(this.routerLocation.pathname === FrontendPagePath.USAGE_CONFIG_PATH){
              subIndex = 4;
            } else if(this.routerLocation.pathname === FrontendPagePath.DB_CONFIG_PATH){
              subIndex = 5;
            } else if(session !== undefined && session.ctFlag !== undefined && session.ctFlag.value && this.routerLocation.pathname === FrontendPagePath.CONTROL_TOWER_PATH){
              subIndex = 6;
            } else if(this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_ENTRIES){
              subIndex = 7;
            } else if(this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_SUBNOTIFICATION){
              subIndex = 8;
            } else if(this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_AUTH_TOKEN){
              subIndex = 9;
            } else if(this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_SAML_ENTRIES){
              if(setSaml)
                subIndex = 0;
              else
                subIndex = 10;
            } else if(this.routerLocation.pathname === FrontendPagePath.APP_CONFIG_AUDITS){
              subIndex = 11;
            } else {
              // subIndex = 0;
              // page = FrontendPagePath.MAIN_CONFIG_PATH;
              requestLogout();
              page = FrontendPagePath.LOGIN_PATH;
              this.routerLocation.pathname = page;
            }           
          } else if (
            this.routerLocation.pathname === FrontendPagePath.BUYMORE_PATH
          ) {
            index = 7;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.buymore"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.RESERVATION_FEATURE_BASE_PATH
          ) {
            index = 3;
            subIndex = 1;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.reservation"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH
          ) {
            index = 3;
            subIndex = 2;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.reservation"
            );
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.USER_UPDATE_FORM_PATH
          ) {
            index = 5;
            subIndex = -1;
            document.title = LocaleUtility.getLocaleMessage("page.title.user");
          } else if (
            this.routerLocation.pathname ===
            FrontendPagePath.COMMUTER_CHECKIN_PATH
          ) {
            index = 4;
            subIndex = 1;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.commuter"
            );
          }else if (
            this.routerLocation.pathname ===
            FrontendPagePath.USAGE_REPORT_GRAPHS_PATH
          ) {
            index = 0;
            document.title = LocaleUtility.getLocaleMessage(
              "page.title.usage"
            );
          } else {
            if(index > 0 && responseJson.loginStatus === "REDIRECT" && session !== undefined &&
          session.isadmin !== undefined && session.isadmin.value){
              index = 6;
              subIndex = 0;
              document.title = LocaleUtility.getLocaleMessage(
                "page.title.config"
              );
              page = FrontendPagePath.MAIN_CONFIG_PATH;
            } else {
              if(index > 0 && "true" === localStorage.getItem("changedMTFlag")) {
                localStorage.setItem("changedMTFlag", false);
                index = 6;
                subIndex = 0;
                document.title = LocaleUtility.getLocaleMessage(
                  "page.title.config"
                );
                page = FrontendPagePath.MAIN_CONFIG_PATH;
              } else {
                // index = 1;
                // subIndex = 1;
                // document.title = LocaleUtility.getLocaleMessage(
                //   "page.title.license.view"
                // );
                // page = FrontendPagePath.LICENSE_VIEW_PATH;
                requestLogout();
                page = FrontendPagePath.LOGIN_PATH;
                this.routerLocation.pathname = page;
              }
            }
          }

          if(session !== undefined && session.isidm !== undefined && session.isidm.value && 
            (idmpoints.some(v => (this.routerLocation.pathname.indexOf(v) > -1)) || (this.routerLocation.pathname.indexOf("/userManagement") > -1 && !this.routerLocation.pathname.endsWith("tenants")))){
            // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
            // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
            requestLogout();
            page = FrontendPagePath.LOGIN_PATH;
            this.routerLocation.pathname = page;
          } else if(
            //session !== undefined && queryTenant !== null && 
          tenantpoints.some(v => (this.routerLocation.pathname.indexOf(v) > -1)) &&  
          (session !== undefined && ((session.tenantNotFound !== undefined && session.tenantNotFound.value === "N") ||
          (tid !== "")))){
            // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
            // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
            requestLogout();
            page = FrontendPagePath.LOGIN_PATH;
            this.routerLocation.pathname = page;
          } else if(session !== undefined && session.roletype !== undefined && session.roletype.value !== undefined){
            roletype = session.roletype.value === 'product' || session.roletype.value === 'fullview';
            if(roletype && nonadminpoints.some(v => (this.routerLocation.pathname.indexOf(v) > -1))){
              // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
              // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
              requestLogout();
              page = FrontendPagePath.LOGIN_PATH;
              this.routerLocation.pathname = page;
            } else if(session.currentUser.value === 'aplsuser' && session.roletype.value === 'fullview' 
                        && (this.routerLocation.pathname.indexOf('/user/update') > -1)){
              // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
              // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
              requestLogout();
              page = FrontendPagePath.LOGIN_PATH;
              this.routerLocation.pathname = page;
            } else if(session.roletype.value === 'fullview' && viewonlypoints.some(v => (this.routerLocation.pathname.indexOf(v) > -1))){
              // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
              // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
              requestLogout();
              page = FrontendPagePath.LOGIN_PATH;
              this.routerLocation.pathname = page;
            } else if(session !== undefined && ((session.iseditable !== undefined && 
              session.iseditable.value !== 'DB') || (session.isSaml !== undefined && 
              session.isSaml.value)) && 
              this.routerLocation.pathname.indexOf(FrontendPagePath.USER_UPDATE_FORM_PATH) > -1){
              // page = FrontendPagePath.LICENSE_VIEW_PATH; index = 1; subIndex = 1; 
              // document.title = LocaleUtility.getLocaleMessage('page.title.license.view');
              requestLogout();
              page = FrontendPagePath.LOGIN_PATH;
              this.routerLocation.pathname = page;
            }
          } 

          if(setSaml && page !== FrontendPagePath.APP_CONFIG_SAML_ENTRIES){
            requestLogout();
            page = FrontendPagePath.SAML_CONFIG_LOGIN_PATH;
            this.routerLocation.pathname = page;
          }
          if(tenantMisMatch){
            requestLogout();
            page = FrontendPagePath.LOGIN_PATH;
            this.routerLocation.pathname = page;
            tid="";
          }

          var state = { index: index, subIndex: subIndex };
          if(tid !== undefined && tid !== '' && tid !== null && tid !== "null") {
             this.routerHistory({ pathname: page, search: createSearchParams('?tenant='+tid).toString(), state: state });  
          } else {
            this.routerHistory({ pathname: page, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
          }
          this.setState({
            activeIndex: index,
            subIndex: subIndex,
            container: container,
            session: session,
            user:
              this.routerLocation.state !== undefined
                ? this.routerLocation.state.user
                : undefined
          });
        });
    } else {
      var state = { index: index, subIndex: subIndex };
      if(tid !== undefined && tid !== "" && tid !== null && tid !== "null") {
        this.routerHistory({ pathname: page, search: createSearchParams('?tenant='+tid).toString(), state: state});
      } else {
        this.routerHistory({ pathname: page, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
      }
      this.setState({
        activeIndex: index,
        subIndex: subIndex,
        container: container
      });
    }
  //}
  }

  callback2(index, subIndex, page1, user) {
    var state = { index: index, subIndex: subIndex, user: user };
    if (this.state.session === undefined) {
      page1 = FrontendPagePath.LOGIN_PATH;
      state = {};
      this.routerLocation.state = state;
      this.routerHistory({ pathname: page1, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
      this.setState({
        activeIndex: index,
        subIndex: subIndex,
        user: undefined
      });
      return undefined;
    }

    this.routerHistory({ pathname: page1, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: state});
    var refreshState = 0;
    if (index !== 8) {
      /* DO NOT REFRESH ONCLICK OF ABOUT PAGE*/
      /* On same page tab/sub index, refresh the page */
      if (
        (this.state.activeIndex === 8 ||
          (this.state.activeIndex === index &&
            this.state.subIndex === subIndex)) &&
        this.state.refreshState === 0
      ) {
        Dispatcher.dispatch({
          type: "OK_LOADING_SPIN",
          payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        refreshPage(page1);
      } else if (
        (this.state.activeIndex === 8 ||
          (this.state.activeIndex === index &&
            this.state.subIndex === subIndex)) &&
        this.state.refreshState === 1
      ) {
        Dispatcher.dispatch({
          type: "OK_LOADING_SPIN",
          payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        refreshPage(page1);
      }
    }

    this.setState({
      activeIndex: index,
      subIndex: subIndex,
      refreshState: refreshState,
      user: user
    });
  }

  render() {
    let showPageHeader,
      //showPageFooter,
      defaultPage = LoginForm,
      sectionDiv;
    let tid = this.findTidFn(this.routerLocation);
    if (this.state.user !== undefined) {
      showPageHeader = 
      // (
      //   <Route
      //     element= {
              <Menu
                callbackMethod={this.callback2}
                user={this.state.user}
                session={this.state.session}
                setSaml={this.state.setSaml}
              />;
      //     }
      //   />
      // );
      // showPageFooter = (
      //   <div style={{ paddingLeft: "1em" }}>
      //     <Section margin="none" pad="none">
      //       <Footer justify="between">
      //         <Box direction="row" pad={{ between: "medium" }}>
      //           <Paragraph margin="none">
      //             {LocaleUtility.getLocaleMessage("about.copyright")}
      //           </Paragraph>
      //         </Box>
      //       </Footer>
      //     </Section>
      //   </div>
      // );
      defaultPage = LicenseView;
    } 
    // else {
    //   if (
    //     this.routerLocation !== undefined &&
    //     !(this.routerLocation.pathname.indexOf("/ls/login/user/reset/") > -1 ||
    //       this.routerLocation.pathname.indexOf("__IDM_SECURITY_VERIFY__") > -1 || 
    //       (AppStore.getState().saml !== undefined && AppStore.getState().saml) ||
    //       this.routerLocation.pathname.indexOf("/ls/login/tenant/password/create/") > -1 ||
    //       this.routerLocation.pathname.indexOf("/ls/setsaml") > -1)
    //   ) {
        
    //     if(tid !== undefined && tid !== '' && tid !== null && tid !== "null") {
    //       this.routerHistory.push({pathname: FrontendPagePath.LOGIN_PATH, search: '?tenant='+tid, state: {}});
    //     } else {
    //       this.routerHistory.push({pathname: FrontendPagePath.LOGIN_PATH, search: Utils.buildURLQueryParam(), state: {}});
    //     }
    //   }
    // }

    sectionDiv = { minHeight: window.innerHeight - 40 };
    return (
      <div lang="en-US" className="grommet grommetux-app bodyfont" style={{backgroundColor: "rgb(245, 247, 248)", top: "0px", left: "0px"}} aria-hidden="false">
      <Grommet centered={false} className="bodyfont" inline style={{backgroundColor: "#F5F7F8"}} theme={theme}>
        {/* <Router> */}
          <AccessRoute callbackMethod={this.callback1} idm={this.state.idm} saml={this.state.saml} setSaml={true}>
            {/* <div style={sectionDiv}> */}
            <div className="App_Wrap">
              {showPageHeader} 
              <section className="sectionCls">
              <Routes>             
              {/* <Section pad="small" className="sectionCls"> */}
                  <Route
                    path={FrontendPagePath.SAML_CONFIG_LOGIN_PATH}
                    //element={props => <LoginForm {...props} idm={this.state.idm}  saml={this.state.saml} setSaml={true}/>}
                    element={<LoginForm idm={this.state.idm}  saml={this.state.saml} setSaml={true}/>}
                  />
                  <Route
                    path={FrontendPagePath.LOGIN_PATH}
                    //element={props => <LoginForm {...props} idm={this.state.idm}  saml={this.state.saml}></Login>}
                    element={<LoginForm idm={this.state.idm}  saml={this.state.saml} />}
                  />
                  <Route
                    path={FrontendPagePath.USER_RESET_PATH}
                    element={<LoginForm />}
                  />
                  <Route
                    path={FrontendPagePath.RESET_PATH}
                    element={<LoginForm />}
                  />
                  <Route
                    path={FrontendPagePath.TENANT_CREATE_PWD_PATH}
                    element={<LoginForm />}
                  />
                  <Route

                    path={FrontendPagePath.USAGE_PATH}
                    element={<UsageReportsList />}
                  />
                  <Route

                    path={FrontendPagePath.LICENSE_PATH}
                    element={<LicenseHome />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_VIEW_PATH}
                    element={<LicenseView />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_ARCHIVE_PATH}
                    element={<ArchivedLicense />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_DETAIL_PATH}
                    element={<LicenseDetail />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_CLEANUP_PATH}
                    element={<LicenseCleanup  />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_PRODUCTFEATURE_PATH}
                    element={<ProductFeature />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_NAMEDUSER_PATH}
                    element={<NamedUserLicense />}
                  />
                  <Route

                    path={FrontendPagePath.LICENSE_BORROW_PATH}
                    element={<LicenseBorrow />}
                  />
                  <Route
                    path={FrontendPagePath.LICENSE_BORROW_FORM_PATH}
                    element={<LicenseBorrowForm />}
                  />
                  <Route
                    path={FrontendPagePath.REPORTS_PATH}
                    element={<Report/>}
                  />
                  <Route
                    path={FrontendPagePath.RESERVATION_POOL_MANAGE_PATH}
                    element={<PoolManagement />}
                  />
                  <Route
                    path={FrontendPagePath.RESERVATION_FEATURE_BASE_PATH}
                    element={<FeatureBaseReservation />}
                  />
                  <Route
                    path={FrontendPagePath.RESERVATION_PRODUCT_BASE_PATH}
                    element={<ProductBaseReservation />}
                  />
                  <Route
                    path={FrontendPagePath.COMMUTER_CHECKOUT_PATH}
                    element={<CommuterCheckout />}
                  />
                  <Route
                    path={FrontendPagePath.COMMUTER_CHECKIN_PATH}
                    element={<CommuterCheckin />}
                  />
                  <Route
                    path={FrontendPagePath.USER_UPDATE_FORM_PATH}
                    element={<UserUpdate />}
                  />
                  <Route

                    path={FrontendPagePath.USER_MGMT_PATH}
                    element={<UsersManagmentHome />}
                  />
                  <Route
                    path={FrontendPagePath.USER_GROUPS_PATH}
                    element={<UserGroupManagmentHome />}
                  />
                  <Route
                    path={FrontendPagePath.CONFIG_PATH}
                    element={<MainConfiguration />}
                  />
                  <Route
                    path={FrontendPagePath.BUYMORE_PATH}
                    element={<BuymoreHome session={this.state.session}/>}
                    
                  />
                  <Route
                    path={FrontendPagePath.MAIN_CONFIG_PATH}
                    element={<MainConfiguration />}
                  />
                  <Route
                    path={FrontendPagePath.USER_CONFIG_PATH}
                    element={<UserConfig />}
                  />
                  <Route
                    path={FrontendPagePath.LDAP_CONFIG_PATH}
                    element={<LDAPConfig />}
                  />
                  <Route
                    path={FrontendPagePath.IDM_CONFIG_PATH}
                    element={<IDMConfig />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_CONFIG_PATH}
                    element={<UsageHubConfig />}
                  />
                  <Route
                    path={FrontendPagePath.DB_CONFIG_PATH}
                    element={<DataBaseConfig />}
                  />
                  <Route
                    path={FrontendPagePath.APP_LOGS_CONFIG}
                    element={<AppLogs  />}
                  />
                  <Route
                    path={FrontendPagePath.APP_CONFIG_ENTRIES}
                    element={<ConfigEntries />}
                  />
                  <Route
                    path={FrontendPagePath.APP_CONFIG_SAML_ENTRIES}
                    element={<Saml />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_LIST_PATH}
                    element={<UsageReportsList />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_LIST_SEARCH_PATH}
                    element={<UsageReportsListSearch />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_GRAPHS_PATH}
                    element={<UsageReportGraphs />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_ADVANCED_PATH}
                    element={<AdvancedUsageReports />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_TOKEN_CAL_PATH}
                    element={<TokenCal />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_INSTANT_ON_PATH}
                    element={<PerInstanceDetail />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_REPORT_GENERIC_PATH}
                    element={<GenericReporting />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_TENANT_WISE_REPORT_PATH}
                    element={<UsageTenantWiseReport />}
                  />
                   <Route
                    path={FrontendPagePath.CONTROL_TOWER_PATH}
                    element={<ControlTower />}
                  />
                  <Route
                    path={FrontendPagePath.USER_TENANTS_PATH}
                    element={<TenantNames />}
                  />
                  <Route
                    path={FrontendPagePath.APP_CONFIG_SUBNOTIFICATION}
                    element={<SubNotification />}
                  />
                  <Route
                    path={FrontendPagePath.APP_CONFIG_AUTH_TOKEN}
                    element={<ServerAuthToken />}
                  />
                  <Route
                    path={FrontendPagePath.APP_CONFIG_AUDITS}
                    element={<Audits />}
                  />
                   <Route

                    path={FrontendPagePath.USER_TENANT_LICENSE_PATH}
                    element={<TenantLicense />}
                  />
                   <Route
                    path={FrontendPagePath.LICENSE_TENANT_FORM_PATH}
                    element={<LicenseTenantForm />}
                  />
                  <Route
                    path={FrontendPagePath.USAGE_PRODUCT_REPORT_PATH}
                    element={<ProductUsage />}
                  />
                  <Route 
                    //element={props => <LoginForm {...props} idm={this.state.idm}  saml={this.state.saml} />} /> 
                    element={<LoginForm idm={this.state.idm}  saml={this.state.saml} />} />
                </Routes>
              {/* </Section> */}
              </section>
            </div>
            <div id="notificationDiv" style={{float: "left"}}>
              {/* <Section pad="none"> */}
                <AppNotification />
              {/* </Section> */}
            </div>
          </AccessRoute>
        {/* </Router> */}
        {/* {showPageFooter} */}
        <AlertModal />
        <ConfirmModal styleProps={{top: "45%", overflowY: "auto"}}/>
       </Grommet>
       </div>
    );
  }
}
/**
 * Added code to hidden the drop down on scroll of the window
 */
window.onscroll = function () {
    var htmlCollection = document.getElementsByClassName("grommet grommetux-drop");
    if(htmlCollection !== undefined && htmlCollection.length > 0) {
      var el = htmlCollection[0];
      if(el !== undefined) {
        el.click();
      }
    }
};

export default withRouter(App);
