import { Component } from "react";
import withRouter from "./withRouter";

class AccessRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 1, secondCount: 1 };
  }

  callback1(loca, hist) {
    this.routerLocation = loca;
    this.routerHistory = hist;
    if (this.routerLocation.pathname === "/sublink2") {
      this.setState({ activeIndex: 1 });
    }
  }

  componentDidMount() {
    this.props.callbackMethod(
      this.props.match,
      this.props.location,
      this.props.navigate
    );
  }

  componentDidUpdate() {
    // this.props.callbackMethod(
    //   this.props.match,
    //   this.props.location,
    //   this.props.history
    // );
  }

  componentWillUnmount() {}

  render() {
    return this.props.children;
  }
}

//export default withRouter(AccessRoute);
export default withRouter(AccessRoute);
