import React, { Component, useState } from "react";
import AnnotatedMeterGraph from "./AnnotatedMeterGraph";
import TableGrid from "../../../components/commons/table/APTable";
import * as FetchHelper from "../../../utils/FetchHelper";
import APTableStore from "../../../flux/UsageHubConfigurationStore";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import SpinningNotification from '../../generic/SpinningNotification';
import ClientInfoPopup from "./ClientInfoPopup";
import ExecutedByInfo from "./ExecutedByInfo";
import * as ValidationUtility from "../../../utils/ValidationUtility";
import { Button } from "grommet/components/Button";
import { Anchor } from 'grommet/components/Anchor';
import ReactSpeedometer from "react-d3-speedometer";
import Value from '../../../components/generic/Value';
import { Meter } from 'grommet/components/Meter';
import { Box, Stack, Text } from 'grommet';
import { Legend } from '../../commons/grommet/Legend';
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as Util from "../../../utils/Util";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import * as Utils from "../../../utils/Util";
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
let highlight= false;
class InUseGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: {
                required: true,
                style: "default",
                totalCount: 0,
                pageNumber: 1,
                pageSize: FetchHelper.getPageSize(),
                sortBy: "serverLicenseTable.licenseStartDate",
                sortOrder: "DESC"
            },
            selectedIds: [],
            defaultSelIds: [],
            loadedData: false,
            executedByMap: "",
            clientInfoMap: "",
            clientInfoObj: "",
            executedByInfoObj: "",
            defaultSelIdsToValidate:[],
            sortIndex: 0,
            sortAscending: false,
            inUsePercentage: 0,
            totalCapacity: 0,
            poolId: "0",
            groupstotalcapacity: 0,
            poolWiseSeries: [],
            checkoutSeries: [],
            licensetotalcapcity: 0,
            exportHistoryRecordSize: 0,
            filteredgrpname: "",
            grptotalcapacity: "",
            reservedcapacity: "",
            downloadLicMap: {},
            popupTop: "800px",
            popupLeft: "550px",
            session: undefined,
            showPoolWiseToolTip: false,
            poolWiseToolTipPoolName: "",
            poolWiseToolTipConsumed: "",
            poolWiseToolTipReserved: "",
            showCheckoutTypeToolTip: false,
            checkoutTypeToolTipPoolName: "",
            checkoutTypeToolTipConsumed: "",
            active: 0,
            label: "",
            newSeries:[]
        }
        this.findLabel = this.findLabel.bind(this);
        this.loadInUseTableGrid = this.loadInUseTableGrid.bind(this);
        this.onPaginationClicked = this.onPaginationClicked.bind(this);
        this.onClickClientInfo = this.onClickClientInfo.bind(this);
        this.onClickExecutedByInfo = this.onClickExecutedByInfo.bind(this);
        this.closeTootipAction = this.closeTootipAction.bind(this);
        this.onSortClicked = this.onSortClicked.bind(this);
        this.handleRevokeAction = this.handleRevokeAction.bind(this);
        this.setPoolId = this.setPoolId.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.downloadCommuterLicense = this.downloadCommuterLicense.bind(this);
        this.checkoutTypeToolTipAction = this.checkoutTypeToolTipAction.bind(this);
        this.buildHeadersForGrid = this.buildHeadersForGrid.bind(this);
        this.poolWiseToolTipAction = this.poolWiseToolTipAction.bind(this);
        this.setActive = this.setActive.bind(this);
        this.setHighlight = this.setHighlight.bind(this);
        this.setLabel = this.setLabel.bind(this);
        this.onHoverFunction = this.onHoverFunction.bind(this);
    }

    setActive(value){
        this.setState({active: value});
    }

    setHighlight(value){
        highlight = value;
    }

    setLabel(value){
        this.setState({label: value});
    }

    onHoverFunction(over, value, label){
        if(!over){
            this.setState({showCheckoutTypeToolTip:false, showPoolWiseToolTip: false});
        } else {
            this.checkoutTypeToolTipAction(label);
            this.poolWiseToolTipAction(label);
        }
        this.setActive(over ? value : 0);
        this.setLabel(over ? label : undefined);
    }

    poolWiseToolTipAction(index) {
        if(index !== undefined) {
            if(this.state.poolWiseSeries.length > 0) {
                Object.entries(this.state.poolWiseSeries).map(([i, obj]) => {
                    if(obj.label === index) {
                        this.setState({showPoolWiseToolTip: true});
                        let percentage = 0;
                        if(Number(obj.grpcapacity) > 0) {
                            percentage = Number(obj.value)/Number(obj.grpcapacity)*100;
                        }
                        let checkValue = (percentage%1);
                        if(Number(checkValue) !== 0) {
                            if(Number(checkValue) !== 0.0) {
                                percentage = percentage.toFixed(0);
                            } else {
                                percentage = percentage.toFixed(1);
                            }
                        }
                        this.setState({poolWiseToolTipPoolName: obj.label+" ("+percentage+"%)"});
                        this.setState({poolWiseToolTipConsumed: obj.value});
                        this.setState({poolWiseToolTipReserved: obj.grpcapacity});
                    }
                    return "";
                });
            }
        } else {
            this.setState({showPoolWiseToolTip: false});
        }
    }
    checkoutTypeToolTipAction(index) {
        if(index !== undefined) {
            if(this.state.checkoutSeries.length > 0) {
                Object.entries(this.state.checkoutSeries).map(([i, obj]) => {
                    if(obj.label === index) {
                        this.setState({showCheckoutTypeToolTip: true});
                        let percentage = 0;
                        if(Number(obj.totalCheckoutCapacity) > 0) {
                            percentage = Number(obj.value)/Number(obj.totalCheckoutCapacity)*100;
                        }
                        let checkValue = (percentage%1);
                        if(Number(checkValue) !== 0) {
                            if(Number(checkValue) !== 0.0) {
                                percentage = percentage.toFixed(0);
                            } else {
                                percentage = percentage.toFixed(1);
                            }
                        }
                        this.setState({checkoutTypeToolTipPoolName: obj.label+" ("+percentage+"%)"});
                        this.setState({checkoutTypeToolTipConsumed: obj.value});
                    }
                    return "";
                });
            }
        } else {
            this.setState({showCheckoutTypeToolTip: false});
        }
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    onRowClicked(keys, event) {
        this.setState({selectedIds: keys});
        this.setState({defaultSelIds: keys})
    }
    handleRevokeAction() {
        if(this.state.selectedIds.length > 0) {
            let revokeIds = [];
            let params = {};
            let featInfo = localStorage.getItem("featureId:Version");
            let licenseModel = localStorage.getItem("licenseModel");
            this.state.defaultSelIdsToValidate.map(id=>{
                if(this.state.selectedIds.indexOf(id) === -1) {
                    revokeIds.push(id);
                }
                return "";
            });
            this.state.selectedIds.map(id=>{
                if(this.state.defaultSelIdsToValidate.indexOf(id) === -1) {
                    revokeIds.push(id);
                }
                return "";
            });
            let featArr = featInfo.split(":");
            params["pagination"] = this.state.pagination;
            params["sortIndex"] = this.state.sortIndex;
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["groupId"] = this.state.poolId;
            params["minCapacity"] = "0";
            params["licenseModel"] = licenseModel;
            params["showall"] = "false";
            if(this.state.poolId !== "0") {
                params["filterPool"] = "true";
            }
            if(revokeIds.length > 0) {
                params["serverLicenses"] = revokeIds.join();
                FetchHelper.revokeInuseRecords(this.context.intl, params);
                this.setState({enableRevokeBtn: false});
                this.setState({enablePurgeBtn: false});
                this.setState({loadedData: false});
                this.setState({selectedIds: []});
            } else {
                ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.revoke.alert"), "USAGE_REPORTS_PAGE_ERROR");
            }
        } else {
            ValidationUtility.showErrorMessage(LocaleUtility.getLocaleMessage("usage.search.revoke.alert"), "USAGE_REPORTS_PAGE_ERROR");
        }
    }
    componentDidMount() {
        APTableStore.on("change", this.loadInUseTableGrid);
        APTableStore.on("change", this.props.listPoolNamesAction);
        APTableStore.on("change", this.props.callToExportRequest);
        this.setState({poolId: this.props.selectPoolId+""});
        this.findData(this.state.sortIndex, this.state.pagination, 
            this.props.selectPoolId, this.props.instanceId);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.loadInUseTableGrid);
        APTableStore.removeListener("change", this.props.listPoolNamesAction);
        APTableStore.removeListener("change", this.props.callToExportRequest);
    }
    onClickClientInfo(key, event) {     
        this.setState({executedByInfoObj: ""});
        let clientObj = this.state.clientInfoMap[key];
        this.setState({clientInfoObj: clientObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    onClickExecutedByInfo(key, event) {
        this.setState({clientInfoObj: ""});
        let executedObj = this.state.executedByMap[key];
        this.setState({executedByInfoObj: executedObj});
        this.setState({popupLeft: event.pageX+"px"});
        this.setState({popupTop: event.pageY+"px"});
    }
    closeTootipAction() {
        this.setState({clientInfoObj: ""});
        this.setState({executedByInfoObj: ""});
    }
    onSortClicked(index, order, header) {
        let sortAsc = this.state.sortAscending;
        let sortOrder = "ASC"
        if(sortAsc) {
            sortOrder = "DESC"
        }
        let paginationTemp = this.state.pagination;
        let sortBy = "serverLicenseTable.licenseStartDate";
        if(index === 1) {
            sortBy = "serverLicenseTable.licenseExpiryDate";
        } else if(index === 2) {
            sortBy = "serverLicenseTable.licenseType";
        } else if(index === 3) {
            sortBy = "serverLicenseTable.units";
        } else if(index === 7) {
            sortBy = "serverLicenseTable.checkedoutrefcount";
        }
        paginationTemp.sortOrder = sortOrder;
        paginationTemp.sortBy = sortBy;
        this.findData(index, paginationTemp, this.state.poolId, this.props.instanceId);
    }
    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({ pagination: paginationTemp });
        this.findData(this.state.sortIndex, paginationTemp, this.state.poolId, this.props.instanceId);
    }
    setPoolId(val, usageStartDate, usageEndDate, instanceId) {
        this.setState({poolId: val});
        this.findData(this.state.sortIndex, this.state.pagination, val, instanceId);
    }
    downloadCommuterLicense(key) {
        let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
        if(this.state.downloadLicMap && this.state.downloadLicMap[key] && isAdmin) {
            let licInfo = this.state.downloadLicMap[key].split("####@@@@");
            let licId = licInfo[0];
            let checkoutId = licInfo[1];
            let request = {
                licenseId: licId,
                checkoutId: checkoutId
            }
            FetchHelper.downloadInuseLicense(request, this.context.intl);
        }
    }
    findData(sortIndex, pagination, poolId, instanceId) {
        let featInfo = localStorage.getItem("featureId:Version");
        let licenseModel = localStorage.getItem("licenseModel");
        if(featInfo) {
            this.setState({defaultSelIds: []});
            let featArr = featInfo.split(":");
            let params = {};
            params["pagination"] = pagination;
            params["sortIndex"] = sortIndex;
            params["featureId"] = featArr[0];
            params["featureVersion"] = featArr[1];
            params["groupId"] = poolId;
            params["minCapacity"] = "0";
            params["licenseModel"] = licenseModel;
            params["showall"] = "false";
            params["uniqueInstanceId"] = instanceId;
            if(poolId !== "0") {
                params["filterPool"] = "true";
            }
            FetchHelper.listUsageDataForGraph(this.context.intl, params, "");
            this.setState({loadedData: false});
        }
    }
    loadInUseTableGrid() {
        let pageType = APTableStore.getState().pageType;
        if(pageType === "inuse") {
            let groupColorPalette = APTableStore.getState().groupColorPalette;
            let licModel = localStorage.getItem("licenseModel");
            if(licModel === "6" || licModel === 6) {
                licModel = 6;
            }
            if(licModel === "7" || licModel === 7) {
                licModel = 7;
            }
            let requestMap = APTableStore.getState().requestMap;
            let totalCap = requestMap["totalcapacity"];
            let grpTotalCap = requestMap["grptotalcapacity"];
            let filteredGrpName = requestMap["filteredgrpname"];
            let resCap = requestMap["reservedcapacity"];
            this.setState({session: FetchHelper.getSession()});
            this.setState({grptotalcapacity: grpTotalCap});
            this.setState({filteredgrpname: filteredGrpName});
            this.setState({reservedcapacity: resCap});
            let groupstotalcap = requestMap["groupstotalcapacity"];
            if(groupstotalcap !== undefined) {
                groupstotalcap = Util.roundOfValue(groupstotalcap);
            }
            this.setState({groupstotalcapacity: groupstotalcap});
            //this.setState({poolWiseSeries: APTableStore.getState().poolSeries});
            if(APTableStore.getState().poolSeries){
                const newPoolSeries = APTableStore.getState().poolSeries.map(item => {
                    item["onHover"] = (over) => { this.onHoverFunction(over, item.value, item.label);}
                    item["colorIndex"] = groupColorPalette[item.colorIndex];
                    item["color"] = item.colorIndex;
                    return item;
                });
                this.setState({poolWiseSeries: newPoolSeries});
            }
            let checkSeries = [], newSeries = [];
            let commuterValue = Number(requestMap["licensecommutercapcity"] ? requestMap["licensecommutercapcity"] : 0);
            let liveValue = Number(requestMap["licenselivecapacity"] ? requestMap["licenselivecapacity"] : 0);
            let offlineCommuterValue = Number(requestMap["licenseremotecapcity"] ? requestMap["licenseremotecapcity"] : 0);
            let totalCheckoutCap = requestMap["licensetotalcapacity"];
            if(commuterValue > 0) {
                checkSeries.push({"label": "Commuter", "value": commuterValue, "colorIndex": "highlight-2", totalCheckoutCapacity: totalCheckoutCap}); 
                newSeries.push({label: "Commuter", value: commuterValue, color: "#00abf3", highlight: true, "onHover": (over) => { this.onHoverFunction(over, commuterValue, "Commuter");}});
            }
            if(liveValue > 0) {
                checkSeries.push({"label": "Live", "value": liveValue, "colorIndex": "statushighlight-3", totalCheckoutCapacity: totalCheckoutCap});
                newSeries.push({label: "Live", value: liveValue, color: "#fde159", highlight: true, "onHover": (over) => { this.onHoverFunction(over, liveValue, "Live");}});
            }
            if(offlineCommuterValue > 0) {
                checkSeries.push({"label": "Offline Commuter", "value": offlineCommuterValue, "colorIndex": "statushighlight-2", totalCheckoutCapacity: totalCheckoutCap});
                newSeries.push({label: "Offline Commuter", value: offlineCommuterValue, color: "#f48b34", highlight: true, "onHover": (over) => { this.onHoverFunction(over, offlineCommuterValue, "Offline Commuter");}});
            }
            this.setState({checkoutSeries: checkSeries, newSeries: newSeries});
             if(totalCheckoutCap !== undefined) {
                totalCheckoutCap = Util.roundOfValue(totalCheckoutCap);
            }
            this.setState({licensetotalcapcity: totalCheckoutCap});
            this.setState({inUsePercentage: requestMap["usage"]});
            if(this.state.poolId !== "0" && this.state.poolId !== 0) {
                this.setState({totalCapacity: resCap});
            } else {
                this.setState({totalCapacity: totalCap});
            }
            let sortIndex = APTableStore.getState().sortIndex;
            let sortAscending = APTableStore.getState().sortOrder;
            this.setState({sortIndex: sortIndex});
            if(sortAscending === "ASC") {
                this.setState({sortAscending: true});
            } else {
                this.setState({sortAscending: false});
            }
            this.setState({enablePurgeBtn: APTableStore.getState().enablePurgeBtn});
            this.setState({enableRevokeBtn: APTableStore.getState().enableRevokeBtn});
            this.setState({defaultSelIds: APTableStore.getState().defaultIds});
            this.setState({disabledRows: APTableStore.getState().defDisabledIds});
            this.setState({defaultSelIdsToValidate: APTableStore.getState().defaultIds});
            this.setState({clientInfoMap: APTableStore.getState().clientInfoMap});
            this.setState({executedByMap: APTableStore.getState().executedByMap});
            
            let downloadLicMap = {};
            let isAdmin = ValidationUtility.isAdminCheck(this.state.session);
            this.setState({downloadLicMap: downloadLicMap});
            let pagination = APTableStore.getState().pagination;
            pagination.required = true;
            pagination.style = "default";
            this.setState({pagination: pagination});
            this.setState({exportHistoryRecordSize: APTableStore.getState().exportHistoryRecordSize});
            var validFeatures = FetchHelper.getUserFeatures();
            var showDownload = false;
            //let featureData = APTableStore.getState().data.pdfFeature;
            validFeatures.then(data => {
                showDownload = (isAdmin || this.state.session.roletype === 'product');
                if(data !== undefined && data.indexOf(localStorage.getItem("featureId:Version")) === -1){
                    showDownload = false;
                } 
                this.setState({admin: showDownload});
                APTableStore.getState().reportSearchList.map(item => {
                    Object.entries(item).map(([key, value]) => { 
                        if(key === "licenseType") {
                            let licenseTypeLabel = item[key];
                            if(item[key] === 'Live'){
                                licenseTypeLabel = LocaleUtility.getLocaleMessage("label.license.type.live");
                            }else if(item[key] === 'Commuter'){
                                licenseTypeLabel = LocaleUtility.getLocaleMessage("label.license.type.commuter");
                            }

                            if((item["licenseTypeId"] === "201" || item["licenseTypeId"] === "301")
                                && item["checkedIn"] === 0 && (licModel !== 6 || licModel !== 7)) {
                                if(showDownload) {
                                    item[key]= <Anchor label={licenseTypeLabel} onClick={(event)=>this.downloadCommuterLicense(item["id"])}/>
                                } else {
                                    item[key] = licenseTypeLabel;
                                }
                                //item["hiddenCheckBox"] = "Y";
                                downloadLicMap[item["id"]] = item["licenseID"]+"####@@@@"+item["checkOutId"];
                            }else{
                                item[key] = licenseTypeLabel;
                            }
                        } else if(key === "groupsId"){
                            if(item[key] === 'Common Pool'){
                                item[key] = LocaleUtility.getLocaleMessage("label.common.pool");
                            }
                        }
                        return "";
                    });
                    return "";
                });
                let rowDataStore = APTableStore.getState().reportSearchList;
                this.setState({rowData: rowDataStore});
                ConfigurationUtils.hideLoadMask(this.context.intl);
                return "";
            });
        }
    }
    buildHeadersForGrid(licModel) {
        let headers = [];
        //let intl = this.context.intl;
        headers.push({
            columnName: "licenseStartDate",
            headerLabel: this.findLabel("usage.search.startdate.label"),
            width: "auto",
            columnKey: "licenseStartDate",
            sort: true,
        });
        if(licModel !== 7) {
        headers.push({
            columnName: "licenseExpiryDate",
            headerLabel: this.findLabel("usage.search.expdate.label"),
            href: false,
            width: "auto",
            sort: true,
        });
    }
     if(licModel === 7) {
        headers.push({
            columnName: "licenseExpiryDate",
            headerLabel: this.findLabel("usage.search.checkintime.label"),
            href: false,
            width: "auto",
            sort: true,
        });
    }
        if(licModel !== 6 || licModel !== 7) {
            headers.push({
                columnName: "licenseType",
                headerLabel: this.findLabel("usage.search.checkouttype.label") ,
                href: false,
                width: "auto",
                sort: true,
                columnKey: "id",
            });
        }
        if(licModel === 7) {
        headers.push({
            columnName: "units",
            headerLabel: this.findLabel("usage.graph.usage.label"),
            href: false,
            width: "auto",
            sort: true,
        });
        }
         if(licModel !== 7) {
        headers.push({
            columnName: "units",
            headerLabel: this.findLabel("usage.search.capacity.label"),
            href: false,
            width: "auto",
            sort: true,
        });
    }
        headers.push({
            columnName: "ipAddress",
            headerLabel: this.findLabel("usage.search.clientinfo.label"),
            width: "auto",
            href: true,
            columnKey: "id",
            onClick: this.onClickClientInfo,
        });
        headers.push({
            columnName: "checkedOutBy",
            headerLabel: this.findLabel("usage.search.executedby.label"),
            width: "auto",
            href: true,
            columnKey: "id",
            onClick: this.onClickExecutedByInfo,
        });
        if(licModel === 6 && licModel !== 7) {
            headers.push({
                columnName: "lastCheckedInDate",
                headerLabel: this.findLabel("usage.search.checkintime.label"),
                href: false,
                width: "auto",
            });
        }
    if(licModel !== 7) {
        headers.push({
            columnName: "groupsId",
            headerLabel: this.findLabel("usage.search.pool.label"),
            href: false,
            width: "auto",
        });
        headers.push({
            columnName: "checkedoutrefcount",
            headerLabel: this.findLabel("usage.search.sharedby.label"),
            href: false,
            width: "auto",
            sort: true,
        });
    
        //if(localStorage.getItem("isTokenBaseFeature") === "0") {

       // }
    }
        headers.push({
            columnName: "productFeature",
            headerLabel: this.findLabel("usage.search.product.info.label"),
            href: false,
            width: "auto"
        });
        // if(licModel === 7) {
        //     headers.push({
        //         columnName: "productFeature",
        //         headerLabel: this.findLabel("usage.search.product.info.label", intl),
        //         href: false,
        //         width: "auto"
        //     });
        // }
        return headers;
    }
    render() {
        //let intl = this.context.intl;
        let hideInfo = "tooltipContent tooltipContentHide";
        let hideExcutedInfo = "tooltipContent tooltipContentHide";
        let infoObj = "";
        let executeObj = "";
        let showCheckOutGraphType = 0;
        let showPoolWiseGraphType = 0;
        if(this.state.checkoutSeries.length > 1) {
            showCheckOutGraphType = 1;
        } else if(this.state.checkoutSeries.length === 1) {
             showCheckOutGraphType = 2;
             if(this.state.licensetotalcapcity === 0 || this.state.licensetotalcapcity === "0") {
                showCheckOutGraphType = 0;
             }
        }
        if(this.state.poolWiseSeries.length > 1) {
            showPoolWiseGraphType = 1;
        } else if(this.state.poolWiseSeries.length === 1) {
            showPoolWiseGraphType = 2;
             if(this.state.groupstotalcapacity === "0" || this.state.groupstotalcapacity === 0) {
                showPoolWiseGraphType = 0;
             }
        }
        let poolWiseTotal = 0;
        let poolWiseLegendSeries = [];
        if(this.state.poolWiseSeries.length > 0) {
            this.state.poolWiseSeries.map(obj => {
                let v = Number(Util.roundOfValue(obj.value));
                Object.entries(obj).map(([key, value])=>{
                    if(key === "value") {
                        obj[key] = v;
                    }
                    return "";
                });
                poolWiseTotal = poolWiseTotal + v;
                poolWiseLegendSeries.push({"label": obj.label, "value": "", "colorIndex": obj.colorIndex});
                return "";
            });
            poolWiseTotal = Util.roundOfValue(poolWiseTotal);
        }

        let checkoutTypeTotal = 0;
        let checkoutLegendsSeries = [];
        if(this.state.checkoutSeries.length > 0) {
            this.state.checkoutSeries.map(obj => {
                let v = Number(Util.roundOfValue(obj.value));
                Object.entries(obj).map(([key, value])=>{
                    if(key === "value") {
                        obj[key] = v;
                    }
                    return "";
                });
                checkoutTypeTotal = checkoutTypeTotal + v;
                checkoutLegendsSeries.push({"label": obj.label, "value": "", "colorIndex": obj.colorIndex});
                return "";
            });
            checkoutTypeTotal = Util.roundOfValue(checkoutTypeTotal);
        }
        if(this.state.clientInfoObj !== "") {
            infoObj = this.state.clientInfoObj;
            hideInfo = "tooltipContent tooltipContentshow";
        }
        if(this.state.executedByInfoObj !== "") {
            executeObj = this.state.executedByInfoObj;
            hideExcutedInfo = "tooltipContent tooltipContentshow";
        }
        let revokeBtnCls = "disanbleExcludeClientsSection";
        if(this.state.enableRevokeBtn) {
            revokeBtnCls = "enableExcludeClientsSection";
        }
        let usageText = "";
        let usageValue = 0;
        let poolHeightStyle = "60px";
        if(this.state.poolWiseSeries.length > 3){
             poolHeightStyle = "120px";
        }
        if(this.state.inUsePercentage === "NA") {
            usageText = this.findLabel("reportpage.usage")+"("+this.state.inUsePercentage+")";
        } else {
            if(this.state.inUsePercentage !== undefined) {
                usageText = this.findLabel("reportpage.usage")+"("+this.state.inUsePercentage+"%)";
            } else {
                usageText = this.findLabel("reportpage.usage")+"(0%)";
            }
            usageValue = Number(this.state.inUsePercentage);
            if(Number(usageValue) > 100) {
                usageValue = 100;
            }
        }
        let licModel = localStorage.getItem("licenseModel");
        let rowSelectionValue = 3;
        if(licModel === "6" || licModel === 6) {
            licModel = 6;
            rowSelectionValue = 0;
        }
         if(licModel === "7" || licModel === 7) {
            licModel = 7;
            rowSelectionValue = 0;
        }
        let poolWiseToolTipCls = "inuse-poolwise-tooltipContent inuse-poolwise-tooltipContentHide";
        if(this.state.showPoolWiseToolTip) {
            poolWiseToolTipCls = "inuse-poolwise-tooltipContent inuse-poolwise-tooltipContentshow";
        }
        let checkoutTypeToolTipCls = "inuse-poolwise-tooltipContent inuse-poolwise-tooltipContentHide";
        if(this.state.showCheckoutTypeToolTip) {
            checkoutTypeToolTipCls = "inuse-poolwise-tooltipContent inuse-poolwise-tooltipContentshow";
        }
        let headers = this.buildHeadersForGrid(licModel);
        let anchorPath = FrontendPagePath.MAIN_CONFIG_PATH;

        return (
            <div style={{float: "left", padding: "7px", marginBottom: "6px", paddingTop: "20px"}} className="inuse-cont-backgroud">
                <div style={{width: "100%", float: "left"}}>
                    <div style={{width: "30%", minHeight:"300px", float: "left", paddingLeft: "40px", borderRight: "1px solid #ccc", backgroundColor: "#FFFFFF"}}>
                        <table>
                            <tbody>
                            <tr>
                                <th align="center">
                        {licModel === 7?this.findLabel("usage.consumption.repusage.label"):this.findLabel("usage.graph.inuse.overalusage")}
                                    
                                </th>
                            </tr>
                            <tr>
                                <td align="center" style={{padding:"20px 0px"}} id="inuse_usage_graph_Id">
                                    { <ReactSpeedometer 
                                        minValue={0}
                                        maxValue={100}
                                        ringWidth={10}
                                        value= {usageValue}
                                        needleHeightRatio={0.85}
                                        segments={4}
                                        currentValueText={usageText}
                                        segmentColors={[
                                            "#d9e7b6",
                                            "#a2c34b",
                                            "#fdcf2d",
                                            "#eb6a42" 
                                        ]}
                                        width={250}
                                        height={160}
                                        needleColor="#0073E7"
                                    /> }
                                </td>
                            </tr>
                            {this.state.inUsePercentage >= 0 ? 
                                <tr>
                                    <td align="center">
                                        <span id="total_inuse_capacity_label_id">{this.findLabel("label.grid.col.capacity")} </span> : <span id="total_inuse_capacity_value_id" style={{fontWeight: "bold", color: "#0073E7"}}>{this.state.totalCapacity}</span>
                                    </td>
                                </tr>
                            : null }
                            </tbody>
                        </table>
                    </div>
                    <div style={{width: "30%", minHeight:"300px", float: "left", borderRight: "1px solid #ccc", backgroundColor: "#FFFFFF"}}>
                        <table>
                            <tbody>
                            <tr>
                                <th align="center">
                                    <span >
                                        {licModel === 7?this.findLabel("usage.consumption.activeusage.label"):this.findLabel("usage.graph.inuse.checkout")}
                                        </span>
                                </th>
                            </tr>
                            <tr>
                                <td align="center" style={{paddingTop:"20px"}} id="inuse_checkout_type_graph_id">
                                    <div className={checkoutTypeToolTipCls}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" style={{fontWeight: "bold"}}>{this.state.checkoutTypeToolTipPoolName}</td>
                                                </tr>
                                                <tr>
                                                    <td><span>{this.findLabel("consumedLabel")+" : "}</span></td>
                                                    <td className="chekout_type_tooltip">{this.state.checkoutTypeToolTipConsumed}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {showCheckOutGraphType === 1 ?
                                        // <AnnotatedMeterGraph
                                        //     size="small"
                                        //     type="circle"
                                        //     units=""
                                        //     max={checkoutTypeTotal}
                                        //     defaultMessage={this.findLabel("commuter.capacity.label")}
                                        //     series={this.state.checkoutSeries} 
                                        //     legend={false}
                                        //     onActive={this.checkoutTypeToolTipAction}
                                        //     totalValuePrecision={12}
                                        // />
                                        <Stack anchor="center">
                                        <Meter
                                        type="circle"
                                        background="light-2"
                                        values={this.state.newSeries} 
                                        max={checkoutTypeTotal}
                                        size="small"
                                        thickness="xsmall"
                                        />
                                        <Box align="center">
                                            <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                <Text size="medium" weight="bold">
                                                {(this.state.showCheckoutTypeToolTip ? this.state.active : checkoutTypeTotal)}
                                                </Text>
                                            </Box>
                                            <Text size="small">{(this.state.showCheckoutTypeToolTip ? this.state.label : this.findLabel("commuter.capacity.label"))}</Text>
                                        </Box>
                                        </Stack>

                                    : null }
                                    {showCheckOutGraphType === 2 ?
                                        // <Meter series={this.state.checkoutSeries}
                                        //     stacked={true}
                                        //     type='circle' 
                                        //     size="small"
                                        //     max={this.state.licensetotalcapcity}
                                        //     label={<Value 
                                        //         value={this.state.licensetotalcapcity}
                                        //         size="xsmall"
                                        //         toolTipText = {this.state.licensetotalcapcity}
                                        //         units={this.findLabel("commuter.capacity.label")}
                                        //          />
                                        //     }
                                        //     onActive={this.checkoutTypeToolTipAction}
                                        // />
                                        <Stack anchor="center">
                                        <Meter
                                        type="circle"
                                        background="light-2"
                                        values={this.state.newSeries} 
                                        max={this.state.licensetotalcapcity}
                                        size="small"
                                        thickness="xsmall"
                                        />
                                        <Box align="center">
                                            <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                <Text size="medium" weight="bold">
                                                {(this.state.showCheckoutTypeToolTip ? this.state.active : this.state.licensetotalcapcity)}
                                                </Text>
                                            </Box>
                                            <Text size="small">{(this.state.showCheckoutTypeToolTip ? this.state.label :this.findLabel("commuter.capacity.label"))}</Text>
                                        </Box>
                                        </Stack>
                                    : null }
                                    {showCheckOutGraphType === 0 ? 
                                    // <Meter type='circle'
									//   size='small'
                                    //   label={<Value value={0}
                                    //   //toolTipText = {0}
									//   units={this.findLabel("commuter.capacity.label")}
									//   size='xsmall' />}
									//   value={0}
									//  />
                                    <Stack anchor="center">
                                    <Meter
                                        type="circle"
                                        background="light-2"
                                        max={0}
                                        size="small"
                                        thickness="xsmall"
                                        />
                                        <Box align="center">
                                            <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                <Text size="medium" weight="bold">
                                                {this.state.licensetotalcapcity}
                                                </Text>
                                            </Box>
                                            <Text size="small">{this.findLabel("commuter.capacity.label")}</Text>
                                        </Box>
                                        </Stack>
                                    : null }
                                </td>
                            </tr>
                            <tr>
                                <td align="center" id="inuse_checkout_type_graph_legend_id">
                                    <Legend 
                                        series={checkoutLegendsSeries}
                                        total={false} 
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.poolId === "0" ? <div style={{width: "30%", minHeight:"300px", float: "left", paddingRight: "40px", backgroundColor: "#FFFFFF"}}>
                        <table>
                            <tbody>
                            <tr>
                                <th align="center">
                                    <span>{this.findLabel("usage.graph.inuse.poolwise")}</span>
                                </th>
                            </tr>
                            <tr>
                                
                                    <td align="center" style={{paddingTop:"20px"}} id="inuse_poolwise_graph_id">
                                        <div className={poolWiseToolTipCls}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="2" style={{fontWeight: "bold"}}>{this.state.poolWiseToolTipPoolName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{this.findLabel("consumedLabel")+" : "}</td>
                                                        <td className="chekout_type_tooltip">{this.state.poolWiseToolTipConsumed}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{this.findLabel("reservedLabel")+" : "}</td>
                                                        <td className="chekout_type_tooltip">{this.state.poolWiseToolTipReserved}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {licModel===7 && (<div style={{height: "190px", fontSize: "30px",paddingLeft: "120px",paddingTop: "130px"}}>N/A</div>)}
                                        {licModel!==7 && (showPoolWiseGraphType === 1) ?
                                            // <AnnotatedMeterGraph 
                                            //     size="small"
                                            //     type="circle"
                                            //     units=""
                                            //     max={poolWiseTotal}
                                            //     defaultMessage={this.findLabel("commuter.capacity.label")}
                                            //     series={this.state.poolWiseSeries} 
                                            //     legend={false}
                                            //     onActive={this.poolWiseToolTipAction}
                                            //     totalValuePrecision={12}
                                            // />
                                            <Stack anchor="center">
                                            <Meter
                                            type="circle"
                                            background="light-2"
                                            values={this.state.poolWiseSeries} 
                                            max={poolWiseTotal}
                                            size="small"
                                            thickness="xsmall"
                                            />
                                            <Box align="center">
                                                <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                    <Text size="medium" weight="bold">
                                                    {(this.state.showPoolWiseToolTip ? this.state.active : poolWiseTotal)}
                                                    </Text>
                                                </Box>
                                                <Text size="small">{(this.state.showPoolWiseToolTip ? this.state.label : this.findLabel("commuter.capacity.label"))}</Text>
                                            </Box>
                                            </Stack>
                                        : null }
                                        {licModel!==7 && (showPoolWiseGraphType === 2) ?
                                            // <Meter series={this.state.poolWiseSeries}
                                            //     stacked={true}
                                            //     type='circle' 
                                            //     size="small"
                                            //     max={poolWiseTotal}
                                            //     label={<Value 
                                            //         value={poolWiseTotal}
                                            //         toolTipText = {poolWiseTotal}
                                            //         size="xsmall"
                                            //         units={this.findLabel("commuter.capacity.label")}
                                            //         />
                                            //     }
                                            //     onActive={this.poolWiseToolTipAction}
                                            // />
                                            <Stack anchor="center">
                                            <Meter
                                            type="circle"
                                            background="light-2"
                                            values={this.state.poolWiseSeries} 
                                            max={poolWiseTotal}
                                            size="small"
                                            thickness="xsmall"
                                            />
                                            <Box align="center">
                                                <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                    <Text size="medium" weight="bold">
                                                    {(this.state.showPoolWiseToolTip ? this.state.active : poolWiseTotal)}
                                                    </Text>
                                                </Box>
                                                <Text size="small">{(this.state.showPoolWiseToolTip ? this.state.label : this.findLabel("commuter.capacity.label"))}</Text>
                                            </Box>
                                            </Stack>
                                        : null }
                                        {licModel!==7 && (showPoolWiseGraphType === 0) ? 
                                        // <Meter type='circle'
                                        // size='small'
                                        // label={<Value value={0}
                                        // //toolTipText = {0}
                                        // units={this.findLabel("commuter.capacity.label")}
                                        // size='xsmall' />}
                                        // value={0}
                                        // />
                                        <Stack anchor="center">
                                        <Meter
                                        type="circle"
                                        background="light-2"
                                        max={0}
                                        size="small"
                                        thickness="xsmall"
                                        />
                                        <Box align="center">
                                            <Box direction="row" align="center" pad={{ bottom: 'xsmall' }}>
                                                <Text size="medium" weight="bold">
                                                {0}
                                                </Text>
                                            </Box>
                                            <Text size="small">{this.findLabel("commuter.capacity.label")}</Text>
                                        </Box>
                                        </Stack>
                                        : null }
                                    </td>
                            </tr>
                        {licModel === 7?"":
                            <tr>
                                <td align="center" id="inuse_poolwise_graph_legend_id">
                                    <div style={{width: "98%", height: poolHeightStyle, overflowY: "auto", float: "left"}}>
                                        <Legend 
                                            series={poolWiseLegendSeries}
                                            total={false} 
                                        />
                                    </div>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    : <div style={{width: "33%", float: "left", padding: "10px", height: "376px", backgroundColor: "#FFFFFF"}}>
                        <table>
                            <tbody>
                            <tr>
                                <td id="inuse_total_pool_capacity_label_id" style={{width: "60%"}}>
                                    {this.findLabel("usage.graph.inuse.totalcap")} 
                                    <span id="inuse_total_pool_capacity_id" align="left" style={{color: "#0073E7", paddingLeft: "3px"}}>
                                        : {this.state.grptotalcapacity}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td id="inuse_rejected_pool_capacity_label_id" style={{width: "60%"}}>
                                    {this.findLabel("usage.graph.inuse.rescap")} {this.state.filteredgrpname}
                                </td>
                                <td id="inuse_rejected_pool_capacity_id" align="left" style={{color: "#0073E7", paddingLeft: "3px"}}>
                                    : {this.state.reservedcapacity}
                                </td>
                            </tr>
                            </tbody>
                          </table>
                    </div>}
                </div>
                <div className="tooltipMain">
                    <div id="inuse_client_info_popup_id" style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={hideInfo}>
                        <ClientInfoPopup 
                            ipAddress = {infoObj.ipAddress}
                            userName = {infoObj.userName}
                            hostID = {infoObj.hostID}
                            productCode = {infoObj.productCode}
                            clientUniqueId = {infoObj.clientUniqueId}
                            namedUserId = {infoObj.namedUserId}
                            applicationName = {infoObj.applicationName}
                            closeTootipAction={this.closeTootipAction}
                            showApplicationName = {true}
                        />
                    </div>
                    <div style={{"top": this.state.popupTop, "left": this.state.popupLeft}} className={hideExcutedInfo} id="inuse_executed_by_popup_id">
                        <ExecutedByInfo 
                            checkedOutBy = {executeObj.checkedOutBy}
                            checkedInBy = {executeObj.checkedInBy}
                            closeTootipAction={this.closeTootipAction}
                        />
                    </div>
                </div>
                <div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    <TableGrid id="inUseListGrid"
                        header={headers}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={rowSelectionValue}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        defaultSelectIds={this.state.defaultSelIds}
                        disabledRowIds={this.state.disabledRows}
                        rowKey="idRevokedId"
                        uncheckTheDisabled={true}
                        noDataFoundText={this.findLabel("no.items.found.message")}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                </div>
                <div id="inuse_export_limit_value_id" style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    {this.findLabel("usage.graph.export.limit.label")} {this.state.exportHistoryRecordSize}
                </div>
                {(licModel === 6 || licModel === 7) &&(
                    <div id="inuse_export_limit_value_id" style={{width: "100%", float: "left", paddingTop: "5px"}}>
                        {this.findLabel("label.click")} {<Anchor label={this.findLabel("usageNavigateHere")} href={anchorPath+Utils.buildURLQueryParam()}/>}
                        {this.findLabel("label.view.purg.config")}
                    </div>
                )}
                <div style={{width: "100%", float: "left", paddingTop: "5px"}}>
                    {(licModel !== 6 && licModel !== 7) && this.state.admin ? 
                        <div className="button_wrap_primary"><Button className={revokeBtnCls} id="usage_inuse_revokeBtn" label={this.findLabel("label.button.revoke")} plain={false} primary={true} onClick={this.handleRevokeAction} /></div>
                    : null }
                    {/*<span style={{padding: "5px"}}></span>
                    <Button id="usage_inuse_backBtn" label={this.findLabel("label.button.back", intl)} plain={false} secondary={true} onClick={this.props.backButtonAction} />*/}
                </div>
                <div>
                    <SpinningNotification centerPage={true} />
                </div>
            </div>
        )
    }
}
// InUseGraph.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default InUseGraph;