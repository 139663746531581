import ReduceStore from "./ReduceStore";

class ProductStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            productNameSelected: 'NONE',
            productNameSelectOptions: [],
            featureSelectOptions: [],
        };
    }
    reduce(action, state) {
         switch (action.type) {
            case "PRODUCT_NAME_LIST_FETCH_COMPLETE": {
                return {
                    ...state,
                    productNameSelected: action.payload.productSelected,
                    featureSelectOptions: [],
                    productNameSelectOptions: action.payload.selectData,
                    productNamesForTenant: action.payload.productNamesForTenant,
                    pageRefreshCall: action.payload.pageRefreshCall
                };
            }
            case "PRODUCT_FEATURE_LIST_FETCH_COMPLETE": {
                return {
                    ...state,
                    productNameSelected: action.payload.productName,
                    featureSelectOptions: action.payload.selectData,
                };
            } case "CUSTOM_METRIC_LIST_FETCH_COMPLETE": {
                return {
                    ...state,
                    customMetricOptions: action.payload.customMetric,
                };
            }
            default:
                return state;
        }
    }
}

export default new ProductStore();