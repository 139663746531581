import React, { Component } from "react";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import * as FetchHelper from "../../../utils/FetchHelper";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import TableGrid from "../../../components/commons/table/APTable";

//import { Button } from "grommet/components/Button";
import * as FrontendPagePath from  "../../../utils/FrontendPagePath";
import * as Utils from "../../../utils/Util";
import { Navigate } from 'react-router-dom';
import SpinningNotification from '../../generic/SpinningNotification';
import APTableStore from "../../../flux/APTableStore";
import { useIntl } from 'react-intl';
import { createSearchParams } from "react-router-dom";
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsageReportsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            pagination: null,
            productNameList: [],
            productNameSelValue: "",
            isSearchEnable: false,
            selectedIds: "",
            defaultSelectedIds: this.props.selectedRowsKey,
            isManageCommuterDone: false
        }
        this.findLabel = this.findLabel.bind(this);
        this.handleManageCommuter = this.handleManageCommuter.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.findGridDetails = this.findGridDetails.bind(this);
        this.resetSelectedIds = this.resetSelectedIds.bind(this);
    }
    findLabel(key, intl) {
        return LocaleUtility.getLocaleMessage(key);
    }
    componentDidMount() {
        APTableStore.on("change", this.findGridDetails);
        APTableStore.on("change", this.props.manageCommuterUpdates);
        APTableStore.on("change", this.resetSelectedIds);
    }
    componentWillUnmount() {
        APTableStore.removeListener("change", this.findGridDetails);
        APTableStore.removeListener("change", this.props.manageCommuterUpdates);
        APTableStore.removeListener("change", this.resetSelectedIds);
    }
    findGridDetails() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "usagepagelist") {
            this.setState({defaultSelectedIds: APTableStore.getState().selRows});
        }
    }
    onRowClicked(keys, event) {
        this.setState({selectedIds: keys});
        this.setState({defaultSelectedIds: keys});
    }
    resetSelectedIds() {
        if(APTableStore.getState() && APTableStore.getState().actionType === "resetProductFeaturesGrid") {
            this.setState({defaultSelectedIds: this.props.selectedRowsKey, selectedIds: ""});
        }
    }
    handleManageCommuter() {
        let submitIds = [];
        let defSelectedIds = this.state.defaultSelectedIds;
        let defDisabledIds = this.props.disabledRowsKey;
        let mapData = this.props.featureIdVersionMap;
        this.props.rowData.map(e => {
            if(e.licenseModel !== 6 || e.licenseModel !== 7) {
                if(e.commuter === 2 || e.commuter === 4) {
                    if(defSelectedIds.indexOf(e.featureId) === -1 && defDisabledIds.indexOf(e.featureId) === -1) {
                        submitIds.push(mapData[e.featureId]);
                    }
                } else if(e.commuter === 3) {
                    if(defSelectedIds.indexOf(e.featureId) > -1 && defDisabledIds.indexOf(e.featureId) === -1) {
                        submitIds.push(mapData[e.featureId]);
                    }
                }
            }
            return "";
        });
        if(submitIds.length > 0) {
            FetchHelper.manageCommuter(this.context.intl, submitIds.join());
        } else {
            ConfigurationUtils.showErrorMessage1("usageManageValidationMsg", this.context.intl);
        }
    }
    render() {
        //let intl = this.context.intl;
        let selIds = this.props.selectedRowsKey;
        let disIds = this.props.disabledRowsKey;
        if(this.state.selectedIds) {
            selIds = this.state.selectedIds;
        }
        if(this.state.isManageCommuterDone) {
            return <Navigate to={{pathname: FrontendPagePath.USAGE_REPORT_LIST_PATH, search: createSearchParams(Utils.buildURLQueryParam()).toString(), state: {}}}/>
        } else {
            return(
                <div>
                    <section pad="none" wrap={false} responsive={false} justify="center">
                            <div>
                                <TableGrid id="usage_report_list_grid"
                                    header={[
                                        {
                                            columnName: "featureIdVersionStr",
                                            headerLabel: this.findLabel("usageReportFeatIdVer"),
                                            width: "13%",
                                            columnKey: "featureIdVersionStr",
                                            href: true,
                                            onClick: this.props.onClickFeatureId,
                                            sort: true
                                        },
                                        {
                                            columnName: "featureDescription",
                                            headerLabel: this.findLabel("usageReportFeatDesc"),
                                            href: false,
                                            width: "auto",
                                            sort: true
                                        },
                                        {
                                            columnName: "availableCap",
                                            headerLabel: this.findLabel("usageReportAvailable") ,
                                            href: false,
                                            width: "10%",
                                            sort: true
                                        },
                                        {
                                            columnName: "issuedCap",
                                            headerLabel: this.findLabel("usageReportLive"),
                                            href: false,
                                            width: "8%",
                                            sort: true
                                        },
                                        {
                                            columnName: "commuteCap",
                                            headerLabel: this.findLabel("usageReportCommuter"),
                                            href: false,
                                            width: "12%",
                                            sort: true
                                        },
                                        {
                                            columnName: "totalCap",
                                            headerLabel: this.findLabel("usageReportTotal"),
                                            href: false,
                                            width: "8%",
                                            sort: true,
                                            enabledToolTip: true
                                        },
                                        {
                                            columnName: "totalInstalledLicenseCap",
                                            headerLabel: this.findLabel("usageReportCapacity"),
                                            href: false,
                                            width: "11%",
                                            sort: true
                                        }
                                    ]}
                                    rowData={this.props.rowData}
                                    pagination={this.props.pagination}
                                    rowSelection={3}
                                    isExportRequired={false}
                                    onSort={this.props.onSortClicked}
                                    onFilter={this.onFilterClicked}
                                    onPagination={this.props.onPaginationClicked}
                                    sortIndex={this.props.sortIndex}
                                    sortAscending={this.props.sortAscending}
                                    rowKey="featureId"
                                    onChangeCheckBoxCallback={this.onRowClicked}
                                    defaultSelectIds={selIds}
                                    disabledRowIds={disIds}
                                    childKey="dependentFeatureList"
                                    uncheckTheDisabled={true}
                                    noDataFoundText={LocaleUtility.getLocaleMessage("no.items.found.message")}
                                    itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.props.rowData.length})}
                                />
                            </div>
                            {/*<div>
                                <Button id="usage_list_manageBtn" label={this.findLabel("usageListManageBtn", intl)} plain={false} primary={true} onClick={this.handleManageCommuter} />
                            </div>*/}
                            <div>
                                <SpinningNotification centerPage={true} />
                            </div>
                    </section>
                </div>
            )
        }
        
    }
}
// UsageReportsView.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsageReportsView;