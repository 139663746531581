import ReduceStore from "./ReduceStore";
import Dispatcher from "./Dispatcher";

class SpinningStore extends ReduceStore {
    constructor() {
        super();
        this.state = {
            message: "",
            startSpin: false
        };
    }
    reduce(action, state) {
        if (action.type.endsWith("LOADING_SPIN")) {
            /* Close the active AppNotification component.*/
            // Dispatcher.dispatch({
            //     type: "NOTIFICATION_CLOSE"
            // });
            return { ...state, startSpin: true, message: action.payload };
        } else if (action.type.endsWith("SPIN_DONE")) {
            return { ...state, startSpin: false, message: action.payload };
        }
        return state;
    }
}

export default new SpinningStore();