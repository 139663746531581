import React, { Component } from "react";
import TableGrid from "../../../components/commons/table/APTable";
import Store from "../../../flux/APTableStore";
import { Button } from "grommet/components/Button";

import { Anchor } from 'grommet/components/Anchor';
import { DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import * as FetchHelper from "../../../utils/FetchHelper";
import Dispatcher from "../../../flux/Dispatcher";
import AddUser from "./AddUser";
import GroupSearch from "./GroupSearch";
import * as TableUtility from "../../../utils/TableUtility";
import * as LocaleUtility from "../../../utils/LocaleUtility";
import * as ConfigurationUtils from "../../../utils/ConfigurationUtils";
import {validateEmail, isNumber, alphanumericAndSpaceCheck, confirm} from "../../../utils/ValidationUtility";
import SectionWithTitle from "../../generic/SectionWithTitle";
import { TextInput } from 'grommet/components/TextInput';
import { Tabs } from "grommet/components/Tabs";
import { Tab } from "grommet/components/Tab";
import {Box} from "grommet/components/Box"
import { Form } from "grommet/components/Form";
import { FormField } from "grommet/components/FormField";
import { Search as SearchIcon } from 'grommet-icons';
import SpinningNotification from '../../generic/SpinningNotification';
import "../../../css/index.css";
//import { DocumentExcel as DocumentExcelIcon } from 'grommet-icons';
import { useIntl } from 'react-intl';
// var _Intl = useIntl();
// var _propTypes = require('prop-types');
// var _propTypes2 = _interopRequireDefault(_propTypes);
// var _Intl2 = _interopRequireDefault(_Intl);
// function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class UsersManagment extends Component {
    constructor(props) {
      super(props);
      this.state = {
        count: 1, 
        secondCount: 1,
        rowData: [],
        sortIndex: Store.getState().sortIndex,
        sortAscending: Store.getState().sortAscending,
        selectAllRows: false,
        userDetail: undefined,
        selectedRowKeys:['admin'],
        session:FetchHelper.getSession(true),
        pagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "createdtime",
            sortOrder: "ASC"
        },
        groupsPagination: {
            required: true,
            style: "default",
            totalCount: 0,
            pageNumber: 1,
            pageSize: FetchHelper.getPageSize(),
            sortBy: "",
            sortOrder: "ASC"
        },
        showCreatePopup:false,
        selectedUserGroups: []
    }

    this.refreshState = this.refreshState.bind(this);
    this.onSortClicked = this.onSortClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onRowClicked = this.onRowClicked.bind(this);
    this.handleUpdateButton=this.handleUpdateButton.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleSearchField = this.handleSearchField.bind(this);
    this.searchGroup = this.searchGroup.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.closeGroupSearchButton = this.closeGroupSearchButton.bind(this);
    this.handleAddButton = this.handleAddButton.bind(this);
    this.showGroupList = this.showGroupList.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
    this.handleUserCancelButton = this.handleUserCancelButton.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.onPaginationClicked = this.onPaginationClicked.bind(this);
    this.onGroupPaginationClicked = this.onGroupPaginationClicked.bind(this);
    this.onGroupRowClicked = this.onGroupRowClicked.bind(this);
    this.removeGroupAction = this.removeGroupAction.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);
    this.onConfirmResetPwd = this.onConfirmResetPwd.bind(this);
    this.onConfirmRemoveGroup = this.onConfirmRemoveGroup.bind(this);
    this.onConfirmStatusChange = this.onConfirmStatusChange.bind(this);
    this.onConfirmRemoveAction = this.onConfirmRemoveAction.bind(this);
    }
  
    componentDidMount() {
        let loadSpinAction = "LICENSE_FETCH_INPROGRESS_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });
        FetchHelper.getAllUsers();
        Store.on("change", this.refreshState);
    }

    componentWillUnmount() {
        Store.removeListener("change", this.refreshState);
    }

    onPaginationClicked = (pageNumber, pageSize) => {
        let paginationTemp = this.state.pagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({pagination: paginationTemp});
        let request = {
            "pagenation": paginationTemp
        }
        FetchHelper.getAllUsers(request);
    }

    onGroupPaginationClicked(pageNumber, pageSize) {
        let paginationTemp = this.state.groupsPagination
        paginationTemp.pageNumber = pageNumber;
        paginationTemp.pageSize = pageSize;
        this.setState({groupsPagination: paginationTemp});
    }
    onGroupRowClicked(keys, event) {
        this.setState({selectedUserGroups: keys});
    }

    onSortClicked(columnName, order, header) {
         TableUtility.sort(columnName, order, header);
    }

    onConfirmDelete(flag, key){
        if(flag === 1){
            let loadSpinAction = "DELETING_USER_LOADING_SPIN";
            Dispatcher.dispatch({
                type: loadSpinAction,
                payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
            });
            let request = {
                "email": key,
                "pagenation": this.state.pagination
            }
            FetchHelper.deleteUser(request);
            Dispatcher.dispatch({
                type: "NOTIFICATION_CLOSE"
            });
        }
    }

    onDeleteClicked(key, event){
        confirm(LocaleUtility.getLocaleMessage("um.alert8", {user: key}), this.onConfirmDelete, key);
    }

    onRowClicked(keys, event){
        this.state.rowData.map(item=>{
            if(item.userName === keys[keys.length-1])
             {
                 let statusString="";               
                if(item.status !== undefined && item.status==="active"){
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.active");
                }else if(item.status !== undefined && item.status==="inactive") {
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.inactive");
                }
                let userGroupsList = [];
                if(item.userGroupList !== undefined) {
                    item.userGroupList.map((item, index) => {
                        userGroupsList.push({groupName: item.groupName});
                        return "";
                    });
                }
                this.setState({
                    selectedRowKeys: keys,
                    username: item.userName,
                    firstName:item.firstName !== undefined ? item.firstName: '',
                    lastName:item.lastName !== undefined ? item.lastName: '',
                    email:item.email !== undefined ? item.email: '',
                    statusLocalize:statusString,
                    status:(item.status !== undefined)?item.status:'',
                    pwdResetStatus: (item.status == 'inactive' && item.pwdResetType !== undefined && item.pwdResetType == 'FL' && item.lastloggedintime == "") ? true:false,
                    groupList:item.userGroupList,
                    userGroupsList: userGroupsList
                })
             }
             return "";
        });
        this.setState({errorcode: []});
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
    }

  callback1(loca, hist) {
      if (this.routerLocation.pathname === "/sublink2") {
        this.setState({ activeIndex: 1 });
      }
    }

    refreshState() {       
        var user = "";
        if(Store.getState().selectedUser===undefined || Store.getState().selectedUser === "" ){
            user = "admin";
        } else {
            user = Store.getState().selectedUser;
        }

        if(this.state.groupSearch && Store.getState().groupData !== undefined && Store.getState().groupData.length > 0){
            this.setState({
                //groupSearch:true,
                groupData: Store.getState().groupData
            });
            user = this.state.username;
        } else {
            if(this.state.groupSearch){
                user = this.state.username;
                this.setState({
                    groupSearch:false,
                });
                Dispatcher.dispatch({
                    type: "NO_GROUPS_ALERT_SHOW_TITLE_MODAL",
                    payload: {content: LocaleUtility.getLocaleMessage("um.nogroupfound"), 
                                title: LocaleUtility.getLocaleMessage("um.assigngroups")}
                });
            }
        }

        var data = FetchHelper.getSession(true);
        data.then(session => {
            this.setState({session: session});
        })
        .catch(error => {
            // handle error
        });
        if(this.state.selectedUserGroups !== undefined && this.state.selectedUserGroups.length > 0) {
            ConfigurationUtils.showSuccessMessage("disassociate.message", this.context.intl);
        }
        Store.getState().userData.map(item=>{
            if(item.userName === user)
            {
                let statusString="";               
                if(item.status !== undefined && item.status==="active"){
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.active");
                }else if(item.status !== undefined && item.status==="inactive") {
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.inactive");
                }
                let pagination = Store.getState().userMgpagination;
                pagination.required = true;
                pagination.style = "default";
                let userGroupsList = [];
                if(item.userGroupList !== undefined) {
                    item.userGroupList.map((item, index) => {
                        userGroupsList.push({groupName: item.groupName});
                        return "";
                    });
                }
                this.setState({
                    selectedRowKeys: [user],
                    username: item.userName,
                    firstName:item.firstName !== undefined ? item.firstName: '',
                    lastName:item.lastName !== undefined ? item.lastName: '',
                    email:item.email !== undefined ? item.email: '',
                    statusLocalize:statusString,
                    status:(item.status !== undefined)?item.status:'',
                    pwdResetStatus: (item.status == 'inactive' && item.pwdResetType !== undefined && item.pwdResetType == 'FL' && item.lastloggedintime == "") ? true:false,
                    groupList: item.userGroupList,
                    userGroupsList: userGroupsList,
                    rowData: Store.getState().userData,
                    sortIndex: Store.getState().sortIndex,
                    sortAscending: Store.getState().sortAscending,
                    productNameSelected: Store.getState().productNameSelected,
                    productNameSelectOptions: Store.getState().productNameSelectOptions,
                    errorcode: [],
                    session: FetchHelper.getSession(true),
                    pagination: pagination,
                    selectedUserGroups: []
                })
            }
            return "";
        });
        
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        }); 
        Dispatcher.dispatch({
            type: "LOAD_SPIN_DONE",
            payload: ''
        }); 
    }

onConfirmUpdate(flag){
    if(flag === 1){
        const updatePayload = {
            username: this.state.username,
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            email:this.state.email,
            "pagenation": this.state.pagination
        };
        FetchHelper.updateUser(updatePayload);
    }
}

handleUpdateButton(event){
    event.preventDefault();
    var elements = event.target.elements;
    //const errors = Object.assign([], this.state.errorcode);
    const errors = [];
    var flag = true;
    if(errors.length > 0){
        flag = false;
    } else if(elements.email.value === ''){
        let index = errors.indexOf(elements.email.name)
        if (index === -1) {
            errors.push(elements.email.name);
        }
        flag = false;
    } 
    if (elements.lastName.value !== '' && !alphanumericAndSpaceCheck(elements.lastName.value)){
        flag = false;
        let index = errors.indexOf(elements.lastName.value)
        if (index === -1)
            errors.push('invalidLName');
    }  
    if (elements.firstName.value !== '' && !alphanumericAndSpaceCheck(elements.firstName.value)){
        flag = false;
        let index = errors.indexOf(elements.firstName.value)
        if (index === -1)
            errors.push('invalidFName');
    }

    if(flag === true){
        confirm(LocaleUtility.getLocaleMessage("um.alert6", {mail: this.state.username}), this.onConfirmUpdate);
    } else {
        Dispatcher.dispatch({
            type: "USER_UPDATE_ERROR",
            payload: LocaleUtility.getLocaleMessage("generic.form.fields.error")
        });
        this.setState({errorcode: errors});
    }
}

handleCancelButton(){
    this.state.rowData.map(item=>{
        if(item.userName === this.state.username)
            {
                let statusString="";               
                if(item.status !== undefined && item.status==="active"){
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.active");
                }else if(item.status !== undefined && item.status==="inactive") {
                    statusString = LocaleUtility.getLocaleMessage("label.user.status.inactive");
                }
            let userGroupsList = [];
            if(item.userGroupList !== undefined) {
                item.userGroupList.map((item, index) => {
                    userGroupsList.push({groupName: item.groupName});
                    return "";
                });
            }
            this.setState({
                username: item.userName,
                firstName:item.firstName !== undefined ? item.firstName: '',
                lastName:item.lastName !== undefined ? item.lastName: '',
                email:item.email !== undefined ? item.email: '',
                statusLocalize:statusString,
                status:(item.status !== undefined)?item.status:'',
                groupList:item.userGroupList,
                userGroupsList: userGroupsList
            })
            }
            return "";
    });
    this.setState({errorcode: []});
    Dispatcher.dispatch({
        type: "NOTIFICATION_CLOSE"
    });
}
exportExcel(e) {
    let mimetype = e.currentTarget.attributes.mimetype.value;
    FetchHelper.downloadUserOrGroupList("user", undefined, mimetype);
}

onConfirmStatusChange(flag){
    if(flag === 1){
        const updatePayload = {
            username: this.state.username,
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            email:this.state.email,
            status:this.state.status,
            "pagenation": this.state.pagination
        };
        FetchHelper.changeStatus(updatePayload); 
    }
}

changeStatus(){
    Dispatcher.dispatch({
        type: "NOTIFICATION_CLOSE"
    }); 

    if(!this.state.pwdResetStatus){
        let msg = LocaleUtility.getLocaleMessage("um.alert17", {user: this.state.username})
        if(this.state.status === 'inactive'){
            msg = LocaleUtility.getLocaleMessage("um.alert18", {user: this.state.username})
        }
        confirm(msg, this.onConfirmStatusChange)
    }
    
    //FetchHelper.getAllUsers();
}
handleFirstNameChange(event) {
    const errors = Object.assign([], this.state.errorcode);
    let index = errors.indexOf('firstName');
    if (event.target.value !== '' && isNumber(event.target.value)){
        if (index === -1)
            errors.push('firstName');
    } else {
        if (index !== -1) {
            errors.splice(index, 1);
        }
    }
    this.setState({errorcode: errors});
    this.setState({ firstName: event.target.value });
}
handleLastNameChange(event) {
    const errors = Object.assign([], this.state.errorcode);
    let index = errors.indexOf('lastName');
    if (event.target.value !== '' && isNumber(event.target.value)){
        if (index === -1)
            errors.push('lastName');
    } else {
        if (index !== -1) {
            errors.splice(index, 1);
        }
    }
    this.setState({errorcode: errors});
    this.setState({ lastName: event.target.value });
}

handleEmailChange(event) {
    const errors = Object.assign([], this.state.errorcode);
    let index = errors.indexOf('email');
    if (event.target.value === ''){
        if (index === -1)
            errors.push('email');
    } else {
        if (index !== -1) {
            errors.splice(index, 1);
        }

        index = errors.indexOf('invalidLength')
        if(event.target.name === 'email' && 
                !(event.target.value.length > 5 &&  event.target.value.length < 64)){
            if (index === -1) {
                errors.push('invalidLength');
            }
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
        index = errors.indexOf('invalidEmail')
        if(event.target.name === 'email' && 
               !validateEmail(event.target.value) ){
            if (index === -1) {
                errors.push('invalidEmail');
            }
        } else {
            if (index !== -1) {
                errors.splice(index, 1);
            }
        }
    }

    this.setState({errorcode: errors});
    this.setState({ email: event.target.value });
}

handleSearchField(e) {
    this.setState({ searchValue: e.target.value });
}

searchGroup(){
    Dispatcher.dispatch({
        type: "NOTIFICATION_CLOSE"
    });

    this.setState({ groupSearch:true });
   
    const groupPayload={
        groupName : this.state.searchValue,
        "pagenation": this.state.pagination
    }

    FetchHelper.searchGroup(groupPayload); 
}

modalAddUserContent() {
   
    return (
        <AddUser title={LocaleUtility.getLocaleMessage("um.createusertitle")}
                addUser={this.state.addUser}
                mail={this.state.session.mail}
                handleSubmitButton={this.handleSubmitButton}
                handleUserCancelButton={this.handleUserCancelButton}
               
        />
    );
}

handleSubmitButton(data){
    this.setState({
                addUser: false,
                showCreatePopup:false
            });
    Dispatcher.dispatch({
        type: "USER_CREATE_LOADING_SPIN",
        payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
    });
    data["pagenation"] = this.state.pagination;
    let isTenantAdmin = FetchHelper.getSession().isTenantAdminUser;
    if(isTenantAdmin === 'Y') {
        data["tenantAdmin"] = "Y";
    }
    FetchHelper.addUser(data);
    this.setState({errorcode: []});
}


handleUserCancelButton(){
    this.setState({
        addUser: false,
        showCreatePopup: false,
        errorcode: []
    })
}
modalGroupSearch() {
   
    return (
        <GroupSearch {...this.state}
                title={LocaleUtility.getLocaleMessage("um.assigngroups")}
                groupSearch={this.state.groupSearch}
                closeGroupSearchButton={this.closeGroupSearchButton}
                handleAddButton={this.handleAddButton}

        />
    );
}

closeGroupSearchButton(){
    this.setState({
        groupSearch: false
    }) 
}

handleAddButton(data){
    if(data.resultGroup.length > 0){
        data["pagenation"] = this.state.pagination;
        FetchHelper.addGroupUser(data);
    }
    this.setState({
        groupSearch: false
    })

}

onConfirmRemoveGroup(flag, key){
    if(flag === 1){
        let loadSpinAction = "DELETING_PRODUCT_GROUP_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });

        const payload={
            groupName : key,
            userName : this.state.username,
            "pagenation": this.state.pagination
        }
        FetchHelper.removeGroupFromUser(payload);
    }
}

    removeGroup(key, event){
        confirm(LocaleUtility.getLocaleMessage("um.alert1", 
            {user: this.state.username, group: key}), this.onConfirmRemoveGroup, key);
    }

onConfirmRemoveAction(flag){
    if(flag === 1){
        let loadSpinAction = "DELETING_PRODUCT_GROUP_LOADING_SPIN";
        Dispatcher.dispatch({
            type: loadSpinAction,
            payload: LocaleUtility.getLocaleMessage("label.spinner.loading")
        });

        const payload={
            groupNames : this.state.selectedUserGroups,
            userName : this.state.username,
            "pagenation": this.state.pagination
        }
        FetchHelper.removeGroupFromUser(payload);
    }
}
    removeGroupAction() {
        if(this.state.selectedUserGroups !== undefined && this.state.selectedUserGroups.length > 0) {
            confirm(LocaleUtility.getLocaleMessage("disassociate.warning.message"), this.onConfirmRemoveAction);
        } else {
            ConfigurationUtils.showErrorMessage1("please.select.record", this.context.intl);
        }
    }

    addUser() {        
        Dispatcher.dispatch({
            type: "NOTIFICATION_CLOSE"
        });
        this.setState({errorcode:[] });
        // if(this.state.session.mail !== undefined && this.state.session.mail ){
            this.setState({
                addUser:true,
                showCreatePopup:true
            });
        // } else {
        //     var payload = {
        //         title: LocaleUtility.getLocaleMessage("um.createusertitle"),
        //         content: LocaleUtility.getLocaleMessage("um.createUsermailnotconfiguredmessage"),
        //     }
        //      Dispatcher.dispatch({
        //         type: "MAIL_SERVER_NOT_CONF_ALERT_SHOW_TITLE_MODAL",
        //         payload: payload
        //     });
        // }
    }

  showGroupList() {
        let pRowData = [];
        let paginationObj = this.state.groupsPagination;
        let pageNumber = 1;
        let pageSize = 10;
        let groupsRowData = this.state.userGroupsList;
        if(groupsRowData !== undefined && paginationObj !== undefined) {
            paginationObj.totalCount = groupsRowData.length;
        }
        if(paginationObj !== undefined) {
            pageNumber = paginationObj.pageNumber;
            pageSize = paginationObj.pageSize;
        }
        
        if(groupsRowData !== undefined && groupsRowData.length > pageSize) {
            pRowData = groupsRowData.slice((pageNumber-1)*pageSize, (pageSize*pageNumber));
        } else {
            pRowData = groupsRowData;
        }
        if (this.state.userGroupsList !== undefined && this.state.userGroupsList.length > 0) {
        return (
            <div id="umGroupList">
                <div>
                    <TableGrid id="groups-product-list"
                        header={[
                            {
                                columnName: "groupName",
                                headerLabel: LocaleUtility.getLocaleMessage("label.form.text.groupname"),
                                href: false,
                                width: "auto",
                                columnKey: "groupName"
                            }
                        ]}
                        {...{keys:this.state.selectedUserGroups}}
                        rowData={pRowData}
                        pagination={paginationObj}
                        rowSelection={3}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onGroupPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="groupName"
                        onChangeCheckBoxCallback={this.onGroupRowClicked}
                        selectAllRows={false}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                        "label.grid.item.count.message", {count: pRowData.length})}
                    />
                </div>
            </div>
        )
    } else {
        return LocaleUtility.getLocaleMessage("um.nogroupsassociated");
    }
 }

 onConfirmResetPwd(flag){
     if(flag === 1){
        FetchHelper.resetPasswordFromUser(this.state.username);
     }
 }
 resetPassword() {

     if(validateEmail(this.state.email)){
        if(this.state.session.mail !== undefined && this.state.session.mail ){
            confirm(LocaleUtility.getLocaleMessage("um.alert9", {mail: this.state.username}), this.onConfirmResetPwd);
        } else {
            Dispatcher.dispatch({
                type: "MAIL_SERVER_NOT_CONFIGURED_ALERT_SHOW_MODAL",
                payload: LocaleUtility.getLocaleMessage("label.reset.pwd.form.alert8")
            });
        }
    } else {
        Dispatcher.dispatch({
            type: "EMAIL_INVALID_ALERT_SHOW_MODAL",
            payload: LocaleUtility.getLocaleMessage("um.alert2")
        });
    }
 }


  sectionContent() {

    let emailFormFieldError, fNFormFieldError, lNFormFieldError, emailError, fNError, lNError,submitBtn;

     if(this.state.errorcode !== undefined &&
        this.state.errorcode.length > 0){
        if(this.state.errorcode.indexOf('email') !== -1){
            emailError =<span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("label.input.field.alert")}</span>;
            emailFormFieldError='grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidLength') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert5")}</span>;
            emailFormFieldError = 'grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidEmail') !== -1){
            emailError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert2")}</span>;
            emailFormFieldError = 'grommetux-form-field--error';
        }
        if(this.state.errorcode.indexOf('lastName') !== -1){
            lNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert4")}</span>;
            lNFormFieldError = 'grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidLName') !== -1){
            lNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
            lNFormFieldError = 'grommetux-form-field--error';
        }
        if(this.state.errorcode.indexOf('firstName') !== -1){
            fNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("um.alert3")}</span>;
            fNFormFieldError = 'grommetux-form-field--error';
        } else if(this.state.errorcode.indexOf('invalidFName') !== -1){
            fNError = <span className="grommetux-form-field__error">
            {LocaleUtility.getLocaleMessage("report.alphanumericAndSpaceCheck")}</span>;
            fNFormFieldError = 'grommetux-form-field--error';
        }
        submitBtn=<Button id="user_mgmt_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.save")}
                                                            accent={true} plain={false} disabled={true} />;

    } else {
        emailFormFieldError = '';
        fNFormFieldError = '';
        lNFormFieldError = '';
        emailError = '';
        fNError = '';
        lNError = '';
        submitBtn=<Button id="user_mgmt_submitBtn" label={LocaleUtility.getLocaleMessage("label.button.save")}
                                                            type="submit" plain={false} primary={true} disabled={false}
                                                        />;
    }

let header=[{
                columnName: "userName",
                headerLabel: LocaleUtility.getLocaleMessage("label.form.text.username"),
                width: "auto",
                columnKey: "userName",
            },
            {
                columnName: "firstName",
                headerLabel: LocaleUtility.getLocaleMessage("label.form.text.firstname"),
                href: false,
                width: "auto",
            },
            {
                columnName: "localStatus",
                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.status"),
                href: false,
                width: "auto",
            },
            {
                columnName: "createdtime",
                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.createdtime"),
                href: false,
                width: "auto",
            },
            {
                columnName: "lastloggedintime",
                headerLabel: LocaleUtility.getLocaleMessage("label.grid.col.lastloggedtime"),
                href: false,
                width: "auto",
            }];
            
    if(this.state.session.dbType === 'DB' || this.state.session.dbType === 'LDAP' || this.state.session.dbType === 'DBLDAP'){
        header.push({
                columnName: "delete",
                headerLabel: LocaleUtility.getLocaleMessage("resv.grid.header.pool.delete"),
                columnKey: "userName",
                href: false,
                width: "5%",
                textAlign: "center",
                onClick: this.onDeleteClicked
            });
    }

    let statusStyle = {color:"#0073E7"}
    if(this.state.status === 'inactive'){
        statusStyle = {color:"red"}
    }
   
    return (
      <section pad="none" wrap={false} responsive={false} justify="center"> 
         <div className="action_filter_wrapper">
                        {!this.state.session.saml && this.state.session.dbType !== undefined && (this.state.session.dbType === 'DB') ?
                           <div id="umAddUser" className="button_wrap_primary">
                                <Button label={LocaleUtility.getLocaleMessage("label.create.user")} plain={false} primary={true} onClick={this.addUser.bind(this)} style={{ float: 'right' }}/>
                            </div>
                        : null}
                        {this.state.rowData.length > 0 ? 
                        <div>
                            {/* <a mimetype="excel" title={LocaleUtility.getLocaleMessage("label.tooltip.exportexcel")} onClick={this.exportExcel}><DocumentExcelIcon id="users-export-icon" className="grommetux-control-icon grommetux-color-index-critical"/></a>
                            <span style={{borderRight: "2px solid #ccc",margin: "0 4px 0 0",padding: "0px 3px"}}></span> */}
                            <a mimetype="CSV" title={LocaleUtility.getLocaleMessage("label.tooltip.exportCSV")} onClick={this.exportExcel}><DocumentDownloadIcon id="users-export-icon" colorIndex="brand"/></a>
                        </div>
                        : null}
                        </div>
                <div id="umUserTableGrid">
                    <TableGrid
                        header={header}
                        rowData={this.state.rowData}
                        pagination={this.state.pagination}
                        rowSelection={1}
                        isExportRequired={false}
                        onSort={this.onSortClicked}
                        onFilter={this.onFilterClicked}
                        onPagination={this.onPaginationClicked}
                        sortIndex={this.state.sortIndex}
                        sortAscending={this.state.sortAscending}
                        rowKey="userName"
                        defaultSelectIds={this.state.selectedRowKeys}
                        onChangeCheckBoxCallback={this.onRowClicked}
                        selectAllRows={false}
                        itemsCountMessage={LocaleUtility.getLocaleMessage(
                                            "label.grid.item.count.message", {count: this.state.rowData.length})}
                    />
                    </div>
                    
                        <div id="user-groud-details-tab">
                            <Tabs justify='start' 
                                id="tabs">
                            <Tab id="user-detail-tab-id" title={LocaleUtility.getLocaleMessage("label.user.details")} className='subtab_title primary tabActive'>
                                {/* <Box pad="medium"> */}
                                    <div className="user-details-div border_white_backg_shadow">
                        <div id="userDetailsDiv" className="updateUserDiv">
                            <section pad="none" wrap={false} responsive={false} justify="center">
                                
                                    <Form id="user_form" compact={false} pad="small" plain={true} onSubmit={this.handleUpdateButton} >
                                     <div id="umUserNameDiv" className="userDetails_status_label">
                                            <label>{LocaleUtility.getLocaleMessage("label.grid.col.user")}  : </label>         
                                            <div className="user_name_wrap"><span> {this.state.username}</span></div>
                                    </div>
                                        <div className="userDetails_status_label" style={{display:"flex"}}><label >{LocaleUtility.getLocaleMessage("label.grid.col.status")} : </label>
                                        { this.state.pwdResetStatus ? ( this.state.username !== 'admin' && this.state.username !== this.state.session.currentTenantName &&  
                                            this.state.session.dbType === 'DB' && (<div>&nbsp;<a style={{color:"#0073e7", textDecoration:"none", cursor:"pointer"}} onClick={this.changeStatus}>{LocaleUtility.getLocaleMessage("label.change.status")}</a>&nbsp;&nbsp;(<span style={statusStyle}>{LocaleUtility.getLocaleMessage("label.default.user.pwd.reset.not.done")}</span>)</div>)) 
                                            : ( this.state.username !== 'admin' && this.state.username !== this.state.session.currentTenantName &&  
                                            this.state.session.dbType === 'DB' && (<a style={{color:"#0073e7", textDecoration:"none", cursor:"pointer"}} onClick={this.changeStatus}>{LocaleUtility.getLocaleMessage("label.change.status")}</a>) )
                                         }
                                        </div>
                                        <span style={statusStyle}>
                                            {this.state.statusLocalize}
                                        </span>
                                        <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.email")} className={emailFormFieldError}>
                                            {(this.state.username === this.state.session.currUser &&  
                                                this.state.session.dbType === 'DB') ? (
                                            <TextInput id='email' value={this.state.email} name='email' onChange={event =>this.handleEmailChange(event)}/>
                                            ): (
                                            <TextInput id='email' value={this.state.email} name='email' disabled={true} readOnly="readonly"/>    
                                            )}
                                            {/* {this.state.username !== this.state.session.currUser && (
                                            <TextInput id='email' value={this.state.email} name='email' disabled={true} readOnly="readonly"/>    
                                            )} */}
                                            {emailError}
                                        </FormField>
                                        {this.state.session.dbType === 'DB' && (
                                            <div>
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.firstname")} className={fNFormFieldError}>
                                                <TextInput id='firstName' value={this.state.firstName} name='firstName' onChange={event =>this.handleFirstNameChange(event)} />
                                                {fNError}
                                            </FormField>
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.lastname")} className={lNFormFieldError}>
                                                <TextInput id='lastName' value={this.state.lastName} name='lastName' onChange={event =>this.handleLastNameChange(event)} />
                                                {lNError}
                                            </FormField>
                                            </div>
                                        )}
                                        {this.state.session.dbType !== 'DB' && (
                                            <div>
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.firstname")}>
                                                <TextInput id='firstName' value={this.state.firstName} name='firstName' disabled={true} readOnly="readonly" />
                                            </FormField>
                                            <FormField style={{ width: '30em' }} label={LocaleUtility.getLocaleMessage("label.form.text.lastname")}>
                                                <TextInput id='lastName' value={this.state.lastName} name='lastName' disabled={true} readOnly="readonly" />
                                            </FormField>
                                            </div>
                                        )}
                                        {this.state.session.dbType === 'DB' && !this.state.session.saml && (
                                        <div className="button_left_wrap">
                                            <div className="button_wrap_primary">                                          
                                                            {submitBtn}
                                                            </div>
                                                            <div className="button_wrap_negative">
                                                            <Button id="user_mgmt_cancelBtn" label={LocaleUtility.getLocaleMessage("label.button.cancel")} plain={false} secondary={true} onClick={this.handleCancelButton} />
                                                            </div>
                                                            <Anchor id="user_resetPwd" onClick={this.resetPassword} label={LocaleUtility.getLocaleMessage("label.button.reset.password")}/>
                                        </div>
                                        )}
                                    </Form>
                                
                                </section>
                        </div>
                        <div className="user_details_group" style={{minHeight:"200px", width: "50%", float: "right", margin: "5px", paddingLeft: "5px"}}>
                            <div>
                                {this.state.username !== 'admin' && this.state.username !== this.state.session.currentTenantName && (
                                <span>
                                    <label >{LocaleUtility.getLocaleMessage("label.asso.user.groups")}</label> 
                                    {(this.state.session.dbType === 'DB' || this.state.session.dbType === 'DBLDAP'|| this.state.session.dbType === 'LDAP') &&
                                        this.state.username !== 'admin' && this.state.username !== this.state.session.currentTenantName && (
                                    <span id="group-associate-id">					
                                    <TextInput placeholder={LocaleUtility.getLocaleMessage("label.search.user.groups")} id='searchInput' value={this.state.searchValue} name='firstName' onChange={event =>this.handleSearchField(event)} style={{ width: "35%" }}/>
                                    <Anchor id="user_search"><SearchIcon colorIndex="brand" onClick={this.searchGroup}></SearchIcon></Anchor>
                                    {(this.state.session.dbType === 'DB' || this.state.session.dbType === 'DBLDAP'|| this.state.session.dbType === 'LDAP') && (
                                        <div className="button_wrap_negative">
                                        <Button id="delete-user-group-btn" label={LocaleUtility.getLocaleMessage("label.disassociate.button")} plain={false} primary={true} onClick={event=>this.removeGroupAction()} />
                                        </div>
                                    )}
                                    </span>
                                    )}
                                </span>
                                )}
                            </div>
                            <div>
                                {(this.state.username === 'admin' || this.state.username === this.state.session.currentTenantName) && (
                                    <p style={{marginBottom: "20px", marginTop: "0px", maxWidth: "100%"}}>
                                    {LocaleUtility.getLocaleMessage("admin.gropuList")}</p>
                                )}
                                {this.state.username !== 'admin' && this.state.username !== this.state.session.currentTenantName && (
                                    <div >
                                        {this.showGroupList()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* </Box> */}
                            </Tab>
                            {/* <Tab title="testsfdfds">
                                <Box>
<p>dfsdfsdgdsbbgdhfdgf</p></Box>
                            </Tab> */}
                            </Tabs>
                        </div>
                    
         <SectionWithTitle title={LocaleUtility.getLocaleMessage("label.manage.users")} helpTopic="Users_Management"
                            setionWidth = "100"
                        />
    </section>
    );

  }



   render() {
    return (
      <div className="config-cont-backgroud">
            {this.sectionContent()}
           {this.state.showCreatePopup ? <AddUser title={LocaleUtility.getLocaleMessage("um.createusertitle")}
                addUser={this.state.addUser}
                mail={this.state.session.mail}
                showModal={this.state.showCreatePopup}
                handleSubmitButton={this.handleSubmitButton}
                handleUserCancelButton={this.handleUserCancelButton} />:""}
            {this.modalGroupSearch()}
            <SpinningNotification centerPage={true} />
      </div>

    );
  }
}
// UsersManagment.contextTypes = {
//   intl: _propTypes2.default.object,
// };
export default UsersManagment;