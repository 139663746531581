import React, { Component } from "react";
import Table from "./utill/Table";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";
import Pagination from "./Pagination";
import APPagination from "./APPagination";


import PropTypes from "prop-types";
import "./css/APTable.scss";

class APTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uncheckTheDisabled: this.props.uncheckTheDisabled,
      rowData: this.props.rowData,
      rowKey: this.props.rowKey,
      selectAllRows: this.props.selectAllRows,
      selectedCheckBoxKey: this.getSelectedCheckboxKeys(this.props.selectAllRows, this.props)
    }
    this.onHeaderCheckBoxClick = this.onHeaderCheckBoxClick.bind(this);
    this.onRowCheckBoxClick = this.onRowCheckBoxClick.bind(this);
    this.generateId = this.generateId.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    var prevRow = prevProps.rowData[0];
    var curRow = this.props.rowData[0];
    if((prevProps.rowData.length !== this.props.rowData.length ||
       ( prevRow !== undefined && curRow !== undefined &&
         prevRow[this.props.rowKey] !== curRow[this.props.rowKey]))){
        if(this.state.selectAllRows === true || 
          (this.state.selectAllRows === false && this.state.selectedCheckBoxKey.length === 0)){
            this.setState({
              //selectedCheckBoxKey: this.getSelectedCheckboxKeys(this.state.selectAllRows, this.props),
              didUpdate: false
            });
          }
    } 
    if((prevProps.keys !== undefined && this.props.keys !== undefined &&
         prevProps.keys !== this.props.keys && this.props.keys.length === 0)){
      this.setState({
        selectedCheckBoxKey: [],
        didUpdate: true
      }); 
    }
  }

  getSelectedCheckboxKeys(selectAllRows, props){
    let disabledIds = props.disabledRowIds !== undefined ? 
                      props.disabledRowIds : [];
    if(selectAllRows === true){
      var keys = [];
      props.rowData.map(item => {
          if(props.uncheckTheDisabled === undefined || 
              props.uncheckTheDisabled === false){
            keys.push(item[props.rowKey]);
          } else {
            if(!disabledIds.includes(item[props.rowKey])) {
              keys.push(item[props.rowKey]);
            }
          }
          return "";
        });
      this.props.onChangeCheckBoxCallback(keys);
      return keys;
    } else {
      return [];
    }
  }

  onHeaderCheckBoxClick(event){
    //var selectAll = undefined;
    var keys = [];
    let disabledIds = this.props.disabledRowIds !== undefined ? 
                      this.props.disabledRowIds : [];
	let defaultSelectedIds = this.props.defaultSelectIds !== undefined ? this.props.defaultSelectIds : [];
    if(event.target !== undefined && event.target.checked){
        //selectAll = event.target.checked;
        this.props.rowData.map(item => {
          if(this.props.uncheckTheDisabled === undefined || 
              this.props.uncheckTheDisabled === false){
            keys.push(item[this.props.rowKey])
          } else {
            if(!disabledIds.includes(item[this.props.rowKey])) {
              keys.push(item[this.props.rowKey]);
            }
          }
          return "";
        });
		defaultSelectedIds.map(id =>{
			if(keys.indexOf(id) === -1) {
				keys.push(id);
			}
      return "";
		});
    } else if(event.target !== undefined && !event.target.checked) {
      let resKeys = [];
      if(disabledIds && disabledIds.length > 0) {
        if(this.props.uncheckTheDisabled === undefined || 
            this.props.uncheckTheDisabled === false){
            disabledIds.map(key => {
              resKeys.push(key);
              return "";
            });
        }
        keys = resKeys;
      }
	  defaultSelectedIds.map(id =>{
      if(disabledIds.indexOf(id) > -1) {
        keys.push(id);
      }
      return "";
	  });
	}
	
    this.setState({
        selectAllRows: (keys.length === this.props.rowData.length),
        selectedCheckBoxKey: keys,
        didUpdate: true
    });
    this.props.onChangeCheckBoxCallback(keys, event);
  }

  onRowCheckBoxClick(key, event){
    var keys = Object.assign([], this.state.selectedCheckBoxKey);
    if(event.target.type === 'radio'){
      keys = [];
      keys.push(key);
    } else if(event.target.type === 'checkbox'){
      if(event.target.checked){
        keys.push(key);
      } else {
          var index = keys.indexOf(key)
          if (index !== -1) {
            keys.splice(index, 1);
          }
      }
    }
    this.setState({
        selectAllRows: (keys.length === this.props.rowData.length),
        selectedCheckBoxKey: keys,
        didUpdate: true
    });
    this.props.onChangeCheckBoxCallback(keys, event);
  }
 
 getPagination = (pagination) => {
   if (pagination.required) {
     if (pagination.style === 'basic') {
       return <Pagination  
       totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
           pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
           />;
     } else if (pagination.style === 'default') {

      return <APPagination  
      totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
          pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
          />;
          
     } else {
      return <APPagination  
      totalCount = {pagination.totalCount}    pageNumber ={pagination.pageNumber}
          pageSize = {pagination.pageSize} doPagination = {this.props.onPagination}
          />;
     }
   }
 };

  getHeaderOption = isExportRequired => {
    if (isExportRequired) {
      return (
        <div className="headerOptions">
          <div className="options">
            <a>
              <img src={require("./img/pdf.png")} alt={"Export to pdf"} />
            </a>
          </div>
          <div className="options">
            <a>
              <img
                src={require("./img/xls.png")}
                alt={"Export to excel sheet"}
              />
            </a>
          </div>
        </div>
      );
    }
  };

  generateId() {
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
      let result = "";
      let charactersLength = characters.length;
      for ( var i = 0; i < 5; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }

  render() {

    var showPagination, showCount, tableId;
    if (this.props.pagination !== undefined && this.props.pagination.totalCount > this.props.pagination.pageSize) {
        showPagination = this.getPagination(this.props.pagination);
    } else {
      if (this.props.itemsCountMessage !== undefined && this.props.pagination!==undefined && this.props.pagination.totalCount > 0)
        showCount = <div id="items_found_count_id" style={{marginLeft: "auto", padding: "5px 5px 5px 0px"}}>{this.props.itemsCountMessage}</div>
    }

    tableId = (this.props.id !== undefined ? this.props.id: this.generateId());
    return (
      <div className="tableBody">
        {this.getHeaderOption(this.props.isExportRequired)}
        <Table id={tableId} selectable={true} responsive={false}>
          <TableHeader
            id={tableId}
            header={this.props.header}
            rowSelection={this.props.rowSelection}
            onSort={this.props.onSort}
            setDefaultFilter={this.props.setDefaultFilter}
            onFilter={this.props.onFilter}
            sortIndex={this.props.sortIndex}
            sortAscending={this.props.sortAscending}
            onChangeCheckBoxCallback={this.onHeaderCheckBoxClick}
            isSelectAllClicked={this.state.selectAllRows}
            selectedCheckBoxKey={this.state.selectedCheckBoxKey}
			childKey = {this.props.childKey}
          />
          <TableRows
            header={this.props.header}
            rows={this.props.rowData}
            rowSelection={this.props.rowSelection}
            rowKey={this.props.rowKey}
            onChangeCheckBoxCallback={this.onRowCheckBoxClick}
            selectedCheckBoxKey={this.state.selectedCheckBoxKey}
            uncheckTheDisabled={this.props.uncheckTheDisabled}
            defaultSelectIds={this.props.defaultSelectIds}
            disabledRowIds={this.props.disabledRowIds}
			childKey = {this.props.childKey}
			expandAll = {this.props.expandAll}
			noDataFoundText = {this.props.noDataFoundText}
			addExtraRow = {this.props.addExtraRow}
          />
        </Table>
        <div className="aptable-pagination border_white_backg_shadow">
          {showCount}
          {showPagination}
        </div>
      </div>
    );
  }
}

APTable.propTypes = {
  header: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  sortIndex: PropTypes.number,
  sortAscending: PropTypes.bool
};

export default APTable;
